import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SearchSelect } from 'ui-kit';
import by from 'thenby';

export const PLACEHOLDER = 'Select item...';
export const NO_RESULTS_PLACEHOLDER = 'No item found.';

const toOption = ({
    id,
    name,
    group,
    description,
}) => {
    const label = group.parent ? `${name} ${group.name} ${group.parent.name}` : `${name} ${group.name}`;

    return ({
        value: id,
        label,
        name,
        group,
        description,
    })
};

const toGroupWithParent = (groups) => (group) => ({
    ...group,
    parent: groups.find(({ id }) => id === group.parent)
});

const toItemWithGroup = (groups) => (item) => ({
    ...item,
    group: toGroupWithParent(groups)(groups.find(({ id }) => id === item.group))
});

const MarginField = styled.span`
    margin-left: var(--spacingSmaller);
`;

const ItemOption = ({
    option = {},
}) => {
    if (typeof option.group.parent !== 'undefined') {
        return (
            <div>
                <span>{option.group.parent.fullName}</span> - <span>{option.group.name}</span>
                <MarginField><strong>{option.name}</strong></MarginField>
            </div>
        );
    } else {
        return (
            <div>
                <span>{option.group.name}</span>
                <MarginField><strong>{option.name}</strong></MarginField>
            </div>
        );
    }
};

const SelectItem = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    value,
    items = [],
    groups = [],
    onChange = () => {},
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        options={items
            .map(toItemWithGroup(groups))
            .map(toOption)
            .sort(by(item => item.group.fullName, { ignoreCase: true }))
        }
        onChange={onChange}
        renderOption={option => <ItemOption option={option} />}
    />
);

SelectItem.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    modes: PropTypes.array,
    onChange: PropTypes.func,
};

export default SelectItem;
