import React from 'react'
import classNames from 'classnames'
import './Protip.module.css'

const Protip = ({ isCentered, children }) => (
    <div styleName={classNames("protip", { "center": isCentered })}>
        <i className="bs-icon-lightbulb-on" />
        <strong styleName="title">Protip!</strong>
        <div>
            {children}
        </div>
    </div>
);

export default Protip;
