import { schema } from 'normalizr';
import * as Attendee from '../attendees/schemas';
import * as Driver from 'app/react/entities/events/drivers/schemas';
import * as Mode from './modes/schemas';

export const ENTITY_REDUCER_KEY = 'pickups';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const ENTITY_CONTEXT_ID_KEY = 'pickupId';

const processStrategy = (entity) => ({
    ...entity,
    duration: typeof entity.duration !== 'undefined' ? entity.duration / 60 : undefined,
    driver: typeof entity.driver !== 'undefined' ? entity.driver : null
});

const entityDefinition = {
    passengers: Attendee.entities,
    driver: Driver.entity,
    mode: Mode.entity
};

const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);

entity.define({
    booking: require('app/react/entities/events/bookings/schemas').entity,
});
