import React from 'react'
import './PlaylistPreview.module.css'

const PlaylistPreview = () => (
    <div styleName="previewContainer">
        <img
            styleName="preview"
            src="https://s3.eu-central-1.amazonaws.com/assets.beatswitch.com/promoter-web-app/playlist-official-preview.png"
            alt="Official playlist preview"
        />
        <img
            styleName="preview"
            src="https://s3.eu-central-1.amazonaws.com/assets.beatswitch.com/promoter-web-app/playlist-personalised-preview.png"
            alt="Personalised playlist preview"
        />
    </div>
);

export default PlaylistPreview;
