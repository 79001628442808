import React from 'react'
import SearchSelectUsers from 'app/react/views/event/users/SearchSelectUsers'

const SearchSelectUsersWithState = (props) => {
    const { data, onDataLoaded } = props;
    const { loading, event } = data;
    let users = [];

    if (!loading && event) {
        users = event.users.edges.map(edge => edge.node);
    }

    return (
        <SearchSelectUsers
            {...props}
            users={users || []}
        />
    );
};

export default SearchSelectUsersWithState;
