export const initialState = {
    isFetching: false,
    hasCache: false,
    isSearching: false,
    ids: [],
    latestSearchIds: [],
    recentSearches: {}
};

export const collectionRequestReducer = (state = initialState, action) => {
    const { searchTerm } = action;
    if (typeof searchTerm !== 'undefined') {
        return {
            ...state,
            isSearching: true,
        };
    }
    return {
        ...state,
        isFetching: true,
    };
};

export const collectionSuccessReducer = (state, action) => {
    const { response, searchTerm } = action;
    if (typeof searchTerm !== 'undefined') {
        return {
            ...state,
            isSearching: false,
            latestSearchIds: response.result,
            recentSearches: {
                ...state.recentSearches,
                [searchTerm]: response.result
            }
        };
    }
    return {
        ...state,
        isFetching: false,
        ids: response.result,
        hasCache: true,
    };
};

export const collectionFailureReducer = (state, action) => {
    const { searchTerm } = action;
    if (typeof searchTerm !== 'undefined') {
        return {
            ...state,
            isSearching: false,
        };
    }
    return {
        ...state,
        isFetching: false
    };
};

export const collectionRequestReducerWithKey = (state, action, key) => ({
    ...state,
    [key]: collectionRequestReducer(state[key], action)
});

export const collectionSuccessReducerWithKey = (state, action, key) => ({
    ...state,
    [key]: collectionSuccessReducer(state[key], action)
});

export const collectionFailureReducerWithKey = (state, action, key) => ({
    ...state,
    [key]: collectionFailureReducer(state[key], action)
});
