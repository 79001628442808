
import dotProp from 'dot-prop-immutable';
import {
    REGISTER_COMPONENT,
    UNREGISTER_COMPONENT,
    MERGE_COMPONENT_STATE,
    SET_COMPONENT_STATE,
    SET_COMPONENT_STATE_VALUE,
    MERGE_COMPONENT_STATE_VALUE,
    DELETE_COMPONENT_STATE_VALUE
} from '../actions/_index';

export const components = (state = {}, action) => {
    const {
        type,
        componentId,
        componentState,
        path,
        value
    } = action;
    switch (type) {
        case REGISTER_COMPONENT:
            return dotProp.set(state, componentId, componentState || {});
        case UNREGISTER_COMPONENT: {
            return dotProp.delete(state, componentId);
        }
        case SET_COMPONENT_STATE: {
            return dotProp.set(state, componentId, componentState);
        }
        case MERGE_COMPONENT_STATE: {
            const mergedState = {
                ...state[componentId],
                ...componentState
            };
            return dotProp.set(state, componentId, mergedState);
        }
        case SET_COMPONENT_STATE_VALUE: {
            const fullPath = `${componentId}.${path}`;
            return dotProp.set(state, fullPath, value);
        }
        case MERGE_COMPONENT_STATE_VALUE: {
            const fullPath = `${componentId}.${path}`;
            const oldValue = dotProp.get(state, fullPath);
            const mergedState = {
                ...oldValue,
                ...value
            };
            return dotProp.set(state, fullPath, mergedState);
        }
        case DELETE_COMPONENT_STATE_VALUE: {
            const fullPath = `${componentId}.${path}`;
            return dotProp.delete(state, fullPath);
        }
        default:
            return state;
    }
}
