import React from 'react';
import {parseQueryString} from 'app/react/helpers/_index';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Event from 'app/react/entities/events/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Performer from 'app/react/entities/events/bookings/performers/index';
import * as File from 'app/react/entities/files/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as TicketType from 'app/react/entities/events/ticket_types/index';
import * as PWArtistSettings from 'app/react/entities/events/production_website/settings/artists/index';
import * as PWArtistSettingsSection from 'app/react/entities/events/production_website/settings/artists/sections/index';
import PerformerCreateView from 'app/react/views/event/artists/bookings/performers/views/create/CreateViewContainer';
import PerformerUpdateView from 'app/react/views/event/artists/bookings/performers/views/update/UpdateViewContainer';
import * as UpdateViewState from 'app/react/views/event/artists/bookings/performers/views/update/State';
import * as Routes from './performerRoutes';

export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.create(), null, this.create.bind(this));
        this.router.route(Routes.update(), null, this.update.bind(this));
    }

    create(eventId, bookingId, queryString) {
        const { app, store, dispatch } = this;
        const { redirect } = queryString !== null ? parseQueryString(queryString) : {};
        app.renderContentComponent(React.createElement(PerformerCreateView, { store, redirect }));
        // Define the working context
        // Current accountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(Booking.actions.setCurrentId(parseInt(bookingId, 10)));
        // Current Booking is set from DOM in the initial state
        const currentBooking = Booking.stateHelpers.getCurrent(store.getState());
        const artistTypeId = currentBooking.artistType;

        dispatch(ArtistType.actions.setCurrentId(artistTypeId));
        dispatch(PWArtistSettingsSection.actions.setCurrentId('performers'));

        return dispatch(ArtistType.thunks.fetchOneForCurrentContext(artistTypeId)).then(() => {
            return Promise.all([
                dispatch(PWArtistSettings.thunks.fetchCurrent()),
                dispatch(TicketType.thunks.fetchAllForCurrentContext())
            ]);
        });
    }

    update(eventId, bookingId, performerId, queryString) {
        const { app, store, dispatch } = this;
        const { redirect } = queryString !== null ? parseQueryString(queryString) : {};
        app.renderContentComponent(React.createElement(PerformerUpdateView, { store, redirect }));
        // Define the working context
        // Current accountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(Booking.actions.setCurrentId(parseInt(bookingId, 10)));
        dispatch(Performer.actions.setCurrentId(parseInt(performerId, 10)));
        // Current Booking is set from DOM in the initial state
        const currentBooking = Booking.stateHelpers.getCurrent(store.getState());
        const artistTypeId = currentBooking.artistType;

        dispatch(ArtistType.actions.setCurrentId(artistTypeId));
        dispatch(PWArtistSettingsSection.actions.setCurrentId('performers'));
        return Promise.all([
            dispatch(ArtistType.thunks.fetchOneForCurrentContext(artistTypeId)).then(() => {
                return dispatch(PWArtistSettings.thunks.fetchCurrent());
            }),
            dispatch(Performer.thunks.fetchCurrent(['passport'])).then(() => {
                const state = store.getState();
                const currentPerformer = Performer.stateHelpers.getCurrent(state);
                const selectedPassport = File.stateHelpers.getOne(state, currentPerformer.passport);
                dispatch(UpdateViewState.mergeViewState({
                    values: currentPerformer,
                    selectedPassport
                }));
            }),
            dispatch(TicketType.thunks.fetchAllForCurrentContext())
        ]);
    }

}
