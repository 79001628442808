import { Model }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';

@Model({
    key: 'accreditation_production_website_settings'
})
class AccreditationProductionWebsiteSettings extends BaseModel {
}

export {
    AccreditationProductionWebsiteSettings as Model
};

