import * as Bookings from 'app/react/entities/events/bookings/helpers';
import * as Account from 'app/react/entities/accounts/helpers'
import * as Schemas from './schemas'
import {
    getOneEntity,
    getAllEntities,
    getEntitiesCollectionByKey,
    getSomeEntities,
    getSomeEntitiesDenormalized,
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} = Schemas;

export const getAll = (state) =>
    getAllEntities(state, ENTITY_REDUCER_KEY);

export const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);

export const getCurrent = (state) => {
    const booking = Bookings.getCurrent(state);
    return getOne(state, booking.artist);
};

export const getSome = (state, ids, denormalize = false) => {
    if (denormalize) {
        return getSomeEntitiesDenormalized(state, ENTITY_REDUCER_KEY, Schemas.entity, ids);
    }
    return getSomeEntities(state, ENTITY_REDUCER_KEY, ids);
};

export const getCollection = (state, accountId) => {
    const key = Account.generateCollectionKey(accountId);
    return getEntitiesCollectionByKey(state, COLLECTION_REDUCER_KEY, key);
};

export const getCollectionForCurrentContext = (state) => {
    const accountId = Account.getCurrentId(state);
    return getCollection(state, accountId);
};

export const getSearchResultsForCurrentContext = (state, searchTerm, denormalize) => {
    const collection = getCollectionForCurrentContext(state);
    const ids = collection.recentSearches[searchTerm];
    return getSome(state, ids, denormalize)
};

export const getLatestSearchResultsForCurrentContext = (state, denormalize = false) => {
    const collection = getCollectionForCurrentContext(state);
    const ids = collection.latestSearchIds;
    return getSome(state, ids, denormalize)
};

export const hasResultsForSearchTerm = (state, accountId, searchTerm) => {
    const collection = getCollection(state, accountId);
    return !! collection.recentSearches[searchTerm]
};

export const isSeachingForCurrentContext = (state) =>
    !! getCollectionForCurrentContext(state).isSearching;
