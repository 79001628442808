import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHTTP } from "../../hooks/UseHTTP";
import Icon from "../../utils/Icon";
import Button from "../atoms/button/Button";
import ArtistTypeDialog from "../atoms/dialogs/artistType";
import AddArtistTypeDialog from "../atoms/dialogs/artistType/CreateDialog";
import Footer from "../footer/Footer";

export const ArtistFormSchema = () =>
  yup.object({
    name: yup.string().min(2).max(100).required().nullable(),
  });

export const ArtistForm = ({ setActiveStep, activeSubStep, eventState }) => {
  const [artistTypeToEditId, setArtistTypeToEditId] = useState();
  const [artistTypeSections, setArtistTypeSections] = useState([]);
  const [profileTypes, setProfileTypes] = useState(
    eventState.event.profileTypes || []
  );
  const [ticketTypes, setTicketTypes] = useState(
    eventState.event.ticketTypes || []
  );
  const [addingArtistType, setAddingArtistType] = useState(false);
  const [artistTypes, setArtistTypes] = useState(
    eventState.event.artistTypes || []
  );
  const { doHttpCallJson: doHttpCall } = useHTTP();

  const [loadingArtistTypes, setLoadingArtistTypes] = useState(false);

  console.log(artistTypes);

  useEffect(() => {
    const getArtistTypes = (id) =>
      doHttpCall(`/api/events/${id}/artist-types?limit=all`, "GET")
        .then((artistTypesRes) => {
          if (artistTypesRes && artistTypesRes.data) {
            return artistTypesRes;
          }
        })
        .catch((err) => {});
    const getProfileTypes = async (id) => {
      doHttpCall(
        `/api/events/${eventState.event.id}/profiles?limit=all`,
        "GET"
      ).then((profileTypes) => {
        if (profileTypes && profileTypes.data) {
          setProfileTypes(profileTypes.data);
        }
      });
    };
    const getTicketTypes = async (id) => {
      doHttpCall(
        `/api/events/${eventState.event.id}/ticket-types?limit=all`,
        "GET"
      ).then((ticketTypes) => {
        if (ticketTypes && ticketTypes.data) {
          setTicketTypes(ticketTypes.data);
        }
      });
    };

    if (
      (!eventState.event.artistTypes ||
        eventState.event.artistTypes.length === 0) &&
      eventState.event.oldEventId
    ) {


      const newAndOldTypes = [
        Promise.resolve(getArtistTypes(eventState.event.oldEventId)),
        Promise.resolve(getArtistTypes(eventState.event.id)),
      ];
      Promise.all(newAndOldTypes).then((res) => {
        // "new" meaning: belonging to the new event. If it's just the default artist type we can copy the old data
        const newTypes = res[1].data.length == 1 && res[1].data[0].name == 'Default Artist Type' ? res[1].data.map((type) => type.id) : [];

        // while we're doing all this, we should clear the list
        setArtistTypes([]);

        const oldTypes = res[0].data
          // .filter((type) => !newTypes.includes(type.name))
          .map((type) => ({ name: type.name, originalId: type.id }));

        setLoadingArtistTypes(true);
        batchCreateArtistTypes(oldTypes).then(() => {
          const removeOldsPromises = newTypes.map((id) => deleteArtistType(id));

          Promise.all(removeOldsPromises).then(() => {
            console.log('done deleting');
          })
        });
        setLoadingArtistTypes(false);


        
      });
    } else {
      getArtistTypes(eventState.event.id).then((res) => {
        setArtistTypes(res.data);
      });
    }
    if (
      !eventState.event.profileTypes ||
      eventState.event.profileTypes.length === 0
    ) {
      getProfileTypes();
    }
    if (
      !eventState.event.ticketTypes ||
      eventState.event.ticketTypes.length === 0
    ) {
      getTicketTypes();
      Array.con;
    }
  }, []);

  const createArtistType = async (body) => {
    doHttpCall(`/api/events/${eventState.event.id}/artist-types`, "POST", body)
      .then((artistTypeRes) => {
        if (artistTypeRes && artistTypeRes.data && artistTypeRes.data.id) {
          setArtistTypes((prev) => [...prev, artistTypeRes.data]);
          getArtistTypeSectionsById(artistTypeRes.data.id);
        }
      })
      .catch((err) => {});
  };

  const batchCreateArtistTypes = async (types) => {
    const promises = types.map((type) => (
      Promise.resolve(
        doHttpCall(
          `/api/events/${eventState.event.id}/artist-types/${type.originalId}/copy`,
          "POST",
          {}
        )
    )));
    Promise.all(promises)
      .then((artistTypeRes) => {
        setArtistTypes((prev) => [
          ...prev,
          ...artistTypeRes.map((res) => res.data),
        ]);
      })
      .catch((error) => {
        setError(
          "Something went wrong while copying the artist types from the previous event"
        );
        console.error("batch create artist types:: ", error);
      });
  };

  const getArtistTypeSectionsById = async (id) => {
    doHttpCall(
      `/api/events/${eventState.event.id}/artist-types/${id}/production-website/artists/settings?include=sections.form_fields`,
      "GET"
    )
      .then((artistTypeSectionsRes) => {
        if (
          artistTypeSectionsRes &&
          artistTypeSectionsRes.data &&
          artistTypeSectionsRes.data.sections
        ) {
          setArtistTypeSections([
            ...artistTypeSectionsRes.data.sections.data,
            {
              id: "intro",
              intro: artistTypeSectionsRes.data.intro,
              closed: false,
              deadline: artistTypeSectionsRes.data.deadline,
            },
          ]);
          setArtistTypeToEditId(id);
          setAddingArtistType(false);
        }
      })
      .catch((err) => {});
  };

  const deleteArtistType = async (artistTypeId) => {
    doHttpCall(
      `/api/events/${eventState.event.id}/artist-types/${artistTypeId}`,
      "DELETE"
    )
      .then((artistTypeRes) => {
        if (artistTypeRes && artistTypeRes.status === 204) {
          removeArtistType(artistTypeId);
        }
      })
      .catch((err) => {});
  };

  const updateArtistTypeSection = async (artistTypeId, section, body) => {
    doHttpCall(
      `/api/events/${eventState.event.id}/artist-types/${artistTypeId}/production-website/artists/setttings/${section}`,
      "PUT",
      body
    )
      .then((artistTypeRes) => {
        if (artistTypeRes && artistTypeRes.status === 204) {
          removeArtistType(artistTypeId);
        }
      })
      .catch((err) => {});
  };

  const removeArtistType = (artistTypeId) => {
    setArtistTypes((prev) =>
      prev.filter(
        (existingArtistType) => existingArtistType.id !== artistTypeId
      )
    );
  };

  return (
    <div className="form_content">
      <h2>Artist types</h2>
      <h3 className="form-sub-title">Add artists attending your event</h3>
      <span>
        Edit the default artist type or add your own artist types below. Then,
        customize which input sections you need from each artist type in the
        production website.
      </span>
      {artistTypes && artistTypes.length > 0 && (
        <table className="form__table">
          <thead>
            <tr className="form__table-header">
              <th>TITLE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {artistTypes.map((type) => {
              return (
                <tr className="form__table-row" key={type.id}>
                  <td>{type.name}</td>
                  <td className="form__table__last-column">
                    <button
                      className="form__table__button"
                      onClick={() => getArtistTypeSectionsById(type.id)}
                    >
                      <Icon
                        size={26}
                        name="edit"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                    {type.name !== "Default Artist Type" && (
                      <button
                        className="form__table__button"
                        onClick={() => deleteArtistType(type.id)}
                      >
                        <Icon
                          size={26}
                          name="delete"
                          style={{ marginBottom: "-10px" }}
                        />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <br />
      <Button
        label="Add artist type"
        variant="secondary"
        onClick={() => setAddingArtistType(true)}
      />
      {artistTypeToEditId && artistTypeSections.length > 0 && (
        <ArtistTypeDialog
          artistTypeId={artistTypeToEditId}
          artistTypeName={
            artistTypes.find(({ id }) => id === artistTypeToEditId).name
          }
          eventState={eventState}
          setCancelDialogOpen={() => {
            setArtistTypeToEditId(undefined);
            setArtistTypeSections([]);
          }}
          updateArtistTypeSection={updateArtistTypeSection}
          sections={artistTypeSections}
          profileTypes={profileTypes}
          ticketTypes={ticketTypes}
        />
      )}
      {addingArtistType && (
        <AddArtistTypeDialog
          createArtistType={createArtistType}
          setCancelDialogOpen={setAddingArtistType}
        />
      )}
      <Footer
        setActiveStep={(step) =>
          setActiveStep(step, { artistTypes: artistTypes })
        }
        activeSubStep={activeSubStep}
        eventState={eventState}
        isNextDisabled={loadingArtistTypes || artistTypes.length == 0}
      />
    </div>
  );
};

ArtistForm.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
  eventState: PropTypes.object,
};

export default ArtistForm;
