import BaseView from 'app/backbone/components/Base.view'
import $, { contains } from 'jquery'
import Popper from 'popper.js'
import { createPicker, parseColor } from 'a-color-picker'
import './ColorPicker.css'

const CLASS_PICKER = 'a-color-picker';

export default class ColorPickerView extends BaseView {

    ui() {
        return {
            input: 'input[type=text]',
            preview: '.colorPreview',
        };
    }

    events() {
        return {
            'click input': 'openPicker',
            'focus input': 'openPicker',
            'focusout input': 'removePicker',
            'change input': 'updatePreview',
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.picker = undefined;
        this.clickedElement = undefined;
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        $(document).on('mousedown', this.registerClickedElement.bind(this));
        return this.delegateEvents();
    }

    registerClickedElement({ target }) {
        this.clickedElement = target
    }

    openPicker() {
        if (this.picker) {
            return;
        }

        this.picker = createPicker({
            attachTo: this.$el,
            color: this.currentColor(),
            showHSL: false,
            showRGB: false,
            showHEX: false,
        });
        this.picker.on('change', this.onChangeColor.bind(this));

        new Popper(this.$el, this.picker.element);
    }

    currentColor() {
        return this.ui.input.val() || this.ui.input.data('default-color');
    }

    onChangeColor(target, color) {
        const hexColor = parseColor(color, 'hex');

        this.ui.input.focus();
        this.ui.input.val(hexColor).trigger('change');
    }

    removePicker({ relatedTarget }) {
        const { clickedElement } = this;
        this.clickedElement = undefined;

        if (relatedTarget && this.isChild(relatedTarget)) {
            return;
        }

        if (clickedElement && this.isChild(clickedElement)) {
            return;
        }

        this.picker.element.remove();
        this.picker = undefined;
    }

    isChild(target) {
        return contains(this.$el[0], target);
    }

    updatePreview() {
        this.ui.preview[0].style.backgroundColor = this.currentColor();
    }
}
