import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BookingStatusSquare from '../../booking_statuses/components/BookingStatusSquare';

const BookingName = styled.span`
    margin-right: 1rem !important;
`;

const BookingType = styled.strong`
    margin-right: 1rem !important;
`;

const DayName = styled.span`
    margin-left: 1rem !important;
`;

const StageName = styled.span`
    margin-left: 1rem;
`;

export const toBookingOption = ({
    id,
    name,
    artist = {},
    type = {},
    status = {},
    performance,
}) => ({
    value: id,
    label: artist.name,
    artistName: artist.name,
    typeName: type.name,
    statusColor: status.color,
    isAssigned: typeof performance !== 'undefined',
    dayName: performance && performance.day.name,
    stageName: performance && performance.stage.name,
});

const BookingOption = ({
    option = {},
    children,
}) => {
    const { artistName, typeName, statusColor, isAssigned, dayName, stageName } = option;

    return (
        <span>
            <BookingName>{artistName}</BookingName>
            <BookingType>{typeName}</BookingType>
            <BookingStatusSquare color={statusColor} />
            {isAssigned &&
                <DayName>{dayName}</DayName>
            }
            {isAssigned &&
                <StageName>{stageName}</StageName>
            }
            {children}
        </span>
    );
}

BookingOption.propTypes = {
    option: PropTypes.shape({
        label: PropTypes.string,
        artistName: PropTypes.string,
        typeName: PropTypes.string,
        statusColor: PropTypes.string
    })
};

export default BookingOption;
