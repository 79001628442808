import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as Performance from 'app/react/entities/events/performances/index';
import * as Event from 'app/react/entities/events/index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import {
    navigate,
    showErrorPopup,
    showSuccessPopup
} from 'app/react/state/actions/_index';
import {
    getModalState,
    setModalState,
    deleteModalState,
    mergeDeleteConfirmModalState,
    openDeleteConfirmModal
} from '../ModalState';
import BookingTable from './Table';

const DELETE_TITLE = 'Delete booking';
const DELETE_TEXT = name =>
    `Are you sure you want to delete <strong>${name}?</strong>`;
const DELETE_BUTTON_TEXT = name =>
    `Yes, delete <strong>${name}</strong>`;
const REMOVE_FROM_PERFORMANCE_TITLE = 'Remove booking from performance';
const REMOVE_FROM_PERFORMANCE_TEXT = name =>
    `Are you sure you want to remove <strong>${name}</strong> from this performance?`;
const REMOVE_FROM_PERFORMANCE_BUTTON_TEXT = name =>
    `Yes, remove <strong>${name}</strong> from performance`;
const POPUP_MESSAGES = {
    FAILED_TO_CREATE: () =>
        `Failed to create booking.`,
    SUCCESSFUL_CREATE: name =>
        `Booking <strong>${name}</strong> successfully created.`,
    FAILED_TO_UPDATE: name =>
        `Failed to update booking <strong>${name}</strong>`,
    SUCCESSFUL_UPDATE: name =>
        `Booking <strong>${name}</strong> successfully updated.`,
    FAILED_TO_DELETE: name =>
        `Failed to delete booking <strong>${name}</strong>.`,
    SUCCESSFUL_DELETE: name =>
        `Booking <strong>${name}</strong> successfully deleted.`,
    FAILED_TO_REMOVE_FROM_PERFORMANCE: name =>
        `Failed to remove booking <strong>${name}</strong> from performance.`,
    SUCCESSFUL_REMOVE_FROM_PERFORMANCE: name =>
        `Booking <strong>${name}</strong> successfully deleted from performance.`
};

const mapStateToProps = (state) => {
    const {
        selectedPerformanceId,
        newBooking,
        existingBooking,
        editingBookings,
        isCreatingNewArtist
    } = getModalState(state);
    const performance = Performance.stateHelpers.getOne(state, selectedPerformanceId) || {};
    return {
        eventId: Event.stateHelpers.getCurrentId(state),
        accountId: stateHelpers.getCurrentAccountId(state),
        performanceId: selectedPerformanceId,
        isArtistTypesEnabled: Event.stateHelpers.isArtistTypesEnabledForCurrent(state),
        bookings: Booking.stateHelpers.getSomeDenormalized(state, performance.bookings),
        artists: Artist.stateHelpers.getAll(state),
        artistTypes: ArtistType.stateHelpers.getAllForCurrentContext(state),
        bookingTypes: BookingType.stateHelpers.getAll(state),
        bookingStatuses: BookingStatus.stateHelpers.getAllForCurrentContext(state),
        unassignedBookings: Booking.stateHelpers.getAll(state, true).filter(({ isUnassigned }) => isUnassigned),
        isFetchingUnassignedBookings: Booking.stateHelpers.isFetchingAllUnassignedForCurrentEvent(state),
        newBooking,
        isCreatingNewArtist,
        existingBooking,
        editingBookings,
        performance
    };
};

const mapDispatchToProps = dispatch => ({
    onViewDetailsBooking: (eventId, bookingId) => {
        dispatch(navigate.newTab(`/events/${eventId}/artists/bookings/${bookingId}`));
    },
    onCreateNewBooking: () => {
        dispatch(setModalState('newBooking', {}));
    },
    onAddExistingBooking: (accountId, eventId) => {
        dispatch(setModalState('existingBooking', {}));
        dispatch(Booking.actions.fetchAllUnassigned(accountId, eventId));
    },
    onChangeNewBookingValue: (key, value) => {
        dispatch(setModalState(`newBooking.${key}`, value));
    },
    onChangeExistingBookingValue: (key, value) => {
        dispatch(setModalState(`existingBooking.${key}`, value));
    },
    onToggleCreateNewArtist: (isCreating = false) => {
        dispatch(setModalState('isCreatingNewArtist', isCreating));
        if (!isCreating) dispatch(deleteModalState('newBooking.artistName'));
    },
    onSaveNewBooking: (eventId, performanceId, values, artistName) => {
        const apiValues = {
            ...values,
            performance: { id: performanceId },
            artist: values.artistName ?
                { name: values.artistName } :
                { id: values.artist }
        };
        dispatch(setModalState('newBooking.isSaving', true));
        dispatch(Booking.actions.createOneForPerformance(eventId, performanceId, apiValues))
            .then(({ error, response }) => {
                dispatch(setModalState('newBooking.isSaving', false));
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(setModalState('newBooking.errors', errors));
                    dispatch(showErrorPopup(POPUP_MESSAGES.FAILED_TO_CREATE(artistName)));
                } else {
                    dispatch(deleteModalState('isCreatingNewArtist'));
                    dispatch(deleteModalState('newBooking'));
                    dispatch(showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_CREATE(artistName)));
                    const apiStatusValue = {
                        status: apiValues.status,
                    }
                    dispatch(Performance.actions.updateOne(eventId, performanceId, apiStatusValue));
                }
            });
    },
    onCancelNewBooking: () => {
        dispatch(deleteModalState('newBooking'));
    },
    onSaveAddExistingBooking: (eventId, performanceId, bookingId, artistName) => {
        const apiValues = {
            performance: performanceId
        };
        dispatch(setModalState('existingBooking.isSaving', true));
        dispatch(Booking.actions.updateOneForPerformance(eventId, performanceId, bookingId, apiValues))
            .then(({ error }) => {
                dispatch(setModalState('existingBooking.isSaving', false));
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(setModalState('existingBooking.errors', errors));
                    dispatch(showErrorPopup(POPUP_MESSAGES.FAILED_TO_UPDATE(artistName)));
                } else {
                    dispatch(deleteModalState('existingBooking'));
                    dispatch(showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_UPDATE(artistName)));
                    const apiStatusValue = {
                        status: apiValues.status,
                    }
                    dispatch(Performance.actions.updateOne(eventId, performanceId, apiStatusValue));
                }
            });
    },
    onCancelAddExistingBooking: () => {
        dispatch(deleteModalState('existingBooking'));
    },
    onUpdateBooking: (editingBookings = [], booking) => {
        dispatch(setModalState('editingBookings', [...editingBookings, { ...booking }]));
    },
    onChangeBookingValue: (key, value, index) => {
        dispatch(setModalState(`editingBookings.${index}.${key}`, value));
    },
    onSaveBooking: (eventId, performanceId, bookingId, index, values, artistName, onSuccessCallback) => {
        let typeId;
        let statusId;
        let artistTypeId;
        if (typeof values.type !== 'undefined') {
            typeId = values.type.id
        }
        if (typeof values.status !== 'undefined') {
            statusId = values.status.id
        }
        if (typeof values.artistType !== 'undefined') {
            artistTypeId = values.artistType.id
        }
        const apiValues = {
            type: typeId,
            status: statusId,
            artistType: artistTypeId
        };
        dispatch(setModalState(`editingBookings.${index}.isSaving`, true));
        dispatch(Booking.actions.updateOneForPerformance(eventId, performanceId, bookingId, apiValues))
            .then(({ error }) => {
                dispatch(setModalState(`editingBookings.${index}.isSaving`, false));
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(setModalState(`editingBookings.${index}.errors`, errors));
                    dispatch(showErrorPopup(POPUP_MESSAGES.FAILED_TO_UPDATE(artistName)));
                } else {
                    dispatch(deleteModalState(`editingBookings.${index}`));
                    dispatch(showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_UPDATE(artistName)));
                    const apiStatusValue = {
                        status: statusId,
                    }
                    dispatch(Performance.actions.updateOne(eventId, performanceId, apiStatusValue));
                }
            });
        
    },
    onCancelBooking: (editingBookings = [], bookingId) => {
        dispatch(setModalState('editingBookings', editingBookings.filter(({ id }) => id !== bookingId)));
    },
    onRemoveBookingFromPerformance: (eventId, performanceId, bookingId, artistName) => {
        const failedPopup = POPUP_MESSAGES.FAILED_TO_REMOVE_FROM_PERFORMANCE(artistName);
        const successPopup = POPUP_MESSAGES.SUCCESSFUL_REMOVE_FROM_PERFORMANCE(artistName);
        const values = { performance: null };
        const action = {
            ...Booking.actions.updateOneForPerformance(eventId, performanceId, bookingId, values),
            successPopup,
            failedPopup
        };
        dispatch(mergeDeleteConfirmModalState({
            title: REMOVE_FROM_PERFORMANCE_TITLE,
            text: REMOVE_FROM_PERFORMANCE_TEXT(artistName),
            buttonText: REMOVE_FROM_PERFORMANCE_BUTTON_TEXT(artistName)
        }));
        dispatch(openDeleteConfirmModal(action));
    },
    onDeleteBooking: (eventId, performanceId, bookingId, artistName) => {
        const failedPopup = POPUP_MESSAGES.FAILED_TO_DELETE(artistName);
        const successPopup = POPUP_MESSAGES.SUCCESSFUL_DELETE(artistName);
        const action = {
            ...Booking.actions.deleteOneForPerformance(eventId, performanceId, bookingId),
            successPopup,
            failedPopup
        };
        dispatch(mergeDeleteConfirmModalState({
            title: DELETE_TITLE,
            text: DELETE_TEXT(artistName),
            buttonText: DELETE_BUTTON_TEXT(artistName)
        }));
        dispatch(openDeleteConfirmModal(action));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onViewDetailsBooking: (bookingId) => {
        const { eventId } = stateProps;
        dispatchProps.onViewDetailsBooking(eventId, bookingId);
    },
    onSaveNewBooking: () => {
        const { eventId, performanceId, newBooking, artists } = stateProps;
        const artist = artists.find(({ id }) => id === newBooking.artist);
        dispatchProps.onSaveNewBooking(eventId, performanceId, newBooking, artist && artist.name);
    },
    onUpdateBooking: (bookingId) => {
        const { bookings, editingBookings } = stateProps;
        const booking = bookings.find(({ id }) => id === bookingId);
        dispatchProps.onUpdateBooking(editingBookings, booking);
    },
    onChangeBookingValue: (key, value, bookingId) => {
        const { editingBookings, artistTypes, bookingTypes, bookingStatuses } = stateProps;
        const booking = editingBookings.find(({ id }) => id === bookingId);
        const index = editingBookings.indexOf(booking);
        let finalValue = value;
        if (key === 'artistType') {
            finalValue = artistTypes.find(({ id }) => id === value);
        } else if (key === 'type') {
            finalValue = bookingTypes.find(({ id }) => id === value);
        } else if (key === 'status') {
            finalValue = bookingStatuses.find(({ id }) => id === value);
        }
        dispatchProps.onChangeBookingValue(key, finalValue, index);
    },
    onSaveBooking: (bookingId) => {
        const { eventId, performanceId, editingBookings } = stateProps;
        const { onSuccessCallback } = ownProps;
        const booking = editingBookings.find(({ id }) => id === bookingId);
        const index = editingBookings.indexOf(booking);
        dispatchProps.onSaveBooking(eventId, performanceId, bookingId, index, booking, booking.artist.name, onSuccessCallback);
    },
    onCancelBooking: (bookingId) => {
        const { editingBookings } = stateProps;
        dispatchProps.onCancelBooking(editingBookings, bookingId);
    },
    onRemoveBookingFromPerformance: (bookingId) => {
        const { eventId, performanceId, bookings } = stateProps;
        const booking = bookings.find(({ id }) => id === bookingId);
        return dispatchProps.onRemoveBookingFromPerformance(eventId, performanceId, bookingId, booking.artist.name);
    },
    onDeleteBooking: (bookingId) => {
        const { eventId, performanceId, bookings } = stateProps;
        const booking = bookings.find(({ id }) => id === bookingId);
        return dispatchProps.onDeleteBooking(eventId, performanceId, bookingId, booking.artist.name);
    },
    onSaveAddExistingBooking: () => {
        const { eventId, performanceId, existingBooking, unassignedBookings } = stateProps;
        const booking = unassignedBookings.find(({ id }) => id === existingBooking.selectedBooking);
        if (typeof booking === 'undefined') return;
        return dispatchProps.onSaveAddExistingBooking(eventId, performanceId, booking.id, booking.artist.name);
    },
    onAddExistingBooking: () => {
        const { accountId, eventId } = stateProps;
        return dispatchProps.onAddExistingBooking(accountId, eventId);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(BookingTable);
