var _ = require('underscore'),
    Backbone = require('backbone');

var Modal = require('./Modal.view');

var template = require('./ConfirmModal.hbs');

var ConfirmModal = Modal.extend({

    template,

    events: {
        'click .modal-confirm': 'confirm',
        'click .modal-cancel': 'cancel'
    },

    options: [
        {'title': 'Confirm'},
        {'body': 'Are you sure?'},
        {'confirmText': 'Confirm'},
        {'cancelText': 'Cancel'},
        {'confirmClass': 'primary'},
        {'cancelClass': 'secondary'},
        {'autoClose': true},
        {'isSyncing': false},
        {'syncingText': 'Please wait...'},
    ],

    initialize(options) {
        this._super(options);
        this.$el.addClass('tiny');
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                title: this.title,
                body: this.body,
                confirmText: this.confirmText,
                cancelText: this.cancelText,
                confirmClass: this.confirmClass,
                cancelClass: this.cancelClass,
                height: this.height,
                isSyncing: this.isSyncing,
                syncingText: this.syncingText
            }
        }
    },

    confirm() {
        if (typeof this.confirmFn === 'function') this.confirmFn();
        if (this.autoClose) this.hide();
    },

    wrapConfirm(confirm) {
        return function() {
            if (confirm) confirm.call(this, ...arguments);
            this.trigger('confirm');
        };
    },

    cancel() {
        if (typeof this.confirmFn === 'function') this.cancelFn();
        if (this.autoClose) this.hide();
    },

    wrapCancel(cancel) {
        return function() {
            if (cancel) cancel.call(this, ...arguments);
            this.trigger('cancel');
        };
    },

    open(options = {}) {

        // Set options
        _.each(options, (option, key)=> {
            if(_.has(this, key) && !_.isUndefined(option)) this[key] = option;
        });

        // Wrap confirm callback with a fallback confirm event
        var confirm = options.confirm;
        options.confirm = this.wrapConfirm(confirm);

        // Wrap cancel callback with a fallback cancel event
        var cancel = options.cancel;
        options.cancel = this.wrapCancel(cancel);

        this.confirmFn = options.confirm;
        this.cancelFn = options.cancel;

        this.isSyncing = false;
        this.render();
        this.show();
    },

    setSyncing(isSyncing){
        this.isSyncing = isSyncing;
        this.render();
    }
});

module.exports = ConfirmModal;
