import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledSelect = styled.select`
    margin-right: 0.5rem;
    margin-left: ${({ hasLabel }) => hasLabel ? '0.5rem' : '0'};
`;

export const Filter = ({
    options = [],
    width = '150px',
    label,
    value = 0,
    onChange = () => {}
}) => (
    <label>
        {label}
        <StyledSelect
            hasLabel={typeof label !== 'undefined'}
            value={value}
            onChange={e => onChange(e.target.value)}
            style={{ width }}
        >
            {options.map(({ value, label }) => (
                <option
                    key={value}
                    value={value}
                >
                    {label}
                </option>
            ))}
        </StyledSelect>
    </label>
);

Filter.propTypes = {
    options: PropTypes.array,
    width: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    onChange: PropTypes.func,
};

export default Filter;
