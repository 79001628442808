import Backbone from 'backbone';
import Backgrid from 'backgrid';
import IconTooltipView from 'app/backbone/components/icon/IconTooltip.view';

export default class ItemCell extends Backgrid.Cell {

    className() {
        return 'items-cell';
    }

    initialize(options = {}) {
        super.initialize(options);

        this.items = this.model.getRelation('items');
        this.itemViews = this.initIconViews();
    }

    render() {
        this.itemViews.forEach((view) => {
            this.$el.append(view.el);
            view.render();
        });
        return this.delegateEvents();
    }

    viewModel() {
        return {
            items: this.items.models
        };
    }

    initIconViews() {
        const iconViews = this.items.map((item) => new IconTooltipView({
            iconClassName: 'bs-icon-tag',
            tooltipText: `${item.getRelation('item_group').get('name')} - ${item.get('name')}`
        }));
        return new Backbone.ChildViewContainer(iconViews);
    }

}
