import React from 'react';
import PropTypes from 'prop-types';
import { NoRecordsRow, Tooltip } from 'app/react/components/_index';

const FirstColumnsItemsTable = ({
    items = [],
    groups = [],
    selectedDayId = 0,
}) => (
    <table className="overflow">
        <thead>
            <tr>
                <th>Parent group(s)</th>
                <th>Group</th>
                <th>Name</th>
                <th style={{ width: '30px' }} />
            </tr>
        </thead>
        <tbody>
            {items.length === 0
                ? <NoRecordsRow colSpan={4} showText={selectedDayId !== 0}/>
                : items.map(item => {
                    const group = groups.find(({ id }) => id === item.group) || {};
                    const isChild = group.parent !== null;
                    const parentGroup = isChild 
                        ? (groups.find(({ id }) => id === group.parent) || {}) 
                        : group;
                    return (
                        <tr key={item.id}>
                            <td>{parentGroup.fullName}</td>
                            <td>{isChild && group.name}</td>
                            <td>{item.name}</td>
                            <td>
                                {item.description &&
                                    <Tooltip content={item.description}>
                                        <i className="bs-icon-circle-info" />
                                    </Tooltip>
                                }
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>
    </table>
);

FirstColumnsItemsTable.propTypes = {
    items: PropTypes.array,
    groups: PropTypes.array,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func
};

export default FirstColumnsItemsTable;
