import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Items from 'app/react/entities/events/artists/items/index';
import * as ItemGroups from 'app/react/entities/events/artists/item_groups/index';
import CreateItineraryContactView from 'app/backbone/views/event/settings/artists/itinerary_contacts/Create.view';
import TicketTypesOverview from 'app/backbone/views/event/settings/artists/ticket_types/TicketTypesOverview.view';
import EventArtistsItemsOverview from 'app/react/views/event/settings/artists/items/views/OverviewContainer';
import LocationsOverview from 'app/backbone/views/account/crm/locations/LocationsOverview.view';
import CreateLocationView from 'app/backbone/views/account/crm/locations/Create.view';
import CreateLocationRouteView from 'app/backbone/views/account/crm/locations/CreateRoute.view';
import AccommodationsOverview from 'app/backbone/views/account/crm/accommodations/AccommodationsOverview.view';
import CreateAccommodationView from 'app/backbone/views/event/settings/artists/accommodations/Create.view';
import CreateRoomTypeView from 'app/backbone/views/event/settings/artists/accommodations/room_types/Create.view';
import * as Routes from './routes';

export default class EventArtistsSettingsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.itineraryContactsCreate(), null, this.itineraryContactsCreate.bind(this));
        this.router.route(Routes.items(), null, this.items.bind(this));
        this.router.route(Routes.ticketTypes(), null, this.ticketTypes.bind(this));
        this.router.route(Routes.locations(), null, this.locations.bind(this));
        this.router.route(Routes.locationsCreate(), null, this.locationsCreate.bind(this));
        this.router.route(Routes.locationsCreateRoute(), null, this.locationsRouteCreate.bind(this));
        this.router.route(Routes.accommodations(), null, this.accommodations.bind(this));
        this.router.route(Routes.accommodationsCreate(), null, this.accommodationsCreate.bind(this));
        this.router.route(Routes.roomTypesCreate(), null, this.roomTypesCreate.bind(this));
    }

    itineraryContactsCreate() {
        this.app.renderContentView(new CreateItineraryContactView());
    }

    items() {
        const { app, store, dispatch } = this;
        // Set the working context
        // Account ID is set from DOM in the initial state
        dispatch(Items.thunks.fetchAllForCurrentContext());
        dispatch(ItemGroups.thunks.fetchAllForCurrentContext());
        app.renderContentComponent(React.createElement(EventArtistsItemsOverview, { store }));
    }

    ticketTypes() {
        this.app.renderContentView(new TicketTypesOverview());
    }

    locations() {
        this.app.renderContentView(new LocationsOverview());
    }

    locationsCreate() {
        this.app.renderContentView(new CreateLocationView());
    }

    locationsRouteCreate() {
        this.app.renderContentView(new CreateLocationRouteView());
    }

    accommodations() {
        this.app.renderContentView(new AccommodationsOverview());
    }

    accommodationsCreate() {
        this.app.renderContentView(new CreateAccommodationView());
    }

    roomTypesCreate() {
        this.app.renderContentView(new CreateRoomTypeView());
    }

}
