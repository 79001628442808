import { schema } from 'normalizr';
import * as File from 'app/react/entities/files/schemas';

export const ENTITY_CONTEXT_ID_KEY = 'performerId';
export const ENTITY_REDUCER_KEY = 'performers';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const entityDefinition = {
    passport: File.entity
};
export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);

entity.define({
    booking: require('app/react/entities/events/bookings/schemas').entity,
});
