import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import * as OfferStatus from './OfferStatus';

@Model({
    key: 'offer'
})
class Offer extends BaseModel {

    static Relations() {
        return {
            booking: require('./Booking').Model, // Circular
            status: OfferStatus.Model
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

}

@Collection({
    key: 'offers',
    model: Offer
})
class OfferCollection extends BaseCollection {

    static Relations() {
        return {
            booking: require('./Booking').Model // Circular
        }
    }

}

const PageableCollection = OfferCollection.prototype.PageableCollection();

export {
    Offer as Model,
    OfferCollection as Collection,
    PageableCollection
};
