import React from 'react';
import PropTypes from 'prop-types';
import DialogContainer from '../dialogContainer/DialogContainer';
import DialogCard from '../dialogCard/DialogCard';
import Icon from '../../../utils/Icon';
import Button from '../button/Button';

const CancelCreateDialog = ({cancelCreate, ...props}) => (
	<DialogContainer>
		<DialogCard {...props}>
			<div className={'cancel-create-dialog__container'}>

					<Icon
						size={32}
						name="info"
						color='#0CD3AB'
						style={{marginBottom: '20px'}}
					/>
					<h3>You are about to cancel the creation of your event</h3>
					<div className={'cancel-create-dialog__text'}>
						<p>Please note that the input you have provided, will not be saved.</p><p> By clicking cancel below, your event will be deleted. </p>
					</div>
					<Button label="Confirm" variant="cancel" onClick={cancelCreate} />
			</div>
		</DialogCard>
	</DialogContainer>
);

CancelCreateDialog.propTypes = {
	setCancelDialogOpen: PropTypes.func.isRequired,
	cancelCreate: PropTypes.func.isRequired,
};

export default CancelCreateDialog;
