import { schema } from 'normalizr';
import * as Contact from 'app/react/entities/accounts/contacts/schemas';
import * as RoomType from 'app/react/entities/events/accommodations/room_types/schemas';

export const ENTITY_REDUCER_KEY = 'reservations';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const entityDefinition = {
    roomType: RoomType.entity,
    guests: Contact.entities,
};

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);

entity.define({
    booking: require('app/react/entities/events/bookings/schemas').entity,
    accommodation: require('app/react/entities/events/accommodations/schemas').entity
});
