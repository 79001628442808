import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import EventCompanyForm from '../forms/ProfileForm.view';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';

export default class ProfileFormCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            model: OptionTypes.instanceOf(Profile.Model).isRequired
        };
    }

    initContent() {
        const { model } = this;
        return new EventCompanyForm({ model });
    }

}
