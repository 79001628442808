import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Item from 'app/backbone/models/account/accreditation/Item';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';
import ItemTable from '../tables/ItemTable.view';
import ItemModalCreate from '../modals/ItemModalCreate.view';

export default class ItemsOverviewCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            items: OptionTypes.instanceOf(Item.PageableCollection).isRequired,
            itemGroups: OptionTypes.instanceOf(ItemGroup.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options)
        // Subviews
        this.createModal = this.initCreateModal();
        // Listeners
        this.listenTo(this.itemGroups, 'update reset', () => {
            this.header.toggleHideAction('add', this.itemGroups.isEmpty());
        });
    }

    initContent() {
        return this.initTable();
    }

    initTable() {
        const { items, itemGroups } = this;
        return new ItemTable({ items, itemGroups });
    }

    initCreateModal() {
        const { itemGroups } = this;
        const createModal = new ItemModalCreate({ itemGroups });
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    onCreated() {
        this.items.fetch({ bustCache: true });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add item',
            icon: 'bs-icon-plus',
            action: this.createItem.bind(this),
            isHidden: () => this.itemGroups.isEmpty()
        }];
    }

    createItem() {
        if (!this.itemGroups.isEmpty()) {
            const account = this.items.getRelation('account');
            this.createModal.open(null, { account });
        }
    }
}
