import * as Schemas from './schemas'
import {
    getAllEntities,
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
} = Schemas;

export const getAll = (state, accountId, eventId) =>
    getAllEntities(state, ENTITY_REDUCER_KEY);
