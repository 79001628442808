import { html } from 'common-tags';
import Content from 'app/backbone/components/layout/content/Content.view';
import ZoneOverviewCard from './cards/ZoneOverviewCard.view';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';

export default class ZoneOverview extends Content {

    ui() {
        return {
            zonesCard: '#zones-card'
        };
    }

    optionTypes() {
        return {
            zones: OptionTypes.instanceOf(Zone.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.zonesCard = this.initZonesCard();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.zonesCard, this.ui.zonesCard);
        return this.delegateEvents();
    }

    template() {
        return html`<div id="zones-card" class="card"></div>`;
    }

    remove() {
        super.remove();
        this.zonesCard.remove();
    }

    initZonesCard() {
        const { zones } = this;
        const title = 'Zones';
        return new ZoneOverviewCard({ zones, title });
    }

}
