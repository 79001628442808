var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.SyncingPlaceholder,depth0,{"name":"SyncingPlaceholder","hash":{"text":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.syncingText : stack1),"height":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.height : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return ((stack1 = container.invokePartial(partials.ModalTitle,depth0,{"name":"ModalTitle","hash":{"title":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.title : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.ModalBody,depth0,{"name":"ModalBody","fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"modalButtonsContainer\">\n        <a class=\"button modal-confirm "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.confirmClass : stack1), depth0))
    + "\">\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.confirmText : stack1), depth0))
    + "\n        </a>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <p>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.body : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n        <label for=\"deny-reason\">\n            Reason:\n            <textarea id=\"deny-reason\" rows=\"5\"></textarea>\n        </label>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isSyncing : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});