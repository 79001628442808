import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ALL_REQUEST = 'ALL_ARTISTS_REQUEST';
export const ALL_SUCCESS = 'ALL_ARTISTS_SUCCESS';
export const ALL_FAILURE = 'ALL_ARTISTS_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const searchAll = (accountId, searchTerm) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(accountId, searchTerm),
        schema: Schemas.entities
    },
    accountId,
    searchTerm
});
