import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HeaderStepIndicator from './HeaderStepIndicator';

const HeaderStep = ({title, subSteps, isActive, activeSubStep}) => {
	return(
    <div className={classNames("headerStep", {"headerStep-active": isActive})}>
			<div className="headerStep__title-block">
				<div className="headerStep__title">{title}</div>
				<div role="doc-subtitle" className="headerStep__subtitle">{(`${subSteps.filter(({id}) => id > activeSubStep).length} steps left`).toUpperCase()}</div>
			</div>
			<HeaderStepIndicator steps={subSteps} activeSubStep={activeSubStep} />
		</div>
)};

HeaderStep.propTypes = {
	title: PropTypes.string,
	subSteps: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
		subTitle: PropTypes.string,
	})),
	activeSubStep: PropTypes.number,
};

export default HeaderStep;
