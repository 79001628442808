import CompanyItemModalAdd from 'app/backbone/views/event/accreditation/companies/items/modals/CompanyItemModalAdd.view';
import * as AccrediteeItem from 'app/backbone/models/event/accreditation/accreditees/AccrediteeItem';

export default class AccrediteeItemAddModal extends CompanyItemModalAdd {

    newModel(attributes = {}) {
        return new AccrediteeItem.Model({
            ...attributes,
            granted_by_promoter: true,
            granted_by: 'Promoter'
        });
    }

}

