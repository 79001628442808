import AccrediteeProfileModalAdd from 'app/backbone/views/event/accreditation/accreditees/profiles/modals/AccrediteeProfileModalAdd.view';
import * as CompanyProfile from 'app/backbone/models/event/accreditation/companies/CompanyProfile';
import template from './CompanyProfileModalAdd.hbs';

export default class CompanyProfileModalAdd extends AccrediteeProfileModalAdd {

    get template() {
        return template;
    }

    ui() {
        return {
            defaultAmount: '#defaultAmount'
        };
    }

    newModel(attributes) {
        return new CompanyProfile.Model(attributes);
    }

    add() {
        this.setDefaultAvailableProfiles();
        super.add();
    }

    addAnother() {
        this.setDefaultAvailableProfiles();
        super.addAnother();
        this.ui.defaultAmount.focus();
    }

    setDefaultAvailableProfiles() {
        const defaultAmount = parseInt(this.ui.defaultAmount.val(), 10);
        if (this.model.isValid(true) && defaultAmount) {
            this.model.setMaxAvailableOnAll(this.model.days, defaultAmount);
        }
    }

}


