import BaseView from 'app/backbone/components/Base.view';
import DataTable from 'app/backbone/components/tables/DataTable.view';

export default class EventsArchiveView extends BaseView{

    ui() {
        return {
            eventsTable: '#events-table'
        };
    }

    initialize() {
        this.eventsTable = new DataTable();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.eventsTable, this.ui.eventsTable);
        return this.delegateEvents();
    }

}
