import BaseController from 'app/backbone/helpers/BaseController'
import ReservationFormView from 'app/backbone/views/event/artists/bookings/reservations/Form.view'

const createRoute = (
    bookingId = ':booking',
) => `booking/add-accommodation/${bookingId}`;

const updateRoute = (
    bookingId = ':booking',
    reservationId = ':reservation',
) => `booking/edit-accommodation/${bookingId}/${reservationId}`;

export default class ReservationsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(createRoute(), null, this.create.bind(this));
        this.router.route(updateRoute(), null, this.update.bind(this));
    }

    create() {
        this.app.renderContentView(new ReservationFormView());
    }

    update() {
        this.app.renderContentView(new ReservationFormView());
    }
};
