import $ from 'jquery';

export default class BaseController {

    constructor(options = {}) {
        const { parent } = options;
        const { app } = parent;
        this.parent = parent;
        this.router = parent.router || parent; // Parent might be the router
        this.app = app;
        this.data = app.data;
        this.store = app.store;
        this.dispatch = app.store.dispatch;
        this.getState = app.store.getState;
    }

    when() {
        return $.when(...arguments);
    }

}
