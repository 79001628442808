import Toastr from 'toastr';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PageableTable from 'app/backbone/components/tables/PageableTable.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import ItemGroupModalUpdate from '../modals/ItemGroupModalUpdate.view';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';

export default class ItemGroupTable extends PageableTable {

    optionTypes() {
        return {
            itemGroups: OptionTypes.instanceOf(ItemGroup.PageableCollection).isRequired
        };
    }

    initialize(options) {
        this.resolveOptions(options);
        this.collection = this.itemGroups;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.placeholderText = this.getPlaceholderText();

        // SubViews
        this.confirmModal = this.initConfirmModal();
        this.updateModal = this.initUpdateModel();
        super.initialize(options);
    }

    initColumns() {
        const { actions } = this;
        return [{
            name: 'name',
            label: 'Name',
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(actions),
            sortable: false,
            editable: false
        }];
    }

    initActions() {
        return [{
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            action: this.update.bind(this)
        }, {
            name: 'remove',
            icon: 'bs-icon-cross',
            tooltip: 'Remove',
            action: this.delete.bind(this)
        }];
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    initUpdateModel() {
        return new ItemGroupModalUpdate();
    }

    update(itemGroup) {
        this.updateModal.open(itemGroup);
    }

    delete(itemGroup) {
        const name = itemGroup.get('name');
        this.confirmModal.open({
            title: 'Remove Item Group',
            body: `Are you sure you want to remove <strong>${name}</strong>?`,
            confirmClass: 'danger',
            confirmText: 'Yes, remove',
            autoClose: true,
            syncingText: `Removing ${name}...`,
            confirm: () => {
                itemGroup.destroy({ wait: true })
                .fail(() => {
                    Toastr.error(`<strong>${name}</strong> still has items attached`);
                });
            }
        });
    }

    getPlaceholderText() {
        return 'No item groups have been created for this event.';
    }
}
