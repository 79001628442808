import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import styled from 'styled-components';
import ItemsRow from './ItemRow';
import ItemGroupRow from './ItemGroupRow';
import {
    toGroupsWithItems,
    toGroupsWithSubGroups,
    whereIsNotChild
} from 'app/react/helpers/itemGroups';

const ArtistsItemsTable = ({
    currency,
    groups = [],
    items = [],
    onUpdate = () => {},
    onDelete = () => {},
    onAdd = () => {}
}) => {
    const groupsWithItems = toGroupsWithItems(groups, items);

    return (
        <table className="table-with-indented-rows overflow actions-right">
            {toGroupsWithSubGroups(groupsWithItems)
                .filter(whereIsNotChild)
                .sort(by('name', { ignoreCase: true }))
                .map(group => (
                    <ItemGroupRow
                        key={group.id}
                        group={group}
                        onAdd={onAdd}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        currency={currency}
                    />
                ))
            }
        </table>
    );
}

ArtistsItemsTable.propTypes = {
    groups: PropTypes.array,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func
};

export default ArtistsItemsTable;
