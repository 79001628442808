import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'auth.user'
})
class User extends BaseModel {

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        response.fullname = `${response.firstname} ${response.lastname}`;
        return response;
    }

}

@Collection({
    key: 'users',
    model: User
})
class UserCollection extends BaseCollection {

}

const PageableCollection = UserCollection.prototype.PageableCollection();

export {
    User as Model,
    UserCollection as Collection,
    PageableCollection
};
