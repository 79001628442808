import _ from 'underscore';
import Backgrid from 'backgrid';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import FixedColumnTable from 'app/backbone/components/tables/FixedColumnTable.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';

export default class PeriodDaysTable extends FixedColumnTable {

    optionTypes() {
        return _.extend({}, super.optionTypes(), {
            periods: OptionTypes.instanceOf(Period.Collection).isRequired
        });
    }

    optionDefaults() {
        return _.extend({}, super.optionDefaults(), {
            header: Backgrid.Extension.GroupedHeader
        });
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.firstColumns = this.nestColumns('', this.firstColumns);
        this.lastColumns = this.nestColumns('', this.lastColumns);
        this.columns = this.addPeriodColumns();
        super.initialize(options);
    }

    nestColumns(name, columns) {
        return columns.map((column) => {
            column.nesting = [name];
            return column;
        });
    }

    render() {
        super.render();
        // We add a class to the containers of the first and last table.
        // This pushes the containers down so they align with the second row of the center table.
        this.ui.firstColumnsTable.addClass('table-header-padding');
        this.ui.lastColumnsTable.addClass('table-header-padding');
    }

    addPeriodColumns() {
        const { periods, editing } = this;
        const columns = [];
        periods.each(period => {
            const periodName = period.get('name');
            period.days.each(day => {
                const startDate = day.get('start_date_short');
                const className = !period.isFirst() && day.isFirst() ? 'border-left' : '';
                const headerClassName = `${className} center`;
                columns.push({
                    name: '',
                    nesting: [periodName],
                    label: startDate,
                    headerCell: CellFactory.createHeaderCell(null, headerClassName),
                    cell: this.createDayCell(day, className, editing),
                    sortable: false,
                    editable: false
                });
            });
        });
        return columns;
    }

}
