export const TOGGLE_TASK = 'TOGGLE_TASK';
export const TOGGLE_LOADING_TASK = 'TOGGLE_LOADING_TASK';

export const toggleTask = (taskId, isCompleted) => ({
    type: TOGGLE_TASK,
    taskId,
    isCompleted
});

export const toggleLoadingTask = (taskId, isLoading) => ({
    type: TOGGLE_LOADING_TASK,
    taskId,
    isLoading
});

