import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    filterParam,
    limitAllParam as pageSizeAllParam,
} from 'app/react/state/middleware/api'

export const fetchAll = (
    eventId,
    filters = {},
    includes = []
) => oneLineTrim`
    events/${eventId}/locations?
    ${pageSizeAllParam}&
    ${filterParam(filters)}&
    ${includeParam(includes)}&
`;
