import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Table from 'app/backbone/components/tables/Table.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import * as Day from 'app/backbone/models/event/Day';

export default class SelectDaysTable extends Table {

    optionTypes() {
        return {
            days: OptionTypes.instanceOf(Day.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.collection = this.days;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.placeholderText = this.getPlaceholderText();
        this.placeholderHeight = 350;
        this.hasActions = false;
        super.initialize(options);
    }

    initColumns() {
        const columns = super.initColumns();
        columns.push({
            name: '',
            cell: 'select-row',
            headerCell: CellFactory.createSelectAllHeaderCell()
        }, {
            name: 'start_date_short',
            cell: 'string',
            label: 'Day',
            headerCell: CellFactory.createHeaderCell('75px'),
            sortable: false,
            editable: false
        }, {
            name: 'period_name',
            label: 'Period',
            cell: 'string',
            sortable: false,
            editable: false
        });
        return columns;
    }


    getPlaceholderText() {
        const eventId = this.days.getRelation('event').get('id');
        const createPeriodUrl = `/events/${eventId}/settings/accreditation/periods`;
        return `No periods have been created for this event. <a href="${createPeriodUrl}">Create your first Period.</a>`;
    }

}
