import { connect } from 'react-redux';
import * as actions from 'app/react/state/actions/_index';
import * as stateHelpers from 'app/react/state/helpers';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import EditIntroView from './EditIntroView';
import * as Event from 'app/react/entities/events/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Settings from 'app/react/entities/events/production_website/settings/artists/index';
import * as Routes from 'app/routes/event/production_website/artists/form/routes';

export const SETTINGS_SECTIONS_FORM_ID = 'settingsSectionsForm';
export const POPUP_MESSAGES = {
    FAILED: 'Failed to save artist website settings.',
    DONE: 'Artist website settings saved successfully.'
};

const mapStateToProps = (state) => {
    const formState = stateHelpers.getFormComponentState(state, SETTINGS_SECTIONS_FORM_ID);
    return ({
        isFetching: (
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Settings.stateHelpers.isFetchingCurrent(state)
        ),
        isArtistTypesEnabled: Event.stateHelpers.isArtistTypesEnabledForCurrent(state),
        eventId: Event.stateHelpers.getCurrentId(state),
        artistTypeId: ArtistType.stateHelpers.getCurrentId(state),
        formState
    });
};

const mapDispatchToProps = (dispatch) => {
    const onBackToSections = (isArtistTypesEnabled, eventId, artistTypeId) => {
        const url = Routes.form(isArtistTypesEnabled, eventId, artistTypeId);
        dispatch(actions.navigate.to(url));
    };
    const onChangeIntro = (value) => {
        dispatch(actions.updateFormComponentStateValue(SETTINGS_SECTIONS_FORM_ID, 'intro', value));
    };
    const onSaveIntro = (eventId, artistTypeId, formState) => {
        const { values } = formState;
        const body = {
            intro: values.intro,
        };
        dispatch(Settings.thunks.updateCurrent(body))
            .then(({ error }) => {
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.mergeComponentState(SETTINGS_SECTIONS_FORM_ID, { errors }));
                    dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED));
                } else {
                    dispatch(actions.showSuccessPopup(POPUP_MESSAGES.DONE));
                }
            });
    };

    return {
        onBackToSections,
        onChangeIntro,
        onSaveIntro
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onBackToSections: () => dispatchProps.onBackToSections(
        stateProps.isArtistTypesEnabled,
        stateProps.eventId,
        stateProps.artistTypeId
    ),
    onSaveIntro: () => dispatchProps.onSaveIntro(
        stateProps.eventId,
        stateProps.artistTypeId,
        stateProps.formState
    )
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditIntroView);




