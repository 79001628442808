import { connect } from 'react-redux';
import { ConfirmModal } from 'app/react/components/_index';
import * as State from './DeleteModalState';

const TITLE = 'Delete Vehicle';
const TEXT = (name) => `Are you sure you want to delete <strong>${name}?</strong>`;
const BUTTON_TEXT =  'Yes, delete vehicle';

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSyncing,
        vehicle = {},
    } = State.getState(state);
    return {
        title: TITLE,
        text: TEXT(`${vehicle.make} ${vehicle.model}`),
        buttonText: BUTTON_TEXT,
        buttonClassName: 'alert',
        isOpened,
        isSyncing,
        vehicle,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onConfirmed: () => {
        const { onConfirmed } = ownProps;
        const { vehicle } = stateProps;
        if (typeof onConfirmed === 'function') {
            onConfirmed(vehicle);
        }
    }
});

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch(State.close());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ConfirmModal);
