var Account = require('app/backbone/models/account/Account'),
    Company = require('app/backbone/models/account/Company'),
    Contact = require('app/backbone/models/account/Contact'),
    CompanyModal = require('./CompanyModal.view');

var template = require('./CompanyModalCreate.hbs');

var CompanyModalCreate = CompanyModal.extend({

    template,

    events: {
        'keypress': 'keypress',
        'click .create': 'create',
        'click .create-another': 'createAnother'
    },

    open() {
        this.model = this.newModel();
        this.render();
        this.show();
    },

    newModel(initialValues = {}) {
        const account = new Account.Model().fetchFromDOM();
        const primary_contact = new Contact.Model(null, { account });
        return new Company.Model(initialValues, { account, primary_contact });
    },

    create(e, another = false) {
        if(this.model.isValid(true) && this.model.primary_contact.isValid(true)){
            this.isCreatingAnother = another;
            if(this.model.primary_contact.isNew()) {
                this.model.primary_contact.save(null, {
                    success: () => { this.model.save(); }
                });
            } else {
                this.model.save();
            }
        }
    },

    createAnother(e) {
        this.create(e, true)
    },

    syncSuccess() {
        this.trigger('create', this.model);
        this.unbindModel();
        if(this.isCreatingAnother) {
            this.clearForm();
            this.model = this.newModel();
            this.setSyncing(false);
        } else {
            this.hide();
            this.setSyncing(false);
        }
    },

    keypress(e) {
        if(e.which == 13) {
            e.preventDefault();
            this.create();
        }
    }

});

module.exports = CompanyModalCreate;
