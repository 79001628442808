import React from 'react';
import PropTypes from 'prop-types';
import { Card, InputGroup } from 'ui-kit';
import { FileUpload } from 'app/react/components/_index';
import { NONE_OPTION_VALUE, INPUT_FIELD_MAX_WIDTH } from './TicketSettingsViewContainer';

export const TICKETS_CARD_TITLE = 'Tickets';
export const SELECT_TICKET_PROVIDER_LABEL = 'Select Ticket Provider';
export const TICKET_BACKGROUND_LABEL = 'Ticket Background';
export const TICKET_BACKGROUND_TEMPLATE_URL = 'https://s3.eu-central-1.amazonaws.com/assets.beatswitch.com/promoter-web-app/tickettemplate.jpg';
export const TICKET_BACKGROUND_FILE_TYPE = 'ticket_background';
export const TICKET_BACKGROUND_FILE_EXTENSIONS = ['jpg'];

const { FileInfo } = FileUpload;

const TicketSettingsCard = ({
    isLocked = false,
    ticketProviderOptions = [],

    selectedTicketProviderId,
    selectedTicketBackground,

    onChangeTicketProvider = () => {},
    onChangeTicketBackground = () => {},
    onUploadTicketBackground = () => {}
}) => (
    <Card>
        <Card.Header>
            <Card.Header.Title>
                {TICKETS_CARD_TITLE}
            </Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <InputGroup style={{ maxWidth: `${INPUT_FIELD_MAX_WIDTH}px` }}>
                <label>{SELECT_TICKET_PROVIDER_LABEL}</label>
                <select
                    value={selectedTicketProviderId || NONE_OPTION_VALUE}
                    disabled={isLocked}
                    onChange={(e) => onChangeTicketProvider(e.target.value)}
                >
                    { ticketProviderOptions.map(
                        ({ value, label }) => <option key={value} value={value}>{label}</option>
                    )}
                </select>
            </InputGroup>
            <InputGroup style={{ maxWidth: `${INPUT_FIELD_MAX_WIDTH}px` }}>
                <label>{TICKET_BACKGROUND_LABEL}</label>
                <FileUpload
                    value={selectedTicketBackground}
                    disabled={isLocked}
                    fileType={TICKET_BACKGROUND_FILE_TYPE}
                    onChange={onChangeTicketBackground}
                    onUploadSuccess={onUploadTicketBackground}
                >
                    <FileInfo
                        maxSize={5}
                        allowedFileExtensions={TICKET_BACKGROUND_FILE_EXTENSIONS}
                    >
                        <small>Template <strong><a href={TICKET_BACKGROUND_TEMPLATE_URL} target="_blank">Download</a></strong>.</small>
                    </FileInfo>
                </FileUpload>
            </InputGroup>
        </Card.Content>
        <Card.Footer />
    </Card>
);

TicketSettingsCard.propTypes = {
    isLocked: PropTypes.bool,
    ticketProviderOptions: PropTypes.array,

    selectedTicketProviderId: PropTypes.number,
    selectedTicketBackground: PropTypes.object,

    onChangeTicketProvider: PropTypes.func,
    onChangeTicketBackground: PropTypes.func,
    onUploadTicketBackground: PropTypes.func
};

export default TicketSettingsCard;
