import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';

const { Tabs, TitleMenu } = Header;
const { Title, Actions } = TitleMenu;
const { Tab } = Tabs;

const BASE_PATH = '/settings/accreditation';
export const TABS = {
    ITEMS: {
        ID: 'items',
        PATH: 'items'
    }
};

const AccreditationSettingsContentHeader = ({
    children,
    activeTab
}) => (
    <Header>
        <TitleMenu>
            <Title>Accreditation</Title>
            <Actions>{children}</Actions>
        </TitleMenu>
        <Tabs>
            <Tab
                activeTab={activeTab}
                tabId={TABS.ITEMS.ID}
                url={`${BASE_PATH}/${TABS.ITEMS.PATH}`}
            >
                Items
            </Tab>
        </Tabs>
    </Header>
);

AccreditationSettingsContentHeader.propTypes = {
    children: PropTypes.node,
    activeTab: PropTypes.string
};

export default AccreditationSettingsContentHeader;
