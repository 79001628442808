import dotty from 'dotty';
import { transformResponseBody } from './util'

export const DOM_OBJECT = window.beatswitch;

export const getDataFromDOM = (key, schema) => {
    const data = dotty.get(DOM_OBJECT, key);
    if (data) return transformResponseBody(data, schema);
    return {};
};

