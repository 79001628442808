import BaseView from 'app/backbone/components/Base.view';
import ArtistContactListModal from './modals/DownloadArtistContactListModal.view';
import ArtistItemsModal from './modals/DownloadArtistItemsModal.view';
import AttendeeModal from './modals/DownloadAttendeesModal.view';
import BookingArtistItemsModal from './modals/DownloadBookingArtistItemsModal.view';
import RiderModal from './modals/DownloadRiderModal.view';
import TasksModal from './modals/DownloadTasksModal.view';

export default class ArtistReportsOverviewView extends BaseView {

    ui() {
        return {
            artistContactListDownloadButton: '#download-artist-contact-list',
            artistItemsDownloadButton: '#download-artist-items',
            attendeeDownloadButton: '#download-attendee',
            bookingArtistItemsDownloadButton: '#download-booking-artist-items',
            riderDownloadButton: '#download-rider',
            tasksDownloadButton: '#download-tasks'
        };
    }

    events() {
        return {
            'click artistContactListDownloadButton': 'openArtistContactListModal',
            'click artistItemsDownloadButton': 'openArtistItemsModal',
            'click attendeeDownloadButton': 'openAttendeeModal',
            'click bookingArtistItemsDownloadButton': 'openBookingArtistItemsModal',
            'click riderDownloadButton': 'openRiderModal',
            'click tasksDownloadButton': 'openTasksModal'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.artistContactListModal = this.initArtistContactListModal();
        this.artistItemsModal = this.initArtistItemsModal();
        this.attendeeModal = this.initAttendeeModal();
        this.bookingArtistItemsModal = this.initBookingArtistItemsModal();
        this.riderModal = this.initRiderModal();
        this.tasksModal = this.initTasksModal();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    initArtistContactListModal() {
        return new ArtistContactListModal();
    }

    openArtistContactListModal(e) {
        e.preventDefault();

        this.artistContactListModal.open();
    }

    initArtistItemsModal() {
        return new ArtistItemsModal();
    }

    openArtistItemsModal(e) {
        e.preventDefault();

        this.artistItemsModal.open();
    }

    initAttendeeModal() {
        return new AttendeeModal();
    }

    openAttendeeModal(e) {
        e.preventDefault();

        this.attendeeModal.open();
    }

    initBookingArtistItemsModal() {
        return new BookingArtistItemsModal();
    }

    openBookingArtistItemsModal(e) {
        e.preventDefault();

        this.bookingArtistItemsModal.open();
    }

    initRiderModal() {
        return new RiderModal();
    }

    openRiderModal(e) {
        e.preventDefault();

        this.riderModal.open();
    }

    initTasksModal() {
        return new TasksModal();
    }

    openTasksModal(e) {
        e.preventDefault();

        this.tasksModal.open();
    }
}
