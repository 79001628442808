import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, PostfixedInput } from 'ui-kit';
import { Modal } from 'app/react/components/_index';
import SelectItemGroup from '../groups/SelectItemGroup';

const { Header, Content, Footer } = Modal;

const NAME_LABEL = 'Name';
const DESCRIPTION_LABEL = 'Short description';
const UNIT_PRICE_LABEL = 'Price per unit';
const GROUP_LABEL = 'Group';

const CREATE_TITLE = 'Create item';
const CREATE_BUTTON_TEXT = 'Create';
const CREATE_ANOTHER_BUTTON_TEXT = 'Create another';

const UPDATE_TITLE = 'Update item';
const UPDATE_BUTTON_TEXT = 'Update';

const ArtistsItemModal = ({
    isCreating = false,
    canCreateAnother = true,
    isOpened = false,
    isSaving = false,
    values = {},
    errors = {},

    currency = 'EUR',
    groups = [],

    onClosed = () => {},
    onChangeValue = () => {},
    onSave = () => {},
}) => (
    <Modal
        width={460}
        closeOnBackgroundClick={false}
        opened={isOpened}
        syncing={isSaving}
        onClosed={onClosed}
    >
        <Header onClosed={onClosed}>
            <Modal.Header.Title>{isCreating ? CREATE_TITLE : UPDATE_TITLE}</Modal.Header.Title>
        </Header>
        <Content>
            <InputGroup messages={errors.name}>
                <label className="required">
                    {NAME_LABEL}
                </label>
                <input
                    type="text"
                    value={values.name || ''}
                    onChange={e => onChangeValue('name', e.target.value)}
                />
            </InputGroup>
            <InputGroup messages={errors.group}>
                <label className="required">
                    {GROUP_LABEL}
                </label>
                <SelectItemGroup
                    groups={groups}
                    value={values.group}
                    onChange={value => onChangeValue('group', value)}
                />
            </InputGroup>
            <InputGroup messages={errors.price}>
                <label>
                    {UNIT_PRICE_LABEL}
                </label>
                <PostfixedInput isUnified label={currency}>
                    <input
                        type="number"
                        value={values.price || ''}
                        onChange={e => onChangeValue('price', e.target.value)}
                    />
                </PostfixedInput>
            </InputGroup>
            <InputGroup messages={errors.description}>
                <label>
                    {DESCRIPTION_LABEL}
                </label>
                <input
                    type="text"
                    value={values.description || ''}
                    onChange={e => onChangeValue('description', e.target.value)}
                />
            </InputGroup>
        </Content>
        {isCreating 
            ? <Footer>
                {canCreateAnother &&
                    <Footer.Button
                        className="create-another"
                        onClick={() => onSave(true)}
                    >
                        {CREATE_ANOTHER_BUTTON_TEXT}
                    </Footer.Button>
                }
                <Footer.Button
                    className="success"
                    onClick={() => onSave()}
                >
                    {CREATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer> 
        : <Footer>
                <Footer.Button
                    className="success"
                    onClick={() => onSave()}
                >
                    {UPDATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
        }
    </Modal>
);

ArtistsItemModal.propTypes = {
    isCreating: PropTypes.bool,
    canCreateAnother: PropTypes.bool,
    isOpened: PropTypes.bool,
    isSaving: PropTypes.bool,
    values: PropTypes.object,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
    onCreateAnother: PropTypes.func,
};

export default ArtistsItemModal;
