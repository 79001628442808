import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';

export const VIEW_ID = 'searchSelectContacts';

export const getViewState = (state) =>
    stateHelpers.getComponentState(state, VIEW_ID) || {};

export const setViewStateValue = (key, value) =>
    actions.setComponentStateValue(VIEW_ID, key, value);

export const deleteViewStateValue = (key) =>
    actions.deleteComponentStateValue(VIEW_ID, key);
