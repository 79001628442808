import React from 'react';
import PropTypes from 'prop-types';
import TitleMenu from './TitleMenu';
import Tabs from './Tabs';

const ContentHeader = ({
    children
}) => (
    <div id="content-header" className="content--header_container">
        <div className="content--header">
            { children }
        </div>
    </div>
);

ContentHeader.propTypes = {
    children: PropTypes.node
};

ContentHeader.Tabs = Tabs;
ContentHeader.TitleMenu = TitleMenu;

export default ContentHeader;

