import Backgrid from 'backgrid'

export default class OfferBookingFeeCell extends Backgrid.Cell {

    className() {
        return 'offer-booking-fee-cell';
    }

    initialize(options) {
        super.initialize(options);

        this.offer = this.model.getCurrentOffer();
    }

    render() {
        this.$el.html(this.template());

        return this.delegateEvents();
    }

    template() {
        if (this.model.hasOffer()) {
            if (this.offer.get('booking_fee_included')) {
                if (this.offer.get('booking_fee_formatted')) {
                    return this.offer.get('booking_fee_formatted') + ' (included in artist fee)';
                }

                return '(included in artist fee)';
            }

            return this.offer.get('booking_fee_formatted');
        }
    }

}
