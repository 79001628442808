import {
    createEntityReducer,
    createCollectionReducerWithKey,
    createPaginationReducerWithKey
} from 'app/react/state/reducers/higher_order/_index'
import * as Event from 'app/react/entities/events/helpers'
import {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY,
    PAGINATION_REDUCER_KEY
} from './schemas'
import {
    ONE_TYPES,
    ALL_TYPES,
    ALL_PAGINATED_TYPES,
} from './actions'

const entityReducer = createEntityReducer({
    types: ONE_TYPES
});

const collectionReducer = createCollectionReducerWithKey({
    mapActionToKey: ({ accountId, eventId, after, before }) => {
        const isBetweenDatesCollection = typeof after !== 'undefined' && typeof before !== 'undefined';
        const isEventContextCollection = typeof eventId !== 'undefined';
        const isAccountContextCollection = typeof accountId !== 'undefined';

        if (
            isBetweenDatesCollection &&
            isEventContextCollection &&
            isAccountContextCollection
        ) {
           return `${accountId}/${eventId}/${after}/${before}`
        }

        if (
            isEventContextCollection &&
            isAccountContextCollection
        ) {
            return `${accountId}/${eventId}`
        }

        if (isAccountContextCollection) {
            return `${accountId}`
        }
    },
    types: ALL_TYPES,
    oneTypes: ONE_TYPES
});

const paginationReducer = createPaginationReducerWithKey({
    mapActionToKey: ({ accountId, eventId }) => Event.generateCollectionKey(accountId, eventId),
    types: ALL_PAGINATED_TYPES,
    oneTypes: ONE_TYPES
});

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};

export const collectionReducers = {
    [COLLECTION_REDUCER_KEY]: collectionReducer,
};

export const paginationReducers = {
    [PAGINATION_REDUCER_KEY]: paginationReducer,
};
