import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import * as Event from 'app/react/entities/events/index';
import * as actions from 'app/react/state/actions/_index';
import * as CreateModalState from '../../components/modals/stateful/CreateModalState'
import * as UpdateModalState from '../../components/modals/stateful/UpdateModalState'
import * as DeleteModalState from '../../components/modals/stateful/DeleteModalState'
import PaginatedVehicleTableModalWrapperContainer from './PaginatedTableModalWrapperContainer';

const ROW_HEIGHT = 50;
const TOP_BAR = 45;
const CONTENT_HEADER = 115;
const EXTRA_RECORDS = 2;

const pageHeight = document.body.scrollHeight;
const first = Math.round((pageHeight - TOP_BAR - CONTENT_HEADER) / ROW_HEIGHT) + EXTRA_RECORDS;

export const VEHICLES_PAGINATED_TABLE_QUERY = gql`
    query AllVehiclesForEvent($eventId: ID!, $first: Int, $after: String, $sort: [String!]) {
        vehicles(event: $eventId, first: $first, after: $after, sort: $sort) {
            edges {
                node {
                    id
                    type {
                        id
                    }
                    make
                    model
                    numberOfPassengers
                    pickups {
                        totalCount
                    }
                    licencePlate
                    mileageIn
                    mileageOut
                    notes
                    isDeletable
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
            }
            count
            totalCount
        }
    }
`;

const mapStateToProps = (state) => {
    const { manageLogistics } = Event.stateHelpers.getUserPermissions(state);
    return {
        first,
        userCanManageLogistics: manageLogistics,
        eventId: Event.stateHelpers.getCurrentId(state)
    }
};

const mapDispatchToProps = (dispatch) => ({
    onDataLoaded: (data, schemaMapping) => {
        dispatch(actions.mergeEntities(data, schemaMapping))
    },
    onOpenCreateModal: () => {
        dispatch(CreateModalState.open())
    },
    onOpenUpdateModal: (vehicle) => {
        dispatch(UpdateModalState.open({
            ...vehicle,
            type: vehicle.type.id
        }))
    },
    onOpenDeleteModal: (vehicle) => {
        dispatch(DeleteModalState.open(vehicle))
    }
});

const injectState = connect(
    mapStateToProps,
    mapDispatchToProps
);

const injectDate = graphql(
    VEHICLES_PAGINATED_TABLE_QUERY
);

export default compose(
    injectState,
    injectDate
)(PaginatedVehicleTableModalWrapperContainer)
