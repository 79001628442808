import { html } from 'common-tags';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import { syncingPlaceholder, createButton, updateButton } from 'app/backbone/helpers/templates';
import * as Event from 'app/backbone/models/event/Event';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as Contact from 'app/backbone/models/account/Contact';
import * as Accreditee from 'app/backbone/models/event/accreditation/accreditees/Accreditee';
import * as CustomAccreditee from 'app/backbone/models/event/accreditation/accreditees/CustomAccreditee';
import AccrediteeContactCard from '../cards/AccrediteeContactCard.view';
import AccrediteeProfilesCard from '../profiles/cards/AccrediteeProfilesCard.view';
import AccrediteeItemsCard from '../items/cards/AccrediteeItemsCard.view';

export default class CustomAccrediteeView extends Content {

    ui() {
        return {
            contactCard: '#contact-card',
            profilesCard: '#profiles-card',
            itemsCard: '#items-card'
        };
    }

    optionTypes() {
        return {
            event: OptionTypes.instanceOf(Event.Model).isRequired,
            contacts: OptionTypes.instanceOf(Contact.Collection).isRequired,
            profiles: OptionTypes.instanceOf(Profile.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            accreditees: OptionTypes.instanceOf(Accreditee.PageableCollection).isRequired,
            accreditee: OptionTypes.instanceOf(CustomAccreditee.Model)
        };
    }

    initialize(options) {
        this.resolveOptions(options);
        // Props
        this.model = this.accreditee || this.newModel();
        this.canCreateAnother = true;
        // Subviews
        this.contactCard = this.initContactCard();
        this.profilesCard = this.initProfilesCard();
        this.itemsCard = this.initItemsCard();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        if (!this.isSyncing) {
            this.unbindModel();
            this.bindModel();
            this.assignSubview(this.contactCard, this.ui.contactCard);
            this.assignSubview(this.profilesCard, this.ui.profilesCard);
            this.assignSubview(this.itemsCard, this.ui.itemsCard);
        }
        return this.delegateEvents();
    }

    remove() {
        super.remove();
        this.contactCard.remove();
        this.profilesCard.remove();
        this.itemsCard.remove();
    }

    viewModel() {
        return {
            canCreateAnother: this.canCreateAnother,
            isSyncing: this.isSyncing,
            syncingText: this.syncingText,
            isUpdating: this.isUpdating,
        };
    }

    template(viewModel) {
        const { canCreateAnother, isSyncing, syncingText, isUpdating } = viewModel;
        if (isSyncing) {
            return syncingPlaceholder(syncingText)
        }
        return html`
            <div class="grid grid--1of2">
                <div id="contact-card" class="grid-cell card"></div>
            </div>
            <div class="grid">
                <div id="profiles-card" class="grid-cell card"></div>
            </div>
            <div class="grid">
                <div id="items-card" class="grid-cell card"></div>
            </div>
            ${ isUpdating ? updateButton() : createButton(canCreateAnother)}
        `;
    }

    initContactCard() {
        const { model, contacts } = this;
        const title = 'Contact';
        return new AccrediteeContactCard({ model, contacts, title });
    }

    initProfilesCard() {
        const { model, periods, profiles } = this;
        return new AccrediteeProfilesCard({ model, periods, profiles });
    }

    initItemsCard() {
        const { model, periods, items } = this;
        return new AccrediteeItemsCard({ model, periods, items });
    }

    newModel(attrs) {
        const { event } = this;
        return new CustomAccreditee.Model(attrs, { event });
    }

    bindModel() {
        if (this.model) {
            this.listenTo(this.model, 'request', () => { this.setSyncing(true); });
            this.listenTo(this.model, 'error', this.onError);
            this.listenTo(this.model, 'sync', this.onSync);
        }
    }

    unbindModel() {
        if (this.model) this.stopListening(this.model);
    }

    setSyncing(syncing) {
        if (syncing !== this.isSyncing) {
            this.isSyncing = syncing;
            this.render();
        }
    }

    onError(model, xhr) {
        this.setSyncing(false);
        this.contactCard.validateResponse(xhr);
    }

    backToOverview() {
        const eventId = this.model.getRelation('event').get('id');
        navigate(`/events/${eventId}/accreditation/attendees`);
    }
}
