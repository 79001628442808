import {
    getComponentState
} from 'app/react/state/helpers';
import {
    mergeComponentState,
    setComponentStateValue,
    showSuccessPopup,
    showErrorPopup
} from 'app/react/state/actions/_index';
import * as Pickup from 'app/react/entities/events/bookings/pickups';

const DELETE_MODAL_ID = 'deletePickupModal';

const FAILED_POPUP = error => `Failed to delete pickup. ${error}`;
const SUCCESSFUL_POPUP = 'Pickup successfully deleted.';

const getState = (state) =>
    getComponentState(state, DELETE_MODAL_ID) || {};

const setSyncing = () =>
    setComponentStateValue(DELETE_MODAL_ID, 'isSyncing', true);

const unsetSyncing = () =>
    setComponentStateValue(DELETE_MODAL_ID, 'isSyncing', false);

const close = () =>
    mergeComponentState(DELETE_MODAL_ID, {
        isOpened: false,
        isSyncing: false
    });

const open = (pickupId) => (dispatch, getState) => {
    const state = getState();
    const pickup = Pickup.stateHelpers.getOne(state, pickupId, true);
    dispatch(mergeComponentState(DELETE_MODAL_ID, {
        isOpened: true,
        pickup,
    }));
};

const deletePickup = (pickupId) => (dispatch, getState) => {
    dispatch(setSyncing());
    dispatch(Pickup.thunks.deleteOneForCurrentContext(pickupId))
        .then(({ isFailure }) => {
            if (isFailure) {
                dispatch(showErrorPopup(FAILED_POPUP));
                dispatch(unsetSyncing());
            } else {
                dispatch(showSuccessPopup(SUCCESSFUL_POPUP));
                dispatch(close());
            }
        });
};

export const stateHelpers = {
    getState
};

export const actions = {
    close,
    setSyncing,
    unsetSyncing
};

export const thunks = {
    open,
    deletePickup
};

