import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';
import { Tooltip, NoRecordsPlaceholder } from 'app/react/components/_index';

const { Header } = Card;
const { Title, Action } = Header;

const TITLE = 'Items overview';
const CREATE_TOOLTIP = 'Create new item';
const NO_GROUPS_PLACEHOLDER_TEXT = 'No groups have been created. Create at least one item group.';
const NO_RECORDS_PLACEHOLDER_TEXT = 'No items created.';
const NO_RECORDS_CREATE_TEXT = 'Create new item';

const ArtistsItemsCard = ({
    hasRecords = false,
    hasGroups = false,
    children,
    onCreate = () => {},
}) => (
    <Card>
        <Header>
            <Title>{TITLE}</Title>
            <Tooltip content={CREATE_TOOLTIP}>
                <Action onClick={onCreate}>
                    <i className="bs-icon-plus" />
                </Action>
            </Tooltip>
        </Header>
        <Card.Content
            isFlexContainer={!hasRecords}
            style={{ minHeight: '300px' }}
        >
            {(() => {
                if (!hasGroups) {
                    return (
                        <NoRecordsPlaceholder style={{ flex: 1 }}>
                            {NO_GROUPS_PLACEHOLDER_TEXT}
                        </NoRecordsPlaceholder>
                    )
                }
                if (!hasRecords) {
                    return (
                        <NoRecordsPlaceholder style={{ flex: 1 }}>
                            {NO_RECORDS_PLACEHOLDER_TEXT}&nbsp;
                            <a onClick={onCreate}>
                                {NO_RECORDS_CREATE_TEXT}
                            </a>
                        </NoRecordsPlaceholder>
                    )
                }
                return children
            })()}
        </Card.Content>
    </Card>
);

ArtistsItemsCard.propTypes = {
    hasRecords: PropTypes.bool,
    children: PropTypes.node,
    onOpenCreateModal: PropTypes.func,
};

export default ArtistsItemsCard;
