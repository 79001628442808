import SubTableRow from './SubTableRow.view';

export default class SubTableLastRow extends SubTableRow {
    
    setSubTable(table) {
        this.subTable = table.lastColumnsTable;
        this.subTable.header = null;
    }
    
}
