import CheckCell from 'app/backbone/components/tables/cells/CheckCell.view'

export default class OfferSentCell extends CheckCell {

    className() {
        return 'center';
    }

    initialize(options) {
        super.initialize(options);
        this.offer = this.model.getCurrentOffer();
    }

    isChecked() {
        return this.offer.get('is_sent');
    }

    isVisible() {
        return this.model.hasOffer();
    }

}
