import { schema } from 'normalizr';
import * as Stage from 'app/react/entities/events/stages/schemas';

export const ENTITY_REDUCER_KEY = 'ticketTypes';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const entityDefinition = {};

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);
