
var config = function() {
    var self = this;
    return {
        valueField: 'id',
        labelField: 'name',
        searchField: ['name'],
        onDropdownOpen:  this.dropdownOpen.bind(this),
        onDropdownClose:  this.dropdownClose.bind(this),
        onChange:  this.optionSelected.bind(this),
        onBlur: this.selectFirstOptionIfEmpty.bind(this),
        create: (function(){
            if(self.create) return self.createOption.bind(self);
            else return false;
        })(),
        render: {
            option: this.renderOption.bind(this),
            option_create: this.renderCreateOption.bind(this)
        }
    };
};

module.exports = config;
