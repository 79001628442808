import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    pageParam,
    filterParam,
    limitParam as pageSizeParam,
    searchParam,
    sortParam
} from 'app/react/state/middleware/api'

const rootAll = (eventId) => `events/${eventId}/reservations`;
const rootOne = (eventId, reservationId) => `events/${eventId}/reservations/${reservationId}`;

export const updateOne = (
    eventId,
    reservationId
) => oneLineTrim`
    ${rootOne(eventId, reservationId)}
`;

export const awaitApprovalForOne = (
    eventId,
    reservationId,
    includes = []
) => oneLineTrim`
    ${rootOne(eventId, reservationId)}/await-approval?
    ${includeParam(includes)}
`;

export const approveOne = (
    eventId,
    reservationId,
    includes = []
) => oneLineTrim`
    ${rootOne(eventId, reservationId)}/approve?
    ${includeParam(includes)}
`;

export const denyOne = (
    eventId,
    reservationId,
    includes = []
) => oneLineTrim`
    ${rootOne(eventId, reservationId)}/deny?
    ${includeParam(includes)}
`;

export const deleteOne = (
    eventId,
    reservationId
) => oneLineTrim`
    ${rootOne(eventId, reservationId)}
`;

export const fetchPaginated = (
    eventId,
    page = 1,
    pageSize = 100,
    filters = {},
    search = '',
    sortKey = '',
    sortOrder = 1,
    includes = []
) => oneLineTrim`
    ${rootAll(eventId)}?
    ${pageParam(page)}&
    ${pageSizeParam(pageSize)}&
    ${filterParam(filters)}&
    ${searchParam(search)}&
    ${sortParam(sortKey, sortOrder)}&
    ${includeParam(includes)}
`;
