import React, { Fragment } from 'react'
import {
    AlertBox,
    Button,
    ConfirmModal,
    InputGroup,
    SearchSelect,
} from 'ui-kit'
import styled from 'styled-components'
import SearchSelectBookings from 'app/react/views/event/artists/bookings/components/selects/SearchSelectBookingContainer'
import BookingOption, { toBookingOption } from 'app/react/views/event/artists/bookings/components/selects/BookingOption'

const toOption = (props) => {
    const {
        id,
        currentBooking,
        assignedItems = [],
    } = props;

    return {
        ...toBookingOption(props),
        isDisabled: assignedItems.length === 0 || id === currentBooking,
        assignedItemsCount: assignedItems.length,
    };
};

const Option = (option) => (
    <BookingOption option={option}>
        <AssignedItemsCount>
            {option.assignedItemsCount > 1
                ? `${option.assignedItemsCount} assigned items`
                : 'No assigned items'
            }
        </AssignedItemsCount>
    </BookingOption>
);

const Buttons = ({ closeModal, onConfirm }) => (
    <Fragment>
        <Button
            type={Button.SECONDARY}
            onClick={closeModal}
        >
            Cancel
        </Button>
        <Button onClick={onConfirm}>
            Copy
        </Button>
    </Fragment>
);

const AssignedItemsCount = styled.span`
    font-weight: var(--fontWeightMedium);
    margin-left: 1rem;
`;

const CopyItemsFromBookingModal = ({
    isLoading,
    renderTrigger,
    booking,
    currentBooking,
    onChangeBooking,
    onCopyItems,
    errors,
}) => (
    <ConfirmModal
        isLoading={isLoading}
        title="Copy items from a booking"
        renderTrigger={renderTrigger}
        renderButtons={Buttons}
        onConfirm={onCopyItems}
        width="50rem"
    >
        <AlertBox
            isSubtle
            hasIconPrefix
            type={AlertBox.WARNING}
        >
            This will remove and replace all currently assigned items.
        </AlertBox>
        <InputGroup messages={errors.booking}>
            <p className="margin-tiny-bottom">
                Instead of having to assign every item manually, you can start off from a booking you've already assigned items to.
                This is mostly useful for artists that have multiple bookings for this event.
            </p>
            <label className="required">Booking</label>
            <SearchSelectBookings
                required
                value={booking}
                onChange={onChangeBooking}
                additionalIncludes={['assigned_items']}
                toOption={(props) => toOption({
                    ...props,
                    currentBooking,
                })}
                renderOption={Option}
            />
        </InputGroup>
    </ConfirmModal>
);

CopyItemsFromBookingModal.defaultProps = {
    isLoading: false,
    errors: {},
};

export default CopyItemsFromBookingModal;
