import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Day from 'app/backbone/models/event/Day';
import AccrediteeTable from '../tables/AccrediteeTable.view';
import * as Accreditee from 'app/backbone/models/event/accreditation/accreditees/Accreditee';

export default class AccrediteesOverviewCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            accreditees: OptionTypes.instanceOf(Accreditee.PageableCollection).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired,
            title
        };
    }

    initContent() {
        this.table = this.initTable();
        return this.table;
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add attendees',
            icon: 'bs-icon-plus',
            action: this.createAccreditee.bind(this)
        }];
    }

    initTable() {
        const { accreditees, days } = this;
        return new AccrediteeTable({ accreditees, days });
    }

    createAccreditee() {
        const eventId = this.accreditees.event.id;
        navigate(`/events/${eventId}/accreditation/attendees/create`);
    }

}
