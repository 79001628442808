import React from 'react';
import { Grid, AlertBox } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import ContentHeader from '../../components/layout/ContentHeader';
import ItemsCard from '../components/items/ItemsCard';
import ItemsTable from '../components/items/ItemsTable';
import GroupsCard from '../components/groups/GroupsCard';
import GroupsTable from '../components/groups/GroupsTable';
import CreateGroupModal from '../components/groups/statefull/CreateGroupModalContainer';
import CreateItemModal from '../components/items/statefull/CreateItemModalContainer';
import UpdateGroupModal from '../components/groups/statefull/UpdateGroupModalContainer';
import UpdateItemModal from '../components/items/statefull/UpdateItemModalContainer';
import DeleteGroupModal from '../components/groups/statefull/DeleteGroupModalContainer';
import DeleteItemModal from '../components/items/statefull/DeleteItemModalContainer';

const { Content } = ContentWrapper;
const { Cell } = Grid;

const MAX_WIDTH = '1100px';
const GROUPS_CARD_WIDTH = '400px';
const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';

const AccountArtistsItemsOverview = ({
    store,
    canManageItems = false,
    currency = 'EUR',
    isFetching = false,
    items = [],
    groups = [],

    onOpenCreateItemModal = () => {},
    onOpenUpdateItemModal = () => {},
    onOpenDeleteItemModal = () => {},
    onOpenCreateGroupModal = () => {},
    onOpenUpdateGroupModal = () => {},
    onOpenDeleteGroupModal = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader activeTab="items" />
        <Content isLoading={isFetching}>
            {!canManageItems
                ? <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox>
                : <div style={{ maxWidth: MAX_WIDTH }}>
                    <Grid>
                        <Cell>
                            <ItemsCard
                                hasRecords={items.length > 0}
                                hasGroups={groups.length > 0}
                                onCreate={() => onOpenCreateItemModal()}
                            >
                                <ItemsTable
                                    currency={currency}
                                    items={items}
                                    groups={groups}
                                    onAdd={onOpenCreateItemModal}
                                    onUpdate={onOpenUpdateItemModal}
                                    onDelete={onOpenDeleteItemModal}
                                />
                            </ItemsCard>
                        </Cell>
                        <Cell
                            isAutoSized
                            width={GROUPS_CARD_WIDTH}
                        >
                            <GroupsCard
                                hasRecords={groups.length > 0}
                                onCreate={() => onOpenCreateGroupModal()}
                            >
                                <GroupsTable
                                    groups={groups}
                                    onAdd={onOpenCreateGroupModal}
                                    onUpdate={onOpenUpdateGroupModal}
                                    onDelete={onOpenDeleteGroupModal}
                                />
                            </GroupsCard>
                        </Cell>
                    </Grid>
                </div>
            }

        </Content>
        <CreateGroupModal />
        <CreateItemModal />
        <UpdateGroupModal />
        <UpdateItemModal />
        <DeleteGroupModal />
        <DeleteItemModal />
    </ContentWrapper>
);

AccountArtistsItemsOverview.propTypes = {
};

export default AccountArtistsItemsOverview;
