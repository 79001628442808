import * as actions from 'app/react/state/actions/_index';
import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import { fetchAll, fetchAllPaginated, createOne, updateOne, deleteOne } from './actions';
import {  } from './helpers';
import { COLLECTION_REDUCER_KEY } from './schemas';

export const addOneToCurrentContextCollection = (vehicleId) => (dispatch, getState) => {
    const key = Event.generateCurrentContextKey(getState());
    return dispatch(actions.addEntityToCollection(vehicleId, COLLECTION_REDUCER_KEY, key));
};

export const fetchAllForCurrentContext = (fields) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(fetchAll(accountId, eventId, fields));
};

export const fetchAllPaginatedForCurrentContext = (query, variables) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const finalVariables = {
        ...variables,
        accountId,
        eventId
    };
    return dispatch(fetchAllPaginated(query, finalVariables));
};

export const createOneForCurrentContext = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(createOne(accountId, eventId, values));
};

export const updateOneForCurrentContext = (vehicleId, values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, vehicleId, values));
};

export const deleteOneForCurrentContext = (vehicleId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(deleteOne(accountId, eventId, vehicleId));
};


