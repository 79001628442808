import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment';
import * as actions from 'app/react/state/actions/_index';
import * as stateHelpers from 'app/react/state/helpers';
import SettingsFormOverview, { CONFIRM_MODAL_TEXT } from './Overview';
import * as Event from 'app/react/entities/events/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Settings from 'app/react/entities/events/production_website/settings/artists/index';
import * as Routes from 'app/routes/event/production_website/artists/form/routes';

export const SETTINGS_SECTIONS_FORM_ID = 'settingsSectionsForm';
export const SETTINGS_SECTIONS_CARD_ID = 'settingsSectionsCard';
export const CONFIRM_MODAL_ID = 'settingsSectionsConfirmModal';
export const POPUP_MESSAGES = {
    FAILED: 'Failed to save artist website settings.',
    DONE: 'Artist website settings saved successfully.'
};

const formatDate = (date) =>
    date === null ? null : moment(date).format();

class SettingsFormOverviewContainer extends React.Component {

    componentDidMount() {
        $(window).on('beforeunload', () => this.props.onLeavePage());
    }

    componentWillUnmount() {
        $(window).off('beforeunload');
    }

    render() {
        return <SettingsFormOverview { ...this.props } />;
    }

}

SettingsFormOverviewContainer.propTypes = {
    onLeavePage: PropTypes.func.isRequired
};

const formHasChanged = (formValues = {}, originalSettings = {}, originalSections = []) => {
    const { sections, deadline } = formValues;
    let sectionsHasChanged = false;
    if (typeof sections !== 'undefined') {
        originalSections.forEach(section => {
            sections.forEach(formSection => {
                if (section.uuid === formSection.uuid && !sectionsHasChanged) {
                    sectionsHasChanged = !_.isEqual(section, formSection);
                }
            });
        });
    }
    return (
        originalSettings.deadline !== deadline ||
        sectionsHasChanged
    );
};

const mapStateToProps = (state) => {
    const artistType = ArtistType.stateHelpers.getCurrent(state) || {};
    const formState = stateHelpers.getFormComponentState(state, SETTINGS_SECTIONS_FORM_ID);
    const cardState = stateHelpers.getComponentState(state, SETTINGS_SECTIONS_CARD_ID);
    const confirmModalState = stateHelpers.getComponentState(state, CONFIRM_MODAL_ID);
    const originalSettings = Settings.stateHelpers.getCurrentWithSections(state) || {};
    const originalSections = originalSettings.sections;
    return ({
        isFetching: (
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Settings.stateHelpers.isFetchingCurrent(state)
        ),
        isArtistTypesEnabled: Event.stateHelpers.isArtistTypesEnabledForCurrent(state),
        eventId: Event.stateHelpers.getCurrentId(state),
        artistTypeId: ArtistType.stateHelpers.getCurrentId(state),
        eventDateFormat: Event.stateHelpers.getCurrentDateFormat(state),
        artistTypeName: artistType.name,
        hasUnsavedChanges: formHasChanged(formState.values, originalSettings, originalSections),
        formState,
        cardState,
        confirmModalState
    });
};

const mapDispatchToProps = (dispatch) => {
    const onConfirmModalClosed = () => {
        dispatch(actions.closeConfirmModal(CONFIRM_MODAL_ID));
    };
    const onConfirmModalConfirmed = (action) => {
        dispatch(actions.closeConfirmModal(CONFIRM_MODAL_ID));
        setTimeout(() => dispatch(action), 300);
    };
    const onLeavePage = (hasUnsavedChanges = false) => {
        if (hasUnsavedChanges) return CONFIRM_MODAL_TEXT;
    };
    const onBackToArtistTypes = (eventId, hasUnsavedChanges = false) => {
        const url = Routes.artistTypes(eventId);
        const action = actions.navigate.to(url);
        if (hasUnsavedChanges) {
            dispatch(actions.openConfirmModal(CONFIRM_MODAL_ID, action));
        } else {
            dispatch(action);
        }
    };
    const onChangeDeadline = (value) => {
        dispatch(actions.updateFormComponentStateValue(SETTINGS_SECTIONS_FORM_ID, 'deadline', value));
    };
    const onChangeCascadeToAllBookings = (value) => {
        dispatch(actions.updateFormComponentStateValue(SETTINGS_SECTIONS_FORM_ID, 'shouldCascadeToAllBookings', value));
    };
    const onCopyDeadlineToSections = (deadline) => {
        const mapValue = (section) => ({ ...section, deadline });
        dispatch(actions.updateFormComponentStateMapValue(SETTINGS_SECTIONS_FORM_ID, 'sections', mapValue));
    };
    const onEditSection = (isArtistTypesEnabled, eventId, artistTypeId, sectionId, hasUnsavedChanges = false) => {
        const pageReload = sectionId === 'guest_lists';
        const url =
            (sectionId === 'intro') ?
                Routes.formIntro(isArtistTypesEnabled, eventId, artistTypeId) :
                Routes.formSection(isArtistTypesEnabled, eventId, artistTypeId, sectionId);
        const action = actions.navigate.to(url, { pageReload });
        if (hasUnsavedChanges) {
            dispatch(actions.openConfirmModal(CONFIRM_MODAL_ID, action));
        } else {
            dispatch(action);
        }
    };
    const onChangeSectionValue = (sectionId, propKey, value) => {
        const mapValue = (section) => {
            if (section.id === sectionId) return { ...section, [propKey]: value };
            return section;
        };
        dispatch(actions.updateFormComponentStateMapValue(SETTINGS_SECTIONS_FORM_ID, 'sections', mapValue));
    };
    const onSaveSettings = (eventId, artistTypeId, formState) => {
        const { values } = formState;
        const body = {
            deadline: formatDate(values.deadline),
            shouldCascadeToAllBookings: values.shouldCascadeToAllBookings,
            sections: values.sections.map(
                ({
                    id,
                    enabled,
                    name,
                    deadline,
                    closed
                }) =>
                    ({
                        id,
                        enabled,
                        name,
                        deadline: formatDate(deadline),
                        closed
                    })
            )
        };
        dispatch(Settings.thunks.updateCurrent(body))
            .then(({ isFailure, errorMessage }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(`${POPUP_MESSAGES.FAILED}: ${errorMessage}`));
                } else {
                    dispatch(actions.showSuccessPopup(POPUP_MESSAGES.DONE));
                }
            });
    };

    return {
        onConfirmModalClosed,
        onConfirmModalConfirmed,
        onLeavePage,
        onBackToArtistTypes,
        onChangeDeadline,
        onChangeCascadeToAllBookings,
        onCopyDeadlineToSections,
        onEditSection,
        onChangeSectionValue,
        onSaveSettings
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onConfirmModalConfirmed: () => dispatchProps.onConfirmModalConfirmed(stateProps.confirmModalState.action),
    onLeavePage: () => dispatchProps.onLeavePage(stateProps.hasUnsavedChanges),
    onBackToArtistTypes: () => dispatchProps.onBackToArtistTypes(
        stateProps.eventId,
        stateProps.hasUnsavedChanges
    ),
    onCopyDeadlineToSections: () => dispatchProps.onCopyDeadlineToSections(stateProps.formState.values.deadline),
    onEditSection: (sectionId) => dispatchProps.onEditSection(
        stateProps.isArtistTypesEnabled,
        stateProps.eventId,
        stateProps.artistTypeId,
        sectionId,
        stateProps.hasUnsavedChanges
    ),
    onSaveSettings: () => dispatchProps.onSaveSettings(
        stateProps.eventId,
        stateProps.artistTypeId,
        stateProps.formState
    )
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SettingsFormOverviewContainer);
