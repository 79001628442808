import { combineReducers } from 'redux';
import * as AccountArtistItem from 'app/react/entities/accounts/items/reducers';
import * as AccountArtistItemGroup from 'app/react/entities/accounts/item-groups/reducers';
import * as AccountContact from 'app/react/entities/accounts/contacts/reducers';
import * as AccountArtist from 'app/react/entities/accounts/artists/reducers';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/reducers';
import * as BookingType from 'app/react/entities/booking_types/reducers';
import * as BookingAttendee from 'app/react/entities/events/bookings/attendees/reducers';
import * as Day from 'app/react/entities/events/days/reducers';
import * as Stage from 'app/react/entities/events/stages/reducers';
import * as Profile from 'app/react/entities/events/accreditation/profiles/reducers';
import * as ArtistType from 'app/react/entities/events/artist_types/reducers';
import * as PWArtistSettingsSectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/reducers';
import * as EventLocation from 'app/react/entities/events/locations/reducers';
import * as Booking from 'app/react/entities/events/bookings/reducers';
import * as Driver from 'app/react/entities/events/drivers/reducers';
import * as Pickup from 'app/react/entities/events/bookings/pickups/reducers';
import * as PickupMode from 'app/react/entities/events/bookings/pickups/modes/reducers';
import * as Transport from 'app/react/entities/events/bookings/transports/reducers';
import * as Vehicle from 'app/react/entities/events/vehicles/reducers';
import * as VehicleType from 'app/react/entities/vehicle_types/reducers';
import * as EventArtistItem from 'app/react/entities/events/artists/items/reducers';
import * as EventArtistItemGroup from 'app/react/entities/events/artists/item_groups/reducers';
import * as BookingItem from 'app/react/entities/events/bookings/items/reducers';
import * as Accommodation from 'app/react/entities/events/accommodations/reducers';
import * as TicketType from 'app/react/entities/events/ticket_types/reducers';

export const collectionsReducers = {
    ...BookingStatus.collectionReducers,
    ...BookingType.collectionReducers,
    ...BookingAttendee.collectionReducers,
    ...Day.collectionReducers,
    ...Stage.collectionReducers,
    ...Profile.collectionReducers,
    ...ArtistType.collectionReducers,
    ...PWArtistSettingsSectionFormField.collectionReducers,
    ...EventLocation.collectionReducers,
    ...Booking.collectionReducers,
    ...Driver.collectionReducers,
    ...Pickup.collectionReducers,
    ...PickupMode.collectionReducers,
    ...Transport.collectionReducers,
    ...Vehicle.collectionReducers,
    ...VehicleType.collectionReducers,
    ...AccountArtistItem.collectionReducers,
    ...AccountArtistItemGroup.collectionReducers,
    ...AccountContact.collectionReducers,
    ...AccountArtist.collectionReducers,
    ...EventArtistItem.collectionReducers,
    ...EventArtistItemGroup.collectionReducers,
    ...BookingItem.collectionReducers,
    ...Accommodation.collectionReducers,
    ...TicketType.collectionReducers
};

export default combineReducers(collectionsReducers);
