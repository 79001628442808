import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    filterParam,
    limitAllParam as pageSizeAllParam,
} from 'app/react/state/middleware/api'

const root = (eventId) => `events/${eventId}/vehicles`;

export const fetchAll = (
    eventId,
    filters = {},
    includes = []
) => oneLineTrim`
    ${root(eventId)}?
    ${pageSizeAllParam}&
    ${filterParam(filters)}&
    ${includeParam(includes)}&
`;

export const createOne = (
    eventId
) => oneLineTrim`
    ${root(eventId)}
`;

export const updateOne = (
    eventId,
    vehicleId
) => oneLineTrim`
    ${root(eventId)}/${vehicleId}
`;

export const deleteOne = (
    eventId,
    vehicleId
) => oneLineTrim`
    ${root(eventId)}/${vehicleId}
`;
