import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import * as Event from 'app/backbone/models/event/Event';
import * as Company from 'app/backbone/models/account/Company';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as EventUser from 'app/backbone/models/event/EventUser';
import * as User from 'app/backbone/models/account/User';
import EventCompanyCard from '../cards/EventCompanyCard.view';
import CompanyProfilesSettingsCard from '../profiles/cards/CompanyProfilesSettingsCard.view';
import CompanyItemsSettingsCard from '../items/cards/CompanyItemsSettingsCard.view';
import EventCompanyGeneralSettingsCard from '../cards/EventCompanyGeneralSettingsCard.view';
import template from './EventCompany.hbs';

export default class EventCompanyView extends Content {

    get template() {
        return template;
    }

    ui() {
        return {
            companyCard: '#company-card',
            profilesCard: '#profiles-card',
            itemsCard: '#items-card',
            generalSettingsCard: '#general-card'
        };
    }

    optionTypes() {
        return {
            event: OptionTypes.instanceOf(Event.Model).isRequired,
            companies: OptionTypes.instanceOf(Company.Collection).isRequired,
            profiles: OptionTypes.instanceOf(Profile.Collection).isRequired,
            items: OptionTypes.instanceOf(EventItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            users: OptionTypes.instanceOf(EventUser.Collection).isRequired,
            currentUser: OptionTypes.instanceOf(User.Model).isRequired,
            eventCompanies: OptionTypes.instanceOf(EventCompany.PageableCollection).isRequired,
            myEventCompanies: OptionTypes.instanceOf(EventCompany.PageableCollection).isRequired,
            company: OptionTypes.instanceOf(EventCompany.Model)
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Props
        this.model = this.company || this.newModel();
        // Subviews
        this.companyCard = this.initCompanyCard();
        this.profilesCard = this.initProfilesCard();
        this.itemsCard = this.initItemsCard();
        this.generalSettingsCard = this.initGeneralSettingsCard();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        if (!this.isSyncing) {
            this.unbindModel();
            this.bindModel();
            this.assignSubview(this.companyCard, this.ui.companyCard);
            this.assignSubview(this.profilesCard, this.ui.profilesCard);
            this.assignSubview(this.itemsCard, this.ui.itemsCard);
            this.assignSubview(this.generalSettingsCard, this.ui.generalSettingsCard);
        }
        return this.delegateEvents();
    }

    remove() {
        super.remove();
        this.companyCard.remove();
        this.profilesCard.remove();
        this.itemsCard.remove();
        this.generalSettingsCard.remove();
    }

    viewModel() {
        return {
            view: {
                canCreateAnother: true,
                syncingText: this.syncingText,
                placeholderHeight: this.placeholderHeight,
                isSyncing: this.isSyncing,
                isUpdating: this.isUpdating
            },
            model: this.model.attributes
        };
    }

    initCompanyCard() {
        const { model, companies } = this;
        const title = 'Company';
        return new EventCompanyCard({ model, companies, title });
    }

    initProfilesCard() {
        const { model, periods, profiles, items } = this;
        const title = 'Available profiles';
        return new CompanyProfilesSettingsCard({ model, periods, profiles, items, title });
    }

    initItemsCard() {
        const { model, periods, items } = this;
        const title = 'Requestable items';
        const editing = true;
        return new CompanyItemsSettingsCard({ company: model, periods, items, editing, title });
    }

    initGeneralSettingsCard() {
        const { model, currentUser, users } = this;
        const title = 'General settings';
        return new EventCompanyGeneralSettingsCard({ company: model, currentUser, users, title });
    }

    newModel(initialValues) {
        const { event } = this;
        return new EventCompany.Model(initialValues, { event });
    }

    bindModel() {
        if (this.model) {
            this.listenTo(this.model, 'request', () => { this.setSyncing(true); });
            this.listenTo(this.model, 'error', this.onError);
            this.listenTo(this.model, 'sync', this.onSync);
        }
    }

    unbindModel() {
        if (this.model) this.stopListening(this.model);
    }

    setSyncing(syncing) {
        if (syncing !== this.isSyncing) {
            this.isSyncing = syncing;
            this.render();
        }
    }

    onError(model, xhr) {
        this.setSyncing(false);
        this.companyCard.form.validateResponse(xhr);
    }

    backToOverview() {
        const { event } = this;
        const eventId = event.id;
        navigate(`/events/${eventId}/accreditation/companies`);
    }

}
