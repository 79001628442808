import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/button/Button';
import { useHTTP } from '../../hooks/UseHTTP';
import ChooseEventDialog from '../atoms/dialogs/ChooseEventDialog';
import { blancoEvent } from '../../hooks/UseEventState';
import CancelCreateDialog from '../atoms/dialogs/CancelCreateDialog';

export const GetStarted = ({ setActiveStep, eventState }) => {
	const [events, setEvents] = useState(eventState.event.existingEvents);
	const [chooseEventDialog, setChooseEventDialog] = useState(false);
	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

	const { doHttpCall } = useHTTP();

	useEffect(() => {
		const getEvents = async () => {
			if (!eventState.event.existingEvents || (eventState.event.existingEvents && eventState.event.existingEvents.length === 0)) {
				doHttpCall(`/api/accounts/${eventState.account.id}/events`, 'GET')
					.then((eventsRes) => {
						if (eventsRes && eventsRes.data) {
							setEvents(eventsRes.data);
						}
					})
					.catch((err) => {});
			}
		};
		getEvents();
	}, [eventState.event.existingEvents]);

	return (
		<div className='form_content'>
			<h2>Let's get started on your event</h2>
			<h4 className="form-sub-caption">Choose how you want to start</h4>
			<div className='get-started__card-container'>
				<div className='get-started__card'>
					<h5>Start from scratch</h5>
					<span>Create a total new event and fill in all the blancs</span>
					<Button
						label='Choose'
						onClick={() =>
							setActiveStep(1, { ...blancoEvent, existingEvents: events })
						}
						modFullWidth
					/>
				</div>
				{events && events.length > 0 && (
					<div className='get-started__card'>
						<h5>Start from an existing event</h5>
						<span>Choose one of your previous events as a starting point</span>
						<Button
							label='Choose'
							onClick={() => setChooseEventDialog(true)}
							modFullWidth
						/>
					</div>
				)}
			</div>
			{chooseEventDialog && (
				<ChooseEventDialog
					events={events}
					setCancelDialogOpen={setChooseEventDialog}
					setActiveStep={setActiveStep}
				/>
			)}
			<Fragment>
				<div className='event-footer'>
					<Button
						label='Cancel'
						variant='cancel'
						onClick={() => setCancelDialogOpen(true)}
					/>
				</div>
				{cancelDialogOpen && (
					<CancelCreateDialog
						setCancelDialogOpen={setCancelDialogOpen}
						cancelCreate={() => navigate('/events', { pageReload: true })}
					></CancelCreateDialog>
				)}
			</Fragment>
		</div>
	);
};

GetStarted.propTypes = {
	setActiveStep: PropTypes.func,
	activeSubstep: PropTypes.number,
	eventState: PropTypes.object,
};

export default GetStarted;
