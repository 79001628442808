import React from 'react'
import { Card, Grid } from 'ui-kit'
import './FormBuilderView.css'

const { Header, Content, Footer } = Card;
const { Title } = Header;
const { Cell } = Grid;

export const TITLE = 'Available fields';
export const TEXT_FORM_FIELD_TYPE = 'text';
export const DATE_FORM_FIELD_TYPE = 'date';
const AVAILABLE_FORM_FIELDS = [{
    type: TEXT_FORM_FIELD_TYPE,
    label: 'Text input',
}, {
    type: DATE_FORM_FIELD_TYPE,
    label: 'Date input',
}];

export const AvailableFormFieldsCard = ({
    dragula,
    dragulaContainerId,
}) => (
    <Card className="isSticky">
        <Header>
            <Title>{TITLE}</Title>
        </Header>
        <Content>
            <ul
                id={dragulaContainerId}
                className="available-fields-list"
                style={{ minHeight: '100px' }}
                ref={node => node && dragula.containers.push(node)}
            >
                {AVAILABLE_FORM_FIELDS.map(({ type, label }) => (
                    <li className="available-fields-list__item" key={type} data-form-field-type={type}>
                        <Grid hasGutters={false}>
                            <Cell
                                isAutoSized
                                isContentCentered
                                width="35px"
                                style={{ alignItems: 'flex-start', cursor: 'grab' }}
                            >
                                <i className="bs-icon-cursor-move" />
                            </Cell>
                            <Cell>{label}</Cell>
                        </Grid>
                    </li>
                ))}
            </ul>
        </Content>
        <Footer />
    </Card>
);

export default AvailableFormFieldsCard;
