import Toastr from "toastr";
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PageableTable from 'app/backbone/components/tables/PageableTable.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import SelectableRow from 'app/backbone/components/tables/rows/SelectableRow.view';
import * as Day from 'app/backbone/models/event/Day';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import ItemCell from './cells/ItemsCell.view';
import AddedByCell from './cells/AddedByCell.view';
import * as Accreditee from 'app/backbone/models/event/accreditation/accreditees/Accreditee';

export default class AccrediteeTable extends PageableTable {

    optionTypes() {
        return {
            accreditees: OptionTypes.instanceOf(Accreditee.PageableCollection).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.collection = this.accreditees;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.row = SelectableRow;
        this.filtersDef = this.getFiltersDef();
        this.placeholderText = this.getPlaceholderText();

        // SubViews
        this.confirmModal = this.initConfirmModal();

        super.initialize(options);

        this.listenTo(this.accreditees, 'backgrid:select', this.update);
    }

    initColumns() {
        const { actions } = this;
        return [{
            name: 'fullname',
            label: 'Attendee',
            headerCell: CellFactory.createHeaderCell(),
            cell: 'string',
            sortable: true,
            editable: false,
            sortValue() {
                return 'contact.firstname';
            }
        }, {
            name: 'email',
            label: 'Email',
            headerCell: CellFactory.createHeaderCell(),
            cell: 'string',
            sortable: true,
            editable: false,
            sortValue() {
                return 'contact.email';
            }
        }, {
            name: 'telephone',
            label: 'Tel',
            headerCell: CellFactory.createHeaderCell('100px'),
            cell: 'string',
            sortable: true,
            editable: false,
            sortValue() {
                return 'contact.telephone';
            }
        }, {
            name: 'name',
            label: 'Added by',
            headerCell: CellFactory.createHeaderCell(),
            cell: AddedByCell,
            sortable: false,
            editable: false,
            sortValue() {
                return 'profilesGrantedByCompanies.eventCompanyProfile.eventCompany.company.name';
            }
        }, {
            name: 'added_at',
            label: 'Added on',
            headerCell: CellFactory.createHeaderCell('100px'),
            cell: 'string',
            sortable: true,
            editable: false,
            sortValue() {
                return 'created';
            }
        }, {
            name: 'name',
            label: 'Items',
            headerCell: CellFactory.createHeaderCell(),
            cell: ItemCell,
            sortable: false,
            editable: false
        }, {
            name: 'name',
            label: 'Profiles',
            headerCell: CellFactory.createHeaderCell(),
            cell: CellFactory.createSubCollectionLabelCell(null, null, 'profiles'),
            sortable: true,
            editable: false,
            sortValue() {
                return 'accrediteeProfiles.profile.name';
            }
        }, {
            name: 'actions',
            label: '',
            headerCell: CellFactory.createHeaderCell('130px'),
            cell: CellFactory.createActionCell(actions),
            sortable: false,
            editable: false
        }];
    }

    getFiltersDef() {
        const { days } = this;
        return [{
            name: 'day',
            label: 'Day',
            placeholder: 'All days',
            collection: days,
            filterName: 'day',
            optionValue: 'id',
            optionName: (day) => `${day.get('start_date_short')} ${day.get('name')}`
        }];
    }

    initActions() {
        return [{
            name: 'ticket',
            icon: 'bs-icon-download',
            tooltip: 'Download ticket',
            action: this.downloadTicket.bind(this),
            canRender: (accreditee) => accreditee.canDownloadTicket()
        }, {
            name: 'send-ticket',
            icon: 'bs-icon-ticket',
            tooltip: 'Send ticket',
            action: this.sendTicket.bind(this),
            canRender: (accreditee) => accreditee.canSendTicket()
        }, {
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            action: this.update.bind(this),
        }, {
            name: 'remove',
            icon: 'bs-icon-bin',
            tooltip: 'Remove',
            action: this.delete.bind(this),
        }];
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    downloadTicket(accreditee) {
        const eventId = accreditee.event.id;
        const accrediteeId = accreditee.id;
        navigate(`/events/${eventId}/accreditation/accreditees/${accrediteeId}/ticket`, { pageReload: true });
    }

    sendTicket(accreditee) {
        const email = accreditee.get('email');
        this.confirmModal.open({
            title: 'Send Ticket',
            body: `Are you sure you want to send the ticket to this attendee at <strong>${email}</strong>?`,
            confirmClass: 'submit',
            confirmText: 'Send',
            autoClose: true,
            syncingText: `Sending Ticket to ${email}...`,
            confirm: () => {
                accreditee.sendTicket()
                    .done(() => {
                        Toastr.success(`The ticket was successfully sent to <strong>${email}</strong>.`);
                        accreditee.collection.fetch({ bustCache: true });
                    })
                    .fail(() => {
                        Toastr.error(`Failed to send the ticket to <strong>${email}</strong>.
                        Please try again later or contact our support.`);
                    });
            }
        });
    }

    update(accreditee, newTab = false) {
        const eventId = accreditee.event.id;
        const accrediteeId = accreditee.id;
        navigate(`/events/${eventId}/accreditation/attendees/${accrediteeId}/edit`, { newTab });
    }

    delete(accreditee) {
        const name = accreditee.get('fullname');
        this.confirmModal.open({
            title: 'Remove attendee',
            body: `Are you sure you want to remove attendee <strong>${name}</strong>?`,
            confirmText: 'Yes, remove',
            confirmClass: 'danger',
            autoClose: true,
            syncingText: `Removing ${name}...`,
            confirm: () => { accreditee.destroy({ wait: true }); }
        });
    }

    getPlaceholderText() {
        return 'No attendees have been added for this event yet.';
    }

    hasSearch() {
        return true;
    }
}
