import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import { loadData } from 'app/react/helpers/_index';
import ItemsView from 'app/react/views/account/settings/accreditation/ItemsView';
import * as Item from 'app/backbone/models/account/accreditation/Item';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';
import * as Routes from './routes'

// Backbone Controller
export default class AccreditationSettingsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.accreditationItems(), null, this.items.bind(this));
    }

    items() {
        const { app, store, data } = this;

        const account = data.registerCurrentAccount();
        const items = data.register('pagedItems', Item.PageableCollection, { account });
        const itemGroups = data.register('pagedItemGroups', ItemGroup.PageableCollection, { account });
        items.setIncludes(['groups']).setSortKey('item_groups.name');

        app.renderContentComponent(React.createElement(ItemsView, { store, items, itemGroups }));
        loadData({ models: [items, itemGroups] });
    }

}
