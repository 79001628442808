var _ = require('underscore');

var ValidationGroup = require('../inputs/ValidationGroup.view'),
    CardErrorTemplate = require('../inputs/errors/CardError.hbs'),
    ErrorTemplate = require('../inputs/errors/Error.hbs');

/**
 * @mixin
 */
var ValidatedForm = {

    messages: {
        error: 'Something went wrong. Please try again later or contact our support.',
        unauthorized: 'You are not authorized to perform this action.'
    },

    /**
     * Creates a validation group from the nearest '.input-group' element to an input.
     * Provide the name attribute of the input for which you want to create a validation group.
     * Adds the view to the form.validationGroups object with the input's name as key.
     *
     * Example:
     *
     * <div class="input-group">
     *     <label>Event Name</label>
     *     <input type="text" name="event_name"/>
     * </div>
     *
     * @param {string} inputName - The name of the input that should be validated.
     * @param {Backbone.Model} model - The model to which this input is bound
     * @param {string} modelAttr - The specific attribute that is bound to this input group. If no attribute is given, the input name is used.
     */
    initValidationGroup: function(inputName, model, modelAttr) {
        var $inputGroup = this.$('form').find('[name=' + inputName + ']').closest('.input-group');

        if($inputGroup.length) {
            var validationGroup = new ValidationGroup({
                el: $inputGroup,
                inputName: inputName,
                model: model,
                modelAttr: modelAttr ? modelAttr : inputName
            });
            validationGroup.removeError();
            return validationGroup;
        } else {
            throw new Error('Cannot find input-group for input with name "' + inputName + '"')
        }
    },

    /**
     * Adds an error to an element on the page.
     *
     * @param {string} selector - The jquery element selector where you want to add the error.
     * @param {string} message - The error message.
     * @param {string} location - Determines the location of the error relative to the selector, default is append
     * @param {boolean} preservePrev - If true, previous errors will not be removed.
     */
    addError: function(selector, message, location, preservePrev) {
        if(!preservePrev) this.removeError(selector);

        var $el = this.$(selector);
        var errorHtml = ErrorTemplate({ message: message});
        switch (location) {
            case 'prepend': $el.prepend(errorHtml); break;
            case 'append': $el.append(errorHtml); break;
            case 'before': $el.before(errorHtml); break;
            case 'after': $el.after(errorHtml); break;
            default: $el.append(errorHtml);
        }

        if($el.is('fieldset')) $el.addClass('fieldset-error');
    },

    /**
     * Adds an error to the footer of a card.
     *
     * @param {string} selector - The jquery element selector where you want to add the error.
     * @param {string} message - The error message.
     * @param {string} location - Determines the location of the error relative to the selector, default is append
     * @param {boolean} preservePrev - If true, previous errors will not be removed.
     */
    addCardError: function(selector, message, location, preservePrev) {
        if (!preservePrev) this.removeCardError(selector);

        var $el = this.$(selector).find('.card--footer');
        var errorHtml = CardErrorTemplate({message: message});
        switch (location) {
            case 'prepend': $el.prepend(errorHtml); break;
            case 'append': $el.append(errorHtml); break;
            case 'before': $el.before(errorHtml); break;
            case 'after': $el.after(errorHtml); break;
            default: $el.append(errorHtml);
        }
    },

    /**
     * Removes all errors from an element.
     *
     * @param {string} selector - The jquery element selector where you want to remove all errors.
     */
    removeError: function(selector) {
        var $el = this.$(selector);
        $el.find('small.error').remove();
        $el.siblings('small.error').remove();

        if($el.is('fieldset')) $el.removeClass('fieldset-error');
    },

    /**
     * Removes all errors from a card.
     *
     * @param {string} selector - The jquery element selector where you want to remove all errors.
     */
    removeCardError: function(selector) {
        var $el = this.$(selector).parent();
        $el.find('.card--footer').find('.error').remove();
    },

    /**
     * Adds an error to the form element on the page. Removes all previous errors on the form.
     *
     * @param {string} message - The error message.
     * @param {boolean} prepend - If true, the error get's prepended to the form's contents. Default the error get appended.
     * @param preservePrev - If true, previous errors will not be removed.
     */
    addFormError: function(message, prepend, preservePrev)  {
        this.addError('form', message, prepend, preservePrev);
    },

    /**
     * Removes all errors from the form.
     *
     */
    removeFormError: function()  {
        this.removeError('form');
    },

    /**
     * Validate an xhr response and adds errors to the validation groups or form.
     *
     * If server return 422, check if a validation group is present on the form for the returned fields and add errors to these validation groups.
     * If no validation group is found show errors as form errors.
     *
     * Example laravel response object:
     *
     * {
     *      "artist": ["The artist field is required.", "The artist already exists."],
     *      "day": ["The day field is required."]
     * }
     *
     * If server return 401 or 402, show unauthorized form error.
     * Default, show a something went wrong form error
     *
     * @param {Object} xhr - The xhr response returned by the server.
     */
    validateResponse: function(xhr) {
        if(xhr.status == 422 && xhr.responseJSON) {
            var errors =  xhr.responseJSON['errors'];
            if(errors) {
                _.each(errors, function(messages, field) {
                    _.each(messages, function(message) {
                        this.addResponseError(field, message)
                    }, this);
                }, this);
            } else {
                //TODO remove this if old requests are removed
                _.each(xhr.responseJSON, function(messages, field) {
                    _.each(messages, function(message) {
                        this.addResponseError(field, message)
                    }, this);
                }, this);
            }
        } else if(xhr.status == 401 || xhr.status == 403) {
            this.addFormError(this.messages.unauthorized, 'prepend');
        } else {
            this.addFormError(this.messages.error, 'prepend');
        }
    },

    addResponseError(field, message) {
        var validationGroup;
        if(this.form && this.form.groups && (validationGroup = this.form.groups.findByCustom(field))){
            validationGroup.addError(message)
        } else if(this.groups && (validationGroup = this.groups.findByCustom(field))) {
            validationGroup.addError(message)
        } else {
            this.addFormError(message, 'prepend', true);
        }
    }
};

module.exports = ValidatedForm;
