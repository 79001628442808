import OptionTypes from 'app/backbone/helpers/OptionTypes';
import AccrediteeProfileTable from 'app/backbone/views/event/accreditation/accreditees/profiles/tables/AccrediteeProfileTable.view';
import GrantedDayCell from './cells/GrantedDayCell.view';
import GrantedAllDaysCell from './cells/GrantedAllDaysCell.view';
import * as CompanyAccrediteeProfile from 'app/backbone/models/event/accreditation/companies/CompanyAccrediteeProfile';

export default class CompanyAccrediteeProfileTable extends AccrediteeProfileTable {

    optionTypes() {
        const { periods, profiles, placeholderHeight } = super.optionTypes();
        return {
            collection: OptionTypes.instanceOf(CompanyAccrediteeProfile.Collection).isRequired,
            periods,
            profiles,
            placeholderHeight
        };
    }

    initFirstColumns() {
        const columns = super.initFirstColumns();
        columns.splice(1, 1); // We remove second (index 1), Granted By, column
        return columns;
    }

    createAllDaysCell() {
        return GrantedAllDaysCell;
    }

    createDayCell(day, classNamePostfix) {
        const className = GrantedDayCell.prototype.className() + ' ' + classNamePostfix;
        return GrantedDayCell.extend({ day, className });
    }

}
