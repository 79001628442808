var _ = require('underscore'),
    Backbone = require('backbone');

var ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin');

var template = require('./EventCompanyForm.hbs');

var EventCompanyForm = Backbone.View.extend({

    mixins: [
        ValidatedForm,
        Form
    ],

    template,

    contactBindings: {
        '[name=firstname]': 'firstname',
        '[name=lastname]': 'lastname',
        '[name=email]': 'email',
        '[name=telephone]': 'telephone'
    },

    options: [
        'model!',
        'companies!',
        'hiddenInputs',
        'disabledInputs'
    ],

    initialize(options) {
        this.setOptions(options);

        // Default Options
        !_.isUndefined(this.hiddenInputs) || (this.hiddenInputs = []);
        !_.isUndefined(this.disabledInputs) || (this.disabledInputs = []);

        this.inputs = new Backbone.ChildViewContainer();
        this.groups = new Backbone.ChildViewContainer();
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.unstickit(this.model.primary_contact);
        this.initForm(this.model, this.companies);
        this.stickit(this.model.primary_contact, this.contactBindings);
        this.renderForm();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                hiddenInputs: this.hiddenInputs,
                placeholderHeight: this.placeholderHeight
            },
            model: this.model.attributes
        };
    },

    initForm(model, companies) {
        this.disableInputs(this.disabledInputs);

        if(this.isAvailable('company')) {
            let companiesSelectize = this.initSingleSelectize('company', { collection: companies });
            this.listenTo(companiesSelectize, 'selected', this.onCompanySelected);
            this.inputs.add(companiesSelectize, 'company');
        }
        if(this.isAvailable('firstname')) this.groups.add(this.initValidationGroup('firstname', model.primary_contact), 'firstname');
        if(this.isAvailable('lastname')) this.groups.add(this.initValidationGroup('lastname', model.primary_contact), 'lastname');
        if(this.isAvailable('email')) this.groups.add(this.initValidationGroup('email', model.primary_contact), 'email');
        if(this.isAvailable('telephone')) this.groups.add(this.initValidationGroup('telephone', model.primary_contact), 'telephone');
    },

    renderForm() {
        this.inputs.call('render');
        this.groups.call('render');
    },

    onCompanySelected(company) {
        if(company) {
            this.model.set({
                id: company.get('id'),
                name: company.get('name')
            });
            this.model.primary_contact.set({
                id: company.primary_contact.get('id'),
                firstname: company.primary_contact.get('firstname'),
                lastname: company.primary_contact.get('lastname'),
                fullname: company.primary_contact.get('fullname'),
                email: company.primary_contact.get('email'),
                telephone: company.primary_contact.get('telephone')
            });
        }
    }
});

module.exports = EventCompanyForm;
