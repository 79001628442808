export const SET_STATE = 'SET_STATE';
export const MERGE_STATE = 'MERGE_STATE';
export const SET_STATE_VALUE = 'SET_STATE_VALUE';
export const MERGE_STATE_VALUE = 'MERGE_STATE_VALUE';


export const setState = (stateToSet) => ({
    type: SET_STATE,
    stateToSet,
});

export const mergeState = (stateToMerge) => ({
    type: MERGE_STATE,
    stateToMerge,
});

export const setStateValue = (path, valueToSet) => ({
    type: SET_STATE_VALUE,
    path,
    valueToSet
});

export const mergeStateValue = (path, valueToMerge) => ({
    type: MERGE_STATE_VALUE,
    path,
    valueToMerge,
});

