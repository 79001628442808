import React, { Fragment } from 'react'
import {InputGroup} from 'ui-kit'
import {
    DESCRIPTION_HEIGHT,
    DESCRIPTION_LABEL,
    DESCRIPTION_PROP_NAME,
} from 'app/react/views/event/production_website/artists/artist_types/booking_form/sections/views/forms/constants'
import TextEditor from 'app/react/views/event/production_website/artists/ProductionWebsiteSettingsTextEditor'

const REQUIRE_TELEPHONE_PROP_NAME = 'telephoneRequired';
const REQUIRE_TELEPHONE_LABEL = 'Require a telephone number for contacts';

export const ContactsForm = ({
    values,
    errors,
    onChangeValue,
}) => (
    <Fragment>
        <InputGroup messages={errors[DESCRIPTION_PROP_NAME]}>
            <label>{DESCRIPTION_LABEL}</label>
            <TextEditor
                imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                height={DESCRIPTION_HEIGHT}
                value={values[DESCRIPTION_PROP_NAME] || ''}
                onBlur={(e) => onChangeValue(DESCRIPTION_PROP_NAME, e.target.value)}
            />
        </InputGroup>
        <fieldset>
            <legend>Optional fields</legend>
            <InputGroup messages={errors[REQUIRE_TELEPHONE_PROP_NAME]}>
                <label>
                    <input
                        type="checkbox"
                        style={{ marginRight: '1rem' }}
                        checked={typeof values[REQUIRE_TELEPHONE_PROP_NAME] !== 'undefined' ?
                            values[REQUIRE_TELEPHONE_PROP_NAME] :
                            false
                        }
                        onChange={e => onChangeValue(REQUIRE_TELEPHONE_PROP_NAME, e.target.checked)}
                    />
                    {REQUIRE_TELEPHONE_LABEL}
                </label>
            </InputGroup>
        </fieldset>
    </Fragment>
);

ContactsForm.defaultProps = {
    errors: {},
    onChangeValue: () => {},
};
