import React from 'react'
import { Footer } from 'ui-kit'

const showIntercom = () => Intercom('show');

const Content = () => {
    const year = new Date().getFullYear();

    return (
        <Footer
            renderCopyrightText={() => `Copyright © ${year} Beatswitch. All rights reserved.`}
            renderMenu={() => (
                <ul>
                    <li>
                        <a href="https://beatswitch.com">Beatswitch.com</a>
                    </li>
                    <li>
                        <a onClick={() => showIntercom()}>Support</a>
                    </li>
                    <li>
                        <a href="https://beatswitch.com/terms">Terms</a>
                    </li>
                    <li>
                        <a href="https://beatswitch.com/privacy">Privacy</a>
                    </li>
                    <li>
                        <a href="https://twitter.com/beatswitch" target="_blank" rel="nofollow">Twitter</a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/beatswitch" target="_blank" rel="nofollow">Facebook</a>
                    </li>
                </ul>
            )}
        />
    )
}

export default Content;
