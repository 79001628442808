import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Bookings from 'app/react/entities/events/bookings/helpers';
import { fetchAll } from './actions';

export const fetchAllForCurrentContext = (bookingId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const finalBookingId = typeof bookingId !== 'undefined' ? bookingId : Bookings.getCurrentId(state);
    return dispatch(fetchAll(accountId, eventId, finalBookingId));
};

