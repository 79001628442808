import Tooltip from 'tether-tooltip';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import { DEFAULT_OPEN_DELAY } from 'app/backbone/components/tooltips/constants';
import BaseView from 'app/backbone/components/Base.view';
import template from './CellAction.hbs';

export default class CellAction extends BaseView {

    get template() {
        return template;
    }

    tagName() {
        return 'a';
    }

    className() {
        return 'action';
    }

    events() {
        return {
            'click': 'onClick'
        };
    }

    optionTypes() {
        return {
            model: OptionTypes.object.isRequired,
            icon: OptionTypes.string.isRequired,
            tooltip: OptionTypes.string.isRequired,
            extraClassName: OptionTypes.string,
            canRender: OptionTypes.func,
            isHidden: OptionTypes.func,
            isDisable: OptionTypes.func,
            action: OptionTypes.func,
            event: OptionTypes.objectWithShape({
                name: OptionTypes.string,
                type: OptionTypes.oneOf([ 'model', 'collection' ])
            })
        };
    }

    optionDefaults() {
        return {
            canRender: () => true,
            isHidden: () => false,
            isDisable: () => false
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.hidden = this.isHidden(this.model);
        this.disabled = this.isDisable(this.model);
        // Subviews
        this.tooltip = this.initTooltip();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();

        this.rendered = true;

        this.addExtraClassName();
        this.toggleHidden(this.hidden);
        this.toggleDisabled(this.disabled);

        return this.delegateEvents();
    }

    remove() {
        this.removeTooltip();
        super.remove();
    }

    viewModel() {
        return {
            view: {
                icon: this.icon,
                hidden: this.hidden,
                disabled: this.disabled
            }
        };
    }

    addExtraClassName() {
        if (this.rendered) this.$el.addClass(this.extraClassName);
    }

    initTooltip() {
        const content = this.tooltip;
        const target = this.el;
        const openDelay = DEFAULT_OPEN_DELAY;
        return new Tooltip({ target, content, openDelay });
    }

    toggleHidden(hidden) {
        if (this.rendered) this.$el.css('display', hidden ? 'none' : '');
    }

    toggleDisabled(disabled) {
        if (this.rendered) this.$el.toggleClass('disabled', disabled);
    }

    show() {
        this.hidden = false;
        this.toggleHidden(this.hidden);
    }

    hide() {
        this.hidden = true;
        this.toggleHidden(this.hidden);
    }

    enable() {
        this.disabled = false;
        this.toggleDisabled(this.disabled);
    }

    disable() {
        this.disabled = true;
        this.toggleDisabled(this.disabled);
    }

    onClick(e) {
        e.stopPropagation();
        const newTab = e.ctrlKey || e.metaKey;
        if (this.action) {
            this.action(this.model, newTab);
        }
        if (this.event) {
            if (this.event.type === 'model') {
                this.model.trigger(this.event.name, this.model, newTab);
            }
            if (this.event.type === 'collection') {
                this.model.collection.trigger(this.event.name, this.model, newTab);
            }
        }
    }

}
