import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Field, Formik, Form } from 'formik';
import Footer from '../footer/Footer';
import { useEventState } from '../../hooks/UseEventState';
import FeedbackMessage from '../atoms/feedback/Feedback';

export const RegionDateFormSchema = () =>
	yup.object({
		currency: yup.string().required(),
		unit_of_distance: yup.string().required(),
		date_format: yup.string().required(),
		time_format: yup.string().required(),
		timezone: yup.string().required(),
	});

export const RegionDateForm = ({
	setActiveStep,
	activeSubStep,
	eventState,
}) => {
	const { regionData } = useEventState();

	return (
		<div className='form_content'>
			<h2>Choose your region, date & time formats</h2>
			<Formik
				initialValues={{
					currency: eventState.event.currency ? eventState.event.currency : '',
					unit_of_distance: eventState.event.unit_of_distance
						? eventState.event.unit_of_distance
						: '',
					date_format: eventState.event.date_format
						? eventState.event.date_format
						: '',
					time_format: eventState.event.time_format
						? eventState.event.time_format
						: '',
					timezone: eventState.event.timezone ? eventState.event.timezone : '',
				}}
				validationSchema={RegionDateFormSchema()}
				validateOnMount
				enableReinitialize
			>
				{(formik) => {
					const { isValid, values } = formik;

					return (
						<Form>
							<div className='region-date-dialog__sub-container'>
								<h3 className='form-sub-title'>Region formats</h3>

								{
									beatswitch.auth.account.currency != values.currency && 
									(<FeedbackMessage modBottomMargin={true} style={{['margin-top']: "10px"}} variant='danger' text="This event will use a different currency than the one in your account settings. 
									Account items will be imported to this event using this new currency and prices are converted 1:1, 
									not by actual monetary value!" />)
								}

								<div className='dialog__input-line'>
									<label
										htmlFor='currency'
										className='select-label'
										style={{ width: '350px' }}
									>
										<span className='select-label__span'>Currency*</span>
										<Field as='select' id='currency' name='currency'>
											<option value=''>Select your preferred currency</option>
											{regionData.currencies.map(({ label, value }, i) => (
												<option key={`currency-${i}-${value}`} value={value}>
													{label}
												</option>
											))}
										</Field>


										
									</label>
									<label
										htmlFor='unit_of_distance'
										className='select-label'
										style={{ width: '350px' }}
									>
										<span className='select-label__span'>Distance unit*</span>
										<Field
											as='select'
											id='unit_of_distance'
											name='unit_of_distance'
										>
											<option value=''>Select your distance format</option>
											{regionData.distances.map(({ label, value }, i) => (
												<option key={`distance-${i}-${value}`} value={value}>
													{label}
												</option>
											))}
										</Field>
									</label>
								</div>
							</div>
							<div className='region-date-dialog__sub-container'>
								<h3 className='form-sub-title'>Date & Time</h3>
								<div className='dialog__input-line'>
									<label
										htmlFor='date_format'
										className='select-label'
										style={{ width: '350px' }}
									>
										<span className='select-label__span'>Date format*</span>
										<Field as='select' id='date_format' name='date_format'>
											<option value=''>Select your date format</option>
											{regionData.dateFormats.map(({ label, value }, i) => (
												<option key={`date-${i}-${value}`} value={value}>
													{label}
												</option>
											))}
										</Field>
									</label>
									<label
										htmlFor='time_format'
										className='select-label'
										style={{ width: '350px' }}
									>
										<span className='select-label__span'>Time format*</span>
										<Field as='select' id='time_format' name='time_format'>
											<option value=''>Select your time format</option>
											{regionData.timeFormats.map(({ label, value }, i) => (
												<option key={`time-${i}-${value}`} value={value}>
													{label}
												</option>
											))}
										</Field>
									</label>
								</div>
								<div className='dialog__input-line'>
									<label
										htmlFor='timezone'
										className='select-label'
										style={{ width: '350px' }}
									>
										<span className='select-label__span'>Time zone*</span>
										<Field as='select' id='timezone' name='timezone'>
											<option value=''>Select your timezone</option>
											{eventState.timeZones.map(({ id, name }) => (
												<option key={`timezone-${id}`} value={id}>
													{name}
												</option>
											))}
										</Field>
									</label>
								</div>
							</div>
							<Footer
								setActiveStep={(step) => setActiveStep(step, values)}
								activeSubStep={activeSubStep}
								isNextDisabled={!isValid}
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

RegionDateForm.propTypes = {
	setActiveStep: PropTypes.func,
	activeSubstep: PropTypes.number,
};

export default RegionDateForm;
