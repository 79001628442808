import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'app/react/components/_index';
import BookingStatusLabel from 'app/react/views/event/artists/bookings/booking_statuses/components/BookingStatusLabel';

const VIEW_DETAILS_TOOLTIP = 'Go to booking (Opens in new tab)';
const UPDATE_TOOLTIP = 'Update booking';
const REMOVE_FROM_PERFORMANCE_TOOLTIP = 'Remove booking from performance';
const DELETE_TOOLTIP = 'Delete booking';

const BookingRow = ({
    isArtistTypesEnabled = true,
    booking = {},
    onDetails = () => {},
    onUpdate = () => {},
    onRemoveFromPerformance = () => {},
    onDelete = () => {}
}) => (
    <tr>
        <td>
            {booking.artist && booking.artist.name}
        </td>
        {isArtistTypesEnabled &&
            <td>{booking.artistType.name}</td>
        }
        <td>
            {booking.type.name}
        </td>
        <td className="input">
            <BookingStatusLabel
                title={booking.status.title}
                type={booking.status.colorType}
            />
        </td>
        <td className="actions">
            <Tooltip content={VIEW_DETAILS_TOOLTIP}>
                <a className="action" onClick={() => onDetails(booking.id)}>
                    <i className="bs-icon-magnifier" />
                </a>
            </Tooltip>
            <Tooltip content={UPDATE_TOOLTIP}>
                <a className="action" onClick={() => onUpdate(booking.id)}>
                    <i className="bs-icon-pencil" />
                </a>
            </Tooltip>
            <Tooltip content={REMOVE_FROM_PERFORMANCE_TOOLTIP}>
                <a className="action" onClick={() => onRemoveFromPerformance(booking.id)}>
                    <i className="bs-icon-cross" />
                </a>
            </Tooltip>
            <Tooltip content={DELETE_TOOLTIP}>
                <a className="action" onClick={() => onDelete(booking.id)}>
                    <i className="bs-icon-bin" />
                </a>
            </Tooltip>
        </td>
    </tr>
);

BookingRow.propTypes = {
    isArtistTypesEnabled: PropTypes.bool,
    booking: PropTypes.object,
    onDetails: PropTypes.func,
    onUpdate: PropTypes.func,
    onRemoveFromPerformance: PropTypes.func,
    onDelete: PropTypes.func
};

export default BookingRow;
