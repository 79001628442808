import React from 'react';
import PropTypes from 'prop-types';
import { Card, SchedulerTopBar } from 'ui-kit';
import { Loading } from 'app/react/components/_index';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import PickupModals from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/ModalsWrapperContainer';
import LogisticsContent from '../../../components/layout/ContentContainer';
import PickupsContentHeader from '../../components/layout/ContentHeaderContainer';
import Scheduler from '../../components/scheduler/Scheduler';

const { Header } = Card;
const { Tabs } = Header;
const { Tab } = Tabs;

const PickupsScheduler = ({
    store,
    timeFormat,
    dateFormat,

    isFetching = false,
    eventDays = [],
    drivers = [],
    pickups = [],
    isFetchingPickups = true,

    selectedDay = {},
    activeInterval,

    onChangeDay = () => {},
    onChangeInterval = () => {},

    onDropPickup = () => {},
    onResizePickup = () => {},
    onSelectPeriod = () => {},

    onUpdatePickup = () => {},
    onDeletePickup = () => {},
    onCreatePickupSuccess = () => {},
}) => (
    <ContentWrapper store={store}>
        <PickupsContentHeader
            activeTab="scheduler"
        />
        <LogisticsContent isLoading={isFetching}>
            <Card
                isFull
                className="no-margin"
            >
                <Header hasTabs className="card--header--filling-tabs">
                    <Tabs>
                        {eventDays.map(day => (
                            <Tab
                                key={day.id}
                                isActive={day.id === selectedDay.id}
                                onClick={() => onChangeDay(day)}
                            >
                                {day.name}
                            </Tab>
                        ))}
                    </Tabs>
                </Header>
                <Card.Content isFlexContainer>
                    <SchedulerTopBar
                        activeInterval={activeInterval}
                        onChangeInterval={onChangeInterval}
                    />
                    {isFetchingPickups
                        ? <Loading height="300px" />
                        : <Scheduler
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            dayId={selectedDay.id}
                            start={selectedDay.start}
                            end={selectedDay.end}
                            activeInterval={activeInterval}
                            drivers={drivers}
                            pickups={pickups}
                            onDropPickup={onDropPickup}
                            onResizePickup={onResizePickup}
                            onSelectPeriod={onSelectPeriod}
                            onUpdatePickup={onUpdatePickup}
                            onDeletePickup={onDeletePickup}
                        />
                    }
                </Card.Content>
            </Card>
        </LogisticsContent>
        <PickupModals
            store={store}
            onCreateSuccess={onCreatePickupSuccess}
        />
    </ContentWrapper>
);

PickupsScheduler.propTypes = {
    store: PropTypes.object,
    userCanMangeAccommodations: PropTypes.bool,
    timeFormat: PropTypes.string,
    dateFormat: PropTypes.string,
    isFetching: PropTypes.bool,
    reservations: PropTypes.array,
    days: PropTypes.array,
    accommodations: PropTypes.array,
    roomTypes: PropTypes.array,
    reservationsPagination: PropTypes.object,
    onChangePageSize: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeSearchValue: PropTypes.func,
    onSearch: PropTypes.func,
    onClearSearch: PropTypes.func,
    onSort: PropTypes.func,
    onChangePage: PropTypes.func,
    onToggleReservationCheckIn: PropTypes.func,
    onEditReservation: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
};

export default PickupsScheduler;
