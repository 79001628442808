import { schema } from 'normalizr';
import * as AccountItemGroup from 'app/react/entities/accounts/item-groups/schemas';

export const ENTITY_REDUCER_KEY = 'eventArtistItemGroups';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const processStrategy = AccountItemGroup.processStrategy;

const entityDefinition = {};
const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);

export const toAPI = AccountItemGroup.toAPI;
