import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import DeleteVehicleModalContainer from './DeleteModalContainer';
import { VEHICLES_PAGINATED_TABLE_QUERY } from '../../table/PaginatedTableModalWrapperQueryContainer';
import * as State from './DeleteModalState';

const DELETE_VEHICLE_MUTATION = gql`
    mutation DeleteVehicle($vehicleId: ID!) {
        deleteVehicle(id: $vehicleId) {
            id
        }
    }
`;

const injectMutation = graphql(
    DELETE_VEHICLE_MUTATION, {
        name: 'deleteVehicleMutation',
        options: ({ eventId, first, after, sort }) => ({
            refetchQueries: [{
                query: VEHICLES_PAGINATED_TABLE_QUERY,
                variables: { eventId, first, after, sort },
            }],
        }),
    }
);

const mapDispatchToProps = (dispatch, ownProps) => ({
    onConfirmed: (vehicle) => {
        const { deleteVehicleMutation, onDeleteSuccess } = ownProps;

        const vehicleId = vehicle.id;

        dispatch(State.setStateValue('isSyncing', true));

        deleteVehicleMutation({
            variables: { vehicleId }
        }).then(() => {
            if (typeof onDeleteSuccess === 'function') {
                onDeleteSuccess(vehicle.id);
            }

            dispatch(State.showSuccessPopup(vehicle.make, vehicle.model));
            dispatch(State.close());
        }).catch(() => {
            dispatch(State.showErrorPopup());
            dispatch(State.close());
        });
    }
});

const injectState = connect(
    null,
    mapDispatchToProps,
);

export default compose(
    injectMutation,
    injectState,
)(DeleteVehicleModalContainer)
