import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Event from 'app/react/entities/events/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Reservation from 'app/react/entities/events/reservations/index';
import * as Accommodations from 'app/react/entities/events/accommodations/index';
import Overview from 'app/react/views/event/artists/reservations/views/overview/OverviewContainer';
import Dashboard from 'app/react/views/event/artists/reservations/views/dashboard/DashboardContainer';
import * as Routes from './routes';

export default class ReservationsController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        router.route(Routes.overview(), null, this.overview.bind(this));
        router.route(Routes.dashboard(), null, this.dashboard.bind(this));
    }

    overview(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(Overview, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(Accommodations.thunks.fetchAllForCurrentContext(['room_types', 'address'])),
            dispatch(Reservation.thunks.fetchAllByPaginationForCurrentContext({}, ['status', 'booking.artist', 'guests'])),
        ]);
    }

    dashboard(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(Dashboard, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(Accommodations.thunks.fetchAllByPaginationForCurrentContext({}, [
                'room_types', 'reservations.room_type',
            ])),
        ]);
    }

}
