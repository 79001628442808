import GrantedDayCell from './GrantedDayCell.view';

export default class GrantedAllDaysCell extends GrantedDayCell {

    attributes() {
        return {
            style: 'width:55px'
        };
    }

    isDisabled() {
        const grantableDays = this.getGrantableDays();
        return !grantableDays.length || !this.model.get('granted_by_promoter');
    }

    isChecked() {
        const grantableDays = this.getGrantableDays();
        if (!grantableDays.length) return false;
        return this.model.hasGrantedDayOnEvery(grantableDays);
    }

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        const grantableDays = this.getGrantableDays();
        if (checked) this.model.addGrantedDayOnAll(grantableDays);
        else this.model.removeAllGrantedDays();
        this.render();
    }

    getGrantableDays() {
        return this.model.getRelation('days');
    }

}

