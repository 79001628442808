import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Items from 'app/react/entities/accounts/items/index';
import * as ItemGroups from 'app/react/entities/accounts/item-groups/index';
import ArtistAccountItemsOverview from 'app/react/views/account/settings/artists/items/views/OverviewContainer';
import * as Routes from './routes'

export default class ArtistsSettingsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.artistsItems(), null, this.items.bind(this));
    }

    items() {
        const { app, store, dispatch } = this;
        // Set the working context
        // Account ID is set from DOM in the initial state
        dispatch(Items.thunks.fetchAllForCurrentContext());
        dispatch(ItemGroups.thunks.fetchAllForCurrentContext());

        app.renderContentComponent(React.createElement(ArtistAccountItemsOverview, { store }));
    }

}
