import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

class ArtistItemGroup extends BaseModel {

    static Relations() {
        return {
            event: require('../Event').Model // Circular
        };
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
    }

}

class ArtistItemGroupCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('../Event').Model // Circular
        }
    }

    url() {
        const { event } = this.relations;
        return `/api/events/${event.id}/days`;
    }

    domKey() {
        return 'artist_items_groups'
    }
}

ArtistItemGroupCollection.Model = ArtistItemGroupCollection;

export {
    ArtistItemGroup as Model,
    ArtistItemGroupCollection as Collection,
};
