var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.SyncingPlaceholder,depth0,{"name":"SyncingPlaceholder","hash":{"text":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.syncingText : stack1),"height":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.placeholderHeight : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"grid grid--1of2\">\n        <div id=\"contact-card\" class=\"grid-cell card\"></div>\n    </div>\n    <div class=\"grid\">\n        <div id=\"profiles-card\" class=\"grid-cell card\"></div>\n    </div>\n    <div class=\"grid\">\n        <div id=\"items-card\" class=\"grid-cell card\"></div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isUpdating : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.UpdateButtonRow,depth0,{"name":"UpdateButtonRow","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.CreateButtonRow,depth0,{"name":"CreateButtonRow","hash":{"createAnother":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.canCreateAnother : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isSyncing : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});