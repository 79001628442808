import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import by from 'thenby';
import { Grid } from  'ui-kit';
import { TableFilter } from 'app/react/components/_index';

const { Cell, CellGrid } = Grid;

const ALL_DAY_OPTION = { value: 0, label: 'All Days' };
const ALL_GROUPS_OPTION = { value: 0, label: 'All Groups' };

const toOption = ({
    id,
    name
}) => ({
    value: id,
    label: name
});

const toDayOption = (dateFormat) => ({
    id,
    name,
    start
}) => ({
    value: id,
    label: `${name} ${moment.utc(start, moment.ISO_8601).format(dateFormat)}`
});

const byStartDate = by('start', 1);

const onlyParents =
    group => group.parent === null; // not undefined (see group schema)

const ReservationsTableTopBar = ({
    dateFormatShort,
    days = [],
    groups = [],

    selectedDayId = 0,
    selectedGroupId = 0,

    onChangeFilter = () => {},
}) => (
    <Grid hasGutters={false} className="margin-tiny-bottom">
        <Cell isContentJustifiedAtStart>
            <TableFilter
                width="250px"
                options={[
                    ALL_DAY_OPTION,
                    ...days
                        .sort(byStartDate)
                        .map(toDayOption(dateFormatShort)),
                ]}
                value={selectedDayId}
                onChange={value => onChangeFilter('day', parseInt(value, 10))}
            />
            <TableFilter
                width="175px"
                options={[
                    ALL_GROUPS_OPTION,
                    ...groups
                        .filter(onlyParents)
                        .map(toOption),
                ]}
                value={selectedGroupId}
                onChange={value => onChangeFilter('group', parseInt(value, 10))}
            />
        </Cell>
    </Grid>
);

ReservationsTableTopBar.propTypes = {
    booking: PropTypes.object,
    onEdit: PropTypes.func
};

export default ReservationsTableTopBar;
