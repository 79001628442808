import { connect } from 'react-redux';
import { mapAccountContextToProps, mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as actions from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Driver from 'app/react/entities/events/drivers/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups/index';
import * as CreateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/CreateModalState';
import * as UpdateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/UpdateModalState';
import * as DeleteModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/DeleteModalState';
import PickupsOverview from './Overview';
import * as State from './OverviewState';

const PICKUP_INCLUDES = ['booking.artist', 'passengers', 'vehicle'];
const FAILED_POPUP = (name, error) => `Failed to mark <strong>${name}</strong> as arrived. ${error}`;
const SUCCESSFUL_POPUP = (name) => `<strong>${name}</strong> marked as arrived.`;

const mapStateToProps = (state) => {
    const { manageLogistics, downloadLogisticsReport } = Event.stateHelpers.getUserPermissions(state);
    const pickupsPagination = Pickup.stateHelpers.getPaginationForCurrentContext(state);
    const { requestedFilters = {} } = pickupsPagination;
    const pickups = Pickup.stateHelpers.getAllByPaginationForCurrentContext(state, true);
    let filteredPickups = pickups;
    if (requestedFilters.arrived === true) {
        filteredPickups = pickups.filter(pickup => pickup.isArrived)
    } else if (requestedFilters.arrived === false) {
        filteredPickups = pickups.filter(pickup => !pickup.isArrived)
    }
    return {
        ...mapAccountContextToProps(state),
        ...mapEventContextToProps(state),
        isFetching: (
            Driver.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Day.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        userCanManageLogistics: manageLogistics,
        userCanDownloadReport: downloadLogisticsReport,
        days: Day.stateHelpers.getAllForCurrentContext(state),
        drivers: Driver.stateHelpers.getAllForCurrentContext(state),
        pickups: filteredPickups,
        pickupsPagination
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onDownloadReport: () => {
        const { eventId } = stateProps;
        dispatchProps.onDownloadReport(eventId);
    },
    onChangeFilter: (key, value) => {
        const { requestedFilters } = stateProps.pickupsPagination;
        dispatchProps.onChangeFilter(key, value, requestedFilters)
    },
    onTogglePickupIsArrived: (pickupId, isArrived) => {
        const { pickups } = stateProps;
        const { booking } = pickups.find(({ id }) => id === pickupId);
        dispatchProps.onTogglePickupIsArrived(pickupId, isArrived, booking.artist.name);
    },
    onEditPickup: (pickupId, newTab) => {
        const { pickups, eventId } = stateProps;
        const { booking } = pickups.find(({ id }) => id === pickupId);
        dispatchProps.onEditPickup(eventId, booking.id, pickupId, newTab);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onGoToBooking: (pickupId, newTab) => {
        dispatch(State.thunks.goToBooking(pickupId, newTab))
    },
    onOpenCreateModal: () => {
        dispatch(CreateModalState.thunks.open());
    },
    onOpenUpdateModal: (pickupId) => {
        dispatch(UpdateModalState.thunks.open(pickupId));
    },
    onOpenDeleteModal: (pickupId) => {
        dispatch(DeleteModalState.thunks.open(pickupId));
    },
    onCreatePickupSuccess: () => {
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({}, PICKUP_INCLUDES));
    },
    onChangePageSize: (pageSize) => {
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({ pageSize }, PICKUP_INCLUDES));
    },
    onClearAllFilters: () => {
        const filters = {};
        const search = '';
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({ filters, search }, PICKUP_INCLUDES));
    },
    onChangeFilter: (key, value, currentFilters = {}) => {
        const filters = { ...currentFilters, [key]: value };
        if (value === 0 || value === '0') {
            delete filters[key];
        }
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({ filters }, PICKUP_INCLUDES));
    },
    onSearch: (search) => {
        // We clear filters when searching
        const filters = {};
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({ search, filters }, PICKUP_INCLUDES));
    },
    onClearSearch: () => {
        const search = '';
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({ search }, PICKUP_INCLUDES));
    },
    onSort: (sortKey, sortOrder) => {
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({ sortKey, sortOrder }, PICKUP_INCLUDES));
    },
    onChangePage: (page) => {
        dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({ page }, PICKUP_INCLUDES));
    },
    onTogglePickupIsArrived: (pickupId, isArrived, artistName) => {
        dispatch(Pickup.thunks.toggleIsArrivedForCurrentContext(pickupId, isArrived))
            .then(({ isFailure, errorMessage }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(FAILED_POPUP(artistName, errorMessage)));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP(artistName)));
                }
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PickupsOverview);
