var $ = require('jquery'),
    Backbone = require('backbone'),
    _ = require('underscore'),
    Selectize = require('selectize');

var SingleSelectizeConfig = require('../inputs/SingleSelectize.config');

module.exports = Backbone.View.extend({

    options: [
        'model',
        'modelAttr',
        'collection',
        'deleteDisabled',
        'initialValue',
        'attrKey',
        {'create': false},
        {'createPrefix': ':create:'}
    ],

    initialize(options) {
        var self = this;

        this.setOptions(options);

        var config = SingleSelectizeConfig.call(this);
        this.selectize = this.$el.selectize(config)[0].selectize;

        if (this.deleteDisabled) {
            this.selectize.on('blur', function() {
                self.selectFirstOption();
            });
        }

        this.bindModel();
        this.bindCollection();
    },

    destroy() {
        if (this.selectize) this.selectize.destroy();
        this._super();
    },

    render() {
        this.fillOptions();
    },

    renderOption(option, escape) {
        return '<div class="option">' + escape(option.name) + '</div>'
    },

    renderCreateOption(option, escape) {
        return '<div class="option create">Create <strong>' + escape(option.input) + '</strong></div>';
    },

    bindModel() {
        if(this.model) {
            this.listenTo(this.model, 'change:' + this.modelAttr, function(model, value) {
                value = _.isObject(value) ? this.createPrefix + value.name : value;
                // Only update value if it is different from the selected value
                if(this.selectize.getValue() != value) {
                    this.selectize.setValue(value, true); // silently set value to avoid infinite loop
                }
            });
        }
    },

    bindCollection() {
        if(this.collection) {
            this.listenTo(this.collection, 'reset', this.resetOptions);
            this.listenTo(this.collection, 'add', this.addAndSelectOption);
            this.listenTo(this.collection, 'remove', this.removeOption);
        }
    },

    unbindModel() {
        if(this.model) {
            this.stopListening(this.model);
        }
    },

    unbindCollection() {
        if(this.collection) {
            this.stopListening(this.collection);
        }
    },

    dropdownOpen() {
        var $dropdown = this.$el.siblings('.selectize-control').find('.selectize-dropdown');
        $dropdown.mouseover(function() {
            document.body.style.overflow = 'hidden';
        });
        $dropdown.mouseout(function() {
            document.body.style.overflow = 'auto';
        });
    },

    dropdownClose($dropdown) {
        $dropdown.find('.selected').not('.active').removeClass('selected');
        document.body.style.overflow = 'auto';
    },

    optionSelected(id) {
        if(this.collection) {
            this.trigger('selected', this.collection.get(id))
        }
        if(this.model && this.model.get(this.modelAttr) != id) {
            if(id.slice(0, this.createPrefix.length) == this.createPrefix) {
                this.model.set(this.modelAttr, {name: id.replace(this.createPrefix, '')})
            } else {
                var selectedModel = this.collection && this.collection.get(id);
                if(selectedModel) {
                    this.model[this.modelAttr] = selectedModel;
                    this.model.set(this.modelAttr, selectedModel.id);
                    var name = selectedModel.get('name');
                    if(name) this.model.set(this.modelAttr + '_name', name);
                    var title = selectedModel.get('title');
                    if(title) this.model.set(this.modelAttr + '_name', title);
                } else {
                    this.model.set(this.modelAttr, undefined);
                }
            }
        }
    },

    clearOptions() {
        this.selectize.clearOptions();
    },

    fillOptions() {
        if(this.collection && this.collection.length) {
            this.clearOptions();
            this.collection.each(function(model) {
                this.addOption(model);
            }, this);

            this.initialValue = this.model && this.model.get(this.modelAttr) || this.initialValue || this.collection.first().id;
            this.selectize.setValue(this.initialValue);
        }
    },

    setCollection(collection) {
        this.collection = collection;
        this.bindCollection();
        this.render();
        this.selectize.setValue(this.collection.first().id);
    },

    resetOptions() {
        this.clearOptions();
        this.fillOptions();
    },

    addOption(model) {
        var option = {
            id: model.id,
            name: model.get(this.attrKey) || model.get('name') || model.get('title') || model.get('firstname') + ' ' + model.get('lastname')
        };
        this.selectize.addOption(option);
    },

    selectOption(model) {
        this.selectize.setValue(model.id);
    },

    selectFirstOptionIfEmpty() {
        if (this.selectize.getValue() == '') {
            this.selectize.setValue(this.initialValue);
        }
    },

    getSelectedValue() {
       return this.selectize.getValue();
    },

    getSelectedModel() {
        if(this.collection) return this.collection.get(this.getSelectedValue);
    },

    addAndSelectOption(model) {
        this.addOption(model);
        this.selectOption(model);
    },

    removeOption(model) {
        this.selectize.removeOption(model.id);
    },

    createOption(input, callback) {
        var foundOption = _.filter(this.selectize.options, function(option) {
            return option.name.toLowerCase() == input.toLowerCase();
        });
        if(foundOption.length) {
            callback();
            this.selectize.setValue(foundOption[0].id)
        } else {
            callback({
                id: this.createPrefix + input,
                name: input
            });
        }
    }
});
