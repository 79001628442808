import moment from 'moment';
import * as EventRoutes from 'app/react/entities/events/locations/routes/index';

export const getEventRouteFormValues = (state, id, departure) => {
    const { distance, duration, meetingPoint } = EventRoutes.stateHelpers.getOne(state, id);
    let arrival;
    if (typeof duration !== 'undefined' && typeof departure !== 'undefined') {
        const mDeparture = moment.utc(departure, moment.ISO_8601);
        mDeparture.add(duration, 'minutes');
        arrival = mDeparture.toISOString();
    }
    return {
        distance,
        meetingPoint,
        duration,
        arrival
    }
};
