import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const CELL_TEXT = 'Set Time';

const SetTimeRow = ({
    start,
    end,
    dateFormat,
    timeFormat
}) => {
    const mStart = moment.utc(start);
    const mEnd = moment.utc(end);
    return (
        <tr>
            <td>
                {CELL_TEXT}
            </td>
            <td>
                {mStart.format(dateFormat)}
            </td>
            <td>
                {mStart.format(timeFormat)}
            </td>
            <td>
                {mEnd.format(dateFormat)}
            </td>
            <td>
                {mEnd.format(timeFormat)}
            </td>
            <td />
        </tr>
    );
};

SetTimeRow.propTypes = {
    start: PropTypes.string,
    end: PropTypes.string,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string
};

export default SetTimeRow;
