import moment from 'moment';
import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import {
    showErrorPopup,
    showSuccessPopup
} from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/index';
import * as Performance from 'app/react/entities/events/performances/index';
import * as PerformanceTimeSlot from 'app/react/entities/events/performances/time_slots/index';
import * as PerformanceTimeSlotType from 'app/react/entities/time_slot_types/index';
import {
    getModalState,
    setModalState,
    deleteModalState,
    mergeDeleteConfirmModalState,
    openDeleteConfirmModal
} from '../ModalState';
import TimeSlotTable from './Table';

const DELETE_TITLE = 'Delete time slot';
const DELETE_TEXT = 'Are you sure you want to delete this time slot?';
const DELETE_BUTTON_TEXT = 'Yes, delete time slot.';

const POPUP_MESSAGES = {
    FAILED_TO_CREATE: 'Failed to create time slot.',
    SUCCESSFUL_CREATE: 'Time slot successfully created.',
    FAILED_TO_UPDATE: 'Failed to update time slot.',
    SUCCESSFUL_UPDATE: 'Time slot successfully updated.',
    FAILED_TO_DELETE: 'Failed to delete time slot.',
    SUCCESSFUL_DELETE: 'Time slot successfully deleted.'
};

const mapStateToProps = (state) => {
    const {
        selectedPerformanceId,
        newTimeSlot,
        editingTimeSlots,
        isCreatingOtherTimeSlot
    } = getModalState(state);
    const performance = Performance.stateHelpers.getOne(state, selectedPerformanceId) || {};
    return {
        eventId: Event.stateHelpers.getCurrentId(state),
        performanceId: selectedPerformanceId,
        dateFormat: Event.stateHelpers.getCurrentDateFormat(state),
        timeFormat: Event.stateHelpers.getCurrentTimeFormat(state),
        timeSlots: PerformanceTimeSlot.stateHelpers.getSomeDenormalized(state, performance.timeSlots),
        timeSlotTypes: PerformanceTimeSlotType.stateHelpers.getAll(state),
        newTimeSlot,
        isCreatingOtherTimeSlot,
        editingTimeSlots,
        performance
    };
};

const mergeDateWithTime = (dateISOString, timeISOString) => {
    const mDate = moment.utc(dateISOString, moment.ISO_8601);
    const dateFormat = 'YYYY-MM-DD';
    const datePart = mDate.format(dateFormat);
    const mTime = moment.utc(timeISOString, moment.ISO_8601);
    const timeFormat = 'HH:mm:ss[Z]';
    const timePart = mTime.format(timeFormat);
    return moment.utc(`${datePart}T${timePart}`, `${dateFormat}T${timeFormat}`).toISOString();
};

const getFullStartISOString = (key, value, start) => {
    if (typeof start !== 'undefined' && value !== '') {
        return key === 'startDate' ?
            mergeDateWithTime(value, start) :
            mergeDateWithTime(start, value);
    }
    return value;
};

const getFullEndISOString = (key, value, end) => {
    if (typeof end !== 'undefined' && value !== '') {
        return key === 'endDate' ?
            mergeDateWithTime(value, end) :
            mergeDateWithTime(end, value);
    }
    return value;
};

const reformatStartAndEndIsoStrings = (values) => ({
    ...values,
    start: values.start.replace('.000Z', '+00:00'),
    end: values.end.replace('.000Z', '+00:00')
});

const mapDispatchToProps = dispatch => ({
    onUpdateTimeSlot: (editingTimeSlots = [], timeSlot) => {
        dispatch(setModalState('editingTimeSlots', [...editingTimeSlots, { ...timeSlot }]));
    },
    onSaveTimeSlot: (eventId, performanceId, timeSlotId, index, values) => {
        const apiValues = reformatStartAndEndIsoStrings(values);
        dispatch(setModalState(`editingTimeSlots.${index}.isSaving`, true));
        dispatch(PerformanceTimeSlot.actions.updateOneForPerformance(eventId, performanceId, timeSlotId, apiValues))
            .then(({ error }) => {
                dispatch(setModalState(`editingTimeSlots.${index}.isSaving`, false));
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(setModalState(`editingTimeSlots.${index}.errors`, errors));
                    dispatch(showErrorPopup(POPUP_MESSAGES.FAILED_TO_UPDATE));
                } else {
                    dispatch(deleteModalState(`editingTimeSlots.${index}`));
                    dispatch(showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_UPDATE));
                }
            });
    },
    onCancelTimeSlot: (editingTimeSlots = [], timeSlotId) => {
        dispatch(setModalState('editingTimeSlots', editingTimeSlots.filter(({ id }) => id !== timeSlotId)));
        dispatch(deleteModalState('isCreatingOtherTimeSlot'));
    },
    onDeleteTimeSlot: (eventId, performanceId, timeSlotId) => {
        const failedPopup = POPUP_MESSAGES.FAILED_TO_DELETE;
        const successPopup = POPUP_MESSAGES.SUCCESSFUL_DELETE;
        const action = {
            ...PerformanceTimeSlot.actions.deleteOneForPerformance(eventId, performanceId, timeSlotId),
            successPopup,
            failedPopup
        };
        dispatch(mergeDeleteConfirmModalState({
            title: DELETE_TITLE,
            text: DELETE_TEXT,
            buttonText: DELETE_BUTTON_TEXT
        }));
        dispatch(openDeleteConfirmModal(action));
    },
    onCreateNewTimeSlot: (start, end) => {
        dispatch(setModalState('newTimeSlot', { start, end }));
    },
    onChangeTimeSlotValue: (key, value, index, start, end) => {
        if (key === 'startDate' || key === 'startTime') {
            const startValue = getFullStartISOString(key, value, start);
            dispatch(setModalState(`editingTimeSlots.${index}.start`, startValue));
        } else if (key === 'endDate' || key === 'endTime') {
            const endValue = getFullEndISOString(key, value, end);
            dispatch(setModalState(`editingTimeSlots.${index}.end`, endValue));
        } else {
            dispatch(setModalState(`editingTimeSlots.${index}.${key}`, value));
        }
    },
    onToggleCreateOtherTimeSlot: (isCreating = false) => {
        dispatch(setModalState('isCreatingOtherTimeSlot', isCreating));
    },
    onChangeNewTimeSlotValue: (key, value, start, end) => {
        if (key === 'startDate' || key === 'startTime') {
            const startValue = getFullStartISOString(key, value, start);
            dispatch(setModalState('newTimeSlot.start', startValue));
        } else if (key === 'endDate' || key === 'endTime') {
            const endValue = getFullEndISOString(key, value, end);
            dispatch(setModalState('newTimeSlot.end', endValue));
        } else {
            dispatch(setModalState(`newTimeSlot.${key}`, value));
        }
    },
    onSaveNewTimeSlot: (eventId, performanceId, values) => {
        const apiValues = reformatStartAndEndIsoStrings(values);
        dispatch(setModalState('newTimeSlot.isSaving', true));
        dispatch(PerformanceTimeSlot.actions.createOneForPerformance(eventId, performanceId, apiValues))
            .then(({ error }) => {
                dispatch(setModalState('newTimeSlot.isSaving', false));
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(setModalState('newTimeSlot.errors', errors));
                    dispatch(showErrorPopup(POPUP_MESSAGES.FAILED_TO_CREATE));
                } else {
                    dispatch(deleteModalState('isCreatingOtherTimeSlot'));
                    dispatch(deleteModalState('newTimeSlot'));
                    dispatch(showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_CREATE));
                }
            });
    },
    onCancelNewTimeSlot: () => {
        dispatch(deleteModalState('newTimeSlot'));
        dispatch(deleteModalState('isCreatingOtherTimeSlot'));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateTimeSlot: (timeSlotId) => {
        const { timeSlots, editingTimeSlots } = stateProps;
        const timeSlot = timeSlots.find(({ id }) => id === timeSlotId);
        dispatchProps.onUpdateTimeSlot(editingTimeSlots, timeSlot);
    },
    onSaveTimeSlot: (timeSlotId) => {
        const { eventId, performanceId, editingTimeSlots } = stateProps;
        const timeSlot = editingTimeSlots.find(({ id }) => id === timeSlotId);
        const index = editingTimeSlots.indexOf(timeSlot);
        dispatchProps.onSaveTimeSlot(eventId, performanceId, timeSlotId, index, timeSlot);
    },
    onCancelTimeSlot: (timeSlotId) => {
        const { editingTimeSlots } = stateProps;
        dispatchProps.onCancelTimeSlot(editingTimeSlots, timeSlotId);
    },
    onChangeTimeSlotValue: (key, value, timeSlotId) => {
        const { editingTimeSlots } = stateProps;
        const timeSlot = editingTimeSlots.find(({ id }) => id === timeSlotId);
        const index = editingTimeSlots.indexOf(timeSlot);
        dispatchProps.onChangeTimeSlotValue(key, value, index, timeSlot.start, timeSlot.end);
    },
    onChangeNewTimeSlotValue: (key, value) => {
        const { newTimeSlot } = stateProps;
        dispatchProps.onChangeNewTimeSlotValue(key, value, newTimeSlot.start, newTimeSlot.end);
    },
    onCreateNewTimeSlot: () => {
        const { performance } = stateProps;
        dispatchProps.onCreateNewTimeSlot(performance.start, performance.end);
    },
    onSaveNewTimeSlot: () => {
        const { eventId, performanceId, newTimeSlot } = stateProps;
        dispatchProps.onSaveNewTimeSlot(eventId, performanceId, newTimeSlot);
    },
    onDeleteTimeSlot: (timeSlotId) => {
        const { eventId, performanceId } = stateProps;
        return dispatchProps.onDeleteTimeSlot(eventId, performanceId, timeSlotId);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(TimeSlotTable);
