import React from 'react'
import PropTypes from 'prop-types'
import { GroupedColumnsTable } from 'ui-kit'
import FirstColumnsItemsTable from './columns/FirstColumnsTable'
import DaysColumnsItemsTable from './columns/DaysColumnsTable'
import LastColumnsItemsTable from './columns/LastColumnsTable'

const { ColumnGroup } = GroupedColumnsTable;

const ItemsTable = ({
    dateFormatShort,
    currencySymbol,
    items = [],
    groups = [],
    days = [],
    selectedDayId = 0,
    sortKey,
    sortOrder,
    onSort = () => {},
}) => (
    <GroupedColumnsTable>
        <ColumnGroup minWidth="560px">
            <FirstColumnsItemsTable
                items={items}
                groups={groups}
                selectedDayId={selectedDayId}
                sortKey={sortKey}
                sortOrder={sortOrder}
                onSort={onSort}
            />
        </ColumnGroup>
        {selectedDayId === 0 && (
            <ColumnGroup isScrollable>
                <DaysColumnsItemsTable
                    dateFormatShort={dateFormatShort}
                    items={items}
                    days={days}
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                    onSort={onSort}
                />
            </ColumnGroup>
        )}
        <ColumnGroup minWidth="250px">
            <LastColumnsItemsTable
                currencySymbol={currencySymbol}
                items={items}
                sortKey={sortKey}
                sortOrder={sortOrder}
                onSort={onSort}
            />
        </ColumnGroup>
    </GroupedColumnsTable>
);

ItemsTable.propTypes = {
    dateFormatShort: PropTypes.string,
    currencySymbol: PropTypes.string,
    items: PropTypes.array,
    groups: PropTypes.array,
    days: PropTypes.array,
    selectedDayId: PropTypes.number,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func,
};

export default ItemsTable;
