import CompanyAccrediteeView from './CompanyAccreditee.view';

export default class CompanyAccrediteeCreateView extends CompanyAccrediteeView {

    ui() {
        const { contactCard, profilesCard, itemsCard } = super.ui();
        return {
            contactCard,
            profilesCard,
            itemsCard,
            createAnother: '.create-another'
        };
    }

    events() {
        return {
            'click .create': 'create',
            'click .create-another': 'createAnother',
            'click .back': 'backToOverview'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.isUpdating = false;
        this.syncingText = 'Creating Attendee...';
        const profiles = this.company.getRelation('profiles');
        this.listenTo(profiles, 'change:total_available', (profile, total) => {
            this.canCreateAnother = total > 0;
            if (this.canCreateAnother) this.ui.createAnother.css('visibility', 'visible');
            else this.ui.createAnother.css('visibility', 'hidden');
        });
    }

    render() {
        this.canCreateAnother = this.company.getRelation('profiles').getTotalAvailable() > 0;
        return super.render();
    }

    create(e, another = false) {
        if (this.model.isValid(true)) {
            this.isCreatingAnother = another;
            this.model.forceCreate();
        }
    }

    createAnother(e) {
        if (this.canCreateAnother) this.create(e, true);
    }

    onSync() {
        this.unbindModel();
        if (this.isCreatingAnother) {
            this.model = this.newModel();
            this.contactCard.remove();
            this.profilesCard.remove();
            this.contactCard = this.initContactCard();
            this.profilesCard = this.initProfilesCard();
            this.itemsCard = this.initItemsCard();

            if (this.profilesCard.profiles.length === 1) this.profilesCard.addInitialProfiles();
            this.bindModel();
            this.setSyncing(false);
        } else {
            this.accreditees.bustCache();
            this.backToOverview();
        }
    }
}
