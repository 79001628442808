import React from 'react'
import PropTypes from 'prop-types'
import {Dropdown, Tooltip,} from 'ui-kit'
import './MainAdvancingContactsIcon.module.css'
import Icon from "production-website-app/src/components/util/Icon";

const MainAdvancingContactsIcon = ({
                                       contacts,
                                   }) => {
    let tooltipText;
    if (contacts.length === 1) {
        tooltipText = `${contacts.length} main advancing contact`;
    } else {
        tooltipText = `${contacts.length} main advancing contacts`;
    }

    return (
        <Dropdown
            renderRef={({ref, refProps}) => (
                <div
                    ref={ref}
                    {...refProps}
                    styleName="dropdownTrigger"
                >
                    <Tooltip content={tooltipText}>
                        <Icon name={'info'} size={24}/>
                    </Tooltip>
                </div>
            )}
            renderContent={({handleClose}) => (
                <div styleName="contactsContainer">
                    {contacts.map(({id, contact}) => (
                        <div key={id} styleName="contact">
                            <div><span>{contact.firstname} {contact.lastname}</span></div>
                            {contact.email &&
                            (<div><a href={'mailto:' + contact.email}>{contact.email}</a></div>)}
                            {contact.telephone &&
                            (<div><a href={'tel:' + contact.telephone}>{contact.telephone}</a></div>)}
                        </div>
                    ))}
                </div>
            )}
        />
    );
};

MainAdvancingContactsIcon.defaultProps = {
    contacts: [],
};

MainAdvancingContactsIcon.propTypes = {
    contacts: PropTypes.array,
};

export default MainAdvancingContactsIcon;
