import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Square = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
`;

const BookingStatusSquare = ({
    color
}) => (
    <Square style={{ backgroundColor: color }} />
);

BookingStatusSquare.propTypes = {
    color: PropTypes.string
};

export default BookingStatusSquare;
