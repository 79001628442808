var AccrediteeProfileModal = require('./AccrediteeProfileModal.view');

var template = require('./AccrediteeProfileModalAdd');

var AccrediteeProfileModalAdd = AccrediteeProfileModal.extend({

    template,

    events: {
        'click .create': 'add',
        'click .create-another': 'addAnother',
        'keypress': 'keypress'
    },

    render() {
        this.canCreateAnother = this.profiles.length > 1;
        this._super();
    },

    open(initialAttributes) {
        this.model = this.newModel(initialAttributes);
        this.render();
        this.show();
    },

    add() {
        if (this.model.isValid(true)) {
            this.trigger('add', this.model);
            this.unbindModel();
            this.hide();
        }
    },

    addAnother() {
        this.model.validate();
        if (this.model.isValid()) {
            this.trigger('add', this.model);
            this.model = this.newModel();
            this.render();
        }
    },

    keypress(e) {
        if(e.which == 13) {
            e.preventDefault();
            if(this.canCreateAnother) this.addAnother();
            else this.add();
        }
    }
});

module.exports = AccrediteeProfileModalAdd;
