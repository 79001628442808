import BaseBody from './BaseBody.view';

export default class SubTableBody extends BaseBody {

    insertRow(model, collection, options) {
        super.insertRow(model, collection, options);

        // Re-append the subTableRow for each row that has a subTableRow row
        this.rows.forEach((row) => {
            if (row.subTableRow) row.appendTableRow();
        });

        return this;
    }

}
