import { connect } from 'react-redux';
import { ConfirmModal } from 'app/react/components/_index';
import * as actions from 'app/react/state/actions/_index';
import * as ItemGroup from 'app/react/entities/accounts/item-groups/index';

import * as State from './GroupModalState';

const TITLE = 'Delete Group';
const TEXT = (name) => `Are you sure you want to delete <strong>${name}?</strong>`;
const BUTTON_TEXT =  'Yes, delete group';
const FAILED_POPUP = 'Failed to delete group.';
const SUCCESSFUL_POPUP = 'Group successfully deleted.';

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSyncing,
        group = {},
    } = State.getDeleteModalState(state);
    return {
        title: TITLE,
        text: TEXT(group.name),
        buttonText: BUTTON_TEXT,
        buttonClassName: 'alert',
        isOpened,
        isSyncing,
        group,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onConfirmed: () => {
        const { group } = stateProps;
        dispatchProps.onConfirmed(group.id);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch((State.setDeleteModalStateValue('isOpened', false)));
    },
    onConfirmed: (groupId) => {
        dispatch((State.setDeleteModalStateValue('isSyncing', true)));
        dispatch(ItemGroup.thunks.deleteOneForCurrentContext(groupId))
            .then(({ isFailure, errorMessage }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(`${FAILED_POPUP} ${errorMessage}`));
                    dispatch(State.mergeDeleteModalState({
                        isSyncing: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP));
                    dispatch(State.mergeDeleteModalState({
                        isOpened: false,
                        isSyncing: false
                    }));
                }
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ConfirmModal);
