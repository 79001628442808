import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import { SearchSelect } from 'ui-kit';
import BookingStatusLabel from 'app/react/views/event/artists/bookings/booking_statuses/components/BookingStatusLabel';

export const PLACEHOLDER = 'Select status...';
export const NO_RESULTS_PLACEHOLDER = 'No status found.';

const toOption = ({
    id,
    title,
    colorType,
}) => ({
    value: id,
    label: title,
    colorType,
});

const BookingStatusOption = ({
    option = {}
}) => (
    <BookingStatusLabel
        title={option.label}
        type={option.colorType}
    />
);

const SelectBookingStatus = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    value,
    bookingStatuses = [],
    onChange = () => {},
    isClearable = false,
    isFetchingBookings=false
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled || isFetchingBookings}
        options={bookingStatuses.map(toOption)}
        renderOption={option => <BookingStatusOption option={option} />}
        onChange={onChange}
        isClearable={isClearable}
        sortBy={by('')}
    />
);

SelectBookingStatus.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    bookingStatuses: PropTypes.array,
    onChange: PropTypes.func,
    isClearable: PropTypes.bool,
    isFetchingBookings: PropTypes.bool,
};

export default SelectBookingStatus;
