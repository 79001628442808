import { combinePaginationFields } from 'app/react/helpers/_index'
import {
    addEntityToCollection,
    invalidateCollectionCache,
    navigate
} from 'app/react/state/actions/_index'
import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Bookings from 'app/react/entities/events/bookings/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as ReportRoutes from 'app/routes/event/artists/reports/routes';
import {
    COLLECTION_REDUCER_KEY
} from './schemas'
import {
    getCurrentId,
    getOne,
    getPaginationForCurrentContext,
    generateBetweenDatesCollectionKeyForCurrentContext,
    getCollectionBetweenDatesForCurrentContext
} from './helpers';
import {
    createOne,
    fetchOne,
    updateOne,
    deleteOne,
    fetchAllPaginated,
    fetchAllByDay,
} from './actions';

export const fetchCurrent = () => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Bookings.getCurrentId(state);
    const pickupId = getCurrentId(state);
    return dispatch(fetchOne(accountId, eventId, bookingId, pickupId));
};

export const createOneForCurrentContext = (bookingId, values, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(createOne(accountId, eventId, bookingId, values, includes));
};

export const updateOneForCurrentContext = (bookingId, pickupId, values, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, bookingId, pickupId, values, includes));
};

export const updateCurrent = (values, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Bookings.getCurrentId(state);
    const pickupId = getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, bookingId, pickupId, values, includes));
};

export const deleteOneForCurrentContext = (pickupId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Bookings.getCurrentId(state) || getOne(state, pickupId).booking;
    return dispatch(deleteOne(accountId, eventId, bookingId, pickupId));
};

export const toggleIsArrivedForCurrentContext = (pickupId, isArrived) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Bookings.getCurrentId(state) || getOne(state, pickupId).booking;
    return dispatch(updateOne(accountId, eventId, bookingId, pickupId, { isArrived }));
};

export const fetchAllByPaginationForCurrentContext = (newPagination, includes) => (dispatch, getState) => {
    const state = getState();
    const pagination = getPaginationForCurrentContext(state);
    const combinedPagination = combinePaginationFields(newPagination, pagination);
    // No Cache
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(fetchAllPaginated(accountId, eventId, combinedPagination, includes));
};

export const addPickupToBetweenDatesCollection = (pickupId, after, before) => (dispatch, getState) => {
    const state = getState();
    const key = generateBetweenDatesCollectionKeyForCurrentContext(after, before, state);
    return dispatch(addEntityToCollection(pickupId, COLLECTION_REDUCER_KEY, key))
};

export const invalidateBetweenDatesCollectionCache = (after, before) => (dispatch, getState) => {
    const state = getState();
    const key = generateBetweenDatesCollectionKeyForCurrentContext(after, before, state);
    return dispatch(invalidateCollectionCache(COLLECTION_REDUCER_KEY, key))
};

export const fetchAllBetweenDatesForCurrentContextOrLoadFromCache = (after, before, includes) => (dispatch, getState) => {
    const state = getState();
    const collection = getCollectionBetweenDatesForCurrentContext(after, before, state);
    if (collection.hasCache) {
        return Promise.resolve();
    }
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(fetchAllByDay(accountId, eventId, after, before, includes));
};

export const downloadPickupsReport = () => (dispatch, getState) => {
    const eventId = Event.getCurrentId(getState());
    dispatch(navigate.newTab(ReportRoutes.driverSchedules(eventId)));
};
