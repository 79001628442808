import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_EVENT_ARTISTS_ITEM_REQUEST';
export const ONE_SUCCESS = 'ONE_EVENT_ARTISTS_ITEM_SUCCESS';
export const ONE_FAILURE = 'ONE_EVENT_ARTISTS_ITEM_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_REQUEST = 'ALL_EVENT_ARTISTS_ITEMS_REQUEST';
export const ALL_SUCCESS = 'ALL_EVENT_ARTISTS_ITEMS_SUCCESS';
export const ALL_FAILURE = 'ALL_EVENT_ARTISTS_ITEMS_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const ALL_PAGINATED_REQUEST = 'ALL_EVENT_ARTISTS_ITEMS_PAGINATED_REQUEST';
export const ALL_PAGINATED_SUCCESS = 'ALL_EVENT_ARTISTS_ITEMS_PAGINATED_SUCCESS';
export const ALL_PAGINATED_FAILURE = 'ALL_EVENT_ARTISTS_ITEMS_PAGINATED_FAILURE';
export const ALL_PAGINATED_TYPES = [
    ALL_PAGINATED_REQUEST,
    ALL_PAGINATED_SUCCESS,
    ALL_PAGINATED_FAILURE
];

export const fetchAll = (accountId, eventId) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId),
        schema: Schemas.entities
    },
    accountId,
    eventId
});

export const createOne = (accountId, eventId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId),
        schema: Schemas.entity
    },
    accountId,
    eventId
});

export const updateOne = (accountId, eventId, itemId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOne(eventId, itemId),
        schema: Schemas.entity
    },
    accountId,
    eventId
});


export const deleteOne = (accountId, eventId, itemId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, itemId),
        schema: Schemas.entity,
        id: itemId
    },
    accountId,
    eventId
});

export const fetchAllPaginated = (
    accountId,
    eventId,
    pagination,
    includes
) => {
    const {
        page = 1,
        pageSize = 'all',
        filters = {},
        search = '',
        sortKey = '',
        sortOrder = 1,
    } = pagination;
    return {
        [CALL_API]: {
            types: ALL_PAGINATED_TYPES,
            endpoint: Endpoints.fetchPaginated(
                eventId,
                page,
                pageSize,
                filters,
                search,
                sortKey,
                sortOrder,
                includes
            ),
            requestedPage: page,
            requestedPageSize: pageSize,
            requestedFilters: filters,
            requestedSearch: search,
            requestedSortKey: sortKey,
            requestedSortOrder: sortOrder,
            schema: Schemas.entities
        },
        accountId,
        eventId,
    };
};


