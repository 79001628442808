import React from 'react'
import by from 'thenby'
import { Card as UIKitCard } from 'ui-kit'
import Table from './TableWithData'
import TopBar from './TopBar'

const { Header, Content } = UIKitCard;
const { Tabs } = Header;
const { Tab } = Tabs;

const Card = (props) => {
    const {
        search,
        filters,
    } = props;
    return (
        <UIKitCard isFull>
            <Content>
                <TopBar
                    {...props}
                    searchValue={search}
                />
                <Table
                    {...props}
                    isCheckedIn={filters.isCheckedIn}
                    includePerformers={filters.includePerformers}
                    includeCrew={filters.includeCrew}
                    includeGuests={filters.includeGuests}
                    day={filters.day}
                    includeTicketTypes={filters.ticketTypes}
                    first={25}
                />
            </Content>
        </UIKitCard>
    );
};

export default Card;
