import React from 'react';
import PropTypes from 'prop-types'
import { TableSortCell, TableHeaderCell } from 'ui-kit'

const COLUMNS = [{
    name: 'Make',
    sortKey: 'make'
}, {
    name: 'Model',
    sortKey: 'model'
}, {
    name: 'Licence Plate',
    sortKey: 'licencePlate'
}, {
    name: 'Passengers',
    sortKey: 'numberOfPassengers',
    isCentered: true,
}, {
    name: 'Pickups',
    sortKey: 'pickups',
    isCentered: true,
}, {
    name: 'Mileage In',
    sortKey: 'mileageIn'
}, {
    name: 'Mileage Out',
    sortKey: 'mileageOut'
}, {
    name: '',
}];

const VehicleTableHeaderRow = ({
    currentSortKey,
    currentSortOrder,
    onSort,
}) => (
    <tr>
        {COLUMNS.map(({ name, sortKey, isCentered }) => {
            if (typeof sortKey !== 'undefined') {
                return (
                    <TableSortCell
                        key={name}
                        sortKey={sortKey}
                        currentSortKey={currentSortKey}
                        currentSortOrder={currentSortOrder}
                        onSort={onSort}
                        isCentered={isCentered}
                    >
                        {name}
                    </TableSortCell>
                )
            }

            return (
                <TableHeaderCell key={name} isCentered={isCentered}>
                    {name}
                </TableHeaderCell>
            );
        })}
    </tr>
);

VehicleTableHeaderRow.propTypes = {
    currentSortKey: PropTypes.string,
    currentSortOrder: PropTypes.number,
    onSort: PropTypes.func,
};

export default VehicleTableHeaderRow;
