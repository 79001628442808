import BaseController from 'app/backbone/helpers/BaseController'
import TransportsFormView from 'app/backbone/views/event/artists/bookings/transports/Form.view'

const createRoute = (
    bookingId = ':booking',
) => `booking/add-transport/${bookingId}`;

const updateRoute = (
    bookingId = ':booking',
    transportId = ':transport',
) => `booking/edit-transport/${bookingId}/${transportId}`;

export default class TransportsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(createRoute(), null, this.create.bind(this));
        this.router.route(updateRoute(), null, this.update.bind(this));
    }

    create() {
        this.app.renderContentView(new TransportsFormView());
    }

    update() {
        this.app.renderContentView(new TransportsFormView());
    }
};
