import BaseController from 'app/backbone/helpers/BaseController'
import MarketingController from 'app/routes/shared/marketing/Controller'

export default class SharedController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new MarketingController({ parent: this }),
        ];
    }

}
