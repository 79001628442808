import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import DialogContainer from '../dialogContainer/DialogContainer';
import Icon from '../../../utils/Icon';
import Button from '../button/Button';
import classNames from 'classnames';
import {date} from 'yup';
import {blancoEvent} from '../../../hooks/UseEventState';

const ChooseEventDialog = ({ setCancelDialogOpen, events, setActiveStep }) => {
	const [event, setEvent] = useState(events[0]);

	return (
		<DialogContainer>
			<Fragment>
				<div className='artist-dialog__container'>
					<div className='get-started__left-list'>
						<h2>Your events</h2>
						{events.map(({ name, id }, index) => (
							<button
								className={classNames(
									'get-started__event-button',
									id === event.id && 'get-started__event-button--active'
								)}
								onClick={() => setEvent(events[index])}
								key={`${name}-${index}`}
							>
								{name}
								<Icon size={24} name='arrowRight' style={{ color: id === event.id ?  'hsl(168, 89%, 41%)' : 'hsl(0, 0%, 100%)'}} />
							</button>
						))}
					</div>
					<div className='get-started__right-container'>
						<button
							className={'artist-dialog__cancel-button'}
							onClick={() => setCancelDialogOpen(false)}
						>
							<Icon size={24} name='close' style={{ marginBottom: '-10px' }} />
						</button>
						<div className='get-started__dialog-container'>
							<h3>{event.name}</h3>
							<div className='get-started__info-row'>
								<label className='get-started__info-row__label'>
									Event name
								</label>
								<span className='get-started__info-row__text'>
									{event.name}
								</span>
							</div>
							<div className='get-started__info-row'>
								<label className='get-started__info-row__label'>Currency</label>
								<span className='get-started__info-row__text'>
									{event.currency}
								</span>
							</div>
							{event.distance_unit && (
								<div className='get-started__info-row'>
									<label className='get-started__info-row__label'>
										Distance unit
									</label>
									<span className='get-started__info-row__text'>
										{event.unit_of_distance}
									</span>
								</div>
							)}
							{event.venue && event.venue.data && event.venue.data.address && (
								<div className='get-started__info-row'>
									<label className='get-started__info-row__label'>
										Location
									</label>
									<span className='get-started__info-row__text'>{`${event.venue.data.address.data.street}, ${event.venue.data.address.data.zip} ${event.venue.data.address.data.city}`}</span>
								</div>
							)}
							{event.date_time_format && (
								<div className='get-started__info-row'>
									<label className='get-started__info-row__label'>
										Date format
									</label>
									<span className='get-started__info-row__text'>{`${event.date_time_format.date.momentjs}  -  ${event.date_time_format.time.momentjs}`}</span>
								</div>
							)}
						</div>
						<Fragment>
							<div className='artist-dialog__footer'>
								<Button
									type='submit'
									label='Choose this event'
									variant='primary'
									onClick={() =>
										setActiveStep(1, {
											// ...event,
											...blancoEvent,
											id: undefined,
											accreditation_enabled: event.accreditation_enabled,
											accreditation_ticket_provider_id: event.accreditation_ticket_provider_id,
											artist_types_enabled: event.artist_types_enabled,
											currency: event.currency,
											timezone: event.timezone,
											name: event.name,
											unit_of_distance: event.unit_of_distance,
											venue: event.venue.data.id,
											time_format: event.date_time_format.time.momentjs,
											date_format: event.date_time_format.date.momentjs,
											oldEventId: event.id,
											existingEvents: events,
										})
									}
								/>
							</div>
						</Fragment>
					</div>
				</div>
			</Fragment>
		</DialogContainer>
	);
};

ChooseEventDialog.propTypes = {
	setCancelDialogOpen: PropTypes.func.isRequired,
};

export default ChooseEventDialog;
