import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlipMove from 'react-flip-move';
import {
    Grid,
    Badge,
    StylelessButton,
} from  'ui-kit';
import { Tooltip } from 'app/react/components/_index';
import {
    DropTarget as dropTarget,
    DragSource as dragSource
} from 'react-dnd';
import ChildPageItem from './ChildItem';
import { ListContainer } from './List';
import './Item.module.css';

const { Cell } = Grid;
const Types = {
    ROW: 'pageRow'
};

const HOME_PAGE_LABEL = 'HOMEPAGE';
const FORM_PAGE_LABEL = 'FORM PAGE';
const DROP_CELL_WIDTH = '50px';
const NAME_CELL_WIDTH = '400px';
const ACTION_CELL_WIDTH = '150px';

const TOOLTIPS = {
    ADD_SUB_PAGE: 'Add sub page',
    UPDATE: 'Edit page',
    UPDATE_FORM_PAGE: 'Form page setting',
    DELETE: 'Delete page'
};

export const PageItem = ({
    id,
    name,
    slug,
    isHomePage = false,
    isFormPage = false,
    isOverTopArea = false,
    isOverMiddleArea = false,
    isOverBottomArea = false,
    hasChildren = false,
    children = [],
    onAddChild = () => {},
    onUpdate = () => {},
    onDelete = () => {},
    updatePageSequence = () => {},
    updateHoverState = () => {},
    dropOutsideList = () => {},
    // Drag props
    isDragging = false,
    isOver = false,
    canDrop = false,
    connectDragSource = () => {},
    connectDropTarget = () => {}
}) => {
    const dragDisabled = isOverMiddleArea && (isHomePage || isFormPage);
    return connectDragSource(
        connectDropTarget(
            <li
                styleName={classNames('item', {
                    isDragging,
                    dragOnTop: isOverTopArea,
                    dragOnBottom: isOverBottomArea,
                    dragInto: isOverMiddleArea && !(isHomePage || isFormPage),
                    dragDisabled,
                })}
            >
                <div styleName="itemContent">
                    <Grid hasGutters={false}>
                        <Cell isAutoSized width={DROP_CELL_WIDTH}>
                            {dragDisabled
                                ? <i className="bs-icon-cancel" style={{ color: 'var(--colorDanger500)' }} />
                                : <i className="bs-icon-cursor-move"/>
                            }
                        </Cell>
                        <Cell isAutoSized width={NAME_CELL_WIDTH}>
                            <span className="margin-tiny-right">{name}</span>
                            {isHomePage && (
                                <Badge>{HOME_PAGE_LABEL}</Badge>
                            )}
                            {isFormPage && (
                                <Badge type={Badge.SUCCESS}>{FORM_PAGE_LABEL}</Badge>
                            )}
                        </Cell>
                        <Cell>
                            {!hasChildren && (
                                <code>{slug}</code>
                            )}
                        </Cell>
                        <Cell
                            isAutoSized
                            width={ACTION_CELL_WIDTH}
                            style={{ textAlign: 'right', marginRight: 'var(--spacingMedium)' }}
                        >
                            <Tooltip content={TOOLTIPS.UPDATE}>
                                <StylelessButton onClick={() => onUpdate(id)}>
                                    <i className="bs-icon-pencil" />
                                </StylelessButton>
                            </Tooltip>
                            {!isFormPage && (
                                <Tooltip content={TOOLTIPS.DELETE}>
                                    <StylelessButton onClick={() => onDelete(id)}>
                                        <i className="bs-icon-bin" />
                                    </StylelessButton>
                                </Tooltip>
                            )}
                        </Cell>
                    </Grid>
                </div>
                {hasChildren && (
                    <div styleName="subItemsContainer">
                        <ListContainer>
                            <FlipMove
                                staggerDurationBy="30"
                                duration={500}
                                enterAnimation="accordianVertical"
                            >
                                {children.map(child => (
                                    <ChildPageItem
                                        key={child.id}
                                        id={child.id}
                                        parentId={id}
                                        name={child.name}
                                        slug={child.slug}
                                        isHomePage={child.isHomePage}
                                        isFormPage ={child.isFormPage}
                                        isOverTopArea={child.isOverTopArea}
                                        isOverBottomArea={child.isOverBottomArea}
                                        updatePageSequence={updatePageSequence}
                                        updateHoverState={updateHoverState}
                                        dropOutsideList={dropOutsideList}
                                        onUpdate={onUpdate}
                                        onDelete={onDelete}
                                    />
                                ))}
                            </FlipMove>
                        </ListContainer>
                    </div>
                )}
            </li>
        )
    );
};

PageItem.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    name: PropTypes.string,
    isHomePage: PropTypes.bool,
    isFormPage: PropTypes.bool,
    isOverTopArea: PropTypes.bool,
    isOverMiddleArea: PropTypes.bool,
    isOverBottomArea: PropTypes.bool,
    hasChildren: PropTypes.bool,
    children: PropTypes.array,
    slug: PropTypes.string,
    onAddChild: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    updatePageSequence: PropTypes.func,
    updateHoverState: PropTypes.func.isRequired,
    dropOutsideList: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool,
    connectDragSource: PropTypes.func
};

const sourceSpec = {
    beginDrag: (props) => ({
        id: props.id,
        sequence: props.sequence,
        name: props.name,
        hasChildren: props.hasChildren,
        children: props.children,
        isFormPage: props.isFormPage
    }),
    endDrag: (props, monitor) => {
        if (monitor.getDropResult() === null) {
            props.dropOutsideList(props.id);
        }
    }
};

const targetSpec = {
    drop: (props, monitor, component) => {
        const dragItem = monitor.getItem();
        if (dragItem) {
            // Don't replace items with themselves
            if (dragItem.id === props.id) return;
            props.updatePageSequence(
                dragItem.id,
                props.id,
                dragItem.parentId,
                props.parentId,
                props.isOverTopArea,
                props.isOverMiddleArea,
                props.isOverBottomArea
            );
        }
    },
    hover: (props, monitor, component) => {
        const dragItem = monitor.getItem();
        // Don't replace items with themselves
        if (dragItem.id === props.id) return;
        // Determine rectangle on screen
        const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverBoundarySize = 20;
        const hoverBoundaryTop = hoverBoundarySize;
        const hoverBoundaryBottom = (hoverBoundingRect.bottom - hoverBoundingRect.top) - hoverBoundarySize;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        const isOverTopArea =
            hoverClientY <= (dragItem.hasChildren ? hoverMiddleY : hoverBoundaryTop);
        const isOverMiddleArea =
            dragItem.hasChildren ? false : (hoverClientY > hoverBoundaryTop && hoverClientY < hoverBoundaryBottom);
        const isOverBottomArea =
            hoverClientY > (dragItem.hasChildren ? hoverMiddleY : hoverBoundaryBottom);
        // Is cursor is still over same area don't dispatch
        const isStillOverTop = props.isOverTopArea && isOverTopArea;
        const isStillOverMiddle = props.isOverMiddleArea && isOverMiddleArea;
        const isStillOverBottom = props.isOverBottomArea && isOverBottomArea;
        if (isStillOverTop || isStillOverMiddle || isStillOverBottom) {
            return;
        }
        // Do not dispatch if the page has children and is over middle area
        if (props.hasChildren && (isOverMiddleArea)) {
            return;
        }
        props.updateHoverState(
            dragItem.id,
            props.id,
            undefined,
            undefined,
            isOverTopArea,
            isOverMiddleArea,
            isOverBottomArea
        );
    }
};

const collectSourceProps = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
});

const collectTargetProps = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
});

export default dropTarget(
    Types.ROW, targetSpec, collectTargetProps
)(dragSource(
    Types.ROW, sourceSpec, collectSourceProps
)(PageItem));
