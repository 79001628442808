import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from 'ui-kit'
import ProgressCircle from './TaskProgressCircle'

const TaskProgressCell = ({
    bookingId,
    tasks = [],
    onToggleTask = () => {}
 }) => (
    <TableCell isCentered>
        <ProgressCircle
            bookingId={bookingId}
            tasks={tasks}
            onToggleTask={onToggleTask}
        />
    </TableCell>
);

TaskProgressCell.propTypes = {
    tasks: PropTypes.array
};

export default TaskProgressCell;
