import * as actions from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/helpers';
import * as Pickup from 'app/react/entities/events/bookings/pickups/helpers';
import * as CreateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/CreateModalState';
import * as UpdateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/UpdateModalState';
import * as BookingRoutes from 'app/routes/event/artists/bookings/routes';

const updatePickupModalsDriverValue = (driverId) => (dispatch, getState) => {
    const state = getState();
    if (CreateModalState.stateHelpers.isOpen(state)) {
        dispatch(CreateModalState.actions.setStateValue('values.driver', driverId));
    }
    if (UpdateModalState.stateHelpers.isOpen(state)) {
        dispatch(UpdateModalState.actions.setStateValue('values.driver', driverId));
    }
};

const updatePickupModalsVehicleValue = (vehicleId) => (dispatch, getState) => {
    const state = getState();
    if (CreateModalState.stateHelpers.isOpen(state)) {
        dispatch(CreateModalState.actions.setStateValue('values.vehicle', vehicleId));
    }
    if (UpdateModalState.stateHelpers.isOpen(state)) {
        dispatch(UpdateModalState.actions.setStateValue('values.vehicle', vehicleId));
    }
};

const goToBooking = (pickupId, newTab) => (dispatch, getState) => {
    const state = getState();
    const eventId = Event.getCurrentId(state);
    const pickup = Pickup.getOne(state, pickupId);
    const bookingId = pickup.booking;
    const url = BookingRoutes.details(eventId, bookingId);
    if (newTab) {
        dispatch(actions.navigate.newTab(url))
    } else {
        dispatch(actions.navigate.pageReload(url))
    }
};

export const thunks = {
    updatePickupModalsDriverValue,
    updatePickupModalsVehicleValue,
    goToBooking
};
