import React, { Fragment } from 'react'
import { Tooltip } from 'ui-kit'
import { Price } from 'app/react/components/util/_index'

const TOOLTIP_TOTAL_PRICE_NA = () => (
    <Fragment>
        <strong>The total price is not available when:</strong><br/>
        - the Accommodation does not have Room Types<br/>
        - none of the Room Types have a price specified<br/>
        - none of the Reservations have a Room Type assigned
    </Fragment>
);
const TOOLTIP_TOTAL_PRICE_INCOMPLETE = () => (
    <Fragment>
        <strong>The total price is incomplete due to:</strong><br/>
        - one or more Room Types that don't have a price specified<br/>
        - one or more Reservations that don't have a Room Type assigned
    </Fragment>
);

const TotalPriceForReservations = ({
    currencySymbol,
    accommodation = {},
}) => {
    const totalPrice = accommodation.totalPriceForReservationsByPromoter;

    if (totalPrice === null || typeof totalPrice === 'undefined') {
        return (
            <Tooltip renderContent={TOOLTIP_TOTAL_PRICE_NA}>
                <span>N/A</span>
            </Tooltip>
        );
    }

    if (accommodation.isTotalPriceIncomplete) {
        return (
            <Tooltip renderContent={TOOLTIP_TOTAL_PRICE_INCOMPLETE}>
                <Price
                    hasAlert
                    amount={totalPrice}
                    currencySymbol={currencySymbol}
                />
            </Tooltip>
        );
    }

    return (
        <Price
            amount={totalPrice}
            currencySymbol={currencySymbol}
        />
    );
};

export default TotalPriceForReservations;
