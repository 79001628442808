import { schema } from 'normalizr';
import { ICON_MAPPINGS } from 'app/react/entities/events/bookings/transports/schemas'

export const ENTITY_REDUCER_KEY = 'pickupModes';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const processStrategy = (entity) => ({
    ...entity,
    icon: ICON_MAPPINGS[entity.id]
});

const entityDefinition = {};
const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);
