export const base = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}/performers`;

export const create = (
    eventId = ':event',
    bookingId = ':booking'
) => `${base(eventId, bookingId)}/create(?*query)`;

export const update = (
    eventId = ':event',
    bookingId = ':booking',
    performerId = ':performer'
) => `${base(eventId, bookingId)}/${performerId}/edit(?*query)`;
