import BaseView from 'app/backbone/components/Base.view'
import Datepicker from 'app/backbone/components/inputs/Datepicker.view'

export default class FormView extends BaseView {

    ui() {
        return {
            dueDatePicker: '[name=due_date]',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderDueDatePicker();
        return this.delegateEvents();
    }

    renderDueDatePicker() {
        this.dueDatePicker = new Datepicker({ el: this.ui.dueDatePicker });
        this.dueDatePicker.render();
    }
}
