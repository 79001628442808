const CheckboxCell = require('app/backbone/components/tables/cells/CheckboxCell.view');
const template = require('./GrantedAllDaysCell.hbs');

const AllDaysGrantedCell = CheckboxCell.extend({

    template,

    className: 'all-days-granted-cell input center',

    events: {
        'change input[type=text]': 'onChangeInput',
        'change input[type=checkbox]': 'onChangeCheckbox'
    },

    initialize(options = {}) {
        this._super(options);

        // Props
        this.grantedDays = this.model.getRelation('granted_days');

        // listeners
        this.listenTo(this.grantedDays, 'update reset', this.onUpdateGrantedDays);
    },

    viewModel() {
        return {
            view: {
                isDisabled: this.isDisabled(),
                isVisible: this.isVisible(),
                isChecked: this.isChecked(),
                isEditing: this.isEditing(),
                isDateDependable: this.isDateDependable(),
                usesAmount: this.usesAmount(),
                amount: this.getAmount()
            }
        };
    },

    isChecked() {
        if (this.isDateDependable()) {
            const grantableDays = this.getGrantableDays();
            if (!grantableDays.length) return false;
            return this.model.hasGrantedDayOnEvery(grantableDays);
        } else {
            return this.model.get('granted');
        }
    },

    isDateDependable() {
        return this.model.get('date_dependable');
    },

    isEditing() {
        return this.editing || false;
    },

    usesAmount() {
        return this.model.get('uses_amounts');
    },

    getAmount() {
        if (this.usesAmount() && !this.isDateDependable()) return this.model.get('amount');
    },

    onChangeInput(e) {
        const amount = this.$(e.currentTarget).val();
        this.model.setAmount(amount);
        this.render();
    },

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        if (this.isDateDependable()) {
            const grantableDays = this.getGrantableDays();
            if (checked) this.model.addGrantedDayOnAll(grantableDays);
            else this.model.removeGrantedDayOnAll(grantableDays);
        } else {
            this.model.set('granted', checked);
        }
    },

    getGrantableDays() {
        return this.model.getRelation('available_days');
    },

    onUpdateGrantedDays() {
        this.render();
    }

});

module.exports = AllDaysGrantedCell;
