import Card from '../Card.view';
import TabsCardHeader from './TabsCardHeader.view';
import TabsCardContent from './TabsCardContent.view';
import template from './TabsCard.hbs';

export default class TabsCard extends Card {

    get template() {
        return template;
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        this.views = this.initViews();
        this.tabs = this.initTabs();
        this.header = this.initHeader();
        this.content = this.initContent();
        this.footer = this.initFooter();

        // Settings
        this.activeTab = this.getSetting('tab') || this.storeSetting('tab', this.activeTab);
        if (this.activeTab) this.header.tabsContainer.selectTab(this.activeTab);

        this.listenTo(this.header, 'tab:select', (tab) => {
            this.storeSetting('tab', tab.name);
            this.content.renderSelectedView(tab.name);
        });
    }

    render() {
        const { header, content, footer } = this;
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.assignSubview(header, this.ui.header);
        this.assignSubview(content, this.ui.content);

        if (this.activeTab) content.renderSelectedView(this.activeTab);
        else content.renderFirst();

        this.assignSubview(footer, this.ui.footer);
        return this.delegateEvents();
    }

    remove() {
        this.views.forEach(view => view.remove());
        super.remove();
    }

    initHeader() {
        const { tabs } = this;
        const actions = this.initHeaderActions();
        return new TabsCardHeader({ tabs, actions });
    }

    initContent() {
        const { views } = this;
        return new TabsCardContent({ views });
    }

    initViews() {
        return [];
    }

    initTabs() {
        return [];
    }
}
