import React from 'react'
import Modal from '../ItemModal'

class ItemModalContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            itemLocations: []
        }

        this.getLocations = this.getLocations.bind(this)
        this.updateLocations = this.updateLocations.bind(this)
    }

    async getLocations() {
        const { token, eventId } = this.props;
        const url = `/api/events/${eventId}/artists/item-locations`;
        const method = 'GET';
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        return fetch(url, { headers, method })
            .then(response => {
                if (response.status === 204) { // No response body
                    if (!response.ok) {
                        return Promise.reject();
                    }
                    return Promise.resolve();
                }
                return response.json()
                    .then(json => {
                        if (!response.ok) {
                            return Promise.reject(json);
                        }
                        return json;
                    });
            });
    }

    updateLocations(value) {
        const { itemLocations } = this.state;
        if (!itemLocations.includes(value)) {
            itemLocations.push(value)
        }
    }

    componentDidMount() {
        this.getLocations()
            .then(response => response)
            .then(({ data }) => {
                const itemLocations = data.map(location => location.name)
                this.setState({
                    itemLocations
                })
            })
    }

    render() {
        const props = this.props;
        const { itemLocations } = this.state;

        return(
            <Modal
                {...props}
                itemLocations={itemLocations}
                updateLocations={this.updateLocations}
            />
        )
    }
}

export default ItemModalContainer;
