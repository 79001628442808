import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import template from './GeneralCardContent.hbs';

export default class GeneralCardContent extends BaseView {

    get template() {
        return template;
    }

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    }

    viewModel() {
        return {
            model: this.company.production_website_settings.attributes
        };
    }

}
