import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import styled from 'styled-components';
import ItemsRow from './ItemRow';
import ItemGroupRow from './ItemGroupRow';
import {
    toGroupsWithItems,
    toGroupsWithSubGroups,
    toGroupsWithSubGroupsAndItems,
    whereIsNotChild
} from 'app/react/helpers/itemGroups';

const BookingItemsTable = ({
    currency,
    items = [],
    groups = [],
    onUpdate = () => {},
    onDelete = () => {},
    onAdd = () => {},
}) => {
    const groupsWithItems = toGroupsWithItems(groups, items);
    const groupsWithSubGroups = toGroupsWithSubGroups(groupsWithItems);

    return (
        <table className="table-with-indented-rows overflow">
            <tbody>
                {toGroupsWithSubGroupsAndItems(groupsWithSubGroups)
                    .filter(whereIsNotChild)
                    .sort(by('name', { ignoreCase: true }))
                    .map(group => (
                        <ItemGroupRow
                            key={group.id}
                            group={group}
                            onAdd={onAdd}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                            currency={currency}
                        />
                    ))
                }
            </tbody>
        </table>
    );
}

BookingItemsTable.propTypes = {
    vehicles: PropTypes.array,
    onOpenUpdateModal: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
};

export default BookingItemsTable;
