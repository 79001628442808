import React from 'react';
import PropTypes from 'prop-types';
import { SectionHeaderRow, SectionRow, IntroSectionRow } from './Rows';

const SectionsTable = ({
    sections = [],
    dateFormat = 'L',
    onEdit = () => {},
    onChangeValue = () => {}
}) => (
    <table>
        <thead>
            <SectionHeaderRow />
        </thead>
        <tbody>
            <IntroSectionRow onEdit={() => onEdit('intro')} />
            {sections.map((section) =>
                <SectionRow
                    key={section.id}
                    sectionId={section.id}
                    section={section}
                    dateFormat={dateFormat}
                    onEdit={onEdit}
                    onChangeValue={onChangeValue}
                />
            )}
        </tbody>
    </table>
);

SectionsTable.propTypes = {
    sections: PropTypes.array,
    dateFormat: PropTypes.string,
    onEdit: PropTypes.func,
    onChangeValue: PropTypes.func
};

export default SectionsTable;

