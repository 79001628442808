import React, { Fragment, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import classNames from 'classnames'
import useModalAutoSizing from 'ui-kit/lib/components/hooks/useModalAutoSizing'
import Loading from 'ui-kit/lib/components/modal/Loading'
import { reduxStore } from 'app/react/state/_index'
import withState from './withState'
import Header from './header/Header'
import Tabs from './tabs/Tabs'
import Content from './Content'
import Footer from './footer/Footer'

const OUTER_MARGIN = 32;
const Portal = ({ children, container }) => createPortal(children, container);

const Wrapper = ({ isReduxProvider, children }) => {
    return isReduxProvider
        ? <ReduxProvider store={reduxStore}>{children}</ReduxProvider>
        : <Fragment>{children}</Fragment>
};

export const Modal = ({
    className,
    children,
    syncing,
    syncingText,
    isReduxProvider,
    width,
}) => {
    const ref = useRef(null);
    const { height, alignSelf } = useModalAutoSizing(ref, syncing);
    const modalStyle = {
        width,
        minHeight: height,
        alignSelf,
    };

    return (
        <div ref={ref} className={classNames('modal', className)} style={modalStyle}>
            <Wrapper isReduxProvider={isReduxProvider}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {syncing
                        ? <Loading text={syncingText} />
                        : children
                    }
                </div>
            </Wrapper>
        </div>
    );
};

Modal.defaultProps = {
    isReduxProvider: false,
};
Modal.Header = Header;
Modal.Tabs = Tabs;
Modal.Content = Content;
Modal.Syncing = Loading;
Modal.Footer = Footer;

export default withState(Modal);
