import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'ui-kit';
import ActiveBadge from './ActiveBadge';

const { Content, Footer } = Card;
const ACTIVATED_BUTTON_TEXT = 'Activated';
const DEACTIVATED_BUTTON_TEXT = 'Deactivate';
const ACTIVATE_BUTTON_TEXT = 'Activate';

class ActivatedFooterButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false
        };
    }

    render() {
        const { id, onDeactivate } = this.props;
        const { hover } = this.state;
        return (
            <div
                style={{ width: '100%' }}
                onMouseOver={() => this.setState({ hover: true })}
                onMouseOut={() => this.setState({ hover: false })}
            >
                {hover
                    ? <Button
                        isExpanded
                        type={Button.DANGER}
                        onClick={() => onDeactivate(id)}
                    >
                        {DEACTIVATED_BUTTON_TEXT}
                    </Button>
                    : <Button
                        isExpanded
                        type={Button.SECONDARY}
                    >
                        {ACTIVATED_BUTTON_TEXT}
                    </Button>
                }
            </div>
        );
    }
};

ActivatedFooterButton.propTypes = {
    id: PropTypes.number.isRequired,
    onDeactivate: PropTypes.func
};

const IntegrationCard = ({
    id,
    name,
    logoUrl = '',
    categoryName = '',
    activated = false,
    onActivate = () => {},
    onDeactivate = () => {},
}) => (
    <Card width="22rem">
        <Content textAlign="center">
            <div
                style={{
                    height: '135px',
                    background: `url(${logoUrl}) no-repeat 50% 50% / cover`
                }}
            />
            <h6>
                {categoryName}
                {activated && (
                    <ActiveBadge />
                )}
            </h6>
        </Content>
        <Footer>
            {activated
                ? <ActivatedFooterButton id={id} onDeactivate={onDeactivate} />
                : <Button isExpanded onClick={() => onActivate(id)}>
                    {ACTIVATE_BUTTON_TEXT}
                </Button>
            }
        </Footer>
    </Card>
);

IntegrationCard.propTypes = {
    id: PropTypes.number.isRequired,
    logoUrl: PropTypes.string,
    categoryName: PropTypes.string,
    activated: PropTypes.bool,
    onActivate: PropTypes.func,
    onDeactivate: PropTypes.func
};

export default IntegrationCard;
