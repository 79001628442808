import BaseView from 'app/backbone/components/Base.view'
import DataTable from 'app/backbone/components/tables/DataTable.view'

export default class DetailView extends BaseView {

    ui() {
        return {
            addContact: '.add-company-contact',
            addContactModal: '#add-company-contact-modal',
            table: '#companies-table',
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.table = new DataTable();
    }

    events() {
        return {
            'click addContact': 'openModal',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.table, this.ui.table);
        return this.delegateEvents();
    }

    openModal() {
        this.openModalFromDOM(this.ui.addContactModal);
    }
}
