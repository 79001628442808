import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as ArtistType from 'app/react/entities/events/artist_types/helpers';
import * as Section from '../helpers';
import * as Schemas from './schemas'
import {
    getSomeEntities,
    getEntitiesCollectionByKey
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} = Schemas;

export const getSome = (state, ids) =>
    getSomeEntities(state, ENTITY_REDUCER_KEY, ids);

export const generateCollectionKey = (accountId, eventId, artistTypeId, sectionId) =>
    `${accountId}/${eventId}/${artistTypeId}/${sectionId}`;

export const getCollection= (state, accountId, eventId, artistTypeId, sectionUuid) => {
    const key = generateCollectionKey(accountId, eventId, artistTypeId, sectionUuid);
    return getEntitiesCollectionByKey(state, COLLECTION_REDUCER_KEY, key);
};

export const getCollectionForCurrentContext = (state) => {
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const artistTypeId = ArtistType.getCurrentId(state);
    const sectionId = Section.getCurrentId(state);
    return getCollection(state, accountId, eventId, artistTypeId, sectionId);
};

export const getAllForCurrentContext = (state) => {
    const collection = getCollectionForCurrentContext(state);
    return getSomeEntities(state, ENTITY_REDUCER_KEY, collection.ids);
};

export const isFetchingAllForCurrentContext = (state) => {
    const collection = getCollectionForCurrentContext(state);
    return !! collection.isFetching
};
