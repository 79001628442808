import CompanyAccrediteeCreateView from 'app/backbone/views/event/accreditation/companies/accreditees/views/CompanyAccrediteeCreate.view';

export default class ProductionWebsiteCompanyAccrediteeCreateView extends CompanyAccrediteeCreateView {


    initialize(options = {}) {
        super.initialize(options);

        this.profilesCard.header.title = 'Credentials';
        const addAction = this.profilesCard.header.getAction('add');
        addAction.removeTooltip();
        addAction.initTooltip({ content: 'Select Credential Type', position: 'top right' });
        this.profilesCard.addModal.title = 'Add Credential';
        this.profilesCard.content.placeholderText = 'No credentials have been selected for this attendee. Add at least one.';
        this.profilesCard.content.firstColumnsTable.columns.findWhere({ label: 'Profile' }).set('label', 'Credential');
    }

    backToOverview() {
        const productionWebsiteUrl = window.beatswitch.production_website.slug;
        navigate(`/production-website-accreditation/${productionWebsiteUrl}/company-form`);
    }

}
