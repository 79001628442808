import { schema } from 'normalizr';
import { CALL_API } from 'app/react/state/middleware/api';
import { createPaginationReducer } from 'app/react/state/reducers/higher_order/_index';
import {
    getAllEntities,
    getEntitiesPagination,
    isFetchingEntities
} from 'app/react/state/helpers';

export const ALL_REQUEST = 'ALL_PERFORMANCE_TIME_SLOT_TYPES_REQUEST';
export const ALL_SUCCESS = 'ALL_PERFORMANCE_TIME_SLOT_TYPES_SUCCESS';
export const ALL_FAILURE = 'ALL_PERFORMANCE_TIME_SLOT_TYPES_FAILURE';

export const ENTITY_REDUCER_KEY = 'performanceTimeSlotTypes';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;
const entity = new schema.Entity(ENTITY_REDUCER_KEY);
const array = new schema.Array(entity);

const getAll = (state) =>
    getAllEntities(state, ENTITY_REDUCER_KEY);

const getPagination = (state) =>
    getEntitiesPagination(state, ENTITY_REDUCER_KEY, PAGINATION_REDUCER_KEY);

const isFetching = (state) =>
    isFetchingEntities(state, PAGINATION_REDUCER_KEY);

const paginationReducer = createPaginationReducer({
    types: [
        ALL_REQUEST,
        ALL_SUCCESS,
        ALL_FAILURE
    ]
});

const Endpoints = {
    all: () => 'performance-time-slot-types'
};

const fetchAll = () => ({
    [CALL_API]: {
        types: [
            ALL_REQUEST,
            ALL_SUCCESS,
            ALL_FAILURE
        ],
        endpoint: Endpoints.all(),
        schema: array
    }
});

const fetchAllOrLoadFromCache = () => (dispatch, getState) => {
    const state = getState();
    return dispatch(fetchAll());
};

export const schemas = {
    entity,
    array
};

export const stateHelpers = {
    getAll,
    isFetching
};

export const reducers = {
    pagination: paginationReducer
};

export const actions = {
    fetchAll,
    fetchAllOrLoadFromCache
};
