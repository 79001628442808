var $ = require('jquery'),
    _ = require('underscore');

var Datepicker = require('../inputs/Datepicker.view'),
    Timepicker = require('../inputs/Timepicker.view'),
    SingleSelectize = require('../inputs/SingleSelectize.view');

module.exports = {

    initInput: function(inputName, options, clazz) {
        var $input = this.$('form').find('[name=' + inputName + ']');

        if($input.length) {
            var opt = options || {};
            _.extend(opt, {el: $input});
            return new clazz(opt);
        } else {
            throw new Error('Cannot find input with name "' + inputName + '"')
        }
    },

    initDatepicker: function(inputName, options) {
        return this.initInput(inputName, options, Datepicker);
    },

    initTimepicker: function(inputName, options) {
        return this.initInput(inputName, options, Timepicker);
    },

    initSingleSelectize: function(inputName, options) {
        if(!options.modelAttr){
            options.modelAttr = inputName;
        }
        return this.initInput(inputName, options, SingleSelectize);
    },

    clearForm: function() {
        this.$('form').find('input[type=text]').val('');
    },

    focusFirstInput: function() {
        var $input = this.$('form').find('input').first();
        if($input.length) {
            $input.focus();
        }
    },

    focusInput: function(inputName) {
        var $input = this.$('form').find('[name=' + inputName + ']');
        if($input.length) {
            $input.focus();
        }
    },

    disableInputs: function(inputNames) {
        _.each(inputNames, (inputName) => {
            var $input = this.$('form').find('[name=' + inputName + ']');
            var $label = $input.siblings('label');
            if($input.length) $input.prop('disabled', true);
            if($label.length) $label.removeClass('required');
        });
    },

    getGroup(name) {
        if (name && this.groups) return this.groups.findByCustom(name);
    },

    getInput(name) {
        if (name && this.inputs) return this.inputs.findByCustom(name);
    },

    /**
     * Check if the hiddenInputs array does not contain the input name.
     *
     * @param {string} inputName
     * @returns {boolean}
     */
    isNotHidden: function(inputName){
        return !_.contains(this.hiddenInputs, inputName)
    },

    /**
     * Check if the disabledInputs array does not contain the input name.
     *
     * @param {string} inputName
     * @returns {boolean}
     */
    isNotDisabled: function(inputName){
        return !_.contains(this.disabledInputs, inputName)
    },

    /**
     * Check if the disabledInputs and hiddenInputs arrays do not contain the input name.
     *
     * @param {string} inputName
     * @returns {boolean}
     */
    isAvailable: function(inputName){
        return this.isNotHidden(inputName) && this.isNotDisabled(inputName)
    }
};
