import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Cocktail from 'backbone.cocktail';
import Handle from 'backbone-handle';
import ViewOptions from 'backbone-view-options';
import Handlebars from 'handlebars-template-loader/runtime';
import Tooltip from 'tether-tooltip';
import Toastr from 'toastr';
import {
    PAGE_SIZE_DEFAULT,
    PAGE_SIZE_OPTION_LABELS,
    PAGE_SIZE_OPTION_VALUES,
} from 'app/backbone/components/tables/DataTable.view';

import 'babel-polyfill'; // Needs to be a dep not devDep
import 'whatwg-fetch';
import 'foundation';
import 'datatables.net';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import 'pickadate/lib/picker.time';
import 'backbone-super';
import 'backbone-validation';
import 'backbone.babysitter';
import 'backbone.stickit';
import 'backgrid';
import 'backgrid-select-all';
import 'backgrid-grouped-columns';
import 'backgrid-filter';
import 'froala-editor/js/froala_editor.min';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/fullscreen.min';

import Services from 'app/services';

export default class Config {

    static jquery() {
        $.fn.focusAtTextEnd = function () {
            this.focus().val(this.val());
        };

        $.fn.isFocused = function () {
            return this.is(':focus');
        };
    }

    static backbone() {
        // TODO this needs to be refactored
        if (window.beatswitch && window.beatswitch.auth) {
            $.ajaxSetup({
                cache: false,
                headers: { Authorization: 'Bearer '.concat(window.beatswitch.auth.jwt) }
            });
        }

        Backbone.$ = $;
        Backbone._ = _;
        Cocktail.patch(Backbone);

        _.extend(Backbone.Model.prototype, Backbone.Validation.mixin);
        ViewOptions.add(Backbone.View.prototype);
        Handle.add(Backbone.View.prototype);
    }

    static handlebars() {
        // Helpers
        Handlebars.registerHelper('is', (value, test, options) => (
            value && value === test ? options.fn(this) : options.inverse(this)
        ));
        Handlebars.registerHelper('inArray', (array, value, options) => (
            array.indexOf(value) >= 0 ? options.fn(this) : options.inverse(this)
        ));
        Handlebars.registerHelper('capitalizeFirst', (str) => (
            str.charAt(0).toUpperCase() + str.slice(1)
        ));
        Handlebars.registerHelper('capitalizeEach', (str) => (
            str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1))
        ));
        // Partials
        // Forms
        Handlebars.registerPartial('CompanyForm', require('app/backbone/views/account/crm/companies/forms/CompanyForm.hbs'));
        Handlebars.registerPartial('ContactForm', require('app/backbone/views/account/crm/contacts/forms/ContactForm.hbs'));
        // Inputs
        Handlebars.registerPartial('CountryOptions', require('app/backbone/components/inputs/CountryOptions.hbs'));
        // Errors
        Handlebars.registerPartial('Error', require('app/backbone/components/inputs/errors/Error.hbs'));
        Handlebars.registerPartial('Success', require('app/backbone/components/inputs/errors/Succes.hbs'));
        Handlebars.registerPartial('Warning', require('app/backbone/components/inputs/errors/Warning.hbs'));
        // Placeholders
        Handlebars.registerPartial('SyncingPlaceholder', require('app/backbone/components/placeholders/SyncingPlaceholder.hbs'));
        Handlebars.registerPartial('NoRecordsPlaceholder', require('app/backbone/components/placeholders/NoRecordsPlaceholder.hbs'));
        // Modals
        Handlebars.registerPartial('CreateButtonRow', require('app/backbone/components/modals/partials/CreateButtonRow.hbs'));
        Handlebars.registerPartial('UpdateButtonRow', require('app/backbone/components/modals/partials/UpdateButtonRow.hbs'));
        Handlebars.registerPartial('ModalCreateButtonRow', require('app/backbone/components/modals/partials/ModalCreateButtonRow.hbs'));
        Handlebars.registerPartial('ModalUpdateButtonRow', require('app/backbone/components/modals/partials/ModalUpdateButtonRow.hbs'));
        Handlebars.registerPartial('ModalTitle', require('app/backbone/components/modals/partials/ModalTitle.hbs'));
        Handlebars.registerPartial('ModalBody', require('app/backbone/components/modals/partials/ModalBody.hbs'));
        Handlebars.registerPartial('SyncModal', require('app/backbone/components/modals/partials/SyncModal.hbs'));
    }

    static foundation() {
        $(document).foundation();
    }

    static datatables() {
        $.extend(true, $.fn.dataTable.defaults, {
            language: {
                emptyTable: 'No data available in table',
                info: 'Showing _START_ - _END_ of _TOTAL_',
                infoEmpty: '',
                infoFiltered: '',
                infoPostFix: '',
                thousands: ',',
                lengthMenu: 'Show: _MENU_',
                loadingRecords: 'Loading...',
                processing: 'Processing...',
                search: 'Search:',
                zeroRecords: 'No matching records found.',
                paginate: {
                    first: 'First',
                    last: 'Last',
                    next: 'Next',
                    previous: 'Previous'
                }
            },
            columnDefs: [{
                targets: 'no-sort',
                orderable: false
            }],
            autoWidth: false,
            lengthMenu: [
                PAGE_SIZE_OPTION_VALUES,
                PAGE_SIZE_OPTION_LABELS,
            ],
            displayLength: PAGE_SIZE_DEFAULT,
        });
    }

    static tooltips() {
        Tooltip.autoinit = false;
    }

    static toastr() {
        Toastr.options = {
            tapToDismiss: false,
            showDuration: 0,
            hideDuration: 300,
            timeOut: 6000,
            extendedTimeOut: 6000,
            closeButton: true,
            progressBar: true,
            closeHtml: '<button><i class="bs-icon-cross"></i></button>'
        };
    }

    static froala() {
        // Set licence key
        $.FroalaEditor.DEFAULTS.key = Services.froalaKey;

        // Define popup template.
        $.extend($.FroalaEditor.POPUP_TEMPLATES, {
            "customPlugin.popup": '[_CUSTOM_LAYER_]'
        });

        // The custom popup is defined inside a plugin (new or existing).
        $.FroalaEditor.PLUGINS.customPlugin = function(editor) {
            // Create custom popup.
            function initPopup() {
                // Load popup template.
                var template = {
                    custom_layer: '<div class="fr-video-embed-layer fr-layer fr-active" id="fr-video-embed-layer-1"><div class="fr-input-line"><textarea type="text" placeholder="Place your iframe code here" tabindex="1" rows="5" dir="auto"></textarea><span>Embedded Code</span></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="embedIframe" tabindex="2">Insert</button></div></div>'
                };

                // Create popup.
                var $popup = editor.popups.create('customPlugin.popup', template);

                return $popup;
            }

            // Show the popup
            function showPopup() {
                // Get the popup object defined above.
                var $popup = editor.popups.get('customPlugin.popup');

                // If popup doesn't exist then create it.
                // To improve performance it is best to create the popup when it is first needed
                // and not when the editor is initialized.
                if (!$popup) $popup = initPopup();

                // Set the editor toolbar as the popup's container.
                editor.popups.setContainer('customPlugin.popup', editor.$tb);


                // This custom popup is opened by pressing a button from the editor's toolbar.
                // Get the button's object in order to place the popup relative to it.
                var $btn = editor.$tb.find('.fr-command[data-cmd="insertIframe"]');

                // Set the popup's position.
                var left = $btn.offset().left + $btn.outerWidth() / 2;
                var top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

                // Show the custom popup.
                // The button's outerHeight is required in case the popup needs to be displayed above it.
                editor.popups.show('customPlugin.popup', left, top, $btn.outerHeight());
            }

            // Hide the custom popup.
            function hidePopup() {
                editor.popups.hide('customPlugin.popup');
            }

            function getPopup() {
                return editor.popups.get('customPlugin.popup');
            }

            // Methods visible outside the plugin.
            return {
                showPopup,
                hidePopup,
                getPopup
            };
        }

        // Define an icon and command for the button that opens the custom popup.
        $.FroalaEditor.DefineIcon('buttonIcon', { NAME: 'code' })
        $.FroalaEditor.RegisterCommand('insertIframe', {
            title: 'Insert iframe',
            icon: 'buttonIcon',
            undo: false,
            focus: false,
            plugin: 'customPlugin',
            refreshAfterCallback: true,
            callback: function () {
                this.customPlugin.showPopup();
            }
        });

        // Define custom popup 1.
        $.FroalaEditor.RegisterCommand('embedIframe', {
            title: 'Insert iframe',
            focus: true,
            undo: false,
            refreshAfterCallback: true,
            callback: function () {
                var $popup = this.customPlugin.getPopup();
                var code = $popup.find('.fr-video-embed-layer textarea').val() || '';
                this.html.insert(code);
                $('textarea.fr-not-empty').val('');
                this.customPlugin.hidePopup();
            }
        });

        $.FroalaEditor.isConfigured = true;
    }
}
