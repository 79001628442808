import moment from 'moment'
import {
    filter,
    isEmpty,
    forOwn,
} from 'lodash'
import BaseView from 'app/backbone/components/Base.view'
import Datepicker from 'app/backbone/components/inputs/Datepicker.view'
import Timepicker from 'app/backbone/components/inputs/Timepicker.view'

const DEFAULT_EARLY_CHECK_IN_TIME = '09:00';
const DEFAULT_LATE_CHECK_OUT_TIME = '15:00';

const accommodationJsonDetailsRoute = (
    eventId = ':event',
    accommodationId = ':accommodation',
) => `/events/${eventId}/accommodations/${accommodationId}/ajax`;

export default class ReservationFormView extends BaseView {

    ui() {
        return {
            accommodationDropdownContainer: '#accommodation-select',
            accommodationDropdown: 'select#accommodation',
            editAccommodationButton: '#edit-accommodation-url',
            roomTypeDropdown: '[name=room_type]',
            roomTypeDropdownInfo: '.roomTypeDropdownInfo',
            roomTypeNameField: '[name=room_type_name]',
            roomTypeNameFieldInfo: '.roomTypeTextInfo',
            checkInDatePicker: '[name=check_in_date]',
            checkInTimePicker: '[name=check_in_time]',
            earlyCheckIn: '[name=early_check_in]',
            checkOutDatePicker: '[name=check_out_date]',
            checkOutTimePicker: '[name=check_out_time]',
            lateCheckOut: '[name=late_check_out]',
            reservationName: '[name=reservation]',
            attendees: '#attendee-list :checkbox',
            guestAmount: '[name=guest_amount]',
        };
    }

    events() {
        return {
            'change accommodationDropdown': 'handleAccommodationChange',
            'change earlyCheckIn': 'toggleCheckInTime',
            'change lateCheckOut': 'toggleCheckOutTime',
            'click attendees': 'handleGuestSelection',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderCheckInDatePicker();
        this.renderCheckInTimePicker();
        this.renderCheckOutDatePicker();
        this.renderCheckOutTimePicker();
        return this.delegateEvents();
    }

    renderCheckInDatePicker() {
        this.checkInDatePicker = new Datepicker({ el: this.ui.checkInDatePicker });
        this.checkInDatePicker.render();
    }

    renderCheckInTimePicker() {
        this.checkInTimePicker = new Timepicker({ el: this.ui.checkInTimePicker });
        this.checkInTimePicker.render();
        this.checkInTimeFormat = this.ui.checkInTimePicker.data('momentjs-format');
        this.previousCheckInTime = this.ui.checkInTimePicker.val();
    }

    renderCheckOutDatePicker() {
        this.checkOutDatePicker = new Datepicker({ el: this.ui.checkOutDatePicker });
        this.checkOutDatePicker.render();
    }

    renderCheckOutTimePicker() {
        this.checkOutTimePicker = new Timepicker({ el: this.ui.checkOutTimePicker });
        this.checkOutTimePicker.render();
        this.checkOutTimeFormat = this.ui.checkOutTimePicker.data('momentjs-format');
        this.previousCheckOutTime = this.ui.checkOutTimePicker.val();
    }

    handleAccommodationChange(e) {
        const accommodationId = this.ui.accommodationDropdown.val();
        const eventId = this.ui.accommodationDropdown.data('event');
        const editUri = this.ui.accommodationDropdown.find(':selected').data('edit-url');

        if (this.ui.editAccommodationButton && editUri) {
            this.ui.editAccommodationButton
                .removeClass('hidden')
                .attr('href', editUri);
            this.ui.accommodationDropdownContainer.addClass('postfixedInput');
        } else {
            this.ui.editAccommodationButton
                .addClass('hidden')
                .attr('href', '');
            this.ui.accommodationDropdownContainer.removeClass('postfixedInput');
        }

        if (accommodationId) {
            $.get(
                accommodationJsonDetailsRoute(eventId, accommodationId),
                this.toggleRoomTypeDropdown.bind(this),
                'json'
            );
        } else {
            this.ui.roomTypeDropdownInfo.addClass('hide');
            this.ui.roomTypeNameFieldInfo.addClass('hide');
        }
    }

    toggleRoomTypeDropdown({ room_types: roomTypes }) {
        if (isEmpty(roomTypes)) {
            this.ui.roomTypeNameField.show();
            this.ui.roomTypeNameFieldInfo.removeClass('hide');
            this.ui.roomTypeDropdownInfo.addClass('hide');

            if (this.ui.roomTypeDropdown.length) {
                this.ui.roomTypeDropdown.empty().hide();
            }
        } else {
            this.ui.roomTypeNameField.hide();
            this.ui.roomTypeNameFieldInfo.addClass('hide');
            this.ui.roomTypeDropdownInfo.removeClass('hide');

            if (this.ui.roomTypeDropdown.length) {
                this.ui.roomTypeDropdown.empty();

                forOwn(roomTypes, (name, id) => {
                    this.ui.roomTypeDropdown.append(
                        $(`<option value="${id}">${name}</option>`)
                    );
                });

                this.ui.roomTypeDropdown.show();
            }
        }
    }

    toggleCheckInTime(e) {
        const hasEarlyCheckIn = e.target.checked;
        const currentTime = this.ui.checkInTimePicker.val();
        const earlyCheckInTime = moment(DEFAULT_EARLY_CHECK_IN_TIME, 'H:i').format(this.checkInTimeFormat);

        this.ui.checkInTimePicker.val(hasEarlyCheckIn ? earlyCheckInTime : this.previousCheckInTime);
        this.previousCheckInTime = currentTime;
    }

    toggleCheckOutTime(e) {
        const hasLateCheckOut = e.target.checked;
        const currentTime = this.ui.checkOutTimePicker.val();
        const lateCheckOutTime = moment(DEFAULT_LATE_CHECK_OUT_TIME, 'H:i').format(this.checkOutTimeFormat);

        this.ui.checkOutTimePicker.val(hasLateCheckOut ? lateCheckOutTime : this.previousCheckOutTime);
        this.previousCheckOutTime = currentTime;
    }

    handleGuestSelection() {
        const attendeesMarkedAsGuest = this.attendeesMarkedAsGuest();
        const reservationName = attendeesMarkedAsGuest.map(attendee => attendee.dataset.name).join(', ');
        this.ui.guestAmount.val(attendeesMarkedAsGuest.length || '');
        this.ui.reservationName.val(reservationName);
    }

    attendeesMarkedAsGuest() {
        return filter(this.ui.attendees, (attendee) => attendee.checked);
    }
}
