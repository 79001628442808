var _ = require('underscore'),
    Backbone = require('backbone');

var ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin'),
    Modal = require('app/backbone/components/modals/Modal.view'),
    Period = require('app/backbone/models/event/settings/accreditation/Period');

var template = require('./PeriodModal.hbs');

var PeriodModal = Modal.extend({

    template,

    mixins: [
        ValidatedForm,
        Form
    ],

    bindings: {
        '[name=name]': 'name',
        '[name=start_date]': 'start_date',
        '[name=end_date]': 'end_date'
    },

    options: [
        'width',
        'canCreateAnother',
        'hiddenInputs',
        'disabledInputs'
    ],

    initialize(options) {
        this._super(options);
        // Options
        !_.isUndefined(this.hiddenInputs) || (this.hiddenInputs = []);
        !_.isUndefined(this.disabledInputs) || (this.disabledInputs = []);
        !_.isUndefined(this.canCreateAnother) || (this.canCreateAnother = true);
        !_.isUndefined(this.width) || (this.width = 500);
        // SubViews
        this.inputs = new Backbone.ChildViewContainer();
        this.groups = new Backbone.ChildViewContainer();
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        if(!this.isSyncing) {
            this.unbindModel();
            this.initForm(this.model);
            this.bindModel();
            this.renderForm();
        }
        this._super();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                isUpdating:  this.isUpdating,
                title: this.title,
                canCreateAnother: this.canCreateAnother,
                hiddenInputs: this.hiddenInputs,
                syncingText: this.syncingText,
                isSyncing: this.isSyncing,
                height: this.height
            },
            model: this.model.attributes
        };
    },

    initForm(model) {
        this.disableInputs(this.disabledInputs);

        if(this.isAvailable('start_date')) this.inputs.add(this.initDatepicker('start_date'), 'start_date');
        if(this.isAvailable('end_date')) this.inputs.add(this.initDatepicker('end_date'), 'end_date');

        if(this.isAvailable('name')) this.groups.add(this.initValidationGroup('name', model), 'name');
        if(this.isAvailable('start_date')) this.groups.add(this.initValidationGroup('start_date', model), 'start_date');
        if(this.isAvailable('end_date')) this.groups.add(this.initValidationGroup('end_date', model), 'end_date');
    },

    renderForm() {
        this.inputs.call('render');
        this.groups.call('render');
    },

    bindModel() {
        if(this.model) {
            this.stickit();
            this.listenTo(this.model, 'change:start_date', this.onChangeStartDate);
        }
    },

    newModel(attributes, options) {
        return new Period.Model(attributes, options);
    },

    unbindModel() {
        if(this.model) {
            this.unstickit();
            this.stopListening(this.model);
        }
    },

    setSyncing(syncing) {
        if(syncing != this.isSyncing){
            this.isSyncing = syncing;
            this.render();
        }
    },

    onChangeStartDate(model, value) {
        if(!this.model.has('end_date')) {
            this.model.set('end_date', value);
            this.inputs.findByCustom('end_date').render();
        }
    },

    onError(model, xhr){
        this.setSyncing(false);
        this.validateResponse(xhr);
    },

    shown() {
        this._super();
        this.focusFirstInput();
    }
});

module.exports = PeriodModal;
