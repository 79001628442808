import { oneLineTrim } from 'common-tags';
import { limitAllParam as pageSizeAllParam } from 'app/react/state/middleware/api'

export const fetchAll = (
    eventId
) => oneLineTrim`
    events/${eventId}/stages?
    ${pageSizeAllParam}
`;

export const updateStagesSequence = (
    eventId,
    dayId
) => `events/${eventId}/days/${dayId}/stages/sequences`;