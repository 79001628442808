import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Avatar,
    Button,
    TimeIcon,
    EventIcon,
    ExpandHorizontalIcon,
    PlaceIcon,
    VisibleIcon,
    HiddenIcon,
} from 'ui-kit';
import moment from 'moment';
import { humanizeDifference } from 'app/react/helpers/_index';
import HeroModal from 'app/react/components/modals/HeroModal'
import TimeSlotTable from './time_slot_table/TableContainer';
import BookingTable from './booking_table/TableContainer';
import './Modal.module.css';

const {
    PanelList,
    PanelListItem
} = HeroModal;

export const DETAILS_MODAL_ID = 'performanceDetailsModal';

const BOOKING_TABLE_TITLE = 'Bookings';
const SCHEDULE_TABLE_TITLE = 'Schedule';

const PublicationBadge = ({ isPublished }) => (
    <div styleName={classNames('publicationBadge', { isPublished })}>
        {isPublished
            ? <VisibleIcon size={16} />
            : <HiddenIcon size={16} />
        }
    </div>
);

const Panel = ({
    performance,
    day,
    stage,
    dateFormat,
    timeFormat,
    artistImageUrl,
    isPublishing,
    onTogglePublishPerformance,
}) => {
    const {
        title,
        changeover,
        start,
        end,
    } = performance;
    const mStart = moment.utc(start);
    const mEnd = moment.utc(end);
    const setTime = humanizeDifference(start, end);
    return (
        <Fragment>
            <div styleName="avatarContainer">
                <Avatar
                    size={10}
                    type={Avatar.CIRCLE}
                    name={title || ''}
                    imageUrl={artistImageUrl}
                />
                <PublicationBadge isPublished={performance.published} />
            </div>
            <PanelList>
                {changeover && (
                    <PanelListItem>
                        <span>
                            <ExpandHorizontalIcon size={16} />
                        </span>
                        {changeover}m
                    </PanelListItem>
                )}
                <PanelListItem>
                    <span>
                        <TimeIcon size={16} />
                    </span>
                    {mStart.format(timeFormat)} - {mEnd.format(timeFormat)} {setTime}
                </PanelListItem>
                <PanelListItem>
                    <span>
                        <EventIcon size={16} />
                    </span>
                    {day.name} {mStart.format(dateFormat)}
                </PanelListItem>
                <PanelListItem>
                    <span>
                        <PlaceIcon size={16} />
                    </span>
                    {stage.name}
                </PanelListItem>
            </PanelList>
            <Button
                type={performance.published ? Button.PRIMARY : Button.SUCCESS}
                isExpanded
                isLoading={isPublishing}
                onClick={onTogglePublishPerformance}
            >
                {performance.published ? 'Unpublish' : 'Publish now'}
            </Button>
        </Fragment>
    );
};

const PerformanceDetailsModal = ({
    isOpened = false,
    isSyncing = false,
    isPublishing = false,
    performance = {},
    firstArtist = {},
    day = {},
    stage = {},
    productionWebsite = {},
    dateFormat,
    timeFormat,
    onTogglePublishPerformance = () => {},
    onClosed = () => {},
}) => {
    return (
        <HeroModal
            width="120rem"
            panelWidth="20%"
            heroImg={productionWebsite.backgroundUrl === "" ? undefined : productionWebsite.backgroundUrl}
            title={performance.title}
            renderPanel={() => (
                <Panel
                    performance={performance}
                    isPublishing={isPublishing}
                    published={performance.published}
                    artistImageUrl={firstArtist.imageSize150}
                    day={day}
                    stage={stage}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    onTogglePublishPerformance={onTogglePublishPerformance}
                />
            )}
            isOpened={isOpened}
            onClose={onClosed}
        >
            <h4>{BOOKING_TABLE_TITLE}</h4>
            <BookingTable />
            <h4>{SCHEDULE_TABLE_TITLE}</h4>
            <TimeSlotTable />
        </HeroModal>
    )
};

PerformanceDetailsModal.propTypes = {
    store: PropTypes.object.isRequired,
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    selectedPerformance: PropTypes.object,
    selectedPerformanceDay: PropTypes.object,
    selectedPerformanceStage: PropTypes.object,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    onEdit: PropTypes.func,
    onClosed: PropTypes.func,
};

export default PerformanceDetailsModal;
