import React from 'react'
import PropTypes from 'prop-types'
import { TableSortCell, TableHeaderCell } from 'ui-kit'

const PickupsTableHeaderRow = ({
    sortKey,
    sortOrder,
    onSort = () => {},
}) => (
    <tr>
        <TableSortCell
            isCentered
            style={{ width: '40px' }}
            sortKey="is_arrived"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        />
        <TableHeaderCell style={{ width: '50px' }}/>
        <TableSortCell
            sortKey="artist"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Artist
        </TableSortCell>
        <TableSortCell
            style={{ width: '100px' }}
            isCentered
            sortKey="passenger_amount"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Passengers
        </TableSortCell>
        <TableSortCell
            sortKey="driver"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Driver
        </TableSortCell>
        <TableSortCell
            style={{ width: '150px' }}
            sortKey="driver_leave_time"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Leave Time
        </TableSortCell>
        <TableSortCell
            style={{ width: '150px' }}
            sortKey="departure"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Pickup Time
        </TableSortCell>
        <TableSortCell
            sortKey="departure_location"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            From
        </TableSortCell>
        <TableSortCell
            sortKey="arrival_location"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            To
        </TableSortCell>
        <TableHeaderCell style={{ width: '80px' }} isCentered>
            Notes
        </TableHeaderCell>
        <TableHeaderCell style={{ width: '50px' }} />
    </tr>
);

PickupsTableHeaderRow.propTypes = {
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func
};

export default PickupsTableHeaderRow;
