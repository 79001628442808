import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'item_group'
})
class ItemGroup extends BaseModel {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model,
            items: require('./Item').Collection
        }
    }

    get defaults() {
        return {
            icon: 1
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a name.'
            }
        }
    }

    initialize(model, options  = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('account');
        const account = this.getRelation('account');
        return `/api/accounts/${account.id}/item-groups`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }
}

@Collection({
    key: 'item_groups',
    model: ItemGroup,
    comparator: 'weight'
})
class ItemGroupCollection extends BaseCollection {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model
        }
    }

    url() {
        this.validateRelations('account');
        const account = this.getRelation('account');
        return `/api/accounts/${account.id}/item-groups`;
    }
}

const PageableCollection = ItemGroupCollection.prototype.PageableCollection();

export {
    ItemGroup as Model,
    ItemGroupCollection as Collection,
    PageableCollection
};
