import { mergeComponentState } from './components';

export const openModal = (storeId) => (
    mergeComponentState(storeId, {
        isOpened: true
    })
);

export const closeModal = (storeId) => (
    mergeComponentState(storeId, {
        isOpened: false,
        isSyncing: false,
        footer: {}
    })
);

export const setModalSyncing = (storeId, isSyncing) => (
    mergeComponentState(storeId, {
        isSyncing
    })
);

export const openConfirmModal = (storeId, action) => (
    mergeComponentState(storeId, {
        isOpened: true,
        action
    })
);

export const closeConfirmModal = (storeId) => (
    mergeComponentState(storeId, { isOpened: false })
);
