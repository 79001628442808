import React from 'react'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import Header from 'app/react/components/layout/content/header/Header'
import TimetablePreview from 'app/react/views/shared/marketing/timetable_web_embed/TimetablePreview'
import CTAs from 'app/react/views/shared/marketing/CTAs'

const { Content } = ContentWrapper;
const { TitleMenu } = Header;
const { Title } = TitleMenu;

const TITLE = 'Timetable web embed';
const INTERCOM_MESSAGE = 'I’m interested in the timetable web embed. Can you tell me how can I get this product?';

const Overview = () => (
    <ContentWrapper>
        <Header>
            <TitleMenu>
                <Title>{TITLE}</Title>
            </TitleMenu>
        </Header>
        <Content>
            <TimetablePreview />
            <div className="text-align-center margin-medium-top">
                <h5>Easily embed your timetable on your website!</h5>
                <p>In just a few clicks and always in sync with your Beatswitch account.</p>
                <CTAs intercomMessage={INTERCOM_MESSAGE} />
            </div>
        </Content>
    </ContentWrapper>
);

export default Overview;
