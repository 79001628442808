import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
    SchedulerTopBar,
    Badge,
} from 'ui-kit';

const { Cell } = Grid;

const STATUS_TEXT = 'Performance statuses';
const TIMESCALE_TEXT = 'Time scale';

const TopBar = ({
    isShowingMcs = false,
    activeInterval = '00:15',
    statuses = [],
    onChangeInterval = () => {}
}) => (
    <SchedulerTopBar
        activeInterval={activeInterval}
        onChangeInterval={onChangeInterval}
    >
        <p>
            <strong>{STATUS_TEXT}: </strong>
            {statuses.map(({ title, colorType }, key) => (
                <Badge key={key} type={colorType}>{title}</Badge>
            ))}
        </p>
    </SchedulerTopBar>
);

TopBar.propTypes = {
    isShowingMcs: PropTypes.bool,
    activeInterval: PropTypes.string,
    statuses: PropTypes.array,
    onChangeInterval: PropTypes.func
};

export default TopBar;
