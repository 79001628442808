import BaseView from 'app/backbone/components/Base.view';

export default class ArtistsOverviewView extends BaseView {

  ui() {
    return {
      table: '#artists-table',
    };
  }

  initialize(options = {}) {
    super.initialize(options);
    // this.table = new DataTable();
  }

  render() {
    // Enhanced view, html provided by server.
    this.resolveHandles();
    //  this.assignSubview(this.table, this.ui.table);
    return this.delegateEvents();
  }

}
