import { Model, Collection } from 'app/backbone/models/decorators';
import * as Item from 'app/backbone/models/account/accreditation/Item';
import * as EventItemGroup from './EventItemGroup';

@Model({
    key: 'event_item'
})
class EventItem extends Item.Model {

    static Relations() {
        return {
            event: require('../../Event').Model, // Circular
            item_group: EventItemGroup.Model
        }
    }

    urlRoot() {
        this.validateRelations('event');
        let eventId = this.event.id;
        return `/api/events/${eventId}/items`;
    }
}

@Collection({
    key: 'event_items',
    model: EventItem
})
class EventItemCollection extends Item.Collection {

    static Relations() {
        return {
            event: require('../../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        let eventId = this.event.id;
        return `/api/events/${eventId}/items`;
    }
}

const PageableCollection = EventItemCollection.prototype.PageableCollection();

export {
    EventItem as Model,
    EventItemCollection as Collection,
    PageableCollection
};
