export function isPerformer({ __typename }) {
    return __typename === 'Performer';
}

export function isCrew({ __typename }) {
    return __typename === 'CrewMember';
}

export function isGuest({ __typename }) {
    return __typename === 'Guest';
}
