import React from 'react'
import './AllDataFetchedPlaceholder.module.css'

const AllDataFetchedPlaceholder = () => (
    <div styleName="placeholder">
        <i
            styleName="icon"
            className="bs-icon-star-four-points"
        />
        All data's been loaded!
    </div>
);

export default AllDataFetchedPlaceholder;
