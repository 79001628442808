import SubTableRow from 'app/backbone/components/tables/rows/sub_table/SubTableRow.view';

export default class CompanyProfileCenterRow extends SubTableRow {

    getSubTableCollection() {
        return this.model.getRelation('available_items');
    }

}

