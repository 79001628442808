import NavigationCollapsable from '../NavigationCollapsable.view';

export default class SidebarAccounts extends NavigationCollapsable {

    initialize(options = {}) {
        super.initialize(options);
        this.shown = false;
    }

    toggle() {
        this.$el.removeClass('hidden');
        this.$el.toggleClass('slideInLeft slideOutLeft');
        this.shown = !this.shown;
    }

    show() {
        this.$el.addClass('slideInLeft');
        this.$el.removeClass('slideOutLeft');
        this.shown = true;
    }

    hide() {
        this.$el.addClass('slideOutLeft');
        this.$el.removeClass('slideInLeft');
        this.shown = false;
    }

}

