import React from 'react'
import PropTypes from 'prop-types'
import { AlertBox, InputGroup } from 'ui-kit'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import { UpdateButtonRow, ConfirmModal } from 'app/react/components/_index'
import ContentHeader, { TABS } from '../../../views/ContentHeader'
import Card from './cards/SectionsCard'
import SectionsTable from './table/Table'
import AllDeadlines from './inputs/AllDeadlines'

const { Content } = ContentWrapper

export const CONFIRM_MODAL_TITLE = 'Unsaved changes';
export const CONFIRM_MODAL_TEXT = 'You have unsaved changes. Are you sure you want to leave?';
export const BACK_TO_ARTIST_TYPES_BUTTON_TEXT = 'Back';
const WARNING_TEXT = "Any changes to these settings will cascade to all bookings of the Artist type that " +
    "aren't fully closed yet. Fully closed means that all enabled sections for the booking are closed.";

const ArtistsFormOverview = ({
    store,
    eventId,
    eventDateFormat = 'L',
    isFetching = false,
    isArtistTypesEnabled = true,
    artistTypeName,
    formState = {},
    cardState = {},
    confirmModalState = {},
    onConfirmModalClosed = () => {},
    onConfirmModalConfirmed = () => {},
    onBackToArtistTypes = () => {},
    onChangeDeadline = () => {},
    onChangeCascadeToAllBookings = () => {},
    onCopyDeadlineToSections = () => {},
    onEditSection = () => {},
    onChangeSectionValue = () => {},
    onSaveSettings = () => {}
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            activeTab={TABS.ARTIST_TYPES.ID}
            eventId={eventId}
            artistTypesEnabled={isArtistTypesEnabled}
        >
            {isArtistTypesEnabled && (
                <a className="button primary" onClick={onBackToArtistTypes}>
                    {BACK_TO_ARTIST_TYPES_BUTTON_TEXT}
                </a>
            )}
        </ContentHeader>
        <Content isLoading={isFetching}>
            <Card
                isArtistTypesEnabled={isArtistTypesEnabled}
                artistTypeName={artistTypeName}
                footer={cardState.footer}
            >
                <AlertBox
                    isSubtle
                    hasIconPrefix
                    type={AlertBox.WARNING}
                >
                    {WARNING_TEXT}
                </AlertBox>
                <AllDeadlines
                    settings={formState.values}
                    dateFormat={eventDateFormat}
                    onChangeDeadline={onChangeDeadline}
                    onCopyDeadlineToSections={onCopyDeadlineToSections}
                />
                <SectionsTable
                    sections={formState.values.sections}
                    dateFormat={eventDateFormat}
                    onEdit={onEditSection}
                    onChangeValue={onChangeSectionValue}
                />
                <div className="text-align-right margin-medium-top">
                    <InputGroup>
                        <label>
                            Cascade changes to all (including fully closed) bookings
                            <input
                                type="checkbox"
                                name="cascadeToAllBookings"
                                className="margin-tiny-left"
                                checked={formState.values.shouldCascadeToAllBookings}
                                onChange={(e) => onChangeCascadeToAllBookings(e.target.checked)}
                            />
                        </label>
                    </InputGroup>
                </div>
            </Card>
            <UpdateButtonRow onUpdate={onSaveSettings} />
        </Content>
        <ConfirmModal
            isOpened={confirmModalState.isOpened}
            onConfirmed={onConfirmModalConfirmed}
            onClosed={onConfirmModalClosed}
            title={CONFIRM_MODAL_TITLE}
            text={CONFIRM_MODAL_TEXT}
        />
    </ContentWrapper>
);

ArtistsFormOverview.propTypes = {
    store: PropTypes.object.isRequired,
    // State
    isFetching: PropTypes.bool,
    eventId: PropTypes.number.isRequired,
    eventDateFormat: PropTypes.string,
    isArtistTypesEnabled: PropTypes.bool,
    artistTypeName: PropTypes.string,
    formState: PropTypes.object,
    cardState: PropTypes.object,
    confirmModalState: PropTypes.object,
    onConfirmModalClosed: PropTypes.func,
    onConfirmModalConfirmed: PropTypes.func,
    onBackToArtistTypes: PropTypes.func,
    onChangeDeadline: PropTypes.func,
    onCopyDeadlineToSections: PropTypes.func,
    onEditSection: PropTypes.func,
    onChangeSectionValue: PropTypes.func,
    onSaveSettings: PropTypes.func
};

export default ArtistsFormOverview;
