import AccrediteeGrantedDayCell from 'app/backbone/views/event/accreditation/accreditees/items/tables/cells/GrantedDayCell.view';

export default class GrantedDayCell extends AccrediteeGrantedDayCell {

    isChecked() {
        return !this.isDisabled() && this.model.hasGrantedDayOn(this.day);
    }

    isDisabled() {
        return !this.accrediteeProfiles.someHaveItemWithAvailableDayOnAndHasGrantedDayOn(this.model, this.day);
    }

}
