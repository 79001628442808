import OptionTypes from 'app/backbone/helpers/OptionTypes';
import AccrediteeItemsCard from 'app/backbone/views/event/accreditation/accreditees/items/cards/AccrediteeItemsCard.view';
import AccrediteeItemTable from '../tables/AccrediteeItemTable.view';
import AccrediteeItemAddModal from '../modals/CompanyAccrediteeItemAddModal.view';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';

export default class CompanyAccrediteeItemsCard extends AccrediteeItemsCard {

    optionTypes() {
        const { title, periods } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(CompanyAccreditee.Model).isRequired,
            periods,
            title
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        // Props
        this.accrediteeProfiles = this.model.getRelation('profiles');
        this.accrediteeItems = this.model.getRelation('items');
        this.items = this.accrediteeProfiles.getAllUniqueAvailableItems();

        // Subviews
        this.addModal = this.initAddModel();

        // Listeners
        this.listenTo(this.accrediteeProfiles, 'update reset', this.onUpdateOrResetProfiles);
        this.listenTo(this.accrediteeItems, 'update reset', this.onUpdateOrResetItems);
        this.listenTo(this.model, 'validated', this.onValidated);

        super.initialize(options);
    }

    initContent() {
        const { model, periods } = this;
        const collection = model.getRelation('items');
        return new AccrediteeItemTable({ collection, periods });
    }

    initAddModel() {
        const items = this.getModalItems();
        const addModal = new AccrediteeItemAddModal({ items });
        this.listenTo(addModal, 'add', this.onAdded);
        return addModal;
    }

    onUpdateOrResetItems() {
        this.updateModalItems();
        this.updateModalButton();
        if (this.hasError()) this.model.validate();
    }

    onUpdateOrResetProfiles() {
        const { accrediteeProfiles, accrediteeItems } = this;
        const availableItems = accrediteeProfiles.getAllUniqueAvailableItems();

        this.items = availableItems;

        accrediteeItems.forEach((item) => {
            const itemFound = availableItems.get(item.id);
            if (itemFound) {
                itemFound.setRelation('granted_days', item.getRelation('granted_days'));
            }
        });

        accrediteeItems.reset(availableItems.map(item => {
            item.collection = accrediteeItems;
            return item;
        }));
    }

}
