var Backbone = require('backbone');

var DateTime = require('app/backbone/helpers/DateTime');

module.exports = Backbone.View.extend({

    events: {
        'focus': 'open',
        'focusout': 'formatInput',
        'keyup': 'close'
    },

    options: [
        {'formatKey': 'account'},
        'format',
        {'interval': 30},
        {'editable': true},
        'min',
        'max'
    ],

    initialize: function(options) {
        this.setOptions(options);

        this.format = DateTime[this.formatKey].getTimepickerFormat();

        this.$el.pickatime({
            clear: '',
            format: this.format,
            interval: this.interval,
            editable: this.editable,
            min: this.min,
            max: this.max
        });
        this.picker = this.$el.pickatime('picker');
    },

    render: function() {
        this.picker.render();
    },

    open: function() {
        this.picker.open(true);
    },

    close: function() {
        this.picker.close(true);
    },

    getValue: function() {
        return this.$el.val()
    },

    setValue: function(value) {
        this.$el.val(value);
        this.$el.trigger('change');
    },

    setSelected: function(date) {
        if(date instanceof Date) {
            this.picker.set('select', date);
        } else {
            this.picker.set('select', DateTime[this.formatKey].parseTimeStringToDate(date));
        }
    },

    setMin: function(date) {
        if(date instanceof Date) {
            this.picker.set('min', date)
        } else {
            this.picker.set('select', DateTime[this.formatKey].parseTimeStringToDate(date));
        }
    },

    setMax: function(date) {
        if(date instanceof Date) {
            this.picker.set('min', date)
        } else {
            this.picker.set('select', DateTime[this.formatKey].parseTimeStringToDate(date));
        }
    },

    formatInput: function() {
        var value = this.getValue();
        if(value != '') {
            var mDate = DateTime[this.formatKey].parseTimeStringToMoment(value);
            if(mDate.isValid()) {
                this.setSelected(DateTime.convertDateToUTC(mDate.toDate()));
                this.setValue(DateTime[this.formatKey].formatMomentToTimeString(mDate));
            } else {
                this.setValue('');
            }
        }
    }
});
