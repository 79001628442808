import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Grid } from 'ui-kit';
import { Modal } from 'app/react/components/_index';
import PickupFields from '../form/PickupFields';
import RouteFields from '../form/RouteFields';
import PassengersFields from '../form/PassengersFields';
import SearchSelectBooking from 'app/react/views/event/artists/bookings/components/selects/SearchSelectBookingContainer';

const { Header, Content, Footer } = Modal;
const { Cell } = Grid;

const CREATE_TITLE = 'Create pickup';
const CREATE_BUTTON_TEXT = 'Create';
const CREATE_ANOTHER_BUTTON_TEXT = 'Create another';

const UPDATE_TITLE = 'Update pickup';
const UPDATE_BUTTON_TEXT = 'Update';

const SAVING_TEXT = 'Saving pickup...';
const LOADING_TEXT = 'Loading pickup data...';

const BOOKING_LABEL = 'Select booking';
const PICKUP_FIELDSET_TITLE = 'Pickup';
const PASSENGERS_FIELDSET_TITLE = 'Passengers';
const ROUTE_FIELDSET_TITLE = 'Route';

const PickupModal = ({
    store,
    isCreating = false,
    canCreateAnother = true,
    isOpened = false,
    isLoading = false,
    isSaving = false,

    values = {},
    errors = {},
    isSettingCustomDepartureName = false,
    isSettingCustomArrivalName  = false,
    transportsIsCollapsed = true,

    timeFormat,
    dateFormat,
    modes = [],
    drivers = [],
    vehicles = [],
    locations = [],
    bookingAttendees = [],

    onClosed = () => {},
    onOpened = () => {},
    onChangeValue = () => {},
    onChangeDeparture = () => {},
    onSelectDepartureLocation = () => {},
    onSelectArrivalLocation = () => {},
    onToggleSetCustomDepartureName = () => {},
    onToggleSetCustomArrivalName = () => {},
    onOpenCreateDriverModal = () => {},
    onOpenCreateVehicleModal = () => {},
    onSelectBooking = () => {},
    onSelectPassenger = () => {},
    onSelectAllPassengers = () => {},
    onCreateDriverSuccess = () => {},
    onCreateVehicleSuccess = () => {},
    onSave = () => {},
}) => (
    <Modal
        isReduxProvider
        width={1000}
        closeOnBackgroundClick={false}
        opened={isOpened}
        syncing={isSaving || isLoading}
        onClosed={onClosed}
        onOpened={onOpened}
    >
        <Header onClosed={onClosed}>
            <Modal.Header.Title>{isCreating ? CREATE_TITLE : UPDATE_TITLE}</Modal.Header.Title>
        </Header>
        <Content scrollable>
            {isCreating &&
                <InputGroup messages={errors.booking}>
                    <label className="required">{BOOKING_LABEL}</label>
                    <SearchSelectBooking
                        value={values.booking}
                        onChange={onSelectBooking}
                    />
                </InputGroup>
            }
            <Grid>
                <Cell>
                    <fieldset>
                        <legend>
                            {PICKUP_FIELDSET_TITLE}
                        </legend>
                        <PickupFields
                            values={values}
                            errors={errors}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            modes={modes}
                            drivers={drivers}
                            vehicles={vehicles}
                            locations={locations}
                            isSettingCustomDepartureName={isSettingCustomDepartureName}
                            isSettingCustomArrivalName={isSettingCustomArrivalName}
                            onChangeValue={onChangeValue}
                            onChangeDeparture={onChangeDeparture}
                            onSelectDepartureLocation={onSelectDepartureLocation}
                            onSelectArrivalLocation={onSelectArrivalLocation}
                            onToggleSetCustomDepartureName={onToggleSetCustomDepartureName}
                            onToggleSetCustomArrivalName={onToggleSetCustomArrivalName}
                            onOpenCreateDriverModal={onOpenCreateDriverModal}
                            onOpenCreateVehicleModal={onOpenCreateVehicleModal}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>
                            {ROUTE_FIELDSET_TITLE}
                        </legend>
                        <RouteFields
                            values={values}
                            errors={errors}
                            onChangeValue={onChangeValue}
                        />
                    </fieldset>
                </Cell>
                <Cell
                    isAutoSized
                    width="300px"
                >
                    <fieldset className="no-margin">
                        <legend>
                            {PASSENGERS_FIELDSET_TITLE}
                        </legend>
                        <PassengersFields
                            values={values}
                            errors={errors}
                            attendees={bookingAttendees}
                            selectedVehicle={vehicles.find(vehicle => vehicle.id === values.vehicle)}
                            onChangeValue={onChangeValue}
                            onSelectPassenger={onSelectPassenger}
                            onSelectAllPassengers={onSelectAllPassengers}
                        />
                    </fieldset>
                </Cell>
            </Grid>
        </Content>
        {isCreating 
            ? <Footer>
                {canCreateAnother &&
                    <Footer.Button
                        className="create-another"
                        onClick={() => onSave(true)}
                    >
                        {CREATE_ANOTHER_BUTTON_TEXT}
                    </Footer.Button>
                }
                <Footer.Button
                    className="success"
                    onClick={() => onSave()}
                >
                    {CREATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer> 
            : <Footer>
                <Footer.Button
                    className="success"
                    onClick={() => onSave()}
                >
                    {UPDATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
        }
    </Modal>
);

PickupModal.propTypes = {
    isCreating: PropTypes.bool,
    canCreateAnother: PropTypes.bool,
    isOpened: PropTypes.bool,
    isSaving: PropTypes.bool,
    values: PropTypes.object,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
    onCreateAnother: PropTypes.func,
};

export default PickupModal;
