import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    filterParam,
    limitAllParam as pageSizeAllParam,
} from 'app/react/state/middleware/api'

const root = (accountId) => `accounts/${accountId}/artists/items`;

export const fetchAll = (
    accountId,
    filters = {},
    includes = []
) => oneLineTrim`
    ${root(accountId)}?
    ${pageSizeAllParam}&
    ${filterParam(filters)}&
    ${includeParam(includes)}
`;

export const createOne = (
    accountId
) => oneLineTrim`
    ${root(accountId)}
`;

export const updateOne = (
    accountId,
    itemId
) => oneLineTrim`
    ${root(accountId)}/${itemId}
`;

export const deleteOne = (
    accountId,
    itemId
) => oneLineTrim`
    ${root(accountId)}/${itemId}
`;
