import {
    getComponentState
} from 'app/react/state/helpers';
import {
    mergeComponentState,
    setComponentStateValue,
    mergeComponentStateValue,
    deleteComponentStateValue,
    showSuccessPopup,
    showErrorPopup
} from 'app/react/state/actions/_index';
import {
    reformatISOString
} from 'app/react/helpers/_index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as BookingAttendees from 'app/react/entities/events/bookings/attendees/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups';
import * as EventLocations from 'app/react/entities/events/locations/index';

export const CREATE_MODAL_ID = 'createPickupModal';

const FAILED_POPUP = () =>
    `Failed to create pickup.`;
const SUCCESSFUL_POPUP = (departureName, arrivalName) =>
    `Pickup from <strong>${departureName}</strong> to <strong>${arrivalName}</strong> successfully created.`;
const BOOKING_ERROR = 'Please select a valid booking.';

const getState = (state) =>
    getComponentState(state, CREATE_MODAL_ID) || {};

const isOpen = (state) =>
    getComponentState(state, CREATE_MODAL_ID).isOpened;

const mergeState = (newState) =>
    mergeComponentState(CREATE_MODAL_ID, newState);

const setStateValue = (key, value) =>
    setComponentStateValue(CREATE_MODAL_ID, key, value);

const mergeStateValue = (key, newState) =>
    mergeComponentStateValue(CREATE_MODAL_ID, key, newState);

const deleteStateValue = (key) =>
    deleteComponentStateValue(CREATE_MODAL_ID, key);

const close = () => (dispatch) => {
    dispatch(mergeState({
        isOpened: false,
        isSaving: false,
        values: {},
        errors: {},
        isSettingCustomDepartureName: false,
        isSettingCustomArrivalName: false,
    }));
};

const open = (values) => (dispatch, getState) => {
    dispatch(mergeState({
        isOpened: true,
        values
    }));
};

// We also check for driver === 0 for scheduler. (unassigned driver row is resourceId: 0)
const toAPIValues = values => ({
    ...values,
    driver: typeof values.driver === 'undefined' || values.driver === 0 ? null : values.driver,
    vehicle: typeof values.vehicle === 'undefined' ? null : values.vehicle,
    departure: reformatISOString(values.departure),
    arrival: reformatISOString(values.arrival),
    duration: typeof values.duration !== 'undefined' ? values.duration * 60 : undefined
});

const save = (isCreatingAnother, values, onSaveSuccess) => (dispatch, getState) => {
    const state = getState();
    const bookingId = values.booking;
    const hasSelectedBooking = typeof bookingId !== 'undefined';
    if (!hasSelectedBooking) {
        dispatch(mergeState({
            errors: {
                booking: [{
                    type: 'error',
                    text: BOOKING_ERROR
                }]
            }
        }));
        return;
    }
    const { departureLocation, arrivalLocation } = values;
    let departureName = values.departureLocationName;
    let arrivalName = values.arrivalLocationName;
    const isSettingCustomDepartureName = typeof departureLocation === 'undefined';
    const isSettingCustomArrivalName = typeof arrivalLocation === 'undefined';
    if (!isSettingCustomDepartureName) {
        departureName = EventLocations.stateHelpers.getOne(state, departureLocation).name
    }
    if (!isSettingCustomArrivalName) {
        arrivalName = EventLocations.stateHelpers.getOne(state, arrivalLocation).name
    }
    dispatch(setStateValue('isSaving', true));
    const apiValues = toAPIValues(values);
    dispatch(Pickup.thunks.createOneForCurrentContext(bookingId, apiValues, ['passengers']))
        .then(({ isFailure, errors, response }) => {
            if (isFailure) {
                dispatch(showErrorPopup(FAILED_POPUP(departureName, arrivalName)));
                dispatch(mergeState({
                    errors,
                    isSaving: false
                }));
            } else if (isCreatingAnother) {
                dispatch(showSuccessPopup(SUCCESSFUL_POPUP(departureName, arrivalName)));
                dispatch(mergeState({
                    errors: {},
                    values: {},
                    isSaving: false,
                    isSettingCustomDepartureName: false,
                    isSettingCustomArrivalName: false,
                }));
            } else {
                if (typeof onSaveSuccess === 'function') {
                    onSaveSuccess(response.result);
                }
                dispatch(showSuccessPopup(SUCCESSFUL_POPUP(departureName, arrivalName)));
                dispatch(close())
            }
        });
};

export const stateHelpers = {
    getState,
    isOpen
};

export const actions = {
    mergeState,
    setStateValue,
    mergeStateValue,
    deleteStateValue,
};

export const thunks = {
    open,
    close,
    save
};


