var $ = require('jquery'),
    Backbone = require('backbone');

module.exports = Backbone.View.extend({

    events: {
        "click ul > li > input[type=checkbox]" : "checkSubPermissions",
        "click ul > li > ul > li > input[type=checkbox]" : "checkMainPermissions"
    },

    checkSubPermissions: function(e) {
        var mainCheckbox = $(e.currentTarget);
        var subCheckboxes = mainCheckbox.parent().find('ul > li > input[type=checkbox]');
        subCheckboxes.each(function() {
            $(this).prop('checked', mainCheckbox.prop('checked'));
        })
    },

    checkMainPermissions: function(e) {
        var subPermissionsList = $(e.currentTarget).closest('ul');
        var mainPermission = subPermissionsList.siblings("input[type=checkbox]").first();
        var subPermissions = subPermissionsList.find('li > input[type=checkbox]');
        var allSubPermissionsChecked = true;
        subPermissions.each(function() {
            if(! $(this).prop('checked')){
                allSubPermissionsChecked = false
            }
        });
        mainPermission.prop('checked', allSubPermissionsChecked);
    }
});
