import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import * as CompanyItem from 'app/backbone/models/event/accreditation/companies/CompanyItem';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import GrantedCompanyItemTable from 'app/backbone/views/event/accreditation/companies/items/tables/GrantedCompanyItemTable.view';
import template from './CompanyItemGroupCardContent.hbs';

export default class CompanyItemGroupCardContent extends BaseView {

    get template() {
        return template;
    }

    ui() {
        return {
            itemsTable: '.items-table'
        };
    }

    optionTypes() {
        return {
            itemGroup: OptionTypes.instanceOf(EventItemGroup.Model).isRequired,
            items: OptionTypes.instanceOf(CompanyItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            editing: OptionTypes.bool
        };
    }

    optionDefaults() {
        return {
            editing: false
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.itemsTable = this.initItemsTable();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.assignSubview(this.itemsTable, this.ui.itemsTable);
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {},
            model: this.itemGroup.attributes
        };
    }

    initItemsTable() {
        const { items, periods, editing } = this;
        return new GrantedCompanyItemTable({ collection: items, periods, editing });
    }

}
