import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import * as Venue from '../account/Venue';
import * as Day from './Day';
import * as Stage from './Stage';
import * as StageZone from './StageZone';

@Model({
    key: 'event'
})
class Event extends BaseModel {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model, // Circular
            venue: Venue.Model,
            days: Day.Collection,
            stageZones: StageZone.Collection
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter an event name.'
            },
            venue: {
                required: true,
                msg: 'Select a venue.'
            },
            timezone: {
                required: true,
                msg: 'Select a time zone.'
            },
            days: 'validateDays',
            stages: 'validateStages',
            stageZones: 'validateStageZones'
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('account');
        const accountId = this.getRelation('account').get('id');
        return `/api/accounts/${accountId}/events`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if (this.get('multiDay')) {
            if (this.hasRelation('stageZones')) {
                const days = this.getRelation('days');
                const stageZones = this.getRelation('stageZones');
                days.forEach((day) => {
                    day.setRelation('stages', stageZones.filterSelectedStagesByDay(day))
                });
                json.days = days.toJSON();
            }
        } else {
            if (this.hasRelation('days')) json.days = this.getRelation('days').toJSON();
        }

        return json;
    }

    validateDays() {
        if (this.getRelation('days').isEmpty()) return 'Add at least one day to the event.';
    }

    validateStages() {
    }

    validateStageZones() {
        if (this.get('multiDay') && this.getRelation('stageZones').isEmpty()) return 'Add at least one stage to the event.';
    }

    getAllStages() {
        const days = this.getRelation('days');
        return new Stage.Collection(days.map(day => day.getRelation('stages').models))
    }

    getDateFormat(type) {
        const dataTime = this.get('date_time_format');
        return dataTime ? dataTime.date[type] : 'L';
    }

    hasTicketProvider() {
        return this.get('accreditation_ticket_provider') !== 0;
    }

    isArtistTypesEnabled() {
        return this.get('artist_types_enabled');
    }

}

@Collection({
    key: 'events',
    model: Event
})
class EventCollection extends BaseCollection {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model // Circular
        }
    }

    url() {
        this.validateRelations('account');
        const accountId = this.getRelation('account').get('id');
        return `/api/accounts/${accountId}/events`;
    }

}

const PageableCollection = EventCollection.prototype.PageableCollection();

export {
    Event as Model,
    EventCollection as Collection,
    PageableCollection
};
