import {ATTENDEE_TYPES as ticketTypeAttendeeTypes} from 'app/react/entities/events/ticket_types/constants';
export const DESCRIPTION_PROP_NAME = 'description';
export const DESCRIPTION_LABEL = 'Section text';
export const DESCRIPTION_HEIGHT = 400;
export const FIELD_MAX_WIDTH = 760;
export const LIMIT_PROP_NAME = 'limit';
export const LIMIT_PLACEHOLDER = 'Unlimited';
export const ENABLE_PASSPORTS_PROP_NAME = 'passportsEnabled';
export const ENABLE_PASSPORTS_LABEL = 'Enable to upload passports';
export const REQUIRE_PASSPORTS_PROP_NAME = 'passportsRequired';
export const REQUIRE_PASSPORTS_LABEL = 'Require passports on the production website';
export const UPDATE_BOOKINGS_LABEL = 'Update limits for existing bookings';
export const UPDATE_BOOKINGS_PROP_NAME = 'updateBookings';
export const TICKET_TYPE_PROP_NAME = 'ticketType';
export const TICKET_TYPE_LABEL = 'Ticket Type';
export const ATTENDEE_TYPES = ticketTypeAttendeeTypes;
