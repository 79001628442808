import dotProp from 'dot-prop-immutable';
import { REGISTER_USER_SETTING } from '../actions/_index'

export function userSettings(state = {}, action) {
    const { type, key, value = {} } = action;
    switch (type) {
        case REGISTER_USER_SETTING:
            return dotProp.set(state, key, value);
        default:
            return state;
    }
}