import BaseController from 'app/backbone/helpers/BaseController';
import CreateFileView from 'app/backbone/views/account/events/artists/bookings/files/Create.view';

export const createRoute = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}/files/create`;

export default class FilesController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(createRoute(), null, this.create.bind(this));
    }

    create(eventId, bookingId) {
        this.app.renderContentView(new CreateFileView());
    }
}
