import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import { fetchAll, fetchOne, createOne, updateOne, deleteOne, duplicateOne, setCurrentId } from './actions';
import { getFirst } from './helpers';

export const fetchOneForCurrentContext = (artistTypeId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(fetchOne(accountId, eventId, artistTypeId));
};

export const fetchAllForCurrentContext = () => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(fetchAll(accountId, eventId));
};

export const createOneForCurrentContext = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(createOne(accountId, eventId, values));
};

export const duplicateOneForCurrentContext = (artistTypeId, values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(duplicateOne(accountId, eventId, artistTypeId, values));
};

export const updateOneForCurrentContext = (artistTypeId, values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, artistTypeId, values));
};

export const deleteOneForCurrentContext = (artistTypeId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(deleteOne(accountId, eventId, artistTypeId));
};

export const setFirstAsCurrentId = () => (dispatch, getState) => {
    const state = getState();
    const { id } = getFirst(state) || {};
    dispatch(setCurrentId(id));
};


