import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'app/react/components/_index';
import moment from 'moment';

const UPDATE_TOOLTIP = 'Update time slot';
const DELETE_TOOLTIP = 'Delete time slot';

const TimeSlotRow = ({
    timeSlot = {},
    dateFormat,
    timeFormat,
    onUpdate = () => {},
    onDelete = () => {}
}) => {
    const mStart = moment.utc(timeSlot.start);
    const mEnd = moment.utc(timeSlot.end);
    return (
        <tr>
            <td>
                {timeSlot.name}
            </td>
            <td>
                {mStart.format(dateFormat)}
            </td>
            <td>
                {mStart.format(timeFormat)}
            </td>
            <td>
                {mEnd.format(dateFormat)}
            </td>
            <td>
                {mEnd.format(timeFormat)}
            </td>
            <td className="actions">
                <Tooltip content={UPDATE_TOOLTIP}>
                    <a className="action" onClick={() => onUpdate(timeSlot.id)}>
                        <i className="bs-icon-pencil" />
                    </a>
                </Tooltip>
                <Tooltip content={DELETE_TOOLTIP}>
                    <a className="action" onClick={() => onDelete(timeSlot.id)}>
                        <i className="bs-icon-bin" />
                    </a>
                </Tooltip>
            </td>
        </tr>
    );
};

TimeSlotRow.propTypes = {
    timeSlot: PropTypes.object,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func
};

export default TimeSlotRow;
