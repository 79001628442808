import React  from 'react';
import styled from 'styled-components';

const PLACEHOLDER = 'No matching records found.';
const MIN_COL_SPAN = 9;

const Cell = styled.td`
    text-align: center !important;
    padding: 1rem .5rem !important;
`;

const NoRecordsRow = ({
    canManageTransports,
    canManageParkingPasses,
    canManageReservations,
    canManageTask,
    canSendLogins,
    canSendEmails,
}) => {
    let colSpan = MIN_COL_SPAN;

    if (canManageTransports) {
        colSpan++;
    }
    if (canManageParkingPasses) {
        colSpan++;
    }
    if (canManageReservations) {
        colSpan++;
    }
    if (canManageTask) {
        colSpan++;
    }
    if (canSendEmails) {
        colSpan++;
    }
    if (canSendLogins) {
        colSpan++;
    }
    if ((canSendEmails || canSendLogins)) {
        colSpan++;
    }

    return (
        <tr>
            <Cell colSpan={colSpan}>
                {PLACEHOLDER}
            </Cell>
        </tr>
    );
}
export default NoRecordsRow;
