import BaseController from 'app/backbone/helpers/BaseController';
import OffersController from './offers/Controller';
import LogisticsController from './logistics/Controller';
import PerformancesController from './performances/Controller';
import ReservationsController from './reservations/Controller';
import ItemsController from './items/Controller';
import RidersController from './documents/RidersController';
import EntranceController from './box-office/Controller';
import ChecklistController from './checklist/Controller';
import BookingController from './bookings/Controller';
import ReportsController from './reports/Controller';

export default class ArtistController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new OffersController({ parent: this }),
            new PerformancesController({ parent: this }),
            new LogisticsController({ parent: this }),
            new ReservationsController({ parent: this }),
            new ItemsController({ parent: this }),
            new RidersController({ parent: this }),
            new EntranceController({ parent: this }),
            new ChecklistController({ parent: this }),
            new BookingController({ parent: this }),
            new ReportsController({ parent: this }),
        ];
    }

}
