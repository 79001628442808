import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeaderStep = ({steps, activeSubStep}) => (
    <div className="indicator">
			{steps.map(step => (<div key={step.id} className={classNames({"indicator-done": step.id < activeSubStep, 'indicator-active': step.id===activeSubStep, 'indicator-todo': step.id > activeSubStep})}></div>))}
		</div>
);

HeaderStep.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
		subTitle: PropTypes.string,
	})),
	activeSubStep: PropTypes.number,
};

export default HeaderStep;
