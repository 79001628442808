import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PageableTable from 'app/backbone/components/tables/PageableTable.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import InfoModal from 'app/backbone/components/modals/InfoModal.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import ProfileZoneCell from './ProfileZoneCell.view';

export default class ProfileTable extends PageableTable {

    optionTypes() {
        return {
            profiles: OptionTypes.instanceOf(Profile.PageableCollection).isRequired,
            zones: OptionTypes.instanceOf(Zone.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.collection = this.profiles;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.placeholderText = this.getPlaceholderText();

        // SubViews
        this.confirmModal = this.initConfirmModal();
        this.infoModal = this.initInfoModal();

        super.initialize(options);
    }

    initColumns() {
        const columns = super.initColumns();
        columns.push({
            name: 'name',
            label: 'Name',
            headerCell: CellFactory.createHeaderCell('200px'),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(this.actions),
            sortable: false,
            editable: false
        });

        this.zones.each((zone) => {
            columns.splice(1, 0, {
                name: '',
                label: zone.get('name'),
                headerCell: CellFactory.createHeaderCell(null, 'center'),
                cell: ProfileZoneCell.extend({ zone }),
                sortable: false,
                editable: false
            });
        });

        return columns;
    }

    initActions() {
        return [{
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            action: this.update.bind(this)
        }, {
            name: 'remove',
            icon: 'bs-icon-bin',
            tooltip: 'Remove',
            action: this.delete.bind(this)
        }];
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    initInfoModal() {
        return new InfoModal();
    }

    update(profile) {
        const event = profile.getRelation('event');
        navigate(`/events/${event.id}/settings/accreditation/profiles/${profile.id}/edit`);
    }

    delete(profile) {
        const name = profile.get('name');
        const isDeletable = profile.get('is_deletable');
        if (isDeletable) {
            this.confirmModal.open({
                title: 'Remove Profile',
                body: `Are you sure you want to remove <strong>${name}</strong>?`,
                confirmClass: 'danger',
                confirmText: 'Yes, remove',
                autoClose: true,
                syncingText: `Removing ${name}...`,
                confirm: () => { profile.destroy({ wait: true }); }
            });
        } else {
            this.infoModal.open({
                title: 'Remove Profile',
                body: `This profile is still assigned to one or more attendees. Remove <strong>${name}</strong> from all attendees first.`
            });
        }
    }

    getPlaceholderText() {
        return 'No profiles have been created for this event.';
    }

}
