//Old
import * as Performance from 'app/react/entities/events/performances/index';
import * as Image from 'app/react/entities/images/index';
import * as PWPage from 'app/react/entities/events/production_website/pages/index';
// New
import * as Task from 'app/react/entities/events/bookings/tasks/reducers';
import * as PWArtistSettingsSectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/reducers';
import * as BookingAttendee from 'app/react/entities/events/attendees/reducers';
import * as Stage from 'app/react/entities/events/stages/reducers'

export default [
    Image.reducers.feature,
    PWPage.reducers.feature,
    Performance.reducers.feature,
    Task.featureReducer,
    PWArtistSettingsSectionFormField.featureReducer,
    BookingAttendee.featureReducer,
    Stage.featureReducer
]
