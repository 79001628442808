import CustomAccrediteeView from './CustomAccreditee.view';

export default class CustomAccrediteeCreateView extends CustomAccrediteeView {

    events() {
        return {
            'click .create': 'create',
            'click .create-another': 'createAnother',
            'click .back': 'backToOverview'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.isUpdating = false;
        this.syncingText = 'Creating attendee...';
    }

    create(e, another = false) {
        if (this.model.isValid(true)) {
            this.isCreatingAnother = another;
            this.model.forceCreate();
        }
    }

    createAnother(e) {
        if (this.canCreateAnother) this.create(e, true);
    }

    onSync() {
        this.unbindModel();
        if (this.isCreatingAnother) {
            this.model = this.newModel(null, this.event);
            this.contactCard.remove();
            this.profilesCard.remove();
            this.contactCard = this.initContactCard();
            this.profilesCard = this.initProfilesCard();
            this.itemsCard = this.initItemsCard();
            this.bindModel();
            this.contacts.fetch({ bustCache: true }).done(() => { this.setSyncing(false); });
        } else {
            this.accreditees.bustCache();
            this.backToOverview();
        }
    }
}
