import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Schemas from './schemas'
import {
    getEntitiesCollectionByKey,
    getSomeEntities,
} from 'app/react/state/helpers';
import {
    generateCollectionKey
} from 'app/react/entities/events/helpers'

const {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} = Schemas;

export const getAll = (state, accountId, eventId) => {
    const collection = getCollection(state, accountId, eventId);
    return getSomeEntities(state, ENTITY_REDUCER_KEY, collection.ids);
};

export const getCollection= (state, accountId, eventId) => {
    const key = generateCollectionKey(accountId, eventId);
    return getEntitiesCollectionByKey(state, COLLECTION_REDUCER_KEY, key);
};

export const getCollectionForCurrentContext = (state) => {
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return getCollection(state, accountId, eventId);
};

export const getAllForCurrentContext = (state) => {
    const collection = getCollectionForCurrentContext(state);
    return getSomeEntities(state, ENTITY_REDUCER_KEY, collection.ids);
};

export const isFetchingAllForCurrentContext = (state) => {
    const collection = getCollectionForCurrentContext(state);
    return !! collection.isFetching
};
