import React from 'react';
import classNames from 'classnames';
import { StylelessButton, CloseIcon } from 'ui-kit';
import Title from './Title';

export default class ModalHeader extends React.Component {

    render() {
        const { children, onClosed } = this.props;

        return (
            <div className="modalHeader">
                <div className="modalTitleContainer">
                    {children || this.renderTitle()}
                </div>
                <StylelessButton onClick={onClosed}>
                    <CloseIcon />
                </StylelessButton>
            </div>
        );
    }

    renderTitle() {
        const { title } = this.props;

        if (title) {
            return <Title title={title} />;
        }
    }
}

ModalHeader.defaultProps = {
    onClosed: () => {},
};
ModalHeader.Title = Title;
