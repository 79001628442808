import React from 'react'
import BaseController from 'app/backbone/helpers/BaseController'
import * as actions from 'app/react/state/actions/_index'
import * as Profile from 'app/react/entities/events/accreditation/profiles/index'
import * as TicketType from 'app/react/entities/events/ticket_types/index'
import FormBuilderController from './FormSectionsFormBuilderController'
import * as Account from 'app/react/entities/accounts/index'
import * as Event from 'app/react/entities/events/index'
import * as ArtistType from 'app/react/entities/events/artist_types/index'
import * as Settings from 'app/react/entities/events/production_website/settings/artists/index'
import * as Section from 'app/react/entities/events/production_website/settings/artists/sections/index'
import EditSectionView, { EDIT_SECTION_FORM_ID } from 'app/react/views/event/production_website/artists/artist_types/booking_form/sections/views/EditSectionViewContainer'
import ProductionWebsiteFormView from 'app/backbone/views/event/production_webiste/form/ProductionWebsiteForm.view'
import * as Routes from './routes'

const mustLoadProfiles = (accreditationEnabled, sectionId) => (
    accreditationEnabled &&
    (sectionId === 'crew' || sectionId === 'performers')
);

export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.formSection(), null, this.artistTypesSection.bind(this));
        this.router.route(Routes.formSection(false), null, this.section.bind(this));

        this.subControllers = [
            new FormBuilderController({ parent: this })
        ];
    }

    /*
     *  Artist Types enabled
     *
     *  1. Set current artist type by id in url
     *  2. Set current section by id in url (not uuid)
     *  3. Load the settings for the current artist type (this will also load all sections)
     *  4. Set the form values equal to the current section
     *  5. Load accreditation profile if accreditation is enabled and current section needs it
     */
    artistTypesSection(eventId, artistTypeId, sectionId) {
        if (sectionId === 'guest_lists') {
            this.app.renderContentView(new ProductionWebsiteFormView());
        } else {
            const { app, store, dispatch } = this;
            app.renderContentComponent(React.createElement(EditSectionView, { store }));
            // Account ID is set from DOM in the initial state
            dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
            dispatch(ArtistType.actions.setCurrentId(parseInt(artistTypeId, 10)));
            dispatch(Section.actions.setCurrentId(sectionId));

            const isAccreditationEnabled = Account.stateHelpers.isAccreditationEnabledForCurrent(store.getState());
            let profilesPromise = Promise.resolve();
            if (mustLoadProfiles(isAccreditationEnabled, sectionId)) {
                profilesPromise = dispatch(Profile.thunks.fetchAllForCurrentContext());
            }

            return Promise.all([
                profilesPromise,
                dispatch(ArtistType.thunks.fetchAllForCurrentContext()),
                dispatch(Settings.thunks.fetchCurrent()),
                dispatch(TicketType.thunks.fetchAllForCurrentContext()),
            ]).then(() => {
                const values = Section.stateHelpers.getCurrent(store.getState()) || {};
                store.dispatch(actions.registerFormComponentState(EDIT_SECTION_FORM_ID, { values }));
            });
        }
    }

    /*
     *  Artist Types disabled
     *
     *  1. Set current section by id in url (not uuid)
     *  2. Load all artist types (this will load only one because artist types is disabled)
     *  3. Set First artist type as current
     *  4. Load the settings for the current artist type (this will also load all sections)
     *  5. Set the form values equal to the current section
     *  6. Load accreditation profile if accreditation is enabled and current section needs it
     */
    section(eventId, sectionId) {
        if (sectionId === 'guest_lists') {
            this.app.renderContentView(new ProductionWebsiteFormView());
        } else {
            const { app, store, dispatch } = this;
            app.renderContentComponent(React.createElement(EditSectionView, { store }));
            // Account ID is set from DOM in the initial state
            dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
            dispatch(Section.actions.setCurrentId(sectionId));

            const isAccreditationEnabled = Account.stateHelpers.isAccreditationEnabledForCurrent(store.getState());
            let profilesPromise = Promise.resolve();
            if (mustLoadProfiles(isAccreditationEnabled, sectionId)) {
                dispatch(Profile.thunks.fetchAllForCurrentContext());
            }
            return Promise.all([
                profilesPromise,
                dispatch(ArtistType.thunks.fetchAllForCurrentContext()).then(() => {
                    return Promise.all([
                        dispatch(ArtistType.thunks.setFirstAsCurrentId()),
                        dispatch(TicketType.thunks.fetchAllForCurrentContext()),
                        dispatch(Settings.thunks.fetchCurrent()).then(() => {
                            const values = Section.stateHelpers.getCurrent(store.getState()) || {};
                            store.dispatch(actions.registerFormComponentState(EDIT_SECTION_FORM_ID, { values }));
                        }),
                    ])
                })
            ]);
        }
    }

}
