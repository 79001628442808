import _ from 'underscore';
import EventCompanyTable from './EventCompanyTable.view';

export default class MyEventCompanyTable extends EventCompanyTable {

    initColumns() {
        const actions = super.initColumns();
        const index = actions.indexOf(_.findWhere(actions, { label: 'Responsible' }));
        if (index > -1) actions.splice(index, 1);
        return actions;
    }

    getPlaceholderText() {
        return 'No companies have been assigned to you.';
    }
}
