import React from 'react';
import { connect } from 'react-redux';
import { SearchSelect } from 'ui-kit';
import * as Contact from 'app/react/entities/accounts/contacts/index';
import * as State from './State';

const toOption = ({
    id,
    firstname,
    lastname
}) => ({
    value: id,
    label: `${firstname} ${lastname}`.trim()
});

const mapStateToProps = (state) => {
    const {
        value,
        searchTerm
    } = State.getViewState(state);
    return {
        value,
        options: Contact.stateHelpers.getSearchResultsForCurrentContext(state, searchTerm),
        isLoading: Contact.stateHelpers.isSeachingForCurrentContext(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChangeInput: (searchTerm) => {
        dispatch(State.setViewStateValue('searchTerm', searchTerm));
        dispatch(Contact.thunks.searchAllForCurrentContext(searchTerm))
    },
    onChange: (id) => {
        dispatch((dispatch, getState) => {
            const contact = Contact.stateHelpers.getOne(getState(), id);
            if (typeof contact !== 'undefined') {
                dispatch(State.setViewStateValue('value', contact.id));
                ownProps.onChange(contact)
            } else {
                dispatch(State.deleteViewStateValue('value'));
                ownProps.onChange()
            }
        })
    }
});



const SearchSelectContacts = (props) => (
    <SearchSelect
        {...props}
        options={props.options.map(toOption)}
    />
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchSelectContacts);
