import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import by from 'thenby';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select day...';
export const NO_RESULTS_PLACEHOLDER = 'No day found.';

const toOption = ({
    id,
    name,
    start,
    end,
}) => ({
    value: id,
    label: name,
    start,
    end,
});

const DayOption = ({
    option = {},
    dateFormat,
    timeFormat
}) => {
    const mStart = moment.utc(option.start);
    const mEnd = moment.utc(option.end);
    return (
        <span>
            <span style={{ marginRight: '20px' }}>{option.label}</span>
            <span>
                <span>{mStart.format(dateFormat)}</span>
                <strong style={{ marginLeft: '10px' }}>{mStart.format(timeFormat)}</strong>
                <span style={{ marginLeft: '10px', marginRight: '10px' }}>-</span>
                <span>{mEnd.format(dateFormat)}</span>
                <strong style={{ marginLeft: '10px' }}>{mEnd.format(timeFormat)}</strong>
            </span>
        </span>
    );
};

const SelectDay = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    value,
    days = [],
    dateFormat,
    timeFormat,
    onChange = () => {},
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        options={days.sort(by('start')).map(toOption)}
        renderOption={option => (
            <DayOption
                option={option}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
            />
        )}
        onChange={onChange}
    />
);

SelectDay.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    days: PropTypes.array,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    onChange: PropTypes.func,
};

export default SelectDay;
