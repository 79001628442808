import { connect } from 'react-redux';
import ModalsWrapper from '../ModalsWrapper';
import * as UpdateModalState from './UpdateModalState';
import * as CreateModalState from './CreateModalState';
import * as Event from 'app/react/entities/events/index';

const mapStateToProps = (state) => ({
    eventId: Event.stateHelpers.getCurrentId(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onCreateDriverSuccess: (driverId) => {
        dispatch(CreateModalState.actions.setStateValue('values.driver', driverId));
        dispatch(UpdateModalState.actions.setStateValue('values.driver', driverId));
        if (typeof ownProps.onCreateDriverSuccess === 'function') {
            ownProps.onCreateDriverSuccess()
        }
    },
    onCreateVehicleSuccess: (vehicleId) => {
        dispatch(CreateModalState.actions.setStateValue('values.vehicle', vehicleId));
        dispatch(UpdateModalState.actions.setStateValue('values.vehicle', vehicleId));
        if (typeof ownProps.onCreateVehicleSuccess === 'function') {
            ownProps.onCreateVehicleSuccess()
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalsWrapper);
