import React, { useState } from 'react'
import { connect } from 'react-redux'
import { isUndefined } from 'lodash'
import { notifySuccess, notifyError } from 'ui-kit'
import * as BookingItem from 'app/react/entities/events/bookings/items'
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index'
import CopyItemsFromBookingModal from './CopyItemsFromBookingModal'

const SUCCESS_MESSAGE = 'Assigned items were successfully copied.';
const FAILED_MESSAGE = 'Assigned items could not be copied...';

const CopyItemsFromBookingModalWithState = ({
    bookingId,
    renderTrigger,
    dispatch,
}) => {
    const [booking, setBooking] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    function onChangeBooking(booking) {
        setBooking(booking);
        setErrors({ ...errors, booking: undefined });
    }

    async function onCopyItems({ closeModal }) {
        setIsLoading(true);

        // No need to manually close the modal, as the dispatch call below will refresh the Redux
        // state, causing the modal to re-render. It's annoying, but inevitable atm... However,
        // validation happens upfront, so chances of the the call failing a quite minimal.
        const { error, response } = await dispatch(BookingItem.thunks.copyAllFromBookingForCurrentContext({ booking }));

        if (!isUndefined(error)) {
            const errors = getFormFieldErrorsFromResponseJSON(error);
            notifyError(FAILED_MESSAGE);
        } else {
            notifySuccess(SUCCESS_MESSAGE);
        }
    }

    return (
        <CopyItemsFromBookingModal
            isLoading={isLoading}
            renderTrigger={renderTrigger}
            booking={booking}
            currentBooking={bookingId}
            onChangeBooking={onChangeBooking}
            onCopyItems={onCopyItems}
            errors={errors}
        />
    );
};

export default connect()(CopyItemsFromBookingModalWithState);
