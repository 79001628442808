import Services from 'app/services';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import '../../../../performances/views/Scheduler.css';
import { html } from 'common-tags';
import {
    actionsTemplate,
    calculateEndTime,
    calculateStartTime,
    driverToFcResource,
    findAddedDrivers,
    findAddedPickups,
    findChangedPickups,
    findRemovedPickups,
    iconsTemplate,
    driverDepartureTimeTemplate,
    removeDriverDepartureTimeFromStart,
    pickupToFcEvent
} from './utils';


export default class Scheduler extends React.Component {

    componentDidMount() {
        const target = ReactDOM.findDOMNode(this);
        this.$target = $(target);
        this.renderFullCalender(this.props);
    }

    componentWillReceiveProps(newProps) {
        const { dayId, activeInterval, pickups, drivers } = newProps;
        if (typeof dayId !== 'undefined' && dayId !== this.props.dayId) {
            this.renderFullCalender(newProps);
        }
        if (typeof drivers !== 'undefined' && drivers.length !== this.props.drivers.length) {
            const addedDrivers = findAddedDrivers(drivers, this.props.drivers);
            addedDrivers.forEach((pickup) => {
                this.addDriver(pickup);
            });
        }
        if (typeof activeInterval !== 'undefined' && activeInterval !== this.props.activeInterval) {
            this.$target.fullCalendar('option', 'slotDuration', activeInterval);
        }
        if (typeof pickups !== 'undefined' && dayId === this.props.dayId) {
            const newPickups = pickups;
            const currentPickups = this.props.pickups;

            let addedPickups = [];
            let removedPickups = [];

            if (newPickups.length !== currentPickups.length) {
                addedPickups = findAddedPickups(currentPickups, newPickups);
                removedPickups = findRemovedPickups(currentPickups, newPickups);

                addedPickups.forEach((pickup) => {
                    this.addPickup(pickup);
                });
                removedPickups.forEach((pickup) => {
                    this.removePickup(pickup.id);
                });
            }

            const changedPickups = findChangedPickups(currentPickups, newPickups, addedPickups, removedPickups);

            if (changedPickups.length > 0) {
                const newChangedPickups = newPickups.filter(({ id }) => changedPickups.some(pickup => pickup.id === id));
                newChangedPickups.forEach((pickup) => {
                    this.editPickup(pickup);
                });
            }
        }
    }

    componentWillUnmount() {
        this.$target.fullCalendar('destroy');
    }

    eventClick(fcEvent, e) {
        const $target = $(e.target);
        const { onUpdatePickup, onDeletePickup } = this.props;
        if ($target.hasClass('fc-pickup-action-delete') || $target.parent().hasClass('fc-pickup-action-delete')) {
            onDeletePickup(fcEvent.id);
        }else {
            onUpdatePickup(fcEvent.id);
        }
    }

    getFcEvent (id) {
        return this.$target.fullCalendar('clientEvents', id)[0]
    }

    addDriver (driver) {
        const fcResource = driverToFcResource(driver);
        this.$target.fullCalendar('addResource', fcResource, true);
    }

    addPickup (pickup) {
        const fcEvent = pickupToFcEvent(pickup);
        this.$target.fullCalendar('renderEvent', fcEvent, true);
    }

    removePickup (pickupId) {
        this.$target.fullCalendar('removeEvents', pickupId, true);
    }

    editPickup (pickup) {
        const fcEvent = this.getFcEvent(pickup.id);
        const newFcEvent = pickupToFcEvent(pickup);
        // Must be the original fcEvent, not merely a reconstructed object
        const mergedFcEvent = Object.assign(fcEvent, newFcEvent);
        this.$target.fullCalendar('updateEvent', mergedFcEvent, true);
    }

    eventDrop(fcEvent, delta, revertCallback) {
        const { id, start, end, resourceId, driverDepartureTime } = fcEvent;
        if (start === null || end === null || resourceId === null) {
            revertCallback();
            return;
        }
        // Only ground transports can be assigned to drivers
        const driver = parseInt(resourceId, 10);
        if (fcEvent.mode.id !== 3 && driver !== 0) {
            revertCallback();
            return;
        }
        const values = {
            departure: removeDriverDepartureTimeFromStart(start, driverDepartureTime),
            arrival: end.toISOString(),
            driver,
        };
        this.props.onDropPickup(id, values, revertCallback);
    }

    eventResize(fcEvent, delta, revertCallback) {
        const { id, start, end, driverDepartureTime } = fcEvent;
        if (start === null || end === null) {
            revertCallback();
            return;
        }
        const values = {
            departure: removeDriverDepartureTimeFromStart(start, driverDepartureTime),
            arrival: end.toISOString()
        };
        this.props.onResizePickup(id, values, revertCallback);
    }

    selectPeriod(mStart, mEnd, jsEvent, view, resource) {
        const successCallback = (pickup) => {
            const fcEvent = pickupToFcEvent(pickup);
            if (fcEvent) this.$target.fullCalendar('renderEvent', fcEvent, true);
        };
        const driver = parseInt(resource.id, 10);
        const values = {
            departure: mStart.toISOString(),
            arrival: mEnd.toISOString(),
            driver,
            mode: driver !== 0 ? 3 : undefined // Ground transport if driver is selected
        };
        this.props.onSelectPeriod(values, successCallback);
    }

    renderFullCalender(props) {
        const { dateFormat, timeFormat, interval, start, end, drivers, pickups } = props;
        const startTime = calculateStartTime(start);
        const endTime = calculateEndTime(end);
        this.$target.fullCalendar('destroy');
        this.$target.fullCalendar({
            schedulerLicenseKey: Services.fullcalendarSchedulerKey,
            timezone: 'UTC',
            timeFormat,
            slotLabelFormat: [dateFormat, timeFormat],
            eventConstraint: {
                start,
                end: endTime
            },
            visibleRange: {
                start: startTime,
                end: endTime
            },
            defaultDate: start, // Required
            lang: 'en',
            height: 'auto',
            resourceAreaWidth: '20%',
            slotDuration: interval,
            slotWidth: 30,
            editable: true,
            aspectRatio: 2,
            selectable: true,
            defaultView: 'timeline',
            handleWindowResize: true,
            header: false,
            eventResourceField: 'resourceId',
            resourceLabelText: 'Drivers',
            resources: [
                ...drivers.map(driverToFcResource),
                {
                    id: 0,
                    title: 'No driver assigned'
                }
            ],
            events: pickups.map(pickupToFcEvent),
            eventRender: (fcEvent, $el, view) => {
                $el.addClass('fc-pickup success');
                $el.find('.fc-title').prepend(iconsTemplate(fcEvent.mode.icon));
                $el.append(actionsTemplate());

                const { driverDepartureTime } = fcEvent;
                if (typeof driverDepartureTime !== 'undefined' && driverDepartureTime !== null  && driverDepartureTime !== 0) {
                    const { slotWidth } = view;
                    const { slotDuration } = view.options;
                    const slotDurationInt = parseInt(slotDuration.substr(slotDuration.length - 2), 10);
                    const driverDepartureTimeWidth = (driverDepartureTime / slotDurationInt) * slotWidth;
                    $el.prepend(driverDepartureTimeTemplate(driverDepartureTimeWidth, `${driverDepartureTime} M`));
                }
            },
            eventClick: this.eventClick.bind(this),
            eventDrop: this.eventDrop.bind(this),
            eventResize: this.eventResize.bind(this),
            select: this.selectPeriod.bind(this)
        });
    }

    render() {
        return <div className="fc-scheduler scheduler-pickups" />;
    }
}

Scheduler.defaultProps = {
    timeFormat: 'h:mm',
    dateFormat: 'L',
    interval: '00:15',
    drivers: [],
    pickups: [],
    onSelectPeriod: () => {},
    onDropPerformance: () => {},
    onResizePerformance: () => {},
    onToggleLockPerformance: () => {},
    onTogglePublishPerformance: () => {},
    onUpdatePerformance: () => {},
    onViewPerformance: () => {},
    onDeletePerformance: () => {}
};

Scheduler.propTypes = {
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    dayId: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string,
    interval: PropTypes.string,
    stages: PropTypes.array,
    performances: PropTypes.array,
    onSelectPeriod: PropTypes.func,
    onDropPerformance: PropTypes.func,
    onResizePerformance: PropTypes.func,
    onLockPerformance: PropTypes.func,
    onUpdatePerformance: PropTypes.func,
    onViewPerformance: PropTypes.func,
    onDeletePerformance: PropTypes.func
};
