import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'stage'
})
class Stage extends BaseModel {

    static Relations() {
        return {
            day: require('./Day').Model, // Circular
            stageZone: require('./StageZone').Model // Circular
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a stage name.'
            },
            capacity: {
                required: false,
                pattern: 'digits',
                msg: 'Stage capacity must be a positive number.'
            }
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
    }

    urlRoot() {
        const eventId = this.getRelation('day').getRelation('event').get('id');
        return `/api/events/${eventId}/stages`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

}

@Collection({
    key: 'stages',
    model: Stage
})
class StageCollection extends BaseCollection {

    static Relations() {
        return {
            day: require('./Day').Model, // Circular
            stageZone: require('./StageZone').Model // Circular
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.on('add', (model, collection) => {
            if (!model.get('sequence')) model.set('sequence', collection.indexOf(model) + 1);
        });
    }

    url() {
        this.validateRelations('event');
        const eventId = this.getRelation('day').getRelation('event').get('id');
        return `/api/events/${eventId}/stages`;
    }

    comparator(model) {
        return model.get('sequence');
    }

    filterByDay(day) {
        return new this.constructor(this.filter(stage => stage.get('day') && (stage.get('day') == day.get('id'))));
    }

}

const PageableCollection = StageCollection.prototype.PageableCollection();

export {
    Stage as Model,
    StageCollection as Collection,
    PageableCollection
};
