import $ from 'jquery';
import BaseView from 'app/backbone/components/Base.view';

export default class SelectableTable extends BaseView {

    events() {
        return {
            'click tr' : 'redirect'
        };
    }

    redirect(e) {
        const $el = $(e.target);
        const shouldOpenNewTab = e.ctrlKey || e.metaKey;

        if ($el.is('td')) {
            const href = $el.parent().data('href');

            if (href) {
                navigate(new URL(href).pathname, {
                    pageReload: !shouldOpenNewTab,
                    newTab: shouldOpenNewTab,
                });
            }
        }
    }

}
