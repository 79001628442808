import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import ItemsOverviewCard from 'app/backbone/views/account/settings/accreditation/views/cards/ItemsOverviewCard.view';
import EventItemTable from '../tables/EventItemTable.view';
import EventItemModalCreate from '../modals/EventItemModalCreate.view';

export default class EventItemsOverviewCard extends ItemsOverviewCard {

    optionTypes() {
        return {
            items: OptionTypes.instanceOf(EventItem.PageableCollection).isRequired,
            itemGroups: OptionTypes.instanceOf(EventItemGroup.PageableCollection).isRequired
        };
    }

    initCreateModal() {
        const { itemGroups } = this;
        const createModal = new EventItemModalCreate({ itemGroups });
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    createItem() {
        if (!this.itemGroups.isEmpty()) {
            const event = this.items.getRelation('event');
            this.createModal.open(null, { event });
        }
    }

    initTable() {
        const { items, itemGroups } = this;
        return new EventItemTable({ items, itemGroups });
    }

}
