export const NAVIGATE = 'NAVIGATE';
export const NAVIGATE_BACK = 'NAVIGATE_BACK';
export const NAVIGATE_PAGE_RELOAD = 'NAVIGATE_PAGE_RELOAD';
export const NAVIGATE_NEW_TAB = 'NAVIGATE_NEW_TAB';
export const NAVIGATE_RELOAD_CURRENT = 'NAVIGATE_RELOAD_CURRENT';

export const to = (url, options) => () => {
    navigate(url, options);
};

export const back = () => () => {
    navigate(null, { back: true });
};

export const pageReload = (url, options) => () => {
    navigate(url, { ...options, pageReload: true });
};

export const newTab = (url, options) => () => {
    navigate(url, { ...options, newTab: true });
};

export const reloadCurrent = (url) => () => {
    navigate(url, { reloadCurrent: true });
};

export default {
    to,
    back,
    pageReload,
    newTab,
    reloadCurrent
};
