import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'company'
})
class Company extends BaseModel {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model, // Circular
            primary_contact: require('app/backbone/models/account/Contact').Model, // Circular
            contacts: require('app/backbone/models/account/Contact').Collection // Circular
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a name.'
            }
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('account');
        let account = this.account.id;
        return `/api/accounts/${account}/companies`;
    }

    parse(response, xhr) {
        const parsedResponse = super.parse(response, xhr);
        this.parseRelations(parsedResponse);
        return parsedResponse;
    }

    toJSON(options) {
        var json = super.toJSON(arguments);
        if(this.hasRelation('primary_contact')) json.primary_contact = this.primary_contact.pick('id', 'firstname', 'lastname', 'email', 'telephone');
        return json;
    }
}

@Collection({
    key: 'companies',
    model: Company,
    comparator: 'name'
})
class CompanyCollection extends BaseCollection {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model // Circular
        }
    }

    url() {
        this.validateRelations('account');
        let account = this.account.id;
        return `/api/accounts/${account}/companies`;
    }
}

const PageableCollection = CompanyCollection.prototype.PageableCollection();

export {
    Company as Model,
    CompanyCollection as Collection,
    PageableCollection
};
