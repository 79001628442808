export const dateFormats = [
	{
			label: 'e.g. 01/19/2018',
			value: 'MM/DD/YYYY',
	},
	{
			label: 'e.g. 19/01/2018',
			value: 'DD/MM/YYYY',
	},
	{
			label: 'e.g. 2018-01-19',
			value: 'YYYY-MM-DD',
	}
]
export const timeFormats = [
	{
			label: '24-hour format',
			value: 'HH:mm',
	},
	{
			label: '12-hour format',
			value: 'hh:mm A',
	},
]

export const currencies = [
	{label: 'EUR - Euro', value: 'EUR'},
	{label: 'USD - US Dollar', value: 'USD'},
	{label: 'GBP - British Pound', value: 'GBP'},
	{label: 'AUD - Australian Dollar', value: 'AUD'},
	{label: 'AOA - Angolan Kwanza', value: 'AOA'},
	{label: 'BRL - Brazil Real', value: 'BRL'},
	{label: 'BBD - Barbadian Dollar', value: 'BBD'},
	{label: 'CAD - Canadian Dollar', value: 'CAD'},
	{label: 'CNY - China Yuan', value: 'CNY'},
	{label: 'CZK - Czech Koruna', value: 'CZK'},
	{label: 'DKK - Denmark Krone', value: 'DKK'},
	{label: 'AED - United Arab Emirates dirham', value: 'AED'},
	{label: 'HKD - Hong Kong Dollar', value: 'HKD'},
	{label: 'INR - India Rupees', value: 'INR'},
	{label: 'JPY - Japan Yen', value: 'JPY'},
	{label: 'NZD - New Zealand Dollars', value: 'NZD'},
	{label: 'NOK - Norway Krone', value: 'NOK'},
	{label: 'PLN - Poland Zloty', value: 'PLN'},
	{label: 'RON - Romanian Leu', value: 'RON'},
	{label: 'RUB - Russia Ruble', value: 'RUB'},
	{label: 'SGD - Singapore Dollar', value: 'SGD'},
	{label: 'CHF - Switzerland Francs', value: 'CHF'},
	{label: 'ZAR - South Africa Rand', value: 'ZAR'},
	{label: 'SEK - Sweden Krona', value: 'SEK'},
	{label: 'TWD - Taiwan New Dollars', value: 'TWD'},
	{label: 'THB - Thai Baht', value: 'THB'},
	{label: 'TTD - Trinidad And Tobago Dollars', value: 'TTD'},
]

export const distances = [
	{label: 'km - Kilometers', value: 'km'},
	{label: 'mi - Miles', value: 'mi'},
]