import React, { Fragment } from 'react'
import { Tooltip } from 'app/react/components/_index';
import ItemRow from './ItemRow';

const ADD_TOOLTIP = 'Add item to group';

const ItemGroupRowContent = ({
    group,
    onAdd,
    onUpdate,
    onDelete,
    currency,
}) => (
    <Fragment>
        <tr className="row-with-border-bottom">
            <td>
                <strong>{group.name}</strong>
            </td>
            <td>
                <Tooltip content={ADD_TOOLTIP}>
                    <a className="action" onClick={() => onAdd(group.id)}>
                        <i className="bs-icon-plus" />
                    </a>
                </Tooltip>
            </td>
        </tr>
        {group.items && group.items.map(item => (
            <ItemRow
                key={item.id}
                item={item}
                currency={currency}
                onUpdate={onUpdate}
                onDelete={onDelete}
            />
        ))}
    </Fragment>
)

export default ItemGroupRowContent;
