import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import by from 'thenby'
import {
    Avatar,
    AvatarWithTooltip,
    TableRow,
    TableCell,
    TableActionsDropdown,
    Tooltip,
} from 'ui-kit'
import { DANGER } from 'ui-kit/lib/util/types'
import { Flag } from 'app/react/components/_index'
import { ItemNumber } from './tableStyles'
import TaskProgressCell from '../../components/TaskProgressCell'
import ProductionWebsiteProgressCell from '../../components/ProductionWebsiteProgressCell'
import TransportIcon from '../../components/TransportIcon'
import { countries } from 'country-data'
import { getUserName } from 'app/react/helpers/users'
import MainAdvancingContactsCell from "../../components/main_advancing_contacts/MainAdvancingContactsCell";

const TOOLTIPS = {
    DELETE: 'Delete',
    SEND_ITINERARY: 'Send mail/itinerary',
    SEND_ITINERARY_SUCCESS: 'Itinerary was sent',
    SEND_LOGIN: 'Send production website login',
    SEND_LOGIN_SUCCESS: 'Production website login was sent',
    BOOKING_WAS_CHANGED: 'This booking has been changed after sending the itinerary.',
    CREATE_TRANSPORT: 'Add transport',
};

const getCountryTooltipContent = (code) => {
    const country = countries.all.find(({ alpha2 }) => alpha2 === code)

    if (typeof country === 'undefined') {
        return 'No country selected'
    }

    return country.name;
};

const getTransportTooltipContent = ({
    departure,
    departureLocationName,
    arrival,
    arrivalLocationName,
}, timeFormat) => {
    const departureTime = moment.utc(departure, moment.ISO_8601).format(timeFormat);
    const arrivalTime = moment.utc(arrival, moment.ISO_8601).format(timeFormat);

    return (
        <Fragment>
            {departureLocationName} <strong>{departureTime}</strong> to {arrivalLocationName} <strong>{arrivalTime}</strong>
        </Fragment>
    );
}

const BookingTableRow = ({
    booking = {},
    timeFormat,

    canManageTransports = false,
    canManageParkingPasses = false,
    canManageReservations = false,
    canManageTask = false,
    canSendLogins = false,
    canSendEmails = false,

    onClick = () => {},
    onClickTransport = () => {},
    onClickPickup = () => {},
    onCreateTransport = () => {},
    onClickParking = () => {},
    onClickReservations = () => {},
    onToggleTask = () => {},
    onSendItinerary = () => {},
    onSendLogin = () => {},
}) => {
    const {
        artist,
        status = {},
        performance,
        parkingPasses,
        transports = [],
        pickups = [],
        reservations,
        tasks,
        advancingUser,
        isEditedAfterItineraryWasSent,
        mainAdvancingContacts
    } = booking;
    const hasPerformance = typeof performance !== 'undefined' && performance !== null;
    let startTime;
    let endTime;
    let dayName;
    let stageName;
    if (hasPerformance) {
        const { start, end, day, stage } = performance;
        startTime = moment.utc(start, moment.ISO_8601).format(timeFormat);
        endTime = moment.utc(end, moment.ISO_8601).format(timeFormat);
        const hasDay = typeof day !== 'undefined' && day !== null;
        const hasStage = typeof stage !== 'undefined' && stage !== null;
        if (hasDay)  {
            dayName = day.name;
        }
        if (hasStage) {
            stageName = stage.name;
        }
    }
    const hasParkingPasses = typeof parkingPasses !== 'undefined' && parkingPasses.length > 0;
    const hasTransports = typeof transports !== 'undefined' && transports.length > 0;
    const hasPickups = typeof pickups !== 'undefined' && pickups.length > 0;
    const hasReservations = typeof reservations !== 'undefined' && reservations.length > 0;

    return (
        <TableRow
            isSelectable
            type={status.colorType}
            highlight={isEditedAfterItineraryWasSent ? DANGER : null}
            onClick={e => onClick(booking.id, e.ctrlKey || e.metaKey)}
        >
            <TableCell
                isCentered
                style={{ padding: 0 }}
            >
                <Avatar
                    name={artist.name}
                    imageUrl={artist.imageSize80}
                />
            </TableCell>
            <TableCell>{artist.name}</TableCell>
            <TableCell isCentered>
                <Tooltip content={getCountryTooltipContent(artist.country)}>
                    <Flag code={artist.country} />
                </Tooltip>
            </TableCell>
            <TableCell>
                {hasPerformance && dayName}
            </TableCell>
            <TableCell>
                {hasPerformance && `${startTime} - ${endTime}`}
            </TableCell>
            <TableCell>
                {hasPerformance && stageName}
            </TableCell>
            {canManageTransports && (
                <TableCell>
                    {(hasTransports || hasPickups) &&
                        [
                            ...transports.map(transport => ({ ...transport, isInbound: transport.type === 1 })),
                            ...pickups.map(pickup => ({ ...pickup, isPickup: true }))
                        ].sort(by('departure')).map(transport => (
                            <Tooltip renderContent={() => getTransportTooltipContent(transport, timeFormat)} key={transport.id}>
                                <TransportIcon
                                    isPickup={transport.isPickup}
                                    isInbound={transport.isInbound}
                                    onClick={e => {
                                        transport.isPickup ?
                                            onClickPickup(booking.id, transport.id, e.ctrlKey || e.metaKey) :
                                            onClickTransport(booking.id, transport.id, e.ctrlKey || e.metaKey);
                                        e.stopPropagation()
                                    }}
                                />
                            </Tooltip>
                        ))
                    }
                </TableCell>
            )}
            {canManageParkingPasses && (
                <TableCell isCentered>
                    {hasParkingPasses && (
                        <ItemNumber
                            onClick={e => {
                                onClickParking(booking.id, e.ctrlKey || e.metaKey);
                                e.stopPropagation()
                            }}
                        >
                            <i className="bs-icon-parking" />
                            {parkingPasses.length}
                        </ItemNumber>
                    )}
                </TableCell>
            )}
            {canManageReservations && (
                <TableCell isCentered>
                    {hasReservations && (
                        <ItemNumber
                            onClick={e => {
                                onClickReservations(booking.id, e.ctrlKey || e.metaKey);
                                e.stopPropagation()
                            }}
                        >
                            <i className="bs-icon-accommodation" />
                            {reservations.length}
                        </ItemNumber>
                    )}
                </TableCell>
            )}
            {canManageTask && (
                <TaskProgressCell
                    isCentered
                    onToggleTask={onToggleTask}
                    tasks={tasks}
                    bookingId={booking.id}
                />
            )}
            {canSendEmails && (
                <TableCell isCentered>
                    {booking.isItinerarySent && (
                        <Tooltip content={TOOLTIPS.SEND_ITINERARY_SUCCESS}>
                            <i className="bs-icon-check" style={{ color: 'var(--colorSuccess500)' }} />
                        </Tooltip>
                    )}
                </TableCell>
            )}
            {canSendLogins && (
                <TableCell isCentered>
                    {booking.isProductionWebsiteLoginSent && (
                        <Tooltip content={TOOLTIPS.SEND_LOGIN_SUCCESS}>
                            <i className="bs-icon-check" style={{ color: 'var(--colorSuccess500)' }} />
                        </Tooltip>
                    )}
                </TableCell>
            )}
            <ProductionWebsiteProgressCell percentage={booking.productionWebsiteProgress.percentage} />
            <TableCell isCentered>
                {advancingUser && (
                    <AvatarWithTooltip
                        name={getUserName(advancingUser)}
                        imageUrl={advancingUser.avatarUrl || undefined}
                        size={2.5}
                    />
                )}
            </TableCell>
            <MainAdvancingContactsCell contacts={mainAdvancingContacts} />
            <TableCell isCentered>
                {isEditedAfterItineraryWasSent && (
                    <Tooltip content={TOOLTIPS.BOOKING_WAS_CHANGED} position="top right">
                        <i
                            className="bs-icon-triangle-exclamation-mark"
                            style={{ color: 'var(--colorDanger500)' }}
                        />
                    </Tooltip>
                )}
            </TableCell>
            {(canSendEmails || canSendLogins) && (
                <TableCell.Actions>
                    <TableActionsDropdown>
                        {canManageTransports && (
                            <TableActionsDropdown.Action onClick={(e) => {
                                onCreateTransport(booking.id, e.ctrlKey || e.metaKey);
                                e.stopPropagation()
                            }}>
                                <i className="bs-icon-road" /> {TOOLTIPS.CREATE_TRANSPORT}
                            </TableActionsDropdown.Action>
                        )}
                        {canSendEmails && (
                            <TableActionsDropdown.Action onClick={(e) => {
                                onSendItinerary(booking.id, e.ctrlKey || e.metaKey);
                                e.stopPropagation()
                            }}>
                                <i className="bs-icon-envelope"/> {TOOLTIPS.SEND_ITINERARY}
                            </TableActionsDropdown.Action>
                        )}
                        {canSendLogins && (
                            <TableActionsDropdown.Action onClick={e => {
                                onSendLogin(booking.id, e.ctrlKey || e.metaKey);
                                e.stopPropagation()
                            }}>
                                <i className="bs-icon-globe"/> {TOOLTIPS.SEND_LOGIN}
                            </TableActionsDropdown.Action>
                        )}
                    </TableActionsDropdown>
                </TableCell.Actions>
            )}
        </TableRow>
    );
};

BookingTableRow.propTypes = {
    booking: PropTypes.object,
    onEdit: PropTypes.func
};

export default BookingTableRow;
