import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import { loadingData } from './loadingData';
import { components } from './components';
import { currentContext } from './currentContext';
import { collections } from './collections';
import { entities } from './entities';
import { userSettings } from './userSettings'
import { setState } from './setState';

import entitiesReducer from 'app/react/entities/entitiesReducer';
import collectionsReducer from 'app/react/entities/collectionsReducer';
import paginationReducer from 'app/react/entities/paginationReducer';
import featureReducer from 'app/react/entities/featureReducer';

const combinedReducer = combineReducers({
    tokens: (state = {}) => state,
    services: (state = {}) => state,
    userSettings,
    currentContext,
    loadingData,
    components,
    entities: reduceReducers(
        entities,
        entitiesReducer
    ),
    pagination: paginationReducer,
    collections: reduceReducers(
        collections,
        collectionsReducer
    )
});

export const rootReducer = reduceReducers(
    combinedReducer,
    setState,
    ...featureReducer
);
