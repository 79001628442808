import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Field, Formik, Form } from 'formik';
import NameField from '../atoms/formfields/NameField';
import Footer from '../footer/Footer';

export const NameFormSchema = () =>
  yup.object({
		name: yup.string().min(1).max(100).required().nullable()
	});

export const NameForm = ({setActiveStep, activeSubStep, eventState}) => {

    return (
        <div className="form_content">
					<h2>First things first</h2>
					<h3 className="form-sub-caption">Give your event a name</h3>
					<Formik
						initialValues={{name: eventState.event.name}}
						validationSchema={NameFormSchema()}
						validateOnMount
						enableReinitialize
					>
					{(formik) => {
						const { handleChange, isValid, handleBlur, values, touched, errors } = formik;

						return (
							<Form>
                <div className="form-label-prefixed">
                  <Field
                    as={NameField}
                    id="name"
                    name="name"
                    placeholder="Name your legendary event"
                    onChange={handleChange}
                    required
                    error={
                      touched.name && errors.name !== undefined
                        ? errors.name
                        : undefined
                    }
                    onBlur={handleBlur}
                  />
                </div>
								<Footer setActiveStep={(step) => setActiveStep(step, values)} activeSubStep={activeSubStep} isNextDisabled={!isValid} />
							</Form>
						);
					}}
				</Formik>
        </div>
    )
};

NameForm.propTypes = {
	setActiveStep: PropTypes.func,
	activeSubstep: PropTypes.number,
	eventState: PropTypes.object,
};

export default NameForm;
