import BaseView from 'app/backbone/components/Base.view';
import DataTable from 'app/backbone/components/tables/DataTable.view';

export default class AccountUsersOverviewView extends BaseView {

    ui() {
        return {
            accountUsersTable: '#account-users-table',
            eventUserTable: '#event-users-table'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.accountUsersTable = new DataTable();
        this.eventUserTable = new DataTable();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.accountUsersTable, this.ui.accountUsersTable);
        this.assignSubview(this.eventUserTable, this.ui.eventUserTable);
        return this.delegateEvents();
    }

}
