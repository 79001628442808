import Backbone from 'backbone';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import TabsCard from 'app/backbone/components/cards/tabs/TabsCard.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import EventCompanyTable from'../tables/EventCompanyTable.view';
import MyEventCompanyTable from'../tables/MyEventCompanyTable.view';

export default class EventCompanyOverviewCard extends TabsCard {

    optionTypes() {
        return {
            companies: OptionTypes.instanceOf(EventCompany.PageableCollection).isRequired,
            myCompanies: OptionTypes.instanceOf(EventCompany.PageableCollection).isRequired
        };
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add company',
            icon: 'bs-icon-plus',
            action: this.createCompany.bind(this)
        }];
    }

    createCompany() {
        const eventId = this.companies.getRelation('event').get('id');
        navigate(`/events/${eventId}/accreditation/companies/create`);
    }

    initTabs() {
        return [{
            name: 'all_companies',
            title: 'All companies',
            isActive: true
        }, {
            name: 'my_companies',
            title: 'My companies',
            isActive: false
        }];
    }

    initViews() {
        const { companies, myCompanies } = this;
        const tableContainer = new Backbone.ChildViewContainer();
        const companyTable = new EventCompanyTable({ companies });
        const myCompanyTable = new MyEventCompanyTable({ companies: myCompanies });

        // Linked the two tables, if on record is deleted in a table the other table needs to fetch
        this.listenTo(myCompanies, 'destroy', this.fetchCompanyTable, this);
        this.listenTo(companies, 'destroy', this.fetchMyCompanyTable, this);

        tableContainer.add(companyTable, 'all_companies');
        tableContainer.add(myCompanyTable, 'my_companies');

        return tableContainer;
    }

    getUniqueName() {
        return `eventCompaniesOverview${this.companies.event.id}`;
    }

    fetchCompanyTable(company) {
        this.views.findByCustom('all_companies').collection.remove(company);
    }

    fetchMyCompanyTable(company) {
        this.views.findByCustom('my_companies').collection.remove(company);
    }
}
