export const overview = (
    eventId = ':event'
) => `events/${eventId}/artists/accommodations/overview`;

export const dashboard = (
    eventId = ':event'
) => `events/${eventId}/artists/accommodations/dashboard`;

export const update = (
    eventId = ':event',
    bookingId = ':booking',
    reservationId = ':reservation'
) => `/booking/edit-accommodation/${bookingId}/${reservationId}`;

export const report = (
    eventId = ':event'
) => `/events/${eventId}/artists/reports/accommodations`
