import { unique } from 'app/react/helpers/_index'

export const addEntityToCollection = (state, action) => ({
    ...state,
    ids: unique([...state.ids, action.response.result]),
});

export const removeEntityFromCollection = (state, action) => {
    const { response = {} } = action;
    // TODO refactor all to id or response result
    const entityId = action.id || response.result;
    return ({
        ...state,
        ids: typeof state.ids !== 'undefined'
            ? state.ids.filter(id => id !== entityId)
            : undefined,
    })
};

export const removeEntityForEachCollection = (state, action) => {
    const newState = {};
    const collectionKeys = Object.keys(state);
    collectionKeys.forEach(key => {
        newState[key] = removeEntityFromCollection(state[key], action)
    });
    return newState
};
