import { Model, Collection }  from 'app/backbone/models/decorators';
import * as User from 'app/backbone/models/account/User';
import * as Event from './Event';

@Model({
    key: 'event_user'
})
class EventUser extends User.Model {
    static Relations() {
        return {
            event: Event.Model // Circular
        }
    }
}

@Collection({
    key: 'event_users',
    model: EventUser
})

class EventUserCollection extends User.Collection {

    static Relations() {
        return {
            event: require('./Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/users`;
    }
}

const PageableCollection = EventUserCollection.prototype.PageableCollection();

export {
    EventUser as Model,
    EventUserCollection as Collection,
    PageableCollection
};
