import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import { setCurrentContextId } from 'app/react/state/actions/_index'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_PW_ARTIST_SETTINGS_SECTION_REQUEST';
export const ONE_SUCCESS = 'ONE_PW_ARTIST_SETTINGS_SECTION_SUCCESS';
export const ONE_FAILURE = 'ONE_PW_ARTIST_SETTINGS_SECTION_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const setCurrentId = id => setCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY, id);

export const fetchOne = (
    accountId,
    eventId,
    artistTypeId,
    sectionId,
    includes
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchOne(eventId, artistTypeId, sectionId, includes),
        id: sectionId,
        schema: Schemas.entity,
    },
    accountId,
    eventId,
    artistTypeId,
});

export const updateOne = (
    accountId,
    eventId,
    artistTypeId,
    sectionId,
    sectionUuid,
    values
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        endpoint: Endpoints.updateOne(eventId, artistTypeId, sectionId, ['form_fields']),
        id: sectionUuid,
        body: values,
        schema: Schemas.entity,
    },
    accountId,
    eventId,
    artistTypeId
});



