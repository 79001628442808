import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';
import Tooltip from 'app/react/components/util/Tooltip';

const { Action } = Card.Header;

const TEXT = 'Clear filters';
const TOOLTIP = 'Removes all active filters';

const ClearActiveFiltersCardAction = ({
    onClick = () => {},
}) => (
    <Tooltip content={TOOLTIP}>
        <Action onClick={onClick} hasText color="transparent" >
            <i className="bs-icon-cross alert" /> {TEXT}
        </Action>
    </Tooltip>
)

ClearActiveFiltersCardAction.propTypes = {
    onClick: PropTypes.func,
};

export default ClearActiveFiltersCardAction;
