import { oneLineTrim } from 'common-tags';
import {
    includeParam,
} from 'app/react/state/middleware/api'

const root = (eventId, bookingId) =>
 `events/${eventId}/bookings/${bookingId}/transports`;

export const fetchAll = (
    eventId,
    bookingId,
    includes = []
) => oneLineTrim`
    ${root(eventId, bookingId)}?
    ${includeParam(includes)}
`;
