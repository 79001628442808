import BaseController from 'app/backbone/helpers/BaseController'
import CreateBookingContactView from 'app/backbone/views/event/artists/bookings/contacts/Create.view'
import { createRoute } from './contactRoutes'

export default class ContactsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(createRoute(), null, this.create.bind(this));
    }

    create() {
        this.app.renderContentView(new CreateBookingContactView());
    }
}
