import React from 'react'
import {InputGroup} from "ui-kit";

import PropTypes from "prop-types";

const TICKET_TYPE_LABEL = 'Ticket type';
const TICKET_TYPE_PROP_NAME = 'ticketType';
const DEFAULT_ATTENDEE_TYPE_OPTION = {
    value: null,
    label: 'Select ' + TICKET_TYPE_LABEL,
};


const TicketTypeSelectInputGroup = (
    {
        attendeeType,
        options = [],
        selectedValue,
        errors = {},
        onChangeValue = () => {
        },
    }) => {
    options = options.filter(function (item) {
        return (item.attendeeTypes.filter(function (e) {
            return e.code === attendeeType;
        }).length > 0);
    }).map(({id, name}) => ({value: id, label: name}));
    return (
        <fieldset>
            <InputGroup messages={errors[TICKET_TYPE_PROP_NAME]}>
                <label className="required">{TICKET_TYPE_LABEL}</label>
                <select
                    value={selectedValue || ''}
                    onChange={(e) => onChangeValue(TICKET_TYPE_PROP_NAME, e.target.value)}
                >
                    {[DEFAULT_ATTENDEE_TYPE_OPTION, ...options].map(
                        ({value, label}) =>
                            <option key={value} value={value}>{label}</option>
                    )}
                </select>
            </InputGroup>
        </fieldset>
    )
};

export default TicketTypeSelectInputGroup;

TicketTypeSelectInputGroup.propTypes = {
    options: PropTypes.array,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    attendeeType: PropTypes.number.isRequired
};
