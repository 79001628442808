import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    InputGroup,
    DateTimeInput,
    TimeInput,
    Grid,
    PostfixedInput
} from 'ui-kit';
import SearchSelectArtist from '../../../../views/SearchSelectArtistContainer';
import { Modal } from 'app/react/components/_index';
import SelectBooking from '../../selects/SelectBooking';
import SelectBookingStatus from '../../selects/SelectBookingStatus';
import SelectBookingType from '../../selects/SelectBookingType';
import SelectArtistType from '../../selects/SelectArtistType';

const { Header, Content, Footer } = Modal;
const { Cell } = Grid;

const SELECT_ARTIST_LABEL = 'Select artist';
const CREATE_ARTIST_LABEL = 'New artist name';
const SELECT_EXISTING_ARTIST_BUTTON_TEXT = 'Select existing artist';
const SELECT_ARTIST_TYPE_LABEL = 'Artist type';
const SELECT_BOOKING_LABEL = 'Select booking';
const SELECT_BOOKING_PLACEHOLDER = 'Selected an unassigned booking';
const SELECT_BOOKING_NO_RESULTS = 'No unassigned bookings found for the artist on this event';
const SELECT_BOOKING_TYPE_LABEL = 'Booking type';
const SELECT_BOOKING_STATUS_LABEL = 'Booking status';
const SELECT_EXISTING_BOOKING_BUTTON_TEXT = 'Select existing booking';

const PERFORMANCE_NAME_LABEL = 'Performance name';
const PERFORMANCE_CHANGEOVER_LABEL = 'Changeover duration';
const PERFORMANCE_PUBLISHED_DATE_LABEL = 'Publish date';
const PERFORMANCE_START_LABEL = 'Start time';
const PERFORMANCE_END_LABEL = 'End time';
const BOOKING_FIELDSET_LABEL = 'New booking (optional)';

const TITLE = 'Create performance';
const CREATE_BUTTON_TEXT = 'Create';

export const CREATE_MODAL_ID = 'createPerformanceModal';

const CreatePerformanceModal = ({
    store,
    isOpened = false,
    isSyncing = false,
    isCreatingNewArtist = false,
    isCreatingNewBooing = false,
    isEditingPerformanceName = false,
    hasSelectedExistingArtist = false,

    values = [],
    errors = {},

    dateFormat,
    timeFormat,
    isArtistTypesEnabled = true,

    isFetchingBookingsForSelectedArtist = false,
    bookingsForSelectedArtist = [],

    artistTypes = [],
    bookingTypes = [],
    bookingStatuses = [],

    onChangeValue = () => {},
    onSelectArtist = () => {},
    onClosed = () => {},
    onSaved = () => {},
    onToggleCreateNewArtist = () => {},
    onToggleCreateNewBooking = () => {},
    onToggleEditPerformanceName = () => {}
}) => {
    const artistValueIsSet = typeof values.artist !== 'undefined' && values.artist !== '';
    return (
        <Modal
            isReduxProvider
            width={700}
            closeOnBackgroundClick={false}
            opened={isOpened}
            syncing={isSyncing}
            onClosed={onClosed}
        >
            <Header onClosed={onClosed}>
                <Modal.Header.Title>{TITLE}</Modal.Header.Title>
            </Header>
            <Content>
                <fieldset
                    style={{ marginTop: 0 }}
                >
                    <legend>{BOOKING_FIELDSET_LABEL}</legend>
                    {!isCreatingNewArtist 
                        ? <InputGroup>
                            <label>{SELECT_ARTIST_LABEL}</label>
                            <PostfixedInput.Container
                                postfix={
                                    <button
                                        onClick={() => onToggleCreateNewArtist(true)}
                                        className="button postfix button-icon"
                                    >
                                        New
                                    </button>
                                }
                            >
                                <SearchSelectArtist
                                    value={values.artist}
                                    onChange={value => onSelectArtist(value)}
                                />
                            </PostfixedInput.Container>
                        </InputGroup> 
                        : <InputGroup>
                            <label>{CREATE_ARTIST_LABEL}</label>
                            <Grid hasGutters={false}>
                                <Cell>
                                    <input
                                        type="text"
                                        value={values.artist || ''}
                                        onChange={e => onChangeValue('artist', e.target.value)}
                                    />
                                </Cell>
                                <Cell
                                    isAutoSized
                                    isContentCentered
                                    width="25%"
                                >
                                    <div
                                        style={{
                                            margin: '0 0 1rem 0',
                                            width: '100%',
                                            padding: '0.5rem',
                                            border: '1px solid transparent',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <a
                                            onClick={() => onToggleCreateNewArtist(false)}
                                        >
                                            {SELECT_EXISTING_ARTIST_BUTTON_TEXT}
                                        </a>
                                    </div>
                                </Cell>
                            </Grid>
                        </InputGroup>
                    }
                    {(() => {
                        if (isCreatingNewBooing) {
                            return (
                                <div>
                                    {isArtistTypesEnabled &&
                                        <InputGroup messages={errors['bookings.0.artistType']}>
                                            <label>{SELECT_ARTIST_TYPE_LABEL}</label>
                                            <SelectArtistType
                                                artistTypes={artistTypes}
                                                value={values.artistType}
                                                disabled={!artistValueIsSet}
                                                onChange={value => onChangeValue('artistType', value)}
                                            />
                                        </InputGroup>
                                    }
                                    <Grid>
                                        <Cell>
                                            <InputGroup messages={errors['bookings.0.type']}>
                                                <label className={classNames({ required: hasSelectedExistingArtist })}>
                                                    {SELECT_BOOKING_TYPE_LABEL}
                                                </label>
                                                <SelectBookingType
                                                    bookingTypes={bookingTypes}
                                                    value={values.bookingType}
                                                    disabled={!artistValueIsSet}
                                                    onChange={value => onChangeValue('bookingType', value)}
                                                />
                                            </InputGroup>
                                        </Cell>
                                        <Cell>
                                            <InputGroup messages={errors['bookings.0.status']}>
                                                <label className={classNames({ required: hasSelectedExistingArtist })}>
                                                    {SELECT_BOOKING_STATUS_LABEL}
                                                </label>
                                                <SelectBookingStatus
                                                    bookingStatuses={bookingStatuses}
                                                    value={values.bookingStatus}
                                                    disabled={!artistValueIsSet}
                                                    onChange={value => onChangeValue('bookingStatus', value)}
                                                />
                                            </InputGroup>
                                        </Cell>
                                    </Grid>
                                    {hasSelectedExistingArtist && bookingsForSelectedArtist.length > 0 &&
                                        <a onClick={() => onToggleCreateNewBooking(false)}>
                                            {SELECT_EXISTING_BOOKING_BUTTON_TEXT}
                                        </a>
                                    }
                                </div>
                            );
                        }
                        return (
                            <InputGroup>
                                <label>{SELECT_BOOKING_LABEL}</label>
                                <Grid>
                                    <Cell>
                                        <SelectBooking
                                            bookings={bookingsForSelectedArtist}
                                            isLoading={isFetchingBookingsForSelectedArtist}
                                            value={values.booking}
                                            placeholder={SELECT_BOOKING_PLACEHOLDER}
                                            disabled={!hasSelectedExistingArtist}
                                            noResultsText={SELECT_BOOKING_NO_RESULTS}
                                            onChange={value => onChangeValue('booking', value)}
                                        />
                                    </Cell>
                                    <Cell>
                                        <button
                                            disabled={!hasSelectedExistingArtist}
                                            onClick={() => onToggleCreateNewBooking(true)}
                                            className="button postfix button-icon"
                                        >
                                            <i className="bs-icon-plus no-margin" />
                                        </button>
                                    </Cell>
                                </Grid>
                            </InputGroup>
                        );
                    })()}
                </fieldset>
                <InputGroup messages={errors.title}>
                    <label>{PERFORMANCE_NAME_LABEL}</label>
                    <PostfixedInput.Container
                        postfix={!isEditingPerformanceName
                            ? <button
                                className="postfix success"
                                onClick={() => onToggleEditPerformanceName(true)}
                            >
                                Edit
                            </button>
                            : <button
                                className="postfix"
                                onClick={() => onToggleEditPerformanceName(false)}
                            >
                                Delete
                            </button>
                        }
                    >
                        <input
                            type="text"
                            value={values.title || ''}
                            disabled={!isEditingPerformanceName}
                            onChange={e => onChangeValue('title', e.target.value)}
                        />
                    </PostfixedInput.Container>
                </InputGroup>
                <Grid>
                    <Cell>
                        <InputGroup messages={errors.start}>
                            <label className="required">
                                {PERFORMANCE_START_LABEL}
                            </label>
                            <TimeInput
                                showMenuTop
                                value={values.startTime}
                                onChange={value => onChangeValue('startTime', value)}
                                timeFormat={timeFormat}
                            />
                        </InputGroup>
                    </Cell>
                    <Cell>
                        <InputGroup messages={errors.end}>
                            <label className="required">
                                {PERFORMANCE_END_LABEL}
                            </label>
                            <TimeInput
                                showMenuTop
                                value={values.endTime}
                                onChange={value => onChangeValue('endTime', value)}
                                timeFormat={timeFormat}
                            />
                        </InputGroup>
                    </Cell>
                </Grid>
                <Grid className="no-margin-bottom">
                    <Cell>
                        <InputGroup messages={errors.publishDate}>
                            <label>{PERFORMANCE_PUBLISHED_DATE_LABEL}</label>
                            <DateTimeInput
                                showMenuTop
                                value={values.publishDate}
                                dateFormat={dateFormat}
                                onChange={value => onChangeValue('publishDate', value)}
                            />
                        </InputGroup>
                    </Cell>
                    <Cell>
                        <InputGroup messages={errors.changeover}>
                            <label>{PERFORMANCE_CHANGEOVER_LABEL}</label>
                            <PostfixedInput label="min">
                                <input
                                    type="number"
                                    value={values.changeover || ''}
                                    onChange={e => onChangeValue('changeover', e.target.value)}
                                />
                            </PostfixedInput>
                        </InputGroup>
                    </Cell>
                </Grid>
            </Content>
            <Footer>
                <Footer.Button
                    className="success"
                    onClick={onSaved}
                >
                    {CREATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
        </Modal>
    );
};

CreatePerformanceModal.propTypes = {
    store: PropTypes.object.isRequired,
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    isCreatingNewArtist: PropTypes.bool,
    isCreatingNewBooing: PropTypes.bool,
    isEditingPerformanceName: PropTypes.bool,
    hasSelectedExistingArtist: PropTypes.bool,
    isFetchingBookingsForSelectedArtist: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    isArtistTypesEnabled: PropTypes.bool,

    artistTypes: PropTypes.array,
    bookingsForSelectedArtist: PropTypes.array,
    bookingTypes: PropTypes.array,
    bookingStatuses: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectArtist: PropTypes.func,
    onClosed: PropTypes.func,
    onSaved: PropTypes.func,
    onToggleCreateNewArtist: PropTypes.func,
    onToggleCreateNewBooking: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
};

export default CreatePerformanceModal;
