import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import CompanyAccrediteeForm from '../forms/CompanyAccrediteeForm.view';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';

export default class CompanyAccrediteeContactCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(CompanyAccreditee.Model).isRequired,
            title
        };
    }

    initContent() {
        this.form = this.initFrom(this.model);
        return this.form;
    }

    render() {
        super.render();
        this.form.setElement(this.ui.content).render();
        return this;
    }

    initFrom(model, contacts) {
        return new CompanyAccrediteeForm({ model, contacts });
    }

}
