import Backgrid from 'backgrid'
import { html } from 'common-tags'

export default class OfferStatusCell extends Backgrid.Cell {

    className() {
        return 'offer-status-cell';
    }

    initialize(options) {
        super.initialize(options);
        this.offer = this.model.getCurrentOffer();
        if (this.model.hasOffer()) this.status = this.offer.getRelation('status');
    }

    render() {
        this.$el.html(this.template());
        return this.delegateEvents();
    }

    template() {
        if (this.model.hasOffer()) {
            return html`
                <span class="badge ${this.status.get('color_type')}">
                    ${this.status.get('name')}
                </span>
            `;
        }
    }

}
