import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    InputGroup,
    PostfixedInput,
} from 'ui-kit';
import { DebouncedInput } from 'app/react/components/_index';

const { Cell } = Grid;

const DISTANCE_LABEL = 'Distance';
const DURATION_LABEL = 'Duration';
const MEETING_POINT_LABEL = 'Meeting point';

const PickupRouteFields = ({
    values = {},
    errors = {},

    onChangeValue = () => {},
}) => (
    <Fragment>
        <Grid className="margin-tiny-bottom">
            <Cell>
                <InputGroup messages={errors.distance}>
                    <label>{DISTANCE_LABEL}</label>
                    <PostfixedInput label="km">
                        <DebouncedInput
                            type="text"
                            value={values.distance || ''}
                            onChange={value => onChangeValue('distance', value)}
                        />
                    </PostfixedInput>
                </InputGroup>
            </Cell>
            <Cell>
                <InputGroup messages={errors.duration}>
                    <label>{DURATION_LABEL}</label>
                    <PostfixedInput label="min">
                        <DebouncedInput
                            type="number"
                            value={values.duration || ''}
                            onChange={value => onChangeValue('duration', value)}
                        />
                    </PostfixedInput>
                </InputGroup>
            </Cell>
        </Grid>
        <InputGroup messages={errors.meetingPoint}>
            <label>{MEETING_POINT_LABEL}</label>
            <DebouncedInput
                component="textarea"
                value={values.meetingPoint || ''}
                onChange={value => onChangeValue('meetingPoint', value)}
            />
        </InputGroup>
    </Fragment>
);

PickupRouteFields.propTypes = {
    isEditingPerformanceName: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    dayStart: PropTypes.string,
    dayEnd: PropTypes.string,

    days: PropTypes.array,
    stages: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectDay: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
};

export default PickupRouteFields;
