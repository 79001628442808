import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Tooltip from 'tether-tooltip';
import { DEFAULT_POSITION } from '../tooltips/constants';
import BaseView from 'app/backbone/components/Base.view';

export default class IconTooltipView extends BaseView {

    get tagName() {
        return 'i';
    }

    className() {
        return 'margin-small-right';
    }

    optionTypes() {
        return {
            iconClassName: OptionTypes.string.isRequired,
            tooltipText: OptionTypes.string.isRequired,
            tooltipPosition: OptionTypes.string,
            tooltipOpenDelay: OptionTypes.string
        };
    }

    optionDefaults() {
        return {
            tooltipPosition: DEFAULT_POSITION,
            tooltipOpenDelay: 200
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.tooltip = this.initTooltip();
    }

    render() {
        this.$el.addClass(this.iconClassName);
        return this.delegateEvents();
    }

    remove() {
        if (this.tooltip) {
            // Hack to avoid bug on destroy when tooltip is not opened yet
            if (this.tooltip.drop.isOpened()) this.tooltip.destroy();
            else this.tooltip.drop.on('open', () => { this.tooltip.destroy(); });
        }
        super.remove();
    }

    initTooltip() {
        const target = this.el;
        const content = this.tooltipText;
        const position = this.tooltipPosition;
        const openDelay = this.tooltipOpenDelay;
        return new Tooltip({ target, content, position, openDelay });
    }

}
