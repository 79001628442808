import React, { Fragment } from 'react'
import moment from 'moment'
import omitby from 'lodash.omitby'
import humanizeDuration from 'humanize-duration'
import _ from 'underscore'
import $ from 'jquery'
import { reduxStore } from 'app/react/state/_index'
import * as actions from '../state/actions/_index'

export function loadData({
    models = [],
}) {
    return new Promise((resolve, reject) => {
        reduxStore.dispatch(actions.loadingDataStarted());
        $.when.apply(null, models.map(arg => arg && arg.fetch && arg.fetch()))
            .done(() => {
                reduxStore.dispatch(actions.loadingDataDone());
                resolve();
            })
            .fail((xhr) => {
                // ToDo Implement failed dispatch
                reject(xhr);
            });
    });
}

export function getSingleErrorMessageFromResponseJSON(responseJSON) {
    const { status_code, statusCode } = responseJSON;
    // IF status is 500 we do not show the error message instead, we show a default message
    const status500 = status_code === 500 || statusCode === 500;
    const status404 = status_code === 404 || statusCode === 404;
    const hasNoMessage = typeof responseJSON.message === 'undefined';
    if (status500 || status404 || hasNoMessage) {
        return 'Something went wrong, please try again later.';
    }
    // Default we return the general message but we remove the first 4 chars to remove the status code;
    return responseJSON.message.substring(4);
}


export function getFormFieldErrorsFromResponseJSON(responseJSON = {}) {
    const { errors = {} } = responseJSON;
    const formFieldErrors = {};
    _.forEach(errors, (messages, key) => {
        formFieldErrors[key] = messages.map(message => ({ type: 'error', text: message }));
    });
    return formFieldErrors;
}

export function transformGraphQLErrorsToFormErrors(graphQLErrors) {
    const errors = graphQLErrors[0].state;
    if (typeof errors === 'undefined') {
        return {}
    }
    return Object.keys(errors).reduce((formErrors, key) => {
        formErrors[key] = errors[key].map(text => ({ type: 'error', text }));
        return formErrors
    }, {});
}

export function responseJSONHasFormFieldErrors(responseJSON) {
    const { errors = {} } = responseJSON;
    return !!_.keys(errors).length;
}

export function uploadFile(file, fileType) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', fileType);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/api/upload');
    xhr.setRequestHeader('Authorization', `bearer ${window.beatswitch.auth.jwt}`);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.send(formData);

    return new Promise((resolve, reject) => {
        xhr.onload = (ee) => {
            const responseJSON = JSON.parse(ee.target.response);
            resolve(responseJSON);
        };
        xhr.onerror =  () => {
            reject();
        };
    });
}

export function mergeStaticAndCustomFields(staticFields, customFields) {
    const fields = [];
    // We clone all props of the static fields
    fields.push(...staticFields.map(field => Object.assign({}, field)));
    // We clone all props of the custom fields and add the amount of static fields to the sequence.
    // This way all custom fields will come after the static fields
    fields.push(...customFields.map(field => (
        Object.assign(
            {},
            field,
            { sequence: field.sequence  +  staticFields.length }
        )
    )));
    return fields;
}

export function mergeDateWithTime(dateISOString, timeISOString) {
    const mDate = moment.utc(dateISOString, moment.ISO_8601);
    const dateFormat = 'YYYY-MM-DD';
    const datePart = mDate.format(dateFormat);
    const mTime = moment.utc(timeISOString, moment.ISO_8601);
    const timeFormat = 'HH:mm:ss[Z]';
    const timePart = mTime.format(timeFormat);
    return moment.utc(`${datePart}T${timePart}`, `${dateFormat}T${timeFormat}`).toISOString();
}

const INVALID_DURATION = 'Invalid duration';
export function humanizeDifference(startISOString, endISOString) {
    if (
        typeof startISOString === 'undefined' &&
        typeof endISOString === 'undefined'
    ) {
        return INVALID_DURATION;
    }
    const mStart = moment.utc(startISOString);
    const mEnd = moment.utc(endISOString);
    if (
        !mStart.isValid() ||
        !mEnd.isValid()
    ) {
        return INVALID_DURATION;
    }

    return humanizeDuration(mEnd.diff(mStart), {
        language: 'en_short',
        delimiter: ' ',
        languages: {
            en_short: {
                y: c => `year${c !== 1 ? 's' : ''}`,
                mo: c => `month${c !== 1 ? 's' : ''}`,
                w: c => `week${c !== 1 ? 's' : ''}`,
                d: () => 'D',
                h: () => 'H',
                m: () => 'M',
                s: c => `second${c !== 1 ? 's' : ''}`,
                ms: c => `millisecond${c !== 1 ? 's' : ''}`,
                decimal: '.'
            }
        }
    });
}

export const reformatISOString = (ISOString) => {
    if (ISOString) {
        if (ISOString.includes('.')) {
            return ISOString.slice(0, -5) + 'Z';
        } else {
            return ISOString
        }
    }
};

export const dateIsBetween = (date, after, before) => {
    const mDate = moment.utc(date);
    const mAfter = moment.utc(after);
    const mBefore = moment.utc(before);
    return mDate.isBetween(mAfter, mBefore)
};

export const isNumeric = (num) => !isNaN(num);

export const unique = (array) => [...new Set(array)];

export const parseQueryString = (queryString) => {
    const query = {};
    if (queryString !== null) {
        const parts = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        parts.forEach(part => {
            const [key, value] = part.split('=');
            if (key !== '' && value !== '') {
                const decodedKey = decodeURIComponent(key);
                const decodedValue = decodeURIComponent(value);
                query[decodedKey] = isNumeric(decodedValue) ? parseInt(decodedValue, 10) : decodedValue;
            }
        });
    }
    return query;
};

export const combinePaginationFields = (newPagination = {}, previousPagination = {}) => {
    const {
        page = 1,
        pageSize = null,
        filters = null,
        search = null,
        sortKey = null,
        sortOrder = null
    } = newPagination;
    const {
        requestedPageSize,
        requestedFilters,
        requestedSearch,
        requestedSortKey,
        requestedSortOrder
    } = previousPagination;
    return {
        page,
        pageSize: pageSize !== null ? pageSize : requestedPageSize,
        filters: filters !== null ? filters : requestedFilters,
        search: search !== null ? search : requestedSearch,
        sortKey: sortKey !== null ? sortKey : requestedSortKey,
        sortOrder: sortOrder !== null ? sortOrder : requestedSortOrder
    }
};

export const omitAllUndefinedKeys = (obj) =>
    omitby(obj, (value) => typeof value === 'undefined');

export const omitAllNullKeys = (obj) =>
    omitby(obj, (value) => value === null);

export const omitAllEmptyStringKeys = (obj) =>
    omitby(obj, (value) => value === '');

export const omitAllUnsetKeys = (obj) =>
    omitAllUndefinedKeys(omitAllNullKeys(omitAllEmptyStringKeys(obj)));

export const omitByKeys = (obj, keys) =>
    omitby(obj, (value, key) => keys.includes(key));

export const parseIntByKeys = (obj, keys) => ({
    ...obj,
    ...keys.reduce((acc, cur) => {
        const value = obj[cur];
        if (typeof obj[cur] !== 'undefined') {
            acc[cur] = parseInt(value, 10);
        }
        return acc;
    }, {})
});

export const nl2br = (text) => (
    text.split('\n').map((fragment, key) => (
        <Fragment key={key}>
            {fragment}
            <br />
        </Fragment>
    ))
);
