import { Model }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';

@Model({
    key: 'offer_status'
})
class OfferStatus extends BaseModel {

    static Relations() {
        return {
            booking: require('./Offer').Model // Circular
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

}

export {
    OfferStatus as Model
};
