import React from 'react';
import { parseQueryString } from 'app/react/helpers/_index';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Driver from 'app/react/entities/events/drivers/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups/index';
import * as PickupMode from 'app/react/entities/events/bookings/pickups/modes/index';
import * as Event from 'app/react/entities/events/index';
import * as EventLocations from 'app/react/entities/events/locations/index';
import * as BookingAttendees from 'app/react/entities/events/bookings/attendees/index';
import * as Transport from 'app/react/entities/events/bookings/transports/index';
import * as Vehicle from 'app/react/entities/events/vehicles/index';
import * as VehicleType from 'app/react/entities/vehicle_types/index';
import CreateViewContainer from 'app/react/views/event/artists/bookings/pickups/views/create/CreateViewContainer';
import UpdateViewContainer from 'app/react/views/event/artists/bookings/pickups/views/update/UpdateViewContainer';
import * as UpdateViewState from 'app/react/views/event/artists/bookings/pickups/views/update/UpdateViewState';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses'

export const createRoute = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}/pickups/create(?*query)`;

export const updateRoute = (
    eventId = ':event',
    bookingId = ':booking',
    pickupId = ':pickup'
) => `events/${eventId}/artists/bookings/${bookingId}/pickups/${pickupId}/edit(?*query)`;

export default class PickupController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(createRoute(), null, this.create.bind(this));
        this.router.route(updateRoute(), null, this.update.bind(this));
    }

    create(eventId, bookingId, queryString) {
        const { app, store, dispatch } = this;
        const { redirect } = queryString !== null ? parseQueryString(queryString) : {};
        app.renderContentComponent(React.createElement(CreateViewContainer, { store, redirect }));
        // Set the working context
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(Booking.actions.setCurrentId(parseInt(bookingId, 10)));
        // Fetch Data
        return Promise.all([
            dispatch(Booking.thunks.fetchCurrent()),
            dispatch(PickupMode.actions.fetchAll()),
            dispatch(VehicleType.actions.fetchAll()),
            dispatch(Vehicle.thunks.fetchAllForCurrentContext()),
            dispatch(Driver.thunks.fetchAllForCurrentContext()),
            dispatch(EventLocations.thunks.fetchAllForCurrentContext()),
            dispatch(BookingAttendees.thunks.fetchAllForCurrentContext()),
            dispatch(Transport.thunks.fetchAllForCurrentContext()),
            dispatch(BookingStatus.thunks.fetchAllForCurrentContext()),
        ])
    }

    update(eventId, bookingId, pickupId, queryString) {
        const { app, store, dispatch } = this;
        const { redirect } = queryString !== null ? parseQueryString(queryString) : {};
        app.renderContentComponent(React.createElement(UpdateViewContainer, { store, redirect }));
        // Set the working context
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(Booking.actions.setCurrentId(parseInt(bookingId, 10)));
        dispatch(Pickup.actions.setCurrentId(parseInt(pickupId, 10)));
        // Fetch Data
        return Promise.all([
            dispatch(Booking.thunks.fetchCurrent()),
            dispatch(PickupMode.actions.fetchAll()),
            dispatch(VehicleType.actions.fetchAll()),
            dispatch(Vehicle.thunks.fetchAllForCurrentContext()),
            dispatch(Driver.thunks.fetchAllForCurrentContext()),
            dispatch(EventLocations.thunks.fetchAllForCurrentContext()),
            dispatch(BookingAttendees.thunks.fetchAllForCurrentContext()),
            dispatch(Transport.thunks.fetchAllForCurrentContext()),
            dispatch(Pickup.thunks.fetchCurrent()).then(() => {
                const state = store.getState();
                const currentPickup = Pickup.stateHelpers.getCurrent(state);
                const { departureLocation, arrivalLocation } = currentPickup;
                dispatch(UpdateViewState.mergeViewState({
                    values: currentPickup,
                    isSettingCustomDepartureName: typeof departureLocation === 'undefined',
                    isSettingCustomArrivalName : typeof arrivalLocation === 'undefined'
                }));
            }),
            dispatch(BookingStatus.thunks.fetchAllForCurrentContext()),
        ]);
    }


}
