export const form = (
    productionWebsiteId = ':productionWebsite'
) => `production-website-accreditation/${productionWebsiteId}/company-form`;

export const accrediteesCreate = (
    productionWebsiteId = ':productionWebsite'
) => `${form(productionWebsiteId)}/accreditees/create`;

export const accrediteesUpdate = (
    productionWebsiteId = ':productionWebsite',
    accrediteeId = ':accreditee'
) => `${form(productionWebsiteId)}/accreditees/${accrediteeId}/edit`;

export const itemGroupUpdate = (
    productionWebsiteId = ':productionWebsite',
    groupId = ':itemGroup'
) => `${form(productionWebsiteId)}/item-group/${groupId}/edit`;
