import AccrediteeProfileModalAdd from 'app/backbone/views/event/accreditation/accreditees/profiles/modals/AccrediteeProfileModalAdd.view';
import * as CompanyAccrediteeProfile from 'app/backbone/models/event/accreditation/companies/CompanyAccrediteeProfile';

export default class CompanyAccrediteeProfileModalAdd extends AccrediteeProfileModalAdd {

    newModel(attributes) {
        return new CompanyAccrediteeProfile.Model(attributes);
    }

    onProfileSelected(profile) {
        super.onProfileSelected(profile);
        if (profile) {
            this.model.setRelation('available_profile', profile);
        }
    }

}
