var ItemGroupModal = require('./ItemGroupModal.view');

var ItemGroupModalUpdate = ItemGroupModal.extend({

    events: {
        'click .update': 'update',
        'keypress': 'keypress'
    },

    initialize(options) {
        this._super(options);
        this.isUpdating = true;
        this.title = 'Update Item Group';
        this.syncingText = 'Updating Item Group...';
    },

    open(model) {
        this.original = model;
        this.model = this.original.clone();
        this.isSyncing = false;
        this.render();
        this.show();
    },

    bindModel() {
        this._super();
        if(this.original) {
            this.listenTo(this.original, 'request', () => { this.setSyncing(true) });
            this.listenTo(this.original, 'sync', this.onSync);
            this.listenTo(this.original, 'error', this.onError);
        }
    },

    unbindModel() {
        this._super();
        if(this.original) this.stopListening(this.original);
    },

    update() {
        this.model.validate();
        if(this.model.isValid()) {
            this.original.save(this.model.attributes, { wait: true });
        }
    },

    onSync() {
        this.trigger('update', this.original);
        this.unbindModel();
        this.hide();
    },

    keypress(e) {
        if(e.which == 13) {
            e.preventDefault();
            this.update();
        }
    }
});

module.exports = ItemGroupModalUpdate;
