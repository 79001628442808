var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "    <div class=\"grid margin-small-bottom\">\n        <div class=\"table-filters grid-cell\">\n            <div class=\"select-filters grid\"></div>\n        </div>\n        <div class=\"grid-cell grid-cell--justifyEndContent\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hasSearch : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isSyncing : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"grid\">\n        <div class=\"grid-cell\">\n            <div class=\"table-page-size grid-cell\"></div>\n        </div>\n        <div class=\"grid-cell grid-cell--verticalCenterContent table-pager\">\n        </div>\n        <div class=\"grid-cell grid-cell--verticalCenterContent\">\n            <div class=\"pageOffset\">\n                Showing "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.startOffset : stack1), depth0))
    + " - "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.endOffset : stack1), depth0))
    + " of "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.totalRecords : stack1), depth0))
    + "\n            </div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"search-filter grid margin-tiny-left\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.SyncingPlaceholder,depth0,{"name":"SyncingPlaceholder","hash":{"class":"table-placeholder","height":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.tableHeight : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "        <table class=\"backgrid overflow\"></table>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isSyncing : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.SyncingPlaceholder,depth0,{"name":"SyncingPlaceholder","hash":{"class":"table-placeholder","height":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.placeholderHeight : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.NoRecordsPlaceholder,depth0,{"name":"NoRecordsPlaceholder","hash":{"text":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.placeholderText : stack1),"height":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.placeholderHeight : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hasRecordsOrIsSearching : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\n\n";
},"usePartial":true,"useData":true});