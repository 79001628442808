import React from 'react';
import PropTypes from 'prop-types';
import { Card, InputGroup } from 'ui-kit';
import { Loading } from 'app/react/components/_index';
import { NONE_OPTION_VALUE, INPUT_FIELD_MAX_WIDTH } from './TicketSettingsViewContainer';

const CARD_TITLE = 'Ticket Mapper';
const SELECT_INTEGRATION_EVENT_LABEL = (integrationName = 'Integration') => `Select ${integrationName} Event`;
const TABLE_INTEGRATION_PROFILE_HEADER = (integrationName = 'Integration') => `${integrationName} Profile`;
const TABLE_BEATSWITCH_PROFILE_HEADER = 'Beatswitch Profile';
const UPCOMING_GROUP_LABEL = 'Upcoming Events';
const COMPLETED_GROUP_LABEL = 'Completed Events';
const AVAILABLE_PROFILES_GROUP_LABEL = 'Available Profiles';
const UNAVAILABLE_PROFILES_GROUP_LABEL = 'Unavailable Profiles';

const TicketMapperCard = ({
    isLocked = false,
    isFetchingIntegrationEvents = false,

    integrationEventOptions = [],
    integrationEventProfileOptions = [],

    savedTicketSettingsProfiles = [],

    selectedTicketProviderName,
    selectedIntegrationEventId,
    selectedProfilesMapping = {},

    onChangeIntegrationEvent = () => {},
    onChangeIntegrationEventProfile = () => {}
}) => (
    isFetchingIntegrationEvents ?
        <Loading /> :
        <Card>
            <Card.Header>
                <Card.Header.Title>
                    {CARD_TITLE}
                </Card.Header.Title>
            </Card.Header>
            <Card.Content>
                <InputGroup style={{ maxWidth: `${INPUT_FIELD_MAX_WIDTH}px` }}>
                    <label>
                        {SELECT_INTEGRATION_EVENT_LABEL(selectedTicketProviderName)}
                    </label>
                    <select
                        value={selectedIntegrationEventId}
                        disabled={isLocked}
                        onChange={(e) => onChangeIntegrationEvent(e.target.value)}
                    >
                        <optgroup label={UPCOMING_GROUP_LABEL}>
                            { integrationEventOptions.filter(({ completed }) => !completed).map(
                                ({ value, label }) =>
                                    <option key={value} value={value}>{label}</option>
                            )}
                        </optgroup>
                        <optgroup label={COMPLETED_GROUP_LABEL}>
                            { integrationEventOptions.filter(({ completed }) => completed).map(
                                ({ value, label }) =>
                                    <option key={value} value={value}>{label}</option>
                            )}
                        </optgroup>
                    </select>
                </InputGroup>
                <table className="overflow">
                    <thead>
                    <tr>
                        <th>
                            {TABLE_BEATSWITCH_PROFILE_HEADER}
                        </th>
                        <th>
                            {TABLE_INTEGRATION_PROFILE_HEADER(selectedTicketProviderName)}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    { savedTicketSettingsProfiles.map(({ id, name }) => (
                        <tr key={id}>
                            <td>{name}</td>
                            <td className="input">
                                <select
                                    value={selectedProfilesMapping[id] || NONE_OPTION_VALUE}
                                    disabled={isLocked}
                                    onChange={(e) => onChangeIntegrationEventProfile(id, e.target.value)}
                                >
                                    <optgroup label={AVAILABLE_PROFILES_GROUP_LABEL}>
                                        { integrationEventProfileOptions.filter(({ available }) => available).map(
                                            ({ value, label }) =>
                                                <option key={value} value={value}>{label}</option>
                                        )}
                                    </optgroup>
                                    <optgroup label={UNAVAILABLE_PROFILES_GROUP_LABEL}>
                                        { integrationEventProfileOptions.filter(({ available }) => !available).map(
                                            ({ value, label }) =>
                                                <option key={value} value={value}>{label}</option>
                                        )}
                                    </optgroup>
                                </select>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card.Content>
            <Card.Footer />
        </Card>
);

TicketMapperCard.propTypes = {
    isLocked: PropTypes.bool,
    isFetchingIntegrationEvents: PropTypes.bool,

    integrationEventOptions: PropTypes.array,
    integrationEventProfileOptions: PropTypes.array,

    savedTicketSettingsProfiles: PropTypes.array,

    selectedTicketProviderName: PropTypes.string,
    selectedIntegrationEventId: PropTypes.string,
    selectedProfilesMapping: PropTypes.object,

    onChangeIntegrationEvent: PropTypes.func,
    onChangeIntegrationEventProfile: PropTypes.func
};


export default TicketMapperCard;
