import {
    transformRequestBody,
    transformResponseBody,
    transformResponseErrorBody
} from './util'

export const API_ROOT = '/api/';

export const getDataFromAPI = (jwt, endpoint, schema, method, body) => {
    const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;

    const headers = {
        'Authorization': `Bearer ${jwt}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };
    return fetch(fullUrl, { headers, method, body: transformRequestBody(body) })
        .then(response => {
            if (response.status === 204) { // No response body
                if (!response.ok) {
                    return Promise.reject();
                }
                return Promise.resolve();
            }
            return response.json()
                .then(json => {
                    if (!response.ok) {
                        return Promise.reject(transformResponseErrorBody(json));
                    }
                    return transformResponseBody(json, schema);
                });
        });
};


