import BaseController from 'app/backbone/helpers/BaseController';
import EventsController from './EventsController';
import CrmController from './CrmController';
import UsersController from './UsersController';
import AccountSettingsController from './settings/AccountSettingsController';
import ArtistsSettingsController from './settings/ArtistsSettingsController';
import AccreditationSettingsController from './settings/AccreditationSettingsController';

export default class AccountController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new EventsController({ parent: this }),
            new CrmController({ parent: this }),
            new UsersController({ parent: this }),
            new AccountSettingsController({ parent: this }),
            new ArtistsSettingsController({ parent: this }),
            new AccreditationSettingsController({ parent: this }),
        ];
    }

}
