import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import * as actions from 'app/react/state/actions/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as Integration from 'app/react/entities/accounts/integrations/index';
import AuthenticateModal, { AUTHENTICATE_MODAL_ID } from './AuthenticateModal';

const FAILED_TO_AUTHENTICATE_POPUP = 'Failed to authenticate.';
const SUCCESSFUL_AUTHENTICATE_POPUP = 'Successfully authenticated.';

const mapStateToProps = (state) => {
    const {
        isOpened = false,
        isSyncing = false,
        selectedIntegration,
        values = {},
        errors = {}
    } = stateHelpers.getComponentState(state, AUTHENTICATE_MODAL_ID);
    return {
        accountId: stateHelpers.accounts.getCurrentId(state),
        selectedIntegration,
        isOpened,
        isSyncing,
        values,
        errors
    };
};

const mapDispatchToProps = dispatch => ({
    onChangeValue: (key, value) => {
        dispatch(actions.setComponentStateValue(AUTHENTICATE_MODAL_ID, `values.${key}`, value));
    },
    onConfirm: (accountId, integrationName, values) => {
        dispatch(actions.setModalSyncing(AUTHENTICATE_MODAL_ID, true));
        dispatch(Integration.actions.authenticate(accountId, integrationName.toLowerCase(), values))
            .then(({ error }) => {
                dispatch(actions.setModalSyncing(AUTHENTICATE_MODAL_ID, false));
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.mergeComponentState(AUTHENTICATE_MODAL_ID, { errors }));
                    dispatch(actions.showErrorPopup(FAILED_TO_AUTHENTICATE_POPUP));
                } else {
                    dispatch(actions.closeModal(AUTHENTICATE_MODAL_ID));
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_AUTHENTICATE_POPUP));
                }
            });
    },
    onClosed: () => {
        dispatch(actions.closeConfirmModal(AUTHENTICATE_MODAL_ID));
        dispatch(actions.deleteComponentStateValue(AUTHENTICATE_MODAL_ID, 'errors'));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onConfirm: () => {
        const { accountId, selectedIntegration, values } = stateProps;
        dispatchProps.onConfirm(accountId, selectedIntegration.name, values);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AuthenticateModal);
