import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import PeriodTable from '../tables/PeriodTable.view';
import PeriodModalCreate from '../modals/PeriodModalCreate.view';

export default class PeriodOverviewCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            periods: OptionTypes.instanceOf(Period.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // Subviews
        this.createModal = this.initCreateModal();
    }

    initContent() {
        return this.initTable();
    }

    initTable() {
        const { periods } = this;
        return new PeriodTable({ periods });
    }

    initCreateModal() {
        const createModal = new PeriodModalCreate();
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    onCreated() {
        this.periods.fetch({ bustCache: true });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add period',
            icon: 'bs-icon-plus',
            action: this.create.bind(this)
        }];
    }

    create() {
        const event = this.periods.getRelation('event');
        this.createModal.open(null, { event });
    }

}
