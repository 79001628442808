import { createEntityReducer } from 'app/react/state/reducers/higher_order/_index'
import { ENTITY_REDUCER_KEY } from './schemas'
import { ONE_TYPES } from './actions'

const entityReducer = createEntityReducer({
    types: ONE_TYPES
});

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};
