import BaseView from 'app/backbone/components/Base.view'
import { detailRoute } from 'app/routes/event/artists/bookings/contactRoutes'

const DEFAULT_VALUES = {
    firstname: '',
    lastname: '',
    email: '',
    telephone: '',
    role: '',
};

function jsonDetailRoute(contactId) {
    return `/${detailRoute(contactId)}?format=json`;
}

function shouldAddNewContact(id) {
    return !id;
}

export default class CreateBookingContactView extends BaseView {

    ui() {
        return {
            form: '#booking-contact-form',
            contactDropdown: '#booking-contact-form select[name=contact]',
            generalFields: '#booking-contact-form .booking-contact-general-fields input',
        };
    }

    events() {
        return {
            'change contactDropdown': 'toggleGeneralFields',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    toggleGeneralFields(e) {
        const selectedContactId = e.target.value;

        if (shouldAddNewContact(selectedContactId)) {
            this.autofillGeneralFields(DEFAULT_VALUES);
            this.ui.generalFields.removeAttr('disabled');
        } else {
            this.ui.generalFields.attr('disabled', true);
            $.get(jsonDetailRoute(selectedContactId), this.autofillGeneralFields.bind(this), 'json');
        }
    }

    autofillGeneralFields(data) {
        for (let prop in data) {
            this.ui.form.find(`input[name=${prop}]`).val(data[prop]);
        }
    }
}
