import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';
import * as Routes from 'app/routes/account/settings/routes';

const { Tabs, TitleMenu } = Header;
const { Title, Actions } = TitleMenu;
const { Tab } = Tabs;

const ArtistsSettingsContentHeader = ({
    children,
    activeTab
}) => (
    <Header>
        <TitleMenu>
            <Title>Settings</Title>
            <Actions>{children}</Actions>
        </TitleMenu>
        <Tabs>
            <Tab
                tabId="general"
                activeTab={activeTab}
                url={Routes.base()}
                pageReload
            >
                General
            </Tab>
            <Tab
                tabId="integrations"
                activeTab={activeTab}
                url={Routes.integrations()}
            >
                Integrations
            </Tab>
            <Tab
                tabId="privacy"
                activeTab={activeTab}
                url={Routes.privacy()}
            >
                Privacy
            </Tab>
        </Tabs>
    </Header>
);

ArtistsSettingsContentHeader.propTypes = {
    children: PropTypes.node,
    activeTab: PropTypes.string
};

export default ArtistsSettingsContentHeader;
