import {
    openModal,
    setComponentState
} from 'app/react/state/actions/_index';

import { UPDATE_MODAL_ID } from './Modal';

// Actions
export const openUpdateModal = () =>
    openModal(UPDATE_MODAL_ID);
export const mergeUpdateModalState = values =>
    setComponentState(UPDATE_MODAL_ID, values);
