import * as Schemas from './schemas'
import {
    getFirstEntity,
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
} = Schemas;

export const getFirst = state =>
    getFirstEntity(state, ENTITY_REDUCER_KEY);
