import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'artist'
})
class Artist extends BaseModel {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model // Circular
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter an artist name.'
            }
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('account');
        var accountId = this.getRelation('account').get('id');
        return `/api/accounts/${accountId}/artists`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }
}

@Collection({
    key: 'artists',
    model: Artist
})
class ArtistCollection extends BaseCollection {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model // Circular
        }
    }

    url() {
        this.validateRelations('account');
        var accountId = this.getRelation('account').get('id');
        return `/api/accounts/${accountId}/artists`;
    }
}

const PageableCollection = ArtistCollection.prototype.PageableCollection();

export {
    Artist as Model,
    ArtistCollection as Collection,
    PageableCollection
};
