import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Event from 'app/react/entities/events/index';
import * as Vehicle from 'app/react/entities/events/vehicles/index';
import * as VehicleType from 'app/react/entities/vehicle_types/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Driver from 'app/react/entities/events/drivers/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups/index';
import * as PickupMode from 'app/react/entities/events/bookings/pickups/modes/index';
import * as EventLocations from 'app/react/entities/events/locations/index';
import TransportsOverviewView from 'app/backbone/views/event/artists/logistics/TransportsOverview.view';
import DriversOverviewView from 'app/backbone/views/event/artists/logistics/drivers/Overview.view';
import DriversDetailView from 'app/backbone/views/event/artists/logistics/drivers/Detail.view';
import CreateDriverView from 'app/backbone/views/event/artists/logistics/drivers/Create.view';
import VehiclesOverview from 'app/react/views/event/artists/logistics/vehicles/views/VehiclesOverviewContainer';
import PickupsOverview from 'app/react/views/event/artists/logistics/pickups/views/overview/OverviewContainer';
import PickupsScheduler from 'app/react/views/event/artists/logistics/pickups/views/scheduler/SchedulerContainer';
import * as PickupsSchedulerViewState from 'app/react/views/event/artists/logistics/pickups/views/scheduler/SchedulerState';
import * as PickupsSchedulerState from 'app/react/views/event/artists/logistics/pickups/components/scheduler/SchedulerState';
import { calculateDateWithExtraSchedulerHours } from 'app/react/views/event/artists/logistics/pickups/components/scheduler/utils';
import { generateEventDaysFromShowDays } from 'app/react/helpers/days';
import * as Routes from './routes'

export default class LogisticsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.transportsOverview(), null, this.transportsOverview.bind(this));
        this.router.route(Routes.pickupsOverview(), null, this.pickupsOverview.bind(this));
        this.router.route(Routes.pickupsScheduler(), null, this.pickupsScheduler.bind(this));
        this.router.route(Routes.driversOverview(), null, this.driversOverview.bind(this));
        this.router.route(Routes.driversDetail(), null, this.driversDetail.bind(this));
        this.router.route(Routes.createDriver(), null, this.createDriver.bind(this));
        this.router.route(Routes.vehiclesOverview(), null, this.vehiclesOverview.bind(this));
    }

    transportsOverview() {
        this.app.renderContentView(new TransportsOverviewView());
    }

    driversOverview() {
        this.app.renderContentView(new DriversOverviewView());
    }

    driversDetail() {
        this.app.renderContentView(new DriversDetailView());
    }

    createDriver() {
        this.app.renderContentView(new CreateDriverView());
    }

    vehiclesOverview(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(VehiclesOverview, { store }));
        // Set the working context
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(VehicleType.actions.fetchAll()),
        ]);
    }

    pickupsOverview(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(PickupsOverview, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(PickupMode.actions.fetchAll()),
            dispatch(Driver.thunks.fetchAllForCurrentContext()),
            dispatch(VehicleType.actions.fetchAll()),
            dispatch(Vehicle.thunks.fetchAllForCurrentContext()),
            dispatch(EventLocations.thunks.fetchAllForCurrentContext()),
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(Pickup.thunks.fetchAllByPaginationForCurrentContext({}, ['booking.artist', 'passengers', 'vehicle'])),
        ])
    }

    pickupsScheduler(eventId) {
        const { app, dispatch, store, getState } = this;
        app.renderContentComponent(React.createElement(PickupsScheduler, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(PickupMode.actions.fetchAll()),
            dispatch(Driver.thunks.fetchAllForCurrentContext()),
            dispatch(VehicleType.actions.fetchAll()),
            dispatch(Vehicle.thunks.fetchAllForCurrentContext()),
            dispatch(EventLocations.thunks.fetchAllForCurrentContext()),
            dispatch(Day.thunks.fetchAllForCurrentContext()).then(() => {
                const state = getState();
                const days = Day.stateHelpers.getAllForCurrentContext(state);
                const hasDays = days.length > 0;
                if (hasDays) {
                    const dateFormatShort = Event.stateHelpers.getCurrentDateFormatShort(state);
                    const format = `ddd ${dateFormatShort}`;
                    const eventDays = generateEventDaysFromShowDays(days, format, 2);
                    const eventDaysWithExtraHours = eventDays.map(day => ({
                        ...day,
                        endWithExtraSchedulerHours: calculateDateWithExtraSchedulerHours(day.end)
                    }));

                    const selectedDay = eventDaysWithExtraHours[0];
                    dispatch(PickupsSchedulerViewState.actions.setState({ selectedDay, eventDays: eventDaysWithExtraHours }));
                    eventDays.forEach((eventDay) => {
                        dispatch(PickupsSchedulerState.actions.setState(eventDay.id, { activeInterval: '00:15', eventDay }));
                    });

                    const after = selectedDay.start;
                    const before = selectedDay.endWithExtraSchedulerHours;
                    dispatch(Pickup.thunks.fetchAllBetweenDatesForCurrentContextOrLoadFromCache(after, before, ['booking.artist', 'passengers', 'vehicle']))
                }
            }),
        ])
    }
}
