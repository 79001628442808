import Tooltip from 'tether-tooltip';
import CheckboxCell from 'app/backbone/components/tables/cells/CheckboxCell.view';
import { DEFAULT_OPEN_DELAY } from 'app/backbone/components/tooltips/constants';
import template from './AvailableAllDaysCell.hbs';

const AvailableAllDaysCell = CheckboxCell.extend({

    template,

    className: 'checkbox-cell input center',

    attributes: {
        style: 'width:55px'
    },

    initialize(options = {}) {
        this._super(options);

        if (!this.isDateDependable()) this.tooltip = this.initTooltip();

        this.listenTo(this.model.getRelation('available_days'), 'update', this.render);
    },

    initTooltip() {
        const content = 'This type of item is available on all days.';
        const target = this.el;
        const position = 'right center';
        const openDelay = DEFAULT_OPEN_DELAY;
        return new Tooltip({ target, content, position, openDelay });
    },

    viewModel() {
        return {
            view: {
                isDisabled: this.isDisabled(),
                isVisible: this.isVisible(),
                isChecked: this.isChecked(),
                isEditing: this.isEditing(),
                isDateDependable: this.isDateDependable()
            }
        };
    },

    isChecked() {
        return this.model.hasAvailableDayOnAll(this.days);
    },

    isDateDependable() {
        return this.model.get('date_dependable');
    },

    isEditing() {
        return this.editing || false;
    },

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        if (checked) this.model.addAvailableDayOnAll(this.days);
        else this.model.removeAvailableDayOnAll(this.days);
        this.render();
    }

});

module.exports = AvailableAllDaysCell;
