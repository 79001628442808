import dotProp from 'dot-prop-immutable';
import { unique } from 'app/react/helpers/_index'
import {
    ADD_ENTITY_TO_COLLECTION,
    INVALIDATE_COLLECTION_CACHE,
} from '../actions/_index';

export const collections = (state = {}, action) => {
    const { type, collectionKey, collectionSubKey } = action;

    const typesForThisReducer = [ADD_ENTITY_TO_COLLECTION, INVALIDATE_COLLECTION_CACHE];
    const actionIsForThisReducer = typesForThisReducer.includes(type);

    if (actionIsForThisReducer) {

        const collectionPath = typeof collectionSubKey !== 'undefined'
            ? `${collectionKey}.${collectionSubKey}`
            : collectionKey;

        switch (type) {
            case ADD_ENTITY_TO_COLLECTION: {
                const { entityId } = action;
                const idsPath = `${collectionPath}.ids`;
                const ids = dotProp.get(state, idsPath);
                if (typeof ids !== 'undefined') {
                    return dotProp.set(state, idsPath, unique([...ids, entityId]));
                }
                return state;
            }
            case INVALIDATE_COLLECTION_CACHE: {
                const hasCachePath = `${collectionPath}.hasCache`;
                if (typeof hasCachePath !== 'undefined') {
                    return dotProp.set(state, hasCachePath, false);
                }
                return state;
            }
            default:
                return state;
        }
    }

    return state
};
