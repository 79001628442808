import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_ATTENDEE_REQUEST';
export const ONE_SUCCESS = 'ONE_ATTENDEE_SUCCESS';
export const ONE_FAILURE = 'ONE_ATTENDEE_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_PAGINATED_REQUEST = 'ALL_ATTENDEES_PAGINATED_REQUEST';
export const ALL_PAGINATED_SUCCESS = 'ALL_ATTENDEES_PAGINATED_SUCCESS';
export const ALL_PAGINATED_FAILURE = 'ALL_ATTENDEES_PAGINATED_FAILURE';
export const ALL_PAGINATED_TYPES = [
    ALL_PAGINATED_REQUEST,
    ALL_PAGINATED_SUCCESS,
    ALL_PAGINATED_FAILURE
];

export const deleteOne = (
    accountId,
    eventId,
    attendeeId
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, attendeeId),
        schema: Schemas.entity,
        id: attendeeId
    },
    accountId,
    eventId
});

export const updateOne = (
    accountId,
    eventId,
    attendeeId,
    values
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOne(eventId, attendeeId),
        schema: Schemas.entity,
        id: attendeeId,
    },
    accountId,
    eventId
});

export const fetchAllPaginated = (
    accountId,
    eventId,
    pagination,
    includes
) => {
    const {
        page = 1,
        pageSize = 100,
        filters = {},
        search = '',
        sortKey = '',
        sortOrder = 1,
    } = pagination;
    return {
        [CALL_API]: {
            types: ALL_PAGINATED_TYPES,
            endpoint: Endpoints.fetchPaginated(
                eventId,
                page,
                pageSize,
                filters,
                search,
                sortKey,
                sortOrder,
                includes
            ),
            requestedPage: page,
            requestedPageSize: pageSize,
            requestedFilters: filters,
            requestedSearch: search,
            requestedSortKey: sortKey,
            requestedSortOrder: sortOrder,
            schema: Schemas.entities
        },
        accountId,
        eventId,
    };
};
