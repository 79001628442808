import $ from 'jquery';
import Backgrid from 'backgrid';

export default class SubTableRow extends Backgrid.Row {

    initialize(options = {}) {
        super.initialize(options);
        this.subTableCollection = this.getSubTableCollection();
        this.subTableRow = null;

        this.listenTo(this.model, 'backgrid:sub-table:created', this.setSubTable);
        this.listenTo(this.model, 'remove', this.removeTableRow);
        this.listenTo(this.subTableCollection, 'update', this.renderSubTableRow);
    }

    render() {
        super.render();
        this.renderSubTableRow();
        return this;
    }

    renderSubTableRow() {
        if (this.subTable) {
            if (this.subTableCollection.length === 0) this.removeTableRow();
            else if (!this.subTableRow) this.appendTableRow();
        }
    }

    getSubTableCollection() {
        // Needs to be overridden
        return null;
    }

    setSubTable(table) {
        this.subTable = table.table;
        this.subTable.header = null;
    }

    appendTableRow() {
        const { subTable } = this;

        const subTableRow = this.subTableRow || this.createRow();
        const subTableCell = this.subTableCell || this.createCell();

        subTableCell.html(subTable.el);
        subTableRow.html(subTableCell);
        subTable.$el.addClass('sub-table');
        this.$el.after(subTableRow);

        subTable.render();

        this.subTableRow = subTableRow;
        this.subTableCell = subTableCell;
    }

    createRow() {
        return $('<tr></tr>');
    }

    createCell() {
        const colspan = this.subTable.columns.length;
        return $(`<td style="padding: 0;" colspan="${colspan}"></td>`);
    }

    removeTableRow() {
        if (this.subTableRow) {
            this.subTableRow.remove();
            this.subTableRow = null;
        }
    }

}
