import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import * as Stage from './Stage';

@Model({
    key: 'stage_zone'
})
class StageZone extends BaseModel {

    static Relations() {
        return {
            event: require('./Event').Model, // Circular
            stages: Stage.Collection
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a stage name.'
            },
            capacity: {
                required: false,
                pattern: 'digits',
                msg: 'Stage capacity must be a positive number.'
            }
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
        // We listen to updates on the days and sync the corresponding stages
        this.days = this.getRelation('event').getRelation('days');
        this.listenTo(this.days, 'add', this.onAddDay);
        this.listenTo(this.days, 'remove', this.onRemoveDay);
        this.listenTo(this.days, 'sort', this.onSortDays);
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

    onAddDay(day) {
        this.stages.add(new Stage.Model({ selected: true }, { day }));
    }

    onRemoveDay(day) {
        this.stages.remove(this.stages.find((stage) => stage.getRelation('day').cid === day.cid));
    }

    onSortDays() {
        const startIndex = 1;
        const stages = this.getRelation('stages');
        this.days.forEach((day, index) => {
            const sequence = startIndex + index;
            const foundStage = this.stages.find((stage) => stage.getRelation('day').cid === day.cid);
            if (foundStage) foundStage.set({ sequence });
        });
        this.stages.sort();
    }

    syncStagesWithDays() {
        const startIndex = 1;
        const stages = this.getRelation('stages');
        this.days.forEach((day, index) => {
            const sequence = startIndex + index;
            const foundStage = this.stages.find((stage) => stage.getRelation('day').cid === day.cid);
            if (foundStage) foundStage.set({ sequence });
            else this.stages.add(new Stage.Model({ selected: true }, { day }));
            this.stages.sort();
        });
    }

}

@Collection({
    key: 'stage_zones',
    model: StageZone
})
class StageZoneCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('./Event').Model // Circular
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.on('add', (model, collection) =>{
            model.set('sequence', collection.indexOf(model) + 1);
        });
    }

    comparator(model) {
        return model.get('sequence');
    }

    filterSelectedStagesByDay(day) {
        const { event } = this;
        const stages =  new Stage.Collection(null, event);
        this.forEach((stageZone) => {
            const selectedStage = stageZone.getRelation('stages').find((stage) => stage.getRelation('day').cid === day.cid && stage.get('selected'));
            if (selectedStage) {
                if (!selectedStage.get('name')) selectedStage.set('name', stageZone.get('name'));
                selectedStage.set('capacity', stageZone.get('capacity'));
                selectedStage.set('sequence', stageZone.get('sequence'));
                stages.add(selectedStage);
            }
        });
        return stages;
    }

}

const PageableCollection = StageZoneCollection.prototype.PageableCollection();

export {
    StageZone as Model,
    StageZoneCollection as Collection,
    PageableCollection
};
