import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_PW_ARTIST_SETTINGS_REQUEST';
export const ONE_SUCCESS = 'ONE_PW_ARTIST_SETTINGS_SUCCESS';
export const ONE_FAILURE = 'ONE_PW_ARTIST_SETTINGS_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const fetchOne = (accountId, eventId, artistTypeId, artistSettingsId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchOne(eventId, artistTypeId, ['sections.form_fields']),
        id: artistSettingsId,
        schema: Schemas.entity
    },
    accountId,
    eventId,
    artistTypeId
});

export const updateOne = (accountId, eventId, artistTypeId, artistSettingsId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        endpoint: Endpoints.updateOne(eventId, artistTypeId, ['sections.form_fields']),
        id: artistSettingsId,
        body: values,
        schema: Schemas.entity,
    },
    accountId,
    eventId,
    artistTypeId
});



