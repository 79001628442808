import CompanyItemModalAdd from '../../../items/modals/CompanyItemModalAdd.view';
import * as CompanyProfileItem from 'app/backbone/models/event/accreditation/companies/CompanyProfileItem';

export default class CompanyProfileItemModalAdd extends CompanyItemModalAdd {

    newModel(attributes) {
        return new CompanyProfileItem.Model(attributes);
    }

}

