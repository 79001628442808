import React from 'react';
import { TipType } from '../../types/Event';
import Icon from '../../utils/Icon';

const TipCard = ({tip}) => (
			<div className="tip-card">
				{tip.type === 'note' && 
				(<React.Fragment>
					<Icon
								size={32}
								name="info"
								color='#0CD3AB'
								style={{marginBottom: '20px'}}
						/>
					<b>{tip.title}</b>
					<span>{tip.message}</span>
				</React.Fragment>)
				}
				{tip.type === 'tip' && (
					(
						<React.Fragment>
							<span>
								<Icon
										size={26}
										name="lightbulb"
										style={{marginBottom: '-10px'}}
								/>
								<b>Tip:</b> {tip.message}
							</span>
					</React.Fragment>)
				)}
			</div>
);

TipCard.propTypes = {
    tip: TipType.isRequired
};

export default TipCard;
