import BaseView from 'app/backbone/components/Base.view';
import DataTable from 'app/backbone/components/tables/DataTable.view';

export default class TicketTypesOverview extends BaseView {

    ui() {
        return {
            table: '#ticket-types-table'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.table = new DataTable();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.table, this.ui.table);
        return this.delegateEvents();
    }

}
