import React from 'react'
import { Tooltip } from 'ui-kit'

const MapsLink = ({ address, className }) => {
    const { country } = address;
    const addressParts = [
        address.street,
        address.city,
        address.zip,
        address.state,
        country.name,
    ];
    const fullAddress = addressParts
        .filter((value) => value !== null && value !== '')
        .join(',');

    return (
        <Tooltip renderContent={() => 'View on Google Maps'}>
            <a
                href={`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${fullAddress}&ie=UTF8`}
                target="_blank"
                className={className}
            >
                <i className="bs-icon-marker" />
            </a>
        </Tooltip>
    );
};

export default MapsLink;
