import { schema } from 'normalizr';
import * as RoomType from 'app/react/entities/events/accommodations/room_types/schemas';
import * as Reservations from 'app/react/entities/events/reservations/schemas';

export const ENTITY_REDUCER_KEY = 'accommodations';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

export const processStrategy = entity => ({
    ...entity,
    totalPriceForReservationsByPromoter: entity.totalPriceForReservationsByPromoter
        ? parseFloat(parseInt(entity.totalPriceForReservationsByPromoter || 0, 10) / 100).toFixed(2)
        : null
});

const entityDefinition = {
    roomTypes: RoomType.entities,
    reservations: Reservations.entities,
};

const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);
