var Backgrid = require('backgrid');

var template = require('./TextInputCell.hbs');

var TextInputCell = Backgrid.Cell.extend({

    template,

    className: 'text-input-cell input',

    events: {
        'change input[type=text]':  'onChangeInput'
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                isDisabled: this.isDisabled(),
                isVisible: this.isVisible(),
                value: this.getValue()
            }
        };
    },

    isDisabled() {
        return false;
    },

    isVisible() {
        return true;
    },

    getValue() {
        return this.model.get(this.column.get('name'));
    },

    onChangeInput(e) {
        var value = this.$(e.currentTarget).val();
        if(value) this.model.set(this.column.get('name'), value);
        this.render();
    }
});

module.exports = TextInputCell;
