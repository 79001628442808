import React from 'react';
import PropTypes from 'prop-types'
import { InputGroup } from 'ui-kit'
import TextEditor from 'app/react/views/event/production_website/artists/ProductionWebsiteSettingsTextEditor'
import {
    DESCRIPTION_PROP_NAME,
    DESCRIPTION_LABEL,
    DESCRIPTION_HEIGHT,
} from './constants'

export const GeneralForm = ({
    values,
    errors = {},
    onChangeValue = () => {},
}) => (
    <div>
        <InputGroup
            messages={errors[DESCRIPTION_PROP_NAME]}
        >
            <label>{DESCRIPTION_LABEL}</label>
            <TextEditor
                imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                height={DESCRIPTION_HEIGHT}
                value={values[DESCRIPTION_PROP_NAME] || ''}
                onBlur={e => onChangeValue(DESCRIPTION_PROP_NAME, e.target.value)}
            />
        </InputGroup>
    </div>
);

GeneralForm.propTypes = {
    values: PropTypes.shape({
        [DESCRIPTION_PROP_NAME]: PropTypes.string
    }).isRequired,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
};
