import { connect } from 'react-redux';
import * as VehicleType from 'app/react/entities/vehicle_types/index';
import * as State from './UpdateModalState';
import Modal from '../Modal';

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSaving,
        values,
        errors,
    } = State.getState(state);
    return {
        isCreating: false,
        vehicleTypes: VehicleType.stateHelpers.getAll(state),
        isOpened,
        isSaving,
        values,
        errors
    }
};

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch(State.close());
    },
    onChangeValue: (key, value) => {
        dispatch(State.changeValue(key, value));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Modal);
