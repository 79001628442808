import { mergeComponentState, registerComponent } from './components';

export const initialFormState = {
    fields: [],
    values: {},
    errors: {}
};

export const registerFormComponentState = (storeId, {
    fields = initialFormState.fields,
    values = initialFormState.values,
    errors = initialFormState.errors
}) => (
    registerComponent(storeId, { fields, values, errors })
);

export const updateFormComponentStateValue = (storeId, key, value) => (dispatch, getState) => {
    const currentValues = getState().components[storeId].values;
    const values = Object.assign({}, currentValues, { [key]: value });
    return dispatch(mergeComponentState(storeId, { values }));
};

export const deleteFormComponentStateValue = (storeId, key) => (dispatch, getState) => {
    const currentValues = getState().components[storeId].values;
    const values = Object.assign({}, currentValues);
    delete values[key];
    return dispatch(mergeComponentState(storeId, { values }));
};

export const updateFormComponentStateMapValue = (storeId, key, mapValue) => (dispatch, getState) => {
    const currentValues = getState().components[storeId].values;
    const newValue = currentValues[key].map(mapValue)
    const values = {
        ...currentValues,
        [key]: newValue
    };
    return dispatch(mergeComponentState(storeId, { values }));
};

export const resetComponentStateValues = (storeId) => (
    mergeComponentState(storeId, { values: {} })
);

export const updateFormComponentStateValuesFromModel = (storeId, model) => (
    mergeComponentState(storeId, { values: Object.assign({}, model.attributes) })
);

export const updateFormComponentStateValueFromModel = (storeId, key, model) => (
    updateFormComponentStateValue(storeId, key, Object.assign({}, model.attributes))
);
