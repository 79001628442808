var ZoneModal = require('./ZoneModal.view');

var ZoneModalCreate = ZoneModal.extend({

    events: {
        'click .create': 'create',
        'click .create-another': 'createAnother',
        'keypress': 'keypress'
    },

    initialize(options) {
        this._super(options);
        this.isUpdating = false;
        this.title = 'Create Zone';
        this.syncingText = 'Creating Zone...';
    },

    open(initialAttr, initialOptions) {
        this.model = this.newModel(initialAttr, initialOptions);
        this.initialAttr = initialAttr;
        this.initialOptions = initialOptions;
        this.isSyncing = false;
        this.render();
        this.show();
    },

    bindModel() {
        this._super();
        if(this.model) {
            this.listenTo(this.model, 'request', () => { this.setSyncing(true) });
            this.listenTo(this.model, 'sync', this.onSync);
            this.listenTo(this.model, 'error', this.onError);
        }
    },

    unbindModel() {
        this._super();
        if(this.model) this.stopListening(this.model);
    },

    create(e, another = false) {
        if(this.model.isValid(true)) {
            this.isCreatingAnother = another;
            this.model.save();
        }
    },

    createAnother(e) {
        this.create(e, true);
    },

    onSync() {
        this.trigger('create', this.model);
        this.unbindModel(this.model);
        if(this.isCreatingAnother) {
            let attr = this.initialAttr;
            let options = this.initialOptions;
            this.model = this.newModel(attr, options);
            this.setSyncing(false);
            this.focusFirstInput();
        } else {
            this.hide();
        }
    },

    keypress(e) {
        if(e.which == 13) {
            e.preventDefault();
            if(this.canCreateAnother) this.createAnother();
            else this.create();
        }
    }
});

module.exports = ZoneModalCreate;
