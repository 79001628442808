import BaseView from 'app/backbone/components/Base.view';
import DOM from 'app/backbone/helpers/DOM';
import DataTable from 'app/backbone/components/tables/DataTable.view';
import EventsCalendar from './EventsCalendar.view';

export default class EventsOverview extends BaseView {

    ui() {
        return {
            table: '#events-table',
            calendar: '#events-calendar',
            tableContainer: '#table-container',
            calendarContainer: '#calendar-container',
            showTableButton: '#show-table',
            showCalendarButton: '#show-calendar',
            createEventButton: '.create-event'
        };
    }

    events() {
        return {
            'click showTableButton': 'onClickShowTable',
            'click showCalendarButton': 'onClickShowCalendar',
            'click createEventButton': 'onClickCreateEvent'
        };
    }

    initialize() {
        this.table = this.initTable();
        this.calendar = this.initCalendar();
        this.shownSubview = this.table;
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();

        if (this.shownSubview === this.table) {
            this.ui.showTableButton.addClass('active');
            this.ui.showCalendarButton.removeClass('active');
            this.ui.calendarContainer.addClass('hidden');
            this.ui.tableContainer.removeClass('hidden');
        }

        if (this.shownSubview === this.calendar) {
            this.ui.showCalendarButton.addClass('active');
            this.ui.showTableButton.removeClass('active');
            this.ui.tableContainer.addClass('hidden');
            this.ui.calendarContainer.removeClass('hidden');
        }

        if (this.isTableShown()) this.assignSubview(this.table, this.ui.table);
        if (this.isCalendarShown()) this.assignSubview(this.calendar, this.ui.calendar);

        return this.delegateEvents();
    }

    initTable() {
        return new DataTable();
    }

    initCalendar() {
        const events = this.getFormattedEvents();
        return new EventsCalendar({ events });
    }

    getFormattedEvents() {
        const events = DOM.getData('events');
        // Add 1 day to the end date to visualize correctly in fullcalendar.
        return events.map((event) => {
            const formattedEvent = event;
            if (formattedEvent.end) {
                const dayMonthYear = formattedEvent.end.split('-');
                let [year, month, day] = dayMonthYear;
                day = parseInt(day) + 1; // Add 1 day
                if (day.toString().length === 1) day = `0${day}`; // Add zero
                formattedEvent.end = `${year}-${month}-${day}`;
            }
            return event;
        });
    }

    isTableShown() {
        return ! this.ui.tableContainer.hasClass('hidden');
    }

    isCalendarShown() {
        return ! this.ui.calendarContainer.hasClass('hidden');
    }

    onClickShowTable() {
        this.shownSubview = this.table;
        this.render();
    }

    onClickShowCalendar() {
        this.shownSubview = this.calendar;
        this.render();
    }

    onClickCreateEvent() {
        navigate('/events/create-multi-day', { pageReload: true });
    }

}

