import Backbone from 'backbone';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import GeneralCard from './cards/GeneralCard.view';
import AccrediteesCard from '../accreditees/cards/AccrediteesCard.view';
import CompanyItemGroupCard from '../items/cards/CompanyItemGroupCard.view';
import template from './CompanyForm.hbs';

export default class CompanyForm extends Content {

    get template() {
        return template;
    }

    ui() {
        return {
            generalCard: '#general-card',
            accrediteesCard: '#accreditees-card',
            itemsCards: '#items-cards',
            sendForApprovalButton: '#approval-button'
        };
    }

    events() {
        return {
            'click sendForApprovalButton': 'sendForApproval'
        };
    }

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            accreditees: OptionTypes.instanceOf(CompanyAccreditee.PageableCollection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.generalCard = this.initGeneralCard();
        this.accrediteesCard = this.initAccrediteesCard();
        this.itemsCards = this.initItemsCards();
        this.confirmModal = this.initConfirmModal();
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.assignSubview(this.generalCard, this.ui.generalCard);
        this.assignSubview(this.accrediteesCard, this.ui.accrediteesCard);
        this.appendSubviews(this.itemsCards, this.ui.itemsCards);
        return this.delegateEvents();
    }

    remove() {
        super.remove();
        this.generalCard.remove();
        this.accrediteesCard.remove();
        this.itemsCards.call('remove');
    }

    viewModel() {
        const { company, event } = this;

        return {
            view: {
                eventName: event.attributes.name,
                isSendButtonVisible: company.canRequestApproval(),
                isAwaitingApproval: company.isAwaitingApproval(),
                isApproved: company.isApproved() || company.areTicketsSent(),
                isDenied: company.isDenied(),
                deniedReason: company.request_status.get('reason'),
            }
        };
    }

    initGeneralCard() {
        const { company } = this;
        const title = company.production_website_settings.attributes.general_title;
        return new GeneralCard({ company, title });
    }

    initAccrediteesCard() {
        const { accreditees, company } = this;
        const isCollapsible = true;
        const collapsed = true;
        const title = company.production_website_settings.attributes.accreditees_title;
        return new AccrediteesCard({ accreditees, company, title, isCollapsible, collapsed });
    }

    initItemsCards() {
        const { company, periods } = this;
        const isCollapsible = true;
        const collapsed = true;
        const itemGroups = company.getItemGroups();
        const cards = itemGroups.map((itemGroup) => {
            const items = company.getCompanyItemsByItemGroup(itemGroup);
            const editing = false;
            return new CompanyItemGroupCard({ company, items, itemGroup, periods, editing, isCollapsible, collapsed });
        });
        return new Backbone.ChildViewContainer(cards);
    }

    sendForApproval() {
        this.confirmModal.open({
            title: 'Send For Approval',
            body: 'Are you sure you want to send this data to the promoter for approval? <strong>By clicking send, the request form will be locked and the status will change to "waiting for approval".</strong>',
            confirmClass: 'submit',
            confirmText: 'Send',
            autoClose: true,
            syncingText: 'Sending data to the promoter...',
            confirm: () => {
                this.company.requestApproval().done(() => {
                    location.reload(true);
                });
            }
        });
    }
}
