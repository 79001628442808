var _ = require('underscore'),
    Backbone = require('backbone');

var ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin');

var template = require('./CustomAccrediteeForm.hbs');

var CustomAccrediteeForm = Backbone.View.extend({

    mixins: [
        ValidatedForm,
        Form
    ],

    template,

    bindings: {
        '[name=firstname]': 'firstname',
        '[name=lastname]': 'lastname',
        '[name=email]': 'email',
        '[name=telephone]': 'telephone'
    },

    options: [
        'model!',
        'contacts!',
        'hiddenInputs',
        'disabledInputs'
    ],

    initialize(options) {
        this.setOptions(options);

        // Default Options
        !_.isUndefined(this.hiddenInputs) || (this.hiddenInputs = []);
        !_.isUndefined(this.disabledInputs) || (this.disabledInputs = []);

        this.inputs = new Backbone.ChildViewContainer();
        this.groups = new Backbone.ChildViewContainer();
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.unstickit();
        this.initForm(this.model, this.contacts);
        this.stickit();
        this.renderForm();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                hiddenInputs: this.hiddenInputs,
                placeholderHeight: this.placeholderHeight
            },
            model: this.model.attributes
        };
    },

    initForm(model, contacts) {
        this.disableInputs(this.disabledInputs);

        if(this.isAvailable('contact')) {
            let contactsSelectize = this.initSingleSelectize('contact', { collection: contacts });
            this.listenTo(contactsSelectize, 'selected', this.onContactSelected);
            this.inputs.add(contactsSelectize, 'contact');
        }

        if(this.isAvailable('firstname')) this.groups.add(this.initValidationGroup('firstname', model), 'firstname');
        if(this.isAvailable('lastname')) this.groups.add(this.initValidationGroup('lastname', model), 'lastname');
        if(this.isAvailable('email')) this.groups.add(this.initValidationGroup('email', model), 'email');
        if(this.isAvailable('telephone')) this.groups.add(this.initValidationGroup('telephone', model), 'telephone');
    },

    renderForm() {
        this.inputs.call('render');
        this.groups.call('render');
    },

    onContactSelected(contact) {
        if(contact) {
            this.model.set({
                id: contact.get('id'),
                firstname: contact.get('firstname'),
                lastname: contact.get('lastname'),
                fullname: contact.get('fullname'),
                email: contact.get('email'),
                telephone: contact.get('telephone')
            });
        }
    }
});

module.exports = CustomAccrediteeForm;
