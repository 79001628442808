import { METHODS } from '../../middleware/api';

const initialPaginationState = {
    isFetching: false,
    pageCount: 0,
    ids: []
};

export const entitySuccessPaginationReducer = (state, action) => {
    const { method } = action;
    const hasIds = typeof state.ids !== 'undefined' && state.ids.length > 0;
    if (method === METHODS.DELETE && hasIds) {
        return {
            ...state,
            ids: state.ids.filter(id => id !== action.id),
        };
    }
    return state;
};

const paginationReducer = (types = [], oneTypes  = []) => (state = initialPaginationState, action) => {
    const [oneRequestType, oneSuccessType, oneFailureType] = oneTypes;
    const [requestType, successType, failureType] = types;
    const { id, requestedPage, requestedPageSize, requestedFilters, requestedSearch, requestedSortKey, requestedSortOrder } = action;
    switch (action.type) {
        case requestType:
            return {
                ...state,
                isFetching: true,
                requestedPage,
                requestedPageSize,
                requestedFilters,
                requestedSearch,
                requestedSortKey,
                requestedSortOrder,
            };
        case successType:
            if (action.method === METHODS.DELETE && typeof id !== 'undefined') {
                return {
                    ...state,
                    isFetching: false,
                    ids: state.ids.filter(iteratingId => iteratingId !== id)
                };
            }
            const { pagination = {} } = action.response;
            return {
                ...state,
                ...pagination,
                isFetching: false,
                ids: Array.isArray(action.response.result) ?
                    action.response.result :
                    [action.response.result],
                pageCount: state.pageCount + 1
            };
        case failureType:
            return {
                ...state,
                isFetching: false
            };
        case oneSuccessType:
            return entitySuccessPaginationReducer(state, action);
        default:
            return state;
    }
};

export const createPaginationReducer = ({ types, oneTypes }) => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.');
    }
    if (!types.every(t => typeof t === 'string')) {
        throw new Error('Expected types to be strings.');
    }

    return paginationReducer(types, oneTypes);
};

export const createPaginationReducerWithKey = ({ types, oneTypes = [], mapActionToKey }) => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.');
    }
    if (!types.every(t => typeof t === 'string')) {
        throw new Error('Expected types to be strings.');
    }
    if (typeof mapActionToKey !== 'function') {
        throw new Error('Expected mapActionToKey to be a function.');
    }

    const [requestType, successType, failureType] = types;
    const [oneRequestType, oneSuccessType, oneFailureType] = oneTypes;

    return (state = {}, action) => {
        // Update pagination by key
        switch (action.type) {
            case requestType:
            case successType:
            case failureType:
            case oneRequestType:
            case oneSuccessType:
            case oneFailureType: {
                const key = mapActionToKey(action);
                if (typeof key !== 'string' && typeof key !== 'number') {
                    throw new Error('Expected key to be a string.');
                }
                return {
                    ...state,
                    [key]: paginationReducer(types, oneTypes)(state[key], action)
                };
            }
            default:
                return state;
        }
    };
};

