import React from 'react'
import BaseController from 'app/backbone/helpers/BaseController'
import * as Event from 'app/react/entities/events/index'
import MobileAppView from 'app/react/views/shared/marketing/mobile_app/views/Overview'
import TimetableWebEmbedView from 'app/react/views/shared/marketing/timetable_web_embed/views/Overview'
import PlaylistGeneratorView from 'app/react/views/shared/marketing/playlist_generator/views/Overview'
import * as eventRoutes from 'app/routes/event/marketing/routes'
import * as accountRoutes from 'app/routes/account/marketing/routes'

export default class MarketingController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        router.route(eventRoutes.mobileApp(), null, this.mobileApp.bind(this));
        router.route(accountRoutes.mobileApp(), null, this.mobileApp.bind(this));
        router.route(eventRoutes.timetableWebEmbed(), null, this.timetableWebEmbed.bind(this));
        router.route(accountRoutes.timetableWebEmbed(), null, this.timetableWebEmbed.bind(this));
        router.route(eventRoutes.playlistGenerator(), null, this.playlistGenerator.bind(this));
        router.route(accountRoutes.playlistGenerator(), null, this.playlistGenerator.bind(this));
    }

    mobileApp(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(MobileAppView, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
    }

    timetableWebEmbed(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(TimetableWebEmbedView, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
    }

    playlistGenerator(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(PlaylistGeneratorView, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
    }
}
