var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert-box warning hasIconPrefix\">\n        <i class=\"bs-icon-clock\"></i>\n        <p>This company's accreditation request is <strong>awaiting approval</strong>.</p>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isApproved : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert-box success hasIconPrefix\">\n        <i class=\"bs-icon-check-round\"></i>\n        <p>This company's accreditation request is <strong>approved</strong>.</p>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isDenied : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert-box alert hasIconPrefix\">\n        <i class=\"bs-icon-triangle-exclamation-mark\"></i>\n        <p>This company's accreditation request is <strong>denied</strong>.</p>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"grid\">\n        <div class=\"grid-cell grid-cell--justifyEndContent\">\n            <a id=\"deny\" class=\"button danger\">Deny</a>\n            <a id=\"approve\" class=\"button success\">Approve</a>\n        </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isAwaitingApproval : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "<div id=\"accreditees-card\" class=\"card\"></div>\n<div id=\"items-cards\" class=\"grid\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isAwaitingApproval : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});