import _ from 'underscore';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import PeriodDaysTable from 'app/backbone/views/event/settings/accreditation/components/PeriodDaysTable.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as CompanyItem from 'app/backbone/models/event/accreditation/companies/CompanyItem';
import GrantedAllDaysCell from './cells/GrantedAllDaysCell.view';
import GrantedDayCell from './cells/GrantedDayCell.view';

export default class GrantedCompanyItemTable extends PeriodDaysTable {

    optionTypes() {
        return _.extend({}, super.optionTypes(), {
            collection: OptionTypes.instanceOf(CompanyItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            editing: OptionTypes.bool,
            placeholderHeight: OptionTypes.number
        });
    }

    optionDefaults() {
        return _.extend({}, super.optionDefaults(), {
            editing: true,
            placeholderHeight: 100
        });
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        this.actions = this.initActions();
        this.firstColumns = this.initFirstColumns();
        this.columns = this.initColumns();
        this.lastColumns = this.initLastColumns();
        this.placeholderText = this.getPlaceholderText();
        this.overflowX = true;

        super.initialize(options);
    }

    initFirstColumns() {
        return [{
            name: 'name',
            label: 'Item',
            cell: 'string',
            headerCell: CellFactory.createHeaderCell('200px'),
            sortable: false,
            editable: false
        }, {
            name: 'all_days',
            label: 'All',
            cell: this.createAllDaysCell(this.periods.getAllDays()),
            headerCell: CellFactory.createHeaderCell('60px', 'center'),
            sortable: false,
            editable: false
        }];
    }

    initColumns() {
        return [];
    }

    initLastColumns() {
        return [];
    }

    initActions() {
        return [];
    }

    removeItem(item) {
        this.collection.remove(item);
    }

    createDayCell(day, className) {
        const { editing } = this;
        const extendedClassName = `${GrantedDayCell.prototype.className} ${className}`;
        return GrantedDayCell.extend({ day, className: extendedClassName, editing });
    }

    createAllDaysCell() {
        const { editing, periods } = this;
        const days = periods.getAllDays();
        return GrantedAllDaysCell.extend({ days, editing });
    }

    getPlaceholderText() {
        return 'No items have been added for this event.';
    }

}
