import BaseController from 'app/backbone/helpers/BaseController';
import CompanyController from './CompanyController';
import CustomAccrediteeController from './CustomAccrediteeController';

export default class AccreditationController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new CompanyController({ parent: this }),
            new CustomAccrediteeController({ parent: this }),
        ];
    }

}
