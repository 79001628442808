import React from 'react';

const SAVING_NEW_TEXT = 'Saving Time Slot...';

const BookingHeaderRow = () => (
    <tr style={{ height: '49px' }}>
        <td colSpan="6" style={{ textAlign: 'center' }}>
            <div className="spinner inline-left tiny" />
            {SAVING_NEW_TEXT}
        </td>
    </tr>
);

export default BookingHeaderRow;
