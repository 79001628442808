import React, { Fragment, useState } from 'react'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Loading from 'app/react/components/util/Loading'
import LoadingPlaceholder from 'app/react/components/tables/LoadingPlaceholder'
import ScrollSentinel from 'app/react/components/tables/ScrollSentinel'
import AllDataFetchedPlaceholder from 'app/react/components/tables/AllDataFetchedPlaceholder'
import Table from './Table'

const boxOfficeQuery = gql`
    query getBoxOfficeData(
        $eventId: ID!, 
        $day: ID, 
        $isCheckedIn: Boolean,
        $includePerformers: Boolean!,
        $includeCrew: Boolean!,
        $includeGuests: Boolean!,
        $includeTicketTypes: [ID!],
        $search: String,
        $sort: [String!],
        $after: String,
        $first: Int
    ) {
        event(id: $eventId) {
            bookingAttendees(
                day: $day,
                isCheckedIn: $isCheckedIn,
                includePerformers: $includePerformers,
                includeCrew: $includeCrew,
                includeGuests: $includeGuests,
                ticketTypes: $includeTicketTypes,
                search: $search,
                sort: $sort,
                after: $after,
                first: $first,
            ) {
                edges {
                    node {
                        id
                        firstName
                        lastName
                        email
                        telephone
                        isCheckedIn
                        checkedInAt
                        booking {
                            id
                            artist {
                                name
                                image {
                                    url
                                }
                                country {
                                    code
                                    name
                                }
                            }
                            performance {
                                start
                                end
                                day {
                                    name
                                    start
                                    end
                                }
                                stage {
                                    name
                                }
                            }
                        }
                        ... on Performer {
                            role
                            passport {
                                uuid
                                filename
                                extension
                                url
                                name
                                description
                                createdAt
                            }
                            ticketType {
                                name
                            }
                        }
                        ... on CrewMember {
                            role
                            passport {
                                uuid
                                filename
                                extension
                                url
                                name
                                description
                                createdAt
                            }
                            ticketType {
                                name
                            }
                        }
                        ... on Guest {
                            via
                            birthday
                            ticketType {
                                name
                            }
                            day {
                                name
                            }
                        }
                        __typename
                    }
                }
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

const TableWithData = (props) => {
    const { data, first } = props;

    if (data.loading || isFetchingNextCanceled()) {
        return <LoadingPlaceholder />;
    }

    const [fetchedAmount, setFetchedAmount] = useState(first);
    const [isFetchingNext, setIsFetchingNext] = useState(false);
    const { event, fetchMore } = data;
    const { bookingAttendees } = event;
    const { pageInfo } = bookingAttendees;
    const { hasNextPage, endCursor } = pageInfo;
    const bookingAttendeeNodes = bookingAttendees.edges.map((edge) => edge.node);

    function onDeleteAttendee() {
        fetchMore({
            variables: {
                after: null,
                first: fetchedAmount,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult,
        });
    }

    function isFetchingNextCanceled() {
        return typeof data.event === 'undefined';
    }

    async function fetchNext() {
        setIsFetchingNext(true);

        await fetchMore({
            variables: {
                after: endCursor,
                first,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newBookingAttendees = fetchMoreResult.event.bookingAttendees.edges;
                const newPageInfo = fetchMoreResult.event.bookingAttendees.pageInfo;

                if (newBookingAttendees.length === 0) {
                    return previousResult;
                }

                setFetchedAmount(fetchedAmount + first);

                return {
                    event: {
                        ...event,
                        bookingAttendees: {
                            edges: [...bookingAttendees.edges, ...newBookingAttendees],
                            pageInfo: newPageInfo,
                        },
                    },
                };
            },
        });

        setIsFetchingNext(false);
    }

    return (
        <Fragment>
            <Table
                {...props}
                onDeleteAttendee={onDeleteAttendee}
                bookingAttendees={bookingAttendeeNodes}
            />
            {!isFetchingNext && hasNextPage && (
                <ScrollSentinel onIntersect={fetchNext} />
            )}
            {isFetchingNext && (
                <Loading />
            )}
            {!hasNextPage && (
                <AllDataFetchedPlaceholder />
            )}
        </Fragment>
    );
};

export default compose(
    graphql(boxOfficeQuery)
)(TableWithData);
