import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledSortCell = styled.th`
    &:hover {
        cursor: pointer;
    }
`;

export const StyledSortIcon = styled.i`
    font-size: 10px;
    margin-left: ${props => props.hasText ? '20px' : '0'};
    
    &:before {
        vertical-align: 0 !important;
    }
`;

export const SortCell = ({
    className,
    style,
    children,
    sortKey,
    currentSortKey,
    currentSortOrder,
    onSort = () => {}
}) => (
    <StyledSortCell
        className={className}
        style={style}
        onClick={() => onSort(sortKey, currentSortOrder === 1 ? -1 : 1)}>
        {children}
        {(() => {
            if (typeof currentSortKey !== 'undefined' && currentSortKey === sortKey) {
                if (currentSortOrder === 1) {
                    return (
                        <StyledSortIcon
                            className="bs-icon-arrow-thin-up"
                            hasText={typeof children !== 'undefined'}
                        />
                    )
                }
                return (
                    <StyledSortIcon
                        className="bs-icon-arrow-thin-down"
                        hasText={typeof children !== 'undefined'}
                    />
                )
            }
            return null
        })()}
    </StyledSortCell>
);

SortCell.propTypes = {
    sortKey: PropTypes.string.isRequired,
    currentSortKey: PropTypes.string,
    currentSortOrder: PropTypes.number,
    onSort: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};

export default SortCell;
