import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Button,
    InputGroup,
} from 'ui-kit';
import { ConfirmModal } from 'app/react/components/_index';
import {
    requestStatusMap,
    isDeniedStatus,
    isAwaitingApprovalStatus,
} from "app/react/helpers/reservations";

const LABEL_REASON = 'Briefly describe why this reservation has been denied:';

const ReviewStatusModal = ({
   isOpened = false,
   isSyncing = false,

   reservation = {},
   errors = {},

   onClosed = () => {},
   onSaved = () => {},
   onChangeValue = () => {},
}) => {
    const {
        status = {}
    } = reservation;
    const requestStatus = requestStatusMap[status.id] || {
        message() {}
    };
    const isDenied = isDeniedStatus(requestStatus.ID);
    let buttonClassName = Button.PRIMARY;

    if (isDenied) {
        buttonClassName = Button.DANGER;
    } else if (isAwaitingApprovalStatus(requestStatus.ID)) {
        buttonClassName = Button.WARNING;
    }

    return (
        <ConfirmModal
            isReduxProvider
            title={requestStatus.ACTION}
            buttonText={requestStatus.ACTION}
            buttonClassName={buttonClassName}
            closeOnBackgroundClick={false}
            isOpened={isOpened}
            isSyncing={isSyncing}
            onClosed={onClosed}
            onConfirmed={onSaved}
        >
            <p
                className="margin-tiny-bottom"
                dangerouslySetInnerHTML={{__html: requestStatus.message(reservation.name)}}
            />
            {isDenied && (
                <InputGroup messages={errors.reason} className="no-margin-bottom">
                    <label>{LABEL_REASON}</label>
                    <textarea
                        value={status.reason}
                        onChange={e => onChangeValue('reason', e.target.value)}
                    />
                </InputGroup>
            )}
        </ConfirmModal>
    );
};

ReviewStatusModal.propTypes = {
    reason: PropTypes.string,
};

export default ReviewStatusModal;
