import _ from 'underscore';
import Backbone from 'backbone';
import BaseView from 'app/backbone/components/Base.view';
import CardAction from './actions/CardAction.view';

export default class CardActionContainer extends BaseView {

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.renderActions();
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {},
            model: {}
        };
    }

    remove() {
        super.remove();
        this.actions.call('remove');
    }

    initHeaderActions() {
        return this.initActions('header');
    }

    initFooterActions() {
        return this.initActions('footer');
    }

    initActions(type) {
        const actions = new Backbone.ChildViewContainer();
        this.actions.forEach((action) => {
            actions.add(this.initAction(_.extend(action, { type })), action.name);
        });
        return actions;
    }

    renderActions() {
        this.actions.forEach((action) => {
            if (action.canRender(this.model)) this.$el.append(action.render().el);
        });
    }

    initAction(options) {
        options.extraClassName = options.className;
        delete options.className; // Removes to original class name
        return new CardAction(options);
    }

    toggleHideAction(name, hidden) {
        const action = this.getAction(name);
        if (action) action.toggleHide(hidden);
    }

    showAction(name) {
        const action = this.getAction(name);
        if (action) action.show();
    }

    showAllActions() {
        this.actions.call('show');
    }

    hideAction(name) {
        const action = this.getAction(name);
        if (action) action.hide();
    }

    hideAllActions() {
        this.actions.call('hide');
    }

    toggleDisableAction(name, disabled) {
        const action = this.getAction(name);
        if (action) action.toggleDisable(disabled);
    }

    enableAction(name) {
        const action = this.getAction(name);
        if (action) action.enable();
    }

    disableAction(name) {
        const action = this.getAction(name);
        if (action) action.disable();
    }

    getAction(name) {
        return this.actions.findByCustom(name);
    }

}
