import React, { Fragment } from 'react'
import { DateTime } from 'luxon'
import { Button, DismissibleModal } from 'ui-kit'
import { isCrew, isGuest, isPerformer } from 'app/react/helpers/graphQlTypes'

const BUFFER_TIME = { hours: 4 };

function typeNameWithPrefix(attendee) {
    if (isPerformer(attendee)) {
        return 'a performer';
    }

    if (isCrew(attendee)) {
        return 'a crew member';
    }

    if (isGuest(attendee)) {
        return 'a guest';
    }
}

const CheckInWarningModal = ({
    eventId,
    attendee,
    renderTrigger,
    onConfirm,
}) => {
    const {
        firstName,
        lastName,
        booking,
    } = attendee;
    const name = [firstName, lastName].join(' ');
    const artist = { booking };
    const day = booking.performance && booking.performance.day || new Date();
    const today = DateTime.local();
    const isPassedCheckInDay = DateTime.fromISO(day.start).minus(BUFFER_TIME) < today;
    const isFutureCheckInDay = DateTime.fromISO(day.end).plus(BUFFER_TIME) > today;

    function renderModalTrigger(triggerProps) {
        return renderTrigger({
            ...triggerProps,
            isPassedCheckInDay,
            isFutureCheckInDay,
        });
    }

    return (
        <DismissibleModal
            rememberAs={`event.${eventId}.boxOffice.dismissCheckInWarningModal`}
            title="Possible check-in day mismatch"
            renderTrigger={renderModalTrigger}
            onConfirm={onConfirm}
            dismissButtonType={Button.WARNING}
            dismissButtonText="Yes, check-in"
        >
            {isPassedCheckInDay && (
                <Fragment>
                    You're about to check-in <strong>{name}</strong>, who is {typeNameWithPrefix(attendee)} for the
                    <strong>{artist.name}</strong> performance which is already done. Are you sure you want to proceed the check-in?
                </Fragment>
            )}
            {isFutureCheckInDay && (
                <Fragment>
                    You're about to check-in <strong>{name}</strong>, who is {typeNameWithPrefix(attendee)} for the
                    <strong>{artist.name}</strong> performance which takes place on a different day. Are you sure you want to proceed the check-in?
                </Fragment>
            )}
        </DismissibleModal>
    );
};

export default CheckInWarningModal;
