import { schema } from 'normalizr';
import * as AccountItem from 'app/react/entities/accounts/items/schemas';

export const ENTITY_REDUCER_KEY = 'eventArtistItems';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const processStrategy = AccountItem.processStrategy;

const entityDefinition = {};
const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);

export const toAPI = AccountItem.toAPI;
