import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from 'app/react/components/_index';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import ContentHeader, { TABS } from '../../views/ContentHeader';
import ArtistTypesOverviewCard from './Card';
import Table from './Table';
import CreateArtistTypeModal from './Modal';

const { Content } = ContentWrapper;

const ArtistTypesOverview = ({
    store,
    eventId,
    isFetching = false,
    artistTypes = [],
    cardState = {},
    modalState = {},
    confirmModalState = {},
    onCardCreate = () => {},
    onTableGoToForm = () => {},
    onTableEdit = () => {},
    onTableDelete = () => {},
    onTableDuplicate = () => {},
    onModalSave = () => {},
    onModalChangeValue = () => {},
    onModalClosed = () => {},
    onConfirmModalConfirmed = () => {},
    onConfirmModalClosed = () => {}
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            activeTab={TABS.ARTIST_TYPES.ID}
            eventId={eventId}
            artistTypesEnabled
        />
        <Content isLoading={isFetching}>
            <ArtistTypesOverviewCard
                footer={cardState.footer}
                onCreate={onCardCreate}
            >
                <Table
                    artistTypes={artistTypes}
                    onGoToForm={onTableGoToForm}
                    onEdit={onTableEdit}
                    onDelete={onTableDelete}
                    onDuplicate={onTableDuplicate}
                />
            </ArtistTypesOverviewCard>
            <CreateArtistTypeModal
                isOpened={modalState.isOpened}
                isSyncing={modalState.isSyncing}
                isCreating={modalState.isCreating}
                isDuplicating={modalState.isDuplicating}
                values={modalState.values}
                errors={modalState.errors}
                footer={modalState.footer}
                onChangeValue={onModalChangeValue}
                onClosed={onModalClosed}
                onSave={onModalSave}
            />
        </Content>
        <ConfirmModal
            isOpened={confirmModalState.isOpened}
            isSyncing={confirmModalState.isSyncing}
            title={confirmModalState.title}
            text={confirmModalState.text}
            buttonText={confirmModalState.buttonText}
            buttonClassName="alert"
            onConfirmed={onConfirmModalConfirmed}
            onClosed={onConfirmModalClosed}
        />
    </ContentWrapper>
);

ArtistTypesOverview.propTypes = {
    store: PropTypes.object.isRequired,
    // State
    isFetching: PropTypes.bool,
    eventId: PropTypes.number.isRequired,
    artistTypes: PropTypes.array,
    cardState: PropTypes.object,
    modalState: PropTypes.object,
    confirmModalState: PropTypes.object,
    onCardCreate: PropTypes.func,
    onTableGoToForm: PropTypes.func,
    onTableEdit: PropTypes.func,
    onTableDuplicate: PropTypes.func,
    onModalSave: PropTypes.func,
    onModalChangeValue: PropTypes.func,
    onModalClosed: PropTypes.func
};

export default ArtistTypesOverview;
