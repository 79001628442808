import EventCompanyView from './EventCompany.view';

export default class EventCompanyCreateView extends EventCompanyView {

    events() {
        return {
            'click .create': 'create',
            'click .create-another': 'createAnother'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.isUpdating = false;
        this.syncingText = 'Creating company...';
    }

    create(e, another = false) {
        this.model.validate();
        this.model.primary_contact.validate();
        if (this.model.isValid() && this.model.primary_contact.isValid()) {
            this.isCreatingAnother = another;
            this.model.forceCreate();
        }
    }

    createAnother(e) {
        this.create(e, true);
    }

    onSync() {
        this.unbindModel();
        if (this.isCreatingAnother) {
            this.model = this.newModel();
            this.companyCard.remove();
            this.profilesCard.remove();
            this.itemsCard.remove();
            this.generalSettingsCard.remove();
            this.companyCard = this.initCompanyCard(this.model, this.companies);
            this.profilesCard = this.initProfilesCard(this.model, this.periods, this.profiles);
            this.itemsCard = this.initItemsCard(this.model, this.periods, this.items);
            this.generalSettingsCard = this.initGeneralSettingsCard();
            this.bindModel();
            this.companies.fetch({ bustCache: true }).done(() => { this.setSyncing(false); });
        } else {
            this.eventCompanies.bustCache();
            this.myEventCompanies.bustCache();
            this.backToOverview();
        }
    }
}
