var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"groupedColumnsTable\">\n        <div class=\"columnGroup first-columns-container\"></div>\n        <div class=\"columnGroup isScrollable table-container "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.overflowClassName : stack1), depth0))
    + "\"></div>\n        <div class=\"last-columns-container\"></div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.NoRecordsPlaceholder,depth0,{"name":"NoRecordsPlaceholder","hash":{"heightUnit":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.placeholderHeightUnit : stack1),"text":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.placeholderText : stack1),"height":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.placeholderHeight : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.totalRecords : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});