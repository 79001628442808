import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PageableTable from 'app/backbone/components/tables/PageableTable.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import ZoneModalUpdate from '../modals/ZoneModalUpdate.view';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';

export default class ZoneTable extends PageableTable {

    optionTypes() {
        return {
            zones: OptionTypes.instanceOf(Zone.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.collection = this.zones;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.placeholderText = this.getPlaceholderText();

        // SubViews
        this.confirmModal = this.initConfirmModal();
        this.updateModal = this.initUpdateModel();

        super.initialize(options);
    }

    initColumns() {
        const columns = super.initColumns();
        columns.push({
            name: 'name',
            label: 'Name',
            headerCell: CellFactory.createHeaderCell('40%'),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'symbol',
            label: 'Symbol',
            headerCell: CellFactory.createHeaderCell('100px', 'center'),
            cell: CellFactory.createCell('100px', 'uppercase center'),
            sortable: false,
            editable: false
        }, {
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(this.actions),
            sortable: false,
            editable: false
        });
        return columns;
    }

    initActions() {
        return [{
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            action: this.update.bind(this)
        }, {
            name: 'remove',
            icon: 'bs-icon-bin',
            tooltip: 'Remove',
            action: this.delete.bind(this)
        }];
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    initUpdateModel() {
        return new ZoneModalUpdate();
    }

    update(zone) {
        this.updateModal.open(zone);
    }

    delete(zone) {
        const name = zone.get('name');
        this.confirmModal.open({
            title: 'Remove Zone',
            body: `Are you sure you want to remove <strong>${name}</strong>?`,
            confirmClass: 'danger',
            confirmText: 'Yes, remove',
            autoClose: true,
            syncingText: `Removing ${name}...`,
            confirm: () => { zone.destroy({ wait: true }); }
        });
    }

    getPlaceholderText() {
        return 'No zones have been created for this event.';
    }

}
