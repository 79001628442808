import { connect } from 'react-redux';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/index';
import * as ProductionWebsite from 'app/react/entities/events/production_website/settings/general/index';
import * as Performance from 'app/react/entities/events/performances/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import DetailsModal, { DETAILS_MODAL_ID } from './Modal';
import {
    closeDetailsModal,
    deleteModalState,
    mergeDetailsModalState
} from './ModalState';

const POPUP_MESSAGES = {
    FAILED_TO_PUBLISH_POPUP: (error) =>
        `Failed to publish performance. ${error}`,
    SUCCESSFUL_PUBLISH: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully published.`,
    SUCCESSFUL_UNPUBLISH: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully unpublished.`
}

const mapStateToProps = (state) => {
    const {
        isOpened,
        isPublishing,
        selectedPerformanceId,
        successCallback
    } = stateHelpers.getComponentState(state, DETAILS_MODAL_ID) || {};
    const performance = Performance.stateHelpers.getOne(state, selectedPerformanceId) || { bookings: [] };
    const firstBookingId = performance.bookings && performance.bookings[0];
    const firstBooking = Booking.stateHelpers.getOne(state, firstBookingId) || {};
    return {
        isOpened,
        isPublishing,
        performance,
        successCallback,
        // stage and day are still include in performance need to denormalize
        firstArtist: Artist.stateHelpers.getOne(state, firstBooking.artist),
        day: stateHelpers.getOneEntity(state, 'days', performance.day),
        stage: stateHelpers.getOneEntity(state, 'stages', performance.stage),
        productionWebsite: ProductionWebsite.stateHelpers.getFirst(state),
        dateFormat: Event.stateHelpers.getCurrentDateFormat(state),
        timeFormat: Event.stateHelpers.getCurrentTimeFormat(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClosed: (performance, successCallback) => {
        successCallback(performance);
        dispatch(closeDetailsModal());
        dispatch(deleteModalState('newBooking'));
        dispatch(deleteModalState('newTimeSlot'));
        dispatch(deleteModalState('existingBooking'));
    },
    onTogglePublishPerformance: (performanceId, performanceName, published, successCallback) => {
        dispatch(mergeDetailsModalState({ isPublishing: true }));
        dispatch(Performance.thunks.publishOneForCurrentContext(performanceId, published))
            .then(({ isFailure, errorMessage, response }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_PUBLISH_POPUP(errorMessage)));
                } else {
                    const successMessage = published ?
                        POPUP_MESSAGES.SUCCESSFUL_PUBLISH(performanceName) :
                        POPUP_MESSAGES.SUCCESSFUL_UNPUBLISH(performanceName);
                    dispatch(actions.showSuccessPopup(successMessage));
                }
                dispatch(mergeDetailsModalState({ isPublishing: false }));
                successCallback(Performance.stateHelpers.getOne(response, response.result));
            });
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onClosed: () => {
        const { performance, successCallback } = stateProps;
        dispatchProps.onClosed(performance, successCallback); 
    },
    onTogglePublishPerformance: () => {
        const { performance, successCallback } = stateProps;
        dispatchProps.onTogglePublishPerformance(performance.id, performance.title, !performance.published, successCallback);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(DetailsModal);
