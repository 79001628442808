import CustomAccreditee from './CustomAccreditee.view';

export default class CustomAccrediteeUpdateView extends CustomAccreditee {

    events() {
        return {
            'click .update': 'update',
            'click .back': 'backToOverview'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.isUpdating = true;
        this.syncingText = 'Updating attendee...';
        this.contactCard.form.hiddenInputs.push('contact');
        this.contactCard.header.hideAction('create');
    }

    update() {
        if (this.model.isValid(true)) {
            this.model.save();
        }
    }

    onSync() {
        this.unbindModel();
        this.accreditees.bustCache();
        this.backToOverview();
    }
}
