import dotProp from 'dot-prop-immutable';
import { METHODS } from '../../middleware/api';

const initialEntitiesState = {};

export const entityRequestReducer = (state, action) => {
    const { method, id, updateType } = action;
    if (method === METHODS.GET) {
        return dotProp.set(state, id, { id, isFetching: true });
    }
    if (method === METHODS.PUT) {
        switch (updateType) {
            case 'artist': {
                return dotProp.set(state, `${id}.isFetchingArtist`, true);
            }
            case 'manager': {
                return dotProp.set(state, `${id}.isFetchingManager`, true);
            }
            default: {
                return dotProp.set(state, `${id}.isFetching`, true);
            }
        }
    }
    return state;
};

export const entitySuccessReducer = (state, action) => {
    const { method, id, updateType } = action;
    if (method === METHODS.DELETE) {
        return dotProp.delete(state, id);
    }
    if (method === METHODS.GET) {
        return dotProp.set(state, `${id}.isFetching`, false);
    }
    if (method === METHODS.PUT) {
        switch (updateType) {
            case 'artist': {
                return dotProp.set(state, `${id}.isFetchingArtist`, false);
            }
            case 'manager': {
                return dotProp.set(state, `${id}.isFetchingManager`, false);
            }
            default: {
                return dotProp.set(state, `${id}.isFetching`, false);
            }
        }
    }
    return state;
};

export const entityFailureReducer = (state, action) => {
    const { method, id } = action;
    if (method === METHODS.GET) {
        return dotProp.set(state, `${id}.isFetching`, false);
    }
    if (method === METHODS.PUT) {
        return dotProp.set(state, `${id}.isFetching`, false);
    }
    return state;
};

export const createEntityReducer = ({ types }) => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.');
    }
    if (!types.every(t => typeof t === 'string')) {
        throw new Error('Expected types to be strings.');
    }

    const [requestType, successType, failureType] = types;
    return (state = initialEntitiesState, action) => {
        const { type, id } = action;
        if (typeof id === 'undefined') {
            // throw new Error('Expected action to have an id.');
            return state;
        }
        switch (type) {
            case requestType: {
                return entityRequestReducer(state, action)
            }
            case successType: {
                return entitySuccessReducer(state, action)
            }
            case failureType: {
                return entityFailureReducer(state, action);
            }
            default: {
                return state;
            }
        }
    }
};

