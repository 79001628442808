import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ALL_REQUEST = 'ALL_ACCOMMODATIONS_REQUEST';
export const ALL_SUCCESS = 'ALL_ACCOMMODATIONS_SUCCESS';
export const ALL_FAILURE = 'ALL_ACCOMMODATIONS_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const ALL_PAGINATED_REQUEST = 'ALL_ACCOMMODATIONS_PAGINATED_REQUEST';
export const ALL_PAGINATED_SUCCESS = 'ALL_ACCOMMODATIONS_PAGINATED_SUCCESS';
export const ALL_PAGINATED_FAILURE = 'ALL_ACCOMMODATIONS_PAGINATED_FAILURE';
export const ALL_PAGINATED_TYPES = [
    ALL_PAGINATED_REQUEST,
    ALL_PAGINATED_SUCCESS,
    ALL_PAGINATED_FAILURE
];

export const fetchAll = (accountId, eventId, includes) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId, includes),
        schema: Schemas.entities
    },
    accountId,
    eventId
});

export const fetchAllPaginated = (
    accountId,
    eventId,
    pagination,
    includes
) => {
    const {
        page = 1,
        pageSize = 100,
        filters = {},
        search = '',
        sortKey = '',
        sortOrder = 1,
    } = pagination;
    return {
        [CALL_API]: {
            types: ALL_PAGINATED_TYPES,
            endpoint: Endpoints.fetchPaginated(
                eventId,
                page,
                pageSize,
                filters,
                search,
                sortKey,
                sortOrder,
                includes
            ),
            requestedPage: page,
            requestedPageSize: pageSize,
            requestedFilters: filters,
            requestedSearch: search,
            requestedSortKey: sortKey,
            requestedSortOrder: sortOrder,
            schema: Schemas.entities
        },
        accountId,
        eventId,
    };
};

