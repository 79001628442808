import BaseController from 'app/backbone/helpers/BaseController';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import AccrediteeController from './AccrediteeController';
import ItemGroupController from './ItemGroupController';
import CompanyFormView from 'app/backbone/views/production_website/accreditation/company/CompanyForm.view';
import * as Routes from './routes';

// Backbone Controller
export default class CompanyController extends BaseController {

    constructor(options = {}) {
        super(options);

        this.router.route(Routes.form(), null, this.form.bind(this));

        this.subControllers = [
            new AccrediteeController({ parent: this }),
            new ItemGroupController({ parent: this })
        ];
    }

    form(eventId) {
        const { app, data } = this;
        app.renderSyncingView();

        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const company = data.register('eventCompany', EventCompany.Model, { event });
        const accreditees = data.register('pagedCompanyAccreditees', CompanyAccreditee.PageableCollection, { event, company });
        const periods = data.register('allPeriods', Period.Collection, { event });

        company.setIncludes([
            'production_website_settings',
            'profiles.days',
            'profiles.available_days',
            'profiles.granted_days',
            'items.groups',
            'items.available_days',
            'items.granted_days',
            'permissions'
        ]);
        accreditees.setIncludes(['profiles', 'items.groups', 'permissions']);
        periods.setIncludes(['days']);
        company.getIdFromDOM('event_company');

        this.when(
            account.fetch(),
            event.fetch(),
            company.fetch(),
            periods.fetch(),
            accreditees.fetch()
        ).done(() => {
            this.app.renderContentView(new CompanyFormView({ event, company, accreditees, periods }));
        });

    }

}
