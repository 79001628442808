import Backbone from 'backbone';
import Backgrid from 'backgrid';

export default class BaseBody extends Backgrid.Body {

    insertRow(model, collection, options) {
        if (this.rows[0] instanceof Backgrid.EmptyRow) this.rows.pop().remove();

        // insertRow() is called directly
        if (!(collection instanceof Backbone.Collection) && !options) {
            this.collection.add(model, (options = collection));
            return;
        }

        const { columns } = this
        const newRow = new this.row({ columns, model });

        const index = collection.indexOf(model);
        this.rows.splice(index, 0, newRow);

        this.render();

        const $children = this.$el.children();
        
        if (index >= $children.length) this.$el.append(newRow.el);
        else $children.eq(index).before(newRow.el);
        // we render after we added the row.
        newRow.render();

        return this;
    }

    render() {
        this.$el.empty();

        const fragment = document.createDocumentFragment();
        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];
            fragment.appendChild(row.el);
            // we render after we appended the row.
            row.render();
        }

        this.el.appendChild(fragment);

        this.delegateEvents();

        return this;
    }

}
