import _ from 'underscore';
import BaseView from './Base.view';
import Datepicker from './inputs/Datepicker.view';
import Timepicker from './inputs/Timepicker.view';
import SingleSelectize from './inputs/SingleSelectize.view';
import ValidationGroup from './inputs/ValidationGroup.view';

export default class FormView extends BaseView {

    initInput(inputName, options = {}, Clazz) {
        const $input = this.$('form').find(`[name=${inputName}]`);
        options.el = $input;
        if (!options.modelAttr) options.modelAttr = inputName;
        if ($input.length) return new Clazz(options);
        else throw new Error(`Cannot find input with name \`${inputName}\``);
    }

    initDatepicker(inputName, options = {}) {
        return this.initInput(inputName, options, Datepicker);
    }

    initTimepicker(inputName, options = {}) {
        return this.initInput(inputName, options, Timepicker);
    }

    initSingleSelectize(inputName, options = {}) {
        return this.initInput(inputName, options, SingleSelectize);
    }

    initValidationGroup(inputName, options) {
        const $group = this.$('form').find(`[name=${inputName}]`).closest('.input-group');
        options.el = $group;
        options.inputName = inputName;
        if (!options.modelAttr) options.modelAttr = inputName;
        if ($group.length) {
            const validationGroup = new ValidationGroup(options);
            validationGroup.removeError();
            return validationGroup;
        } else throw new Error(`Cannot find input-group with name \`${inputName}\``);
    }

    clearForm() {
        this.$('form').find('input[type=text]').val('');
    }

    focusFirstInput() {
        const $input = this.$('form').find('input').first();
        if ($input.length) $input.focus();
    }

    focusInput(inputName) {
        const $input = this.$('form').find(`[name=${inputName}]`);
        if ($input.length) $input.focus();
    }

    disableInputs(inputNames) {
        inputNames.forEach((inputName) => {
            const $input = this.$('form').find(`[name=${inputName}]`);
            const $label = $input.siblings('label');
            if ($input.length) $input.prop('disabled', true);
            if ($label.length) $label.removeClass('required');
        });
    }

    isNotHidden(inputName) {
        return !_.contains(this.hiddenInputs, inputName);
    }

    isNotDisabled(inputName) {
        return !_.contains(this.disabledInputs, inputName);
    }

    isAvailable(inputName) {
        return this.isNotHidden(inputName) && this.isNotDisabled(inputName);
    }

    getGroup(name) {
        if (name && this.groups) return this.groups.findByCustom(name);
    }

    getInput(name) {
        if (name && this.inputs) return this.inputs.findByCustom(name);
    }

}
