import { connect } from 'react-redux';
import * as Event from 'app/react/entities/events/index';
import SchedulerContentHeader from './ContentHeader';

const mapStateToProps = (state) => {
    const { manageTimetable } = Event.stateHelpers.getUserPermissions(state);
    const eventId = Event.stateHelpers.getCurrentId(state);
    return {
        eventId,
        userCanManageTimetable: manageTimetable,
    };
};

export default connect(
    mapStateToProps
)(SchedulerContentHeader);
