import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ALL_REQUEST = 'ALL_STAGES_REQUEST';
export const ALL_SUCCESS = 'ALL_STAGES_SUCCESS';
export const ALL_FAILURE = 'ALL_STAGES_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];
export const ALL_UPDATE_SEQUENCE_REQUEST = 'ALL_UPDATE_STAGES_SEQUENCE_REQUEST';
export const ALL_UPDATE_SEQUENCE_SUCCESS = 'ALL_UPDATE_STAGES_SEQUENCE_SUCCESS';
export const ALL_UPDATE_SEQUENCE_FAILURE = 'ALL_UPDATE_STAGES_SEQUENCE_FAILURE';
export const ALL_UPDATE_SEQUENCE_TYPES = [
    ALL_UPDATE_SEQUENCE_REQUEST,
    ALL_UPDATE_SEQUENCE_SUCCESS,
    ALL_UPDATE_SEQUENCE_FAILURE
]

export const fetchAll = (accountId, eventId) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId),
        schema: Schemas.entities
    },
    accountId,
    eventId
});

export const updateStagesSequence = (
    eventId,
    dayId,
    stageIds
) => ({
    [CALL_API]: {
        types: ALL_UPDATE_SEQUENCE_TYPES,
        method: METHODS.PUT,
        body: stageIds,
        endpoint: Endpoints.updateStagesSequence(eventId, dayId),
        schema: Schemas.entities
    },
    eventId,
    dayId,
    stageIds
})