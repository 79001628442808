import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import AccrediteeProfileTable from '../tables/AccrediteeProfileTable.view';
import AccrediteeProfileModalAdd from '../modals/AccrediteeProfileModalAdd.view';
import * as CustomAccreditee from 'app/backbone/models/event/accreditation/accreditees/CustomAccreditee';

export default class AccrediteeProfilesCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(CustomAccreditee.Model).isRequired,
            profiles: OptionTypes.instanceOf(Profile.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            title
        };
    }

    optionDefaults() {
        return {
            title: 'Profiles'
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.addModal = this.initAddModel();

        // Listeners
        this.listenTo(this.model.getRelation('profiles'), 'update reset', this.onUpdateOrReset);
        this.listenTo(this.model, 'validated', this.onValidated);

        super.initialize(options);
    }

    initContent() {
        const { profiles, periods, model } = this;
        const collection = model.getRelation('profiles');
        return new AccrediteeProfileTable({ collection, profiles, periods });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add profile',
            icon: 'bs-icon-plus',
            action: this.addProfile.bind(this),
            isHidden: () => this.addModal.profiles.isEmpty()
        }];
    }

    initAddModel() {
        const profiles = this.getModalProfiles();
        const addModal = new AccrediteeProfileModalAdd({ profiles });
        this.listenTo(addModal, 'add', this.onAdded);
        return addModal;
    }

    onAdded(profile) {
        this.model.profiles.add(profile);
    }

    addProfile() {
        if (!this.addModal.profiles.isEmpty()) this.addModal.open();
    }

    onUpdateOrReset() {
        this.addModal.profiles = this.getModalProfiles();
        this.header.toggleHideAction('add', this.addModal.profiles.isEmpty());
        if (this.hasError()) this.model.validate();
    }

    onValidated(isModelValid, model, errors) {
        if (isModelValid) this.removeError();
        else if (errors.profiles) this.addError(errors.profiles);
    }

    getModalProfiles() {
        const { profiles, model } = this;
        return profiles.rejectIds(model.getRelation('profiles').pluck('id'));
    }

}
