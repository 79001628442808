import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ALL_REQUEST = 'ALL_BOOKING_STATUSES_REQUEST';
export const ALL_SUCCESS = 'ALL_BOOKING_STATUSES_SUCCESS';
export const ALL_FAILURE = 'ALL_BOOKING_STATUSES_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const fetchAll = (accountId) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(accountId),
        schema: Schemas.entities
    },
    accountId
});
