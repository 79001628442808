import classNames from 'classnames';
import React, { Fragment } from 'react';

const NameField = ({
  id,
  value,
  type = 'text',
  autofocus,
  error,
  onChange,
  onBlur,
  placeholder,
  required,
}) => {

  return (
    <Fragment>
      <label htmlFor={id} >
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          autoFocus={autofocus}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={(e) => {
            onBlur && onBlur(e);
          }}
          required={required}
          className={classNames(
            'form-field__name',
          )}
        />
      </label>
      {error && <div className={'form-field__error'}>{error}</div>}
    </Fragment>
  );
};

export default NameField;
