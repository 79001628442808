import React from 'react';
import PropTypes from 'prop-types';
import { Card, PaginationBar } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import PickupModals from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/ModalsWrapperContainer';
import LogisticsContent from '../../../components/layout/ContentContainer';
import PickupsContentHeader from '../../components/layout/ContentHeaderContainer';
import { ClearActiveFiltersCardAction, Tooltip, TableLoadingPlaceholder } from 'app/react/components/_index';
import PickupsTable from '../../components/table/Table';
import PickupsTableTopBar from '../../components/table/TopBar';

const { Header } = Card;

const CARD_TITLE = 'Overview';
const CREATE_PICKUP_TOOLTIP = 'Create new pickup';

const PickupsOverview = ({
    store,
    timeFormat,
    dateFormat,
    dateFormatShort,

    isFetching = false,
    days = [],
    drivers = [],
    pickups = [],
    pickupsPagination = {},

    onChangePageSize = () => {},
    onChangeFilter = () => {},
    onSearch = () => {},
    onClearAllFilters = () => {},
    onClearSearch = () => {},
    onSort = () => {},
    onChangePage = () => {},
    onTogglePickupIsArrived = () => {},
    onGoToBooking = () => {},
    onOpenCreateModal = () => {},
    onOpenUpdateModal = () => {},
    onOpenDeleteModal = () => {},
    onCreatePickupSuccess = () => {},
}) => {
    const {
        isFetching: isFetchingPickups = false,
        requestedSortKey,
        requestedSortOrder,
        requestedPageSize = 100,
        requestedFilters = {},
        requestedSearch = '',
        requestedPage,
        totalPages,
        totalCount
    } = pickupsPagination;
    const isFilterActive = Object.keys(requestedFilters).length > 0;
    const isSearchActive = requestedSearch.length > 0;
    return (
        <ContentWrapper store={store}>
            <PickupsContentHeader
                activeTab="overview"
            />
            <LogisticsContent isLoading={isFetching}>
                <Card>
                    <Header>
                        <Header.Title>{CARD_TITLE}</Header.Title>
                        {(isFilterActive || isSearchActive) &&
                            <ClearActiveFiltersCardAction onClick={onClearAllFilters} />
                        }
                        <Tooltip content={CREATE_PICKUP_TOOLTIP} position="top right">
                            <Header.Action onClick={onOpenCreateModal}>
                                <i className="bs-icon-plus" />
                            </Header.Action>
                        </Tooltip>
                    </Header>
                    <Card.Content>
                        <PickupsTableTopBar
                            dateFormat={dateFormat}
                            days={days}
                            drivers={drivers}
                            selectedDeparture={requestedFilters.after}
                            selectedArrival={requestedFilters.before}
                            selectedDriverId={requestedFilters.driver}
                            selectedArrivedFilter={requestedFilters.arrived}
                            selectedPageSize={requestedPageSize}
                            searchValue={requestedSearch}
                            onChangePageSize={onChangePageSize}
                            onChangeFilter={onChangeFilter}
                            onSearch={onSearch}
                            onClearSearch={onClearSearch}
                        />
                        {isFetchingPickups
                            ? <TableLoadingPlaceholder />
                            : <PickupsTable
                                pickups={pickups}
                                sortKey={requestedSortKey}
                                sortOrder={requestedSortOrder}
                                timeFormat={timeFormat}
                                dateFormat={dateFormat}
                                dateFormatShort={dateFormatShort}
                                onSort={onSort}
                                onToggleIsArrived={onTogglePickupIsArrived}
                                onGoToBooking={onGoToBooking}
                                onEditPickup={onOpenUpdateModal}
                                onDeletePickup={onOpenDeleteModal}
                            />
                        }
                        <PaginationBar
                            page={requestedPage}
                            pageSize={requestedPageSize}
                            totalPages={totalPages}
                            totalCount={totalCount}
                            onChangePage={onChangePage}
                            onChangePageSize={onChangePageSize}
                        />
                    </Card.Content>
                </Card>
            </LogisticsContent>
            <PickupModals
                store={store}
                onCreateSuccess={onCreatePickupSuccess}
            />
        </ContentWrapper>
    )
};

PickupsOverview.propTypes = {
    store: PropTypes.object,
    userCanMangeAccommodations: PropTypes.bool,
    timeFormat: PropTypes.string,
    dateFormat: PropTypes.string,
    isFetching: PropTypes.bool,
    reservations: PropTypes.array,
    days: PropTypes.array,
    accommodations: PropTypes.array,
    roomTypes: PropTypes.array,
    reservationsPagination: PropTypes.object,
    onChangePageSize: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeSearchValue: PropTypes.func,
    onSearch: PropTypes.func,
    onClearSearch: PropTypes.func,
    onSort: PropTypes.func,
    onChangePage: PropTypes.func,
    onToggleReservationCheckIn: PropTypes.func,
    onEditReservation: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
};

export default PickupsOverview;
