import { combineReducers } from 'redux';
// Old
import * as Performance from 'app/react/entities/events/performances/index';
import * as Integration from 'app/react/entities/accounts/integrations/index';
import * as IntegrationEvents from 'app/react/entities/accounts/integrations/events/index';
import * as TicketSettings from 'app/react/entities/events/accreditation/tickets/settings/index';
import * as PWPage from 'app/react/entities/events/production_website/pages/index';
// New
import * as Booking from 'app/react/entities/events/bookings/reducers';
import * as Accommodation from 'app/react/entities/events/accommodations/reducers';
import * as Reservation from 'app/react/entities/events/reservations/reducers';
import * as Item from 'app/react/entities/events/artists/items/reducers';
import * as Pickup from 'app/react/entities/events/bookings/pickups/reducers';
import * as Vehicle from 'app/react/entities/events/vehicles/reducers';
import * as Guest from 'app/react/entities/events/guest_list/reducers';
import * as Attendee from 'app/react/entities/events/attendees/reducers';

export const paginationReducers = {
    [Performance.PAGINATION_REDUCER_KEY]: Performance.reducers.pagination,
    [Integration.AVAILABLE_PAGINATION_REDUCER_KEY]: Integration.reducers.availablePagination,
    [Integration.ACTIVATED_PAGINATION_REDUCER_KEY]: Integration.reducers.activatedPagination,
    [IntegrationEvents.PAGINATION_REDUCER_KEY]: IntegrationEvents.reducers.pagination,
    [TicketSettings.PAGINATION_REDUCER_KEY]: TicketSettings.reducers.pagination,
    [PWPage.PAGINATION_REDUCER_KEY]: PWPage.reducers.pagination,
    ...Booking.paginationReducers,
    ...Accommodation.paginationReducers,
    ...Reservation.paginationReducers,
    ...Item.paginationReducers,
    ...Pickup.paginationReducers,
    ...Vehicle.paginationReducers,
    ...Guest.paginationReducers,
    ...Attendee.paginationReducers
};

export default combineReducers(paginationReducers);
