import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import EventUsersOverview from 'app/backbone/views/event/users/views/EventUsersOverview.view'
import * as Routes from './routes'

export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.users(), null, this.users.bind(this));
    }

    users() {
        this.app.renderContentView(new EventUsersOverview());
    }

}
