import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { countries } from 'country-data';
import { SearchSelect } from 'ui-kit';
import Flag from '../util/Flag';

export const PLACEHOLDER = 'Search country...';
export const NO_RESULTS_PLACEHOLDER = 'No county found.';

const toOption = ({
    name,
    alpha2,
}) => ({
    value: alpha2,
    label: name,
});

const whereIsOfficiallyAssigned = ({ status }) => status === 'assigned';

export const TextContainer = styled.div`
    display: inline-block;
    margin-left: 8px !important;
    max-width: ${props => props.maxWidth};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: text-top;
    height: 18px;
    line-height: 18px;
`;

const CountryOption = ({
    option = {},
    maxWidth,
}) => (
    <Fragment>
        <Flag code={option.value} />
        <TextContainer maxWidth={maxWidth}>{option.label}</TextContainer>
    </Fragment>
);

const SelectCountry = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    value,
    onChange = () => {},
}) => {
    return (
        <SearchSelect
            placeholder={placeholder}
            noResultsPlaceholder={noResultsPlaceholder}
            value={value}
            isClearable
            isDisabled={disabled}
            options={countries.all.filter(whereIsOfficiallyAssigned).map(toOption)}
            onChange={onChange}
            renderOption={(option) => (
                <CountryOption option={option} maxWidth="160px" />
            )}
        />
    );
};

SelectCountry.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    onChange: PropTypes.func,
};

export default SelectCountry;
