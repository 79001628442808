import { getDataFromGraphQLAPI } from '../data_sources/graphql';
import { omitAllUndefinedKeys } from 'app/react/helpers/_index';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_GRAPHQL_API = Symbol('Call GraphQL API');
export const QUERY_TYPE = {
    QUERY: 'QUERY',
    MUTATION: 'MUTATION',
};

export default store => next => action => {
    const callGraphQLAPI = action[CALL_GRAPHQL_API];
    if (typeof callGraphQLAPI === 'undefined') {
        return next(action);
    }

    let { query } = callGraphQLAPI;
    const {
        types,
        variables,
        schemaMapping,
        queryType = QUERY_TYPE.QUERY,
    } = callGraphQLAPI;

    if (typeof query === 'function') {
        query = query(store.getState());
    }
    // if (typeof query !== 'string') {
    //     throw new Error('Specify a string endpoint URL.');
    // }
    // if (!schema) {
    //     throw new Error('Specify one of the exported Schemas.');
    // }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    const actionWith = data => {
        const finalAction = {
            ...action,
            ...data,
            query,
            queryType,
            schemaMapping,
        };
        delete finalAction[CALL_GRAPHQL_API];
        return omitAllUndefinedKeys(finalAction);
    };

    const [requestType, successType, failureType] = types;

    next(actionWith({
        type: requestType,
        requestedPage: variables.page,
        requestedPageSize: variables.pageSize,
    }));

    return getDataFromGraphQLAPI(query, queryType, variables, schemaMapping)
        .then(response => next(actionWith({
            type: successType,
            isSuccess: true,
            isFailure: false,
            response
        })))
        .catch(error => next(actionWith({
            type: failureType,
            isFailure: true,
            isSuccess: false,
            error,
        })))
};
