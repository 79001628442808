import React, { Component } from 'react';

const NoRecordsPlaceholder = ({
    children,
    style = {}
}) => {
    return (
        <div className="no-records-placeholder" style={style}>
            {children}
        </div>
    );
};

export default NoRecordsPlaceholder;
