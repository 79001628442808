import React, { useState } from "react";
import PropTypes from "prop-types";
import Colorpicker from "../../atoms/colorpicker/Colorpicker";
import Footer from "../../footer/Footer";
import FileDAD from "../../atoms/fileDAD/FileDAD";
import FeedbackMessage from "../../atoms/feedback/Feedback";
import { useHTTP } from "../../../hooks/UseHTTP";
export const ProductionWebsiteFormSchema = () => yup.object({});

export const ProductionWebsiteForm = ({
  setActiveStep,
  activeSubStep,
  eventState,
  updateProductionWebsiteSettings,
  error,
  setError,
}) => {
  const [activePicker, setActivePicker] = useState("");
  const [settings, setSettings] = useState(
    eventState.event.productionWebsite || {
      logo: undefined,
      background: undefined,
      favicon: undefined,
      primaryColor: "#405EB0",
      secondaryColor: "#0CD4AC",
      topBarColor: "#242747",
    }
  );

  const setImage = (iconType, image) => {
    const { doHttpCall } = useHTTP();
    if (image) {
      doHttpCall("/api/upload", "POST", {
        file: image.file,
        type: iconType === 'favicon' ? 'favicon' : "image",
      })
        .then((res) => {
          setSettings((prev) => {
            return {
              ...prev,
              [iconType]: { ...image, uuid: res.data.uuid },
            };
          });
        })
        .catch((error) => {
          if (error.statusText) {
            setError(
              error.statusText === "Unprocessable Content"
                ? "Image format needs to be JPG or JPEG"
                : error.statusText === "Request Entity Too Large"
                ? "Please upload a smaller image"
                : "Something went wrong while trying to upload the image, please try again with a different image"
            );
          } else {
            console.log(error);
            setError(
              "Something went wrong while trying to upload the image, please try again with a different image"
            );
          }
        });
    } else {
      setSettings((prev) => {
        return {
          ...prev,
          [iconType]: undefined,
        };
      });
    }
  };

  const saveSettings = (nextStep) => {
    const formattedSettings = Object.entries(settings).reduce(
      (prev, setting) => {
        const [key, value] = setting;
        if (!value) return prev;
        if (typeof value === "string") {
          prev[key] = value;
        } else {
          prev[key] = value.uuid;
        }
        return prev;
      },
      {}
    );
    updateProductionWebsiteSettings(eventState.event.id, formattedSettings)
      .then((res) => {
        if (res && res.data) {
          setActiveStep(nextStep, { productionWebsite: settings });
        } else {
          console.error("Save settings:: ", res.error);
          setError(
            "Something went wrong. This is probably because of the images, please try adding only colors ftm"
          );
        }
      })
      .catch((error) => {
        setError(
          "Something went wrong. This is probably because of the images, please try adding only colors ftm"
        );
        console.error("Save settings:: ", error);
      });
  };

  return (
    <div className="form_content">
      {error && (
        <FeedbackMessage
          text={error}
          variant="error"
          closeFeedback={() => setError("")}
        />
      )}
      <h2>Design production website</h2>
      <div className="production-website__colorpickers">
        <Colorpicker
          label="Primary Color"
          color={settings.primaryColor}
          type="primaryColor"
          setShowColorpicker={(picker) =>
            setActivePicker((current) => (current === picker ? "" : picker))
          }
          showColorPicker={activePicker === "primaryColor"}
          onChange={(color) =>
            setSettings((old) => ({ ...old, primaryColor: color }))
          }
        />
        <Colorpicker
          label="Secondary Color"
          color={settings.secondaryColor}
          type="secondaryColor"
          setShowColorpicker={(picker) =>
            setActivePicker((current) => (current === picker ? "" : picker))
          }
          showColorPicker={activePicker === "secondaryColor"}
          onChange={(color) =>
            setSettings((old) => ({ ...old, secondaryColor: color }))
          }
        />
        <Colorpicker
          label="Top bar Color"
          color={settings.topBarColor}
          type="topbarColor"
          setShowColorpicker={(picker) =>
            setActivePicker((current) => (current === picker ? "" : picker))
          }
          showColorPicker={activePicker === "topbarColor"}
          onChange={(color) =>
            setSettings((old) => ({ ...old, topBarColor: color }))
          }
        />
      </div>
      <div>
        <FileDAD
          setImage={setImage}
          label="Logo"
          iconType="logo"
          image={settings["logo"]}
        />
        <FileDAD
          setImage={setImage}
          label="Background"
          iconType="background"
          image={settings["background"]}
        />
        <FileDAD
          setImage={setImage}
          label="Favicon"
          iconType="favicon"
          image={settings["favicon"]}
        />
      </div>
      <Footer
        setActiveStep={(step) => saveSettings(step)}
        activeSubStep={activeSubStep}
        eventState={eventState}
        isNextDisabled={false}
      />
    </div>
  );
};

ProductionWebsiteForm.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
  eventState: PropTypes.object,
};

export default ProductionWebsiteForm;
