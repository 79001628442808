import { transformResponseBody } from '../data_sources/util';

export const BROADCAST_RECEIVED = Symbol('Broadcast Received');

export const BROADCAST_TYPES = {
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    DELETED: 'DELETED',
};

export default store => next => action => {
    const subscriptionAction = action[BROADCAST_RECEIVED];
    if (typeof subscriptionAction === 'undefined') {
        return next(action);
    }
    const { type, eventType, schema, body } = subscriptionAction;
    const response = transformResponseBody(body, schema);

    const finalAction = {
        ...action,
        type,
        eventType,
        response
    };
    delete finalAction[BROADCAST_RECEIVED];

    return next(finalAction);
};
