var _ = require('underscore'),
    Backbone = require('backbone');

var ConfirmModal = require('./ConfirmModal.view');

var RedirectConfirmModal = ConfirmModal.extend({

    options: [
        {'title': 'Confirm'},
        {'body': 'Are you sure?'},
        {'confirmText': 'Confirm'},
        {'cancelText': 'Cancel'},
        {'confirmClass': 'primary'},
        {'cancelClass': 'secondary'},
        {'autoClose': true},
        {'isSyncing': false},
        {'syncingText': 'Please wait...'},
        'confirmHref',
        'cancelHref'
    ],

    viewModel: function() {
        var viewModel = this._super();
        viewModel.view.confirmHref = this.confirmHref;
        viewModel.view.cancelHref = this.cancelHref;
        return viewModel;
    },

    confirm: function() {
        if(this.confirmHref) navigate(this.confirmHref, { pageReload: true });
        if(this.autoClose) this.hide();
    },

    cancel: function() {
        if(this.cancelHref) navigate(this.cancelHref, { pageReload: true });
        if(this.autoClose) this.hide();
    },

    open: function() {
        this.render();
        this.show();
    }
});

module.exports = RedirectConfirmModal;
