import React from 'react';
import PropTypes from 'prop-types';
import {
    InputGroup,
    PostfixedInput,
    Grid,
} from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';
import SearchSelectArtist from '../../../views/SearchSelectArtistContainer';
import SelectBookingStatus from '../../../performances/views/selects/SelectBookingStatus';
import SelectBookingType from '../../../performances/views/selects/SelectBookingType';
import SelectArtistType from '../../../performances/views/selects/SelectArtistType';

const { Cell } = Grid;

const SELECT_ARTIST_LABEL = 'Select artist';
const CREATE_ARTIST_LABEL = 'New artist name';
const CREATE_NEW_ARTIST_TOOLTIP = 'Create new artist';
const SELECT_EXISTING_ARTIST_BUTTON_TEXT = 'Select existing artist';
const SELECT_ARTIST_TYPE_LABEL = 'Artist type';
const SELECT_BOOKING_TYPE_LABEL = 'Booking type';
const SELECT_BOOKING_STATUS_LABEL = 'Booking status';

const CreatePerformanceModal = ({
    isCreatingNewArtist = false,

    values = [],
    errors = {},

    isArtistTypesEnabled = true,

    artistTypes = [],
    bookingTypes = [],
    bookingStatuses = [],

    onChangeValue = () => {},
    onSelectArtist = () => {},
    onToggleCreateNewArtist = () => {}
}) => {
    const artistErrorMessages = errors.artist || errors['artist.name'];
    const hasArtistError = !!artistErrorMessages;
    return (
        <div>
            {!isCreatingNewArtist 
                ? <InputGroup messages={artistErrorMessages}>
                    <label className="required">{SELECT_ARTIST_LABEL}</label>
                    <PostfixedInput.Container
                        postfix={
                            <Tooltip content={CREATE_NEW_ARTIST_TOOLTIP}>
                                <button
                                    onClick={() => onToggleCreateNewArtist(true)}
                                    className="button postfix button-icon"
                                >
                                    New
                                </button>
                            </Tooltip>
                        }
                    >
                        <SearchSelectArtist
                            value={values.artist}
                            onChange={value => onSelectArtist(value)}
                        />
                    </PostfixedInput.Container>
                </InputGroup> 
                : <InputGroup messages={artistErrorMessages}>
                    <label className="required">
                        {CREATE_ARTIST_LABEL}
                    </label>
                    <Grid hasGutters={false}>
                        <Cell>
                            <input
                                type="text"
                                value={values.artist || ''}
                                onChange={e => onChangeValue('artist', e.target.value)}
                            />
                        </Cell>
                        <Cell
                            isAutoSized
                            isContentCentered
                            width="25%"
                        >
                            <div
                                style={{
                                    margin: `0 0 ${hasArtistError ? '0.5rem' : '1rem'} 0`,
                                    width: '100%',
                                    padding: '0.5rem',
                                    border: '1px solid transparent',
                                    textAlign: 'center'
                                }}
                            >
                                <a
                                    onClick={() => onToggleCreateNewArtist(false)}
                                >
                                    {SELECT_EXISTING_ARTIST_BUTTON_TEXT}
                                </a>
                            </div>
                        </Cell>
                    </Grid>
                </InputGroup>
            }
            {isArtistTypesEnabled &&
                <InputGroup messages={errors.artistType}>
                    <label className="required">
                        {SELECT_ARTIST_TYPE_LABEL}
                    </label>
                    <SelectArtistType
                        artistTypes={artistTypes}
                        value={values.artistType}
                        onChange={value => onChangeValue('artistType', value)}
                    />
                </InputGroup>
            }
            <Grid>
                <Cell>
                    <InputGroup messages={errors.type}>
                        <label className="required">
                            {SELECT_BOOKING_TYPE_LABEL}
                        </label>
                        <SelectBookingType
                            bookingTypes={bookingTypes}
                            value={values.type}
                            onChange={value => onChangeValue('type', value)}
                        />
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors.status}>
                        <label className="required">
                            {SELECT_BOOKING_STATUS_LABEL}
                        </label>
                        <SelectBookingStatus
                            bookingStatuses={bookingStatuses}
                            value={values.status}
                            onChange={value => onChangeValue('status', value)}
                        />
                    </InputGroup>
                </Cell>
            </Grid>
        </div>
    );
};

CreatePerformanceModal.propTypes = {
    isCreatingNewArtist: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    isArtistTypesEnabled: PropTypes.bool,

    artists: PropTypes.array,
    artistTypes: PropTypes.array,
    bookingTypes: PropTypes.array,
    bookingStatuses: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectArtist: PropTypes.func,
    onToggleCreateNewArtist: PropTypes.func
};

export default CreatePerformanceModal;
