import React from 'react';
import PropTypes from 'prop-types';
import { TableHeaderCell, TableSortCell, AddUserIcon } from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';

const TOOLTIPS = {
    TRANSPORTS: 'Transports',
    COUNTRIES: 'Countries',
    PARKING_PASSES: 'Parking passes',
    ACCOMMODATIONS: 'Accommodations',
    ITINERARY_IS_SEND: 'Itinerary sent?',
    LOGIN_IS_SEND: 'Production website sent?',
    TASK_PROGRESS: 'Task progress',
    PW_PROGRESS: 'Production website progress',
};

const BookingTableHeaderRow = ({
    canManageTransports = false,
    canManageParkingPasses = false,
    canManageReservations = false,
    canManageTask = false,
    canSendLogins = false,
    canSendEmails = false,

    sortKey,
    sortOrder,

    onSort = () => {},
}) => (
    <tr>
        <TableHeaderCell style={{ width: '70px' }} />
        <TableSortCell
            sortKey="artist.name"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Name
        </TableSortCell>
        <TableSortCell
            style={{ width: '30px' }}
            sortKey="artist.country"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
            isCentered
        >
            <Tooltip content={TOOLTIPS.COUNTRIES}>
                <i className="bs-icon-globe" />
            </Tooltip>
        </TableSortCell>
        <TableSortCell
            sortKey="performance.day.start"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Day
        </TableSortCell>
        <TableSortCell
            style={{ width: '90px' }}
            sortKey="performance.start"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Time
        </TableSortCell>
        <TableSortCell
            sortKey="performance.stage.name"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Stage
        </TableSortCell>
        {canManageTransports && (
            <TableSortCell
                sortKey="transports"
                currentSortKey={sortKey}
                currentSortOrder={sortOrder}
                onSort={onSort}
            >
                Transports
            </TableSortCell>
        )}
        {canManageParkingPasses && (
            <TableSortCell
                isCentered
                style={{ width: '70px' }}
                sortKey="parking_passes"
                currentSortKey={sortKey}
                currentSortOrder={sortOrder}
                onSort={onSort}
            >
                <Tooltip content={TOOLTIPS.PARKING_PASSES}>
                    <i className="bs-icon-parking" />
                </Tooltip>
            </TableSortCell>
        )}
        {canManageReservations && (
            <TableSortCell
                isCentered
                style={{ width: '70px' }}
                sortKey="reservations"
                currentSortKey={sortKey}
                currentSortOrder={sortOrder}
                onSort={onSort}
            >
                <Tooltip content={TOOLTIPS.ACCOMMODATIONS}>
                    <i className="bs-icon-accommodation" />
                </Tooltip>
            </TableSortCell>
        )}
        {canManageTask && (
            <TableSortCell
                style={{ width: '50px' }}
                isCentered
                sortKey="tasks.progress"
                currentSortKey={sortKey}
                currentSortOrder={sortOrder}
                onSort={onSort}
            >
                <Tooltip content={TOOLTIPS.TASK_PROGRESS}>
                    <i className="bs-icon-check-round" />
                </Tooltip>
            </TableSortCell>
        )}
        {canSendEmails && (
            <TableHeaderCell
                isCentered
                style={{ width: '50px' }}
            >
                <Tooltip content={TOOLTIPS.ITINERARY_IS_SEND}>
                    <i className="bs-icon-envelope" />
                </Tooltip>
            </TableHeaderCell>
        )}
        {canSendLogins && (
            <TableHeaderCell
                isCentered
                style={{ width: '50px' }}
            >
                <Tooltip content={TOOLTIPS.LOGIN_IS_SEND}>
                    <AddUserIcon />
                </Tooltip>
            </TableHeaderCell>
        )}
        <TableHeaderCell
            isCentered
            style={{ width: '50px' }}
        >
            <Tooltip content={TOOLTIPS.PW_PROGRESS}>
                <i className="bs-icon-checklist" />
            </Tooltip>
        </TableHeaderCell>
        <TableSortCell
            isCentered
            sortKey="advancingUser"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
            style={{ width: '50px' }}
        >
            <Tooltip content="Advancing user">
                <i className="bs-icon-user" />
            </Tooltip>
        </TableSortCell>
        <TableSortCell
            isCentered
            style={{ width: '50px' }}
        >
            <Tooltip content="Main advancing contacts">
                <i className="bs-icon-team" />
            </Tooltip>
        </TableSortCell>
        <TableHeaderCell
            isCentered
            style={{ width: '50px' }}
        />
        {(canSendEmails || canSendLogins) && (
            <TableHeaderCell
                isCentered
                style={{ width: '50px' }}
            />
        )}
    </tr>
);

BookingTableHeaderRow.propTypes = {
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func
};

export default BookingTableHeaderRow;
