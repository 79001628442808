import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import CreateContactModal from 'app/backbone/views/account/crm/contacts/modals/ContactModalCreate.view';
import * as Contact from 'app/backbone/models/account/Contact';
import CustomAccrediteeForm from '../forms/CustomAccrediteeForm.view';
import * as CustomAccreditee from 'app/backbone/models/event/accreditation/accreditees/CustomAccreditee';

export default class AccrediteeContactCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(CustomAccreditee.Model).isRequired,
            contacts: OptionTypes.instanceOf(Contact.Collection).isRequired,
            title
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // Subviews
        this.createModal = this.initCreateModel();
    }

    initContent() {
        this.form = this.initFrom(this.model, this.contacts);
        return this.form;
    }

    initHeaderActions() {
        return [{
            name: 'create',
            tooltip: 'Create contact',
            icon: 'bs-icon-plus',
            action: this.create.bind(this)
        }];
    }

    initCreateModel(canCreateAnother = false) {
        let createModal = new CreateContactModal({ canCreateAnother });
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    initFrom(model, contacts) {
       return new CustomAccrediteeForm({ model, contacts });
    }

    onCreated(contact) {
        this.contacts.add(contact);
    }

    create() {
        this.createModal.open();
    }

}
