import BaseView from 'app/backbone/components/Base.view'

export default class RidersOverviewView extends BaseView {

    ui() {
        return {
            filter: '.riderFilter',
        };
    }

    events() {
        return {
            'change filter': 'applyFilter',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    applyFilter(e) {
        window.location = e.target.value;
    }
}
