import { html } from 'common-tags';

export const syncingPlaceholder = (syncingText, className, height) => html`
    <div class="syncing-placeholder vertical-placeholder ${className}" style="height: ${height}px">
        <div class="spinner-container">
            <div class="spinner"></div>
            <div class="margin-top">${syncingText}</div>
        </div>
    </div>
`;

export const createButton = (
    canCreateAnother = true,
    text = 'Create',
    anotherText = 'Create Another',
) => html`
    <div class="grid">
        <div class="grid-cell"></div>
        <div class="grid-cell grid-cell--autoSize grid-cell--justifyEndContent">
            ${ canCreateAnother && `
                <a class="button secondary expand no-margin-bottom create-another">
                    ${anotherText}
                </a>`
            }
            <a class="button expand no-margin-bottom shadow create">
               ${text}
            </a>
        </div>
    </div>
`;

export const updateButton = (text = 'Update') => html`
    <div class="grid">
        <div class="grid-cell"></div>
        <div class="grid-cell grid-cell--autoSize grid-cell--justifyEndContent">
            <a class="button expand no-margin-bottom update">
                ${text}
            </a>
        </div>
    </div>
`;
