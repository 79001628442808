import dotProp from 'dot-prop-immutable';
import { createReducer } from 'redux-create-reducer';
import { METHODS } from 'app/react/state/middleware/api';
import {
    createCollectionReducerWithKey
} from 'app/react/state/reducers/higher_order/_index'
import {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} from './schemas'
import {
    ALL_TYPES,
    ALL_SUCCESS
} from './actions'
import {
    generateCollectionKey
} from './helpers'

const entityReducer = (state = {}) => state;

const collectionReducer = createCollectionReducerWithKey({
    mapActionToKey: ({ accountId, eventId, artistTypeId, sectionId }) =>
        generateCollectionKey(accountId, eventId, artistTypeId, sectionId),
    types: ALL_TYPES,
});

export const featureReducer = createReducer({}, {
    [ALL_SUCCESS]: (state, action) => {
        if (action.method === METHODS.PUT) {
            return dotProp.set(
                state,
                `entities.pwArtistSettingsSections.${action.id}.formFields`,
                action.response.result
            );
        }
        return state;
    }
});

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};

export const collectionReducers = {
    [COLLECTION_REDUCER_KEY]: collectionReducer
};
