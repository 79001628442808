import BaseView from 'app/backbone/components/Base.view';
import TextEditor from 'app/backbone/components/inputs/TextEditor.view';

export default class ProductionWebsiteForm extends BaseView {

    ui() {
        return {
            textarea: '.wysiwyg'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.textEditor = new TextEditor();
    }

    render() {
        this.resolveHandles();
        this.textEditor.setElement(this.ui.textarea).render();
    }

}
