import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Title = ({
    children,
    style,
    width = '100%',
    toggle = () => {}
}) => (
    <a
        className={classNames('accordion-title')}
        style={{ ...style, width }}
        onClick={toggle}
    >
        {children}
    </a>
);

Title.propTypes = {
    style: PropTypes.object,
    width: PropTypes.string,
    toggle: PropTypes.func,
    children: PropTypes.node
};

const Actions = ({
    children,
    style,
    width = '100%'
}) => (
    <div
        className={classNames('accordion-actions')}
        style={{ ...style, width }}
    >
        {children}
    </div>
);

Actions.propTypes = {
    style: PropTypes.object,
    width: PropTypes.string,
    children: PropTypes.node
};

const Content = ({
    children,
    style,
    height
}) => (
    <div
        className={classNames('content')}
        style={{ ...style, height }}
    >
        {children}
    </div>
);

Content.propTypes = {
    style: PropTypes.object,
    height: PropTypes.string,
    children: PropTypes.node
};

class StateFullNavigation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpened: props.isOpened || false
        };
    }

    componentWillReceiveProps(newProps) {
        const { isOpened, isDisabled } = newProps;
        if (this.state.isOpened !== isOpened) {
            this.setState({
                isOpened
            });
        }
        if (this.props.isDisabled !== isDisabled && isDisabled) {
            this.setState({
                isOpened: false
            });
        }
    }

    toggle() {
        const { accordionNumber, onAccordionChange } = this.props;
        const { isOpened } = this.state;
        const accordionNumberOrNull = isOpened ? null : accordionNumber;
        onAccordionChange(accordionNumberOrNull)

        if (!this.props.isDisabled) {
            this.setState({
                isOpened: !isOpened
            });
        }
    }

    render() {
        const { isDisabled, style, children } = this.props;
        const { isOpened } = this.state;
        return (
            <li
                className={classNames('accordion-navigation', { active: isOpened, disabled: isDisabled })}
                style={{ ...style }}
            >
                { children.map((child, index) => (
                    child.type === Title ?
                        React.cloneElement(child, { toggle: this.toggle.bind(this), key: index }) :
                        child
                ))}
            </li>
        );
    }

}

StateFullNavigation.propTypes = {
    style: PropTypes.object,
    isOpened: PropTypes.bool,
    isDisabled: PropTypes.bool,
    children: PropTypes.node,
    accordionNumber: PropTypes.number,
    onAccordionChange: PropTypes.func
};

const Navigation = ({
    children,
    style,
    isOpened = false
}) => (
    <li
        className={classNames('accordion-navigation', { active: isOpened })}
        style={{ ...style }}
    >
        {children}
    </li>
);

Navigation.propTypes = {
    style: PropTypes.object,
    isOpened: PropTypes.bool,
    children: PropTypes.node
};

const Accordion = ({
    children
}) => (
    <ul className={classNames('accordion')}>
        {children}
    </ul>
);

Accordion.propTypes = {
    children: PropTypes.node
};

Accordion.Navigation = StateFullNavigation;
Accordion.Title = Title;
Accordion.Actions = Actions;
Accordion.Content = Content;

export default Accordion;
