import React from 'react';
import PropTypes from 'prop-types';
import {
    InputGroup,
    DateInput,
    TimeInput,
    PostfixedInput,
} from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';
import SelectTimeSlotType from '../../../../selects/SelectTimeSlotType';

const SAVE_UPDATE_TOOLTIP = 'Save changes ';
const CANCEL_UPDATE_TOOLTIP = 'Cancel changes';
const CUSTOM_PLACEHOLDER = 'Custom name';
const SAVE_NEW_TOOLTIP = 'Save new time slot';
const CANCEL_NEW_TOOLTIP = 'Cancel create time slot';

const PREFIX_WIDTH = '30px';

const inputGroupStyles = {
    margin: "var(--spacingSmaller) 0",
};

const EditTimeSlotRow = ({
    isNew = false,
    isCreatingOther = false,
    timeSlot = {},
    errors = {},
    dateFormat,
    timeFormat,
    timeSlotTypes = [],
    onChangeValue = () => {},
    onToggleCreateOther = () => {},
    onSave = () => {},
    onCancel = () => {}
}) => {
    const typeError = errors.type && errors.type[0].text;
    const nameError = errors.name && errors.name[0].text;
    const startError = errors.start && errors.start[0].text;
    const endError = errors.end && errors.end[0].text;
    const typeHasError = typeof typeError !== 'undefined';
    const nameHasError = typeof nameError !== 'undefined';
    const startHasError = typeof startError !== 'undefined';
    const endHasError = typeof endError !== 'undefined';
    return (
        <tr>
            <td className="input">
                <InputGroup
                    hasError={typeHasError || nameHasError}
                    style={inputGroupStyles}
                >
                    <Tooltip
                        isAlert
                        isOpened={typeHasError || nameHasError}
                        content={typeError || nameError}
                    >
                        {(() => {
                            if (isNew) {
                                return (
                                    <PostfixedInput.Container
                                        postfix={!isCreatingOther
                                            ? <button
                                                onClick={() => {
                                                    onChangeValue('type', 0); // zero === other time slot type
                                                    onToggleCreateOther(true);
                                                }}
                                                className="postfix"
                                            >
                                                <i className="bs-icon-pencil no-margin" />
                                            </button>
                                            : <button
                                                onClick={() => {
                                                    onChangeValue('type', null);
                                                    onToggleCreateOther(false);
                                                }}
                                                className="postfix"
                                            >
                                                <i className="bs-icon-cross no-margin" />
                                            </button>
                                        }
                                    >
                                        {!isCreatingOther
                                            ? <SelectTimeSlotType
                                                showMenuTop
                                                value={timeSlot.type}
                                                timeSlotTypes={timeSlotTypes.filter(({ id }) => id !== 0)}
                                                onChange={value => onChangeValue('type', value, timeSlot.id)}
                                            />
                                            : <input
                                                type="text"
                                                placeholder={CUSTOM_PLACEHOLDER}
                                                value={timeSlot.name || ''}
                                                onChange={e => onChangeValue('name', e.target.value, timeSlot.id)}
                                            />
                                        }
                                    </PostfixedInput.Container>
                                );
                            }
                            if (timeSlot.type === 0) {
                                return (
                                    <input
                                        type="text"
                                        placeholder={CUSTOM_PLACEHOLDER}
                                        value={timeSlot.name || ''}
                                        onChange={e => onChangeValue('name', e.target.value, timeSlot.id)}
                                    />
                                )
                            }
                            return (
                                <SelectTimeSlotType
                                    value={timeSlot.type}
                                    timeSlotTypes={timeSlotTypes.filter(({ id }) => id !== 0)}
                                    onChange={value => onChangeValue('type', value, timeSlot.id)}
                                />
                            )
                        })()}
                    </Tooltip>
                </InputGroup>
            </td>
            <td className="input" style={{ paddingRight: 0 }}>
                <InputGroup
                    hasError={startHasError}
                    style={{ ...inputGroupStyles, marginRight: "var(--spacingSmallest)" }}
                >
                    <Tooltip
                        isAlert
                        isOpened={startHasError}
                        content={startError}
                    >
                        <DateInput
                            prefixWidth={PREFIX_WIDTH}
                            value={timeSlot.start}
                            dateFormat={dateFormat}
                            onChange={value => onChangeValue('startDate', value, timeSlot.id)}
                        />
                    </Tooltip>
                </InputGroup>
            </td>
            <td className="input" style={{ paddingLeft: 0 }}>
                <InputGroup
                    hasError={startHasError}
                    style={inputGroupStyles}
                >
                    <TimeInput
                        showMenuTop
                        prefixWidth={PREFIX_WIDTH}
                        prefixStyle={{ borderLeft: 'none' }}
                        value={timeSlot.start}
                        timeFormat={timeFormat}
                        onChange={value => onChangeValue('startTime', value, timeSlot.id)}
                    />
                </InputGroup>
            </td>
            <td className="input" style={{ paddingRight: 0 }}>
                <InputGroup
                    hasError={endHasError}
                    style={{ ...inputGroupStyles, marginRight: "var(--spacingSmallest)" }}
                >
                    <Tooltip
                        isAlert
                        isOpened={endHasError}
                        content={endError}
                    >
                        <DateInput
                            prefixWidth={PREFIX_WIDTH}
                            value={timeSlot.end}
                            dateFormat={dateFormat}
                            onChange={value => onChangeValue('endDate', value, timeSlot.id)}
                        />
                    </Tooltip>
                </InputGroup>
            </td>
            <td className="input" style={{ paddingLeft: 0 }}>
                <InputGroup
                    hasError={endHasError}
                    style={inputGroupStyles}
                >
                    <TimeInput
                        showMenuTop
                        prefixWidth={PREFIX_WIDTH}
                        prefixStyle={{ borderLeft: 'none' }}
                        value={timeSlot.end}
                        timeFormat={timeFormat}
                        onChange={value => onChangeValue('endTime', value, timeSlot.id)}
                    />
                </InputGroup>
            </td>
            <td className="actions">
                <Tooltip content={isNew ? SAVE_NEW_TOOLTIP : SAVE_UPDATE_TOOLTIP}>
                    <a className="action" onClick={() => onSave(timeSlot.id)}>
                        <i className="bs-icon-check" />
                    </a>
                </Tooltip>
                <Tooltip content={isNew ? CANCEL_NEW_TOOLTIP : CANCEL_UPDATE_TOOLTIP}>
                    <a className="action" onClick={() => onCancel(timeSlot.id)}>
                        <i className="bs-icon-cross" />
                    </a>
                </Tooltip>
            </td>
        </tr>
    );
};

EditTimeSlotRow.propTypes = {
    isNew: PropTypes.bool,
    isCreatingOther: PropTypes.bool,
    timeSlot: PropTypes.object,
    errors: PropTypes.object,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    timeSlotTypes: PropTypes.array,
    onChangeValue: PropTypes.func,
    onToggleCreateOther: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};

export default EditTimeSlotRow;
