import { connect } from 'react-redux'
import { mapAccountContextToProps, mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext'
import * as Event from 'app/react/entities/events/index'
import * as Day from 'app/react/entities/events/days/index'
import * as Accommodation from 'app/react/entities/events/accommodations/index'
import ReservationDashboard from './Dashboard'
import * as TableState from './components/table/TableState'

const ACCOMMODATION_INCLUDES = ['room_types', 'reservations.room_type'];

const mapStateToProps = (state) => {
    const { manageAccommodations } = Event.stateHelpers.getUserPermissions(state);
    const { expandedRows = [] } = TableState.getTableState(state);
    return {
        ...mapAccountContextToProps(state),
        ...mapEventContextToProps(state),
        isFetching: (
            Accommodation.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Day.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        expandedRows,
        userCanManageAccommodations: manageAccommodations,
        days: Day.stateHelpers.getAllForCurrentContext(state),
        accommodations: Accommodation.stateHelpers.getAllByPaginationForCurrentContext(state, true),
        accommodationsPagination: Accommodation.stateHelpers.getPaginationForCurrentContext(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onToggleRowExpansion: (rowKey) => {
        const { expandedRows } = stateProps;
        dispatchProps.onToggleRowExpansion(rowKey, expandedRows);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onChangePageSize: (pageSize) => {
        dispatch(Accommodation.thunks.fetchAllByPaginationForCurrentContext({ pageSize }, ACCOMMODATION_INCLUDES));
    },
    onSearch: (search) => {
        // We clear filters when searching
        const filters = {};
        dispatch(Accommodation.thunks.fetchAllByPaginationForCurrentContext({ search, filters }, ACCOMMODATION_INCLUDES));
    },
    onClearSearch: () => {
        const search = '';
        dispatch(Accommodation.thunks.fetchAllByPaginationForCurrentContext({ search }, ACCOMMODATION_INCLUDES));
    },
    onSort: (sortKey, sortOrder) => {
        dispatch(Accommodation.thunks.fetchAllByPaginationForCurrentContext({ sortKey, sortOrder }, ACCOMMODATION_INCLUDES));
    },
    onChangePage: (page) => {
        dispatch(Accommodation.thunks.fetchAllByPaginationForCurrentContext({ page }, ACCOMMODATION_INCLUDES));
    },
    onToggleRowExpansion: (rowKey, expandedRows = []) => {
        if (expandedRows.includes(rowKey)) {
            expandedRows = expandedRows.filter(row => row !== rowKey);
        } else {
            expandedRows.push(rowKey);
        }
        dispatch(TableState.mergeTableState({ expandedRows }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ReservationDashboard);
