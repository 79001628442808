import NavigationCollapsable from '../NavigationCollapsable.view';
import TitleBar from './TitleBar.view';
import DropDownMenu from './DropDownMenu.view';
import DropdownToggle from './DropdownToggle.view';

export default class Header extends NavigationCollapsable {

    ui() {
        return {
            titleBar: '#header--titlebar',
            userMenu: '#header--user-menu',
            dropdowns: '.header--dropdown'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.titleBar = new TitleBar();
        this.userMenu = new DropDownMenu({ dropDownId: '#user-menu-dropdown' });
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.titleBar, this.ui.titleBar);
        this.assignSubview(this.userMenu, this.ui.userMenu);
        const dropdowns = this.initViewsForEachEl(DropdownToggle, this.ui.dropdowns);
        dropdowns.forEach(dropdown => dropdown.render());
        return this.delegateEvents();
    }

}
