import BaseView from 'app/backbone/components/Base.view';

export default class ContentView extends BaseView {

    remove() {
        this.stopListening();
        this.undelegateEvents();
        this.$el.empty();
        this.unbind();
    }

}
