import { connect } from 'react-redux';
import { mapAccountContextToProps, mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import { enhanceStateWithHelpers } from 'app/react/entities/enhanceStateWithHelpers';
import * as State from './OverviewState';
import AccountArtistsItemsOverview from './Overview';
import * as Event from 'app/react/entities/events/index';
import * as GroupModalState from '../components/groups/GroupModalState';
import * as ItemModalState from '../components/items/ItemModalState';

const mapStateToProps = (state) => {
    const { helpers } = enhanceStateWithHelpers(state);
    const { eventArtistItems, eventArtistItemGroups } = helpers;
    return {
        ...mapEventContextToProps(state),
        permissions: Event.stateHelpers.getUserPermissions(state),
        isFetching: (
            eventArtistItems.isFetchingAllForCurrentContext() ||
            eventArtistItemGroups.isFetchingAllForCurrentContext()
        ),
        items: eventArtistItems.getAllForCurrentContext(),
        groups: eventArtistItemGroups.getAllForCurrentContext()
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onOpenUpdateItemModal: (itemId) => {
        const { items } = stateProps;
        const item = items.find(({ id }) => id === itemId);
        dispatchProps.onOpenUpdateItemModal(item);
    },
    onOpenDeleteItemModal: (itemId) => {
        const { items } = stateProps;
        const item = items.find(({ id }) => id === itemId);
        dispatchProps.onOpenDeleteItemModal(item);
    },
    onOpenUpdateGroupModal: (groupId) => {
        const { groups } = stateProps;
        const group = groups.find(({ id }) => id === groupId);
        dispatchProps.onOpenUpdateGroupModal(group);
    },
    onOpenDeleteGroupModal: (groupId) => {
        const { groups } = stateProps;
        const group = groups.find(({ id }) => id === groupId);
        dispatchProps.onOpenDeleteGroupModal(group);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onOpenCreateItemModal: (groupId) => {
        dispatch(ItemModalState.mergeCreateModalState({
            isOpened: true,
            values: typeof groupId !== 'undefined' ? { group: groupId } : {},
            errors: {}
        }))
    },
    onOpenUpdateItemModal: (item) => {
        dispatch(ItemModalState.mergeUpdateModalState({
            isOpened: true,
            itemId: item.id,
            values: { ...item },
            errors: {}
        }))
    },
    onOpenDeleteItemModal: (item) => {
        dispatch(ItemModalState.mergeDeleteModalState({
            isOpened: true,
            item,
        }))
    },
    onOpenCreateGroupModal: (parentId) => {
        dispatch(GroupModalState.mergeCreateModalState({
            isOpened: true,
            values: typeof parentId !== 'undefined' ? { parent: parentId } : {},
            errors: {}
        }))
    },
    onOpenUpdateGroupModal: (group) => {
        dispatch(GroupModalState.mergeUpdateModalState({
            isOpened: true,
            groupId: group.id,
            values: { ...group },
            errors: {}
        }))
    },
    onOpenDeleteGroupModal: (group) => {
        dispatch(GroupModalState.mergeDeleteModalState({
            isOpened: true,
            group,
        }))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(AccountArtistsItemsOverview);
