import React from 'react';
import PropTypes from 'prop-types';
import { DateInput, PostfixedButtonInput } from 'ui-kit';

const DEADLINE_BUTTON = 'Copy to sections';

export const AllDeadlinesInputGroup = ({
    settings = {},
    dateFormat = 'L',
    onCopyDeadlineToSections = () => {},
    onChangeDeadline = () => {}
}) => (
    <div className="input-group margin-medium-bottom" style={{ width: '40%' }}>
        <label>All deadlines</label>
        <PostfixedButtonInput
            buttonText={DEADLINE_BUTTON}
            buttonOnClick={onCopyDeadlineToSections}
        >
            <DateInput
                value={settings.deadline}
                dateFormat={dateFormat}
                onChange={value => onChangeDeadline(value)}
            />
        </PostfixedButtonInput>
    </div>
);

AllDeadlinesInputGroup.propTypes = {
    settings: PropTypes.object,
    dateFormat: PropTypes.string,
    onChangeDeadline: PropTypes.func,
    onCopyDeadlineToSections: PropTypes.func
};

export default AllDeadlinesInputGroup;
