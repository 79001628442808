import $ from 'jquery';
import BaseView from 'app/backbone/components/Base.view';

export default class CreateLocationRouteView extends BaseView {

    ui() {
        return {
            origin: '#route-form #origin',
            destination: '#route-form #destination'
        };
    }

    events() {
        return {
            'change origin': 'disableLocationAsDestination',
            'change destination': 'toggleCRMLocationFields'
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    disableLocationAsDestination(e) {
        this.disableLocationInDropdown(this.ui.destination, e.target.value);
    }

    toggleCRMLocationFields(e) {
        this.disableLocationInDropdown(this.ui.origin, e.target.value);
    }

    disableLocationInDropdown($dropdownEl, locationId) {
        $dropdownEl.find('option').each(function() {
            $(this).removeAttr('disabled');
        });
        $dropdownEl.find(`option[value="${locationId}"]`).attr('disabled', 'disabled');
    }

}


