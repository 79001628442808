import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { Tooltip, ConfirmModal } from 'app/react/components/_index';
import PagesList from './List';
import PageModal from './Modal';

const { Content } = ContentWrapper;
const { Header, Footer } = Card;
const { Title, Action } = Header;

const CARD_TITLE = 'Overview';
const CREATE_TOOLTIP = 'Create page';
export const PAGE_MODAL_ID = 'updatePageModal';
export const CONFIRM_MODAL_ID = 'removePageConfirmModal';
export const CONFIRM_MODAL_TITLE = 'Remove page';
export const CONFIRM_MODAL_TEXT = 'Are you sure you want to remove this page?';
export const CONFIRM_MODAL_BUTTON_TEXT = 'Yes, remove page!';

class PagesContent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeArtistTypeAccordion: null
        }

        this.handleAccordionChange = this.handleAccordionChange.bind(this)
    }

    handleAccordionChange(number) {
        this.setState({
            activeArtistTypeAccordion: number
        })
    }

    render() {
        const {
            productionWebsiteUrl,
            isFetching = false,
            isArtistTypesEnabled = false,
            pages = [],
            modalState = {
                isOpened: true,
                isSyncing: false,
                isCreating: true,
                values: {},
                errors: {}
            },
            confirmModalState = {
                isOpened: true,
                isSyncing: false
            },
            onConfirmModalConfirmed = () => {},
            onConfirmModalClosed = () => {},
            onModalClosed = () => {},
            onModalSave = () => {},
            onModalChangeFormValue = () => {},
            onCreate = () => {},
            onUpdate = () => {},
            onDelete = () => {},
            updatePageSequence = () => {},
            updateHoverState = () => {},
            dropOutsideList = () => {}
        } = this.props;
        const { activeArtistTypeAccordion } = this.state;

        return(
            <Content isLoading={isFetching}>
                <Card>
                    <Header>
                        <Title>{CARD_TITLE}</Title>
                        <Tooltip content={CREATE_TOOLTIP}>
                            <Action onClick={() => onCreate()}>
                                <i className="bs-icon-plus" />
                            </Action>
                        </Tooltip>
                    </Header>
                    <Card.Content>
                        <PagesList
                            pages={pages}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                            updatePageSequence={updatePageSequence}
                            updateHoverState={updateHoverState}
                            dropOutsideList={dropOutsideList}
                        />
                    </Card.Content>
                    <Footer />
                </Card>
                <PageModal
                    productionWebsiteUrl={productionWebsiteUrl}
                    isArtistTypesEnabled={isArtistTypesEnabled}
                    isOpened={modalState.isOpened}
                    isSyncing={modalState.isSyncing}
                    isCreating={modalState.isCreating}
                    isHomePage={modalState.isHomePage}
                    isFormPage={modalState.isFormPage}
                    hasChildren={modalState.hasChildren}
                    formValues={modalState.values}
                    formErrors={modalState.errors}
                    onChangeFormValue={onModalChangeFormValue}
                    onClosed={onModalClosed}
                    onSave={onModalSave}
                    activeArtistTypeAccordion={activeArtistTypeAccordion}
                    handleAccordionChange={this.handleAccordionChange}
                />
                <ConfirmModal
                    isOpened={confirmModalState.isOpened}
                    isSyncing={confirmModalState.isSyncing}
                    onConfirmed={onConfirmModalConfirmed}
                    onClosed={onConfirmModalClosed}
                    title={CONFIRM_MODAL_TITLE}
                    text={CONFIRM_MODAL_TEXT}
                    buttonText={CONFIRM_MODAL_BUTTON_TEXT}
                    buttonClassName="alert"
                />
            </Content>
        )
    }
}

PagesContent.propTypes = {
    productionWebsiteUrl: PropTypes.string.isRequired,
    isFetching: PropTypes.bool,
    isArtistTypesEnabled: PropTypes.bool,
    modalState: PropTypes.object,
    confirmModalState: PropTypes.object,
    onConfirmModalConfirmed: PropTypes.func,
    onConfirmModalClosed: PropTypes.func,
    onModalClosed: PropTypes.func,
    onModalSave: PropTypes.func,
    onModalChangeFormValue: PropTypes.func,
    onCreate: PropTypes.func,
    ...PagesList.propTypes
};

export default PagesContent;
