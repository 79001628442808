import { denormalize } from 'normalizr'
import dotProp from 'dot-prop-immutable'
import { LOADING_DATA_STATUS_DONE } from './constants'

export const isLoading = (state) => state.loadingData.status !== LOADING_DATA_STATUS_DONE;

export const getComponentState = (state, id) => state.components[id] || {};

export const getFormComponentState = (state, id) => state.components[id] || {
    fields: [],
    values: {},
    errors: {}
};

export const getCurrentContextId = (state, key) =>
    state.currentContext[key];
export const getCurrentContextEntity = (state, ENTITY_REDUCER_KEY, ENTITY_CONTEXT_ID_KEY) =>
    state.entities[ENTITY_REDUCER_KEY][getCurrentContextId(state, ENTITY_CONTEXT_ID_KEY)];

export const getCurrentAccountId = (state) => getCurrentContextId(state, 'accountId');

export const getCurrentAccount = (state) => state.entities.accounts[getCurrentAccountId(state)];

export const accounts = {
    getCurrentId: getCurrentAccountId,
    getCurrent: getCurrentAccount
};

export const getCurrentEventId = (state) => getCurrentContextId(state, 'eventId');

export const getCurrentEvent = (state) => state.entities.events[getCurrentEventId(state)];

export const getCurrentEventDateTimeFormatObject = (state) => {
    const { dateTimeFormat } = state.entities.events[getCurrentEventId(state)] || {};
    if (typeof dateTimeFormat === 'undefined') return { date: {}, time: {} };
    return dateTimeFormat;
};

export const getCurrentEventDateFormat = (state) =>
    getCurrentEventDateTimeFormatObject(state).date.momentjs;

export const getCurrentEventTimeFormat = (state) =>
    getCurrentEventDateTimeFormatObject(state).time.momentjs;

export const isArtistTypesEnabledForCurrentEvent = (state) => {
    const { artistTypesEnabled } = state.entities.events[getCurrentEventId(state)] || {};
    return !!artistTypesEnabled;
};

export const events = {
    getCurrentId: getCurrentEventId,
    getCurrent: getCurrentEvent,
    getCurrentDateFormat: getCurrentEventDateFormat,
    getCurrentTimeFormat: getCurrentEventTimeFormat,
    isArtistTypesEnabledForCurrent: isArtistTypesEnabledForCurrentEvent
};

export const getAllEntities = (state, entityReducerKey) => {
    const { entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    return Object.keys(entitiesByReducerKey).map(key => entitiesByReducerKey[key]);
};

export const getAllEntitiesByPagination = (state, entityReducerKey, paginationReducerKey = entityReducerKey) => {
    const { pagination, entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    const paginationByKey = pagination[paginationReducerKey] || { ids: [] };
    const { ids = [] } = paginationByKey;
    return ids.map(id => entitiesByReducerKey[id]);
};

export const getAllEntitiesByPaginationByKey = (state, entityReducerKey, paginationReducerKey, key) => {
    const { pagination, entities } = state;
    const paginationByKey = pagination[paginationReducerKey][key] || { ids: [] };
    const { ids = [] } = paginationByKey;
    return ids.map(id => entities[entityReducerKey][id]);
};

export const getSomeEntities = (state, entityReducerKey, ids = []) => {
    const { entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    return ids.map(id => entitiesByReducerKey[id]);
};

export const getAllEntitiesByPaginationDenormalized = (
    state,
    entityReducerKey,
    paginationReducerKey = entityReducerKey,
    entitySchema
) => {
    const { pagination, entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    const paginationByReducerKey = pagination[paginationReducerKey] || { ids: [] };
    const input = { [entityReducerKey]: Object.keys(entitiesByReducerKey) };
    const denormalizeSchema = { [entityReducerKey]: [entitySchema] };
    const denormalizedData = denormalize(input, denormalizeSchema, entities);
    const { ids = [] } = paginationByReducerKey;
    return denormalizedData[entityReducerKey].filter(({ id }) => ids.includes(id))
};

export const denormalizeAllEntitiesByPagination = (
    state,
    entityReducerKey,
    schema,
    pagination = {}
) => {
    const { entities } = state;
    const { ids = [] } = pagination;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    const input = { [entityReducerKey]: Object.keys(entitiesByReducerKey) };
    const denormalizeSchema = { [entityReducerKey]: [schema] };
    const denormalizedData = denormalize(input, denormalizeSchema, entities);
    return ids.map(id => denormalizedData[entityReducerKey].find(entity => entity.id === id))
};

export const getAllEntitiesDenormalized = (state, entityReducerKey, entitySchema) => {
    const { entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    const input = { [entityReducerKey]: Object.keys(entitiesByReducerKey) };
    const denormalizeSchema = { [entityReducerKey]: [entitySchema] };
    const denormalizedData = denormalize(input, denormalizeSchema, entities);
    return denormalizedData[entityReducerKey];
};

export const getSomeEntitiesDenormalized = (state, entityReducerKey, entitySchema, ids = []) => {
    const { entities } = state;
    const input = { [entityReducerKey]: ids };
    const denormalizeSchema = { [entityReducerKey]: [entitySchema] };
    const denormalizedData = denormalize(input, denormalizeSchema, entities);
    return denormalizedData[entityReducerKey];
};

export const getOneEntity = (state, entityReducerKey, id) => {
    const { entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    return entitiesByReducerKey[id];
};

export const getOneEntityDenormalized = (state, entityReducerKey, entitySchema, id) => {
    const { entities } = state;
    const input = { [entityReducerKey]: [id] };
    const denormalizeSchema = { [entityReducerKey]: [entitySchema] };
    const denormalizedData = denormalize(input, denormalizeSchema, entities);
    return denormalizedData[entityReducerKey][0];
};

export const getFirstEntity = (state, entityReducerKey) => {
    const { entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    const firstId = Object.keys(entitiesByReducerKey)[0];
    return entitiesByReducerKey[firstId];
};

export const addIdToOtherEntityArray = (state, entityReducerKey, id, arrayKey, idToAdd) =>
    dotProp.set(
        state,
        `entities.${entityReducerKey}.${id}.${arrayKey}`,
        (array = []) => [...array, idToAdd]
    );

export const removeIdFromOtherEntityArray = (state, entityReducerKey, id, arrayKey, indexInArray) =>
    dotProp.delete(
        state,
        `entities.${entityReducerKey}.${id}.${arrayKey}.${indexInArray}`
    );

export const addOneEntity = (state, entityReducerKey, entity, idAttribute = 'id') =>
    dotProp.set(
        state,
        `entities.${entityReducerKey}.${entity[idAttribute]}`,
        entity
    );

export const removeOneEntity = (state, entityReducerKey, id) => {
    return dotProp.delete(
        state,
        `entities.${entityReducerKey}.${id}`
    );
};

export const removeOneEntityFromPagination = (state, paginationReducerKey, id) => {
    const { pagination } = state;
    const { ids = [] } = pagination[paginationReducerKey];
    const index = ids.indexOf(id);
    if (index === -1) return state;
    return dotProp.delete(
        state,
        `pagination.${paginationReducerKey}.ids.${index}`
    );
};

export const getEntitiesByPaginationByKey = (state, entityReducerKey, paginationReducerKey, key) => {
    const { pagination, entities } = state;
    const paginationByKey = pagination[paginationReducerKey][key] || { ids: [] };
    const { ids = [] } = paginationByKey;
    return ids.map(id => entities[entityReducerKey][id]);
};

export const getFirstEntityByPaginationByKey = (state, entityReducerKey, paginationReducerKey, key) => {
    const { pagination, entities } = state;
    const entitiesByReducerKey = entities[entityReducerKey] || {};
    const paginationByKey = pagination[paginationReducerKey][key] || { ids: [] };
    const { ids = [] } = paginationByKey;
    const firstId = ids[0];
    return entitiesByReducerKey[firstId];
};

export const getEntitiesPagination = (state, paginationReducerKey) => {
    const { pagination } = state;
    return pagination[paginationReducerKey] || {};
};

export const getEntitiesPaginationByKey = (state, paginationReducerKey, key) => {
    const { pagination } = state;
    return pagination[paginationReducerKey][key] || { ids: [] };
};

export const getEntitiesCollection = (state, collectionReducerKey) => {
    const { collections } = state;
    return collections[collectionReducerKey] || { ids: [], recentSearches: {} };
};

export const getEntitiesCollectionByKey = (state, collectionReducerKey, key) => {
    const { collections } = state;
    return collections[collectionReducerKey][key] || { ids: [], recentSearches: {} };
};

export const isFetchingCollectionEntitiesByKey = (state, collectionReducerKey, key) => {
    const { isFetching = true } = getEntitiesCollectionByKey(state, collectionReducerKey, key);
    return isFetching
};

export const isFetchingEntities = (state, paginationReducerKey) =>
    !! getEntitiesPagination(state, paginationReducerKey).isFetching;


export const isFetchingEntitiesByKey = (state, paginationReducerKey, key) => {
    const pagination = getEntitiesPaginationByKey(state, paginationReducerKey, key);
    if (typeof pagination.isFetching === 'undefined') {
        return true
    }
    return pagination.isFetching
}

export const getUserSettingState = (state, key) => {
    const { userSettings } = state;
    return typeof userSettings[key] !== 'undefined'
}
