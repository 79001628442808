import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'request_status'
})
class RequestStatus extends BaseModel {

    urlRoot() {
        return `/api/companies/request-statuses`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        return response;
    }
}

@Collection({
    key: 'request_statuses',
    model: RequestStatus
})
class RequestStatusCollection extends BaseCollection {

    url() {
        return `/api/companies/request-statuses`;
    }

}

export {
    RequestStatus as Model,
    RequestStatusCollection as Collection
};
