import React from 'react'
import PropTypes from 'prop-types'
import {
    Tooltip,
    TableCell,
    SmartProgressCircle,
} from 'ui-kit'

const ProductionWebsiteProgressCell = ({ percentage }) => (
    <TableCell isCentered>
        <Tooltip content={`${percentage}%`}>
            <SmartProgressCircle progressPct={percentage} size={24} />
        </Tooltip>
    </TableCell>
);

ProductionWebsiteProgressCell.propTypes = {
    percentage: PropTypes.number,
};

export default ProductionWebsiteProgressCell;
