import { connect } from 'react-redux';
import * as actions from 'app/react/state/actions/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as Integration from 'app/react/entities/accounts/integrations/index';
import IntegrationsView from './IntegrationsView';
import { AUTHENTICATE_MODAL_ID } from '../components/AuthenticateModal';

const AUTHENTICATE_URL = integrationName => `/settings/integrations/${integrationName}`;

const mapStateToProps = state => ({
    isFetching: (
        Integration.stateHelpers.isFetchingAllAvailable(state) ||
        Integration.stateHelpers.isFetchingAllActivated(state)
    ),
    accountId: stateHelpers.accounts.getCurrentId(state),
    canManageIntegrations: !!stateHelpers.accounts.getCurrent(state).permissions.manage,
    availableIntegrations: Integration.stateHelpers.getAllAvailable(state),
    activatedIntegrations: Integration.stateHelpers.getAllActivated(state),
});

const mapDispatchToProps = dispatch => ({
    activateIntegration: (integration) => {
        if (integration.authType === 'basic') {
            dispatch(actions.openModal(AUTHENTICATE_MODAL_ID));
            dispatch(actions.setComponentStateValue(AUTHENTICATE_MODAL_ID, 'selectedIntegration', integration));
        } else {
            const integrationName = integration.name.toLowerCase();
            dispatch(actions.navigate.pageReload(AUTHENTICATE_URL(integrationName)));
        }
    },
    deactivateIntegration: (accountId, integrationId) => {
        dispatch(Integration.actions.deactivate(accountId, integrationId));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    activateIntegration: (integrationId) => {
        const { availableIntegrations } = stateProps;
        const integration = availableIntegrations.find(({ id }) => id === integrationId);
        dispatchProps.activateIntegration(integration);
    },
    deactivateIntegration: (integrationId) => {
        const { accountId, activatedIntegrations } = stateProps;
        const integration = activatedIntegrations.find(({ type }) => type === integrationId);
        dispatchProps.deactivateIntegration(accountId, integration.id);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(IntegrationsView);
