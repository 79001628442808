import React, { Fragment } from 'react';
import by from 'thenby';
import { Tooltip } from 'app/react/components/_index';
import ItemRow from './ItemRow';

const ADD_TOOLTIP = 'Add item to group';

const ItemGroupRowContent = ({
    group,
    onUpdate,
    onDelete,
    currency
}) => {
    const items = group.items || [];

    return (
        <Fragment>
            <tr className="row-with-border-bottom">
                <td>
                    <strong>{group.name}</strong>
                </td>
                <td />
            </tr>
            {items.sort(by((item) => item.name))
                .map(item => (
                    <ItemRow
                        key={item.id}
                        item={item}
                        currency={currency}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                    />
                ))
            }
        </Fragment>
    );
};

export default ItemGroupRowContent;
