import { schema } from 'normalizr';
import * as Section from './sections/schemas';

export const ENTITY_REDUCER_KEY = 'pwArtistSettings';

const entityDefinition = {
    sections: Section.entities,
};

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);
