import navigate from 'app/react/state/actions/navigate';
import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Routes from 'app/routes/event/artists/bookings/routes';
import { getCurrentEventId, getCurrentAccountId } from 'app/react/state/helpers';
import { getCurrentId, getPaginationForCurrentEvent } from './helpers';
import { fetchOne, fetchPaginatedForEvent, updateEntranceOne, searchAll } from './actions';

export const navigateToCurrent = (options) => (dispatch, getState) => {
    const state = getState();
    const eventId = getCurrentEventId(state);
    const bookingId = getCurrentId(state);
    dispatch(navigate.to(Routes.details(eventId, bookingId), options));
};

export const fetchCurrent = () => (dispatch, getState) => {
    const state = getState();
    const eventId = getCurrentEventId(state);
    const bookingId = getCurrentId(state);
    return dispatch(fetchOne(eventId, bookingId));
};

export const toggleIsCheckInForCurrentContext = (bookingId, isCheckedIn, checkInType) => (dispatch, getState) => {
    const state = getState();
    const accountId = getCurrentAccountId(state);
    const eventId = getCurrentEventId(state);
    if (checkInType === 'Artist') {
        const isArtistCheckedIn = isCheckedIn;
        return dispatch(updateEntranceOne(accountId, eventId, bookingId, { isArtistCheckedIn }, 'artist'));
    } else {
        const isManagerCheckedIn = isCheckedIn;
        return dispatch(updateEntranceOne(accountId, eventId, bookingId, { isManagerCheckedIn }, 'manager'));
    }
};

export const fetchAllByPaginationForCurrentEvent = (
    page = null,
    pageSize = null,
    filters = null,
    search = null,
    sortKey = null,
    sortOrder = null
) => (dispatch, getState) => {
    const state = getState();
    const { requestedPage, requestedPageSize, requestedFilters, requestedSearch, requestedSortKey, requestedSortOrder } = getPaginationForCurrentEvent(state);
    let finalPage = requestedPage;
    let finalPageSize = requestedPageSize;
    let finalFilters = requestedFilters;
    let finalSearch = requestedSearch;
    let finalSortKey = requestedSortKey;
    let finalSortOrder = requestedSortOrder;
    if (page !== null) {
        finalPage = page;
    }
    if (pageSize !== null) {
        finalPageSize = pageSize;
    }
    if (filters !== null) {
        finalFilters = filters;
    }
    if (search !== null) {
        finalSearch = search;
    }
    if (sortKey !== null) {
        finalSortKey = sortKey;
    }
    if (sortOrder !== null) {
        finalSortOrder = sortOrder;
    }
    const eventId = getCurrentEventId(state);
    const accountId = getCurrentAccountId(state);
    return dispatch(fetchPaginatedForEvent(accountId, eventId, finalPage, finalPageSize, finalFilters, finalSearch, finalSortKey, finalSortOrder));
};

export const searchAllForCurrentContext = (searchTerm, includes, filters) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(searchAll(accountId, eventId, includes, filters, searchTerm));
};


