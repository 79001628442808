var _ = require('underscore'),
    Backbone = require('backbone');

var ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin'),
    Modal = require('app/backbone/components/modals/Modal.view');

var ContactModal = Modal.extend({

    mixins: [
        ValidatedForm,
        Form
    ],

    bindings: {
        '[name=firstname]': 'firstname',
        '[name=lastname]': 'lastname',
        '[name=email]': 'email'
    },

    options: [
        'width',
        'canCreateAnother'
    ],

    initialize(options) {
        this._super(options);

        // Default Options
        !_.isUndefined(this.width || (this.width = 600));
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.delegateEvents();

        if(!this.isSyncing) {
            this.unbindModel();
            this.initForm();
            this.bindModel();
            this.renderForm();
        }

        this._super();
    },

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                isSyncing: this.isSyncing,
                hiddenInputs: this.hiddenInputs,
                height: this.height
            },
            model: this.model.attributes
        };
    },

    initForm() {
        this.form = {
            inputs: new Backbone.ChildViewContainer(),
            groups: new Backbone.ChildViewContainer()
        };

        this.disableInputs(this.disabledInputs);

        if(this.isAvailable('firstname')) this.form.groups.add(this.initValidationGroup('firstname', this.model), 'firstname');
        if(this.isAvailable('lastname')) this.form.groups.add(this.initValidationGroup('lastname', this.model), 'lastname');
        if(this.isAvailable('email')) this.form.groups.add(this.initValidationGroup('email', this.model), 'email');
    },

    renderForm() {
        this.form.inputs.call('render');
        this.form.groups.call('render');
    },

    bindModel() {
        this.stickit();
        this.listenTo(this.model, 'sync', this.syncSuccess);
        this.listenTo(this.model, 'request', () => { this.setSyncing(true) });
        this.listenTo(this.model, 'error', this.syncError);
    },

    unbindModel() {
        this.unstickit();
        this.stopListening(this.model);
    },

    syncError(model, xhr){
        this.setSyncing(false);
        this.validateResponse(xhr);
    },

    setSyncing(syncing) {
        if(syncing != this.isSyncing){
            this.isSyncing = syncing;
            this.render();
        }
    },

    shown() {
        this._super();
        this.focusFirstInput();
    }
});

module.exports = ContactModal;
