import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_EVENT_ROUTE_REQUEST';
export const ONE_SUCCESS = 'ONE_EVENT_ROUTE_SUCCESS';
export const ONE_FAILURE = 'ONE_EVENT_ROUTE_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const fetchDirections = (eventId, departureLocation, arrivalLocation) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchDirections(eventId, departureLocation, arrivalLocation),
        schema: Schemas.entity
    }
});



