import { connect } from 'react-redux';
import { trim } from 'common-tags';
import { mapEventContextToProps, mapBookingContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as actions from 'app/react/state/actions/_index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Performer from 'app/react/entities/events/bookings/performers/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as TicketType from 'app/react/entities/events/ticket_types/index';
import * as PWArtistSettings from 'app/react/entities/events/production_website/settings/artists/index';
import * as PWArtistSettingsSection from 'app/react/entities/events/production_website/settings/artists/sections/index';
import * as PWArtistSettingsSectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/index';
import View from '../../components/View';
import * as State from './State';
import omit from 'lodash.omit'

const FAILED_TO_CREATE_POPUP =
    'Failed to create performer.';
const SUCCESSFUL_CREATE_ANOTHER_POPUP = (fullName) =>
    `Performer <strong>${fullName}</strong> successfully created.`;
const SUCCESSFUL_CREATE_POPUP = (fullName) =>
    `${SUCCESSFUL_CREATE_ANOTHER_POPUP(fullName)} Redirecting...`;

const mapStateToProps = (state) => {
    const {
        values = {},
        errors = {},
        selectedPassport = null,
        isSaving,
    } = State.getViewState(state);
    const section = PWArtistSettingsSection.stateHelpers.getCurrent(state) || {};
    const formFields = PWArtistSettingsSectionFormField.stateHelpers.getSome(state, section.formFields);
    const ticketTypeOptions = TicketType.stateHelpers.getAllForCurrentContext(state);
    return {
        ...mapEventContextToProps(state),
        ...mapBookingContextToProps(state),
        isFetching: (
            isSaving ||
            ArtistType.stateHelpers.isFetchingCurrent(state) ||
            PWArtistSettings.stateHelpers.isFetchingCurrent(state) ||
            TicketType.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        values,
        errors,
        arePassportsEnabled: section.passportsEnabled,
        dynamicFields: formFields,
        selectedPassport,
        ticketTypeOptions
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSave: (createAnother = false) => {
        const { values } = stateProps;
        const { redirect } = ownProps;
        dispatchProps.onSave(createAnother, values, redirect);
    },
    onBack: () => {
        const { redirect } = ownProps;
        dispatchProps.onBack(redirect);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onChangeValue: (key, value) => {
        dispatch(State.setViewStateValue(`values.${key}`, value));
    },
    onChangePassport: (passport) => {
        if (typeof passport === 'undefined') {
            dispatch(State.setViewStateValue('values.passport', null));
            dispatch(State.deleteViewStateValue('selectedPassport'));
        } else {
            dispatch(State.setViewStateValue('values.passport', passport.uuid));
        }
    },
    onUploadSuccess: (uploadedFile) => {
        dispatch(State.setViewStateValue('selectedPassport', uploadedFile));
        dispatch(State.mergeViewState({ errors: {} }));
    },
    onUploadFailed: (errors) => {
        dispatch(State.mergeViewState({ errors }));
        dispatch(State.setViewStateValue('values.passport', null));
    },
    onSelectContact: (contact) => {
        if (typeof contact !== 'undefined') {
            const { id } = contact;
            // API requires the key to be named 'contact' instead of 'id' to re-use existing contacts
            contact.contact = id;
            dispatch(State.mergeViewStateValue('values', {
                ...omit(contact, 'id')
            }));
        } else {
            // when deselecting contact, make sure to remove 'contact' property,
            // else the previous filled in contact will still be updated
            dispatch(State.deleteViewStateValue('values.contact'));
            dispatch(State.mergeViewState({
                values: {}
            }));
        }
    },
    onSave: (createAnother, values, redirect) => {
        dispatch((State.setViewStateValue('isSaving', true)));
        // Set passport to null if no passport is given to remove it.
        const apiValues = {
            ...values,
            passport: values.passport || null
        };
        const { firstname, lastname } = values;
        const fullName = `${firstname} ${lastname}`.trim();
        dispatch(Performer.thunks.createOneForCurrentContext(apiValues))
            .then(({ isFailure, errors }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(FAILED_TO_CREATE_POPUP));
                    dispatch(State.mergeViewState({
                        errors: errors,
                        isSaving: false
                    }));
                } else if (createAnother) {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_CREATE_ANOTHER_POPUP(fullName)));
                    dispatch(State.mergeViewState({
                        errors: {},
                        values: {},
                        isSaving: false
                    }));
                    dispatch(actions.deleteComponentStateValue('searchSelectContacts', 'value'));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_CREATE_POPUP(fullName)));
                    if (typeof redirect === 'undefined') {
                        dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
                    } else {
                        dispatch(actions.navigate.pageReload(redirect))
                    }
                }
            });
    },
    onBack: (redirect) => {
        if (typeof redirect === 'undefined') {
            dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
        } else {
            dispatch(actions.navigate.pageReload(redirect))
        }
    }
});



export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(View);
