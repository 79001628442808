import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SIZE = '16px';

const CheckBox = styled.div`
    display: inline-block;
    position: relative;
     
    input[type="checkbox"] {
        opacity: 0;
        width: ${SIZE};
        height: ${SIZE};
        margin: 0;
    }        
        
    input[type="checkbox"] + label {
        display: block;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: ${SIZE};
        height: ${SIZE};
        cursor: pointer;
    }
        
    input[type="checkbox"] + label svg { 
        position: absolute;
        top: 0;
        left: 0;
        width: ${SIZE};
        height: ${SIZE};
        border: 1px solid #D3D9F0;
        border-radius: .2rem;
    }
    
    input[type="checkbox"].loading ~ label svg {
        border-color: transparent;
    }
    
    input[type="checkbox"].loading ~ label svg {
        border-color: transparent;
    }  
    
    input[type="checkbox"]:not(.loading):checked ~ label svg {
        background-color: #13D3AB;
        border-color: #13D3AB;
    }  
    
    input[type="checkbox"]:not(.loading):checked ~ label svg polyline {
        stroke: #FFF;
    }
`;

const CheckBoxInput = ({
    id,
    checked = false,
    disabled = false,
    loading = false,
    style = {},
    onChange = () => {},
    onClick = () => {}
}) => (
    <CheckBox>
        <input
            type="checkbox"
            id={id}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            onClick={onClick}
            className={classNames({ loading })}
        />
        <label htmlFor={id}>
            <svg viewBox="0 0 24 24">
                {loading && (
                    <path
                        fill="none"
                        stroke="#13D3AB"
                        strokeWidth="2"
                        d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12"
                    >
                        <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 12 12"
                            to="360 12 12"
                            dur="0.7s"
                            repeatCount="indefinite"
                        />
                    </path>
                )}
                <polyline
                    fill="none"
                    stroke="none"
                    strokeWidth="3"
                    points="6.23742676 11.9567464 10.0386963 15.5963949 17.7574463 7.69209798"
                />
            </svg>
        </label>
    </CheckBox>
);

CheckBoxInput.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    reverse: PropTypes.bool,
    disabled: PropTypes.bool,
    tiny: PropTypes.bool,
    lock: PropTypes.bool,
    uncheckedYellow: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
};

export default CheckBoxInput;
