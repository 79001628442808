import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import ProductionWebsiteForm from "./ProductionWebsiteForm";
import { apolloClient } from "../../../../../../state/data_sources/graphql";

const UPDATE_PRODUCTION_WEBSITE_SETTINGS = gql`
  mutation updateProductionWebsiteLayout(
    $event: ID!
    $input: ProductionWebsiteLayoutInput!
  ) {
    updateProductionWebsiteLayout(event: $event, input: $input) {
      productionWebsite {
        id
      }
    }
  }
`;

const updateProductionWebsiteSettings = (event, input) =>
  apolloClient.mutate({
    mutation: UPDATE_PRODUCTION_WEBSITE_SETTINGS,
    variables: { event, input },
  });

const ProductionWebsiteFormContainer = ({
  setActiveStep,
  activeSubStep,
  eventState,
}) => {
  const [error, setError] = useState(undefined);
  return (
    <ProductionWebsiteForm
      eventState={eventState}
      setActiveStep={setActiveStep}
      activeSubStep={activeSubStep}
      updateProductionWebsiteSettings={updateProductionWebsiteSettings}
      error={error}
      setError={setError}
    />
  );
};

ProductionWebsiteFormContainer.propTypes = {};

export default ProductionWebsiteFormContainer;
