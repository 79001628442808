import { denormalize } from 'normalizr';
import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Schemas from './schemas'
import {
    getCurrentContextId,
    getCurrentContextEntity,
    getOneEntity,
    getAllEntities,
    getAllEntitiesByPagination,
    denormalizeAllEntitiesByPagination,
    getAllEntitiesDenormalized,
    getSomeEntities,
    getSomeEntitiesDenormalized,
    isFetchingEntitiesByKey,
    isFetchingEntities,
    getEntitiesPaginationByKey,
    getEntitiesCollectionByKey,
    getCurrentAccountId,
    getCurrentEventId,
} from 'app/react/state/helpers';
import {
    generateCollectionKey
} from 'app/react/entities/events/helpers'
import {
    generatePaginationKeyForEvent,
    generatePaginationKeyForEventForUnassigned,
    generatePaginationKeyForEventForArtistForUnassigned
} from './actions'

const {
    ENTITY_REDUCER_KEY,
    ENTITY_CONTEXT_ID_KEY,
    PAGINATION_REDUCER_KEY,
    PAGINATION_BY_ARTIST_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} = Schemas;

export const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);

export const getAll = (state, denormalize = false) => {
    if (denormalize) {
        return getAllEntitiesDenormalized(state, ENTITY_REDUCER_KEY, Schemas.entity);
    }
    return getAllEntities(state, ENTITY_REDUCER_KEY);
};

export const getSome = (state, ids, denormalize = false) => {
    if (denormalize) {
        return getSomeEntitiesDenormalized(state, ENTITY_REDUCER_KEY, Schemas.entity, ids);
    }
    return getSomeEntities(state, ENTITY_REDUCER_KEY, ids);
};

export const getCurrent = (state) =>
    getCurrentContextEntity(state, ENTITY_REDUCER_KEY, ENTITY_CONTEXT_ID_KEY);

export const getCurrentId = (state) =>
    getCurrentContextId(state, ENTITY_CONTEXT_ID_KEY);

export const isFetchingCurrent = (state) =>
    !! (getCurrent(state) || {}).isFetching;

export const getSomeDenormalized = (state, ids) =>
    getSomeEntitiesDenormalized(state, ENTITY_REDUCER_KEY, Schemas.entity, ids);

export const getAllDenormalized = (state) =>
    getAllEntitiesDenormalized(state, ENTITY_REDUCER_KEY, Schemas.entity);

export const getAllDenormalizedByArtistId = (state, artistId) => {
    const { pagination, entities } = state;
    const paginationByKey = pagination[PAGINATION_BY_ARTIST_REDUCER_KEY][artistId] || { ids: [] };
    const input = { [ENTITY_REDUCER_KEY]: paginationByKey.ids };
    const denormalizeSchema = { [ENTITY_REDUCER_KEY]: [Schemas.entity] };
    const denormalizedData = denormalize(input, denormalizeSchema, entities);
    return denormalizedData[ENTITY_REDUCER_KEY];
};

export const getAllByPagination = (state) =>
    getAllEntitiesByPagination(state, ENTITY_REDUCER_KEY, PAGINATION_REDUCER_KEY);

export const isFetchingByArtistId = (state, artistId) =>
    isFetchingEntitiesByKey(state, PAGINATION_BY_ARTIST_REDUCER_KEY, artistId);

export const getPaginationByArtistId = (state, artistId) =>
    getEntitiesPaginationByKey(state, PAGINATION_BY_ARTIST_REDUCER_KEY, artistId);

export const isFetching = (state) =>
    isFetchingEntities(state, PAGINATION_REDUCER_KEY);

export const getPaginationByKey = (state, key) =>
    getEntitiesPaginationByKey(state, PAGINATION_REDUCER_KEY, key);


export const getPaginationForCurrentEventForUnassigned = (state) =>
    getPaginationByKey(
        state,
        generatePaginationKeyForEventForUnassigned(
            getCurrentAccountId(state),
            getCurrentEventId(state)
        )
    );

export const isFetchingAllUnassignedForCurrentEvent = (state) =>
    !! getPaginationForCurrentEventForUnassigned(state).isFetching;

export const getPaginationForCurrentEventForArtistForUnassigned = (state, artistId) =>
    getPaginationByKey(
        state,
        generatePaginationKeyForEventForArtistForUnassigned(
            getCurrentAccountId(state),
            getCurrentEventId(state),
            artistId
        )
    );

export const isFetchingAllUnassignedForCurrentEventByArtistId = (state, artistId) =>
    getPaginationForCurrentEventForArtistForUnassigned(state, artistId).isFetching;

export const denormalizeAllUnassignedByPaginationForCurrentEventByArtistId = (state, artistId) =>
    denormalizeAllEntitiesByPagination(
        state,
        ENTITY_REDUCER_KEY,
        Schemas.entity,
        getPaginationForCurrentEventForArtistForUnassigned(state, artistId)
    );

export const getPaginationForCurrentEvent = (state) =>
    getPaginationByKey(
        state,
        generatePaginationKeyForEvent(
            getCurrentAccountId(state),
            getCurrentEventId(state)
        )
    );

export const denormalizeAllByPaginationForCurrentEvent = (state) =>
    denormalizeAllEntitiesByPagination(
        state,
        ENTITY_REDUCER_KEY,
        Schemas.entity,
        getPaginationForCurrentEvent(state)
    );

export const getCollection = (state, accountId, eventId) => {
    const key = generateCollectionKey(accountId, eventId);
    return getEntitiesCollectionByKey(state, COLLECTION_REDUCER_KEY, key);
};

export const getCollectionForCurrentContext = (state) => {
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return getCollection(state, accountId, eventId);
};

export const getLatestSearchResultsForCurrentContext = (state, denormalize = false) => {
    const collection = getCollectionForCurrentContext(state);
    const ids = collection.latestSearchIds;
    return getSome(state, ids, denormalize);
};

export const getSearchResultsForCurrentContext = (state, searchTerm, denormalize) => {
    const collection = getCollectionForCurrentContext(state);
    const ids = collection.recentSearches[searchTerm];
    return getSome(state, ids, denormalize)
};

export const isSeachingForCurrentContext = (state) =>
    !! getCollectionForCurrentContext(state).isSearching;
