import _ from 'underscore';
import Paginator from 'backbone.paginator'
import { DEFAULT_SIZE } from 'ui-kit/lib/components/pagination/PageSize'

export default class PageableCollection extends Paginator {

    getInitialState() {
        return {
            firstPage: 1,
            lastPage: null,
            currentPage: 1,
            pageSize: DEFAULT_SIZE,
            totalPages: null,
            totalRecords: null,
            sortKey: null,
            directions: null,
            order: -1,
            includes: [],
            filters: {}
        };
    }

    getQueryParams() {
        return {
            currentPage: 'page',
            pageSize: 'limit',
            totalPages: null,
            totalRecords: null,
            sortKey: 'sort',
            directions: { 1: '', '-1': '-' },
            order: 'order'
        };
    }

    setSortKey(sortKey) {
        if (sortKey) this.state.sortKey = sortKey;
        return this; // Chainable
    }

    parseRecords(response = {}) {
        return response.data ? response.data : response;
    }

    parseState(response = {}) {
        const meta = response.meta || {};
        const { pagination, filters, includes } = meta;
        const newState = {};
        if (pagination) {
            newState.currentPage = pagination.current_page;
            newState.pageSize = pagination.per_page;
            newState.totalPages = pagination.total_pages;
            newState.totalRecords = pagination.total;
        }
        if (includes) {
            newState.includes = includes;
        }
        if (filters) {
            newState.filters = filters;
        }
        return newState;
    }

    getCurrentPage() {
        this.getPage(this.state.currentPage);
    }

    isEmptyButHasMore() {
        return this.isEmpty() && this.state.totalRecords > 1;
    }

    setSorting(sortKey, order, options = {}) {
        const customKey = _.result(options, 'sortValue') || sortKey;

        let newKey;
        if (order === -1) newKey = `-${customKey}`;
        else if (order === 1) newKey = customKey;

        // We removed comparator because we sort server side
        this.comparator = null;

        return super.setSorting(newKey, order, options);
    }

    getPage(index, options = {}) {
        _.extend(options, { bustCache: true });
        return super.getPage(index, options);
    }

    fetch(options = {}) {
        // set a flag to bust the cache if we have ever set it before, and it's been more than 30 seconds
        // or the options bustCache has been passed
        const bustCache = (
            this.cacheTimeHasExpired() ||
            this.includesHaveChangedSinceLastFetch() ||
            this.filtersHaveChangedSinceLastFetch() ||
            this.sortFieldHasChangedSinceLastFetch() ||
            options.bustCache
        );

        // first fetch
        if (!this.lastFetchedDeferred) {
            const state = this.getStoredState();
            if (state) {
                const { currentPage, filters, order, sortKey, pageSize } = state;
                this.state = {
                    ...this.state,
                    currentPage,
                    pageSize,
                    sortKey,
                    order,
                    filters
                };
            }
        }

        // if we've never cached the call to `fetch`, or if we're busting the cache
        if (!this.lastFetchedDeferred || bustCache) {
            this.lastFetched = new Date();
            this.lastFetchedIncludes = this.state.includes;
            this.lastFetchedFilters = this.state.filters;
            this.lastFetchedSortKey = this.state.sortKey;
            this.lastFetchedDeferred = super.fetch(options);
            this.storeState();
        }

        // return the promise object in the cache
        return this.lastFetchedDeferred;
    }

    sortFieldHasChangedSinceLastFetch() {
        return ! _.isEqual(this.lastFetchedSortKey, this.state.sortKey);
    }

    getConstructorName() {
        return this.constructorName;
    }

}
