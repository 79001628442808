import OptionTypes from 'app/backbone/helpers/OptionTypes';
import CardActionContainer from './CardActionContainer.view';
import template from './CardFooter.hbs';

export default class CardFooter extends CardActionContainer {

    get template() {
        return template;
    }

    ui() {
        return {
            content: '.card--footer_content'
        };
    }

    optionTypes() {
        return {
            actions: OptionTypes.array,
            error: OptionTypes.object,
            success: OptionTypes.object,
            warning: OptionTypes.object
        };
    }

    optionDefaults() {
        return {
            actions: []
        };
    }

    initialize(options) {
        this.resolveOptions(options);
        this.actions = this.initFooterActions();
    }

    viewModel() {
        return {
            view: {
                error: this.error,
                success: this.success,
                warning: this.warning
            },
            model: {}
        };
    }

    hasError() {
        return !! this.error;
    }

    setError(message) {
        if (message) this.error = { message };
        else this.error = null;
        this.render();
    }

    getError() {
        if (this.hasError()) return this.error.message;
        return null;
    }

}
