import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import DateTime from 'app/backbone/helpers/DateTime';
import * as Day from '../../Day';

@Model({
    key: 'period'
})
class Period extends BaseModel {

    static Relations() {
        return {
            days: Day.Collection,
            event: require('../../Event').Model // Circular
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a name.'
            },
            start_date: {
                required: true,
                msg: 'Enter a start date.'
            },
            end_date: {
                required: true,
                msg: 'Enter a end date.'
            }
        }
    }

    initialize(model, options  = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('event');
        let eventId = this.event.id;
        return `/api/events/${eventId}/periods`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        response.start_date = DateTime.account.formatDateToDateString(response.start_date);
        response.end_date = DateTime.account.formatDateToDateString(response.end_date);
        return response;
    }
}

@Collection({
    key: 'periods',
    model: Period
})
class PeriodCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('../../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/periods`;
    }

    comparator(model) {
        return DateTime.account.parseDateTimeStringToDate(model.get('start_date')).getTime();
    }

    getAllDays() {
        let days = [];
        this.each(period => { days.push.apply(days, period.getRelation('days').models) });
        return new Day.Collection(days)
    }
}

const PageableCollection = PeriodCollection.prototype.PageableCollection();

export {
    Period as Model,
    PeriodCollection as Collection,
    PageableCollection
};
