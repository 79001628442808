import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';

const { Tabs, TitleMenu } = Header;
const { Title, Actions } = TitleMenu;
const { Tab } = Tabs;

const BASE_PATH = (eventId) => `/events/${eventId}/settings/accreditation`;
export const TABS = {
    PERIODS: {
        ID: 'periods',
        PATH: 'periods'
    },
    ZONES: {
        ID: 'zones',
        PATH: 'zones'
    },
    PROFILES: {
        ID: 'profiles',
        PATH: 'profiles'
    },
    ITEMS: {
        ID: 'items',
        PATH: 'items'
    },
    TICKETS: {
        ID: 'tickets',
        PATH: 'tickets'
    }
};

const EventAccreditationSettingsContentHeader = ({
    children,
    eventId,
    activeTab
}) => {
    const basePath = BASE_PATH(eventId);
    return (
        <Header>
            <TitleMenu>
                <Title>Accreditation</Title>
                <Actions>{children}</Actions>
            </TitleMenu>
            <Tabs>
                <Tab
                    activeTab={activeTab}
                    tabId={TABS.PERIODS.ID}
                    url={`${basePath}/${TABS.PERIODS.PATH}`}
                >
                    Periods
                </Tab>
                <Tab
                    activeTab={activeTab}
                    tabId={TABS.ZONES.ID}
                    url={`${basePath}/${TABS.ZONES.PATH}`}
                >
                    Zones
                </Tab>
                <Tab
                    activeTab={activeTab}
                    tabId={TABS.PROFILES.ID}
                    url={`${basePath}/${TABS.PROFILES.PATH}`}
                >
                    Profiles
                </Tab>
                <Tab
                    activeTab={activeTab}
                    tabId={TABS.ITEMS.ID}
                    url={`${basePath}/${TABS.ITEMS.PATH}`}
                >
                    Items
                </Tab>
                <Tab
                    activeTab={activeTab}
                    tabId={TABS.TICKETS.ID}
                    url={`${basePath}/${TABS.TICKETS.PATH}`}
                >
                    Tickets
                </Tab>
            </Tabs>
        </Header>
    );
};

EventAccreditationSettingsContentHeader.propTypes = {
    children: PropTypes.node,
    eventId: PropTypes.number.isRequired,
    activeTab: PropTypes.string
};

export default EventAccreditationSettingsContentHeader;
