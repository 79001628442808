import { html } from 'common-tags';
import Content from 'app/backbone/components/layout/content/Content.view';
import PeriodOverviewCard from './cards/PeriodOverviewCard.view';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';

export default class PeriodOverview extends Content {

    ui() {
        return {
            periodsCard: '#periods-card'
        };
    }

    optionTypes() {
        return {
            periods: OptionTypes.instanceOf(Period.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        // Subviews
        this.periodsCard = this.initPeriodsCard();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.periodsCard, this.ui.periodsCard);
        return this.delegateEvents();
    }

    template() {
        return html`<div id="periods-card" class="card"></div>`;
    }

    remove() {
        super.remove();
        this.periodsCard.remove();
    }

    initPeriodsCard() {
        const { periods } = this;
        const title = 'Periods';
        return new PeriodOverviewCard({ periods, title });
    }

}
