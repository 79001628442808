import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    InputGroup,
    Grid,
} from 'ui-kit';
import SelectBookingStatus from '../../../performances/views/selects/SelectBookingStatus';
import SelectBookingType from '../../../performances/views/selects/SelectBookingType';
import SelectArtistType from '../../../performances/views/selects/SelectArtistType';

const { Header, Footer } = Card;
const { Title } = Header;
const { Cell } = Grid;

const SELECT_ARTIST_TYPE_LABEL = 'Artist type';
const SELECT_BOOKING_TYPE_LABEL = 'Booking type';
const SELECT_BOOKING_STATUS_LABEL = 'Booking status';

const TITLE = 'Booking';

const UpdateBookingCard = ({
    values = [],
    errors = {},

    isArtistTypesEnabled = true,

    artistTypes = [],
    bookingTypes = [],
    bookingStatuses = [],

    onChangeValue = () => {},
}) => (
    <Card>
        <Header>
            <Title>{TITLE}</Title>
        </Header>
        <Card.Content>
            {isArtistTypesEnabled &&
                <InputGroup messages={errors.artistType}>
                    <label className="required">
                        {SELECT_ARTIST_TYPE_LABEL}
                    </label>
                    <SelectArtistType
                        artistTypes={artistTypes}
                        value={values.artistType}
                        onChange={value => onChangeValue('artistType', value)}
                    />
                </InputGroup>
            }
            <Grid>
                <Cell>
                    <InputGroup messages={errors.type}>
                        <label className="required">
                            {SELECT_BOOKING_TYPE_LABEL}
                        </label>
                        <SelectBookingType
                            bookingTypes={bookingTypes}
                            value={values.type}
                            onChange={value => onChangeValue('type', value)}
                        />
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors.status}>
                        <label className="required">
                            {SELECT_BOOKING_STATUS_LABEL}
                        </label>
                        <SelectBookingStatus
                            bookingStatuses={bookingStatuses}
                            value={values.status}
                            onChange={value => onChangeValue('status', value)}
                        />
                    </InputGroup>
                </Cell>
            </Grid>
        </Card.Content>
        <Footer />
    </Card>
);

UpdateBookingCard.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,

    isArtistTypesEnabled: PropTypes.bool,

    artistTypes: PropTypes.array,
    bookingTypes: PropTypes.array,
    bookingStatuses: PropTypes.array,

    onChangeValue: PropTypes.func,
};

export default UpdateBookingCard;
