import _ from 'underscore';
import Backbone from 'backbone';

export function model(modelClass) {
    return function decorator(target) {
        if (modelClass.prototype instanceof Backbone.Model) {
            target.prototype.model = modelClass;
        } else {
            throw new Error('The model decorator takes either a single argument that should be an instance of Backbone.Model');
        }
    };
}

export function key(keyString) {
    return function decorator(target) {
        if (_.isString(keyString)) {
            target.prototype.key = keyString;
        } else {
            throw new Error('The key decorator takes either a single argument that should be a string');
        }
    };
}

export function comparator(comparatorString) {
    return function decorator(target) {
        if (_.isString(comparatorString)) {
            target.prototype.comparator = comparatorString;
        } else {
            throw new Error('The comparator decorator takes either a single argument that should be a string');
        }
    };
}

export function Model(modelObject) {
    return function decorator(target) {
        if(_.isObject(modelObject)) {
            if (_.isString(modelObject.key)) target.prototype.key = modelObject.key;
            else throw new Error('The key field for the Model decorator object should be a string.');
        } else throw new Error('The Model decorator takes a single argument that should be an object.');
    };
}

export function Collection(collectionObject) {
    return function decorator(target) {
        if(_.isObject(collectionObject)) {
            if(!_.isUndefined(collectionObject.key)) {
                if (_.isString(collectionObject.key)) target.prototype.key = collectionObject.key;
                else throw new Error('The key field for the Collection decorator object should be a string.');
            } else throw new Error('The Collection decorator object does not contain a key field.');
            if(!_.isUndefined(collectionObject.model)) {
                if (collectionObject.model.prototype instanceof Backbone.Model) target.prototype.model = collectionObject.model;
                else throw new Error('The model field for the Collection decorator object should be an instance of Backbone.Model.');
            } else throw new Error('The Collection decorator object does not contain a model field.');
            if(!_.isUndefined(collectionObject.comparator)) {
                if (_.isString(collectionObject.comparator)) target.prototype.comparator = collectionObject.comparator;
                else throw new Error('The comparator field for the Collection decorator object should be a string.');
            }
        } else throw new Error('The Collection decorator takes a single argument that should be an object.');
    };
}
