import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'contact'
})
class Contact extends BaseModel {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model, // Circular
            passport: BaseModel
        }
    }

    get validation() {
        return {
            firstname: {
                required: true,
                msg: 'Enter a first name.'
            },
            lastname: {
                required: true,
                msg: 'Enter a last name.'
            },
            email: [{
                required: true,
                msg: 'Enter an email.'
            },{
                pattern: 'email',
                msg: 'Please enter a valid email'
            }]
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        //TODO validate relation
        var account = this.account.id;
        return `/api/accounts/${account}/contacts`;
    }


    parse(response, xhr) {
        const parsedResponse = super.parse(response, xhr);
        this.parseRelations(parsedResponse);
        const { firstname, lastname } = parsedResponse;
        parsedResponse.fullname = `${firstname} ${lastname}`;
        return parsedResponse;
    }
}

@Collection({
    key: 'contacts',
    model: Contact,
    comparator: 'fullname'
})
class ContactCollection extends BaseCollection {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model // Circular
        }
    }

    url() {
        this.validateRelations('account');
        let account = this.account.id;
        return `/api/accounts/${account}/contacts`;
    }
}

const PageableCollection = ContactCollection.prototype.PageableCollection();

export {
    Contact as Model,
    ContactCollection as Collection,
    PageableCollection
};
