import { createReducer } from 'redux-create-reducer';
import dotProp from 'dot-prop-immutable';
import {
    createCollectionReducerWithKey
} from 'app/react/state/reducers/higher_order/_index'
import {
    generateCollectionKey
} from 'app/react/entities/events/helpers'
import {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} from './schemas'
import {
    ALL_TYPES,
    ONE_TYPES,
    ALL_UPDATE_SEQUENCE_SUCCESS
} from './actions'

const entityReducer = (state = {}) => state;

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};

const collectionReducer = createCollectionReducerWithKey({
    mapActionToKey: ({ accountId, eventId }) =>
        generateCollectionKey(accountId, eventId),
    types: ALL_TYPES,
});

export const collectionReducers = {
    [COLLECTION_REDUCER_KEY]: collectionReducer
};

export const featureReducer = createReducer({}, {
    [ALL_UPDATE_SEQUENCE_SUCCESS]: (state, action) => {
        const { stageIds } = action;
        return dotProp.set(
            state,
            'entities.stages',
            stages => {
                const stagesAsArray = Object.keys(stages).map((k) => stages[k])
                const stagesWithNewSequence = stagesAsArray.map(stage => {
                    if (stageIds.includes(stage.id)) {
                        const index = stageIds.indexOf(stage.id)
                        return ({
                            ...stage,
                            sequence: index + 1
                        })
                    }
                    return stage
                });
                return stagesWithNewSequence.reduce((state, next) => {
                    state[next.id] = next;
                    return state
                }, {})
            }
        )
    }
})