import {
    getComponentState
} from 'app/react/state/helpers';
import {
    mergeComponentState,
    setComponentState
} from 'app/react/state/actions/_index';

export const PICKUP_SCHEDULER_ID = 'pickupsScheduler';

const getState = (dayId, state) =>
    getComponentState(state, `${PICKUP_SCHEDULER_ID}${dayId}`) || {};

const setState = (dayId, newState) =>
    setComponentState(`${PICKUP_SCHEDULER_ID}${dayId}`, newState);

const mergeState = (dayId, newState) =>
    mergeComponentState(`${PICKUP_SCHEDULER_ID}${dayId}`, newState);

export const stateHelpers = {
    getState,
};

export const actions = {
    setState,
    mergeState,
};
