import { Model, Collection }  from 'app/backbone/models/decorators';
import * as Day from '../../Day';

@Model({
    key: 'available_day'
})
class AvailableDay extends Day.Model {

    get defaults() {
        return {
            max: 0,
            used: 0,
            available: 0
        }
    }

    get validation() {
        return {
            id: {
                required: true,
                msg: 'Select a day.'
            },
            max: [{
                pattern: 'number',
                msg: 'The maximum field must be a number.'
            },{
                min: 1,
                msg: 'The maximum must be greater than 0.'
            }]
        }
    }

    initialize(model, options = {}) {
       //no op remove after Day Model is refactored
        this.on('change:used', this.resetAvailable);
        this.on('change:max', this.resetAvailable);
    }

    resetAvailable() {
        this.set('available', this.get('max') - this.get('used'));
    }

    parse(response) {
        response = super.parse(response);
        let parsed = {};
        parsed.id = response.id;
        parsed.name = response.name;
        parsed.max = response.max ? response.max : 0;
        parsed.used = response.used ? response.used : 0;
        parsed.available = response.max - response.used;
        return parsed;
    }

    toJSON(options) {
        let json = {};
        json.id = this.get('id');
        json.name = this.get('name');
        if (this.get('max') !== 0) json.max = this.get('max');
        return json;
    }
}

@Collection({
    key: 'available_days',
    model: AvailableDay
})
class AvailableDayCollection extends Day.Collection {

    incrementUsedOn(day, amount = 1){
        let availableDay = this.get(day.id);
        if(availableDay) availableDay.set('used', availableDay.get('used') + amount)
    }

    decrementUsedOn(day, amount = 1){
        let availableDay = this.get(day.id);
        if(availableDay) availableDay.set('used', availableDay.get('used') - amount)
    }

    getTotalAvailable() {
        return this.getTotal('available');
    }

    getTotalMax() {
        return this.getTotal('max');
    }

    getTotalUsed() {
        return this.getTotal('used');
    }

    getTotal(attr) {
        let total = 0;
        this.each(day => { total += day.get(attr) });
        return total;
    }
}

const PageableCollection = AvailableDayCollection.prototype.PageableCollection();

export {
    AvailableDay as Model,
    AvailableDayCollection as Collection,
    PageableCollection
};
