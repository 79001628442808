import React from 'react';
import PropTypes from 'prop-types';

const Title = ({
    children,
    subTitle,
}) => (
    <div className="content--header_title">
        <h2>{children}</h2>
        {subTitle && (
            <h4>{subTitle}</h4>
        )}
    </div>
);

Title.defaultProps = {
    subTitle: undefined,
};

Title.propTypes = {
    children: PropTypes.node,
};

const TitleDivider = () => (
    <span className="content--header_divider" />
);

const SubTitle = ({ children }) => (
    <div className="content--header_sub-title">
        <h4>{children}</h4>
    </div>
);

SubTitle.propTypes = {
    children: PropTypes.node,
};

const Actions = ({ children }) => (
    <div className="content--header_actions-menu">
        <div className="button-bar right">
            {children}
        </div>
    </div>
);

Actions.propTypes = {
    children: PropTypes.node,
};

const TitleMenu = ({ children }) => (
    <div className="content--header_title-menu-container">
        {children}
    </div>
);

TitleMenu.propTypes = {
    children: PropTypes.node,
};

TitleMenu.Title = Title;
TitleMenu.SubTitle = SubTitle;
TitleMenu.TitleDivider = TitleDivider;
TitleMenu.Actions = Actions;

export default TitleMenu;
