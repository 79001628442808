import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import DialogContainer from "../dialogContainer/DialogContainer";
import DialogCard from "../dialogCard/DialogCard";
import DialogCardBottom from "../dialogCard/DialogBottom";
import FormField from "../formfields/FormField";
import Button from "../button/Button";
import DatePickerField from "../datePicker/DatePickerField";

const AddingDaysValidation = () =>
  yup.object({
    name: yup.string().required(),
    start: yup.date().required(),
    end: yup
      .date()
      .min(yup.ref("start"), "End date can't be before start date")
      .required(),
  });

const AddingDaysDialog = ({
  addDay,
  day,
  dateFormat,
  timeFormat,
  ...props
}) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const dayOfMonth = new Date().getDate();

  return (
    <DialogContainer>
      <DialogCard {...props}>
        <Formik
          initialValues={{
            name: day.name,
            start:
              day && day.start
                ? new Date(day.start)
                : new Date(year, month, dayOfMonth, 13, 0, 0),
            end:
              day && day.end
                ? new Date(day.end)
                : new Date(year, month, dayOfMonth, 23, 45, 0),
          }}
          validationSchema={AddingDaysValidation()}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            const { isValid, values, setFieldValue, handleChange, errors } =
              formik;

            return (
              <Fragment>
                <div className={"dialog-inner-container"}>
                  <h3 className="dialog-title">
                    {day.id ? "Update day" : "Add day"}
                  </h3>
                  <Form>
                    <Field name="name" label="Name" required as={FormField} />
                    <div className="adding-days-dialog__input-line">
                      <DatePickerField
                        value={values.start}
                        name="start"
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          if (new Date(values.end) < new Date(value)) {
                            const year = new Date(value).getFullYear();
                            const month = new Date(value).getMonth();
                            const dayOfMonth = new Date(value).getDate();
                            const newEnd = new Date(
                              year,
                              month,
                              dayOfMonth,
                              23,
                              45,
                              0
                            );
                            setFieldValue("end", newEnd);
                          }
                        }}
                        showPopperArrow={false}
                        label="Start date and time"
                        dateFormat={dateFormat}
                        required
                      />
                      <DatePickerField
                        value={values.start}
                        name="start"
                        onChange={setFieldValue}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        timeFormat={timeFormat}
                        dateFormat={timeFormat}
                        showPopperArrow={false}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "25px",
                      }}
                    >
                      <div
                        className="adding-days-dialog__input-line"
                        style={{ marginBottom: "unset" }}
                      >
                        <DatePickerField
                          value={values.end}
                          name="end"
                          onChange={setFieldValue}
                          showPopperArrow={false}
                          label="End date and time"
                          dateFormat={dateFormat}
                          required
                        />
                        <DatePickerField
                          value={values.end}
                          name="end"
                          onChange={setFieldValue}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                          timeFormat={timeFormat}
                          dateFormat={timeFormat}
                          showPopperArrow={false}
                        />
                      </div>
                      {errors && errors.end && (
                        <p style={{ color: "#df4f4f" }}>{errors.end}</p>
                      )}
                    </div>
                  </Form>
                  <DialogCardBottom>
                    <Button
                      label={day.id ? "Update" : "Add"}
                      variant="primary"
                      type="button"
                      onClick={() => addDay({ ...values, id: day.id })}
                      isDisabled={!isValid}
                    />
                  </DialogCardBottom>
                </div>
              </Fragment>
            );
          }}
        </Formik>
      </DialogCard>
    </DialogContainer>
  );
};

AddingDaysDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
  addDay: PropTypes.func.isRequired,
  day: PropTypes.any.isRequired,
};

export default AddingDaysDialog;
