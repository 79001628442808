import { schema } from 'normalizr';
import * as FormField from './form_fields/schemas';

export const ENTITY_REDUCER_KEY = 'pwArtistSettingsSections';
export const ENTITY_CONTEXT_ID_KEY = 'pwArtistSettingsSectionId';

const entityDefinition = { formFields: FormField.entities };
const entityOptions = { idAttribute: 'id' };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);
