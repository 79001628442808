import { pick, toLower } from 'lodash';
import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import * as actions from 'app/react/state/actions/_index';
import * as Reservation from 'app/react/entities/events/reservations/index';
import { isAwaitingApprovalStatus, isApprovedStatus, isDeniedStatus } from "app/react/helpers/reservations";
import * as State from './ModalState';
import ReviewStatusModal from './ReviewStatusModal';

const SUCCESSFUL_POPUP = (statusName) => `Reservation successfully ${toLower(statusName)}.`;
const FAILED_POPUP = (statusName) => `Reservation could not be ${toLower(statusName)}.`;

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSyncing,
        reservation,
        errors = {}
    } = State.getReviewModalState(state);
    return {
        isOpened,
        isSyncing,
        reservation,
        errors
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSaved: () => {
        const { reservation } = stateProps;
        dispatchProps.onSaved(reservation);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onChangeValue: (key, value) => {
        dispatch(State.setReviewModalStateValue(`reservation.status.${key}`, value));
    },
    onClosed: () => {
        dispatch((State.mergeReviewModalState({
            isOpened: false,
            isSyncing: false
        })));
        dispatch((State.deleteReviewModalStateValue('errors')));
    },
    onSaved: (reservation) => {
        dispatch((State.setReviewModalStateValue('isSyncing', true)));

        const { id, status } = reservation;
        let apiCall;

        if (isAwaitingApprovalStatus(status.id)) {
            apiCall = Reservation.thunks.awaitApprovalForOneForCurrentContext(id, ['status']);
        } else if (isApprovedStatus(status.id)) {
            apiCall = Reservation.thunks.approveOneForCurrentContext(id, ['status']);
        } else if (isDeniedStatus(status.id)) {
            apiCall = Reservation.thunks.denyOneForCurrentContext(id, {reason: status.reason}, ['status']);
        }

        dispatch(apiCall)
            .then(({ errors }) => {
                if (typeof errors !== 'undefined') {
                    dispatch(actions.showErrorPopup(FAILED_POPUP(status.name)));
                    dispatch(State.mergeReviewModalState({
                        isSyncing: false,
                        errors
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP(status.name)));
                    dispatch(State.mergeReviewModalState({
                        isOpened: false,
                        isSyncing: false
                    }));
                }
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ReviewStatusModal);
