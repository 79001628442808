import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Venue from 'app/backbone/models/account/Venue';
import * as TimeZone from 'app/backbone/models/time_zone/TimeZone';
import CreateEventContentHeader from 'app/react/views/account/layout/CreateEventContentHeader';
import CreateEventContainer from 'app/react/views/event/create-event/views/CreateEventContainer';
import EventsOverview from 'app/backbone/views/account/events/views/EventsOverview.view';
import EventsArchive from 'app/backbone/views/account/events/views/EventsArchive.view';
import * as Routes from './routes'

// Backbone Controller
export default class EventController extends BaseController {

    constructor(options) {
        super(options);
        this.router.route(Routes.events(), null, this.events.bind(this));
        this.router.route(Routes.eventsArchive(), null, this.archive.bind(this));
        this.router.route(Routes.eventsCreateMultiDay(), null, this.createMultiDayReact.bind(this));
    }

    renderCreateEventContentHeader() {
        const { app } = this;
        app.renderContentHeader(React.createElement(CreateEventContentHeader));
    }

    registerCommonData() {
        const { data } = this;
        const account = data.registerCurrentAccount();
        const venues = data.register('allVenues', Venue.Collection, { account });
        const timeZones = data.register('allTimeZones', TimeZone.Collection);
        return { account, venues, timeZones };
    }

    events() {
        const { app } = this;
        app.renderContentView(new EventsOverview());
    }

    archive() {
        const { app } = this;
        app.renderContentView(new EventsArchive());
    }

    createMultiDayReact() {
        const { app } = this;
        app.renderSyncingView();
        this.renderCreateEventContentHeader();
        const { account, venues, timeZones } = this.registerCommonData();
        this.when(
            account.fetch(),
            venues.fetch(),
            timeZones.fetch()
        ).done(() => {
            app.renderReactView(React.createElement(CreateEventContainer, {account, venues, timeZones}));
        });
    }

}
