import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import {
    transformRequestBody,
    transformResponseBody,
    transformResponseErrorBody,
    transformGraphQLResponseBody
} from './util'

export const QUERY_TYPE = {
    QUERY: 'QUERY',
    MUTATION: 'MUTATION',
};

export const API_ROOT = '/graphql';

const httpLink = createHttpLink({
    uri: API_ROOT,
});

const authLink = setContext((_, { headers }) => {
    const token = window.beatswitch.auth.jwt;
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
        }
    }
});

export const createApolloClient = () =>  new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export const apolloClient = createApolloClient();

export const getDataFromGraphQLAPI = (query, queryType, variables, schemaMapping) => {
    const options = {
        query,
        variables
    };

    // if (queryType === QUERY_TYPE.MUTATION) {
    //
    // }

    return apolloClient.query(options)
        .then(response => {
            return transformGraphQLResponseBody(response, schemaMapping)
        });
};


