import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import ItemGroupsOverviewCard from 'app/backbone/views/account/settings/accreditation/groups/views/cards/ItemGroupsOverviewCard.view';
import EventItemGroupModalCreate from '../modals/EventItemGroupModalCreate.view';
import EventItemGroupTable from '../tables/EventItemGroupTable.view';

export default class EventItemGroupsOverviewCard extends ItemGroupsOverviewCard {

    optionTypes() {
        return {
            itemGroups: OptionTypes.instanceOf(EventItemGroup.PageableCollection).isRequired
        };
    }

    initCreateModal() {
        const createModal = new EventItemGroupModalCreate();
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    createItemGroup() {
        const event = this.itemGroups.event;
        this.createModal.open(null, { event });
    }

    initTable() {
        const { itemGroups } = this;
        return new EventItemGroupTable({ itemGroups });
    }

}
