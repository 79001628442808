import { Model, Collection }  from 'app/backbone/models/decorators';
import * as Accreditee from '../accreditees/Accreditee';
import * as CompanyAccrediteeProfile from './CompanyAccrediteeProfile';
import * as CompanyAccrediteeItem from './CompanyAccrediteeItem';

@Model({
    key: 'company_accreditee'
})
class CompanyAccreditee extends Accreditee.Model {

    static Relations() {
        return {
            event: Accreditee.Model.Relations().event,
            companies: Accreditee.Model.Relations().companies,
            company: require('./Company').Model, // Circular
            profiles: CompanyAccrediteeProfile.Collection,
            items: CompanyAccrediteeItem.Collection
        }
    }

    urlRoot() {
        if(this.hasRelation('company')) {
            this.validateRelations('company');
            this.company.validateRelations('event');
            let eventId = this.company.event.id;
            let companyId = this.company.id;
            return `/api/events/${eventId}/companies/${companyId}/accreditees`;
        }
        if(this.hasRelation('event')) {
            this.validateRelations('event');
            let event = this.event.id;
            return `/api/events/${event}/companies/accreditees`;
        }
    }

    sendTicket() {
        const eventId = this.getRelation('company').getRelation('event').get('id');
        const accrediteeId = this.id;
        const url = `/api/events/${eventId}/accreditees/${accrediteeId}`;

        return $.post(`${url}/send-ticket`);
    }

    destroy(options = {}) {
        options.url = this.url();
        super.destroy(options)
    }

}

@Collection({
    key: 'company_accreditees',
    model: CompanyAccreditee
})
class CompanyAccrediteeCollection extends Accreditee.Collection {

    static Relations() {
        return {
            company: require('./Company').Model // Circular
        }
    }

    url() {
        if(this.hasRelation('company')) {
            this.company.validateRelations('event');
            let eventId = this.company.event.id;
            let companyId = this.company.id;
            return `/api/events/${eventId}/companies/${companyId}/accreditees`;
        }
        if(this.hasRelation('event')) {
            let event = this.event.id;
            return `/api/events/${event}/companies/accreditees`;
        }

    }
}

const PageableCollection = CompanyAccrediteeCollection.prototype.PageableCollection();

export {
    CompanyAccreditee as Model,
    CompanyAccrediteeCollection as Collection,
    PageableCollection
};
