import React, {useState} from 'react'
import {TableSortCell} from 'ui-kit'
import {connect} from 'react-redux'
import {isEmpty} from 'lodash'
import usePersistedEventState from 'app/react/hooks/usePersistedEventState'
import {mapEventContextToProps} from 'app/react/components/util/connectBeatswitchContext'
import * as Event from 'app/react/entities/events'
import * as TicketType from 'app/react/entities/events/ticket_types'
import * as State from 'app/react/views/event/artists/box-office/views/OverviewState'
import * as BoxOfficeRoutes from 'app/routes/event/artists/box-office/routes'
import * as CrewRoutes from 'app/routes/event/artists/bookings/crewRoutes'
import * as PerformerRoutes from 'app/routes/event/artists/bookings/performerRoutes'
import * as actions from 'app/react/state/actions/_index'
import * as GuestRoutes from 'app/routes/event/artists/bookings/guestsRoutes'
import * as Day from 'app/react/entities/events/days'
import * as FileRoutes from 'app/routes/files/routes'
import {
    ATTENDEE_TYPE_CREW,
    ATTENDEE_TYPE_GUEST,
    ATTENDEE_TYPE_PERFORMER,
} from 'app/react/views/event/artists/box-office/components/TopBar'
import Overview from './Overview'

const mapStateToProps = (state) => ({
    ...mapEventContextToProps(state),
    isFetching: (
        Day.stateHelpers.isFetchingAllForCurrentContext(state) ||
        TicketType.stateHelpers.isFetchingAllForCurrentContext(state)
    ),
    permissions: Event.stateHelpers.getUserPermissions(state),
    days: Day.stateHelpers.getAllForCurrentContext(state),
    ticketTypes: TicketType.stateHelpers.getAllForCurrentContext(state),
});

const mapDispatchToProps = (dispatch) => ({
    onDownloadPassport: (passportUuid) => {
        dispatch(actions.navigate.newTab(FileRoutes.download(passportUuid)));
    },
    onGoToBooking: (bookingId, newTab) => {
        dispatch(State.thunks.goToBooking(bookingId, newTab));
    },
    onEditAttendee: (eventId, bookingId, attendeeId, type, newTab = false) => {
        const redirect = BoxOfficeRoutes.boxOffice(eventId);
        let url = undefined;

        if (type === ATTENDEE_TYPE_PERFORMER) {
            url = PerformerRoutes.update(eventId, bookingId, attendeeId);
        }

        if (type === ATTENDEE_TYPE_CREW) {
            url = CrewRoutes.update(eventId, bookingId, attendeeId);
        }

        if (type === ATTENDEE_TYPE_GUEST) {
            url = GuestRoutes.update(eventId, bookingId, attendeeId);
        }

        if (newTab) {
            dispatch(actions.navigate.newTab(url, { redirect }));
        } else {
            dispatch(actions.navigate.pageReload(url, { redirect }));
        }
    },
});

const OverviewWithState = (props) => {
    const { eventId } = props;
    const [day, setDay] = usePersistedEventState(eventId, 'boxOffice.day', null);
    const [isCheckedIn, setIsCheckedIn] = usePersistedEventState(eventId, 'boxOffice.isCheckedIn', null);
    const [types, setTypes] = usePersistedEventState(eventId, 'boxOffice.types', []);
    const [ticketTypes, setTicketTypes] = usePersistedEventState(eventId, 'boxOffice.ticketTypes', []);
    const [sortKey, setSortKey] = usePersistedEventState(eventId, 'boxOffice.sort', 'artist');
    const [sortOrder, setSortOrder] = usePersistedEventState(eventId, 'boxOffice.sortOrder', TableSortCell.ASC);
    const [search, setSearch] = useState(null);
    const filters = {
        day,
        isCheckedIn,
        types,
        ticketTypes,
        includePerformers: isEmpty(types) || types.includes(ATTENDEE_TYPE_PERFORMER),
        includeCrew: isEmpty(types) || types.includes(ATTENDEE_TYPE_CREW),
        includeGuests: isEmpty(types) || types.includes(ATTENDEE_TYPE_GUEST),
    };

    function setFilterByName(filterName, value) {
        return {
            day: setDay,
            types: setTypes,
            isCheckedIn: setIsCheckedIn,
            ticketTypes: setTicketTypes,
        }[filterName](value);
    }

    function onChangeFilter(filterName, value) {
        setFilterByName(filterName, value);
    }

    function onSort(sortKey, sortOrder) {
        setSortKey(sortKey);
        setSortOrder(sortOrder);
    }

    function onClearSearch() {
        setSearch('');
    }

    return (
        <Overview
            {...props}
            filters={filters}
            onChangeFilter={onChangeFilter}
            sort={`${sortOrder === TableSortCell.DESC ? '-' : ''}${sortKey}`}
            sortKey={sortKey}
            sortOrder={sortOrder}
            onSort={onSort}
            search={search}
            onSearch={setSearch}
            onClearSearch={onClearSearch}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OverviewWithState);
