import { schema } from 'normalizr';
import * as TicketType from 'app/react/entities/events/ticket_types/schemas';

export const ENTITY_REDUCER_KEY = 'guests';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const entityDefinition = {
    ticketType: TicketType.entity
};

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);

entity.define({
    booking: require('app/react/entities/events/bookings/schemas').entity,
});
