import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as User from 'app/backbone/models/account/User';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as EventUser from 'app/backbone/models/event/EventUser';
import GeneralSettingsForm from '../forms/GeneralSettingsForm.view';

export default class EventCompanyGeneralSettingsCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            currentUser: OptionTypes.instanceOf(User.Model).isRequired,
            users: OptionTypes.instanceOf(EventUser.Collection).isRequired,
            title
        };
    }

    initContent() {
        const { company, currentUser, users } = this;
        return new GeneralSettingsForm({ company, currentUser, users });
    }
}
