import { reduce } from 'lodash';

export const toGroupsWithItems = (groups, items) => (
    groups.map(group => ({
        ...group,
        items: items.filter((item) => item.group === group.id)
    }))
);

export const toGroupsWithSubGroups = (groups, parentId) => reduce(groups, (tree, group) => {
    parentId = parentId || null;

    if (group.parent === parentId) {
        const subGroups = toGroupsWithSubGroups(groups, group.id);
        if (subGroups) {
            group.subGroups = subGroups;
        }
        tree.push(group);
    }

    return tree;
}, []);

export const toGroupsWithSubGroupsAndItems = (groups) => reduce(groups, (tree, group) => {
    group.subGroups = toGroupsWithSubGroupsAndItems(group.subGroups || []);
    const hasItems = group.items !== undefined && group.items.length > 0;
    const hasItemsThroughSubGroups = group.subGroups.length > 0;

    if (hasItems || hasItemsThroughSubGroups) {
        tree.push(group);
    }

    return tree;
}, []);

export const whereIsNotChild = (group) => group.parent === null;
