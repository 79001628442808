import React from 'react';
import classNames from 'classnames';
import { Grid } from 'ui-kit';

const { Cell } = Grid;

const Button = ({ text, onClick, className }) => (
    <a className={classNames('button', className)}
       onClick={onClick}>
        {text}
    </a>
);

const CreateButton = ({ onCreate }) => (
    <Button text="Add" className="primary" onClick={onCreate} />
);

const CreateAnotherButton = ({ onCreateAnother }) => (
    <Button text="Add another" className="secondary" onClick={onCreateAnother} />
);

const UpdateButton = ({ onUpdate }) => (
    <Button text="Save" className="primary" onClick={onUpdate} />
);

export const CreateButtonRow = ({ onCreate, onCreateAnother, canCreateAnother }) => (
    <Grid className="grid-cell--justifyEndContent">
        {canCreateAnother && (
            <Cell isAutoSized>
                <CreateAnotherButton onCreateAnother={onCreateAnother} />
            </Cell>
        )}
        <Cell isAutoSized>
            <CreateButton onCreate={onCreate} />
        </Cell>
    </Grid>
);

export const UpdateButtonRow = ({ onUpdate }) => (
    <Grid className="grid-cell--justifyEndContent">
        <Cell isAutoSized>
            <UpdateButton onUpdate={onUpdate} />
        </Cell>
    </Grid>
);

