import { Model, Collection }  from 'app/backbone/models/decorators';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';

@Model({
    key: 'event_item_group'
})
class EventItemGroup extends ItemGroup.Model {

    static Relations() {
        return {
            event: require('../../Event').Model,
            items: require('./EventItem').Collection
        }
    }

    urlRoot() {
        this.validateRelations('event');
        const event = this.getRelation('event');
        return `/api/events/${event.id}/item-groups`;
    }
}

@Collection({
    key: 'event_item_groups',
    model: EventItemGroup
})
class EventItemGroupCollection extends ItemGroup.Collection {

    static Relations() {
        return {
            event: require('../../Event').Model
        }
    }

    url() {
        this.validateRelations('event');
        const event = this.getRelation('event');
        return `/api/events/${event.id}/item-groups`;
    }
}

const PageableCollection = EventItemGroupCollection.prototype.PageableCollection();

export {
    EventItemGroup as Model,
    EventItemGroupCollection as Collection,
    PageableCollection
};
