import React from 'react';
import { connect } from 'react-redux';
import {
    getFormFieldErrorsFromResponseJSON
} from 'app/react/helpers/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import ArtistTypesOverview from './Overview';
import * as Account from 'app/react/entities/accounts/index';
import * as Event from 'app/react/entities/events/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Routes from 'app/routes/event/production_website/artists/form/routes';

export const ARTIST_TYPE_MODAL_ID = 'artistTypeModal';
export const CONFIRM_MODAL_ID = 'artistTypeConfirmModal';
export const ARTIST_TYPE_CARD_ID = 'artistTypesCard';
export const DELETE_CONFIRM_TITLE = 'Delete artist type';
export const DELETE_CONFIRM_TEXT = name => `Are you sure you want to delete <strong>${name}?</strong>`;
export const DELETE_CONFIRM_BUTTON_TEXT = name => `Yes, delete <strong>${name}.</strong>`;
export const POPUP_MESSAGES = {
    FAILED: 'Failed to save artist type.',
    FAILED_TO_DELETE: name => `Failed to delete <strong>${name}</strong>.`,
    DELETED: name => `Artist type <strong>${name}</strong> deleted.`,
    CREATED: name => `Artist type <strong>${name}</strong> created.`,
    UPDATED: name => `Artist type <strong>${name}</strong> updated.`
};

const mapStateToProps = (state) => ({
    isFetching: ArtistType.stateHelpers.isFetchingAllForCurrentContext(state),
    accountId: Account.stateHelpers.getCurrentId(state),
    eventId: Event.stateHelpers.getCurrentId(state),
    artistTypes: ArtistType.stateHelpers.getAllForCurrentContext(state),
    cardState: stateHelpers.getComponentState(state, ARTIST_TYPE_CARD_ID),
    modalState: stateHelpers.getComponentState(state, ARTIST_TYPE_MODAL_ID),
    confirmModalState: stateHelpers.getComponentState(state, CONFIRM_MODAL_ID)
});

const mapDispatchToProps = (dispatch) => {
    const onCardCreate = () => {
        dispatch(actions.mergeComponentState(ARTIST_TYPE_MODAL_ID, { values: {}, isCreating: true, isDuplicating: false }));
        dispatch(actions.openModal(ARTIST_TYPE_MODAL_ID));
    };

    const onTableEdit = (values) => {
        dispatch(actions.mergeComponentState(ARTIST_TYPE_MODAL_ID, { values, isCreating: false, isDuplicating: false }));
        dispatch(actions.openModal(ARTIST_TYPE_MODAL_ID));
    };

    const onTableDuplicate = (values) => {
        dispatch(actions.mergeComponentState(ARTIST_TYPE_MODAL_ID, { values: { id: values.id }, isCreating: false, isDuplicating: true }));
        dispatch(actions.openModal(ARTIST_TYPE_MODAL_ID));
    }

    const onTableDelete = (accountId, eventId, artistTypeId, artistTypeName) => {
        const failedPopup = POPUP_MESSAGES.FAILED_TO_DELETE(artistTypeName);
        const successPopup = POPUP_MESSAGES.DELETED(artistTypeName);
        const action = {
            ...ArtistType.actions.deleteOne(accountId, eventId, artistTypeId),
            successPopup,
            failedPopup
        };
        dispatch(actions.setComponentState(CONFIRM_MODAL_ID, {
            title: DELETE_CONFIRM_TITLE,
            text: DELETE_CONFIRM_TEXT(artistTypeName),
            buttonText: DELETE_CONFIRM_BUTTON_TEXT(artistTypeName)
        }));
        dispatch(actions.openConfirmModal(CONFIRM_MODAL_ID, action));
    };

    const onTableGoToForm = (eventId, artistTypeId) => {
        const url = Routes.form(true, eventId, artistTypeId);
        dispatch(actions.navigate.to(url));
    };

    const onModalChangeValue = (propKey, value) => {
        dispatch(actions.updateFormComponentStateValue(ARTIST_TYPE_MODAL_ID, propKey, value));
    };

    const onModalSave = (eventId, formValues, isCreating, isDuplicating) => {
        dispatch(actions.setModalSyncing(ARTIST_TYPE_MODAL_ID, true));

        const artistType = { ...formValues };
        const artistTypeId = artistType.id;
        if (!isCreating) delete artistType.id;

        const action = isCreating ?
            ArtistType.thunks.createOneForCurrentContext(artistType) :
                isDuplicating ? ArtistType.thunks.duplicateOneForCurrentContext(artistTypeId, artistType) :
                ArtistType.thunks.updateOneForCurrentContext(artistTypeId, artistType);

        dispatch(action).then(({ error }) => {
            if (error) {
                const errors = getFormFieldErrorsFromResponseJSON(error);
                dispatch(actions.mergeComponentState(ARTIST_TYPE_MODAL_ID, { errors }));
                dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED));
            } else {
                dispatch(actions.closeModal(ARTIST_TYPE_MODAL_ID));
                const popupMessage = isCreating || isDuplicating ?
                    POPUP_MESSAGES.CREATED(artistType.name) :
                    POPUP_MESSAGES.UPDATED(artistType.name);
                dispatch(actions.showSuccessPopup(popupMessage));
                dispatch(actions.mergeComponentState(ARTIST_TYPE_MODAL_ID, { errors: {} }));
            }
            dispatch(actions.setModalSyncing(ARTIST_TYPE_MODAL_ID, false));
        });
    };

    const onModalClosed = () => {
        dispatch(actions.closeModal(ARTIST_TYPE_MODAL_ID));
    };

    const onConfirmModalConfirmed = (action) => {
        dispatch(actions.setModalSyncing(CONFIRM_MODAL_ID, true));
        dispatch(action)
            .then(({ isFailure, errorMessage }) => {
                dispatch(actions.setModalSyncing(CONFIRM_MODAL_ID, false));
                dispatch(actions.closeConfirmModal(CONFIRM_MODAL_ID));
                if (isFailure) {
                    const message = `${action.failedPopup} ${errorMessage}`;
                    dispatch(actions.showErrorPopup(message));
                } else {
                    dispatch(actions.showSuccessPopup(action.successPopup));
                }
            });
    };

    const onConfirmModalClosed = () => {
        dispatch(actions.closeModal(CONFIRM_MODAL_ID));
    };

    return {
        onCardCreate,
        onTableGoToForm,
        onTableEdit,
        onTableDuplicate,
        onTableDelete,
        onModalChangeValue,
        onModalSave,
        onModalClosed,
        onConfirmModalClosed,
        onConfirmModalConfirmed
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onTableGoToForm: (artistTypeId) => dispatchProps.onTableGoToForm(stateProps.eventId, artistTypeId),
    onTableDelete: (artistTypeId) => {
        const { artistTypes, accountId, eventId } = stateProps;
        const artistType = artistTypes.find(({ id }) => id === artistTypeId);
        dispatchProps.onTableDelete(accountId, eventId, artistTypeId, artistType.name)
    },
    onModalSave: () => dispatchProps.onModalSave(stateProps.eventId, stateProps.modalState.values, stateProps.modalState.isCreating, stateProps.modalState.isDuplicating),
    onConfirmModalConfirmed: () =>
        dispatchProps.onConfirmModalConfirmed(stateProps.confirmModalState.action)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ArtistTypesOverview);
