import BaseController from 'app/backbone/helpers/BaseController';
import AccreditationController from './accreditation/Controller';
import ArtistsController from './artists/Controller';

export default class AccountController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new AccreditationController({ parent: this }),
            new ArtistsController({ parent: this }),
        ];
    }

}
