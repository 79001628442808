import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';

export const DELETE_MODAL_ID = 'deleteReservationModal';

export const getDeleteModalState = (state) =>
    stateHelpers.getComponentState(state, DELETE_MODAL_ID) || {};

export const mergeDeleteModalState = (newState) =>
    actions.mergeComponentState(DELETE_MODAL_ID, newState);

export const setDeleteModalStateValue = (key, value) =>
    actions.setComponentStateValue(DELETE_MODAL_ID, key, value);

export const mergeDeleteModalStateValue = (key, newState) =>
    actions.mergeComponentStateValue(DELETE_MODAL_ID, key, newState);

export const deleteDeleteModalStateValue = (key) =>
    actions.deleteComponentStateValue(DELETE_MODAL_ID, key);

export const REVIEW_STATUS_MODAL_ID = 'reviewStatusModal';

export const getReviewModalState = (state) =>
    stateHelpers.getComponentState(state, REVIEW_STATUS_MODAL_ID) || {};

export const mergeReviewModalState = (newState) =>
    actions.mergeComponentState(REVIEW_STATUS_MODAL_ID, newState);

export const setReviewModalStateValue = (key, value) =>
    actions.setComponentStateValue(REVIEW_STATUS_MODAL_ID, key, value);

export const mergeReviewModalStateValue = (key, newState) =>
    actions.mergeComponentStateValue(REVIEW_STATUS_MODAL_ID, key, newState);

export const deleteReviewModalStateValue = (key) =>
    actions.deleteComponentStateValue(REVIEW_STATUS_MODAL_ID, key);
