import { oneLineTrim } from 'common-tags';
import { includeParam } from 'app/react/state/middleware/api'

const rootAll = (eventId, bookingId) =>
    `events/${eventId}/bookings/${bookingId}/crew`;
const rootOne = (eventId, bookingId, crewId) =>
    `${rootAll(eventId, bookingId)}/${crewId}`;

export const createOne = (
    eventId,
    bookingId
) => oneLineTrim`
    ${rootAll(eventId, bookingId)}
`;

export const fetchOne = (
    eventId,
    bookingId,
    crewId,
    includes
) => oneLineTrim`
    ${rootOne(eventId, bookingId, crewId)}?
    ${includeParam(includes)}
`;

export const updateOne = (
    eventId,
    bookingId,
    crewId
) => oneLineTrim`
    ${rootOne(eventId, bookingId, crewId)}
`;

export const deleteOne = (
    eventId,
    bookingId,
    crewId
) => oneLineTrim`
    ${rootOne(eventId, bookingId, crewId)}
`;
