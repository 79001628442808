import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import PerformersController from './PerformersController';
import CrewController from './CrewController';

export default class ArtistsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new PerformersController({ parent: this }),
            new CrewController({ parent: this })
        ];
    }

}
