import React  from 'react';
import styled from 'styled-components';

const TEXT = 'No matching records found.';
const COL_SPAN = 100;

const StyledCell = styled.td`
    text-align: center !important;
    padding: 1rem .5rem !important;
`;

const NoRecordsRow = ({
    showText = true,
    text = TEXT,
    colSpan = COL_SPAN
}) => (
    <tr>
        <StyledCell colSpan={colSpan}>
            {showText && text}
        </StyledCell>
    </tr>
);

export default NoRecordsRow;
