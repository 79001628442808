import React from 'react';
import CreateEvent from './CreateEvent';
import { useEventState } from '../hooks/UseEventState';

const CreateEventContainer = (props) => {
	const { eventState, setActiveStep, setVenues } = useEventState(props);

	return (
		<CreateEvent
			eventState={eventState}
			setActiveStep={setActiveStep}
			setVenues={setVenues}
		/>
	);
};

CreateEventContainer.propTypes = {};

export default CreateEventContainer;
