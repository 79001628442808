import Backgrid from 'backgrid';
import { html } from 'common-tags';

export default class CheckCell extends Backgrid.Cell {

    className() {
        return 'check-cell';
    }

    render() {
        this.$el.html(this.template());
        return this.delegateEvents();
    }

    template() {
        if (this.isVisible() && this.isChecked()) {
            return html`<i class="bs-icon-check"></i>`;
        }
    }

    isChecked() {
        return this.checked || true;
    }

    isVisible() {
        return this.model.get(this.column.get('name'));
    }

}
