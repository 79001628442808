import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/button/Button';
import CancelCreateDialog from '../atoms/dialogs/CancelCreateDialog';
import propTypes from 'prop-types';

const Footer = ({isNextDisabled = false, setActiveStep, activeSubStep}) => {
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const onConfirmCancel = () => {
        navigate('/events', {pageReload: true})
    }

    return(
        <Fragment>
            <div className="event-footer">
                <Button label="Cancel" variant="cancel" onClick={() => setCancelDialogOpen(true)} />
                {activeSubStep > 0 && <Button label="Previous" variant="primary" onClick={() => setActiveStep(activeSubStep - 1)} />}
                <Button type="submit" isDisabled={isNextDisabled} label="Next" variant="primary" onClick={() => setActiveStep(activeSubStep + 1)} />
            </div>
            {cancelDialogOpen && <CancelCreateDialog setCancelDialogOpen={setCancelDialogOpen} cancelCreate={onConfirmCancel}></CancelCreateDialog>}
        </Fragment>
    )
};

Footer.propTypes = {
    isNextDisabled: propTypes.bool,
	setActiveStep: PropTypes.func,
    activeSubStep: PropTypes.number,
};

export default Footer;
