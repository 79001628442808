import _ from 'underscore'
import Backbone from 'backbone'
import './Modal.css'

const Modal = Backbone.View.extend({

    events: {
        'click .modal-close': 'hide',
        'click .cancel': 'hide',
        'closed.fndtn.reveal': 'onHidden',
        'opened.fndtn.reveal': 'onShown'
    },

    attributes: {
        'class': 'reveal-modal',
        'data-reveal': ''
    },

    options: [
        { 'animation': 'fadeAndPop' },
        { 'animation_speed': 250 },
        { 'close_on_background_click': false },
        { 'close_on_esc': true },
        { 'multiple_opened': true },
        { 'root_element': '#modal-container' },
        'width',
        'height'
    ],

    initialize(options) {
        this.setOptions(options);

        if (this.width) this.$el.css('width', this.width + 'px');
        if (this.height) this.$el.css('height', this.height + 'px');

        this.$el.foundation({
            reveal: {
                animation: this.animation,
                animation_speed: this.animation_speed,
                close_on_background_click: this.close_on_background_click,
                close_on_esc: this.close_on_esc,
                dismiss_modal_class: this.dismiss_modal_class,
                multiple_opened: this.multiple_opened,
                root_element: this.root_element
            }
        });
    },

    render() {
        if (this.width) this.$el.css('width', this.width + 'px');
        else this.width = this.$el.width();
    },

    show() {
        this.$el.foundation('reveal', 'open');
    },

    onShown() {
        if (!this.height) this.height = this.$el.height();
        this.trigger('shown');
    },

    hide() {
        this.$el.foundation('reveal', 'close');
    },

    onHidden() {
        this.trigger('hidden');
    }
});

Modal.extend = function(child) {
    var view = Backbone.View.extend.apply(this, arguments);
    view.prototype.events = _.extend({}, this.prototype.events, child.events);
    view.prototype.attributes = _.extend({}, this.prototype.attributes, child.attributes);
    view.prototype.options = _.union(this.prototype.options, child.options);
    return view;
};

module.exports = Modal;
