import * as Schemas from './schemas'
import {
    getCurrentContextId,
    getCurrentContextEntity
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    ENTITY_CONTEXT_ID_KEY,
} = Schemas;

export const getCurrentId = state =>
    getCurrentContextId(state, ENTITY_CONTEXT_ID_KEY);

export const getCurrent = state =>
    getCurrentContextEntity(state, ENTITY_REDUCER_KEY, ENTITY_CONTEXT_ID_KEY);

export const getCurrentDateTimeFormatObject = (state) => {
    const { dateTimeFormat = { date: {}, time: {} } } = getCurrent(state) || {};
    return dateTimeFormat;
};

export const getCurrentDateFormat = state =>
    getCurrentDateTimeFormatObject(state).date.momentjs;

export const getCurrentTimeFormat = state =>
    getCurrentDateTimeFormatObject(state).time.momentjs;

export const getCurrentCurrency = (state) => {
    const { currency } = getCurrent(state) || {};
    return currency;
};

export const getUserPermissions = (state) => {
    const { permissions = {} } = getCurrent(state) || {};
    return permissions;
};

export const isArtistTypesEnabledForCurrent = (state) => {
    const { artistTypesEnabled = true } = getCurrent(state) || {};
    return artistTypesEnabled;
};

export const isAccreditationEnabledForCurrent = (state) => {
    const { accreditationEnabled = true } = getCurrent(state) || {};
    return accreditationEnabled;
};

export const generateCollectionKey = (accountId) =>
    accountId;
