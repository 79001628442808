import * as ArtistSettings from '../helpers';
import * as Schemas from './schemas'
import {
    getCurrentContextId,
    getSomeEntities,
    getOneEntity
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    ENTITY_CONTEXT_ID_KEY
} = Schemas;

export const getOne = (state, uuid) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, uuid);

export const getSome = (state, uuids) =>
    getSomeEntities(state, ENTITY_REDUCER_KEY, uuids);

export const getCurrentId = state =>
    getCurrentContextId(state, ENTITY_CONTEXT_ID_KEY);

export const getCurrent = (state) => {
    const { sections } = ArtistSettings.getCurrent(state) || {};
    if (sections) {
        const initResult = undefined;
        return sections.reduce((result, uuid) => {
            if (result !== initResult) return result;
            const section = getOne(state, uuid);
            if (section.id === getCurrentId(state)) {
                return section;
            }
            return initResult;
        }, initResult);
    }
};

export const isFetchingCurrent = state => {
    const section = getCurrent(state) || {};
    return section.isFetching
};

