import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select vehicle...';
export const NO_RESULTS_PLACEHOLDER = 'No vehicle found.';

const toOption = ({
    id,
    make,
    model,
    licencePlate,
    numberOfPassengers,
    amountOfPickups,
}) => ({
    value: id,
    label: `${make} ${model} ${licencePlate} ${numberOfPassengers}`,
    model,
    make,
    licencePlate,
    numberOfPassengers,
    amountOfPickups,
});

const VehicleField = styled.span`
    margin-right: 15px;
`;

const VehicleOption = ({
    option = {},
}) => (
    <div>
        <VehicleField>{option.make}</VehicleField>
        <VehicleField>{option.model}</VehicleField>
        <VehicleField>{option.licencePlate}</VehicleField>
        <VehicleField>
            <strong>{option.numberOfPassengers}</strong> pax.
        </VehicleField>
    </div>
);

const SelectVehicle = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    clearable = false,
    value,
    vehicles = [],
    onChange = () => {},
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        isClearable={clearable}
        options={vehicles.map(toOption)}
        onChange={onChange}
        renderOption={option => <VehicleOption option={option}/>}
    />
);

SelectVehicle.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    vehicles: PropTypes.array,
    onChange: PropTypes.func,
};

export default SelectVehicle;
