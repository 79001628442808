import { Model, Collection }  from 'app/backbone/models/decorators';
import * as AccrediteeItem from '../accreditees/AccrediteeItem';
import * as CompanyAccrediteeProfile from './CompanyAccrediteeProfile';

@Model({
    key: 'company_accreditee_item'
})
class CompanyAccrediteeItem extends AccrediteeItem.Model {

    static Relations() {
        return {
            item_group: AccrediteeItem.Model.Relations().item_group,
            granted_days: AccrediteeItem.Model.Relations().granted_days,
            granted_profiles: CompanyAccrediteeProfile.Collection
        }
    }

}

@Collection({
    key: 'company_accreditee_items',
    model: CompanyAccrediteeItem
})
class CompanyAccrediteeItemCollection extends AccrediteeItem.Collection {

    static Relations() {
        return {
            accreditee: require('./CompanyAccreditee').Model // Circular
        }
    }

}

const PageableCollection = CompanyAccrediteeItemCollection.prototype.PageableCollection();

export {
    CompanyAccrediteeItem as Model,
    CompanyAccrediteeItemCollection as Collection,
    PageableCollection
};
