import React  from 'react';

const SAVING_NEW_TEXT = 'Saving booking...';

const BookingHeaderRow = () => (
    <tr style={{ height: '49px' }}>
        <td colSpan="6" style={{ textAlign: 'center' }}>
            {SAVING_NEW_TEXT}
        </td>
    </tr>
);

export default BookingHeaderRow;
