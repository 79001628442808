import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'base_stage'
})
class BaseStage extends BaseModel {

    static Relations() {
        return {}
    }

    get validation() {
        return {}
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

}

@Collection({
    key: 'base_stages',
    model: BaseStage
})
class BaseStageCollection extends BaseCollection {

    static Relations() {
        return {}
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.on('add', (model, collection) => {
            if (!model.get('sequence')) model.set('sequence', collection.indexOf(model) + 1);
        });
    }

    comparator(model) {
        return model.get('sequence');
    }
}

const PageableCollection = BaseStageCollection.prototype.PageableCollection();

export {
    BaseStage as Model,
    BaseStageCollection as Collection,
    PageableCollection
};
