import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    pageParam,
    filterParam,
    limitParam as pageSizeParam,
    searchParam,
    sortParam
} from 'app/react/state/middleware/api'

const rootAll = (eventId) => `events/${eventId}/attendees`;
const rootOne = (eventId, attendeeId) => `events/${eventId}/attendees/${attendeeId}`;

export const updateOne = (
    eventId,
    attendeeId
) => oneLineTrim`
    ${rootOne(eventId, attendeeId)}
`;

export const deleteOne = (
    eventId,
    attendeeId
) => oneLineTrim`
    ${rootOne(eventId, attendeeId)}
`;

export const fetchPaginated = (
    eventId,
    page = 1,
    pageSize = 100,
    filters = {},
    search = '',
    sortKey = '',
    sortOrder = 1,
    includes = []
) => oneLineTrim`
    ${rootAll(eventId)}?
    ${pageParam(page)}&
    ${pageSizeParam(pageSize)}&
    ${filterParam(filters)}&
    ${searchParam(search)}&
    ${sortParam(sortKey, sortOrder)}&
    ${includeParam(includes)}
`;
