import { html } from 'common-tags';
import SubCollectionCell from './SubCollectionCell.view';

export default class SubCollectionFirstCell extends SubCollectionCell {

    template(vm) {
        const { hasLabel, labelClassName } = vm.view;
        return html`
            <span class="margin-small-right ${hasLabel ? 'label' : ''} ${labelClassName || ''}">
                ${vm.collection[0] || ''}
            </span>
        `;
    }



}
