import React, { Fragment } from 'react'
import {
    Avatar,
    Button,
    Drawer,
    Grid,
    Summary,
} from 'ui-kit'
import { isEmpty } from 'lodash'
import { Duration } from 'luxon'
import moment from 'moment'
import { nl2br } from 'app/react/helpers/_index'

const {
    Item,
    Group,
    List,
} = Summary;
const { Cell } = Grid;

const DetailDrawer = ({
    pickup,
    dateFormatShort,
    timeFormat,
    onClickEdit,
    onClickDelete,
    renderTrigger,
}) => {
    const {
        booking,
        mode = {},
        driverLeaveTime,
        departure,
        arrival,
        passengers = [],
        passengerAmount,
        driver,
        vehicle,
        notes,
        distance,
        duration,
        meetingPoint,
    } = pickup;
    const { artist } = booking;
    const formattedLeaveTime = driverLeaveTime
        ? moment.utc(driverLeaveTime, moment.ISO_8601).format(`${dateFormatShort} ${timeFormat}`)
        : null;
    const formattedDeparture = moment.utc(departure, moment.ISO_8601).format(`${dateFormatShort} ${timeFormat}`);
    const formattedArrival = moment.utc(arrival, moment.ISO_8601).format(`${dateFormatShort} ${timeFormat}`);
    const formattedDuration = Duration.fromObject({ minutes: duration }).toObject();

    return (
        <Drawer
            renderTrigger={renderTrigger}
            renderTitle={() => (
                <Grid hasGutters={false}>
                    <Cell isAutoSized>
                        <Avatar
                            size={4.6}
                            name={artist.name}
                            imageUrl={artist.image}
                        />
                    </Cell>
                    <Cell className="margin-tiny-left">
                        <h5>{pickup.departureLocationName} - {pickup.arrivalLocationName}</h5>
                        <span className="subTitle">{mode.name} pickup</span>
                    </Cell>
                </Grid>
            )}
            renderFooter={() => (
                <Fragment>
                    <Button
                        type={Button.SECONDARY}
                        onClick={onClickDelete}
                    >
                        Remove
                    </Button>
                    <Button onClick={onClickEdit}>
                        Edit
                    </Button>
                </Fragment>
            )}
        >
            <Summary>
                <Group>
                    {formattedLeaveTime && (formattedLeaveTime !== formattedDeparture) && (
                        <Item
                            isLarge
                            renderLabel={() => 'Leave time'}
                        >
                            {formattedLeaveTime}
                        </Item>
                    )}
                    <Item
                        isLarge
                        renderLabel={() => 'Pickup'}
                    >
                        {formattedDeparture}
                    </Item>
                    <Item
                        isLarge
                        renderLabel={() => 'Arrival'}
                    >
                        {formattedArrival}
                    </Item>
                    <Item
                        isLarge
                        renderLabel={() => 'Passengers'}
                    >
                        <i className="bs-icon-user" />
                        {passengerAmount}
                    </Item>
                </Group>
                {driver && (
                    <Item renderLabel={() => 'Driver'}>
                        {driver.firstname} {driver.lastname}
                    </Item>
                )}
                {vehicle && (
                    <Item renderLabel={() => 'Vehicle'}>
                        {vehicle.make} {vehicle.model}
                    </Item>
                )}
                {distance && (
                    <Item renderLabel={() => 'Distance'}>
                        {distance} km
                    </Item>
                )}
                {duration !== 0 && (
                    <Item renderLabel={() => 'Duration'}>
                        {formattedDuration.hours || 0}h{formattedDuration.minutes}
                    </Item>
                )}
                {meetingPoint && (
                    <Item renderLabel={() => 'Meeting point'}>
                        {meetingPoint}
                    </Item>
                )}
                {notes && (
                    <Item renderLabel={() => 'Notes'}>
                        {nl2br(notes)}
                    </Item>
                )}
                {!isEmpty(passengers) && (
                    <List>
                        {passengers.map(({ id, firstname, lastname }, key) => (
                            <Item
                                key={id}
                                renderLabel={() => key === 0 ? 'Passengers' : ''}
                            >
                                {firstname} {lastname}
                            </Item>
                        ))}
                    </List>
                )}
            </Summary>
        </Drawer>
    );
};

DetailDrawer.defaultProps = {
    pickup: {},
};

export default DetailDrawer;
