import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';

const { TitleMenu } = Header;
const { Title, TitleDivider, SubTitle, Actions  } = TitleMenu;

const TITLE = 'Bookings';
const BUTTON_TEXT = 'Booking';

const DetailsSubEntityContentHeader = ({
    title = '',
    bookingName,
    backButtonText = BUTTON_TEXT,
    onClickBack = () => {}
}) => (
    <Header>
        <TitleMenu>
            <Title subTitle={typeof bookingName !== 'undefined' ? bookingName : '...'}>
                {TITLE}
            </Title>
            <Actions>
                <a className="button primary" onClick={onClickBack}>
                    {backButtonText}
                </a>
            </Actions>
        </TitleMenu>
    </Header>
);

DetailsSubEntityContentHeader.propTypes = {
    title: PropTypes.string,
    bookingName: PropTypes.string,
    onClickBack: PropTypes.func
};

export default DetailsSubEntityContentHeader;
