import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({modNoMargin, className, ...props}) => (
	<label
		className={classNames([
			'checkbox__label',
			modNoMargin ? 'checkbox__label--no-margin' : '',
			className
		])}
		htmlFor={props.id}
	>
		<input type='checkbox' checked={props.value} {...props}></input>
		{props.label || ''}
	</label>
);

Checkbox.propTypes = {
	disabled: PropTypes.bool,
	label: PropTypes.string,
};

export default Checkbox;
