export const mobileApp = (
    eventId = ':event'
) => `events/${eventId}/marketing/mobile-app`;

export const timetableWebEmbed = (
    eventId = ':event'
) => `events/${eventId}/marketing/timetable-web-embed`;

export const playlistGenerator = (
    eventId = ':event'
) => `events/${eventId}/marketing/playlist-generator`;
