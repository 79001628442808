import React from 'react'
import { Badge , Tooltip } from 'ui-kit'

const TOOLTIP = 'This is an early stage feature that does not contain all of its planned features yet and may be unstable.';

const BetaBadge = () => (
    <Tooltip renderContent={() => TOOLTIP}>
        <span style={{ marginLeft: '.6rem' }}>
            <Badge type={Badge.WARNING}>beta</Badge>
        </span>
    </Tooltip>
);

export default BetaBadge;
