import _ from 'underscore';
import BaseView from 'app/backbone/components/Base.view';

export default class CreateRoomTypeView extends BaseView {

    ui() {
        return {
            roomTypeForm: '#room-type-form',
            roomTypeFromCRM: '#room-type-form select[name=room_type]',
            crmAlert: '#crm-alert',
            addToCRM: '.add-to-crm',
        };
    }

    events() {
        return {
            'change roomTypeFromCRM': 'toggleFieldsManagedThroughCRM',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.ui.roomTypeFromCRM.trigger('change');
        return this.delegateEvents();
    }

    toggleFieldsManagedThroughCRM(e) {
        const shouldImportRoomTypeFromCRM = e.target.value !== '',
            fieldsManagedByCRM = ['name', 'currency'],
            fieldsManagedByEvent = ['price'],
            values = this.ui.roomTypeFromCRM.find(':selected').data();

        _.each(fieldsManagedByCRM.concat(fieldsManagedByEvent), fieldName => {
            this.ui.roomTypeForm.find(`[name="${fieldName}"]`)
                .val(values[fieldName])
                .prop('disabled', shouldImportRoomTypeFromCRM && fieldsManagedByCRM.includes(fieldName));
        });

        this.ui.crmAlert.css('display', shouldImportRoomTypeFromCRM ? '' : 'none');
        this.ui.addToCRM.css('display', shouldImportRoomTypeFromCRM ? 'none' : '');
    }

}
