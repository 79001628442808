import React from 'react'
import {
    SearchSelect,
    Avatar,
} from 'ui-kit'
import { getUserName } from 'app/react/helpers/users'
import './SearchSelectUsers.module.css'

const toOption = ({
    id,
    firstName,
    lastName,
    email,
    avatarUrl,
}) => ({
    value: parseInt(id),
    label: getUserName({
        firstname: firstName,
        lastname: lastName,
        primaryEmailAddress: email
    }),
    email,
    avatarUrl,
});

const Option = ({
    label,
    avatarUrl,
}) => (
    <div styleName="option">
        <div styleName="avatarContainer">
            <Avatar
                size={2.5}
                name={label}
                imageUrl={avatarUrl}
            />
        </div>
        <div styleName="nameContainer">
            {label}
        </div>
    </div>
);

const SearchSelectUsers = (props) => (
    <SearchSelect
        {...props}
        isClearable
        placeholder="All advancing users"
        options={props.users.map(toOption)}
        renderOption={(option) => (
            <Option {...option} />
        )}
    />
);

export default SearchSelectUsers;
