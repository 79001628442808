import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TableCell, TableActionsDropdown, TableRow } from 'ui-kit'
import { Tooltip, Checkbox } from 'app/react/components/_index'
import { getGuestsTooltipContent } from "app/react/helpers/reservations"
import ReservationStatusActions from "../ReservationStatusActions"

const TOOLTIP_DELETE = 'Delete reservation';
const TOOLTIP_EDIT = 'Edit reservation';
const TOOLTIP_NOT_CHECKED_IN = (name) => `Check in <strong>${name}</strong>`;
const TOOLTIP_CHECKED_IN = (date) => `Checked in on <strong>${date}</strong>`;
const TOOLTIP_NO_REQUIRED_APPROVAL = 'Approval not required for this artist type';

const ItemNumber = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: var(--fontWeightMedium);
    color: var(--colorNeutral300);

    i {
        margin-right: var(--spacingSmallest);
    }
`;

const Row = ({
    reservation = {},
    isApprovalRequired,
    isApprovalRequiredSomewhere,
    dateFormat,
    timeFormat,
    onClick,
    onClickReviewReservation = () => {},
    onClickEdit = () => {},
    onChangeCheckIn = () => {},
    onClickDelete = () => {},
}) => {
    const {
        name,
        checkedInAt,
        checkIn,
        checkOut,
        guestContactNumber,
        guests = [],
        accommodation = {},
        isCheckedIn,
        isFetching,
    } = reservation;
    const artistName = reservation.booking.artist.name;
    const guestsTooltip = getGuestsTooltipContent(guests, guestContactNumber);
    const checkedInAtDateTime = moment.parseZone(checkedInAt, moment.ISO_8601).format(`${dateFormat} ${timeFormat}`);
    const checkInDate = moment.utc(checkIn, moment.ISO_8601).format(dateFormat);
    const checkInTime = moment.utc(checkIn, moment.ISO_8601).format(timeFormat);
    const checkOutDate = moment.utc(checkOut, moment.ISO_8601).format(dateFormat);
    const checkOutTime = moment.utc(checkOut, moment.ISO_8601).format(timeFormat);
    return (
        <TableRow onClick={onClick}>
            <td className="center">
                <Tooltip content={isCheckedIn ? TOOLTIP_CHECKED_IN(checkedInAtDateTime) : TOOLTIP_NOT_CHECKED_IN(name)}>
                    <Checkbox
                        id={`reservationCheckbox-${reservation.id}`}
                        checked={isCheckedIn}
                        loading={isFetching}
                        onChange={e => {
                            onChangeCheckIn(reservation.id, e.target.checked);
                            e.stopPropagation();
                        }}
                    />
                </Tooltip>
            </td>
            <td>{artistName}</td>
            <td>{name}</td>
            <td className="center">
                <Tooltip content={guestsTooltip}>
                    <ItemNumber>
                        <i className="bs-icon-users"/>
                        {reservation.guestAmount}
                    </ItemNumber>
                </Tooltip>
            </td>
            <td>{accommodation.name}</td>
            <td>{reservation.roomType && reservation.roomType.name || reservation.roomTypeName}</td>
            <td className="center">{reservation.nightsPaidByPromoter}</td>
            <td>{checkInDate} {checkInTime}</td>
            <td>{checkOutDate} {checkOutTime}</td>
            {isApprovalRequiredSomewhere &&
                <td className="center" style={{ overflow: "visible" }}>
                    {isApprovalRequired
                        ? <ReservationStatusActions
                            reservation={reservation}
                            onClickReviewReservation={onClickReviewReservation}
                        />
                        : <Tooltip content={TOOLTIP_NO_REQUIRED_APPROVAL}>
                            <i className="bs-icon-inactive-round" />
                        </Tooltip>
                    }
                </td>
            }
            <TableCell.Actions>
                <TableActionsDropdown>
                    <TableActionsDropdown.Action onClick={e => {
                        onClickEdit(reservation.id, e.ctrlKey || e.metaKey);
                        e.stopPropagation();
                    }}>
                        <i className="bs-icon-pencil" /> {TOOLTIP_EDIT}
                    </TableActionsDropdown.Action>
                    <TableActionsDropdown.Action onClick={e => {
                        onClickDelete(reservation.id, e.ctrlKey || e.metaKey);
                        e.stopPropagation();
                    }}>
                        <i className="bs-icon-cross" /> {TOOLTIP_DELETE}
                    </TableActionsDropdown.Action>
                </TableActionsDropdown>
            </TableCell.Actions>
        </TableRow>
    );
};

Row.propTypes = {
    reservation: PropTypes.object,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    onChangeCheckIn: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func
};

export default Row;
