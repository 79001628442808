import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, notify } from 'ui-kit';
import { reduxStore } from 'app/react/state/_index';
import Router from './router';
import Data from './backbone/data';
import BaseView from 'app/backbone/components/Base.view';
import Sidebar from 'app/backbone/components/layout/navigation/sidebar/Sidebar.view';
import Header from 'app/backbone/components/layout/navigation/header/Header.view';
import DOMDrawerView from 'app/backbone/components/drawers/DOMDrawer.View';
import SupportButton from 'app/backbone/components/buttons/SupportButton.view';
import RedirectConfirmButton from 'app/backbone/components/buttons/RedirectConfirmButton.view';
import RedirectConfirmModal from 'app/backbone/components/modals/RedirectConfirmModal.view';
import SingleSelectize from 'app/backbone/components/inputs/SingleSelectize.view';
import SelectableTable from 'app/backbone/components/tables/SelectableTable.view';
import TooltipView from 'app/backbone/components/tooltips/Tooltip.view';
import UploadedImage from 'app/backbone/components/forms/UploadedImage.view'
import syncingPlaceholderTemplate from 'app/backbone/components/placeholders/SyncingPlaceholder.hbs';
import '../styles/index.css';
import autosize from 'autosize';

export default class App extends BaseView {

    el() {
        return '#app-wrapper';
    }

    ui() {
        return {
            sidebar: '#sidebar',
            header: '#header',
            contentWrapper: '#content-wrapper',
            content: '#content-container:not(.small)',
            contentHeader: '#content-header',
            contentFooter: '#content-footer',
            supportButton: '.js-open-support',
            confirmButton: '.js-confirm-prompt',
            selectableTable: 'table.selectable',
            singleSelectize: '.single-selectize-field',
            tooltip: '[data-tooltip-content]',
            uploadedImage: '.image-with-detail',
        };
    }

    initialize() {
        this.data = new Data();
        this.store = reduxStore;

        this.router = new Router({ app: this });

        this.header = new Header();
        this.sidebar = new Sidebar();
        this.drawer = new DOMDrawerView();

        this.listenTo(this.sidebar, 'collapsed', this.onToggleNavigationCollapse);
        this.listenTo(this.header.titleBar, 'toggleAccounts', this.onToggleSidebarAccounts);
        autosize(document.querySelectorAll('textarea'));
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderFlashNotifications();
        this.assignSubview(this.sidebar, this.ui.sidebar);
        this.assignSubview(this.header, this.ui.header);
        this.assignSubview(this.drawer, this.$el);
        this.initViewsForEachEl(SupportButton, this.ui.supportButton);
        this.initViewsForEachEl(RedirectConfirmButton, this.ui.confirmButton, {
            confirmModal: new RedirectConfirmModal()
        });
        this.initViewsForEachEl(SelectableTable, this.ui.selectableTable);
        this.initViewsForEachEl(SingleSelectize, this.ui.singleSelectize);
        this.initViewsForEachEl(TooltipView, this.ui.tooltip);
        this.initViewsForEachEl(UploadedImage, this.ui.uploadedImage).forEach(view => view.render());
        return this.delegateEvents();
    }

    renderFlashNotifications() {
        const { flashNotifications = {} } = window;

        Object.keys(flashNotifications).forEach((type) => {
            notify(flashNotifications[type], type);
        });
    }

    renderSyncingView() {
        if (this.contentView) this.contentView.remove();
        this.contentView = null;
        this.ui.content.html(syncingPlaceholderTemplate({ text: 'Loading data...' }));
        this.ui.content.addClass('isLoading');
    }

    renderContentView(view) {
        if (this.contentView) this.contentView.remove();
        this.contentView = view;
        this.assignSubview(this.contentView, this.ui.content);
        this.ui.content.removeClass('isLoading');
    }

    renderContentHeader(Component) {
        const node = this.ui.contentHeader[0];
        if (Component && node) {
            if (!(this.contentHeader instanceof Component.type)) {
                ReactDOM.unmountComponentAtNode(node);
            }
            this.contentHeader = ReactDOM.render((
                <ThemeProvider>{Component}</ThemeProvider>
            ), node);
        }
    }

    renderContentComponent(Component) {
        const node = this.ui.contentWrapper[0];
        if (Component && node) {
            if (!(this.contentWrapper instanceof Component.type)) {
                ReactDOM.unmountComponentAtNode(node);
            }
            this.contentWrapper = ReactDOM.render((
                <ThemeProvider>
                    {Component}
                    <aside id="drawers" />
                    <aside id="modals" />
                </ThemeProvider>
            ), node);
        }
    }
    renderReactView(Component) {
        const node = this.ui.contentWrapper[0];
        if (Component && node) {
            if (!(this.contentWrapper instanceof Component.type)) {
                ReactDOM.unmountComponentAtNode(node);
            }
            this.contentWrapper = ReactDOM.render((
                <ThemeProvider>
                    {Component}
                </ThemeProvider>
            ), node);
        }
    }

    onToggleNavigationCollapse(windowIsSmall) {
        this.header.titleBar.toggleCollapse(windowIsSmall);
        this.header.userMenu.toggleCollapse(windowIsSmall);
        this.postCollapsedState(windowIsSmall);
    }

    onToggleSidebarAccounts() {
        this.sidebar.sidebarAccounts.toggle();
    }

    postCollapsedState(windowIsSmall) {
        $.post('/user/toggle-sidebar', windowIsSmall ? 'collapsed' : 'expanded');
    }

}
