var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <p class=\"margin-tiny-bottom\">Which tasks would you like to download?</p>\n    <form>\n        <div class=\"input-group\">\n            <label for=\"days\">Select days</label>\n            <select name=\"days\" />\n        </div>\n        <div class=\"input-group\">\n            <label for=\"stages\">Select stages</label>\n            <select name=\"stages\" />\n        </div>\n        <label for=\"confirmed\">\n            <input type=\"checkbox\" id=\"confirmed\" name=\"confirmed\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.confirmedOnly : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            Only include confirmed bookings\n        </label>\n    </form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.ModalTitle,depth0,{"name":"ModalTitle","hash":{"title":"Download Tasks"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.ModalBody,depth0,{"name":"ModalBody","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div class=\"modalButtonsContainer\">\n    <a class=\"button download submit\">\n        Download\n    </a>\n</div>\n";
},"usePartial":true,"useData":true});