import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import AccreditationSettingsContentHeader from 'app/react/views/event/settings/accreditation/ContentHeader';
import PeriodController from './PeriodController';
import ZoneController from './ZoneController';
import ProfileController from './ProfileController';
import ItemController from './ItemController';
import TicketController from './TicketsController';

export const BASE_NAME = 'events.settings.accreditation';
export const BASE_PATH = 'events/:event/settings/accreditation';

// Backbone Controller
export default class AccreditationEventSettingsController extends BaseController {

    constructor(options) {
        super(options);
        // Route definitions
        this.router.route(`${BASE_PATH}`, `${BASE_NAME}`, this.periodsOverview.bind(this));
        this.router.route(`${BASE_PATH}/periods`, `${BASE_NAME}.periods.overview`, this.periodsOverview.bind(this));
        this.router.route(`${BASE_PATH}/zones`, `${BASE_NAME}.zones.overview`, this.zonesOverview.bind(this));
        this.router.route(`${BASE_PATH}/profiles`, `${BASE_NAME}.profiles.overview`, this.profilesOverview.bind(this));
        this.router.route(`${BASE_PATH}/profiles/create`, `${BASE_NAME}.profiles.create`, this.profilesCreate.bind(this));
        this.router.route(`${BASE_PATH}/profiles/:profile/edit`, `${BASE_NAME}.profiles.edit`, this.profilesEdit.bind(this));
        this.router.route(`${BASE_PATH}/items`, `${BASE_NAME}/items.overview`, this.itemsOverview.bind(this));
        // Sub controllers
        this.periodController = new PeriodController({ parent: this });
        this.zoneController = new ZoneController({ parent: this });
        this.profileController = new ProfileController({ parent: this });
        this.itemController = new ItemController({ parent: this });
        this.ticketController = new TicketController({ parent: this });
    }

    renderContentHeader(eventId, activeTab) {
        const { app } = this;
        app.renderContentHeader(React.createElement(AccreditationSettingsContentHeader, { eventId, activeTab }));
    }

    periodsOverview(eventId) {
        const numberEventId = parseInt(eventId, 10);
        this.app.renderSyncingView();
        this.renderContentHeader(numberEventId, 'periods');
        this.periodController.overview(numberEventId);
    }

    zonesOverview(eventId) {
        const intEventId = parseInt(eventId, 10);
        this.app.renderSyncingView();
        this.renderContentHeader(intEventId, 'zones');
        this.zoneController.overview(intEventId);
    }

    profilesOverview(eventId) {
        const intEventId = parseInt(eventId, 10);
        this.app.renderSyncingView();
        this.renderContentHeader(intEventId, 'profiles');
        this.profileController.overview(intEventId);
    }

    profilesCreate(eventId) {
        const intEventId = parseInt(eventId, 10);
        this.app.renderSyncingView();
        this.renderContentHeader(intEventId, 'profiles');
        this.profileController.create(intEventId);
    }

    profilesEdit(eventId, profileId) {
        const intEventId = parseInt(eventId, 10);
        this.app.renderSyncingView();
        this.renderContentHeader(intEventId, 'profiles');
        this.profileController.edit(intEventId, profileId);
    }

    itemsOverview(eventId) {
        const intEventId = parseInt(eventId, 10);
        this.app.renderSyncingView();
        this.renderContentHeader(intEventId, 'items');
        this.itemController.overview(intEventId);
    }

}
