export const base = (
    eventId = ':event',
    bookingId = ':booking',
) => `events/${eventId}/artists/bookings/${bookingId}/guests`;

export const create = (
    eventId = ':event',
    bookingId = ':booking',
) => `${base(eventId, bookingId)}/create`;

export const update = (
    eventId = ':event',
    bookingId = ':booking',
    guestId = ':guest',
) => `${base(eventId, bookingId)}/${guestId}/edit`;
