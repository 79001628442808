import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';
import * as Routes from 'app/routes/event/artists/logistics/routes';

const { TitleMenu, Tabs } = Header;
const { Title } = TitleMenu;
const { Tab } = Tabs;

const TITLE = 'Logistics';
const TRANSPORTS_TAB = 'Transports';
const PICKUPS_TAB = 'Pickups';
const PICKUPS_SCHEDULER_TAB = 'Pickups scheduler';
const DRIVERS_TAB = 'Drivers';
const VEHICLES_TAB = 'Vehicles';

const LogisticsContentHeader = ({
    eventId,
    activeTab,
    children
}) => (
    <Header>
        <TitleMenu>
            <Title>{TITLE}</Title>
            {children}
        </TitleMenu>
        <Tabs>
            <Tab activeTab={activeTab} tabId="transports" url={Routes.transportsOverview(eventId)} pageReload>
                {TRANSPORTS_TAB}
            </Tab>
            <Tab activeTab={activeTab} tabId="pickups" url={Routes.pickupsOverview(eventId)} >
                {PICKUPS_TAB}
            </Tab>
            <Tab activeTab={activeTab} tabId="pickupsScheduler" url={Routes.pickupsScheduler(eventId)}>
                {PICKUPS_SCHEDULER_TAB}
            </Tab>
            <Tab activeTab={activeTab} tabId="drivers" url={Routes.driversOverview(eventId)} pageReload>
                {DRIVERS_TAB}
            </Tab>
            <Tab activeTab={activeTab} tabId="vehicles" url={Routes.vehiclesOverview(eventId)} >
                {VEHICLES_TAB}
            </Tab>
        </Tabs>
    </Header>
);

LogisticsContentHeader.propTypes = {
    activeTab: PropTypes.string.isRequired,
    eventId: PropTypes.number,
    children: PropTypes.node,
};

export default LogisticsContentHeader
