import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';
import Store from 'store';
import cloneDeep from 'lodash.clonedeep';
import PageableCollection from './PageableCollection';
import DOM from 'app/backbone/helpers/DOM';

export const DEFAULT_FETCH_CACHE_TIMEOUT = 30000;

export default class BaseCollection extends Backbone.Collection {

    static Relations() {
        return {};
    }

    initialize(model, options = {}) {
        super.initialize(model, options);

        this.relations = {};
        this.initializeRelations(options);

        const { objectId } = options;
        if (objectId) this.objectId = objectId;
        else this.objectId = this.getConstructorName();

        if (!this.mode) this.mode = 'client';

        const initialState = this.getInitialState();
        if (initialState) this.state = initialState;
        const queryParams = this.getQueryParams();
        if (queryParams) this.queryParams = queryParams;

        this.setupListeners();
    }

    getInitialState() {
        return {
            pageSize: 'all',
            sortKey: null,
            directions: null,
            order: -1,
            includes: [],
            filters: {}
        };
    }

    getQueryParams() {
        return {
            pageSize: 'limit',
            sortKey: 'sort',
            directions: { 1: '', '-1': '-' },
            order: 'order'
        };
    }

    setupListeners() {
        this.listenTo(this, 'request', () => (this.isSyncing = true));
        this.listenTo(this, 'request:delete', () => (this.isDestroying = true));
        this.listenTo(this, 'destroy', () => (this.isDestroying = false));
        this.listenTo(this, 'sync', () => (this.isSyncing = false));
        this.listenTo(this, 'error', () => {
            this.isSyncing = false;
            this.isDestroying = false;
        });
    }

    setIncludes(includes) {
        if (_.isArray(includes)) this.state.includes = includes;
        return this;
    }

    setFilters(filters) {
        if (_.isObject(filters)) this.state.filters = filters;
        return this;
    }

    setFilter(key, value) {
        if (_.isString(key)) this.state.filters[key] = value;
        return this;
    }

    getFilter(key) {
        return this.state.filters[key];
    }

    parse(response, xhr) {
        return response.data ? response.data : response;
    }

    sync(method, models, options = {}) {
        const { includes, filters } = this.state;
        const requestParams = {};
        // Add each filter as a request param
        if (filters) _.extend(requestParams, filters);
        if (includes.length) requestParams.include = includes.join();
        if (this.mode === 'client') requestParams.limit = 'all';


        if (method === 'read') {
            options.data = _.extend(options.data || {}, requestParams);
        }

        // We add the request params manually to the url because the data property is used for the json data
        if (!options.url && (method === 'create' || method === 'update' || method === 'patch')) {
            const url = _.result(models, 'url');
            options.url = `${url}?${$.param(requestParams)}`;
        }

        const xhr = super.sync(method, models, options);
        this.trigger(`request:${method}`, models, xhr, options);
        return xhr;
    }

    isSomethingSyncing() {
        return this.isSyncing || this.some((model) => model.isSyncing);
    }

    isSomethingDestroying() {
        return this.isDestroying || this.some((model) => model.isDestroying);
    }

    fetch(options = {}) {
        // set a flag to bust the cache if we have ever set it before, and it's been more than 30 seconds
        // or the options bustCache has been passed
        const bustCache = (
            this.cacheTimeHasExpired() ||
            this.includesHaveChangedSinceLastFetch() ||
            this.filtersHaveChangedSinceLastFetch() ||
            options.bustCache
        );

        // if we've never cached the call to `fetch`, or if we're busting the cache,
        // make a note of the current time, hit the server, and set the cache to this.lastFetchDeferred.
        if (!this.lastFetchedDeferred || bustCache) {
            this.lastFetched = new Date();
            this.lastFetchedIncludes = this.state.includes;
            this.lastFetchedFilters = this.state.filters;
            this.lastFetchedDeferred = super.fetch(options);
        }

        // return the promise object in the cache
        return this.lastFetchedDeferred;
    }

    cacheTimeHasExpired() {
        const cacheTimeout = this.getFetchCacheTimeout();
        // If cache timeout equals null we use an unlimited cache time
        if (cacheTimeout === null) return false;
        return ! (this.lastFetched && new Date() - this.lastFetched < cacheTimeout);
    }

    includesHaveChangedSinceLastFetch() {
        return ! _.isEqual(this.lastFetchedIncludes, this.state.includes);
    }

    filtersHaveChangedSinceLastFetch() {
        return ! _.isEqual(this.lastFetchedFilters, this.state.filters);
    }

    getFetchCacheTimeout() {
        return DEFAULT_FETCH_CACHE_TIMEOUT;
    }

    bustCache() {
        delete this.lastFetchedDeferred;
    }

    fetchFromDOM(key = this.key || this.domKey()) {
        if (key && !this.invalidatedDOM) {
            const data = DOM.getData(key);
            if (data) this.set(data, { parse: true });
            else throw new Error(`Unable to fetch data from DOM with key '${key}'. Please bind to object to the DOM before fetching it.`);
        } else throw new Error(`Cannot fetch data form DOM without object key.`);
        return this;
    }

    fetchFromDOMOrServer(key = this.key || this.domKey()) {
        const data = DOM.getData(key);
        if (data && !this.invalidatedDOM) {
            this.set(data, { parse: true });
            return $.when();
        } else {
            return this.fetch();
        }
    }

    fetchIfEmpty(options) {
        if (this.isEmpty()) return this.fetch(options);
    }

    fetchAll(options = { data: {}}) {
        options.data.limit = 'all';
        return this.fetch(options);
    }

    save(options) {
        this.each((model) => {
            model.save(null, options);
        });
    }

    allModelsSynced() {
        return this.every((model) => !model.isNew())
    }

    newRelation(key, object) {
        if (key && object) {
            this[key] = object;
            this.relations[key] = object;
        }
    }

    initializeRelations(options) {
        const keys = this.getRelationKeys();
        keys.forEach(key => {
            if (!this.hasRelation(key)) this.newRelation(key, options[key]);
        });
    }

    getRelationKeys() {
        if (!this.constructor.Relations) return [];
        return _.keys(this.constructor.Relations());
    }

    getRelation(key) {
        if(key) return this[key] || this.relations[key];
    }

    hasValidRelations(keyOrKeys) {
        const keys = _.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys];
        return _.every(keys, key => this.hasRelation(key) && !this.getRelation(key).isNew());
    }

    validateRelations(keyOrKeys) {
        const keys = _.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys];
        keys.forEach((key) => {
            if (!this.hasRelation(key)) {
                throw new Error(`Instance of class '${this.constructor.name}' has no relation set to '${key}'.`);
            } else if (this.getRelation(key).isNew()) {
                throw new Error(`The relation with '${key}' is new and thus has no id.`);
            }
        });
    }

    hasRelation(key) {
        return ! _.isUndefined(this[key] || this.relations[key]);
    }

    filterIds(ids) {
        return new this.constructor(this.filter(model => _.contains(ids, model.id)));
    }

    rejectIds(ids) {
        return new this.constructor(this.reject(model => _.contains(ids, model.id)));
    }

    filterByAttribute(attr) {
        return new this.constructor(this.filter(model => model.get(attr)));
    }

    rejectByAttribute(attr) {
        return new this.constructor(this.reject(model => model.get(attr)));
    }

    log() {
        console.table(this.map(model => model.attributes));
    }

    getObjectId() {
        try {
            return `${this.objectId}:${this.url().replace('/api/', '')}`;
        } catch (e) {
            return null;
        }
    }

    setObjectId(objectId) {
        if (objectId) this.objectId = objectId;
        return this;
    }

    getConstructorName() {
        return this.constructor.name;
    }

    storeSetting(name, value) {
        if (name || value) {
            const objectId = this.getObjectId();
            if (objectId) {
                const settings = Store.get(objectId) || {};
                settings[name] = value;
                Store.set(objectId, settings);
            }
        }
    }

    getStoredSetting(name) {
        const objectId = this.getObjectId();
        if (objectId) {
            const settings = Store.get(this.getObjectId()) || {};
            return settings[name];
        }
    }

    storeSettings(settings) {
        if (settings) {
            const objectId = this.getObjectId();
            if (objectId) {
                const storedSettings = Store.get(objectId) || {};
                Store.set(objectId, _.extend(storedSettings, settings));
            }
        }
    }

    getStoredSettings() {
        const objectId = this.getObjectId();
        if (objectId) return Store.get(this.getObjectId());
    }

    storeState() {
        const { filters } = this.state;
        const storeSate = cloneDeep(this.state);
        const resultFilters = {};

        // We execute each filter function to store the returned value in the local storage
        _.forEach(filters, (filter, key) => {
            if (key !== 'search') {
                if (_.isFunction(filter)) resultFilters[key] = filter();
                else resultFilters[key] = filter;
            }
        });

        storeSate.filters = resultFilters;
        return this.storeSetting('state', storeSate);
    }

    getStoredState() {
        return this.getStoredSetting('state');
    }

    PageableCollection() {
        return PageableCollection.extend({
            key: this.key,
            model: this.model,
            comparator: this.comparator,
            url: this.url,
            initialize: this.initialize,
            setupListeners: this.setupListeners,
            setIncludes: this.setIncludes,
            setFilters: this.setFilters,
            setFilter: this.setFilter,
            getFilter: this.getFilter,
            sync: this.sync,
            reset: this.reset,
            bustCache: this.bustCache,
            cacheTimeHasExpired: this.cacheTimeHasExpired,
            includesHaveChangedSinceLastFetch: this.includesHaveChangedSinceLastFetch,
            filtersHaveChangedSinceLastFetch: this.filtersHaveChangedSinceLastFetch,
            getFetchCacheTimeout: this.getFetchCacheTimeout,
            initializeRelations: this.initializeRelations,
            getRelationKeys: this.getRelationKeys,
            isSomethingSyncing: this.isSomethingSyncing,
            destroy: this.destroy,
            isSomethingDestroying: this.isSomethingDestroying,
            fetchFromDOM: this.fetchFromDOM,
            fetchFromDOMOrServer: this.fetchFromDOMOrServer,
            fetchIfEmpty: this.fetchIfEmpty,
            allModelsSynced: this.allModelsSynced,
            newRelation: this.newRelation,
            getRelation: this.getRelation,
            validateRelations: this.validateRelations,
            hasRelation: this.hasRelation,
            log: this.log,
            getObjectId: this.getObjectId,
            setObjectId: this.setObjectId,
            storeSetting: this.storeSetting,
            storeSettings: this.storeSettings,
            getStoredSetting: this.getStoredSetting,
            getStoredSettings: this.getStoredSettings,
            storeState: this.storeState,
            getStoredState: this.getStoredState,
            constructorName: this.constructor.name.replace('Collection', 'PageableCollection')
        }, {
            Relations: this.constructor.Relations
        });
    }

}
