import BaseView from 'app/backbone/components/Base.view'
import Datepicker from 'app/backbone/components/inputs/Datepicker.view'
import Timepicker from 'app/backbone/components/inputs/Timepicker.view'

export default class UpdateDayView extends BaseView {

    ui() {
        return {
            startDatePicker: 'input[name=start_date]',
            startTimePicker: 'input[name=start_time]',
            endDatePicker: 'input[name=end_date]',
            endTimePicker: 'input[name=end_time]',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderStartDatePicker();
        this.renderStartTimePicker();
        this.renderEndDatePicker();
        this.renderEndTimePicker();
        return this.delegateEvents();
    }

    renderStartDatePicker() {
        this.startDatePicker = new Datepicker({ el: this.ui.startDatePicker });
        this.startDatePicker.render();
    }

    renderStartTimePicker() {
        this.startTimePicker = new Timepicker({ el: this.ui.startTimePicker });
        this.startTimePicker.render();
    }

    renderEndDatePicker() {
        this.endDatePicker = new Datepicker({ el: this.ui.endDatePicker });
        this.endDatePicker.render();
    }

    renderEndTimePicker() {
        this.endTimePicker = new Timepicker({ el: this.ui.endTimePicker });
        this.endTimePicker.render();
    }
}
