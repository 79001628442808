import React, {Fragment} from 'react'
import {
    AlertBox,
    Drawer,
    Summary,
} from 'ui-kit'
import {
    groupBy,
    isEmpty,
} from 'underscore'
import by from 'thenby'
import AccommodationHeaderActions from 'app/react/views/event/artists/reservations/views/overview/components/drawers/AccommodationHeaderActions'
import RoomTypeDetails from 'app/react/views/event/artists/reservations/views/dashboard/components/drawers/RoomTypeDetails'
import {isBookedByPromoter} from 'app/react/helpers/reservations'

const {
    Item,
    Group,
} = Summary;

const appendEmptyRoom = (roomTypes, reservationsWithoutRoomType) => {
    if (isEmpty(reservationsWithoutRoomType)) {
        return roomTypes;
    }

    return roomTypes.concat([{
        id: 0,
        name: 'Without room type'
    }]);
};

const DetailDrawer = ({
    accommodation,
    currencySymbol,
    amountOfColumns,
    hideDetailRow,
    renderTrigger,
}) => {
    const { name, totalPriceForReservationsByPromoter } = accommodation;
    const reservations = accommodation.reservations || [];
    const bookedByPromoter = reservations.filter(isBookedByPromoter);
    const roomsBookedByPromoter = bookedByPromoter
        .reduce((roomsBooked, reservation) => roomsBooked + reservation.nights, 0);
    const reservationsGroupedByRoomType = groupBy(reservations, (reservation) => {
        return reservation.roomType ? reservation.roomType.id : 0;
    });
    const reservationsWithoutRoomType = reservationsGroupedByRoomType[0] || [];
    const roomTypes = appendEmptyRoom(accommodation.roomTypes || [], reservationsWithoutRoomType);

    return (
        <Drawer
            renderTrigger={renderTrigger}
            renderTitle={() => (
                <Fragment>
                    <h5>{name}</h5>
                    <AccommodationHeaderActions accommodation={accommodation} />
                </Fragment>
            )}
        >
            <Summary>
                <Group>
                    <Item
                        isLarge
                        renderLabel={() => 'Reservations'}
                    >
                        <i className="bs-icon-accommodation" /> {bookedByPromoter.length}
                    </Item>
                    <Item
                        isLarge
                        renderLabel={() => 'Nights'}
                    >
                        <i className="bs-icon-night" /> {roomsBookedByPromoter}
                    </Item>
                    <Item
                        isLarge
                        renderLabel={() => 'Total price'}
                    >
                        {totalPriceForReservationsByPromoter
                            ? <Fragment>
                                <i className="bs-icon-money" /> {totalPriceForReservationsByPromoter}
                            </Fragment>
                            : 'N/A'
                        }
                    </Item>
                </Group>
                {roomTypes
                    .sort(by(roomType => roomType.name, { ignoreCase: true }))
                    .map((roomType) => (
                        <RoomTypeDetails
                            key={roomType.id}
                            roomType={roomType}
                            reservations={reservationsGroupedByRoomType[roomType.id] || []}
                            currencySymbol={currencySymbol}
                        />
                    ))
                }
                {isEmpty(roomTypes) && (
                    <AlertBox>You currently have no room types for this accommodation.</AlertBox>
                )}
            </Summary>
        </Drawer>
    )
};

DetailDrawer.defaultProps = {
    accommodation: {},
};

export default DetailDrawer;
