import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import CompanyAccrediteeTable from '../tables/CompanyAccrediteeTable.view';
import AvailableProfilesView from './AvailableProfiles.view';
import template from './AccrediteesCardContent.hbs';

export default class AccrediteesCardContent extends BaseView {

    get template() {
        return template;
    }

    ui() {
        return {
            accrediteesTable: '#accreditees-table',
            availableProfilesView: '#available-accreditee-profiles'
        };
    }

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            accreditees: OptionTypes.instanceOf(CompanyAccreditee.PageableCollection).isRequired
        };
    }

    initialize(options) {
        this.resolveOptions(options);
        // Subviews
        this.availableProfilesView = this.initAvailableProfilesView();
        this.accrediteesTable = this.initAccrediteeTable();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.assignSubview(this.availableProfilesView, this.ui.availableProfilesView);
        this.assignSubview(this.accrediteesTable, this.ui.accrediteesTable);
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {},
            model: this.company.getRelation('production_website_settings').attributes
        };
    }

    initAvailableProfilesView() {
        const profiles = this.company.getRelation('profiles');
        return new AvailableProfilesView({ profiles });
    }

    initAccrediteeTable() {
        const { accreditees } = this;
        return new CompanyAccrediteeTable({ accreditees });
    }

}
