import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import SelectZonesTable from '../tables/SelectZonesTable.view';

export default class SelectZonesCard extends Card {

    optionTypes() {
        const { title, hasMaxHeight } = super.optionTypes();
        return {
            title,
            hasMaxHeight,
            profile: OptionTypes.instanceOf(Profile.Model).isRequired,
            zones: OptionTypes.instanceOf(Zone.Collection).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // Listeners
        this.listenTo(this.profile, 'validated', this.onValidated);
    }

    initContent() {
        const { zones } = this;
        return new SelectZonesTable({ zones });
    }

    onValidated(isModelValid, model, errors) {
        if (errors.zones) this.addError(errors.zones);
        else this.removeError();

    }

}
