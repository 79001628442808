import { connect } from 'react-redux'
import * as Event from 'app/react/entities/events/index'
import ContentHeader from './ContentHeader'

export const OVERVIEW_TAB_ID = 'overview';
export const DASHBOARD_TAB_ID = 'dashboard';

const mapStateToProps = (state) => {
    const eventId = Event.stateHelpers.getCurrentId(state);
    return {
        eventId
    };
};

export default connect(
    mapStateToProps
)(ContentHeader);
