import getSymbolFromCurrency from 'currency-symbol-map'
import * as Account from 'app/react/entities/accounts/helpers';
import * as Schemas from './schemas'
import {
    getCurrentContextId,
    getCurrentContextEntity
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    ENTITY_CONTEXT_ID_KEY,
} = Schemas;

export const getCurrentId = state =>
    getCurrentContextId(state, ENTITY_CONTEXT_ID_KEY);

export const getCurrent = state =>
    getCurrentContextEntity(state, ENTITY_REDUCER_KEY, ENTITY_CONTEXT_ID_KEY);

export const isFetchingCurrent = (state) => {
    const { isFetching } = getCurrent(state) || {};
    return !! isFetching;
};

const getCurrentDateTimeFormatObject = (state) => {
    const { dateTimeFormat = { date: {}, time: {} } } = getCurrent(state) || {};
    return dateTimeFormat;
};

export const getCurrentDateFormat = state =>
    getCurrentDateTimeFormatObject(state).date.momentjs;

export const getCurrentDateFormatShort = state =>
    getCurrentDateTimeFormatObject(state).date.momentjs.replace(/[/]y{1,}|y{1,}[-]/ig, ''); // RegEx to remove the year part

export const getCurrentTimeFormat = state =>
    getCurrentDateTimeFormatObject(state).time.momentjs;

export const getCurrentCurrency = (state) => {
    const { currency } = getCurrent(state) || {};
    return currency;
};

export const getCurrentCurrencySymbol = (state) => {
    return getSymbolFromCurrency(getCurrentCurrency(state));
};

export const getUserPermissions = (state) => {
    const { permissions = {} } = getCurrent(state) || {};
    return permissions;
};

export const isArtistTypesEnabledForCurrent= (state) => {
    const { artistTypesEnabled } = getCurrent(state) || {};
    return !! artistTypesEnabled;
};

export const getCurrentUnitOfDistance= (state) => {
    const { unitOfDistance } = getCurrent(state) || {};
    return unitOfDistance;
};

export const generateCollectionKey = (accountId, eventId) =>
    `${accountId}/${eventId}`;


export const generateCurrentContextKey = (state) =>
    generateCollectionKey(Account.getCurrentId(state), getCurrentId(state));
