import Backbone from 'backbone'
import SingleSelectize from 'app/backbone/components/inputs/SingleSelectize.view'
import BaseView from 'app/backbone/components/Base.view'
import Datepicker from 'app/backbone/components/inputs/Datepicker.view'
import Timepicker from 'app/backbone/components/inputs/Timepicker.view'

export default class TimeSlotFormView extends BaseView {

    ui() {
        return {
            typeSelectize: 'select[name=type]',
            startDatePicker: '[name=start_date]',
            startTimePicker: '[name=start_time]',
            endDatePicker: '[name=end_date]',
            endTimePicker: '[name=end_time]',
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.inputs = new Backbone.ChildViewContainer();
    }

    initTypeSelectize() {
        const typeSelectizeEl = this.ui.typeSelectize;
        const typeSelectize = new SingleSelectize({
            el: typeSelectizeEl,
            create: true,
            createPrefix: '',
        });

        this.inputs.add(typeSelectize, 'type');
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.initTypeSelectize();
        this.renderStartDatePicker();
        this.renderStartTimePicker();
        this.renderEndDatePicker();
        this.renderEndTimePicker();
        return this.delegateEvents();
    }

    renderStartDatePicker() {
        this.startDatePicker = new Datepicker({ el: this.ui.startDatePicker });
        this.startDatePicker.render();
    }

    renderStartTimePicker() {
        this.startTimePicker = new Timepicker({ el: this.ui.startTimePicker });
        this.startTimePicker.render();
    }

    renderEndDatePicker() {
        this.endDatePicker = new Datepicker({ el: this.ui.endDatePicker });
        this.endDatePicker.render();
    }

    renderEndTimePicker() {
        this.endTimePicker = new Timepicker({ el: this.ui.endTimePicker });
        this.endTimePicker.render();
    }
}
