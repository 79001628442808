import { html } from 'common-tags';
import Content from 'app/backbone/components/layout/content/Content.view';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import ProfileOverviewCard from './cards/ProfileOverviewCard.view';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';

export default class ProfileOverview extends Content {

    ui() {
        return {
            profilesCard: '#profiles-card'
        };
    }

    optionTypes() {
        return {
            profiles: OptionTypes.instanceOf(Profile.PageableCollection).isRequired,
            zones: OptionTypes.instanceOf(Zone.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.profilesCard = this.initProfilesCard();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.profilesCard, this.ui.profilesCard);
        return this.delegateEvents();
    }

    template() {
        return html`<div id="profiles-card" class="card"></div>`;
    }

    remove() {
        super.remove();
        this.profilesCard.remove();
    }

    initProfilesCard() {
        const { profiles, zones } = this;
        const title = 'Profiles';
        return new ProfileOverviewCard({ profiles, zones, title });
    }

}
