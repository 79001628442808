import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import EventCompanyDetailsContentHeader from 'app/react/views/event/accreditation/companies/CompanyDetailsContentHeader';
import CompanyItemGroupUpdateView from 'app/backbone/views/event/accreditation/companies/items/views/CompanyItemGroupUpdate.view';

// Backbone Controller
export default class CompanyItemController extends BaseController {

    renderDetailsContentHeader(eventId, companyId, companyName, activeTab) {
        const { app } = this;
        app.renderContentHeader(
            React.createElement(
                EventCompanyDetailsContentHeader,
                {
                    eventId,
                    companyId,
                    companyName,
                    activeTab
                }
            )
        );
    }

    edit(eventId, companyId, groupId) {
        const { app, data } = this;
        const intEventId = parseInt(eventId, 10);
        const intCompanyId = parseInt(companyId, 10);

        app.renderSyncingView();
        this.renderDetailsContentHeader(intEventId, intCompanyId, null, 'details');

        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const company = this.data.register('eventCompany', EventCompany.Model, { event });
        const periods = this.data.register('allPeriods', Period.Collection, { event });
        const itemGroup = this.data.register('eventItemGroup', EventItemGroup.Model, { event });

        periods.setIncludes(['days']);
        company.set('id', parseInt(companyId, 10));
        company.setIncludes([
            'items.groups',
            'items.available_days',
            'items.granted_days'
        ]);
        itemGroup.set('id', parseInt(groupId, 10));

        this.when(
            account.fetch(),
            event.fetch(),
            periods.fetch(),
            itemGroup.fetch(),
            company.fetch()
        ).done(() => {
            const companyName = company.get('name');
            this.renderDetailsContentHeader(intEventId, intCompanyId, companyName, 'details');
            app.renderContentView(new CompanyItemGroupUpdateView({ company, periods, itemGroup }));
        });

    }

}
