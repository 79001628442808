var _ = require('underscore'),
    Backbone = require('backbone');

var ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin'),
    Modal = require('app/backbone/components/modals/Modal.view');

var CompanyModal = Modal.extend({

    mixins: [
        ValidatedForm,
        Form
    ],

    bindings: {
        '[name=name]': 'name'
    },

    contactBindings: {
        '[name=contact_firstname]': 'firstname',
        '[name=contact_lastname]': 'lastname',
        '[name=contact_email]': 'email'
    },

    options: [
        'hiddenInputs',
        'disabledInputs',
        'canCreateAnother',
        'width'
    ],

    initialize(options) {
        this._super(options);

        // Default Options
        !_.isUndefined(this.width) || (this.width = 600);
        !_.isUndefined(this.hiddenInputs) || (this.hiddenInputs = []);
        !_.isUndefined(this.disabledInputs) || (this.disabledInputs = []);
        !_.isUndefined(this.canCreateAnother) || (this.canCreateAnother = true);
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.delegateEvents();

        if(!this.isSyncing) {
            this.unbindModel();
            this.initForm();
            this.bindModel();
            this.renderForm();
        }

        this._super();
    },

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                isSyncing: this.isSyncing,
                hiddenInputs: this.hiddenInputs,
                height: this.height
            },
            model: this.model.attributes
        };
    },

    initForm() {
        this.form = {
            inputs: new Backbone.ChildViewContainer(),
            groups: new Backbone.ChildViewContainer()
        };

        this.disableInputs(this.disabledInputs);

        if(this.isAvailable('name')) this.form.groups.add(this.initValidationGroup('name', this.model), 'name');

        if(this.isAvailable('contact_firstname')) this.form.groups.add(this.initValidationGroup('contact_firstname', this.model.primary_contact, 'firstname'), 'firstname');
        if(this.isAvailable('contact_lastname')) this.form.groups.add(this.initValidationGroup('contact_lastname', this.model.primary_contact, 'lastname'), 'lastname');
        if(this.isAvailable('contact_email')) this.form.groups.add(this.initValidationGroup('contact_email', this.model.primary_contact, 'email'), 'email');
    },

    renderForm() {
        this.form.inputs.call('render');
        this.form.groups.call('render');
    },

    bindModel() {
        this.stickit();
        this.stickit(this.model.primary_contact, this.contactBindings);

        this.listenTo(this.model, 'sync', this.syncSuccess);
        this.listenTo(this.model, 'request', () => { this.setSyncing(true) });
        this.listenTo(this.model.primary_contact, 'request', () => { this.setSyncing(true) });
        this.listenTo(this.model, 'error', this.syncError);
    },

    unbindModel() {
        this.unstickit();
        this.unstickit(this.model.primary_contact);
        this.stopListening(this.model);
        this.stopListening(this.model.primary_contact);
    },

    syncError(model, xhr){
        this.setSyncing(false);
        this.validateResponse(xhr);
    },

    setSyncing(syncing) {
        if(syncing != this.isSyncing){
            this.isSyncing = syncing;
            this.render();
        }
    },

    shown() {
        this._super();
        this.focusFirstInput();
    }
});

module.exports = CompanyModal;
