import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { InputGroup } from 'ui-kit'
import TextEditor from 'app/react/views/event/production_website/artists/ProductionWebsiteSettingsTextEditor'
import {
    DESCRIPTION_PROP_NAME,
    DESCRIPTION_LABEL,
    DESCRIPTION_HEIGHT,
    FIELD_MAX_WIDTH,
    LIMIT_PROP_NAME,
    LIMIT_PLACEHOLDER,
    UPDATE_BOOKINGS_LABEL,
    UPDATE_BOOKINGS_PROP_NAME,
} from './constants'

const LIMIT_LABEL = 'Max. parking passes';

export const ParkingForm = ({
    values,
    errors = {},
    onChangeValue = () => {},
}) => (
    <Fragment>
        <InputGroup messages={errors[DESCRIPTION_PROP_NAME]}>
            <label>{DESCRIPTION_LABEL}</label>
            <TextEditor
                imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                height={DESCRIPTION_HEIGHT}
                value={values[DESCRIPTION_PROP_NAME] || ''}
                onBlur={e => onChangeValue(DESCRIPTION_PROP_NAME, e.target.value)}
            />
        </InputGroup>
        <fieldset>
            <legend>Limits</legend>
            <InputGroup
                messages={errors[LIMIT_PROP_NAME]}
                style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
            >
                <label>{LIMIT_LABEL}</label>
                <input
                    type="number"
                    placeholder={LIMIT_PLACEHOLDER}
                    value={typeof values[LIMIT_PROP_NAME] !== 'undefined' && values[LIMIT_PROP_NAME] !== null
                        ? values[LIMIT_PROP_NAME]
                        : ''
                    }
                    onChange={e => onChangeValue(LIMIT_PROP_NAME, e.target.value)}
                />
            </InputGroup>
            <InputGroup
                messages={errors[UPDATE_BOOKINGS_PROP_NAME]}
                style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
            >
                <label>
                    <input
                        type="checkbox"
                        style={{ marginRight: '1rem' }}
                        checked={typeof values[UPDATE_BOOKINGS_PROP_NAME] !== 'undefined'
                            ? values[UPDATE_BOOKINGS_PROP_NAME]
                            : false
                        }
                        onChange={e => onChangeValue(UPDATE_BOOKINGS_PROP_NAME, e.target.checked)}
                    />
                    {UPDATE_BOOKINGS_LABEL}
                </label>
            </InputGroup>
        </fieldset>
    </Fragment>
);

ParkingForm.propTypes = {
    values: PropTypes.shape({
        [DESCRIPTION_PROP_NAME]: PropTypes.string,
        [LIMIT_PROP_NAME]: PropTypes.number,
    }).isRequired,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
};
