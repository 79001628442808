import moment from 'moment';
import { pick } from 'underscore';
import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Performance from 'app/react/entities/events/performances/index';
import { reformatPerformanceFormValues } from '../../utils';
import UpdateModal, { UPDATE_MODAL_ID } from './Modal';

export const POPUP_MESSAGES = {
    FAILED_TO_DELETE: (performanceName) =>
        `Failed to delete performance <strong>${performanceName}</strong>`,
    SUCCESSFUL_DELETE: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully deleted.`,
    SUCCESSFUL_UPDATE: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully update.`,
    FAILED_TO_UPDATE: (performanceName) =>
        `Failed to update performance <strong>${performanceName}</strong>`,
};

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSyncing,
        isEditingPerformanceName,
        originalPerformance = { day: {} },
        values = {},
        errors = {},
        activeTab,
        updateSuccessCallback
    } = stateHelpers.getComponentState(state, UPDATE_MODAL_ID);
    return {
        isOpened,
        isSyncing,
        activeTab,
        isEditingPerformanceName,
        originalPerformance,
        selectedDay: stateHelpers.getOneEntity(state, 'days', values.day),

        values,
        errors,
        updateSuccessCallback,

        days: stateHelpers.getAllEntities(state, 'days'),
        stages: stateHelpers.getAllEntities(state, 'stages'),

        eventId: stateHelpers.events.getCurrentId(state),
        accountId: stateHelpers.accounts.getCurrentId(state),
        dateFormat: stateHelpers.events.getCurrentDateFormat(state),
        timeFormat: stateHelpers.events.getCurrentTimeFormat(state)
    };
};

const calculateDifferenceDuration = (startISOString, endISOString) => {
    const mStart = moment.utc(startISOString);
    const mEnd = moment.utc(endISOString);
    const difference = mEnd.diff(mStart);
    return moment.duration(difference);
};

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch(actions.closeConfirmModal(UPDATE_MODAL_ID));
        dispatch(actions.deleteComponentStateValue(UPDATE_MODAL_ID, 'errors'));
    },
    onSaved: (
        eventId,
        performanceId,
        values,
        isEditingPerformanceName,
        originalDayId,
        newDay,
        successCallback
    ) => {
        if (!Performance.isValid(values)) {
            const errors = Performance.getValidationErrors(values);
            dispatch(actions.mergeComponentState(UPDATE_MODAL_ID, { errors }));
        } else {
            dispatch(actions.setModalSyncing(UPDATE_MODAL_ID, true));
            const formattedValues = reformatPerformanceFormValues(values);
            const apiKeys = ['day', 'stage', 'start', 'end', 'changeover', 'publishDate', 'bio'];
            if (isEditingPerformanceName) apiKeys.push('title');
            const apiValues = pick(formattedValues, apiKeys);
            dispatch(Performance.actions.updateOne(eventId, performanceId, apiValues)).then(({ error, response }) => {
                if (error) {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.mergeComponentState(UPDATE_MODAL_ID, { errors }));
                    dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_UPDATE));
                } else {
                    dispatch(actions.closeModal(UPDATE_MODAL_ID));
                    dispatch(actions.showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_UPDATE(values.title)));
                    dispatch(actions.mergeComponentState(UPDATE_MODAL_ID, { errors: {} }));
                    successCallback(Performance.stateHelpers.getOne(response, response.result));
                }
                dispatch(actions.setModalSyncing(UPDATE_MODAL_ID, false));
            });
        }
    },
    onSelectDay: (dayId, dayStart, originalPerformance) => {
        dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'values.day', dayId));
        if (originalPerformance.day !== dayId) {
            dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'values.start', dayStart));
            const mEnd = moment(dayStart);
            mEnd.add(1, 'hours');
            dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'values.end', mEnd.toISOString()));
        } else {
            dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'values.start', originalPerformance.start));
            dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'values.end', originalPerformance.end));
        }
    },
    onChangeValue: (key, value) => {
        dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, `values.${key}`, value));
    },
    onToggleEditPerformanceName: (isEditing = false, originalName) => {
        dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'isEditingPerformanceName', isEditing));
        if (!isEditing) {
            dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'values.title', originalName));
        }
    },
    onChangeTab: (activeTab) => {
        dispatch(actions.setComponentStateValue(UPDATE_MODAL_ID, 'activeTab', activeTab));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSelectDay: (dayId) => {
        const { days, originalPerformance } = stateProps;
        const day = days.find(({ id }) => id === dayId);
        if (typeof day !== 'undefined') {
            dispatchProps.onSelectDay(dayId, day.start, originalPerformance);
        }
    },
    onSaved: () => {
        const { values, eventId, isEditingPerformanceName, days, originalPerformance, updateSuccessCallback } = stateProps;
        const performanceId = originalPerformance.id;
        const newDay = days.find(({ id }) => id === values.day);
        const originalDayId = originalPerformance.day;
        dispatchProps.onSaved(
            eventId,
            performanceId,
            values,
            isEditingPerformanceName,
            originalDayId,
            newDay,
            updateSuccessCallback
        );
    },
    onToggleEditPerformanceName: (isEditing) => {
        const { originalPerformance } = stateProps;
        dispatchProps.onToggleEditPerformanceName(isEditing, originalPerformance.title);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(UpdateModal);
