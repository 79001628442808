import merge from 'lodash.merge';
import dotProp from 'dot-prop-immutable';
import {
    SET_STATE,
    MERGE_STATE,
    SET_STATE_VALUE,
    MERGE_STATE_VALUE
} from '../actions/_index';

export const setState = (state = {}, action) => {
    switch (action.type) {
        case SET_STATE:
            return action.stateToSet;
        case MERGE_STATE:
            return merge({}, state, action.stateToMerge);
        case SET_STATE_VALUE: {
            return dotProp.set(state, action.path, action.valueToSet);
        }
        case MERGE_STATE_VALUE: {
            const { path, valueToMerge } = action;
            const originalValue = dotProp.get(state, path);
            const mergedState = {
                ...originalValue,
                ...valueToMerge
            };
            return dotProp.set(state, path, mergedState);
        }
        default:
            return state;
    }
};
