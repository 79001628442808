import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'zone'
})
class Zone extends BaseModel {

    static Relations() {
        return {
            event: require('../../Event').Model // Circular
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a name.'
            },
            symbol: [{
                required: true,
                msg: 'Enter a symbol.'
            },{
                maxLength: 10,
                msg: 'The symbol may not be greater than 10 characters.'
            }]
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/zones`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }
}

@Collection({
    key: 'zones',
    model: Zone,
    comparator: 'name'
})
class ZoneCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('../../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/zones`;
    }
}

const PageableCollection = ZoneCollection.prototype.PageableCollection();

export {
    Zone as Model,
    ZoneCollection as Collection,
    PageableCollection
};
