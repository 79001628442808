import * as ArtistType from 'app/react/entities/events/artist_types/helpers';
import * as Sections from './sections/helpers';
import * as Schemas from './schemas'
import {
    getOneEntity
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
} = Schemas;

export const getCurrentId = state => {
    const { artistSettings } = ArtistType.getCurrent(state) || {};
    return artistSettings
};

export const getCurrent = state =>
    getOneEntity(state, ENTITY_REDUCER_KEY, getCurrentId(state));

export const isFetchingCurrent = state => {
    const settings = getCurrent(state) || {};
    return settings.isFetching
};

export const getCurrentWithSections = state => {
    const settings = getCurrent(state) || {};
    const sections = Sections.getSome(state, settings.sections);
    return {
        ...settings,
        sections
    };
};
