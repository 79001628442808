import { Model, Collection }  from 'app/backbone/models/decorators';
import DateTime from 'app/backbone/helpers/DateTime';
import * as Booking from 'app/backbone/models/event/artists/Booking';
import * as Contact from 'app/backbone/models/account/Contact';
import * as AccrediteeProfile from './AccrediteeProfile';
import * as AccrediteeItem from './AccrediteeItem';

@Model({
    key: 'accreditee'
})
class Accreditee extends Contact.Model {

    static Relations() {
        return {
            event: require('../../Event').Model, // Circular
            bookings: Booking.Collection, // Circular
            companies: require('../companies/Company').Collection, // Circular
            profiles: AccrediteeProfile.Collection,
            items: AccrediteeItem.Collection
        };
    }

    get defaults() {
        return {
            total_granted: 0
        }
    }

    get validation() {
        return {
            firstname: super.validation.firstname,
            lastname: super.validation.lastname,
            email: [{
                required: false,
                pattern: 'email',
                msg: 'Please enter a valid email'
            }],
            profiles: 'validateProfiles'
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
        this.listenTo(this.profiles, 'reset update change:total_granted', this.resetTotalGranted);
        this.resetTotalGranted()
    }

    urlRoot() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/accreditees`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        response.added_at = DateTime.account.formatDateToDateString(response.added_at);
        return response;
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if(this.hasRelation('profiles')) {
            json.profiles = this.getRelation('profiles').toJSON();
        }
        if(this.hasRelation('items')) {
            json.items = this.getRelation('items').filterByHasGrantedDay().toJSON();
        }
        return json;
    }

    validateProfiles() {
        if(this.profiles.isEmpty()) return 'Select at least one profile.';
        if(this.profiles.someHaveNoGrantedDays()) return 'Select at least one day per added profile.';
    }

    resetTotalGranted() {
        this.set('total_granted', this.profiles.getTotalGranted() )
    }

    canDownloadTicket() {
        const permissions = this.get('permissions').data;

        return !! permissions.download_ticket;
    }

    canSendTicket() {
        const permissions = this.get('permissions').data;

        return !! permissions.send_ticket;
    }

    sendTicket() {
        const url = this.url();

        return $.post(`${url}/send-ticket`);
    }

    destroy(options = {}) {
        if (!options.url) {
            this.validateRelations('event');
            const eventId = this.event.id;
            const accrediteeId = this.id;
            options.url = `/api/events/${eventId}/custom-accreditees/${accrediteeId}`;
        }
        super.destroy(options)
    }
}

@Collection({
    key: 'accreditees',
    model: Accreditee
})
class AccrediteeCollection extends Contact.Collection {

    static Relations() {
        return {
            event: require('../../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        let eventId = this.event.id;
        return `/api/events/${eventId}/accreditees`;
    }
}

const PageableCollection = AccrediteeCollection.prototype.PageableCollection();

export {
    Accreditee as Model,
    AccrediteeCollection as Collection,
    PageableCollection
};
