import { connect } from 'react-redux';
import moment from 'moment';
import { getFormFieldErrorsFromResponseJSON, reformatISOString } from 'app/react/helpers/_index';
import {
    mapBeatswitchContextToProps,
    mapBookingContextToProps,
    mapEventContextToProps,
} from 'app/react/components/util/connectBeatswitchContext'
import * as actions from 'app/react/state/actions/_index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups';
import * as EventLocations from 'app/react/entities/events/locations/index';
import * as EventRoutes from 'app/react/entities/events/locations/routes/index';
import * as BookingAttendees from 'app/react/entities/events/bookings/attendees/index';
import * as Driver from 'app/react/entities/events/drivers/index';
import * as Vehicle from 'app/react/entities/events/vehicles/index'
import * as VehicleType from 'app/react/entities/vehicle_types/index'
import * as PickupMode from 'app/react/entities/events/bookings/pickups/modes/index'
import * as Transport from 'app/react/entities/events/bookings/transports/index'
import * as State from './CreateViewState'
import * as CreateVehicleModalState from 'app/react/views/event/artists/logistics/vehicles/components/modals/stateful/CreateModalState'
import * as DriverModalState from 'app/react/views/event/artists/logistics/drivers/components/ModalState'
import CreateView from './CreateView'
import * as Event from 'app/react/entities/events'
import * as Account from 'app/react/entities/accounts'
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses'

const FAILED_TO_CREATE_POPUP = (departureName, arrivalName) =>
        `Failed to create pickup from <strong>${departureName}</strong> to <strong>${arrivalName}</strong>.`;

const SUCCESSFUL_CREATE_ANOTHER_POPUP = (departureName, arrivalName) =>
    `Pickup from <strong>${departureName}</strong> to <strong>${arrivalName}</strong> successfully created.`;

const SUCCESSFUL_CREATE_POPUP = (departureName, arrivalName) =>
    `${SUCCESSFUL_CREATE_ANOTHER_POPUP(departureName, arrivalName)} Redirecting...`;

const mapStateToProps = (state) => {
    const {
        values = {},
        errors = {},
        isSettingCustomDepartureName,
        isSettingCustomArrivalName,
        transportsIsCollapsed,
        isSaving,
        userHasSetArrival,
    } = State.getViewState(state);

    return {
        ...mapBeatswitchContextToProps(state),
        ...mapEventContextToProps(state),
        ...mapBookingContextToProps(state),
        isFetching: (
            isSaving ||
            Booking.stateHelpers.isFetchingCurrent(state) ||
            PickupMode.stateHelpers.isFetchingAll(state) ||
            BookingAttendees.stateHelpers.isFetchingAllForCurrentContext(state) ||
            EventLocations.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Driver.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Vehicle.stateHelpers.isFetchingAllForCurrentContext(state) ||
            VehicleType.stateHelpers.isFetchingAll(state) ||
            BookingStatus.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        isFetchingTransports: Transport.stateHelpers.isFetchingAllForCurrentContext(state),
        values,
        errors,
        isSettingCustomDepartureName,
        isSettingCustomArrivalName,
        transportsIsCollapsed,
        userHasSetArrival,

        artist: Artist.stateHelpers.getCurrent(state),
        modes: PickupMode.stateHelpers.getAll(state),
        drivers: Driver.stateHelpers.getAllForCurrentContext(state),
        vehicles: Vehicle.stateHelpers.getAllForCurrentContext(state),
        vehicleTypes: VehicleType.stateHelpers.getAll(state),
        locations: EventLocations.stateHelpers.getAllForCurrentContext(state),
        bookingAttendees: BookingAttendees.stateHelpers.getAllForCurrentContext(state),
        transports: Transport.stateHelpers.getAllForCurrentContext(state),
        permissions: Event.stateHelpers.getUserPermissions(state),
        accountPermissions: Account.stateHelpers.getUserPermissions(state),
    };
};

const toAPIValues = values => ({
    ...values,
    departure: reformatISOString(values.departure),
    arrival: reformatISOString(values.arrival),
    duration: typeof values.duration !== 'undefined' ? values.duration * 60 : undefined
});

const mapDispatchToProps = (dispatch) => ({
    onChangeValue: (key, value) => {
        dispatch(State.setViewStateValue(`values.${key}`, value));
        if (key === 'arrival') {
            dispatch(State.setViewStateValue('userHasSetArrival', true));
        }
    },
    onChangeDeparture: (departure, arrival, duration, userHasSetArrival = false) => {
        dispatch(State.setViewStateValue('values.departure', departure));
        if (!userHasSetArrival) {
            const mDeparture = moment.utc(departure, moment.ISO_8601);
            if (typeof duration !== 'undefined') {
                mDeparture.add(duration, 'minutes');
            } else {
                mDeparture.add(60, 'minutes');
            }
            dispatch(State.setViewStateValue('values.arrival', mDeparture.toISOString()));
        }
    },
    onSelectDepartureLocation: (value) => {
        dispatch(State.setViewStateValue('values.departureLocation', value));
    },
    onSelectArrivalLocation: (eventId, departureLocation, arrivalLocation, departure) => {
        dispatch(State.setViewStateValue('values.arrivalLocation', arrivalLocation));
        dispatch(EventRoutes.actions.fetchDirections(eventId, departureLocation, arrivalLocation))
            .then(({ response, error }) => {
                if (typeof error === 'undefined') {
                    const { distance, duration, meetingPoint } =
                        EventRoutes.stateHelpers.getOne(response, response.result);
                    let arrival;
                    if (typeof duration !== 'undefined' && typeof departure !== 'undefined') {
                        const mDeparture = moment.utc(departure, moment.ISO_8601);
                        mDeparture.add(duration, 'minutes');
                        arrival = mDeparture.toISOString();
                    }
                    dispatch(State.mergeViewStateValue('values', {
                        distance,
                        meetingPoint,
                        duration,
                        arrival
                    }));
                }
            })
    },
    onToggleSetCustomDepartureName: (setCustomName) => {
        dispatch(State.setViewStateValue('isSettingCustomDepartureName', setCustomName));
        dispatch(State.deleteViewStateValue(setCustomName ? 'values.departureLocation' : 'values.departureLocationName'));
    },
    onToggleSetCustomArrivalName: (setCustomName) => {
        dispatch(State.setViewStateValue('isSettingCustomArrivalName', setCustomName));
        dispatch(State.deleteViewStateValue(setCustomName ? 'values.arrivalLocation' : 'values.arrivalLocationName'));
    },
    onToggleTransportsCollapse: (isCollapsed) => {
        dispatch(State.setViewStateValue('transportsIsCollapsed', isCollapsed));
    },
    onSelectPassenger: (selectedPassengers = [], attendeeId, isSelected) => {
        const passengers = isSelected ?
            [...selectedPassengers, attendeeId] :
            selectedPassengers.filter(id => id !== attendeeId);
        dispatch(State.setViewStateValue('values.passengers', passengers));
        dispatch(State.setViewStateValue('values.passengerAmount', passengers.length));
    },
    onSelectAllPassengers: (bookingAttendees = [], isSelectingAll) => {
        const passengers = isSelectingAll ?
            bookingAttendees.map(({ id }) => id) :
            [];
        dispatch(State.setViewStateValue('values.passengers', passengers));
        dispatch(State.setViewStateValue('values.passengerAmount', passengers.length));
    },
    onCreate: (createAnother, accountId, eventId, bookingId, values, departureName, arrivalName, redirect) => {
        dispatch((State.setViewStateValue('isSaving', true)));
        const apiValues = toAPIValues(values);
        dispatch(Pickup.actions.createOne(accountId, eventId, bookingId, apiValues))
            .then(({ error, response }) => {
                if (typeof error !== 'undefined') {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.showErrorPopup(FAILED_TO_CREATE_POPUP(departureName, arrivalName)));
                    dispatch(State.mergeViewState({
                        errors: errors,
                        isSaving: false
                    }));
                } else if (createAnother) {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_CREATE_ANOTHER_POPUP(departureName, arrivalName)));
                    dispatch(State.mergeViewState({
                        errors: {},
                        values: {},
                        isSaving: false
                    }));
                    dispatch(Transport.thunks.fetchAllForCurrentContext());
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_CREATE_POPUP(departureName, arrivalName)));
                    if (typeof redirect === 'undefined') {
                        dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
                    } else {
                        dispatch(actions.navigate.pageReload(redirect))
                    }
                }
            });
    },
    onOpenCreateDriverModal: () => {
        dispatch(DriverModalState.mergeCreateModalState({
            isOpened: true,
            values: {},
            errors: {}
        }))
    },
    onCreateDriverSuccess: (driverId) => {
        dispatch(State.setViewStateValue('values.driver', driverId));
    },
    onOpenCreateVehicleModal: () => {
        dispatch(CreateVehicleModalState.open())
    },
    onCreateVehicleSuccess: (vehicleId) => {
        dispatch(State.setViewStateValue('values.vehicle', vehicleId));
    },
    onBack: (redirect) => {
        if (typeof redirect === 'undefined') {
            dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
        } else {
            dispatch(actions.navigate.pageReload(redirect))
        }
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onChangeDeparture: (departure) => {
        const { values = {}, userHasSetArrival } = stateProps;
        dispatchProps.onChangeDeparture(departure, values.arrival, values.duration, userHasSetArrival);
    },
    onSelectPassenger: (attendeeId, isSelected) => {
        const { values = {} } = stateProps;
        dispatchProps.onSelectPassenger(values.passengers, attendeeId, isSelected);
    },
    onSelectAllPassengers: (isSelectingAll) => {
        const { bookingAttendees } = stateProps;
        dispatchProps.onSelectAllPassengers(bookingAttendees, isSelectingAll);
    },
    onSelectArrivalLocation: (value) => {
        const { eventId, values } = stateProps;
        dispatchProps.onSelectArrivalLocation(eventId, values.departureLocation, value, values.departure);
    },
    onCreate: (createAnother = false) => {
        const {
            accountId,
            eventId,
            bookingId,
            values,
            locations,
            isSettingCustomDepartureName,
            isSettingCustomArrivalName
        } = stateProps;
        const { redirect } = ownProps;

        const selectedDepartureLocation = locations.find(({ id }) => id === values.departureLocation) || {};
        const selectedArrivalLocation = locations.find(({ id }) => id === values.arrivalLocation) || {};
        const departureName = isSettingCustomDepartureName ?
            values.departureLocationName :
            selectedDepartureLocation.name;
        const arrivalName = isSettingCustomArrivalName ?
            values.arrivalLocationName :
            selectedArrivalLocation.name;

        dispatchProps.onCreate(createAnother, accountId, eventId, bookingId, values, departureName, arrivalName, redirect);
    },
    onSaveModal: () => {
        const {  modalValues } = stateProps;
        dispatchProps.onSaveModal(modalValues);
    },
    onBack: () => {
        const { redirect } = ownProps;
        dispatchProps.onBack(redirect);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateView);
