import * as Schemas from './schemas'
import {
    getCurrentContextId,
    getCurrentContextEntity,
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    ENTITY_CONTEXT_ID_KEY,
} = Schemas;

export const getCurrent = (state) =>
    getCurrentContextEntity(state, ENTITY_REDUCER_KEY, ENTITY_CONTEXT_ID_KEY);

export const getCurrentId = (state) =>
    getCurrentContextId(state, ENTITY_CONTEXT_ID_KEY);

export const isFetchingCurrent = (state) =>
    !! (getCurrent(state) || {}).isFetching;
