import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    filterParam,
    limitAllParam as pageSizeAllParam,
} from 'app/react/state/middleware/api'

const rootAll = (eventId) =>
    `events/${eventId}/artist-types`;
const rootOne = (eventId, artistTypeId) =>
    `${rootAll(eventId)}/${artistTypeId}`;
const duplicateRootOne = (eventId, artistTypeId) =>
    `${rootAll(eventId)}/${artistTypeId}/duplicate`;

export const fetchAll = (
    eventId
) => oneLineTrim`
    ${rootAll(eventId)}?
    ${pageSizeAllParam}
`;

export const fetchOne = (
    eventId,
    artistTypeId
) => oneLineTrim`
    ${rootOne(eventId, artistTypeId)}
`;

export const createOne = (
    eventId
) => oneLineTrim`
    ${rootAll(eventId)}
`;

export const updateOne = (
    eventId,
    artistTypeId
) => oneLineTrim`
    ${rootOne(eventId, artistTypeId)}
`;

export const deleteOne = (
    eventId,
    artistTypeId
) => oneLineTrim`
    ${rootOne(eventId, artistTypeId)}
`;

export const duplicateOne = (
    eventId,
    artistTypeId
) => oneLineTrim`
    ${duplicateRootOne(eventId, artistTypeId)}
`;