import React from 'react';
import {
    Card,
    CollapsibleCard,
    Grid,
} from 'ui-kit';
import { beatswitchContextPropTypes } from 'app/react/components/util/connectBeatswitchContext';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { UpdateButtonRow, Tooltip } from 'app/react/components/_index';
import ContentHeader from 'app/react/views/event/artists/bookings/components/layout/DetailsContentHeader';
import PickupFields from '../../components/form/PickupFields';
import RouteFields from '../../components/form/RouteFields';
import PassengersFields from '../../components/form/PassengersFields';
import TransportsSchedule from '../create/TransportsSchedule';
import CreateVehicleModal from 'app/react/views/event/artists/logistics/vehicles/components/modals/stateful/CreateModalContainer';
import CreateDriverModal from 'app/react/views/event/artists/logistics/drivers/components/CreateModalContainer';

const { Content } = ContentWrapper;
const { Header, Footer } = Card;
const { Title, Action } = Header;
const { Cell } = Grid;

const MAX_WIDTH = '1100px';
const CARD_TITLE = 'Pickup';
const PICKUPS_SCHEDULE_TITLE = 'Transports';
const ROUTE_TITLE = 'Route';
const PASSENGERS_TITLE = 'Passengers';
const SHOW_HIDE_TRANSPORTS_TOOLTIP = 'Show/Hide Transports Schedule';

const PickupUpdateView = ({
    store,
    eventId,
    bookingId,
    isFetching = false,
    isFetchingTransports = false,
    dateFormat,
    timeFormat,

    artistName,
    artistImageSize80,
    artistCountryCode,
    bookingStatusTitle,
    bookingStatusColorType,
    permissions = {},
    accountPermissions = {},

    values = {},
    errors = {},
    isSettingCustomDepartureName = false,
    isSettingCustomArrivalName  = false,
    transportsIsCollapsed = true,

    artist = {},
    modes = [],
    drivers = [],
    vehicles = [],
    locations = [],
    route = [],
    bookingAttendees = [],
    transports = [],

    onChangeValue = () => {},
    onChangeDeparture = () => {},
    onSelectDepartureLocation = () => {},
    onSelectArrivalLocation = () => {},
    onToggleSetCustomDepartureName = () => {},
    onToggleSetCustomArrivalName = () => {},
    onToggleTransportsCollapse = () => {},
    onOpenCreateDriverModal = () => {},
    onOpenCreateVehicleModal = () => {},
    onSelectPassenger = () => {},
    onSelectAllPassengers = () => {},
    onUpdate = () => {},
    onCreateDriverSuccess = () => {},
    onCreateVehicleSuccess = () => {},
    onBack = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            title="Edit Pickup"
            bookingId={bookingId}
            eventId={eventId}
            bookingName={artist.name}
            artistName={artistName}
            artistImageSize80={artistImageSize80}
            artistCountryCode={artistCountryCode}
            bookingStatusTitle={bookingStatusTitle}
            bookingStatusColorType={bookingStatusColorType}
            permissions={permissions}
            accountPermissions={accountPermissions}
            onClickBack={onBack}
        />
        <Content isLoading={isFetching}>
            <div style={{ maxWidth: MAX_WIDTH }}>
                <CollapsibleCard
                    isCollapsed={true}
                    renderHeader={({ onClickTitle }) => (
                        <Title onClick={onClickTitle}>{PICKUPS_SCHEDULE_TITLE}</Title>
                    )}
                    renderContent={() => (
                        <TransportsSchedule
                            transports={transports}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            currentPickupId={values.id}
                            isFetchingTransports={isFetchingTransports}
                        />
                    )}
                />
                <Grid>
                    <Cell>
                        <Card>
                            <Header>
                                <Title>{CARD_TITLE}</Title>
                            </Header>
                            <Card.Content>
                                <PickupFields
                                    values={values}
                                    errors={errors}
                                    dateFormat={dateFormat}
                                    timeFormat={timeFormat}
                                    modes={modes}
                                    drivers={drivers}
                                    vehicles={vehicles}
                                    locations={locations}
                                    route={route}
                                    isSettingCustomDepartureName={isSettingCustomDepartureName}
                                    isSettingCustomArrivalName={isSettingCustomArrivalName}
                                    onChangeValue={onChangeValue}
                                    onChangeDeparture={onChangeDeparture}
                                    onSelectDepartureLocation={onSelectDepartureLocation}
                                    onSelectArrivalLocation={onSelectArrivalLocation}
                                    onToggleSetCustomDepartureName={onToggleSetCustomDepartureName}
                                    onToggleSetCustomArrivalName={onToggleSetCustomArrivalName}
                                    onOpenCreateDriverModal={onOpenCreateDriverModal}
                                    onOpenCreateVehicleModal={onOpenCreateVehicleModal}
                                />
                            </Card.Content>
                            <Footer />
                        </Card>
                        <Card>
                            <Header>
                                <Title>{ROUTE_TITLE}</Title>
                            </Header>
                            <Card.Content>
                                <RouteFields
                                    values={values}
                                    errors={errors}
                                    onChangeValue={onChangeValue}
                                />
                            </Card.Content>
                            <Footer />
                        </Card>
                        <UpdateButtonRow
                            onUpdate={onUpdate}
                        />
                    </Cell>
                    <Cell
                        isAutoSized
                        width="300px"
                    >
                        <Card className="isSticky">
                            <Header>
                                <Title>{PASSENGERS_TITLE}</Title>
                            </Header>
                            <Card.Content>
                                <PassengersFields
                                    values={values}
                                    errors={errors}
                                    attendees={bookingAttendees}
                                    selectedVehicle={vehicles.find(vehicle => vehicle.id === values.vehicle)}
                                    onChangeValue={onChangeValue}
                                    onSelectPassenger={onSelectPassenger}
                                    onSelectAllPassengers={onSelectAllPassengers}
                                />
                            </Card.Content>
                            <Footer />
                        </Card>
                    </Cell>
                </Grid>
            </div>
        </Content>
        <CreateDriverModal
            canCreateAnother={false}
            onSaveSuccess={onCreateDriverSuccess}
        />
        <CreateVehicleModal
            canCreateAnother={false}
            onSaveSuccess={onCreateVehicleSuccess}
        />
    </ContentWrapper>
);

PickupUpdateView.propTypes = {
    ...beatswitchContextPropTypes
};

export default PickupUpdateView;
