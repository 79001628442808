import BaseView from 'app/backbone/components/Base.view'
import DataTable from 'app/backbone/components/tables/DataTable.view'
import './Checklist.css'

const DISABLED_CLASS = 'disabled';
const SELECT_ALL_TEXT = 'Select all';
const DESELECT_ALL_TEXT = 'Deselect all';

export default class ChecklistView extends BaseView {

    ui() {
        return {
            table: '#checklist-table',
            selectAllButton: '#select-all-bookings',
            sendReminderButton: '#send-reminder',
            sendReminderModal: '#send-reminder-modal',
            bookingCheckboxes: 'input[name="bookings[]"]:not([disabled])',
            filter: '.checklistFilter',
            row: '.checklistRow',
        };
    }

    events() {
        return {
            'click bookingCheckboxes': 'onClickBookingCheckbox',
            'click selectAllButton': 'onClickSelectAllButton',
            'click sendReminderButton': 'openModal',
            'change filter': 'applyFilter',
            'click row': 'goToBooking',
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.table = new DataTable();
        this.state = {
            areAllBookingCheckboxesSelected: false,
            areAnyBookingCheckboxesSelected: false,
        };
    }

    setState(options) {
        this.state = {
            ...this.state,
            ...options,
        };
        this.render();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.table, this.ui.table);
        this.renderSelectAllButton();
        this.renderSendReminderButton();
        return this.delegateEvents();
    }

    renderSelectAllButton() {
        const buttonText = this.state.areAllBookingCheckboxesSelected
            ? DESELECT_ALL_TEXT
            : SELECT_ALL_TEXT;
        this.ui.selectAllButton.html(buttonText);
    }

    renderSendReminderButton() {
        if (this.state.areAnyBookingCheckboxesSelected) {
            this.ui.sendReminderButton.removeClass(DISABLED_CLASS);
        } else {
            this.ui.sendReminderButton.addClass(DISABLED_CLASS);
        }
    }

    openModal({ target }) {
        if ($(target).hasClass(DISABLED_CLASS)) {
            return;
        }

        this.openModalFromDOM(this.ui.sendReminderModal);
    }

    onClickBookingCheckbox(e) {
        e.stopPropagation();
        this.setState({
            areAllBookingCheckboxesSelected: this.areAllBookingCheckboxesSelected(),
            areAnyBookingCheckboxesSelected: this.areAnyBookingCheckboxesSelected(),
        });
    }

    onClickSelectAllButton({ target: button }) {
        const isChecked = !this.state.areAllBookingCheckboxesSelected;
        this.toggleAllBookingCheckboxes(isChecked);
        this.setState({
            areAllBookingCheckboxesSelected: isChecked,
            areAnyBookingCheckboxesSelected: isChecked,
        });
    }

    applyFilter({ target: filter }) {
        window.location = filter.value;
    }

    goToBooking({ currentTarget: row }) {
        window.location = row.dataset.url;
    }

    toggleAllBookingCheckboxes(isChecked) {
        this.ui.bookingCheckboxes.each((key, bookingCheckbox) => {
            $(bookingCheckbox).prop('checked', isChecked);
        });
    }

    areAllBookingCheckboxesSelected() {
        let areAllSelected = true;

        this.ui.bookingCheckboxes.each((key, artist) => {
            if (!artist.checked) {
                areAllSelected = false;
                return;
            }
        });

        return areAllSelected;
    }

    areAnyBookingCheckboxesSelected() {
        let areAllSelected = false;

        this.ui.bookingCheckboxes.each((key, artist) => {
            if (artist.checked) {
                areAllSelected = true;
                return;
            }
        });

        return areAllSelected;
    }
}
