import { connect } from 'react-redux';
import { ConfirmModal } from 'app/react/components/_index';
import * as actions from 'app/react/state/actions/_index';
import * as Reservation from 'app/react/entities/events/reservations/index';
import * as State from './ModalState';

const TITLE = 'Delete reservation';
const TEXT = (name) => `Are you sure you want to delete reservation of <strong>${name}</strong>?`;
const BUTTON_TEXT =  'Yes, delete reservation';
const FAILED_POPUP = 'Failed to delete reservation.';
const SUCCESSFUL_POPUP = 'Reservation successfully deleted.';

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSyncing,
        reservation = {},
    } = State.getDeleteModalState(state);
    return {
        title: TITLE,
        text: TEXT(reservation.name),
        buttonText: BUTTON_TEXT,
        buttonClassName: 'alert',
        isOpened,
        isSyncing,
        reservation,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onConfirmed: () => {
        const { reservation } = stateProps;
        dispatchProps.onConfirmed(reservation.id);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch((State.setDeleteModalStateValue('isOpened', false)));
    },
    onConfirmed: (reservationId) => {
        dispatch((State.setDeleteModalStateValue('isSyncing', true)));
        dispatch(Reservation.thunks.deleteOneForCurrentContext(reservationId))
            .then(({ error }) => {
                if (typeof error !== 'undefined') {
                    dispatch(actions.showErrorPopup(FAILED_POPUP));
                    dispatch(State.mergeDeleteModalState({
                        isSyncing: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP));
                    dispatch(State.mergeDeleteModalState({
                        isOpened: false,
                        isSyncing: false
                    }));
                }
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ConfirmModal);
