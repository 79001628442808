import { Model }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';

@Model({
    key: 'auth.account'
})
class Account extends BaseModel {

    urlRoot() {
        return 'api/accounts';
    }

    fetch() {
        return this.fetchFromDOMOrServer();
    }

    getDateFormat(type) {
        const dataTime = this.get('date_time_format');
        return dataTime ? dataTime.date[type] : 'L';
    }

}

export {
    Account as Model
};
