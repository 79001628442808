var Backgrid = require('backgrid');

var template = require('./CheckboxCell.hbs');

var CheckboxCell = Backgrid.Cell.extend({

    template,

    className: 'checkbox-cell input',

    events: {
        'change input[type=checkbox]': 'onChangeCheckbox'
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                isDisabled: this.isDisabled(),
                isVisible: this.isVisible(),
                isChecked: this.isChecked(),
                isEditing: this.isEditing()
            }
        };
    },

    isDisabled() {
        return false;
    },

    isVisible() {
        return true;
    },

    isChecked() {
        return !! this.model.get(this.column.get('name'));
    },

    isEditing() {
        return true;
    },

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        this.model.set(this.column.get('name'), checked);
        this.render();
    }
});

module.exports = CheckboxCell;
