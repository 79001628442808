import BaseController from 'app/backbone/helpers/BaseController';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import CompanyAccrediteeCreateView from 'app/backbone/views/production_website/accreditation/accreditees/CompanyAccrediteeCreate.view';
import CompanyAccrediteeUpdateView from 'app/backbone/views/production_website/accreditation/accreditees/CompanyAccrediteeUpdate.view';
import * as Routes from './routes';

// Backbone Controller
export default class AccrediteeController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.accrediteesCreate(), null, this.create.bind(this));
        this.router.route(Routes.accrediteesUpdate(), null, this.update.bind(this));
    }

    registerCommonData(eventId) {
        const { data } = this;
        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const company = data.register('eventCompany', EventCompany.Model, { event });
        const periods = data.register('allPeriods', Period.Collection, { event });
        const accreditees = data.register('pagedCompanyAccreditees', CompanyAccreditee.PageableCollection, { event, company });

        company.getIdFromDOM('event_company');
        company.setIncludes([
            'profiles.days',
            'profiles.available_days',
            'profiles.available_items.available_days'
        ]);
        periods.setIncludes(['days']);
        accreditees.setIncludes(['profiles', 'items.groups', 'permissions']);

        return { account, event, company, periods, accreditees };
    }

    create(eventId) {
        const { app } = this;
        app.renderSyncingView();

        const { account, event, company, periods, accreditees } = this.registerCommonData(eventId);

        this.when(
            account.fetch(),
            event.fetch(),
            company.fetch(),
            periods.fetch()
        ).done(() => {
            app.renderContentView(new CompanyAccrediteeCreateView({ company, periods, accreditees }));
        });
    }

    update(eventId, accrediteeId) {
        const { app, data } = this;
        app.renderSyncingView();

        const { account, event, company, periods, accreditees } = this.registerCommonData(eventId);
        const accreditee = data.register('companyAccreditee', CompanyAccreditee.Model, { company });

        accreditee.set('id', parseInt(accrediteeId, 10));
        accreditee.setIncludes([
            'profiles.days',
            'profiles.granted_days',
            'profiles.available_profile.available_items.available_days',
            'items.granted_days',
            'items.granted_profiles.available_profile.available_items.available_days'
        ]);


        this.when(
            account.fetch(),
            event.fetch(),
            company.fetch(),
            periods.fetch(),
            accreditee.fetch()
        ).done(() => {
            app.renderContentView(new CompanyAccrediteeUpdateView({ accreditee, company, periods, accreditees }));
        });
    }

}
