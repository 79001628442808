import React from 'react';
import PropTypes from 'prop-types';
import TimeSlotEditRow from './rows/Edit';
import TimeSlotRow from './rows/Default';
import TimeSlotHeaderRow from './rows/Header';
import TimeSlotFooterRow from './rows/Footer';
import TimeSlotSavingRow from './rows/Saving';
import ChangeoverRow from './rows/Changeover';
import SetTimeRow from './rows/SetTime';

const PerformanceTimeSlotsTable = ({
    dateFormat = 'L',
    timeFormat = 'HH:mm',
    timeSlots = [],
    timeSlotTypes = [],
    editingTimeSlots = [],
    performance = {},
    newTimeSlot,
    isCreatingOtherTimeSlot = false,
    onUpdateTimeSlot = () => {},
    onDeleteTimeSlot = () => {},
    onCreateNewTimeSlot = () => {},
    onChangeNewTimeSlotValue = () => {},
    onChangeTimeSlotValue = () => {},
    onToggleCreateOtherTimeSlot = () => {},
    onSaveTimeSlot = () => {},
    onCancelTimeSlot = () => {},
    onSaveNewTimeSlot = () => {},
    onCancelNewTimeSlot = () => {}
}) => (
    <table className="actions-right no-margin-tb isLarge">
        <thead>
            <TimeSlotHeaderRow />
        </thead>
        <tbody>
            {(
                typeof performance.changeover !== 'undefined' &&
                performance.changeover !== null
            ) ?
                <ChangeoverRow
                    start={performance.start}
                    changeover={performance.changeover}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                /> :
                null
            }
            <SetTimeRow
                start={performance.start}
                end={performance.end}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
            />
            {timeSlots.map((timeSlot) => {
                const editingTimeSlot = editingTimeSlots.find(({ id }) => id === timeSlot.id)
                if (typeof editingTimeSlot !== 'undefined') {
                    if (editingTimeSlot.isSaving) {
                        return <TimeSlotSavingRow key={editingTimeSlot.id} />;
                    }
                    return (
                        <TimeSlotEditRow
                            key={editingTimeSlot.id}
                            isCreatingOther={isCreatingOtherTimeSlot}
                            timeSlot={editingTimeSlot}
                            errors={editingTimeSlot.errors}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            timeSlotTypes={timeSlotTypes}
                            onChangeValue={onChangeTimeSlotValue}
                            onToggleCreateOther={onToggleCreateOtherTimeSlot}
                            onSave={onSaveTimeSlot}
                            onCancel={onCancelTimeSlot}
                        />
                    );
                }
                return (
                    <TimeSlotRow
                        key={timeSlot.id}
                        timeSlot={timeSlot}
                        dateFormat={dateFormat}
                        timeFormat={timeFormat}
                        onUpdate={onUpdateTimeSlot}
                        onDelete={onDeleteTimeSlot}
                    />
                );
            })}
            {(() => {
                if (typeof newTimeSlot !== 'undefined') {
                    if (newTimeSlot.isSaving) {
                        return <TimeSlotSavingRow />;
                    }
                    return (
                        <TimeSlotEditRow
                            isNew
                            isCreatingOther={isCreatingOtherTimeSlot}
                            timeSlot={newTimeSlot}
                            errors={newTimeSlot.errors}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            timeSlotTypes={timeSlotTypes}
                            onChangeValue={onChangeNewTimeSlotValue}
                            onToggleCreateOther={onToggleCreateOtherTimeSlot}
                            onSave={onSaveNewTimeSlot}
                            onCancel={onCancelNewTimeSlot}
                        />
                    );
                }
                return (
                    <TimeSlotFooterRow
                        onCreateNew={onCreateNewTimeSlot}
                    />
                );
            })()}
        </tbody>
    </table>
);

PerformanceTimeSlotsTable.propTypes = {
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    timeSlots: PropTypes.array,
    timeSlotTypes: PropTypes.array,
    newTimeSlot: PropTypes.object,
    isCreatingOtherTimeSlot: PropTypes.bool,
    performance: PropTypes.object,
    editingTimeSlots: PropTypes.array,
    onUpdateTimeSlot: PropTypes.func,
    onDeleteTimeSlot: PropTypes.func,
    onCreateNewTimeSlot: PropTypes.func,
    onChangeNewTimeSlotValue: PropTypes.func,
    onChangeTimeSlotValue: PropTypes.func,
    onToggleCreateOtherTimeSlot: PropTypes.func,
    onSaveTimeSlot: PropTypes.func,
    onCancelTimeSlot: PropTypes.func,
    onSaveNewTimeSlot: PropTypes.func,
    onCancelNewTimeSlot: PropTypes.func
};

export default PerformanceTimeSlotsTable;
