import Toastr from 'toastr';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PageableTable from 'app/backbone/components/tables/PageableTable.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import SelectableRow from 'app/backbone/components/tables/rows/SelectableRow.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import RequestStatusCell from './cells/RequestStatusCell.view';
import DenyReasonModal from '../modals/DenyReasonModal.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';

export default class EventCompanyTable extends PageableTable {

    className() {
        return 'tableWithTopAndBottomBar';
    }

    optionTypes() {
        return {
            companies: OptionTypes.instanceOf(EventCompany.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.row = SelectableRow;
        this.placeholderText = this.getPlaceholderText();
        this.collection = this.companies;
        this.placeholderHeight = 100;
        // Subviews
        this.confirmModal = this.initConfirmModal();
        this.denyReasonModal = this.initDenyReasonModal();

        super.initialize(options);

        this.listenTo(this.companies, 'backgrid:select', this.details);
        this.listenTo(this.companies, 'backgrid:update', this.update);
        this.listenTo(this.companies, 'backgrid:delete', this.delete);
        this.listenTo(this.companies, 'backgrid:send-login', this.sendLogin);
        this.listenTo(this.companies, 'backgrid:approve', this.approve);
        this.listenTo(this.companies, 'backgrid:deny', this.deny);
        this.listenTo(this.companies, 'backgrid:send-tickets', this.sendTickets);
    }

    initColumns() {
        const actions = this.actions;
        return [{
            name: 'name',
            label: 'Company',
            headerCell: CellFactory.createHeaderCell(),
            cell: 'string',
            sortable: true,
            editable: false,
            sortValue() {
                return 'company.name';
            }
        }, {
            name: 'fullname',
            label: 'Contact',
            headerCell: CellFactory.createHeaderCell(),
            cell: CellFactory.createSubModelCell(null, null, 'primary_contact'),
            sortable: true,
            editable: false,
            sortValue() {
                return 'primary_contact.firstname';
            }
        }, {
            name: 'name',
            label: 'Status',
            headerCell: CellFactory.createHeaderCell(null, 'center'),
            cell: RequestStatusCell,
            sortable: true,
            editable: false,
            sortValue() {
                return 'request_status.key';
            }
        }, {
            name: 'fullname',
            label: 'Responsible',
            headerCell: CellFactory.createHeaderCell(),
            cell: CellFactory.createSubModelCell(null, null, 'responsible_user'),
            sortable: false,
            editable: false
        }, {
            name: 'actions',
            label: 'Actions',
            headerCell: CellFactory.createHeaderCell('200px'),
            cell: CellFactory.createActionCell(actions),
            sortable: false,
            editable: false
        }];
    }

    initActions() {
        return [{
            name: 'approve',
            icon: 'bs-icon-hand-like',
            tooltip: 'Approve request',
            event: {
                name: 'backgrid:approve',
                type: 'collection'
            },
            canRender: (company) => company.canApprove()
        }, {
            name: 'deny',
            icon: 'bs-icon-hand-unlike',
            tooltip: 'Deny request',
            event: {
                name: 'backgrid:deny',
                type: 'collection'
            },
            canRender: (company) => company.canDeny()
        }, {
            name: 'send-login',
            icon: 'bs-icon-user-gear',
            tooltip: 'Send login',
            event: {
                name: 'backgrid:send-login',
                type: 'collection'
            }
        }, {
            name: 'send-tickets',
            icon: 'bs-icon-ticket',
            tooltip: 'Send tickets',
            event: {
                name: 'backgrid:send-tickets',
                type: 'collection'
            },
            canRender: (company) => company.canSendTickets()
        }, {
            name: 'update',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            event: {
                name: 'backgrid:update',
                type: 'collection'
            }
        }, {
            name: 'remove',
            icon: 'bs-icon-cross',
            tooltip: 'Remove',
            event: {
                name: 'backgrid:delete',
                type: 'collection'
            }
        }];
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    initDenyReasonModal() {
        return new DenyReasonModal();
    }

    details(company, newTab = false) {
        const eventId = company.event.id;
        const companyId = company.id;
        navigate(`/events/${eventId}/accreditation/companies/${companyId}/details`, { newTab });
    }

    update(company, newTab = false) {
        const eventId = company.event.id;
        const companyId = company.id;
        navigate(`/events/${eventId}/accreditation/companies/${companyId}/edit`, { newTab });
    }

    delete(company) {
        const name = company.get('name');
        this.confirmModal.open({
            title: 'Remove company',
            body: `Are you sure you want to remove <strong>${name}</strong>?`,
            confirmText: 'Yes, remove',
            confirmClass: 'danger',
            autoClose: true,
            syncingText: `Removing ${name}...`,
            confirm: () => { company.destroy({ wait: true }); }
        });
    }

    sendLogin(company) {
        const email = company.primary_contact.get('email');
        this.confirmModal.open({
            title: 'Send login',
            body: `Are you sure you want to send this login to <strong>${email}</strong>?`,
            confirmClass: 'submit',
            confirmText: 'Send',
            autoClose: true,
            syncingText: `Sending login to ${email}...`,
            confirm: () => {
                company.sendLogin()
                    .done(() => {
                        Toastr.success(`A login email was successfully sent to <strong>${email}</strong>.`);
                        company.collection.fetch({ bustCache: true });
                    })
                    .fail(() => {
                        Toastr.error(`Failed to send email to <strong>${email}</strong>.
                        Please try again later or contact our support.`);
                    });
            }
        });
    }

    approve(company) {
        const name = company.get('name');
        const email = company.primary_contact.get('email');
        this.confirmModal.open({
            title: 'Approve request',
            body: `
                Are you sure you want to approve the request made by <strong>${name}</strong>?
                An approval email will be send to <strong>${email}</strong>.`,
            confirmText: 'Approve',
            autoClose: true,
            confirmClass: 'success',
            syncingText: `Approving request to ${name}...`,
            confirm: () => {
                company.approve().done(() => {
                    company.collection.fetch({ bustCache: true });
                });
            }
        });
    }

    deny(company) {
        const name = company.get('name');
        const email = company.primary_contact.get('email');
        this.denyReasonModal.open({
            title: 'Deny request',
            body: `
                Are you sure you want deny the request made by <strong>${name}</strong>?
                A denied email will be sent to <strong>${email}</strong> containing the reason below.`,
            confirmText: 'Deny',
            autoClose: true,
            confirmClass: 'alert',
            syncingText: `Deny request to ${name}...`,
            confirm: (reason) => {
                company.deny(reason).done(() => {
                    company.collection.fetch({ bustCache: true });
                });
            }
        });
    }

    sendTickets(company) {
        const email = company.primary_contact.get('email');
        this.confirmModal.open({
            title: 'Send tickets',
            body: `Are you sure you want to send tickets for this company to <strong>${email}</strong>?`,
            confirmClass: 'submit',
            confirmText: 'Send',
            autoClose: true,
            syncingText: `Sending Tickets to ${email}...`,
            confirm: () => {
                company.sendTickets()
                    .done(() => {
                        Toastr.success(`The tickets were successfully sent to <strong>${email}</strong>.`);
                        company.collection.fetch({ bustCache: true });
                    })
                    .fail(() => {
                        Toastr.error(`Failed to send tickets to <strong>${email}</strong>.
                        Please try again later or contact our support.`);
                    });
            }
        });
    }

    getPlaceholderText() {
        return 'No companies have been added for this event yet.';
    }

    hasSearch() {
        return true;
    }
}
