import React from 'react';
import { Card, InputGroup } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { CreateButtonRow, UpdateButtonRow } from 'app/react/components/_index';
import SearchSelectContactsContainer from 'app/react/views/account/contacts/components/SearchSelectContactsContainer';
import ContentHeader from '../../components/layout/DetailsSubEntityContentHeader';
import CrewFields from './CrewFields';

const { Content } = ContentWrapper;
const { Header, Footer } = Card;
const { Title } = Header;

const MAX_WIDTH = '650px';
const CARD_TITLE = 'Crew Member';
const CREATE_CONTENT_TITLE = 'Create Crew Member';
const UPDATE_CONTENT_TITLE = 'Update Crew Member';
const SELECT_CONTACT_LABEL = 'Select Existing Contact';

const CrewView = ({
    store,
    isCreating = true,
    redirect,

    isFetching = false,
    dateFormat,
    timeFormat,
    artistName,

    values = {},
    errors = {},
    arePassportsEnabled,
    dynamicFields = {},
    selectedPassport,
    ticketTypeOptions,

    onChangeValue = () => {},
    onChangePassport = () => {},
    onSelectContact = () => {},
    onSave = () => {},
    onBack = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            title={isCreating ? CREATE_CONTENT_TITLE : UPDATE_CONTENT_TITLE}
            bookingName={artistName}
            backButtonText={typeof redirect === 'undefined' ? 'Booking' : 'Back'}
            onClickBack={onBack}
        />
        <Content isLoading={isFetching}>
            <div style={{ maxWidth: MAX_WIDTH }}>
                <Card>
                    <Header>
                        <Title>{CARD_TITLE}</Title>
                    </Header>
                    <Card.Content>
                        { isCreating &&
                            <InputGroup messages={errors.contact}>
                                <label>
                                    {SELECT_CONTACT_LABEL}
                                </label>
                                <SearchSelectContactsContainer
                                    onChange={onSelectContact}
                                />
                            </InputGroup>
                        }
                        <CrewFields
                            values={values}
                            errors={errors}
                            arePassportsEnabled={arePassportsEnabled}
                            dynamicFields={dynamicFields}
                            requiredFieldNames={['firstname']}
                            selectedPassport={selectedPassport}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            onChangeValue={onChangeValue}
                            onChangePassport={onChangePassport}
                            ticketTypeOptions={ticketTypeOptions}
                        />
                    </Card.Content>
                    <Footer />
                </Card>
                { isCreating ?
                    <CreateButtonRow
                        canCreateAnother
                        onCreate={() => onSave(false)}
                        onCreateAnother={() => onSave(true)}
                    /> :
                    <UpdateButtonRow
                        onUpdate={onSave}
                    />
                }
            </div>
        </Content>
    </ContentWrapper>
);

export default CrewView;
