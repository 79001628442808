import React from 'react'
import PropTypes from 'prop-types'
import {
    Dropdown,
    SmartProgressCircle,
    Spinner,
    Tooltip,
} from 'ui-kit'
import './TaskProgressCircle.module.css'

const TaskProgressCircle = ({
    bookingId,
    tasks,
    onToggleTask,
}) => {
    const completeTasks = tasks.filter(task => task.isCompleted);
    let percentageCompleted = 0;
    if (tasks.length !== 0 && completeTasks.length !== 0) {
        percentageCompleted = Math.round((completeTasks.length / tasks.length) * 100);
    }

    return (
        <Dropdown
            renderRef={({ ref, refProps }) => (
                <div
                    ref={ref}
                    {...refProps}
                    styleName="dropdownTrigger"
                >
                    <Tooltip content={`${percentageCompleted}%`}>
                        <SmartProgressCircle progressPct={percentageCompleted} size={24} />
                    </Tooltip>
                </div>
            )}
            renderContent={({ handleClose }) => (
                <div styleName="taskContainer">
                    {tasks.map(({ id, name, isCompleted, isLoading = false }) => (
                        <label key={id}>
                            {isLoading
                                ? <span styleName="spinnerContainer">
                                    <Spinner size="14" />
                                </span>
                                : <input
                                    type="checkbox"
                                    checked={isCompleted}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => onToggleTask(bookingId, id)}
                                />
                            }
                            {name}
                        </label>
                    ))}
                </div>
            )}
        />
    );
};

TaskProgressCircle.defaultProps = {
    tasks: [],
    onToggleTask: () => {},
};

TaskProgressCircle.propTypes = {
    tasks: PropTypes.array,
};

export default TaskProgressCircle;
