import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

const Colorpicker = ({
	label,
	color,
	onChange,
	setShowColorpicker,
	showColorPicker,
	type,
}) => {
	return (
		<div className='colorpicker-container'>
			<h4 className='production-website__label'>{label}</h4>
			<div className='colorpicker__input-container'>
				<input onChange={(evt) => onChange(evt.target.value)} value={color} />
				<button
					className='colorpicker__color-button'
					style={{ backgroundColor: color }}
					onClick={() => setShowColorpicker(type, !showColorPicker)}
				/>
			</div>
			{showColorPicker && (
				<div className='colorpicker__picker'>
					<ChromePicker
						color={color}
						onChange={(color) => onChange(color.hex)}
						disableAlpha
					/>
				</div>
			)}
		</div>
	);
};

Colorpicker.propTypes = {
	label: PropTypes.string,
	color: PropTypes.string,
	showColorPicker: PropTypes.bool,
	type: PropTypes.oneOf(['primaryColor', 'secondaryColor', 'topbarColor']),
	onChange: PropTypes.func,
	setShowColorpicker: PropTypes.func,
};

export default Colorpicker;
