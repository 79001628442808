import { connect } from 'react-redux';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Performance from 'app/react/entities/events/performances/index';
import * as Stage from 'app/react/entities/events/stages/index';
import * as Event from 'app/react/entities/events/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import SchedulerView, {
    PERFORMANCE_SCHEDULER_CARD_ID,
    DELETE_CONFIRM_MODAL_ID,
    PERFORMANCE_SCHEDULER_ID,
    REORDER_RESOURCES_CONFIRM_MODAL_ID
} from './SchedulerView';
import { reformatStartAndEnd } from './utils';
import { CREATE_MODAL_ID } from './modals/create/CreateModal';
import { openUpdateModal, mergeUpdateModalState } from './modals/update/ModalState';
import { setModalState, openDetailsModal, mergeDetailsModalState } from './modals/details/ModalState';

const DELETE_PERFORMANCE_TITLE = 'Delete performance';
const DELETE_PERFORMANCE_TEXT = name =>
    `Are you sure you want to delete <strong>${name}?</strong>`;
const DELETE_PERFORMANCE_BUTTON_TEXT = () =>
    'Yes, delete';

const REORDER_RESOURCES_TITLE = 'Move stage';
const REORDER_RESOURCES_TEXT = `
    <p>
        Changing the order of the stages on the timetable will not only be visible to you,
        but also to every other user who has access to the timetable.
    <p>
    <p>
        Please note this will also affect the order of how the Timetable data is published 
        in connected apps and platforms.
    </p>`
const REORDER_RESOURCES_BUTTON_TEXT = 'Yes, I understand.';
const USER_CONFIRMED_REORDERING_STAGES_KEY = 'userConfirmedReorderingStages'

const POPUP_MESSAGES = {
    FAILED_TO_CREATE: (performanceName) =>
        `Failed to create performance <strong>${performanceName}</strong>.`,
    SUCCESSFUL_CREATE: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully created.`,
    FAILED_TO_DELETE: (performanceName) =>
        `Failed to delete performance <strong>${performanceName}</strong>`,
    SUCCESSFUL_DELETE: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully deleted.`,
    FAILED_TO_UPDATE: (performanceName, errorMessage) =>
        `Failed to update performance <strong>${performanceName}</strong>. ${errorMessage}`,
    SUCCESSFUL_LOCK: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully locked.`,
    SUCCESSFUL_UNLOCK: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully unlocked.`,
    SUCCESSFUL_PUBLISH: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully published.`,
    SUCCESSFUL_UNPUBLISH: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully unpublished.`,
    FAILED_TO_PUBLISH: (performanceName, errorMessage) =>
        `Failed to publish performance <strong>${performanceName}</strong>. ${errorMessage}`,
    FAILED_TO_UNPUBLISH: (performanceName, errorMessage) =>
        `Failed to unpublish performance <strong>${performanceName}</strong>. ${errorMessage}`
};

const mapStateToProps = (state) => {
    const { manageTimetable, setupStages } = Event.stateHelpers.getUserPermissions(state);
    const { selectedDayId } = stateHelpers.getComponentState(state, PERFORMANCE_SCHEDULER_CARD_ID);
    const { activeInterval } = stateHelpers.getComponentState(state, PERFORMANCE_SCHEDULER_ID(selectedDayId));
    const deleteConfirmModalState = stateHelpers.getComponentState(state, DELETE_CONFIRM_MODAL_ID);
    const reorderResourcesConfirmModalState = stateHelpers.getComponentState(state, REORDER_RESOURCES_CONFIRM_MODAL_ID);
    const eventId = stateHelpers.events.getCurrentId(state);
    const accountId = stateHelpers.accounts.getCurrentId(state);
    const userConfirmedReorderingStagesState = stateHelpers.getUserSettingState(state, USER_CONFIRMED_REORDERING_STAGES_KEY);

    return {
        accountId,
        eventId,
        isArtistTypesEnabled: stateHelpers.events.isArtistTypesEnabledForCurrent(state),
        eventDateFormat: stateHelpers.events.getCurrentDateFormat(state),
        eventTimeFormat: stateHelpers.events.getCurrentTimeFormat(state),
        userCanManageTimetable: manageTimetable,
        userCanManageSequenceStages: setupStages,
        selectedDay: stateHelpers.getOneEntity(state, 'days', selectedDayId),
        activeInterval,
        isFetching: (
            Event.stateHelpers.isFetchingCurrent(state) ||
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            BookingType.stateHelpers.isFetchingAll(state) ||
            BookingStatus.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        isFetchingPerformancesForSelectedDayId: Performance.stateHelpers.isFetchingByDayId(state, selectedDayId),
        days: stateHelpers.getAllEntities(state, 'days'),
        stages: stateHelpers.getAllEntities(state, 'stages'),
        performances: stateHelpers.getAllEntities(state, 'performances'),
        bookingStatuses: BookingStatus.stateHelpers.getAllForCurrentContext(state),

        deleteConfirmModalState,
        reorderResourcesConfirmModalState,
        userConfirmedReorderingStagesState
    };
};

const failedToUpdate = (errorMessage, performanceName) => {
    const message = POPUP_MESSAGES.FAILED_TO_UPDATE(performanceName, errorMessage);
    return actions.showErrorPopup(message);
};

const mapDispatchToProps = (dispatch) => ({
    onChangeDay: (id) => {
        dispatch(Performance.actions.fetchAllByDayIdOrLoadFromCache(id));
        dispatch(actions.setComponentStateValue(PERFORMANCE_SCHEDULER_CARD_ID, 'selectedDayId', id));
    },
    onChangeInterval: (selectedDayId, e) => {
        const value = e.target.value;
        dispatch(actions.setComponentStateValue(PERFORMANCE_SCHEDULER_ID(selectedDayId), 'activeInterval', value));
    },
    onSelectPeriod: (values, successCallback = () => {}) => {
        const modalState = {
            values: {
                ...values,
                startDate: values.start, // we set separate these to later merge with set time
                startTime: values.start,
                endDate: values.end,
                endTime: values.end
            },
            errors: {},
            hasSelectedExistingArtist: false,
            isCreatingNewArtist: false,
            isCreatingNewBooing: true,
            isEditingPerformanceName: false,
            createSuccessCallback: successCallback
        };
        dispatch(actions.mergeComponentState(CREATE_MODAL_ID, modalState));
        dispatch(actions.openModal(CREATE_MODAL_ID));
    },
    onDropPerformance: (eventId, performanceId, performanceName, values, revertCallback = () => {}) => {
        const apiValues = {
            ...values,
            ...reformatStartAndEnd(values),
            stage: { id: values.stage }
        };
        dispatch(Performance.actions.updateOne(eventId, performanceId, apiValues, revertCallback = () => {}))
            .then(({ isFailure, errorMessage }) => {
                if (isFailure) {
                    revertCallback();
                    dispatch(failedToUpdate(errorMessage, performanceName));
                }
            });
    },
    onResizePerformance: (eventId, performanceId, performanceName, values, revertCallback = () => {}) => {
        const apiValues = {
            ...values,
            ...reformatStartAndEnd(values)
        };
        dispatch(Performance.actions.updateOne(eventId, performanceId, apiValues, revertCallback))
            .then(({ isFailure, errorMessage }) => {
                if (isFailure) {
                    revertCallback();
                    dispatch(failedToUpdate(errorMessage, performanceName));
                }
            });
    },
    onToggleLockPerformance: (eventId, performanceId, performanceName, locked, successCallback = () => {}) => {
        dispatch(Performance.actions.updateOne(eventId, performanceId, { locked }))
            .then(({ isFailure, errorMessage, response }) => {
                if (isFailure) {
                    dispatch(failedToUpdate(errorMessage, performanceName));
                } else {
                    const successMessage = locked ?
                        POPUP_MESSAGES.SUCCESSFUL_LOCK(performanceName) :
                        POPUP_MESSAGES.SUCCESSFUL_UNLOCK(performanceName);
                    dispatch(actions.showSuccessPopup(successMessage));
                    successCallback(Performance.stateHelpers.getOne(response, response.result));
                }
            });
    },
    onTogglePublishPerformance: (performanceId, performanceName, published, successCallback = () => {}) => {
        dispatch(Performance.thunks.publishOneForCurrentContext(performanceId, published))
            .then(({ isFailure, errorMessage, response }) => {
                if (isFailure) {
                    if (published) {
                        dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_UNPUBLISH(performanceName, errorMessage)));
                    } else {
                        dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_PUBLISH(performanceName, errorMessage)));
                    }
                } else {
                    const successMessage = published ?
                        POPUP_MESSAGES.SUCCESSFUL_PUBLISH(performanceName) :
                        POPUP_MESSAGES.SUCCESSFUL_UNPUBLISH(performanceName);
                    dispatch(actions.showSuccessPopup(successMessage));
                    successCallback(Performance.stateHelpers.getOne(response, response.result));
                }
            });
    },
    onUpdatePerformance: (performance, successCallback = () => {}) => {
        const modalState = {
            values: {
                ...performance,
                start: performance.start,
                end: performance.end,
                day: performance.day,
                stage: performance.stage
            },
            isEditingPerformanceName: performance.customTitle,
            originalPerformance: performance,
            updateSuccessCallback: successCallback
        };
        dispatch(mergeUpdateModalState(modalState));
        dispatch(openUpdateModal());
    },
    onViewPerformance: (performanceId, successCallback = () => {}) => {
        const modalState = {
            'selectedPerformanceId' : performanceId,
            successCallback
        }
        dispatch(mergeDetailsModalState(modalState));
        dispatch(openDetailsModal());
    },
    onDeletePerformance: (eventId, performanceId, performanceName, successCallback = () => {}) => {
        const failedPopup = POPUP_MESSAGES.FAILED_TO_DELETE(performanceName);
        const successPopup = POPUP_MESSAGES.SUCCESSFUL_DELETE(performanceName);
        const action = {
            ...Performance.actions.deleteOne(eventId, performanceId),
            successCallback,
            successPopup,
            failedPopup
        };
        dispatch(actions.setComponentState(DELETE_CONFIRM_MODAL_ID, {
            title: DELETE_PERFORMANCE_TITLE,
            text: DELETE_PERFORMANCE_TEXT(performanceName),
            buttonText: DELETE_PERFORMANCE_BUTTON_TEXT(performanceName)
        }));
        dispatch(actions.openConfirmModal(DELETE_CONFIRM_MODAL_ID, action));
    },
    onDeleteConfirmModalClosed: () => {
        dispatch(actions.closeConfirmModal(DELETE_CONFIRM_MODAL_ID));
    },
    onDeleteConfirmModalConfirmed: (action) => {
        const hasSuccessCallback = typeof action.successCallback === 'function';
        dispatch(actions.setModalSyncing(DELETE_CONFIRM_MODAL_ID, true));
        dispatch(action).then(({ isFailure, errorMessage }) => {
            dispatch(actions.setModalSyncing(DELETE_CONFIRM_MODAL_ID, false));
            dispatch(actions.closeConfirmModal(DELETE_CONFIRM_MODAL_ID));
            if (isFailure) {
                const message = `${action.failedPopup} ${errorMessage}`;
                dispatch(actions.showErrorPopup(message));
            } else {
                if (hasSuccessCallback) action.successCallback();
                dispatch(actions.showSuccessPopup(action.successPopup));
            }
        });
    },
    onReorderResources: (eventId, dayId, stageIds, userConfirmedReorderingStagesState) => {
        if (!userConfirmedReorderingStagesState) {
            const action = {
                ...Stage.actions.updateStagesSequence(eventId, dayId, stageIds)
            }
            dispatch(actions.setComponentState(REORDER_RESOURCES_CONFIRM_MODAL_ID, {
                title: REORDER_RESOURCES_TITLE,
                text: REORDER_RESOURCES_TEXT,
                buttonText: REORDER_RESOURCES_BUTTON_TEXT
            }));
            dispatch(actions.openConfirmModal(REORDER_RESOURCES_CONFIRM_MODAL_ID, action));
        } else {
            dispatch(Stage.actions.updateStagesSequence(eventId, dayId, stageIds))
        }
    },
    onReorderResourcesConfirmModalConfirmed: (action, userConfirmedReorderingStages) => {
        dispatch(actions.setModalSyncing(REORDER_RESOURCES_CONFIRM_MODAL_ID, true));
        // is userConfirmedReorderingStages is true, add to localStorage
        if (userConfirmedReorderingStages) {
            dispatch(actions.registerUserSetting(USER_CONFIRMED_REORDERING_STAGES_KEY, userConfirmedReorderingStages))
        }

        dispatch(action).then(() => {
            dispatch(actions.setModalSyncing(REORDER_RESOURCES_CONFIRM_MODAL_ID, false));
            dispatch(actions.closeConfirmModal(REORDER_RESOURCES_CONFIRM_MODAL_ID));
        })
    },
    onReorderResourcesConfirmModalClosed: () => {
        dispatch(actions.closeConfirmModal(REORDER_RESOURCES_CONFIRM_MODAL_ID));
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onToggleLockPerformance: (performanceId, successCallback) => {
        const { eventId, performances } = stateProps;
        const performance = performances.find(({ id }) => id === performanceId);
        return dispatchProps.onToggleLockPerformance(
            eventId,
            performance.id,
            performance.title,
            !performance.locked,
            successCallback
        );
    },
    onTogglePublishPerformance: (performanceId, successCallback) => {
        const { performances } = stateProps;
        const performance = performances.find(({ id }) => id === performanceId);
        return dispatchProps.onTogglePublishPerformance(
            performance.id,
            performance.title,
            !performance.published,
            successCallback
        );
    },
    onDropPerformance: (performanceId, values, revertCallback = () => {}) => {
        const { eventId, performances } = stateProps;
        const performance = performances.find(({ id }) => id === performanceId);
        return dispatchProps.onDropPerformance(
            eventId,
            performance.id,
            performance.title,
            values,
            revertCallback
        );
    },
    onResizePerformance: (performanceId, values, revertCallback = () => {}) => {
        const { eventId, performances } = stateProps;
        const performance = performances.find(({ id }) => id === performanceId);
        return dispatchProps.onResizePerformance(
            eventId,
            performance.id,
            performance.title,
            values,
            revertCallback
        );
    },
    onDeletePerformance: (performanceId, successCallback = () => {}) => {
        const { eventId, performances } = stateProps;
        const performance = performances.find(({ id }) => id === performanceId);
        return dispatchProps.onDeletePerformance(
            eventId,
            performance.id,
            performance.title,
            successCallback
        );
    },
    onUpdatePerformance: (performanceId, successCallback = () => {}) => {
        const { performances } = stateProps;
        const performance = performances.find(({ id }) => id === performanceId);
        dispatchProps.onUpdatePerformance(performance, successCallback);
    },
    onChangeInterval: (value) =>
        dispatchProps.onChangeInterval(stateProps.selectedDay.id, value),
    onDeleteConfirmModalConfirmed: () =>
        dispatchProps.onDeleteConfirmModalConfirmed(stateProps.deleteConfirmModalState.action),
    onReorderResources: (stageIds) => {
        const { eventId, selectedDay, userConfirmedReorderingStagesState } = stateProps;
        const dayId = selectedDay.id;
        return dispatchProps.onReorderResources(
            eventId,
            dayId,
            stageIds,
            userConfirmedReorderingStagesState
        )
    },
    onReorderResourcesConfirmModalConfirmed: (userConfirmedReorderingStages) => {
        return dispatchProps.onReorderResourcesConfirmModalConfirmed(stateProps.reorderResourcesConfirmModalState.action, userConfirmedReorderingStages)
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SchedulerView);
