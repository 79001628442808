import moment from 'moment';
import { schema } from 'normalizr';
import { createReducer } from 'redux-create-reducer';
import { CALL_API, METHODS, includeParam, limitAllParam } from 'app/react/state/middleware/api';
import { reformatISOString } from 'app/react/helpers/_index'
import {
    createPaginationReducerWithKey,
    createEntityReducer
} from 'app/react/state/reducers/higher_order/_index';
import {
    getOneEntity,
    getEntitiesByPaginationByKey,
    getEntitiesPaginationByKey,
    isFetchingEntitiesByKey,
    getCurrentEventId,
    addIdToOtherEntityArray,
    removeIdFromOtherEntityArray
} from 'app/react/state/helpers';
import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as TimeSlot from 'app/react/entities/events/performances/time_slots/index';
import * as Day from 'app/react/entities/events/days/schemas';
import * as Stage from 'app/react/entities/events/stages/schemas';

export const ALL_BY_DAY_REQUEST = 'ALL_PERFORMANCES_BY_DAY_REQUEST';
export const ALL_BY_DAY_SUCCESS = 'ALL_PERFORMANCES_BY_DAY_SUCCESS';
export const ALL_BY_DAY_FAILURE = 'ALL_PERFORMANCES_BY_DAY_FAILURE';

export const ONE_REQUEST = 'ONE_PERFORMANCE_REQUEST';
export const ONE_SUCCESS = 'ONE_PERFORMANCE_SUCCESS';
export const ONE_FAILURE = 'ONE_PERFORMANCE_FAILURE';

export const ENTITY_REDUCER_KEY = 'performances';
export const PAGINATION_REDUCER_KEY = `${ENTITY_REDUCER_KEY}ByDay`;

const BOOKINGS_KEY = 'bookings';
const TIME_SLOTS_KEY = 'timeSlots';
const DAY_KEY = 'day';
const STAGE_KEY = 'stage';

const processStrategy = (entity) => ({
    ...entity,
    changeover: entity.changeover && entity.changeover / 60
});
const entityDefinition = {
    [BOOKINGS_KEY]: Booking.schemas.entities,
    [TIME_SLOTS_KEY]: TimeSlot.schemas.array,
    [DAY_KEY]: Day.entity,
    [STAGE_KEY]: Stage.array
};

const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, { processStrategy });
const array = new schema.Array(entity);

const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);

const getAllByDayId = (state, dayId) =>
    getEntitiesByPaginationByKey(state, ENTITY_REDUCER_KEY, PAGINATION_REDUCER_KEY, dayId);

const isFetchingByDayId = (state, dayId) =>
    isFetchingEntitiesByKey(state, PAGINATION_REDUCER_KEY, dayId);

const getPaginationByDayId = (state, dayId) =>
    getEntitiesPaginationByKey(state, PAGINATION_REDUCER_KEY, dayId);

const addTimeSlotIdToPerformance = (state, id, timeSlotId) =>
    addIdToOtherEntityArray(state, ENTITY_REDUCER_KEY, id, TIME_SLOTS_KEY, timeSlotId);

const removeTimeSlotIdFromPerformance = (state, id, timeSlotId) => {
    const performance = getOne(state, id);
    const index = performance[TIME_SLOTS_KEY].indexOf(timeSlotId);
    return removeIdFromOtherEntityArray(state, ENTITY_REDUCER_KEY, id, TIME_SLOTS_KEY, index);
};

const addBookingIdToPerformance = (state, id, bookingId) =>
    addIdToOtherEntityArray(state, ENTITY_REDUCER_KEY, id, BOOKINGS_KEY, bookingId);

const removeBookingIdFromPerformance = (state, id, bookingId) => {
    const performance = getOne(state, id);
    const index = performance[BOOKINGS_KEY].indexOf(bookingId);
    return removeIdFromOtherEntityArray(state, ENTITY_REDUCER_KEY, id, BOOKINGS_KEY, index);
};

const paginationReducer = createPaginationReducerWithKey({
    mapActionToKey: (action) => action.dayId,
    types: [
        ALL_BY_DAY_REQUEST,
        ALL_BY_DAY_SUCCESS,
        ALL_BY_DAY_FAILURE
    ]
});

const entityReducer = createEntityReducer({
    types: [
        ONE_REQUEST,
        ONE_SUCCESS,
        ONE_FAILURE
    ]
});

const featureReducer = createReducer({}, {
    [TimeSlot.ONE_SUCCESS]: (state, action) => {
        const { performanceId, id, method, response } = action;
        if (typeof performanceId !== 'undefined') {
            if (method === METHODS.POST) {
                return addTimeSlotIdToPerformance(state, performanceId, response.result);
            }
            if (method === METHODS.DELETE) {
                return removeTimeSlotIdFromPerformance(state, performanceId, id);
            }
        }
        return state;
    },
    [Booking.actions.ONE_SUCCESS]: (state, action) => {
        const { performanceId, response, id = response.result, method } = action;
        if (typeof performanceId !== 'undefined') {
            if (method === METHODS.POST) {
                return addBookingIdToPerformance(state, performanceId, id);
            }
            if (method === METHODS.PUT) {
                const booking = Booking.stateHelpers.getOne(response, id);
                if (booking.performance === null) {
                    return removeBookingIdFromPerformance(state, performanceId, id);
                } else {
                    const performance = getOne(state, performanceId);
                    const { bookings = [] } = performance;
                    // Make sure the performance exists & the booking is not already included
                    if (typeof performance !== 'undefined' && !bookings.includes(id)) {
                        return addBookingIdToPerformance(state, performanceId, id);
                    }
                }
                return state;
            }
            if (method === METHODS.DELETE) {
                return removeBookingIdFromPerformance(state, performanceId, id);
            }
        }
        return state;
    }
});

const rootUrl = eventId => `events/${eventId}/performances`;
const defaultIncludeParams = includeParam(['bookings.artist', 'time_slots', 'status']);
const Endpoints = {
    getOne: (eventId, performanceId) =>
        `${rootUrl(eventId)}/${performanceId}`,
    deleteOne: (eventId, performanceId) =>
        `${rootUrl(eventId)}/${performanceId}`,
    createOne: (eventId) =>
        `${rootUrl(eventId)}?${defaultIncludeParams}`,
    updateOne: (eventId, performanceId) =>
        `${rootUrl(eventId)}/${performanceId}?${defaultIncludeParams}`,
    allByDayId: (eventId, dayId) =>
        `${rootUrl(eventId)}?day=${dayId}&${defaultIncludeParams}&${limitAllParam}`
};

const fetchOne = (eventId, performanceId) => ({
    [CALL_API]: {
        types: [
            ONE_REQUEST,
            ONE_SUCCESS,
            ONE_FAILURE
        ],
        id: performanceId,
        endpoint: Endpoints.getOne(eventId, performanceId),
        schema: entity
    }
});

const deleteOne = (eventId, performanceId) => ({
    [CALL_API]: {
        types: [
            ONE_REQUEST,
            ONE_SUCCESS,
            ONE_FAILURE
        ],
        method: METHODS.DELETE,
        id: performanceId,
        endpoint: Endpoints.deleteOne(eventId, performanceId),
        schema: entity
    }
});

const createOne = (eventId, values) => ({
    [CALL_API]: {
        types: [
            ONE_REQUEST,
            ONE_SUCCESS,
            ONE_FAILURE
        ],
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId),
        schema: entity
    }
});

const updateOne = (eventId, performanceId, values) => ({
    [CALL_API]: {
        types: [
            ONE_REQUEST,
            ONE_SUCCESS,
            ONE_FAILURE
        ],
        method: METHODS.PUT,
        id: performanceId,
        body: values,
        endpoint: Endpoints.updateOne(eventId, performanceId),
        schema: entity
    }
});

const fetchAllByDayId = (eventId, dayId) => ({
    dayId,
    [CALL_API]: {
        types: [
            ALL_BY_DAY_REQUEST,
            ALL_BY_DAY_SUCCESS,
            ALL_BY_DAY_FAILURE
        ],
        endpoint: Endpoints.allByDayId(eventId, dayId),
        schema: array
    }
});

const fetchAllByDayIdOrLoadFromCache = (dayId) => (dispatch, getState) => {
    const state = getState();
    const { pageCount = 0 } = getPaginationByDayId(state, dayId);
    if (pageCount > 0) return Promise.resolve();
    const eventId = getCurrentEventId(state);
    return dispatch(fetchAllByDayId(eventId, dayId));
};

export const publishOneForCurrentContext = (performanceId, published) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const now = moment().utc().toISOString();
    const publishDate = published ? reformatISOString(now) : null;
    return dispatch(updateOne(eventId, performanceId, { publishDate }));
};

const isNotBetween = (mTarget, mStart, mEnd) => (
    !(mTarget.isBetween(mStart, mEnd) ||
    mTarget.isSame(mStart) ||
    mTarget.isSame(mEnd))
);

const validateIfStartAndEndIsBetweenDay = (start, end, startDay, endDay) => {
    const mStart = moment(start);
    const mEnd = moment(end);
    const mStartDay = moment(startDay);
    const mEndDay = moment(endDay);
    const errors = {}
    if (isNotBetween(mStart, mStartDay, mEndDay)) {
        errors.start = 'The start date must fall between the start and end date of the day';
    }
    if (isNotBetween(mEnd, mStartDay, mEndDay)) {
        errors.end = 'The end date must fall between the start and end date of the day';
    }
    return errors;
};

const isRequired = (object, key, message) => {
    const value = object[key];
    if (typeof value !== 'undefined' && value !== '') return {};
    return { [key]: { type: 'error', text: message } };
};

const isTitleRequired = (object, message) => {
    const { title, artist } = object;
    if (typeof artist !== 'undefined') return {};
    if (typeof title !== 'undefined' && title !== '') return {};
    return { title: { type: 'error', text: message } };
};

export const getValidationErrors = (object) => ({
    ...isTitleRequired(object, 'Please fill in the performance name.'),
    ...isRequired(object, 'start', 'Please fill in the start time.'),
    ...isRequired(object, 'end', 'Please fill in the end time.')
});

export const isValid = (performance) => Object.keys(getValidationErrors(performance)).length === 0;

export const schemas = {
    entity,
    array
};

export const stateHelpers = {
    getOne,
    getAllByDayId,
    isFetchingByDayId,
    getPaginationByDayId
};

export const reducers = {
    entity: entityReducer,
    pagination: paginationReducer,
    feature: featureReducer,
};

export const actions = {
    fetchOne,
    createOne,
    updateOne,
    deleteOne,
    fetchAllByDayId,
    fetchAllByDayIdOrLoadFromCache,
};

export const thunks = {
    publishOneForCurrentContext,
};
