import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'
import {
    setCurrentContextId,
    unsetCurrentContextId,
    addEntityToCollection
} from 'app/react/state/actions/_index'

export const ONE_REQUEST = 'ONE_PICKUP_REQUEST';
export const ONE_SUCCESS = 'ONE_PICKUP_SUCCESS';
export const ONE_FAILURE = 'ONE_PICKUP_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_REQUEST = 'ALL_PICKUPS_REQUEST';
export const ALL_SUCCESS = 'ALL_PICKUPS_SUCCESS';
export const ALL_FAILURE = 'ALL_PICKUPS_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const ALL_PAGINATED_REQUEST = 'ALL_PICKUPS_PAGINATED_REQUEST';
export const ALL_PAGINATED_SUCCESS = 'ALL_PICKUPS_PAGINATED_SUCCESS';
export const ALL_PAGINATED_FAILURE = 'ALL_PICKUPS_PAGINATED_FAILURE';
export const ALL_PAGINATED_TYPES = [
    ALL_PAGINATED_REQUEST,
    ALL_PAGINATED_SUCCESS,
    ALL_PAGINATED_FAILURE
];

export const setCurrentId = (id) =>
    setCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY, id);

export const unsetCurrentId = (id) =>
    unsetCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY);

export const createOne = (
    accountId,
    eventId,
    bookingId,
    values
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId, bookingId),
        schema: Schemas.entity
    },
    accountId,
    eventId,
    bookingId,
});

export const fetchOne = (
    accountId,
    eventId,
    bookingId,
    pickupId
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchOne(eventId, bookingId, pickupId, ['passengers']),
        schema: Schemas.entity,
        id: pickupId
    },
    accountId,
    eventId,
    bookingId
});

export const updateOne = (
    accountId,
    eventId,
    bookingId,
    pickupId,
    values,
    includes
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOne(eventId, bookingId, pickupId, includes),
        schema: Schemas.entity,
        id: pickupId
    },
    accountId,
    eventId,
    bookingId,
});

export const deleteOne = (
    accountId,
    eventId,
    bookingId,
    pickupId
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, bookingId, pickupId),
        schema: Schemas.entity,
        id: pickupId
    },
    accountId,
    eventId,
    bookingId,
});

export const fetchAllPaginated = (
    accountId,
    eventId,
    pagination,
    includes
) => {
    const {
        page = 1,
        pageSize = 100,
        filters = {},
        search = '',
        sortKey = '',
        sortOrder = 1,
    } = pagination;
    return {
        [CALL_API]: {
            types: ALL_PAGINATED_TYPES,
            endpoint: Endpoints.fetchAllPaginated(
                eventId,
                page,
                pageSize,
                filters,
                search,
                sortKey,
                sortOrder,
                includes
            ),
            requestedPage: page,
            requestedPageSize: pageSize,
            requestedFilters: filters,
            requestedSearch: search,
            requestedSortKey: sortKey,
            requestedSortOrder: sortOrder,
            schema: Schemas.entities
        },
        accountId,
        eventId,
    };
};

export const fetchAllByDay = (
    accountId,
    eventId,
    after,
    before,
    includes
) => {
    return {
        [CALL_API]: {
            types: ALL_TYPES,
            endpoint: Endpoints.fetchAllByDay(
                eventId,
                after,
                before,
                includes,
            ),
            schema: Schemas.entities
        },
        accountId,
        eventId,
        after,
        before,
    };
};
