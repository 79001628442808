import React from 'react'
import PropTypes from 'prop-types'
import {
    Grid,
    InputGroup,
    Button as UIKitButton,
} from 'ui-kit'
import withState from './withState'
import { Modal } from './Modal'
import { withStateHandlers } from 'recompose'
import './ConfirmModalWithCheckbox.module.css'

const { Cell } = Grid;;
const { Header, Content, Footer } = Modal;
const { Title } = Header;
const { Button } = Footer;

export const TITLE = 'Confirm';
export const TEXT = 'Are you sure?';
export const BUTTON_TEXT = 'Yes';
const CHECKBOX_TEXT = 'Do not remind me again'

const injectState = withStateHandlers(
    () => ({
        checked: false
    }),
    {
        toggleChecked: ({ checked }) => () => ({
            checked: !checked
        }),
    }
);

const ConfirmModal = ({
    isOpened = false,
    isSyncing = false,
    title = TITLE,
    width = "48rem",
    text = TEXT,
    buttonClassName = 'info',
    buttonText = BUTTON_TEXT,
    onConfirmed = () => {},
    onClosed = () => {},
    checked = false,
    toggleChecked = () => {}
}) => (
    <Modal
        width={width}
        opened={isOpened}
        syncing={isSyncing}
        onClosed={onClosed}
        className="confirmModal"
    >
        <Header onClosed={onClosed}>
            <Title>{title}</Title>
        </Header>
        <Content>
            <p dangerouslySetInnerHTML={{ __html: text }} />
        </Content>
        <Footer>
            <Grid hasGutters={false}>
                <Cell
                    isAutoSized
                    isContentCenteredVertical
                >
                    <InputGroup className="no-margin-bottom">
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={toggleChecked}
                            />
                            {CHECKBOX_TEXT}
                        </label>
                    </InputGroup>
                </Cell>
                <Cell>
                    <Button
                        className={UIKitButton.PRIMARY}
                        onClick={() => {onConfirmed(checked)}}
                    >
                        <span dangerouslySetInnerHTML={{ __html: buttonText }} />
                    </Button>
                </Cell>
            </Grid>
        </Footer>
    </Modal>
);

ConfirmModal.propTypes = {
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    title: PropTypes.string,
    width: PropTypes.number,
    text: PropTypes.string,
    buttonText: PropTypes.string,
    onConfirmed: PropTypes.func,
    onClosed: PropTypes.func,
    checked: PropTypes.bool,
    toggleChecked: PropTypes.func
};

export default withState(
    injectState(ConfirmModal)
);
