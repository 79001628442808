import React, { Fragment } from 'react'

const Stars = ({
    rating,
    size,
}) => (
    <Fragment>
        {Array.from({ length: rating }, (value, rating) => (
            <i
                key={rating}
                className="bs-icon-star"
                style={{ fontSize: size }}
            />
        ))}
    </Fragment>
);

Stars.defaultProps = {
    size: '1.4rem',
};

export default Stars;
