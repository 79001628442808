import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import DragItem from './Item';
import './List.module.css';

export const ListContainer = ({ children }) => (
    <ul styleName="list">
        {children}
    </ul>
);

const PagesTable = ({
    pages = [],
    onAddChild = () => {},
    onUpdate = () => {},
    onDelete = () => {},
    updatePageSequence = () => {},
    updateHoverState = () => {},
    dropOutsideList = () => {}
}) => (
    <ListContainer>
        <FlipMove
            staggerDurationBy="30"
            duration={500}
            enterAnimation="accordianVertical"
        >
            {pages.map(({
                id,
                sequence,
                name,
                isHomePage,
                isFormPage,
                isOverTopArea,
                isOverMiddleArea,
                isOverBottomArea,
                slug,
                children = []
            }) => (
                <DragItem
                    key={id}
                    id={id}
                    sequence={sequence}
                    name={name}
                    slug={slug}
                    isHomePage={isHomePage}
                    isFormPage={isFormPage}
                    isOverTopArea={isOverTopArea}
                    isOverMiddleArea={isOverMiddleArea}
                    isOverBottomArea={isOverBottomArea}
                    hasChildren={!!children.length}
                    children={children}
                    onAddChild={onAddChild}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    updatePageSequence={updatePageSequence}
                    updateHoverState={updateHoverState}
                    dropOutsideList={dropOutsideList}
                />
            ))}
        </FlipMove>
    </ListContainer>
);

PagesTable.propTypes = {
    pages: PropTypes.array,
    isHomePage: PropTypes.bool,
    onAddChild: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    updatePageSequence: PropTypes.func,
    updateHoverState: PropTypes.func.isRequired,
    dropOutsideList: PropTypes.func
};

export default PagesTable;
