import * as Account from 'app/react/entities/accounts/helpers'
import * as Event from 'app/react/entities/events/helpers'
import * as Booking from 'app/react/entities/events/bookings/helpers'
import { fetchAll, copyAll, createOne, updateOne, deleteOne } from './actions'

export const fetchAllForCurrentContext = () => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    return dispatch(fetchAll(accountId, eventId, bookingId));
};

export const copyAllFromBookingForCurrentContext = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    return dispatch(copyAll(accountId, eventId, bookingId, values));
};

export const createOneForCurrentContext = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    return dispatch(createOne(accountId, eventId, bookingId, values));
};

export const updateOneForCurrentContext = (itemId, values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, bookingId, itemId, values));
};

export const deleteOneForCurrentContext = (itemId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    return dispatch(deleteOne(accountId, eventId, bookingId, itemId));
};
