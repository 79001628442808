import dotProp from 'dot-prop-immutable';
import {
    MERGE_CURRENT_CONTEXT,
    SET_CURRENT_CONTEXT_ID,
    UNSET_CURRENT_CONTEXT_ID
} from '../actions/_index';

export function currentContext(state = {}, action) {
    const { type, context, key, id } = action;
    switch (type) {
        case MERGE_CURRENT_CONTEXT: {
            return {
                ...state,
                ...context
            };
        }
        case SET_CURRENT_CONTEXT_ID: {
            return dotProp.set(state, key, id);
        }
        case UNSET_CURRENT_CONTEXT_ID: {
            return dotProp.delete(state, key);
        }
        default:
            return state;
    }
}
