import _ from 'underscore';
import { normalize } from 'normalizr';
import * as Humps from 'humps';
import merge from 'lodash.merge';

const isUuid = (uuid) =>
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);

const onlyWhenNotUuid = (key, convert) =>
    isUuid(key) ? key : convert(key);

const camelizeKeys = (data) =>
    Humps.camelizeKeys(data, onlyWhenNotUuid);

const decamelizeKeys = (data) =>
    Humps.decamelizeKeys(data);

const isNotObjectOrArray = (obj) => (
    !_.isObject(obj) ||
    _.isDate(obj) ||
    _.isRegExp(obj) ||
    _.isBoolean(obj) ||
    _.isFunction(obj)
);

export const cleanDataProps = (obj) => {
    if (isNotObjectOrArray(obj)) return obj;

    let output;
    if (Array.isArray(obj)) {
        output = [];
        obj.forEach((value) => {
            output.push(cleanDataProps(value));
        });
    } else {
        if (obj.data) output = cleanDataProps(obj.data);
        else {
            output = {};
            Object.keys(obj).forEach((key) => {
                output[key] = cleanDataProps(obj[key]);
            });
        }
    }
    return output;
};

const reformatPagination = (pagination) => ({
    totalCount: pagination.total,
    totalPages: pagination.totalPages,
    currentPage: pagination.currentPage,
    currentPageCount: pagination.count,
});

export const transformResponseBody = (body, schema) => {
    const cleanedData = cleanDataProps(body);
    const camelizedData = camelizeKeys(cleanedData);
    const hasMeta = typeof body.meta !== 'undefined';
    const camelizedMeta = hasMeta ? camelizeKeys(body.meta) : {};
    const hasPagination = typeof camelizedMeta.pagination !== 'undefined';
    if (hasPagination) {
        return {
            ...normalize(camelizedData, schema),
            pagination: reformatPagination(camelizedMeta.pagination)
        };
    }
    return normalize(camelizedData, schema)
};

export const transformResponseErrorBody = (body) =>
    camelizeKeys(body);

export const transformRequestBody = (body) => {
    const decamelizedBody = decamelizeKeys(body);
    return JSON.stringify(decamelizedBody);
};

export const transformGraphQLResponseBody = (body, schemaMapping) => {
    const cleanedData = cleanDataProps(body.data);
    let normalizedData = {};
    Object.keys(schemaMapping).forEach((key) => {
        const  normalizedKey = normalize(cleanedData[key], schemaMapping[key]);
        normalizedData = merge(normalizedData, normalizedKey);
    });
    return normalizedData;
};



