import React, { Fragment } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import DialogContainer from '../../dialogContainer/DialogContainer';
import DialogCard from '../../dialogCard/DialogCard';
import DialogCardBottom from '../../dialogCard/DialogBottom';
import FormField from '../../formfields/FormField';
import Button from '../../button/Button';

const AddArtistTypeValidation = () =>
	yup.object({
		name: yup.string().min(2).required(),
	});

const AddArtistTypeDialog = ({ createArtistType, ...props }) => (
		<DialogContainer>
			<DialogCard {...props}>
				<div className={'dialog-inner-container'}>
					<h3 className="dialog-title">Add artist type</h3>
					<Formik
						initialValues={{
							name: '',
						}}
						enableReinitialize
						validationSchema={AddArtistTypeValidation()}
						validateOnMount
					>
						{(formik) => {
							const { isValid, values } = formik;
							return (
								<Fragment>
									<Form>
										<Field name='name' label='Name' required as={FormField} />
									</Form>
									<DialogCardBottom>
										<Button
											label='Add'
											variant='primary'
											type='button'
											onClick={() => createArtistType({ name: values.name })}
											isDisabled={!isValid}
										/>
									</DialogCardBottom>
								</Fragment>
							);
						}}
					</Formik>
				</div>
			</DialogCard>
		</DialogContainer>
	);

AddArtistTypeDialog.propTypes = {
	createArtistType: PropTypes.func,
	setCancelDialogOpen: PropTypes.func,
};

export default AddArtistTypeDialog;
