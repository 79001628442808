import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';

const { Tabs, TitleMenu } = Header;
const { Title, Actions } = TitleMenu;
const { Tab } = Tabs;

const BASE_PATH = (eventId) => `/events/${eventId}/production-website/accreditation`;
export const TABS = {
    PAGES: {
        ID: 'pages',
        PATH: 'pages',
        TITLE: 'Pages'
    },
    FORM: {
        ID: 'form',
        PATH: 'form',
        TITLE: 'Form'
    },
    EMAILS: {
        ID: 'emails',
        PATH: 'emails',
        TITLE: 'Emails'
    }
};

export const AccreditationSettingsContentHeader = ({
    children,
    activeTab,
    eventId
}) => {
    const basePath = BASE_PATH(eventId);
    return (
        <Header>
            <TitleMenu>
                <Title>Accreditation</Title>
                <Actions>{children}</Actions>
            </TitleMenu>
            <Tabs>
                <Tab
                    tabId={TABS.PAGES.ID}
                    activeTab={activeTab}
                    url={`${basePath}/${TABS.PAGES.PATH}`}
                >
                    {TABS.PAGES.TITLE}
                </Tab>
                <Tab
                    tabId={TABS.FORM.ID}
                    activeTab={activeTab}
                    url={`${basePath}/${TABS.FORM.PATH}`}
                    pageReload
                >
                    {TABS.FORM.TITLE}
                </Tab>
                <Tab
                    tabId={TABS.EMAILS.ID}
                    activeTab={activeTab}
                    url={`${basePath}/${TABS.EMAILS.PATH}`}
                    pageReload
                >
                    {TABS.EMAILS.TITLE}
                </Tab>
            </Tabs>
        </Header>
    );
};

AccreditationSettingsContentHeader.propTypes = {
    children: PropTypes.node,
    activeTab: PropTypes.string,
    eventId: PropTypes.number
};

export default AccreditationSettingsContentHeader;
