var Dotty = require("dotty");

var DOM = {

    getData(key) {
        if(Dotty.exists(window.beatswitch, key)) return Dotty.get(window.beatswitch, key);
    },

    hasData(key) {
        return Dotty.exists(window.beatswitch, key);
    },

    getAccount() {
        if(Dotty.exists(window.beatswitch, 'auth.account')){
            var temp = {}; //Add account to dom from api
            temp.data = Dotty.get(window.beatswitch, 'auth.account');
            return temp;
        }
    },

    getEvent() {
        if(Dotty.exists(window.beatswitch, 'event')){
            return Dotty.get(window.beatswitch, 'event').data;
        }
    }
};

module.exports = DOM;
