import React, { Fragment } from 'react'
import {
    AlertBox,
    Avatar,
    Button,
    Drawer,
    Grid,
    Summary,
    Tooltip,
} from 'ui-kit'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { nl2br } from 'app/react/helpers/_index'
import { isDeniedStatus, requestStatusMap } from 'app/react/helpers/reservations'
import ReservationStatusIcon from 'app/react/views/event/artists/reservations/views/overview/components/ReservationStatusIcon'
import AccommodationHeaderActions from 'app/react/views/event/artists/reservations/views/overview/components/drawers/AccommodationHeaderActions'

const {
    Item,
    Group,
    List,
} = Summary;
const { Cell } = Grid;

const DENIED_TEXT = 'This reservation has been denied.';

const ReservationStatusBadge = ({ requestStatus }) => {
    const style = {
        fontSize: 'var(--fontSizeSmall)',
        marginLeft: 'var(--spacingSmallest)',
    };

    return (
        <span style={style}>
            <ReservationStatusIcon requestStatus={requestStatus} />
        </span>
    );
};

const DetailDrawer = ({
    reservation,
    dateFormat,
    timeFormat,
    isApprovalRequired,
    onClickEdit,
    onClickDelete,
    renderTrigger,
}) => {
    const {
        accommodation,
        booking,
        checkIn,
        checkOut,
        guestAmount,
        roomTypeName,
        name,
        guestContactNumber,
        hasEarlyCheckIn,
        hasLateCheckOut,
        confirmationNumber,
        isBookedByArtist,
        nightsPaidByPromoter,
        notes = '',
        guests = [],
        status = {},
    } = reservation;
    const { artist = {} } = booking;
    const requestStatus = requestStatusMap[status.id];
    const formattedCheckIn = moment.utc(checkIn, moment.ISO_8601).format(`${dateFormat} ${timeFormat}`);
    const formattedCheckOut = moment.utc(checkOut, moment.ISO_8601).format(`${dateFormat} ${timeFormat}`);

    return (
        <Drawer
            renderTrigger={renderTrigger}
            renderTitle={() => (
                <Grid hasGutters={false}>
                    <Cell isAutoSized>
                        <Avatar
                            size={4.6}
                            name={artist.name}
                            imageUrl={artist.image}
                        />
                    </Cell>
                    <Cell className="margin-tiny-left">
                        <h5>
                            <span>
                                {accommodation
                                    ? accommodation.name
                                    : 'Unassigned reservation'
                                }
                            </span>
                            {isApprovalRequired && requestStatus && (
                                <ReservationStatusBadge requestStatus={requestStatus} />
                            )}
                        </h5>
                        {accommodation && (
                            <AccommodationHeaderActions accommodation={accommodation} />
                        )}
                    </Cell>
                </Grid>
            )}
            renderFooter={() => (
                <Fragment>
                    <Button
                        type={Button.SECONDARY}
                        onClick={(e) => onClickDelete(reservation.id, e.ctrlKey || e.metaKey)}
                    >
                        Remove
                    </Button>
                    <Button onClick={(e) => onClickEdit(reservation.id, e.ctrlKey || e.metaKey)}>
                        Edit
                    </Button>
                </Fragment>
            )}
        >
            {isApprovalRequired && isDeniedStatus(status.id) && (
                <AlertBox type={AlertBox.DANGER}>
                    {DENIED_TEXT} {status.reason}
                </AlertBox>
            )}
            <Summary>
                <Group>
                    <Item
                        isLarge
                        renderLabel={() => 'Check-in'}
                    >
                        {formattedCheckIn}
                    </Item>
                    <Item
                        isLarge
                        renderLabel={() => 'Check-out'}
                    >
                        {formattedCheckOut}
                    </Item>
                    <Item
                        isLarge
                        renderLabel={() => 'Guests'}
                    >
                        <i className="bs-icon-user" />
                        {guestAmount}
                    </Item>
                </Group>
                {roomTypeName && (
                    <Item renderLabel={() => 'Room type'}>
                        {roomTypeName}
                    </Item>
                )}
                {name && (
                    <Item renderLabel={() => 'Reservation name'}>
                        {name}
                    </Item>
                )}
                {guestContactNumber && (
                    <Item renderLabel={() => 'Guest contact number'}>
                        {guestContactNumber}
                    </Item>
                )}
                <Item renderLabel={() => 'Early check-in?'}>
                    {hasEarlyCheckIn ? 'Yes' : 'No'}
                </Item>
                <Item renderLabel={() => 'Late check-out?'}>
                    {hasLateCheckOut ? 'Yes' : 'No'}
                </Item>
                {confirmationNumber && (
                    <Item renderLabel={() => 'Confirmation number'}>
                        {confirmationNumber}
                    </Item>
                )}
                <Item renderLabel={() => 'Booked by'}>
                    {isBookedByArtist ? 'Artist': 'Promoter'}
                </Item>
                <Item renderLabel={() => 'Breakfast included?'}>
                    {hasEarlyCheckIn ? 'Yes' : 'No'}
                </Item>
                {nightsPaidByPromoter && (
                    <Item renderLabel={() => 'Nights paid by promoter'}>
                        {nightsPaidByPromoter}
                    </Item>
                )}
                {notes && (
                    <Item renderLabel={() => 'Notes'}>
                        {nl2br(notes)}
                    </Item>
                )}
                {!isEmpty(guests) && (
                    <List>
                        {guests.map(({ id, firstname, lastname }, key) => (
                            <Item
                                key={id}
                                renderLabel={() => key === 0 ? 'Guests' : ''}
                            >
                                {firstname} {lastname}
                            </Item>
                        ))}
                    </List>
                )}
            </Summary>
        </Drawer>
    );
};

export default DetailDrawer;
