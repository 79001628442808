import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import ProfileTable from '../tables/ProfileTable.view';

export default class ProfileOverviewCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            profiles: OptionTypes.instanceOf(Profile.PageableCollection).isRequired,
            zones: OptionTypes.instanceOf(Zone.Collection).isRequired
        };
    }

    initContent() {
        return this.initTable();
    }

    initTable() {
        const { profiles, zones } = this;
        return new ProfileTable({ profiles, zones });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add profile',
            icon: 'bs-icon-plus',
            action: this.create.bind(this)
        }];
    }

    create() {
        const eventId = this.profiles.getRelation('event').id;
        navigate(`/events/${eventId}/settings/accreditation/profiles/create`);
    }

}
