import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import {
    omitAllUndefinedKeys,
    omitAllNullKeys,
    parseIntByKeys,
    transformGraphQLErrorsToFormErrors
} from 'app/react/helpers/_index';
import * as actions from 'app/react/state/actions/_index';
import * as Vehicle from 'app/react/entities/events/vehicles/index';
import CreateVehicleModalContainer from './CreateModalContainer';
import { VEHICLES_PAGINATED_TABLE_QUERY } from '../../table/PaginatedTableModalWrapperQueryContainer';
import * as State from './CreateModalState';

const MUTATION = gql`
    mutation CreateVehicle($eventId: ID!, $input: CreateVehicleInput!) {
        createVehicle(event: $eventId, input: $input) {
            vehicle {
                id
                type {
                    id
                }
                make
                model
                numberOfPassengers
                pickups {
                    totalCount
                }
                licencePlate
                mileageIn
                mileageOut
                notes
            }
        }
    }
`;

const injectMutation = graphql(
    MUTATION, {
        name: 'createVehicleMutation',
        options: ({ eventId, first, after, sort }) => ({
            refetchQueries: [{
                query: VEHICLES_PAGINATED_TABLE_QUERY,
                variables: { eventId, first, after, sort },
            }],
        }),
    }
);

const parseIntKeys = (obj) => parseIntByKeys(obj, ['type', 'numberOfPassengers', 'mileageIn', 'mileageOut']);

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSave: (isCreatingAnother, values) => {
        const { eventId, createVehicleMutation, onSaveSuccess } = ownProps;

        const finalValues = omitAllUndefinedKeys(omitAllNullKeys(parseIntKeys(values)));
        const input = {
            ...finalValues,
            type: typeof finalValues.type !== 'undefined'
                ? { id: finalValues.type }
                : null
        };

        dispatch(State.setStateValue('isSaving', true));

        createVehicleMutation({
            variables: { eventId, input }
        }).then(({ data }) => {
            const { vehicle } = data.createVehicle;
            const { id, make, model } = vehicle;

            // Add the vehicle to the state and collection
            const vehicles = [vehicle];
            const schemaMapping = { vehicles: Vehicle.schemas.entities };
            dispatch(actions.mergeEntities({ vehicles }, schemaMapping));
            dispatch(Vehicle.thunks.addOneToCurrentContextCollection(id));

            if (typeof onSaveSuccess === 'function') {
                onSaveSuccess(parseInt(id, 10));
            }

            dispatch(State.showSuccessPopup(make, model));
            dispatch(State.mergeState({
                isOpened: isCreatingAnother,
                isSaving: false,
                values: {},
                errors: {}
            }));
        }).catch(({ graphQLErrors }) => {
            const errors = transformGraphQLErrorsToFormErrors(graphQLErrors);
            dispatch(State.showErrorPopup());
            dispatch(State.mergeState({
                errors,
                isSaving: false
            }));
        });
    }
});

const injectState = connect(
    null,
    mapDispatchToProps,
);

export default compose(
    injectMutation,
    injectState,
)(CreateVehicleModalContainer)
