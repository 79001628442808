var Moment = require('moment'),
    Dotty = require("dotty"),
    HumanizeDuration = require('humanize-duration');

var DateTime = {

    account: {
        getMomentDateFormat(excludeYear = false) {
            var format = Dotty.get(window.beatswitch, 'auth.account.date_time_format.date.momentjs') || 'DD/MM/YYYY';
            if (excludeYear) return format.replace(/[/]y{1,}|y{1,}[-]/ig, ''); //RegEx to remove the year part
            else return format;
        },

        getMomentTimeFormat: function() {
            var format = Dotty.get(window.beatswitch, 'auth.account.date_time_format.time.momentjs');
            return format ? format : 'HH:mm';
        },

        getMomentDateTimeFormat: function() {
            return this.getMomentDateFormat() + " " + this.getMomentTimeFormat();
        },

        getDatepickerFormat: function() {
            var format = Dotty.get(window.beatswitch, 'auth.account.date_time_format.date.pickadate');
            return format ? format : 'dd/mm/yyyy';
        },

        getTimepickerFormat: function() {
            var format = Dotty.get(window.beatswitch, 'auth.account.date_time_format.time.pickatime');
            return format ? format : 'HH:i';
        },

        parseDateStringToMoment: function(dateString) {
            return Moment.utc(dateString, this.getMomentDateFormat());
        },

        parseTimeStringToMoment: function(timeString) {
            return Moment.utc(timeString, this.getMomentTimeFormat());
        },

        parseDateTimeStringToMoment: function(dateTimeString) {
            return Moment.utc(dateTimeString, this.getMomentDateTimeFormat());
        },

        parseDateStringToDate: function(dateString) {
            return this.parseDateStringToMoment(dateString).toDate();
        },

        parseTimeStringToDate: function(timeString) {
            return this.parseTimeStringToMoment(timeString).toDate();
        },

        parseDateTimeStringToDate: function(dateTimeString) {
            return this.parseDateTimeStringToMoment(dateTimeString).toDate();
        },

        parseDateTimeStringToISOString: function(dateTimeString) {
            return this.parseDateTimeStringToMoment(dateTimeString).format();
        },

        parseDateStringToISOString: function(dateString) {
            return this.parseDateTimeStringToMoment(dateString).format();
        },

        parseTimeStringToISOString: function(timeString) {
            return this.parseDateTimeStringToMoment(timeString).format();
        },

        formatMomentToDateString: function(mDate, excludeYear = false) {
            return mDate.utc().format(this.getMomentDateFormat(excludeYear));
        },

        formatMomentToTimeString: function(mDate) {
            return mDate.utc().format(this.getMomentTimeFormat());
        },

        formatMomentToDateTimeString: function(mDate) {
            return mDate.utc().format(this.getMomentDateTimeFormat());
        },

        formatDateToDateString(date, excludeYear = false) {
            return Moment(date).utc().format(this.getMomentDateFormat(excludeYear));
        },

        formatDateToTimeString: function(date) {
            return Moment(date).utc().format(this.getMomentTimeFormat());
        },

        formatDateToDateTimeString: function(date) {
            return Moment(date).utc().format(this.getMomentDateTimeFormat());
        }
    },

    event: {
        getMomentDateFormat(excludeYear = false) {
            var format = Dotty.get(window.beatswitch, 'event.data.date_time_format.date.momentjs') || 'DD/MM/YYYY';
            if (excludeYear) return format.replace(/[/]y{1,}|y{1,}[-]/ig, ''); //RegEx to remove the year part
            else return format;
        },

        getMomentTimeFormat: function() {
            var format = Dotty.get(window.beatswitch, 'event.data.date_time_format.time.momentjs');
            return format ? format : 'HH:mm';
        },

        getMomentDateTimeFormat: function() {
            return this.getMomentDateFormat() + " " + this.getMomentTimeFormat();
        },

        getDatepickerFormat: function() {
            var format = Dotty.get(window.beatswitch, 'auth.account.date_time_format.date.pickadate');
            return format ? format : 'dd/mm/yyyy';
        },

        getTimepickerFormat: function() {
            var format = Dotty.get(window.beatswitch, 'event.data.date_time_format.time.pickatime');
            return format ? format : 'HH:i';
        },

        parseDateStringToMoment: function(dateString) {
            return Moment.utc(dateString, this.getMomentDateFormat());
        },

        parseTimeStringToMoment: function(timeString) {
            return Moment.utc(timeString, this.getMomentTimeFormat());
        },

        parseDateTimeStringToMoment: function(dateTimeString) {
            return Moment.utc(dateTimeString, this.getMomentDateTimeFormat());
        },

        parseDateStringToDate: function(dateString) {
            return this.parseDateStringToMoment(dateString).toDate();
        },

        parseTimeStringToDate: function(timeString) {
            return this.parseTimeStringToMoment(timeString).toDate();
        },

        parseDateTimeStringToDate: function(dateTimeString) {
            return this.parseDateTimeStringToMoment(dateTimeString).toDate();
        },

        parseDateTimeStringToISOString: function(dateTimeString) {
            return this.parseDateTimeStringToMoment(dateTimeString).format();
        },

        parseDateStringToISOString: function(dateString) {
            return this.parseDateTimeStringToMoment(dateString).format();
        },

        parseTimeStringToISOString: function(timeString) {
            return this.parseDateTimeStringToMoment(timeString).format();
        },

        formatMomentToDateString: function(mDate, excludeYear = false) {
            return mDate.utc().format(this.getMomentDateFormat(excludeYear));
        },

        formatMomentToTimeString: function(mDate) {
            return mDate.utc().format(this.getMomentTimeFormat());
        },

        formatMomentToDateTimeString: function(mDate) {
            return mDate.utc().format(this.getMomentDateTimeFormat());
        },

        formatDateToDateString(date, excludeYear = false) {
            return Moment(date).utc().format(this.getMomentDateFormat(excludeYear));
        },

        formatDateToTimeString: function(date) {
            return Moment(date).utc().format(this.getMomentTimeFormat());
        },

        formatDateToDateTimeString: function(date) {
            return Moment(date).utc().format(this.getMomentDateTimeFormat());
        }
    },

    convertDateToUTC: function(date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    },

    duration: function(mStart, mEnd) {
        var duration;
        if(mStart && mEnd) {
            duration = HumanizeDuration(mEnd.diff(mStart), {
                language: 'en_short',
                delimiter: ' ',
                languages: {
                    en_short: {
                        y: function(c) { return "year" + ((c !== 1) ? "s" : ""); },
                        mo: function(c) { return "month" + ((c !== 1) ? "s" : ""); },
                        w: function(c) { return "week" + ((c !== 1) ? "s" : ""); },
                        d: function(c) { return "D"; },
                        h: function(c) { return "H"; },
                        m: function(c) { return "M"; },
                        s: function(c) { return "second" + ((c !== 1) ? "s" : ""); },
                        ms: function(c) { return "millisecond" + ((c !== 1) ? "s" : ""); },
                        decimal: "."
                    }
                }
            });
        } else {
            duration = 'Invalid duration';
        }
        return duration
    }
};

module.exports = DateTime;
