import { schema } from 'normalizr';
import { CALL_API, METHODS, includeParam } from 'app/react/state/middleware/api';
import { createPaginationReducer } from 'app/react/state/reducers/higher_order/_index';
import * as Image from 'app/react/entities/images/index';
import {
    getOneEntity,
    getEntitiesPagination,
    isFetchingEntities,
    getCurrentEventId
} from 'app/react/state/helpers';

export const TICKET_SETTINGS_REQUEST = 'TICKET_SETTINGS_REQUEST';
export const TICKET_SETTINGS_SUCCESS = 'TICKET_SETTINGS_SUCCESS';
export const TICKET_SETTINGS_FAILURE = 'TICKET_SETTINGS_FAILURE';

export const ENTITY_REDUCER_KEY = 'ticketSettings';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const DEFAULT_ID = 1;
export const TICKET_BACKGROUND_KEY = 'ticketBackground';
const entityDefinition = { [TICKET_BACKGROUND_KEY]: Image.schemas.entity };
const entityOptions = { idAttribute: () => DEFAULT_ID };
const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);

export const schemas = {
    entity
};

const getOne = (state) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, DEFAULT_ID);

const getPagination = (state) =>
    getEntitiesPagination(state, ENTITY_REDUCER_KEY, PAGINATION_REDUCER_KEY);

const isFetching = (state) =>
    isFetchingEntities(state, PAGINATION_REDUCER_KEY);

export const stateHelpers = {
    getOne,
    getPagination,
    isFetching
};

const paginationReducer = createPaginationReducer({
    types: [
        TICKET_SETTINGS_REQUEST,
        TICKET_SETTINGS_SUCCESS,
        TICKET_SETTINGS_FAILURE
    ]
});

export const reducers = {
    pagination: paginationReducer
};

const Endpoints = {
    one: (eventId) =>
        `events/${eventId}/accreditation/ticket-settings?${includeParam(['ticket_background', 'profiles.days'])}`
};

const fetchOne = (eventId) => ({
    [CALL_API]: {
        types: [TICKET_SETTINGS_REQUEST, TICKET_SETTINGS_SUCCESS, TICKET_SETTINGS_FAILURE],
        endpoint: Endpoints.one(eventId),
        schema: entity
    }
});

const updateOne = (eventId, updatedEntity) => ({
    [CALL_API]: {
        types: [TICKET_SETTINGS_REQUEST, TICKET_SETTINGS_SUCCESS, TICKET_SETTINGS_FAILURE],
        method: METHODS.PUT,
        id: DEFAULT_ID,
        body: updatedEntity,
        endpoint: Endpoints.one(eventId),
        schema: entity
    }
});

const loadOne = () => (dispatch, getState) => {
    const state = getState();
    const entity = getOne(state);
    if (typeof entity !== 'undefined') return Promise.resolve();
    const eventId = getCurrentEventId(state);
    return dispatch(fetchOne(eventId));
};

const editOne = (updatedEntity) => (dispatch, getState) => {
    const state = getState();
    const entity = getOne(state);
    if (typeof entity === 'undefined') return null;
    const eventId = getCurrentEventId(state);
    return dispatch(updateOne(eventId, updatedEntity));
};

export const actions = {
    fetchOne,
    updateOne,
    loadOne,
    editOne
};
