import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({isDisabled, label, variant, modFullWidth, type = 'button', onClick}) => (
    <button className={classNames('button', {
			'button__cancel': variant === 'cancel', 
			'button__primary': variant === 'primary',
			'button__secondary': variant == 'secondary',
			'button__disabled': isDisabled,
			'button--full-width': modFullWidth
			})}
			disabled={isDisabled}
			onClick={onClick}
			type={type}
		>
			{label}
		</button>
);

Button.propTypes = {
    isDisabled: PropTypes.bool,
    modFullWidth: PropTypes.bool,
		label: PropTypes.string,
		variant: PropTypes.oneOf(['cancel', 'primary', 'secondary']),
		type: PropTypes.oneOf(['submit', 'button']),
		onClick: PropTypes.func
};

export default Button;
