import dotProp from 'dot-prop-immutable';
import { createReducer } from 'redux-create-reducer';
import { ENTITY_REDUCER_KEY } from './schemas'
import { TOGGLE_TASK, TOGGLE_LOADING_TASK } from './actions'

const entityReducer = (state = {}) => state;

export const featureReducer = createReducer({}, {
    [TOGGLE_TASK]: (state, action) => {
        const { taskId, isCompleted } = action;
        return dotProp.set(
            state,
            `entities.${ENTITY_REDUCER_KEY}.${taskId}.isCompleted`,
            isCompleted
        );
    },
    [TOGGLE_LOADING_TASK]: (state, action) => {
        const { taskId, isLoading } = action;
        return dotProp.set(
            state,
            `entities.${ENTITY_REDUCER_KEY}.${taskId}.isLoading`,
            isLoading
        );
    },
});

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};
