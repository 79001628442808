import React from 'react'
import classNames from 'classnames'
import { countries } from 'country-data'
import './Flag.module.css'

const countryFlagUri = alpha3 => `https://restcountries.eu/data/${alpha3.toLowerCase()}.svg`;

const Flag = ({
    code,
    isBadge,
}) => {
    const alpha3 = code !== '' ? countries[code].alpha3 : undefined;
    const conditionalClassNames = { isBadge };

    return (
        <div
            styleName={classNames('flag', conditionalClassNames)}
            style={{ backgroundImage: alpha3 ? `url(${countryFlagUri(alpha3)})` : null }}
        />
    );
};

Flag.defaultProps = {
    code: '',
    isBadge: false,
};

export default Flag;
