import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import by from "thenby";
import { Field, Form, Formik } from "formik";
import DialogContainer from "../dialogContainer/DialogContainer";
import DialogCard from "../dialogCard/DialogCard";
import DialogCardBottom from "../dialogCard/DialogBottom";
import FormField from "../formfields/FormField";
import Button from "../button/Button";
import Checkbox from "../checkbox/Checkbox";
import TextEditor from "app/react/components/inputs/TextEditor";
import { Tooltip } from "app/react/components/_index";
import { Accordion, PrefixedInput, Grid, InputGroup, Switch } from "ui-kit";

const { Cell } = Grid;

const PagesDialog = ({
  addPage,
  page,
  contacts,
  productionWebsiteUrl,
  ...props
}) => {
  const [activeArtistTypeAccordion, setActiveArtistTypeAccordion] = useState(0);

  return (
    <DialogContainer>
      <DialogCard {...props}>
        <Formik
          initialValues={{
            slug: page.slug,
            homepage: page.homepage,
            name: page.name,
            body: page.body,
            form_page: page.form_page,
            sequence: page.sequence,
            artist_type_pages: page.artist_type_pages,
          }}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            const { isValid, values, setFieldValue } = formik;
            return (
              <Fragment>
                <div className={"dialog-inner-container"}>
                  <h3 className="dialog-title">
                    {page.id ? "Edit page" : "Create page"}
                  </h3>
                  <Form>
                    <InputGroup messages={[]}>
                      <label>Url</label>
                      <PrefixedInput label={productionWebsiteUrl}>
                        <Field
                          as="input"
                          type="text"
                          disabled={values.form_page}
                          name="slug"
                        />
                      </PrefixedInput>
                    </InputGroup>
                    {!(page.homepage && page.form_page) && (
                      <Field
                        name="homepage"
                        id="homepage"
                        as={Checkbox}
                        label="Homepage"
                      />
                    )}
                    <Field name="name" label="Page name" as={FormField} />
                    {!page.form_page && (
                      <TextEditor
                        imageInsertButtonsOverride={[
                          "imageBack",
                          "|",
                          "imageByURL",
                        ]}
                        height={200}
                        onBlur={(e) => setFieldValue(`body`, e.target.value)}
                        id="body"
                        value={values.body}
                      />
                    )}
                    {values.artist_type_pages && !page.form_page && (
                      <Accordion>
                        {values.artist_type_pages
                          .sort(by("artist_type_name"))
                          .map((artistTypePage, index) => (
                            <Accordion.Item
                              key={index}
                              id={index}
                              isDisabled={
                                !artistTypePage.visible &&
                                !artistTypePage.homepage
                              }
                              isOpen={activeArtistTypeAccordion === index + 1}
                              renderTitle={() => (
                                <Grid isExpanded hasGutters={false}>
                                  <Cell>{artistTypePage.artist_type_name}</Cell>
                                  {!page.homepage && !values.homepage && (
                                    <Cell
                                      isContentJustifiedAtEnd
                                      className="margin-tiny-right"
                                    >
                                      <Tooltip content="Show/hide this page for these artists.">
                                        <Switch
                                          id={`artist_type_pages.${index}.visible`}
                                          name={`artist_type_pages.${index}.visible`}
                                          isOn={artistTypePage.visible}
                                          onChange={(e, checked) =>
                                            setFieldValue(
                                              `artist_type_pages.${index}`,
                                              {
                                                ...artistTypePage,
                                                visible: checked,
                                              }
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </Cell>
                                  )}
                                </Grid>
                              )}
                            >
                              <InputGroup messages={[]}>
                                <label>Overwrite default text</label>
                                <TextEditor
                                  imageInsertButtonsOverride={[
                                    "imageBack",
                                    "|",
                                    "imageByURL",
                                  ]}
                                  height={200}
                                  value={
                                    values.artist_type_pages[index].body
                                      ? values.artist_type_pages[index].body
                                      : values.body
                                  }
                                  onBlur={(e) =>
                                    setFieldValue(
                                      `artist_type_pages.${index}.body`,
                                      e.target.value
                                    )
                                  }
                                />
                              </InputGroup>
                            </Accordion.Item>
                          ))}
                      </Accordion>
                    )}
                  </Form>
                </div>
                <DialogCardBottom>
                  <Button
                    label={page.id ? "Update" : "Add"}
                    variant="primary"
                    type="button"
                    onClick={() => addPage({ ...values, id: page.id })}
                    isDisabled={!isValid}
                  />
                </DialogCardBottom>
              </Fragment>
            );
          }}
        </Formik>
      </DialogCard>
    </DialogContainer>
  );
};

PagesDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  page: PropTypes.any.isRequired,
};

export default PagesDialog;
