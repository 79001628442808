import { schema } from 'normalizr';
import * as Artist from 'app/react/entities/accounts/artists/schemas';
import * as ArtistType from 'app/react/entities/events/artist_types/schemas';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/schemas';
import * as BookingType from 'app/react/entities/booking_types/schemas'
import * as Performance from 'app/react/entities/events/performances/schemas';
import * as Task from 'app/react/entities/events/bookings/tasks/schemas';
import * as BookingItem from 'app/react/entities/events/bookings/items/schemas';
import * as User from 'app/react/entities/users/schemas';

export const ENTITY_REDUCER_KEY = 'bookings';
export const ENTITY_CONTEXT_ID_KEY = `bookingId`;
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const KEYS = {
    ARTIST: 'artist',
    ARTIST_TYPE: 'artistType',
    STATUS: 'status',
    TYPE: 'type',
    PERFORMANCE: 'performance',
    TASKS: 'tasks',
    ASSIGNED_ITEMS: 'assignedItems',
    ADVANCING_USER: 'advancingUser',
};

const entityDefinition = {
    [KEYS.ARTIST]: Artist.entity,
    [KEYS.ARTIST_TYPE]: ArtistType.entity,
    [KEYS.STATUS]: BookingStatus.entity,
    [KEYS.TYPE]: BookingType.entity,
    [KEYS.PERFORMANCE]: Performance.entity,
    [KEYS.TASKS]: Task.entities,
    [KEYS.ASSIGNED_ITEMS]: BookingItem.entities,
    [KEYS.ADVANCING_USER]: User.entity,
};

const processStrategy = entity => ({
    ...entity,
    isUnassigned: entity[KEYS.PERFORMANCE] === null
});

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, { processStrategy });
export const entities = new schema.Array(entity);

entity.KEYS = KEYS;
