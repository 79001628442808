import by from 'thenby';
import React from 'react';
import PropTypes from 'prop-types';
import {
    AlertBox,
    Card,
    Grid,
} from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { Loading, ConfirmModal, ConfirmModalWithCheckbox, Protip } from 'app/react/components/_index';
import SchedulerContentHeaderContainer from './layout/ContentHeaderContainer';
import Scheduler from './Scheduler';
import TopBar from './TopBar';
import CreateModal from './modals/create/CreateModalContainer';
import UpdateModal from './modals/update/ModalContainer';
import DetailsModal from './modals/details/ModalContainer';

const { Content } = ContentWrapper;
const { Header } = Card;
const { Tabs } = Header;
const { Tab } = Tabs;
const { Cell } = Grid;

export const DELETE_CONFIRM_MODAL_ID = 'deletePerformanceConfirmModal';
export const PERFORMANCE_SCHEDULER_CARD_ID = 'performanceSchedulerCard';
export const PERFORMANCE_SCHEDULER_ID = dayId => `performanceScheduler${dayId}`;
export const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';
const TIP_REORDER_RESOURCES = 'You can change the order of the stages on the scheduler by using the up/down arrow displayed next to the stage name.';
export const REORDER_RESOURCES_CONFIRM_MODAL_ID = 'reorderResourcesConfirmModal';

const SchedulerView = ({
    store,
    eventDateFormat,
    eventTimeFormat,
    userCanManageTimetable = false,
    userCanManageSequenceStages = false,
    selectedDay = {
        stages: []
    },
    activeInterval,
    isFetching = true,
    isFetchingPerformancesForSelectedDayId = true,

    days = [],
    stages = [],
    performances = [],
    bookingStatuses = [],

    deleteConfirmModalState = {},
    onDeleteConfirmModalConfirmed = () => {},
    onDeleteConfirmModalClosed = () => {},

    reorderResourcesConfirmModalState = {},
    onReorderResourcesConfirmModalConfirmed = () => {},
    onReorderResourcesConfirmModalClosed = () => {},

    onChangeDay = () => {},
    onChangeInterval = () => {},
    onSelectPeriod = () => {},
    onDropPerformance = () => {},
    onResizePerformance = () => {},
    onToggleLockPerformance = () => {},
    onUpdatePerformance = () => {},

    onViewPerformance = () => {},
    onViewPerformanceClosed = () => {},

    onDeletePerformance = () => {},
    onTogglePublishPerformance = () => {},

    onReorderResources = () => {}
}) => {
    const selectedDayHasStages = typeof selectedDay.stages !== 'undefined' && selectedDay.stages.length > 0;
    const stagesForSelectedDay = selectedDayHasStages
        ? stages.filter(stage => selectedDay.stages.includes(stage.id))
        : [];
    const performancesForSelectedDay = performances.filter(performance => performance.day === selectedDay.id);
    return (
        <ContentWrapper store={store}>
            <SchedulerContentHeaderContainer />
            <Content isLoading={isFetching}>
                {!userCanManageTimetable
                    ? <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox>
                    : <Card
                        isFull
                        className="no-margin"
                    >
                        <Header hasTabs className="card--header--filling-tabs">
                            <Tabs>
                                {days.sort(by('start')).map(day => (
                                    <Tab
                                        key={day.id}
                                        isActive={day.id === selectedDay.id}
                                        onClick={() => onChangeDay(day.id)}
                                    >
                                        {day.name}
                                    </Tab>
                                ))}
                            </Tabs>
                        </Header>
                        <Card.Content isFlexContainer>
                            <TopBar
                                statuses={bookingStatuses}
                                activeInterval={activeInterval}
                                onChangeInterval={onChangeInterval}
                            />
                            {isFetchingPerformancesForSelectedDayId
                                ? <Loading height="300px" />
                                : <Scheduler
                                    dateFormat={eventDateFormat}
                                    timeFormat={eventTimeFormat}
                                    dayId={selectedDay.id}
                                    start={selectedDay.start}
                                    end={selectedDay.end}
                                    interval={activeInterval}
                                    stages={stagesForSelectedDay}
                                    performances={performancesForSelectedDay}
                                    onSelectPeriod={onSelectPeriod}
                                    onDropPerformance={onDropPerformance}
                                    onResizePerformance={onResizePerformance}
                                    onToggleLockPerformance={onToggleLockPerformance}
                                    onUpdatePerformance={onUpdatePerformance}
                                    onViewPerformance={onViewPerformance}
                                    onDeletePerformance={onDeletePerformance}
                                    onTogglePublishPerformance={onTogglePublishPerformance}
                                    onReorderResources={onReorderResources}
                                    userCanManageSequenceStages={userCanManageSequenceStages}
                                />
                            }
                            {userCanManageSequenceStages &&
                                <Grid className="no-margin-top no-margin-bottom">
                                    <Cell>
                                        <Protip isCentered>{TIP_REORDER_RESOURCES}</Protip>
                                    </Cell>
                                </Grid>
                            }
                        </Card.Content>
                    </Card>
                }
            </Content>
            <ConfirmModal
                isOpened={deleteConfirmModalState.isOpened}
                isSyncing={deleteConfirmModalState.isSyncing}
                onConfirmed={onDeleteConfirmModalConfirmed}
                onClosed={onDeleteConfirmModalClosed}
                title={deleteConfirmModalState.title}
                text={deleteConfirmModalState.text}
                buttonText={deleteConfirmModalState.buttonText}
                buttonClassName="alert"
            />
            <ConfirmModalWithCheckbox
                isOpened={reorderResourcesConfirmModalState.isOpened}
                isSyncing={reorderResourcesConfirmModalState.isSyncing}
                onConfirmed={onReorderResourcesConfirmModalConfirmed}
                onClosed={onReorderResourcesConfirmModalClosed}
                title={reorderResourcesConfirmModalState.title}
                text={reorderResourcesConfirmModalState.text}
                buttonText={reorderResourcesConfirmModalState.buttonText}
            />
            <CreateModal store={store} />
            <UpdateModal store={store} />
            <DetailsModal store={store} />
        </ContentWrapper>
    )
};

SchedulerView.propTypes = {
    store: PropTypes.object.isRequired,
    eventId: PropTypes.number,
    eventDateFormat: PropTypes.string,
    eventTimeFormat: PropTypes.string,
    selectedDay: PropTypes.object,
    activeInterval: PropTypes.string,
    isFetchingEvent: PropTypes.bool,
    isFetchingPerformancesForSelectedDayId: PropTypes.bool,
    days: PropTypes.array,
    stages: PropTypes.array,
    performances: PropTypes.array,
    onChangeDay: PropTypes.func,
    onChangeInterval: PropTypes.func,
    onSelectPeriod: PropTypes.func,
    onDropPerformance: PropTypes.func,
    onResizePerformance: PropTypes.func,
    onToggleLockPerformance: PropTypes.func,
    onUpdatePerformance: PropTypes.func,
    onViewPerformance: PropTypes.func,
    onDeletePerformance: PropTypes.func,
    onTogglePublishPerformance: PropTypes.func
};

export default SchedulerView;
