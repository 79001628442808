import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import { proxyStaticProps } from 'ui-kit/lib/util/hoc'
import { Overlay } from 'ui-kit'

const Portal = ({ children, container }) => createPortal(children, container);

const withState = (Modal) => {
    class ModalWithState extends Component {

        componentWillReceiveProps({ opened, isOpened }) {
            if (opened && !this.props.opened) {
                this.onOpen();
            } else if (isOpened && !this.props.isOpened) {
                this.onOpen();
            } else if (!opened && this.props.opened) {
                this.onClose();
            } else if (!isOpened && this.props.isOpened) {
                this.onClose();
            }
        }

        isOpen() {
            // Yes, both of these are currently used atm... Seems like the latter is mostly used for ConfirmModals.
            return this.props.opened || this.props.isOpened;
        }

        onOpen() {
            if (typeof this.props.onOpen === 'function') {
                this.props.onOpen();
            }

            /**
             * @deprecated Use onOpen instead...
             */
            if (typeof this.props.onOpened === 'function') {
                this.props.onOpened();
            }
        }

        onClose() {
            if (typeof this.props.onClose === 'function') {
                this.props.onClose();
            }

            /**
             * @deprecated Use onClose instead...
             */
            if (typeof this.props.onClosed === 'function') {
                this.props.onClosed();
            }
        }

        render() {
            const { containerId } = this.props;

            return this.isOpen()
                ? <Portal container={document.getElementById(containerId)}>
                    <Overlay>
                        <Modal
                            {...this.props}
                            openModal={this.onOpen.bind(this)}
                            closeModal={this.onClose.bind(this)}
                        />
                    </Overlay>
                </Portal>
                : null;
        }
    };

    proxyStaticProps(Modal, ModalWithState);

    ModalWithState.defaultProps = {
        containerId: 'modals',
        ...Modal.defaultProps,
    };

    return ModalWithState;
};

export default withState;
