import React from 'react';
import PropTypes from 'prop-types';
import {
    InputGroup,
    PostfixedInput,
    AutoSuggest,
} from 'ui-kit';
import { Modal } from 'app/react/components/_index';
import SelectItem from '../components/SelectItem';
import SelectItemGroup from 'app/react/views/account/settings/artists/items/components/groups/SelectItemGroup';

const { Header, Content, Footer } = Modal;

const LABELS = {
    item: 'Item',
    amount: 'Quantity',
    location: 'Location',
    notes: 'Note',
    name: 'Name',
    group: 'Group',
    description: 'Short description',
    price: 'Price per unit',
};

const CREATE_TITLE = 'Assign item';
const CREATE_BUTTON_TEXT = 'Assign';
const CREATE_ANOTHER_BUTTON_TEXT = 'Assign another';
const DESCRIPTION_LABEL = 'Short description';

const UPDATE_TITLE = 'Update assigned item';
const UPDATE_BUTTON_TEXT = 'Update';
const FIELDSET_TITLE = 'New item';

const BookingItemModal = ({
    isCreating = false,
    canCreateAnother = true,
    isOpened = false,
    isSaving = false,
    isCreatingNewItem = false,
    values = {},
    errors = {},

    currency = 'EUR',
    items = [],
    groups = [],
    itemLocations = [],

    onClosed = () => {},
    onChangeValue = () => {},
    onToggleCreateNewItem = () => {},
    onSave = () => {},
    updateLocations = () => {},
}) => (
    <Modal
        width={640}
        closeOnBackgroundClick={false}
        opened={isOpened}
        syncing={isSaving}
        onClosed={onClosed}
    >
        <Header onClosed={onClosed}>
            <Modal.Header.Title>{isCreating ? CREATE_TITLE : UPDATE_TITLE}</Modal.Header.Title>
        </Header>
        <Content>
            {(() => {
                if (isCreating) {
                    if (isCreatingNewItem) {
                        return (
                            <fieldset className="no-margin-top">
                                <legend>
                                    {FIELDSET_TITLE}
                                    <a onClick={() => onToggleCreateNewItem(false)}>
                                        <i className="bs-icon-cross last" />
                                    </a>
                                </legend>
                                <InputGroup messages={errors.name}>
                                    <label className="required">
                                        {LABELS.name}
                                    </label>
                                    <input
                                        type="text"
                                        value={values.name || ''}
                                        onChange={e => onChangeValue('name', e.target.value)}
                                    />
                                </InputGroup>
                                <InputGroup messages={errors.description}>
                                    <label>
                                        {DESCRIPTION_LABEL}
                                    </label>
                                    <input
                                        type="text"
                                        value={values.description || ''}
                                        onChange={e => onChangeValue('description', e.target.value)}
                                    />
                                </InputGroup>
                                <InputGroup messages={errors.group}>
                                    <label className="required">
                                        {LABELS.group}
                                    </label>
                                    <SelectItemGroup
                                        groups={groups}
                                        value={values.group}
                                        onChange={value => onChangeValue('group', value)}
                                    />
                                </InputGroup>
                                <InputGroup messages={errors.price}>
                                    <label>
                                        {LABELS.price}
                                    </label>
                                    <PostfixedInput isUnified label={currency}>
                                        <input
                                            type="number"
                                            value={values.price || ''}
                                            onChange={e => onChangeValue('price', e.target.value)}
                                        />
                                    </PostfixedInput>
                                </InputGroup>
                            </fieldset>
                        )
                    }
                    return (
                        <InputGroup messages={errors.item}>
                            <label className="required">
                                {LABELS.item}
                            </label>
                            <PostfixedInput.Container postfix={(
                                <button
                                    onClick={() => onToggleCreateNewItem(true)}
                                    className="button postfix"
                                >
                                    New
                                </button>
                            )}>
                                <SelectItem
                                    items={items}
                                    groups={groups}
                                    value={values.item}
                                    onChange={value => onChangeValue('item', value)}
                                />
                            </PostfixedInput.Container>
                        </InputGroup>
                    )
                }
                return null
            })()}
            <InputGroup messages={errors.amount}>
                <label className="required">
                    {LABELS.amount}
                </label>
                <input
                    type="number"
                    value={values.amount || ''}
                    onChange={e => onChangeValue('amount', e.target.value)}
                />
            </InputGroup>
            <InputGroup messages={errors.location}>
                <label>
                    {LABELS.location}
                </label>
                <AutoSuggest
                    suggestions={itemLocations}
                    handleChange={value => onChangeValue('location', value)}
                    value={values.location || ''}
                />
            </InputGroup>
            <InputGroup messages={errors.notes}>
                <label>
                    {LABELS.notes}
                </label>
                <textarea
                    value={values.notes || ''}
                    onChange={e => onChangeValue('notes', e.target.value)}
                />
            </InputGroup>
        </Content>
        {isCreating
            ? <Footer>
                {canCreateAnother &&
                    <Footer.Button
                        className="create-another"
                        onClick={() => {
                            onSave(true)
                            updateLocations(values.location)
                        }}
                    >
                        {CREATE_ANOTHER_BUTTON_TEXT}
                    </Footer.Button>
                }
                <Footer.Button
                    className="success"
                    onClick={() => {
                        onSave()
                        updateLocations(values.location)
                    }}
                >
                    {CREATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
            : <Footer>
                <Footer.Button
                    className="success"
                    onClick={() => {
                        onSave()
                        updateLocations(values.location)
                    }}
                >
                    {UPDATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
        }
    </Modal>
);

BookingItemModal.propTypes = {
    isCreating: PropTypes.bool,
    canCreateAnother: PropTypes.bool,
    isOpened: PropTypes.bool,
    isSaving: PropTypes.bool,
    values: PropTypes.object,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    onToggleCreateNewItem: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
};

export default BookingItemModal;
