import merge from 'lodash.merge';
import { isObject } from 'underscore';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import apiMiddleware from './middleware/api';
import graphqlMiddleware from './middleware/graphql';
import broadcastingMiddleware from './middleware/broadcasting';
import { DOM_OBJECT, getDataFromDOM } from './data_sources/dom';
import { rootReducer } from './reducers/_index';
import * as User from 'app/react/entities/users/schemas';
import * as Team from 'app/react/entities/team/schemas';
import * as Account from 'app/react/entities/accounts/schemas';
import * as Event from 'app/react/entities/events/schemas';
import * as Booking from 'app/react/entities/events/bookings/schemas';

import persistState, { mergePersistedState } from 'redux-localstorage';
import createLocalStorageAdapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';

const composeInitialState = () => {
    let initialState = {};
    if (DOM_OBJECT) {
        const { csrf, auth = {}, services = {} } = DOM_OBJECT;
        const jwt = auth.jwt;
        initialState.tokens = { csrf, jwt };

        const froala = services.froala.key;
        const fullcalendarScheduler = services.fullcalendar_scheduler.key;
        initialState.services = { froala, fullcalendarScheduler };

        const user = getDataFromDOM('auth.user', User.entity);
        initialState = merge({}, initialState, user);
        const team = getDataFromDOM('auth.team', Team.entity);
        initialState = merge({}, initialState, team);
        const account = getDataFromDOM('auth.account', Account.entity);
        initialState = merge({}, initialState, account);
        const event = getDataFromDOM('event', Event.entity);
        initialState = merge({}, initialState, event);
        const booking = getDataFromDOM('booking', Booking.entity);
        initialState = merge({}, initialState, booking);

        delete initialState.result;
        initialState.currentContext = {
            userId: user.result,
            teamId: team.result,
            accountId: account.result,
            eventId: event.result,
            bookingId: booking.result
        };
    }
    initialState.userSettings = {}
    return initialState;
};

const LOCAL_STORAGE_KEY = 'beatswitchRedux';
const PAGINATION_FILTER_KEY = 'pagination';
const REMIND_SETTINGS_FILTER_KEY = 'userSettings';
const FILTER_KEYS = [PAGINATION_FILTER_KEY, REMIND_SETTINGS_FILTER_KEY];
const WHITE_LISTED_PAGINATION_KEYS = [
    'requestedPage',
    'requestedPageSize',
    'requestedFilters',
    'requestedSortKey',
    'requestedSortOrder'
];

const whiteListPaginationKeys = (pagination = {}) => {
    const newPagination = {};
    Object.keys(pagination).forEach(key => {
        Object.keys(pagination[key]).forEach(subKey => {
            if (isObject(pagination[key][subKey]) && !Array.isArray(pagination[key][subKey])) {
                newPagination[key] = {};
                newPagination[key][subKey] = {};
                Object.keys(pagination[key][subKey]).forEach(subSubKey => {
                    if (WHITE_LISTED_PAGINATION_KEYS.includes(subSubKey)) {
                        newPagination[key][subKey][subSubKey] = pagination[key][subKey][subSubKey];
                    }
                })
            } else {
                if (WHITE_LISTED_PAGINATION_KEYS.includes(subKey)) {
                    newPagination[key][subKey] = pagination[key][subKey];
                }
            }
        })
    });
    return newPagination;
};

const mergeInitialStateWithStoredState = (initialState, storedState) => {
    return ({
        ...initialState,
        ...storedState,
        [PAGINATION_FILTER_KEY]: whiteListPaginationKeys(storedState[PAGINATION_FILTER_KEY])
    });
};

export const createReduxStore = () => {
    const persistedStateReducer = mergePersistedState(mergeInitialStateWithStoredState);
    const reducer = compose(persistedStateReducer)(rootReducer);

    const storageFilter = filter(FILTER_KEYS);
    const localStorageAdapter = createLocalStorageAdapter(window.localStorage);
    const storage = compose(storageFilter)(localStorageAdapter);

    const persistStateEnhancer = persistState(storage, LOCAL_STORAGE_KEY);
    const middlewareEnhancer = applyMiddleware(
        thunkMiddleware,
        apiMiddleware,
        graphqlMiddleware,
        broadcastingMiddleware
    );

    const devToolsComposeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const enhancer = devToolsComposeEnhancers(
        middlewareEnhancer,
        persistStateEnhancer
    );

    return createStore(
        reducer,
        composeInitialState(),
        enhancer,
    );
};

export const reduxStore = createReduxStore();
