import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InputGroup, DateInput } from 'ui-kit'
import FileUpload from 'app/react/components/upload/FileUpload'
import { reformatISOString } from 'app/react/helpers/_index';

const ALLOWED_EXTENSIONS = ['pdf', 'jpg', 'jpeg'];

const { FileInfo } = FileUpload;

const compareSequenceAsc = (a, b) => {
    if (a.sequence < b.sequence) return -1;
    if (a.sequence > b.sequence) return 1;
    return 0;
};

export default class DynamicForm extends React.Component {

    static get propTypes() {
        return {
            fields: PropTypes.array,
            values: PropTypes.object,
            errors: PropTypes.object,
            onChange: PropTypes.func
        };
    }

    static get defaultProps() {
        return {
            fields: [],
            values: {},
            errors: {},
            onChange() {},
            width: '100%',
            dateFormat: 'L'
        };
    }

    render() {
        const { fields } = this.props;
        return (
            <div>
                {fields.sort(compareSequenceAsc).map(field => this.renderInputGroup(field))}
            </div>
        );
    }

    renderInputGroup({ id, type, label, placeholder, required }) {
        const { errors, width } = this.props;
        return (
            <InputGroup key={id} style={{ width }} messages={errors[id]}>
                <label className={classNames({ required })}>
                    {label}
                </label>
                {this.renderInput({ id, type, placeholder })}
            </InputGroup>
        );
    }

    renderInput({ id, type, placeholder }) {
        const { values, errors, dateFormat, disabled } = this.props;
        const className = classNames({ error: !! errors[id] });
        const value = values[id] || '';
        if (type === 'file') {
            return (
                <FileUpload
                    className={className}
                    value={value}
                    disabled={disabled}
                    onChange={this.onChangeFileUpload.bind(this, id)}
                >
                    <FileInfo
                        maxSize={5}
                        allowedFileExtensions={ALLOWED_EXTENSIONS}
                    />
                </FileUpload>
            );
        }
        if (type === 'date') {
            return (
                <DateInput
                    className={className}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    dateFormat={dateFormat}
                    onChange={this.onChangeDate.bind(this, id)}
                />
            );
        }
        return (
            <input
                type="text"
                className={className}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onChange={this.onChange.bind(this, id)}
            />
        );
    }

    onChange(id, e) {
        const { onChange } = this.props;
        const { value } = e.target;
        onChange(id, value);
    }

    onChangeFileUpload(id, file) {
        const { onChange } = this.props;
        onChange(id, file);
    }

    onChangeDate(id, value) {
        const { onChange } = this.props;
        // we need to reformat the ISO string to the correct format needed by the API
        onChange(id, reformatISOString(value));
    }

}
