var ItemGroup = require('app/backbone/models/account/accreditation/ItemGroup'),
    ItemModal = require('../../../views/modals/ItemModal.view');

var template = require('./ItemGroupModal');

var ItemGroupModal = ItemModal.extend({

    template,

    bindings: {
        '[name=name]': 'name'
    },

    options: [
        'width',
        'canCreateAnother',
        'hiddenInputs',
        'disabledInputs'
    ],

    initForm(model){
        this.disableInputs(this.disabledInputs);

        if(this.isAvailable('name')) this.groups.add(this.initValidationGroup('name', model), 'name');
    },

    newModel(attributes, options) {
        return new ItemGroup.Model(attributes, options);
    }

});

module.exports = ItemGroupModal;
