import BaseView from 'app/backbone/components/Base.view';
import PermissionsForm from './forms/PermissionsForm.view';

export default class RoleDetailsView extends BaseView {

    ui() {
        return {
            permissionForm: '#permissions-form'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.permissionForm = this.initPermissionForm();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderPermissionForm();
        return this.delegateEvents();
    }

    initPermissionForm() {
        return new PermissionsForm();
    }

    renderPermissionForm() {
        if (this.permissionForm) this.permissionForm.setElement(this.ui.permissionForm).render();
    }

}
