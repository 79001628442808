import { contactsUpdate } from 'app/routes/account/routes'
import BaseView from 'app/backbone/components/Base.view'
import Backbone from 'backbone'
import SingleSelectize from 'app/backbone/components/inputs/SingleSelectize.view'

export default class CreateDriverView extends BaseView {

    ui() {
        return {
            contactDropdown: 'select[name=contact]',
            newContactButton: '#new-contact',
            newContactFields: '#new-contact-fields',
            editContactButton: '#edit-contact',
        };
    }

    events() {
        return {
            'change contactDropdown': 'useContactFromCRM',
            'click newContactButton': 'addNewContact',
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.inputs = new Backbone.ChildViewContainer();
    }

    initContactSelectize() {
        const contactSelectizeEl = this.ui.contactDropdown;
        const contactSelectize = new SingleSelectize({
            el: contactSelectizeEl,
        });

        this.inputs.add(contactSelectize, 'contact');
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.initContactSelectize();
        return this.delegateEvents();
    }

    useContactFromCRM(e) {
        const contactId = e.target.value;

        if (contactId) {
            const url = `${contactsUpdate(contactId)}?redirect=${window.location}`;
            this.ui.editContactButton.attr('href', url).show();
            this.ui.newContactFields.hide();
            this.ui.newContactButton.show();
        } else {
            this.showNewContactFields();
        }
    }

    showNewContactFields() {
        this.ui.contactDropdown[0].selectize.clear();
        this.ui.editContactButton.attr('href', '#').hide();
        this.ui.newContactFields.show();
        this.ui.newContactButton.hide();
    }

    addNewContact(e) {
        e.preventDefault();
        this.showNewContactFields();
    }
};
