import BaseView from 'app/backbone/components/Base.view';

export default class NavigationCollapsableView extends BaseView {

    initialize(options = {}) {
        super.initialize(options);
        this.collapsed = false;
        this.collapseWidth = 1024;
    }

    toggleCollapse(windowIsSmall) {
        if (windowIsSmall) {
            this.$el.removeClass('collapsed');
            this.$el.toggleClass('expanded');
            this.collapsed = false;
        } else {
            this.$el.removeClass('expanded');
            this.$el.toggleClass('collapsed');
            this.collapsed = true;
        }
    }

}
