import { connect } from 'react-redux';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Day from 'app/react/entities/events/days/index';
import * as Stage from 'app/react/entities/events/stages/index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Tasks from 'app/react/entities/events/bookings/tasks/index';
import BookingOverview from './Overview';
import {
    detailsRoute,
    createRoute,
    detailsParkingRoute,
    detailsReservationsRoute,
    detailsSendItineraryRoute,
    detailsSendLoginRoute,
    createTransportRoute,
    editTransportRoute,
    editPickupRoute
} from 'app/routes/event/artists/bookings/Controller';

export const CARD_ID = 'bookingOverviewCard';
export const getCardState = (state) =>
    stateHelpers.getComponentState(state, CARD_ID) || {};
export const mergeNewCardState = (newState) =>
    actions.setComponentState(CARD_ID, newState);
export const setCardStateValue = (key, value) =>
    actions.setComponentStateValue(CARD_ID, key, value);
export const deleteCardStateValue = (key) =>
    actions.deleteComponentStateValue(CARD_ID, key);

const mapStateToProps = (state) => {
    const {
        searchValue = '',
    } = getCardState(state);
    const pagination = Booking.stateHelpers.getPaginationForCurrentEvent(state) || {};
    const { isFetching = false, requestedPageSize, requestedFilters = {}, requestedSearch } = pagination;
    const currentEvent = stateHelpers.events.getCurrent(state);

    return {
        isFetching: (
            Day.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Stage.stateHelpers.isFetchingAllForCurrentContext(state) ||
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            BookingType.stateHelpers.isFetchingAll(state) ||
            BookingStatus.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        accountId: stateHelpers.accounts.getCurrentId(state),
        eventId: currentEvent.id,
        eventPermissions: currentEvent.permissions,
        isArtistTypesEnabled: stateHelpers.events.isArtistTypesEnabledForCurrent(state),
        dateFormat: stateHelpers.events.getCurrentDateFormat(state),
        timeFormat: stateHelpers.events.getCurrentTimeFormat(state),

        bookings: Booking.stateHelpers.denormalizeAllByPaginationForCurrentEvent(state),
        days: Day.stateHelpers.getAllForCurrentContext(state),
        stages: Stage.stateHelpers.getAllForCurrentContext(state),
        artists: Artist.stateHelpers.getAll(state),
        artistTypes: ArtistType.stateHelpers.getAllForCurrentContext(state),
        bookingTypes: BookingType.stateHelpers.getAll(state),
        bookingStatuses: BookingStatus.stateHelpers.getAllForCurrentContext(state),

        bookingsPagination: pagination,
        isFetchingBookings: isFetching,
        selectedPageSize: requestedPageSize,
        requestedFilters,
        selectedAdvancingUserFilter: requestedFilters.advancingUser,
        selectedDayFilter: requestedFilters.day,
        selectedStageFilter: requestedFilters.stage,
        selectedArtistTypeFilter: requestedFilters.artistType,
        selectedBookingStatusFilter: requestedFilters.bookingStatus,

        searchValue,
        isShowingSearchResults: typeof requestedSearch !== 'undefined' && requestedSearch !== '',
    };
};

const navigateToBooking = (url, newTab, redirect) => {
    if (newTab) {
        return actions.navigate.newTab(url, { redirect })
    }
    return actions.navigate.pageReload(url, { redirect })
};

const getCurrentPath = () => {
    const { location } = window;
    const pathName = location.pathname;
    const queryStrings = location.search;
    return `${pathName}${queryStrings}`
}

const mapDispatchToProps = (dispatch) => ({
    onChangePageSize: (size) => {
        console.warn(size)
        dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(1, size))
    },
    onClearAllFilters: () => {
        dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(1, null, {}, ''))
    },
    onChangeFilter: (key, value, currentFilters = {}) => {
        const newFilters = { ...currentFilters, [key]: value };
        if (value === 0) {
            delete newFilters[key];
        }
        if (key === 'day') {
            delete newFilters.stage
        }
        dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(1, null, newFilters, null))
    },
    onChangeSearchValue(value) {
        dispatch(setCardStateValue('searchValue', value))
    },
    onSearch: (search) => {
        dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(1, null, null, search))
    },
    onClearSearch: () => {
        dispatch(setCardStateValue('searchValue', ''))
        dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(1, null, null, ''))
    },
    onSort: (key, order) => {
        dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(1, null, null, null, key, order))
    },
    onChangePage: (page) => {
        dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(page))
    },
    onClickBooking: (eventId, bookingId, newTab = false) => {
        const route = detailsRoute(eventId, bookingId);
        dispatch(navigateToBooking(route, newTab));
    },
    onClickBookingTransport(eventId, bookingId, transportId, newTab = false) {
        const redirect = getCurrentPath();
        const route = editTransportRoute(bookingId, transportId);
        dispatch(navigateToBooking(route, newTab, redirect));
    },
    onClickBookingPickup(eventId, bookingId, pickupId, newTab = false) {
        const redirect = getCurrentPath();
        const route = editPickupRoute(eventId, bookingId, pickupId);
        dispatch(navigateToBooking(route, newTab, redirect));
    },
    onCreateBookingTransport(eventId, bookingId, newTab = false) {
        const redirect = getCurrentPath();
        const route = createTransportRoute(bookingId);
        dispatch(navigateToBooking(route, newTab, redirect));
    },
    onClickBookingParking(eventId, bookingId, newTab = false) {
        const route = detailsParkingRoute(eventId, bookingId);
        dispatch(navigateToBooking(route, newTab));
    },
    onClickBookingReservations(eventId, bookingId, newTab = false) {
        const route = detailsReservationsRoute(eventId, bookingId);
        dispatch(navigateToBooking(route, newTab));
    },
    onToggleBookingTask(eventId, bookingId, taskId) {
        dispatch(Tasks.thunks.ajaxToggleTask(eventId, bookingId, taskId));
    },
    onSendBookingItinerary(eventId, bookingId, newTab = false) {
        const redirect = getCurrentPath();
        const route = detailsSendItineraryRoute(eventId, bookingId);
        dispatch(navigateToBooking(route, newTab, redirect));
    },
    onSendBookingLogin(eventId, bookingId, newTab = false) {
        const redirect = getCurrentPath();
        const route = detailsSendLoginRoute(eventId, bookingId);
        dispatch(navigateToBooking(route, newTab, redirect));
    },
    onDeleteBooking(eventId, bookingId, newTab = false) {
    },
    onCreateBooking(eventId) {
        dispatch(actions.navigate.to(createRoute(eventId)));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onCreateBooking: () =>
        dispatchProps.onCreateBooking(stateProps.eventId),
    onChangeFilter: (key, value) =>
        dispatchProps.onChangeFilter(key, value, stateProps.requestedFilters),
    onClickBooking: (bookingId, newTab) =>
        dispatchProps.onClickBooking(stateProps.eventId, bookingId, newTab),
    onClickBookingTransport: (bookingId, transportId, newTab) =>
        dispatchProps.onClickBookingTransport(stateProps.eventId, bookingId, transportId, newTab),
    onClickBookingPickup: (bookingId, pickupId, newTab) =>
        dispatchProps.onClickBookingPickup(stateProps.eventId, bookingId, pickupId, newTab),
    onCreateBookingTransport: (bookingId, newTab) =>
        dispatchProps.onCreateBookingTransport(stateProps.eventId, bookingId, newTab),
    onClickBookingParking: (bookingId, newTab) =>
        dispatchProps.onClickBookingParking(stateProps.eventId, bookingId, newTab),
    onClickBookingReservations: (bookingId, newTab) =>
        dispatchProps.onClickBookingReservations(stateProps.eventId, bookingId, newTab),
    onSendBookingItinerary: (bookingId, newTab) =>
        dispatchProps.onSendBookingItinerary(stateProps.eventId, bookingId, newTab),
    onSendBookingLogin: (bookingId, newTab) =>
        dispatchProps.onSendBookingLogin(stateProps.eventId, bookingId, newTab),
    onToggleBookingTask: (bookingId, taskId) =>
        dispatchProps.onToggleBookingTask(stateProps.eventId, bookingId, taskId),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(BookingOverview);
