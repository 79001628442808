import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';

export const ID = 'updateVehicleModal';

const ERROR_POPUP = 'Failed to update vehicle.';
const SUCCESSFUL_POPUP = (make, model) =>
    `Vehicle <strong>${make}</strong> <strong>${model}</strong> successfully updated.`;

export const getState = (state) =>
    stateHelpers.getComponentState(state, ID) || {};

export const mergeState = (newState) =>
    actions.mergeComponentState(ID, newState);

export const setStateValue = (key, value) =>
    actions.setComponentStateValue(ID, key, value);

export const changeValue = (key, value) =>
    setStateValue(`values.${key}`, value);

export const open = (vehicle) => mergeState({
    isOpened: true,
    values: { ...vehicle },
    errors: {}
});

export const close = () => mergeState({
    isSaving: false,
    isOpened: false,
    values: {},
    errors: {}
});

export const showSuccessPopup = (make, modal) =>
    actions.showSuccessPopup(SUCCESSFUL_POPUP(make, modal));

export const showErrorPopup = (key) =>
    actions.showErrorPopup(ERROR_POPUP);
