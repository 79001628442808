import { Model, Collection }  from 'app/backbone/models/decorators';
import * as GrantedDay from '../../settings/accreditation/GrantedDay';
import * as AccrediteeProfile from '../accreditees/AccrediteeProfile';
import * as CompanyProfile from './CompanyProfile';
import * as CompanyAccrediteeItem from './CompanyAccrediteeItem';

@Model({
    key: 'company_accreditee_profile'
})
class CompanyAccrediteeProfile extends AccrediteeProfile.Model {

    static Relations() {
        return {
            days: AccrediteeProfile.Model.Relations().days,
            granted_days: AccrediteeProfile.Model.Relations().granted_days,
            available_profile: CompanyProfile.Model
        }
    }

    get defaults() {
        return {
            total_available: 0
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        // Listeners to update the available profiles on for each granted day
        this.listenTo(this.getRelation('granted_days'), 'add', day => {
            this.getRelation('available_profile').getRelation('available_days').incrementUsedOn(day);
        });
        this.listenTo(this.getRelation('granted_days'), 'remove', day => {
            this.getRelation('available_profile').getRelation('available_days').decrementUsedOn(day);
        });
        // Listeners to set the total available profiles
        this.listenTo(this.getRelation('available_profile').getRelation('available_days'), 'reset update change:available', this.resetTotalAvailable);
        this.resetTotalAvailable();
    }

    isAvailable() {
        return this.getRelation('available_profile').isAvailable();
    }

    isAvailableOn(day) {
        return this.getRelation('available_profile').isAvailableOn(day) || this.hasGrantedDayOn(day)
    }

    resetTotalAvailable() {
        this.set('total_available', this.getRelation('available_profile').getRelation('available_days').getTotalAvailable());
    }
}

@Collection({
    key: 'company_accreditee_profiles',
    model: CompanyAccrediteeProfile
})
class CompanyAccrediteeProfileCollection extends AccrediteeProfile.Collection {

    initialize(models, options = {}) {
        super.initialize(models, options);
        // When a profile is remove we update the available profiles for each granted day
        this.listenTo(this, 'remove', profile => {
            profile.getRelation('granted_days').each(day => {
                profile.getRelation('available_profile').getRelation('available_days').decrementUsedOn(day)
            })
        })
    }

    getTotalAvailable() {
        let total = 0;
        this.each(profile => { total += profile.get('total_available') });
        return total;
    }

    someHaveItemWithAvailableDayOnAndHasGrantedDayOn(item, day) {
        return this.some(profile => {
            return (
                profile.getRelation('available_profile').hasItemWithAvailableDayOn(item, day) &&
                profile.hasGrantedDayOn(day)
            )
        });
    }

    getAllUniqueGrantedDaysWithAvailableItemDay(item) {
        const grantedDays = this.getAllUniqueGrantedDays();
        const uniqueDays = grantedDays.filter((day) => {
            return this.someHaveItemWithAvailableDayOnAndHasGrantedDayOn(item, day)
        });
        return new GrantedDay.Collection(uniqueDays);
    }

    getAllUniqueAvailableItems() {
        const uniqueItems = new CompanyAccrediteeItem.Collection();
        this.forEach(profile => {
            const availableItems = profile.getRelation('available_profile').getRelation('available_items');
            availableItems.forEach(item => {
                const itemFound = uniqueItems.get(item.id);
                if(itemFound) itemFound.getRelation('granted_profiles').add(profile);
                else {
                    const newItem = item.transform(CompanyAccrediteeItem.Model);
                    newItem.getRelation('granted_profiles').add(profile);
                    uniqueItems.add(newItem);
                }
            })
        });
        return uniqueItems;
    }

}

const PageableCollection = CompanyAccrediteeProfileCollection.prototype.PageableCollection();

export {
    CompanyAccrediteeProfile as Model,
    CompanyAccrediteeProfileCollection as Collection,
    PageableCollection
};
