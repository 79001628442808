import React from 'react';
import PropTypes from 'prop-types'
import { SmartProgressCircle } from 'ui-kit'
import {
    hasNoRoomLimit,
    isRoomLimitReached,
    isRoomLimitExceeded,
} from 'app/react/helpers/reservations'

const RoomsAvailableProgress = ({
    booked,
    available,
}) => {
    if (hasNoRoomLimit(available)) {
        return (
            <SmartProgressCircle
                size={20}
                progressPct={100}
            />
        );
    }

    if (isRoomLimitReached(booked, available) || isRoomLimitExceeded(booked, available)) {
        return (
            <SmartProgressCircle
                isInversed
                size={20}
                progressPct={100}
            />
        );
    }

    const remaining = available - booked;

    return (
        <SmartProgressCircle
            size={20}
            progressPct={remaining / available * 100}
        />
    );
}

RoomsAvailableProgress.propTypes = {
    booked: PropTypes.number,
    available: PropTypes.number,
};

export default RoomsAvailableProgress;
