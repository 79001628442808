import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Booking from 'app/react/entities/events/bookings/helpers';
import * as Artist from 'app/react/entities/accounts/artists/helpers';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/helpers';

export const mapBeatswitchContextToProps = (state) => ({
    accountId: Account.getCurrentId(state),
    eventId: Event.getCurrentId(state),
    bookingId: Booking.getCurrentId(state),
    dateFormat: Event.getCurrentDateFormat(state),
    timeFormat: Event.getCurrentTimeFormat(state),
    unitOfDistance: Event.getCurrentUnitOfDistance(state),
    isArtistTypesEnabled: Event.isArtistTypesEnabledForCurrent(state)
});

export const mapAccountContextToProps = (state) => ({
    accountId: Account.getCurrentId(state),
    isArtistTypesEnabled: Account.isArtistTypesEnabledForCurrent(state),
    isAccreditationEnabled: Account.isAccreditationEnabledForCurrent(state),
    dateFormat: Account.getCurrentDateFormat(state),
    timeFormat: Account.getCurrentTimeFormat(state),
    currency: Account.getCurrentCurrency(state),
});

export const mapEventContextToProps = (state) => ({
    eventId: Event.getCurrentId(state),
    isArtistTypesEnabled: Event.isArtistTypesEnabledForCurrent(state),
    dateFormat: Event.getCurrentDateFormat(state),
    dateFormatShort: Event.getCurrentDateFormatShort(state),
    timeFormat: Event.getCurrentTimeFormat(state),
    currency: Event.getCurrentCurrency(state),
    currencySymbol: Event.getCurrentCurrencySymbol(state),
    unitOfDistance: Event.getCurrentUnitOfDistance(state),
});

export const mapBookingContextToProps = (state) => {
    const artist = Artist.getOne(state, (Booking.getCurrent(state) || {}).artist) || {};
    const booking = Booking.getCurrent(state) || {};
    const bookingStatuses = BookingStatus.getAllForCurrentContext(state) || {};
    const bookingStatus = bookingStatuses.find((status) => status.code === booking.status) || {};
    return {
        bookingId: booking.id,
        artistId: artist.id,
        artistName: artist.name,
        artistImageSize80: artist.imageSize80,
        artistCountryCode: artist.country,
        bookingStatusTitle: bookingStatus.title,
        bookingStatusColorType: bookingStatus.colorType,
    };
};

export const beatswitchContextPropTypes = {
    eventId: PropTypes.number,
    accountId: PropTypes.number,
    bookingId: PropTypes.number,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    unitOfDistance: PropTypes.string,
    isArtistTypesEnabled: PropTypes.bool,
};

export const accountContextPropTypes = {
    accountId: PropTypes.number,
    isArtistTypesEnabled: PropTypes.bool,
    isAccreditationEnabled: PropTypes.bool,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    currency: PropTypes.string,
};

export default WrappedComponent => connect(mapStateToProps)(WrappedComponent)
