var PeriodModal = require('./PeriodModal.view');

var PeriodModalUpdate = PeriodModal.extend({

    events: {
        'keypress': 'keypress',
        'click .update': 'update'
    },

    initialize(options) {
        this._super(options);
        this.isUpdating = true;
        this.title = 'Update Period';
        this.syncingText = 'Updating Period...';
    },

    open(model) {
        this.original = model;
        this.model = this.original.clone();
        this.isSyncing = false;
        this.render();
        this.show();
    },

    bindModel() {
        this._super();
        if(this.original) {
            this.listenTo(this.original, 'request', () => { this.setSyncing(true) });
            this.listenTo(this.original, 'sync', this.onSync);
            this.listenTo(this.original, 'error', this.onError);
        }
    },

    unbindModel() {
        this._super();
        if(this.original) this.stopListening(this.original);
    },

    update() {
        if(this.model.isValid(true)) {
            this.original.save(this.model.attributes, { wait: true });
        }
    },

    onSync() {
        this.trigger('update', this.original);
        this.unbindModel();
        this.hide();
    },

    keypress(e) {
        if(e.which == 13) {
            e.preventDefault();
            this.update();
        }
    }
});

module.exports = PeriodModalUpdate;
