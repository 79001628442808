import * as Schemas from './schemas'
import {
    getEntitiesCollection,
    getSomeEntities,
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} = Schemas;

export const getAll = (state) => {
    const collection = getCollection(state);
    return getSomeEntities(state, ENTITY_REDUCER_KEY, collection.ids);
};

export const isFetchingAll = (state) => {
    const collection = getCollection(state);
    return !! collection.isFetching
};

export const getCollection= (state) => {
    return getEntitiesCollection(state, COLLECTION_REDUCER_KEY);
};

