import {
    previousPageNumbers,
    nextPageNumbers,
} from 'ui-kit/lib/components/pagination/Pager'
import BaseView from 'app/backbone/components/Base.view'
import template from './Pager.hbs'
import './Pager.css'

const FORWARD = 'forward';
const BACKWARDS = 'backwards';

export default class Pager extends BaseView {

    constructor({ collection }) {
        super();
        this.collection = collection;
        this.lastPagerDirection = FORWARD;
    }

    get template() {
        return template;
    }

    events() {
        return {
            'click .primaryAction': 'onClickPrimaryAction',
            'click .firstPage': 'onFirstPage',
            'click .lastPage': 'onLastPage',
            'click .pageNumber': 'onPageChange',
        };
    }

    onClickPrimaryAction() {
        if (this.shouldGoBackwards()) {
            this.onPreviousPage();
        } else {
            this.onNextPage();
        }
    }

    onFirstPage() {
        this.lastPagerDirection = FORWARD;
        this.collection.getFirstPage();
    }

    onLastPage() {
        this.lastPagerDirection = BACKWARDS;
        this.collection.getLastPage();
    }

    onNextPage() {
        this.lastPagerDirection = FORWARD;
        this.collection.getNextPage();
    }

    onPreviousPage() {
        this.lastPagerDirection = BACKWARDS;
        this.collection.getPreviousPage();
    }

    onPageChange(e) {
        const { currentPage } = this.collection.state;
        const page = parseInt(e.target.innerText);
        this.lastPagerDirection = page > currentPage
            ? FORWARD
            : BACKWARDS;
        this.collection.getPage(page);
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    }

    viewModel() {
        const {
            currentPage,
            totalPages,
        } = this.collection.state;
        const previousPages = previousPageNumbers(currentPage, totalPages);
        const nextPages = nextPageNumbers(currentPage, totalPages);
        previousPages.push(currentPage);
        nextPages.unshift(currentPage);
        return {
            view: {
                pageInfo: {
                    currentPage,
                    isAllDataLoaded: totalPages === 1,
                    previousPageNumbers: previousPages,
                    nextPageNumbers: nextPages,
                },
                primaryAction: this.shouldGoBackwards() ? 'Previous' : 'Next',
            },
        };
    }

    shouldGoBackwards() {
        const {
            currentPage,
            lastPage,
        } = this.collection.state;
        const isLastPage = currentPage === lastPage;
        const isFirstPage = currentPage === 1;
        return isLastPage
            || (!isFirstPage && this.lastPagerDirection === BACKWARDS);
    }
}
