import React, { useState } from 'react'
import Row from './Row'

const RowWithState = (props) => {
    const { attendee, onToggleCheckIn } = props;
    const [isTogglingCheckIn, setIsTogglingCheckIn] = useState(false);
    const [checkedInAt, setCheckedInAt] = useState(attendee.checkedInAt);

    async function toggleCheckIn() {
        setIsTogglingCheckIn(true);

        try {
            const { checkInStatus } = await onToggleCheckIn(attendee);
            setCheckedInAt(checkInStatus.at);
            setIsTogglingCheckIn(false);
        } catch (e) {
            setIsTogglingCheckIn(false);
        }
    }

    return (
        <Row
            {...props}
            checkedInAt={checkedInAt}
            isTogglingCheckIn={isTogglingCheckIn}
            onToggleCheckIn={toggleCheckIn}
        />
    );
};

export default RowWithState;
