export const base = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}/crew`;

export const create = (
    eventId = ':event',
    bookingId = ':booking'
) => `${base(eventId, bookingId)}/create(?*query)`;

export const update = (
    eventId = ':event',
    bookingId = ':booking',
    crewId = ':crew'
) => `${base(eventId, bookingId)}/${crewId}/edit(?*query)`;
