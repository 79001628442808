import React from 'react';
import PropTypes from 'prop-types';
import { SearchSelect } from 'ui-kit';
import { GroundTransport } from 'app/react/views/event/artists/bookings/transports/components/Icons';

export const PLACEHOLDER = 'Select mode...';
export const NO_RESULTS_PLACEHOLDER = 'No mode found.';

const toOption = ({
    id,
    name,
    icon,
}) => ({
    value: id,
    label: name,
    icon,
});

const PickupModeOption = ({
   option = {},
}) => (
    <span>
        { option.value === 3 ?
            <GroundTransport className={option.icon} style={{ marginRight: '20px' }}/> :
            <i className={option.icon} style={{ marginRight: '20px' }} />
        }
        {option.label}
    </span>
);

const SelectPickupMode = ({
   placeholder = PLACEHOLDER,
   noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
   disabled = false,
   value,
   modes = [],
   onChange = () => {},
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        options={modes.map(toOption)}
        renderOption={option => <PickupModeOption option={option}/>}
        onChange={onChange}
    />
);

SelectPickupMode.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    modes: PropTypes.array,
    onChange: PropTypes.func,
};

export default SelectPickupMode;
