import React from 'react';
import PropTypes from 'prop-types';
import { AlertBox, InputGroup } from 'ui-kit';
import { DebouncedInput } from 'app/react/components/_index';

const PASSENGERS_AMOUNT_LABEL = 'Amount';
const NO_PASSENGERS_TEXT = 'There are no attendees (performer or crew) for this booking yet to add as passenger for this pickup.';
const SELECT_ALL_LABEL = 'Select all';
const AMOUNT_WARNING = 'Careful, you have selected more passengers than the maximum capacity of the vehicle.';

const PassengersFields = ({
    values = {},
    errors = {},

    attendees = [],
    selectedVehicle,

    onChangeValue = () => {},
    onSelectPassenger = () => {},
    onSelectAllPassengers = () => {},
}) => {
    const { passengers = [] } = values;
    const allAttendeesSelected = attendees.every(attendee => passengers.includes(attendee.id));
    return (
        <div>
            {attendees.length === 0
                ? <p className="card--content_description">{NO_PASSENGERS_TEXT}</p>
                : <ul className="no-bullet checkbox" style={{ marginBottom: 'var(--spacingSmall)' }}>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                checked={allAttendeesSelected}
                                onChange={e => onSelectAllPassengers(e.target.checked)}
                                style={{ marginRight: 'var(--spacingSmaller)' }}
                            />
                            {SELECT_ALL_LABEL}
                        </label>
                    </li>
                    {attendees.map(attendee => (
                        <li key={attendee.id}>
                            <label>
                                <DebouncedInput
                                    type="checkbox"
                                    checked={passengers.includes(attendee.id)}
                                    onChange={checked => onSelectPassenger(attendee.id, checked)}
                                    style={{ marginRight: 'var(--spacingSmaller)' }}
                                />
                                {attendee.firstname} {attendee.lastname}
                            </label>
                        </li>
                    ))}
                </ul>
            }
            <InputGroup messages={errors.passengerAmount}>
                <label>{PASSENGERS_AMOUNT_LABEL}</label>
                <DebouncedInput
                    type="number"
                    value={values.passengerAmount || ''}
                    onChange={value => onChangeValue('passengerAmount', value)}
                />
            </InputGroup>
            {(typeof selectedVehicle !== 'undefined' && values.passengerAmount > selectedVehicle.numberOfPassengers) &&
                <AlertBox type={AlertBox.WARNING}>{AMOUNT_WARNING}</AlertBox>
            }
        </div>
    )
};

PassengersFields.propTypes = {
    isEditingPerformanceName: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    dayStart: PropTypes.string,
    dayEnd: PropTypes.string,

    days: PropTypes.array,
    stages: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectDay: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
};

export default PassengersFields;
