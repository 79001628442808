import _ from 'underscore';
import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import DateTime from 'app/backbone/helpers/DateTime';
import * as Stage from './Stage';

@Model({
    key: 'day'
})
class Day extends BaseModel {

    static Relations() {
        return {
            event: require('./Event').Model, // Circular
            stages: Stage.Collection
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a name.'
            },
            start_date: {
                required: true,
                msg: 'Enter a start date.'
            },
            start_time: {
                required: true,
                msg: 'Enter a start time.'
            },
            end_date: [{
                required: true,
                msg: 'Enter an end date.'
            },{
                fn: 'validateIfEndDateIsNotBeforeStartDate'
            },{
                fn: 'validateIfEndDateIsNotAfterNextDay'
            }],
            end_time: [{
                required: true,
                msg: 'Enter an end time.'
            },{
                fn: 'validateIfEndTimeIsNotBeforeStartTime'
            }]
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
        this.checkIfStartAndEndDateNeedToBeFilled();
        this.on('change:end_date change:end_time', this.onChangeEndDate);
        this.on('change:start_date change:start_time', this.onChangeStartDate);
    }

    urlRoot() {
        const eventId = this.getRelation('event').get('id');
        return `/api/events/${eventId}/days`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);

        response.start_date = DateTime.account.formatDateToDateString(response.start);
        response.start_date_short = DateTime.account.formatDateToDateString(response.start, true);
        response.start_time = DateTime.account.formatDateToTimeString(response.start);
        response.end_date = DateTime.account.formatDateToDateString(response.end);
        response.end_date_sort = DateTime.account.formatDateToDateString(response.end, true);
        response.end_time = DateTime.account.formatDateToTimeString(response.end);
        if(response.is_show_day) response.period_name = `Show Day`;
        else response.period_name = response.name;

        return response;
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if (this.hasRelation('stages')) json.stages = this.getRelation('stages').toJSON();
        return json;
    }

    toGrantedDay(attrs = {}) {
        const GrantedDay = require('app/backbone/models/event/settings/accreditation/GrantedDay'); // Circular
        const name = this.get('name');
        const id = this.get('id');
        return new GrantedDay.Model(_.extend(attrs, { id, name }))
    }

    toAvailableDay(attrs = {}) {
        const AvailableDay = require('app/backbone/models/event/settings/accreditation/AvailableDay'); // Circular
        const name = this.get('name');
        const id = this.get('id');
        return new AvailableDay.Model(_.extend(attrs, { id, name }))
    }

    validateIfEndDateIsNotBeforeStartDate(endDate, attr, computedState) {
        const mStart = DateTime.account.parseDateStringToMoment(computedState.start_date);
        const mEnd = DateTime.account.parseDateStringToMoment(endDate);
        if (mEnd.isBefore(mStart)) return 'The end date must be equal or later than the start date.';
    }

    validateIfEndTimeIsNotBeforeStartTime(endTime, attr, computedState) {
        const mStart = DateTime.account.parseDateTimeStringToMoment(computedState.start_date + ' ' +  computedState.start_time);
        const mEnd = DateTime.account.parseDateTimeStringToMoment(computedState.end_date + ' ' +  endTime);
        if (mEnd.isBefore(mStart) || mEnd.isSame(mStart)) return 'The end time must be later than the start time.';
    }

    validateIfEndDateIsNotAfterNextDay(endDate, attr, computedState) {
        const mEnd =  DateTime.account.parseDateStringToMoment(endDate);
        const mNextDay = DateTime.account.parseDateStringToMoment(computedState.start_date).add(1, 'days');
        if (mEnd.isAfter(mNextDay)) return 'A day\'s duration cannot span more than two days.';
    }

    onChangeStartDate() {
        const startDate = this.get('start_date');
        const startTime = this.get('start_time');
        const start = DateTime.account.parseDateTimeStringToISOString(`${startDate} ${startTime}`);
        this.set('start', start);
    }

    onChangeEndDate() {
        const endDate = this.get('end_date');
        const endTime = this.get('end_time');
        const end = DateTime.account.parseDateTimeStringToISOString(`${endDate} ${endTime}`);
        this.set('end', end);
    }

    checkIfStartAndEndDateNeedToBeFilled() {
        const startDate = this.get('start_date');
        const startTime = this.get('start_time');

        if (startDate !== '') {
            const start = DateTime.account.parseDateTimeStringToISOString(`${startDate} ${startTime}`);
            this.set('start', start);
        }

        const endDate = this.get('end_date');
        const endTime = this.get('end_time');

        if (endDate !== '') {
            const end = DateTime.account.parseDateTimeStringToISOString(`${endDate} ${endTime}`);
            this.set('end', end);
        }
    }

}

@Collection({
    key: 'days',
    model: Day
})
class DayCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('app/backbone/models/event/Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        const eventId = this.getRelation('event').get('id');
        return `/api/events/${eventId}/days`;
    }

    comparator(model) {
        const start_date = model.get('start_date');
        const start_time = model.get('start_time');
        return DateTime.account.parseDateTimeStringToDate(`${start_date} ${start_time}`).getTime();
    }

    someHaveNoStages() {
        return this.some(day => day.getRelation('stages').isEmpty());
    }

    getAllStages(addDayNames = false) {
        const stages = new Stage.Collection();
        this.forEach((day) => {
            day.getRelation('stages').forEach((stage) => {
                // We clone the stages so we don't modify the original
                const newStage = stage.clone();
                // If the 'addDayNames' options is passed we prefix each stage name with the day name
                if (addDayNames) newStage.set('name', `${day.get('name')} - ${stage.get('name')}`);
                stages.add(newStage);
            })
        });
        return stages
    }

}

const PageableCollection = DayCollection.prototype.PageableCollection();

export {
    Day as Model,
    DayCollection as Collection,
    PageableCollection
};
