import React from 'react';
import PropTypes from 'prop-types';
import { TipType } from '../../types/Event';
import TipCard from './TipCard';

const TipsContainer = ({tips}) => (
			<div className="tip-container">
				{tips.map((tip, index) => {
					return <TipCard tip={tip} key={index} />
				})}
			</div>
);

TipsContainer.propTypes = {
    tips: PropTypes.arrayOf(TipType).isRequired
};

export default TipsContainer;
