import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    pageParam,
    filterParam,
    limitParam as pageSizeParam,
    limitAllParam as pageSizeAll,
    searchParam,
    sortParam
} from 'app/react/state/middleware/api'

const rootAll = (eventId) =>
    `events/${eventId}/pickups`;
const rootAllForBooking = (eventId, bookingId) =>
    `events/${eventId}/bookings/${bookingId}/pickups`;
const rootOneForBooking = (eventId, bookingId, pickupId) =>
    `events/${eventId}/bookings/${bookingId}/pickups/${pickupId}`;

export const createOne = (
    eventId,
    bookingId,
    includes = []
) => oneLineTrim`
    ${rootAllForBooking(eventId, bookingId)}?
    ${includeParam(includes)}
`;

export const fetchOne = (
    eventId,
    bookingId,
    pickupId,
    includes = []
) => oneLineTrim`
    ${rootOneForBooking(eventId, bookingId, pickupId)}?
    ${includeParam(includes)}
`;

export const updateOne = (
    eventId,
    bookingId,
    pickupId,
    includes = []
) => oneLineTrim`
    ${rootOneForBooking(eventId, bookingId, pickupId)}?
    ${includeParam(includes)}
`;

export const deleteOne = (
    eventId,
    bookingId,
    pickupId
) => oneLineTrim`
    ${rootOneForBooking(eventId, bookingId, pickupId)}
`;

export const fetchAllByDay = (
    eventId,
    after,
    before,
    includes = []
) => oneLineTrim`
    ${rootAll(eventId)}?
    ${pageSizeAll}&
    ${filterParam({ after, before })}&
    ${includeParam(includes)}
`;

export const fetchAllPaginated = (
    eventId,
    page = 1,
    pageSize = 100,
    filters = {},
    search = '',
    sortKey = '',
    sortOrder = 1,
    includes = []
) => oneLineTrim`
    ${rootAll(eventId)}?
    ${pageParam(page)}&
    ${pageSizeParam(pageSize)}&
    ${filterParam(filters)}&
    ${searchParam(search)}&
    ${sortParam(sortKey, sortOrder)}&
    ${includeParam(includes)}
`;
