import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Table from 'app/backbone/components/tables/Table.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';

export default class SelectZonesTable extends Table {

    optionTypes() {
        return {
            zones: OptionTypes.instanceOf(Zone.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.collection = this.zones;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.placeholderText = this.getPlaceholderText();
        this.placeholderHeight = 350;
        this.hasActions = false;
        super.initialize(options);
    }

    initColumns() {
        const columns = super.initColumns();
        columns.push({
            name: '',
            cell: 'select-row',
            headerCell: CellFactory.createSelectAllHeaderCell()
        }, {
            name: 'symbol',
            cell: CellFactory.createCell('100px', 'center'),
            label: 'Symbol',
            headerCell: CellFactory.createHeaderCell('100px', 'center'),
            sortable: false,
            editable: false
        }, {
            name: 'name',
            cell: 'string',
            label: 'Name',
            sortable: false,
            editable: false
        });
        return columns;
    }


    getPlaceholderText() {
        const eventId = this.zones.getRelation('event').get('id');
        const createZoneUrl = `/events/${eventId}/settings/accreditation/zones`;
        return `No zones have been created for this event. <a href="${createZoneUrl}">Create your first zone.</a>`;
    }

}
