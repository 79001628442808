import _ from 'underscore'
import PromoterCompanyAccrediteeTable from 'app/backbone/views/event/accreditation/companies/accreditees/tables/CompanyAccrediteeTable.view';

export default class CompanyAccrediteeTable extends PromoterCompanyAccrediteeTable {

    initActions() {
        const actions = super.initActions();

        actions[0] = _.extend(actions[0], {
            action: this.downloadTicket.bind(this),
            canRender: (accreditee) => (
                (
                    accreditee.getRelation('company').isApproved() ||
                    accreditee.getRelation('company').areTicketsSent()
                ) && accreditee.canDownloadTicket()
            )
        });

        actions[2] = _.extend(actions[2], {
            canRender: (accreditee) => accreditee.getRelation('company').canRequestApproval()
        });

        actions[3] = _.extend(actions[3], {
            canRender: (accreditee) => accreditee.getRelation('company').canRequestApproval()
        });

        return [actions[0], actions[2], actions[3]];
    }

    getFiltersDef() {
        return [];
    }

    downloadTicket(accreditee) {
        const eventId = accreditee.getRelation('company').getRelation('event').get('id');
        const accrediteeId = accreditee.id;
        navigate(`/events/${eventId}/accreditation/accreditees/${accrediteeId}/ticket`, { pageReload: true });
    }

    update(accreditee) {
        const productionWebsiteUrl = window.beatswitch.production_website.slug;
        const accrediteeId = accreditee.get('id');
        navigate(`/production-website-accreditation/${productionWebsiteUrl}/company-form/accreditees/${accrediteeId}/edit`);
    }

    getPlaceholderText() {
        return 'No attendees have been added for this event.';
    }
}
