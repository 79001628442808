import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import PagesOverview from 'app/react/views/event/production_website/accreditation/pages/views/OverviewContainer';
import * as Event from 'app/react/entities/events/index';
import * as PWPage from 'app/react/entities/events/production_website/pages/index';
import * as Routes from './routes'

export default class PagesController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.pages(), null, this.overview.bind(this));
    }

    overview(eventId) {
        const { app, store, dispatch } = this;
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        app.renderContentComponent(React.createElement(PagesOverview, { store }));
        dispatch(PWPage.actions.fetchAllOrLoadFromCache('accreditation'));
    }

}
