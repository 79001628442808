import RouteControl from 'backbone-route-control';
import SharedController from 'app/routes/shared/Controller';
import AccountController from 'app/routes/account/Controller';
import EventController from 'app/routes/event/Controller';
import ProductionWebsiteController from 'app/routes/production_website/Controller';

export default class Router extends RouteControl {

    constructor(options = {}) {
        super(options);
        const { app } = options;
        this.app = app;
        const parent = this;
        const subControllers = [
            new SharedController({ parent }),
            new AccountController({ parent }),
            new EventController({ parent }),
            new ProductionWebsiteController({ parent }),
        ];
    }

}
