import { schema } from 'normalizr';
import { CALL_API } from 'app/react/state/middleware/api';
import { createPaginationReducer } from 'app/react/state/reducers/higher_order/_index';
import {
    getOneEntity,
    getAllEntities,
    getEntitiesPagination,
    isFetchingEntities,
    getCurrentAccountId
} from 'app/react/state/helpers';

export const TICKET_PROVIDERS_REQUEST = 'TICKET_PROVIDERS_REQUEST';
export const TICKET_PROVIDERS_SUCCESS = 'TICKET_PROVIDERS_SUCCESS';
export const TICKET_PROVIDERS_FAILURE = 'TICKET_PROVIDERS_FAILURE';

export const ENTITY_REDUCER_KEY = 'ticketProviders';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;
const entity = new schema.Entity(ENTITY_REDUCER_KEY);
const array = new schema.Array(entity);

export const schemas = {
    entity,
    array
};

const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);

const getAll = (state) =>
    getAllEntities(state, ENTITY_REDUCER_KEY);

const getPagination = (state) =>
    getEntitiesPagination(state, ENTITY_REDUCER_KEY, PAGINATION_REDUCER_KEY);

const isFetching = (state) =>
    isFetchingEntities(state, PAGINATION_REDUCER_KEY);

export const stateHelpers = {
    getOne,
    getAll,
    getPagination,
    isFetching
};

const paginationReducer = createPaginationReducer({
    types: [
        TICKET_PROVIDERS_REQUEST,
        TICKET_PROVIDERS_SUCCESS,
        TICKET_PROVIDERS_FAILURE
    ]
});

export const reducers = {
    pagination: paginationReducer
};

const Endpoints = {
    all: (accountId) =>
        `accounts/${accountId}/accreditation/ticket-providers`
};

const fetchAll = (accountId) => ({
    [CALL_API]: {
        types: [
            TICKET_PROVIDERS_REQUEST,
            TICKET_PROVIDERS_SUCCESS,
            TICKET_PROVIDERS_FAILURE
        ],
        endpoint: Endpoints.all(accountId),
        schema: array
    }
});

const loadAll = () => (dispatch, getState) => {
    const state = getState();
    const { pageCount = 0 } = getPagination(state);
    if (pageCount > 0) return Promise.resolve();
    const accountId = getCurrentAccountId(state);
    return dispatch(fetchAll(accountId));
};

export const actions = {
    fetchAll,
    loadAll
};
