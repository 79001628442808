import Backbone from 'backbone';
import Modal from 'app/backbone/components/modals/Modal.view';
import * as Account from 'app/backbone/models/account/Account';
import * as Event from 'app/backbone/models/event/Event';
import * as Day from 'app/backbone/models/event/Day';
import Form from 'app/backbone/components/forms/Form.mixin';
import template from './DownloadAttendeesModal.hbs';

const DownloadAttendeesModal = Modal.extend({

    template,

    mixins: [
        Form
    ],

    ui: {
        attendeeDownloadButton: '.download'
    },

    events: {
        'click attendeeDownloadButton': 'download'
    },

    options: [
        { 'width': 400 }
    ],

    initialize(options) {
        this._super(options);

        const account = new Account.Model().fetchFromDOM();
        const event = new Event.Model(null, { account }).fetchFromDOM();
        this.event = event;
        this.days = new Day.Collection(null, { event }).fetchFromDOM();

        this.days.add({ id: 0, name: 'All Days' }, { at: 0 });
    },

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.delegateEvents();
    },

    initForm() {
        const { days } = this;
        this.inputs = new Backbone.ChildViewContainer();

        this.daysSelectize = this.initSingleSelectize('days', { collection: days });
        this.inputs.add(this.daysSelectize, 'days');
    },

    renderForm() {
        this.inputs.call('render');
    },

    open() {
        this.render();
        this.initForm();
        this.delegateEvents();
        this.renderForm();
        this.show();
    },

    download() {
        const event = this.event;
        const dayId = this.daysSelectize.getSelectedValue();
        navigate(
            `/events/${event.id}/artists/reports/attendees?day=${dayId}`,
            { pageReload: true }
        );
    },
});

export default DownloadAttendeesModal;
