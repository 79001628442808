import DOM from 'app/backbone/helpers/DOM';

export default class Services {

    static get froalaKey() {
        return DOM.getData('services.froala.key');
    }

    static get fullcalendarSchedulerKey() {
        return DOM.getData('services.fullcalendar_scheduler.key');
    }

}
