import React from 'react'
import PropTypes from 'prop-types'
import {
    TableCell,
    TableActionsDropdown,
} from 'ui-kit'
import { Tooltip } from 'app/react/components/_index'
import VehicleTableHeaderRow from './HeaderRow'

const UPDATE_TOOLTIP = 'Update vehicle';
const DELETE_TOOLTIP = 'Delete vehicle';
const NOT_DELETABLE_TOOLTIP = 'You cannot delete this vehicle. There are still pickups attached to this vehicle.';

const VehiclesTable = ({
    vehicles = [],
    currentSortKey,
    currentSortOrder,
    onUpdate,
    onDelete,
    onSort,
}) => (
        <table className="overflow actions-right">
            <thead>
                <VehicleTableHeaderRow
                    onSort={onSort}
                    currentSortKey={currentSortKey}
                    currentSortOrder={currentSortOrder}
                />
            </thead>
            <tbody>
                { vehicles
                    .map(vehicle => (
                        <tr key={vehicle.id}>
                            <td>{vehicle.make}</td>
                            <td>{vehicle.model}</td>
                            <td>{vehicle.licencePlate}</td>
                            <td className="center">{vehicle.numberOfPassengers}</td>
                            <td className="center">{vehicle.pickups.totalCount}</td>
                            <td>{vehicle.mileageIn}</td>
                            <td>{vehicle.mileageOut}</td>
                            <TableCell.Actions>
                                <TableActionsDropdown>
                                    <TableActionsDropdown.Action onClick={() => onUpdate(vehicle)}>
                                        <i className="bs-icon-pencil" /> {UPDATE_TOOLTIP}
                                    </TableActionsDropdown.Action>
                                    <Tooltip
                                        position="bottom right"
                                        content={vehicle.isDeletable
                                            ? DELETE_TOOLTIP
                                            : NOT_DELETABLE_TOOLTIP
                                        }
                                    >
                                        <TableActionsDropdown.Action isDisabled={!vehicle.isDeletable}
                                            onClick={() => vehicle.isDeletable && onDelete(vehicle)}
                                        >
                                            <i className="bs-icon-cross" /> {DELETE_TOOLTIP}
                                        </TableActionsDropdown.Action>
                                    </Tooltip>
                                </TableActionsDropdown>
                            </TableCell.Actions>
                        </tr>
                    ))
                }
            </tbody>
        </table>
);

VehiclesTable.propTypes = {
    vehicles: PropTypes.array,
    vehicleTypes: PropTypes.array,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default VehiclesTable;
