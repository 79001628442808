var _ = require('underscore');

var DeleteEventRoleModalTemplate = require('./DeleteEventRoleModal'),
    RedirectConfirmModal = require('app/backbone/components/modals/RedirectConfirmModal.view');

module.exports = RedirectConfirmModal.extend({

    render: function() {
        this.$el.html(DeleteEventRoleModalTemplate(this.viewModel()));
    },

    viewModel: function() {
        var viewModel = this._super();
        viewModel.view.eventRoles = window.beatswitch.eventRoles.filter(role => role.id != this.role);
        return viewModel;
    },

    confirm: function() {}
});
