import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/index';
import * as Performance from 'app/react/entities/events/performances/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as PerformanceTimeSlotType from 'app/react/entities/time_slot_types/index';
import SchedulerView from 'app/react/views/event/artists/performances/views/SchedulerViewContainer';
import { PERFORMANCE_SCHEDULER_CARD_ID, PERFORMANCE_SCHEDULER_ID } from 'app/react/views/event/artists/performances/views/SchedulerView';
import * as Routes from './routes';

export default class PerformancesController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        router.route(Routes.scheduler(), null, this.scheduler.bind(this));
    }

    scheduler() {
        const { app, store, dispatch, getState } = this;
        app.renderContentComponent(React.createElement(SchedulerView, { store }));
        const state = getState();

        const accountId = stateHelpers.getCurrentAccountId(state);
        const eventId = stateHelpers.getCurrentEventId(state);

        const { manageTimetable } = Event.stateHelpers.getUserPermissions(state);
        if (manageTimetable) {
            return Promise.all([
                dispatch(ArtistType.thunks.fetchAllForCurrentContext()),
                dispatch(BookingType.actions.fetchAll()),
                dispatch(BookingStatus.thunks.fetchAllForCurrentContext()),
                dispatch(PerformanceTimeSlotType.actions.fetchAllOrLoadFromCache()),

                dispatch(Event.actions.fetchOne(accountId, eventId, ['days.stages']))
                    .then(() => {
                        const latestState = getState();
                        const days = stateHelpers.getAllEntities(latestState, 'days') || {};
                        const firstDay = stateHelpers.getFirstEntity(latestState, 'days') || {};
                        dispatch(actions.registerComponent(PERFORMANCE_SCHEDULER_CARD_ID, { selectedDayId: firstDay.id }));
                        days.forEach((day) => {
                            dispatch(actions.registerComponent(PERFORMANCE_SCHEDULER_ID(day.id), { activeInterval: '00:15' }));
                        });
                        return dispatch(Performance.actions.fetchAllByDayIdOrLoadFromCache(firstDay.id));
                    }),
            ]);
        }
        return Promise.resolve();
    }

}
