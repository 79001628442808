import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = ({
  name,
  value,
  onChange,
  label,
  required,
  id,
  ...rest
}) => {
  return (
    <div className="date-picker-field">
      <label htmlFor={id}>
        {label && (
          <span className={"form-field__label"}>
            {label}
            {required ? "*" : null}
          </span>
        )}
      </label>
      <DatePicker
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          onChange(name, val);
        }}
        id={id}
        {...rest}
      />
    </div>
  );
};

export default DatePickerField;
