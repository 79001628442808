import React from 'react'
import {
    Table as UIKitTable,
    TableRow,
    TableHeaderCell,
    TableSortCell,
    TableCell,
} from 'ui-kit'
import { NoRecordsRow } from 'app/react/components/tables'
import Row from './RowWithMutation'

const Table = ({
    eventId,
    bookingAttendees,
    dateFormat,
    dateFormatShort,
    timeFormat,
    sortKey,
    sortOrder,
    onSort,
    onDownloadPassport,
    onGoToBooking,
    onEditAttendee,
    onDeleteAttendee,
}) => (
    <UIKitTable isLarge hasHiddenOverflow>
        <thead>
            <TableRow>
                <TableSortCell
                    sortKey="checkedInAt"
                    currentSortKey={sortKey}
                    currentSortOrder={sortOrder}
                    onSort={onSort}
                    style={{ width: '5rem' }}
                />
                <TableHeaderCell
                    isCentered
                    style={{ width: '5rem' }}
                />
                <TableSortCell
                    sortKey="artist"
                    currentSortKey={sortKey}
                    currentSortOrder={sortOrder}
                    onSort={onSort}
                >
                    Artist
                </TableSortCell>
                <TableSortCell
                    sortKey="name"
                    currentSortKey={sortKey}
                    currentSortOrder={sortOrder}
                    onSort={onSort}
                >
                    Name
                </TableSortCell>
                <TableSortCell
                    sortKey="email"
                    currentSortKey={sortKey}
                    currentSortOrder={sortOrder}
                    onSort={onSort}
                >
                    Email
                </TableSortCell>
                <TableSortCell
                    sortKey="stage"
                    currentSortKey={sortKey}
                    currentSortOrder={sortOrder}
                    onSort={onSort}
                >
                    Stage
                </TableSortCell>
                <TableSortCell
                    sortKey="ticketType"
                    currentSortKey={sortKey}
                    currentSortOrder={sortOrder}
                    onSort={onSort}
                >
                    Ticket type
                </TableSortCell>
                <TableHeaderCell>Day</TableHeaderCell>
                <TableCell style={{ width: '5rem' }} />
            </TableRow>
        </thead>
        <tbody>
            {bookingAttendees.length === 0
                ? <NoRecordsRow colSpan={8} />
                : bookingAttendees.map((attendee) => (
                    <Row
                        key={`${attendee.__typename}${attendee.id}`}
                        eventId={eventId}
                        attendee={attendee}
                        dateFormat={dateFormat}
                        dateFormatShort={dateFormatShort}
                        timeFormat={timeFormat}
                        onDownloadPassport={onDownloadPassport}
                        onGoToBooking={onGoToBooking}
                        onEditAttendee={onEditAttendee}
                        onDeleteAttendee={onDeleteAttendee}
                    />
                ))
            }
        </tbody>
    </UIKitTable>
);

export default Table;
