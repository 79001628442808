import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { InputGroup } from 'ui-kit'
import EnablePassportUploadsInput from 'app/react/views/event/production_website/artists/artist_types/booking_form/sections/views/modals/EnablePassportUploadsInput'
import TextEditor from 'app/react/views/event/production_website/artists/ProductionWebsiteSettingsTextEditor'
import {
    DESCRIPTION_PROP_NAME,
    DESCRIPTION_LABEL,
    DESCRIPTION_HEIGHT,
    FIELD_MAX_WIDTH,
    LIMIT_PROP_NAME,
    LIMIT_PLACEHOLDER,
    ENABLE_PASSPORTS_PROP_NAME,
    ENABLE_PASSPORTS_LABEL,
    REQUIRE_PASSPORTS_PROP_NAME,
    REQUIRE_PASSPORTS_LABEL,
    UPDATE_BOOKINGS_LABEL,
    UPDATE_BOOKINGS_PROP_NAME, TICKET_TYPE_PROP_NAME, ATTENDEE_TYPES,
} from './constants'
import TicketTypeInputGroup from "./_TicketTypeInputGroup";

const LIMIT_LABEL = 'Max. crew';
const PROFILE_PROP_NAME = 'accreditationProfile';
const PROFILE_LABEL = 'Accreditation profile';

const DEFAULT_ACCREDITATION_PROFILE_OPTION = {
    value: null,
    label: 'Select profile',
};

export const CrewForm = ({
    isAccreditationEnabled = false,
    accreditationProfileOptions = [],
    ticketTypeOptions = [],
    values,
    errors = {},
    onChangeValue = () => {},
}) => {
    const passportUploadsEnabled = values[ENABLE_PASSPORTS_PROP_NAME] || false;

    return (
        <Fragment>
            <InputGroup messages={errors[DESCRIPTION_PROP_NAME]}>
                <label>{DESCRIPTION_LABEL}</label>
                <TextEditor
                    imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                    height={DESCRIPTION_HEIGHT}
                    value={values[DESCRIPTION_PROP_NAME] || ''}
                    onBlur={e => onChangeValue(DESCRIPTION_PROP_NAME, e.target.value)}
                />
            </InputGroup>
            <TicketTypeInputGroup errors={errors} onChangeValue={onChangeValue}
                                  selectedValue={values[TICKET_TYPE_PROP_NAME]} options={ticketTypeOptions}
                                  attendeeType={ATTENDEE_TYPES.CREW}/>
            <fieldset>
                <legend>Limits</legend>
                <InputGroup
                    messages={errors[LIMIT_PROP_NAME]}
                    style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
                >
                    <label>{LIMIT_LABEL}</label>
                    <input
                        type="number"
                        placeholder={LIMIT_PLACEHOLDER}
                        value={typeof values[LIMIT_PROP_NAME] !== 'undefined' && values[LIMIT_PROP_NAME] !== null
                            ? values[LIMIT_PROP_NAME]
                            : ''
                        }
                        onChange={e => onChangeValue(LIMIT_PROP_NAME, e.target.value)}
                    />
                </InputGroup>
                <InputGroup
                    messages={errors[UPDATE_BOOKINGS_PROP_NAME]}
                    style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
                >
                    <label>
                        <input
                            type="checkbox"
                            style={{ marginRight: '1rem' }}
                            checked={typeof values[UPDATE_BOOKINGS_PROP_NAME] !== 'undefined'
                                ? values[UPDATE_BOOKINGS_PROP_NAME]
                                : false
                            }
                            onChange={e => onChangeValue(UPDATE_BOOKINGS_PROP_NAME, e.target.checked)}
                        />
                        {UPDATE_BOOKINGS_LABEL}
                    </label>
                </InputGroup>
            </fieldset>
            <fieldset>
                <legend>Optional fields</legend>
                <InputGroup
                    messages={errors[ENABLE_PASSPORTS_PROP_NAME]}
                    style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
                >
                    <label>
                        <EnablePassportUploadsInput
                            type="crew"
                            arePassportUploadsEnabled={passportUploadsEnabled}
                            onChange={() => {
                                onChangeValue(ENABLE_PASSPORTS_PROP_NAME, !passportUploadsEnabled)
                            }}
                        />
                        {ENABLE_PASSPORTS_LABEL}
                    </label>
                </InputGroup>
                {values[ENABLE_PASSPORTS_PROP_NAME] && (
                    <InputGroup
                        messages={errors[ENABLE_PASSPORTS_PROP_NAME]}
                        style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
                    >
                        <label>
                            <input
                                type="checkbox"
                                style={{ marginRight: '1rem' }}
                                checked={(values[REQUIRE_PASSPORTS_PROP_NAME]) || false}
                                onChange={e => onChangeValue(REQUIRE_PASSPORTS_PROP_NAME, e.target.checked)}
                            />
                            {REQUIRE_PASSPORTS_LABEL}
                        </label>
                    </InputGroup>
                )}
            </fieldset>
            {isAccreditationEnabled && (
                <fieldset>
                    <legend>Accreditation</legend>
                    <InputGroup
                        messages={errors[PROFILE_PROP_NAME]}
                        style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
                    >
                        <label className="required">{PROFILE_LABEL}</label>
                        <select
                            value={values[PROFILE_PROP_NAME] || ''}
                            onChange={e => onChangeValue(PROFILE_PROP_NAME, e.target.value)}
                        >
                            {[DEFAULT_ACCREDITATION_PROFILE_OPTION, ...accreditationProfileOptions].map(
                                ({ value, label }) =>
                                    <option key={value} value={value}>{label}</option>
                            )}
                        </select>
                    </InputGroup>
                </fieldset>
            )}
        </Fragment>
    );
}

CrewForm.propTypes = {
    isAccreditationEnabled: PropTypes.bool,
    accreditationProfileOptions: PropTypes.array,
    ticketTypeOptions: PropTypes.array,
    values: PropTypes.shape({
        [DESCRIPTION_PROP_NAME]: PropTypes.string,
        [LIMIT_PROP_NAME]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        [ENABLE_PASSPORTS_PROP_NAME]: PropTypes.boolean,
        [REQUIRE_PASSPORTS_PROP_NAME]: PropTypes.boolean,
        [PROFILE_PROP_NAME]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
};
