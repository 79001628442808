import { connect } from 'react-redux';
import * as Event from 'app/react/entities/events/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups/index';
import ContentHeader from './ContentHeader';

const mapStateToProps = (state) => {
    const { downloadLogisticsReport } = Event.stateHelpers.getUserPermissions(state);
    return {
        userCanDownloadReport: downloadLogisticsReport,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onDownloadReport: () => {
        dispatch(Pickup.thunks.downloadPickupsReport());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContentHeader);
