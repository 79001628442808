import $ from 'jquery';
import SubTableRow from './SubTableRow.view';

export default class SubTableFirstRow extends SubTableRow {

    setSubTable(table) {
        this.subTable = table.firstColumnsTable;
        this.subTable.header = null;
    }

    createCell() {
        const colspan = this.subTable.columns.length;
        // add some padding to indicate that this is a sub table
        return $(`<td style="padding: 0;padding-left: 20px;" colspan="${colspan}"></td>`);
    }

}
