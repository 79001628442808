import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import {
    TableRow,
    TableCell,
    TableActionsDropdown,
    Checkbox,
 } from 'ui-kit'
import { GroundTransport } from 'app/react/views/event/artists/bookings/transports/components/Icons'
import { Tooltip } from 'app/react/components/_index'

const TOOLTIP_DELETE = 'Delete pickup';
const TOOLTIP_EDIT = 'Edit pickup';
const TOOLTIP_GO_TO_BOOKING = 'Go to booking';
const TOOLTIP_NOT_CHECKED_IN = (name) => `Mark <strong>${name}</strong> as arrived`;
const TOOLTIP_CHECKED_IN = (date) => `Arrived on <strong>${date}</strong>`;

const ItemNumber = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: var(--fontWeightMedium);
    color: var(--colorNeutral300);

    i {
        margin-right: var(--spacingSmallest);
    }
`;

const PickupsTableRow = ({
    pickup = {},
    dateFormat,
    dateFormatShort,
    timeFormat,
    onClick = () => {},
    onClickEdit = () => {},
    onClickGoToBooking = () => {},
    onChangeIsArrived = () => {},
    onClickDelete = () => {},
}) => {
    const {
        id,
        mode = {},
        booking = {},
        departure,
        driverDepartureTime = 0,
        departureLocationName,
        arrivalLocationName,
        passengerAmount,
        passengers = [],
        isArrived,
        notes,
        isFetching
    } = pickup;
    const driver = pickup.driver || {}; // can be null (so no destruct)
    const passengersTooltip = passengers.length > 0
        ? passengers.map(({ firstname, lastname }) => `${firstname} ${lastname}`).join('<br />')
        : passengerAmount > 0 ? 'No specific passengers selected' : 'No passengers selected';
    const departureDate = moment.utc(departure, moment.ISO_8601).format(dateFormatShort);
    const departureTime = moment.utc(departure, moment.ISO_8601).format(timeFormat);
    const driverLeaveDate = moment.utc(pickup.driverLeaveTime, moment.ISO_8601).format(dateFormatShort);
    const driverLeaveTime = moment.utc(pickup.driverLeaveTime, moment.ISO_8601).format(timeFormat);
    const arrivedAt = moment.parseZone(pickup.arrivedAt, moment.ISO_8601).format(`${dateFormat} ${timeFormat}`);
    return (
        <TableRow onClick={onClick}>
            <TableCell className="center">
                <Tooltip
                    content={isArrived
                        ? TOOLTIP_CHECKED_IN(arrivedAt)
                        : TOOLTIP_NOT_CHECKED_IN(booking.artist.name)
                    }
                >
                    <Checkbox
                        id={`reservationCheckbox-${id}`}
                        checked={isArrived}
                        loading={isFetching}
                        onChange={e => {
                            onChangeIsArrived(id, e.target.checked);
                            e.stopPropagation();
                        }}
                    />
                </Tooltip>
            </TableCell>
            <TableCell className="center">
                <Tooltip content={mode.name}>
                    {mode.id === 3
                        ? <span style={{ color: 'var(--colorNeutral300)' }}>
                            <GroundTransport className={mode.icon} />
                        </span>
                        : <i className={mode.icon} />
                    }
                </Tooltip>
            </TableCell>
            <TableCell>{booking.artist.name}</TableCell>
            <TableCell className="center">
                <Tooltip content={passengersTooltip}>
                    <ItemNumber>
                        <i className="bs-icon-users" />
                        {passengerAmount}
                    </ItemNumber>
                </Tooltip>
            </TableCell>
            <TableCell>{driver.firstname} {driver.lastname}</TableCell>
            <TableCell>
                {driverDepartureTime !== 0 &&
                    <span>
                        {driverLeaveDate}&nbsp;
                        <strong style={{ marginLeft: '10px' }}>{driverLeaveTime}</strong>
                    </span>
                }
            </TableCell>
            <TableCell>
                <span>
                    {departureDate}&nbsp;
                    <strong style={{ marginLeft: '10px' }}>{departureTime}</strong>
                </span>
            </TableCell>
            <TableCell>{departureLocationName}</TableCell>
            <TableCell>{arrivalLocationName}</TableCell>
            <TableCell className="center">
                {notes &&
                    <Tooltip content={notes} position="top right">
                        <i className="bs-icon-document" />
                    </Tooltip>
                }
            </TableCell>
            <TableCell.Actions>
                <TableActionsDropdown>
                    <TableActionsDropdown.Action
                        onClick={e => {
                            onClickGoToBooking(id, e.ctrlKey || e.metaKey);
                            e.stopPropagation();
                        }}
                        onMouseUp={e => e.stopPropagation()}
                    >
                        <i className="bs-icon-link" /> {TOOLTIP_GO_TO_BOOKING}
                    </TableActionsDropdown.Action>
                    <TableActionsDropdown.Action
                        onClick={e => {
                            onClickEdit(id, e.ctrlKey || e.metaKey);
                            e.stopPropagation()
                        }}
                        onMouseUp={e => e.stopPropagation()}
                    >
                        <i className="bs-icon-pencil" /> {TOOLTIP_EDIT}
                    </TableActionsDropdown.Action>
                    <TableActionsDropdown.Action onClick={() => onClickDelete(id)}>
                        <i className="bs-icon-cross" /> {TOOLTIP_DELETE}
                    </TableActionsDropdown.Action>
                </TableActionsDropdown>
            </TableCell.Actions>
        </TableRow>
    )
};

PickupsTableRow.propTypes = {
    reservation: PropTypes.object,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    onChangeIsArrived: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func
};

export default PickupsTableRow;
