import React, { Fragment } from 'react'
import PaginatedVehicleTable from './PaginatedTable'
import CreateVehicleModalMutationContainer from '../../components/modals/stateful/CreateModalMutationContainer'
import UpdateVehicleModalMutationContainer from '../../components/modals/stateful/UpdateModalMutationContainer'
import DeleteVehicleModalMutationContainer from '../../components/modals/stateful/DeleteModalMutationContainer'

const PaginatedVehicleTableModalWrapper = ({
    vehicles,
    count,
    totalCount,
    isLoading,
    isLoadingMore,
    canLoadMore,
    eventId,
    first,
    after,
    sort,
    currentSortKey,
    currentSortOrder,
    onCreate = () => {},
    onUpdate = () => {},
    onDelete = () => {},
    onSort = () => {},
    onLoadMore = () => {},
}) => (
    <Fragment>
        <PaginatedVehicleTable
            vehicles={vehicles}
            count={count}
            totalCount={totalCount}

            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            canLoadMore={canLoadMore}
            currentSortKey={currentSortKey}
            currentSortOrder={currentSortOrder}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onSort={onSort}
            onLoadMore={onLoadMore}
        />
        <CreateVehicleModalMutationContainer eventId={eventId} first={first} after={after} sort={sort} />
        <UpdateVehicleModalMutationContainer eventId={eventId} first={first} after={after} sort={sort} />
        <DeleteVehicleModalMutationContainer eventId={eventId} first={first} after={after} sort={sort} />
    </Fragment>
);

export default PaginatedVehicleTableModalWrapper;
