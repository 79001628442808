import { oneLineTrim } from 'common-tags';
import {
    includeParam,
} from 'app/react/state/middleware/api'

const rootAll = (eventId, artistTypeId) =>
    `events/${eventId}/artist-types/${artistTypeId}/production-website/artists/settings`;
const rootOne = (eventId, artistTypeId, sectionId) =>
    `${rootAll(eventId, artistTypeId)}/${sectionId}`;

export const fetchOne = (
    eventId,
    artistTypeId,
    sectionId,
    includes = []
) => oneLineTrim`
    ${rootOne(eventId, artistTypeId, sectionId)}?
    ${includeParam(includes)}
`;

export const updateOne = (
    eventId,
    artistTypeId,
    sectionId,
    includes = []
) => oneLineTrim`
    ${rootOne(eventId, artistTypeId, sectionId)}?
    ${includeParam(includes)}
`;
