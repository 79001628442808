import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Event from 'app/react/entities/events/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Item from 'app/react/entities/events/artists/items/index';
import * as ItemGroups from 'app/react/entities/events/artists/item_groups/index';
import Overview from 'app/react/views/event/artists/items/views/overview/OverviewContainer';
import * as Routes from './routes'

export default class ItemsController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        router.route(Routes.base(), null, this.overview.bind(this));
    }

    overview(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(Overview, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        const filters = { assigned: true };
        const pageSize = 'all';
        const includes = ['day_totals'];

        return Promise.all([
            dispatch(Item.thunks.fetchAllByPaginationForCurrentContext({ filters, pageSize }, includes)),
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(ItemGroups.thunks.fetchAllForCurrentContext()),
        ])
    }

}
