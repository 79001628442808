import BaseView from 'app/backbone/components/Base.view'

export default class DetailView extends BaseView {

    ui() {
        return {
            row: 'tr[data-url]',
            addPerformer: '.add-artist-contact-performer',
            addPerformerModal: '#add-artist-contact-performer-modal',
            addCrew: '.add-artist-contact-crew',
            addCrewModal: '#add-artist-contact-crew-modal',
            addContact: '.add-artist-contact-contact',
            addContactModal: '#add-artist-contact-contact-modal',
            addCompany: '.add-artist-company',
            addCompanyModal: '#add-artist-company-modal',
        };
    }

    events() {
        return {
            'click row': 'goToUrl',
            'click addPerformer': 'openPerformerModal',
            'click addCrew': 'openCrewModal',
            'click addContact': 'openContactModal',
            'click addCompany': 'openCompanyModal',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    goToUrl({ currentTarget }) {
        window.location = currentTarget.dataset.url;
    }

    openPerformerModal() {
        this.openModalFromDOM(this.ui.addPerformerModal);
    }

    openCrewModal() {
        this.openModalFromDOM(this.ui.addCrewModal);
    }

    openContactModal() {
        this.openModalFromDOM(this.ui.addContactModal);
    }

    openCompanyModal() {
        this.openModalFromDOM(this.ui.addCompanyModal);
    }
}
