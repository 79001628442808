import styled from 'styled-components';

export const SortCell = styled.th`
    &:hover {
        cursor: pointer;
    }
`;

export const SelectableRow = styled.tr`
    &:hover {
        td {
            cursor: pointer;
            background-color: #F5F7FC;
        }
    }
`;

export const SortButton = styled.a`
    color: #002634;
    font-size: 0.875rem;
    font-weight: 400;
    
    &:hover {
        color: #002634;
    }
`;


export const SortIcon = styled.i`
    font-size: 10px;
    margin-left: ${props => props.hasText ? '20px' : '0'};
    
    &:before {
        vertical-align: 0 !important;
    }
`;

export const ItemNumber = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        margin-right: var(--spacingSmallest);
    }
`;
