import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHTTP } from "../../hooks/UseHTTP";
import Footer from "../footer/Footer";
import EmailTemplateDialog from "../atoms/dialogs/EmailTemplateDialog";
import Button from "../atoms/button/Button";
import Icon from "../../utils/Icon";
import DeleteDialog from "../atoms/dialogs/DeleteDialog";
import FeedbackMessage from "../atoms/feedback/Feedback";

export const EmailForm = ({ setActiveStep, activeSubStep, eventState }) => {
  const { doHttpCall, doHttpCallJson } = useHTTP();
  const [error, setError] = useState(undefined);
  const [emailTemplateToEdit, setEmailTemplateToEdit] = useState(undefined);
  const [templates, setTemplates] = useState(eventState.event.templates);
  const [templateToRemove, setTemplateToRemove] = useState(undefined);
  const getAllTemplates = async (url) => {
    const templatesRes = await doHttpCall(url, "GET");
    if (
      templatesRes.meta &&
      templatesRes.meta.pagination &&
      templatesRes.meta.pagination.links &&
      templatesRes.meta.pagination.links.next
    ) {
      const nextRes = await getAllTemplates(
        templatesRes.meta.pagination.links.next
      );
      if (nextRes.data) {
        return {
          ...nextRes,
          data: [...templatesRes.data, ...nextRes.data],
        };
      }
    } else {
      return templatesRes;
    }
  };

  useEffect(() => {
    if (
      eventState.event.id &&
      (!eventState.event.templates || eventState.event.templates.length === 0)
    ) {
      if (eventState.event.oldEventId) {
        const newAndOldTemplates = [
          Promise.resolve(
            getAllTemplates(
              `/api/events/${eventState.event.id}/email-templates`
            )
          ),
          Promise.resolve(
            getAllTemplates(
              `/api/events/${eventState.event.oldEventId}/email-templates`
            )
          ),
        ];
        Promise.all(newAndOldTemplates).then((res) => {
          const newTemplates = res[0].data.map((template) => template.title);
          const oldTemplates = res[1].data
            .filter((temp) => !newTemplates.includes(temp.title))
            .map((template) => ({
              title: template.title,
              body: template.body,
              subject: template.subject,
            }));
          if (oldTemplates && oldTemplates.length > 0) {
            batchCreateTemplates(oldTemplates);
          }
          setTemplates(res[0].data);
        });
      } else {
        getAllTemplates(`/api/events/${eventState.event.id}/email-templates`)
          .then((res) => {
            if (res && res.data) {
              setTemplates(res.data);
            }
          })
          .catch((error) => {
            console.error("Getting email templates:: ", error);
            setError("Something went wrong while fetching the email templates");
          });
      }
    }
  }, [eventState.event.id]);

  const createEmailTemplate = (template) => {
    if (template.id) {
      doHttpCallJson(
        `/api/events/${eventState.event.id}/email-templates/${template.id}`,
        "PUT",
        template
      )
        .then((templateRes) => {
          if (templateRes && templateRes.data && templateRes.data.id) {
            setTemplates((prev) =>
              prev.map((existingTemplate) => {
                return existingTemplate.id === templateRes.data.id
                  ? templateRes.data
                  : existingTemplate;
              })
            );
            setEmailTemplateToEdit(undefined);
          }
        })
        .catch((error) => {
          setError("Something went wrong while updating the email template");
          console.error("Email update:: ", error);
        });
    } else {
      doHttpCall(
        `/api/events/${eventState.event.id}/email-templates`,
        "POST",
        template
      )
        .then((templateRes) => {
          if (templateRes && templateRes.data && templateRes.data.id) {
            setTemplates([...templates, templateRes.data]);
            setEmailTemplateToEdit(undefined);
          }
        })
        .catch((error) => {
          setError("Something went wrong while creating the email template");
          console.error("Email create:: ", error);
        });
    }
  };

  const batchCreateTemplates = (templates) => {
    const promises = templates.map((template) =>
      Promise.resolve(
        doHttpCallJson(
          `/api/events/${eventState.event.id}/email-templates`,
          "POST",
          template
        )
      )
    );
    Promise.all(promises)
      .then((emailRes) => {
        setTemplates((prev) => [...prev, ...emailRes.map((res) => res.data)]);
      })
      .catch((error) => {
        console.error("Batch create templates:: ", error);
        setError(
          "Something went wrong while fetching the templates from the previous event"
        );
      });
  };

  const removeTemplate = (template) => {
    doHttpCall(
      `/api/events/${eventState.event.id}/email-templates/${template.id}`,
      "DELETE",
      template
    ).then((templateRes) => {
      if (templateRes && templateRes.status === 204) {
        setTemplates((prev) =>
          prev.filter((existingTemplate) => existingTemplate.id !== template.id)
        );
        setTemplateToRemove(undefined);
      }
    });
  };

  return (
    <div className="form_content">
      {error && (
        <FeedbackMessage
          text={error}
          variant="error"
          closeFeedback={() => setError("")}
        />
      )}
      <h2>Email template</h2>
      <h3 className="form-sub-title">Setup emails for your contacts</h3>
      <span>
        You can create as many email templates as you like. For this first
        setup, we would like you to have a look at the Welcome mail as an
        example and edit it.
      </span>
      {templates.length > 0 && (
        <table className="form__table">
          <thead>
            <tr className="form__table-header">
              <th>TITLE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {templates.map((template) => {
              return (
                <tr className="form__table-row" key={template.id}>
                  <td>{template.title}</td>
                  <td className="form__table__last-column">
                    <button
                      className="form__table__button"
                      onClick={() => setEmailTemplateToEdit(template)}
                    >
                      <Icon
                        size={26}
                        name="edit"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                    {template.id !== "welcome-mail" && (
                      <button
                        className="form__table__button"
                        onClick={() => setTemplateToRemove(template)}
                      >
                        <Icon
                          size={26}
                          name="delete"
                          style={{ marginBottom: "-10px" }}
                        />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <br />
      <Button
        label="Add e-mail template"
        variant="secondary"
        onClick={() =>
          setEmailTemplateToEdit({
            title: "",
            subject: undefined,
            body: "",
          })
        }
      />
      {emailTemplateToEdit && (
        <EmailTemplateDialog
          addTemplate={createEmailTemplate}
          setCancelDialogOpen={() => setEmailTemplateToEdit(undefined)}
          template={emailTemplateToEdit}
        />
      )}
      {templateToRemove && (
        <DeleteDialog
          title="Remove email template"
          text="Are you sure you want to remove this template?"
          buttonText="Yes, remove template!"
          confirm={() => removeTemplate(templateToRemove)}
          setCancelDialogOpen={() => setTemplateToRemove(undefined)}
        />
      )}
      <Footer
        setActiveStep={(step) => setActiveStep(step, { templates })}
        activeSubStep={activeSubStep}
        eventState={eventState}
        isNextDisabled={templates.length === 0}
      />
    </div>
  );
};

EmailForm.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
  eventState: PropTypes.object,
};

export default EmailForm;
