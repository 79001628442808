import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PageableTable from 'app/backbone/components/tables/PageableTable.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import ItemModalUpdate from '../modals/ItemModalUpdate.view';
import * as Item from 'app/backbone/models/account/accreditation/Item';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';

export default class ItemTable extends PageableTable {

    optionTypes() {
        return {
            items: OptionTypes.instanceOf(Item.PageableCollection).isRequired,
            itemGroups: OptionTypes.instanceOf(ItemGroup.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.collection = this.items;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.placeholderText = this.getPlaceholderText();

        // SubViews
        this.confirmModal = this.initConfirmModal();
        this.updateModal = this.initUpdateModel();

        super.initialize(options);
    }

    initColumns() {
        const actions = this.actions;
        return [{
            name: 'name',
            label: 'Name',
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'name',
            label: 'Group',
            cell: CellFactory.createSubModelCell(null, null, 'item_group'),
            sortable: false,
            editable: false
        },{
            name: 'date_dependable',
            label: 'Date',
            headerCell: CellFactory.createHeaderCell('75px', 'center'),
            cell: CellFactory.createCheckCell('75px', 'center'),
            sortable: false,
            editable: false
        },{
            name: 'uses_amounts',
            label: 'Amount',
            headerCell: CellFactory.createHeaderCell('75px', 'center'),
            cell: CellFactory.createCheckCell('75px', 'center'),
            sortable: false,
            editable: false
        },{
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(actions, '8Calendar0px'),
            sortable: false,
            editable: false
        }];
    }

    initActions() {
        return [{
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            action: this.update.bind(this)
        },{
            name: 'remove',
            icon: 'bs-icon-cross',
            tooltip: 'Remove',
            action: this.delete.bind(this)
        }];
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    initUpdateModel() {
        const { itemGroups } = this;
        return new ItemModalUpdate({ itemGroups });
    }

    update(item) {
        this.updateModal.open(item);
    }

    delete(item) {
        const name = item.get('name');
        this.confirmModal.open({
            title: 'Remove Item',
            body: `Are you sure you want to remove <strong>${name}</strong>?`,
            confirmClass: 'danger',
            confirmText: 'Yes, remove',
            autoClose: true,
            syncingText: `Removing ${name}...`,
            confirm: () => { item.destroy({wait: true}); }
        });
    }

    onSync() {
        super.onSync();
        this.collection.sort();
    }

    getPlaceholderText() {
        if (!this.itemGroups.isEmpty()) return 'No items have been created for this event.';
        return 'No item groups have been created for this event. <strong>First create an item group.</strong>';
    }

}
