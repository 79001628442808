import { METHODS } from '../../../middleware/api';
import {
    addEntityToCollection,
    removeEntityFromCollection,
    removeEntityForEachCollection
} from './util';

export const entitySuccessReducer = (state, action) => {
    switch (action.method) {
        case METHODS.DELETE: {
            return removeEntityFromCollection(state, action)
        }
        case METHODS.POST: {
            return addEntityToCollection(state, action)
        }
    }
    return state
};

export const entitySuccessReducerWithKey = (state, action, key) => {
    switch (action.method) {
        case METHODS.DELETE: {
            return removeEntityForEachCollection(state, action)
        }
        case METHODS.POST: {
            if (typeof state[key] === 'undefined') {
                return state
            }
            return {
                ...state,
                [key]: addEntityToCollection(state[key], action)
            };
        }
    }
    return state
};
