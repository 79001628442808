import Backbone from 'backbone';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import SingleSelectize from 'app/backbone/components/inputs/SingleSelectize.view';
import * as User from 'app/backbone/models/account/User';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as EventUser from 'app/backbone/models/event/EventUser';
import template from './GeneralSettingsForm.hbs';

export default class GeneralSettingsForm extends BaseView {

    get template() {
        return template;
    }

    ui() {
        return {
            responsibleUserSelectize: 'select[name="responsible_user"]'
        };
    }

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            currentUser: OptionTypes.instanceOf(User.Model).isRequired,
            users: OptionTypes.instanceOf(EventUser.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.inputs = new Backbone.ChildViewContainer();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.initResponsableUserSingleSelectize();
        this.renderForm();
        return this.delegateEvents();
    }

    initResponsableUserSingleSelectize() {
        const { currentUser, users, company } = this;
        const responsableUserSelectizeEl = this.ui.responsibleUserSelectize;
        const usersSelectize = new SingleSelectize({
            model: company,
            collection: users,
            initialValue: currentUser.id,
            el: responsableUserSelectizeEl,
            modelAttr: 'responsible_user'
        });

        this.inputs.add(usersSelectize, 'responsible_user');
    }

    renderForm() {
        this.inputs.call('render');
    }
}
