import { Model, Collection }  from 'app/backbone/models/decorators';
import * as Accreditee from './Accreditee';

@Model({
    key: 'custom_accreditee'
})
class CustomAccreditee extends Accreditee.Model {

    urlRoot() {
        const { event } = this.relations;
        return `/api/events/${event.id}/custom-accreditees`;
    }

    sync(method, model, options){
        if (method === 'read'){
            const { event } = this.relations;
            options.url = `/api/events/${event.id}/accreditees/${this.id}`;
        }
        return super.sync(method, model, options);
    }

    toJSON(options) {
        const { profiles, items } = this.relations;
        const json = super.toJSON(options);
        json.profiles = profiles
            .filterByGrantedByPromoter()
            .toJSON();
        json.items = items
            .filterByGrantedByPromoter()
            .filterByHasGrantedDay()
            .toJSON();
        return json;
    }

    validateProfiles() {
        if(!this.isNew()) {
            if(this.profiles.someGrantedByPromoterHaveNoGrantedDays()) {
                return 'Select at least one day per added profile.';
            }
            return false;
        } else {
            return super.validateProfiles()
        }
    }

}

@Collection({
    key: 'custom_accreditees',
    model: CustomAccreditee
})
class CustomAccrediteeCollection extends Accreditee.Collection {

    url() {
        const { event } = this.relations;
        return `/api/events/${event.id}/custom-accreditees`;
    }
}

const PageableCollection = CustomAccrediteeCollection.prototype.PageableCollection();

export {
    CustomAccreditee as Model,
    CustomAccrediteeCollection as Collection,
    PageableCollection
};
