import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import {
    omitAllUndefinedKeys,
    omitAllNullKeys,
    parseIntByKeys,
    omitByKeys,
    transformGraphQLErrorsToFormErrors
} from 'app/react/helpers/_index';
import UpdateVehicleModalContainer from './UpdateModalContainer';
import { VEHICLES_PAGINATED_TABLE_QUERY } from '../../table/PaginatedTableModalWrapperQueryContainer';
import * as State from './UpdateModalState';

const MUTATION = gql`
    mutation UpdateVehicle($vehicleId: ID!, $input: UpdateVehicleInput!) {
        updateVehicle(id: $vehicleId, input: $input) {
            vehicle {
                id
                type {
                    id
                }
                make
                model
                numberOfPassengers
                pickups {
                    totalCount
                }
                licencePlate
                mileageIn
                mileageOut
                notes
            }
        }
    }
`;

const injectMutation = graphql(
    MUTATION, {
        name: 'updateVehicleMutation',
        options: ({ eventId, first, after, sort }) => ({
            refetchQueries: [{
                query: VEHICLES_PAGINATED_TABLE_QUERY,
                variables: { eventId, first, after, sort },
            }],
        }),
    }
);

const omitUnusedKeys = (obj) => omitByKeys(obj, ['id', '__typename', 'pickups', 'isDeletable']);
const parseIntKeys = (obj) => parseIntByKeys(obj, ['type', 'numberOfPassengers', 'mileageIn', 'mileageOut']);

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSave: (values) => {
        const { updateVehicleMutation, onSaveSuccess } = ownProps;

        dispatch(State.setStateValue('isSaving', true));

        const vehicleId = parseInt(values.id, 10);
        const finalValues = omitUnusedKeys(parseIntKeys(omitAllUndefinedKeys(omitAllNullKeys(values))));
        const input = {
            ...finalValues,
            type: {
                id: finalValues.type
            }
        };

        updateVehicleMutation({
            variables: { vehicleId, input }
        }).then(({ data }) => {
            const { id, make, model } = data.updateVehicle.vehicle;

            if (typeof onSaveSuccess === 'function') {
                onSaveSuccess(id);
            }

            dispatch(State.showSuccessPopup(make, model));
            dispatch(State.close());
        }).catch(({ graphQLErrors }) => {
            const errors = transformGraphQLErrorsToFormErrors(graphQLErrors);
            dispatch(State.showErrorPopup());
            dispatch(State.mergeState({
                errors,
                isSaving: false
            }));
        });
    }
});

const injectState = connect(
    null,
    mapDispatchToProps,
);

export default compose(
    injectMutation,
    injectState,
)(UpdateVehicleModalContainer)
