import CheckboxCell from 'app/backbone/components/tables/cells/CheckboxCell.view';

export default class GrantedDayCell extends CheckboxCell {

    className() {
        return 'checkbox-cell input center';
    }

    initialize(options = {}) {
        super.initialize(options);

        // Props
        this.grantedDays = this.model.getRelation('granted_days');

        this.listenTo(this.grantedDays, 'update reset', this.render);
    }

    isDisabled() {
        return !this.model.hasDayOn(this.day) || !this.model.get('granted_by_promoter');
    }

    isChecked() {
        return this.model.hasGrantedDayOn(this.day);
    }

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        if (checked) this.model.addGrantedDayOn(this.day);
        else this.model.removeGrantedDayOn(this.day);
        this.render();
    }

}
