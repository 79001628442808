import { oneLineTrim } from 'common-tags';
import {
    searchParam,
} from 'app/react/state/middleware/api'

export const fetchAll = (
    accountId,
    searchTerm
) => oneLineTrim`
    accounts/${accountId}/artists?
    ${searchParam(searchTerm)}
`;
