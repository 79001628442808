import {
    HEIGHT,
    SCROLLABLE_CONTAINER,
    TOOLBAR_STICKY,
    TOOLBAR_BUTTONS,
    IMAGE_INSERT_BUTTONS,
    LINK_LIST,
} from 'ui-kit/lib/util/froala'
import BaseView from 'app/backbone/components/Base.view'
import OptionTypes from 'app/backbone/helpers/OptionTypes'
import Services from 'app/services'

export default class TextEditor extends BaseView {

    tagName() {
        return 'textarea';
    }

    optionTypes() {
        return {
            height: OptionTypes.number,
            value: OptionTypes.string,
            onBlur: OptionTypes.func,
        };
    }

    optionDefaults() {
        return {
            height: 300,
            onBlur: () => {},
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
    }

    render() {
        this.$el.froalaEditor({
            key: Services.froalaKey,
            height: this.height,
            placeholderText: '',
            toolbarBottom: true,
            toolbarButtons: TOOLBAR_BUTTONS,
            toolbarButtonsMD: TOOLBAR_BUTTONS,
            toolbarButtonsSM: TOOLBAR_BUTTONS,
            toolbarButtonsXS: TOOLBAR_BUTTONS,
            toolbarSticky: TOOLBAR_STICKY,
            imageInsertButtons: IMAGE_INSERT_BUTTONS,
            imageDefaultWidth: 0,
            imageDefaultAlign: 'left',
            imageUploadRemoteUrls: false,
            linkAutoPrefix: '',
            linkList: LINK_LIST,
        });

        if (this.value) this.$el.froalaEditor('html.set', this.value);
        this.$el.on('froalaEditor.blur', this.onBlur);
    }

    remove() {
        this.$el.froalaEditor('destroy');
        super.remove();
    }
}
