import React from 'react';
import PropTypes from 'prop-types';
import { Grid, PostfixedButtonInput } from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';
import { PageSizeSelect, FilterSelect, ClearButton } from './topBarStyles';

const { Cell } = Grid;

const PAGE_SIZE_LABEL = 'Show:';
const PAGE_SIZE_OPTIONS = [{
    value: 5,
    label: '5'
}, {
    value: 10,
    label: '10'
}, {
    value: 25,
    label: '25'
},{
    value: 50,
    label: '50'
} ,{
    value: 100,
    label: '100'
}];
const SEARCH_PLACEHOLDER = 'Search...';
const SEARCH_TOOLTIP = 'Search or press ENTER key';

export const PageSize = ({
    label = PAGE_SIZE_LABEL,
    options = PAGE_SIZE_OPTIONS,
    value = 10,
    onChange = () => {},
}) => (
    <label>
        {label}
        <PageSizeSelect
            value={value}
            onChange={e => onChange(parseInt(e.target.value, 10))}
        >
            {options.map(({ value, label }) => (
                <option
                    key={value}
                    value={value}
                >
                    {label}
                </option>
            ))}
        </PageSizeSelect>
    </label>
);

PageSize.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array
};

export const Filter = ({
    options = [],
    width = '150px',
    value = 0,
    onChange = () => {},
    isFetchingBookings = false,
}) => (
    <label>
        <FilterSelect
            value={value}
            onChange={e => onChange(e.target.value)}
            style={{ width }}
            disabled={isFetchingBookings}
        >
            {options.map(({ value, label }) => (
                <option
                    key={value}
                    value={value}
                >
                    {label}
                </option>
            ))}
        </FilterSelect>
    </label>
);

Filter.propTypes = {
    label: PropTypes.string.isRequired,
    width: PropTypes.string,
    options: PropTypes.array
};

export const Search = ({
    value = '',
    isShowingSearchResults = false,
    onChangeValue = () => {},
    onSearch = () => {},
    onClear = () => {}
}) => (
    <PostfixedButtonInput
        buttonIcon={(
            <Tooltip content={SEARCH_TOOLTIP} position="top right">
                <i className="bs-icon-magnifier" />
            </Tooltip>
        )}
        buttonOnClick={() => onSearch(value)}
    >
        <input
            type="text"
            placeholder={SEARCH_PLACEHOLDER}
            value={value}
            onKeyPress={e => {
                if (value !== '') {
                    if (e.charCode === 13) {
                        onSearch(value);
                    }
                }
            }}
            onChange={e => {
                const newValue = e.target.value;
                if (newValue === '' && isShowingSearchResults) {
                    onClear();
                } else {
                    onChangeValue(newValue);
                }
            }}
        />
        {isShowingSearchResults && (
            <ClearButton onClick={onClear}>
                <i className="bs-icon-cross no-margin" />
            </ClearButton>
        )}
    </PostfixedButtonInput>
);

Search.propTypes = {
    value: PropTypes.string,
    onChangeValue: PropTypes.func,
    onSearch: PropTypes.func,
    onClear: PropTypes.func
};

export default Search;
