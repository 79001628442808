import React from 'react';
import PropTypes from 'prop-types';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select driver...';
export const NO_RESULTS_PLACEHOLDER = 'No driver found.';

const toOption = ({
    id,
    firstname,
    lastname,
}) => ({
    value: id,
    label: `${firstname} ${lastname}`,
});

const SelectDriver = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    clearable = false,
    value,
    drivers = [],
    onChange = () => {}
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        isClearable={clearable}
        options={drivers.map(toOption)}
        onChange={onChange}
    />
);

SelectDriver.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    drivers: PropTypes.array,
    onChange: PropTypes.func
};

export default SelectDriver;
