import CompanyItemGroupUpdateView from 'app/backbone/views/event/accreditation/companies/items/views/CompanyItemGroupUpdate.view';
import CompanyItemGroupCard from './cards/CompanyItemGroupCard.view';

export default class ProductionWebsiteCompanyItemGroupUpdateView extends CompanyItemGroupUpdateView {

    initItemsCard() {
        const { company, items, periods, itemGroup } = this;
        const editing = true;
        const title = itemGroup.get('name');
        return new CompanyItemGroupCard({ company, items, periods, itemGroup, editing, title });
    }

    backToOverview() {
        const productionWebsiteUrl = window.beatswitch.production_website.slug;
        navigate(`/production-website-accreditation/${productionWebsiteUrl}/company-form`);
    }

}
