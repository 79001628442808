import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PageableTable from 'app/backbone/components/tables/PageableTable.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import PeriodModalUpdate from '../modals/PeriodModalUpdate.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';

export default class PeriodTable extends PageableTable {

    optionTypes() {
        return {
            periods: OptionTypes.instanceOf(Period.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.collection = this.periods;
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.placeholderText = this.getPlaceholderText();

        // SubViews
        this.confirmModal = this.initConfirmModal();
        this.updateModal = this.initUpdateModel();

        super.initialize(options);
    }

    initColumns() {
        const columns = super.initColumns();
        columns.push({
            name: 'name',
            label: 'Name',
            headerCell: CellFactory.createHeaderCell('30%'),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'start_date',
            label: 'Start',
            headerCell: CellFactory.createHeaderCell('150px'),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'end_date',
            label: 'End',
            headerCell: CellFactory.createHeaderCell('150px'),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'days_count',
            label: '# Days',
            headerCell: CellFactory.createHeaderCell('75px', 'center'),
            cell: CellFactory.createCell('75px', 'center'),
            sortable: false,
            editable: false
        }, {
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(this.actions),
            sortable: false,
            editable: false
        });
        return columns;
    }

    initActions() {
        return [{
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            action: this.update.bind(this),
            canRender: model => ! model.get('ready_only')
        }, {
            name: 'remove',
            icon: 'bs-icon-bin',
            tooltip: 'Remove',
            action: this.delete.bind(this),
            canRender: model => ! model.get('ready_only')
        }];
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    initUpdateModel() {
        return new PeriodModalUpdate();
    }

    update(period) {
        this.updateModal.open(period);
    }

    delete(period) {
        const name = period.get('name');
        this.confirmModal.open({
            title: 'Remove Period',
            body: `Are you sure you want to remove <strong>${name}</strong>?`,
            confirmClass: 'danger',
            confirmText: 'Yes, remove',
            autoClose: true,
            syncingText: `Removing ${name}...`,
            confirm: () => { period.destroy({ wait: true }); }
        });
    }

    getPlaceholderText() {
        return 'No periods have been created for this event.';
    }

}
