import CheckboxCell from 'app/backbone/components/tables/cells/CheckboxCell.view';

export default class GrantedDayCell extends CheckboxCell {

    className() {
        return `${super.className} center`;
    }

    initialize(options = {}) {
        super.initialize(options);

        // Props
        this.grantedDays = this.model.getRelation('granted_days');
        this.accrediteeProfiles = this.model.getRelation('accreditee').getRelation('profiles');

        this.accrediteeProfiles.forEach((profile) => {
            this.addProfileGrantedDaysListener(profile);
        });

        // Listeners
        this.listenTo(this.grantedDays, 'add remove', this.onAddOrRemoveGrantedDay);
        this.listenTo(this.accrediteeProfiles, 'update reset', this.onUpdateProfiles);
        this.listenTo(this.accrediteeProfiles, 'add', this.onAddProfile);
        this.listenTo(this.accrediteeProfiles, 'remove', this.onRemoveProfile);
    }

    renderMaybe(day) {
        if (day.id === this.day.id) this.render();
    }

    isVisible() {
        return this.model.get('date_dependable');
    }

    isChecked() {
        return this.accrediteeProfiles.someHaveGrantedDayOn(this.day) && this.model.hasGrantedDayOn(this.day);
    }

    isDisabled() {
        return !this.accrediteeProfiles.someHaveGrantedDayOn(this.day) || !this.model.get('granted_by_promoter');
    }

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        if (checked) this.model.addGrantedDayOn(this.day);
        else this.model.removeGrantedDayOn(this.day);
    }

    onAddOrRemoveGrantedDay(day) {
        this.renderMaybe(day);
    }

    onUpdateProfiles() {
        this.render();
    }

    onAddProfile(profile) {
        this.addProfileGrantedDaysListener(profile);
    }

    onRemoveProfile(profile) {
        this.removeProfileGrantedDaysListener(profile);
    }

    /**
     * We listen for adds or removes to the profile granted days.
     * If the day that is added or removed is equal to this cell's day we re-render the cell. If a day is added the
     * checkbox will become enabled. If a day is removed the checkbox will become disable
     *
     * @param day
     */
    onAddOrRemoveProfileGrantedDay(day) {
        this.renderMaybe(day);
    }

    /**
     * We listen for an update to the profile granted days. If this model has a granted day on the cell's day
     * we check if our day is still present in anny of the profile granted days. If it is not we remove the day from
     * the granted days.
     *
     */
    onUpdateProfileGrantedDays() {
        if (this.model.hasGrantedDayOn(this.day)) {
            const grantableDays = this.accrediteeProfiles.getAllUniqueGrantedDays();
            if (!grantableDays.get(this.day.id)) this.grantedDays.remove(this.day.id);
        }
    }

    addProfileGrantedDaysListener(profile) {
        this.listenTo(profile.getRelation('granted_days'), 'add remove', this.onAddOrRemoveProfileGrantedDay);
        this.listenTo(profile.getRelation('granted_days'), 'update', this.onUpdateProfileGrantedDays);
    }

    removeProfileGrantedDaysListener(profile) {
        this.stopListening(profile.getRelation('granted_days'));
    }

}
