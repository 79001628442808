import { connect } from 'react-redux';
import { mapAccountContextToProps, mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as Event from 'app/react/entities/events/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Item from 'app/react/entities/events/artists/items/index';
import * as Group from 'app/react/entities/events/artists/item_groups/index';
import * as State from './OverviewState';
import ItemsOverview from './Overview';

const ITEMS_INCLUDES = ['day_totals'];

const mapStateToProps = (state) => {
    const {} = State.getViewState(state);
    const { manageAllArtistItems } = Event.stateHelpers.getUserPermissions(state);
    return {
        ...mapAccountContextToProps(state),
        ...mapEventContextToProps(state),
        isFetching: (
            Day.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Group.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        userCanMangeArtistItems: manageAllArtistItems,
        days: Day.stateHelpers.getAllForCurrentContext(state),
        groups: Group.stateHelpers.getAllForCurrentContext(state),
        items: Item.stateHelpers.getAllByPaginationForCurrentContext(state),
        itemsPagination: Item.stateHelpers.getPaginationForCurrentContext(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onDownloadReport: () => {
        const { eventId } = stateProps;
        dispatchProps.onDownloadReport(eventId);
    },
    onChangeFilter: (key, value) => {
        const { requestedFilters } = stateProps.itemsPagination;
        dispatchProps.onChangeFilter(key, value, requestedFilters)
    }
});

const mapDispatchToProps = (dispatch) => ({
    onDownloadReport: (eventId) => {
    },
    onChangeFilter: (key, value, currentFilters = {}) => {
        const search = '';
        const filters = { ...currentFilters, [key]: value };
        if (value === 0) {
            delete filters[key];
        }
        if (key === 'accommodation') {
            delete filters.roomType
        }
        dispatch(Item.thunks.fetchAllByPaginationForCurrentContext({ search, filters }, ITEMS_INCLUDES));
    },
    onSort: (sortKey, sortOrder) => {
        dispatch(Item.thunks.fetchAllByPaginationForCurrentContext({ sortKey, sortOrder }, ITEMS_INCLUDES));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ItemsOverview);
