import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'artist_type'
})
class ArtistType extends BaseModel {

    static Relations() {
        return {
            event: require('../Event').Model // Circular
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
    }

    urlRoot() {
        this.validateRelations('event');
        const eventId = this.getRelation('event').get('id');
        return `/api/events/${eventId}/artist-types`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }
}

@Collection({
    key: 'artist_types',
    model: ArtistType
})
class ArtistTypeCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        const eventId = this.getRelation('event').get('id');
        return `/api/events/${eventId}/artist-types`;
    }
}

const PageableCollection = ArtistTypeCollection.prototype.PageableCollection();

export {
    ArtistType as Model,
    ArtistTypeCollection as Collection,
    PageableCollection
};
