import React from 'react';
import styled from 'styled-components';
import { withState } from 'recompose';

const SelectableRowStyled = styled.tr`
    &:hover {
        td {
            cursor: pointer;
            background-color: #F5F7FC;
        }
    }
`;

const injectDragState = withState('isDragging', 'setIsDragging', false);
const injectMouseDownState = withState('isMouseDown', 'setIsMouseDown', false);

const SelectableRow = ({
    children,
    isDragging = false,
    isMouseDown = false,
    setIsDragging = () => {},
    setIsMouseDown = () => {},
    onClick = () => {},
}) => (
    <SelectableRowStyled
        onMouseDown={() => {
            setIsMouseDown(true);
        }}
        onMouseMove={() => {
            if (isMouseDown) {
                setIsDragging(true);
            }
        }}
        onMouseUp={e => {
            if (!isDragging) {
                onClick(e.ctrlKey || e.metaKey);
            }
            setIsMouseDown(false);
            setIsDragging(false);
        }}
    >
        {children}
    </SelectableRowStyled>
);

 export default injectDragState(injectMouseDownState(SelectableRow))
