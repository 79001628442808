import BaseView from 'app/backbone/components/Base.view';

export default class UploadedImageView extends BaseView {

    ui() {
        return {
            buttonShow: '.image-detail .remove-upload',
            buttonHide: '.image-upload .undo-remove-upload',
        };
    }

    events() {
        return {
            'click buttonShow': 'showUpload',
            'click buttonHide': 'hideUpload',
        };
    }

    initialize(options = {}) {
        super.initialize(options);
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    showUpload() {
        this.$el.find('.image-upload').removeClass('hide');
        this.$el.find('.image-detail').hide();
        this.$el.find('.image-upload .delete-check').attr('value', 1);
    }

    hideUpload() {
        this.$el.find('.image-upload').addClass('hide');
        this.$el.find('.image-detail').show();
        this.$el.find('.image-upload .delete-check').attr('value', 0);
    }

}

