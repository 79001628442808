import { humanizeDifference } from 'app/react/helpers/_index';
import Services from 'app/services';
import classNames from 'classnames';
import { html } from 'common-tags';
import $ from 'jquery';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import by from 'thenby';
import './Scheduler.css';

export const getChangeoverStart = (start, changeover) => {
    const mStart = moment.utc(start);
    mStart.subtract(changeover, 'minutes');
    return mStart.toISOString();
};

export const removeChangeoverFromStart = (start, changeover) => {
    const mStart = moment.utc(start);
    mStart.add(changeover, 'minutes');
    return mStart.toISOString();
};

const mapStageToFcResource = stage => ({
    id: stage.id,
    title: stage.name,
    sequence: stage.sequence
});

const lowercaseFirstLetter = string => `${string.charAt(0).toLowerCase()}${string.slice(1)}`;

const mapPerformancesToFcEvents = performance => ({
    id: performance.id,
    title: performance.title,
    resourceId: performance.stage,
    start: getChangeoverStart(performance.start, performance.changeover),
    end: performance.end,
    performanceStart: performance.start,
    changeover: performance.changeover || 0,
    editable: !performance.locked,
    className: lowercaseFirstLetter(performance.status.colorType),
    published: performance.published
});

const filterPerformances = (dayStart, dayEnd) => (performance) => {
    if (
        typeof performance.start === 'undefined' ||
        typeof performance.end === 'undefined'
    ) {
        return false;
    }

    if (
        typeof performance.stage === 'undefined' ||
        performance.stage === null
    ) {
        return false;
    }

    const mStart = moment(performance.start);
    const mEnd = moment(performance.end);
    return (
        (
            typeof performance.title !== 'undefined' ||
            (
                typeof performance.bookings !== 'undefined' &&
                typeof performance.bookings[0] !== 'undefined'
            )
        ) &&
        (
            mStart.isAfter(dayStart) ||
            mStart.isSame(dayStart)
        ) &&
        (
            mEnd.isBefore(dayEnd) ||
            mEnd.isSame(dayEnd)
        ) &&
        (
            mStart.isBefore(mEnd) ||
            mEnd.isAfter(mStart)
        )
    );
};

const getFcResourcesFromStages = (stages) =>
    stages.map(mapStageToFcResource).sort(by('sequence'));

const getFcEventsFromPerformances = (performances, start, end) =>
    performances.filter(filterPerformances(start, end)).map(mapPerformancesToFcEvents);

const utilityBarTemplate = (duration, settings) => {
    const { locked, published } = settings;

    return (html`
        <div class="fc-util-bar">
            <span class="fc-duration">
                <i class="bs-icon-clock" style="margin-right: var(--spacingSmallest);" /><span>${duration}</span>
            </span>
            <div class="fc-util-actions">
                <a class="fc-action publish-performance" title="${published ? "Unpublish performance" : "Publish performance"}">
                    <i class="${published ? "bs-icon-eye-slash" : "bs-icon-eye"}"></i>
                </a>
                <a class="fc-action lock-performance" title="${locked ? "Unlock performance" : "Lock performance"}">
                    <i class="${locked ? "bs-icon-lock-square-unlock" : "bs-icon-lock"}"></i>
                </a>
                <a class="fc-action update-performance" title="Update performance">
                    <i class="bs-icon-pencil"></i>
                </a>
                <a class="fc-action delete-performance" title="Remove performance">
                    <i class="bs-icon-cross"></i>
                </a>
            </div>
        </div>
    `)
};

const setTimeTemplate = (start, end, timeFormat) => {
    const mStart = moment.utc(start, moment.ISO_8601);
    const startTime = mStart.format(timeFormat);
    const mEnd = moment.utc(end, moment.ISO_8601);
    const endTime = mEnd.format(timeFormat);
    return html`
        <span class="fc-set-time">${startTime} - ${endTime}</span>
    `;
}

const changeoverTemplate = (width = 0, duration) => html`
    <div class="fc-changeover" style="width: ${width}px">
        ${width >= 60 &&
            html`
                <span class="fc-duration">
                    <i class="bs-icon-expand-horizontal" /><span>${duration}</span>
                </span>
            `
        }
    </div>
`;

const resourceActionsTemplate = (fcResource, resources, $el, resourceClick) => {
    const index = resources.findIndex(resource => resource.id === parseInt(fcResource.id));
    $el.on('click', e => resourceClick(e, fcResource, resources));
    return (html`
        <div class="${classNames("fc-resource-actions-container", { 
            first: index === 0,
            last: index === resources.length - 1
        })}">
            <a class="move-resource-up" title="Move stage up"><i class="bs-icon-arrow-thin-up"></i></a>
            <a class="move-resource-down" title="Move stage down"><i class="bs-icon-arrow-thin-down"></i></a>
        </div>
    `);
}

const reorderResources = (fcResource, resources, move) => {
    let newIndex;
    if (move === "up") {
        newIndex = fcResource.sequence - 2 // - 1 (index) - 1 (move up)
    } else {
        newIndex = fcResource.sequence // - 1 (index) + 1 (move down)
    }
    const resourceToMove = resources.find(resource => resource.id === parseInt(fcResource.id));
    const remainingResources = resources.filter(resource => resource.id !== parseInt(fcResource.id));
    const reorderedResources = [
        ...remainingResources.slice(0, newIndex),
        resourceToMove,
        ...remainingResources.slice(newIndex)
    ]
    return reorderedResources.map((resource, index) => ({
        ...resource,
        sequence: index + 1
    }));
}


export default class Scheduler extends React.Component {

    componentDidMount() {
        this.$target = $(this.refs.target);
        this.renderFullCalender(this.props);
    }

    componentWillReceiveProps(newProps) {
        const { dayId, interval, stages } = newProps;
        const prevStagesSeq = this.props.stages.map(({ sequence }) => sequence)
        const newStagesSeq = stages.map(({ sequence }) => sequence)
        const stagesSequenceChanged = prevStagesSeq.reduce((state, next, index) => {
            return state || next !== newStagesSeq[index]
        }, false)
        if (stagesSequenceChanged) {
            this.renderFullCalender(newProps);
        }
        if (typeof dayId !== 'undefined' && dayId !== this.props.dayId) {
            this.renderFullCalender(newProps);
        }
        if (typeof interval !== 'undefined' && interval !== this.props.interval) {
            this.$target.fullCalendar('option', 'slotDuration', interval);
        }
    }

    componentWillUnmount() {
        this.$target.fullCalendar('destroy');
    }

    resourceClick(e, fcResource, resources) {
        const { onReorderResources } = this.props;
        const $target = $(e.target);
        let reorderedResources;
        if ($target.hasClass('move-resource-up') || $target.parent().hasClass('move-resource-up')) {
            reorderedResources = reorderResources(fcResource, resources, "up");
        } else if ($target.hasClass('move-resource-down') || $target.parent().hasClass('move-resource-down')) {
            reorderedResources = reorderResources(fcResource, resources, "down");
        } else {
            return;
        }
        const reorderedResourcesIds = reorderedResources.map(resource => resource.id)
        onReorderResources(reorderedResourcesIds)
    }

    eventClick(fcEvent, e) {
        const $target = $(e.target);
        const { onToggleLockPerformance, onUpdatePerformance, onViewPerformance, onDeletePerformance, onTogglePublishPerformance } = this.props;
        if ($target.hasClass('lock-performance') || $target.parent().hasClass('lock-performance')) {
            const successCallback = (performance) => {
                fcEvent.editable = !performance.locked;
                this.$target.fullCalendar('updateEvent', fcEvent, true);
            };
            onToggleLockPerformance(fcEvent.id, successCallback);
        } else if ($target.hasClass('publish-performance') || $target.parent().hasClass('publish-performance')) {
            const successCallback = (performance) => {
                fcEvent.published = performance.published;
                this.$target.fullCalendar('updateEvent', fcEvent, true);
            }
            onTogglePublishPerformance(fcEvent.id, successCallback);
        } else if ($target.hasClass('update-performance') || $target.parent().hasClass('update-performance')) {
            const successCallback = (performance) => {
                const newFcEvent = mapPerformancesToFcEvents(performance);
                Object.assign(fcEvent, newFcEvent, { className: [newFcEvent.className] })
                this.$target.fullCalendar('updateEvent', fcEvent, true);
            };
            onUpdatePerformance(fcEvent.id, successCallback);
        } else if ($target.hasClass('delete-performance') || $target.parent().hasClass('delete-performance')) {
            const successCallback = () => {
                this.$target.fullCalendar('removeEvents', fcEvent.id, true);
            };
            onDeletePerformance(fcEvent.id, successCallback);
        } else {
            const successCallback = (performance) => {
                const performanceFcEvent = mapPerformancesToFcEvents(performance);
                fcEvent.className = [performanceFcEvent.className];
                fcEvent.published = performanceFcEvent.published;
                this.$target.fullCalendar('updateEvent', fcEvent, true);
            };
            onViewPerformance(fcEvent.id, successCallback);
        }
    }

    eventDrop(fcEvent, delta, revertCallback) {
        const mStart = fcEvent.start.clone();
        mStart.add(fcEvent.changeover, 'minutes');
        const values = {
            start: mStart.toISOString(),
            end: fcEvent.end.toISOString(),
            stage: parseInt(fcEvent.resourceId, 10)
        };
        this.props.onDropPerformance(fcEvent.id, values, revertCallback);
    }

    eventResize(fcEvent, delta, revertCallback) {
        const mStart = fcEvent.start.clone();
        mStart.add(fcEvent.changeover, 'minutes');
        const values = {
            start: mStart.toISOString(),
            end: fcEvent.end.toISOString()
        };
        this.props.onResizePerformance(fcEvent.id, values, revertCallback);
    }

    selectPeriod(mStart, mEnd, jsEvent, view, resource) {
        const successCallback = (performance) => {
            const fcEvent = mapPerformancesToFcEvents(performance);
            if (fcEvent) this.$target.fullCalendar('renderEvent', fcEvent, true);
        };
        const values = {
            start: mStart.toISOString(),
            end: mEnd.toISOString(),
            stage: parseInt(resource.id, 10),
            day: this.props.dayId
        };
        this.props.onSelectPeriod(values, successCallback);
    }

    renderFullCalender(props) {
        const { dateFormat, timeFormat, interval, start, end, stages, performances, userCanManageSequenceStages } = props;
        const resourceRenderObj = {}
        resourceRenderObj.resourceRender = (fcResource, $el) => {
            if (userCanManageSequenceStages) {
                $el.append(resourceActionsTemplate(fcResource, resources, $el, this.resourceClick.bind(this)));
            }
        }
        const startTime = moment.utc(start);
        const endTime = moment.utc(end);
        const resources = getFcResourcesFromStages(stages);
        this.$target.fullCalendar('destroy');
        this.$target.fullCalendar({
            schedulerLicenseKey: Services.fullcalendarSchedulerKey,
            timezone: 'UTC',
            timeFormat,
            slotLabelFormat: [dateFormat, timeFormat],
            eventConstraint: {
                start,
                end
            },
            visibleRange: {
                start: startTime,
                end: endTime
            },
            defaultDate: start, // Required
            lang: 'en',
            height: 'auto',
            slotDuration: interval,
            slotWidth: 30,
            editable: true,
            aspectRatio: 2,
            selectable: true,
            defaultView: 'beatswitchTimeLine',
            views: {
                beatswitchTimeLine: {
                    type: 'timeline'
                }
            },
            handleWindowResize: true,
            header: false,
            eventResourceField: 'resourceId',
            resourceLabelText: 'Stages',
            resourceAreaWidth: '20%',
            resources: resources,
            ...resourceRenderObj,
            events: getFcEventsFromPerformances(performances, start, end),
            eventRender: (fcEvent, $el, view) => {
                const { changeover } = fcEvent;
                const isLocked = !fcEvent.editable;
                const isPublished = fcEvent.published;
                $el.addClass('fc-scheduler-performance');
                if (isLocked) {
                    $el.addClass('locked');
                    $el.find('.fc-title').prepend('<i class="bs-icon-lock" title="Performance locked" style="margin-right: var(--spacingSmallest);"></i>');
                }
                if (isPublished) {
                    $el.addClass('published');
                    $el.find('.fc-title').prepend('<i class="bs-icon-eye" title="Performance published" style="margin-right: var(--spacingSmallest);"></i>');
                }
                const settings = {
                    locked: isLocked,
                    published: isPublished,
                };
                const duration = fcEvent.performanceStart && fcEvent.end ?
                    humanizeDifference(fcEvent.performanceStart, fcEvent.end.toISOString()) :
                    '';
                const performanceStart = removeChangeoverFromStart(fcEvent.start, fcEvent.changeover);
                $el.find('.fc-content').append(utilityBarTemplate(duration, settings));
                $el.find('.fc-title').append(setTimeTemplate(performanceStart, fcEvent.end.toISOString(), timeFormat));

                if (typeof changeover !== 'undefined' && changeover !== null  && changeover !== 0) {
                    const { slotWidth } = view;
                    const { slotDuration } = view.options;
                    const slotDurationInt = parseInt(slotDuration.substr(slotDuration.length - 2), 10);
                    console.log(changeover, slotDuration, slotWidth)
                    const changeoverWidth = (changeover / slotDurationInt) * slotWidth;
                    $el.prepend(changeoverTemplate(changeoverWidth, `${changeover} M`));
                }
            },
            eventAfterRender: (fcEvent, $el) => {
                const elWidth = $el.find('.fc-content').width();
                if (elWidth < '175') $el.find('.fc-content').addClass('medium');
            },
            eventClick: this.eventClick.bind(this),
            eventDrop: this.eventDrop.bind(this),
            eventResize: this.eventResize.bind(this),
            select: this.selectPeriod.bind(this)
        });
    }

    render() {
        return <div ref="target" className="fc-scheduler" />;
    }
}

Scheduler.defaultProps = {
    timeFormat: 'h:mm',
    dateFormat: 'L',
    interval: '00:15',
    stages: [],
    performances: [],
    onSelectPeriod: () => {},
    onDropPerformance: () => {},
    onResizePerformance: () => {},
    onToggleLockPerformance: () => {},
    onTogglePublishPerformance: () => {},
    onUpdatePerformance: () => {},
    onViewPerformance: () => {},
    onDeletePerformance: () => {}
};

Scheduler.propTypes = {
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    dayId: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string,
    interval: PropTypes.string,
    stages: PropTypes.array,
    performances: PropTypes.array,
    onSelectPeriod: PropTypes.func,
    onDropPerformance: PropTypes.func,
    onResizePerformance: PropTypes.func,
    onLockPerformance: PropTypes.func,
    onUpdatePerformance: PropTypes.func,
    onViewPerformance: PropTypes.func,
    onDeletePerformance: PropTypes.func
};
