import BaseView from 'app/backbone/components/Base.view';

export default class CreateItineraryContactView extends BaseView {

    ui() {
        return {
            contactFromCRM: '#itinerary-contacts-form select[name=contact]',
            generalFields: '#itinerary-contacts-form .general-contact-fields',
        };
    }

    events() {
        return {
            'change contactFromCRM': 'toggleCRMContactFields',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.ui.contactFromCRM.trigger('change');
        return this.delegateEvents();
    }

    toggleCRMContactFields(e) {
        const shouldImportContactFromCRM = e.target.value !== '',
            display = shouldImportContactFromCRM ? 'none' : '';

        this.ui.generalFields.css('display', display);
    }

}


