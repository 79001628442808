import React from 'react';
import { EventStateType } from '../../types/Event';
import HeaderStep from './HeaderStep';

const Header = ({eventState}) => (
    <div className="event-header">
        {eventState.steps.map((step, index) => {
					return <HeaderStep title={step.title} subSteps={step.subSteps} isActive={index === eventState.activeStep} activeSubStep={eventState.activeSubStep} key={index} />
				})}
    </div>
);

Header.propTypes = {
	eventState: EventStateType,
};

export default Header;
