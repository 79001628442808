import {
    createCollectionReducerWithKey
} from 'app/react/state/reducers/higher_order/_index'
import {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY
} from './schemas'
import {
    ALL_TYPES
} from './actions'

const entityReducer = (state = {}) => state;

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};

export const generateCollectionKey = (accountId) => accountId;

const collectionReducer = createCollectionReducerWithKey({
    mapActionToKey: ({ accountId }) =>
        generateCollectionKey(accountId),
    types: ALL_TYPES,
});

export const collectionReducers = {
    [COLLECTION_REDUCER_KEY]: collectionReducer
};

