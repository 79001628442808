var _ = require('underscore'),
    Backbone = require('backbone');

var Modal = require('./Modal.view');

var template = require('./InfoModal.hbs');

var InfoModal = Modal.extend({

    template,

    options: [
        'title',
        'body'
    ],

    initialize(options) {
        this._super(options);
        this.$el.addClass('tiny');
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                title: this.title,
                body: this.body
            }
        }
    },

    open(options = {}) {
        // Set options
        _.each(options, (option, key)=> {
            if(_.has(this, key) && !_.isUndefined(option)) this[key] = option;
        });

        this.render();
        this.show();
    }
});

module.exports = InfoModal;
