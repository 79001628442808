import CompanyItemsAvailableAllDaysCell from '../../../../items/tables/cells/AvailableAllDaysCell.view';

export default class AvailableAllDaysCell extends CompanyItemsAvailableAllDaysCell {

    initialize(options = {}) {
        super.initialize(options);

        this.availableDays = this.model.getRelation('available_days');
        this.profileAvailableDays = this.model.getRelation('profile').getRelation('available_days');

        // Listeners
        this.listenTo(this.availableDays, 'update reset', this.render);
        this.listenTo(this.profileAvailableDays, 'update reset', this.render);
    }

    isDisabled() {
        const availableDays = this.getAvailableDays();
        return !availableDays.length;
    }

    isChecked() {
        const availableDays = this.getAvailableDays();
        if (!availableDays.length) return false;
        return this.model.hasAvailableDayOnAll(availableDays);
    }

    isEditing() {
        return true;
    }

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        const availableDays = this.getAvailableDays();
        if (checked) this.model.addAvailableDayOnAll(availableDays);
        else this.model.removeAvailableDayOnAll(availableDays);
        this.render();
    }

    getAvailableDays() {
        return this.profileAvailableDays;
    }

}

