import React from 'react'
import PropTypes from 'prop-types'
import {
    AlertBox,
    Card,
    PaginationBar,
} from 'ui-kit'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import { TableLoadingPlaceholder } from 'app/react/components/_index'
import ReservationsContentHeader, { OVERVIEW_TAB_ID } from '../../components/layout/ContentHeaderContainer'
import ReservationsTable from './components/table/Table'
import ReservationsTableTopBar from './components/table/TopBar'
import DeleteModal from './components/modals/stateful/DeleteModalContainer'
import StatusModal from './components/modals/stateful/ReviewStatusModalContainer'

const { Content } = ContentWrapper;
const { Header } = Card;
const { Title } = Header;

const CARD_TITLE = 'Overview';
const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';

const ReservationsOverview = ({
    store,
    userCanManageAccommodations = false,
    timeFormat,
    dateFormat,

    isFetching = false,
    days = [],
    accommodations = [],
    roomTypes = [],
    reservations = [],
    reservationsPagination = {},

    onDownloadReport = () => {},
    onChangePageSize = () => {},
    onChangeFilter = () => {},
    onChangeSearchValue = () => {},
    onSearch = () => {},
    onClearSearch = () => {},
    onSort = () => {},
    onChangePage = () => {},
    onToggleReservationCheckIn = () => {},
    onOpenStatusModal = () => {},
    onEditReservation = () => {},
    onOpenDeleteModal = () => {},
}) => {
    const {
        isFetching: isFetchingReservations = false,
        requestedSortKey,
        requestedSortOrder,
        requestedPageSize = 100,
        requestedFilters = {},
        requestedSearch,
        requestedPage,
        totalPages,
        totalCount
    } = reservationsPagination;
    return (
        <ContentWrapper store={store}>
            <ReservationsContentHeader
                activeTab={OVERVIEW_TAB_ID}
                onDownloadReport={onDownloadReport}
            />
            <Content isLoading={isFetching}>
                {!userCanManageAccommodations
                    ? <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox>
                    : <Card>
                        <Header>
                            <Title>{CARD_TITLE}</Title>
                        </Header>
                        <Card.Content>
                            <ReservationsTableTopBar
                                dateFormat={dateFormat}
                                days={days}
                                accommodations={accommodations}
                                roomTypes={roomTypes}
                                selectedCheckIn={requestedFilters.checkIn}
                                selectedAccommodationId={requestedFilters.accommodation}
                                selectedRoomTypeId={requestedFilters.roomType}
                                searchValue={requestedSearch}
                                onChangeFilter={onChangeFilter}
                                onChangeSearchValue={onChangeSearchValue}
                                onSearch={onSearch}
                                onClearSearch={onClearSearch}
                            />
                            {isFetchingReservations
                                ? <TableLoadingPlaceholder />
                                : <ReservationsTable
                                    reservations={reservations}
                                    sortKey={requestedSortKey}
                                    sortOrder={requestedSortOrder}
                                    timeFormat={timeFormat}
                                    dateFormat={dateFormat}
                                    onSort={onSort}
                                    onChangeCheckIn={onToggleReservationCheckIn}
                                    onClickReviewReservation={onOpenStatusModal}
                                    onEditReservation={onEditReservation}
                                    onDeleteReservation={onOpenDeleteModal}
                                />
                            }
                            <PaginationBar
                                page={requestedPage}
                                pageSize={requestedPageSize}
                                totalPages={totalPages}
                                totalCount={totalCount}
                                onChangePage={onChangePage}
                                onChangePageSize={onChangePageSize}
                            />
                        </Card.Content>
                    </Card>
                }
            </Content>
            <StatusModal />
            <DeleteModal />
        </ContentWrapper>
    )
};

ReservationsOverview.propTypes = {
    store: PropTypes.object,
    userCanManageAccommodations: PropTypes.bool,
    timeFormat: PropTypes.string,
    dateFormat: PropTypes.string,
    isFetching: PropTypes.bool,
    reservations: PropTypes.array,
    days: PropTypes.array,
    accommodations: PropTypes.array,
    roomTypes: PropTypes.array,
    reservationsPagination: PropTypes.object,
    onChangePageSize: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeSearchValue: PropTypes.func,
    onSearch: PropTypes.func,
    onClearSearch: PropTypes.func,
    onSort: PropTypes.func,
    onChangePage: PropTypes.func,
    onToggleReservationCheckIn: PropTypes.func,
    onOpenStatusModal: PropTypes.func,
    onEditReservation: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
};

export default ReservationsOverview;
