import { DragDropContext as dragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { debounce } from 'underscore';
import {
    getFormFieldErrorsFromResponseJSON
} from 'app/react/helpers/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import PagesOverview from './Overview';
import { PAGE_MODAL_ID, CONFIRM_MODAL_ID } from '../../../common/pages/views/Content';
import * as PWSettings from 'app/react/entities/events/production_website/settings/general/index';
import * as PWPage from 'app/react/entities/events/production_website/pages/index';

export const POPUP_MESSAGES = {
    FAILED: 'Failed to save page.',
    CREATED: page => `Page <strong>${page}</strong> created.`,
    UPDATED: page => `Page <strong>${page}</strong> updated.`
};

const mapStateToProps = (state) => {
    const pwSettings = PWSettings.stateHelpers.getFirst(state) || {};
    return {
        eventId: stateHelpers.events.getCurrentId(state),
        productionWebsiteUrl: pwSettings.url,
        pages: PWPage.stateHelpers.getAllSortedWithSortedChildren(state),
        isFetching: PWPage.stateHelpers.isFetching(state),
        confirmModalState: stateHelpers.getComponentState(state, CONFIRM_MODAL_ID),
        modalState: stateHelpers.getComponentState(state, PAGE_MODAL_ID)
    }
};

const mapDispatchToProps = (dispatch) => ({
    onCreate: () => {
        const modalState = {
            values: {},
            isFormPage: false,
            isHomePage: false,
            hasChildren: false,
            isCreating: true
        };
        dispatch(actions.mergeComponentState(PAGE_MODAL_ID, modalState));
        dispatch(actions.openModal(PAGE_MODAL_ID));
    },
    onUpdate: (values) => {
        const { isFormPage, isHomePage, children } = values;
        const modalState = {
            values, isFormPage, isHomePage, hasChildren: !!children.length, isCreating: false
        };
        dispatch(actions.mergeComponentState(PAGE_MODAL_ID, modalState));
        dispatch(actions.openModal(PAGE_MODAL_ID));
    },
    onConfirmModalClosed: () => {
        dispatch(actions.closeConfirmModal(CONFIRM_MODAL_ID));
    },
    onConfirmModalConfirmed: (action) => {
        dispatch(actions.setModalSyncing(CONFIRM_MODAL_ID, true));
        dispatch(action).then(() => {
            dispatch(actions.setModalSyncing(CONFIRM_MODAL_ID, false));
            dispatch(actions.closeConfirmModal(CONFIRM_MODAL_ID));
        });
    },
    onModalClosed: () => {
        dispatch(actions.closeModal(PAGE_MODAL_ID));
    },
    onModalChangeFormValue: (key, value) => {
        dispatch(actions.setComponentStateValue(PAGE_MODAL_ID, `values.${key}`, value));
    },
    onModalSave: (eventId, formValues, pages) => {
        dispatch(actions.setModalSyncing(PAGE_MODAL_ID, true));
        const isCreating = typeof formValues.id === 'undefined';

        const pageId = formValues.id;
        const page = formValues;
        if (isCreating) page.sequence = pages.length;
        else delete page.id;

        const action = isCreating ?
            PWPage.actions.createOne(eventId, page, 'accreditation') :
            PWPage.actions.updateOne(eventId, pageId, page.parentId, page, 'accreditation');

        dispatch(action).then(({ error }) => {
            if (error) {
                const errors = getFormFieldErrorsFromResponseJSON(error);
                dispatch(actions.mergeComponentState(PAGE_MODAL_ID, { errors }));
                dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED));
            } else {
                dispatch(actions.closeModal(PAGE_MODAL_ID));
                const popupMessage = isCreating ?
                    POPUP_MESSAGES.CREATED(page.name) :
                    POPUP_MESSAGES.UPDATED(page.name);
                dispatch(actions.showSuccessPopup(popupMessage));
                dispatch(actions.mergeComponentState(PAGE_MODAL_ID, { errors: {} }));
            }
            dispatch(actions.setModalSyncing(PAGE_MODAL_ID, false));
        });
    },
    onDelete: (eventId, pageId, parentId) => {
        const action = PWPage.actions.deleteOne(eventId, pageId, parentId, 'accreditation');
        dispatch(actions.openConfirmModal(CONFIRM_MODAL_ID, action));
    },
    updatePageSequence: (dragId, hoverId, dragParentId, hoverParentId, isOverTopArea, isOverMiddleArea, isOverBottomArea) => {
        dispatch(PWPage.actions.updatePageSequence(
            dragId,
            hoverId,
            dragParentId,
            hoverParentId,
            isOverTopArea,
            isOverMiddleArea,
            isOverBottomArea
        ));
        PWPage.actions.updateAllIfChangedDebounced(dispatch, 'accreditation');
    },
    dropOutsideList: (dragId, parentId) => {
        dispatch(PWPage.actions.dropOutsideList(dragId, parentId));
        PWPage.actions.updateAllIfChangedDebounced(dispatch, 'accreditation');
    },
    updateHoverState: (dragId, hoverId, dragParentId, hoverParentId, isOverTopArea, isOverMiddleArea, isOverBottomArea) => {
        dispatch(PWPage.actions.updateHoverState(
            dragId,
            hoverId,
            dragParentId,
            hoverParentId,
            isOverTopArea,
            isOverMiddleArea,
            isOverBottomArea
        ));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onModalSave: () => dispatchProps.onModalSave(stateProps.eventId, stateProps.modalState.values, stateProps.pages),
    onUpdate: (id, parentId) => {
        const isChildPage = typeof parentId !== 'undefined';
        if (isChildPage) {
            const { children } = stateProps.pages.find(page => page.id === parentId);
            return dispatchProps.onUpdate(children.find(child => child.id === id));
        }
        return dispatchProps.onUpdate(stateProps.pages.find(page => page.id === id));
    },
    onDelete: (id, parentId) => dispatchProps.onDelete(stateProps.eventId, id, parentId),
    onConfirmModalConfirmed: () => dispatchProps.onConfirmModalConfirmed(stateProps.confirmModalState.action)

});

export default dragDropContext(
    HTML5Backend
)(connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PagesOverview));
