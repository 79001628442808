import _ from 'underscore';
import { Model, Collection }  from 'app/backbone/models/decorators';
import * as Profile from '../../settings/accreditation/Profile'
import * as AvailableDay from '../../settings/accreditation/AvailableDay';
import * as CompanyProfileItem from './CompanyProfileItem';

@Model({
    key: 'company_profile'
})
class CompanyProfile extends Profile.Model {

    static Relations() {
        return {
            event: Profile.Model.Relations().event,
            days: Profile.Model.Relations().days,
            available_days: AvailableDay.Collection,
            available_items: CompanyProfileItem.Collection
        }
    }

    get defaults() {
        return {
            total_available: 0,
            total_max: 0,
            total_used: 0
        }
    }

    get validation() {
        return {
            id: {
                required: true,
                msg: 'Select a profile.'
            }
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        // Listeners to set the total available and max profiles
        this.listenTo(this.available_days, 'reset change:available', this.resetTotalAvailable);
        this.listenTo(this.available_days, 'reset change:max', this.resetTotalMax);
        this.listenTo(this.available_days, 'reset change:used', this.resetTotalUsed);
        this.resetTotalAvailable();
        this.resetTotalMax();
        this.resetTotalUsed();
    }

    toJSON(options) {
        let json = super.toJSON(options);
        delete json.days;
        if(this.hasRelation('available_days')) json.available_days = this.available_days.toJSON();
        if(this.hasRelation('available_items')) json.available_items = this.available_items.toJSON();
        return json;
    }

    isAvailable() {
        return this.getRelation('available_days').some(day => day.get('available') > 0)
    }

    isAvailableOn(day) {
        return this.hasAvailableDayOn(day) && this.getAvailableDayOn(day).get('available') > 0;
    }

    hasAvailableDayOn(day) {
        return this.getAvailableDayOn(day) ? true : false;
    }

    getAvailableDayOn(day) {
        return this.available_days.get(day.id);
    }

    getAllGrantableDays() {
        return new AvailableDay.Collection(this.available_days.filter(day => day.get('available') > 0));
    }

    getMaxAvailableOn(day) {
        return this.hasAvailableDayOn(day) ? this.getAvailableDayOn(day).get('max') : ''
    }

    setMaxAvailableOnAll(days, max = 0) {
        days.each((day) => this.setMaxAvailableOn(day, max));
    }

    setMaxAvailableOn(day, max = 0) {
        max = _.isNaN(max) ? 0 : max;
        if (max === 0) this.available_days.remove(day.id);
        else if (this.hasAvailableDayOn(day)) this.getAvailableDayOn(day).set('max', max);
        else this.available_days.add(day.toAvailableDay({ max }));
    }

    resetTotalAvailable() {
        this.set('total_available', this.available_days.getTotalAvailable() )
    }

    resetTotalMax() {
        this.set('total_max', this.available_days.getTotalMax() )
    }

    resetTotalUsed() {
        this.set('total_used', this.available_days.getTotalUsed() )
    }

    hasItemsWithNoAvailableDay() {
        return this.getRelation('available_items').someHaveNoAvailableDay()
    }

    hasItemWithAvailableDayOn(item, day) {
        const availableItem = this.getRelation('available_items').get(item.id);
        if (availableItem) return availableItem.hasAvailableDayOn(day);
        return false;
    }
}

@Collection({
    key: 'company_profiles',
    model: CompanyProfile
})
class CompanyProfileCollection extends Profile.Collection {

    someAreAvailable() {
        return this.some(profile => profile.isAvailable());
    }

    someHaveNoAvailableDay() {
        return this.some(profile => profile.getRelation('available_days').isEmpty());
    }

    someHaveItemsWithNoAvailableDay() {
        return this.some(profile => profile.hasItemsWithNoAvailableDay());
    }

    getTotalAvailable() {
        return this.getTotal('available');
    }

    getTotalMax() {
        return this.getTotal('max');
    }

    getTotalUsed() {
        return this.getTotal('used');
    }

    getTotal(attr) {
        let total = 0;
        this.each(profile => { total += profile.get('total_' + attr) });
        return total;
    }
}

const PageableCollection = CompanyProfileCollection.prototype.PageableCollection();

export {
    CompanyProfile as Model,
    CompanyProfileCollection as Collection,
    PageableCollection
};
