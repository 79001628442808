import { schema } from 'normalizr';

export const ICON_MAPPINGS = {
    1: 'bs-icon-plane',
    2: 'bs-icon-train',
    3: 'bs-icon-car',
    4: 'bs-icon-road',
    5: 'bs-icon-ship',
    6: 'bs-icon-road',
    7: 'bs-icon-road',
    8: 'bs-icon-plane',
    9: 'bs-icon-bus',
    10: 'bs-icon-helicopter',
};

export const ENTITY_REDUCER_KEY = 'transports';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const processStrategy = (entity) => ({
    ...entity,
    modeIcon: ICON_MAPPINGS[entity.mode.id]
});

const entityDefinition = {};
const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);
