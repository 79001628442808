import * as stateHelpers from 'app/react/state/helpers'
import * as actions from 'app/react/state/actions/_index'

export const TABLE_ID = 'reservationsDashboardTable';

export const getTableState = (state) =>
    stateHelpers.getComponentState(state, TABLE_ID) || {};

export const mergeTableState = (newState) =>
    actions.mergeComponentState(TABLE_ID, newState);

export const setTableStateValue = (key, value) =>
    actions.setComponentStateValue(TABLE_ID, key, value);

export const mergeTableStateValue = (key, newState) =>
    actions.mergeComponentStateValue(TABLE_ID, key, newState);

export const deleteTableStateValue = (key) =>
    actions.deleteComponentStateValue(TABLE_ID, key);
