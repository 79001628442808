var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "\n        <div class=\"input-group\">\n            <label class=\"required\">Company</label>\n            <select name=\"company\"></select>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"grid-cell input-group\">\n                <label class=\"required\">First name</label>\n                <input type=\"text\" name=\"firstname\"/>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"grid-cell input-group\">\n                <label class=\"required\">Last name</label>\n                <input type=\"text\" name=\"lastname\"/>\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "\n        <div class=\"grid input-group\">\n            <label class=\"required\">Email</label>\n            <input type=\"text\" name=\"email\"/>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "\n        <div class=\"grid input-group\">\n            <label>Telephone</label>\n            <input type=\"text\" name=\"telephone\"/>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<form>\n    "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"company",{"name":"inArray","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    <div class=\"grid grid--gutters no-margin-bottom\">\n        "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"firstname",{"name":"inArray","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "        "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"lastname",{"name":"inArray","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n    "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"email",{"name":"inArray","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "    "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"telephone",{"name":"inArray","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "</form>\n";
},"useData":true});