const CheckboxCell = require('app/backbone/components/tables/cells/CheckboxCell.view');
const template = require('./GrantedDayCell.hbs');

const GrantedDayCell = CheckboxCell.extend({

    template,

    className: 'granted-day-cell input center',

    events: {
        'change input[type=text]': 'onChangeInput',
        'change input[type=checkbox]': 'onChangeCheckbox'
    },

    initialize(options = {}) {
        this._super(options);

        // Props
        this.grantedDays = this.model.getRelation('granted_days');

        // listeners
        this.listenTo(this.grantedDays, 'add remove', this.onAddOrRemoveGrantedDay);
    },

    renderMaybe(day) {
        if (day.id === this.day.id) this.render();
    },

    viewModel() {
        return {
            view: {
                isDisabled: this.isDisabled(),
                isVisible: this.isVisible(),
                isChecked: this.isChecked(),
                isEditing: this.isEditing(),
                usesAmount: this.usesAmount(),
                amount: this.getAmount()
            }
        };
    },

    isChecked() {
        return this.model.hasGrantedDayOn(this.day);
    },

    isDisabled() {
        return !this.model.hasAvailableDayOn(this.day);
    },

    isVisible() {
        return this.model.get('date_dependable');
    },

    usesAmount() {
        return this.model.get('uses_amounts');
    },

    getAmount() {
        return this.model.getAmountGrantedOn(this.day);
    },

    isEditing() {
        return this.editing || false;
    },

    onChangeInput(e) {
        const amount = this.$(e.currentTarget).val();
        this.model.setAmountGrantedOn(this.day, amount);
        this.render();
    },

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        if (checked) this.model.addGrantedDayOn(this.day);
        else this.model.removeGrantedDayOn(this.day);
        this.render();
    },

    onAddOrRemoveGrantedDay(day) {
        this.renderMaybe(day);
    }
});

module.exports = GrantedDayCell;
