import React from 'react';
import PropTypes from 'prop-types';
import { EventStateType } from '../types/Event';
import Header from '../components/header/Header';
import TipsContainer from '../components/tips/TipsContainer';

const CreateEvent = ({ setActiveStep, eventState, setVenues }) => {
	const { steps, activeStep, activeSubStep } = eventState;
	const subStep = steps[activeStep].subSteps.find(
		({ id }) => id === activeSubStep
	);
	return (
		<div className='create-event-container'>
			<div className='create-event-container__left'>
				<Header eventState={eventState} />
				<subStep.form
					eventState={eventState}
					setActiveStep={setActiveStep}
					activeSubStep={activeSubStep}
					setVenues={setVenues}
				/>
			</div>
			{subStep.tips && <TipsContainer tips={subStep.tips} />}
		</div>
	);
};

CreateEvent.propTypes = {
	eventState: EventStateType,
	setActiveStep: PropTypes.func,
	setVenues: PropTypes.func,
};

export default CreateEvent;
