import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import { loadData } from 'app/react/helpers/_index';
import EventItemsView from 'app/react/views/event/settings/accreditation/items/EventItemsView';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';

// Backbone Controller
export default class EventItemController extends BaseController {

    overview(eventId) {
        const { app, data, store } = this;
        const { event } = data.registerCurrentEventAndAccount(eventId);
        const items = data.register('pagedEventItems', EventItem.PageableCollection, { event });
        const itemGroups = data.register('pagedEventItemGroups', EventItemGroup.PageableCollection, { event });
        items.setIncludes(['groups']).setSortKey('item_groups.name');

        app.renderContentComponent(React.createElement(EventItemsView, { items, itemGroups, store }));
        loadData({ models: [items, itemGroups] });
    }

}
