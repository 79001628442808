import React from 'react'
import './ActiveBadge.module.css'

const ActiveBadge = ({ text }) => (
    <i
        styleName="badge"
        className="bs-icon-check-round"
    />
);

export default ActiveBadge;
