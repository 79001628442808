var TextInputCell = require('app/backbone/components/tables/cells/TextInputCell.view');

var AvailableDayEditCell = TextInputCell.extend({

    className: 'text-input-cell input center',

    isDisabled() {
        return !this.model.hasDayOn(this.day);
    },

    getValue() {
        return this.model.getMaxAvailableOn(this.day);
    },

    onChangeInput(e) {
        var max = parseInt(this.$(e.currentTarget).val());
        this.model.setMaxAvailableOn(this.day, max);
        this.render();
    }
});

module.exports = AvailableDayEditCell;
