import React from 'react';
import { FileUpload as UIKitFileUpload } from 'ui-kit';
import { FileInfo } from 'ui-kit/lib/components/input/FileInput';
import { uploadFile, getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';

const upload = async (
    selectedFile,
    fileType,
    onUploadSuccess,
    onUploadFailed,
    onChange,
) => {
    uploadFile(selectedFile, fileType).then((responseJSON) => {
        const { data, errors } = responseJSON;
        if (errors) {
            const errors = getFormFieldErrorsFromResponseJSON(responseJSON);
            const firstKey = Object.keys(errors)[0]
            const message = errors[firstKey][0].text;
            onUploadFailed(errors);
            onChange();
        } else {
            onUploadSuccess(data);
            onChange(data);
            return data;
        }
    });
};

const FileUpload = (props) => {
    const {
        fileType = 'passport',
        onUploadSuccess = () => {},
        onUploadFailed = () => {},
        onChange = () => {},
    } = props;
    return (
        <UIKitFileUpload
            {...props}
            upload={(selectedFile) =>
                upload(selectedFile, fileType, onUploadSuccess, onUploadFailed, onChange)
            }
        />
    );
};

FileUpload.FileInfo = FileInfo;

export default FileUpload;
