import BaseView from 'app/backbone/components/Base.view';

export default class CreateLocationView extends BaseView {

    ui() {
        return {
            locationFormCRM: '#location-form select[name=location]',
            addToCRM: '.add-to-crm',
            generalFields: '#location-form .location-general-fields',
            editGeneralInfo: '.edit-general-info',
        };
    }

    events() {
        return {
            'change locationFormCRM': 'toggleGeneralInfo',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.ui.locationFormCRM.trigger('change');
        return this.delegateEvents();
    }

    toggleGeneralInfo(e) {
        const shouldImportLocationFromCRM = e.target.value !== '',
            displayCRMFields = shouldImportLocationFromCRM ? 'none' : '',
            displayGeneralInfo = shouldImportLocationFromCRM ? '' : 'none';

        this.ui.addToCRM.css('display', displayCRMFields);
        this.ui.generalFields.css('display', displayCRMFields);
        this.ui.editGeneralInfo.css('display', displayGeneralInfo);
    }

}


