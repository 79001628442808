import React, { useState, useEffect } from "react";
import dragula from "dragula";
import PropTypes from "prop-types";
import PageFooter from "../../footer/Footer";
import { useHTTP } from "../../../hooks/UseHTTP";
import PagesDialog from "../../atoms/dialogs/PagesDialog";
import Button from "../../atoms/button/Button";
import Icon from "../../../utils/Icon";
import DeleteDialog from "../../atoms/dialogs/DeleteDialog";
import FeedbackMessage from "../../atoms/feedback/Feedback";

const mapPages = (pages) => {
  return pages
    .map((pageToMap) => ({
      slug: pageToMap.slug,
      homepage: pageToMap.homepage,
      name: pageToMap.name,
      body: pageToMap.body,
      form_page: pageToMap.form_page,
      sequence: pageToMap.sequence,
      artist_type_pages: pageToMap.artist_type_pages.data,
      id: pageToMap.id,
      children: pageToMap.children && pageToMap.children.data ? mapPages(pageToMap.children.data) : [],
    }))
    .sort((a, b) => {
      return a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0;
    });
};

const getOrder = (childConfig) => {
  const container = document.getElementById("pages-table-body");
  const body = [...container.children].map((el, i) => {
    const children = el.getElementsByClassName("pages-row__children-inner");
    const childComps = children
      ? [...children].map((child, childIndex) => ({
          id: parseInt(child.id),
          sequence: childIndex,
          children: [],
        }))
      : [];
    if (childConfig && i === childConfig.parentIndex) {
      childComps.push({
        id: parseInt(childConfig.id),
        sequence: children.length,
        children: [],
      });
    }
    return {
      id: parseInt(el.id),
      sequence: i,
      children: childComps,
    };
  });
  return body;
};

export const ProductionPagesForm = ({
  setActiveStep,
  activeSubStep,
  eventState,
}) => {
  const { doHttpCallJson: doHttpCall } = useHTTP();
  const [error, setError] = useState(undefined);
  const [pageToEdit, setPageToEdit] = useState(undefined);
  const [pageToRemove, setPageToRemove] = useState(undefined);
  const [parentIndex, setParentIndex] = useState(undefined);
  const [artistTypes, setArtistTypes] = useState(
    eventState.event.artistTypes || []
  );
  const [productionPages, setProductionPages] = useState(
    eventState.event.pages ? eventState.event.pages : []
  );

  const drake = dragula([]);

  const updateSequence = (body) =>
    doHttpCall(
      `/api/events/${eventState.event.id}/production-website/artists/pages/sequences`,
      "PUT",
      body
    )
      .then((res) => {
        if (res && res.data) {
          return res.data;
        }
      })
      .catch((error) => {
        console.error("Updating page sequence:: ", error);
        setError("Something went wrong while updating the order of the pages");
      });

  drake.on("drop", async (el, target) => {
    const body = getOrder();
    const sequenceRes = await updateSequence(body);
    if (sequenceRes && sequenceRes.length > 0) {
      setProductionPages(mapPages(sequenceRes));
    }
  });

  const getPages = (id) =>
    doHttpCall(
      `/api/events/${id}/production-website/artists/pages?limit=all`,
      "GET"
    )
      .then((pagesRes) => {
        if (pagesRes && pagesRes.data) {
          return pagesRes;
        }
      })
      .catch((err) => {});
  useEffect(() => {
    const getArtistTypes = async () => {
      doHttpCall(
        `/api/events/${
          eventState.event.oldEventId
            ? eventState.event.oldEventId
            : eventState.event.id
        }/artist-types?limit=all`,
        "GET"
      )
        .then((artistTypesRes) => {
          if (artistTypesRes && artistTypesRes.data) {
            setArtistTypes(artistTypesRes.data);
          }
        })
        .catch((err) => {
          console.error("Fetching artist types:: ", error);
          setError(
            "Something went wrong while loading the artist types related to your event."
          );
        });
    };
    if (
      (!eventState.event.pages || eventState.event.pages.length === 0) &&
      eventState.event.oldEventId
    ) {
      getPages(eventState.event.id).then((newPageRes) => {
        const promises = newPageRes.data.filter((page) => !page.form_page).map((page) =>
          Promise.resolve(
            doHttpCall(
              `/api/events/${eventState.event.id}/production-website/artists/pages/${page.id}`,
              "DELETE"
            )
          )
        );
        Promise.all(promises)
          .then((deleteRes) => {
            getPages(eventState.event.oldEventId)
              .then((pagesRes) => {
                const childParentMap = {};
                if (pagesRes && pagesRes.data) {
                  const pagesWithoutId = pagesRes.data.filter((page) => !page.form_page).reduce((prev, page) => {
                    const newPage = {
                      ...page,
                      artist_type_pages: page.artist_type_pages.data,
                      slug: page.slug,
                    };
                    delete newPage.id;
                    if (
                      newPage.children &&
                      newPage.children.data &&
                      newPage.children.data.length > 0
                    ) {
                      newPage.children.data.forEach((child) => {
                        const newChild = {
                          ...child,
                          artist_type_pages: child.artist_type_pages.data,
                          slug: child.slug,
                        };
                        delete newChild.id;
                        delete newChild.children;
                        childParentMap[newChild.slug] = newPage.slug;
                        prev.push(newChild);
                      });
                    }
                    delete newPage.children;
                    prev.push(newPage);
                    return prev;
                  }, []);
                  batchCreatePages(pagesWithoutId, childParentMap);
                }
              })
              .catch((error) => {
                console.error("Fetching pages:: ", error);
                setError("Something went wrong while loading the pages.");
              });
          })
          .catch((error) => {
            console.error("delete standard pages:: ", error);
          });
      });
    } else {
      getPages(eventState.event.id).then((pageRes) => {
        if (pageRes && pageRes.data) {
          setProductionPages(mapPages(pageRes.data));
        }
      });
    }
    if (
      !eventState.event.artistTypes ||
      eventState.event.artistTypes.length === 0
    ) {
      getArtistTypes();
    }
  }, []);

  useEffect(() => {
    if (document.getElementById("pages-table-body")) {
      const table = document.getElementById("pages-table-body");
      drake.containers.push(table);
    }
  }, []);

  const goToNextStep = (step) => {
    const rows = document.getElementsByClassName("form__table-row");
    const ids = Array.from(rows, (element) => element.id.split("-")[1]);
    const sortedpages = ids.map((id) =>
      productionPages.find((pages) => pages.id === parseInt(id))
    );
    setActiveStep(step, { pages: sortedpages });
  };

  const batchCreatePages = async (pages, parentChildMap) => {
    const promises = pages.map((page) =>
      Promise.resolve(
        doHttpCall(
          `/api/events/${eventState.event.id}/production-website/artists/pages`,
          "POST",
          page
        )
      )
    );
    Promise.all(promises)
      .then((values) => {
        const savedPages = values.map((page) => page.data);
        const sequences = savedPages.reduce((prev, current) => {
          const parentSlug = parentChildMap[current.slug];
          if (parentSlug) {
            const parentPage = savedPages.find(
              (page) => page.slug === parentSlug
            );
            const usedIndex = prev.findIndex(
              (processed) => processed.id === parentPage.id
            );
            if (usedIndex === -1) {
              prev.push({
                id: parseInt(parentPage.id),
                sequence: parentPage.sequence,
                children: [
                  { id: parseInt(current.id), sequence: 0, children: [] },
                ],
              });
            } else {
              prev[usedIndex].children.push({
                id: parseInt(current.id),
                sequence: prev[usedIndex].children.length,
                children: [],
              });
            }
          } else {
            const usedIndex = prev.findIndex(
              (processed) => processed.id === current.id
            );
            if (usedIndex === -1) {
              prev.push({
                id: parseInt(current.id),
                sequence: current.sequence,
                children: [],
              });
            }
          }
          return prev;
        }, []);
        return updateSequence(sequences);
      })
      .then((sequenceRes) => {
        if (sequenceRes && sequenceRes.length > 0)
        setProductionPages(mapPages(sequenceRes));
      })
      .catch((error) => {
        setError(
          "Something went wrong while copying the pages from the previous event"
        );
        console.error("batch create pages:: ", error);
      });
  };

  const createOrUpdatePage = async (body) => {
    if (body.id) {
      doHttpCall(
        `/api/events/${eventState.event.id}/production-website/artists/pages/${body.id}`,
        "PUT",
        body
      )
        .then((pageRes) => {
          if (pageRes && pageRes.data && pageRes.data.id) {
            const formattedPage = mapPages([pageRes.data])[0];
            setProductionPages((prev) =>
              prev.map((oldPage) =>
                oldPage.id === formattedPage.id ? formattedPage : oldPage
              )
            );
            setPageToEdit(undefined);
          }
        })
        .catch((errors) => {
          console.error("Updating page:: ", errors);
          setError("Something went wrong while updating the page");
        });
    } else if (typeof parentIndex !== "number") {
      doHttpCall(
        `/api/events/${eventState.event.id}/production-website/artists/pages`,
        "POST",
        body
      )
        .then((pageRes) => {
          if (pageRes && pageRes.data && pageRes.data.id) {
            const formattedPage = mapPages([pageRes.data])[0];
            setProductionPages((prev) => [...prev, formattedPage]);
            setPageToEdit(undefined);
          }
        })
        .catch((errors) => {
          console.error("Saving page:: ", errors);
          setError("Something went wrong while saving the page");
        });
    } else {
      doHttpCall(
        `/api/events/${eventState.event.id}/production-website/artists/pages`,
        "POST",
        body
      )
        .then((pageRes) => {
          if (pageRes && pageRes.data && pageRes.data.id) {
            const body = getOrder({
              parentIndex: parentIndex,
              id: pageRes.data.id,
            });
            return updateSequence(body);
          }
        })
        .then((sequenceRes) => {
          setProductionPages(mapPages(sequenceRes));
          setPageToEdit(undefined);
          setParentIndex(undefined);
        })
        .catch((errors) => {
          console.error("Saving page:: ", errors);
          setError("Something went wrong while saving the page");
        });
    }
  };

  const removePage = (page) => {
    doHttpCall(
      `/api/events/${eventState.event.id}/production-website/artists/pages/${page.id}`,
      "DELETE"
    )
      .then((deleteRes) => {
        if (deleteRes && deleteRes.status === 204) {
          return getPages(eventState.event.id);
        }
      })
      .then((res) => {
        if (res && res.data) {
          setProductionPages(mapPages(res.data));
          setPageToRemove(undefined);
        }
      })
      .catch((errors) => {
        console.error("Removing page:: ", errors);
        setError("Something went wrong while removing the page");
      });
  };

  return (
    <div className="form_content">
      {error && (
        <FeedbackMessage
          text={error}
          variant="error"
          closeFeedback={() => setError("")}
        />
      )}
      <h2>Production site pages</h2>
      <h3 className="form-sub-title">Finalise your event</h3>
      <span>Edit the default production site pages or add new ones.</span>
      <div className="form__table">
        <div id="pages-table-body">
          {productionPages.map((page, index) => {
            return (
              <div className="pages-row" key={page.id} id={page.id}>
                <div className="pages-row__title">
                  <div className="form__table__front-button">
                    <button className="form__table__button">
                      <Icon
                        size={26}
                        name="dragAndDrop"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                  </div>
                  <div className="name-cell">{page.name}</div>
                  <div className="form__table__last-column">
                    {!page.form_page && !page.homepage && (
                      <button
                        className="form__table__button"
                        onClick={() => {
                          setParentIndex(index);
                          setPageToEdit({
                            slug: "",
                            homepage: false,
                            name: "",
                            body: "",
                            children: [],
                            form_page: false,
                            sequence: productionPages.length + 1,
                            artist_type_pages: artistTypes.map((artist) => ({
                              artist_type_id: artist.id,
                              artist_type_name: artist.name,
                              visible: true,
                              body: "",
                              open: false,
                            })),
                          });
                        }}
                      >
                        <Icon
                          size={26}
                          name="plus"
                          style={{ marginBottom: "-10px" }}
                          viewbox="0 0 3 3"
                        />
                      </button>
                    )}
                    <button
                      className="form__table__button"
                      onClick={() => setPageToEdit(page)}
                    >
                      <Icon
                        size={26}
                        name="edit"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                    {!page.form_page && !page.homepage && (
                      <button
                        className="form__table__button"
                        onClick={() => setPageToRemove(page)}
                      >
                        <Icon
                          size={26}
                          name="delete"
                          style={{ marginBottom: "-10px" }}
                        />
                      </button>
                    )}
                  </div>
                </div>

                {page.children && (
                  <div className="pages-row__children">
                    {page.children.map((child) => (
                      <div
                        className="pages-row__children-inner"
                        key={child.id}
                        id={child.id}
                      >
                        {child.name}
                        <div className="form__table__last-column">
                          <button
                            className="form__table__button"
                            onClick={() => setPageToEdit(child)}
                          >
                            <Icon
                              size={26}
                              name="edit"
                              style={{ marginBottom: "-10px" }}
                            />
                          </button>
                          {!child.form_page && (
                            <button
                              className="form__table__button"
                              onClick={() => setPageToRemove(child)}
                            >
                              <Icon
                                size={26}
                                name="delete"
                                style={{ marginBottom: "-10px" }}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <br/>
      <Button
        label="Add new page"
        variant="secondary"
        onClick={() =>
          setPageToEdit({
            slug: "",
            homepage: false,
            name: "",
            body: "",
            children: [],
            form_page: false,
            sequence: productionPages.length + 1,
            artist_type_pages: artistTypes.map((artist) => ({
              artist_type_id: artist.id,
              artist_type_name: artist.name,
              visible: true,
              body: "",
              open: false,
            })),
          })
        }
      />
      {pageToRemove && (
        <DeleteDialog
          title="Remove page"
          text="Are you sure you want to remove this page?"
          buttonText="Yes, remove page!"
          confirm={() => removePage(pageToRemove)}
          setCancelDialogOpen={() => setPageToRemove(undefined)}
        />
      )}

      {pageToEdit && (
        <PagesDialog
          addPage={createOrUpdatePage}
          setCancelDialogOpen={() => setPageToEdit(undefined)}
          page={pageToEdit}
          productionWebsiteUrl={`http://app.beatswitch.com/production-website/${eventState.event.id}`}
        />
      )}
      <PageFooter
        setActiveStep={(step) => goToNextStep(step)}
        activeSubStep={activeSubStep}
        eventState={eventState}
        isNextDisabled={false}
      />
    </div>
  );
};

ProductionPagesForm.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
};

export default ProductionPagesForm;
