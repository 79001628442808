import React from 'react'
import {
    AlertBox,
    Card,
    Grid,
} from 'ui-kit'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import { Tooltip, NoRecordsPlaceholder } from 'app/react/components/_index'
import ContentHeader from '../../components/layout/DetailsContentHeader'
import ItemsTable from '../components/ItemsTable'
import CreateItemModal from '../components/stateful/CreateItemModalContainer'
import UpdateItemModal from '../components/stateful/UpdateItemModalContainer'
import DeleteItemModal from '../components/stateful/DeleteItemModalContainer'
import CopyItemsFromBookingModal from '../components/modals/CopyItemsFromBookingModalWithState'

const { Content } = ContentWrapper;
const { Header, Footer } = Card;
const { Title, Action } = Header;
const { Cell } = Grid;

const MAX_WIDTH = '1100px';
const GROUPS_CARD_WIDTH = '400px';
const CARD_TITLE = 'Assigned items';
const ADD_TOOLTIP = 'Add item';
const COPY_FROM_BOOKING_TOOLTIP = 'Copy items from a booking';
const NO_RECORDS_PLACEHOLDER_TEXT = 'No items assigned.';
const NO_RECORDS_CREATE_TEXT = 'Assign item';
const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';

const BookingItemsOverview = ({
    store,
    eventId,
    bookingId,
    artistId,
    artistName,
    artistImageSize80,
    artistCountryCode,
    bookingStatusTitle,
    bookingStatusColorType,
    permissions = {},
    accountPermissions = {},
    currency = 'EUR',
    isFetching = false,
    eventGroups = [],
    bookingItems = [],

    onOpenCreateItemModal = () => {},
    onOpenUpdateItemModal = () => {},
    onOpenDeleteItemModal = () => {},
}) => {
    const hasRecords = bookingItems.length > 0;
    return (
        <ContentWrapper store={store}>
            <ContentHeader
                activeTab="items"
                eventId={eventId}
                bookingId={bookingId}
                artistId={artistId}
                artistName={artistName}
                artistImageSize80={artistImageSize80}
                artistCountryCode={artistCountryCode}
                bookingStatusTitle={bookingStatusTitle}
                bookingStatusColorType={bookingStatusColorType}
                permissions={permissions}
                accountPermissions={accountPermissions}
                isFetching={isFetching}
            />
            <Content isLoading={isFetching}>
                {!permissions.manageArtistItems
                    ? <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox>
                    : <div style={{ maxWidth: MAX_WIDTH }}>
                        <Card>
                            <Header>
                                <Title>{CARD_TITLE}</Title>
                                <CopyItemsFromBookingModal
                                    bookingId={bookingId}
                                    renderTrigger={({ openModal }) => (
                                        <Tooltip content={COPY_FROM_BOOKING_TOOLTIP}>
                                            <Action color="green" onClick={openModal}>
                                                <i className="bs-icon-files" />
                                            </Action>
                                        </Tooltip>
                                    )}
                                />
                                <Tooltip content={ADD_TOOLTIP}>
                                    <Action onClick={onOpenCreateItemModal}>
                                        <i className="bs-icon-plus" />
                                    </Action>
                                </Tooltip>
                            </Header>
                            <Card.Content
                                isFlexContainer={!hasRecords}
                                style={{ minHeight: '300px' }}
                            >
                                {!hasRecords
                                    ? <NoRecordsPlaceholder style={{ flex: 1 }}>
                                        {NO_RECORDS_PLACEHOLDER_TEXT}&nbsp;
                                        <a onClick={onOpenCreateItemModal}>
                                            {NO_RECORDS_CREATE_TEXT}
                                        </a>
                                    </NoRecordsPlaceholder>
                                    : <ItemsTable
                                        currency={currency}
                                        items={bookingItems}
                                        groups={eventGroups}
                                        onUpdate={onOpenUpdateItemModal}
                                        onDelete={onOpenDeleteItemModal}
                                    />
                                }
                            </Card.Content>
                        </Card>
                    </div>
                }
            </Content>
            <CreateItemModal />
            <UpdateItemModal />
            <DeleteItemModal />
        </ContentWrapper>
    )
};

BookingItemsOverview.propTypes = {
};

export default BookingItemsOverview;
