import { find } from 'lodash'
import { uniq } from 'underscore'
import * as moment from 'moment'

const STATUS_ID_AWAIT_APPROVAL = 1;
const STATUS_ID_APPROVED = 2;
const STATUS_ID_DENIED = 3;

const MESSAGE_AWAIT_APPROVAL = (reservationName)  =>
    `You are about to reset the reservation for <strong>${reservationName}</strong> to await approval. Production Website users will be able to edit the reservation up until you approved it.`;
const MESSAGE_APPROVE = (reservationName)  =>
    `You are about to approve the reservation for <strong>${reservationName}</strong>. Production Website users will no longer be able to edit the reservation.`;
const MESSAGE_DENY = (reservationName)  =>
    `You are about to deny the reservation for <strong>${reservationName}</strong>. Production Website users will be able to edit the reservation to request approval again.`;

export const doesAnyReservationHaveAStatus = (reservations) => {
    const reservationWithStatus = find(reservations, (reservation) => {
        return reservation.status !== null && reservation.status !== undefined;
    });

    return reservationWithStatus !== undefined;
};

export const isAwaitingApprovalStatus = (requestStatusId) => requestStatusId === STATUS_ID_AWAIT_APPROVAL;
export const isApprovedStatus = (requestStatusId) => requestStatusId === STATUS_ID_APPROVED;
export const isDeniedStatus = (requestStatusId) => requestStatusId === STATUS_ID_DENIED;

export const requestStatusMap = {
    [STATUS_ID_AWAIT_APPROVAL]: {
        ID:  STATUS_ID_AWAIT_APPROVAL,
        NAME: 'Awaiting approval',
        ACTION: 'Await approval',
        ICON: 'bs-icon-clock',
        COLOR_TYPE: 'neutral',
        message: MESSAGE_AWAIT_APPROVAL,
    },
    [STATUS_ID_APPROVED]: {
        ID: STATUS_ID_APPROVED,
        NAME: 'Approved',
        ACTION: 'Approve',
        ICON: 'bs-icon-check-round',
        COLOR_TYPE: 'success',
        message: MESSAGE_APPROVE,
    },
    [STATUS_ID_DENIED]: {
        ID: STATUS_ID_DENIED,
        NAME: 'Denied',
        ACTION: 'Deny',
        ICON: 'bs-icon-triangle-exclamation-mark',
        COLOR_TYPE: 'danger',
        message: MESSAGE_DENY,
    }
};

export const getGuestsTooltipContent = (guests, guestContactNumber) => {
    const guestList = guests.length > 0
        ? guests.map(({ firstname, lastname }) => `${firstname} ${lastname}`).join('<br />')
        : 'No guest selected';

    if (guestContactNumber) {
        return `<strong>Contact No: ${guestContactNumber}</strong><br />${guestList}`;
    }

    return guestList;
};

export const getReservationDays = (reservations, eventDays) => {
    const reservationDays = reservations.flatMap(reservation => {
        const reservationDays = [];
        const { checkIn, nights } = reservation;
        let appendedNights = 0;

        while (appendedNights < nights) {
            const day = moment.utc(checkIn, moment.ISO_8601)
                .startOf('day')
                .add(appendedNights, 'days');
            reservationDays.push(day.toISOString());
            appendedNights++;
        }

        return reservationDays;
    });

    eventDays.forEach(day => {
        day = moment.utc(day.start, moment.ISO_8601).startOf('day');
        reservationDays.push(day.toISOString());
    });

    return uniq(reservationDays.sort());
};

export const hasNightOnDay = (reservation, day) => moment.utc(day, moment.ISO_8601).isBetween(
    moment.utc(reservation.checkIn, moment.ISO_8601).startOf('day'),
    moment.utc(reservation.checkOut, moment.ISO_8601).startOf('day'),
    null,
    '[)'
);

export const hasNoRoomLimit = (available) => available === null;
export const isRoomLimitAlmostReached = (booked, available) => (booked / available * 100) >= 50;
export const isRoomLimitReached = (booked, available) => booked === available;
export const isRoomLimitExceeded = (booked, available) => booked > available;

export const isBookedByPromoter = (reservation) => !reservation.isBookedByArtist;
export const isBookedByArtists = (reservation) => reservation.isBookedByArtist;
