import BaseView from 'app/backbone/components/Base.view'
import {
    DEFAULT_SIZE,
    DEFAULT_OPTIONS,
} from 'ui-kit/lib/components/pagination/PageSize'

export const PAGE_SIZE_DEFAULT = DEFAULT_SIZE;
export const PAGE_SIZE_OPTION_LABELS = DEFAULT_OPTIONS;
export const PAGE_SIZE_OPTION_VALUES = DEFAULT_OPTIONS.map((option) => {
    if (option === 'all') {
        return -1;
    }

    return option;
});

export default class DataTable extends BaseView {

    render() {
        if (!this.rendered) {
            this.$el.DataTable({
                stateSave: true,
                lengthMenu: [
                    PAGE_SIZE_OPTION_VALUES,
                    PAGE_SIZE_OPTION_LABELS,
                ],
                displayLength: DEFAULT_SIZE,
            });
            this.$el.removeClass('hidden');
        }
        this.rendered = true;
    }

}

