import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tab = ({
    tabId,
    activeTab,
    url,
    pageReload = false,
    children
}) => {
    const selectTab = () => {
        if (url) {
            if (pageReload) navigate(url, { pageReload });
            else navigate(url);
        }
    };

    return (
        <li className={classNames({ active: tabId === activeTab })}
            onClick={selectTab}
        >
            <a>
                <span>{ children }</span>
            </a>
        </li>
    );
};

Tab.propTypes = {
    tabId: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    url: PropTypes.string,
    pageReload: PropTypes.bool,
    children: PropTypes.node
};

const Tabs = ({
    children
}) => (
    <div className="content--header_tabs">
        <ul>{ children }</ul>
    </div>
);

Tabs.propTypes = {
    children: PropTypes.node
};

Tabs.Tab = Tab;

export default Tabs;
