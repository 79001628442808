import _ from 'underscore';
import Backbone from 'backbone';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import ServerSideFilter from './ServerSideFilter.view';
import template from './SelectFilter';

export default class SelectFilter extends ServerSideFilter {

    get template() {
        return template;
    }

    className() {
        return 'select-filter grid-cell';
    }

    events() {
        return {
            'change .filter-input': 'filter'
        };
    }

    optionTypes() {
        const { collection, filterName, value, placeholder } = super.optionTypes();
        return {
            collection,
            filterName,
            value,
            placeholder,
            label: OptionTypes.string.isRequired,
            optionsCollection: OptionTypes.instanceOf(Backbone.Collection).isRequired,
            optionName: OptionTypes.oneOfType([OptionTypes.string, OptionTypes.func]).isRequired,
            optionValue: OptionTypes.oneOfType([OptionTypes.string, OptionTypes.func]).isRequired,
            width: OptionTypes.number
        };
    }

    optionDefaults() {
        return {
            width: 150
        };
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {
                label: this.label,
                value: this.value,
                width: this.width,
                options: this.getFilterOptions()
            }
        };
    }

    getFilterOptions() {
        const options = this.optionsCollection.map((model) => {
            return {
                key: _.isFunction(this.optionName) ? this.optionName(model) : model.get(this.optionName),
                value: _.isFunction(this.optionValue) ? this.optionValue(model) : model.get(this.optionValue)
            };
        });
        options.unshift(this.getEmptyOption());
        return options;
    }

    getEmptyOption() {
        return {
            key: this.placeholder || ' ',
            value: ''
        };
    }

}
