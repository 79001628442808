import React from 'react';
import classNames from 'classnames';
import Icon from '../../../utils/Icon';

const FeedbackMessage = ({
	closeFeedback,
	modBottomMargin = false,
	text,
	variant,
}) => {
	const feedbackMessageClassNames = classNames([
		`feedback-message--${variant}`,
		modBottomMargin && 'feedback-message--margin',
		'feedback-message',
	]);

	return (
		<div className={feedbackMessageClassNames}>
			<span className={'feedback-message_text'}>{text}</span>
			{closeFeedback && (
				<button
					className={'feedback-message_close-button'}
					onClick={closeFeedback}
				>
					<Icon size={24} name='close' style={{ marginBottom: '-10px' }} />
				</button>
			)}
		</div>
	);
};

export default FeedbackMessage;
