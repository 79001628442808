import React from 'react';
import PropTypes from 'prop-types';
import CreateModal from './stateful/CreateModalContainer';
import UpdateModal from './stateful/UpdateModalContainer';
import DeleteModal from './stateful/DeleteModalContainer';
import CreateModalMutationContainer from 'app/react/views/event/artists/logistics/vehicles/components/modals/stateful/CreateModalMutationContainer';
import CreateDriverModal from 'app/react/views/event/artists/logistics/drivers/components/CreateModalContainer';

const PickupModalsWrapper = ({
    store,
    eventId,
    onCreateSuccess = () => {},
    onUpdateSuccess = () => {},
    onDeleteSuccess = () => {},
    onCreateDriverSuccess = () => {},
    onCreateVehicleSuccess = () => {},
}) => (
    <div>
        <CreateModal
            store={store}
            onSaveSuccess={onCreateSuccess}
        />
        <UpdateModal
            store={store}
            onSaveSuccess={onUpdateSuccess}
        />
        <DeleteModal
            store={store}
            onDeleteSuccess={onDeleteSuccess}
        />
        <CreateDriverModal
            canCreateAnother={false}
            onSaveSuccess={onCreateDriverSuccess}
        />
        <CreateModalMutationContainer
            eventId={eventId}
            canCreateAnother={false}
            onSaveSuccess={onCreateVehicleSuccess}
        />
    </div>
);

PickupModalsWrapper.propTypes = {
    store: PropTypes.object,
    eventId: PropTypes.number,
    onCreateSuccess: PropTypes.func,
    onUpdateSuccess: PropTypes.func,
    onDeleteSuccess: PropTypes.func,
    onCreateDriverSuccess: PropTypes.func,
    onCreateVehicleSuccess: PropTypes.func,
};

export default PickupModalsWrapper;
