import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import classNames from 'classnames';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select type...';
export const NO_RESULTS_PLACEHOLDER = 'No type found.';

const toOption = ({
    id,
    name
}) => ({
    value: id,
    label: name
});

const SelectPerformanceTimeSlot = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    showMenuTop = false,
    value,
    timeSlotTypes = [],
    onChange = () => {}
}) => (
    <SearchSelect
        className={classNames({ 'Select--show-menu-top': showMenuTop })}
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        options={timeSlotTypes.sort(by('name')).map(toOption)}
        onChange={onChange}
    />
);

SelectPerformanceTimeSlot.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    timeSlotTypes: PropTypes.array,
    onChange: PropTypes.func
};

export default SelectPerformanceTimeSlot;
