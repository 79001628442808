import React from 'react';
import styled from 'styled-components';

export const FilterSelect = styled.select`
    margin-right: 0.5rem;
`;

export const PageSizeSelect = styled.select`
    margin-left: 0.5rem;
    width: 75px;
`;

export const ClearButton = styled.a`
    position: absolute;
    top: 0;
    right: 0;
    color: #004B67;
    font-size: 13px;
    padding: 9px 12px;
`;
