import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select group...';
export const NO_RESULTS_PLACEHOLDER = 'No group found.';

const toOption = ({
    id,
    fullName,
}) => ({
    value: id,
    label: fullName,
});

const SelectParentGroup = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    value,
    groups = [],
    onChange = () => {}
}) => {
    return (
        <SearchSelect
            placeholder={placeholder}
            noResultsPlaceholder={noResultsPlaceholder}
            value={value}
            isDisabled={disabled}
            isClearable
            options={
                groups
                    .sort(by('fullName', { ignoreCase: true }))
                    .map(toOption)
            }
            onChange={onChange}
        />
    );
}

SelectParentGroup.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    modes: PropTypes.array,
    onChange: PropTypes.func,
};

export default SelectParentGroup;
