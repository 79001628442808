import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import EventCompanyDetailsContentHeader from 'app/react/views/event/accreditation/companies/CompanyDetailsContentHeader';
import CompanyAccrediteeCreateView from 'app/backbone/views/event/accreditation/companies/accreditees/views/CompanyAccrediteeCreate.view';
import CompanyAccrediteeUpdateView from 'app/backbone/views/event/accreditation/companies/accreditees/views/CompanyAccrediteeUpdate.view';

// Backbone Controller
export default class CompanyAccrediteeController extends BaseController{

    registerCommonData(eventId, companyId) {
        const { data } = this;
        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const company = data.register('eventCompany', EventCompany.Model, { event });
        const periods = data.register('allPeriods', Period.Collection, { event });
        const accreditees = data.register('pagedCompanyAccreditees', CompanyAccreditee.PageableCollection, { event, company });

        company.set('id', parseInt(companyId, 10));
        company.setIncludes([
            'profiles.days',
            'profiles.available_days',
            'profiles.available_items.available_days'
        ]);
        periods.setIncludes(['days']);
        accreditees.setIncludes(['profiles', 'items.groups']);

        return { account, event, company, periods, accreditees };
    }

    renderDetailsContentHeader(eventId, companyId, companyName, activeTab) {
        const { app } = this;
        app.renderContentHeader(
            React.createElement(
                EventCompanyDetailsContentHeader,
                {
                    eventId,
                    companyId,
                    companyName,
                    activeTab
                }
            )
        );
    }


    create(eventId, companyId) {
        const { app } = this;
        const intEventId = parseInt(eventId, 10);
        const intCompanyId = parseInt(companyId, 10);
        app.renderSyncingView();
        this.renderDetailsContentHeader(intEventId, intCompanyId, null, 'details');

        const { account, event, company, periods, accreditees } = this.registerCommonData(eventId, companyId);

        this.when(
            account.fetch(),
            event.fetch(),
            company.fetch(),
            periods.fetch()
        ).done(() => {
            const companyName = company.get('name');
            this.renderDetailsContentHeader(intEventId, intCompanyId, companyName, 'details');
            app.renderContentView(new CompanyAccrediteeCreateView({ company, periods, accreditees }));
        });
    }

    edit(eventId, companyId, accrediteeId) {
        const { app, data } = this;
        const intEventId = parseInt(eventId, 10);
        const intCompanyId = parseInt(companyId, 10);
        app.renderSyncingView();
        this.renderDetailsContentHeader(intEventId, intCompanyId, null, 'details');

        const { account, event, company, periods, accreditees } = this.registerCommonData(eventId, companyId);
        const accreditee = data.register('companyAccreditee', CompanyAccreditee.Model, { company });

        accreditee.set('id', parseInt(accrediteeId, 10));
        accreditee.setIncludes([
            'profiles.days',
            'profiles.granted_days',
            'profiles.available_profile.available_items.available_days',
            'items.granted_days',
            'items.granted_profiles.available_profile.available_items.available_days'
        ]);

        this.when(
            account.fetch(),
            event.fetch(),
            company.fetch(),
            periods.fetch(),
            accreditee.fetch()
        ).done(() => {
            const companyName = company.get('name');
            this.renderDetailsContentHeader(intEventId, intCompanyId, companyName, 'details');
            app.renderContentView(new CompanyAccrediteeUpdateView({ accreditee, company, periods, accreditees }));
        });
    }

}
