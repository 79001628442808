import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'ui-kit';
import { Modal } from 'app/react/components/modals/_index';

const { Header, Content, Footer } = Modal;
const { Title } = Header;
const { Button, Error } = Footer;

const TITLE = {
    UPDATE: 'Edit name of artist type',
    CREATE: 'Add artist type',
    DUPLICATE: 'Duplicate artist type',
};
const NAME_LABEL = 'Name';
const NAME_PROP_KEY = 'name';

const BUTTON_TEXT = {
    UPDATE: 'Edit',
    CREATE: 'Add',
    DUPLICATE: 'Duplicate',
};

const ArtistTypeModal = ({
    isOpened = false,
    isSyncing = false,
    isCreating = true,
    isDuplicating = false,
    values = {},
    errors = {},
    footer = {},
    onChangeValue = () => {},
    onClosed = () => {},
    onSave = () => {}
}) => {
    const errorMessage = footer.message;
    const title = isCreating ? TITLE.CREATE : isDuplicating ? TITLE.DUPLICATE : TITLE.UPDATE;
    const buttonText = isCreating ? BUTTON_TEXT.CREATE : isDuplicating ? BUTTON_TEXT.DUPLICATE : BUTTON_TEXT.UPDATE;
    const buttonClassName = isCreating ? 'create' : 'update';

    return (
        <Modal
            width={600}
            closeOnBackgroundClick={false}
            opened={isOpened}
            syncing={isSyncing}
            onClosed={onClosed}
        >
            <Header onClosed={onClosed}>
                <Title>{title}</Title>
            </Header>
            <Content>
                <InputGroup
                    messages={errors[NAME_PROP_KEY]}
                >
                    <label>{NAME_LABEL}</label>
                    <input
                        type="text"
                        value={values[NAME_PROP_KEY] || ''}
                        onChange={e => onChangeValue(NAME_PROP_KEY, e.target.value)}
                    />
                </InputGroup>
            </Content>
            <Footer>
                {errorMessage &&
                    <Error>{errorMessage}</Error>
                }
                <Button
                    className={buttonClassName}
                    onClick={onSave}
                >
                    {buttonText}
                </Button>
            </Footer>
        </Modal>
    );
};

ArtistTypeModal.propTypes = {
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    isCreating: PropTypes.bool,
    isDuplicating: PropTypes.bool,
    values: PropTypes.shape({
        [NAME_PROP_KEY]: PropTypes.string
    }),
    errors: PropTypes.object,
    footer: PropTypes.object,
    onChangeValue: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
};

export default ArtistTypeModal;
