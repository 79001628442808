import React from 'react'
import classNames from 'classnames'
import './Price.module.css'

const Price = ({
    amount,
    currencySymbol,
    hasAlert,
}) => (
    <span styleName={classNames('price', { hasAlert })}>
        {currencySymbol}{amount}
    </span>
);

Price.defaultProps = {
    hasAlert: false,
};

export default Price;
