import BaseController from 'app/backbone/helpers/BaseController';
import GeneralEventSettingsController from './event/Controller';
import ArtistsSettingsController from './artists/Controller';
import AccreditationSettingsController from './accreditation/Controller';

export default class EventSettingsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new GeneralEventSettingsController({ parent: this }),
            new ArtistsSettingsController({ parent: this }),
            new AccreditationSettingsController({ parent: this }),
        ];
    }

}
