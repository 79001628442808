import _ from 'underscore';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import PeriodDaysTable from 'app/backbone/views/event/settings/accreditation/components/PeriodDaysTable.view';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as CompanyItem from 'app/backbone/models/event/accreditation/companies/CompanyItem';
import AvailableDayCell from './cells/AvailableDayCell.view';
import AvailableAllDaysCell from './cells/AvailableAllDaysCell.view';

export default class AvailableCompanyItemTable extends PeriodDaysTable {

    optionTypes() {
        return _.extend({}, super.optionTypes(), {
            collection: OptionTypes.instanceOf(CompanyItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            items: OptionTypes.instanceOf(EventItem.Collection).isRequired,
            editing: OptionTypes.bool,
            placeholderHeight: OptionTypes.number
        });
    }

    optionDefaults() {
        return _.extend({}, super.optionDefaults(), {
            editing: true,
            placeholderHeight: 100
        });
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        this.actions = this.initActions();
        this.firstColumns = this.initFirstColumns();
        this.columns = this.initColumns();
        this.lastColumns = this.initLastColumns();
        this.placeholderText = this.getPlaceholderText();

        super.initialize(options);
    }

    initFirstColumns() {
        return [{
            name: 'name',
            label: 'Item',
            cell: 'string',
            headerCell: CellFactory.createHeaderCell('150px'),
            sortable: false,
            editable: false
        }, {
            name: 'name',
            label: 'Group',
            cell: CellFactory.createSubModelCell(null, null, 'item_group'),
            headerCell: CellFactory.createHeaderCell('100px'),
            sortable: false,
            editable: false
        }, {
            name: 'all_days',
            label: 'All',
            cell: this.createAllDaysCell(this.periods.getAllDays()),
            headerCell: CellFactory.createHeaderCell('60px', 'center'),
            sortable: false,
            editable: false
        }];
    }

    initColumns() {
        return [];
    }

    initLastColumns() {
        const { actions } = this;
        return this.editing ? [{
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(actions),
            sortable: false,
            editable: false
        }] : [];
    }

    initActions() {
        const actions = [];
        if (this.editing) {
            actions.push({
                name: 'remove',
                icon: 'bs-icon-cross',
                tooltip: 'Remove',
                action: this.removeItem.bind(this)
            });
        }
        return actions;
    }

    removeItem(item) {
        this.collection.remove(item);
    }

    createDayCell(day, classNamePostfix) {
        const { editing } = this;
        const className = `${AvailableDayCell.prototype.className} ${classNamePostfix}`;
        return AvailableDayCell.extend({ day, editing, className });
    }

    createAllDaysCell() {
        const { editing, periods } = this;
        const days = periods.getAllDays();
        return AvailableAllDaysCell.extend({ days, editing });
    }

    getPlaceholderText() {
        const { items, editing } = this;
        if (editing) {
            if (!items.isEmpty()) return 'No items have been added for this company yet.';
            const eventId = items.hasRelation('event') ? items.getRelation('event').get('id') : items.first().getRelation('event').get('id');
            const createItemUrl = `/events/${eventId}/settings/accreditation/items`;
            return `No items have been created for this event. <a href="${createItemUrl}">Create your first item.</a>`;
        }
        return 'No items have been added for this company yet.';
    }

}
