import _ from 'underscore';
import Backbone from 'backbone';
import Backgrid from 'backgrid';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import SearchFilter from './views/SearchFilter.view';
import SelectFilter from './views/SelectFilter.view';
import Pager from './views/Pager.view';
import PageSize from './views/PageSize.view';
import template from './PageableTable.hbs';
import './PageableTable.css';

export default class PageableTable extends BaseView {

    get template() {
        return template;
    }

    ui() {
        return {
            pageSizeView: '.table-page-size',
            filterViews: '.select-filters',
            searchView: '.search-filter',
            grid: 'table',
            paginatorView: '.table-pager',
            infoView: '.table-info'
        };
    }

    optionTypes() {
        return {
            collection: OptionTypes.instanceOf(Backbone.Collection).isRequired,
            columns: OptionTypes.arrayOf(OptionTypes.object).isRequired,
            actions: OptionTypes.arrayOf(OptionTypes.object),
            filtersDef: OptionTypes.arrayOf(OptionTypes.object),
            header: OptionTypes.func,
            row: OptionTypes.func,
            placeholderHeight: OptionTypes.number,
            placeholderText: OptionTypes.string,
            pageSizeOptions: OptionTypes.arrayOf(OptionTypes.number)
        };
    }

    optionDefaults() {
        return {
            actions: [],
            filtersDef: [],
            header: Backgrid.Header,
            row: Backgrid.Row,
            placeholderHeight: 200,
            placeholderText: 'No records.'
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Props
        this.hasActions = !! this.actions.length;
        this.addColumnsDefaults();
        // Subviews
        this.table = this.initTable();
        this.grid = this.table;
        this.paginatorView = this.initPaginator();
        this.pageSizeView = this.initPageSize();
        this.filterViews = this.initFilters();
        if (this.hasSearch()) this.searchView = this.initSearch();
        this.isSearching = this.areSomeFiltersActive() || this.isSearchActive();
        // Listeners
        this.bindCollection();
    }

    render() {
        this.tableHeight = this.handlesResolved ? this.ui.grid.height() : this.placeholderHeight;
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();

        if (!this.isSyncing) {
            this.assignSubview(this.grid, this.ui.grid);
            this.grid.$el.toggleClass('actions-right', this.hasActions);
        }
        this.assignSubview(this.pageSizeView, this.ui.pageSizeView);
        this.insertSubview(this.paginatorView, this.ui.paginatorView);
        this.appendSubviews(this.filterViews, this.ui.filterViews);
        if (this.hasSearch()) this.assignSubview(this.searchView, this.ui.searchView);

        return this.delegateEvents();
    }

    remove() {
        this.grid.remove();
        this.paginatorView.remove();
        this.pageSizeView.remove();
        this.filterViews.call('remove');
        super.remove();
    }

    viewModel() {
        return {
            view: {
                isSyncing: this.isSyncing,
                tableHeight: this.tableHeight,
                placeholderHeight: this.placeholderHeight,
                placeholderText: this.placeholderText,
                startOffset: this.startOffset(),
                endOffset: this.endOffset(),
                hasRecordsOrIsSearching: this.totalRecords() || this.isSearching,
                hasSearch: this.hasSearch(),
                totalRecords: this.totalRecords()
            }
        };
    }

    initTable() {
        const collection = this.collection;
        const columns = this.columns;
        const header = this.header;
        const row = this.row;
        const emptyText = 'No matching records found.';
        return new Backgrid.Grid({ collection, columns, header, row, emptyText });
    }

    initPaginator() {
        const { collection } = this;
        return new Pager({ collection });
    }

    initPageSize() {
        const { collection } = this;
        return new PageSize({ collection });
    }

    initSearch() {
        const { collection } = this;
        const filterName = 'search';
        const placeholder = 'Search';
        return new SearchFilter({ collection, filterName, placeholder });
    }

    initFilters() {
        const { collection, filtersDef } = this;
        const filters = new Backbone.ChildViewContainer();
        filtersDef.forEach(def => {
            filters.add(new SelectFilter({
                collection,
                filterName: def.filterName,
                label: def.label,
                placeholder: def.placeholder,
                optionsCollection: def.collection,
                optionName: def.optionName,
                optionValue: def.optionValue,
                width: def.width
            }), def.name);
        });
        return filters;
    }

    addColumnsDefaults() {
        const sortable = false;
        const editable = false;
        return this.columns.forEach(column => _.defaults(column, { sortable, editable }));
    }

    bindCollection() {
        this.listenTo(this.collection, 'request', this.onRequest);
        this.listenTo(this.collection, 'sync', this.onSync);
        this.listenTo(this.collection, 'error', this.onError);
        this.listenTo(this.collection, 'destroy', this.onDestroy);
    }

    unbindCollection() {
        this.stopListening(this.collection);
    }

    onRequest() {
        this.setSyncing(true);
    }

    onSync() {
        this.setSyncing(false);
    }

    onError() {
        this.setSyncing(false);
    }

    onDestroy() {
        const { collection } = this;
        if (!collection.isSomethingDestroying()) {
            if (collection.isEmptyButHasMore()) collection.getPreviousPage();
            else collection.getCurrentPage();
        }
    }

    setSyncing(syncing) {
        if (syncing !== this.isSyncing) {
            this.isSyncing = syncing;
            this.isSearching = this.areSomeFiltersActive() || this.isSearchActive();
            this.render();
        }
    }

    areSomeFiltersActive() {
        return this.filterViews.some(view => view.active);
    }

    isSearchActive() {
        return this.hasSearch() && this.searchView.active;
    }

    startOffset() {
        const { collection: { state } } = this;
        const pageSize = state.pageSize;
        const currentPage = state.currentPage;
        return ((pageSize * currentPage) - pageSize) + 1;
    }

    endOffset() {
        return ((this.startOffset() - 1) + this.collection.length);
    }

    totalRecords() {
        return this.collection.state.totalRecords;
    }

    // Actions
    hideAllAction(name) {
        if (name) {
            this.collection.forEach(model => this.hideAction(model, name));
        } else {
            const actionCells = this.findAllActionCells();
            if (actionCells.length) {
                _.each(actionCells, (cell) => {
                    if (cell) cell.actions.call('hide');
                });
            }
        }
    }

    findAllActionCells() {
        const rows = this.table.body.rows;
        return rows.map((row) => this.findCellInRow(row, 'actions'));
    }

    hideAction(model, name) {
        const cell = this.findCell(model, 'actions');
        if (cell) cell.hideAction(name);
    }

    findCell(model, columnName) {
        const row = this.findRow(model);
        if (row) return this.findCellInRow(row, columnName);
    }

    findCellInRow(row, columnName) {
        if (row.cells) return row.cells.filter(cell => cell.column.get('name') === columnName)[0];
    }

    findRow(model) {
        const rows = this.table.body.rows;
        return rows.filter(row => row.model.id === model.id)[0];
    }

    initColumns() {
        return [];
    }

    getFiltersDef() {
        return [];
    }

    hasSearch() {
        return false;
    }

}
