import React from "react";
import PropTypes from "prop-types";
import Button from "../atoms/button/Button";
import { Card } from "ui-kit";

export const FinalSplashPage = () => {
  const { Content } = Card;

  const onConfirmCancel = () => {
    navigate("/events", { pageReload: true });
  };
  return (
    <div className="final-splash-page__content">
      <img
        width="260"
        height="210"
        src="https://s3.eu-central-1.amazonaws.com/assets.beatswitch.com/promoter-web-app/event-creation-success.svg"
        alt="Finished event creation flow"
        class="final-splash-page__img"
      />
      <Card width="70rem" height="35rem">
        <Content
          textAlign="center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>YES! Your event is up and running!</h2>
          <p className="final-splash-page__description">You can still do optimizations.</p>
          <Button label="Go to event dashboard" onClick={onConfirmCancel} />
        </Content>
      </Card>
    </div>
  );
};

FinalSplashPage.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
};

export default FinalSplashPage;
