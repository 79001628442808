import React from 'react';
import PropTypes from 'prop-types';

const CREATE_NEW_TEXT = 'Create booking';
const ADD_EXISTING_TEXT = 'Add existing booking';

const BookingFooterRow = ({
    onCreateNew = () => {},
    onAddExisting = () => {},
    isArtistTypesEnabled,
}) => (
    <tr style={{ height: '49px' }}>
        <td colSpan={isArtistTypesEnabled ? '5' : '4'} style={{ textAlign: 'center' }}>
            <a onClick={onCreateNew}>
                + {CREATE_NEW_TEXT}
            </a>
            <a onClick={onAddExisting} style={{ marginLeft: '100px' }}>
                + {ADD_EXISTING_TEXT}
            </a>
        </td>
    </tr>
);

BookingFooterRow.propTypes = {
    onCreateNew: PropTypes.func,
    onAddExisting: PropTypes.func,
    isArtistTypesEnabled: PropTypes.bool,
};

export default BookingFooterRow;
