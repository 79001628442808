import React from 'react';
import { AlertBox, Card } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { CreateButtonRow, UpdateButtonRow } from 'app/react/components/_index';
import CrewFields from 'app/react/views/event/artists/bookings/crew/components/CrewFields';

const { Content } = ContentWrapper;
const { Header, Footer } = Card;
const { Title } = Header;

const MAX_WIDTH = '420px';
const CREATE_CARD_TITLE = 'Create Crew Member';
const UPDATE_CARD_TITLE = 'Update Crew Member';
const REACHED_LIMIT_MESSAGE = 'You have added the maximum amount of crew members.';

const CrewView = ({
    store,
    isCreating = true,
    limitReached = false,

    isFetching = false,
    dateFormat,
    timeFormat,
    artistName,

    values = {},
    errors = {},
    dynamicFields = {},
    selectedPassport,

    onChangeValue = () => {},
    onChangePassport = () => {},
    onSave = () => {}
}) => (
    <ContentWrapper store={store} showFooter={false}>
        { limitReached && isCreating ?
            <Content isLoading={true}>
                <AlertBox type={AlertBox.WARNING}>
                    {REACHED_LIMIT_MESSAGE}
                </AlertBox>
            </Content>  :
            <Content isLoading={isFetching}>
                <Card>
                    <Header>
                        <Title>{isCreating ? CREATE_CARD_TITLE : UPDATE_CARD_TITLE}</Title>
                    </Header>
                    <Card.Content>
                        <div style={{ maxWidth: MAX_WIDTH }}>
                            <CrewFields
                                values={values}
                                errors={errors}
                                dynamicFields={dynamicFields}
                                requiredFieldNames={['firstname', 'lastname', 'role']}
                                selectedPassport={selectedPassport}
                                dateFormat={dateFormat}
                                timeFormat={timeFormat}
                                onChangeValue={onChangeValue}
                                onChangePassport={onChangePassport}
                            />
                        </div>
                    </Card.Content>
                    <Footer />
                </Card>
                { isCreating ?
                    <CreateButtonRow
                        canCreateAnother
                        onCreate={() => onSave(false)}
                        onCreateAnother={() => onSave(true)}
                    /> :
                    <UpdateButtonRow
                        onUpdate={onSave}
                    />
                }
            </Content>
        }
    </ContentWrapper>
);

export default CrewView;
