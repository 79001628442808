import { connect } from 'react-redux';
import { mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as Event from 'app/react/entities/events/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Driver from 'app/react/entities/events/drivers/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups/index';
import PickupsScheduler from './Scheduler';
import * as CreateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/CreateModalState';
import * as UpdateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/UpdateModalState';
import * as DeleteModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/DeleteModalState';
import * as PickupsSchedulerState from '../../components/scheduler/SchedulerState';
import * as State from './SchedulerState';

const mapStateToProps = (state) => {
    const { selectedDay = {}, eventDays = [] } = State.stateHelpers.getState(state);
    const { activeInterval } = PickupsSchedulerState.stateHelpers.getState(selectedDay.id, state);
    const { manageLogistics, downloadLogisticsReport } = Event.stateHelpers.getUserPermissions(state);
    const after = selectedDay.start;
    const before = selectedDay.endWithExtraSchedulerHours;
    const pickups = Pickup.stateHelpers.getAllBetweenDatesForCurrentContext(after, before, state, true);
    const pickupsCollection = Pickup.stateHelpers.getCollectionBetweenDatesForCurrentContext(after, before, state);
    return {
        ...mapEventContextToProps(state),
        isFetching: (
            Driver.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Day.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        userCanManageLogistics: manageLogistics,
        userCanDownloadReport: downloadLogisticsReport,
        pickups,
        isFetchingPickups: pickupsCollection.isFetching,
        drivers: Driver.stateHelpers.getAllForCurrentContext(state),
        eventDays,

        selectedDay,
        activeInterval,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onChangeDay: (selectedDay) => {
        const after = selectedDay.start;
        const before = selectedDay.endWithExtraSchedulerHours;
        dispatch(State.actions.mergeState({ selectedDay }));
        dispatch(Pickup.thunks.fetchAllBetweenDatesForCurrentContextOrLoadFromCache(after, before, ['booking.artist', 'passengers']))
    },
    onChangeInterval: (selectedDay, e) => {
        const activeInterval = e.target.value;
        dispatch(PickupsSchedulerState.actions.mergeState(selectedDay.id, { activeInterval }));
    },
    onDropPickup: (pickupId, values, revertCallback) => {
        dispatch(State.thunks.updatePickup(pickupId, values, revertCallback))
    },
    onResizePickup: (pickupId, values, revertCallback) => {
        dispatch(State.thunks.updatePickup(pickupId, values, revertCallback))
    },
    onSelectPeriod: (values) => {
        dispatch(CreateModalState.thunks.open(values))
    },
    onCreatePickupSuccess: (pickupId) => {
        dispatch(State.thunks.afterPickupCreated(pickupId))
    },
    onUpdatePickup: (pickupId) => {
        dispatch(UpdateModalState.thunks.open(pickupId));
    },
    onDeletePickup: (pickupId) => {
        dispatch(DeleteModalState.thunks.open(pickupId));
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onChangeInterval: (e) => {
        const { selectedDay } = stateProps;
        dispatchProps.onChangeInterval(selectedDay, e)
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PickupsScheduler);
