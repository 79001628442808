import Tooltip from 'tether-tooltip';
import { DEFAULT_OPEN_DELAY } from 'app/backbone/components/tooltips/constants';
import GrantedDayCell from './GrantedDayCell.view';
import template from './GrantedAllDaysCell.hbs';

export default class GrantedAllDaysCell extends GrantedDayCell {

    get template() {
        return template;
    }

    attributes() {
        return {
            style: 'width:55px'
        };
    }

    initialize(options = {}) {
        super.initialize(options);

        // Subview
        if (!this.isDateDependable()) this.tooltip = this.initTooltip();

        // listeners
        this.listenTo(this.grantedDays, 'update reset', this.onUpdateGrantedDays);
        // We remove the listeners for 'add' and 'remove' events because we now listen to the
        // more general 'update' event
        this.stopListening(this.grantedDays, 'add remove');
    }

    viewModel() {
        return {
            view: {
                isDisabled: this.isDisabled(),
                isVisible: this.isVisible(),
                isChecked: this.isChecked(),
                isEditing: this.isEditing(),
                isDateDependable: this.isDateDependable()
            }
        };
    }

    initTooltip() {
        const target = this.el;
        const content = 'This type of item is available on all days.';
        const position = 'right center';
        const openDelay = DEFAULT_OPEN_DELAY;
        return new Tooltip({ target, content, position, openDelay });
    }

    isVisible() {
        return true;
    }

    isChecked() {
        const grantableDays = this.getGrantableDays();
        if (!grantableDays.length) return false;
        return this.model.hasGrantedDayOnEvery(grantableDays);
    }

    isDisabled() {
        const grantableDays = this.getGrantableDays();
        return !grantableDays.length || !this.model.get('granted_by_promoter');
    }

    isDateDependable() {
        return this.model.get('date_dependable');
    }

    getGrantableDays() {
        return this.accrediteeProfiles.getAllUniqueGrantedDays();
    }

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        const grantableDays = this.getGrantableDays();
        if (checked) this.model.addGrantedDayOnAll(grantableDays);
        else this.model.removeGrantedDayOnAll(grantableDays);
    }

    onUpdateGrantedDays() {
        this.render();
    }

    onUpdateProfileGrantedDays() {
        this.render();
    }

    addProfileGrantedDaysListener(profile) {
        // In this cell we only listen to the 'update' event of the
        this.listenTo(profile.getRelation('granted_days'), 'update', this.onUpdateProfileGrantedDays);
    }

}

