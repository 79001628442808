import React from 'react';
import PropTypes from 'prop-types';
import { NoRecordsRow } from 'app/react/components/_index';
import { doesAnyReservationHaveAStatus } from 'app/react/helpers/reservations';
import HeaderRow from './HeaderRow';
import Row from './Row';
import DetailDrawer from 'app/react/views/event/artists/reservations/views/overview/components/drawers/DetailDrawer'

const ReservationsTable = (props) => {
    const {
        reservations = [],
        dateFormat,
        timeFormat,
        sortKey,
        sortOrder,
        onSort = () => {},
        onEditReservation = () => {},
        onDeleteReservation = () => {},
    } = props;
    const isApprovalRequiredSomewhere = doesAnyReservationHaveAStatus(reservations);

    return (
        <table className="overflow actions-right">
            <thead>
                <HeaderRow
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                    onSort={onSort}
                    isApprovalRequiredSomewhere={isApprovalRequiredSomewhere}
                />
            </thead>
            <tbody>
                {reservations.length === 0
                    ? <NoRecordsRow colSpan={10} />
                    : reservations.map(reservation => (
                        <DetailDrawer
                            key={reservation.id}
                            reservation={reservation}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            isApprovalRequired={typeof reservation.status !== "undefined"}
                            onClickEdit={onEditReservation}
                            onClickDelete={onDeleteReservation}
                            renderTrigger={({ onOpenDrawer }) => (
                                <Row
                                    {...props}
                                    key={reservation.id}
                                    id={reservation.id}
                                    reservation={reservation}
                                    isApprovalRequiredSomewhere={isApprovalRequiredSomewhere}
                                    isApprovalRequired={typeof reservation.status !== "undefined"}
                                    onClick={onOpenDrawer}
                                    onClickEdit={onEditReservation}
                                    onClickDelete={onDeleteReservation}
                                />
                            )}
                        />
                    ))
                }
            </tbody>
        </table>
    );
};

ReservationsTable.propTypes = {
    reservations: PropTypes.array,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func,
    onChangeCheckIn: PropTypes.func,
    onClickReservation: PropTypes.func,
    onDeleteReservation: PropTypes.func
};

export default ReservationsTable;
