import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import ArtistReportsOverview from 'app/backbone/views/event/artists/reports/ArtistReportsOverview.view'
import * as Routes from './routes'

export default class ReportsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.base(), null, this.reports.bind(this));
    }

    reports() {
        this.app.renderContentView(new ArtistReportsOverview());
    }

}
