import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import ItemTable from 'app/backbone/views/account/settings/accreditation/views/tables/ItemTable.view';

export default class EventItemTable extends ItemTable {

    optionTypes() {
        return {
            itemGroups: OptionTypes.instanceOf(EventItemGroup.PageableCollection).isRequired,
            items: OptionTypes.instanceOf(EventItem.PageableCollection).isRequired
        };
    }

}
