import Backbone from 'backbone'
import DateTime from 'app/backbone/helpers/DateTime'
import Modal from 'app/backbone/components/modals/Modal.view'
import * as Account from 'app/backbone/models/account/Account'
import * as Event from 'app/backbone/models/event/Event'
import * as Day from 'app/backbone/models/event/Day'
import * as Stage from 'app/backbone/models/event/Stage'
import * as BaseStage from 'app/backbone/models/event/BaseStage'
import * as Booking from 'app/backbone/models/event/artists/Booking'
import * as ArtistItemGroup from 'app/backbone/models/event/artists/ArtistItemGroup'
import Form from 'app/backbone/components/forms/Form.mixin'
import template from './DownloadArtistItemsModal.hbs'

const DownloadArtistItemsModal = Modal.extend({

    template,

    mixins: [
        Form
    ],

    ui: {
        days: '[name=days]',
        stages: '[name=stages]',
        bookings: '[name=bookings]',
        artistItemsDownloadButton: '.download',
        includeFinancialDataCheck: '#include_financial_data',
        includeLocationDataCheck: '#include_location_data',
    },

    events: {
        'change bookings': 'onBookingSelected',
        'change days': 'toggleBookingFilter',
        'change stages': 'toggleBookingFilter',
        'click artistItemsDownloadButton': 'download',
        'change includeFinancialDataCheck': 'onChangeIncludeFinancialData',
        'change includeLocationDataCheck': 'onChangeIncludeLocationData'
    },

    options: [
        { 'width': 400 }
    ],

    initialize(options) {
        this._super(options);

        const account = new Account.Model().fetchFromDOM();
        const event = new Event.Model(null, { account }).fetchFromDOM();

        this.event = event;

        this.days = new Day.Collection(null, { event }).fetchFromDOM();
        this.days.add({ id: 0, name: 'All days' }, { at: 0 });
        // this.stages = new Stage.Collection(null, { event }).fetchFromDOM();
        this.stages = new BaseStage.Collection(null, { event }).fetchFromDOM();
        console.log(this.baseStages);
        // this.stagesRenamed = new Stage.Collection()
        //     .setIncludes(['day'])
        //     .fetchFromDOM();
        // this.stagesRenamed.forEach((stage) => {
        //     stage.set('name', `${stage.getRelation('day').get('name')} - ${stage.get('name')}`);
        // });
        this.addAllStagesOption(this.stages);
        // this.addAllStagesOption(this.stagesRenamed);
        this.groups = new ArtistItemGroup.Collection(null, { event }).fetchFromDOM();
        this.groups.add({ id: 0, name: 'All groups' }, { at: 0 });
        this.bookings = new Booking.Collection(null, { event });
        this.bookings.setIncludes(['artist']);
        this.bookings.fetchAll().done(() => {
            this.render();
            this.bookings.add({ id: 0, name: 'All bookings' }, { at: 0 });
        });
        this.prevSelectedStage = undefined;
    },

    addAllStagesOption(stages) {
        stages.add({ id: 0, name: 'All stages' }, { at: 0 });
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                includeFinancialData: this.includeFinancialData,
                includeLocationData: this.includeLocationData
            }
        };
    },

    initForm() {
        const { days, stages, groups, bookings } = this;
        this.inputs = new Backbone.ChildViewContainer();

        const dayValue = this.daysSelectize && this.daysSelectize.getSelectedValue();
        this.daysSelectize = this.initSingleSelectize('days', { collection: days, initialValue: dayValue });
        this.inputs.add(this.daysSelectize, 'days');
        this.listenTo(this.daysSelectize, 'selected', this.onDaySelected);

        const stageValue = this.stagesSelectize && this.stagesSelectize.getSelectedValue();
        this.stagesSelectize = this.initSingleSelectize('stages', { collection: stages, initialValue: stageValue });
        this.inputs.add(this.stagesSelectize, 'stages');
        this.listenTo(this.stagesSelectize, 'selected', this.onStageSelected);

        const groupValue = this.groupsSelectize && this.groupsSelectize.getSelectedValue();
        this.groupsSelectize = this.initSingleSelectize('groups', { collection: groups, initialValue: groupValue, attrKey: 'full_name' });
        this.inputs.add(this.groupsSelectize, 'groups');

        const bookingValue = this.bookingsSelectize && this.bookingsSelectize.getSelectedValue();
        this.bookingsSelectize = this.initSingleSelectize('bookings', { collection: bookings, initialValue: bookingValue, attrKey: 'title_with_day' });
        this.inputs.add(this.bookingsSelectize, 'bookings');

        this.datePicker = this.initDatepicker('date', { formatKey: 'event' });
        this.inputs.add(this.datePicker, 'date');
        this.timePicker = this.initTimepicker('time', { formatKey: 'event' });
        this.inputs.add(this.timePicker, 'time');
    },

    renderForm() {
        this.inputs.call('render');
    },

    open() {
        this.render();
        this.initForm();
        this.delegateEvents();
        this.renderForm();
        this.show();
    },

    download() {
        const event = this.event;
        const dayId = this.daysSelectize.getSelectedValue();
        const stageId = this.stagesSelectize.getSelectedValue();
        const groupId = this.groupsSelectize.getSelectedValue();
        const bookingId = this.bookingsSelectize.getSelectedValue();
        const date = this.datePicker.getValue();
        const time = this.timePicker.getValue();
        const includeFinancialData = this.includeFinancialData ? 1 : 0;
        const includeLocationData = this.includeLocationData ? 1 : 0;
        let fullDate = '';
        if (date !== '') {
            fullDate = DateTime.event.parseDateTimeStringToISOString(`${date} ${time}`).slice(0, -1);
        }
        navigate(
            `/events/${event.id}/artists/reports/items?day=${dayId}&stage=${stageId}&group=${groupId}&booking=${bookingId}&financial=${includeFinancialData}&location=${includeLocationData}&since=${fullDate}`,
            { pageReload: true }
        );
    },

    onChangeIncludeLocationData(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        this.includeLocationData = checked;
        this.render();
        this.initForm();
        this.renderForm();
    },

    onChangeIncludeFinancialData(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        this.includeFinancialData = checked;
        this.render();
        this.initForm();
        this.renderForm();
    },

    onDaySelected(day) {
        // const { stages, stagesRenamed } = this;
        // let stagesCollection = stagesRenamed;

        // if (day.get('id') !== 0) {
        //     const stagesCollection = stages.filterByDay(day);
        //     this.addAllStagesOption(stagesCollection);
        //     this.stagesSelectize.setCollection(stagesCollection);
        // } else {
        //     this.stagesSelectize.setCollection(stagesCollection);
        // }

        // if (this.prevSelectedStage) {
        //     const selectedStage = stagesCollection.find((stage) => stage.get('id') === this.prevSelectedStage)
        //     this.stagesSelectize.selectOption(selectedStage);
        // }
    },

    onStageSelected(stage) {
        // if (!stage || stage.get('id') === 0) {
        //     return;
        // }

        // if (this.prevSelectedStage === stage.get('id')) {
        //     return;
        // }

        // this.prevSelectedStage = stage.get('id');
        // this.daysSelectize.selectOption(stage.getRelation('day'));
    },

    toggleBookingFilter(e) {
        const selectedValue = parseInt(e.target.value);

        if (selectedValue !== 0) {
            this.bookingsSelectize.selectize.disable();
        } else {
            this.bookingsSelectize.selectize.enable();
        }
    },

    onBookingSelected(e) {
        const selectedBooking = parseInt(e.target.value);

        if (selectedBooking !== 0) {
            this.daysSelectize.selectize.disable();
            this.stagesSelectize.selectize.disable();
        } else {
            this.daysSelectize.selectize.enable();
            this.stagesSelectize.selectize.enable();
        }
    }
});

export default DownloadArtistItemsModal;
