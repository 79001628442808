import React from 'react';
import classNames from 'classnames';
import { 
    Grid, 
    DateInput,
} from 'ui-kit';
import { TEXT_FORM_FIELD_TYPE } from './AvailableFormFieldsCard';
import './FormBuilderView.css'

const { Cell } = Grid;

const FormFieldItem = ({
    id,
    type,
    required,
    label,
    placeholder,
    selected,
    selectFormField = () => {},
    removeFormField = () => {},
}) => (
    <li
        className={classNames('form-builder-list__item', { 'item--selected': selected })}
        key={id}
        data-form-field-id={id}
        onClick={() => selectFormField(id, selected)}
    >
        <Grid hasGutters={false}>
            <Cell
                isAutoSized
                isContentCentered
                width="35px"
                style={{ alignItems: 'flex-start', cursor: 'grab' }}
            >
                <i className="bs-icon-cursor-move" />
            </Cell>
            <Cell>
                <label className={classNames({ required })}>
                    {label}
                </label>
                {type === TEXT_FORM_FIELD_TYPE
                    ? <input
                        type="text"
                        placeholder={placeholder}
                    />
                    : <DateInput />
                }
            </Cell>
            <Cell
                isAutoSized
                isContentCentered
                width="50px"
                style={{ alignItems: 'flex-end' }}
            >
                <a
                    className="alert"
                    onClick={(e) => {
                        e.stopPropagation();
                        removeFormField(id, selected);
                    }}
                >
                    <i className="bs-icon-cross" />
                </a>
            </Cell>
        </Grid>
    </li>
);

export default FormFieldItem;
