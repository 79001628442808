import React from 'react';
import PropTypes from 'prop-types';
import TitleMenu from 'app/react/components/layout/content/header/TitleMenu';
import ContentHeaderContainer from '../../../components/layout/ContentHeaderContainer';

const { Actions } = TitleMenu;

const OVERVIEW_TAB = 'overview';
const SCHEDULER_TAB = 'scheduler';

const TAB_MAP = {
    [OVERVIEW_TAB]: 'pickups',
    [SCHEDULER_TAB]: 'pickupsScheduler'
};

const PickupsContentHeader = ({
    activeTab,
    userCanDownloadReport = false,
    onDownloadReport
}) => (
    <ContentHeaderContainer activeTab={TAB_MAP[activeTab]}>
        {userCanDownloadReport && (
            <Actions>
                <a className="button primary" onClick={onDownloadReport}>
                    Download schedules
                </a>
            </Actions>
        )}
    </ContentHeaderContainer>
);

PickupsContentHeader.propTypes = {
    activeTab: PropTypes.oneOf([OVERVIEW_TAB, SCHEDULER_TAB]).isRequired,
    userCanDownloadReport: PropTypes.bool,
    onDownloadReport: PropTypes.func.isRequired,
};

export default PickupsContentHeader;
