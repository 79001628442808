import Backbone from 'backbone';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import CompanyAccrediteesOverviewCard from '../accreditees/cards/CompanyAccrediteesOverviewCard.view';
import CompanyItemsCard from '../items/cards/CompanyItemsCard.view';
import CompanyItemGroupCard from '../items/cards/CompanyItemGroupCard.view';
import DenyReasonModal from '../modals/DenyReasonModal.view';
import template from './EventCompanyDetails.hbs';

export default class EventCompanyDetails extends Content {

    get template() {
        return template;
    }

    ui() {
        return {
            accrediteesCard: '#accreditees-card',
            itemsCards: '#items-cards',
            approve: '#approve',
            deny: '#deny'
        };
    }

    events() {
        return {
            'click approve': 'onClickApprove',
            'click deny': 'onClickDeny'
        };
    }

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            accreditees: OptionTypes.instanceOf(CompanyAccreditee.PageableCollection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired
        };
    }

    initialize(options) {
        this.resolveOptions(options);
        // Subviews
        this.accrediteesCard = this.initAccrediteesCard();
        // this.itemsCard = this.initItemsCard();
        this.itemsCards = this.initItemsCards();
        this.confirmModal = this.initConfirmModal();
        this.denyReasonModal = this.initDenyReasonModal();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.assignSubview(this.accrediteesCard, this.ui.accrediteesCard);
        this.appendSubviews(this.itemsCards, this.ui.itemsCards);
        return this.delegateEvents();
    }

    remove() {
        super.remove();
        this.accrediteesCard.remove();
        this.itemsCards.call('remove');
        this.confirmModal.remove();
        this.denyReasonModal.remove();
    }

    viewModel() {
        return {
            view: {
                isAwaitingApproval: this.company.isAwaitingApproval(),
                isApproved: this.company.isApproved() || this.company.areTicketsSent(),
                isDenied: this.company.isDenied()
            }
        };
    }

    initAccrediteesCard() {
        const { accreditees } = this;
        const title = 'Attendees';
        return new CompanyAccrediteesOverviewCard({ accreditees, title });
    }

    initItemsCard() {
        const { company, periods } = this;
        const title = 'Requested items';
        const items = company.items;
        return new CompanyItemsCard({ items, periods, title });
    }

    initItemsCards() {
        const { company, periods } = this;
        const itemGroups = company.getItemGroups();
        const cards = itemGroups.map((itemGroup) => {
            const items = company.getCompanyItemsByItemGroup(itemGroup);
            const editing = false;
            return new CompanyItemGroupCard({ items, itemGroup, periods, editing });
        });
        return new Backbone.ChildViewContainer(cards);
    }

    initDenyReasonModal() {
        return new DenyReasonModal();
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    onClickApprove() {
        const { company } = this;
        const name = company.get('name');
        const email = company.primary_contact.get('email');
        this.confirmModal.open({
            title: 'Approve request',
            body: `
                Are you sure you want approve the request made by <strong>${name}</strong>?
                An approval email will be send to <strong>${email}</strong>.`,
            confirmText: 'Approve',
            autoClose: true,
            confirmClass: 'success',
            syncingText: `Approving Request to ${name}...`,
            confirm: () => {
                company.approve().done(() => {
                    company.fetch({ bustCache: true }).done(() => { this.render(); });
                });
            }
        });
    }

    onClickDeny() {
        const { company } = this;
        const name = company.get('name');
        const email = company.primary_contact.get('email');
        this.denyReasonModal.open({
            title: 'Deny request',
            body: `
                Are you sure you want deny the request made by <strong>${name}</strong>?
                A denied email will be send to <strong>${email}</strong> containing the reason below.`,
            confirmText: 'Deny',
            autoClose: true,
            confirmClass: 'alert',
            syncingText: `Denying request to ${name}...`,
            confirm: (reason) => {
                company.deny(reason).done(() => {
                    company.fetch({ bustCache: true }).done(() => { this.render(); });
                });
            }
        });
    }

}
