export const base = (
    eventId = ':event'
) => `events/${eventId}/artists/bookings`;

export const overview = (
    eventId = ':event'
) => `${base(eventId)}(?*query)`;

export const create = (
    eventId = ':event'
) => `${base(eventId)}/create`;

export const update = (
    eventId = ':event',
    bookingId = ':booking'
) => `${base(eventId)}/${bookingId}/edit`;

export const details = (
    eventId = ':event',
    bookingId = ':booking'
) => `${base(eventId)}/${bookingId}`;

export const detailsTransports = (
    eventId = ':event',
    bookingId = ':booking'
) => `${details(eventId, bookingId)}#transport`;

export const detailsParking = (
    eventId = ':event',
    bookingId = ':booking'
) => `${details(eventId, bookingId)}#parking`;

export const detailsReservations = (
    eventId = ':event',
    bookingId = ':booking'
) => `${details(eventId, bookingId)}#accommodation`;

export const sendItinerary = (
    eventId = ':event',
    bookingId = ':booking'
) => `${details(eventId, bookingId)}/send`;

export const sendLogin = (
    eventId = ':event',
    bookingId = ':booking'
) => `${details(eventId, bookingId)}/send-production-website-login`;

export const createTransport = (
    bookingId = ':booking'
) => `booking/add-transport/${bookingId}`;

export const updateTransport = (
    bookingId = ':booking',
    transportId = ':pickup'
) => `booking/edit-transport/${bookingId}/${transportId}`;

export const updatePickup = (
    bookingId = ':booking',
    pickupId = ':pickup'
) => `booking/edit-pickup/${bookingId}/${pickupId}`;

export const createItineraryContact = (
    bookingId = ':booking',
) => `booking/${bookingId}/add-itinerary-contact`;
