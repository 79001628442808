import {
    getComponentState
} from 'app/react/state/helpers';
import {
    mergeComponentState,
    setComponentState,
    showErrorPopup,
} from 'app/react/state/actions/_index';
import {
    dateIsBetween,
    reformatISOString,
} from 'app/react/helpers/_index';
import * as Pickup from 'app/react/entities/events/bookings/pickups/index';
import * as CreateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/CreateModalState';
import * as UpdateModalState from 'app/react/views/event/artists/bookings/pickups/components/modals/stateful/UpdateModalState';

export const PICKUP_SCHEDULER_VIEW_ID = 'pickupsSchedulerView';
const FAILED_POPUP = (error) =>
    `Failed to update pickup. ${error}`;

const getState = (state) =>
    getComponentState(state, PICKUP_SCHEDULER_VIEW_ID) || {};

const setState = (newState) =>
    setComponentState(PICKUP_SCHEDULER_VIEW_ID, newState);

const mergeState = (newState) =>
    mergeComponentState(PICKUP_SCHEDULER_VIEW_ID, newState);

const updatePickupModalsDriverValue = (driverId) => (dispatch, getState) => {
    const state = getState();
    if (CreateModalState.stateHelpers.isOpen(state)) {
        dispatch(CreateModalState.actions.setStateValue('values.driver', driverId));
    }
    if (UpdateModalState.stateHelpers.isOpen(state)) {
        dispatch(UpdateModalState.actions.setStateValue('values.driver', driverId));
    }
};

const updatePickupModalsVehicleValue = (vehicleId) => (dispatch, getState) => {
    const state = getState();
    if (CreateModalState.stateHelpers.isOpen(state)) {
        dispatch(CreateModalState.actions.setStateValue('values.vehicle', vehicleId));
    }
    if (UpdateModalState.stateHelpers.isOpen(state)) {
        dispatch(UpdateModalState.actions.setStateValue('values.vehicle', vehicleId));
    }
};

const updatePickup = (pickupId, values, revertCallback) => (dispatch, getState) => {
    const state = getState();
    const pickup = Pickup.stateHelpers.getOne(state, pickupId);
    const bookingId = pickup.booking;

    const driver = values.driver === 0 ? null : values.driver;
    const apiValues = {
        ...values,
        driver,
        departure: reformatISOString(values.departure),
        arrival: reformatISOString(values.arrival),
    };
    dispatch(Pickup.thunks.updateOneForCurrentContext(bookingId, pickupId, apiValues))
        .then(({ isFailure, errorMessage }) => {
            if (isFailure) {
                revertCallback();
                dispatch(showErrorPopup(FAILED_POPUP(errorMessage)));
            }
        });
};

const afterPickupCreated = (pickupId) => (dispatch, getStoreState) => {
    const state = getStoreState();
    const pickup = Pickup.stateHelpers.getOne(state, pickupId);
    const { eventDays } = getState(state);
    const daysWhoShouldContainCreatedPickup = eventDays.filter(day =>
        dateIsBetween(pickup.departure, day.start, day.endWithExtraSchedulerHours)
    );
    daysWhoShouldContainCreatedPickup.forEach((day) => {
        const after = day.start;
        const before = day.endWithExtraSchedulerHours;
        dispatch(Pickup.thunks.addPickupToBetweenDatesCollection(pickupId, after, before));
    })
};

export const stateHelpers = {
    getState,
};

export const actions = {
    setState,
    mergeState,
};

export const thunks = {
    updatePickup,
    updatePickupModalsDriverValue,
    updatePickupModalsVehicleValue,
    afterPickupCreated,
};
