import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BackboneView } from 'app/react/components/_index';
import * as stateHelpers from 'app/react/state/helpers';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import EventContentHeader, { TABS } from '../ContentHeader';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import ItemsContentView from 'app/backbone/views/event/settings/accreditation/items/views/EventItemsContent.view';

const { Content } = ContentWrapper;

const EventItemsView = ({
    items,
    itemGroups,
    isLoading,
    eventId
}) => (
    <ContentWrapper>
        <EventContentHeader activeTab={TABS.ITEMS.ID} eventId={eventId} />
        <Content isLoading={isLoading} >
            <BackboneView viewClass={ItemsContentView} items={items} itemGroups={itemGroups} />
        </Content>
    </ContentWrapper>
);

EventItemsView.propTypes = {
    items: PropTypes.instanceOf(EventItem.PageableCollection).isRequired,
    itemGroups: PropTypes.instanceOf(EventItemGroup.PageableCollection).isRequired,
    // State
    isLoading: PropTypes.bool.isRequired,
    eventId: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: stateHelpers.isLoading(state),
    eventId: stateHelpers.events.getCurrentId(state)
});

export default connect(mapStateToProps)(EventItemsView);
