import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'app/react/components/_index';

const TOOLTIPS = {
    FORM: 'Edit form sections',
    DUPLICATE: 'Duplicate',
    UPDATE: 'Edit name',
    DELETE: 'Delete',
    NOT_DELETABLE: `Can't delete: there are still bookings assigned to this artist type`
};

const Row = ({
    artistType = {},
    onGoToForm = () => {},
    onEdit = () => {},
    onDelete = () => {},
    onDuplicate = () => {}
}) => (
    <tr>
        <td>{ artistType.name }</td>
        <td className="actions">
            <Tooltip content={TOOLTIPS.FORM}>
                <a className="action" onClick={() => onGoToForm(artistType.id)}>
                    <i className="bs-icon-globe" />
                </a>
            </Tooltip>
            <Tooltip content={TOOLTIPS.DUPLICATE}>
                <a className="action" onClick={() => onDuplicate(artistType)}>
                    <i className="bs-icon-duplicate" />
                </a>
            </Tooltip>
            <Tooltip content={TOOLTIPS.UPDATE}>
                <a className="action" onClick={() => onEdit(artistType)}>
                    <i className="bs-icon-pencil" />
                </a>
            </Tooltip>
            <Tooltip
                position={artistType.isDeletable ? 'top center' : 'top right'}
                content={artistType.isDeletable ? TOOLTIPS.DELETE : TOOLTIPS.NOT_DELETABLE}
            >
                <a
                    className={classNames('action', {
                        'disabled': !artistType.isDeletable
                    })}
                    onClick={() => artistType.isDeletable && onDelete(artistType.id)}
                >
                    <i className="bs-icon-cross" />
                </a>
            </Tooltip>
        </td>
    </tr>
);

Row.propTypes = {
    artistType: PropTypes.object,
    onGoToForm: PropTypes.func,
    onEdit: PropTypes.func,
    onDuplicate: PropTypes.func
};

const Table = ({
    artistTypes = [],
    onGoToForm = () => {},
    onEdit = () => {},
    onDelete = () => {},
    onDuplicate = () => {}
}) => (
    <table className="overflow actions-right no-margin-tb no-border-last-row">
        <thead>
            <tr>
                <th>Name</th>
                <th />
            </tr>
        </thead>
        <tbody>
            {artistTypes.map((artistType) => (
                <Row
                    key={artistType.id}
                    artistType={artistType}
                    onGoToForm={onGoToForm}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                />
            ))}
        </tbody>
    </table>
);

Table.Row = Row;
Table.propTypes = {
    artistTypes: PropTypes.array,
    onGoToForm: PropTypes.func,
    onEdit: PropTypes.func
};

export default Table;
