import ProfileView from './Profile.view';

export default class ProfileUpdateView extends ProfileView {

    events() {
        return {
            'click .update': 'update'
        };
    }


    initialize(options = {}) {
        super.initialize(options);
        this.isUpdating = true;
        this.syncingText = 'Updating profile...';
    }

    render() {
        super.render();
        this.model.getRelation('days').each((day) => {
            this.days.get(day.id).trigger('backgrid:select', day, true);
        });
        this.model.getRelation('zones').each((zone) => {
            this.zones.get(zone.id).trigger('backgrid:select', zone, true);
        });
    }

    update() {
        this.model.days.reset(this.daysCard.content.getSelectedModels());
        this.model.zones.reset(this.zonesCard.content.getSelectedModels());
        this.model.validate();
        if (this.model.isValid()) {
            this.model.save();
        }
    }

    onSync() {
        this.unbindModel();
        this.profiles.bustCache();
        this.backToOverview();
    }

}
