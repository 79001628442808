import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import GeneralCardContent from './GeneralCardContent.view';

export default class GeneralCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options);
    }

    initContent() {
        const { company } = this;
        return new GeneralCardContent({ company });
    }

    getUniqueName() {
        const { company } = this;
        return `generalCard${company.event.id + company.id}`;
    }
}
