import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const CELL_TEXT = 'Changeover';

const ChangeoverRow = ({
    start,
    changeover,
    dateFormat,
    timeFormat
}) => {
    const mEnd = moment.utc(start);
    const mStart = mEnd.clone();
    mStart.subtract(changeover, 'minutes');
    return (
        <tr>
            <td>
                {CELL_TEXT}
            </td>
            <td>
                {mStart.format(dateFormat)}
            </td>
            <td>
                {mStart.format(timeFormat)}
            </td>
            <td>
                {mEnd.format(dateFormat)}
            </td>
            <td>
                {mEnd.format(timeFormat)}
            </td>
            <td />
        </tr>
    );
};

ChangeoverRow.propTypes = {
    start: PropTypes.string,
    changeover: PropTypes.number,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string
};

export default ChangeoverRow;
