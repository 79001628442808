import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_EVENT_ARTISTS_GROUP_REQUEST';
export const ONE_SUCCESS = 'ONE_EVENT_ARTISTS_GROUP_SUCCESS';
export const ONE_FAILURE = 'ONE_EVENT_ARTISTS_GROUP_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_REQUEST = 'ALL_EVENT_ARTISTS_GROUPS_REQUEST';
export const ALL_SUCCESS = 'ALL_EVENT_ARTISTS_GROUPS_SUCCESS';
export const ALL_FAILURE = 'ALL_EVENT_ARTISTS_GROUPS_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const fetchAll = (accountId, eventId) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId),
        schema: Schemas.entities
    },
    accountId,
    eventId
});

export const createOne = (accountId, eventId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId),
        schema: Schemas.entity
    },
    accountId,
    eventId
});

export const updateOne = (accountId, eventId, groupId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOne(eventId, groupId),
        schema: Schemas.entity
    },
    accountId,
    eventId
});


export const deleteOne = (accountId, eventId, groupId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, groupId),
        schema: Schemas.entity,
        id: groupId
    },
    accountId,
    eventId
});



