import React, {Fragment} from 'react'
import moment, {ISO_8601} from 'moment'
import {AvatarWithTooltip, Badge, TableActionsDropdown, TableCell, TableRow, Tooltip,} from 'ui-kit'
import {isCrew, isGuest, isPerformer,} from 'app/react/helpers/graphQlTypes'
import Flag from 'app/react/components/util/Flag'
import RemoveAttendeeModal from './modals/RemoveAttendeeModalWithMutation'
import CheckInWarningModal from './modals/CheckInWarningModal'
import {ATTENDEE_TYPE_CREW, ATTENDEE_TYPE_GUEST, ATTENDEE_TYPE_PERFORMER,} from './TopBar'
import CheckInCheckboxCell from './CheckInCheckboxCell'
import DetailDrawer from './DetailDrawer'

const ATTENDEE_TYPE_NAMES = {
    Performer: 'Performer',
    CrewMember: 'Crew',
    Guest: 'Guest',
};

function formatDateTime(isoString, format) {
    return moment.parseZone(isoString, ISO_8601).format(format);
}

function type(attendee) {
    if (isPerformer(attendee)) {
        return ATTENDEE_TYPE_PERFORMER;
    }

    if (isCrew(attendee)) {
        return ATTENDEE_TYPE_CREW;
    }

    if (isGuest(attendee)) {
        return ATTENDEE_TYPE_GUEST;
    }

    return undefined;
}

const RelativeContainer = ({ children }) => (
    <div style={{ position: 'relative' }}>
        {children}
    </div>
);

const Row = ({
    eventId,
    attendee,
    checkedInAt,
    isTogglingCheckIn,
    dateFormat,
    dateFormatShort,
    timeFormat,
    onToggleCheckIn,
    onDownloadPassport,
    onGoToBooking,
    onEditAttendee,
    onDeleteAttendee,
    onClick,
}) => {
    const {
        firstName,
        lastName,
        role,
        email,
        booking,
        passport,
    } = attendee;
    const { artist, performance } = booking;
    const { country } = artist;

    function onConfirm({ closeModal }) {
        closeModal();
        onToggleCheckIn();
    }

    return (
        <TableRow isSelectable onClick={onClick}>
            <CheckInWarningModal
                eventId={eventId}
                attendee={attendee}
                onConfirm={onConfirm}
                renderTrigger={({
                    openModal,
                    isDismissed,
                    isPassedCheckInDay,
                    isFutureCheckInDay,
                }) => (
                    <CheckInCheckboxCell
                        isLoading={isTogglingCheckIn}
                        attendee={attendee}
                        checkedInAt={checkedInAt}
                        dateFormat={dateFormat}
                        dateFormatShort={dateFormatShort}
                        timeFormat={timeFormat}
                        onToggleCheckIn={isDismissed || checkedInAt || (!isPassedCheckInDay && !isFutureCheckInDay)
                            ? onToggleCheckIn
                            : openModal
                        }
                    />
                )}
            />
            <TableCell isCentered className="overflowVisible">
                <RelativeContainer>
                    <AvatarWithTooltip
                        name={artist.name}
                        imageUrl={artist.image ? artist.image.url : null}
                    />
                    {country && (
                        <Tooltip content={country.name}>
                            <Flag code={country.code} isBadge />
                        </Tooltip>
                    )}
                </RelativeContainer>
            </TableCell>
            <TableCell>
                {artist.name}
            </TableCell>
            <TableCell>
                <div>{firstName} {lastName}</div>
                <small>
                    {ATTENDEE_TYPE_NAMES[attendee.__typename]}
                    {role && (
                        <Fragment> - {role}</Fragment>
                    )}
                </small>
            </TableCell>
            <TableCell>
                {email}
            </TableCell>
            <TableCell>
                {performance && performance.stage && (
                    <Fragment>
                        <div>{performance.stage.name}</div>
                        <small>
                            {performance.day && (<Fragment>{performance.day.name} - </Fragment>)} {formatDateTime(performance.start, timeFormat)} - {formatDateTime(performance.end, timeFormat)}
                        </small>

                    </Fragment>
                )}
            </TableCell>
            <TableCell>
                {attendee.ticketType && (
                    <Badge>{attendee.ticketType.name}</Badge>
                )}
            </TableCell>
            <TableCell>
                {!attendee.day && performance && performance.day && (
                    <Badge>{performance.day.name}</Badge>
                )}
                {attendee.day && (
                    <Badge>{attendee.day.name}</Badge>
                )}
            </TableCell>
            <TableCell.Actions>
                <RemoveAttendeeModal
                    attendee={attendee}
                    onDelete={onDeleteAttendee}
                    renderTrigger={({ isOpen, openModal }) => (
                        <TableActionsDropdown isOpen={isOpen || false}>
                            {passport && (
                                <TableActionsDropdown.Action onClick={(e) => {
                                    onDownloadPassport(passport.uuid);
                                    e.stopPropagation();
                                }}>
                                    <i className="bs-icon-arrow-down-box" /> Download passport
                                </TableActionsDropdown.Action>
                            )}
                            <TableActionsDropdown.Action onClick={(e) => {
                                onGoToBooking(booking.id, e.ctrlKey || e.metaKey);
                                e.stopPropagation();
                            }}>
                                <i className="bs-icon-link" /> Go to booking
                            </TableActionsDropdown.Action>
                            <TableActionsDropdown.Action onClick={(e) => {
                                onEditAttendee(eventId, booking.id, attendee.id, type(attendee), e.ctrlKey || e.metaKey);
                                e.stopPropagation();
                            }}>
                                <i className="bs-icon-pencil" /> Edit
                            </TableActionsDropdown.Action>
                            <TableActionsDropdown.Action onClick={(e) => {
                                e.stopPropagation();
                                openModal();
                            }}>
                                <i className="bs-icon-bin" /> Remove
                            </TableActionsDropdown.Action>
                        </TableActionsDropdown>
                    )}
                />
            </TableCell.Actions>
        </TableRow>
    );
};

const RowWithDrawer = (props) => (
    <DetailDrawer
        {...props}
        renderTrigger={({ onOpenDrawer }) => (
            <Row
                {...props}
                onClick={onOpenDrawer}
            />
        )}
    >
    </DetailDrawer>
);

export default RowWithDrawer;
