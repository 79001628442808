import OptionTypes from 'app/backbone/helpers/OptionTypes';
import AccrediteeProfilesCard from 'app/backbone/views/event/accreditation/accreditees/profiles/cards/AccrediteeProfilesCard.view';
import CompanyAccrediteeProfileModalAdd from '../modals/CompanyAccrediteeProfileModalAdd.view';
import CompanyAccrediteeProfileTable from '../tables/CompanyAccrediteeProfileTable.view';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import * as CompanyAccrediteeProfile from 'app/backbone/models/event/accreditation/companies/CompanyAccrediteeProfile';

export default class CompanyAccrediteeProfilesCard extends AccrediteeProfilesCard {

    optionTypes() {
        const { title, periods, profiles } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(CompanyAccreditee.Model).isRequired,
            profiles,
            periods,
            title
        };
    }

    addInitialProfiles() {
        this.profiles.forEach((profile) => {
            const newAccrediteeProfile = new CompanyAccrediteeProfile.Model(profile.attributes);
            newAccrediteeProfile.setRelation('available_profile', profile);
            this.model.getRelation('profiles').add(newAccrediteeProfile);
        });
    }

    initContent() {
        const { profiles, periods, model: { profiles: collection } } = this;
        const placeholderHeight = 100;
        return new CompanyAccrediteeProfileTable({ collection, profiles, periods, placeholderHeight });
    }

    initAddModel() {
        const profiles = this.getModalProfiles();
        const addModal = new CompanyAccrediteeProfileModalAdd({ profiles });
        this.listenTo(addModal, 'add', this.onAdded);
        return addModal;
    }

}
