import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import template from './CardAction.hbs';

export default class CardAction extends BaseView {

    get template() {
        return template;
    }

    tagName() {
        return 'a';
    }

    events() {
        return {
            'click': 'onClick'
        };
    }

    optionTypes() {
        return {
            action: OptionTypes.func.isRequired,
            icon: OptionTypes.string.isRequired,
            type: OptionTypes.oneOf(['header', 'footer']).isRequired,
            tooltip: OptionTypes.string,
            extraClassName: OptionTypes.string,
            text: OptionTypes.string,
            canRender: OptionTypes.func,
            isHidden: OptionTypes.func,
            isDisable: OptionTypes.func
        };
    }

    optionDefaults() {
        return {
            canRender: () => true,
            isHidden: () => false,
            isDisable: () => false
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.hidden = this.isHidden();
        this.disabled = this.isDisable();
        // Subviews

        if (this.tooltip) {
            const content = this.tooltip;
            const position = 'top right';
            this.tooltip = this.initTooltip({ content, position });
        }
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();

        this.rendered = true;

        this.addClassNames();
        this.toggleHide(this.hidden);
        this.toggleDisable(this.disabled);

        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {
                icon: this.icon,
                text: this.text,
                hidden: this.hidden,
                disabled: this.disabled
            }
        };
    }

    remove() {
        super.remove();
        this.removeTooltip();
    }

    addClassNames() {
        if (this.rendered) {
            switch(this.type) {
                case 'header': {
                    if (this.text) this.$el.addClass('card--header_action-text');
                    else this.$el.addClass('card--header_action');
                    break;
                }
                case 'footer': {
                    if (this.text) this.$el.addClass('card--footer_action-text');
                    else this.$el.addClass('card--footer_action');
                    break;
                }
            }
            this.$el.addClass(this.extraClassName);
        }
    }

    toggleHide(hidden) {
        this.hidden = hidden;
        if (this.rendered) this.$el.css('display', hidden ? 'none' : '');
    }

    toggleDisable(disabled) {
        this.disabled = disabled;
        if (this.rendered) this.$el.toggleClass('disabled', disabled);
    }

    show() {
        this.toggleHide(false);
    }

    hide() {
        this.toggleHide(true);
    }

    enable() {
        this.toggleDisable(false);
    }

    disable() {
        this.toggleDisable(true);
    }

    onClick(e) {
        e.stopPropagation();
        this.action();
    }

}
