import React from 'react';
import Loading from 'ui-kit/lib/components/modal/Loading';

const ModalContent = ({ children, syncing }) => (
    <div className="modalContentContainer">
        {syncing
            ? <Loading/>
            : children
        }
    </div>
);

export default ModalContent;
