import { connect } from 'react-redux';
import BookingItemsOverview from './Overview';
import { mapEventContextToProps, mapBookingContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as ItemModalState from '../components/stateful/ItemModalState';
import * as Account from 'app/react/entities/accounts/index';
import * as EventItem from 'app/react/entities/events/artists/items/index';
import * as EventItemGroup from 'app/react/entities/events/artists/item_groups/index';
import * as BookingItem from 'app/react/entities/events/bookings/items/index';
import * as Event from 'app/react/entities/events/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses'

const mapStateToProps = (state) => {
    return {
        ...mapEventContextToProps(state),
        ...mapBookingContextToProps(state),
        isFetching: (
            EventItem.stateHelpers.isFetchingAllForCurrentContext(state) ||
            EventItemGroup.stateHelpers.isFetchingAllForCurrentContext(state) ||
            BookingItem.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Booking.stateHelpers.isFetchingCurrent(state) ||
            BookingStatus.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        eventItems : EventItem.stateHelpers.getAllForCurrentContext(state),
        eventGroups: EventItemGroup.stateHelpers.getAllForCurrentContext(state),
        bookingItems: BookingItem.stateHelpers.getAllForCurrentContext(state),
        permissions: Event.stateHelpers.getUserPermissions(state),
        accountPermissions: Account.stateHelpers.getUserPermissions(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onOpenCreateItemModal: (itemId) => {
        const { bookingItems, eventItems } = stateProps;
        const isCreatingNewItem = bookingItems.length === eventItems.length;
        dispatchProps.onOpenCreateItemModal(isCreatingNewItem);
    },
    onOpenUpdateItemModal: (itemId) => {
        const { bookingItems, eventItems } = stateProps;
        const item = bookingItems.find(({ id }) => id === itemId);
        const isCreatingNewItem = bookingItems.length === eventItems.length;
        dispatchProps.onOpenUpdateItemModal(item, isCreatingNewItem);
    },
    onOpenDeleteItemModal: (itemId) => {
        const { bookingItems } = stateProps;
        const item = bookingItems.find(({ id }) => id === itemId);
        dispatchProps.onOpenDeleteItemModal(item);
    },
});

const mapDispatchToProps = (dispatch) => ({
    onOpenCreateItemModal: (isCreatingNewItem = false) => {
        dispatch(ItemModalState.mergeCreateModalState({
            isOpened: true,
            isCreatingNewItem,
            values: {},
            errors: {}
        }))
    },
    onOpenUpdateItemModal: (item) => {
        dispatch(ItemModalState.mergeUpdateModalState({
            isOpened: true,
            itemId: item.id,
            values: { ...item },
            errors: {}
        }))
    },
    onOpenDeleteItemModal: (item) => {
        dispatch(ItemModalState.mergeDeleteModalState({
            isOpened: true,
            item,
        }))
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(BookingItemsOverview);
