import React from 'react';
import {parseQueryString} from 'app/react/helpers/_index';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Event from 'app/react/entities/events/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Crew from 'app/react/entities/events/bookings/crew/index';
import * as File from 'app/react/entities/files/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as TicketType from 'app/react/entities/events/ticket_types/index';
import * as PWArtistSettings from 'app/react/entities/events/production_website/settings/artists/index';
import * as PWArtistSettingsSection from 'app/react/entities/events/production_website/settings/artists/sections/index';
import CrewCreateView from 'app/react/views/event/artists/bookings/crew/views/create/CreateViewContainer';
import CrewUpdateView from 'app/react/views/event/artists/bookings/crew/views/update/UpdateViewContainer';
import * as UpdateViewState from 'app/react/views/event/artists/bookings/crew/views/update/State';
import * as Routes from './crewRoutes';

export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.create(), null, this.create.bind(this));
        this.router.route(Routes.update(), null, this.update.bind(this));
    }

    create(eventId, bookingId, queryString) {
        const { app, store, dispatch } = this;
        const { redirect } = queryString !== null ? parseQueryString(queryString) : {};
        app.renderContentComponent(React.createElement(CrewCreateView, { store, redirect }));
        // Define the working context
        // Current accountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(Booking.actions.setCurrentId(parseInt(bookingId, 10)));
        // Current Booking is set from DOM in the initial state
        const currentBooking = Booking.stateHelpers.getCurrent(store.getState());
        const artistTypeId = currentBooking.artistType;
        dispatch(ArtistType.actions.setCurrentId(artistTypeId));
        dispatch(PWArtistSettingsSection.actions.setCurrentId('crew'));

        return dispatch(ArtistType.thunks.fetchOneForCurrentContext(artistTypeId)).then(() => {
            return Promise.all([
                dispatch(TicketType.thunks.fetchAllForCurrentContext()),
                dispatch(PWArtistSettings.thunks.fetchCurrent())
            ]);
        });
    }

    update(eventId, bookingId, crewId, queryString) {
        const { app, store, dispatch } = this;
        const { redirect } = queryString !== null ? parseQueryString(queryString) : {};
        app.renderContentComponent(React.createElement(CrewUpdateView, { store, redirect }));
        // Define the working context
        // Current accountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(Booking.actions.setCurrentId(parseInt(bookingId, 10)));
        dispatch(Crew.actions.setCurrentId(parseInt(crewId, 10)));
        // Current Booking is set from DOM in the initial state
        const currentBooking = Booking.stateHelpers.getCurrent(store.getState());
        const artistTypeId = currentBooking.artistType;

        dispatch(ArtistType.actions.setCurrentId(artistTypeId));
        dispatch(PWArtistSettingsSection.actions.setCurrentId('crew'));

        return Promise.all([
            dispatch(ArtistType.thunks.fetchOneForCurrentContext(artistTypeId)).then(() => {
                return dispatch(PWArtistSettings.thunks.fetchCurrent());
            }),
            dispatch(Crew.thunks.fetchCurrent(['passport'])).then(() => {
                const state = store.getState();
                const currentCrew = Crew.stateHelpers.getCurrent(state);
                const selectedPassport = File.stateHelpers.getOne(state, currentCrew.passport);
                dispatch(UpdateViewState.mergeViewState({
                    values: currentCrew,
                    selectedPassport
                }));
            }),
            dispatch(TicketType.thunks.fetchAllForCurrentContext())
        ]);
    }

}
