import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import { CALL_GRAPHQL_API } from 'app/react/state/middleware/graphql'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_VEHICLES_REQUEST';
export const ONE_SUCCESS = 'ONE_VEHICLES_SUCCESS';
export const ONE_FAILURE = 'ONE_VEHICLES_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_REQUEST = 'ALL_VEHICLES_REQUEST';
export const ALL_SUCCESS = 'ALL_VEHICLES_SUCCESS';
export const ALL_FAILURE = 'ALL_VEHICLES_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const ALL_PAGINATED_REQUEST = 'ALL_VEHICLES_PAGINATED_REQUEST';
export const ALL_PAGINATED_SUCCESS = 'ALL_VEHICLES_PAGINATED_SUCCESS';
export const ALL_PAGINATED_FAILURE = 'ALL_VEHICLES_PAGINATED_FAILURE';
export const ALL_PAGINATED_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const fetchAll = (accountId, eventId) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId),
        schema: Schemas.entities
    },
    accountId,
    eventId
});

export const fetchAllPaginated = (query, variables = {}) => {
    const { eventId, accountId } = variables;
    return {
        [CALL_GRAPHQL_API]: {
            types: ALL_PAGINATED_TYPES,
            query,
            variables,
            schemaMapping: {
                vehicles: Schemas.entities
            }
        },
        accountId,
        eventId,
    };
};

export const createOne = (accountId, eventId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId),
        schema: Schemas.entity
    },
    accountId,
    eventId
});

export const updateOne = (accountId, eventId, vehicleId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOne(eventId, vehicleId),
        schema: Schemas.entity
    },
    accountId,
    eventId
});


export const deleteOne = (accountId, eventId, vehicleId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, vehicleId),
        schema: Schemas.entity,
        id: vehicleId
    },
    accountId,
    eventId
});
