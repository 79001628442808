import React from 'react';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { reduxStore } from 'app/react/state/_index';
import { apolloClient } from 'app/react/state/data_sources/graphql';
import Header from './header/Header';
import Content from './content/Content';
import Footer from './footer/Footer';

const ContentWrapper = ({
    showFooter = true,
    children
}) => (
    <ApolloProvider client={apolloClient}>
        <ReduxProvider store={reduxStore}>
            <div id="content-inner-wrapper">
                { children }
                { showFooter &&
                    <Footer/>
                }
            </div>
        </ReduxProvider>
    </ApolloProvider>
);

ContentWrapper.propTypes = {
    showFooter: PropTypes.bool,
    children: PropTypes.node,
};

ContentWrapper.Header = Header;
ContentWrapper.Content = Content;
ContentWrapper.Footer = Footer;

export default ContentWrapper;
