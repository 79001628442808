import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Switch = ({
    id,
    checked = false,
    reverse = false,
    disabled = false,
    tiny = false,
    lock = false,
    uncheckedYellow = false,
    style = {},
    onChange = () => {},
    onClick = () => {}
}) => (
    <div
        className={classNames('switch', { tiny, disabled, 'unchecked-yellow': uncheckedYellow })}
        style={{ ...style }}
    >
        <input
            type="checkbox"
            id={id}
            checked={reverse ? !checked : checked}
            disabled={disabled}
            onChange={e => onChange(reverse ? !e.target.checked : e.target.checked)}
            onClick={onClick}
        />
        <label htmlFor={id} className={classNames({ lock })} />
    </div>
);

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    reverse: PropTypes.bool,
    disabled: PropTypes.bool,
    tiny: PropTypes.bool,
    lock: PropTypes.bool,
    uncheckedYellow: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onClick: PropTypes.func
};

export default Switch;
