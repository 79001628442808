import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    InputGroup,
    PostfixedInput,
} from 'ui-kit';
import { Modal, DebouncedInput } from 'app/react/components/_index';

const { Header, Content, Footer } = Modal;
const { Cell } = Grid;

const CAR_TYPE_LABEL = 'Car type';
const NUMBER_PASSENGER_LABEL = 'Number of passengers (excl driver)';
const MAKE_LABEL = 'Make';
const MODEL_LABEL = 'Model';
const LICENCE_PLATE_LABEL = 'License plate';
const MILEAGE_IN_LABEL = 'Mileage in';
const MILEAGE_OUT_LABEL = 'Mileage out';
const NOTES_LABEL = 'Notes';

const CREATE_TITLE = 'Create vehicle';
const CREATE_BUTTON_TEXT = 'Create';
const CREATE_ANOTHER_BUTTON_TEXT = 'Create another';

const UPDATE_TITLE = 'Update vehicle';
const UPDATE_BUTTON_TEXT = 'Update';

const SELECT_TYPE_OPTION = {
    id: '',
    name: 'Select type',
};

const VehicleModal = ({
    isCreating = false,
    canCreateAnother = true,
    isOpened = false,
    isSaving = false,
    values = {},
    errors = {},

    unitOfDistance = 'km',
    vehicleTypes = [],

    onClosed = () => {},
    onChangeValue = () => {},
    onSave,
}) => (
    <Modal
        width={560}
        closeOnBackgroundClick={false}
        opened={isOpened}
        syncing={isSaving}
        onClosed={onClosed}
    >
        <Header onClosed={onClosed}>
            <Modal.Header.Title>{isCreating ? CREATE_TITLE : UPDATE_TITLE}</Modal.Header.Title>
        </Header>
        <Content scrollable>
            <Grid className="margin-tiny-bottom">
                <Cell>
                    <InputGroup messages={errors.type}>
                        <label className="required">
                            {CAR_TYPE_LABEL}
                        </label>
                        <select
                            value={values.type || ''}
                            onChange={e => onChangeValue('type', e.target.value)}
                        >
                            {[SELECT_TYPE_OPTION, ...vehicleTypes].map(type => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors.numberOfPassengers}>
                        <label className="required">
                            {NUMBER_PASSENGER_LABEL}
                        </label>
                        <DebouncedInput
                            type="number"
                            value={values.numberOfPassengers || ''}
                            onChange={value => onChangeValue('numberOfPassengers', value)}
                        />
                    </InputGroup>
                </Cell>
            </Grid>
            <Grid className="margin-tiny-bottom">
                <Cell>
                    <InputGroup messages={errors.make}>
                        <label className="required">
                            {MAKE_LABEL}
                        </label>
                        <DebouncedInput
                            type="text"
                            value={values.make || ''}
                            onChange={value => onChangeValue('make', value)}
                        />
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors.model}>
                        <label className="required">
                            {MODEL_LABEL}
                        </label>
                        <DebouncedInput
                            type="text"
                            value={values.model || ''}
                            onChange={value => onChangeValue('model', value)}
                        />
                    </InputGroup>
                </Cell>
            </Grid>
            <InputGroup messages={errors.licencePlate} style={{ width: '50%', paddingRight: '0.5rem' }}>
                <label>
                    {LICENCE_PLATE_LABEL}
                </label>
                <DebouncedInput
                    type="text"
                    value={values.licencePlate || ''}
                    onChange={value => onChangeValue('licencePlate', value)}
                />
            </InputGroup>
            <Grid className="margin-tiny-bottom">
                <Cell>
                    <InputGroup messages={errors.mileageIn}>
                        <label>
                            {MILEAGE_IN_LABEL}
                        </label>
                        <PostfixedInput label={unitOfDistance}>
                            <DebouncedInput
                                type="text"
                                value={values.mileageIn || ''}
                                onChange={value => onChangeValue('mileageIn', value)}
                            />
                        </PostfixedInput>
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors.mileageOut}>
                        <label>
                            {MILEAGE_OUT_LABEL}
                        </label>
                        <PostfixedInput label={unitOfDistance}>
                            <DebouncedInput
                                type="text"
                                value={values.mileageOut || ''}
                                onChange={value => onChangeValue('mileageOut', value)}
                            />
                        </PostfixedInput>
                    </InputGroup>
                </Cell>
            </Grid>
            <InputGroup messages={errors.notes}>
                <label>
                    {NOTES_LABEL}
                </label>
                <textarea
                    value={values.notes || ''}
                    onChange={e => onChangeValue('notes', e.target.value)}
                />
            </InputGroup>
        </Content>
        {isCreating 
            ? <Footer>
                {canCreateAnother &&
                    <Footer.Button
                        className="create-another"
                        onClick={() => onSave(true, values)}
                    >
                        {CREATE_ANOTHER_BUTTON_TEXT}
                    </Footer.Button>
                }
                <Footer.Button
                    className="success"
                    onClick={() => onSave(false, values)}
                >
                    {CREATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer> 
            : <Footer>
                <Footer.Button
                    className="success"
                    onClick={() => onSave(values)}
                >
                    {UPDATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
        }
    </Modal>
);

VehicleModal.propTypes = {
    isCreating: PropTypes.bool,
    canCreateAnother: PropTypes.bool,
    isOpened: PropTypes.bool,
    isSaving: PropTypes.bool,
    values: PropTypes.object,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
    onCreateAnother: PropTypes.func,
};

export default VehicleModal;
