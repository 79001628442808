import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from 'ui-kit'
import MainAdvancingContactsIcon from "./MainAdvancingContactsIcon";

const MainAdvancingContactsCell = ({
    contacts = []
 }) => (
    <TableCell isCentered>
        {contacts.length > 0 && (<MainAdvancingContactsIcon
            contacts={contacts}
        />)}
    </TableCell>
);

MainAdvancingContactsCell.propTypes = {
    contacts: PropTypes.array
};

export default MainAdvancingContactsCell;
