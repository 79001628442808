import { schema } from 'normalizr';

export const ENTITY_REDUCER_KEY = 'accountArtistItemGroups';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

export const processStrategy = entity => ({
    ...entity,
    parent: typeof entity.parent === 'undefined' ? null : entity.parent
});

const entityDefinition = {};
const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);

export const toAPI = (entity, groups) => {
    if (entity.parent === null || typeof entity.parent === 'undefined') {
        const parentGroups = groups.filter(({ parent }) => parent === null);
        return {
            ...entity,
            parent: null,
            sequence: parentGroups.length + 1
        }
    }
    const otherChildren = groups.filter(group => group.parent === entity.parent);
    return {
        ...entity,
        sequence: otherChildren.length + 1
    }
}
