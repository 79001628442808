var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <form>\n        "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"name",{"name":"inArray","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "        <div class=\"grid grid--gutters no-margin-bottom\">\n            "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"start_date",{"name":"inArray","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "            "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"end_date",{"name":"inArray","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n    </form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"input-group\">\n                <label class=\"required\">Name</label>\n                <input type=\"text\" name=\"name\"/>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "\n                <div class=\"grid-cell input-group\">\n                    <label class=\"required\">Start Date</label>\n                    <input type=\"text\" name=\"start_date\" class=\"datepicker\">\n                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "\n                <div class=\"grid-cell input-group\">\n                    <label class=\"required\">End Date</label>\n                    <input type=\"text\" name=\"end_date\" class=\"datepicker\">\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.SyncModal,depth0,{"name":"SyncModal","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});