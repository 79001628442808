import BaseView from 'app/backbone/components/Base.view'
import ColorPicker from 'app/backbone/components/forms/ColorPicker.view'

export default class GeneralOverview extends BaseView {

    ui() {
        return {
            colorPicker: '.colorPicker',
        };
    }

    initialize(options = {}) {
        super.initialize(options);
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.initViewsForEachEl(ColorPicker, this.ui.colorPicker).forEach(view => view.render());

        return this.delegateEvents();
    }

}

