import React from 'react'
import PropTypes from 'prop-types'
import { TableSortCell, TableHeaderCell } from 'ui-kit'

const ReservationsTableHeaderRow = ({
    sortKey,
    sortOrder,
    onSort = () => {},
    isApprovalRequiredSomewhere,
}) => (
    <tr>
        <TableSortCell
            isCentered
            style={{ width: '60px' }}
            sortKey="is_checked_in"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        />
        <TableSortCell
            sortKey="booking.artist.name"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Artist
        </TableSortCell>
        <TableSortCell
            sortKey="name"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Name
        </TableSortCell>
        <TableSortCell
            isCentered
            sortKey="guests"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Guests
        </TableSortCell>
        <TableSortCell
            sortKey="accommodation"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Accommodation
        </TableSortCell>
        <TableSortCell
            sortKey="room_type"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Room type
        </TableSortCell>
        <TableSortCell
            sortKey="nights_paid_by_promoter"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
            isCentered
        >
            Nights paid
        </TableSortCell>
        <TableSortCell
            style={{ width: '135px' }}
            sortKey="check_in"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Check in
        </TableSortCell>
        <TableSortCell
            style={{ width: '135px' }}
            sortKey="check_out"
            currentSortKey={sortKey}
            currentSortOrder={sortOrder}
            onSort={onSort}
        >
            Check out
        </TableSortCell>
        {isApprovalRequiredSomewhere && (
            <TableSortCell
                isCentered
                style={{ width: '40px' }}
                sortKey="status"
                currentSortKey={sortKey}
                currentSortOrder={sortOrder}
                onSort={onSort}
            />
        )}
        <TableHeaderCell style={{ width: '40px' }} />
    </tr>
);

ReservationsTableHeaderRow.propTypes = {
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func
};

export default ReservationsTableHeaderRow;
