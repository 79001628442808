import { connect } from 'react-redux';
import { mapAccountContextToProps, mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as BookingAttendees from 'app/react/entities/events/bookings/attendees/index';
import * as Driver from 'app/react/entities/events/drivers/index';
import * as Vehicle from 'app/react/entities/events/vehicles/index';
import * as PickupMode from 'app/react/entities/events/bookings/pickups/modes/index';
import * as EventLocation from 'app/react/entities/events/locations/index';
import * as EventRoutes from 'app/react/entities/events/locations/routes/index';
import * as CreateVehicleModalState from 'app/react/views/event/artists/logistics/vehicles/components/modals/stateful/CreateModalState';
import * as DriverModalState from 'app/react/views/event/artists/logistics/drivers/components/ModalState';
import { getEventRouteFormValues } from '../../../util/index';
import Modal from '../Modal';
import * as ModalState from './CreateModalState';

const mapStateToProps = (state) => {
    const {
        isOpened,
        isLoading,
        isSaving,
        pickupId,
        bookingId,
        isSettingCustomDepartureName,
        isSettingCustomArrivalName,
        values = {},
        errors = {},
    } = ModalState.stateHelpers.getState(state);
    return {
        ...mapAccountContextToProps(state),
        ...mapEventContextToProps(state),
        isCreating: true,
        isOpened,
        isLoading,
        isSaving,
        isSettingCustomDepartureName,
        isSettingCustomArrivalName,
        values,
        errors,
        pickupId,
        bookingId,
        modes: PickupMode.stateHelpers.getAll(state),
        drivers: Driver.stateHelpers.getAllForCurrentContext(state),
        vehicles: Vehicle.stateHelpers.getAllForCurrentContext(state),
        locations: EventLocation.stateHelpers.getAllForCurrentContext(state),
        bookingAttendees: BookingAttendees.stateHelpers.getAllForCurrentContext(state, values.booking),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onChangeDeparture: (departure) => {
        const { values } = stateProps;
        dispatchProps.onChangeDeparture(departure, values.arrival);
    },
    onSelectArrivalLocation: (value) => {
        const { eventId, values } = stateProps;
        dispatchProps.onSelectArrivalLocation(value, eventId, values.departureLocation, values.departure);
    },
    onSelectPassenger: (attendeeId, isSelected) => {
        const { values } = stateProps;
        dispatchProps.onSelectPassenger(attendeeId, isSelected, values.passengers);
    },
    onSelectAllPassengers: (isSelectingAll) => {
        const { bookingAttendees } = stateProps;
        dispatchProps.onSelectAllPassengers(isSelectingAll, bookingAttendees);
    },
    onSave: (isCreatingAnother) => {
        const { values } = stateProps;
        dispatchProps.onSave(isCreatingAnother, values);
    },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClosed: () => {
        dispatch(ModalState.thunks.close());
    },
    onSelectBooking: (bookingId) => {
        dispatch(ModalState.actions.setStateValue('values.booking', bookingId));
        dispatch(BookingAttendees.thunks.fetchAllForCurrentContext(bookingId))
    },
    onChangeValue: (key, value) => {
        dispatch(ModalState.actions.setStateValue(`values.${key}`, value));
    },
    onChangeDeparture: (departure, arrival) => {
        dispatch(ModalState.actions.setStateValue('values.departure', departure));
        if (typeof arrival === 'undefined') {
            dispatch(ModalState.actions.setStateValue('values.arrival', departure));
        }
    },
    onSelectDepartureLocation: (value) => {
        dispatch(ModalState.actions.setStateValue('values.departureLocation', value));
    },
    onSelectArrivalLocation: (value, eventId, departureLocation, departure) => {
        dispatch(ModalState.actions.setStateValue('values.arrivalLocation', value));
        dispatch(EventRoutes.actions.fetchDirections(eventId, departureLocation, value))
            .then(({ isSuccess, response }) => {
                if (isSuccess) {
                    const routeFormValues = getEventRouteFormValues(response, response.result, departure);
                    dispatch(ModalState.actions.mergeStateValue('values', routeFormValues));
                }
            })
    },
    onToggleSetCustomDepartureName: (setCustomName) => {
        dispatch(ModalState.actions.setStateValue('isSettingCustomDepartureName', setCustomName));
        dispatch(ModalState.actions.deleteStateValue(setCustomName ? 'values.departureLocation' : 'values.departureLocationName'));
    },
    onToggleSetCustomArrivalName: (setCustomName) => {
        dispatch(ModalState.actions.setStateValue('isSettingCustomArrivalName', setCustomName));
        dispatch(ModalState.actions.deleteStateValue(setCustomName ? 'values.arrivalLocation' : 'values.arrivalLocationName'));
    },
    onSelectPassenger: (attendeeId, isSelected, selectedPassengers = []) => {
        const passengers = isSelected ?
            [...selectedPassengers, attendeeId] :
            selectedPassengers.filter(id => id !== attendeeId);
        dispatch(ModalState.actions.mergeStateValue('values', {
            passengers,
            passengerAmount: passengers.length
        }));
    },
    onSelectAllPassengers: (isSelectingAll, bookingAttendees = []) => {
        const passengers = isSelectingAll ?
            bookingAttendees.map(({ id }) => id) :
            [];
        dispatch(ModalState.actions.mergeStateValue('values', {
            passengers,
            passengerAmount: passengers.length
        }));
    },
    onOpenCreateDriverModal: () => {
        dispatch(DriverModalState.mergeCreateModalState({
            isOpened: true,
            values: {},
            errors: {},
        }))
    },
    onOpenCreateVehicleModal: () => {
        dispatch(CreateVehicleModalState.open())
    },
    onSave: (isCreatingAnother, values) => {
        const { onSaveSuccess } = ownProps;
        dispatch(ModalState.thunks.save(isCreatingAnother, values, onSaveSuccess));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Modal);
