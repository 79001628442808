import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';

export const CRETE_MODAL_ID = 'createBookingItemModal';

export const getCreateModalState = (state) =>
stateHelpers.getComponentState(state, CRETE_MODAL_ID) || {};

export const mergeCreateModalState = (newState) =>
    actions.mergeComponentState(CRETE_MODAL_ID, newState);

export const setCreateModalStateValue = (key, value) =>
    actions.setComponentStateValue(CRETE_MODAL_ID, key, value);

export const mergeCreateModalStateValue = (key, newState) =>
    actions.mergeComponentStateValue(CRETE_MODAL_ID, key, newState);

export const deleteCreateModalStateValue = (key) =>
    actions.deleteComponentStateValue(CRETE_MODAL_ID, key);

export const UPDATE_MODAL_ID = 'updateBookingItemModal';

export const getUpdateModalState = (state) =>
stateHelpers.getComponentState(state, UPDATE_MODAL_ID) || {};

export const mergeUpdateModalState = (newState) =>
    actions.mergeComponentState(UPDATE_MODAL_ID, newState);

export const setUpdateModalStateValue = (key, value) =>
    actions.setComponentStateValue(UPDATE_MODAL_ID, key, value);

export const mergeUpdateModalStateValue = (key, newState) =>
    actions.mergeComponentStateValue(UPDATE_MODAL_ID, key, newState);

export const deleteUpdateModalStateValue = (key) =>
    actions.deleteComponentStateValue(UPDATE_MODAL_ID, key);

export const DELETE_MODAL_ID = 'deleteBookingItemModal';

export const getDeleteModalState = (state) =>
    stateHelpers.getComponentState(state, DELETE_MODAL_ID) || {};

export const mergeDeleteModalState = (newState) =>
    actions.mergeComponentState(DELETE_MODAL_ID, newState);

export const setDeleteModalStateValue = (key, value) =>
    actions.setComponentStateValue(DELETE_MODAL_ID, key, value);

export const mergeDeleteModalStateValue = (key, newState) =>
    actions.mergeComponentStateValue(DELETE_MODAL_ID, key, newState);

export const deleteDeleteModalStateValue = (key) =>
    actions.deleteComponentStateValue(DELETE_MODAL_ID, key);
