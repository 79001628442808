import BaseController from 'app/backbone/helpers/BaseController';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import ItemGroupUpdateView from 'app/backbone/views/production_website/accreditation/items/CompanyItemGroupUpdate.view';
import * as Routes from './routes';

// Backbone Controller
export default class ItemGroupController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.itemGroupUpdate(), null, this.update.bind(this));
    }

    update(eventId, itemGroupId) {
        const { app, data } = this;
        app.renderSyncingView();

        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const company = data.register('eventCompany', EventCompany.Model, { event });
        const periods = data.register('allPeriods', Period.Collection, { event });
        const itemGroup = data.register('eventItemGroup', EventItemGroup.Model, { event });

        company.setIncludes(['items.groups', 'items.available_days', 'items.granted_days']);
        periods.setIncludes(['days']);
        itemGroup.set('id', parseInt(itemGroupId, 10));
        company.getIdFromDOM('event_company');

        this.when(
            account.fetch(),
            event.fetch(),
            company.fetch(),
            periods.fetch(),
            itemGroup.fetch()
        ).done(() => {
            this.app.renderContentView(new ItemGroupUpdateView({ company, itemGroup, periods }));
        });
    }

}
