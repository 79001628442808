import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import AccrediteeItemTable from '../tables/AccrediteeItemTable.view';
import AccrediteeItemAddModal from '../modals/AccrediteeItemAddModal.view';
import * as CustomAccreditee from 'app/backbone/models/event/accreditation/accreditees/CustomAccreditee';

export default class AccrediteeItemsCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(CustomAccreditee.Model).isRequired,
            items: OptionTypes.instanceOf(EventItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            title
        };
    }

    optionDefaults() {
        return {
            title: 'Items'
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        // Subviews
        this.addModal = this.initAddModel();

        // Listeners
        this.listenTo(this.model.getRelation('items'), 'update reset', this.onUpdateOrResetItems);
        this.listenTo(this.model, 'validated', this.onValidated);

        super.initialize(options);
    }

    initContent() {
        const { model, items, periods } = this;
        const collection = model.getRelation('items');
        return new AccrediteeItemTable({ collection, periods, items });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add items',
            icon: 'bs-icon-plus',
            action: this.addItem.bind(this),
            isHidden: () => this.addModal.items.isEmpty()
        }];
    }

    initAddModel() {
        const items = this.getModalItems();
        const addModal = new AccrediteeItemAddModal({ items });
        this.listenTo(addModal, 'add', this.onAdded);
        return addModal;
    }

    onAdded(item) {
        this.model.items.add(item);
    }

    addItem() {
        if (!this.addModal.items.isEmpty()) this.addModal.open();
    }

    onUpdateOrResetItems() {
        this.updateModalItems();
        this.updateModalButton();
        if (this.hasError()) this.model.validate();
    }

    updateModalItems() {
        this.addModal.items = this.getModalItems();
    }

    updateModalButton() {
        this.header.toggleHideAction('add', this.addModal.items.isEmpty());
    }

    onValidated(isModelValid, model, errors) {
        if (!isModelValid && errors.items) this.addError(errors.items);
        else this.removeError();
    }

    getModalItems() {
        const { items, model } = this;
        return items.rejectIds(model.getRelation('items').pluck('id')).rejectByAttribute('uses_amounts');
    }

}
