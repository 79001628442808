import * as Schemas from './schemas'
import {
    getOneEntity,
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
} = Schemas;

export const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);


