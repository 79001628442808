import React from 'react';
import Header from 'app/react/components/layout/content/header/Header';

const { TitleMenu, Tabs } = Header;
const { Title } = TitleMenu;
const { Tab } = Tabs;

const TITLE = 'Artists';
const ITINERARY_LAYOUT_TAB = 'Itinerary Layout';
const ITINERARY_CONTACTS_TAB = 'Itinerary Contacts';
const EMAIL_TEMPLATES_TAB = 'Email Templates';
const FILES_TAB = 'Files';
const TASKS_TAB = 'Tasks';
const TICKET_TYPES_TAB = 'Ticket Types';
const ITEMS_TAB = 'Items';
const TIMETABLE_TAB = 'Timetable';
const LOCATIONS_TAB = 'Locations';
const OFFERS_TAB = 'Offers';

const EventArtistsSettingsContentHeader = ({
    activeTab,
    eventId,
    permissions = {}
}) => {
    const baseUrl = `events/${eventId}/settings/artists`;
    return (
        <Header>
            <TitleMenu>
                <Title>{TITLE}</Title>
            </TitleMenu>
            <Tabs>
                { permissions.setupSettings &&
                    <Tab activeTab={activeTab} tabId="itineraryLayout" url={`${baseUrl}/itinerary`} pageReload>
                        {ITINERARY_LAYOUT_TAB}
                    </Tab>
                }
                { permissions.setupContacts &&
                    <Tab activeTab={activeTab} tabId="itineraryContacts" url={`${baseUrl}/itinerary-contacts`} pageReload>
                        {ITINERARY_CONTACTS_TAB}
                    </Tab>
                }
                { permissions.setupEmailTemplates &&
                    <Tab activeTab={activeTab} tabId="emailTemplates" url={`${baseUrl}/email-templates`} pageReload>
                        {EMAIL_TEMPLATES_TAB}
                    </Tab>
                }
                { permissions.setupFiles &&
                    <Tab activeTab={activeTab} tabId="files" url={`${baseUrl}/files`} pageReload>
                        {FILES_TAB}
                    </Tab>
                }
                { permissions.setupTasks &&
                    <Tab activeTab={activeTab} tabId="tasks" url={`${baseUrl}/tasks`} pageReload>
                        {TASKS_TAB}
                    </Tab>
                }
                { permissions.setupArtistItems &&
                    <Tab activeTab={activeTab} tabId="items" url={`${baseUrl}/items`} pageReload>
                        {ITEMS_TAB}
                    </Tab>
                }
                { permissions.setupTicketTypes &&
                    <Tab activeTab={activeTab} tabId="ticketTypes" url={`${baseUrl}/tickets`} pageReload>
                        {TICKET_TYPES_TAB}
                    </Tab>
                }
                { permissions.setupSettings &&
                    <Tab activeTab={activeTab} tabId="timetable" url={`${baseUrl}/timetable`} pageReload>
                        {TIMETABLE_TAB}
                    </Tab>
                }
                { permissions.setupLocations &&
                    <Tab activeTab={activeTab} tabId="locations" url={`${baseUrl}/locations`} pageReload>
                        {LOCATIONS_TAB}
                    </Tab>
                }
                { permissions.setupSettings &&
                    <Tab activeTab={activeTab} tabId="offers" url={`${baseUrl}/offers`} pageReload>
                        {OFFERS_TAB}
                    </Tab>
                }
            </Tabs>
        </Header>
    );
};

export default EventArtistsSettingsContentHeader
