import Backbone from 'backbone'
import { DEFAULT_OPTIONS } from 'ui-kit/lib/components/pagination/PageSize'
import OptionTypes from 'app/backbone/helpers/OptionTypes'
import BaseView from 'app/backbone/components/Base.view'
import template from './PageSize.hbs'

// Backgrid only accepts integers as page size.
const PAGE_SIZE_OPTIONS = DEFAULT_OPTIONS.filter((option) => typeof option === 'number');

export default class ServerSideFilter extends BaseView {

    get template() {
        return template;
    }

    ui() {
        return {
            select: 'select',
        };
    }

    events() {
        return {
            'change select': 'onChange',
        };
    }

    optionsTypes() {
        return {
            collection: OptionTypes.instanceOf(Backbone.Collection).isRequired,
            pageSizeOptions: OptionTypes.number,
        };
    }

    optionDefaults() {
        return {
            pageSizeOptions: PAGE_SIZE_OPTIONS,
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.pageSize = this.collection.state.pageSize;
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.ui.select.val(this.pageSize);
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {
                options: this.pageSizeOptions,
            },
        };
    }

    onChange(e) {
        const value = parseInt(this.$(e.currentTarget).val());
        if (this.pageSize !== value) this.setPageSize(value);
    }

    setPageSize(pageSize) {
        this.pageSize = pageSize;
        this.collection.setPageSize(pageSize, { first: true, bustCache: true });
    }

}
