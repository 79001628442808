import moment from 'moment';
import { pick } from 'underscore';
import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Day from 'app/react/entities/events/days/index';
import * as Stage from 'app/react/entities/events/stages/index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import { reformatISOString } from '../../../performances/views/utils';
import CreateView, { CARD_ID, VIEW_ID } from './CreateView';
import { detailsRoute } from 'app/routes/event/artists/bookings/Controller';

const POPUP_MESSAGES = {
    FAILED_TO_CREATE: () =>
        `Failed to create booking.`,
    SUCCESSFUL_CREATE_ANOTHER: name =>
        `Booking <strong>${name}</strong> successfully created.`,
    SUCCESSFUL_CREATE: name =>
        `${POPUP_MESSAGES.SUCCESSFUL_CREATE_ANOTHER(name)} Redirecting...`
};

const mapStateToProps = (state) => {
    const {
        isSaving = false
    } = stateHelpers.getComponentState(state, VIEW_ID);
    const {
        isCreatingNewArtist = false,
        isCreatingNewPerformance = false,
        isEditingPerformanceName = false,
        values = {},
        errors = {}
    } = stateHelpers.getComponentState(state, CARD_ID);
    return {
        isFetching: (
            isSaving ||
            Day.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Stage.stateHelpers.isFetchingAllForCurrentContext(state) ||
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            BookingType.stateHelpers.isFetchingAll(state) ||
            BookingStatus.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        accountId: stateHelpers.accounts.getCurrentId(state),
        eventId: stateHelpers.events.getCurrentId(state),
        isArtistTypesEnabled: stateHelpers.events.isArtistTypesEnabledForCurrent(state),
        dateFormat: stateHelpers.events.getCurrentDateFormat(state),
        timeFormat: stateHelpers.events.getCurrentTimeFormat(state),
        selectedDay: Day.stateHelpers.getOne(state, values.day),
        isCreatingNewArtist,
        isCreatingNewPerformance,
        isEditingPerformanceName,
        values,
        errors,
        days: Day.stateHelpers.getAllForCurrentContext(state),
        stages: Stage.stateHelpers.getAllForCurrentContext(state),
        artists: Artist.stateHelpers.getAll(state),
        artistTypes: ArtistType.stateHelpers.getAllForCurrentContext(state),
        bookingTypes: BookingType.stateHelpers.getAll(state),
        bookingStatuses: BookingStatus.stateHelpers.getAllForCurrentContext(state)
    };
};

const isSet = value => typeof value !== 'undefined' && value !== '';

const toApiValues = (values, isCreatingNewPerformance) => {
    let artist = values.artist;
    if (typeof values.artist === 'string') {
        artist = { name: values.artist };
    }
    let performance;
    if (isCreatingNewPerformance) {
        let start;
        let end;
        let changeover;
        let publishDate;
        if (isSet(values.start)) {
            start = reformatISOString(values.start);
        }
        if (isSet(values.end)) {
            end = reformatISOString(values.end);
        }
        if (isSet(values.publishDate)) {
            publishDate = reformatISOString(values.publishDate);
        }
        if (isSet(values.changeover)) {
            changeover = parseInt(values.changeover, 10) * 60;
        }
        performance = {
            ...pick(values, ['title', 'day', 'stage']),
            start,
            end,
            changeover,
            publishDate
        };
    }
    return {
        ...pick(values, ['artistType', 'type', 'status']),
        artist,
        performance
    };
};

const mapDispatchToProps = (dispatch) => {
    const setCardState = (key, value) => key === null ?
        dispatch(actions.setComponentState(CARD_ID, value)) :
        dispatch(actions.setComponentStateValue(CARD_ID, key, value));
    const deleteCardState = key =>
        dispatch(actions.setComponentStateValue(CARD_ID, key));
    const setViewState = (key, value) =>
        dispatch(actions.setComponentStateValue(VIEW_ID, key, value));

    return ({
        onChangeValue: (key, value) => {
            setCardState(`values.${key}`, value);
            if (key === 'artist') {
                setCardState('values.title', value);
            }
        },
        onSelectArtist: (artistId, artistName) => {
            setCardState('values.artist', artistId);
            if (typeof artistId === 'undefined') {
                deleteCardState('values.title');
            } else {
                setCardState('values.title', artistName);
            }
        },
        onSelectDay: (dayId, dayStart) => {
            setCardState('values.day', dayId);
            setCardState('values.start', dayStart);
            const mEnd = moment(dayStart);
            mEnd.add(1, 'hours');
            setCardState('values.end', mEnd.toISOString());
        },
        onToggleCreateNewArtist: (isCreating = false) => {
            setCardState('isCreatingNewArtist', isCreating);
            deleteCardState('values.artist');
        },
        onToggleCreateNewPerformance: (isCreating = false, days = []) => {
            setCardState('isCreatingNewPerformance', isCreating);
            if (days.length === 1) {
                const day = days[0];
                setCardState('values.day', day.id);
                const mEnd = moment(day.start);
                mEnd.add(1, 'hours');
                setCardState('values.start', day.start);
                setCardState('values.end', mEnd.toISOString());
            }
        },
        onToggleEditPerformanceName: (isEditing = false) => {
            setCardState('isEditingPerformanceName', isEditing);
        },
        onCreate: (createAnother, eventId, values, isCreatingNewPerformance, artistName) => {
            setViewState('isSaving', true);
            const apiValues = toApiValues(values, isCreatingNewPerformance);
            dispatch(Booking.actions.createOne(eventId, apiValues))
                .then(({ error, response }) => {
                    if (error) {
                        setViewState('isSaving', false);
                        const errors = getFormFieldErrorsFromResponseJSON(error);
                        setCardState('errors', errors);
                        dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_CREATE(artistName)));
                    } else if (createAnother) {
                        dispatch(actions.showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_CREATE_ANOTHER(artistName)));
                        setCardState(null, {
                            errors: {},
                            values: {},
                            isEditingPerformanceName: false,
                            isCreatingNewArtist: false
                        });
                        setViewState('isSaving', false);
                    } else {
                        dispatch(actions.showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_CREATE(artistName)));
                        dispatch(actions.navigate.to(detailsRoute(eventId, response.result), { pageReload: true }));
                    }
                });
        },
        onBack: (eventId) => {
            navigate(`/events/${eventId}/artists/bookings`, { pageReload: false });
        }
    });
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onToggleCreateNewPerformance: (isEditing) => {
        const { days } = stateProps;
        dispatchProps.onToggleCreateNewPerformance(isEditing, days);
    },
    onSelectArtist: (artistId) => {
        const { artists } = stateProps;
        const artist = artists.find(({ id }) => id === artistId);
        dispatchProps.onSelectArtist(artistId, artist && artist.name);
    },
    onSelectDay: (dayId) => {
        const { days } = stateProps;
        const day = days.find(({ id }) => id === dayId);
        if (typeof day !== 'undefined') {
            dispatchProps.onSelectDay(dayId, day.start);
        }
    },
    onCreate: (createAnother = false) => {
        const { eventId, values, artists, isCreatingNewPerformance } = stateProps;
        const artist = artists.find(({ id }) => id === values.artist);
        const artistName = typeof values.artist === 'number' ?
            artist.name :
            values.artist;
        dispatchProps.onCreate(createAnother, eventId, values, isCreatingNewPerformance, artistName);
    },
    onBack: () => {
        const { eventId } = stateProps;
        dispatchProps.onBack(eventId);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateView);
