import moment from 'moment';
import { mergeDateWithTime } from 'app/react/helpers/_index';

export const getChangeoverStart = (start, changeover) => {
    const mStart = moment.utc(start);
    mStart.subtract(changeover, 'minutes');
    return mStart.toISOString();
};

export const mergeStartAndEnd = ({ startDate, startTime, endDate, endTime }) => ({
    start: startDate && startTime && mergeDateWithTime(startDate, startTime),
    end: endDate && endTime && mergeDateWithTime(endDate, endTime)
});

export const reformatStartAndEnd = ({ start, end }) => ({
    start: start && start.replace('.000Z', '+00:00'),
    end: end && end.replace('.000Z', '+00:00'),
});

export const reformatISOString = (ISOString) =>
    ISOString.replace('.000Z', '+00:00');

export const reformatPerformanceFormValues = (values) => {
    let changeover = values.changeover === '' ? null : values.changeover;
    let publishDate = values.publishDate === '' ? null : values.publishDate;
    if (typeof publishDate !== 'undefined' && publishDate !== null) {
        publishDate = reformatISOString(publishDate);
    }
    if (typeof changeover !== 'undefined' && changeover !== null) {
        changeover = parseInt(changeover, 10) * 60;
    }
    const { start, end } = Object.keys(values).includes('startDate') ? reformatStartAndEnd(mergeStartAndEnd(values)) : reformatStartAndEnd(values);

    return {
        publishDate,
        changeover,
        start,
        end,
        title: values.title,
        day: values.day,
        stage: values.stage,
        bio: values.bio
    };
}
