import OptionTypes from 'app/backbone/helpers/OptionTypes';
import AccrediteeItemTable from 'app/backbone/views/event/accreditation/accreditees/items/tables/AccrediteeItemTable.view';
import GrantedDayCell from './cells/GrantedDayCell.view';
import GrantedAllDaysCell from './cells/GrantedAllDaysCell.view';
import * as CompanyAccrediteeItem from 'app/backbone/models/event/accreditation/companies/CompanyAccrediteeItem';

export default class CompanyAccrediteeItemTable extends AccrediteeItemTable {

    optionTypes() {
        const { periods, editing, placeholderHeight } = super.optionTypes();
        return {
            collection: OptionTypes.instanceOf(CompanyAccrediteeItem.Collection).isRequired,
            periods,
            editing,
            placeholderHeight
        };
    }

    initFirstColumns() {
        const columns = super.initFirstColumns();
        columns.splice(1, 1); // We remove second, Granted By, column
        return columns;
    }

    createDayCell(day, classNamePostfix) {
        const className = `${GrantedDayCell.prototype.className()} ${classNamePostfix}`;
        return GrantedDayCell.extend({ day, className });
    }

    createAllDaysCell() {
        const { periods } = this;
        const days = periods.getAllDays();
        return GrantedAllDaysCell.extend({ days });
    }

    getPlaceholderText() {
        return `Start by adding profiles to the attendee.
            If the profiles you added contain attached items, these items wil be available here.`;
    }

}
