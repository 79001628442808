import EventCompanyView from './EventCompany.view';

export default class EventCompanyUpdateView extends EventCompanyView {

    events() {
        return {
            'click .update': 'update'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.isUpdating = true;
        this.syncingText = 'Updating company...';
        this.companyCard.form.hiddenInputs.push('company');
        this.companyCard.header.hideAction('create');
        this.companyCard.header.title = 'Contact';
    }

    update() {
        this.model.validate();
        this.model.primary_contact.validate();
        if (this.model.isValid() && this.model.primary_contact.isValid()) {
            this.model.save();
        }
    }

    onSync() {
        this.unbindModel();

        // We reinitialize the view to render the sub items rows correctly
        this.profilesCard.remove();
        this.profilesCard = this.initProfilesCard();

        this.setSyncing(false);
    }

}
