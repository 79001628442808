export const scheduler = (
    eventId = ':event'
) => (
    `events/${eventId}/artists/scheduler`
);

export const liveTimetable = (
    eventId = ':event'
) => (
    `/event/timetable/${eventId}`
);
