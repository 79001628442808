import _ from 'underscore';
import $ from 'jquery';
import trim from 'underscore.string/trim';
import cloneDeep from 'lodash.clonedeep';
import Backbone from 'backbone';
import DOM from 'app/backbone/helpers/DOM';

const FETCH_CACHE_TIMEOUT = 30000;

export default class BaseModel extends Backbone.Model {

    static Relations() {
        return {};
    }

    initialize(model, options = {}) {
        this.includes = options.includes || [];
        this.whiteListedAttributes = options.whiteListedAttibutes || [];
        this.blackListedAttributes = options.blackListedAttibutes || [];
        this.lastFetch = {
            timestamp: null,
            deferred: null,
            id: null,
            includes: [],
            hasChangedSince: false,
            attributes: {}
        };
        this.isSyncing = false;
        this.isDestroying = false;
        // Listeners
        this.listenTo(this, 'change', this.onChange);
        this.listenTo(this, 'request', this.onRequest);
        this.listenTo(this, 'request:delete', this.onDeleteRequest);
        this.listenTo(this, 'destroy', this.onDestroy);
        this.listenTo(this, 'sync', this.onSync);
        this.listenTo(this, 'error', this.onError);
    }

    onChange(model, options = {}) {
        if (!this.isSyncing && !options.parse) {
            this.lastFetch.hasChangedSince = true;
        }
    }

    onRequest() {
        this.isSyncing = true;
    }

    onDeleteRequest() {
        this.isDestroying = true;
    }

    onDestroy() {
        this.isSyncing = false;
        this.isDestroying = false;
    }

    onSync() {
        this.lastFetch.hasChangedSince = false;
        this.isSyncing = false;
    }

    onError() {
        this.isSyncing = false;
        this.isDestroying = false;
    }

    getIncludes() {
        if (!_.isEmpty(this.includes)) return this.includes;
        else if (!_.isUndefined(this.collection)) return this.collection.state.includes;
        else return [];
    }

    setIncludes(includes) {
        if (includes) this.includes = includes;
        return this; // Chainable
    }

    setWhiteListedAttributes(keys = [], { merge = false } = {}) {
        if (merge) this.whiteListedAttributes = _.union(this.whiteListedAttributes, keys);
        else this.whiteListedAttributes = keys;
        // Remove the new white listed from the black listed
        this.blackListedAttributes = _.without(this.blackListedAttributes, ...keys);
    }

    setBlackListedAttributes(keys = [], { merge = false } = {}) {
        if (merge) this.blackListedAttributes = _.union(this.blackListedAttributes, keys);
        else this.blackListedAttributes = keys;
        // Remove the new black listed from the white listed
        this.whiteListedAttributes = _.without(this.whiteListedAttributes, ...keys);
    }

    whiteListAllRelations() {
        const merge = true;
        this.setWhiteListedAttributes(this.getRelationKeys(), { merge });
    }

    blackListAllRelations() {
        const merge = true;
        this.setBlackListedAttributes(this.getRelationKeys(), { merge });
    }

    sync(method, model, options = {}) {
        const includes = this.getIncludes();
        const requestParams = {};

        if (includes.length) requestParams.include = includes.join();

        if (method === 'read') {
            options.data = options.data || requestParams;
        }

        // We add the request params manually to the url because the data property is used for the json data
        if (!options.url && (method === 'create' || method === 'update' || method === 'patch')) {
            const url = _.result(model, 'url');
            options.url = `${url}?${$.param(requestParams)}`;
        }

        const xhr = super.sync(method, model, options);
        this.trigger(`request:${method}`, model, xhr, options);
        return xhr;
    }

    fetch(options = {}) {
        // set a flag to bust the cache if we have ever set it before, and it's been more than 30 seconds
        // or the options bustCache has been passed
        const bustCache = (
            this.cacheTimeHasExpired() ||
            this.idHasChangedSinceLastFetch() ||
            this.includesHaveChangedSinceLastFetch() ||
            options.bustCache
        );

        // if we've never cached the call to `fetch`, or if we're busting the cache,
        // make a note of the current time, hit the server, and set the cache to this.lastFetchDeferred.
        if (!this.lastFetch.deferred || bustCache) {
            this.lastFetch.timestamp = new Date();
            this.lastFetch.id = this.id;
            this.lastFetch.includes = this.includes;
            this.lastFetch.hasChangedSince = false;
            this.lastFetch.deferred = super.fetch(options);
        }

        // return the promise object in the cache
        return this.lastFetch.deferred;
    }

    bustCache() {
        this.lastFetch.deferred = null;
    }

    cacheTimeHasExpired() {
        const { timestamp } = this.lastFetch;
        return ! (timestamp && new Date() - timestamp < FETCH_CACHE_TIMEOUT);
    }

    idHasChangedSinceLastFetch() {
        return this.lastFetch.id !== this.id;
    }

    includesHaveChangedSinceLastFetch() {
        return ! _.isEqual(this.lastFetch.includes, this.includes);
    }

    fetchFromDOM(key = this.key || this.domKey()) {
        if (key) {
            const data = DOM.getData(key);
            if (data) this.set(this.parse(data));
            else throw new Error(`Unable to fetch data from DOM with key '${key}'. Please bind to object to the DOM before fetching it.`);
        } else throw new Error('Cannot fetch data form DOM without object key.');
        return this;
    }

    fetchFromDOMOrServer(key = this.key || this.domKey()) {
        const data = DOM.getData(key);
        if (data) {
            this.set(this.parse(data));
            return $.when();
        } else {
            return this.fetch();
        }
    }

    getIdFromDOM(key = this.key || this.domKey()) {
        const data = DOM.getData(key);
        if (data && data.id) this.set('id', parseInt(data.id, 10));
    }

    toJSON() {
        const { whiteListedAttributes, blackListedAttributes } = this;
        let json = super.toJSON();
        if (whiteListedAttributes.length) json = _.pick(json, ...whiteListedAttributes);
        if (blackListedAttributes.length) json = _.omit(json, ...blackListedAttributes);
        return json;
    }

    toJSONRelations() {
        const { whiteListedAttributes, blackListedAttributes } = this;
        let json = {};
        this.getRelationKeys().forEach(key => {
            json[key] = this.getRelation(key).toJSON();
        });
        if (whiteListedAttributes.length) json = _.pick(json, ...whiteListedAttributes);
        if (blackListedAttributes.length) json = _.omit(json, ...blackListedAttributes);
        return json;
    }

    parse(response, xhr) {
        const unparsedResponse = cloneDeep(response);
        return unparsedResponse.data ? unparsedResponse.data : unparsedResponse;
    }

    parseRelations(response) {
        if (_.isUndefined(this.relations)) this.relations = {};
        const keys = this.getRelationKeys();
        keys.forEach(key => { this.parseRelation(key, response); });
    }

    parseRelation(key, response) {
        if (key && response[key]) {
            const Clazz = this.constructor.Relations()[key];
            const options = { parse: true };

            // Find out if the relation class has a circular relation to this object type.
            // If so, add the key as a property with a reference to this object to the options.
            // This wil create a relation to this object in the relation instance
            const circularKey = this.findCircularRelationKeyInClass(Clazz);
            if (circularKey) options[circularKey] = this;

            // Check if the response property is a single value or an include object
            const parseData = response[key];
            const initData = _.isObject(parseData) ? parseData : { id: parseData };
            // Create new instance of the relation
            const object = new Clazz(initData, options);

            // If the model already has a relation we copy the models or attributes to this existing relation.
            // Because if we would setup a new relation we would lose all bound event listeners to these relations
            // after parsing the model again. (e.g. fetching the model)
            if (this.hasRelation(key)) this.copyRelation(key, object);
            else this.newRelation(key, object);

            // We remove the key, because otherwise it will be added as an attribute to the model
            if (object instanceof Backbone.Model) {
                response[key] = object.id;
            } else if (object instanceof Backbone.Collection) {
                response[key] = object.length;
            }
        }
    }

    initializeRelations(options) {
        if (_.isUndefined(this.relations)) this.relations = {};
        const keys = this.getRelationKeys();
        const collection = options.collection || {};
        keys.forEach(key => {
            // If a key to an instance of the relation is not found in the options,
            // try to add it from a relation that the collection might have.
            // If both are not found the model will create an new instance for the relation
            const object = options[key] || collection[key];
            if (!this.hasRelation(key)) this.newRelation(key, object);
        });
    }

    setRelation(key, object) {
        // Only models or collections can be set as a relation.
        if (object instanceof Backbone.Collection || object instanceof Backbone.Model) {
            // Add relation as a property the this instance
            this[key] = object;
            this.relations[key] = object;
            // Setup some listeners to the object.
            // If the object is a collection set the collection length as an attributes on the model.
            if (object instanceof Backbone.Collection) {
                this.listenTo(object, 'update reset', (collection) => this.set(key, collection.length));
            }
            // If the object is a model set the id as an attributes on the model.
            if (object instanceof Backbone.Model) {
                this.listenTo(object, 'change:id', model => this.set(key, model.id));
            }
        } else throw new Error(`'${object}' is not a collection or model.`);
    }

    newRelation(key, object) {
        const Clazz = this.constructor.Relations()[key];
        if (Clazz) {
            const options = {};
            const circularKey = this.findCircularRelationKeyInClass(Clazz);
            if (circularKey) options[circularKey] = this;

            // If no object is given for the relation we create a new empty object based on relation class.
            object || (object = new Clazz(null, options));

            if (!this.hasRelation(key)) this.setRelation(key, object);
            else throw new Error(`Relation with key '${key}' already exists.`);
        } else throw new Error(`Relation Class for key '${key}' is not defined or provided for '${this.constructor.name}'.`);
    }

    copyRelation(key, object) {
        if (key && object) {
            const Clazz = this.constructor.Relations()[key];
            if (Clazz) {
                if (object instanceof Clazz) {
                    if (this.hasRelation(key)) {
                        const silent = true;
                        if (object instanceof Backbone.Collection) {
                            this.getRelation(key).reset(object.models, { silent });
                        }
                        if (object instanceof Backbone.Model) {
                            const relation = this.getRelation(key);
                            relation.set(object.attributes, { silent });
                            object.forEachRelation((objectRel, objectRelKey) => {
                                if (objectRel instanceof Backbone.Collection) {
                                    relation.relations[objectRelKey].reset(objectRel.models, { silent });
                                }
                                if (objectRel instanceof Backbone.Model) {
                                    relation.relations[objectRelKey].set(objectRel.attributes, { silent });
                                }
                            });
                        }
                    } else throw new Error(`Relation with key '${key}' does not exist.`);
                } else throw new TypeError(`Model '${object}' is not an instance of ${Clazz}.`);
            } else throw new Error(`Relation Class for key '${key}' is not defined or provided for '${this.constructor.name}'.`);
        }
    }

    getRelation(key) {
        if (key) {
            if (this[key] || this.relations[key]) {
                return this[key] || this.relations[key];
            } else if (this.hasCollection() && this.collection.hasRelation(key)) {
                return this.collection.getRelation(key);
            }
        }
    }

    hasRelation(key) {
        return ! _.isUndefined(this[key] || this.relations[key]);
    }

    getRelations(keys) {
        keys || (keys = this.getRelationKeys());
        const relations = {};
        keys.forEach(key => { relations[key] = this.getRelation(key); });
        return relations;
    }

    getRelationKeys() {
        return _.keys(this.constructor.Relations());
    }

    findCircularRelationKeyInClass(Clazz) {
        const Relations = Clazz.Relations && Clazz.Relations();
        return _.findKey(Relations, Relation => this instanceof Relation);
    }

    validateRelations(keys) {
        keys || (keys = this.getRelationKeys());
        keys = !_.isArray(keys) ? [keys] : keys;
        keys.forEach((key) => {
            if (!this.getRelation(key)) {
                throw new Error(`Instance of class '${this.constructor.name}' has no relation set to '${key}'.`);
            } else if (this.getRelation(key).isNew()) {
                throw new Error(`The relation with '${key}' is new and thus has no id.`);
            }
        });
    }

    validate(attributes, options) {
        _.each(this.attributes, (attr, key) => {
            if (_.isString(attr)) this.set(key, trim(attr), { silent: true });
        });
        return super.validate(attributes, options);
    }

    clone() {
        return new this.constructor(this.attributes, this.getRelations());
    }

    transform(Clazz) {
        return new Clazz(this.attributes, this.getRelations());
    }

    forceCreate() {
        this.save(null, {
            type: 'POST',
            url: this.urlRoot()
        });
    }

    isFirst() {
        if (this.hasCollection()) return this.collection.indexOf(this) === 0;
        return false;
    }

    isLast() {
        if (this.hasCollection()) return this.collection.indexOf(this) === this.collection.length - 1;
        return false;
    }

    hasCollection() {
        return !! this.collection;
    }

    forEachRelation(iteratee) {
        _.forEach(this.relations, iteratee);
    }

    someRelations(iteratee) {
        return _.some(this.relations, iteratee);
    }

}



