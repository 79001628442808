import Backbone from 'backbone'
import DOMDrawer from 'app/backbone/components/drawers/DOMDrawer'

export default class DOMDrawerView extends Backbone.View {

    ui() {
        return {
            trigger: '[data-drawer-target]',
            triggerSubActions: '[data-drawer-target] a, [data-drawer-target] button',
        };
    }

    events() {
        return {
            'click trigger': 'open',
            'click triggerSubActions': 'onClickSubAction',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    open({ currentTarget }) {
        new DOMDrawer(currentTarget.dataset.drawerTarget).open();
    }

    onClickSubAction(e) {
        e.stopPropagation();
    }
}
