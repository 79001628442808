import React from 'react';
import PropTypes from 'prop-types';
import DialogContainer from '../dialogContainer/DialogContainer';
import DialogCard from '../dialogCard/DialogCard';
import Icon from '../../../utils/Icon';
import Button from '../button/Button';

const DeleteDialog = ({confirm, title, text, buttonText, ...props}) => (
	<DialogContainer>
		<DialogCard {...props}>
			<div className={'cancel-create-dialog__container'}>

					<Icon
						size={32}
						name="info"
						color='#0CD3AB'
						style={{marginBottom: '20px'}}
					/>
					<h3>{title}</h3>
					<div className={'cancel-create-dialog__text'}>
						<p>{text}</p>
					</div>
					<Button label={buttonText} variant="cancel" onClick={confirm} />
			</div>
		</DialogCard>
	</DialogContainer>
);

DeleteDialog.propTypes = {
	setCancelDialogOpen: PropTypes.func.isRequired,
	confirm: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired
};

export default DeleteDialog;
