import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'
import { setCurrentContextId } from 'app/react/state/actions/_index'

export const ONE_REQUEST = 'ONE_BOOKING_ITEM_REQUEST';
export const ONE_SUCCESS = 'ONE_BOOKING_ITEM_SUCCESS';
export const ONE_FAILURE = 'ONE_BOOKING_ITEM_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_REQUEST = 'ALL_BOOKING_ITEMS_REQUEST';
export const ALL_SUCCESS = 'ALL_BOOKING_ITEMS_SUCCESS';
export const ALL_FAILURE = 'ALL_BOOKING_ITEMS_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const fetchAll = (accountId, eventId, bookingId) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId, bookingId),
        schema: Schemas.entities
    },
    accountId,
    eventId,
    bookingId
});

export const copyAll = (accountId, eventId, bookingId, values) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.copyAll(eventId, bookingId),
        schema: Schemas.entities,
    },
    accountId,
    eventId,
    bookingId,
});

export const createOne = (accountId, eventId, bookingId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId, bookingId),
        schema: Schemas.entity,
    },
    accountId,
    eventId,
    bookingId,
});

export const updateOne = (accountId, eventId, bookingId, itemId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOne(eventId, bookingId, itemId),
        schema: Schemas.entity
    },
    accountId,
    eventId,
    bookingId
});

export const deleteOne = (accountId, eventId, bookingId, itemId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, bookingId, itemId),
        schema: Schemas.entity,
        id: itemId
    },
    accountId,
    eventId,
    bookingId,
});
