import Backbone from 'backbone';
import Modal from 'app/backbone/components/modals/Modal.view';
import * as Account from 'app/backbone/models/account/Account';
import * as Event from 'app/backbone/models/event/Event';
import * as Day from 'app/backbone/models/event/Day';
import * as Booking from 'app/backbone/models/event/artists/Booking';
import * as ArtistItemGroup from 'app/backbone/models/event/artists/ArtistItemGroup'
import Form from 'app/backbone/components/forms/Form.mixin';
import template from './DownloadBookingArtistItemsModal.hbs';

const DownloadBookingArtistItemsModal = Modal.extend({

    template,

    mixins: [
        Form
    ],

    ui: {
        bookingArtistItemsDownloadButton: '.download'
    },

    events: {
        'click bookingArtistItemsDownloadButton': 'download',
        'change input[type=checkbox]': 'onChangeIncludeFinancialData'
    },

    options: [
        { 'width': 400 }
    ],

    initialize(options) {
        this._super(options);

        const account = new Account.Model().fetchFromDOM();
        const event = new Event.Model(null, { account }).fetchFromDOM();

        this.event = event;

        this.days = new Day.Collection(null, { event }).fetchFromDOM();
        this.days.add({ id: 0, name: 'All Days' }, { at: 0 });
        this.groups = new ArtistItemGroup.Collection(null, { event }).fetchFromDOM();
        this.groups.add({ id: 0, name: 'All Groups' }, { at: 0 });
        this.bookings = new Booking.Collection(null, { event });
        this.bookings.setIncludes(['artist']);
        this.bookings.fetchAll().done(() => {
            this.render();
            this.bookings.add({ id: 0, name: 'All Bookings' }, { at: 0 });
        });
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                includeFinancialData: this.includeFinancialData
            }
        };
    },

    initForm() {
        const { days, groups, bookings } = this;
        this.inputs = new Backbone.ChildViewContainer();

        const dayValue = this.daysSelectize && this.daysSelectize.getSelectedValue();
        this.daysSelectize = this.initSingleSelectize('days', { collection: days, initialValue: dayValue });
        this.inputs.add(this.daysSelectize, 'days');

        const groupValue = this.groupsSelectize && this.groupsSelectize.getSelectedValue();
        this.groupsSelectize = this.initSingleSelectize('groups', { collection: groups, initialValue: groupValue, attrKey: 'full_name' });
        this.inputs.add(this.groupsSelectize, 'groups');

        const bookingValue = this.bookingsSelectize && this.bookingsSelectize.getSelectedValue();
        this.bookingsSelectize = this.initSingleSelectize('bookings', { collection: bookings, initialValue: bookingValue, attrKey: 'title_with_day' });
        this.inputs.add(this.bookingsSelectize, 'bookings');
    },

    renderForm() {
        this.inputs.call('render');
    },

    open() {
        this.render();
        this.initForm();
        this.delegateEvents();
        this.renderForm();
        this.show();
    },

    download() {
        const event = this.event;
        const dayId = this.daysSelectize.getSelectedValue();
        const groupId = this.groupsSelectize.getSelectedValue();
        const bookingId = this.bookingsSelectize.getSelectedValue();
        const includeFinancialData = this.includeFinancialData ? 1 : 0;

        navigate(
            `/events/${event.id}/artists/reports/booking-items?day=${dayId}&group=${groupId}&booking=${bookingId}&financial=${includeFinancialData}`,
            { pageReload: true }
        );
    },

    onChangeIncludeFinancialData(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        this.includeFinancialData = checked;
        this.render();
        this.initForm();
        this.renderForm();
    }
});

export default DownloadBookingArtistItemsModal;
