import React, { Fragment } from 'react'
import { Summary } from 'ui-kit'
import Price from 'app/react/components/util/Price'
import { isBookedByPromoter } from 'app/react/helpers/reservations'

const {
    Item,
    List,
} = Summary;

const RoomTypeDetails = ({
    roomType,
    reservations,
    currencySymbol,
}) => {
    const bookedByPromoter = reservations.filter(isBookedByPromoter);
    const nights = bookedByPromoter.reduce((nights, reservation) => {
        return nights + reservation.nights;
    }, 0);
    const paidByPromoter = bookedByPromoter.reduce((paidByPromoter, reservation) => {
        return paidByPromoter + reservation.nightsPaidByPromoter;
    }, 0);
    const hasPrice = roomType.price && roomType.price !== '0.00';
    const items = [
        <Item renderLabel={() => roomType.name}>
            <div className="text-align-right">
                {bookedByPromoter.length
                    ? `${bookedByPromoter.length} reservation(s) - ${nights} night(s)`
                    : 'No reservations'
                }
            </div>
        </Item>
    ];

    if (hasPrice) {
        items.push(
            <Item renderLabel={() => ''}>
                <div className="text-align-right">
                    <Price
                        amount={paidByPromoter * roomType.price}
                        currencySymbol={currencySymbol}
                    />
                    for {paidByPromoter} night(s) paid by promoter
                </div>
            </Item>,
            <Item renderLabel={() => ''}>
                    <span className="text-align-right">
                        {currencySymbol}{roomType.price} per night
                    </span>
            </Item>
        );
    } else {
        items.push(
            <Item renderLabel={() => ''}>
                No price available
            </Item>
        );
    }

    return <List>{items}</List>;
};

RoomTypeDetails.defaultProps = {
    reservations: [],
};

export default RoomTypeDetails;
