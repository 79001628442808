import React from 'react'
import { Grid, SearchInput } from 'ui-kit'

const { Cell } = Grid;

const TotalsTableTopBar = ({
    searchValue,
    onSearch = () => {},
    onClearSearch = () => {},
}) => (
    <Grid hasGutters={false} className="margin-small-bottom">
        <Cell isContentJustifiedAtEnd>
            <SearchInput
                value={searchValue || ''}
                placeholder="Accommodation..."
                onSearch={onSearch}
                onClear={onClearSearch}
            />
        </Cell>
    </Grid>
);

export default TotalsTableTopBar;
