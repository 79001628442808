import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Header from 'app/react/components/layout/content/header/Header'

const { TitleMenu } = Header;
const { Title, SubTitle } = TitleMenu;

const EventCompanyContentHeader = ({
    accrediteeName
}) => (
    <Fragment>
        <Title>Attendees</Title>
        {accrediteeName && <SubTitle>{accrediteeName}</SubTitle>}
    </Fragment>
);

EventCompanyContentHeader.propTypes = {
    accrediteeName: PropTypes.string
};

export default EventCompanyContentHeader;
