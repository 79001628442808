import dotProp from 'dot-prop-immutable';
import { createReducer } from 'redux-create-reducer';
import {
    entityRequestReducer,
    entitySuccessReducer,
    entityFailureReducer,
    createEntityReducer,
    createPaginationReducerWithKey,
    entitySuccessPaginationReducer
} from 'app/react/state/reducers/higher_order/_index'
import {
    generateCollectionKey
} from 'app/react/entities/events/helpers'
import {
    ENTITY_REDUCER_KEY,
    PAGINATION_REDUCER_KEY
} from './schemas'
import {
    ALL_PAGINATED_TYPES,
    ONE_TYPES
} from './actions'
import * as CrewActions from 'app/react/entities/events/bookings/crew/actions'
import * as PerformerActions from 'app/react/entities/events/bookings/performers/actions'

const entityReducer = createEntityReducer({
    types: ONE_TYPES
});

const paginationReducer = createPaginationReducerWithKey({
    mapActionToKey: ({ accountId, eventId }) => generateCollectionKey(accountId, eventId),
    types: ALL_PAGINATED_TYPES,
    oneTypes: ONE_TYPES
});

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};

export const paginationReducers = {
    [PAGINATION_REDUCER_KEY]: paginationReducer,
};

const [crewRequestType, crewSuccessType, crewFailureType] = CrewActions.ONE_TYPES;
const [performerRequestType, performerSuccessType, performerFailureType] = PerformerActions.ONE_TYPES;

const storeEntitiesPath = `entities.${ENTITY_REDUCER_KEY}`;
const storePaginationPath = `pagination.${PAGINATION_REDUCER_KEY}`;

const attendeeSuccessPaginationReducer = (state, action) => {
    const { accountId, eventId } = action;
    const paginationKey = generateCollectionKey(accountId, eventId);
    const paginationPath = `${storePaginationPath}.${paginationKey}`;
    const paginationState = state.pagination[PAGINATION_REDUCER_KEY][paginationKey];
    if (typeof paginationState !== 'undefined') {
        return dotProp.set(
            state,
            paginationPath,
            entitySuccessPaginationReducer(paginationState, action)
        )
    }
    return state
};

export const featureReducer = createReducer({}, {
    [crewRequestType]: (state, action) => dotProp.set(
        state,
        storeEntitiesPath,
        entityRequestReducer(state.entities[ENTITY_REDUCER_KEY], action)
    ),
    [crewSuccessType]: (state, action) => dotProp.set(
        attendeeSuccessPaginationReducer(state, action),
        storeEntitiesPath,
        entitySuccessReducer(state.entities[ENTITY_REDUCER_KEY], action)
    ),
    [crewFailureType]: (state, action) => dotProp.set(
        state,
        storeEntitiesPath,
        entityFailureReducer(state.entities[ENTITY_REDUCER_KEY], action)
    ),
    [performerRequestType]: (state, action) => dotProp.set(
        state,
        storeEntitiesPath,
        entityRequestReducer(state.entities[ENTITY_REDUCER_KEY], action)
    ),
    [performerSuccessType]: (state, action) => dotProp.set(
        attendeeSuccessPaginationReducer(state, action),
        storeEntitiesPath,
        entitySuccessReducer(state.entities[ENTITY_REDUCER_KEY], action)
    ),
    [performerFailureType]: (state, action) => dotProp.set(
        state,
        storeEntitiesPath,
        entityFailureReducer(state.entities[ENTITY_REDUCER_KEY], action)
    )
});
