var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"pagerPlaceholder\">\n        <i class=\"bs-icon-star-four-points\" /> All data's been loaded!\n    </span>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"pager\">\n        <div class=\"previousPages\">\n            <a href=\"#\" class=\"firstPage pageArrow\">\n                <i class=\"bs-icon-double-arrow-thin-left\" />\n            </a>\n            <div class=\"pageNumbers\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.pageInfo : stack1)) != null ? stack1.previousPageNumbers : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <a href=\"#\" class=\"primaryAction\">\n            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.primaryAction : stack1), depth0))
    + "\n        </a>\n        <div class=\"nextPages\">\n            <div class=\"pageNumbers\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.pageInfo : stack1)) != null ? stack1.nextPageNumbers : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n            <a href=\"#\" class=\"lastPage pageArrow\">\n                <i class=\"bs-icon-double-arrow-thin-right\" />\n            </a>\n        </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                    <a href=\"#\" class=\"pageNumber "
    + ((stack1 = (helpers.is || (depth0 && depth0.is) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,((stack1 = ((stack1 = (depths[1] != null ? depths[1].view : depths[1])) != null ? stack1.pageInfo : stack1)) != null ? stack1.currentPage : stack1),{"name":"is","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "isCurrentPage";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.pageInfo : stack1)) != null ? stack1.isAllDataLoaded : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});