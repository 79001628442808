import _ from 'underscore';
import { Model, Collection }  from 'app/backbone/models/decorators';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as AvailableDay from 'app/backbone/models/event/settings/accreditation/AvailableDay';
import * as GrantedDay from 'app/backbone/models/event/settings/accreditation/GrantedDay';

@Model({
    key: 'company_item'
})
class CompanyItem extends EventItem.Model {

    static Relations() {
        return {
            event: EventItem.Model.Relations().event,
            item_group: EventItem.Model.Relations().item_group,
            company: require('./Company').Model, // Circular
            available_days: AvailableDay.Collection,
            granted_days: GrantedDay.Collection
        }
    }

    get defaults() {
        return {
            date_dependable: false,
            uses_amounts: false
        }
    }

    get validation() {
        return {
            id: {
                required: true,
                msg: 'Select an item.'
            }
        }
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if(this.hasRelation('available_days')) json.available_days = this.available_days.toJSON();
        if(this.hasRelation('granted_days')) json.granted_days = this.granted_days.toJSON();
        return json;
    }

    hasAvailableDayOn(day) {
        return this.getAvailableDayOn(day) ? true : false;
    }

    hasAvailableDayOnAll(days) {
        return days.every(day => this.hasAvailableDayOn(day));
    }

    getAvailableDayOn(day) {
        return this.available_days.get(day.id);
    }

    addAvailableDayOn(days) {
         this.available_days.add(_.isArray(days) ? days.map(day => day.toAvailableDay()) : days.toAvailableDay());
    }

    addAvailableDayOnAll(days) {
        this.addAvailableDayOn(days.models);
    }

    removeAvailableDayOn(days) {
        this.available_days.remove(_.isArray(days) ? _.pluck(days, 'id') : days.id );
    }

    removeAvailableDayOnAll(days) {
        this.removeAvailableDayOn(days.models)
    }

    hasGrantedDayOn(day) {
        return this.getGrantedDayOn(day) ? true : false;
    }

    hasGrantedDayOnEvery(days) {
        return days.every(day => this.hasGrantedDayOn(day));
    }

    getGrantedDayOn(day) {
        return this.granted_days.get(day.id);
    }

    getAmountGrantedOn(day) {
        return this.hasGrantedDayOn(day) ? this.getGrantedDayOn(day).get('amount') : '';
    }

    setAmountGrantedOn(day, amount = 0) {
        amount = _.isNaN(parseInt(amount)) ? 0 : parseInt(amount);
        if(amount === 0) this.granted_days.remove(day.id);
        else if(this.hasGrantedDayOn(day)) this.getGrantedDayOn(day).set('amount', amount);
        else this.granted_days.add(day.toGrantedDay({ amount }));
    }

    addGrantedDayOn(days) {
        this.getRelation('granted_days').add(_.isArray(days) ? days.map(day => day.toGrantedDay()) : days.toGrantedDay());
    }

    addGrantedDayOnAll(days) {
        this.addGrantedDayOn(days.models);
    }

    removeGrantedDayOn(days) {
        this.getRelation('granted_days').remove(_.isArray(days) ? _.pluck(days, 'id') : days.id );
    }

    removeGrantedDayOnAll(days) {
        this.removeGrantedDayOn(days.models)
    }

    setAmount(amount) {
        amount = _.isNaN(parseInt(amount)) ? 0 : parseInt(amount);
        if(amount === 0) this.unset('amount');
        else this.set('amount', amount);
    }
}

@Collection({
    key: 'company_items',
    model: CompanyItem
})
class CompanyItemCollection extends EventItem.Collection {

    static Relations() {
        return {
            company: require('./Company').Model // Circular
        }
    }

    someHaveNoAvailableDay() {
        return this.some(item => item.get('date_dependable') && item.available_days.isEmpty());
    }

    filterGranted() {
        return this.constructor(this.filter(day => day.get('granted') || day.get('amount') || day.granted_days.length > 0))
    }

    url() {
        this.validateRelations('company');
        let eventId = this.company.event.id;
        let companyId = this.company.id;
        return `/api/events/${eventId}/companies/${companyId}/items`;
    }

}

const PageableCollection = CompanyItemCollection.prototype.PageableCollection();

export {
    CompanyItem as Model,
    CompanyItemCollection as Collection,
    PageableCollection
};
