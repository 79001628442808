import Backbone from 'backbone';
import Backgrid from 'backgrid';
import CellAction from './CellAction.view';

export default class ActionsCell extends Backgrid.Cell {

    initialize(options = {}) {
        super.initialize(options);
        this.actions = this.initActions();
    }

    render() {
        this.$el.empty();
        this.renderActions();
        return this.delegateEvents();
    }

    remove() {
        this.actions.call('remove');
        super.remove();
    }

    initActions() {
        const actions = new Backbone.ChildViewContainer();
        this.actions.forEach((action) => {
            actions.add(this.initAction(action), action.name)
        });
        return actions;
    }

    renderActions() {
        this.actions.forEach((action) => {
            if (action.canRender(this.model)) this.$el.append(action.render().el)
        });
    }

    initAction(options) {
        const model = this.model;
        const { icon, tooltip, action, canRender, event } = options;
        return new CellAction({ icon, tooltip, model, action, canRender, event });
    }

    toggleAction(name, hidden) {
        const action = this.getAction(name);
        if (action) action.toggleHidden(hidden);
    }

    showAction(name) {
        const action = this.getAction(name);
        if (action) action.show();
    }

    hideAction(name) {
        const action = this.getAction(name);
        if (action) action.hide();
    }

    enableAction(name) {
        const action = this.getAction(name);
        if (action) action.enable();
    }

    disableAction(name) {
        const action = this.getAction(name);
        if (action) action.disable();
    }

    getAction(name) {
        return this.actions.findByCustom(name);
    }

}
