// Actions
export const ADD_ENTITY_TO_COLLECTION = 'ADD_ENTITY_TO_COLLECTION';
export const REMOVE_ENTITY_FROM_COLLECTION = 'REMOVE_ENTITY_FROM_COLLECTION';
export const INVALIDATE_COLLECTION_CACHE = 'INVALIDATE_COLLECTION_CACHE';

export const addEntityToCollection = (entityId, collectionKey, collectionSubKey) => ({
    type: ADD_ENTITY_TO_COLLECTION,
    entityId,
    collectionSubKey,
    collectionKey
});

export const invalidateCollectionCache = (collectionKey, collectionSubKey) => ({
    type: INVALIDATE_COLLECTION_CACHE,
    collectionSubKey,
    collectionKey
});
