import { schema } from 'normalizr';

export const ENTITY_REDUCER_KEY = 'attendees';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const entityDefinition = {};

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);

entity.define({
    booking: require('app/react/entities/events/bookings/schemas').entity,
});
