import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import { mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import { enhanceStateWithHelpers } from 'app/react/entities/enhanceStateWithHelpers';
import * as actions from 'app/react/state/actions/_index';
import Modal from 'app/react/views/account/settings/artists/items/components/items/ItemModal';
import * as State from './ItemModalState';
import * as Item from 'app/react/entities/events/artists/items/index';

const FAILED_POPUP = 'Failed to create item.';
const SUCCESSFUL_POPUP = 'Item successfully created.';

const mapStateToProps = (state) => {
    const { helpers } = enhanceStateWithHelpers(state);
    const { eventArtistItemGroups } = helpers;

    const {
        isOpened,
        isSaving,
        values = {},
        errors = {},
    } = State.getCreateModalState(state);
    return {
        ...mapEventContextToProps(state),
        isCreating: true,
        isOpened,
        values,
        errors,
        isSaving,
        groups: eventArtistItemGroups.getAllForCurrentContext()
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSave: (createAnother) => {
        const { values } = stateProps;
        dispatchProps.onSave(createAnother, values);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch((State.setCreateModalStateValue('isOpened', false)));
    },
    onChangeValue: (key, value) => {
        dispatch(State.setCreateModalStateValue(`values.${key}`, value));
    },
    onSave: (createAnother = false, values) => {
        dispatch((State.setCreateModalStateValue('isSaving', true)));
        const apiValues = Item.schemas.toAPI(values);
        dispatch(Item.thunks.createOneForCurrentContext(apiValues))
            .then(({ error, response }) => {
                if (typeof error !== 'undefined') {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.showErrorPopup(FAILED_POPUP));
                    dispatch(State.mergeCreateModalState({
                        errors: errors,
                        isSaving: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP));
                    dispatch(State.mergeCreateModalState({
                        errors: {},
                        values: {},
                        isOpened: createAnother,
                        isSaving: false
                    }));
                }
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Modal);
