import ItemModalCreate from 'app/backbone/views/account/settings/accreditation/views/modals/ItemModalCreate.view';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';

export default class EventItemModalCreate extends ItemModalCreate {

    newModel(attributes, options) {
        return new EventItem.Model(attributes, options);
    }

}
