import { connect } from 'react-redux';
import VehiclesOverview from './VehiclesOverview';
import * as CreateVehicleModalState from '../components/modals/stateful/CreateModalState';

const mapDispatchToProps = (dispatch) => ({
    onOpenCreateModal: () => {
        dispatch(CreateVehicleModalState.open())
    },
});

const VehiclesOverviewContainer = connect(
    null,
    mapDispatchToProps
)(VehiclesOverview);

export default VehiclesOverviewContainer
