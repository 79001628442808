import React from 'react';
import PropTypes from 'prop-types';

const DialogCardBottom = ({children}) => (
    <div className={'dialog-card__bottom'}>
			{children}
		</div>
);

DialogCardBottom.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DialogCardBottom;
