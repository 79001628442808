import ProfileView from './Profile.view';

export default class ProfileViewCreateView extends ProfileView {

    events() {
        return {
            'click .create': 'create',
            'click .create-another': 'createAnother'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.isUpdating = false;
        this.syncingText = 'Creating profile...';
    }

    create(e, another = false) {
        this.model.days.reset(this.daysCard.content.getSelectedModels());
        this.model.zones.reset(this.zonesCard.content.getSelectedModels());
        this.model.validate();
        if (this.model.isValid()) {
            this.isCreatingAnother = another;
            this.model.save();
        }
    }

    createAnother(e) {
        this.create(e, true);
    }

    onSync() {
        this.unbindModel();
        if (this.isCreatingAnother) {
            this.model = this.newModel();
            this.profileCard.remove();
            this.daysCard.remove();
            this.zonesCard.remove();
            this.profileCard = this.initProfileCard();
            this.daysCard = this.initDaysCard();
            this.zonesCard = this.initZonesCard();
            this.bindModel();
            this.setSyncing(false);
        } else {
            this.profiles.bustCache();
            this.backToOverview();
        }
    }

}
