import React from 'react'
import {
    Button,
    IntercomIcon,
    SendIcon,
} from 'ui-kit'
import './CTAs.module.css'

const SALES_EMAIL = 'sales@beatswitch.com';

const ContactSalesButton = () => (
    <a
        className="button success"
        href={`mailto:${SALES_EMAIL}`}
    >
        <span styleName="buttonIconContainer">
            <SendIcon />
        </span>
        Contact sales
    </a>
);

const CTAs = ({ intercomMessage }) => {
    function showIntercomMessage() {
        Intercom('showNewMessage', intercomMessage);
    }

    return (
        <div styleName="container">
            <Button onClick={showIntercomMessage}>
                <span styleName="buttonIconContainer">
                    <IntercomIcon />
                </span>
                I'm interested
            </Button>
            <span styleName="separator">or</span>
            <ContactSalesButton />
        </div>
    );
};

export default CTAs;
