import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import gql from 'graphql-tag'
import { notifySuccess, notifyError } from 'ui-kit'
import { isCrew, isGuest, isPerformer } from 'app/react/helpers/graphQlTypes'
import RowWithState from './RowWithState'

function notifyAttendeeWasCheckedInSuccessfully({ firstName, lastName }, { isDone }) {
    notifySuccess(`${firstName} ${lastName} was checked ${isDone ? 'in': 'out'} successfully.`);
}

function notifySomethingWentWrong({ firstName, lastName }) {
    notifyError(`${firstName} ${lastName}'s check in status could not be updated. You may be missing some permissions to do so.`);
}

const PERFORMER_MUTATION = gql`
    mutation TogglePerformerCheckInMutation($id: ID!) {
        togglePerformerCheckIn(id: $id) {
            checkInStatus {
                isDone
                at
            }
        }
    }
`;

const injectPerformerMutation = graphql(
    PERFORMER_MUTATION, {
        name: 'togglePerformerCheckInMutation',
    },
);

const CREW_MEMBER_MUTATION = gql`
    mutation ToggleCrewMemberCheckInMutation($id: ID!) {
        toggleCrewMemberCheckIn(id: $id) {
            checkInStatus {
                isDone
                at
            }
        }
    }
`;

const injectCrewMemberMutation = graphql(
    CREW_MEMBER_MUTATION, {
        name: 'toggleCrewMemberCheckInMutation',
    },
);

const GUEST_MUTATION = gql`
    mutation ToggleGuestCheckInMutation($id: ID!) {
        toggleGuestCheckIn(id: $id) {
            checkInStatus {
                isDone
                at
            }
        }
    }
`;

const injectGuestMutation = graphql(
    GUEST_MUTATION, {
        name: 'toggleGuestCheckInMutation',
    },
);

function resolveMutationForAttendee(attendee, {
    togglePerformerCheckInMutation,
    toggleCrewMemberCheckInMutation,
    toggleGuestCheckInMutation,
}) {
    if (isPerformer(attendee)) {
        return {
            mutation: togglePerformerCheckInMutation,
            name: 'togglePerformerCheckIn',
        };
    } else if (isCrew(attendee)) {
        return {
            mutation: toggleCrewMemberCheckInMutation,
            name: 'toggleCrewMemberCheckIn',
        };
    } else if (isGuest(attendee)) {
        return {
            mutation: toggleGuestCheckInMutation,
            name: 'toggleGuestCheckIn',
        };
    } else {
        notifySomethingWentWrong(attendee);

        throw new Error(`The attendee should be a Performer, CrewMember or Guest. Received ${attendee.__typename}.`);
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onToggleCheckIn: async () => {
        const { attendee } = ownProps;
        const { mutation, name } = resolveMutationForAttendee(attendee, ownProps);

        try {
            const { data } = await mutation({
                variables: { id: attendee.id },
            });
            const { checkInStatus } = data[name];

            notifyAttendeeWasCheckedInSuccessfully(attendee, checkInStatus);

            return data[name];
        } catch (e) {
            notifySomethingWentWrong(attendee);

            throw e;
        }
    }
});

const injectState = connect(
    null,
    mapDispatchToProps,
);

export default compose(
    injectPerformerMutation,
    injectCrewMemberMutation,
    injectGuestMutation,
    injectState,
)(RowWithState);
