var _ = require('underscore'),
    Backbone = require('backbone');

var CompanyItem = require('app/backbone/models/event/accreditation/companies/CompanyItem'),
    EventItem = require('app/backbone/models/event/settings/accreditation/EventItem'),
    ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin'),
    Modal = require('app/backbone/components/modals/Modal.view');

var CompanyItemModal = Modal.extend({

    mixins: [
        ValidatedForm,
        Form
    ],

    options: [
        'items!',
        'hiddenInputs',
        'disabledInputs',
        'canCreateAnother',
        'width'
    ],

    initialize(options) {
        this._super(options);

        if(!this.items instanceof EventItem.Collection)
            throw new TypeError(`Option 'item' must be instance of 'EventItem.Collection'`);

        !_.isUndefined(this.hiddenInputs) || (this.hiddenInputs = []);
        !_.isUndefined(this.disabledInputs) || (this.disabledInputs = []);
        !_.isUndefined(this.canCreateAnother) || (this.canCreateAnother = true);
        !_.isUndefined(this.width) || (this.width = 500);

        this.inputs = new Backbone.ChildViewContainer();
        this.groups = new Backbone.ChildViewContainer();
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();

        this.unbindModel();
        this.initForm();
        this.bindModel();
        this.renderForm();
        this._super();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                hiddenInputs: this.hiddenInputs,
                height: this.height
            },
            model: this.model.attributes
        };
    },

    initForm() {
        this.disableInputs(this.disabledInputs);

        if(this.isAvailable('item')) {
            var itemsSelectize = this.initSingleSelectize('item', { collection: this.items });
            this.listenTo(itemsSelectize, 'selected', this.onItemSelected);
            this.inputs.add(itemsSelectize, 'item');
            this.groups.add(this.initValidationGroup('item', this.model, 'id'), 'item');
        }
    },

    renderForm() {
        this.inputs.call('render');
        this.groups.call('render');
    },

    newModel(attributes) {
        return new CompanyItem.Model(attributes);
    },

    bindModel() {
        this.stickit();
    },

    unbindModel() {
        this.unstickit();
        if(this.model) this.stopListening(this.model);
    },

    onItemSelected(item) {
        if (item) {
            this.model.set(item.attributes);
            this.model.setRelation('item_group', item.getRelation('item_group').clone());
        }
    }

});

module.exports = CompanyItemModal;
