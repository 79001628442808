import React from 'react'
import { TextEditor as UIKitTextEditor } from 'ui-kit'
import Services from 'app/services'

const TextEditor = (props) => (
    <UIKitTextEditor
        licenseKey={Services.froalaKey}
        {...props}
    />
);

export default TextEditor;
