import {
    LOADING_DATA_STATUS_STARTED,
    LOADING_DATA_STATUS_DONE
} from '../constants';

// Actions
export const LOADING_DATA = 'LOADING_DATA';

export const loadingDataStarted = () => ({
    type: LOADING_DATA,
    status: LOADING_DATA_STATUS_STARTED
});

export const loadingDataDone = () => ({
    type: LOADING_DATA,
    status: LOADING_DATA_STATUS_DONE
});
