import React, { Fragment } from 'react'
import {
    AlertBox,
    Avatar,
    Button,
    Drawer,
    File,
    Grid,
    Summary,
} from 'ui-kit'
import moment, { ISO_8601 } from 'moment'
import Flag from 'app/react/components/util/Flag'
import {isCrew, isGuest, isPerformer} from 'app/react/helpers/graphQlTypes'
import {
    ATTENDEE_TYPE_CREW, ATTENDEE_TYPE_GUEST,
    ATTENDEE_TYPE_PERFORMER,
} from 'app/react/views/event/artists/box-office/components/TopBar'
import RemoveAttendeeModal
    from 'app/react/views/event/artists/box-office/components/modals/RemoveAttendeeModalWithMutation'

const { Cell } = Grid;
const { Item, Group } = Summary;

const ATTENDEE_TYPE_NAMES = {
    Performer: 'Performer',
    CrewMember: 'Crew',
    Guest: 'Guest',
};

function formatDateTime(isoString, format) {
    return moment.parseZone(isoString, ISO_8601).format(format);
}

function type(attendee) {
    if (isPerformer(attendee)) {
        return ATTENDEE_TYPE_PERFORMER;
    }

    if (isCrew(attendee)) {
        return ATTENDEE_TYPE_CREW;
    }

    if (isGuest(attendee)) {
        return ATTENDEE_TYPE_GUEST;
    }

    return undefined;
}

const RelativeContainer = ({ children }) => (
    <div style={{ position: 'relative' }}>
        {children}
    </div>
);

const DetailDrawer = ({
    eventId,
    attendee,
    checkedInAt,
    dateFormat,
    dateFormatShort,
    timeFormat,
    renderTrigger,
    onGoToBooking,
    onDeleteAttendee,
    onEditAttendee,
}) => {
    const {
        firstName,
        lastName,
        email,
        telephone,
        role,
        booking,
        passport,
        via,
        birthday,
    } = attendee;
    const { artist, performance } = booking;
    const {
        start,
        end,
        day,
        stage,
    } = performance || {};
    const { country } = artist;
    const checkedInMoment = checkedInAt ? moment.parseZone(checkedInAt, ISO_8601) : null;
    let passportFile = undefined;

    if (passport) {
        passportFile = {
            ...passport,
            name: passport.name || passport.filename,
        };
    }

    function handleEditAttendee(e) {
        onEditAttendee(eventId, booking.id, attendee.id, type(attendee), e.ctrlKey || e.metaKey);
    }

    return (
        <Drawer
            renderTrigger={renderTrigger}
            renderTitle={() => (
                <Grid hasGutters={false}>
                    <Cell isAutoSized>
                        <RelativeContainer>
                            <Avatar
                                size={4.6}
                                name={artist.name}
                                imageUrl={artist.image ? artist.image.url : null}
                            />
                            {country && (
                                <Flag code={country.code} isBadge />
                            )}
                        </RelativeContainer>
                    </Cell>
                    <Cell className="margin-tiny-left">
                        <h5>{firstName} {lastName}</h5>
                        <span className="subTitle">
                            {ATTENDEE_TYPE_NAMES[attendee.__typename]}
                            {role && (
                                <Fragment> - {role}</Fragment>
                            )}
                        </span>
                    </Cell>
                </Grid>
            )}
            renderFooter={() => (
                <Fragment>
                    <RemoveAttendeeModal
                        attendee={attendee}
                        onDelete={onDeleteAttendee}
                        renderTrigger={({ openModal }) => (
                            <Button
                                type={Button.SECONDARY}
                                onClick={openModal}
                            >
                                Remove
                            </Button>
                        )}
                    />
                    <Button onClick={handleEditAttendee}>
                        Edit
                    </Button>
                </Fragment>
            )}
        >
            <Summary>
                {checkedInMoment
                    ? <AlertBox
                        isSubtle
                        hasIconPrefix
                        type={AlertBox.SUCCESS}
                        className="margin-tiny-bottom"
                    >
                        Checked in on {checkedInMoment.format(dateFormatShort)} at {checkedInMoment.format(timeFormat)}.
                    </AlertBox>
                    : <AlertBox
                        isSubtle
                        hasIconPrefix
                        className="margin-tiny-bottom"
                    >
                        Did not check in yet.
                    </AlertBox>
                }
                {day && stage && <Group>
                    <Item isLarge renderLabel={() => 'Day'}>
                        {day.name}
                    </Item>
                    {stage && (
                        <Item isLarge renderLabel={() => 'Stage'}>
                            {stage.name}
                        </Item>
                    )}
                    <Item isLarge renderLabel={() => 'Set time'}>
                        {formatDateTime(start, timeFormat)} - {formatDateTime(end, timeFormat)}
                    </Item>
                </Group>}
                {email && (
                    <Item renderLabel={() => 'Email'}>
                        {email}
                    </Item>
                )}
                {telephone && (
                    <Item renderLabel={() => 'Telephone'}>
                        {telephone}
                    </Item>
                )}
                {birthday && (
                    <Item renderLabel={() => 'Birthday'}>
                        {formatDateTime(birthday, `${dateFormat} ${timeFormat}`)}
                    </Item>
                )}
                {via && (
                    <Item renderLabel={() => 'Via'}>
                        {via}
                    </Item>
                )}
                <Item renderLabel={() => 'Booking'}>
                    <a onClick={(e) => {
                        onGoToBooking(booking.id, e.ctrlKey || e.metaKey);
                        e.stopPropagation();
                    }}>
                        View booking
                    </a>
                </Item>
                {passportFile && (
                    <Fragment>
                        <Item renderLabel={() => 'Passport'}>
                            {passport.description}
                        </Item>
                        <File uploadedFile={passportFile} />
                    </Fragment>
                )}
            </Summary>
        </Drawer>
    );
};

export default DetailDrawer;
