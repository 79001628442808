import OptionTypes from 'app/backbone/helpers/OptionTypes';
import CompanyItemsCard from './CompanyItemsCard.view';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';

export default class CompanyItemGroupCard extends CompanyItemsCard {

    optionTypes() {
        const { items, periods, editing } = super.optionTypes();
        return {
            itemGroup: OptionTypes.instanceOf(EventItemGroup.Model).isRequired,
            items,
            periods,
            editing
        };
    }

    initHeader() {
        this.title = this.itemGroup.get('name');
        return super.initHeader();
    }

    updateItems() {
        const { itemGroup, items } = this;
        const company = items.getRelation('company');
        const event = company.getRelation('event');
        navigate(`/events/${event.id}/accreditation/companies/${company.id}/item_groups/${itemGroup.id}/edit`);
    }

}
