import * as Account from 'app/react/entities/accounts/helpers';
import { searchAll } from './actions';

export const searchAllForCurrentContext = (searchTerm) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);

    // TODO: Check cache

    return dispatch(searchAll(accountId, searchTerm));
};
