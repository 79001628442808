import React from 'react'
import { Card } from 'ui-kit'
import { InputGroup } from 'ui-kit'

const { Header, Content, Footer } = Card;
const { Title } = Header;

export const TITLE = 'Field properties';
export const REQUIRED_LABEL = 'Required';
export const LABEL_TEXT_LABEL = 'Label text';
export const PLACEHOLDER_TEXT_LABEL = 'Placeholder text';

export const FormBuilderCard = ({
    selectedFormField = {},
    changeFormFieldProps = () => {},
}) => (
    <Card className="isSticky">
        <Header>
            <Title>{TITLE}</Title>
        </Header>
        <Content>
            <InputGroup>
                <label>
                    <input
                        type="checkbox"
                        checked={selectedFormField.required || false}
                        onChange={e => changeFormFieldProps('required', e.target.checked)}
                    />
                    {REQUIRED_LABEL}
                </label>
            </InputGroup>
            <InputGroup>
                <label>{LABEL_TEXT_LABEL}</label>
                <input
                    type="text"
                    value={selectedFormField.label || ''}
                    onChange={e => changeFormFieldProps('label', e.target.value)}
                />
            </InputGroup>
            {selectedFormField.type !== "date" &&
                <InputGroup>
                    <label>{PLACEHOLDER_TEXT_LABEL}</label>
                    <input
                        type="text"
                        value={selectedFormField.placeholder || ''}
                        onChange={e => changeFormFieldProps('placeholder', e.target.value)}
                    />
                </InputGroup>
            }
        </Content>
        <Footer />
    </Card>
);

export default FormBuilderCard;
