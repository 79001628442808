import $ from 'jquery';
import _ from 'underscore';
import React from 'react';

export default class BackboneView extends React.Component {

    componentDidMount() {
        const { props, refs } = this;
        const { placeholder } = refs;
        const { viewClass } = props;

        const options = _.extend({}, props);
        delete options.viewClass;
        const view = new viewClass(options);

        $(placeholder).html(view.el);
        view.render();
        this.view = view;
    }

    componentWillUnmount() {
        this.view.remove();
    }

    render() {
        return (
            <div ref="placeholder"></div>
        );
    }
}
