import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'venue'
})
class Venue extends BaseModel {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model, // Circular
            event: require('app/backbone/models/event/Event').Model // Circular
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a venue name.'
            },
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('account');
        const accountId = this.getRelation('account').get('id');
        return `/api/accounts/${accountId}/venues`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

}

@Collection({
    key: 'venues',
    model: Venue
})
class VenueCollection extends BaseCollection {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model // Circular
        }
    }

    url() {
        this.validateRelations('account');
        const accountId = this.getRelation('account').get('id');
        return `/api/accounts/${accountId}/venues`;
    }

}

const PageableCollection = VenueCollection.prototype.PageableCollection();

export {
    Venue as Model,
    VenueCollection as Collection,
    PageableCollection
};
