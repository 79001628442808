var Backbone = require('backbone');

var template = require('./AvailableProfiles');

var CardContent = Backbone.View.extend({

    template,

    options: [ 'profiles!' ],

    initialize(options) {
        this.setOptions(options);
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {},
            model: {},
            collection: this.profiles.toJSON()
        }
    }
});

module.exports = CardContent;
