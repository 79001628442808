import BaseController from 'app/backbone/helpers/BaseController'
import TimeSlotFormView from 'app/backbone/views/event/artists/bookings/time_slots/Form.view'

const createRoute = (
    eventId = ':event',
    bookingId = ':booking',
) => `events/${eventId}/artists/bookings/${bookingId}/time-slots`;

const updateRoute = (
    eventId = ':event',
    bookingId = ':booking',
    timeSlotId = ':timeSlot'
) => `events/${eventId}/artists/bookings/${bookingId}/time-slots/${timeSlotId}`;

const performanceUpdateRoute = (
    eventId = ':event',
    bookingId = ':booking',
    timeSlotId = ':timeSlot'
) => `events/${eventId}/artists/bookings/${bookingId}/performance-time-slots/${timeSlotId}`;

export default class TimeSlotsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(createRoute(), null, this.create.bind(this));
        this.router.route(updateRoute(), null, this.update.bind(this));
        this.router.route(performanceUpdateRoute(), null, this.performanceUpdate.bind(this));
    }

    create() {
        this.app.renderContentView(new TimeSlotFormView());
    }

    update() {
        this.app.renderContentView(new TimeSlotFormView());
    }

    performanceUpdate() {
        this.app.renderContentView(new TimeSlotFormView());
    }
};
