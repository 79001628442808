import React from 'react'
import { values } from 'lodash'
import {
    Dropdown,
    TableActionsDropdown,
    Tooltip,
} from 'ui-kit'
import ReservationStatusIcon from './ReservationStatusIcon'
import { requestStatusMap } from 'app/react/helpers/reservations'

const ReservationStatusActions = ({
    reservation = {},
    isOpen = false,
    onToggleDropdown = () => {},
    onClickReviewReservation = () => {},
}) => {
    const { status = {} } = reservation;
    const requestStatus = requestStatusMap[status.id] || {};
    const reviewStatusOptions = values(requestStatusMap)
        .filter((requestStatus) => requestStatus.ID !== reservation.status.id);

    return (
        <Dropdown
            renderRef={({ ref, refProps }) => (
                <div ref={ref} {...refProps}>
                    <Tooltip renderContent={() => status.name}>
                        <ReservationStatusIcon requestStatus={requestStatus} />
                    </Tooltip>
                </div>
            )}
            renderContent={({ handleClose }) => (
                <ul className="text-align-left" style={{ padding: "var(--spacingSmaller) 0" }}>
                    {reviewStatusOptions.map((requestStatus) => (
                        <TableActionsDropdown.Action
                            key={requestStatus.ID}
                            onClick={e => {
                                onClickReviewReservation(reservation, requestStatus);
                                handleClose();
                                e.stopPropagation();
                            }}
                        >
                            <ReservationStatusIcon requestStatus={requestStatus} className="margin-small-right" />
                            {requestStatus.ACTION}
                        </TableActionsDropdown.Action>
                    ))}
                </ul>
            )}
        />
    );
};

export default ReservationStatusActions;
