import _ from 'underscore';
import * as OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from '../Base.view';
import FormView from '../Form.view';
import CardFooter from './CardFooter.view';
import CardContent from './CardContent.view';
import CardHeader from './CardHeader.view';
import template from './Card.hbs';

export default class Card extends BaseView {

    get template() {
        return template;
    }

    className() {
        return 'card';
    }

    ui() {
        return {
            header: '.card--header',
            content: '.card--content',
            footer: '.card--footer',
            collapseButton: '.card--header-collapse-container'
        };
    }

    events() {
        return {
            'click collapseButton': 'onClickToggleCollapseContainer'
        };
    }

    optionTypes() {
        return {
            title: OptionTypes.string,
            text: OptionTypes.string,
            isCollapsible: OptionTypes.bool,
            hasMaxHeight: OptionTypes.bool,
            collapsed: OptionTypes.bool
        };
    }

    optionDefaults() {
        return {
            isCollapsible: false,
            collapsed: false
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Props
        if (this.isCollapsible) {
            const storedCollapsedSetting = this.getSetting('collapsed');
            if (!_.isUndefined(storedCollapsedSetting)) this.collapsed = storedCollapsedSetting;
        }
        // Subviews
        this.header = this.initHeader();
        this.content = this.initContent();
        this.footer = this.initFooter();
    }

    render() {
        const { isCollapsible, hasMaxHeight, collapsed } = this;
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.assignSubview(this.header, this.ui.header);
        this.assignSubview(this.content, this.ui.content);
        this.assignSubview(this.footer, this.ui.footer);

        if (isCollapsible) {
            this.$el.addClass('collapsible');
            this.toggleCollapse(collapsed);
        }

        if (hasMaxHeight) {
            this.ui.content.addClass('card--content--max-height');
        }

        return this.delegateEvents();
    }

    remove() {
        this.header.remove();
        this.content.remove();
        this.footer.remove();
        super.remove();
    }

    viewModel() {
        return {
            view: {
                hasHeader: this.hasHeader(),
                hasContent: this.hasContent(),
                hasFooter: this.hasFooter()
            },
            model: {}
        };
    }

    // Header
    initHeader() {
        const { title, isCollapsible } = this;
        const actions = this.initHeaderActions();
        return new CardHeader({ title, actions, isCollapsible });
    }

    initHeaderActions() {
        return [];
    }

    hasHeader() {
        return !! this.header;
    }

    // Content
    initContent() {
        const { text, content } = this;
        return new CardContent({ text, content });
    }

    hasContent() {
        return !! this.content;
    }

    // Footer
    initFooter() {
        const actions = this.initFooterActions();
        return new CardFooter({ actions });
    }

    initFooterActions() {
        return [];
    }

    hasFooter() {
        return !! this.footer;
    }

    addError(message, field) {
        // We check to see if the content view is a form by either an instance check or for older
        // views the presence of a groups property
        if (field && (this.content instanceof FormView || this.content.groups)) {
            const validationGroup = this.content.getGroup(field);
            if (validationGroup) validationGroup.addError(message);
            else this.footer.setError(message);
        } else this.footer.setError(message);
    }

    removeError() {
        this.footer.setError();
    }

    hasError() {
        return this.footer.hasError();
    }

    validateResponse(xhr) {
        if (xhr.status === 422 && xhr.responseJSON) {
            const errors = xhr.responseJSON.errors || xhr.responseJSON;
            _.each(errors, (messages, field) => {
                messages.forEach((message) => {
                    this.addError(message, field);
                });
            });
        } else this.addError(xhr.statusText);
    }

    toggleCollapse(collapsed) {
        this.$el.toggleClass('collapsed', collapsed);
        this.collapsed = collapsed;
        this.storeSetting('collapsed', collapsed);
    }

    onClickToggleCollapseContainer() {
        this.toggleCollapse(!this.collapsed);
    }

}
