import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';
import { Loading } from 'app/react/components/_index';
import { GroundTransport, Inbound, Outbound } from 'app/react/views/event/artists/bookings/transports/components/Icons';

const TransportsSchedule = ({
    transports = [],
    dateFormat,
    timeFormat,
    isFetchingTransports = false,
    currentPickupId,
}) => (
    <Fragment>
        {isFetchingTransports
            ? <Loading />
            : <table className="overflow">
                <thead>
                    <tr>
                        <th style={{ width: '60px' }} />
                        <th style={{ width: '150px' }}>Mode</th>
                        <th style={{ width: '200px' }}>Departure</th>
                        <th style={{ width: '90px' }} />
                        <th style={{ width: '75px' }} />
                        <th style={{ width: '200px' }}>Arrival</th>
                        <th style={{ width: '90px' }} />
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    { transports.map(transport => {
                        const mDeparture = moment.utc(transport.departure, moment.ISO_8601);
                        const mArrival = moment.utc(transport.arrival, moment.ISO_8601);
                        return (
                            <tr
                                key={transport.id}
                                style={{
                                    backgroundColor: transport.id === currentPickupId ? '#FDF3CE' : '#FFF'
                                }}
                            >
                                <td>
                                    {(() => {
                                        if (transport.mode.id === 3) {
                                            return <GroundTransport className={transport.modeIcon} />;
                                        }
                                        if (transport.mode.id === 1) {
                                            if (transport.type === 1) {
                                                return <Inbound className={transport.modeIcon}/>;
                                            }
                                            if (transport.type === 2) {
                                                return <Outbound className={transport.modeIcon} />;
                                            }
                                        }
                                        return  <i className={transport.modeIcon} />;
                                    })()}
                                </td>
                                <td>{transport.mode.name}</td>
                                <td>{transport.departureLocationName}</td>
                                <td>{mDeparture.format(dateFormat)}</td>
                                <td>{mDeparture.format(timeFormat)}</td>
                                <td>{transport.arrivalLocationName}</td>
                                <td>{mArrival.format(dateFormat)}</td>
                                <td>{mArrival.format(timeFormat)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }
    </Fragment>
);

TransportsSchedule.propTypes = {
    isEditingPerformanceName: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    dayStart: PropTypes.string,
    dayEnd: PropTypes.string,

    pickups: PropTypes.array,
    stages: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectDay: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
};

export default TransportsSchedule;
