import React from 'react';
import { debounce } from 'underscore';

class DebouncedInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            checked: props.checked
        };

        this.handleChange = this.handleChange.bind(this);
        this.debounceOnChange(this.props.onChange);
    }

    componentWillReceiveProps(nextProps)  {
        const { value, checked } = nextProps;
        if (this.state.value !== value) {
            this.setState({ value })
        }
        if (this.state.checked !== checked) {
            this.setState({ checked })
        }
        // We need to rebounce the onChange prop to keep it in sync with redux merge props
        if (typeof nextProps.onChange === 'function') {
            this.debounceOnChange(nextProps.onChange )
        }
    }

    debounceOnChange(onChange) {
        this.onChangeDebounced = debounce(onChange, 250);
    }

    handleChange(e) {
        const { value, checked } = e.target;
        this.setState({ value, checked }, () => {
            if (this.props.type === 'checkbox') {
                this.onChangeDebounced(checked)
            } else {
                this.onChangeDebounced(value)
            }
        })
    };

    render() {
        const Input = this.props.component || 'input';
        const propsToTransfer = { ...this.props };
        delete propsToTransfer.component;
        delete propsToTransfer.onChange;
        delete propsToTransfer.checked;
        delete propsToTransfer.value;

        if (this.props.type === 'checkbox') {
            return (
                <Input
                    {...propsToTransfer}
                    onChange={this.handleChange}
                    checked={this.state.checked}
                />
            )
        }

        return (
            <Input
                {...propsToTransfer}
                onChange={this.handleChange}
                value={this.state.value }
            />
        )
    }
}

export default DebouncedInput;
