import moment from 'moment';
import { pick } from 'underscore';
import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Day from 'app/react/entities/events/days/index';
import * as Stage from 'app/react/entities/events/stages/index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Performance from 'app/react/entities/events/performances/index';
import { reformatISOString } from '../../../performances/views/utils';
import CreateView from './UpdateView';
import { detailsRoute } from 'app/routes/event/artists/bookings/Controller';

export const BOOKING_CARD_ID = 'updateBookingCard';
export const PERFORMANCE_CARD_ID = 'updateBookingPerformanceCard';
export const VIEW_ID = 'updateBookingView';
const POPUP_MESSAGES = {
    FAILED_TO_UPDATE: name =>
        `Failed to update booking <strong>${name}</strong>.`,
    SUCCESSFUL_UPDATE: name =>
        `Booking <strong>${name}</strong> successfully updated. Redirecting...`
};

const mapStateToProps = (state) => {
    const {
        isSaving = false,
    } = stateHelpers.getComponentState(state, VIEW_ID);
    const {
        values: bookingValues = {},
        errors: bookingErrors = {},
    } = stateHelpers.getComponentState(state, BOOKING_CARD_ID);
    const {
        isEditingPerformanceName = false,
        values: performanceValues = {},
        errors: performanceErrors = {},
    } = stateHelpers.getComponentState(state, PERFORMANCE_CARD_ID);
    const booking = Booking.stateHelpers.getCurrent(state) || {};
    return {
        isFetching: (
            isSaving ||
            Booking.stateHelpers.isFetchingCurrent(state) ||
            Day.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Stage.stateHelpers.isFetchingAllForCurrentContext(state) ||
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            BookingType.stateHelpers.isFetchingAll(state) ||
            BookingStatus.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        accountId: stateHelpers.accounts.getCurrentId(state),
        eventId: stateHelpers.events.getCurrentId(state),
        bookingId: booking.id,
        performanceId: booking.performance,
        isArtistTypesEnabled: stateHelpers.events.isArtistTypesEnabledForCurrent(state),
        dateFormat: stateHelpers.events.getCurrentDateFormat(state),
        timeFormat: stateHelpers.events.getCurrentTimeFormat(state),
        selectedDay: Day.stateHelpers.getOne(state, performanceValues.day),
        bookingIsAttachedToPerformance: !! booking.performance,
        isEditingPerformanceName,
        bookingValues,
        bookingErrors,
        performanceValues,
        performanceErrors,
        artist: Artist.stateHelpers.getOne(state, booking.artist),
        days: Day.stateHelpers.getAllForCurrentContext(state),
        stages: Stage.stateHelpers.getAllForCurrentContext(state),
        artistTypes: ArtistType.stateHelpers.getAllForCurrentContext(state),
        bookingTypes: BookingType.stateHelpers.getAll(state),
        bookingStatuses: BookingStatus.stateHelpers.getAllForCurrentContext(state),
    };
};

const isSet = value => typeof value !== 'undefined' && value !== '' && value !== null;

const toPerformanceApiValues = (values) => {
    let start;
    let end;
    let changeover;
    let publishDate;
    if (isSet(values.start)) {
        start = reformatISOString(values.start);
    }
    if (isSet(values.end)) {
        end = reformatISOString(values.end);
    }
    if (isSet(values.publishDate)) {
        publishDate = reformatISOString(values.publishDate);
    }
    if (isSet(values.changeover)) {
        changeover = parseInt(values.changeover, 10) * 60;
    }
    return {
        ...pick(values, ['title', 'day', 'stage']),
        start,
        end,
        changeover,
        publishDate
    };
};

const mapDispatchToProps = (dispatch) => {
    const setBookingCardState = (key, value) =>
        dispatch(actions.setComponentStateValue(BOOKING_CARD_ID, key, value));
    const setPerformanceCardState = (key, value) =>
        dispatch(actions.setComponentStateValue(PERFORMANCE_CARD_ID, key, value));
    const setViewState = (key, value) =>
        dispatch(actions.setComponentStateValue(VIEW_ID, key, value));

    return ({
        onChangeBookingValue: (key, value) => {
            setBookingCardState(`values.${key}`, value);
        },
        onChangePerformanceValue: (key, value) => {
            setPerformanceCardState(`values.${key}`, value);
        },
        onSelectDay: (dayId, dayStart) => {
            setPerformanceCardState('values.day', dayId);
            setPerformanceCardState('values.start', dayStart);
            const mEnd = moment(dayStart);
            mEnd.add(1, 'hours');
            setPerformanceCardState('values.end', mEnd.toISOString());
        },
        onToggleEditPerformanceName: (isEditing = false) => {
            setPerformanceCardState('isEditingPerformanceName', isEditing);
        },
        onUpdate: (eventId, bookingId, bookingIsAttachedToPerformance, performanceId, bookingValues, performanceValues, artistName) => {
            setViewState('isSaving', true);
            const bookingAPIValues = bookingValues;
            const performanceAPIValues = toPerformanceApiValues(performanceValues);
            if (bookingIsAttachedToPerformance) {
                Promise.all([
                    dispatch(Booking.actions.updateOne(eventId, bookingId, bookingAPIValues)),
                    dispatch(Performance.actions.updateOne(eventId, performanceId, performanceAPIValues)),
                ]).then(([{
                    error: bookingError,
                }, {
                    error: performanceError,
                },]) => {
                    if (bookingError || performanceError) {
                        setViewState('isSaving', false);
                        const bookingFormErrors = getFormFieldErrorsFromResponseJSON(bookingError);
                        const performanceFormErrors = getFormFieldErrorsFromResponseJSON(performanceError);
                        setBookingCardState('errors', bookingFormErrors);
                        setPerformanceCardState('errors', performanceFormErrors);
                        dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_UPDATE(artistName)));
                    } else {
                        dispatch(actions.showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_UPDATE(artistName)));
                        dispatch(actions.navigate.to(detailsRoute(eventId, bookingId), { pageReload: true }));
                    }
                });
            } else {
                dispatch(Booking.actions.updateOne(eventId, bookingId, bookingAPIValues)).then(({
                    error
                }) => {
                    if (error) {
                        setViewState('isSaving', false);
                        const formErrors = getFormFieldErrorsFromResponseJSON(error);
                        setBookingCardState('errors', formErrors);
                        dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_UPDATE(artistName)));
                    } else {
                        dispatch(actions.showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_UPDATE(artistName)));
                        dispatch(actions.navigate.to(detailsRoute(eventId, bookingId), { pageReload: true }));
                    }
                });
            }
        },
        onBack: (eventId, bookingId) => {
            navigate(`/events/${eventId}/artists/bookings/${bookingId}`, { pageReload: true });
        }
    });
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSelectDay: (dayId) => {
        const { days } = stateProps;
        const day = days.find(({ id }) => id === dayId);
        if (typeof day !== 'undefined') {
            dispatchProps.onSelectDay(dayId, day.start);
        }
    },
    onUpdate: () => {
        const { eventId, bookingId, bookingIsAttachedToPerformance, performanceId, bookingValues, performanceValues, artist } = stateProps;
        dispatchProps.onUpdate(
            eventId,
            bookingId,
            bookingIsAttachedToPerformance,
            performanceId,
            bookingValues,
            performanceValues,
            artist.name
        );
    },
    onBack: () => {
        const { eventId, bookingId } = stateProps;
        dispatchProps.onBack(eventId, bookingId);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateView);
