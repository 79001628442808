export const base = (
    eventId = ':event'
) => `events/${eventId}/artists/logistics`;

export const transportsOverview = (
    eventId = ':event'
) => `${base(eventId)}/transports`;

export const vehiclesOverview = (
    eventId = ':event'
) => `${base(eventId)}/vehicles`;

export const pickupsOverview = (
    eventId = ':event'
) => `${base(eventId)}/pickups`;

export const pickupsScheduler = (
    eventId = ':event'
) => `${base(eventId)}/pickups/scheduler`;

export const driversOverview = (
    eventId = ':event'
) => `${base(eventId)}/drivers`;

export const driversDetail = (
    eventId = ':event',
    driverId = ':driver',
) => `${base(eventId)}/drivers/${driverId}`;

export const createDriver = (
    eventId = ':event'
) => `${base(eventId)}/drivers/create`;

