import { oneLineTrim } from 'common-tags';

export const updateAll = (
    eventId,
    artistTypeId,
    sectionId,
    includes = []
) => oneLineTrim`
    events/${eventId}/artist-types/${artistTypeId}/production-website/artists/settings/${sectionId}/form-fields
`;
