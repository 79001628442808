import $ from 'jquery';
import NavigationCollapsable from '../NavigationCollapsable.view';
import MenuDropdownView from './MenuDropdown.view';

export default class DropDownMenu extends NavigationCollapsable {

    events() {
        return {
            'click': 'onClick'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.dropdown = new MenuDropdownView();
        this.dropDownId = options.dropDownId;
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.dropdown, this.getDropdownEl());
        this.bindDocumentClick();
        return this.delegateEvents();
    }

    getDropdownEl() {
        return $(this.dropDownId);
    }

    // This method closes the dropdown when clicked outside
    bindDocumentClick() {
        $(document).click((event) => {
            if (this.dropdown.shown) {
                const clickedSelf = $(event.target).is(this.el);
                const clickedChildren = this.$el.has(event.target).length > 0;
                const clickedButton = $(event.target).is(this.dropdown.el);
                const clickedButtonChildren = this.dropdown.$el.has(event.target).length > 0;

                if (!clickedSelf && !clickedChildren && !clickedButton && !clickedButtonChildren) {
                    this.hideDropdown();
                }
            }
        });
    }

    onClick() {
        this.dropdown.toggle();
    }

    showDropdown() {
        this.dropdown.show();
    }

    hideDropdown() {
        this.dropdown.hide();
    }
}
