import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import 'intersection-observer'
import Observer from '@researchgate/react-intersection-observer'

const Sentinel = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
`;

const ScrollSentinel = ({ onIntersect }) => (
    <Observer
        root="#content-wrapper"
        onChange={(e) => {
            if (e.isIntersecting) {
                onIntersect();
            }
        }}
    >
        <Sentinel />
    </Observer>
);

ScrollSentinel.propTypes = {
    onIntersect: PropTypes.func.isRequired,
};

export default ScrollSentinel;
