import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';

const { TitleMenu } = Header;
const { 
    Title, 
    TitleDivider, 
    SubTitle,
} = TitleMenu;

const TITLE = 'Bookings';

const BookingContentHeader = ({
    subTitles = [],
    children,
}) => (
    <Header>
        <TitleMenu>
            <Title>{TITLE}</Title>
            {subTitles.map((subTitle) => [
                <TitleDivider />,
                <SubTitle>{subTitle}</SubTitle>
            ])}
            {children}
        </TitleMenu>
    </Header>
);

BookingContentHeader.propTypes = {
    bookingName: PropTypes.string,
    onClickBack: PropTypes.func
};

export default BookingContentHeader;
