import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';
import { beatswitchContextPropTypes } from 'app/react/components/util/connectBeatswitchContext';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { Tooltip } from 'app/react/components/_index';
import ContentHeaderContainer from '../../components/layout/ContentHeaderContainer';
import PaginatedVehicleTableModalWrapperQueryContainer from '../components/table/PaginatedTableModalWrapperQueryContainer'

const { Content } = ContentWrapper;
const { Header } = Card;
const { Title, Action } = Header;

const CARD_TITLE = 'Overview';
const CREATE_TOOLTIP = 'Create new vehicle';

const VehiclesOverview = ({
    store,
    eventId,
    isFetching = false,
    vehicles = [],
    vehicleTypes = [],
    onOpenCreateModal = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeaderContainer activeTab="vehicles" />
        <Content isLoading={isFetching}>
            <Card>
                <Header>
                    <Title>{CARD_TITLE}</Title>
                    <Tooltip content={CREATE_TOOLTIP}>
                        <Action onClick={onOpenCreateModal}>
                            <i className="bs-icon-plus" />
                        </Action>
                    </Tooltip>
                </Header>
                <Card.Content
                    isFlexContainer={vehicles.length === 0}
                    style={{ minHeight: '200px' }}
                >
                    <PaginatedVehicleTableModalWrapperQueryContainer />
                </Card.Content>
            </Card>
        </Content>
    </ContentWrapper>
);

VehiclesOverview.propTypes = {
    ...beatswitchContextPropTypes,
    store: PropTypes.object,
    eventId: PropTypes.number,
    isFetching: PropTypes.bool,
    vehicles: PropTypes.array,
    vehicleTypes: PropTypes.array,
    onOpenCreateModal: PropTypes.func,
};

export default VehiclesOverview;
