import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import AccommodationsOverview from 'app/backbone/views/account/crm/accommodations/AccommodationsOverview.view';
import LocationsOverviewView from 'app/backbone/views/account/crm/locations/LocationsOverview.view';
import LocationRouteCreateView from 'app/backbone/views/account/crm/locations/CreateRoute.view';
import CompaniesOverview from 'app/backbone/views/account/crm/companies/CompaniesOverview.view';
import CompanyEditView from 'app/backbone/views/account/crm/companies/EditCompany.view';
import CompanyDetailView from 'app/backbone/views/account/crm/companies/Detail.view';
import ArtistsOverviewView from 'app/backbone/views/account/crm/artists/ArtistsOverview.view';
import ArtistDetailView from 'app/backbone/views/account/crm/artists/Detail.view';
import ContactsOverviewView from 'app/backbone/views/account/crm/contacts/ContactsOverview.view';
import * as Routes from './routes'
import CreateFileView from 'app/backbone/views/account/events/artists/bookings/files/Create.view'

// Backbone Controller
export default class CrmController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.accommodations(), null, this.accommodations.bind(this));
        this.router.route(Routes.locations(), null, this.locations.bind(this));
        this.router.route(Routes.locationsRoutesCreate(), null, this.locationsRoutesCreate.bind(this));
        this.router.route(Routes.companies(), null, this.companies.bind(this));
        this.router.route(Routes.companiesUpdate(), null, this.companiesUpdate.bind(this));
        this.router.route(Routes.companiesDetail(), null, this.companiesDetail.bind(this));
        this.router.route(Routes.artists(), null, this.artists.bind(this));
        this.router.route(Routes.artistsDetail(), null, this.artistsDetail.bind(this));
        this.router.route(Routes.contacts(), null, this.contacts.bind(this));
        this.router.route(Routes.createArtistFile(), null, this.createArtistFile.bind(this));
    }

    accommodations() {
        this.app.renderContentView(new AccommodationsOverview());
    }

    locations() {
        this.app.renderContentView(new LocationsOverviewView());
    }

    locationsRoutesCreate() {
        this.app.renderContentView(new LocationRouteCreateView());
    }

    companies() {
        this.app.renderContentView(new CompaniesOverview());
    }

    companiesUpdate() {
        this.app.renderContentView(new CompanyEditView());
    }

    companiesDetail() {
        this.app.renderContentView(new CompanyDetailView());
    }

    artists() {
        this.app.renderContentView(new ArtistsOverviewView());
    }

    artistsDetail() {
        this.app.renderContentView(new ArtistDetailView());
    }

    contacts() {
        this.app.renderContentView(new ContactsOverviewView());
    }

    createArtistFile() {
        this.app.renderContentView(new CreateFileView());
    }
}
