import SubTableLastRow from 'app/backbone/components/tables/rows/sub_table/SubTableLastRow.view';
import CompanyProfileItemAddModal from '../../items/modals/CompanyProfileItemAddModal.view';
import AvailableCompanyProfileItemTable from '../../items/tables/AvailableCompanyProfileItemTable.view';

export default class CompanyProfileLastRow extends SubTableLastRow {

    initialize(options = {}) {
        super.initialize(options);

        this.profileItems = this.subTableCollection;
        this.listenTo(this.model, 'backgrid:sub-table:add', this.openItemAddModal);
        this.listenTo(this.profileItems, 'update reset', this.updateModalItems);

        // Subviews
        this.itemAddModal = this.initItemModal();
        this.itemTable = this.initItemTable();
    }

    render() {
        super.render();
        this.updateAddButton();
    }

    initItemModal() {
        const items = this.getModalItems();
        const modal = new CompanyProfileItemAddModal({ items });
        this.listenTo(modal, 'add', this.onItemAddedByModal);
        return modal;
    }

    getSubTableCollection() {
        return this.model.getRelation('available_items');
    }

    onItemAddedByModal(item) {
        const { profileItems } = this;
        item.profile = this.model;
        profileItems.add(item);
    }

    initItemTable() {
        const { periods, profileItems, model } = this;
        const table = new AvailableCompanyProfileItemTable({ collection: profileItems, periods });
        // Inform the other rows (first and center) that the sub collection table is been created and pass it to them
        model.trigger('backgrid:sub-table:created', table);
        return table;
    }

    openItemAddModal() {
        if (!this.itemAddModal.items.isEmpty()) this.itemAddModal.open();
    }

    updateModalItems() {
        const { itemAddModal } = this;
        itemAddModal.items = this.getModalItems();
        this.updateAddButton();
    }

    updateAddButton() {
        const { itemAddModal, cells } = this;
        const actionCell = cells[0];
        actionCell.toggleAction('add-item', itemAddModal.items.isEmpty());
    }

    getModalItems() {
        const { items, profileItems } = this;
        return items.rejectIds(profileItems.pluck('id')).rejectByAttribute('uses_amounts');
    }
}
