import { notify } from 'ui-kit';
import {
    POPUP_ERROR,
    POPUP_SUCCESS,
    POPUP_INFO
} from '../constants';

export const showPopup = (type, message) => () => notify(message, type);
export const showErrorPopup = (message) => showPopup(POPUP_ERROR, message);
export const showSuccessPopup = (message) => showPopup(POPUP_SUCCESS, message);
export const showInfoPopup = (message) => showPopup(POPUP_INFO, message);
