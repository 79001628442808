var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <p>"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.body : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.ModalTitle,depth0,{"name":"ModalTitle","hash":{"title":((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.title : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.ModalBody,depth0,{"name":"ModalBody","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});