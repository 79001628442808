import React from 'react';
import PropTypes from 'prop-types';
import { Card, PaginationBar } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import {
	Tooltip,
	ClearActiveFiltersCardAction,
	TableLoadingPlaceholder,
} from 'app/react/components/_index';
import ContentHeader from '../../components/layout/ContentHeader';
import BookingTable from './Table';

const { Content } = ContentWrapper;
const { Header, Footer } = Card;
const { Title, Action } = Header;

const CARD_TITLE = 'Overview';
const ADD_TOOLTIP = 'Create Booking';

const BookingOverview = ({
	store,
	eventId,
	isFetching = true,
	isArtistTypesEnabled = true,
	timeFormat,
	eventPermissions = {},

	bookings = [],
	days = [],
	stages = [],
	artists = [],
	artistTypes = [],
	bookingTypes = [],
	bookingStatuses = [],

	bookingsPagination = {},
	isFetchingBookings,
	selectedPageSize,
	requestedFilters,
	selectedAdvancingUserFilter,
	selectedDayFilter,
	selectedStageFilter,
	selectedArtistTypeFilter,
	selectedBookingStatusFilter,
	searchValue,
	isShowingSearchResults,

	onChangePageSize = () => {},
	onChangeFilter = () => {},
	onChangeSearchValue = () => {},
	onSearch = () => {},
	onClearSearch = () => {},
	onClearAllFilters = () => {},
	onSort = () => {},
	onChangePage = () => {},

	onClickBooking = () => {},
	onClickBookingTransport = () => {},
	onClickBookingPickup = () => {},
	onCreateBookingTransport = () => {},
	onClickBookingParking = () => {},
	onClickBookingReservations = () => {},
	onToggleBookingTask = () => {},
	onSendBookingItinerary = () => {},
	onSendBookingLogin = () => {},
	onDeleteBooking = () => {},
	onCreateBooking = () => {},
}) => (
	<ContentWrapper store={store}>
		<ContentHeader />
		<Content isLoading={isFetching}>
			<Card>
				<Header>
					<Title>{CARD_TITLE}</Title>
					{(Object.keys(requestedFilters).length > 0 ||
						searchValue.length > 0) && (
						<ClearActiveFiltersCardAction onClick={onClearAllFilters} />
					)}
					<Tooltip content={ADD_TOOLTIP}>
						<Action onClick={() => onCreateBooking()}>
							<i className='bs-icon-plus' />
						</Action>
					</Tooltip>
				</Header>
				<Card.Content>
					<BookingTable.TopBar
						isArtistTypesEnabled={isArtistTypesEnabled}
						days={days}
						stages={stages}
						artistTypes={artistTypes}
						selectedAdvancingUserId={selectedAdvancingUserFilter}
						selectedDayId={selectedDayFilter}
						selectedStageId={selectedStageFilter}
						selectedArtistTypeId={selectedArtistTypeFilter}
						selectedBookingStatusId={selectedBookingStatusFilter}
						searchValue={searchValue}
						isShowingSearchResults={isShowingSearchResults}
						onChangeFilter={onChangeFilter}
						onChangeSearchValue={onChangeSearchValue}
						onSearch={onSearch}
						onClearSearch={onClearSearch}
						bookingStatuses={bookingStatuses}
						isFetchingBookings={isFetchingBookings}
					/>
					{isFetchingBookings ? (
						<TableLoadingPlaceholder />
					) : (
						<BookingTable
							bookings={bookings}
							sortKey={bookingsPagination.requestedSortKey}
							sortOrder={bookingsPagination.requestedSortOrder}
							timeFormat={timeFormat}
							eventPermissions={eventPermissions}
							onSort={onSort}
							onClickBooking={onClickBooking}
							onClickBookingTransport={onClickBookingTransport}
							onClickBookingPickup={onClickBookingPickup}
							onCreateBookingTransport={onCreateBookingTransport}
							onClickBookingParking={onClickBookingParking}
							onClickBookingReservations={onClickBookingReservations}
							onToggleBookingTask={onToggleBookingTask}
							onSendBookingItinerary={onSendBookingItinerary}
							onSendBookingLogin={onSendBookingLogin}
							onDeleteBooking={onDeleteBooking}
						/>
					)}
					<PaginationBar
						page={bookingsPagination.requestedPage}
						pageSize={bookingsPagination.requestedPageSize}
						totalPages={bookingsPagination.totalPages}
						totalCount={bookingsPagination.totalCount}
						onChangePage={onChangePage}
						onChangePageSize={onChangePageSize}
					/>
				</Card.Content>
				<Footer />
			</Card>
		</Content>
	</ContentWrapper>
);

BookingOverview.propTypes = {
	store: PropTypes.object.isRequired,
	eventId: PropTypes.number,
	isFetching: PropTypes.bool,
	isArtistTypesEnabled: PropTypes.bool,
	dateFormat: PropTypes.string,
	timeFormat: PropTypes.string,

	days: PropTypes.array,
	stages: PropTypes.array,
	artists: PropTypes.array,
	artistTypes: PropTypes.array,
	bookingTypes: PropTypes.array,
	bookingStatuses: PropTypes.array,

	onChangeValue: PropTypes.func,
};

export default BookingOverview;
