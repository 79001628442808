import Backbone from 'backbone';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';

export default class ServerSideFilter extends BaseView {

    optionTypes() {
        return {
            collection: OptionTypes.instanceOf(Backbone.Collection).isRequired,
            filterName: OptionTypes.string.isRequired,
            value: OptionTypes.string,
            placeholder: OptionTypes.string
        };
    }

    ui() {
        return {
            clearButton: '.clear-button',
            filterButton: '.filter-button',
            filterInput: '.filter-input'
        };
    }

    events() {
        return {
            'click .clear-button': 'clear',
            'click .filter-button': 'filter'
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.value = this.collection.getFilter(this.filterName);
        this.active = !! this.value;
        this.collection.setFilter(this.filterName, () => this.query());
    }

    clearFilterInput() {
        this.value = null;
        this.ui.filterInput.val(null);
        this.showClearButtonMaybe();
    }

    showClearButtonMaybe() {
        const query = this.query();
        if (query) this.ui.clearButton.show();
        else this.ui.clearButton.hide();
    }

    query() {
        const stringValue = this.ui.filterInput ? this.ui.filterInput.val() : '';
        const intValue = parseInt(stringValue, 10);
        this.value = isNaN(intValue) ? stringValue : intValue;
        return this.value;
    }

    filter(e) {
        if (e) e.preventDefault();
        this.active = true;
        this.collection.getFirstPage({ reset: true, fetch: true });
    }

    clear(e) {
        if (e) e.preventDefault();
        this.clearFilterInput();
        this.collection.getFirstPage({ reset: true, fetch: true }).then(() => {
            this.active = false;
        });
    }

}
