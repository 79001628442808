import React from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'
import Dragula from 'dragula'
import { connect } from 'react-redux'
import by from 'thenby'
import * as actions from 'app/react/state/actions/_index'
import * as stateHelpers from 'app/react/state/helpers'
import * as ArtistType from 'app/react/entities/events/artist_types/index'
import * as Setting from 'app/react/entities/events/production_website/settings/artists/index'
import * as Section from 'app/react/entities/events/production_website/settings/artists/sections/index'
import * as SectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/index'
import { getStaticFormFields } from '../../views/forms/staticFormFields'
import FormBuilderView, { AVAILABLE_FORM_FIELD_DRAGULA_ID } from './FormBuilderView'
import * as Routes from 'app/routes/event/production_website/artists/form/routes'

export const FORM_BUILDER_ID = 'formBuilder';
export const CONFIRM_MODAL_ID = 'formBuilderConfirmModal';

const isValidUuid = uuid =>
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);

class FormBuilderViewContainer extends React.Component {

    componentWillMount() {
        const { addFormField, setIsDragging } = this.props;

        this.dragula = Dragula([], {
            moves: (el) => !el.classList.contains('form-builder-list__drop-placeholder'),
            copy: (el, source) => source === document.getElementById(AVAILABLE_FORM_FIELD_DRAGULA_ID),
            accepts: (el, target) => target !== document.getElementById(AVAILABLE_FORM_FIELD_DRAGULA_ID),
            removeOnSpill: false
        });
        this.dragula.on('drag', () => {
            setIsDragging(true);
        });
        this.dragula.on('dragend', () => {
            setIsDragging(false);
        });
        this.dragula.on('drop', (el, targetContainer) => {
            const formFieldType = el.dataset.formFieldType;
            const formFieldId = el.dataset.formFieldId;
            const isNewFormField = typeof formFieldId === 'undefined';
            if (targetContainer) {
                const children = [...targetContainer.children];
                const targetNode = children.find(node => node.dataset.formFieldId === formFieldId);
                const targetNodeSequence = children.indexOf(targetNode);
                if (isNewFormField) {
                    targetContainer.removeChild(targetNode);
                }
                const newSequenceMapping = {};
                children.forEach((node, index) => {
                    const id = node.dataset.formFieldId;
                    if (id) newSequenceMapping[id] = index;
                });
                addFormField(isNewFormField, targetNodeSequence, formFieldType, newSequenceMapping);
            }
        });
    }

    componentWillUnmount() {
        this.dragula.destroy();
    }

    render() {
        return <FormBuilderView { ...this.props } dragula={this.dragula} />;
    }
}

FormBuilderViewContainer.defaultProps = {
    addFormField: () => {},
    setIsDragging: () => {}
};

FormBuilderViewContainer.propTypes = {
    addFormField: PropTypes.func,
    setIsDragging: PropTypes.func
};

const mapStateToProps = (state) => {
    const event = stateHelpers.getCurrentEvent(state);
    const section = Section.stateHelpers.getCurrent(state) || {};
    const formState = stateHelpers.getComponentState(state, FORM_BUILDER_ID);
    const confirmModalState = stateHelpers.getComponentState(state, CONFIRM_MODAL_ID);
    const formFields = formState.formFields || {};
    const sortedFormFields = Object.keys(formFields).map(id => formFields[id]).sort(by('sequence'));
    const selectedFormField = sortedFormFields.reduce((result, formField) => {
        if (result !== undefined) return result;
        if (formField.selected) return formField;
        return undefined;
    }, undefined);

    return {
        isFetching: (
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Setting.stateHelpers.isFetchingCurrent(state) ||
            Section.stateHelpers.isFetchingCurrent(state) ||
            SectionFormField.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        eventId: event.id,
        isArtistTypesEnabled: event.artistTypesEnabled,
        artistTypeId: ArtistType.stateHelpers.getCurrentId(state),
        sectionId: section.id,
        confirmModalState,
        staticFormFields: getStaticFormFields(section.passportsEnabled, section.passportsRequired),
        formFields: sortedFormFields,
        isDragging: formState.isDragging,
        isStaticFormFieldsShown: formState.isStaticFormFieldsShown,
        selectedFormField,
    };
};

const mapDispatchToProps = (dispatch) => {
    const confirmModalClosed = () => {
        dispatch(actions.closeConfirmModal(CONFIRM_MODAL_ID));
    };
    const confirmModalConfirmed = (action) => {
        dispatch(actions.closeConfirmModal(CONFIRM_MODAL_ID));
        dispatch(action);
    };
    const backToSection = (isAccreditationEnabled, eventId, artistTypeId, sectionId) => {
        dispatch(actions.navigate.to(Routes.formSection(isAccreditationEnabled, eventId, artistTypeId, sectionId)));
    };
    const selectFormField = (id, isSelected = false) => {
        dispatch(actions.setComponentStateValue(
            FORM_BUILDER_ID,
            'formFields',
            formFields => {
                const deselectedFormFields = {};
                Object.keys(formFields).forEach(formFieldId => {
                    deselectedFormFields[formFieldId] = {
                        ...formFields[formFieldId],
                        selected: false
                    };
                });
                return deselectedFormFields;
            }
        ));
        if (!isSelected) {
            dispatch(actions.setComponentStateValue(
                FORM_BUILDER_ID,
                'formFields',
                formFields => ({
                    ...formFields,
                    [id]: { ...formFields[id], selected: true }
                })
            ));
        }
    };
    const addFormField = (isNewFormField, sequence, type, sequenceMapping) => {
        if (Object.keys(sequenceMapping).length) {
            dispatch(actions.setComponentStateValue(
                FORM_BUILDER_ID,
                'formFields',
                formFields => {
                    const formFieldsWithUpdatedSequence = {};
                    Object.keys(formFields).forEach(id => {
                        formFieldsWithUpdatedSequence[id] = {
                            ...formFields[id],
                            sequence: sequenceMapping[id]
                        };
                    });
                    return formFieldsWithUpdatedSequence;
                }
            ));
        }
        if (isNewFormField) {
            const id = _.uniqueId();
            const label = type === 'date' ? 'New date field' : 'New text field';
            const placeholder = '';
            const selected = false;
            const required = false;
            dispatch(actions.setComponentStateValue(
                FORM_BUILDER_ID,
                'formFields',
                formFields => ({
                    ...formFields,
                    [id]: { id, type, sequence, required, label, placeholder, selected }
                })
            ));
            selectFormField(id);
        }
    };
    const removeFormField = (id, isSelected = false) => {
        if (isSelected) {
            dispatch(actions.deleteComponentStateValue(FORM_BUILDER_ID, 'selectedFormField'));
        }
        if (isValidUuid(id)) {
            const action = actions.deleteComponentStateValue(FORM_BUILDER_ID, `formFields.${id}`);
            dispatch(actions.openConfirmModal(CONFIRM_MODAL_ID, action));
        } else {
            dispatch(actions.deleteComponentStateValue(FORM_BUILDER_ID, `formFields.${id}`));
        }
    };
    const setIsDragging = (isDragging) => {
        dispatch(actions.setComponentStateValue(FORM_BUILDER_ID, 'isDragging', isDragging));
    };
    const toggleShowStaticFormFields = (isStaticFormFieldsShown) => {
        dispatch(actions.setComponentStateValue(FORM_BUILDER_ID, 'isStaticFormFieldsShown', !isStaticFormFieldsShown));
    };
    const changeFormFieldProps = (prop, value, selectedFormField) => {
        const { id } = selectedFormField;
        dispatch(actions.setComponentStateValue(FORM_BUILDER_ID, `selectedFormField.${prop}`, value));
        dispatch(actions.setComponentStateValue(FORM_BUILDER_ID, `formFields.${id}.${prop}`, value));
    };
    const saveForm = (formFields) => {
        const formFieldsToSave = formFields.map(({ id, type, sequence, required, label, placeholder }) => {
            if (isValidUuid(id)) return { id, type, sequence, required, label, placeholder };
            return { type, sequence, required, label, placeholder };
        });
        dispatch(SectionFormField.thunks.updateCurrent(formFieldsToSave));
    };
    return {
        toggleShowStaticFormFields,
        setIsDragging,
        addFormField,
        selectFormField,
        removeFormField,
        changeFormFieldProps,
        saveForm,
        backToSection,
        confirmModalClosed,
        confirmModalConfirmed,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    confirmModalConfirmed: () => dispatchProps.confirmModalConfirmed(stateProps.confirmModalState.action),
    toggleShowStaticFormFields: () => dispatchProps.toggleShowStaticFormFields(
        stateProps.isStaticFormFieldsShown
    ),
    changeFormFieldProps: (prop, value) => dispatchProps.changeFormFieldProps(
        prop, value, stateProps.selectedFormField
    ),
    removeFormField: (formFieldId) => dispatchProps.removeFormField(
        formFieldId, stateProps.selectedFormField
    ),
    saveForm: () => dispatchProps.saveForm(
        stateProps.formFields
    ),
    backToSection: () => dispatchProps.backToSection(
        stateProps.isAccreditationEnabled,
        stateProps.eventId,
        stateProps.artistTypeId,
        stateProps.sectionId,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(FormBuilderViewContainer);
