import React from 'react';
import { Field } from 'formik';
import Checkbox from '../../../../checkbox/Checkbox';

const DisableSection = ({ sectionId, values }) => {
	return (
		<div className='artist-dialog__disable-section'>
			<Field
				as={Checkbox}
				name={`${sectionId}.enabled`}
				id={`${sectionId}.enabled`}
				label='Enable section'
			/>
			<Field
				as={Checkbox}
				name={`${sectionId}.open`}
				id={`${sectionId}.open`}
				disabled={!values.enabled}
				label='Enable section for artists'
			/>
		</div>
	);
};

DisableSection.propTypes = {};

export default DisableSection;
