import NavigationCollapsable from '../NavigationCollapsable.view';

export default class TitleBar extends NavigationCollapsable {

    ui() {
        return {
            backButton: '#back-button',
            title: '.header--titlebar-title',
            backTitle: '.header--titlebar-back-title'
        };
    }

    events() {
        return {
            'click': 'onClick',
            'mouseenter backButton': 'onMouseEnterBackButton',
            'mouseleave backButton': 'onMouseLeaveBackButton'
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    onClick() {
        this.trigger('toggleAccounts');
    }

    onMouseEnterBackButton() {
        this.ui.backTitle.removeClass('hidden');
        this.ui.title.addClass('hidden');
    }

    onMouseLeaveBackButton() {
        this.ui.backTitle.addClass('hidden');
        this.ui.title.removeClass('hidden');
    }

}
