import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ItemGroupRowContent from './ItemGroupRowContent';

class ArtistsItemGroupRow extends React.Component {
    constructor(props) {
        super(props)

        this.renderSubGroupRow = this.renderSubGroupRow.bind(this)
    }

    renderSubGroupRow(subGroups, level = 0) {
        level++;
        const {
            onAdd,
            onUpdate,
            onDelete,
            currency
        } = this.props;

        return subGroups.map(subGroup => {
            let renderChildrenSubGroup;

            if (subGroup.subGroups.length > 0) {
                renderChildrenSubGroup = this.renderSubGroupRow(subGroup.subGroups, level)
            }

            return (
                <Fragment key={subGroup.id}>
                    <tr>
                        <td colSpan={2} style={{padding: `0 0 0 ${level * 30}px`}}>
                            <table className="overflow no-margin actions-right table-with-border-bottom">
                                <tbody>
                                    <ItemGroupRowContent
                                        group={subGroup}
                                        onAdd={onAdd}
                                        onUpdate={onUpdate}
                                        onDelete={onDelete}
                                        currency={currency}
                                    />
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    {renderChildrenSubGroup}
                </Fragment>
            )
        })
    }

    render() {
        const {
            group,
            onAdd,
            onUpdate,
            onDelete,
            currency
        } = this.props;
        const hasSubGroups = group.subGroups.length > 0

        return (
            <tbody>
                <ItemGroupRowContent
                    group={group}
                    onAdd={onAdd}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    currency={currency}
                />
                {hasSubGroups &&
                    this.renderSubGroupRow(group.subGroups)
                }
            </tbody>
        )
    }
}

ArtistsItemGroupRow.propTypes = {
    group: PropTypes.object,
    onAdd: PropTypes.func,
};

export default ArtistsItemGroupRow;
