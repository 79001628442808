import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {InputGroup} from 'ui-kit';
import {DynamicForm, FileUpload} from 'app/react/components/_index';
import TicketTypeSelectInputGroup from "../../components/selects/TicketTypeSelectInputGroup";
import {ATTENDEE_TYPES} from 'app/react/entities/events/ticket_types/constants';

const FIRST_NAME_LABEL = 'First name';
const LAST_NAME_LABEL = 'Last name';
const ROLE_LABEL = 'Role';
const EMAIL_LABEL = 'Email';
const TEL_LABEL = 'Telephone';

export const PASSPORT_LABEL = 'Passport';
export const PASSPORT_FILE_TYPE = 'passport';
export const PASSPORT_ALLOWED_EXTENSIONS = ['pdf', 'jpg', 'jpeg'];

const { FileInfo } = FileUpload;

const CrewFields = ({
    values = {},
    errors = {},
    arePassportsEnabled,
    dynamicFields = [],
    requiredFieldNames = [],

    dateFormat,
    timeFormat,

    selectedPassport,
    ticketTypeOptions,

    onChangeValue = () => {},
    onChangePassport = () => {},
}) => (
    <div>
        <InputGroup messages={errors.firstname}>
            <label className={classNames({ required: requiredFieldNames.includes('firstname') })}>
                {FIRST_NAME_LABEL}
            </label>
            <input
                type="text"
                value={values.firstname || ''}
                onChange={e => onChangeValue('firstname', e.target.value)}
            />
        </InputGroup>
        <InputGroup messages={errors.lastname}>
            <label className={classNames({ required: requiredFieldNames.includes('lastname') })}>
                {LAST_NAME_LABEL}
            </label>
            <input
                type="text"
                value={values.lastname || ''}
                onChange={e => onChangeValue('lastname', e.target.value)}
            />
        </InputGroup>
        <InputGroup messages={errors.role}>
            <label className={classNames({required: requiredFieldNames.includes('role')})}>
                {ROLE_LABEL}
            </label>
            <input
                type="text"
                value={values.role || ''}
                onChange={e => onChangeValue('role', e.target.value)}
            />
        </InputGroup>
        <TicketTypeSelectInputGroup attendeeType={ATTENDEE_TYPES.CREW} options={ticketTypeOptions}
                                    onChangeValue={onChangeValue} errors={errors} selectedValue={values.ticketType}/>
        <InputGroup messages={errors.email}>
            <label className={classNames({required: requiredFieldNames.includes('email')})}>
                {EMAIL_LABEL}
            </label>
            <input
                type="text"
                value={values.email || ''}
                onChange={e => onChangeValue('email', e.target.value)}
            />
        </InputGroup>
        <InputGroup messages={errors.telephone}>
            <label className={classNames({ required: requiredFieldNames.includes('telephone') })}>
                {TEL_LABEL}
            </label>
            <input
                type="text"
                value={values.telephone || ''}
                onChange={e => onChangeValue('telephone', e.target.value)}
            />
        </InputGroup>
        {arePassportsEnabled
            ? <InputGroup>
                <label>{PASSPORT_LABEL}</label>
                <FileUpload
                    value={selectedPassport}
                    fileType={PASSPORT_FILE_TYPE}
                    onChange={onChangePassport}
                >
                    <FileInfo
                        maxSize={5}
                        allowedFileExtensions={PASSPORT_ALLOWED_EXTENSIONS}
                    />
                </FileUpload>
            </InputGroup>
            : null
        }
        <DynamicForm
            fields={dynamicFields}
            values={values}
            errors={errors}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            onChange={onChangeValue}
        />
    </div>
);

CrewFields.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,

    selectedPassport: PropTypes.object,
    ticketTypeOptions: PropTypes.array,

    onChangeValue: PropTypes.func,
    onChangePassport: PropTypes.func,
    onUploadPassport: PropTypes.func,
};

export default CrewFields;
