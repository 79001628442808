import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';

const { Tabs, TitleMenu } = Header;
const { Title, Actions } = TitleMenu;
const { Tab } = Tabs;

const BASE_PATH = (eventId) => `/events/${eventId}/production-website/artists`;
export const TABS = {
    PAGES: {
        ID: 'pages',
        PATH: 'pages',
        TITLE: 'Pages'
    },
    ARTIST_TYPES: {
        ID: 'artistTypes',
        PATH: 'artist-types',
        TITLE: 'Artist types'
    },
    FORM: {
        ID: 'artistTypes',
        PATH: 'form',
        TITLE: 'Form'
    },
    REQUIRED_FILES: {
        ID: 'requiredFiles',
        PATH: 'required-files',
        TITLE: 'Required files'
    }
};

export const ArtistsSettingsContentHeader = ({
    children,
    activeTab,
    eventId,
    artistTypesEnabled
}) => {
    const basePath = BASE_PATH(eventId);
    return (
        <Header>
            <TitleMenu>
                <Title>Artists</Title>
                <Actions>{children}</Actions>
            </TitleMenu>
            <Tabs>
                <Tab
                    tabId={TABS.PAGES.ID}
                    activeTab={activeTab}
                    url={`${basePath}/${TABS.PAGES.PATH}`}
                >
                    {TABS.PAGES.TITLE}
                </Tab>
                {artistTypesEnabled
                    ? <Tab
                        tabId={TABS.ARTIST_TYPES.ID}
                        activeTab={activeTab}
                        url={`${basePath}/${TABS.ARTIST_TYPES.PATH}`}
                    >
                        {TABS.ARTIST_TYPES.TITLE}
                    </Tab>
                    : <Tab
                        tabId={TABS.FORM.ID}
                        activeTab={activeTab}
                        url={`${basePath}/${TABS.FORM.PATH}`}
                    >
                        {TABS.FORM.TITLE}
                    </Tab>
                }
                <Tab
                    tabId={TABS.REQUIRED_FILES.ID}
                    activeTab={activeTab}
                    url={`${basePath}/${TABS.REQUIRED_FILES.PATH}`}
                    pageReload
                >
                    {TABS.REQUIRED_FILES.TITLE}
                </Tab>
            </Tabs>
        </Header>
    );
};

ArtistsSettingsContentHeader.propTypes = {
    children: PropTypes.node,
    activeTab: PropTypes.string,
    eventId: PropTypes.number,
    artistTypesEnabled: PropTypes.bool
};

export default ArtistsSettingsContentHeader;
