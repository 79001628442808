import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NoRecordsRow } from 'app/react/components/_index';

const DaysColumnsItemsTable = ({
    dateFormatShort,
    items = [],
    days = [],
    sortKey,
    sortOrder,
    onSort = () => {},
}) => (
    <table className="overflow">
        <thead>
        <tr>
            { days.map((day) => (
                <th
                    key={day.id}
                    className="center"
                    style={{ width: '100px' }}
                >
                    { moment.utc(day.start, moment.ISO_8601).format(`ddd ${dateFormatShort}`) }
                </th>
            ))}
        </tr>
        </thead>
        <tbody>
        {items.length === 0 
            ? <NoRecordsRow colSpan={1} /> 
            : items.map(item => (
                <tr key={item.id}>
                    { days.map((day) => {
                        const { dayTotals = [] } = item;
                        const dayTotal = dayTotals.find((total) => total.day === day.id);
                        const dayAmount = typeof dayTotal !== 'undefined' ? dayTotal.amount : 0;
                        return (
                            <td key={day.id} className="center">
                                {dayAmount}
                            </td>
                        )
                    })}
                </tr>
            ))
        }
        </tbody>
    </table>
);

DaysColumnsItemsTable.propTypes = {
    dateFormatShort: PropTypes.string,
    items: PropTypes.array,
    days: PropTypes.array,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func
};

export default DaysColumnsItemsTable;
