import {
    initialState,
    collectionRequestReducer,
    collectionSuccessReducer,
    collectionFailureReducer,
    collectionRequestReducerWithKey,
    collectionSuccessReducerWithKey,
    collectionFailureReducerWithKey,
} from './collection/collection';
import {
    entitySuccessReducer,
    entitySuccessReducerWithKey,
} from './collection/entity';
import {
    broadcastReceivedReducer,
    broadcastReceivedReducerWithKey,
} from './collection/broadcast';

export const createCollectionReducer = ({ types, oneTypes= [], broadcastType }) => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.');
    }
    if (!types.every(t => typeof t === 'string')) {
        throw new Error('Expected types to be strings.');
    }

    const [collectionRequestType, collectionSuccessType, collectionFailureType] = types;
    const [entityRequestType, entitySuccessType, entityFailureType] = oneTypes;

    return (state = initialState, action) => {
        switch (action.type) {
            case collectionRequestType:
                return collectionRequestReducer(state, action);
            case collectionSuccessType:
                return collectionSuccessReducer(state, action);
            case collectionFailureType:
                return collectionFailureReducer(state, action);
            case entitySuccessType:
                return entitySuccessReducer(state, action);
            case broadcastType:
                return broadcastReceivedReducer(state, action);
            default:
                return state;
        }
    };
};

export const createCollectionReducerWithKey = ({ types, oneTypes = [], broadcastType, mapActionToKey }) => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.');
    }
    if (!types.every(t => typeof t === 'string')) {
        throw new Error('Expected types to be strings.');
    }
    if (typeof mapActionToKey !== 'function') {
        throw new Error('Expected mapActionToKey to be a function.');
    }

    const [collectionRequestType, collectionSuccessType, collectionFailureType] = types;
    const [entityRequestType, entitySuccessType, entityFailureType] = oneTypes;

    return (state = {}, action) => {

        const typesForThisReducer = [...types, ...oneTypes, broadcastType];
        const actionIsForThisReducer = typesForThisReducer.includes(action.type);

        if (actionIsForThisReducer) {

            const key = mapActionToKey(action);
            if (typeof key !== 'string' && typeof key !== 'number') {
                throw new Error('Expected key to be a string.');
            }

            switch (action.type) {
                case collectionRequestType:
                    return collectionRequestReducerWithKey(state, action, key);
                case collectionSuccessType:
                    return collectionSuccessReducerWithKey(state, action, key);
                case collectionFailureType:
                    return collectionFailureReducerWithKey(state, action, key);
                case entitySuccessType:
                    return entitySuccessReducerWithKey(state, action, key);
                case broadcastType:
                    return broadcastReceivedReducerWithKey(state, action, key);
                default:
                    return state;
            }
        }

        return state;
    };
};
