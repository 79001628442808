import React from 'react';
import { find } from 'lodash';
import { Button as UIKitButton } from 'ui-kit';

const buttonTypes = [
    UIKitButton.PRIMARY,
    UIKitButton.SECONDARY,
    UIKitButton.SUCCESS,
    UIKitButton.WARNING,
    UIKitButton.DANGER,
];

const resolveButtonClasses = (className) => {
    if (!className) {
        return {};
    }

    // Note that we reverse the classes because we want the find the last class that
    // matches a type, as that would be the one that's currently used by CSS.
    return className.split(',')
        .reverse()
        .map((type) => {
            if (type === 'alert') {
                return UIKitButton.DANGER;
            }

            if (['success', 'create', 'update'].includes(type)) {
                return UIKitButton.PRIMARY;
            }

            return type;
        });
}

const Button = ({
    children,
    className,
    onClick,
}) => {
    const classes = resolveButtonClasses(className);
    const type = find(classes, (type) => buttonTypes.includes(type));

    return (
        <UIKitButton
            type={type || UIKitButton.SECONDARY}
            onClick={onClick}
        >
            {children}
        </UIKitButton>
    );
};

Button.defaulProps = {
    className: '',
    onClick: () => {},
};

const Error = ({ children }) => (
    <div className="modal--footer_content error">
        <i className="bs-icon-triangle-exclamation-mark" /> {children}
    </div>
);

const ModalFooter = ({ children }) => (
    <div className="modalButtonsContainer">
        {children}
    </div>
);

ModalFooter.Button = Button;
ModalFooter.Error = Error;

export default ModalFooter;
