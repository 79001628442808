import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as actions from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Settings from 'app/react/entities/events/production_website/settings/artists/index';
import * as Section from 'app/react/entities/events/production_website/settings/artists/sections/index';
import * as SectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/index';
import FormBuilderView, { FORM_BUILDER_ID } from 'app/react/views/event/production_website/artists/artist_types/booking_form/sections/form_builder/views/FormBuilderViewContainer';
import * as Routes from './routes'

export const sectionHasFormBuilder = sectionId => sectionId === 'performers' || sectionId === 'crew';

export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.formSectionFormBuilder(), null, this.artistTypesEnabledFormBuilder.bind(this));
        this.router.route(Routes.formSectionFormBuilder(false), null, this.artistTypesDisabledFormBuilder.bind(this));
    }

    artistTypesEnabledFormBuilder(eventId, artistTypeId, sectionId) {
        if (sectionHasFormBuilder(sectionId)) {
            const { app, store, dispatch } = this;
            app.renderContentComponent(React.createElement(FormBuilderView, { store }));
            // Account ID is set from DOM in the initial state
            dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
            dispatch(ArtistType.actions.setCurrentId(parseInt(artistTypeId, 10)));
            dispatch(Section.actions.setCurrentId(sectionId));

            return Promise.all([
                dispatch(ArtistType.thunks.fetchAllForCurrentContext()),
                dispatch(Settings.thunks.fetchCurrent())
            ]).then(() => {
                const state = store.getState();
                const section = Section.stateHelpers.getCurrent(state) || {};
                const formFields = SectionFormField.stateHelpers.getSome(state, section.formFields);
                dispatch(actions.registerComponent(FORM_BUILDER_ID, {
                    formFields: formFields.reduce((result, item) => {
                        result[item.id] = item;
                        if (item.sequence === null) result[item.id].sequence = 0;
                        return result;
                    }, {}),
                    isDragging: false,
                    isStaticFormFieldsShown: true
                }));
            });
        }
    }

    artistTypesDisabledFormBuilder(eventId, sectionId) {
        if (sectionHasFormBuilder(sectionId)) {
            const { app, store, dispatch } = this;
            app.renderContentComponent(React.createElement(FormBuilderView, { store }));
            // Account ID is set from DOM in the initial state
            dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
            dispatch(Section.actions.setCurrentId(sectionId));

            return dispatch(ArtistType.thunks.fetchAllForCurrentContext()).then(() => {
                dispatch(ArtistType.thunks.setFirstAsCurrentId());

                return dispatch(Settings.thunks.fetchCurrent()).then(() => {
                    const state = store.getState();
                    const section = Section.stateHelpers.getCurrent(state) || {};
                    const formFields = SectionFormField.stateHelpers.getSome(state, section.formFields);
                    dispatch(actions.registerComponent(FORM_BUILDER_ID, {
                        formFields: formFields.reduce((result, item) => {
                            result[item.id] = item;
                            if (item.sequence === null) result[item.id].sequence = 0;
                            return result;
                        }, {}),
                        isDragging: false,
                        isStaticFormFieldsShown: true
                    }));
                });
            });
        }
    }
}
