export const getStaticFormFields = (passportsEnabled, passportsRequired) => {
    const defaultFields = [{
        id: 'firstname',
        type: 'text',
        sequence: 1,
        required: true,
        label: 'First name',
        placeholder: '',
        description: ''
    }, {
        id: 'lastname',
        type: 'text',
        sequence: 2,
        required: true,
        label: 'Last name',
        placeholder: '',
        description: ''
    }, {
        id: 'role',
        type: 'text',
        sequence: 3,
        required: true,
        label: 'Role',
        placeholder: '',
        description: ''
    }, {
        id: 'email',
        type: 'text',
        sequence: 4,
        label: 'Email',
        placeholder: '',
        description: ''
    }, {
        id: 'telephone',
        type: 'text',
        sequence: 5,
        label: 'Telephone',
        placeholder: '',
        description: ''
    }];

    if (passportsEnabled) {
        defaultFields.push({
            id: 'passport',
            type: 'file',
            sequence: 6,
            required: passportsRequired,
            label: 'Passport',
            placeholder: '',
            description: ''
        });
    }

    return defaultFields;
};
