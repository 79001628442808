import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'ui-kit';
import { Modal } from 'app/react/components/_index';

const { Header, Content, Footer } = Modal;

const USERNAME_LABEL = 'Username ';
const PASSWORD_LABEL = 'Password';

const TITLE = name => `Authenticate ${name}`;
const AUTHENTICATE_BUTTON_TEXT = 'Authenticate';

export const AUTHENTICATE_MODAL_ID = 'authenticateIntegrationModal';

const AuthenticateModal = ({
    isOpened = false,
    isSyncing = false,
    selectedIntegration = {},

    values = {},
    errors = {},

    onChangeValue = () => {},
    onClosed = () => {},
    onConfirm = () => {}
}) => (
    <Modal
        width={350}
        closeOnBackgroundClick={true}
        opened={isOpened}
        syncing={isSyncing}
        onClosed={onClosed}
    >
        <Header onClosed={onClosed}>
            <Modal.Header.Title>{TITLE(selectedIntegration.name)}</Modal.Header.Title>
        </Header>
        <Content>
            <InputGroup messages={errors.username}>
                <label>{USERNAME_LABEL}</label>
                <input
                    type="text"
                    value={values.username || ''}
                    onChange={e => onChangeValue('username', e.target.value)}
                />
            </InputGroup>
            <InputGroup messages={errors.password}>
                <label>{PASSWORD_LABEL}</label>
                <input
                    type="password"
                    value={values.password || ''}
                    onChange={e => onChangeValue('password', e.target.value)}
                />
            </InputGroup>
        </Content>
        <Footer>
            <Footer.Button
                className="success"
                onClick={onConfirm}
            >
                {AUTHENTICATE_BUTTON_TEXT}
            </Footer.Button>
        </Footer>
    </Modal>
);

AuthenticateModal.propTypes = {
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    selectedIntegration: PropTypes.object,

    values: PropTypes.object,
    errors: PropTypes.object,

    onChangeValue: PropTypes.func,
    onClosed: PropTypes.func,
    onConfirm: PropTypes.func
};

export default AuthenticateModal;
