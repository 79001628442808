import BaseView from 'app/backbone/components/Base.view';
import PermissionsForm from './forms/PermissionsForm.view';
import DeleteEventRoleModal from './modals/DeleteEventRoleModal.view';
import DeleteEventRoleButton from './modals/DeleteEventRoleButton.view';

export default class RoleOverviewView extends BaseView {

    ui() {
        return {
            permissionForm: '#permissions-form',
            deleteEventRoleButton: '.delete-event-role'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.deleteEventRoleButton = this.initDeleteEventRoleButton();
        this.permissionForm = this.initPermissionForm();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderPermissionForm();
        this.renderDeleteEventRoleButton();
        return this.delegateEvents();
    }

    initDeleteEventRoleButton() {
        return new DeleteEventRoleButton({ confirmModal: new DeleteEventRoleModal() });
    }

    renderDeleteEventRoleButton() {
        if (this.deleteEventRoleButton) this.deleteEventRoleButton.setElement(this.ui.deleteEventRoleButton).render();
    }

    initPermissionForm() {
        return new PermissionsForm();
    }

    renderPermissionForm() {
        if (this.permissionForm) this.permissionForm.setElement(this.ui.permissionForm).render();
    }

}

