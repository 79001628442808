import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'
import {
    setCurrentContextId,
    unsetCurrentContextId,
} from 'app/react/state/actions/_index'

export const ONE_REQUEST = 'ONE_BOOKING_REQUEST';
export const ONE_SUCCESS = 'ONE_BOOKING_SUCCESS';
export const ONE_FAILURE = 'ONE_BOOKING_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_PAGINATED_REQUEST = 'ALL_BOOKINGS_PAGINATED_REQUEST';
export const ALL_PAGINATED_SUCCESS = 'ALL_BOOKINGS_PAGINATED_SUCCESS';
export const ALL_PAGINATED_FAILURE = 'ALL_BOOKINGS_PAGINATED_FAILURE';
export const ALL_PAGINATED_TYPES = [
    ALL_PAGINATED_REQUEST,
    ALL_PAGINATED_SUCCESS,
    ALL_PAGINATED_FAILURE
];

export const ALL_SEARCH_REQUEST = 'ALL_SEARCH_BOOKINGS_REQUEST';
export const ALL_SEARCH_SUCCESS = 'ALL_SEARCH_BOOKINGS_SUCCESS';
export const ALL_SEARCH_FAILURE = 'ALL_SEARCH_BOOKINGS_FAILURE';
export const ALL_SEARCH_TYPES = [
    ALL_SEARCH_REQUEST,
    ALL_SEARCH_SUCCESS,
    ALL_SEARCH_FAILURE
];

export const setCurrentId = (id) =>
    setCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY, id);

export const unsetCurrentId = (id) =>
    unsetCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY);

export const fetchOne = (eventId, bookingId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        id: bookingId,
        endpoint: Endpoints.fetchOne(eventId, bookingId, ['artist', 'performance']),
        schema: Schemas.entity
    }
});

export const createOne = (eventId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId, ['artist']),
        schema: Schemas.entity
    }
});

export const createOneForPerformance = (eventId, performanceId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOneForPerformance(eventId, performanceId, ['artist']),
        schema: Schemas.entity
    },
    performanceId, // We pass this to add the booking to the performance
});

export const updateOne = (eventId, bookingId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        id: bookingId,
        body: values,
        endpoint: Endpoints.updateOne(eventId, bookingId),
        schema: Schemas.entity
    }
});

export const updateOneForPerformance = (eventId, performanceId, bookingId, values) => ({
    ...updateOne(eventId, bookingId, values),
    performanceId // We pass this to add the booking to the performance
});

export const updateEntranceOne = (
    accountId,
    eventId,
    bookingId,
    values,
    updateType
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateEntranceOne(eventId, bookingId),
        schema: Schemas.entity,
        id: bookingId,
    },
    accountId,
    eventId,
    updateType
});

export const deleteOne = (eventId, bookingId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        id: bookingId,
        endpoint: Endpoints.deleteOne(eventId, bookingId),
        schema: Schemas.entity
    }
});

export const deleteOneForPerformance = (eventId, performanceId, bookingId) => ({
    ...deleteOne(eventId, bookingId),
    performanceId, // We pass this to remove the booking to the performance
});

export const generatePaginationKeyForEventForUnassigned = (accountId, eventId) =>
    `${accountId}/${eventId}/unassigned`;

export const fetchAllUnassigned = (accountId, eventId) => ({
    [CALL_API]: {
        types: ALL_PAGINATED_TYPES,
        endpoint: Endpoints.fetchAll(eventId, { unassigned: true }, ['artist']),
        schema: Schemas.entities
    },
    key: generatePaginationKeyForEventForUnassigned(accountId, eventId)
});

export const generatePaginationKeyForEventForArtistForUnassigned = (accountId, eventId, artistId) =>
    `${accountId}/${eventId}/${artistId}/unassigned`;

export const fetchAllUnassignedByArtistId = (accountId, eventId, artistId) => ({
    [CALL_API]: {
        types: ALL_PAGINATED_TYPES,
        endpoint: Endpoints.fetchAllByArtistId(eventId, artistId, { unassigned: true }, ['artist']),
        schema: Schemas.entities
    },
    key: generatePaginationKeyForEventForArtistForUnassigned(accountId, eventId, artistId),
});

export const generatePaginationKeyForEvent = (accountId, eventId) =>
    `${accountId}/${eventId}`;

export const fetchPaginatedForEvent = (
    accountId,
    eventId,
    page = 1,
    pageSize = 100,
    filters = {},
    search = '',
    sortKey = '',
    sortOrder = 1
) => ({
    [CALL_API]: {
        types: ALL_PAGINATED_TYPES,
        endpoint: Endpoints.fetchPaginated(
            eventId,
            page,
            pageSize,
            filters,
            search,
            sortKey,
            sortOrder,
            [
                'artist',
                'performance',
                'transports',
                'pickups',
                'parking_passes',
                'production_website_progress',
                'reservations',
                'tasks',
                'advancing_user',
                'main_advancing_contacts.contact'
            ]
        ),
        requestedPage: page,
        requestedPageSize: pageSize,
        requestedFilters: filters,
        requestedSearch: search,
        requestedSortKey: sortKey,
        requestedSortOrder: sortOrder,
        schema: Schemas.entities
    },
    key: generatePaginationKeyForEvent(accountId, eventId),
});

export const searchAll = (accountId, eventId, includes, filters, searchTerm) => ({
    [CALL_API]: {
        types: ALL_SEARCH_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId, filters, includes, searchTerm),
        schema: Schemas.entities,
    },
    eventId,
    accountId,
    searchTerm,
});






