import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import AvailableCompanyItemTable from '../tables/AvailableCompanyItemTable.view';
import CompanyItemModalAdd from '../modals/CompanyItemModalAdd.view';

export default class CompanyItemsSettingsCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            items: OptionTypes.instanceOf(EventItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            editing: OptionTypes.bool
        };
    }

    optionDefaults() {
        return {
            editing: false
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.companyItems = this.company.getRelation('items');
        // Subviews
        this.addModal = this.initAddModel();
        // Collection & Model Listeners
        this.listenTo(this.company, 'validated', this.onValidated);
        this.listenTo(this.companyItems, 'update reset', this.onUpdateOrReset);
        super.initialize(options);
    }

    initContent() {
        const { companyItems, items, periods, editing } = this;
        const placeholderHeight = 100;
        return new AvailableCompanyItemTable({ collection: companyItems, items, periods, editing, placeholderHeight });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add Item',
            icon: 'bs-icon-plus',
            action: this.addItem.bind(this),
            isHidden: () => this.addModal.items.isEmpty()
        }];
    }

    initAddModel() {
        const items = this.getModalItems();
        const addModal = new CompanyItemModalAdd({ items });
        this.listenTo(addModal, 'add', this.onAdded);
        return addModal;
    }

    onAdded(item) {
        this.companyItems.add(item);
    }

    addItem() {
        if (!this.addModal.items.isEmpty()) this.addModal.open();
    }

    onUpdateOrReset() {
        this.addModal.items = this.getModalItems();
        this.header.toggleHideAction('add', this.addModal.items.isEmpty());
        if (this.hasError()) this.company.validate();
    }

    onValidated(isModelValid, model, errors) {
        if (!isModelValid && errors.items) this.addError(errors.items);
        else this.removeError();
    }

    getModalItems() {
        const { items, companyItems } = this;
        return items.rejectIds(companyItems.pluck('id'));
    }

}
