import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import { fetchAll, createOne, updateOne, deleteOne } from './actions';

export const fetchAllForCurrentContext = () => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(fetchAll(accountId, eventId));
};

export const createOneForCurrentContext = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(createOne(accountId, eventId, values));
};

export const updateOneForCurrentContext = (groupId, values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, groupId, values));
};

export const deleteOneForCurrentContext = (groupId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(deleteOne(accountId, eventId, groupId));
};


