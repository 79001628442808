import React from 'react'
import {
    Card,
    Grid,
    Button,
} from 'ui-kit'
import RemoveUploadedPassportsModal from 'app/react/views/account/settings/account/privacy/components/RemoveUploadedPassportsModalWithMutation'

const { Header, Content } = Card;
const { Title } = Header;

const { Cell } = Grid;

const DangerZoneCard = ({ accountId }) => (
    <Card>
        <Header>
            <Title>Danger zone</Title>
        </Header>
        <Content>
            <Grid>
                <Cell>
                    <label>Remove all uploaded passports</label>
                    <p>You can choose to remove all uploaded passports from your account (whether they were uploaded by your production team or through the Production website).</p>
                </Cell>
                <Cell isAutoSized>
                    <RemoveUploadedPassportsModal accountId={accountId} />
                </Cell>
            </Grid>
        </Content>
    </Card>
);

export default DangerZoneCard;
