import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as ArtistType from 'app/react/entities/events/artist_types/helpers';
import { fetchOne, updateOne } from './actions';
import { getCurrent, getCurrentId } from './helpers';

export const fetchCurrent = (includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const artistTypeId = ArtistType.getCurrentId(state);
    const sectionId = getCurrentId(state);
    return dispatch(fetchOne(accountId, eventId, artistTypeId, sectionId, includes));
};

export const updateCurrent = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const artistTypeId = ArtistType.getCurrentId(state);
    const { id, uuid } = getCurrent(state);
    return dispatch(updateOne(accountId, eventId, artistTypeId, id, uuid, values));
};
