import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, PostfixedButtonInput } from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';
import SearchSelectArtist from '../../../../../../views/SearchSelectArtistContainer';
import SelectArtistType from '../../../../selects/SelectArtistType';
import SelectBookingType from '../../../../selects/SelectBookingType';
import SelectBookingStatus from '../../../../selects/SelectBookingStatus';

export const SAVE_UPDATE_TOOLTIP = 'Save changes';
export const CANCEL_UPDATE_TOOLTIP = 'Cancel changes';
export const SAVE_NEW_TOOLTIP = 'Save booking';
export const CANCEL_NEW_TOOLTIP = 'Cancel create booking';
export const NEW_ARTIST_PLACEHOLDER = 'New artist name';
const inputGroupStyles = {
    margin: "var(--spacingSmaller) 0",
};

const EditBookingRow = ({
    isArtistTypesEnabled = true,
    isNew = false,
    isCreatingNewArtist = false,
    id,
    artistId,
    artistName,
    artistTypeId,
    bookingTypeId,
    bookingStatusId,
    errors = {},
    artistTypes = [],
    bookingTypes = [],
    bookingStatuses = [],
    onChangeValue = () => {},
    onToggleCreateNewArtist = () => {},
    onSave = () => {},
    onCancel = () => {}
}) => {
    const artistError = errors.artist && errors.artist[0].text;
    const artistNameError = errors['artist.name'] && errors['artist.name'][0].text;
    const artistTypeError = errors.artistType && errors.artistType[0].text;
    const typeError = errors.type && errors.type[0].text;
    const statusError = errors.status && errors.status[0].text;
    const artistHasError = typeof artistError !== 'undefined';
    const artistNameHasError = typeof artistNameError !== 'undefined';
    const artistTypeHasError = typeof artistTypeError !== 'undefined';
    const typeHasError = typeof typeError !== 'undefined';
    const statusHasError = typeof statusError !== 'undefined';
    return (
        <tr>
            {isNew 
                ? <td className="input">
                    <InputGroup
                        hasError={artistHasError || artistNameHasError}
                        style={inputGroupStyles}
                    >
                        <Tooltip
                            isAlert
                            isOpened={artistHasError || artistNameHasError}
                            content={artistError || artistNameError}
                        >
                            <PostfixedButtonInput
                                buttonIcon={!isCreatingNewArtist 
                                    ? <i className="bs-icon-plus no-margin" />
                                    : <i className="bs-icon-cross no-margin" />
                                }
                                buttonOnClick={() => onToggleCreateNewArtist(!isCreatingNewArtist)}
                            >
                                {!isCreatingNewArtist 
                                    ? <SearchSelectArtist
                                        placeholder="Type to search.."
                                        value={artistId}
                                        onChange={value => onChangeValue('artist', value, id)}
                                    /> 
                                    : <input
                                        type="text"
                                        placeholder={NEW_ARTIST_PLACEHOLDER}
                                        value={artistName || ''}
                                        onChange={e => onChangeValue('artistName', e.target.value, id)}
                                    />
                                }
                            </PostfixedButtonInput>
                        </Tooltip>
                    </InputGroup>
                </td> 
                : <td>
                    {artistName}
                </td>
            }
            {isArtistTypesEnabled &&
                <td className="input">
                    <InputGroup
                        hasError={artistTypeHasError}
                        style={inputGroupStyles}
                    >
                        <Tooltip
                            isAlert
                            isOpened={artistTypeHasError}
                            content={artistTypeError}
                        >
                            <SelectArtistType
                                value={artistTypeId}
                                artistTypes={artistTypes}
                                onChange={value => onChangeValue('artistType', value, id)}
                            />
                        </Tooltip>
                    </InputGroup>
                </td>
            }
            <td className="input">
                <InputGroup
                    hasError={typeHasError}
                    style={inputGroupStyles}
                >
                    <Tooltip
                        isAlert
                        isOpened={typeHasError}
                        content={typeError}
                    >
                        <SelectBookingType
                            value={bookingTypeId}
                            bookingTypes={bookingTypes}
                            onChange={value => onChangeValue('type', value, id)}
                        />
                    </Tooltip>
                </InputGroup>
            </td>
            <td className="input">
                <InputGroup
                    hasError={statusHasError}
                    style={inputGroupStyles}
                >
                    <Tooltip
                        isAlert
                        isOpened={statusHasError}
                        content={statusError}
                    >
                        <SelectBookingStatus
                            value={bookingStatusId}
                            bookingStatuses={bookingStatuses}
                            onChange={value => onChangeValue('status', value, id)}
                        />
                    </Tooltip>
                </InputGroup>
            </td>
            <td className="actions">
                <Tooltip content={isNew ? SAVE_NEW_TOOLTIP : SAVE_UPDATE_TOOLTIP}>
                    <a className="action" onClick={() => onSave(id)}>
                        <i className="bs-icon-check" />
                    </a>
                </Tooltip>
                <Tooltip content={isNew ? CANCEL_NEW_TOOLTIP : CANCEL_UPDATE_TOOLTIP}>
                    <a className="action" onClick={() => onCancel(id)}>
                        <i className="bs-icon-cross" />
                    </a>
                </Tooltip>
            </td>
        </tr>
    );
};

EditBookingRow.propTypes = {
    isArtistTypesEnabled: PropTypes.bool,
    isNew: PropTypes.bool,
    isCreatingNewArtist: PropTypes.bool,
    id: PropTypes.number,
    artistId: PropTypes.number,
    artistName: PropTypes.string,
    artistTypeId: PropTypes.number,
    bookingTypeId: PropTypes.number,
    bookingStatusId: PropTypes.number,
    errors: PropTypes.object,
    artistTypes: PropTypes.array,
    bookingTypes: PropTypes.array,
    bookingStatuses: PropTypes.array,
    onChangeValue: PropTypes.func,
    onToggleCreateNewArtist: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default EditBookingRow;
