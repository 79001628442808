import { schema } from 'normalizr';
import * as Day from 'app/react/entities/events/days/schemas';
import * as Venue from 'app/react/entities/accounts/venues/schemas';
import * as PWSettings from 'app/react/entities/events/production_website/settings/general/schemas';

export const ENTITY_REDUCER_KEY = 'events';
export const ENTITY_CONTEXT_ID_KEY = 'eventId';

const entityDefinition = {
    days: Day.entities,
    venue: Venue.entity,
    productionWebsite: PWSettings.entity
};

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);
