import React, { Fragment } from 'react'
import TextEditor from 'app/react/components/inputs/TextEditor'
import PageLinkTagsProtip from 'app/react/views/event/production_website/artists/PageLinkTagsProtip'
import TextEditorAlert from 'app/react/views/event/production_website/artists/TextEditorAlert'

const ProductionWebsiteSettingsTextEditor = (props) => (
    <Fragment>
        <TextEditorAlert />
        <div>
            <TextEditor {...props} imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']} />
        </div>
        <PageLinkTagsProtip />
    </Fragment>
);

export default ProductionWebsiteSettingsTextEditor;
