import OptionTypes from 'app/backbone/helpers/OptionTypes';
import CardContent from '../CardContent.view';

export default class TabsCardContent extends CardContent {

    optionTypes() {
        return {
            views: OptionTypes.object
        };
    }

    initialize(options) {
        this.resolveOptions(options);
    }

    renderSelectedView(tabName) {
        this.insertSubview(this.views.findByCustom(tabName), this.$el);
    }

    renderFirst() {
        this.insertSubview(this.views.first(), this.$el);
    }
}
