import { Model, Collection } from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import * as ItemGroup from './ItemGroup';

@Model({
    key: 'item'
})
class Item extends BaseModel {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model,
            item_group: ItemGroup.Model
        }
    }

    get defaults() {
        return {
            date_dependable: true,
            uses_amounts: false
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a name.'
            }
        }
    }

    initialize(model, options  = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    urlRoot() {
        this.validateRelations('account');
        let account = this.account.id;
        return `/api/accounts/${account}/items`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);

        // Api works with collections. We work with single relation.
        if (response.groups && response.groups.data.length) {
            response.item_group = response.groups.data[0];
            delete response.groups;
        }

        this.parseRelations(response);

        return response;
    }

    toJSON() {
        let json = super.toJSON();

        // Api works with collections. We work with single relation.
        let itemGroup = json.item_group;
        json.groups = [itemGroup];
        delete json.item_group;

        return json;
    }
}

@Collection({
    key: 'items',
    model: Item
})
class ItemCollection extends BaseCollection {

    static Relations() {
        return {
            account: require('app/backbone/models/account/Account').Model // Circular
        }
    }

    comparator(model) {
        return [model.getRelation('item_group').get('name'), model.get('weight')]
    }

    url() {
        this.validateRelations('account');
        let account = this.account.id;
        return `/api/accounts/${account}/items`;
    }
}

const PageableCollection = ItemCollection.prototype.PageableCollection();

export {
    Item as Model,
    ItemCollection as Collection,
    PageableCollection
};
