import BaseView from 'app/backbone/components/Base.view';

export default class CreateAccommodationView extends BaseView {

    ui() {
        return {
            accommodationFormCRM: '#accommodation-form select[name=accommodation]',
            addToCRM: '.add-to-crm',
            generalFields: '#accommodation-form .accommodation-general-fields',
            addressFields: '#accommodation-form .accommodation-address-fields',
        };
    }

    events() {
        return {
            'change accommodationFormCRM': 'toggleCRMAccommodationFields',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.ui.accommodationFormCRM.trigger('change');
        return this.delegateEvents();
    }

    toggleCRMAccommodationFields(e) {
        const shouldImportAccommodationFromCRM = e.target.value !== '',
            display = shouldImportAccommodationFromCRM ? 'none' : '';

        this.ui.addToCRM.css('display', display);
        this.ui.generalFields.css('display', display);
        this.ui.addressFields.css('display', display);
    }

}


