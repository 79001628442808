import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'ui-kit'
import TextEditor from 'app/react/components/inputs/TextEditor'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import { UpdateButtonRow } from 'app/react/components/_index'
import ContentHeader, { TABS } from '../../../views/ContentHeader'
import Card from './cards/IntroCard'

const { Content } = ContentWrapper;

export const INTRO_TEXT_LABEL = 'Form intro text';
export const BACK_BUTTON_TEXT = 'Overview';

const ArtistsFormEditIntro = ({
    store,
    eventId,
    isFetching = false,
    isArtistTypesEnabled = true,
    formState = { errors: {}, values: {} },
    onBackToSections = () => {},
    onChangeIntro = () => {},
    onSaveIntro = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            activeTab={TABS.ARTIST_TYPES.ID}
            eventId={eventId}
            artistTypesEnabled={isArtistTypesEnabled}
        >
            <a className="button primary" onClick={onBackToSections}>
                <i className="bs-icon-arrow-back" /> {BACK_BUTTON_TEXT}
            </a>
        </ContentHeader>
        <Content isLoading={isFetching}>
            <Card>
                <InputGroup
                    messages={formState.errors.intro}
                >
                    <label>{INTRO_TEXT_LABEL}</label>
                    <TextEditor
                        imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                        height={400}
                        value={formState.values.intro || ''}
                        onBlur={e => onChangeIntro(e.target.value)}
                    />
                </InputGroup>
            </Card>
            <UpdateButtonRow onUpdate={onSaveIntro} />
        </Content>
    </ContentWrapper>
);

ArtistsFormEditIntro.propTypes = {
    store: PropTypes.object.isRequired,
    isFetching: PropTypes.bool,
    eventId: PropTypes.number.isRequired,
    isArtistTypesEnabled: PropTypes.bool,
    formState: PropTypes.object,
    onBackToSections: PropTypes.func,
    onChangeIntro: PropTypes.func,
    onSaveIntro: PropTypes.func,
};

export default ArtistsFormEditIntro;
