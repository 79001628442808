var Account = require('app/backbone/models/account/Account'),
    Contact = require('app/backbone/models/account/Contact'),
    ContactModal = require('./ContactModal.view');

var template = require('./ContactModelCreate.hbs');

var ContactModalCreate = ContactModal.extend({

    template,

    events: {
        'keypress': 'keypress',
        'click .create': 'create',
        'click .create-another': 'createAnother'
    },

    open() {
        this.model = this.newModel();
        this.render();
        this.show();
    },

    newModel(initialValues = {}) {
        var account = new Account.Model().fetchFromDOM();
        return new Contact.Model(initialValues, { account });
    },

    create(e, another = false) {
        if(this.model.isValid(true)){
            this.isCreatingAnother = another;
            this.model.save();
        }
    },

    createAnother(e) {
        this.create(e, true)
    },

    syncSuccess() {
        this.trigger('create', this.model);
        this.unbindModel();
        if(this.isCreatingAnother) {
            this.clearForm();
            this.model = this.newModel();
            this.setSyncing(false);
        } else {
            this.hide();
            this.setSyncing(false);
        }
    },

    keypress(e) {
        if(e.which == 13) {
            e.preventDefault();
            this.create();
        }
    }

});

module.exports = ContactModalCreate;
