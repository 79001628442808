const CheckboxCell = require('app/backbone/components/tables/cells/CheckboxCell.view');
const template = require('./AvailableDayCell.hbs');

const AvailableDayCell = CheckboxCell.extend({

    template,

    className: 'checkbox-cell input center',

    initialize(options = {}) {
        this._super(options);
        this.listenTo(this.model.getRelation('available_days'), 'update', this.render);
    },

    viewModel() {
        return {
            view: {
                isDisabled: this.isDisabled(),
                isVisible: this.isVisible(),
                isChecked: this.isChecked(),
                isEditing: this.isEditing()
            }
        };
    },

    isChecked() {
        return this.model.hasAvailableDayOn(this.day);
    },

    isVisible() {
        return this.model.get('date_dependable');
    },

    isEditing() {
        return this.editing || false;
    },

    onChangeCheckbox(e) {
        const checked = this.$(e.currentTarget).prop('checked');
        if (checked) this.model.addAvailableDayOn(this.day);
        else this.model.removeAvailableDayOn(this.day);
    }

});

module.exports = AvailableDayCell;
