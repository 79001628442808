import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import TextEditor from 'app/react/components/inputs/TextEditor';
import DatePickerField from '../../../datePicker/DatePickerField';

const GeneralSection = ({ setFieldValue, sectionId, values }) => {
	return (
		<div className='artist-dialog__section'>
			<div className='artist-dialog__section-inner'>
				<h3>General</h3>
				<span
					className={classNames([
						'artist-dialog__configure-setup-button',
						'artist-dialog__configure-setup-button--active',
					])}
				>
					Configure your setup
				</span>
				<div className='artist-dialog__section-inner__content'>
					<DatePickerField
						label='Deadline'
						value={values.deadline}
						name={`${sectionId}.deadline`}
						onChange={setFieldValue}
						showPopperArrow={false}
						isLeft
					/>
					<Field name={`${sectionId}.intro`} label='Intro text'>
						{({ field }) => {
							return (
								<Fragment>
									<label>Intro text</label>
									<TextEditor
										imageInsertButtonsOverride={[
											'imageBack',
											'|',
											'imageByURL',
										]}
										height={200}
										onBlur={(e) =>
											setFieldValue(`${sectionId}.intro`, e.target.value)
										}
										id={'general-introText'}
										value={field.value}
									/>
								</Fragment>
							);
						}}
					</Field>
				</div>
			</div>
		</div>
	);
};

GeneralSection.propTypes = {};

export default GeneralSection;
