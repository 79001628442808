import React, { useReducer } from "react";
import {
  currencies,
  dateFormats,
  distances,
  timeFormats,
} from "../../constants/CurrenciesFormats";
import ArtistsForm from "../components/pages/ArtistsForm";
import EmailForm from "../components/pages/EmailForm";
import EventDaysForm from "../components/pages/EventDaysForm";
import { NameForm } from "../components/pages/NameForm";
import ProductionWebsiteForm from "../components/pages/productionWebsite/ProductionWebsiteFormContainer";
import FinalSplashPage from "../components/pages/FinalSplashPage";
import ProductionPagesForm from "../components/pages/productionPages/ProductionPagesForm";
import RegionDateForm from "../components/pages/RegionDateForm";
import StagesForm from "../components/pages/StagesForm";
import TasksForm from "../components/pages/tasks/TasksFormContainer";
import TicketsForm from "../components/pages/ticketTypes/TicketsFormContainer";
import VenueInformationForm from "../components/pages/VenueInformationForm";
import GetStarted from "../components/pages/GetStarted";

const regionData = {
  currencies: currencies,
  distances: distances,
  timeFormats: timeFormats,
  dateFormats: dateFormats,
};

export const datePickerFormatter = (input) => {
  switch (input) {
    case "DD/MM/YYYY":
      return "dd/MM/yyy";
    case "MM/DD/YYYY":
      return "MM/dd/yyyy";
    case "YYYY-MM-DD":
      return "yyyy-MM-dd";
    case "hh:mm A":
      return "hh:mm aa";
    default:
      return input;
  }
};

export const momentDateFormatToFlatpickrFormat = (momentFormat) => {
  switch (momentFormat) {
    case "DD/MM/YYYY":
      return "d/m/Y";
    case "MM/DD/YYYY":
      return "m/d/Y";
    case "YYYY-MM-DD":
      return "Y-m-d";
    default:
      return momentFormat;
  }
};
export const momentTimeFormatToFlatpickrFormat = (momentFormat) => {
  switch (momentFormat) {
    case "HH:mm":
      return "H:i";
    case "hh:mm A":
      return "h:i A";
    default:
      return momentFormat;
  }
};

const tempDays = [
  {
    name: "Steamy Saturday",
    id: "3232",
    start: new Date(2023, 7, 12, 12, 0, 0),
    end: new Date(2023, 7, 12, 23, 45, 0),
  },
  {
    name: "Funky friday",
    id: "454",
    start: new Date(2023, 7, 11, 12, 0, 0),
    end: new Date(2023, 7, 11, 23, 45, 0),
  },
  {
    name: "Sultry Sunday",
    id: "234",
    start: new Date(2023, 7, 13, 12, 0, 0),
    end: new Date(2023, 7, 13, 23, 45, 0),
  },
];

export const blancoEvent = {
  name: "",
  currency: "",
  date_format: "",
  time_format: "",
  unit_of_distance: "",
  days: [],
  venue: undefined,
  timezone: "",
  stages: [],
  templates: [],
  tasks: [],
  artistTypes: [],
  existingEvents: [],
  oldEventId: undefined,
  productionWebsite: undefined,
  // id: 4,
};

const steps = [
  {
    title: "Initial setup",
    subSteps: [
      {
        id: 0,
        form: GetStarted,
      },
    ],
  },
  {
    title: "Event Practicalities",
    subSteps: [
      {
        id: 1,
        form: NameForm,
        // form: EmailForm,
        tips: [
          {
            type: "tip",
            title: undefined,
            message:
              "add the year (and month) of your event in the event name. This way you can easily distinct it from other editions",
          },
          {
            type: "tip",
            title: undefined,
            message:
              "add the year (and month) of your event in the event name. This way you can easily distinct it from other editions",
          },
          {
            type: "note",
            title: "Why do we need this information",
            message:
              "Event location details will be shared at some point with tour managers or other external contacts. Enter it once in here and easily share it as soon as you need it!",
          },
        ],
      },
      {
        id: 2,
        form: VenueInformationForm,
        initialValues: { name: "" },
      },
      {
        id: 3,
        form: RegionDateForm,
      },
      {
        id: 4,
        form: EventDaysForm,
      },
      {
        id: 5,
        form: StagesForm,
      },
    ],
  },
  {
    title: "Communication",
    subSteps: [
      {
        id: 6,
        form: EmailForm,
      },
      {
        id: 7,
        form: TasksForm,
      },
      {
        id: 8,
        form: ProductionWebsiteForm,
      },
      {
        id: 9,
        form: TicketsForm,
      },
      {
        id: 10,
        form: ArtistsForm,
      },
      {
        id: 11,
        form: ProductionPagesForm,
      },
      {
        id: 12,
        form: FinalSplashPage,
      },
    ],
  },
];

const initialState = {
  activeStep: 0,
  activeSubStep: 0,
  steps,
  event: blancoEvent,
};

//props is object with {account: {...}, venues: {...}, timezones: {...}}
export const useEventState = (props) => {
  const [eventState, dispatch] = useReducer(eventReducer, {
    ...initialState,
    account: props && props.account,
    timeZones:
      props &&
      props.timeZones &&
      props.timeZones.models.map((zone) => ({
        ...zone.attributes,
        cid: zone.cid,
      })),
    venues:
      props &&
      props.venues &&
      props.venues.models.map(({ attributes }) => ({
        ...attributes,
        address: {
          ...attributes.address.data,
          addres_line_1:
            attributes.address.data.addres_line_1 ||
            attributes.address.data.street,
        },
      })),
  });

  function eventReducer(state = initialState, action) {
    switch (action.type) {
      case "SET_STEP":
        const { activeSubStep } = action.payload;
        const activeStep = steps.findIndex((step) => {
          return (
            step.subSteps.findIndex(
              (subStep) => subStep.id === activeSubStep
            ) !== -1
          );
        });
        return { ...state, activeStep, activeSubStep };
      case "UPDATE_EVENT":
        const { eventBody } = action.payload;
        return { ...state, event: { ...state.event, ...eventBody } };
      case "UPDATE_VENUES": {
        return {
          ...state,
          venues: [...state.venues, action.payload.newVenue],
        };
      }
      default:
        return state;
    }
  }

  const setActiveStep = (activeSubStep, eventBody) => {
    dispatch({ type: "SET_STEP", payload: { activeSubStep } });
    if (eventBody) {
      dispatch({ type: "UPDATE_EVENT", payload: { eventBody } });
    }
  };

  const setVenues = (newVenue) => {
    if (newVenue) {
      dispatch({ type: "UPDATE_VENUES", payload: { newVenue } });
    }
  };

  return { setActiveStep, setVenues, eventState, regionData };
};
