import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ALL_REQUEST = 'ALL_PW_ARTIST_SETTINGS_SECTION_FORM_FIELDS_REQUEST';
export const ALL_SUCCESS = 'ALL_PW_ARTIST_SETTINGS_SECTION_FORM_FIELDS_SUCCESS';
export const ALL_FAILURE = 'ALL_PW_ARTIST_SETTINGS_SECTION_FORM_FIELDS_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE,
];

export const updateAll = (accountId, eventId, artistTypeId, sectionId, entities) => {
    return ({
        [CALL_API]: {
            types: ALL_TYPES,
            method: METHODS.PUT,
            endpoint: Endpoints.updateAll(eventId, artistTypeId, sectionId),
            id: sectionId,
            body: entities,
            schema: Schemas.entities,
        },
        accountId,
        eventId,
        artistTypeId,
        sectionId,
    })
};
