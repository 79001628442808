var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert-box warning hasIconPrefix\">\n        <i class=\"bs-icon-clock\"></i>\n        <p>Your request is <strong>awaiting approval</strong>.</p>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isApproved : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"alert-box success hasIconPrefix\">\n        <i class=\"bs-icon-check-round\"></i>\n        <p>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.eventName : stack1), depth0))
    + " has <strong>approved</strong> your request.</p>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isDenied : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div class=\"alert-box danger hasIconPrefix\">\n        <i class=\"bs-icon-triangle-exclamation-mark\"></i>\n        <p>\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.eventName : stack1), depth0))
    + " has <strong>denied</strong> your request. "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.deniedReason : stack1), depth0))
    + " <br> Please change your request where needed in the sections below. Afterwards you can resend your request for approval.\n        </p>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"grid\">\n        <div class=\"grid-cell grid-cell--justifyEndContent\">\n            <a id=\"approval-button\" class=\"button\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isDenied : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "            </a>\n        </div>\n    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                    Resend for approval\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                    Send for approval\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isAwaitingApproval : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n<div class=\"grid\">\n    <div id=\"general-card\" class=\"grid-cell card\"></div>\n</div>\n<div class=\"grid\">\n    <div id=\"accreditees-card\" class=\"grid-cell card\"></div>\n</div>\n<div id=\"items-cards\" class=\"grid\"></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.isSendButtonVisible : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});