import React from 'react';
import Header from 'app/react/components/layout/content/header/Header';

const { TitleMenu } = Header;
const { Title } = TitleMenu;

const EventCompanyContentHeader = () => (
    <Title>Companies</Title>
);

export default EventCompanyContentHeader;
