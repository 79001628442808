import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import CompanyItemGroupCard from '../cards/CompanyItemGroupCard.view';
import template from './CompanyItemGroupUpdate.hbs';

export default class CompanyItemGroupUpdateView extends Content {

    get template() {
        return template;
    }

    ui() {
        return {
            itemsCard: '#items-card'
        };
    }

    events() {
        return {
            'click .update': 'update',
            'click .back': 'backToOverview'
        };
    }

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            itemGroup: OptionTypes.instanceOf(EventItemGroup.Model).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.items = this.company.getCompanyItemsByItemGroup(this.itemGroup);
        this.bindItems();
        // Subviews
        this.itemsCard = this.initItemsCard();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();

        if (!this.isSyncing) {
            this.assignSubview(this.itemsCard, this.ui.itemsCard);
        }
        return this.delegateEvents();
    }

    remove() {
        super.remove();
        this.itemsCard.remove();
    }

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                syncingText: 'Updating items...',
                isSyncing: this.isSyncing
            }
        };
    }

    initItemsCard() {
        const { company, items, periods, itemGroup } = this;
        const editing = true;
        const title = itemGroup.get('name');
        return new CompanyItemGroupCard({ company, items, periods, itemGroup, editing, title });
    }

    bindItems() {
        if (this.items) {
            this.listenTo(this.items, 'request', () => { this.setSyncing(true); });
            this.listenTo(this.items, 'error', this.onError);
            this.listenTo(this.items, 'sync', this.onSync);
        }
    }

    unbindItems() {
        if (this.items) this.stopListening(this.items);
    }

    update() {
        // Add the rest of the company items to the list.
        // Otherwise backend wil think we removed them (endpoint was originally build to update ALL company items).
        this.items.add(this.company.items.models);
        // Update the granted items only.
        this.items.sync('update', this.items.filterGranted())
            .done(() => {
                this.unbindItems();
                this.backToOverview();
            })
            .fail((xhr) => {
                this.onError(null, xhr);
            });
    }

    setSyncing(syncing) {
        if (syncing !== this.isSyncing) {
            this.isSyncing = syncing;
            this.render();
        }
    }

    onError(model, xhr) {
        this.setSyncing(false);
        this.itemsCard.validateResponse(xhr);
    }

    backToOverview() {
        const { company } = this;
        const event = company.getRelation('event');
        navigate(`/events/${event.id}/accreditation/companies/${company.id}/details`);
    }

}
