import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import GroupRow from './GroupRow';
import { toGroupsWithSubGroups, whereIsNotChild } from '../../../../../../../helpers/itemGroups'

const ArtistsGroupsTable = ({
    groups = [],
    onAdd = () => {},
    onUpdate = () => {},
    onDelete = () => {}
}) => (
    <table className="table-with-indented-rows overflow actions-right">
        {toGroupsWithSubGroups(groups)
            .filter(whereIsNotChild)
            .sort(by('name', { ignoreCase: true }))
            .map(group => (
                <GroupRow
                    key={group.id}
                    group={group}
                    onAdd={onAdd}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            ))
        }
    </table>
);

ArtistsGroupsTable.propTypes = {
    vehicles: PropTypes.array,
    onOpenUpdateModal: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
};

export default ArtistsGroupsTable;
