import OptionTypes from 'app/backbone/helpers/OptionTypes';
import AccrediteeTable from 'app/backbone/views/event/accreditation/accreditees/tables/AccrediteeTable.view';
import ItemsCell from 'app/backbone/views/event/accreditation/accreditees/tables/cells/ItemsCell.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';

export default class CompanyAccrediteeTable extends AccrediteeTable {

    optionTypes() {
        return {
            accreditees: OptionTypes.instanceOf(CompanyAccreditee.PageableCollection).isRequired
        };
    }

    initColumns() {
        const { actions } = this;
        return [{
            name: 'fullname',
            label: 'Attendee',
            headerCell: CellFactory.createHeaderCell(),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'email',
            label: 'Email',
            headerCell: CellFactory.createHeaderCell(),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'telephone',
            label: 'Tel',
            headerCell: CellFactory.createHeaderCell('125px'),
            cell: 'string',
            sortable: false,
            editable: false
        }, {
            name: 'name',
            label: 'Items',
            headerCell: CellFactory.createHeaderCell(),
            cell: ItemsCell,
            sortable: false,
            editable: false
        }, {
            name: 'name',
            label: 'Profiles',
            headerCell: CellFactory.createHeaderCell(),
            cell: CellFactory.createSubCollectionLabelCell(null, null, 'profiles'),
            sortable: false,
            editable: false
        }, {
            name: 'actions',
            label: 'Actions',
            headerCell: CellFactory.createHeaderCell('130px'),
            cell: CellFactory.createActionCell(actions),
            sortable: false,
            editable: false
        }];
    }

    initActions() {
        return [{
            name: 'ticket',
            icon: 'bs-icon-download',
            tooltip: 'Download Ticket',
            action: this.downloadTicket.bind(this),
            canRender: (accreditee) => accreditee.canDownloadTicket()
        },{
            name: 'send-ticket',
            icon: 'bs-icon-ticket',
            tooltip: 'Send Ticket',
            action: this.sendTicket.bind(this),
            canRender: (accreditee) => accreditee.canSendTicket()
        }, {
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit',
            action: this.update.bind(this)
        }, {
            name: 'remove',
            icon: 'bs-icon-cross',
            tooltip: 'Remove',
            action: this.delete.bind(this)
        }];
    }

    getFiltersDef() {
        return [];
    }

    downloadTicket(accreditee) {
        const eventId = accreditee.getRelation('company').getRelation('event').get('id');
        const accrediteeId = accreditee.id;
        navigate(`/events/${eventId}/accreditation/accreditees/${accrediteeId}/ticket`, { pageReload: true });
    }

    update(accreditee, newTab = false) {
        const company = accreditee.getRelation('company');
        const eventId = company.getRelation('event').get('id');
        const companyId = company.get('id');
        const accrediteeId = accreditee.get('id');
        navigate(`/events/${eventId}/accreditation/companies/${companyId}/attendees/${accrediteeId}/edit`, { newTab });
    }

    getPlaceholderText() {
        return 'No attendees have been added by this company for this event.';
    }

    hasSearch() {
        return false;
    }
}
