import _ from 'underscore';
import Backgrid from 'backgrid';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Table from './Table.view';
import template from './FixedColumnTable.hbs';

export default class FixedColumnTable extends Table {

    get template() {
        return template;
    }

    className() {
        return 'backgrid overflow';
    }

    ui() {
        return {
            firstColumnsTable: '.first-columns-container',
            lastColumnsTable: '.last-columns-container',
            table: '.table-container',
        };
    }

    optionTypes() {
        return _.extend({}, super.optionTypes(), {
            firstColumns: OptionTypes.arrayOf(OptionTypes.object),
            lastColumns: OptionTypes.arrayOf(OptionTypes.object)
        });
    }

    optionDefaults() {
        return _.extend({}, super.optionDefaults(), {
            overflowX: false,
            firstColumns: [],
            lastColumns: []
        });
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.row = this.getCenterColumnsRowClass();

        super.initialize(options);
        if (this.firstColumns.length) this.firstColumnsTable = this.initFirstColumnsTable();
        if (this.lastColumns.length) this.lastColumnsTable = this.initLastColumnsTable();
    }

    getCenterColumnsRowClass() {
        return Backgrid.Row;
    }

    initFirstColumnsTable() {
        const { collection, header, body, firstColumns, className } = this;
        const row = this.getFirstColumnsRowClass();
        return new Backgrid.Grid({ collection, header, body, columns: firstColumns, row, className });
    }

    getFirstColumnsRowClass() {
        return Backgrid.Row;
    }

    initLastColumnsTable() {
        const { collection, header, body, lastColumns, className } = this;
        const row = this.getLastColumnsRowClass();
        return new Backgrid.Grid({ collection, header, body, columns: lastColumns, row, className });
    }

    getLastColumnsRowClass() {
        return Backgrid.Row;
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.insertSubview(this.firstColumnsTable, this.ui.firstColumnsTable);
        this.insertSubview(this.table, this.ui.table);
        this.insertSubview(this.lastColumnsTable, this.ui.lastColumnsTable);

        if (this.lastColumnsTable) {
            this.ui.lastColumnsTable.addClass('columnGroup');
            this.lastColumnsTable.$el.addClass('actions-right');
        }
        if (this.checkOverflow(this.ui.table[0]).horizontal) {
            this.ui.table.addClass('overflow-x');
        }

        this.isRenderedEmpty = this.isEmpty();
        return this.delegateEvents();
    }

    remove() {
        if (this.firstColumnsTable) this.firstColumnsTable.remove();
        if (this.lastColumnsTable) this.lastColumnsTable.remove();
        super.remove();
    }

}
