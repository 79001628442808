import { connect } from 'react-redux';
import { mapAccountContextToProps, mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as actions from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Accommodation from 'app/react/entities/events/accommodations/index';
import * as RoomType from 'app/react/entities/events/accommodations/room_types/index';
import * as Reservation from 'app/react/entities/events/reservations/index';
import ReservationsOverview from './Overview';
import * as ModalState from './components/modals/stateful/ModalState';
import * as ReservationRoutes from 'app/routes/event/artists/reservations/routes';

const RESERVATIONS_INCLUDES = ['status', 'booking.artist', 'guests'];
const FAILED_POPUP = (name, error) => `Failed to checked in <strong>${name}</strong>. ${error}`;
const SUCCESSFUL_POPUP = (name) => `<strong>${name}</strong> successfully checked in.`;

const mapStateToProps = (state) => {
    const { manageAccommodations } = Event.stateHelpers.getUserPermissions(state);
    return {
        ...mapAccountContextToProps(state),
        ...mapEventContextToProps(state),
        isFetching: (
            Accommodation.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Day.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        userCanManageAccommodations: manageAccommodations,
        days: Day.stateHelpers.getAllForCurrentContext(state),
        accommodations: Accommodation.stateHelpers.getAllForCurrentContext(state),
        roomTypes: RoomType.stateHelpers.getAll(state),
        reservations: Reservation.stateHelpers.getAllByPaginationForCurrentContext(state, true),
        reservationsPagination: Reservation.stateHelpers.getPaginationForCurrentContext(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onDownloadReport: () => {
        const { eventId } = stateProps;
        dispatchProps.onDownloadReport(eventId);
    },
    onChangeFilter: (key, value) => {
        const { requestedFilters } = stateProps.reservationsPagination;
        dispatchProps.onChangeFilter(key, value, requestedFilters)
    },
    onToggleReservationCheckIn: (reservationId, isCheckedIn) => {
        const { reservations } = stateProps;
        const { booking } = reservations.find(({ id }) => id === reservationId);
        dispatchProps.onToggleReservationCheckIn(reservationId, isCheckedIn, booking.artist.name);
    },
    onOpenDeleteModal: (reservationId) => {
        const { reservations } = stateProps;
        const reservation = reservations.find(({ id }) => id === reservationId);
        dispatchProps.onOpenDeleteModal(reservation);
    },
    onEditReservation: (reservationId, newTab) => {
        const { reservations, eventId } = stateProps;
        const { booking } = reservations.find(({ id }) => id === reservationId);
        dispatchProps.onEditReservation(eventId, booking.id, reservationId, newTab);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onDownloadReport: (eventId) => {
        dispatch(actions.navigate.newTab(ReservationRoutes.report(eventId)));
    },
    onChangePageSize: (pageSize) => {
        dispatch(Reservation.thunks.fetchAllByPaginationForCurrentContext({ pageSize }, RESERVATIONS_INCLUDES));
    },
    onChangeFilter: (key, value, currentFilters = {}) => {
        const filters = { ...currentFilters, [key]: value };
        if (value === 0) {
            delete filters[key];
        }
        if (key === 'accommodation') {
            delete filters.roomType
        }
        dispatch(Reservation.thunks.fetchAllByPaginationForCurrentContext({ filters }, RESERVATIONS_INCLUDES));
    },
    onSearch: (search) => {
        // We clear filters when searching
        const filters = {};
        dispatch(Reservation.thunks.fetchAllByPaginationForCurrentContext({ search, filters }, RESERVATIONS_INCLUDES));
    },
    onClearSearch: () => {
        const search = '';
        dispatch(Reservation.thunks.fetchAllByPaginationForCurrentContext({ search }, RESERVATIONS_INCLUDES));
    },
    onSort: (sortKey, sortOrder) => {
        dispatch(Reservation.thunks.fetchAllByPaginationForCurrentContext({ sortKey, sortOrder }, RESERVATIONS_INCLUDES));
    },
    onChangePage: (page) => {
        dispatch(Reservation.thunks.fetchAllByPaginationForCurrentContext({ page }, RESERVATIONS_INCLUDES));
    },
    onToggleReservationCheckIn: (reservationId, isCheckedIn, artistName) => {
        dispatch(Reservation.thunks.toggleIsCheckInForCurrentContext(reservationId, isCheckedIn))
            .then(({ isFailure, errorMessage }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(FAILED_POPUP(artistName, errorMessage)));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP(artistName)));
                }
            });
    },
    onOpenStatusModal(reservation, reviewedStatus, successCallback) {
        reservation.status = {
            id: reviewedStatus.ID,
            name: reviewedStatus.NAME,
        };
        dispatch(ModalState.mergeReviewModalState({
            isOpened: true,
            reservation,
            errors: {},
            createSuccessCallback: successCallback
        }));
    },
    onEditReservation: (eventId, bookingId, reservationId, newTab = false) => {
        const url = ReservationRoutes.update(eventId, bookingId, reservationId);
        if (newTab) {
            dispatch(actions.navigate.newTab(url))
        } else {
            dispatch(actions.navigate.pageReload(url))
        }
    },
    onOpenDeleteModal(reservation) {
        dispatch(ModalState.mergeDeleteModalState({
            isOpened: true,
            reservation,
        }))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ReservationsOverview);
