import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON, reformatISOString } from 'app/react/helpers/_index';
import {
    mapBeatswitchContextToProps,
    mapBookingContextToProps,
    mapEventContextToProps,
} from 'app/react/components/util/connectBeatswitchContext'
import * as actions from 'app/react/state/actions/_index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Pickup from 'app/react/entities/events/bookings/pickups';
import * as EventLocations from 'app/react/entities/events/locations/index';
import * as EventRoutes from 'app/react/entities/events/locations/routes/index';
import * as BookingAttendees from 'app/react/entities/events/bookings/attendees/index';
import * as Driver from 'app/react/entities/events/drivers/index';
import * as Vehicle from 'app/react/entities/events/vehicles/index';
import * as VehicleType from 'app/react/entities/vehicle_types/index';
import * as PickupModes from 'app/react/entities/events/bookings/pickups/modes/index';
import * as Transport from 'app/react/entities/events/bookings/transports/index';
import * as State from './UpdateViewState';
import { getEventRouteFormValues } from '../../util/index';
import * as CreateVehicleModalState from 'app/react/views/event/artists/logistics/vehicles/components/modals/stateful/CreateModalState';
import * as DriverModalState from 'app/react/views/event/artists/logistics/drivers/components/ModalState';

import UpdateView from './UpdateView';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses'
import * as Event from 'app/react/entities/events'
import * as Account from 'app/react/entities/accounts'

const FAILED_TO_UPDATE_POPUP = (departureName, arrivalName) =>
    `Failed to update pickup from <strong>${departureName}</strong> to <strong>${arrivalName}</strong>.`;

const SUCCESSFUL_UPDATE_POPUP = (departureName, arrivalName) =>
    `Pickup from <strong>${departureName}</strong> to <strong>${arrivalName}</strong> successfully updated. Redirecting...`;

const FAILED_TO_CREATE_DRIVER_POPUP = 'Failed to create driver.';
const SUCCESSFUL_CREATE_DRIVER_POPUP = 'Driver successfully created.';

const mapStateToProps = (state) => {
    const {
        values = {},
        errors = {},
        isSettingCustomDepartureName,
        isSettingCustomArrivalName,
        transportsIsCollapsed,
        isSaving,
    } = State.getViewState(state);
    return {
        ...mapBeatswitchContextToProps(state),
        ...mapEventContextToProps(state),
        ...mapBookingContextToProps(state),
        isFetching: (
            isSaving ||
            Pickup.stateHelpers.isFetchingCurrent(state) ||
            Booking.stateHelpers.isFetchingCurrent(state) ||
            PickupModes.stateHelpers.isFetchingAll(state) ||
            BookingAttendees.stateHelpers.isFetchingAllForCurrentContext(state) ||
            EventLocations.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Driver.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Vehicle.stateHelpers.isFetchingAllForCurrentContext(state) ||
            VehicleType.stateHelpers.isFetchingAll(state) ||
            BookingStatus.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        isFetchingTransports: Transport.stateHelpers.isFetchingAllForCurrentContext(state),
        values,
        errors,
        isSettingCustomDepartureName,
        isSettingCustomArrivalName,
        transportsIsCollapsed,

        artist: Artist.stateHelpers.getCurrent(state),
        modes: PickupModes.stateHelpers.getAll(state),
        drivers: Driver.stateHelpers.getAllForCurrentContext(state),
        vehicles: Vehicle.stateHelpers.getAllForCurrentContext(state),
        vehicleTypes: VehicleType.stateHelpers.getAll(state),
        locations: EventLocations.stateHelpers.getAllForCurrentContext(state),
        bookingAttendees: BookingAttendees.stateHelpers.getAllForCurrentContext(state),
        transports: Transport.stateHelpers.getAllForCurrentContext(state),
        permissions: Event.stateHelpers.getUserPermissions(state),
        accountPermissions: Account.stateHelpers.getUserPermissions(state),
    };
};

const toAPIValues = values => ({
    ...values,
    driver: typeof values.driver === 'undefined' ? null : values.driver,
    vehicle: typeof values.vehicle === 'undefined' ? null : values.vehicle,
    departure: reformatISOString(values.departure),
    arrival: reformatISOString(values.arrival),
    duration: typeof values.duration !== 'undefined' ? values.duration * 60 : undefined
});

const mapDispatchToProps = (dispatch) => ({
    onChangeValue: (key, value) => {
        dispatch(State.setViewStateValue(`values.${key}`, value));
    },
    onChangeDeparture: (departure, arrival) => {
        dispatch(State.setViewStateValue('values.departure', departure));
        if (typeof arrival === 'undefined') {
            dispatch(State.setViewStateValue('values.arrival', departure));
        }
    },
    onSelectDepartureLocation: (value) => {
        dispatch(State.setViewStateValue('values.departureLocation', value));
    },
    onSelectArrivalLocation: (eventId, departureLocation, arrivalLocation, departure) => {
        dispatch(State.setViewStateValue('values.arrivalLocation', arrivalLocation));
        dispatch(EventRoutes.actions.fetchDirections(eventId, departureLocation, arrivalLocation))
            .then(({ isSuccess, response }) => {
                if (isSuccess) {
                    const routeFormValues = getEventRouteFormValues(response, response.result, departure);
                    dispatch(State.mergeViewStateValue('values', routeFormValues));
                }
            });
    },
    onToggleSetCustomDepartureName: (setCustomName, values) => {
        dispatch(State.setViewStateValue('isSettingCustomDepartureName', setCustomName));
        dispatch(State.setViewStateValue('values.departureLocation', setCustomName ? null : values.departureLocation));
    },
    onToggleSetCustomArrivalName: (setCustomName, values) => {
        dispatch(State.setViewStateValue('isSettingCustomArrivalName', setCustomName));
        dispatch(State.setViewStateValue('values.arrivalLocation', setCustomName ? null : values.arrivalLocation));
    },
    onToggleTransportsCollapse: (isCollapsed) => {
        dispatch(State.setViewStateValue('transportsIsCollapsed', isCollapsed));
    },
    onSelectPassenger: (selectedPassengers = [], attendeeId, isSelected) => {
        const passengers = isSelected ?
            [...selectedPassengers, attendeeId] :
            selectedPassengers.filter(id => id !== attendeeId);
        dispatch(State.setViewStateValue('values.passengers', passengers));
        dispatch(State.setViewStateValue('values.passengerAmount', passengers.length));
    },
    onSelectAllPassengers: (bookingAttendees = [], isSelectingAll) => {
        const passengers = isSelectingAll ?
            bookingAttendees.map(({ id }) => id) :
            [];
        dispatch(State.setViewStateValue('values.passengers', passengers));
        dispatch(State.setViewStateValue('values.passengerAmount', passengers.length));
    },
    onUpdate: (values, departureName, arrivalName, redirect) => {
        dispatch((State.setViewStateValue('isSaving', true)));
        const apiValues = toAPIValues(values);
        dispatch(Pickup.thunks.updateCurrent(apiValues))
            .then(({ error, response }) => {
                if (typeof error !== 'undefined') {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.showErrorPopup(FAILED_TO_UPDATE_POPUP(departureName, arrivalName)));
                    dispatch(State.mergeViewState({
                        errors: errors,
                        isSaving: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_UPDATE_POPUP(departureName, arrivalName)));
                    if (typeof redirect === 'undefined') {
                        dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
                    } else {
                        dispatch(actions.navigate.pageReload(redirect))
                    }
                }
            });
    },
    onOpenCreateDriverModal: () => {
        dispatch(DriverModalState.mergeCreateModalState({
            isOpened: true,
            values: {},
            errors: {}
        }))
    },
    onCreateDriverSuccess: (driverId) => {
        dispatch(State.setViewStateValue('values.driver', driverId));
    },
    onOpenCreateVehicleModal: () => {
        dispatch(CreateVehicleModalState.open())
    },
    onCreateVehicleSuccess: (vehicleId) => {
        dispatch(State.setViewStateValue('values.vehicle', vehicleId));
    },
    onBack: (redirect) => {
        if (typeof redirect === 'undefined') {
            dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
        } else {
            dispatch(actions.navigate.pageReload(redirect))
        }
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onChangeDeparture: (departure) => {
        const { values = {} } = stateProps;
        dispatchProps.onChangeDeparture(departure, values.arrival);
    },
    onSelectPassenger: (attendeeId, isSelected) => {
        const { values = {} } = stateProps;
        dispatchProps.onSelectPassenger(values.passengers, attendeeId, isSelected);
    },
    onSelectAllPassengers: (isSelectingAll) => {
        const { bookingAttendees } = stateProps;
        dispatchProps.onSelectAllPassengers(bookingAttendees, isSelectingAll);
    },
    onSelectArrivalLocation: (value) => {
        const { eventId, values } = stateProps;
        dispatchProps.onSelectArrivalLocation(eventId, values.departureLocation, value, values.departure);
    },
    onToggleSetCustomDepartureName: (setCustomName) => {
        const { values = {} } = stateProps;
        dispatchProps.onToggleSetCustomDepartureName(setCustomName, values);
    },
    onToggleSetCustomArrivalName: (setCustomName) => {
        const { values = {} } = stateProps;
        dispatchProps.onToggleSetCustomArrivalName(setCustomName, values);
    },
    onUpdate: () => {
        const {
            values,
            locations,
            isSettingCustomDepartureName,
            isSettingCustomArrivalName,
        } = stateProps;
        const { redirect } = ownProps;

        const selectedDepartureLocation = locations.find(({ id }) => id === values.departureLocation) || {};
        const selectedArrivalLocation = locations.find(({ id }) => id === values.arrivalLocation) || {};
        const departureName = isSettingCustomDepartureName ?
            values.departureLocationName :
            selectedDepartureLocation.name;
        const arrivalName = isSettingCustomArrivalName ?
            values.arrivalLocationName :
            selectedArrivalLocation.name;
        dispatchProps.onUpdate(values, departureName, arrivalName, redirect);
    },
    onSaveModal: () => {
        const { modalValues } = stateProps;
        dispatchProps.onSaveModal(modalValues);
    },
    onBack: () => {
        const { redirect } = ownProps;
        dispatchProps.onBack(redirect);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(UpdateView);
