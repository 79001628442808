import React from 'react'
import BaseController from 'app/backbone/helpers/BaseController'
import * as Integration from 'app/react/entities/accounts/integrations/index'
import GeneralAccountSettingsView from 'app/backbone/views/account/settings/account/AccountSettings.view'
import IntegrationsView from 'app/react/views/account/settings/account/integrations/views/IntegrationsViewContainer'
import OverviewView from 'app/react/views/account/settings/account/privacy/views/OverviewViewContainer'
import * as Routes from './routes'

// Hybrid Controller
export default class AccountSettingsController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        // Routes
        router.route(Routes.base(), null, this.general.bind(this));
        router.route(Routes.integrations(), null, this.integrations.bind(this));
        router.route(Routes.privacy(), null, this.privacy.bind(this));
    }

    general () {
        const { app } = this;
        app.renderContentView(new GeneralAccountSettingsView())
    }

    integrations() {
        const { app, store, dispatch } = this;
        dispatch(Integration.actions.loadAllAvailable());
        dispatch(Integration.actions.loadAllActivated());
        app.renderContentComponent(React.createElement(IntegrationsView, { store }));
    }

    privacy() {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(OverviewView, { store }));
    }

}
