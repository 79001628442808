import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from  'ui-kit'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import { UpdateButtonRow, ConfirmModal } from 'app/react/components/_index'
import ContentHeader, { TABS } from '../../../../../views/ContentHeader'
import AvailableFormFieldsCard from './AvailableFormFieldsCard'
import FormBuilderCard from './FormBuilderCard'
import FormFieldPropsCard from './FormFieldPropsCard'

const { Content } = ContentWrapper;
const { Cell } = Grid;

export const BACK_BUTTON_TEXT = 'Back';
export const AVAILABLE_FORM_FIELD_DRAGULA_ID = 'availableFormFieldsCard';
export const FORM_BUILDER_DRAGULA_ID = 'formBuilderCard';
export const CONFIRM_MODAL_TITLE = 'Remove saved form field';
export const CONFIRM_MODAL_TEXT =
    `You are about to remove a saved form field. 
    All data related to this field will be lost.
    Are you sure you want te remove this field?`;
export const CONFIRM_MODAL_BUTTON_TEXT = 'Yes, remove'

const FormBuilderView = ({
    store,
    eventId,
    isFetching = false,
    isDragging = false,
    isStaticFormFieldsShown = true,
    isArtistTypesEnabled = true,
    staticFormFields = [],
    formFields = [],
    selectedFormField,
    confirmModalState,
    dragula,
    confirmModalClosed = () => {},
    confirmModalConfirmed = () => {},
    changeFormFieldProps = () => {},
    toggleShowStaticFormFields = () => {},
    selectFormField = () => {},
    removeFormField = () => {},
    saveForm = () => {},
    backToSection = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            activeTab={TABS.ARTIST_TYPES.ID}
            eventId={eventId}
            artistTypesEnabled={isArtistTypesEnabled}
        >
            <a className="button primary" onClick={backToSection}>
                {BACK_BUTTON_TEXT}
            </a>
        </ContentHeader>
        <Content isLoading={isFetching}>
            <Grid>
                <Cell isAutoSized width="25%">
                    <AvailableFormFieldsCard
                        dragula={dragula}
                        dragulaContainerId={AVAILABLE_FORM_FIELD_DRAGULA_ID}
                    />
                </Cell>
                <Cell isAutoSized width="50%">
                    <FormBuilderCard
                        dragula={dragula}
                        dragulaContainerId={FORM_BUILDER_DRAGULA_ID}
                        staticFormFields={staticFormFields}
                        formFields={formFields}
                        isDragging={isDragging}
                        isStaticFormFieldsShown={isStaticFormFieldsShown}
                        selectedFormField={selectedFormField}
                        selectFormField={selectFormField}
                        removeFormField={removeFormField}
                        toggleShowStaticFormFields={toggleShowStaticFormFields}
                    />
                    <UpdateButtonRow onUpdate={saveForm} />
                </Cell>
                <Cell isAutoSized width="25%">
                    {selectedFormField &&
                        <FormFieldPropsCard
                            selectedFormField={selectedFormField}
                            changeFormFieldProps={changeFormFieldProps}
                        />
                    }
                </Cell>
            </Grid>
        </Content>
        <ConfirmModal
            isOpened={confirmModalState.isOpened}
            onConfirmed={confirmModalConfirmed}
            onClosed={confirmModalClosed}
            title={CONFIRM_MODAL_TITLE}
            text={CONFIRM_MODAL_TEXT}
            buttonText={CONFIRM_MODAL_BUTTON_TEXT}
        />
    </ContentWrapper>
);

FormBuilderView.propTypes = {
    store: PropTypes.object,
    eventId: PropTypes.number,
    isFetching: PropTypes.bool,
    isDragging: PropTypes.bool,
    isArtistTypesEnabled: PropTypes.bool,
    isStaticFormFieldsShown: PropTypes.bool,
    staticFormFields: PropTypes.array,
    formFields: PropTypes.array,
    selectedFormField: PropTypes.object,
    confirmModalState: PropTypes.object,
    dragula: PropTypes.object,
    confirmModalClosed: PropTypes.func,
    confirmModalConfirmed: PropTypes.func,
    changeFormFieldProps: PropTypes.func,
    toggleShowStaticFormFields: PropTypes.func,
    selectFormField: PropTypes.func,
    removeFormField: PropTypes.func,
    saveForm: PropTypes.func,
    backToSection: PropTypes.func,
};

export default FormBuilderView;




