import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { UpdateButtonRow } from 'app/react/components/_index';
import ContentHeader from '../../components/layout/ContentHeader';
import UpdateBookingCard from './UpdateBookingCard';
import UpdatePerformanceCard from './UpdatePerformanceCard';
import Header from 'app/react/components/layout/content/header/Header';

const { Content } = ContentWrapper;
const { Cell } = Grid;
const { TitleMenu } = Header;
const { Actions } = TitleMenu;

const UpdateBookingView = ({
    store,
    eventId,
    isFetching = true,
    isArtistTypesEnabled = true,
    dateFormat,
    timeFormat,
    selectedDay = {},

    bookingIsAttachedToPerformance = false,
    isEditingPerformanceName = false,
    bookingValues = {},
    bookingErrors = [],
    performanceValues = {},
    performanceErrors = [],

    artist = {},
    days = [],
    stages = [],
    artists = [],
    artistTypes = [],
    bookingTypes = [],
    bookingStatuses = [],

    onChangeBookingValue = () => {},
    onChangePerformanceValue = () => {},
    onSelectDay = () => {},
    onToggleEditPerformanceName = () => {},
    onUpdate = () => {},
    onBack = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader eventId={eventId}>
            <Actions>
                <a className="button primary" onClick={onBack}>
                    <i className="bs-icon-arrow-back" /> Back
                </a>
            </Actions>
        </ContentHeader>
        <Content isLoading={isFetching}>
            <div style={{ maxWidth: '864px' }}>
                <UpdateBookingCard
                    isArtistTypesEnabled={isArtistTypesEnabled}
                    values={bookingValues}
                    errors={bookingErrors}
                    artistTypes={artistTypes}
                    bookingTypes={bookingTypes}
                    bookingStatuses={bookingStatuses}
                    onChangeValue={onChangeBookingValue}
                />
                {bookingIsAttachedToPerformance &&
                    <UpdatePerformanceCard
                        isEditingPerformanceName={isEditingPerformanceName}
                        values={performanceValues}
                        errors={performanceErrors}
                        days={days}
                        stages={stages}
                        dateFormat={dateFormat}
                        timeFormat={timeFormat}
                        dayStart={selectedDay.start}
                        dayEnd={selectedDay.end}
                        onChangeValue={onChangePerformanceValue}
                        onSelectDay={onSelectDay}
                        onToggleEditPerformanceName={onToggleEditPerformanceName}
                    />
                }
                <UpdateButtonRow onUpdate={onUpdate} />
            </div>
        </Content>
    </ContentWrapper>
);

UpdateBookingView.propTypes = {
    store: PropTypes.object.isRequired,
    eventId: PropTypes.number,
    isFetching: PropTypes.bool,
    isArtistTypesEnabled: PropTypes.bool,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    selectedDay: PropTypes.object,

    isEditingPerformanceName: PropTypes.bool,

    bookingValues: PropTypes.object,
    bookingErrors: PropTypes.object,
    performanceValues: PropTypes.object,
    performanceErrors: PropTypes.object,

    days: PropTypes.array,
    stages: PropTypes.array,
    artists: PropTypes.array,
    artistTypes: PropTypes.array,
    bookingTypes: PropTypes.array,
    bookingStatuses: PropTypes.array,

    onChangePerformanceValue: PropTypes.func,
    onChangeBookingValue: PropTypes.func,
    onSelectDay: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
    onCreate: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateBookingView;
