import React from 'react'
import PropTypes from 'prop-types'
import {
    Accordion,
    InputGroup,
    PrefixedInput,
    Grid,
    Switch,
} from 'ui-kit'
import by from 'thenby'
import { Modal, Tooltip } from 'app/react/components/_index'
import TextEditor from 'app/react/views/event/production_website/artists/ProductionWebsiteSettingsTextEditor'

const { Header, Content, Footer } = Modal;
const { Title } = Header;
const { Button } = Footer;
const { Cell } = Grid;

const TITLE = {
    UPDATE: 'Edit page',
    CREATE: 'Add page'
};
const FORM = {
    URL: {
        KEY: 'slug',
        LABEL: 'Url'
    },
    NAME: {
        KEY: 'name',
        LABEL: 'Name'
    },
    HOME_PAGE: {
        KEY: 'isHomePage',
        LABEL: 'Homepage'
    },
    TEXT: {
        KEY: 'body',
        LABEL: 'Default text',
        HEIGHT: 200
    },
    ARTIST_PAGE_VISIBLE: {
        KEY: 'visible',
        LABEL: 'This page is visible for these artists:'
    },
    ARTIST_PAGE_TEXT: {
        KEY: 'body',
        LABEL: 'Overwrite default text',
        HEIGHT: 200
    }
};
const BUTTON_TEXT = {
    UPDATE: 'Update',
    CREATE: 'Create'
};
const SHOW_HIDE_TOOLTIP = 'Show/hide this page for these artists.';

const PageModal = ({
    productionWebsiteUrl,
    isArtistTypesEnabled = true,
    isOpened = false,
    isSyncing = false,
    isCreating = true,
    isHomePage = false,
    isFormPage = false,
    hasChildren = false,
    formValues = {},
    formErrors = {},
    onChangeFormValue = () => {},
    onClosed = () => {},
    onSave = () => {},
    activeArtistTypeAccordion,
    handleAccordionChange
}) => {
    const title = isCreating ? TITLE.CREATE : TITLE.UPDATE;
    const buttonText = isCreating ? BUTTON_TEXT.CREATE : BUTTON_TEXT.UPDATE;
    const buttonClassName = isCreating ? 'create' : 'update';
    const artistTypePages = formValues.artistTypePages || [];

    return (
        <Modal
            width={1024}
            closeOnBackgroundClick={false}
            opened={isOpened}
            syncing={isSyncing}
            onClosed={() => {
                handleAccordionChange(null)
                onClosed()
            }}
        >
            <Header onClosed={onClosed}>
                <Title>{title}</Title>
            </Header>
            <Content scrollable>
                {!hasChildren &&
                    <InputGroup
                        messages={formErrors[FORM.URL.KEY]}
                    >
                        <label>{FORM.URL.LABEL}</label>
                        <PrefixedInput label={productionWebsiteUrl}>
                            <input
                                type="text"
                                disabled={formValues.isFormPage}
                                value={formValues[FORM.URL.KEY] || ''}
                                onChange={e => onChangeFormValue(FORM.URL.KEY, e.target.value)}
                            />
                        </PrefixedInput>
                    </InputGroup>
                }
                {!hasChildren && !(isFormPage && isHomePage) &&
                    <InputGroup>
                        <label>
                            {FORM.HOME_PAGE.LABEL}
                            <input
                                type="checkbox"
                                style={{ marginLeft: '1rem' }}
                                checked={formValues[FORM.HOME_PAGE.KEY] || false}
                                onChange={e => onChangeFormValue(FORM.HOME_PAGE.KEY, e.target.checked)}
                            />
                        </label>
                    </InputGroup>
                }
                <InputGroup messages={formErrors[FORM.NAME.KEY]}>
                    <label>{FORM.NAME.LABEL}</label>
                    <input
                        type="text"
                        value={formValues[FORM.NAME.KEY] || ''}
                        onChange={e => onChangeFormValue(FORM.NAME.KEY, e.target.value)}
                    />
                </InputGroup>
                {!isFormPage && !hasChildren &&
                    <InputGroup
                        messages={formErrors[FORM.TEXT.KEY]}
                    >
                        <label>{FORM.TEXT.LABEL}</label>
                        <TextEditor
                            imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                            height={FORM.TEXT.HEIGHT}
                            value={formValues[FORM.TEXT.KEY] || ''}
                            disabled={formValues.isFormPage}
                            onBlur={e => onChangeFormValue(FORM.TEXT.KEY, e.target.value)}
                        />
                    </InputGroup>
                }
                {isArtistTypesEnabled && !isFormPage &&
                    <Accordion>
                        {artistTypePages.sort(by('artistTypeName')).map((artistTypePage, index) => (
                            <Accordion.Item
                                key={index}
                                id={index}
                                isDisabled={
                                    hasChildren ||
                                    (!artistTypePage[FORM.ARTIST_PAGE_VISIBLE.KEY] && !isHomePage)
                                }
                                isOpen={activeArtistTypeAccordion === index + 1}
                                renderTitle={() => (
                                    <Grid isExpanded hasGutters={false}>
                                        <Cell>{artistTypePage.artistTypeName}</Cell>
                                        {(!isHomePage && !formValues.isHomePage) && (
                                            <Cell isContentJustifiedAtEnd className="margin-tiny-right">
                                                <Tooltip content={SHOW_HIDE_TOOLTIP}>
                                                    <Switch
                                                        id={`artistTypePagesSwitch${index}`}
                                                        isOn={artistTypePage[FORM.ARTIST_PAGE_VISIBLE.KEY]}
                                                        onChange={(e, checked) => onChangeFormValue(
                                                            `artistTypePages.${index}.${FORM.ARTIST_PAGE_VISIBLE.KEY}`,
                                                            checked
                                                        )}
                                                    />
                                                </Tooltip>
                                            </Cell>
                                        )}
                                    </Grid>
                                )}
                            >
                                <InputGroup
                                    messages={formErrors[FORM.ARTIST_PAGE_TEXT.KEY]}
                                >
                                    <label>{FORM.ARTIST_PAGE_TEXT.LABEL}</label>
                                    <TextEditor
                                        imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                                        height={FORM.ARTIST_PAGE_TEXT.HEIGHT}
                                        value={artistTypePage[FORM.ARTIST_PAGE_TEXT.KEY] || ''}
                                        onBlur={e => onChangeFormValue(
                                            `artistTypePages.${index}.${FORM.ARTIST_PAGE_TEXT.KEY}`,
                                            e.target.value
                                        )}
                                    />
                                </InputGroup>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                }
            </Content>
            <Footer>
                <Button
                    className={buttonClassName}
                    onClick={onSave}
                >
                    {buttonText}
                </Button>
            </Footer>
        </Modal>
    );
};

PageModal.propTypes = {
    productionWebsiteUrl: PropTypes.string,
    isArtistTypesEnabled: PropTypes.bool,
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    isCreating: PropTypes.bool,
    isHomePage: PropTypes.bool,
    isFormPage: PropTypes.bool,
    hasChildren: PropTypes.bool,
    formValues: PropTypes.shape({
        [FORM.URL.KEY]: PropTypes.string,
        [FORM.NAME.KEY]: PropTypes.string,
        [FORM.HOME_PAGE.KEY]: PropTypes.bool,
        [FORM.TEXT.KEY]: PropTypes.string
    }),
    formErrors: PropTypes.object,
    onChangeFormValue: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
    activeArtistTypeAccordion: PropTypes.number,
    handleAccordionChange: PropTypes.func
};

export default PageModal;
