import _ from 'underscore';
import { Model, Collection }  from 'app/backbone/models/decorators';
import * as AvailableDay from '../../settings/accreditation/AvailableDay';
import * as EventItem from '../../settings/accreditation/EventItem'
import * as CompanyProfile from './CompanyProfile'

@Model({
    key: 'company_profile_item'
})
class CompanyProfileItem extends EventItem.Model {

    static Relations() {
        return {
            item_group: EventItem.Model.Relations().item_group,
            available_days: AvailableDay.Collection
        }
    }

    get validation() {
        return {
            id: {
                required: true,
                msg: 'Select a profile.'
            }
        };
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if(this.hasRelation('available_days')) json.available_days = json.available_days = this.available_days.toJSON();
        return json;
    }

    hasAvailableDayOn(day) {
        return this.getAvailableDayOn(day) ? true : false;
    }

    hasAvailableDayOnAll(days) {
        return days.every(day => this.hasAvailableDayOn(day));
    }

    getAvailableDayOn(day) {
        return this.getRelation('available_days').get(day.id);
    }

    addAvailableDayOn(days) {
        this.available_days.add(_.isArray(days) ? _.map(days, day => day.toAvailableDay()) : days.toAvailableDay());
    }

    addAvailableDayOnAll(days) {
        this.addAvailableDayOn(days.models);
    }

    removeAvailableDayOn(days) {
        this.getRelation('available_days').remove(_.isArray(days) ? _.pluck(days, 'id') : days.id );
    }

    removeAvailableDayOnAll(days) {
        this.removeAvailableDayOn(days.models)
    }

}

@Collection({
    key: 'company_profile_items',
    model: CompanyProfileItem
})
class CompanyProfileItemCollection extends EventItem.Collection {

    static Relations() {
        return {
            profile: CompanyProfile.Model
        }
    }

    someHaveNoAvailableDay() {
        return this.some(item => item.get('date_dependable') && item.available_days.isEmpty());
    }

}

const PageableCollection = CompanyProfileItemCollection.prototype.PageableCollection();

export {
    CompanyProfileItem as Model,
    CompanyProfileItemCollection as Collection,
    PageableCollection
};
