import _ from 'underscore';
import BaseView from 'app/backbone/components/Base.view';
import Services from 'app/services';

export default class EventsCalendar extends BaseView {

    initialize(options = {}) {
        this.events = options.events || [];
        this.fullCalendarOptions = {
            header: {
                left: 'today',
                center: '',
                right: 'prev title next',
            },
            timeFormat: 'H:mm',
            axisFormat: 'H:mm',
            editable: false,
            schedulerLicenseKey: Services.fullcalendarSchedulerKey
        };
    }

    render() {
        if (!this.rendered) {
            const { events } = this;
            this.$el.fullCalendar(_.extend({}, this.fullCalendarOptions, { events }));
            this.$el.removeClass('hidden');
        }
        this.rendered = true;
    }

}

