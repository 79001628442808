import AccrediteeGrantedAllDaysCell from 'app/backbone/views/event/accreditation/accreditees/items/tables/cells/GrantedAllDaysCell.view';

export default class GrantedAllDaysCell extends AccrediteeGrantedAllDaysCell {

    isDisabled() {
        const grantableDays = this.getGrantableDays();
        return !grantableDays.length
    }

    getGrantableDays() {
        return this.accrediteeProfiles.getAllUniqueGrantedDaysWithAvailableItemDay(this.model);
    }

}

