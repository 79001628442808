import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Field, Form, Formik, FieldArray } from "formik";
import DialogContainer from "../dialogContainer/DialogContainer";
import DialogCard from "../dialogCard/DialogCard";
import DialogCardBottom from "../dialogCard/DialogBottom";
import FormField from "../formfields/FormField";
import MoneyField from "../formfields/MoneyField";
import Button from "../button/Button";
import Checkbox from "../checkbox/Checkbox";

const TicketsValidation = () =>
  yup.object({
    name: yup.string().required(),
    price: yup.number().required(),
    attendeeTypes: yup.mixed().test(
      'one-minimum',
      'Select at least one attendee type',
      (value, context) => {
        let has_selected_one = false;
        value.map((v) => {
          if(v.available) has_selected_one = true;
        })
        return has_selected_one;
      }
    )
  });

const types = [
  { label: "Performer", value: "PERFORMER", available: true },
  { label: "Crew", value: "CREW", available: true },
  { label: "Guest", value: "GUEST", available: true },
  { label: "Artist guest", value: "ARTIST_GUEST", available: true },
];
const TicketsDialog = ({
  addTicket,
  ticket,
  contacts,
  currencyUnit,
  ...props
}) => {
  const mapAttendeeTypes = (typesToMap) => {
    return types.map((type) => ({
      ...type,
      available: typesToMap.includes(type.value),
    }));
  };
  return (
    <DialogContainer>
      <DialogCard {...props}>
        <Formik
          initialValues={{
            id: ticket.id,
            name: ticket.name,
            price: ticket.price.amount,
            notes: ticket.notes,
            attendeeTypes: mapAttendeeTypes(ticket.attendeeTypes),
          }}
          validationSchema={TicketsValidation()}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            const { isValid, values } = formik;

            return (
              <Fragment>
                <div className={"dialog-inner-container"}>
                  <h3 className="dialog-title">
                    {ticket.id ? "Edit ticket type" : "Create ticket type"}
                  </h3>
                  <Form>
                    <Field name="name" label="Name" required as={FormField} />
                    <Field
                      name="price"
                      label="Cost"
                      currencyUnit={currencyUnit}
                      type="number"
                      required
                      as={MoneyField}
                    />
                    {values.attendeeTypes && (
                      <FieldArray name="attendeeTypes">
                        <div className="ticket-dialog__attendee-types">
                          {values.attendeeTypes.map((attendee, index) => (
                            <Field
                              as={Checkbox}
                              name={`attendeeTypes.${index}.available`}
                              id={`attendeeTypes.${index}.available`}
                              label={attendee.label}
                              key={`attendee-${index}`}
                            />
                          ))}
                        </div>
                      </FieldArray>
                    )}
                    <Field name="notes" label="Notes (internal2)">
                      {({ field }) => (
                        <label>
                          <span className="form-field__label">
                            Notes (internal)
                          </span>
                          <textarea
                            className="form__textarea"
                            rows="5"
                            id="notes"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                  </Form>
                </div>
                <DialogCardBottom>
                  <Button
                    label={ticket.id ? "Update" : "Add"}
                    variant="primary"
                    type="button"
                    onClick={() => addTicket({ ...values, id: ticket.id })}
                    isDisabled={!isValid}
                  />
                </DialogCardBottom>
              </Fragment>
            );
          }}
        </Formik>
      </DialogCard>
    </DialogContainer>
  );
};

TicketsDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
  addTicket: PropTypes.func.isRequired,
  ticket: PropTypes.any.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.any),
};

export default TicketsDialog;
