import React from 'react'
import {
    AlertBox,
    Button,
    ConfirmModal,
    InputGroup,
} from 'ui-kit'

const RemoveButton = ({ openModal }) => (
    <Button
        type={Button.DANGER}
        onClick={openModal}
    >
        Remove
    </Button>
);

const RemoveUploadedPassportsModal = ({
    isLoading,
    passwordVerification,
    setPasswordVerification,
    errors,
    onConfirm,
}) => {
    function onChangePasswordVerification(e) {
        setPasswordVerification(e.target.value);
    }

    return (
        <ConfirmModal
            isLoading={isLoading}
            title="Remove uploaded passports"
            renderTrigger={RemoveButton}
            confirmButtonText="Yes, remove"
            onConfirm={onConfirm}
        >
            <AlertBox type={AlertBox.WARNING} hasIconPrefix>
                This action cannot be undone.
            </AlertBox>
            <p className="margin-tiny-bottom">
                <strong>This will remove all passports</strong> that were uploaded to your account, regardless of
                whether they were uploaded by someone of your production team or through the Production website.
                They will disappear from all of your contacts, performers and crew members.
            </p>
            <InputGroup messages={errors.passwordVerification}>
                <label for="passwordVerification">
                    Please type in your password to confirm.
                </label>
                <input
                    required
                    type="password"
                    name="passwordVerification"
                    value={passwordVerification}
                    onChange={onChangePasswordVerification}
                />
            </InputGroup>
        </ConfirmModal>
    );
};

RemoveUploadedPassportsModal.defaultProps = {
    isLoading: false,
    errors: {},
};

export default RemoveUploadedPassportsModal;
