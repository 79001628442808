import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'ui-kit';

const BookingStatusLabel = ({
    title,
    type,
}) => (
    <Badge type={type}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
    </Badge>
);

BookingStatusLabel.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string
};

export default BookingStatusLabel;
