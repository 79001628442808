import React, { Fragment } from "react";
import classNames from "classnames";
import { Field } from "formik";
import TextEditor from "app/react/components/inputs/TextEditor";
import DisableSection from "./components/DisableSection";
import FormField from "../../../formfields/FormField";
import DatePickerField from "../../../datePicker/DatePickerField";

const PickupSection = ({ setFieldValue, sectionId, values }) => {
  return (
    <div className="artist-dialog__section">
      <div className="artist-dialog__section-inner">
        <h3>Pickups</h3>
        <span
          className={classNames([
            "artist-dialog__configure-setup-button",
            "artist-dialog__configure-setup-button--active",
          ])}
        >
          Configure your setup
        </span>
        <div className="artist-dialog__section-inner__content">
          <DisableSection sectionId={sectionId} values={values} />
          <Field
            name={`${sectionId}.name`}
            label="Rename"
            as={FormField}
            disabled={!values.enabled}
          />
          <label>
            Deadline
            <DatePickerField
              value={values.deadline}
              name={`${sectionId}.deadline`}
              onChange={setFieldValue}
              showPopperArrow={false}
              isLeft
              disabled={!values.enabled}
            />
          </label>
          {values.enabled && (
            <Field name={`${sectionId}.description`} label="Intro text">
              {({ field }) => {
                return (
                  <Fragment>
                    <span className={"form-field__label"}>Intro text</span>
                    <TextEditor
                      imageInsertButtonsOverride={[
                        "imageBack",
                        "|",
                        "imageByURL",
                      ]}
                      height={200}
                      onBlur={(e) =>
                        setFieldValue(
                          `${sectionId}.description`,
                          e.target.value
                        )
                      }
                      id={"introText"}
                      value={field.value}
                    />
                  </Fragment>
                );
              }}
            </Field>
          )}
          {!values.enabled && (
            <Field name={`${sectionId}.description`} label="Intro text">
              {({ field }) => {
                return (
                  <Fragment>
                    <span className={"form-field__label"}>Intro text</span>
                    <div style={{ position: "relative", height: 295 }}>
                      <div className="artist-dialog__text-editor--disabled"></div>
                      <TextEditor
                        imageInsertButtonsOverride={[
                          "imageBack",
                          "|",
                          "imageByURL",
                        ]}
                        height={200}
                        id={"introText"}
                        value={field.value}
                      />
                    </div>
                  </Fragment>
                );
              }}
            </Field>
          )}
        </div>
      </div>
    </div>
  );
};

PickupSection.propTypes = {};

export default PickupSection;
