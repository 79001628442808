import CompanyAccrediteeUpdateView from 'app/backbone/views/event/accreditation/companies/accreditees/views/CompanyAccrediteeUpdate.view';

export default class ProductionWebsiteCompanyAccrediteeUpdateView extends CompanyAccrediteeUpdateView {

    backToOverview() {
        const productionWebsiteUrl = window.beatswitch.production_website.slug;
        navigate(`/production-website-accreditation/${productionWebsiteUrl}/company-form`);
    }

}
