import { schema } from 'normalizr';
import * as VehicleType from 'app/react/entities/vehicle_types/schemas';

export const ENTITY_REDUCER_KEY = 'vehicles';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const entityDefinition = {
    type: VehicleType.entity
};

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
export const entities = new schema.Array(entity);
