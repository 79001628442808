import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import gql from 'graphql-tag'
import { notifySuccess } from 'ui-kit'
import { transformGraphQLErrorsToFormErrors } from 'app/react/helpers/_index'
import RemoveUploadedPassportsModalWithState from 'app/react/views/account/settings/account/privacy/components/RemoveUploadedPassportsModalWithState'

const SUCCESS_MESSAGE = 'Uploaded passports are successfully being removed. It may take up to a few minutes to complete.';
const GENERAL_ERROR = 'Uploaded passports could not be removed.';

function formErrors(passwordVerification) {
    if (!passwordVerification) {
        passwordVerification = [{
            type: 'error',
            text: GENERAL_ERROR,
        }];
    }

    return {
        hasErrors: true,
        errors: {
            passwordVerification,
        },
    };
}

const MUTATION = gql`
    mutation DeleteUploadedPassports($accountId: ID!, $input: DeleteUploadedPassportsInput!) {
        deleteUploadedPassports(account: $accountId, input: $input) {
            accountId
        }
    }
`;

const injectMutation = graphql(
    MUTATION, {
        name: 'deleteUploadedPassportsMutation',
    },
);

const mapDispatchToProps = (dispatch, ownProps) => ({
    onRemovePassports: async (input) => {
        const { accountId, deleteUploadedPassportsMutation } = ownProps;

        try {
            await deleteUploadedPassportsMutation({
                variables: { accountId, input },
            });
            notifySuccess(SUCCESS_MESSAGE);

            return {};
        } catch ({ graphQLErrors }) {
            const { passwordVerification } = transformGraphQLErrorsToFormErrors(graphQLErrors);

            return formErrors(passwordVerification);
        }
    }
});

const injectState = connect(
    null,
    mapDispatchToProps,
);

export default compose(
    injectMutation,
    injectState,
)(RemoveUploadedPassportsModalWithState)
