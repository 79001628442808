import _ from 'underscore';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import PeriodDaysTable from 'app/backbone/views/event/settings/accreditation/components/PeriodDaysTable.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import AvailableDayCell from './cells/AvailableDayCell.view';
import CompanyProfileLastRow from './rows/CompanyProfileLastRow.view';
import CompanyProfileFirstRow from './rows/CompanyProfileFirstRow.view';
import CompanyProfileCenterRow from './rows/CompanyProfileCenterRow.view';
import SubTableBody from 'app/backbone/components/tables/bodies/SubTableBody.view';
import * as CompanyProfile from 'app/backbone/models/event/accreditation/companies/CompanyProfile';

export default class AvailableCompanyProfileTable extends PeriodDaysTable {

    optionTypes() {
        return _.extend({}, super.optionTypes(), {
            collection: OptionTypes.instanceOf(CompanyProfile.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            profiles: OptionTypes.instanceOf(Profile.Collection).isRequired,
            items: OptionTypes.instanceOf(EventItem.Collection).isRequired,
            placeholderHeight: OptionTypes.number
        });
    }

    optionDefaults() {
        return _.extend({}, super.optionDefaults(), {
            placeholderHeight: 100
        });
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        this.actions = this.initActions();
        this.placeholderText = this.getPlaceholderText();
        this.firstColumns = this.initFirstColumns();
        this.columns = this.initColumns();
        this.lastColumns = this.initLastColumns();
        this.body = SubTableBody;
        super.initialize(options);
    }

    getFirstColumnsRowClass() {
        return CompanyProfileFirstRow;
    }

    initFirstColumns() {
        return [{
            name: 'name',
            cell: 'string',
            label: 'Profile',
            headerCell: CellFactory.createHeaderCell('310px'),
            type: 'first',
            sortable: false,
            editable: false
        }];
    }

    getCenterColumnsRowClass() {
        return CompanyProfileCenterRow;
    }

    initColumns() {
        return [];
    }

    getLastColumnsRowClass() {
        const { items, periods } = this;
        return CompanyProfileLastRow.extend({ items, periods });
    }

    initLastColumns() {
        const { actions } = this;
        return [{
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(actions),
            sortable: false,
            editable: false
        }];
    }

    initActions() {
        return [{
            name: 'add-item',
            icon: 'bs-icon-plus',
            tooltip: 'Add item',
            event: {
                name: 'backgrid:sub-table:add',
                type: 'model'
            }
        }, {
            name: 'remove',
            icon: 'bs-icon-cross',
            tooltip: 'Remove Profile',
            action: this.removeProfile.bind(this)
        }];
    }

    createDayCell(day, classNamePostfix) {
        const className = `${AvailableDayCell.prototype.className} ${classNamePostfix}`;
        return AvailableDayCell.extend({ day, className });
    }

    removeProfile(profile) {
        this.collection.remove(profile);
    }

    getPlaceholderText() {
        let profiles = this.profiles;
        if(!profiles.isEmpty()) return `No profiles have been added for this company. Add at least one.`;
        let eventId = profiles.event ? profiles.event.id : profiles.first().event.id ;
        let createProfileUrl = `/events/${eventId}/settings/accreditation/profiles`;
        return `No profiles have been created for this event. <a href="${createProfileUrl}">Create your first profile.</a>`;
    }

}
