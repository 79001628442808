// Actions
export const REGISTER_COMPONENT = 'REGISTER_COMPONENT';
export const UNREGISTER_COMPONENT = 'UNREGISTER_COMPONENT';
export const MERGE_COMPONENT_STATE = 'MERGE_COMPONENT_STATE';
export const SET_COMPONENT_STATE = 'SET_COMPONENT_STATE';
export const SET_COMPONENT_STATE_VALUE = 'SET_COMPONENT_STATE_VALUE';
export const MERGE_COMPONENT_STATE_VALUE = 'MERGE_COMPONENT_STATE_VALUE';
export const DELETE_COMPONENT_STATE_VALUE = 'DELETE_COMPONENT_STATE_VALUE';

export const registerComponent = (componentId, componentState) => ({
    type: REGISTER_COMPONENT,
    componentId,
    componentState
});

export const unregisterComponent = (componentId) => ({
    type: UNREGISTER_COMPONENT,
    componentId
});

export const setComponentState = (componentId, componentState) => ({
    type: SET_COMPONENT_STATE,
    componentId,
    componentState
});

export const mergeComponentState = (componentId, componentState) => ({
    type: MERGE_COMPONENT_STATE,
    componentId,
    componentState
});

export const setComponentStateValue = (componentId, path, value) => ({
    type: SET_COMPONENT_STATE_VALUE,
    componentId,
    path,
    value
});

export const mergeComponentStateValue = (componentId, path, value) => ({
    type: MERGE_COMPONENT_STATE_VALUE,
    componentId,
    path,
    value
});

export const deleteComponentStateValue = (componentId, path) => ({
    type: DELETE_COMPONENT_STATE_VALUE,
    componentId,
    path
});
