var _ = require('underscore'),
    Backbone = require('backbone');

var ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin');

var template = require('./ProfileForm');

var ProfileForm = Backbone.View.extend({

    mixins: [
        ValidatedForm,
        Form
    ],

    template,

    profileBindings: {
        '[name=name]': 'name'
    },

    options: [
        'model!',
        'hiddenInputs',
        'disabledInputs'
    ],

    initialize(options) {
        this.setOptions(options);

        // Default Options
        !_.isUndefined(this.hiddenInputs) || (this.hiddenInputs = []);
        !_.isUndefined(this.disabledInputs) || (this.disabledInputs = []);

        this.inputs = new Backbone.ChildViewContainer();
        this.groups = new Backbone.ChildViewContainer();
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.unstickit(this.model);
        this.initForm();
        this.stickit(this.model, this.profileBindings);
        this.renderForm();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                hiddenInputs: this.hiddenInputs,
                placeholderHeight: this.placeholderHeight
            },
            model: this.model.attributes
        };
    },

    initForm() {
        this.disableInputs(this.disabledInputs);

        if (this.isAvailable('name')) this.groups.add(this.initValidationGroup('name', this.model), 'name');
    },

    renderForm() {
        this.inputs.call('render');
        this.groups.call('render');
    }

});

module.exports = ProfileForm;
