const ANONYMOUS = '<<anonymous>>';

const OptionTypes = {
    array: createPrimitiveTypeChecker('array'),
    bool: createPrimitiveTypeChecker('boolean'),
    func: createPrimitiveTypeChecker('function'),
    number: createPrimitiveTypeChecker('number'),
    object: createPrimitiveTypeChecker('object'),
    string: createPrimitiveTypeChecker('string'),

    any: createAnyTypeChecker(),
    instanceOf: createInstanceTypeChecker,
    oneOf: createEnumTypeChecker,
    oneOfType: createUnionTypeChecker,
    arrayOf: createArrayOfTypeChecker,
    objectWithShape: createShapeTypeChecker
};

function createChainableTypeChecker(validate) {
    function checkType(isRequired, options, optionName, view) {
        const viewName = getClassName(view);
        if (options[optionName] == null) { // Double eq is required for null || undefined check
            if (isRequired) throw new Error(`Required option \`${optionName}\` was not specified in \`${viewName}\`.`);
            return null;
        }
        return validate(options, optionName, viewName);
    }

    const chainedCheckType = checkType.bind(null, false);
    chainedCheckType.isRequired = checkType.bind(null, true);

    return chainedCheckType;
}

function createPrimitiveTypeChecker(expectedType) {
    function validate(options, optionName, viewName) {
        const optionValue = options[optionName];
        const optionType = getOptionType(optionValue);
        if (optionType !== expectedType) {
            const preciseType = getPreciseType(optionValue);
            throw new Error(
                `Invalid option \`${optionName}\` of type \`${preciseType}\` ` +
                `supplied to \`${viewName}\`, expected \`${expectedType}\`.`
            );
        }
        return null;
    }
    return createChainableTypeChecker(validate);
}


function createAnyTypeChecker() {
    return createChainableTypeChecker(() => null);
}

function createInstanceTypeChecker(expectedClass) {
    function validate(options, optionName, viewName) {
        if (!(options[optionName] instanceof expectedClass)) {
            const expectedClassName = expectedClass.name || ANONYMOUS;
            const actualClassName = getClassName(options[optionName]);
            throw new Error(
                `Invalid option \`${optionName}\` of type \`${actualClassName}\` ` +
                `supplied to \`${viewName}\`, expected instance of \`${expectedClassName}\`.`
            );
        }
        return null;
    }
    return createChainableTypeChecker(validate);
}

function createEnumTypeChecker(expectedValues) {
    if (!Array.isArray(expectedValues)) {
        return createChainableTypeChecker(() => {
            throw new Error(
                `Invalid argument supplied to oneOf, expected an instance of array.`
            );
        });
    }

    function validate(options, optionName, viewName) {
        const optionValue = options[optionName];
        for (let i = 0; i < expectedValues.length; i++) {
            if (is(optionValue, expectedValues[i])) {
                return null;
            }
        }

        const valuesString = JSON.stringify(expectedValues);
        throw new Error(
            `Invalid option \`${optionName}\` of value \`${optionValue}\` ` +
            `supplied to \`${viewName}\`, expected one of ${valuesString}.`
        );
    }
    return createChainableTypeChecker(validate);
}

function createUnionTypeChecker(arrayOfTypeCheckers) {
    if (!Array.isArray(arrayOfTypeCheckers)) {
        return createChainableTypeChecker(() => {
            throw new Error(
                'Invalid argument supplied to oneOfType, expected an instance of array.'
            );
        });
    }

    function validate(options, optionName, viewName) {
        const errors = [];
        for (let i = 0; i < arrayOfTypeCheckers.length; i++) {
            const checker = arrayOfTypeCheckers[i];
            try {
                checker(options, optionName, viewName);
            } catch (newError) {
                errors.push(newError);
                if (errors.length === arrayOfTypeCheckers.length) {
                    throw new Error(
                        `Invalid \`${optionName}\` supplied to \`${viewName}\`. ` +
                        `${errors.map(error => `${error.message}`).join('')}`
                    );
                }
            }
        }
    }
    return createChainableTypeChecker(validate);
}

function createArrayOfTypeChecker(typeChecker) {
    function validate(options, optionName, viewName) {
        if (typeof typeChecker !== 'function') {
            throw new Error(
                `Property \`${optionName}\` of component \`${viewName}\` has invalid OptionType notation inside arrayOf.`
            );
        }
        const optionValue = options[optionName];
        if (!Array.isArray(optionValue)) {
            const optionType = getPropType(optionValue);
            throw new Error(
                `Invalid option \`${optionName}\` of type \`${optionType}\` supplied to \`${viewName}\`, expected an array.`
            );
        }
        for (let i = 0; i < optionValue.length; i++) {
            typeChecker(optionValue, i, viewName);
        }
        return null;
    }
    return createChainableTypeChecker(validate);
}

function createShapeTypeChecker(shapeTypes) {
    function validate(options, optionName, viewName) {
        const optionValue = options[optionName];
        const optionType = getPropType(optionValue);
        if (optionType !== 'object') {
            throw new Error(
                `Invalid option \`${optionName}\` of type \`${optionValue}\` ` +
                `supplied to \`${viewName}\`, expected \`object\`.`
            );
        }
        for (const key in shapeTypes) {
            const checker = shapeTypes[key];
            if (!checker) continue;
            checker(optionValue, key, viewName);
        }
        return null;
    }
    return createChainableTypeChecker(validate);
}

function getOptionType(optionValue) {
    const optionType = typeof optionValue;
    if (Array.isArray(optionValue)) return 'array';
    if (optionValue instanceof RegExp) return 'object';
    return optionType;
}

function getPropType(propValue) {
    const propType = typeof propValue;
    if (Array.isArray(propValue)) return 'array';
    if (propValue instanceof RegExp) return 'object';
    return propType;
}

function getPreciseType(propValue) {
    const propType = getPropType(propValue);
    if (propType === 'object') {
        if (propValue instanceof Date) return 'date';
        else if (propValue instanceof RegExp) return 'regexp';
    }
    return propType;
}

function getClassName(propValue) {
    if (!propValue.constructor || !propValue.constructor.name) return ANONYMOUS;
    return propValue.constructor.name;
}

/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
/* eslint-disable no-self-compare */
function is(x, y) {
    // SameValue algorithm
    if (x === y) { // Steps 1-5, 7-10
        // Steps 6.b-6.e: +0 != -0
        return x !== 0 || 1 / x === 1 / y;
    } else {
        // Step 6.a: NaN == NaN
        return x !== x && y !== y;
    }
}
/* eslint-enable no-self-compare */

module.exports = OptionTypes;
