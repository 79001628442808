import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as actions from 'app/react/state/actions/_index';
import * as Event from 'app/react/entities/events/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Settings from 'app/react/entities/events/production_website/settings/artists/index';
import SectionController from './FormSectionsController';
import FormOverview, { SETTINGS_SECTIONS_FORM_ID } from 'app/react/views/event/production_website/artists/artist_types/booking_form/views/OverviewContainer';
import FormEditIntro from 'app/react/views/event/production_website/artists/artist_types/booking_form/views/EditIntroContainer';
import * as Routes from './routes'

export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new SectionController({ parent: this })
        ];
        // Routes
        this.router.route(Routes.form(), null, this.artistTypesEnabledForm.bind(this));
        this.router.route(Routes.formIntro(), null, this.artistTypesEnabledFormIntro.bind(this));
        this.router.route(Routes.form(false), null, this.artistTypesDisabledForm.bind(this));
        this.router.route(Routes.formIntro(false), null, this.artistTypesDisabledFormIntro.bind(this));
    }

    artistTypeForm(eventId, artistTypeId, Component) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(Component, { store }));
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(ArtistType.actions.setCurrentId(parseInt(artistTypeId, 10)));

        return Promise.all([
            dispatch(ArtistType.thunks.fetchAllForCurrentContext()),
            dispatch(Settings.thunks.fetchCurrent())
        ]).then(() => {
            const values = Settings.stateHelpers.getCurrentWithSections(store.getState());
            const errors = {};
            dispatch(actions.registerComponent(SETTINGS_SECTIONS_FORM_ID, { values, errors }));
        });
    }

    form(eventId, Component) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(Component, { store }));
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return dispatch(ArtistType.thunks.fetchAllForCurrentContext()).then(() => {
            dispatch(ArtistType.thunks.setFirstAsCurrentId());
            return dispatch(Settings.thunks.fetchCurrent())
                .then(() => {
                    const values = Settings.stateHelpers.getCurrentWithSections(store.getState());
                    const errors = {};
                    dispatch(actions.registerComponent(SETTINGS_SECTIONS_FORM_ID, { values, errors }));
                });
        });
    }

    artistTypesEnabledForm(eventId, artistTypeId) {
        this.artistTypeForm(eventId, artistTypeId, FormOverview);
    }

    artistTypesEnabledFormIntro(eventId, artistTypeId) {
        this.artistTypeForm(eventId, artistTypeId, FormEditIntro);
    }

    artistTypesDisabledForm(eventId) {
        this.form(eventId, FormOverview);
    }

    artistTypesDisabledFormIntro(eventId) {
        this.form(eventId, FormEditIntro);
    }

}

