import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import gql from 'graphql-tag'
import * as Event from 'app/react/entities/events'
import SearchSelectUsersWithState from 'app/react/views/event/users/SearchSelectUsersWithState'

const EVENT_USERS_QUERY = gql`
    query getEventUsers($eventId: ID!, $first: Int) {
        event(id: $eventId) {
            users(first: $first) {
                edges {
                    node {
                        id
                        firstName
                        lastName
                        email
                        avatarUrl
                    }
                }
            }
        }
    }
`;

const mapStateToProps = (state) => ({
    first: 50,
    eventId: Event.stateHelpers.getCurrentId(state),
});

const injectState = connect(mapStateToProps);

const injectData = graphql(EVENT_USERS_QUERY);

export default compose(
    injectState,
    injectData,
)(SearchSelectUsersWithState);
