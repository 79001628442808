export const base = (
    eventId = ':event'
) => `events/${eventId}/production-website/artists`;

export const artistTypes = (
    eventId = ':event'
) => `${base(eventId)}/artist-types`;

export const form = (
    isArtistTypesEnabled = true,
    eventId = ':event',
    artistTypeId = ':artistType'
) => (
    isArtistTypesEnabled ?
        `${base(eventId)}/artist-types/${artistTypeId}/form` :
        `${base(eventId)}/form`
);

export const formIntro = (
    isArtistTypesEnabled,
    eventId,
    artistTypeId
) => `${form(isArtistTypesEnabled, eventId, artistTypeId)}/intro/edit`;

export const formSection = (
    isArtistTypesEnabled,
    eventId,
    artistTypeId,
    sectionId = ':section'
) => `${form(isArtistTypesEnabled, eventId, artistTypeId)}/${sectionId}/edit`;

export const formSectionFormBuilder = (
    isArtistTypesEnabled = true,
    eventId = ':event',
    artistTypeId = ':artistType',
    sectionId = ':section'
) => `${formSection(isArtistTypesEnabled, eventId, artistTypeId, sectionId)}/form-builder`;
