import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import * as actions from 'app/react/state/actions/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as IntegrationEvent from 'app/react/entities/accounts/integrations/events/index';
import * as Image from 'app/react/entities/images/index';
import * as TicketProvider from 'app/react/entities/events/accreditation/tickets/provider/index';
import * as TicketSettings from 'app/react/entities/events/accreditation/tickets/settings/index';
import * as Profile from 'app/react/entities/events/accreditation/profiles/index';

export const FORM_ID = 'ticketSettingsForm';
export const CONFIRM_MODAL_ID = 'ticketSettingsConfirmModal';
export const INPUT_FIELD_MAX_WIDTH = 500;
export const NONE_OPTION_VALUE = 'none';
export const FAILED_TO_UPDATE_POPUP = 'Failed to update ticket settings.';
export const SUCCFULL_UPDATE_POPUP = 'Ticket settings successfully updated.';
export const TICKET_PROVIDER_NONE_OPTION = {
    value: NONE_OPTION_VALUE,
    label: 'None',
    completed: false
};
const INTEGRATION_EVENT_PROFILE_NONE_OPTION = {
    value: NONE_OPTION_VALUE,
    label: 'None',
    available: true
};

import TicketSettingsView from './TicketSettingsView';

export const transformTicketSettingsProfilesToFormProfiles = (profiles = []) => {
    const mapping = {};
    profiles.forEach(({ id, days: [{ integrationProfile }] }) => {
        mapping[id] = integrationProfile || NONE_OPTION_VALUE;
    });
    return mapping;
};

export const transformFormProfilesToTicketSettingsProfiles = (mapping = {}, profiles = []) => {
    const clonedProfiles = cloneDeep(profiles);
    clonedProfiles.forEach(({ id, days }) => {
        const selectedIntegrationProfile = mapping[id];
        if (typeof selectedIntegrationProfile !== 'undefined') {
            days.forEach((day) => {
                if (selectedIntegrationProfile === NONE_OPTION_VALUE) {
                    day.integrationProfile = null;
                } else {
                    day.integrationProfile = selectedIntegrationProfile;
                }
            });
        }
    });
    return clonedProfiles;
};

export const resetFormProfiles = (profiles = []) => {
    const mapping = {};
    profiles.forEach(({ id }) => {
        mapping[id] = NONE_OPTION_VALUE;
    });
    return mapping;
};

const transformSelectedProviderId = (value) => {
    let finalValue;
    if (value === NONE_OPTION_VALUE) finalValue = null;
    else finalValue = parseInt(value, 10);
    return finalValue;
};

const mapStateToProps = (state) => {
    const formState = stateHelpers.getComponentState(state, FORM_ID);
    const confirmModalState = stateHelpers.getComponentState(state, CONFIRM_MODAL_ID) || { isOpened: true };
    const formValues = formState.values || {};

    const selectedTicketProviderId = formValues.ticketProvider;
    const selectedTicketProvider = TicketProvider.stateHelpers.getOne(state, selectedTicketProviderId) || {};
    const selectedTicketBackgroundUuid = formValues.ticketBackground;
    const selectedTicketBackground = Image.stateHelpers.getOne(state, selectedTicketBackgroundUuid);
    const selectedIntegrationEventId = formValues.integrationEvent;
    const selectedIntegrationEvent = IntegrationEvent.stateHelpers.getOne(state, selectedIntegrationEventId);
    const selectedProfilesMapping = formValues.profiles;
    const confirmModalOpened = confirmModalState.isOpened;
    const confirmModalAction = confirmModalState.action;

    const ticketSettings =
        TicketSettings.stateHelpers.getOne(state) || {};
    const ticketProviders
        = TicketProvider.stateHelpers.getAll(state);
    const integrationEvents
        = IntegrationEvent.stateHelpers.getAllByPaginationByTicketProviderId(state, selectedTicketProviderId);
    const integrationEventProfiles
        = IntegrationEvent.stateHelpers.getProfilesByEventIntegrationId(state, selectedIntegrationEventId);

    const isLocked = ticketSettings.locked
    const isFetchingTicketSettings =
        TicketSettings.stateHelpers.isFetching(state);
    const isFetchingAccreditationProfiles =
        Profile.stateHelpers.isFetchingAllForCurrentContext(state);
    const isFetchingTicketProviders =
        TicketProvider.stateHelpers.isFetching(state);
    const isFetchingIntegrationEvents =
        IntegrationEvent.stateHelpers.isFetchingAllByTicketProviderId(state, selectedTicketProviderId);

    const savedTicketSettingsProfiles = ticketSettings.profiles;
    const savedTicketProviderId = ticketSettings.ticketProvider;
    const savedTicketProvider = TicketProvider.stateHelpers.getOne(state, savedTicketProviderId) || {};

    const hasSavedTicketProviderThatUsesIntegration = !! savedTicketProvider.usesIntegration;
    const hasSelectedTicketProviderThatUsesIntegration = !! selectedTicketProvider.usesIntegration;

    const ticketProviderOptions
        = ticketProviders.map(({ id, name }) => ({ value: id, label: name }));
    const integrationEventOptions
        = integrationEvents.map(({ id, name, completed }) => ({ value: id, label: name, completed }));
    const integrationEventProfileOptions
        = integrationEventProfiles.map(({ id, name, available }) => ({ value: id, label: name, available }));
    ticketProviderOptions.unshift(TICKET_PROVIDER_NONE_OPTION);
    integrationEventProfileOptions.unshift(INTEGRATION_EVENT_PROFILE_NONE_OPTION);

    return {
        eventId: stateHelpers.events.getCurrentId(state),
        isFetching: (
            (
                isFetchingTicketSettings ||
                isFetchingAccreditationProfiles ||
                isFetchingTicketProviders
            ) ||
            (
                hasSavedTicketProviderThatUsesIntegration &&
                isFetchingIntegrationEvents
            )
        ),
        isFetchingIntegrationEvents,

        ticketProviders, // we need this in mergeProps to determine if selected ticked provider uses integration
        ticketProviderOptions,
        integrationEventOptions,
        integrationEventProfileOptions,

        savedTicketSettingsProfiles,

        hasSelectedTicketProviderThatUsesIntegration,
        isLocked,
        confirmModalOpened,
        confirmModalAction,
        selectedTicketProvider,
        selectedTicketBackground,
        selectedIntegrationEvent,
        selectedProfilesMapping
    };
};

const mapDispatchToProps = (dispatch) => {
    const setTicketProvider = (ticketProviderId, usesIntegration = false) => {
        const finalId = transformSelectedProviderId(ticketProviderId);
        dispatch(actions.updateFormComponentStateValue(FORM_ID, 'ticketProvider', finalId));
        if (usesIntegration) {
            dispatch(IntegrationEvent.actions.loadAllByTicketProviderId(finalId)).then(() => {
                // After fetching the ticket providers,
                // we still need to set the first integration Event as the selected event
                dispatch((dispatchThunk, getState) => {
                    const state = getState();
                    const integrationEvents
                        = IntegrationEvent.stateHelpers.getAllByPaginationByTicketProviderId(state, ticketProviderId);
                    const firstIntegrationEvent = integrationEvents[0] || {};
                    dispatchThunk(
                        actions.updateFormComponentStateValue(
                            FORM_ID,
                            'integrationEvent',
                            firstIntegrationEvent.id
                        )
                    );
                });
            });
        }
    };
    const setTicketBackground = (newTicketBackground = {}) => {
        dispatch(actions.updateFormComponentStateValue(FORM_ID, 'ticketBackground', newTicketBackground.uuid));
    };

    const addTicketBackground = (newTicketBackground = {}) => {
        dispatch(Image.actions.addImage(newTicketBackground));
    };
    const setIntegrationEvent = (integrationEventId, savedTicketSettingsProfiles = []) => {
        dispatch(actions.updateFormComponentStateValue(FORM_ID, 'integrationEvent', integrationEventId));
        const profiles = resetFormProfiles(savedTicketSettingsProfiles);
        dispatch(actions.updateFormComponentStateValue(FORM_ID, 'profiles', profiles));
    };
    const setIntegrationEventProfile = (profileId, integrationEventProfileId, selectedProfilesMapping) => {
        dispatch(actions.updateFormComponentStateValue(FORM_ID, 'profiles', {
            ...selectedProfilesMapping,
            [profileId]: integrationEventProfileId
        }));
    };
    const onConfirmModalConfirmed = (actionsAfterConfirm) => {
        dispatch(actions.closeModal(CONFIRM_MODAL_ID));
        dispatch(actionsAfterConfirm)
            .then(({ error }) => {
                if (error) {
                    dispatch(actions.showErrorPopup(FAILED_TO_UPDATE_POPUP));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCFULL_UPDATE_POPUP));
                }
            });
    };
    const onConfirmModalClosed = () => {
        dispatch(actions.closeModal(CONFIRM_MODAL_ID));
    };
    const updateTicketSettings = (
        selectedTicketProvider = {},
        selectedTicketBackground = {},
        selectedIntegrationEvent = {},
        selectedProfilesMapping = {},
        savedTicketSettingsProfiles = []
    ) => {
        const ticketProviderId = selectedTicketProvider.id || null;
        const selectedTicketBackgroundUuid = selectedTicketBackground.uuid || null;
        let newProfiles;
        let integrationEventId;
        if (selectedTicketProvider.usesIntegration) {
            newProfiles =
                transformFormProfilesToTicketSettingsProfiles(
                    selectedProfilesMapping,
                    savedTicketSettingsProfiles
                );
            integrationEventId = selectedIntegrationEvent.id;
        }
        const actionAfterConfirm = TicketSettings.actions.editOne({
            ticketProvider: ticketProviderId,
            ticketBackground: selectedTicketBackgroundUuid,
            integrationEvent: integrationEventId,
            profiles: newProfiles
        });
        dispatch(actions.openConfirmModal(CONFIRM_MODAL_ID, actionAfterConfirm));
    };

    return {
        onConfirmModalConfirmed,
        onConfirmModalClosed,
        setTicketProvider,
        setTicketBackground,
        addTicketBackground,
        setIntegrationEvent,
        setIntegrationEventProfile,
        updateTicketSettings
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {
        ticketProviders,
        selectedTicketProvider,
        selectedTicketBackground,
        selectedIntegrationEvent,
        selectedProfilesMapping,
        savedTicketSettingsProfiles
    } = stateProps;
    const {
        setTicketProvider,
        setIntegrationEvent,
        setIntegrationEventProfile,
        updateTicketSettings
    } = dispatchProps;

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onConfirmModalConfirmed: () => dispatchProps.onConfirmModalConfirmed(stateProps.confirmModalAction),
        setIntegrationEvent: (integrationEventId) => setIntegrationEvent(
            integrationEventId,
            savedTicketSettingsProfiles
        ),
        setTicketProvider: (ticketProviderId) => {
            const ticketProvider = ticketProviders.find(({ id }) => id === parseInt(ticketProviderId, 10)) || {};
            return setTicketProvider(ticketProviderId, !! ticketProvider.usesIntegration);
        },
        setIntegrationEventProfile: (profileId, integrationEventProfileId) => setIntegrationEventProfile(
            profileId,
            integrationEventProfileId,
            selectedProfilesMapping
        ),
        updateTicketSettings: () => updateTicketSettings(
            selectedTicketProvider,
            selectedTicketBackground,
            selectedIntegrationEvent,
            selectedProfilesMapping,
            savedTicketSettingsProfiles
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TicketSettingsView);
