export const base = (
    eventId = ':event'
) => `events/${eventId}/settings/artists`;

export const itineraryContactsCreate = (
    eventId = ':event'
) => `${base(eventId)}/itinerary-contacts/create`;

export const items = (
    eventId = ':event'
) => `${base(eventId)}/items`;

export const ticketTypes = (
    eventId = ':event'
) => `${base(eventId)}/tickets`;

export const locations = (
    eventId = ':event'
) => `${base(eventId)}/locations`;

export const locationsCreate = (
    eventId = ':event'
) => `${base(eventId)}/locations/create`;

export const locationsCreateRoute = (
    eventId = ':event',
    locationId = ':location'
) => `${base(eventId)}/locations/${locationId}/routes/create`;

export const accommodations = (
    eventId = ':event'
) => `${base(eventId)}/accommodations`;

export const accommodationsCreate = (
    eventId = ':event'
) => `${base(eventId)}/accommodations/create`;

export const roomTypesCreate = (
    eventId = ':event',
    accommodationId = ':accommodation'
) => `${base(eventId)}/accommodations/${accommodationId}/room-types/create`;
