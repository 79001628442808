import SubModelCell from 'app/backbone/components/tables/cells/SubModelCell.view';

var RequestStatusCell = SubModelCell.extend({

    className: 'sub-model-cell center',

    path: 'request_status',

    hasLabel: true,

    viewModel() {
        let { hasLabel, labelClassName } = this;
        labelClassName = this.getLabelClassName();
        return {
            view: { hasLabel, labelClassName },
            attribute: this.getSubModel().get(this.column.get('name'))
        }
    },

    getLabelClassName() {
        if(this.model.isCreated()) return '';
        if(this.model.isLoginSent()) return '';
        if(this.model.isAwaitingApproval()) return 'warning';
        if(this.model.isApproved()) return 'success';
        if(this.model.isDenied()) return 'alert';
        if(this.model.areTicketsSent()) return 'success';
        return '';
    }

});

module.exports = RequestStatusCell;
