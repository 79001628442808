import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Event from 'app/react/entities/events/index';
import * as EventItem from 'app/react/entities/events/artists/items/index';
import * as EventItemGroup from 'app/react/entities/events/artists/item_groups/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses';
import * as BookingItem from 'app/react/entities/events/bookings/items/index';
import BookingItemsOverview from 'app/react/views/event/artists/bookings/items/views/OverviewContainer';

export const overviewRoute = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}/items`;

export default class BookingItemsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(overviewRoute(), null, this.overview.bind(this));
    }

    overview(eventId, bookingId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(BookingItemsOverview, { store }));
        // Set the working context
        dispatch(Event.actions.setCurrentId(eventId));
        dispatch(Booking.actions.setCurrentId(bookingId));

        return Promise.all([
            dispatch(Booking.thunks.fetchCurrent()),
            dispatch(EventItem.thunks.fetchAllForCurrentContext()),
            dispatch(EventItemGroup.thunks.fetchAllForCurrentContext()),
            dispatch(BookingItem.thunks.fetchAllForCurrentContext()),
            dispatch(BookingStatus.thunks.fetchAllForCurrentContext()),
        ])
    }

}
