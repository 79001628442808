import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';
import ItemGroupTable from '../tables/ItemGroupTable.view';
import ItemGroupModalCreate from '../modals/ItemGroupModalCreate.view';

export default class ItemGroupsOverviewCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            itemGroups: OptionTypes.instanceOf(ItemGroup.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options)
        // Subviews
        this.createModal = this.initCreateModal();
    }

    initContent() {
        return this.initTable();
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add item group',
            icon: 'bs-icon-plus',
            action: this.createItemGroup.bind(this)
        }];
    }

    initTable() {
        const { itemGroups } = this;
        return new ItemGroupTable({ itemGroups });
    }

    initCreateModal() {
        const createModal = new ItemGroupModalCreate();
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    onCreated() {
        this.itemGroups.fetch({ bustCache: true });
    }

    createItemGroup() {
        const account = this.itemGroups.getRelation('account');
        this.createModal.open(null, { account });
    }

}
