import { Model, Collection }  from 'app/backbone/models/decorators';
import * as Day from '../../Day';

@Model({
    key: 'granted_day'
})
class GrantedDay extends Day.Model {

    get defaults() {
        return {
            amount: 0
        }
    }

    get validation() {
        return {
            id: {
                required: true,
                msg: 'Select a day.'
            }
        }
    }

    initialize(model, options = {}) {
        //no op remove after Day Model is refactored
    }

    parse(response) {
        response = super.parse(response);
        let parsed = {};
        parsed.id = response.id;
        parsed.name = response.name;
        parsed.amount = response.amount;
        return parsed;
    }

    toJSON(options) {
        let json = {};
        json.id = this.get('id');
        json.name = this.get('name');
        if (this.get('amount') !== 0) json.amount = this.get('amount');
        return json;
    }
}

@Collection({
    key: 'granted_days',
    model: GrantedDay
})
class GrantedDayCollection extends Day.Collection {}

const PageableCollection = GrantedDayCollection.prototype.PageableCollection();

export {
    GrantedDay as Model,
    GrantedDayCollection as Collection,
    PageableCollection
};
