import { pick } from 'underscore';
import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';
import * as Performance from 'app/react/entities/events/performances/index';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import { reformatPerformanceFormValues } from '../../utils';
import CreateModal, { CREATE_MODAL_ID } from './CreateModal';

export const POPUP_MESSAGES = {
    FAILED_TO_CREATE: 'Failed to create performance.',
    SUCCESSFUL_CREATE: (performanceName) =>
        `Performance <strong>${performanceName}</strong> successfully created.`,
};

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSyncing,
        isCreatingNewArtist,
        isCreatingNewBooing,
        isEditingPerformanceName,
        hasSelectedExistingArtist,
        values = {},
        errors = {},
        createSuccessCallback
    } = stateHelpers.getComponentState(state, CREATE_MODAL_ID);
    return {
        isOpened,
        isSyncing,
        isCreatingNewArtist,
        isCreatingNewBooing,
        isEditingPerformanceName,
        hasSelectedExistingArtist,

        values,
        errors,
        createSuccessCallback,

        eventId: stateHelpers.events.getCurrentId(state),
        accountId: stateHelpers.accounts.getCurrentId(state),
        dateFormat: stateHelpers.events.getCurrentDateFormat(state),
        timeFormat: stateHelpers.events.getCurrentTimeFormat(state),
        isArtistTypesEnabled: stateHelpers.events.isArtistTypesEnabledForCurrent(state),

        isFetchingBookingsForSelectedArtist: Booking.stateHelpers.isFetchingAllUnassignedForCurrentEventByArtistId(state, values.artist),
        bookingsForSelectedArtist: Booking.stateHelpers.denormalizeAllUnassignedByPaginationForCurrentEventByArtistId(state, values.artist),

        artists: Artist.stateHelpers.getAll(state),
        artistTypes: ArtistType.stateHelpers.getAllForCurrentContext(state),
        bookingTypes: BookingType.stateHelpers.getAll(state),
        bookingStatuses: BookingStatus.stateHelpers.getAllForCurrentContext(state)
    };
};

const reformatBookingFormValues = (values) => {
    const artistValue = values.artist;
    const bookingValue = values.booking;

    if (
        typeof artistValue === 'undefined' ||
        artistValue === ''
    ) {
        return {};
    }
    const artist = typeof artistValue === 'number' ?
        { artist: artistValue } :
        { artist: { name: artistValue } };

    let booking = {};
    if (typeof bookingValue !== 'undefined') {
        booking = { id: bookingValue };
    }
    return {
        bookings: [{
            ...booking,
            ...artist,
            artistType: values.artistType,
            type: values.bookingType,
            status: values.bookingStatus
        }]
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch(actions.closeConfirmModal(CREATE_MODAL_ID));
        dispatch(actions.deleteComponentStateValue(CREATE_MODAL_ID, 'errors'));
    },
    onSaved: (eventId, values, isEditingPerformanceName, successCallback) => {
        if (!Performance.isValid(values)) {
            const errors = Performance.getValidationErrors(values);
            dispatch(actions.mergeComponentState(CREATE_MODAL_ID, { errors }));
        } else {
            dispatch(actions.setModalSyncing(CREATE_MODAL_ID, true));
            const formattedValues = {
                ...reformatPerformanceFormValues(values),
                ...reformatBookingFormValues(values)
            };
            const apiKeys = ['bookings', 'day', 'stage', 'start', 'end', 'changeover', 'publishDate'];
            if (isEditingPerformanceName) apiKeys.push('title');
            const apiValues = pick(formattedValues, apiKeys);
            dispatch(Performance.actions.createOne(eventId, apiValues))
                .then(({ error, response }) => {
                    if (error) {
                        const errors = getFormFieldErrorsFromResponseJSON(error);
                        dispatch(actions.mergeComponentState(CREATE_MODAL_ID, { errors }));
                        dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED_TO_CREATE));
                    } else {
                        dispatch(actions.closeModal(CREATE_MODAL_ID));
                        dispatch(actions.showSuccessPopup(POPUP_MESSAGES.SUCCESSFUL_CREATE(values.title)));
                        dispatch(actions.mergeComponentState(CREATE_MODAL_ID, { errors: {} }));
                        successCallback(Performance.stateHelpers.getOne(response, response.result));
                    }
                    dispatch(actions.setModalSyncing(CREATE_MODAL_ID, false));
                });
        }
    },
    onChangeValue: (key, value) => {
        dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, `values.${key}`, value));
        if (key === 'artist') {
            dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'hasSelectedExistingArtist', false));
            dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'values.title', value));
        }
    },
    onSelectArtist: (accountId, eventId, artistId, artistName) => {
        dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'values.artist', artistId));
        if (typeof artistId === 'undefined') {
            dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'hasSelectedExistingArtist', false));
            dispatch(actions.deleteComponentStateValue(CREATE_MODAL_ID, 'values.title'));
        } else {
            dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'hasSelectedExistingArtist', true));
            dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'values.title', artistName));
            dispatch(Booking.actions.fetchAllUnassignedByArtistId(accountId, eventId, artistId));
        }
    },
    onToggleCreateNewArtist: (isCreating = false) => {
        dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'isCreatingNewArtist', isCreating));
        if (isCreating) {
            dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'isCreatingNewBooing', true));
        }
        dispatch(actions.deleteComponentStateValue(CREATE_MODAL_ID, 'values.artist'));
        dispatch(actions.deleteComponentStateValue(CREATE_MODAL_ID, 'values.title'));
        dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'hasSelectedExistingArtist', false));
    },
    onToggleCreateNewBooking: (isCreating = false) => {
        dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'isCreatingNewBooing', isCreating));
    },
    onToggleEditPerformanceName: (isEditing = false, artistName) => {
        dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'isEditingPerformanceName', isEditing));
        dispatch(actions.setComponentStateValue(CREATE_MODAL_ID, 'values.title', artistName));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSaved: () => {
        const { values, eventId, isEditingPerformanceName, createSuccessCallback } = stateProps;
        dispatchProps.onSaved(eventId, values, isEditingPerformanceName, createSuccessCallback);
    },
    onSelectArtist: (artistId) => {
        const { accountId, eventId, artists } = stateProps;
        const artist = artists.find(({ id }) => id === artistId);
        dispatchProps.onSelectArtist(accountId, eventId, artistId, artist && artist.name);
    },
    onToggleEditPerformanceName: (isEditing) => {
        const { values, artists } = stateProps;
        const artistValue = values.artist;
        const artistName = typeof artistValue === 'number' ?
            artists.find(artist => artist.id === artistValue).name :
            artistValue;
        dispatchProps.onToggleEditPerformanceName(isEditing, artistName);
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(CreateModal);
