import styled from 'styled-components';

export const GroundTransport = styled.i`
    display: inline-block;
    transform: scaleX(-1) translate(0, -2px);
`;

export const Inbound = styled.i`
    display: inline-block;
    transform: rotate(25deg);
`;

export const Outbound = styled.i`
    display: inline-block;
    transform: rotate(-25deg);
`;
