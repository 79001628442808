import React from 'react'
import classNames from 'classnames'
import './ReservationStatusIcon.module.css'

const ReservationStatusIcon = ({ requestStatus, className }) => (
    <i
        styleName={classNames('statusIcon', requestStatus.COLOR_TYPE)}
        className={classNames(requestStatus.ICON, className)}
    />
);

export default ReservationStatusIcon;
