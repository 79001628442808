import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/button/Button';
import CreateVenueDialog from '../atoms/dialogs/CreateVenueDialog';
import { useHTTP } from '../../hooks/UseHTTP';
import Footer from '../footer/Footer';
import FeedbackMessage from '../atoms/feedback/Feedback';

export const VenueInformationForm = ({
	setActiveStep,
	activeSubStep,
	eventState,
	setVenues,
}) => {
	const [venues, setLocalVenues] = useState(eventState.venues);

	const [isCreatingVenue, setIsCreatingVenue] = useState(false);

	const [venueCreated, setVenueCreated] = useState(false);
	const [venueCreatedError, setVenueCreatedErrors] = useState(false);

	const [selectedVenue, setSelectedVenue] = useState(
		eventState.event.venue ? eventState.event.venue : ''
	);

	const { doHttpCall } = useHTTP();

	const createVenue = async (body) => {
		doHttpCall(`/api/accounts/${eventState.account.id}/venues`, 'POST', body)
			.then((venueRes) => {
				if (venueRes && venueRes.data) {
					setVenues({...venueRes.data, address: venueRes.data.address.data});
					setLocalVenues((prev) => {
						return [...prev, {...venueRes.data, address: venueRes.data.address.data}];
					});
					setSelectedVenue(venueRes.data.id);
					setIsCreatingVenue(false);
					setVenueCreated(true);
					document.getElementById('venue-select').value = venueRes.data.id;
				}
			})
			.catch((err) => {
				console.error('Create venue error:: ', err);
				setVenueCreatedErrors(true);
			});
	};

	const setVenue = () => {
		setSelectedVenue(document.getElementById('venue-select').value);
	};

	return (
		<div className='form_content'>
			{venueCreated && <FeedbackMessage variant="success" text="Venue created!" closeFeedback={() => setVenueCreated(false)} />}
			{venueCreatedError && <FeedbackMessage variant="error" text="Something went wrong while creating the venue, please try again!" closeFeedback={() => setVenueCreatedErrors(false)} />}
			<h2>Create your venue</h2>
			<h3 className="form-sub-title">Opt to reuse a venue or create a new one</h3>
			<div className='create-venue__select-line'>
				<select
					className='create-venue__select'
					id='venue-select'
					name='venue'
					as='select'
					defaultValue={selectedVenue}
					onChange={() => setVenue()}
				>
					<option disabled value=''>
						Select your venue
					</option>
					{venues &&
						venues.map((venue) => {
							return (
								<option key={venue.id} value={venue.id}>
									{venue.name}
								</option>
							);
						})}
				</select>
				<Button
					label='Create new'
					variant='primary'
					onClick={() => setIsCreatingVenue(true)}
				/>
			</div>
			{isCreatingVenue && (
				<CreateVenueDialog
					addVenue={createVenue}
					setCancelDialogOpen={setIsCreatingVenue}
				/>
			)}
			<Footer
				setActiveStep={(step) =>
					setActiveStep(step, { venue: parseInt(selectedVenue) })
				}
				activeSubStep={activeSubStep}
				isNextDisabled={selectedVenue === ''}
			/>
		</div>
	);
};

VenueInformationForm.propTypes = {
	setActiveStep: PropTypes.func,
	activeSubstep: PropTypes.number,
	eventState: PropTypes.object,
};

export default VenueInformationForm;
