import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';

const { Header, Content, Footer } = Card;
const { Title, Action } = Header;
const { Error } = Footer;

const TITLE = 'Overview';
const ADD_TOOLTIP = 'Add Artist Type';

const ArtistTypesOverviewCard = ({
    children = {},
    footer = {},
    onCreate = () => {}
}) => (
    <Card>
        <Header>
            <Title>{TITLE}</Title>
            <Tooltip content={ADD_TOOLTIP}>
                <Action onClick={() => onCreate()}>
                    <i className="bs-icon-plus" />
                </Action>
            </Tooltip>
        </Header>
        <Content>{children}</Content>
        <Footer>
            {footer.message ? <Error>{footer.message}</Error> : null}
        </Footer>
    </Card>
);

ArtistTypesOverviewCard.propTypes = {
    children: PropTypes.node,
    footer: PropTypes.object,
    onCreate: PropTypes.func
};

export default ArtistTypesOverviewCard
