import React from 'react'
import PropTypes from 'prop-types'
import by from 'thenby'
import { Grid, SearchInput } from 'ui-kit'
import { TableFilter, EventDaysTableFilter } from 'app/react/components/_index'

const { Cell } = Grid;

const ALL_ACCOMMODATIONS_OPTION = { value: 0, label: 'All hotels' };
const ALL_ROOM_TYPES_OPTION = { value: 0, label: 'All room types' };

const toOption = ({
    id,
    name
}) => ({
    value: id,
    label: name,
});

const ReservationsTableTopBar = ({
    dateFormat,
    days = [],
    accommodations = [],
    roomTypes = [],

    selectedCheckIn = 0,
    selectedAccommodationId = 0,
    selectedRoomTypeId = 0,
    selectedPageSize = 100,
    searchValue,

    onChangePageSize = () => {},
    onChangeFilter = () => {},
    onChangeSearchValue = () => {},
    onSearch = () => {},
    onClearSearch = () => {},
}) => (
    <Grid hasGutters={false} className="margin-tiny-bottom">
        <Cell isContentJustifiedAtStart>
            <EventDaysTableFilter
                width="150px"
                days={days}
                dateFormat={dateFormat}
                value={selectedCheckIn}
                onChange={value => onChangeFilter('checkIn', value)}
            />
            <TableFilter
                width="150px"
                options={[
                    ALL_ACCOMMODATIONS_OPTION,
                    ...accommodations
                        .sort(by('start', 1))
                        .map(toOption),
                ]}
                value={selectedAccommodationId}
                onChange={value => onChangeFilter('accommodation', parseInt(value, 10))}
            />
            { selectedAccommodationId !== 0 &&
                <TableFilter
                    width="150px"
                    options={[
                        ALL_ROOM_TYPES_OPTION,
                        ...roomTypes
                            .filter(({ accommodation }) => accommodation === selectedAccommodationId)
                            .sort(by('name', 1))
                            .map(toOption),
                    ]}
                    value={selectedRoomTypeId}
                    onChange={value => onChangeFilter('roomType', parseInt(value, 10))}
                />
            }
        </Cell>
        <Cell
            isAutoSized
            isContentJustifedAtEnd
            width="250px"
        >
            <SearchInput
                isExpanded
                value={searchValue || ''}
                placeholder="Artist, name, accommodation or room type..."
                info="You can search for reservations by artist, first and last name, accommodation or room type."
                onSearch={onSearch}
                onClear={onClearSearch}
            />
        </Cell>
    </Grid>
);

ReservationsTableTopBar.propTypes = {
    booking: PropTypes.object,
    onEdit: PropTypes.func
};

export default ReservationsTableTopBar;
