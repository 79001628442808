import React from 'react';
import * as Vehicle from 'app/react/entities/events/vehicles/index';
import PaginatedVehicleTableModalWrapper from './PaginatedTableModalWrapper';

const mergeQueryResults = (previousResult, fetchMoreResult) => ({
    __typename: previousResult.__typename,
    edges: [...previousResult.edges, ...fetchMoreResult.edges],
    count: previousResult.count + fetchMoreResult.count,
    totalCount: fetchMoreResult.pageInfo,
    pageInfo: fetchMoreResult.pageInfo,
});

class PaginatedVehicleTableModalWrapperContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingMore: false,
            after: null
        };
    }

    componentWillReceiveProps(newProps) {
        const { loading, vehicles } = newProps.data;
        const hasVehicles = typeof vehicles !== 'undefined' && vehicles.count > 0;
        if (!loading && hasVehicles) {
            const { edges = [] } = vehicles;
            const vehiclesData = edges.map(edge => edge.node);
            const data = { vehicles: vehiclesData };
            const schemaMapping = { vehicles: Vehicle.schemas.entities };
            this.props.onDataLoaded(data, schemaMapping)
        }
    }

    handleLoadMore() {
        const { data } = this.props;
        this.setState({
            isLoadingMore: true,
        });
        const after = data.vehicles.pageInfo.endCursor;
        data.fetchMore({
            variables: {
                after
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                this.setState({
                    after
                });
                return {
                    vehicles: mergeQueryResults(
                        previousResult.vehicles,
                        fetchMoreResult.vehicles
                    )
                }
            },
        }).then(() => {
            this.setState({ isLoadingMore: false });
        });
    }

    handleSort(sortKey, sortOrder) {
        const { data } = this.props;

        const sort = sortOrder === -1
            ? `-${sortKey}`
            : sortKey;

        data.refetch({
            sort: [sort]
        })
    }

    render() {
        const { onOpenCreateModal, onOpenUpdateModal, onOpenDeleteModal }  = this.props;
        const { vehicles = {}, variables, loading } = this.props.data;
        const { isLoadingMore, after } = this.state;

        const { edges = [], count, totalCount, pageInfo = {} } = vehicles;
        const vehiclesNodes = edges.map(edge => edge.node);
        const { eventId, first, sort } = variables;

        let currentSortKey;
        let currentSortOrder;
        if (typeof sort !== 'undefined' && sort !== null) {
            const firstSort = sort[0];
            const isSortingDesc = firstSort.charAt(0) === '-';
            currentSortOrder = isSortingDesc ? -1 : 1;
            currentSortKey = isSortingDesc ? firstSort.substr(1) : firstSort;
        }

        return (
            <PaginatedVehicleTableModalWrapper
                vehicles={vehiclesNodes}
                count={count}
                totalCount={totalCount}

                isLoading={loading}
                isLoadingMore={isLoadingMore}
                canLoadMore={pageInfo.hasNextPage}

                eventId={eventId}
                first={first}
                after={after}
                sort={sort}

                currentSortKey={currentSortKey}
                currentSortOrder={currentSortOrder}

                onCreate={onOpenCreateModal}
                onUpdate={onOpenUpdateModal}
                onDelete={onOpenDeleteModal}
                onSort={this.handleSort.bind(this)}
                onLoadMore={this.handleLoadMore.bind(this)}
            />
        )
    }

}

export default PaginatedVehicleTableModalWrapperContainer
