import React from 'react';
import PropTypes from 'prop-types';

const CREATE_NEW_TEXT = 'Create time slot';

const TimeSlotFooterRow = ({
    onCreateNew = () => {}
}) => (
    <tr style={{ height: '49px' }}>
        <td colSpan="6" style={{ textAlign: 'center' }}>
            <a onClick={onCreateNew}>
                + {CREATE_NEW_TEXT}
            </a>
        </td>
    </tr>
);

TimeSlotFooterRow.propTypes = {
    onCreateNew: PropTypes.func,
};

export default TimeSlotFooterRow;
