import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    limitAllParam as pageSizeAllParam,
} from 'app/react/state/middleware/api'

const root = (eventId, bookingId) =>
 `events/${eventId}/bookings/${bookingId}/items`;

export const fetchAll = (
    eventId,
    bookingId,
    filters = {},
    includes = [],
) => oneLineTrim`
    ${root(eventId, bookingId)}?
    ${pageSizeAllParam}
`;

export const copyAll = (
    eventId,
    bookingId,
) => oneLineTrim`
    ${root(eventId, bookingId)}/copy-from-booking
`;

export const createOne = (
    eventId,
    bookingId,
    includes = [],
) => oneLineTrim`
    ${root(eventId, bookingId)}?
    ${includeParam(includes)}
`;

export const updateOne = (
    eventId,
    bookingId,
    itemId,
    includes = [],
) => oneLineTrim`
    ${root(eventId, bookingId)}/${itemId}?
    ${includeParam(includes)}
`;

export const deleteOne = (
    eventId,
    bookingId,
    itemId,
) => oneLineTrim`
    ${root(eventId, bookingId)}/${itemId}
`;
