import React from 'react'
import moment from 'moment'
import { SortCell, NoRecordsRow } from 'app/react/components/_index'
import { isBookedByPromoter } from 'app/react/helpers/reservations'
import Row from './DaysColumnsTableRow'

const formatDate = (date, format) => moment.utc(date, moment.ISO_8601).format(`ddd ${format}`);

const DaysColumnsReservationsTable = (props) => {
    const {
        accommodations = [],
        reservationDays = [],
        dateFormatShort,
        expandedRows = [],
        onToggleRowExpansion,
    } = props;
    return (
        <table className="isLarge overflow">
            <thead>
                <tr>
                    <th
                        colSpan={reservationDays.length}
                        className="center"
                    >
                        Rooms Remaining
                    </th>
                </tr>
                <tr>
                    {reservationDays.map((day, key) => (
                        <SortCell
                            key={key}
                            className="center"
                            sortKey="day"
                            style={{ minWidth: '100px' }}
                        >
                            {formatDate(day, dateFormatShort)}
                        </SortCell>
                    ))}
                </tr>
            </thead>
            <tbody>
                {accommodations.length === 0
                    ? <NoRecordsRow />
                    : accommodations.map(accommodation => {
                        const reservations = accommodation.reservations || [];
                        const bookedByPromoter = reservations.filter(isBookedByPromoter);
                        return (
                            <Row
                                {...props}
                                withoutExpansionIcon
                                hideDetailRow
                                key={accommodation.id}
                                id={accommodation.id}
                                isExpanded={expandedRows.includes(accommodation.id)}
                                onToggleExpansion={onToggleRowExpansion}
                                accommodation={accommodation}
                                bookedByPromoter={bookedByPromoter}
                            />
                        );
                    })
                }
            </tbody>
        </table>
    )
};

export default DaysColumnsReservationsTable;
