import React from 'react'
import './MobileAppPreview.module.css'

const MobileAppPreview = () => (
    <img
        styleName="preview"
        src="https://s3.eu-central-1.amazonaws.com/assets.beatswitch.com/promoter-web-app/mobile-app-mockup.png"
        alt="Mobile app preview"
    />
);

export default MobileAppPreview;
