import React from 'react'
import BaseController from 'app/backbone/helpers/BaseController'
import EventSettingsView from 'app/backbone/views/event/settings/event/EventSettings.view'
import DaysOverview from 'app/backbone/views/event/settings/event/DaysOverview.view'
import UpdateDay from 'app/backbone/views/event/settings/event/days/Update.view'
import StagesOverview from 'app/backbone/views/event/settings/event/StagesOverview.view'
import EditStage from 'app/backbone/views/event/settings/event/stages/EditStage.view'
import CreateItineraryContact from 'app/backbone/views/event/settings/event/stages/itinerary_contacts/Create.view'

export const generalRoute = (
    eventId = ':event'
) => `events/${eventId}/settings`;

export const daysRoute = (
    eventId = ':event'
) => `events/${eventId}/settings/days`;

export const dayUpdate = (
    eventId = ':event',
    dayId = ':day',
) => `events/${eventId}/settings/days/${dayId}/edit`;

export const stagesRoute = (
    eventId = ':event'
) => `events/${eventId}/settings/stages`;

export const stageUpdate = (
    eventId = ':event',
    stageId = ':stage',
) => `events/${eventId}/settings/stages/${stageId}/edit`;

export const itineraryContactCreate = (
    eventId = ':event',
    stageId = ':stage',
) => `events/${eventId}/settings/stages/${stageId}/itinerary-contacts/create`;

// Backbone Controller
export default class GeneralEventSettingsController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        // Routes
        router.route(generalRoute(), null, this.general.bind(this));
        router.route(daysRoute(), null, this.days.bind(this));
        router.route(dayUpdate(), null, this.dayUpdate.bind(this));
        router.route(stagesRoute(), null, this.stages.bind(this));
        router.route(stageUpdate(), null, this.stageUpdate.bind(this));
        router.route(itineraryContactCreate(), null, this.createItineraryContact.bind(this));
    }

    general () {
        this.app.renderContentView(new EventSettingsView())
    }

    days() {
        this.app.renderContentView(new DaysOverview())
    }

    dayUpdate() {
        this.app.renderContentView(new UpdateDay())
    }

    stages() {
        this.app.renderContentView(new StagesOverview())
    }

    stageUpdate() {
        this.app.renderContentView(new EditStage())
    }


    createItineraryContact() {
        this.app.renderContentView(new CreateItineraryContact())
    }
}
