import AccrediteeItemAddModal from 'app/backbone/views/event/accreditation/accreditees/items/modals/AccrediteeItemAddModal.view';
import * as CompanyAccrediteeItem from 'app/backbone/models/event/accreditation/companies/CompanyAccrediteeItem';

export default class CompanyAccrediteeItemAddModal extends AccrediteeItemAddModal {

    newModel(attributes) {
        return new CompanyAccrediteeItem.Model(attributes);
    }

    onItemSelected(item) {
        super.onItemSelected(item);
        if (item && item.hasRelation('granted_profiles')) {
            const grantedProfiles = item.getRelation('granted_profiles');
            this.model.copyRelation('granted_profiles', grantedProfiles);
        }
    }

}

