import $ from 'jquery'
import { forEach } from 'lodash'
import { notifySuccess, notifyError } from 'ui-kit'
import BaseView from 'app/backbone/components/Base.view'
import './BookingDetail.css'

const LOADING_CURSOR = 'progress';

function notifyGeneralSectionError(isOpen, sectionName) {
    notifyError(`Something went wrong while ${isOpen ? 'opening' : 'closing'} the ${sectionName} section for this booking on the Production Website.`);
}

export default class BookingDetailView extends BaseView {

    ui() {
        return {
            lockSectionGeneral: 'input#lock-section-general',
            lockSections: 'input.lock-section:not(#lock-section-general)',
            assignAdvancingUserButton: '.assign-advancing-user',
            assignAdvancingUserModal: '#assign-advancing-user-modal',
            taskCheckbox: '.taskCheckbox',
        };
    }

    events() {
        return {
            'change lockSectionGeneral': 'toggleAllSections',
            'click lockSections': 'toggleSection',
            'click assignAdvancingUserButton': 'openEditAdvancingUserModal',
            'change taskCheckbox': 'toggleTaskCompletion',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    toggleAllSections(e) {
        const checkbox = e.target;

        this.startLoadingSwitch(checkbox);

        forEach(this.ui.lockSections, (lockSection) => this.startLoadingSwitch(lockSection));

        $.ajax({
            type: 'GET',
            url: checkbox.dataset.route,
        }).success(({ message }) => {
            this.finishedLoadingSwitch(checkbox);
            forEach(this.ui.lockSections, (lockSection) => {
                lockSection.checked = checkbox.checked;
                this.finishedLoadingSwitch(lockSection);
            });
            notifySuccess(message);
        });
    }

    toggleSection(e) {
        const checkbox = e.target;

        this.startLoadingSwitch(checkbox);

        $.ajax({
            type: 'GET',
            url: checkbox.dataset.route,
        }).success(({ message }) => {
            this.finishedLoadingSwitch(checkbox);
            notifySuccess(message);
        }).error(() => {
            notifyGeneralSectionError(checkbox.checked, checkbox.dataset.sectionName);

            if (checkbox.checked) {
                checkbox.checked = false;
            } else {
                checkbox.checked = true;
            }
        });
    }

    openEditAdvancingUserModal() {
        this.openModalFromDOM(this.ui.assignAdvancingUserModal);
    }

    toggleTaskCompletion(e) {
        const checkbox = e.target;

        this.startLoading(checkbox);

        $.ajax({
            type: 'PUT',
            url: checkbox.dataset.toggleUrl,
        }).success(() => this.finishedLoading(checkbox));
    }

    startLoading(checkbox) {
        checkbox.disabled = true;
        checkbox.style.cursor = LOADING_CURSOR;
    }

    startLoadingSwitch(checkbox) {
        this.startLoading(checkbox);
        $(checkbox).closest('.switch').addClass('isDisabled');
        $(checkbox).parent().find('.switch').css('cursor', LOADING_CURSOR);
    }

    finishedLoading(checkbox) {
        checkbox.disabled = false;
        checkbox.style.cursor = 'default';
    }

    finishedLoadingSwitch(checkbox) {
        this.finishedLoading(checkbox);
        $(checkbox).closest('.switch').removeClass('isDisabled');
        $(checkbox).parent().find('.switch').css('cursor', 'default');
    }
};
