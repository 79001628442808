import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Booking from 'app/backbone/models/event/artists/Booking';
import OffersContentHeader from 'app/react/views/event/artists/offers/OffersContentHeader';
import OffersOverview from 'app/backbone/views/event/artists/offers/views/OffersOverview.view';
import * as Routes from './routes'

export default class OffersController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.base(), null, this.overview.bind(this));
    }

    overview(eventId) {
        const { app, data } = this;
        app.renderSyncingView();
        app.renderContentHeader(React.createElement(OffersContentHeader));

        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const bookings = data.register('pagedBookings', Booking.PageableCollection, { event });

        event.setIncludes(['days.stages']);
        bookings.setIncludes(['artist', 'performance.day', 'performance.stage', 'offers.status']);

        this.when(
            account.fetch(),
            event.fetch(),
            bookings.fetch()
        ).done(() => {
            const days = event.getRelation('days');
            app.renderContentView(new OffersOverview({ bookings, days }));
        });
    }


}
