import { connect } from 'react-redux';
import { trim } from 'common-tags';
import { mapEventContextToProps, mapBookingContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as actions from 'app/react/state/actions/_index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Crew from 'app/react/entities/events/bookings/crew/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as PWArtistSettings from 'app/react/entities/events/production_website/settings/artists/index';
import * as PWArtistSettingsSection from 'app/react/entities/events/production_website/settings/artists/sections/index';
import * as PWArtistSettingsSectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/index';
import View from '../../components/View';
import * as State from './State';
import * as Routes from 'app/routes/production_website/artists/routes';

const FAILED_TO_CREATE_POPUP =
    'Failed to create crew member.';
const SUCCESSFUL_CREATE_ANOTHER_POPUP = (fullName) =>
    `Crew member <strong>${fullName}</strong> successfully created.`;
const SUCCESSFUL_CREATE_POPUP = (fullName) =>
    `${SUCCESSFUL_CREATE_ANOTHER_POPUP(fullName)} Redirecting...`;

const mapStateToProps = (state) => {
    const {
        values = {},
        errors = {},
        isSaving,
    } = State.getViewState(state);
    const currentSection = PWArtistSettingsSection.stateHelpers.getCurrent(state) || {};
    const formFields = PWArtistSettingsSectionFormField.stateHelpers.getSome(state, currentSection.formFields);
    const currentBooking = Booking.stateHelpers.getCurrent(state) || {};
    const { crewLimit, crewUsed } = currentBooking;
    const limitReached = crewLimit !== null && crewUsed >= crewLimit;
    return {
        ...mapEventContextToProps(state),
        ...mapBookingContextToProps(state),
        isCreating: true,
        isFetching: (
            isSaving ||
            ArtistType.stateHelpers.isFetchingCurrent(state) ||
            PWArtistSettings.stateHelpers.isFetchingCurrent(state)
        ),
        values,
        errors,
        dynamicFields: formFields,
        limitReached
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSave: (createAnother = false) => {
        const { values } = stateProps;
        const { productionWebsiteUrl } = ownProps;
        dispatchProps.onSave(createAnother, values, productionWebsiteUrl);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onChangeValue: (key, value) => {
        dispatch(State.setViewStateValue(`values.${key}`, value));
    },
    onChangePassport: (passport) => {
        if (typeof passport === 'undefined') {
            dispatch(State.deleteViewStateValue('values.passport'));
        } else {
            dispatch(State.setViewStateValue('values.passport', passport.uuid));
        }
    },
    onSave: (createAnother, values, productionWebsiteUrl) => {
        dispatch((State.setViewStateValue('isSaving', true)));
        // Set passport to null if no passport is given to remove it.
        const apiValues = {
            ...values,
            passport: values.passport || null
        };
        const { firstname, lastname } = values;
        const fullName = `${firstname} ${lastname}`.trim();
        dispatch(Crew.thunks.createOneForCurrentContext(apiValues))
            .then(({ isFailure, errors }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(FAILED_TO_CREATE_POPUP));
                    dispatch(State.mergeViewState({
                        errors: errors,
                        isSaving: false
                    }));
                } else if (createAnother) {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_CREATE_ANOTHER_POPUP(fullName)));
                    dispatch(State.mergeViewState({
                        errors: {},
                        values: {},
                        isSaving: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_CREATE_POPUP(fullName)));
                    dispatch(actions.navigate.pageReload(Routes.form(productionWebsiteUrl)));
                }
            });
    }
});



export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(View);
