import BaseView from 'app/backbone/components/Base.view'
import Datepicker from 'app/backbone/components/inputs/Datepicker.view'
import Timepicker from 'app/backbone/components/inputs/Timepicker.view'

export default class TransportFormView extends BaseView {

    ui() {
        return {
            departureDatePicker: '[name=departure_date]',
            departureTimePicker: '[name=departure_time]',
            arrivalDatePicker: '[name=arrival_date]',
            arrivalTimePicker: '[name=arrival_time]',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderStartDatePicker();
        this.renderStartTimePicker();
        this.renderEndDatePicker();
        this.renderEndTimePicker();
        return this.delegateEvents();
    }

    renderStartDatePicker() {
        this.departureDatePicker = new Datepicker({ el: this.ui.departureDatePicker });
        this.departureDatePicker.render();
    }

    renderStartTimePicker() {
        this.departureTimePicker = new Timepicker({ el: this.ui.departureTimePicker });
        this.departureTimePicker.render();
    }

    renderEndDatePicker() {
        this.arrivalDatePicker = new Datepicker({ el: this.ui.arrivalDatePicker });
        this.arrivalDatePicker.render();
    }

    renderEndTimePicker() {
        this.arrivalTimePicker = new Timepicker({ el: this.ui.arrivalTimePicker });
        this.arrivalTimePicker.render();
    }
}
