import $ from 'jquery'
import { forEach } from 'lodash'
import BaseView from 'app/backbone/components/Base.view'

const UPLOAD_TAB = 'uploadTab';
const URL_TAB = 'urlTab';
const TAB_ACTIVE_CLASS = 'active';

export default class CreateFileView extends BaseView {

    ui() {
        return {
            cardTabs: '.card--header_tab',
            fileUpload: '.file-upload',
            fileUrl: '.file-url',
        };
    }

    events() {
        return {
            'click cardTabs': 'openCardTab',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        return this.delegateEvents();
    }

    openCardTab(e) {
        const tab = e.currentTarget;

        forEach(this.ui.cardTabs, (tab) => $(tab).removeClass(TAB_ACTIVE_CLASS));
        $(tab).addClass(TAB_ACTIVE_CLASS);

        if (tab.id === UPLOAD_TAB) {
            this.showInputGroup(this.ui.fileUpload);
            this.hideInputGroup(this.ui.fileUrl);
        }

        if (tab.id === URL_TAB) {
            this.hideInputGroup(this.ui.fileUpload);
            this.showInputGroup(this.ui.fileUrl);
        }
    }

    showInputGroup(inputGroup) {
        inputGroup[0].style.display = 'block';
        $(inputGroup).find('input').prop('disabled', false);
    }

    hideInputGroup(inputGroup) {
        inputGroup[0].style.display = 'none';
        $(inputGroup).find('input').prop('disabled', true);
    }
};
