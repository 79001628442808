import $ from 'jquery'

export default class DOMDrawer {

    constructor(id) {
        this.$el = $(`[data-drawer-id="${id}"]`);
        this.$drawer = this.$el.find('.drawer');
        this.bindEvents();
    }

    bindEvents() {
        this.$el.on('click', () => this.close());
        this.$drawer.find('.drawer-close').on('click', () => this.close());
        this.$drawer.on('click', (e) => e.stopPropagation());
    }

    open() {
        this.$el.removeClass('hidden');
    }

    close() {
        this.$el.addClass('hidden');
    }
};
