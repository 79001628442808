import React from 'react';
import PropTypes from 'prop-types';

const DialogContainer = ({children}) => (
    <div className={'dialog-container'}>
			{children}
		</div>
);

DialogContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DialogContainer;
