import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import TicketsForm from "./TicketsForm";
import { apolloClient } from "../../../../../../state/data_sources/graphql";

const GET_EVENT_TICKETS_QUERY = gql`
  query getEventTasks($eventId: ID!) {
    event(id: $eventId) {
      ticketTypes {
        id
        name
        price {
          amount
          currency
        }
        attendeeTypes
        notes
        description
        amountReserved
        amountAssigned
      }
    }
  }
`;

const CREATE_TICKET_FOR_EVENT = gql`
  mutation createTicketType($event: ID!, $input: TicketTypeInput!) {
    createTicketType(event: $event, input: $input) {
      ticketType {
        id
        name
        price {
          amount
        }
        attendeeTypes
        notes
        description
        amountReserved
        amountAssigned
      }
    }
  }
`;
const UPDATE_TICKET_FOR_EVENT = gql`
  mutation updateTicketType($id: ID!, $input: TicketTypeInput!) {
    updateTicketType(id: $id, input: $input) {
      ticketType {
        id
        name
        price {
          amount
        }
        attendeeTypes
        notes
        description
        amountReserved
        amountAssigned
      }
    }
  }
`;

const updateTicketTypeForEvent = (id, ticket) =>
  apolloClient.mutate({
    mutation: UPDATE_TICKET_FOR_EVENT,
    variables: { id, input: ticket },
  });

const getTicketTypesForEvent = (eventId) =>
  apolloClient.query({
    query: GET_EVENT_TICKETS_QUERY,
    variables: { eventId },
  });

const TicketsFormContainer = ({ setActiveStep, activeSubStep, eventState }) => {
  const [ticketTypes, setTicketTypes] = useState(undefined);
  const [error, setError] = useState(undefined);

  // moved this here, because we need setError
const createTicketTypeForEvent = (event, ticket) =>
  apolloClient.mutate({
    mutation: CREATE_TICKET_FOR_EVENT,
    variables: { event, input: ticket },
  }).catch((error) => {
    setError('Something went wrong adding the ticket type');
  }) ;

  useEffect(() => {
    if (
      (!eventState.event.ticketTypes || eventState.event.ticketTypes.length === 0) &&
      eventState.event.oldEventId
    ) {
      const newAndOldTypes = [
        Promise.resolve(getTicketTypesForEvent(eventState.event.oldEventId)),
        Promise.resolve(getTicketTypesForEvent(eventState.event.id)),
      ];
      Promise.all(newAndOldTypes).then((res) => {
        console.log(res);
        const newTypes = res[1].data.event.ticketTypes.map((type) => type.name);
        const oldTypes = res[0].data.event.ticketTypes
          .filter((type) => !newTypes.includes(type.name));
          
        console.log(oldTypes);
        batchCreateTypes(oldTypes, res[1].data.event.ticketTypes);
        // is this necessary? or just confusing
        setTicketTypes(res[1].data.event.ticketTypes);
      });


      // getTicketTypesForEvent(eventState.event.id)
      //   .then((newRes) => {
      //     getTicketTypesForEvent(eventState.event.oldEventId).then((res) => {
      //       if (res && res.data) {

      //         const types = res.data.event.ticketTypes.reduce(
      //           (prev, current) => {
      //             const titles = prev.map((type) => type.name);
      //             if (!titles.includes(current.name)) {
      //               prev.push(current);
      //             } 
      //             return prev;
      //           },
      //           [...newRes.data.event.ticketTypes]
      //         );
      //         console.log('in result', types);

      //         batchCreateTypes(eventState.event.id, types);

      //         setTicketTypes(types);
      //       }
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("Fetch ticket types:: ", error);
      //     setError(
      //       "Something went wrong while getting the ticket types for the event"
      //     );
      //   });
    } else {
      getTicketTypesForEvent(eventState.event.id)
        .then((res) => {
          if (res.data) {
            setTicketTypes(res.data.event.ticketTypes);
          }
        })
        .catch((error) => {
          console.error("Fetch ticket types:: ", error);
          setError(
            "Something went wrong while getting the ticket types for the event"
          );
        });
    }
  }, []);



  const batchCreateTypes = (ticketTypes, oldTypes) => {

    const cleanTickets = ticketTypes.map((ticket) => {
      const formattedTicket = {
        name: ticket.name,
        notes: ticket.notes,
        price: ticket.price.amount,
        attendeeTypes: ticket.attendeeTypes,
        currency: eventState.event.currency
      };

      if (!formattedTicket.notes) {
        delete formattedTicket.notes;
      }
      if (!formattedTicket.description) {
        delete formattedTicket.description;
      }

      return formattedTicket;
    });
    
    const promises = cleanTickets.map((ticketType) =>  Promise.resolve(createTicketTypeForEvent(eventState.event.id, ticketType)));
    Promise.all(promises).then((res) => {
      setTicketTypes(oldTypes.concat(res.map((typeResult) => typeResult.data.createTicketType.ticketType)));
    }).catch((error) => {
      setError('Something went wrong while fetching the ticket types from the event you are trying to copy.')
      console.error('Batch create:: ', error);
    })
  };

  return (
    <TicketsForm
      eventState={eventState}
      setActiveStep={setActiveStep}
      activeSubStep={activeSubStep}
      createTicketTypeForEvent={createTicketTypeForEvent}
      updateTicketTypeForEvent={updateTicketTypeForEvent}
      ticketTypes={ticketTypes}
      setTicketTypes={setTicketTypes}
      error={error}
      setError={setError}
    />
  );
};

TicketsFormContainer.propTypes = {};

export default TicketsFormContainer;
