import * as Account from 'app/react/entities/accounts/helpers';
import { fetchAll, createOne, updateOne, deleteOne } from './actions';

export const fetchAllForCurrentContext = () => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    return dispatch(fetchAll(accountId));
};

export const createOneForCurrentContext = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    return dispatch(createOne(accountId, values));
};

export const updateOneForCurrentContext = (itemId, values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    return dispatch(updateOne(accountId, itemId, values));
};

export const deleteOneForCurrentContext = (itemId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    return dispatch(deleteOne(accountId, itemId));
};


