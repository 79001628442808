import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
    position: relative;
    margin-right: 1.2rem;

    &:after {
        content: '<';
        position: absolute;
        right: -1.2rem;
        top: 50%;
        transform: translateY(-40%);
        font-family: var(--fontFamilyIcons);
        font-style: normal;
        font-weight: normal;
        font-size: var(--fontSizeSmaller);
        color: var(--colorNeutral300);
    }

    &:last-of-type:after {
        display: none;
    }
`;

export const PickUp = styled.i`
    display: inline-block;
`;

const Transport = styled.i`
    display: inline-block;
`;

export default ({
    isPickup = false,
    onClick = () => {},
}) => (
    <Link onClick={onClick}>
        {isPickup 
            ? <PickUp className="bs-icon-car" /> 
            : <Transport className="bs-icon-plane" />
        }
    </Link>
)
