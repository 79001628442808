import React from 'react';
import PropTypes from 'prop-types';
import { AlertBox } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';

const { Content } = ContentWrapper;

const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';

const LogisticsContent = ({
    isLoading,
    userCanManageLogistics = false,
    children,
}) => (
    <Content isLoading={isLoading}>
        {!userCanManageLogistics 
            ? <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox>
            : children
        }
    </Content>
);

LogisticsContent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    userCanManageLogistics: PropTypes.bool,
    children: PropTypes.node,
};

export default LogisticsContent;
