import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';
import SelectBooking from '../../../../selects/SelectBooking';

const ADD_EXISTING_TOOLTIP = 'Add booking';
const CANCEL_ADD_EXISTING_TOOLTIP = 'Cancel add booking';
const inputGroupStyles = {
    margin: "var(--spacingSmaller) 0",
};

const AddExistingBookingRow = ({
    isArtistTypesEnabled = true,
    bookings = [],
    isFetchingBookings = false,
    errors = {},
    values = {},
    onChangeValue = () => {},
    onSave = () => {},
    onCancel = () => {}
}) => {
    const bookingError = errors.booking && errors.booking[0].text;
    const bookingHasError = typeof bookingError !== 'undefined';
    return (
        <tr>
            <td className="input" colSpan={isArtistTypesEnabled ? 4 : 3}>
                <InputGroup
                    hasError={bookingHasError}
                    style={inputGroupStyles}
                >
                    <Tooltip
                        isAlert
                        isOpened={bookingHasError}
                        content={bookingError}
                    >
                        <SelectBooking
                            isLoading={isFetchingBookings}
                            value={values.selectedBooking}
                            bookings={bookings}
                            onChange={value => onChangeValue('selectedBooking', value)}
                        />
                    </Tooltip>
                </InputGroup>
            </td>
            <td className="actions">
                <Tooltip content={ADD_EXISTING_TOOLTIP}>
                    <a className="action" onClick={onSave}>
                        <i className="bs-icon-check" />
                    </a>
                </Tooltip>
                <Tooltip content={CANCEL_ADD_EXISTING_TOOLTIP}>
                    <a className="action" onClick={onCancel}>
                        <i className="bs-icon-cross" />
                    </a>
                </Tooltip>
            </td>
        </tr>
    );
};

AddExistingBookingRow.propTypes = {
    isArtistTypesEnabled: PropTypes.bool,
    bookings: PropTypes.array,
    errors: PropTypes.object,
    values: PropTypes.object,
    onChangeValue: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};

export default  AddExistingBookingRow;
