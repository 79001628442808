import React from 'react';
import { connect } from 'react-redux';
import { SearchSelect } from 'ui-kit';
import * as Artist from 'app/react/entities/accounts/artists/index';
import * as State from './State';

const toOption = ({ id, name }) => ({
    value: id,
    label: name,
});

const mapStateToProps = (state) => {
    const {
        searchTerm
    } = State.getViewState(state);
    return {
        options: Artist.stateHelpers.getSearchResultsForCurrentContext(state, searchTerm),
        isLoading: Artist.stateHelpers.isSeachingForCurrentContext(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChangeInput: (searchTerm) => {
        dispatch(State.setViewStateValue('searchTerm', searchTerm));
        dispatch(Artist.thunks.searchAllForCurrentContext(searchTerm))
    }
});

const SearchSelectArtists = (props) => (
    <SearchSelect
        {...props}
        options={props.options.map(toOption)}
    />
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchSelectArtists);
