var Backgrid = require('backgrid');

var template = require('./LabelCell.hbs');

var LabelCell = Backgrid.Cell.extend({

    template,

    className: 'label-cell input',

    labelClassName: '',

    render() {
        this.$el.html(this.template(this.viewModel()));
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                labelClassName: this.labelClassName
            },
            attribute: this.model.get(this.column.get('name'))
        }
    }
});

module.exports = LabelCell;
