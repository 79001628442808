import React from 'react';
import { AlertBox, Grid } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import ContentHeader from '../../components/layout/ContentHeader';
import ItemsCard from 'app/react/views/account/settings/artists/items/components/items/ItemsCard';
import ItemsTable from 'app/react/views/account/settings/artists/items/components/items/ItemsTable';
import GroupsCard from 'app/react/views/account/settings/artists/items/components/groups/GroupsCard';
import GroupsTable from 'app/react/views/account/settings/artists/items/components/groups/GroupsTable';
import CreateGroupModal from '../components/groups/CreateGroupModalContainer';
import CreateItemModal from '../components/items/CreateItemModalContainer';
import UpdateGroupModal from '../components/groups/UpdateGroupModalContainer';
import UpdateItemModal from '../components/items/UpdateItemModalContainer';
import DeleteGroupModal from '../components/groups/DeleteGroupModalContainer';
import DeleteItemModal from '../components/items/DeleteItemModalContainer';

const { Content } = ContentWrapper;
const { Cell } = Grid;

const GROUPS_CARD_WIDTH = '50%';
const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';

const EventArtistsItemsOverview = ({
    store,
    eventId,
    permissions = {},
    currency = 'EUR',
    isFetching = false,
    items = [],
    groups = [],

    onOpenCreateItemModal = () => {},
    onOpenUpdateItemModal = () => {},
    onOpenDeleteItemModal = () => {},
    onOpenCreateGroupModal = () => {},
    onOpenUpdateGroupModal = () => {},
    onOpenDeleteGroupModal = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            eventId={eventId}
            permissions={permissions}
            activeTab="items"
        />
        <Content isLoading={isFetching}>
            { !permissions.setupArtistItems ?
                <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox> :
                <div>
                    <Grid>
                        <Cell>
                            <ItemsCard
                                hasRecords={items.length > 0}
                                hasGroups={groups.length > 0}
                                onCreate={() => onOpenCreateItemModal()}
                            >
                                <ItemsTable
                                    currency={currency}
                                    items={items}
                                    groups={groups}
                                    onAdd={onOpenCreateItemModal}
                                    onUpdate={onOpenUpdateItemModal}
                                    onDelete={onOpenDeleteItemModal}
                                />
                            </ItemsCard>
                        </Cell>
                        <Cell
                            isAutoSized
                            width={GROUPS_CARD_WIDTH}
                        >
                            <GroupsCard
                                hasRecords={groups.length > 0}
                                onCreate={() => onOpenCreateGroupModal()}
                            >
                                <GroupsTable
                                    groups={groups}
                                    onAdd={onOpenCreateGroupModal}
                                    onUpdate={onOpenUpdateGroupModal}
                                    onDelete={onOpenDeleteGroupModal}
                                />
                            </GroupsCard>
                        </Cell>
                    </Grid>
                </div>
            }
        </Content>
        <CreateGroupModal />
        <CreateItemModal />
        <UpdateGroupModal />
        <UpdateItemModal />
        <DeleteGroupModal />
        <DeleteItemModal />
    </ContentWrapper>
);

EventArtistsItemsOverview.propTypes = {
};

export default EventArtistsItemsOverview;
