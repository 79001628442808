import classNames from 'classnames';
import React, { Fragment, useState } from 'react';

const MoneyField = ({
	id,
	value,
	name,
	label,
	type = 'text',
	autofocus,
	disabled,
	required,
	onChange,
	onBlur,
	placeholder,
	currencyUnit = 'EUR',
}) => {
  const [focused, setFocused] = useState(false);

  const moneyfieldClasses = classNames([
    'money-field__input-container',
    focused ? 'money-field__input-container--focused' : null,
  ]);
	return (
		<Fragment>
			<label htmlFor={id} className='form-field'>
				{label && (
					<span className={'form-field__label'}>
						{label}
						{required ? '*' : null}
					</span>
				)}
				<div className={moneyfieldClasses}>
					<span className='money-field__unit'>{currencyUnit}</span>
					<input
						type={type}
						id={id}
						name={name}
						value={value}
						autoFocus={autofocus}
						disabled={disabled}
						onChange={onChange}
						placeholder={placeholder}
						onBlur={(e) => {
							onBlur(e);
            setFocused(false);
						}}
						required={required}
          onFocus={() => setFocused(true)}
					/>
				</div>
			</label>
		</Fragment>
	);
};

export default MoneyField;
