import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { InputGroup } from 'ui-kit'
import TextEditor from 'app/react/views/event/production_website/artists/ProductionWebsiteSettingsTextEditor'
import {
    DESCRIPTION_PROP_NAME,
    DESCRIPTION_LABEL,
    DESCRIPTION_HEIGHT,
    FIELD_MAX_WIDTH,
    LIMIT_PLACEHOLDER,
    UPDATE_BOOKINGS_LABEL,
    UPDATE_BOOKINGS_PROP_NAME
} from './constants'

const ROOM_LIMIT_PROP_NAME = 'roomLimit';
const ROOM_LIMIT_LABEL = 'Max rooms/day';
const NIGHT_LIMIT_PROP_NAME = 'nightLimit';
const NIGHT_LIMIT_LABEL = 'Max. night';
const CAN_CREATE_PROP_NAME = 'create';
const CAN_CREATE_LABEL = 'Allow to select & add accommodations on the production website';
const REQUIRE_APPROVAL_PROP_NAME = 'requiresApproval';
const REQUIRE_APPROVAL_LABEL = 'Require promoter approval for reservations created or edited through the production website';

export const AccommodationForm = ({
    values,
    errors = {},
    onChangeValue = () => {},
}) => (
    <Fragment>
        <InputGroup messages={errors[DESCRIPTION_PROP_NAME]}>
            <label>{DESCRIPTION_LABEL}</label>
            <TextEditor
                imageInsertButtonsOverride={['imageBack', '|', 'imageByURL']}
                height={DESCRIPTION_HEIGHT}
                value={values[DESCRIPTION_PROP_NAME] || ''}
                onBlur={e => onChangeValue(DESCRIPTION_PROP_NAME, e.target.value)}
            />
        </InputGroup>
        <fieldset>
            <legend>Limits</legend>
            <InputGroup
                messages={errors[ROOM_LIMIT_PROP_NAME]}
                style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
            >
                <label>{ROOM_LIMIT_LABEL}</label>
                <input
                    type="number"
                    placeholder={LIMIT_PLACEHOLDER}
                    value={typeof values[ROOM_LIMIT_PROP_NAME] !== 'undefined' && values[ROOM_LIMIT_PROP_NAME] !== null ?
                        values[ROOM_LIMIT_PROP_NAME] :
                        ''
                    }
                    onChange={e => onChangeValue(ROOM_LIMIT_PROP_NAME, e.target.value)}
                />
            </InputGroup>
            <InputGroup
                messages={errors[NIGHT_LIMIT_PROP_NAME]}
                style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
            >
                <label>{NIGHT_LIMIT_LABEL}</label>
                <input
                    type="number"
                    placeholder={LIMIT_PLACEHOLDER}
                    value={typeof values[NIGHT_LIMIT_PROP_NAME] !== 'undefined' && values[NIGHT_LIMIT_PROP_NAME] !== null ?
                        values[NIGHT_LIMIT_PROP_NAME] :
                        ''
                    }
                    onChange={e => onChangeValue(NIGHT_LIMIT_PROP_NAME, e.target.value)}
                />
            </InputGroup>
            <InputGroup
                messages={errors[UPDATE_BOOKINGS_PROP_NAME]}
                style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
            >
                <label>
                    <input
                        type="checkbox"
                        style={{ marginRight: '1rem' }}
                        checked={typeof values[UPDATE_BOOKINGS_PROP_NAME] !== 'undefined' ?
                            values[UPDATE_BOOKINGS_PROP_NAME] :
                            false
                        }
                        onChange={e => onChangeValue(UPDATE_BOOKINGS_PROP_NAME, e.target.checked)}
                    />
                    {UPDATE_BOOKINGS_LABEL}
                </label>
            </InputGroup>
        </fieldset>
        <fieldset>
            <legend>Accommodation requests</legend>
            <InputGroup
                messages={errors[CAN_CREATE_PROP_NAME]}
                style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
            >
                <label>
                    <input
                        type="checkbox"
                        style={{ marginRight: '1rem' }}
                        checked={typeof values[CAN_CREATE_PROP_NAME] !== 'undefined' ?
                            values[CAN_CREATE_PROP_NAME] :
                            false
                        }
                        onChange={e => onChangeValue(CAN_CREATE_PROP_NAME, e.target.checked)}
                    />
                    {CAN_CREATE_LABEL}
                </label>
            </InputGroup>
            <InputGroup
                messages={errors[REQUIRE_APPROVAL_PROP_NAME]}
                style={{ maxWidth: `${FIELD_MAX_WIDTH}px` }}
            >
                <label>
                    <input
                        type="checkbox"
                        style={{ marginRight: '1rem' }}
                        checked={typeof values[REQUIRE_APPROVAL_PROP_NAME] !== 'undefined' ?
                            values[REQUIRE_APPROVAL_PROP_NAME] :
                            false
                        }
                        onChange={e => onChangeValue(REQUIRE_APPROVAL_PROP_NAME, e.target.checked)}
                    />
                    {REQUIRE_APPROVAL_LABEL}
                </label>
            </InputGroup>
        </fieldset>
    </Fragment>
);

AccommodationForm.propTypes = {
    values: PropTypes.shape({
        [DESCRIPTION_PROP_NAME]: PropTypes.string,
        [ROOM_LIMIT_PROP_NAME]: PropTypes.number,
        [NIGHT_LIMIT_PROP_NAME]: PropTypes.number,
        [CAN_CREATE_PROP_NAME]: PropTypes.bool
    }).isRequired,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func
};
