import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select type...';
export const NO_RESULTS_PLACEHOLDER = 'No type found.';

const toOption = ({ id, name }) => ({
    value: id,
    label: name,
});

const SelectBookingType = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    value,
    bookingTypes = [],
    onChange = () => {},
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        options={bookingTypes.sort(by('name')).map(toOption)}
        onChange={onChange}
    />
);

SelectBookingType.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    bookingTypes: PropTypes.array,
    onChange: PropTypes.func,
};

export default SelectBookingType;
