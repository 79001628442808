import React, { useState, useEffect } from "react";
import dragula from "dragula";
import PropTypes from "prop-types";
import Footer from "../../footer/Footer";
import TasksDialog from "../../atoms/dialogs/TasksDialog";
import Button from "../../atoms/button/Button";
import Icon from "../../../utils/Icon";
import FeedbackMessage from "../../atoms/feedback/Feedback";
import DeleteDialog from "../../atoms/dialogs/DeleteDialog";

export const TaskForm = ({
  setActiveStep,
  activeSubStep,
  eventState,
  createTaskForEvent,
  deleteTaskFromEvent,
  updateTaskForEvent,
  tasks,
  setTasks,
  error,
  setError,
  contacts,
}) => {
  const [taskToEdit, setTaskToEdit] = useState(undefined);
  const [taskToRemove, setTaskToRemove] = useState(undefined);

  const drake = dragula([]);

  useEffect(() => {
    if (document.getElementById("task-table-body")) {
      const table = document.getElementById("task-table-body");
      drake.containers.push(table);
    }
  }, []);

  const goToNextStep = (step) => {
    const rows = document.getElementsByClassName("form__table-row");
    const ids = Array.from(rows, (element) => element.id.split("-")[1]);
    const sortedTasks = ids.map((id) =>
      tasks.find((task) => task.id === parseInt(id))
    );
    setActiveStep(step, { tasks: sortedTasks });
  };

  const addTask = (task) => {
    if (task && task.id) {
      const { id, ...rest } = task;
      updateTaskForEvent(task.id, rest).then((taskRes) => {
        if (taskRes && taskRes.data) {
          setTasks((prev) => {
            return [
              ...prev.map((existing) =>
                existing.id !== taskRes.data.updateTaskForEvent.task.id
                  ? existing
                  : taskRes.data.updateTaskForEvent.task
              ),
            ];
          });
          setTaskToEdit(undefined);
        } else if (taskRes && taskRes.error) {
          setError("Something went wrong while updating the task");
          console.error("Updating task:: ", error);
        }
      });
    } else {
      createTaskForEvent(eventState.event.id, task).then((taskRes) => {
        if (taskRes && taskRes.data) {
          setTasks([...tasks, taskRes.data.createTaskForEvent.task]);
          setTaskToEdit(undefined);
        } else {
          setError("Something went wrong while updating the task");
          console.error("Updating task:: ", error);
        }
      });
    }
  };

  const removeTask = (task) => {
    deleteTaskFromEvent(task.id)
      .then((res) => {
        if (res && res.data) {
          setTasks((prev) =>
            prev.filter(
              (existingTask) =>
                existingTask.id !== res.data.deleteTaskFromEvent.id
            )
          );
          setTaskToRemove(undefined);
        } else if (res && res.error) {
          setError("Something went wrong while deleting the task.");
          console.error("Delete task:: ", res.error);
        }
      })
      .catch((error) => {
        setError("Something went wrong while deleting the task.");
        console.error("Delete task:: ", error);
      });
  };

  return (
    <div className="form_content">
      {error && (
        <FeedbackMessage
          text={error}
          variant="error"
          closeFeedback={() => setError("")}
        />
      )}
      <h2>Tasks</h2>
      <h3 className="form-sub-title">Create tasks for you and your team</h3>
      <span>
        Below you can find a set of standard tasks to add to every booking. Feel
        free to edit them or add new tasks.
      </span>
      <table className="form__table">
        <thead>
          <tr className="form__table-header">
            <th className="form__table__front-button"></th>
            <th>TITLE</th>
            <th></th>
          </tr>
        </thead>
        <tbody id="task-table-body">
          {tasks &&
            tasks.map((task) => {
              return (
                <tr
                  className="form__table-row"
                  key={task.id}
                  id={`row-${task.id}`}
                >
                  <td className="form__table__front-button">
                    <button className="form__table__button">
                      <Icon
                        size={26}
                        name="dragAndDrop"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                  </td>
                  <td>{task.name}</td>
                  <td className="form__table__last-column">
                    <button
                      className="form__table__button"
                      onClick={() => setTaskToEdit(task)}
                    >
                      <Icon
                        size={26}
                        name="edit"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                    <button
                      className="form__table__button"
                      onClick={() => setTaskToRemove(task)}
                    >
                      <Icon
                        size={26}
                        name="delete"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <br />
      <Button
        label="Add new task"
        variant="secondary"
        onClick={() =>
          setTaskToEdit({
            title: "",
            subject: undefined,
            body: "",
          })
        }
      />
      {taskToEdit && (
        <TasksDialog
          addTask={addTask}
          setCancelDialogOpen={() => setTaskToEdit(undefined)}
          task={taskToEdit}
          contacts={contacts}
        />
      )}{" "}
      {taskToRemove && (
        <DeleteDialog
          title="Remove task"
          text="Are you sure you want to remove this task?"
          buttonText="Yes, remove task!"
          confirm={() => removeTask(taskToRemove)}
          setCancelDialogOpen={() => setTaskToRemove(undefined)}
        />
      )}
      <Footer
        setActiveStep={(step) => goToNextStep(step)}
        activeSubStep={activeSubStep}
        eventState={eventState}
        isNextDisabled={false}
      />
    </div>
  );
};

TaskForm.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
};

export default TaskForm;
