import _ from 'underscore';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import PeriodDaysTable from 'app/backbone/views/event/settings/accreditation/components/PeriodDaysTable.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import GrantedDayCell from './cells/GrantedDayCell.view';
import GrantedAllDaysCell from './cells/GrantedAllDaysCell.view';
import SubTableBody from 'app/backbone/components/tables/bodies/SubTableBody.view';
import * as AccrediteeProfile from 'app/backbone/models/event/accreditation/accreditees/AccrediteeProfile';

export default class AccrediteeProfileTable extends PeriodDaysTable {

    optionTypes() {
        return _.extend({}, super.optionTypes(), {
            collection: OptionTypes.instanceOf(AccrediteeProfile.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            profiles: OptionTypes.instanceOf(Profile.Collection).isRequired,
            placeholderHeight: OptionTypes.number
        });
    }

    optionDefaults() {
        return _.extend({}, super.optionDefaults(), {
            placeholderHeight: 100
        });
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        this.actions = this.initActions();
        this.placeholderText = this.getPlaceholderText();
        this.firstColumns = this.initFirstColumns();
        this.columns = this.initColumns();
        this.lastColumns = this.initLastColumns();
        this.body = SubTableBody;

        super.initialize(options);
    }

    initFirstColumns() {
        return [{
            name: 'name',
            cell: 'string',
            label: 'Profile',
            headerCell: CellFactory.createHeaderCell('125px'),
            sortable: false,
            editable: false
        }, {
            name: 'granted_by',
            cell: 'string',
            label: 'Added By',
            headerCell: CellFactory.createHeaderCell('125px'),
            sortable: false,
            editable: false
        }, {
            name: 'all',
            label: 'All',
            cell: this.createAllDaysCell(),
            headerCell: CellFactory.createHeaderCell('55px', 'center'),
            sortable: false,
            editable: false
        }];
    }

    initColumns() {
        return [];
    }

    initLastColumns() {
        const { actions } = this;
        return [{
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(actions),
            sortable: false,
            editable: false
        }];
    }

    initActions() {
        return [{
            name: 'remove',
            icon: 'bs-icon-bin',
            tooltip: 'Remove',
            action: this.removeProfile.bind(this),
            canRender: item => item.get('granted_by_promoter'),
        }];
    }

    removeProfile(profile) {
        this.collection.remove(profile);
    }

    createDayCell(day, classNamePostfix) {
        const className = GrantedDayCell.prototype.className() + ' ' + classNamePostfix;
        return GrantedDayCell.extend({ day, className });
    }

    createAllDaysCell() {
        return GrantedAllDaysCell;
    }

    getPlaceholderText() {
        let profiles = this.profiles;

        if(!profiles.isEmpty()) return `No profiles have been added for this attendee. Add at least one.`;

        let eventId = profiles.event ? profiles.event.id : profiles.first().event.id ;
        let createProfileUrl = `/events/${eventId}/settings/accreditation/profiles`;
        return `No profiles have been created for this event. <a href="${createProfileUrl}">Create your first profile.</a>`;
    }

}
