import React, { Component } from 'react'
import { startCase } from 'lodash'
import { Button, DismissibleModal } from 'ui-kit'

const generateLocalStorageKey = (type) => `dismiss${startCase(type)}PassportUploadsEnabledModal`;

const Title = () => 'Enable passport uploads';
const Body = ({ type }) => (
    <p>
        Since the introduction of GDPR, we strongly advise against storing passport data.
        Please use this at your own risk. Are you sure you want to enable passport uploads for {type}?
    </p>
);
const CONFIRM_TEXT = "Yes, I'm sure";

const EnablePassportUploadsCheckbox = ({
    isChecked,
    isDismissed,
    openModal,
    closeModal,
    onConfirm,
}) => (
    <input
        type="checkbox"
        style={{ marginRight: '1rem' }}
        checked={isChecked}
        onChange={isDismissed || (!isChecked === false)
            ? () => {
                onConfirm({ openModal, closeModal });
            }
            : openModal
        }
    />
);

const EnablePassportUploadsModal = ({
    type,
    arePassportUploadsEnabled,
    onChange,
}) => (
    <DismissibleModal
        rememberAs={generateLocalStorageKey(type)}
        renderTrigger={(triggerProps) => (
            <EnablePassportUploadsCheckbox
                {...triggerProps}
                isChecked={arePassportUploadsEnabled}
           />
        )}
        renderTitle={Title}
        dismissButtonType={Button.DANGER}
        dismissButtonText={CONFIRM_TEXT}
        onConfirm={({ closeModal }) => {
            onChange()
            closeModal()
        }}
    >
        <Body type={type} />
    </DismissibleModal>
);

EnablePassportUploadsModal.defaultProps = {
    arePassportUploadsEnabled: false,
};

export default EnablePassportUploadsModal;
