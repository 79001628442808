import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import { combinePaginationFields } from 'app/react/helpers/_index';
import { getPaginationByKey } from './helpers';
import { fetchAll, fetchAllPaginated } from './actions';

export const fetchAllForCurrentContext = (includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(fetchAll(accountId, eventId, includes));
};

export const fetchAllByPaginationForCurrentContext = (newPagination, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const pagination = getPaginationByKey(state, Event.generateCollectionKey(accountId, eventId));
    const combinedPagination = combinePaginationFields(newPagination, pagination);
    return dispatch(fetchAllPaginated(accountId, eventId, combinedPagination, includes));
};
