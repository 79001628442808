import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as yup from 'yup';
import { datePickerFormatter } from '../../hooks/UseEventState';
import Icon from '../../utils/Icon';
import Button from '../atoms/button/Button';
import AddingDaysDialog from '../atoms/dialogs/AddingDaysDialog';
import Footer from '../footer/Footer';

export const EventDaysFormSchema = () => yup.object({});

export const EventDaysForm = ({ setActiveStep, activeSubStep, eventState }) => {
	const [dayToEdit, setDayToEdit] = useState(undefined);
	const [days, setDays] = useState(
		eventState.event.days ? eventState.event.days : []
	);
	var currentDate = new Date();
	currentDate.setDate(currentDate.getDate() + 1);
	var day = currentDate.getDate();
	var month = currentDate.getMonth();
	var year = currentDate.getFullYear();

	const addDay = (day) => {
		setDayToEdit(undefined);
		if (day && day.id) {
			setDays((prev) =>
				prev.map((existingDay) => {
					return existingDay.id === day.id ? day : existingDay;
				})
			);
		} else {
			setDays([
				...days,
				{ ...day, id: Math.floor(Math.random() * Date.now()) },
			]);
		}
	};

	const removeDay = (day) => {
		setDays((prev) => prev.filter((existingDay) => existingDay.id !== day.id));
	};

	return (
		<div className='form_content'>
			<h2>When does the event take place</h2>
			<h3 className="form-sub-title">Select the days</h3>
			<span>
				Add the days of your event and complete the start and end time per day.
				You will need them for your future line-up.
			</span>
			{days.length > 0 && (
				<table className='form__table'>
					<thead>
						<tr className='form__table-header'>
							<th>DAY</th>
							<th>START</th>
							<th>END</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{days.map(({ name, end, start, id }) => {
							return (
								<tr className='form__table-row' key={id}>
									<td>{name}</td>
									<td>{moment(start).format(`${eventState.event.date_format} ${eventState.event.time_format}`)}</td>
									<td>{moment(end).format(`${eventState.event.date_format} ${eventState.event.time_format}`)}</td>
									<td className='form__table__last-column'>
										<button
											className='form__table__button'
											onClick={() =>
												setDayToEdit({ name, end, start, id })
											}
										>
											<Icon
												size={26}
												name='edit'
												style={{ marginBottom: '-10px' }}
											/>
										</button>
										<button
											className='form__table__button'
											onClick={() =>
												removeDay({ name, end, start, id })
											}
										>
											<Icon
												size={26}
												name='delete'
												style={{ marginBottom: '-10px' }}
											/>
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
			<br/>
			<Button
				label='Add day'
				variant='secondary'
				onClick={() =>
					setDayToEdit({
						name: '',
						start: new Date(year, month, day, 13, 0, 0),
						end: new Date(year, month, day, 23, 45, 0),
					})
				}
			/>
			{dayToEdit && (
				<AddingDaysDialog
					addDay={addDay}
					setCancelDialogOpen={() => setDayToEdit(undefined)}
					day={dayToEdit}
					dateFormat={datePickerFormatter(eventState.event.date_format)}
					timeFormat={datePickerFormatter(eventState.event.time_format)}
				/>
			)}
			<Footer
				setActiveStep={(step) => {
					setActiveStep(step, { days });
					}}
				activeSubStep={activeSubStep}
				isNextDisabled={days.length === 0}
			/>
		</div>
	);
};

EventDaysForm.propTypes = {
	setActiveStep: PropTypes.func,
	activeSubstep: PropTypes.number,
};

export default EventDaysForm;
