import { html } from 'common-tags';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import * as Item from 'app/backbone/models/account/accreditation/Item';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';
import ItemsOverviewCard from './cards/ItemsOverviewCard.view';
import ItemGroupsOverviewCard from '../groups/views/cards/ItemGroupsOverviewCard.view';

export default class ItemsOverview extends Content {

    ui() {
        return {
            itemsCard: '#items-card',
            itemGroupsCard: '#item-groups-card'
        };
    }

    optionTypes() {
        return {
            items: OptionTypes.instanceOf(Item.PageableCollection).isRequired,
            itemGroups: OptionTypes.instanceOf(ItemGroup.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        super.resolveOptions(options);
        // Subviews
        this.itemsCard = this.initItemsCard();
        this.itemGroupsCard = this.initItemGroupsCard();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.itemsCard, this.ui.itemsCard);
        this.assignSubview(this.itemGroupsCard, this.ui.itemGroupsCard);
        return this.delegateEvents();
    }

    template() {
        return html`
            <div class="grid grid--gutters">
                <div class="grid-cell">
                    <div id="items-card" class="card"></div>
                </div>
                <div class="grid-cell">
                    <div id="item-groups-card" class="card"></div>
                </div>
            </div>`;
    }

    remove() {
        super.remove();
        this.itemsCard.remove();
        this.itemGroupsCard.remove();
    }

    initItemsCard() {
        const { items, itemGroups } = this;
        const title = 'Items';
        return new ItemsOverviewCard({ items, itemGroups, title });
    }

    initItemGroupsCard() {
        const { itemGroups } = this;
        const title = 'Groups';
        return new ItemGroupsOverviewCard({ itemGroups, title });
    }

}

