import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TetherTooltip from 'tether-tooltip';

export const OPEN_DELAY = 300;
export const DEFAULT_POSITION = 'top center';

export default class Tooltip extends React.Component {

    componentDidMount() {
        this.createNewTooltip(this.props.content);
        this.toggleTooltip(this.props.isOpened);
    }

    componentWillReceiveProps(newProps) {
        const { content, isOpened } = newProps;
        if (content !== this.props.content) {
            this.createNewTooltip(content);
        }
        if (isOpened !== this.props.isOpened) {
            this.toggleTooltip(isOpened);
        }
    }

    componentWillUnmount() {
        this.destroyTooltip();
    }

    createNewTooltip(content) {
        const { position = DEFAULT_POSITION, isAlert } = this.props;
        const target = ReactDOM.findDOMNode(this);
        if (typeof target === 'undefined') return;
        if (typeof content === 'undefined') return;
        this.destroyTooltip();
        this.tooltip = new TetherTooltip({
            openDelay: OPEN_DELAY,
            target,
            position,
            content,
            classes: classNames({
                'tooltip-alert': isAlert
            })
        });
    }

    destroyTooltip() {
        const { tooltip } = this;
        if (typeof tooltip === 'undefined') return;

        // Hack to avoid bug on destroy when tooltip is not opened yet (https://github.com/HubSpot/drop/pull/153)
        if (tooltip.drop.isOpened()) tooltip.destroy();
        else tooltip.drop.on('open', () => { tooltip.destroy(); });
    }

    toggleTooltip(isOpened = false) {
        const { tooltip } = this;
        if (typeof tooltip === 'undefined') return;
        if (isOpened) tooltip.open();
        else tooltip.close();
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

Tooltip.propTypes = {
    children: PropTypes.node,
    content: PropTypes.string,
    isOpened: PropTypes.bool,
    isAlert: PropTypes.bool,
    position: PropTypes.string
};
