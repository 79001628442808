var $ = require('jquery');

var RedirectConfirmButton = require('app/backbone/components/buttons/RedirectConfirmButton.view');

module.exports = RedirectConfirmButton.extend({

    events: {
        'click' : 'openConfirmModal'
    },

    options: [
        'confirmModal!'
    ],

    openConfirmModal(e) {
        var role = $(e.currentTarget).data('role');
        if (role) this.setModalValue('role', role);
        this._super(e);
    }
});
