import Backgrid from 'backgrid';

export default class SelectableRow extends Backgrid.Row {

    className() {
        return 'selectable'
    }

    events() {
        return {
            'click': 'onClick',
            'mouseover': 'onMouseOver',
            'mouseout': 'onMouseOut'
        };
    }

    onClick(e) {
        this.model.collection.trigger('backgrid:select', this.model, e.ctrlKey || e.metaKey);
    }

    onMouseOver() {
        this.$el.addClass('selected');
    }

    onMouseOut() {
        this.$el.removeClass('selected');
    }

}
