import { BROADCAST_TYPES } from '../../../middleware/broadcasting';
import {
    addEntityToCollection,
    removeEntityFromCollection,
    removeEntityForEachCollection
} from './util';

export const broadcastReceivedReducer = (state, action) => {
    switch (action.eventType) {
        case BROADCAST_TYPES.DELETED: {
            return removeEntityFromCollection(state, action,)
        }
        case BROADCAST_TYPES.CREATED: {
            return addEntityToCollection(state, action)
        }
    }
    return state
};

export const broadcastReceivedReducerWithKey = (state, action, key) => {
    switch (action.eventType) {
        case BROADCAST_TYPES.DELETED: {
            return removeEntityForEachCollection(state, action)
        }
        case BROADCAST_TYPES.CREATED: {
            if (typeof state[key] === 'undefined') {
                return state
            }
            return {
                ...state,
                [key]: addEntityToCollection(state[key], action)
            };
        }
    }
    return state
};
