import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../utils/Icon';

const DialogCard = ({children, setCancelDialogOpen}) => (
    <div className={'dialog-card'}>
			<button className={'dialog-card__cancel-button'} onClick={() => setCancelDialogOpen(false)}>
				<Icon
						size={24}
						name="close"
						style={{marginBottom: '-10px'}}
				/>
			</button>
			{children}
		</div>
);

DialogCard.propTypes = {
	children: PropTypes.node.isRequired,
	setCancelDialogOpen: PropTypes.func.isRequired,
};

export default DialogCard;
