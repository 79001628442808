import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select booking...';
export const NO_RESULTS_PLACEHOLDER = 'No bookings found.';

const toOption = ({
    id,
    artist,
    status,
    type
}) => ({
    value: id,
    label: artist.name,
    status: status.title,
    statusColor: status.color,
    type: type.name
});

const colorSquareStyle = backgroundColor => ({
    display: 'inline-block',
    width: '0.75rem',
    height: '0.75rem',
    backgroundColor,
    marginRight: '0.5rem',
    marginBottom: '-1px'
});

const BookingOption = ({
    option = {}
}) => (
    <span>
        <span style={{ marginRight: '1rem' }}>{option.label}</span>
        <strong style={{ marginRight: '1rem' }}>{option.type}</strong>
        <span style={colorSquareStyle(option.statusColor)} />
        {option.status}
    </span>
)

const SelectBookingStatus = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    isLoading = false,
    disabled = false,
    value,
    bookings = [],
    onChange = () => {}
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        isLoading={isLoading}
        options={bookings.map(toOption)}
        onChange={onChange}
        renderOption={option => <BookingOption option={option} />}
    />
);

SelectBookingStatus.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    bookings: PropTypes.array,
    onChange: PropTypes.func
};

export default SelectBookingStatus;
