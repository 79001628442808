import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';

const { Header, Content, Footer } = Card;
const { Title } = Header;
const { Error } = Footer;

export const TITLE = 'Sections';

export const SectionsCard = ({
    artistTypeName,
    isArtistTypesEnabled = true,
    footer = {},
    children,
}) => (
    <Card>
        <Header>
            <Title>
                {TITLE}
                {isArtistTypesEnabled && (
                    <small>{artistTypeName}</small>
                )}
            </Title>
        </Header>
        <Content>{children}</Content>
        <Footer>
            {footer.message &&
                <Error>{footer.message}</Error>
            }
        </Footer>
    </Card>
);

SectionsCard.propTypes = {
    artistTypeName: PropTypes.string,
    isArtistTypesEnabled: PropTypes.bool,
    footer: PropTypes.object,
    children: PropTypes.node,
};

export default SectionsCard;
