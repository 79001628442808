import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Field, FieldArray, Form, Formik } from "formik";
import DialogContainer from "../dialogContainer/DialogContainer";
import DialogCard from "../dialogCard/DialogCard";
import DialogCardBottom from "../dialogCard/DialogBottom";
import FormField from "../formfields/FormField";
import Button from "../button/Button";
import Checkbox from "../checkbox/Checkbox";
import { countries } from "country-data";

const AddingStagesValidation = () =>
  yup.object({
    name: yup.string().required(),
    capacity: yup.number().min(1),
  });

const AddingStagesDialog = ({ addStage, stage, ...props }) => {
  return (
    <DialogContainer>
      <DialogCard {...props}>
        <Formik
          initialValues={{
            name: stage.name,
            capacity: stage.capacity,
            particularDays: stage.particularDays,
            days: stage.days,
            differentAddress: stage.differentAddress,
            address: stage.address,
          }}
          validationSchema={AddingStagesValidation()}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            const { isValid, values } = formik;

            return (
              <Fragment>
                <div className={"dialog-inner-container"}>
                  <h3 className="dialog-title">
                    {stage.id ? "Update stage" : "Add stage"}
                  </h3>
                  <Form>
                    <Field name="name" label="Name" required as={FormField} />
                    <Field
                      name="capacity"
                      label="Capacity"
                      type="number"
                      as={FormField}
                    />
                    <Field
                      as={Checkbox}
                      name="particularDays"
                      id="particularDays"
                      label="This stage is only open on particular days of the event"
                    />
                    {values.particularDays && (
                      <FieldArray name="days">
                        <div className="stage-dialog__days-container">
                          {values.days.map((day, index) => (
                            <Field
                              as={Checkbox}
                              name={`days.${index}.present`}
                              id={`days.${index}.present`}
                              label={day.name}
                              key={`day-${index}`}
                            />
                          ))}
                        </div>
                      </FieldArray>
                    )}
                    {values.particularDays &&
                      values.days.find((day) => day.present) && (
                        <FieldArray name="days">
                          {(_) => (
                            <div className="stage__days-container">
                              {values.days.map((day, index) => {
                                return day.present ? (
                                  <div
                                    className="stage-dialog__day-name__container"
                                    key={`dayname-${index}`}
                                  >
                                    {day.name}
                                    <Field
                                      className="stage-dialog__day-name__input"
                                      name={`days.${index}.newName`}
                                      placeholder="Stagename on this day"
                                    />
                                  </div>
                                ) : null;
                              })}
                            </div>
                          )}
                        </FieldArray>
                      )}
                    <Field
                      as={Checkbox}
                      name="differentAddress"
                      id="differentAddress"
                      label="This stage has a different address than the venue"
                    />
                    {values.differentAddress && (
                      <Fragment>
                        <div className={"dialog__input-line"}>
                          <Field
                            name="address.address_line_1"
                            label="Street"
                            as={FormField}
                          />
                          <Field
                            name="address.address_line_2"
                            label="Addressline 2"
                            as={FormField}
                          />
                        </div>
                        <div className={"dialog__input-line"}>
                          <Field
                            name="address.zip"
                            label="Zip/Postal code"
                            as={FormField}
                          />
                          <Field
                            name="address.city"
                            label="City"
                            as={FormField}
                          />
                        </div>
                        <div className={"dialog__input-line"}>
                          <Field
                            name="address.state"
                            label="State/Province/Region"
                            as={FormField}
                          />
                          <label
                            htmlFor="date_format"
                            className="form_field"
                            style={{ width: "350px" }}
                          >
                            <span className="form-field__label">Country</span>
                            <Field
                              as="select"
                              id="country"
                              name="address.country"
                            >
                              <option value="">Select your date format</option>
                              {countries.all.map(({ alpha2, name }, i) => (
                                <option
                                  key={`country-${i}-${alpha2}`}
                                  value={alpha2}
                                >
                                  {name}
                                </option>
                              ))}
                            </Field>
                          </label>
                        </div>
                      </Fragment>
                    )}
                  </Form>
                </div>
                <DialogCardBottom>
                  <Button
                    label={stage.id ? "Update" : "Add"}
                    variant="primary"
                    type="button"
                    onClick={() => addStage({ ...values, id: stage.id })}
                    isDisabled={!isValid}
                  />
                </DialogCardBottom>
              </Fragment>
            );
          }}
        </Formik>
      </DialogCard>
    </DialogContainer>
  );
};

AddingStagesDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
  addStage: PropTypes.func.isRequired,
  stage: PropTypes.any.isRequired,
};

export default AddingStagesDialog;
