import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import template from './Tab.hbs';

export default class Tab extends BaseView {

    get template() {
        return template;
    }

    tagName() {
        return 'a';
    }

    className() {
        return 'card--header_tab';
    }

    events() {
        return {
            'click': 'selectTab'
        };
    }

    OptionTypes() {
        return {
            name: OptionTypes.string.isRequired,
            iconClassName: OptionTypes.string,
            title: OptionTypes.string,
            isActive: OptionTypes.boolean
        };
    }

    optionDefaults() {
        return {
            isActive: false
        };
    }

    initialize(options) {
        this.resolveOptions(options);
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {
                title: this.title,
                iconClassName: this.iconClassName
            },
            model: {}
        };
    }

    resetActive() {
        this.isActive = false;
    }

    selectTab() {
        this.trigger('select', this);
    }
}
