import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Day from 'app/backbone/models/event/Day';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import SelectDaysTable from '../tables/SelectDaysTable.view';

export default class SelectDaysCard extends Card {

    optionTypes() {
        const { title, hasMaxHeight } = super.optionTypes();
        return {
            title,
            hasMaxHeight,
            profile: OptionTypes.instanceOf(Profile.Model).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // Listeners
        this.listenTo(this.profile, 'validated', this.onValidated);
    }

    initContent() {
        const { days } = this;
        return new SelectDaysTable({ days });
    }

    onValidated(isModelValid, model, errors) {
        if (errors.days) this.addError(errors.days);
        else this.removeError();
    }

}
