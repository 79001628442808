import React from 'react'
import Protip from 'app/react/components/util/Protip'

const PageLinkTagsProtip = () => (
    <Protip>
        You can link to any of your Pages using the %production_website_link_% tag, followed by the url of the page. E.g:
        <span className="badge">%production_website_link_form%</span>
    </Protip>
);

export default PageLinkTagsProtip;
