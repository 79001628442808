import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as actions from 'app/react/state/actions/_index';
import TicketsView, {
    FORM_ID,
    transformTicketSettingsProfilesToFormProfiles
} from 'app/react/views/event/settings/accreditation/tickets/views/TicketSettingsViewContainer';
import {
    BASE_PATH as PARENT_BASE_PATH
} from './Controller';
import * as Profile from 'app/react/entities/events/accreditation/profiles/index';
import * as IntegrationEvent from 'app/react/entities/accounts/integrations/events/index';
import * as TicketProvider from 'app/react/entities/events/accreditation/tickets/provider/index';
import * as TicketSettings from 'app/react/entities/events/accreditation/tickets/settings/index';

export const BASE_PATH = 'tickets';

// Backbone Controller
export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        // Routes
        this.router.route(`${PARENT_BASE_PATH}/${BASE_PATH}`, null, this.tickets.bind(this));
    }

    tickets(eventId) {
        const { app, store, dispatch } = this;

        dispatch(Profile.thunks.fetchAllForCurrentContext());
        Promise.all([
            dispatch(TicketProvider.actions.loadAll()),
            dispatch(TicketSettings.actions.loadOne())
        ]).then(() => {
            const state = store.getState();
            const settings = TicketSettings.stateHelpers.getOne(state) || {};
            const values = {
                ...settings,
                profiles: transformTicketSettingsProfilesToFormProfiles(settings.profiles)
            };
            dispatch(actions.registerFormComponentState(FORM_ID, { values }));
            const savedTicketProvider = TicketProvider.stateHelpers.getOne(state, settings.ticketProvider);
            if (savedTicketProvider && savedTicketProvider.usesIntegration) {
                dispatch(IntegrationEvent.actions.loadAllByTicketProviderId(savedTicketProvider.id));
            }
        });
        app.renderContentComponent(React.createElement(TicketsView, { store }));
    }

}
