import { decamelize } from 'humps';
import {
    getSingleErrorMessageFromResponseJSON,
    getFormFieldErrorsFromResponseJSON
} from 'app/react/helpers/_index';
import { getDataFromAPI } from '../data_sources/rest';
import { omitAllUndefinedKeys } from 'app/react/helpers/_index';

export const includeParam = (includes = []) => `include=${includes.join()}`;
export const limitParam = limit => `limit=${limit}`;
export const pageParam = limit => `page=${limit}`;
export const limitAllParam = 'limit=all';
export const sortParam = (key = '', order = 1) => `sort=${order === -1 ? '-' : ''}${encodeURIComponent(key)}`;
export const filterParam = (filter = {}) =>
    Object.keys(filter).map(key => `${encodeURIComponent(decamelize(key))}=${encodeURIComponent(filter[key])}`).join('&');

export const searchParam = searchTerm => {
    if (typeof searchTerm === 'undefined') {
        return ''
    }
    return `search=${encodeURIComponent(searchTerm)}`;
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
export const METHODS = {
    GET: 'GET',
    DELETE: 'DELETE',
    POST: 'POST',
    PUT: 'PUT'
};

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
    const callAPI = action[CALL_API];
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    let { jwt, endpoint } = callAPI;
    const {
        schema,
        types,
        method = METHODS.GET,
        id,
        body,
        requestedPage,
        requestedPageSize,
        requestedFilters,
        requestedSearch,
        requestedSortKey,
        requestedSortOrder,
        silentRequest = false,
        doNotMergeResponseWithCache
    } = callAPI;

    if (typeof jwt === 'undefined') {
        jwt = store.getState().tokens.jwt;
    }
    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
    }
    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
    }
    if (!schema) {
        throw new Error('Specify one of the exported Schemas.');
    }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    const actionWith = data => {
        const finalAction = {
            ...action,
            ...data,
            id,
            schema,
            method,
            doNotMergeResponseWithCache
        };
        delete finalAction[CALL_API];
        return omitAllUndefinedKeys(finalAction);
    };

    const [requestType, successType, failureType] = types;
    if (!silentRequest) {
        next(actionWith({
            type: requestType,
            requestedPage,
            requestedPageSize,
            requestedFilters,
            requestedSearch,
            requestedSortKey,
            requestedSortOrder
        }));
    }

    return getDataFromAPI(jwt, endpoint, schema, method, body)
        .then(
            response => next(actionWith({
                type: successType,
                isSuccess: true,
                isFailure: false,
                response
            })),
            error => next(actionWith({
                type: failureType,
                isFailure: true,
                isSuccess: false,
                errorMessage: getSingleErrorMessageFromResponseJSON(error),
                errors: getFormFieldErrorsFromResponseJSON(error),
                error,
            }))
        );
};
