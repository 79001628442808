import { Model, Collection } from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'rider_type'
})
class
RiderType extends BaseModel {

    urlRoot() {
        return `/api/rider-types`;
    }

}

@Collection({
    key: 'rider_types',
    model: RiderType
})
class RiderTypeCollection extends BaseCollection {

    url() {
        return `/api/rider-types`;
    }

}

export {
    RiderType as Model,
    RiderTypeCollection as Collection
};
