import React  from 'react'
import moment, { ISO_8601 } from 'moment'
import { Tooltip } from 'ui-kit'
import { Checkbox } from 'app/react/components/inputs/_index'

function checkInTooltipContent({ name, checkedInMoment, dateFormatShort, timeFormat }) {
    if (checkedInMoment) {
        return `Checked in on ${checkedInMoment.format(dateFormatShort)} at ${checkedInMoment.format(timeFormat)}. Thick this checkbox to undo the check in.`;
    }

    return `Check in ${name}.`;
}

const CheckInCheckboxCell = ({
    isLoading,
    attendee,
    checkedInAt,
    dateFormatShort,
    timeFormat,
    onToggleCheckIn,
}) => {
    const name = `${attendee.firstName} ${attendee.lastName}`;
    const checkedInMoment = checkedInAt ? moment.parseZone(checkedInAt, ISO_8601) : null;

    function handleClick(e) {
        e.stopPropagation();
        onToggleCheckIn(e);
    }

    return (
        <td onClick={handleClick}>
            <Tooltip renderContent={() => checkInTooltipContent({ name, checkedInMoment, dateFormatShort, timeFormat })}>
                <Checkbox
                    id={`attendeeCheckbox-${attendee.__typename}${attendee.id}`}
                    checked={checkedInAt !== null}
                    loading={isLoading}
                />
            </Tooltip>
        </td>
    );
};

export default CheckInCheckboxCell;
