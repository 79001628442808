import React from 'react';
import PropTypes from 'prop-types';
import { AlertBox } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { UpdateButtonRow, ConfirmModal } from 'app/react/components/_index';
import ContentHeader, { TABS } from '../../ContentHeader';
import TicketSettingsCard from './TicketSettingsCard';
import TicketMapperCard from './TicketMapperCard';

const { Content } = ContentWrapper;

const LOCKED_WARNING_MESSAGE =
    `To avoid ticketing errors, these settings are locked. Please contact Beatswitch support 
    (support@beatswitch.com or the chat in the bottom right) to unlock these settings.`;
const CONFIRM_MODAL_TITLE = 'Lock Settings';
const CONFIRM_MODAL_TEXT =
    `To avoid ticketing errors, the ticket settings will be locked after saving these changes. 
    You will need to contact Beatswitch support to unlock these settings. 
    Are you sure you want to save these changes?`;

const TicketSettingsView = ({
    store,
    eventId,

    isFetching,
    isFetchingIntegrationEvents,

    ticketProviderOptions,
    integrationEventOptions,
    integrationEventProfileOptions,

    savedTicketSettingsProfiles,

    hasSelectedTicketProviderThatUsesIntegration,
    isLocked = false,
    selectedTicketProvider = {},
    selectedTicketBackground,
    selectedIntegrationEvent = {},
    selectedProfilesMapping = {},

    confirmModalOpened = false,
    onConfirmModalConfirmed = () => {},
    onConfirmModalClosed = () => {},
    setTicketProvider = () => {},
    setTicketBackground = () => {},
    addTicketBackground = () => {},
    setIntegrationEvent = () => {},
    setIntegrationEventProfile = () => {},
    updateTicketSettings = () => {}
}) => (
    <ContentWrapper store={store}>
        <ContentHeader activeTab={TABS.TICKETS.ID} eventId={eventId} />
        <Content isLoading={isFetching} >
            { isLocked ?
                <AlertBox type={AlertBox.WARNING}>{LOCKED_WARNING_MESSAGE}</AlertBox> :
                null
            }
            <TicketSettingsCard
                isLocked={isLocked}
                ticketProviderOptions={ticketProviderOptions}
                selectedTicketProviderId={selectedTicketProvider.id}
                selectedTicketBackground={selectedTicketBackground}
                onChangeTicketProvider={setTicketProvider}
                onChangeTicketBackground={setTicketBackground}
                onUploadTicketBackground={addTicketBackground}
            />
            { hasSelectedTicketProviderThatUsesIntegration ?
                <TicketMapperCard
                    isLocked={isLocked}
                    isFetchingIntegrationEvents={isFetchingIntegrationEvents}
                    selectedTicketProviderName={selectedTicketProvider.name}
                    selectedIntegrationEventId={selectedIntegrationEvent.id}
                    selectedProfilesMapping={selectedProfilesMapping}
                    savedTicketSettingsProfiles={savedTicketSettingsProfiles}
                    integrationEventOptions={integrationEventOptions}
                    integrationEventProfileOptions={integrationEventProfileOptions}
                    onChangeIntegrationEvent={setIntegrationEvent}
                    onChangeIntegrationEventProfile={setIntegrationEventProfile}
                /> : null
            }
            { !isLocked ?
                <UpdateButtonRow
                    onUpdate={updateTicketSettings}
                /> : null
            }
        </Content>
        <ConfirmModal
            isOpened={confirmModalOpened}
            onConfirmed={onConfirmModalConfirmed}
            onClosed={onConfirmModalClosed}
            title={CONFIRM_MODAL_TITLE}
            text={CONFIRM_MODAL_TEXT}
        />
    </ContentWrapper>
);

TicketSettingsView.propTypes = {
    store: PropTypes.object.isRequired,
    // State
    eventId: PropTypes.number.isRequired,

    isFetching: PropTypes.bool.isRequired,
    isFetchingIntegrationEvents: PropTypes.bool.isRequired,

    ticketProviderOptions: PropTypes.array,
    integrationEventOptions: PropTypes.array,
    integrationEventProfileOptions: PropTypes.array,

    savedTicketSettingsProfiles: PropTypes.array,

    hasSelectedTicketProviderThatUsesIntegration: PropTypes.bool,
    isLocked: PropTypes.bool,
    selectedTicketProvider: PropTypes.object,
    selectedTicketBackground: PropTypes.object,
    selectedIntegrationEvent: PropTypes.object,
    selectedProfilesMapping: PropTypes.object,

    confirmModalOpened: PropTypes.bool,

    onConfirmModalConfirmed: PropTypes.func,
    onConfirmModalClosed: PropTypes.func,
    setTicketProvider: PropTypes.func,
    setTicketBackground: PropTypes.func,
    addTicketBackground: PropTypes.func,
    setIntegrationEvent: PropTypes.func,
    setIntegrationEventProfile: PropTypes.func,
    updateTicketSettings: PropTypes.func
};

export default TicketSettingsView;
