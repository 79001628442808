var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <form>\n        <div class=\"input-group\">\n            <label for=\"days\">Select day</label>\n            <select name=\"days\" />\n        </div>\n        <div class=\"input-group\">\n            <label for=\"days\">Select group</label>\n            <select name=\"groups\" />\n        </div>\n        <div class=\"input-group\">\n            <label for=\"bookings\">Select booking</label>\n            <select name=\"bookings\" />\n        </div>\n        <label for=\"include_financial_data\">\n            <input type=\"checkbox\" id=\"include_financial_data\" name=\"include_financial_data\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.includeFinancialData : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            Include financial data\n        </label>\n    </form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.ModalTitle,depth0,{"name":"ModalTitle","hash":{"title":"Download Booking items"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials.ModalBody,depth0,{"name":"ModalBody","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div class=\"modalButtonsContainer\">\n    <a class=\"button download submit\">\n        Download\n    </a>\n</div>\n";
},"usePartial":true,"useData":true});