import React from 'react';
import propTypes from 'prop-types';
import { Spinner } from 'ui-kit';

const Loading = ({ height }) => (
    <div
        className="flexContainerContentCentered text-align-center margin-large-top margin-large-bottom"
        style={{ height }}
    >
        <Spinner />
    </div>
);

Loading.propTypes = {
    height: propTypes.string,
};

export default Loading;
