import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loading from 'app/react/components/util/Loading';

export const Content = ({
    children,
    isLoading
}) => (
    <div id="content-container" className={classNames({ isLoading })}>
        {isLoading 
            ? <Loading /> 
            : children
        }
    </div>
);

Content.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.node,
};

export default Content;
