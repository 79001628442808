export const events = () => 'events';

export const eventsArchive = () => 'events/archive';

export const eventsCreateMultiDay = () => 'events/create-old';

export const accommodations = () => 'crm/accommodations';

export const locations = () => 'crm/locations';

export const locationsRoutesCreate = (
    routeId = ':location'
) => `crm/locations/${routeId}/routes/create`;

export const contactsCreate = () => 'crm/contacts/create';

export const contactsUpdate = (
    contactId = ':contact'
) => `crm/contacts/${contactId}/edit`;

export const companies = () => 'crm/companies/overview';

export const companiesUpdate = (
    companyId = ':company'
) => `crm/companies/${companyId}/edit`;

export const companiesDetail = (
    companyId = ':company'
) => `crm/companies/${companyId}`;

export const artists = () => 'crm/artists';

export const artistsDetail = (
    artistId = ':artist'
) => `crm/artists/${artistId}`;

export const contacts = () => 'crm/contacts';

export const createArtistFile = (
    artistId = ':artist'
) => `crm/artists/${artistId}/files/create`;

export const users = () => 'users';

export const roles = () => 'users/roles';

export const rolesDetail = (
    roleId = ':role'
) => `users/roles/${roleId}/edit`;
