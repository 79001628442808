import React from 'react'
import { isObject } from 'underscore'
import { GroupedColumnsTable } from 'ui-kit'
import { getReservationDays } from 'app/react/helpers/reservations'
import FirstColumnsTable from './FirstColumnsTable'
import DaysColumnsTable from './DaysColumnsTable'

const { ColumnGroup } = GroupedColumnsTable;

const ReservationDashboardTable = (props) => {
    const {
        accommodations = [],
        days = [],
        currencySymbol,
        dateFormatShort,
        expandedRows = [],
        onToggleRowExpansion,
    } = props;
    const reservations = accommodations.flatMap(accommodation => accommodation.reservations).filter(isObject);
    const reservationDays = getReservationDays(reservations, days);
    return (
        <GroupedColumnsTable>
            <ColumnGroup>
                <FirstColumnsTable {...props} />
            </ColumnGroup>
            <ColumnGroup isScrollable maxWidth="60%">
                <DaysColumnsTable
                    {...props}
                    reservationDays={reservationDays}
                />
            </ColumnGroup>
        </GroupedColumnsTable>
    );
};

export default ReservationDashboardTable;
