// Actions
export const MERGE_CURRENT_CONTEXT = 'MERGE_CURRENT_CONTEXT';
export const SET_CURRENT_CONTEXT_ID = 'SET_CURRENT_CONTEXT_ID';
export const UNSET_CURRENT_CONTEXT_ID = 'SET_CURRENT_CONTEXT_ID';

export const mergeCurrentContext = (context) => ({
    type: MERGE_CURRENT_CONTEXT,
    context
});

export const setCurrentContextId = (key, id) => ({
    type: SET_CURRENT_CONTEXT_ID,
    key,
    id
});

export const unsetCurrentContextId = (key) => ({
    type: UNSET_CURRENT_CONTEXT_ID,
    key
});
