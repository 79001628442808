import BaseView from 'app/backbone/components/Base.view'
import Datepicker from 'app/backbone/components/inputs/Datepicker.view'

export default class GuestListFormView extends BaseView {

    ui() {
        return {
            birthdayDatePicker: '[name=birthday]',
            firstNameField: '[name=firstname]',
            lastNameField: '[name=lastname]',
            emailField: '[name=email]',
            viaField: '[name=via]',
            allCheckboxes: '[type=checkbox]',
            guestListTable: '#guest_list_table',
            guestListTableHeader: '#guest_list_table .card--header',
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderBirthdayDatePicker();
        this.attendeeCheckboxHandler();
        this.guestListTableCollabsibleToggler();
        return this.delegateEvents();
    }

    renderBirthdayDatePicker() {
        if (!this.ui.birthdayDatePicker.length) {
            return;
        }

        this.birthdayDatePicker = new Datepicker({
            el: this.ui.birthdayDatePicker,
            max: true,
        });
        this.birthdayDatePicker.render();
    }

    attendeeCheckboxHandler() {
        const self = this;
        this.ui.allCheckboxes.on('change', function () {
            setTimeout(function () {
                self.fieldStateManager()
            }, 100); //small timeout else it does not get the correct checked amount of attendees...
        });
    }

    guestListTableCollabsibleToggler() {
        const self = this;
        this.ui.guestListTableHeader.on('click', function () {
            self.ui.guestListTable.toggleClass('collapsed');
        })
    }

    fieldStateManager() {
        const shouldDisable = $("[name=attendees\\[\\]]:checked").length > 0;
        this.ui.firstNameField.prop('disabled', shouldDisable);
        this.ui.lastNameField.prop('disabled', shouldDisable);
        this.ui.emailField.prop('disabled', shouldDisable);
        this.ui.viaField.prop('disabled', shouldDisable);
        this.ui.birthdayDatePicker.prop('disabled', shouldDisable);
    }

}
