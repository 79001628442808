import OptionTypes from 'app/backbone/helpers/OptionTypes';
import CardHeader from '../CardHeader.view';
import Tabs from './Tabs.view';
import template from './TabsCardHeader.hbs';

export default class TabsCardHeader extends CardHeader {

    get template() {
        return template;
    }

    optionTypes() {
        return {
            tabs: OptionTypes.array,
            actions: OptionTypes.array
        };
    }

    optionDefaults() {
        return {
            tabs: [],
            actions: []
        };
    }

    ui() {
        return {
            tabsContainer: '.card--header_tabs'
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.tabsContainer = this.initTabsView();
        this.listenTo(this.tabsContainer, 'select', (tab) => { this.tabSelected(tab); });
        this.actions = this.initHeaderActions();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.tabsContainer, this.ui.tabsContainer);
        this.renderActions();
        return this.delegateEvents();
    }

    initTabsView() {
        const { tabs } = this;
        return new Tabs({ tabs });
    }

    tabSelected(tab) {
        this.trigger('tab:select', tab);
    }
}
