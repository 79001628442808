var $ = require('jquery'),
    Backbone = require('backbone');

var RedirectConfirmButton = Backbone.View.extend({

    events: {
        'click' : 'openConfirmModal'
    },

    options: [
        'confirmModal!'
    ],

    initialize(options) {
        this.setOptions(options);
    },

    openConfirmModal(e) {
        e.stopPropagation();
        const target = $(e.currentTarget);
        this.setModalValue('title', target.data('confirm-modal-title'));
        this.setModalValue('body', target.data('confirm-modal-body'));
        this.setModalValue('confirmText', target.data('confirm-modal-confirm'));
        this.setModalValue('cancelText', target.data('confirm-modal-cancel'));
        this.setModalValue('confirmClass', target.data('confirm-modal-confirm-class'));
        this.setModalValue('confirmHref', target.data('confirm-href'));
        this.setModalValue('cancelHref', target.data('cancel-href'));
        this.confirmModal.open();
    },

    setModalValue(field, data) {
        if (data) this.confirmModal[field] = data;
    }
});

module.exports = RedirectConfirmButton;
