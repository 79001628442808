import React from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import { Grid, SearchInput } from 'ui-kit';
import { Filter } from './topBarComponents';
import SearchSelectUsers from 'app/react/views/event/users/SearchSelectUsersWithData';
import SelectBookingStatus from '../../../performances/views/selects/SelectBookingStatus';

const { Cell } = Grid;

const DAY_FILTER_LABEL = 'Day:';
const STAGE_FILTER_LABEL = 'Stage:';
const ARTIST_TYPE_FILTER_LABEL = 'Artist Type:';
const ALL_DAYS_OPTION = { value: 0, label: 'All Days' };
const ALL_STAGES_OPTION = { value: 0, label: 'All Stages' };
const ALL_ARTIST_TYPES_OPTION = { value: 0, label: 'All Artist Types' };
const ALL_BOOKING_STATUSES_PLACEHOLDER = 'All Booking Statuses';

const toOption = ({ id, name }) => ({
	value: id,
	label: name,
});

const whereDayId = (dayId) => (stage) =>
	typeof dayId === 'undefined' || dayId === 0 || stage.day === dayId;

const BookingTableTopBar = ({
	isArtistTypesEnabled = true,
	days = [],
	stages = [],
	artistTypes = [],
	bookingStatuses = [],

	selectedAdvancingUserId,
	selectedDayId = 0,
	selectedStageId,
	selectedArtistTypeId,
	selectedBookingStatusId,
	searchValue,
	isFetchingBookings = false,

	onChangeFilter = () => {},
	onSearch = () => {},
	onClearSearch = () => {},
}) => {
	return (
		<Grid hasGutters={false} className='margin-tiny-bottom'>
			<Cell>
				<Grid hasGutters={false}>
					<Cell isAutoSized>
						<label style={{ width: '20rem', marginRight: '0.5rem' }}>
							<SelectBookingStatus
								placeholder={ALL_BOOKING_STATUSES_PLACEHOLDER}
								isClearable={true}
								bookingStatuses={bookingStatuses}
								value={selectedBookingStatusId}
								onChange={(value) =>
									onChangeFilter('bookingStatus', value || 0)
								}
								isFetchingBookings={isFetchingBookings}
							/>
						</label>
					</Cell>
					<Cell isAutoSized>
						<Filter
							label={DAY_FILTER_LABEL}
							width='150px'
							options={[
								ALL_DAYS_OPTION,
								...days.sort(by('start', 1)).map(toOption),
							]}
							value={selectedDayId}
							onChange={(value) => onChangeFilter('day', parseInt(value, 10))}
							isFetchingBookings={isFetchingBookings}
						/>
					</Cell>
					{selectedDayId !== 0 && (
						<Cell isAutoSized>
							<Filter
								label={STAGE_FILTER_LABEL}
								width='150px'
								options={[
									ALL_STAGES_OPTION,
									...stages
										.filter(whereDayId(selectedDayId))
										.sort(by('name', 1))
										.map(toOption),
								]}
								value={selectedStageId}
								onChange={(value) =>
									onChangeFilter('stage', parseInt(value, 10))
								}
								isFetchingBookings={isFetchingBookings}
							/>
						</Cell>
					)}
					{isArtistTypesEnabled && (
						<Cell isAutoSized>
							<Filter
								label={ARTIST_TYPE_FILTER_LABEL}
								width='150px'
								options={[
									ALL_ARTIST_TYPES_OPTION,
									...artistTypes.map(toOption),
								]}
								value={selectedArtistTypeId}
								onChange={(value) =>
									onChangeFilter('artistType', parseInt(value, 10))
								}
								isFetchingBookings={isFetchingBookings}
							/>
						</Cell>
					)}
					<Cell isAutoSized>
						<label style={{ width: '20rem' }}>
							<SearchSelectUsers
								value={selectedAdvancingUserId}
								onChange={(value) =>
									onChangeFilter('advancingUser', value || '')
								}
								isFetchingBookings={isFetchingBookings}
								isDisabled={isFetchingBookings}
							/>
						</label>
					</Cell>
				</Grid>
			</Cell>
			<Cell isAutoSized isContentJustifiedAtEnd width='250px'>
				<SearchInput
					isExpanded
					value={searchValue || ''}
					placeholder='Artist or performance name'
					info='You can search for bookings by artist or performance name.'
					onSearch={onSearch}
					onClear={onClearSearch}
				/>
			</Cell>
		</Grid>
	);
};

BookingTableTopBar.propTypes = {
	booking: PropTypes.object,
	onEdit: PropTypes.func,
};

export default BookingTableTopBar;
