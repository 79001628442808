import Backgrid from 'backgrid';
import Dotty from 'dotty';
import { html } from 'common-tags';

export default class SubModelCell extends Backgrid.Cell {

    className() {
        return 'sub-model-cell';
    }

    initialize(options = {}) {
        super.initialize(options);
        if (!this.hasSubModel()) throw new Error(`Model is not found for path '${this.path}'`);
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        return this.delegateEvents();
    }

    template(vm) {
        const { hasLabel, labelClassName } = vm.view;
        return html`
            <span class="margin-small-right ${hasLabel ? 'label' : ''} ${labelClassName || ''}">
                ${vm.attribute || ''}
            </span>
        `;
    }

    viewModel() {
        return {
            view: {
                hasLabel: this.hasLabel,
                labelClassName: this.labelClassName
            },
            attribute: this.getSubModel().get(this.column.get('name'))
        };
    }

    hasSubModel() {
        return !! this.getSubModel();
    }

    getSubModel() {
        return Dotty.get(this.model, this.path);
    }
}
