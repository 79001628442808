import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import * as Event from 'app/backbone/models/event/Event';
import * as Day from 'app/backbone/models/event/Day';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import ProfileFormCard from './cards/ProfileFormCard.view';
import SelectDaysCard from './cards/SelectDaysCard.view';
import SelectZonesCard from './cards/SelectZonesCard.view';
import template from './Profile.hbs';

export default class ProfileView extends Content {

    get template() {
        return template;
    }

    ui() {
        return {
            profileCard: '#profile-card',
            daysCard: '#days-card',
            zonesCard: '#zones-card'
        };
    }

    optionTypes() {
        return {
            event: OptionTypes.instanceOf(Event.Model).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired,
            zones: OptionTypes.instanceOf(Zone.Collection).isRequired,
            profiles: OptionTypes.instanceOf(Profile.PageableCollection).isRequired,
            profile: OptionTypes.instanceOf(Profile.Model)
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.model = this.profile || this.newModel();
        // Subviews
        this.profileCard = this.initProfileCard();
        this.daysCard = this.initDaysCard();
        this.zonesCard = this.initZonesCard();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        if (!this.isSyncing) {
            this.unbindModel();
            this.bindModel();
            this.assignSubview(this.profileCard, this.ui.profileCard);
            this.assignSubview(this.daysCard, this.ui.daysCard);
            this.assignSubview(this.zonesCard, this.ui.zonesCard);
        }
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {
                canCreateAnother: true,
                syncingText: this.syncingText,
                placeholderHeight: this.placeholderHeight,
                isSyncing: this.isSyncing,
                isUpdating: this.isUpdating
            },
            model: this.model.attributes
        };
    }

    remove() {
        super.remove();
        this.profileCard.remove();
        this.daysCard.remove();
        this.zonesCard.remove();
    }

    initProfileCard() {
        const { model } = this;
        const title = 'Profile';
        return new ProfileFormCard({ title, model });
    }

    initDaysCard() {
        const { model, days } = this;
        const title = 'Select Available Days';
        const hasMaxHeight = true;
        return new SelectDaysCard({ title, hasMaxHeight, profile: model, days });
    }

    initZonesCard() {
        const { model, zones } = this;
        const title = 'Select Zones';
        const hasMaxHeight = true;
        return new SelectZonesCard({ title, hasMaxHeight, profile: model, zones });
    }

    newModel(initialValues) {
        const { event } = this;
        return new Profile.Model(initialValues, { event });
    }

    bindModel() {
        if (this.model) {
            this.listenTo(this.model, 'request', () => { this.setSyncing(true); });
            this.listenTo(this.model, 'error', this.onError);
            this.listenTo(this.model, 'sync', this.onSync);
        }
    }

    unbindModel() {
        if (this.model) this.stopListening(this.model);
    }

    setSyncing(syncing) {
        if (syncing !== this.isSyncing) {
            this.isSyncing = syncing;
            this.render();
        }
    }

    onError(model, xhr) {
        this.setSyncing(false);
        this.profileCard.validateResponse(xhr);
    }

    backToOverview() {
        const { event } = this;
        navigate(`/events/${event.id}/settings/accreditation/profiles`);
    }

}
