import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withState } from 'recompose'
import { PostfixedButtonInput } from 'ui-kit'
import { Tooltip } from 'app/react/components/_index'

const SEARCH_PLACEHOLDER = 'Search...';
const SEARCH_TOOLTIP = 'Search or press ENTER key';

const ClearButton = styled.a`
    position: absolute;
    top: 0;
    right: 0;
    color: #004B67;
    font-size: 13px;
    padding: 9px 12px;
`;

const injectState = withState('value', 'onChangeValue', '');

export const TableSearch = ({
    value,
    searchValue,
    onChangeValue = () => {},
    onSearch = () => {},
    onClear = () => {},
}) => {
    const isSearching = typeof searchValue !== 'undefined' && searchValue !== '' && searchValue !== null;

    return (
        <PostfixedButtonInput
            buttonIcon={(
                <Tooltip content={SEARCH_TOOLTIP} position="top right">
                    <i className="bs-icon-magnifier" />
                </Tooltip>
            )}
            buttonOnClick={() => onSearch(value)}
        >
            <input
                type="text"
                placeholder={SEARCH_PLACEHOLDER}
                value={value || ''}
                onKeyPress={e => {
                    if (value !== '') {
                        if (e.charCode === 13) {
                            onSearch(value);
                        }
                    }
                }}
                onChange={e => {
                    const newValue = e.target.value;
                    if (newValue === '' && isSearching) {
                        onClear();
                    }
                    onChangeValue(newValue);
                }}
            />
            {isSearching && (
                <ClearButton
                    onClick={() => {
                        onClear();
                        onChangeValue('');
                    }}
                >
                    <i className="bs-icon-cross no-margin" />
                </ClearButton>
            )}
        </PostfixedButtonInput>
    );
};

TableSearch.propTypes = {
    value: PropTypes.string,
    onChangeValue: PropTypes.func,
    onSearch: PropTypes.func,
    onClear: PropTypes.func
};

export default injectState(TableSearch);
