import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  momentDateFormatToFlatpickrFormat,
  momentTimeFormatToFlatpickrFormat,
} from "../../hooks/UseEventState";
import { useHTTP } from "../../hooks/UseHTTP";
import Icon from "../../utils/Icon";
import Button from "../atoms/button/Button";
import AddingStagesDialog from "../atoms/dialogs/AddingStagesDialog";
import FeedbackMessage from "../atoms/feedback/Feedback";
import Footer from "../footer/Footer";

export const EventStagesFormSchema = () => yup.object({});

export const EventStagesForm = ({
  setActiveStep,
  activeSubStep,
  eventState,
}) => {
  const { doHttpCall } = useHTTP();
  const [stageToEdit, setStageToEdit] = useState(undefined);
  const [stages, setStages] = useState(eventState.event.stages);
  const [error, setError] = useState("");

  const getAllStages = async (url) => {
    const stagesRes = await doHttpCall(url, "GET");
    if (
      stagesRes.meta &&
      stagesRes.meta.pagination &&
      stagesRes.meta.pagination.links &&
      stagesRes.meta.pagination.links.next
    ) {
      const nextRes = await getAllStages(stagesRes.meta.pagination.links.next);
      if (nextRes.data) {
        return {
          ...nextRes,
          data: [...stagesRes.data, ...nextRes.data],
        };
      }
    } else {
      return stagesRes;
    }
  };

  useEffect(() => {
    const getStages = async () => {
      const stages = await getAllStages(
        `/api/events/${eventState.event.oldEventId}/stages`
      );

      const mappedStages = new Map(
        stages.data.map((stage) => [
          stage.name,
          {
            id: Math.floor(Math.random() * Date.now()),
            name: stage.name,
            capacity: stage.capacity,
            sequence: stage.sequence,
            particularDays: false,
            differentAddress: false,
            days: eventState.event.days.map((day) => ({
              ...day,
              present: false,
              newName: "",
            })),
          },
        ])
      );
      setStages([...mappedStages.values()]);
    };
    if (
      eventState.event.oldEventId &&
      (!eventState.event.stages || eventState.event.stages.length === 0)
    ) {
      getStages();
    }
  }, [eventState.event.oldEventId]);

  const addStage = (stage) => {
    setStageToEdit(undefined);
    if (stage && stage.id) {
      setStages((prev) =>
        prev.map((existingStage) => {
          return existingStage.id === stage.id ? stage : existingStage;
        })
      );
    } else {
      setStages([
        ...stages,
        { ...stage, id: Math.floor(Math.random() * Date.now()) },
      ]);
    }
  };

  const removeStage = (day) => {
    setStages((prev) =>
      prev.filter((existingStage) => existingStage.id !== day.id)
    );
  };

  const createEvent = async (step) => {
    if (step < activeSubStep) {
      setActiveStep(step, { stages });
    } else {
      const start_date = moment
        .utc(eventState.event.days.map((day) => day.start).sort()[0])
        .format("DD/MM/YYYY");
      const end_date = moment
        .utc(
          eventState.event.days
            .map((day) => day.end)
            .sort()
            .reverse()[0]
        )
        .format("DD/MM/YYYY");
      const venueAddress = eventState.venues.find(
        ({ id }) => id === eventState.event.venue
      ).address;
      const event = {
        ...eventState.event,
        days: eventState.event.days.map((dayToMap) => {
          const day = {
            name: dayToMap.name,
            start: moment(dayToMap.start).format(),
            end: moment(dayToMap.end).format(),
            stages: [],
          };
          stages.forEach((stage, index) => {
            const mappedStage = {
              name: stage.name,
              capacity: stage.capacity,
              sequence: index + 1,
            };
            if (stage.differentAddress) {
              mappedStage["address"] = { ...stage.address };
            }
            if (stage.days.findIndex((dayToFind) => dayToFind.present) === -1) {
              day.stages.push(mappedStage);
            } else {
              if (
                stage.days.findIndex(
                  (dayToFind) =>
                    dayToFind.id === dayToMap.id && dayToFind.present
                ) !== -1
              ) {
                day.stages.push(mappedStage);
              }
            }
          });
          return day;
        }),
        start_date,
        end_date,
        date_format: momentDateFormatToFlatpickrFormat(
          eventState.event.date_format
        ),
        time_format: momentTimeFormatToFlatpickrFormat(
          eventState.event.time_format
        ),
      };
      if (event.days.findIndex(({ stages }) => stages.length === 0) !== -1) {
        setError("Make sure that each day has at least 1 stage");
        return;
      }
      delete event.tasks;
      delete event.stages;
      delete event.templates;
      delete event.existingEvents;
      delete event.oldEventId;
      if (event.id) {
        doHttpCall(
          `/api/accounts/${eventState.account.id}/events/${event.id}`,
          "PUT",
          event
        )
          .then((eventRes) => {
            if (eventRes && eventRes.data) {
              setActiveStep(step, { stages });
            }
          })
          .catch((err) => {});
      } else {
        delete event.id;
        doHttpCall(
          `/api/accounts/${eventState.account.id}/events`,
          "POST",
          event
        )
          .then((eventRes) => {
            if (eventRes && eventRes.data) {
              setActiveStep(step, {
                stages,
                id: eventRes.data.id,
                accreditation_enabled: eventRes.data.accreditation_enabled,
                accreditation_ticket_provider_id:
                  eventRes.data.accreditation_ticket_provider_id,
                artist_types_enabled: eventRes.data.artist_types_enabled,
                booking_stats: eventRes.data.booking_stats,
              });
            }
          })
          .catch((err) => {});
      }
    }
  };

  return (
    <div className="form_content">
      {error && (
        <FeedbackMessage
          text={error}
          variant="error"
          closeFeedback={() => setError("")}
        />
      )}
      <h2>What stages do you provide?</h2>
      <h3 className="form-sub-title">Add your stages</h3>
      <span>
        Easily share all information your team needs about the different stages.
      </span>
      <span>Get started by adding your first stage.</span>
      {stages.length > 0 && (
        <table className="form__table">
          <thead>
            <tr className="form__table-header">
              <th>NAME</th>
              <th>CAPACITY</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {stages.map((stage) => {
              return (
                <tr className="form__table-row" key={stage.id}>
                  <td>{stage.name}</td>
                  <td>{stage.capacity}</td>
                  <td className="form__table__last-column">
                    <button
                      className="form__table__button"
                      onClick={() =>
                        setStageToEdit({
                          ...stage,
                          days: eventState.event.days.map((day) => {
							const existingDay = stage.days.find((dayToFind) => dayToFind.id === day.id)
							return existingDay ? existingDay : {...day, present: false, newName: ''}
                          }),
                        })
                      }
                    >
                      <Icon
                        size={26}
                        name="edit"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                    <button
                      className="form__table__button"
                      onClick={() => removeStage(stage)}
                    >
                      <Icon
                        size={26}
                        name="delete"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <br />
      <Button
        label="Add stages"
        variant="secondary"
        onClick={() =>
          setStageToEdit({
            name: "",
            capacity: undefined,
            days: eventState.event.days.map((day) => ({
              ...day,
              present: false,
              newName: "",
            })),
            particularDays: false,
            differentAddress: false,
          })
        }
      />
      {stageToEdit && (
        <AddingStagesDialog
          addStage={addStage}
          setCancelDialogOpen={() => setStageToEdit(undefined)}
          stage={stageToEdit}
        />
      )}
      <Footer
        setActiveStep={createEvent}
        activeSubStep={activeSubStep}
        eventState={eventState}
        isNextDisabled={stages.length === 0}
      />
    </div>
  );
};

EventStagesForm.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
};

export default EventStagesForm;
