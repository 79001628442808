import Backbone from 'backbone';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import Tab from './Tab.view';

export default class Tabs extends BaseView {

    OptionTypes() {
        return {
            tabs: OptionTypes.array
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.tabViews = this.generateTabViews();
        this.tabViews.forEach((tabView) => {
            this.toggleActive(tabView);
            this.listenTo(tabView, 'select', (tab) => { this.tabSelected(tab); });
        });
    }

    generateTabViews() {
        const { tabs } = this;
        const tabsContainer = new Backbone.ChildViewContainer();

        tabs.map((tab) => {
            const { name, iconClassName, title, viewId, isActive } = tab;
            tabsContainer.add(new Tab({ name, iconClassName, title, viewId, isActive }), name);
        });

        return tabsContainer;
    }

    render() {
        this.tabViews.forEach((tabView) => {
            this.appendSubview(tabView, this.$el);
        });

        return this.delegateEvents();
    }

    tabSelected(tab) {
        this.tabViews.call('resetActive');
        tab.isActive = true;

        this.tabViews.each((tabView) => { this.toggleActive(tabView); });

        this.trigger('select', tab);
    }

    selectTab(tabName) {
        const { tabViews } = this;
        const tab = tabViews.findByCustom(tabName);

        if (tab) tab.selectTab();
    }

    toggleActive(tabView) {
        tabView.isActive ? tabView.$el.addClass('active') : tabView.$el.removeClass('active');
    }
}
