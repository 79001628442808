import _ from 'underscore';
import { Model, Collection }  from 'app/backbone/models/decorators';
import * as EventItem from '../../settings/accreditation/EventItem'
import * as GrantedDay from '../../settings/accreditation/GrantedDay';

@Model({
    key: 'accreditee_item'
})
class AccrediteeItem extends EventItem.Model {

    static Relations() {
        return {
            item_group: EventItem.Model.Relations().item_group,
            granted_days: GrantedDay.Collection
        }
    }

    get validation() {
        return {
            id: {
                required: true,
                msg: 'Select an item.'
            }
        };
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if (this.hasRelation('granted_days')) json.granted_days = this.getRelation('granted_days').toJSON();
        return json;
    }

    hasGrantedDay() {
        return !this.getRelation('granted_days').isEmpty();
    }

    hasGrantedDayOn(day) {
        return !! this.getGrantedDayOn(day);
    }

    hasGrantedDayOnEvery(days) {
        return days.every(day => this.hasGrantedDayOn(day));
    }

    getGrantedDayOn(day) {
        return this.getRelation('granted_days').get(day.id);
    }

    addGrantedDayOn(days) {
        this.getRelation('granted_days').add(_.isArray(days) ? days.map(day => day.toGrantedDay()) : days.toGrantedDay());
    }

    addGrantedDayOnAll(days) {
        this.addGrantedDayOn(days.models);
    }

    removeGrantedDayOn(days) {
        this.getRelation('granted_days').remove(_.isArray(days) ? _.pluck(days, 'id') : days.id );
    }

    removeGrantedDayOnAll(days) {
        this.removeGrantedDayOn(days.models)
    }

}

@Collection({
    key: 'accreditee_items',
    model: AccrediteeItem
})
class AccrediteeItemCollection extends EventItem.Collection {

    static Relations() {
        return {
            accreditee: require('./Accreditee').Model // Circular
        }
    }

    someHaveNoGrantedDays() {
        return this.some(item => item.getRelation('granted_days').isEmpty() && item.get('date_dependable'));
    }

    filterByHasGrantedDay() {
        return this.constructor(this.filter(model => (
            model.hasGrantedDay() ||
            !model.get('date_dependable')
        )));
    }

    filterByGrantedByPromoter() {
        return this.constructor(this.filter(model => model.get('granted_by_promoter')));
    }

}

const PageableCollection = AccrediteeItemCollection.prototype.PageableCollection();

export {
    AccrediteeItem as Model,
    AccrediteeItemCollection as Collection,
    PageableCollection
};
