import React, { Fragment } from 'react'
import { TableRow } from 'ui-kit'
import Tooltip from 'app/react/components/util/Tooltip'
import {
    hasNightOnDay,
    hasNoRoomLimit,
    isRoomLimitReached,
    isRoomLimitExceeded,
} from 'app/react/helpers/reservations'
import RoomsAvailableProgress from '../../components/RoomsAvailableProgress'

const ROOMS_REMAINING_TEXT = (booked, available) => {
    const remaining = available - booked;

    if (available === null) {
        return '∞';
    }

    if (remaining === 0) {
        return 'Full';
    }

    return remaining < 0
        ? 'Overbooked'
        : remaining;
};

const TOOLTIP_ROOMS_AVAILABLE = (booked, available) => {
    const remaining = available - booked;

    if (hasNoRoomLimit(available)) {
        return `${booked} booked<br/>∞ remaining`;
    }

    if (isRoomLimitExceeded(booked, available)) {
        return `Overbooked with ${Math.abs(remaining)} room(s)`;
    }

    if (isRoomLimitReached(booked, available)) {
        return `${booked} booked`;
    }

    return `${booked} booked<br/>${remaining} remaining`;
};

const DaysColumnsTableRow = ({
    accommodation = {},
    bookedByPromoter = [],
    reservationDays = [],
    dateFormatShort,
}) => (
    <TableRow>
        {reservationDays.map((day, key) => {
            const nightsBookedOnDay = bookedByPromoter.reduce((reservedOnDay, reservation) => {
                if (hasNightOnDay(reservation, day)) {
                    reservedOnDay++;
                }
                return reservedOnDay;
            }, 0);

            return (
                <td
                    key={`${accommodation.id}${key}`}
                    className="center"
                >
                    <Tooltip
                        content={TOOLTIP_ROOMS_AVAILABLE(nightsBookedOnDay, accommodation.roomLimitPerDay)}
                        style={{ textAlign: 'center' }}
                    >
                        <Fragment>
                            <RoomsAvailableProgress
                                booked={nightsBookedOnDay}
                                available={accommodation.roomLimitPerDay}
                            />
                            <span style={{ marginLeft: '.4rem' }}>
                                {ROOMS_REMAINING_TEXT(nightsBookedOnDay, accommodation.roomLimitPerDay)}
                            </span>
                        </Fragment>
                    </Tooltip>
                </td>
            )
        })}
    </TableRow>
);

export default DaysColumnsTableRow;
