import _ from 'underscore';
import $ from 'jquery';
import Backbone from 'backbone';
import Config from './src/app/backbone/config';

Config.jquery();
Config.backbone();
Config.datatables();
Config.foundation();
Config.tooltips();
Config.toastr();
Config.handlebars();
Config.froala();

const App = require('./src/app/app').default;
window.app = new App();
window.app.render();

export const redirectToParam = redirectUrl =>
    `redirect=${encodeURIComponent(`${window.location.origin}/${redirectUrl}`)}`;

// Register a global navigate method
window.navigate = (url, options = {}) => {
    const cleanUrl = url.replace('(?*query)', '');
    if (options.back) {
        window.history.back();
    } else if (options.pageReload) {
        const firstCharIsForwardSlash = cleanUrl.charAt(0) === '/';
        const firstCharsAreHttp = cleanUrl.substring(0, 4) === 'http';
        const firstCharsAreHttps = cleanUrl.substring(0, 5) === 'https';
        let finalUrl = firstCharIsForwardSlash || firstCharsAreHttp || firstCharsAreHttps ? cleanUrl : `/${cleanUrl}`;
        if (typeof options.redirect === 'string') {
            finalUrl = `${finalUrl}?${redirectToParam(options.redirect)}`
        }
        window.location.href = finalUrl;
    } else if (options.newTab) {
        const firstCharIsForwardSlash = cleanUrl.charAt(0) === '/';
        let finalUrl = firstCharIsForwardSlash ? cleanUrl : `/${cleanUrl}`;
        if (typeof options.redirect === 'string') {
            finalUrl = `${finalUrl}?${redirectToParam(options.redirect)}`
        }
        window.open(finalUrl, '_blank');
    } else if (options.reloadCurrent) {
        Backbone.history.loadUrl(Backbone.history.fragment);
    }
    else {
        window.app.router.navigate(cleanUrl, _.extend({ trigger: true }, options));
    }
};

// Start history tracking
Backbone.history.start({ pushState: true });

// Do stuff when page loaded
$(window).load(() => {
    $('body').removeClass('preload');
});
