var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression((helpers.capitalizeFirst || (depth0 && depth0.capitalizeFirst) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.text : depth0),{"name":"capitalizeFirst","hash":{},"data":data}))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    return " Update ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"grid\">\n    <div class=\"grid-cell\"></div>\n    <div class=\"grid-cell grid-cell--autoSize grid-cell--justifyEndContent\">\n        <a class=\"button no-margin-bottom update\">\n            "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.text : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </a>\n    </div>\n</div>\n";
},"useData":true});