import React, { Fragment } from 'react'
import { NoRecordsPlaceholder, Loading } from 'app/react/components/_index'
import { TableLoadingPlaceholder } from 'app/react/components/_index'
import VehiclesTable from './Table'
import ScrollSentinel from 'app/react/components/tables/ScrollSentinel'

const NO_RECORDS_TEXT = 'No vehicles created for this event.';
const NO_RECORDS_CREATE_TEXT = 'Create new vehicle';

const PaginatedVehiclesTable = ({
    vehicles = [],
    totalCount,
    isLoading = true,
    isLoadingMore = false,
    canLoadMore = true,
    currentSortKey,
    currentSortOrder,
    onCreate,
    onUpdate,
    onDelete,
    onSort,
    onLoadMore,
}) => {
    if (totalCount === 0) {
        return (
            <NoRecordsPlaceholder style={{ flex: 1 }}>
                {NO_RECORDS_TEXT}&nbsp;
                <a onClick={onCreate}>
                    {NO_RECORDS_CREATE_TEXT}
                </a>
            </NoRecordsPlaceholder>
        )
    }
    return (
        <Fragment>
            { isLoading ?
                <TableLoadingPlaceholder /> :
                <Fragment>
                    <VehiclesTable
                        vehicles={vehicles}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        onSort={onSort}
                        currentSortKey={currentSortKey}
                        currentSortOrder={currentSortOrder}
                    />
                    { (!isLoadingMore && canLoadMore) &&
                        <ScrollSentinel onIntersect={onLoadMore}/>
                    }
                    { isLoadingMore &&
                        <Loading/>
                    }
                </Fragment>
            }
        </Fragment>
    )
};

export default PaginatedVehiclesTable
