import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    pageParam,
    filterParam,
    limitParam as pageSizeParam,
    searchParam,
    sortParam,
    limitAllParam as pageSizeAllParam,
} from 'app/react/state/middleware/api'

const rootAll = (eventId) => `events/${eventId}/artists/items`;
const rootOne = (eventId, reservationId) => `events/${eventId}/artists/items/${reservationId}`;

export const fetchAll = (
    eventId,
    filters = {},
    includes = []
) => oneLineTrim`
    ${rootAll(eventId)}?
    ${pageSizeAllParam}&
    ${filterParam(filters)}&
    ${includeParam(includes)}&
`;

export const createOne = (
    eventId
) => oneLineTrim`
    ${rootAll(eventId)}
`;

export const updateOne = (
    eventId,
    itemId
) => oneLineTrim`
    ${rootOne(eventId, itemId)}
`;

export const deleteOne = (
    eventId,
    itemId
) => oneLineTrim`
    ${rootOne(eventId, itemId)}
`;

export const fetchPaginated = (
    eventId,
    page = 1,
    pageSize = 100,
    filters = {},
    search = '',
    sortKey = '',
    sortOrder = 1,
    includes = []
) => oneLineTrim`
    ${rootAll(eventId)}?
    ${pageParam(page)}&
    ${pageSizeParam(pageSize)}&
    ${filterParam(filters)}&
    ${searchParam(search)}&
    ${includeParam(includes)}
`;
