import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Schemas from './schemas'
import {
    getEntitiesPaginationByKey,
    getEntitiesCollectionByKey,
    getSomeEntities,
    getSomeEntitiesDenormalized,
} from 'app/react/state/helpers';
import {
    generateCollectionKey
} from 'app/react/entities/events/helpers'

const {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY,
    PAGINATION_REDUCER_KEY
} = Schemas;

export const getCollection = (state, accountId, eventId) => {
    const key = generateCollectionKey(accountId, eventId);
    return getEntitiesCollectionByKey(state, COLLECTION_REDUCER_KEY, key);
};

export const getCollectionForCurrentContext = (state) => {
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return getCollection(state, accountId, eventId);
};

export const getAllForCurrentContext = (state) => {
    const collection = getCollectionForCurrentContext(state);
    return getSomeEntities(state, ENTITY_REDUCER_KEY, collection.ids);
};

export const isFetchingAllForCurrentContext = (state) => {
    const collection = getCollectionForCurrentContext(state);
    return !! collection.isFetching
};

export const getPaginationByKey = (state, key) =>
    getEntitiesPaginationByKey(state, PAGINATION_REDUCER_KEY, key);

export const getPaginationForCurrentContext = (state) => {
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const key = generateCollectionKey(accountId, eventId);
    return getEntitiesPaginationByKey(state, PAGINATION_REDUCER_KEY, key);
};

export const getAllByPaginationForCurrentContext = (state, denormalize = false) => {
    const pagination = getPaginationForCurrentContext(state);
    if (denormalize) {
        return getSomeEntitiesDenormalized(state, ENTITY_REDUCER_KEY, Schemas.entity, pagination.ids)
    }
    return getSomeEntities(state, ENTITY_REDUCER_KEY, pagination.ids);
};
