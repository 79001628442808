import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Button from "../../button/Button";
import propTypes from "prop-types";

const ArtistGeneralFooter = ({
  isNextDisabled = false,
  hasPrevious = true,
  hasNext = true,
  setActiveStep,
  saveAndClose,
  activeSectionIndex,
  hasSave = false,
}) => {
  return (
    <Fragment>
      <div className="artist-dialog__footer">
        {hasPrevious && (
          <Button
            type="submit"
            label="Previous"
            variant="primary"
            onClick={() =>
              setActiveStep(activeSectionIndex, activeSectionIndex - 1)
            }
          />
        )}
        {hasNext && (
          <Button
            type="submit"
            isDisabled={isNextDisabled}
            label="Next"
            variant="primary"
            onClick={() =>
              setActiveStep(activeSectionIndex, activeSectionIndex + 1)
            }
          />
        )}
        {hasSave && (
          <Button
            type="submit"
            isDisabled={isNextDisabled}
            label="Save"
            variant="primary"
            onClick={() =>
              saveAndClose(activeSectionIndex, activeSectionIndex + 1)
            }
          />
        )}
      </div>
    </Fragment>
  );
};

ArtistGeneralFooter.propTypes = {
  isNextDisabled: propTypes.bool,
  hasPrevious: propTypes.bool,
  hasNext: propTypes.bool,
  setActiveStep: PropTypes.func,
  activeSectionIndex: PropTypes.number,
};

export default ArtistGeneralFooter;
