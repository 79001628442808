import React, { useState } from 'react'
import RemoveAttendeeModal from './RemoveAttendeeModal'

const RemoveAttendeeModalWithState = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { onDelete } = props;

    async function onConfirm({ closeModal }) {
        setIsLoading(true);

        try {
            await onDelete();
            closeModal();
        } catch (e) {
            setIsLoading(false);
        }
    }

    return (
        <RemoveAttendeeModal
            {...props}
            isLoading={isLoading}
            onConfirm={onConfirm}
        />
    );
};

export default RemoveAttendeeModalWithState;

