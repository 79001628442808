import * as Event from 'app/react/entities/events/helpers'
import * as BookingRoutes from 'app/routes/event/artists/bookings/routes'
import * as actions from 'app/react/state/actions/_index'

const goToBooking = (bookingId, newTab) => (dispatch, getState) => {
    const state = getState();
    const eventId = Event.getCurrentId(state);
    const url = BookingRoutes.details(eventId, bookingId);
    if (newTab) {
        dispatch(actions.navigate.newTab(url))
    } else {
        dispatch(actions.navigate.pageReload(url))
    }
};

export const thunks = {
    goToBooking,
};
