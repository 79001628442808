import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';

const { Header, Content, Footer } = Card;
const { Title } = Header;
const { Error } = Footer;

export const TITLE_POSTFIX = 'section';

export const EditSectionCard = ({
    title = '',
    footer = {},

    Form,
    isAccreditationEnabled = false,
    accreditationProfileOptions = [],
    ticketTypeOptions = [],
    formValues,
    formErrors,
    onChangeFormValue = () => {}
}) => (
    <Card>
        <Header>
            <Title>{`${title} ${TITLE_POSTFIX}`}</Title>
        </Header>
        <Content>
            <Form
                isAccreditationEnabled={isAccreditationEnabled}
                accreditationProfileOptions={accreditationProfileOptions}
                ticketTypeOptions={ticketTypeOptions}
                values={formValues}
                errors={formErrors}
                onChangeValue={onChangeFormValue}
            />
        </Content>
        <Footer>
            {footer.message &&
                <Error>{footer.message}</Error>
            }
        </Footer>
    </Card>
);

EditSectionCard.propTypes = {
    title: PropTypes.string,
    footer: PropTypes.object,

    Form: PropTypes.func,
    isAccreditationEnabled: PropTypes.bool,
    accreditationProfileOptions: PropTypes.array,
    ticketTypeOptions: PropTypes.array,
    formValues: PropTypes.object,
    formErrors: PropTypes.object,
    onChangeFormValue: PropTypes.func
};

export default EditSectionCard;
