import AccrediteesGrantedAllDaysCell from 'app/backbone/views/event/accreditation/accreditees/profiles/tables/cells/GrantedAllDaysCell.view';

export default class GrantedAllDaysCell extends AccrediteesGrantedAllDaysCell {

    isDisabled() {
        return !(this.model.isAvailable() || this.model.hasGrantedDay());
    }

    isChecked() {
        const grantableDays = this.getGrantableDays();
        return this.isDisabled() || grantableDays.isEmpty() || this.model.hasGrantedDayOnEvery(grantableDays);
    }

    getGrantableDays() {
        return this.model.getRelation('available_profile').getAllGrantableDays();
    }

}
