var _ = require('underscore'),
    Backbone = require('backbone');

var AccrediteeProfile = require('app/backbone/models/event/accreditation/accreditees/AccrediteeProfile'),
    Profile = require('app/backbone/models/event/settings/accreditation/Profile'),
    ValidatedForm = require('app/backbone/components/forms/ValidatedForm.mixin'),
    Form = require('app/backbone/components/forms/Form.mixin'),
    Modal = require('app/backbone/components/modals/Modal.view');

var AccrediteeProfileModal = Modal.extend({

    mixins: [
        ValidatedForm,
        Form
    ],

    options: [
        'profiles!',
        'hiddenInputs',
        'disabledInputs',
        'canCreateAnother',
        'width'
    ],

    initialize(options) {
        this._super(options);

        if(!this.profiles instanceof Profile.Collection)
            throw new TypeError(`Option 'profiles' must be instance of 'Profile.Collection'`);

        !_.isUndefined(this.hiddenInputs) || (this.hiddenInputs = []);
        !_.isUndefined(this.disabledInputs) || (this.disabledInputs = []);
        !_.isUndefined(this.canCreateAnother) || (this.canCreateAnother = true);
        !_.isUndefined(this.width) || (this.width = 500);
        this.title = 'Add Profile';
        this.inputs = new Backbone.ChildViewContainer();
        this.groups = new Backbone.ChildViewContainer();
    },

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();

        this.unbindModel();
        this.initForm();
        this.bindModel();
        this.renderForm();
        this._super();
        return this.delegateEvents();
    },

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                hiddenInputs: this.hiddenInputs,
                height: this.height,
                title: this.title
            },
            model: this.model.attributes
        };
    },

    initForm() {
        this.disableInputs(this.disabledInputs);

        if (this.isAvailable('profile')) {
            var profilesSelectize = this.initSingleSelectize('profile', {collection: this.profiles});
            this.listenTo(profilesSelectize, 'selected', this.onProfileSelected);
            this.inputs.add(profilesSelectize, 'profile');
            this.groups.add(this.initValidationGroup('profile', this.model, 'id'), 'profile');
        }
    },

    renderForm() {
        this.inputs.call('render');
        this.groups.call('render');
    },

    newModel(attributes = {}) {
        return new AccrediteeProfile.Model({
            ...attributes,
            granted_by_promoter: true,
            granted_by: 'Promoter'
        });
    },

    bindModel() {
        this.stickit();
    },

    unbindModel() {
        this.unstickit();
        if(this.model) this.stopListening(this.model);
    },

    onProfileSelected(profile) {
        if(profile) {
            this.model.set({
                id: profile.get('id'),
                name: profile.get('name')
            });
            if(profile.days) this.model.days = profile.days.clone();
        }
    }
});

module.exports = AccrediteeProfileModal;
