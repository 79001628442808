import _ from 'underscore';
import Backbone from 'backbone';
import Store from 'store';
import Tooltip from 'tether-tooltip';
import { DEFAULT_OPEN_DELAY } from 'app/backbone/components/tooltips/constants';

class BaseView extends Backbone.View {

    resolveOptions(options = {}) {
        // Validate each option type
        if (this.optionTypes) {
            _.each(this.optionTypes(), (type, name) => { type(options, name, this); });
        } else if (this.constructor.optionTypes) {
            _.each(this.constructor.optionTypes(), (type, name) => { type(options, name, this); });
        }
        // Add default options to undefined options
        if (this.optionDefaults) _.defaults(options, this.optionDefaults());
        // add the options to 'this' context
        _.defaults(this, options);
    }

    resolveHandles() {
        super.resolveHandles();
        this.handlesResolved = true;
    }

    assignSubview(view, $el) {
        if (view) view.setElement($el).render();
    }

    insertSubview(view, $el) {
        if (view) {
            $el.html(view.el);
            view.render();
        }
    }

    appendSubview(view, $el) {
        if (view) {
            $el.append(view.el);
            view.render();
        }
    }

    appendSubviews(views, $el) {
        if (views.length) {
            views.forEach((view) => { this.appendSubview(view, $el); });
        }
    }

    initViewsForEachEl(ViewClass, $el, options = {}) {
        const views = [];
        $el.each((index, el) => views.push(new ViewClass(_.extend(options, { el }))));
        return views;
    }

    storeSetting(settingName, settingValue) {
        const uniqueName = this.getUniqueName();
        this.checkUniqueName(uniqueName);

        if (settingName || settingValue) {
            const settings = Store.get(uniqueName) || {};
            settings[settingName] = settingValue;
            Store.set(uniqueName, settings);
            return settingValue;
        }
    }

    storeSettings(settings) {
        const uniqueName = this.getUniqueName();
        this.checkUniqueName(uniqueName);

        if (settings) {
            Store.set(uniqueName, settings);
            return settings;
        }
    }

    getSetting(settingName) {
        const uniqueName = this.getUniqueName();
        this.checkUniqueName(uniqueName);

        if (settingName) {
            const settings = Store.get(uniqueName) || {};
            return settings[settingName];
        }
    }

    getSettings() {
        const uniqueName = this.getUniqueName();
        this.checkUniqueName(uniqueName);

        return Store.get(uniqueName);
    }

    checkUniqueName(name) {
        if (!name) throw new Error('There\'s no uniqueName specified');
    }

    initTooltip({ content, target = this.el, position = 'top', openDelay = DEFAULT_OPEN_DELAY }) {
        return new Tooltip({ target, content, position, openDelay });
    }

    removeTooltip(tooltip = this.tooltip) {
        if (tooltip && tooltip.drop) {
            // Hack to avoid bug on destroy when tooltip is not opened yet
            if (tooltip.drop.isOpened()) tooltip.destroy();
            else {
                tooltip.drop.on('open', () => {
                    tooltip.destroy();
                });
            }
        }
    }

    openModalFromDOM(modalDomNode) {
        modalDomNode.foundation('reveal', 'open');
    }
}

module.exports = BaseView;
