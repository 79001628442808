export const useHTTP = () => {
	const doHttpCallWithFormData = (url, method, body) => {
		const formData = new FormData();

		const xhr = new XMLHttpRequest();
		xhr.open(method, url);
		xhr.setRequestHeader(
			'Authorization',
			`bearer ${window.beatswitch.auth.jwt}`
		);
		xhr.setRequestHeader('Accept', 'application/json');
		if (body) {
			buildFormData(formData, body);
		}
		xhr.send(formData);
		return new Promise((resolve, reject) => {
			xhr.onload = function () {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (xhr.response) {
						const responseJSON = JSON.parse(xhr.response);
						resolve(responseJSON);
					} else {
						resolve(xhr);
					}
				} else {
					reject({
						status: xhr.status,
						statusText: xhr.statusText,
					});
				}
			};
			xhr.onerror = () => {
				reject({
					status: xhr.status,
					statusText: `Something went wrong while calling ${url}`,
				});
			};
		});
	};

	const doHttpCallJson = (url, method, body) => {

		const xhr = new XMLHttpRequest();
		xhr.open(method, url);
		xhr.setRequestHeader(
			'Authorization',
			`bearer ${window.beatswitch.auth.jwt}`
		);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify(body));
		return new Promise((resolve, reject) => {
			xhr.onload = function () {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (xhr.response) {
						const responseJSON = JSON.parse(xhr.response);
						resolve(responseJSON);
					} else {
						resolve(xhr);
					}
				} else {
					reject({
						status: xhr.status,
						statusText: xhr.statusText,
					});
				}
			};
			xhr.onerror = () => {
				reject({
					status: xhr.status,
					statusText: `Something went wrong while calling ${url}`,
				});
			};
		});
	};

	return { doHttpCall: doHttpCallWithFormData, doHttpCallJson };
};

function buildFormData(formData, data, parentKey) {
	if (
		data &&
		typeof data === 'object' &&
		!(data instanceof Date) &&
		!(data instanceof File)
	) {
		Object.keys(data).forEach((key) => {
			buildFormData(
				formData,
				data[key],
				parentKey ? `${parentKey}[${key}]` : key
			);
		});
	} else {
		const value = data == null ? '' : data;

		formData.append(parentKey, value);
	}
}
