import { html } from 'common-tags'
import OptionTypes from 'app/backbone/helpers/OptionTypes'
import Content from 'app/backbone/components/layout/content/Content.view'
import OfferOverviewCard from '../views/cards/OfferOverviewCard.view'
import * as Day from 'app/backbone/models/event/Day'
import * as Booking from 'app/backbone/models/event/artists/Booking'

export default class OffersOverview extends Content {

    ui() {
        return {
            offersCard: '#offers-card'
        };
    }

    optionTypes() {
        return {
            bookings: OptionTypes.instanceOf(Booking.PageableCollection).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.offersCard = this.initOffersCard();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.offersCard, this.ui.offersCard);
        return this.delegateEvents();
    }

    template() {
        return html`<div id="offers-card" class="card"></div>`;
    }

    remove() {
        super.remove();
        this.offersCard.remove();
    }

    initOffersCard() {
        const { bookings, days } = this;
        const title = 'Offers';
        return new OfferOverviewCard({ bookings, days, title });
    }

}
