var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form method=\"POST\" action=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.confirmHref : stack1), depth0))
    + "\" accept-charset=\"UTF-8\">\n    <input name=\"_method\" type=\"hidden\" value=\"DELETE\">\n    <div class=\"modalHeader\">\n        <h4>"
    + alias2((helpers.capitalizeEach || (depth0 && depth0.capitalizeEach) || helpers.helperMissing).call(alias3,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.title : stack1),{"name":"capitalizeEach","hash":{},"data":data}))
    + "</h4>\n        <a class=\"modal-close close-reveal-modal\">\n            <i class=\"bs-icon-cross\"></i>\n        </a>\n    </div>\n    <div class=\"modalContentContainer\">\n        <p>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.body : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n        <select name=\"new_event_role\" id=\"new_event_role\">\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.eventRoles : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div class=\"modalButtonsContainer\">\n        <input class=\"button modal-confirm "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.confirmClass : stack1), depth0))
    + "\" type=\"submit\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.confirmText : stack1), depth0))
    + "\">\n    </div>\n</form>\n";
},"useData":true});