import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';

@Model({
    key: 'timeZone'
})
class TimeZone extends BaseModel {

    urlRoot() {
        return `/api/time-zones`;
    }

    parse(response, xhr) {
        return super.parse(response, xhr);
    }

}

@Collection({
    key: 'timeZones',
    model: TimeZone
})
class TimeZoneCollection extends BaseCollection {

    url() {
        return `/api/time-zones`;
    }

}

export {
    TimeZone as Model,
    TimeZoneCollection as Collection,
};