import React from 'react'
import {Grid, MultiSelect, SearchInput,} from 'ui-kit'
import styled from 'styled-components'
import {TableFilter} from 'app/react/components/tables'

const { Cell } = Grid;

const CHECKED_IN_OPTIONS = [{
    value: '',
    label: 'All attendees',
}, {
    value: true,
    label: 'Checked in',
}, {
    value: false,
    label: 'Not checked in',
}];

export const ATTENDEE_TYPE_GUEST = 0;
export const ATTENDEE_TYPE_PERFORMER = 1;
export const ATTENDEE_TYPE_CREW = 2;

const ATTENDEE_TYPE_OPTIONS = [{
    value: ATTENDEE_TYPE_GUEST,
    label: 'Guests',
}, {
    value: ATTENDEE_TYPE_PERFORMER,
    label: 'Performers',
}, {
    value: ATTENDEE_TYPE_CREW,
    label: 'Crew',
}];

function toOption({ id, name }) {
    return {
        value: id,
        label: name,
    };
}

function optionalBooleanValue(value) {
    return value === '' ? null : value === 'true';
}

const FilterContainer = styled.div`
    min-width: 15rem;
    max-width: 22rem;
    margin-right: .5rem;
`;

const TopBar = ({
    ticketTypes,
    filters,
    onChangeFilter,
    searchValue,
    onSearch,
    onClearSearch,
    days
}) => {
    const {
        isCheckedIn,
        types: selectedTypes,
        ticketTypes: selectedTicketTypes,
        day: selectedDay
    } = filters;


    days = [{
        value: '',
        label: 'All days',
    }].concat(days.map(toOption));


    function onChangeCheckedIn(isCheckedIn) {
        onChangeFilter('isCheckedIn', optionalBooleanValue(isCheckedIn));
    }

    function onChangeTypes(types) {
        onChangeFilter('types', types);
    }

    function onChangeTicketTypes(ticketTypes) {
        onChangeFilter('ticketTypes', ticketTypes);
    }

    function onChangeDay(day) {
        onChangeFilter('day', day);
    }

    return (
        <Grid hasGutters={false} className="margin-tiny-bottom">
            <Cell isContentJustifiedAtStart>
                <Grid hasGutters={false}>
                    <Cell isAutoSized>
                        <TableFilter
                            options={CHECKED_IN_OPTIONS}
                            value={isCheckedIn !== null ? isCheckedIn : ''}
                            onChange={onChangeCheckedIn}
                        />
                    </Cell>
                    <Cell isAutoSized>
                        <TableFilter
                            placeholder="All days"
                            options={days}
                            value={selectedDay}
                            onChange={onChangeDay}
                        />
                    </Cell>
                    <Cell isAutoSized>
                        <FilterContainer>
                            <MultiSelect
                                placeholder="All types"
                                options={ATTENDEE_TYPE_OPTIONS}
                                values={selectedTypes}
                                onChange={onChangeTypes}
                            />
                        </FilterContainer>
                    </Cell>
                    <Cell isAutoSized>
                        <FilterContainer>
                            <MultiSelect
                                placeholder="All ticket types"
                                options={ticketTypes.map(toOption)}
                                values={selectedTicketTypes}
                                onChange={onChangeTicketTypes}
                            />
                        </FilterContainer>
                    </Cell>
                </Grid>
            </Cell>
            <Cell
                isAutoSized
                isContentJustifiedAtEnd
                width="250px"
            >
                <SearchInput
                    isExpanded
                    value={searchValue || ''}
                    placeholder="Artist, name, email, stage or ticket type..."
                    info="You can search for attendees by artist, first and last name, email, stage or ticket type."
                    onSearch={onSearch}
                    onClear={onClearSearch}
                />
            </Cell>
        </Grid>
    );
};

export default TopBar;
