import { reformatISOString } from 'app/react/helpers/_index';
import { html } from 'common-tags';
import moment from 'moment';

const EXTRA_HOURS_AFTER_DAY_END = 6;

export const driverToFcResource = driver => ({
    id: driver.id,
    title: `${driver.firstname} ${driver.lastname}` ,
    sequence: driver.sequence
});


export const removeDriverDepartureTimeFromStart = (start, driverDepartureTime) => {
    const mStart = moment.utc(start);
    mStart.add(driverDepartureTime, 'minutes');
    return mStart.toISOString();
};


// stolen from the changeover template in the performance scheduler
export const driverDepartureTimeTemplate = (width = 0, duration) => html`
    <div class="fc-changeover" style="width: ${width}px">
        ${width >= 60 &&
            html`
                <span class="fc-duration">
                    <i class="bs-icon-expand-horizontal" /><span>${duration}</span>
                </span>
            `
        }
    </div>
`;

export const pickupToFcEvent = pickup => ({
    id: pickup.id,
    title: pickup.booking.artist.name,
    resourceId: typeof pickup.driver !== 'undefined' && pickup.driver !== null
        ? pickup.driver.id
        : 0,
    start: pickup.driverLeaveTime,
    // driverDepartureTime comes in in seconds for some reason
    driverDepartureTime: pickup.driverDepartureTime / (1000 * 60) || 0,
    end: pickup.arrival,
    mode: pickup.mode,
});

export const calculateStartTime = (start) =>
    moment.utc(start);

export const calculateEndTime = (end) =>
    moment(end).add(EXTRA_HOURS_AFTER_DAY_END, 'hours');

export const findAddedDrivers = (currentDrivers, newDrivers) =>
    newDrivers.filter(({ id }) => !currentDrivers.some(driver => driver.id === id));

export const findAddedPickups = (currentPickups, newPickups) =>
    newPickups.filter(({ id }) => !currentPickups.some(pickup => pickup.id === id));

export const findRemovedPickups = (currentPickups, newPickups) =>
    currentPickups.filter(({ id }) => !newPickups.some(pickup => pickup.id === id));

export const findChangedPickups = (currentPickups, newPickups, addedPickups, removedPickups) =>  {
    return currentPickups.filter(currentPickup => {
        if (
            addedPickups.some(({ id }) => id === currentPickup.id) ||
            removedPickups.some(({ id }) => id === currentPickup.id)
        ) {
            return false
        }
        const newPickup = newPickups.find(({ id }) => id === currentPickup.id);
        const departureHasChanged = currentPickup.departure !== newPickup.departure;
        const driverDepartureTimeHasChanged = currentPickup.driverDepartureTime !== newPickup.driverDepartureTime;
        const arrivalHasChanged = currentPickup.arrival !== newPickup.arrival;
        const bookingHasChanged = currentPickup.booking.id !== newPickup.booking.id;
        const modeHasChanged = currentPickup.mode.id !== newPickup.mode.id;
        const currentPickupHasDriver = typeof currentPickup.driver !== 'undefined' && currentPickup.driver !== null;
        const newPickupHasDriver = typeof newPickup.driver !== 'undefined' && newPickup.driver !== null;
        const driverHasChanged = (
            (currentPickupHasDriver && !newPickupHasDriver) ||
            (newPickupHasDriver && !currentPickupHasDriver) ||
            (
                currentPickupHasDriver &&
                newPickupHasDriver &&
                currentPickup.driver.id !== newPickup.driver.id
            )
        );
        return (
            driverDepartureTimeHasChanged ||
            departureHasChanged ||
            arrivalHasChanged ||
            bookingHasChanged ||
            modeHasChanged ||
            driverHasChanged
        )
    });
};

export const actionsTemplate = () => html`
    <div class="fc-pickup-actions">
        <a class="fc-pickup-action fc-pickup-action-delete" title="Delete pickup">
            <i class="bs-icon-cross" />
        </a>
    </div>
`;

export const iconsTemplate = (icon) =>
    html`<i class="${icon}" style="margin-right: var(--spacingSmallest);" />`

export const calculateDateWithExtraSchedulerHours = (date) =>
    reformatISOString(moment.utc(date).add(EXTRA_HOURS_AFTER_DAY_END, 'hours').toISOString());
