import OptionTypes from 'app/backbone/helpers/OptionTypes';
import AccrediteeProfilesCard from 'app/backbone/views/event/accreditation/accreditees/profiles/cards/AccrediteeProfilesCard.view';
import AvailableCompanyProfileTable from '../tables/AvailableCompanyProfileTable.view';
import CompanyProfileModalAdd from '../modals/CompanyProfileModalAdd.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';

export default class CompanyProfilesSettingsCard extends AccrediteeProfilesCard {

    optionTypes() {
        const { title, periods, profiles } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            profiles,
            periods,
            title
        };
    }

    initContent() {
        const { profiles, periods, items, model: { profiles: collection } } = this;
        return new AvailableCompanyProfileTable({ collection, profiles, periods, items });
    }

    initAddModel() {
        const profiles = this.getModalProfiles();
        const addModal = new CompanyProfileModalAdd({ profiles });
        this.listenTo(addModal, 'add', this.onAdded);
        return addModal;
    }

    onValidated(isModelValid, model, errors) {
        if (isModelValid) this.removeError();
        else {
            if (errors.profiles) this.addError(errors.profiles);
            else if (errors.profileItems) this.addError(errors.profileItems);
        }
    }

}
