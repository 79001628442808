import * as stateHelpers from 'app/react/state/helpers';
import * as actions from 'app/react/state/actions/_index';

export const CRETE_MODAL_ID = 'createDriverModal';

export const getCreateModalState = (state) =>
stateHelpers.getComponentState(state, CRETE_MODAL_ID) || {};

export const mergeCreateModalState = (newState) =>
    actions.mergeComponentState(CRETE_MODAL_ID, newState);

export const setCreateModalStateValue = (key, value) =>
    actions.setComponentStateValue(CRETE_MODAL_ID, key, value);

export const mergeCreateModalStateValue = (key, newState) =>
    actions.mergeComponentStateValue(CRETE_MODAL_ID, key, newState);

export const deleteCreateModalStateValue = (key) =>
    actions.deleteComponentStateValue(CRETE_MODAL_ID, key);
