import { schema } from 'normalizr';
import { CALL_API, METHODS } from 'app/react/state/middleware/api';
import { createEntityReducer } from 'app/react/state/reducers/higher_order/_index';
import {
    getSomeEntitiesDenormalized
} from 'app/react/state/helpers';

export const ONE_REQUEST = 'ONE_PERFORMANCE_TIME_SLOT_REQUEST';
export const ONE_SUCCESS = 'ONE_PERFORMANCE_TIME_SLOT_SUCCESS';
export const ONE_FAILURE = 'ONE_PERFORMANCE_TIME_SLOT_FAILURE';

export const ENTITY_REDUCER_KEY = 'performanceTimeSlots';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const entity = new schema.Entity(ENTITY_REDUCER_KEY);
const array = new schema.Array(entity);

const getSomeDenormalized = (state, ids) =>
    getSomeEntitiesDenormalized(state, ENTITY_REDUCER_KEY, entity, ids);

const entityReducer = createEntityReducer({
    types: [
        ONE_REQUEST,
        ONE_SUCCESS,
        ONE_FAILURE
    ]
});

const Endpoints = {
    createOneForPerformance: (eventId, performanceId) =>
        `events/${eventId}/performances/${performanceId}/time-slots`,
    updateOneForPerformance: (eventId, performanceId, timeSlotId) =>
        `events/${eventId}/performances/${performanceId}/time-slots/${timeSlotId}`,
    deleteOneForPerformance: (eventId, performanceId, timeSlotId) =>
        `events/${eventId}/performances/${performanceId}/time-slots/${timeSlotId}`
};

const createOneForPerformance = (eventId, performanceId, values) => ({
    performanceId, // We pass this to add the booking to the performance
    [CALL_API]: {
        types: [
            ONE_REQUEST,
            ONE_SUCCESS,
            ONE_FAILURE
        ],
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOneForPerformance(eventId, performanceId),
        schema: entity
    }
});

const updateOneForPerformance = (eventId, performanceId, timeSlotId, values) => ({
    performanceId, // We pass this to add the booking to the performance
    [CALL_API]: {
        types: [
            ONE_REQUEST,
            ONE_SUCCESS,
            ONE_FAILURE
        ],
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOneForPerformance(eventId, performanceId, timeSlotId),
        schema: entity
    }
});

const deleteOneForPerformance = (eventId, performanceId, timeSlotId) => ({
    performanceId, // We pass this to remove the booking form the performance
    [CALL_API]: {
        types: [
            ONE_REQUEST,
            ONE_SUCCESS,
            ONE_FAILURE
        ],
        method: METHODS.DELETE,
        id: timeSlotId,
        endpoint: Endpoints.deleteOneForPerformance(eventId, performanceId, timeSlotId),
        schema: entity
    }
});

export const stateHelpers = {
    getSomeDenormalized
};


export const reducers = {
    entity: entityReducer,
};

export const schemas = {
    entity,
    array
};

export const actions = {
    createOneForPerformance,
    deleteOneForPerformance,
    updateOneForPerformance
};
