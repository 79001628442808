import Backbone from 'backbone'
import Backgrid from 'backgrid'
import OptionTypes from 'app/backbone/helpers/OptionTypes'
import BaseView from 'app/backbone/components/Base.view'
import template from './Table.hbs'
import './Table.css'

export default class Table extends BaseView {

    get template() {
        return template;
    }

    className() {
        return 'backgrid overflow';
    }

    ui() {
        return {
            grid: '.table-container'
        };
    }

    optionTypes() {
        return {
            collection: OptionTypes.instanceOf(Backbone.Collection).isRequired,
            columns: OptionTypes.arrayOf(OptionTypes.object),
            actions: OptionTypes.arrayOf(OptionTypes.object),
            filtersDef: OptionTypes.arrayOf(OptionTypes.object),
            header: OptionTypes.func,
            row: OptionTypes.func,
            placeholderHeight: OptionTypes.number,
            placeholderText: OptionTypes.string,
            pageSizeOptions: OptionTypes.arrayOf(OptionTypes.number),
            overflowX: OptionTypes.bool,
            overflowY: OptionTypes.bool
        };
    }

    optionDefaults() {
        return {
            actions: [],
            filtersDef: [],
            header: Backgrid.Header,
            row: Backgrid.Row,
            placeholderHeight: 200,
            placeholderText: 'No records.'
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Props
        this.hasActions = !! this.actions.length;
        // Subviews
        this.table = this.initTable();
        this.grid = this.table;

        // Listeners
        this.listenTo(this.collection, 'update reset', this.renderMaybe);
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.insertSubview(this.grid, this.ui.grid);

        this.grid.$el.toggleClass('actions-right', this.hasActions);

        this.isRenderedEmpty = this.isEmpty();
        return this.delegateEvents();
    }

    remove() {
        this.grid.remove();
        super.remove();
    }

    renderMaybe() {
        // To not always re-render the table we only render the table again if:
        // it has become empty from being filled (shows empty placeholder)
        // it has become filled from being empty (shows backgrid)
        if (this.isRenderedEmpty && !this.isEmpty() || !this.isRenderedEmpty && this.isEmpty()) {
            this.render();
        }
    }

    viewModel() {
        return {
            view: {
                placeholderHeight: this.placeholderHeight,
                placeholderText: this.placeholderText,
                totalRecords: this.getTotalRecords(),
            },
        };
    }

    isEmpty() {
        return this.collection.isEmpty();
    }

    initTable() {
        const { collection, columns, header, body, row, className } = this;
        return new Backgrid.Grid({ collection, columns, header, body, row, className });
    }

    initColumns() {
        return [];
    }

    initActions() {
        return [];
    }

    getTotalRecords() {
        return this.collection.length;
    }

    getSelectedModels() {
        return this.table.getSelectedModels();
    }

    clearSelectedModels() {
        this.grid.render();
        this.grid.clearSelectedModels();
    }

    checkOverflow(el) {
        if (el) {
            return {
                vertical: el.scrollHeight > el.clientHeight,
                horizontal: el.scrollWidth > el.clientWidth
            };
        } else {
            return {};
        }
    }

}
