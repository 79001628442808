import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import gql from 'graphql-tag'
import { startCase } from 'lodash'
import { notifySuccess, notifyError } from 'ui-kit'
import { isCrew, isGuest, isPerformer } from 'app/react/helpers/graphQlTypes'
import RemoveAttendeeModalWithState from './RemoveAttendeeModalWithState'

function notifyAttendeeWasRemovedSuccessfully({ firstName, lastName, __typename }) {
    notifySuccess(`${firstName} ${lastName} was successfully removed as a ${startCase(__typename)} from this event.`);
}

function notifySomethingWentWrong({ firstName, lastName, __typename }) {
    notifyError(`${firstName} ${lastName} could not be removed as a ${startCase(__typename)} for this event. You may be missing some permissions to do so.`);
}

const PERFORMER_MUTATION = gql`
    mutation DeletePerformerMutation($id: ID!) {
        deletePerformer(id: $id) {
            id
        }
    }
`;

const injectPerformerMutation = graphql(
    PERFORMER_MUTATION, {
        name: 'deletePerformerMutation',
    },
);

const CREW_MEMBER_MUTATION = gql`
    mutation DeleteCrewMemberMutation($id: ID!) {
        deleteCrewMember(id: $id) {
            id
        }
    }
`;

const injectCrewMemberMutation = graphql(
    CREW_MEMBER_MUTATION, {
        name: 'deleteCrewMemberMutation',
    },
);

const GUEST_MUTATION = gql`
    mutation DeleteGuestMutation($id: ID!) {
        deleteGuest(id: $id) {
            id
        }
    }
`;

const injectGuestMutation = graphql(
    GUEST_MUTATION, {
        name: 'deleteGuestMutation',
    },
);

function resolveMutationForAttendee(attendee, {
    deletePerformerMutation,
    deleteCrewMemberMutation,
    deleteGuestMutation,
}) {
    if (isPerformer(attendee)) {
        return deletePerformerMutation;
    } else if (isCrew(attendee)) {
        return deleteCrewMemberMutation;
    } else if (isGuest(attendee)) {
        return deleteGuestMutation;
    } else {
        notifySomethingWentWrong(attendee);

        throw new Error(`The attendee should be a Performer, CrewMember or Guest. Received ${attendee.__typename}.`);
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onDelete: async () => {
        const {
            attendee,
            onDelete,
        } = ownProps;
        const mutation = resolveMutationForAttendee(attendee, ownProps);

        try {
            await mutation({
                variables: { id: attendee.id },
            });
            onDelete();
            notifyAttendeeWasRemovedSuccessfully(attendee);
        } catch (e) {
            notifySomethingWentWrong(attendee);

            throw e;
        }
    }
});

const injectState = connect(
    null,
    mapDispatchToProps,
);

export default compose(
    injectPerformerMutation,
    injectCrewMemberMutation,
    injectGuestMutation,
    injectState,
)(RemoveAttendeeModalWithState);
