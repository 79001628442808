import BaseController from 'app/backbone/helpers/BaseController'
import TaskFormView from 'app/backbone/views/event/artists/bookings/tasks/Form.view'

const createRoute = (
    eventId = ':event',
    bookingId = ':booking',
) => `events/${eventId}/artists/bookings/${bookingId}/tasks/create`;

const updateRoute = (
    eventId = ':event',
    bookingId = ':booking',
    taskId = ':task',
) => `events/${eventId}/artists/bookings/${bookingId}/tasks/${taskId}/edit`;

export default class TasksController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(createRoute(), null, this.create.bind(this));
        this.router.route(updateRoute(), null, this.update.bind(this));
    }

    create() {
        this.app.renderContentView(new TaskFormView());
    }

    update() {
        this.app.renderContentView(new TaskFormView());
    }
};
