import React from 'react'
import ContentHeader from 'app/react/views/account/settings/account/components/layout/ContentHeader'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import DangerZoneCard from 'app/react/views/account/settings/account/privacy/components/DangerZoneCard'

const { Content } = ContentWrapper;

const OverviewView = ({
    store,
    accountId,
    canManageAccount,
}) => {
    return (
        <ContentWrapper store={store}>
            <ContentHeader activeTab="privacy" />
            <Content isLoading={false}>
                {canManageAccount
                    ? <DangerZoneCard accountId={accountId} />
                    : <h1>Looks like you're not allowed here.</h1>
                }
            </Content>
        </ContentWrapper>
    );
};

OverviewView.defaultProps = {
    canManageAccount: false,
};

export default OverviewView;
