import React from 'react'
import { ConfirmModal } from 'ui-kit'
import { startCase } from 'lodash'

const RemoveAttendeeModal = ({
    isLoading,
    attendee,
    renderTrigger,
    onConfirm,
}) => (
    <ConfirmModal
        isLoading={isLoading}
        title="Remove attendee"
        renderTrigger={renderTrigger}
        confirmButtonText="Yes, remove"
        onConfirm={onConfirm}
        onCancel={({ closeModal }) => closeModal()}
    >
        <p>
            Are you sure you want to remove <strong>{attendee.firstName} {attendee.lastName}</strong> as a {startCase(attendee.__typename)} from this event?
        </p>
    </ConfirmModal>
);

export default RemoveAttendeeModal;
