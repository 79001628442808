import { schema } from 'normalizr';

export const ENTITY_REDUCER_KEY = 'stages';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

const fromAPI = (entity, parent) => {
    if (parent instanceof Array || typeof parent === 'undefined') {
        return entity;
    }
    return {
        ...entity,
        day: parent.id
    }
};

const entityDefinition = {};
const entityOptions = { processStrategy: fromAPI };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);
