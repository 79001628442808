import React from 'react';
import PropTypes from 'prop-types';
import {
    InputGroup,
    DateTimeInput,
    Grid,
    PostfixedInput,
} from 'ui-kit';
import { Tooltip } from 'app/react/components/_index';
import SelectDay from '../../../performances/views/selects/SelectDay';
import SelectStage from '../../../performances/views/selects/SelectStage';

const { Cell } = Grid;

const PERFORMANCE_NAME_LABEL = 'Performance name';
const PERFORMANCE_CHANGEOVER_LABEL = 'Changeover duration';
const PERFORMANCE_CHANGEOVER_POSTFIX = 'min';
const PERFORMANCE_PUBLISHED_DATE_LABEL = 'Publish date/time';
const PERFORMANCE_START_LABEL = 'Start date/time';
const PERFORMANCE_END_LABEL = 'End date/time';
const SELECT_DAY_LABEL = 'Day';
const SELECT_STAGE_LABEL = 'Stage';
const EDIT_PERFORMANCE_NAME_TOOLTIP = 'Set a custom performance name';
const REMOVE_PERFORMANCE_NAME_TOOLTIP = 'Remove custom performance name';

const CreatePerformanceFields = ({
    isEditingPerformanceName = false,

    values = {},
    errors = {},

    dateFormat,
    timeFormat,
    dayStart,
    dayEnd,

    days = [],
    stages = [],

    onChangeValue = () => {},
    onSelectDay = () => {},
    onToggleEditPerformanceName = () => {}
}) => {
    const defaultDate = days[0].start;
    const indexLastDay = days.length - 1;
    const periods = [{
        start: days[0].start,
        end: days[indexLastDay].end,
        name: "Event"
    }];

    return (
        <div>
            <InputGroup messages={errors.title}>
                <label>{PERFORMANCE_NAME_LABEL}</label>
                <PostfixedInput.Container
                    postfix={!isEditingPerformanceName 
                        ? <Tooltip content={EDIT_PERFORMANCE_NAME_TOOLTIP}>
                            <button
                                onClick={() => onToggleEditPerformanceName(true)}
                                className="button postfix button-icon success"
                            >
                                Edit
                            </button>
                        </Tooltip> 
                        : <Tooltip content={REMOVE_PERFORMANCE_NAME_TOOLTIP}>
                            <button
                                onClick={() => onToggleEditPerformanceName(false)}
                                className="button postfix button-icon"
                            >
                                Delete
                            </button>
                        </Tooltip>
                    }
                >
                    <input
                        type="text"
                        value={values.title || ''}
                        disabled={!isEditingPerformanceName}
                        onChange={e => onChangeValue('title', e.target.value)}
                    />
                </PostfixedInput.Container>
            </InputGroup>
            {days.length > 1 &&
                <div>
                    <InputGroup messages={errors['performance.day']}>
                        <label className="required">
                            {SELECT_DAY_LABEL}
                        </label>
                        <SelectDay
                            days={days}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            value={values.day}
                            onChange={value => onSelectDay(value)}
                        />
                    </InputGroup>
                </div>
            }
            <InputGroup messages={errors['performance.stage']}>
                <label className="required">
                    {SELECT_STAGE_LABEL}
                </label>
                <SelectStage
                    stages={values.day ? stages.filter(({ day }) => day === values.day) : stages}
                    value={values.stage}
                    onChange={value => onChangeValue('stage', value)}
                />
            </InputGroup>
            <Grid className="no-margin-bottom">
                <Cell>
                    <InputGroup messages={errors['performance.start']}>
                        <label className="required">
                            {PERFORMANCE_START_LABEL}
                        </label>
                        <DateTimeInput
                            value={values.start}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            minDate={dayStart}
                            maxDate={dayEnd}
                            defaultDate={defaultDate}
                            periods={periods}
                            onChange={value => onChangeValue('start', value)}
                        />
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors['performance.end']}>
                        <label className="required">
                            {PERFORMANCE_END_LABEL}
                        </label>
                        <DateTimeInput
                            value={values.end}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            minDate={dayStart}
                            maxDate={dayEnd}
                            defaultDate={defaultDate}
                            periods={periods}
                            onChange={value => onChangeValue('end', value)}
                        />
                    </InputGroup>
                </Cell>
            </Grid>
            <Grid className="no-margin-bottom">
                <Cell>
                    <InputGroup messages={errors['performance.publishDate']}>
                        <label className="title">{PERFORMANCE_PUBLISHED_DATE_LABEL}</label>
                        <DateTimeInput
                            value={values.publishDate}
                            dateFormat={dateFormat}
                            timeFormat={timeFormat}
                            defaultDate={defaultDate}
                            periods={periods}
                            onChange={value => onChangeValue('publishDate', value)}
                        />
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors['performance.changeover']}>
                        <label>{PERFORMANCE_CHANGEOVER_LABEL}</label>
                        <PostfixedInput label={PERFORMANCE_CHANGEOVER_POSTFIX}>
                            <input
                                type="number"
                                value={values.changeover || ''}
                                onChange={e => onChangeValue('changeover', e.target.value)}
                            />
                        </PostfixedInput>
                    </InputGroup>
                </Cell>
            </Grid>
        </div>
    )
};

CreatePerformanceFields.propTypes = {
    isEditingPerformanceName: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    dayStart: PropTypes.string,
    dayEnd: PropTypes.string,

    days: PropTypes.array,
    stages: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectDay: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
};

export default CreatePerformanceFields;
