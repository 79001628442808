import * as User from 'app/backbone/models/account/User';
import * as Account from 'app/backbone/models/account/Account';
import * as Event from 'app/backbone/models/event/Event';

export default class Data {

    register(name, Class, options, data) {
        const found = this[name];
        if (found) return found;
        this[name] = new Class(data, options);
        return this[name];
    }

    registerCurrentUser(id) {
        const user = this.register('currentUser', User.Model);
        if (id) user.set('id', parseInt(id, 10));
        return user;
    }

    registerCurrentAccount(id) {
        const account = this.register('currentAccount', Account.Model);
        if (id) account.set('id', parseInt(id, 10));
        else account.getIdFromDOM('auth.account');
        return account;
    }

    registerCurrentEvent(id) {
        const account = this.registerCurrentAccount();
        const event = this.register('currentEvent', Event.Model, { account });
        if (id) event.set('id', parseInt(id, 10));
        return event;
    }

    registerCurrentEventAndAccount(eventId) {
        const event = this.registerCurrentEvent(eventId);
        const account = event.getRelation('account');
        return { account, event };
    }

}
