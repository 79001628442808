import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Event from 'app/react/entities/events/index';
import * as TicketType from 'app/react/entities/events/ticket_types/index';
import * as Day from 'app/react/entities/events/days/index';
import BoxOffice from 'app/react/views/event/artists/box-office/views/OverviewContainer';
import * as Routes from './routes';

export default class EntranceController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.boxOffice(), null, this.boxOffice.bind(this));
    }

    boxOffice(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(BoxOffice, { store }));

        // AccountId is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(TicketType.thunks.fetchAllForCurrentContext()),
        ]);
    }
}
