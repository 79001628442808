import {
    createEntityReducer,
    createCollectionReducerWithKey,
    createPaginationReducerWithKey
} from 'app/react/state/reducers/higher_order/_index'
import {
    generateCollectionKey
} from 'app/react/entities/events/helpers'
import {
    ENTITY_REDUCER_KEY,
    COLLECTION_REDUCER_KEY,
    PAGINATION_REDUCER_KEY
} from './schemas'
import {
    ONE_TYPES,
    ALL_TYPES,
    ALL_PAGINATED_TYPES
} from './actions'

const entityReducer = createEntityReducer({
    types: ONE_TYPES
});

const collectionReducer = createCollectionReducerWithKey({
    mapActionToKey: ({ accountId, eventId }) =>
        generateCollectionKey(accountId, eventId),
    types: ALL_TYPES,
    oneTypes: ONE_TYPES
});

const paginationReducer = createPaginationReducerWithKey({
    mapActionToKey: ({ accountId, eventId }) =>
        generateCollectionKey(accountId, eventId),
    types: ALL_PAGINATED_TYPES,
    oneTypes: ONE_TYPES
});

export const entityReducers = {
    [ENTITY_REDUCER_KEY]: entityReducer,
};

export const collectionReducers = {
    [COLLECTION_REDUCER_KEY]: collectionReducer
};

export const paginationReducers = {
    [PAGINATION_REDUCER_KEY]: paginationReducer,
};
