import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Booking from 'app/react/entities/events/bookings/helpers';
import { createOne, fetchOne, updateOne, deleteOne } from './actions';
import { getCurrentId } from './helpers';

export const createOneForCurrentContext = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    return dispatch(createOne(accountId, eventId, bookingId, values));
};

export const fetchCurrent = (includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    const performerId = getCurrentId(state);
    return dispatch(fetchOne(accountId, eventId, bookingId, performerId, includes));
};

export const updateCurrent = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const bookingId = Booking.getCurrentId(state);
    const performerId = getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, bookingId, performerId, values));
};

export const deleteOneForBookingInCurrentContext = (bookingId, performerId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(deleteOne(accountId, eventId, bookingId, performerId));
};

export const toggleIsCheckInForBookingInCurrentContext = (bookingId, performerId, isCheckedIn) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, bookingId, performerId, { isCheckedIn }));
};
