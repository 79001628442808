import BaseView from 'app/backbone/components/Base.view';
import UploadedImage from 'app/backbone/components/forms/UploadedImage.view';

export default class EditStageView extends BaseView {

    ui() {
        return {
            uploadedImage: '.image-with-detail'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.initViewsForEachEl(UploadedImage, this.ui.uploadedImage).forEach(view => view.render());

        return this.delegateEvents();
    }

}

