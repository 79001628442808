import React from 'react'
import Header from 'app/react/components/layout/content/header/Header'
import { OVERVIEW_TAB_ID, DASHBOARD_TAB_ID } from './ContentHeaderContainer'
import * as Routes from 'app/routes/event/artists/reservations/routes'

const { TitleMenu, Tabs } = Header;
const { Title, Actions } = TitleMenu;
const { Tab } = Tabs;

const TITLE = 'Accommodations';
const OVERVIEW_TAB = 'Overview';
const DASHBOARD_TAB = 'Dashboard';

const isOverview = (tabId) => tabId === OVERVIEW_TAB_ID;

const ReservationsContentHeader = ({
    eventId,
    activeTab,
    onDownloadReport = () => {},
}) => {
    return (
        <Header>
            <TitleMenu>
                <Title>{TITLE}</Title>
                {isOverview(activeTab) &&
                    <Actions>
                        <a className="button primary" onClick={onDownloadReport}>
                            Download report
                        </a>
                    </Actions>
                }
            </TitleMenu>
            <Tabs>
                <Tab activeTab={activeTab} tabId={OVERVIEW_TAB_ID} url={Routes.overview(eventId)}>
                    {OVERVIEW_TAB}
                </Tab>
                <Tab activeTab={activeTab} tabId={DASHBOARD_TAB_ID} url={Routes.dashboard(eventId)}>
                    {DASHBOARD_TAB}
                </Tab>
            </Tabs>
        </Header>
    );
};

export default ReservationsContentHeader;
