import BaseView from 'app/backbone/components/Base.view';

export default class MenuDropdown extends BaseView {

    initialize(options = {}) {
        super.initialize(options);
        this.shown = false;
    }

    toggle() {
        this.$el.toggleClass('fadeIn fadeOut');
        this.$el.toggleClass('hidden');
        this.shown = !this.shown;
    }

    show() {
        this.$el.addClass('fadeIn');
        this.$el.removeClass('fadeOut');
        this.$el.removeClass('hidden');
        this.shown = true;
    }

    hide() {
        this.$el.removeClass('fadeIn');
        this.$el.addClass('fadeOut');
        this.$el.addClass('hidden');
        this.shown = false;
    }
}
