import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';
import { Tooltip, NoRecordsPlaceholder } from 'app/react/components/_index';

const { Header } = Card;
const { Title, Action } = Header;

const TITLE = 'Groups';
const CREATE_TOOLTIP = 'Create new groups';
const NO_RECORDS_PLACEHOLDER_TEXT = 'No groups created.';
const NO_RECORDS_CREATE_TEXT = 'Create new group';

const ArtistsGroupsCard = ({
    hasRecords = false,
    children,
    onCreate = () => {},
}) => (
    <Card>
        <Header>
            <Title>{TITLE}</Title>
            <Tooltip content={CREATE_TOOLTIP}>
                <Action onClick={onCreate}>
                    <i className="bs-icon-plus" />
                </Action>
            </Tooltip>
        </Header>
        <Card.Content
            isFlexContainer={!hasRecords}
            style={{ minHeight: '300px' }}
        >
            { !hasRecords ?
                <NoRecordsPlaceholder style={{ flex: 1, flexDirection: 'column' }}>
                    {NO_RECORDS_PLACEHOLDER_TEXT}
                    <div>
                        <a onClick={onCreate}>
                            {NO_RECORDS_CREATE_TEXT}
                        </a>
                    </div>
                </NoRecordsPlaceholder> :
                children
            }
        </Card.Content>
    </Card>
);

ArtistsGroupsCard.propTypes = {
    hasRecords: PropTypes.bool,
    children: PropTypes.node,
    onOpenCreateModal: PropTypes.func,
};

export default ArtistsGroupsCard;
