import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from  'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { UpdateButtonRow } from 'app/react/components/_index';
import ContentHeader, { TABS } from '../../../../views/ContentHeader';
import EditSectionCard from './EditSectionCard';
import ExampleFormCard from './ExampleFormCard';

const { Content } = ContentWrapper;
const { Cell } = Grid;

export const BACK_BUTTON_TEXT = 'Back';

const EditSectionView = ({
    store,
    eventId,
    sectionId,
    sectionName,
    isFetching,
    isArtistTypesEnabled,

    Form,
    isAccreditationEnabled,
    accreditationProfileOptions,
    ticketTypeOptions,
    formValues,
    formErrors,
    onChangeFormValue,

    exampleFormFields,
    dateFormat,

    backToSectionOverview = () => {},
    goToFormBuilder = () => {},
    updateSection = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            activeTab={TABS.ARTIST_TYPES.ID}
            eventId={eventId}
            artistTypesEnabled={isArtistTypesEnabled}
        >
            <a className="button primary" onClick={backToSectionOverview}>
                {BACK_BUTTON_TEXT}
            </a>
        </ContentHeader>
        <Content isLoading={isFetching}>
            {(() => {
                if (sectionId === 'performers' || sectionId === 'crew') {
                    return (
                        <Grid>
                            <Cell>
                                <EditSectionCard
                                    title={sectionName}
                                    Form={Form}
                                    isAccreditationEnabled={isAccreditationEnabled}
                                    accreditationProfileOptions={accreditationProfileOptions}
                                    ticketTypeOptions={ticketTypeOptions}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    onChangeFormValue={onChangeFormValue}
                                />
                                <UpdateButtonRow onUpdate={updateSection} />
                            </Cell>
                            <Cell>
                                <ExampleFormCard
                                    formFields={exampleFormFields}
                                    dateFormat={dateFormat}
                                    goToFormBuilder={goToFormBuilder}
                                />
                            </Cell>
                        </Grid>
                    );
                }
                return (
                    <div>
                        <EditSectionCard
                            title={sectionName}
                            Form={Form}
                            isAccreditationEnabled={isAccreditationEnabled}
                            accreditationProfileOptions={accreditationProfileOptions}
                            ticketTypeOptions={ticketTypeOptions}
                            formValues={formValues}
                            formErrors={formErrors}
                            onChangeFormValue={onChangeFormValue}
                        />
                        <UpdateButtonRow onUpdate={updateSection} />
                    </div>
                );
            })()}
        </Content>
    </ContentWrapper>
);

EditSectionView.propTypes = {
    store: PropTypes.object,
    eventId: PropTypes.number,
    sectionId: PropTypes.string,
    sectionName: PropTypes.string,
    isFetching: PropTypes.bool,
    isArtistTypesEnabled: PropTypes.bool,

    Form: PropTypes.func,
    isAccreditationEnabled: PropTypes.bool,
    accreditationProfileOptions: PropTypes.array,
    ticketTypeOptions: PropTypes.array,
    formValues: PropTypes.object,
    formErrors: PropTypes.object,
    onChangeFormValue: PropTypes.func,

    exampleFormFields: PropTypes.array,
    dateFormat: PropTypes.string,

    backToSectionOverview: PropTypes.func,
    goToFormBuilder: PropTypes.func,
    updateSection: PropTypes.func
};

export default EditSectionView;
