import React from 'react';
import PropTypes from 'prop-types';
import BookingAddRow from './rows/Add';
import BookingEditRow from './rows/Edit';
import BookingRow from './rows/Default';
import BookingHeaderRow from './rows/Header';
import BookingFooterRow from './rows/Footer';
import BookingSavingRow from './rows/Saving';

const PerformanceBookingTable = ({
    isArtistTypesEnabled = true,
    isCreatingNewArtist = false,
    bookings = [],
    artists = [],
    artistTypes = [],
    bookingTypes = [],
    bookingStatuses = [],
    unassignedBookings = [],
    isFetchingUnassignedBookings = false,
    newBooking,
    existingBooking,
    editingBookings = [],
    onViewDetailsBooking = () => {},
    onUpdateBooking = () => {},
    onRemoveBookingFromPerformance = () => {},
    onDeleteBooking = () => {},
    onCreateNewBooking = () => {},
    onAddExistingBooking = () => {},
    onChangeNewBookingValue = () => {},
    onChangeExistingBookingValue = () => {},
    onChangeBookingValue = () => {},
    onToggleCreateNewArtist = () => {},
    onSaveBooking = () => {},
    onCancelBooking = () => {},
    onSaveNewBooking = () => {},
    onCancelNewBooking = () => {},
    onSaveAddExistingBooking = () => {},
    onCancelAddExistingBooking = () => {},
}) => (
    <table className="actions-right no-margin-tb isLarge">
        <thead>
            <BookingHeaderRow
                isArtistTypesEnabled={isArtistTypesEnabled}
            />
        </thead>
        <tbody>
            {bookings.map((booking) => {
                const editingBooking = editingBookings.find(({ id }) => id === booking.id);
                if (typeof editingBooking !== 'undefined') {
                    if (editingBooking.isSaving) {
                        return <BookingSavingRow key={editingBooking.id} />;
                    }
                    return (
                        <BookingEditRow
                            key={editingBooking.id}
                            id={editingBooking.id}
                            artistName={editingBooking.artist && editingBooking.artist.name}
                            artistTypeId={editingBooking.artistType && editingBooking.artistType.id}
                            bookingTypeId={editingBooking.type && editingBooking.type.id}
                            bookingStatusId={editingBooking.status && editingBooking.status.id}
                            errors={editingBooking.errors}
                            isArtistTypesEnabled={isArtistTypesEnabled}
                            artists={artists}
                            artistTypes={artistTypes}
                            bookingTypes={bookingTypes}
                            bookingStatuses={bookingStatuses}
                            onChangeValue={onChangeBookingValue}
                            onToggleCreateNewArtist={onToggleCreateNewArtist}
                            onSave={onSaveBooking}
                            onCancel={onCancelBooking}
                        />
                    );
                }
                return (
                    <BookingRow
                        key={booking.id}
                        booking={booking}
                        isArtistTypesEnabled={isArtistTypesEnabled}
                        onDetails={onViewDetailsBooking}
                        onUpdate={onUpdateBooking}
                        onRemoveFromPerformance={onRemoveBookingFromPerformance}
                        onDelete={onDeleteBooking}
                    />
                );
            })}
            {(() => {
                if (typeof newBooking !== 'undefined') {
                    if (newBooking.isSaving) {
                        return <BookingSavingRow />;
                    }
                    return (
                        <BookingEditRow
                            isNew
                            isCreatingNewArtist={isCreatingNewArtist}
                            artistId={newBooking.artist}
                            artistName={newBooking.artistName}
                            artistTypeId={newBooking.artistType}
                            bookingTypeId={newBooking.type}
                            bookingStatusId={newBooking.status}
                            errors={newBooking.errors}
                            isArtistTypesEnabled={isArtistTypesEnabled}
                            artists={artists}
                            artistTypes={artistTypes}
                            bookingTypes={bookingTypes}
                            bookingStatuses={bookingStatuses}
                            onChangeValue={onChangeNewBookingValue}
                            onToggleCreateNewArtist={onToggleCreateNewArtist}
                            onSave={onSaveNewBooking}
                            onCancel={onCancelNewBooking}
                        />
                    );
                } else if (typeof existingBooking !== 'undefined') {
                    if (existingBooking.isSaving) {
                        return <BookingSavingRow />;
                    }
                    return (
                        <BookingAddRow
                            isArtistTypesEnabled={isArtistTypesEnabled}
                            bookings={unassignedBookings}
                            isFetchingBookings={isFetchingUnassignedBookings}
                            values={existingBooking}
                            onChangeValue={onChangeExistingBookingValue}
                            onSave={onSaveAddExistingBooking}
                            onCancel={onCancelAddExistingBooking}
                        />
                    );
                }
                return (
                    <BookingFooterRow
                        onCreateNew={onCreateNewBooking}
                        onAddExisting={onAddExistingBooking}
                        isArtistTypesEnabled={isArtistTypesEnabled}
                    />
                );
            })()}
        </tbody>
    </table>
);

PerformanceBookingTable.propTypes = {
    isArtistTypesEnabled: PropTypes.bool,
    isCreatingNewArtist: PropTypes.bool,
    bookings: PropTypes.array,
    artists: PropTypes.array,
    artistTypes: PropTypes.array,
    bookingTypes: PropTypes.array,
    bookingStatuses: PropTypes.array,
    unassignedBookings: PropTypes.array,
    newBooking: PropTypes.object,
    existingBooking: PropTypes.object,
    editingBookings: PropTypes.array,
    onViewDetailsBooking: PropTypes.func,
    onUpdateBooking: PropTypes.func,
    onRemoveBookingFromPerformance: PropTypes.func,
    onDeleteBooking: PropTypes.func,
    onCreateNewBooking: PropTypes.func,
    onAddExistingBooking: PropTypes.func,
    onChangeNewBookingValue: PropTypes.func,
    onChangeExistingBookingValue: PropTypes.func,
    onChangeBookingValue: PropTypes.func,
    onToggleCreateNewArtist: PropTypes.func,
    onSaveBooking: PropTypes.func,
    onCancelBooking: PropTypes.func,
    onSaveNewBooking: PropTypes.func,
    onCancelNewBooking: PropTypes.func,
    onSaveAddExistingBooking: PropTypes.func,
    onCancelAddExistingBooking: PropTypes.func
};

export default PerformanceBookingTable;
