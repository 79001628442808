import React from 'react'
import {
    AlertBox,
    Card,
    PaginationBar,
} from 'ui-kit'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import { TableLoadingPlaceholder } from 'app/react/components/_index'
import ReservationsContentHeader, { DASHBOARD_TAB_ID } from '../../components/layout/ContentHeaderContainer'
import Table from './components/table/Table'
import TableTopBar from './components/table/TopBar'

const { Content } = ContentWrapper;
const { Header } = Card;
const { Title } = Header;

const CARD_TITLE = 'Reservations by Promoter';
const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';

const ReservationDashboard = ({
    store,
    currencySymbol,
    dateFormatShort,
    userCanManageAccommodations = false,
    timeFormat,
    dateFormat,

    isFetching = false,
    expandedRows = [],
    days = [],
    accommodations = [],
    accommodationsPagination = {},

    onChangePageSize = () => {},
    onChangeSearchValue = () => {},
    onSearch = () => {},
    onClearSearch = () => {},
    onSort = () => {},
    onChangePage = () => {},
    onToggleRowExpansion,
}) => {
    const {
        isFetching: isFetchingAccommodations = false,
        requestedSortKey,
        requestedSortOrder,
        requestedPageSize = 100,
        requestedFilters = {},
        requestedSearch,
        requestedPage,
        totalPages,
        totalCount
    } = accommodationsPagination;
    return (
        <ContentWrapper store={store}>
            <ReservationsContentHeader activeTab={DASHBOARD_TAB_ID} />
            <Content isLoading={isFetching}>
                {!userCanManageAccommodations
                    ? <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox>
                    : <Card>
                        <Header>
                            <Title>{CARD_TITLE}</Title>
                        </Header>
                        <Card.Content>
                            <TableTopBar
                                searchValue={requestedSearch}
                                onChangeSearchValue={onChangeSearchValue}
                                onSearch={onSearch}
                                onClearSearch={onClearSearch}
                            />
                            {isFetchingAccommodations
                                ? <TableLoadingPlaceholder />
                                : <Table
                                    accommodations={accommodations}
                                    days={days}
                                    currencySymbol={currencySymbol}
                                    dateFormatShort={dateFormatShort}
                                    sortKey={requestedSortKey}
                                    sortOrder={requestedSortOrder}
                                    onSort={onSort}
                                    expandedRows={expandedRows}
                                    onToggleRowExpansion={onToggleRowExpansion}
                                />
                            }
                            <PaginationBar
                                page={requestedPage}
                                pageSize={requestedPageSize}
                                totalPages={totalPages}
                                totalCount={totalCount}
                                onChangePage={onChangePage}
                                onChangePageSize={onChangePageSize}
                            />
                        </Card.Content>
                    </Card>
                }
            </Content>
        </ContentWrapper>
    )
};

export default ReservationDashboard;
