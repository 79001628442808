import React from 'react'
import {InputGroup} from "ui-kit";
import {TICKET_TYPE_LABEL, TICKET_TYPE_PROP_NAME, FIELD_MAX_WIDTH} from "./constants";
import PropTypes from "prop-types";

const DEFAULT_ATTENDEE_TYPE_OPTION = {
    value: null,
    label: 'Select ' + TICKET_TYPE_LABEL,
};


const TicketTypeInputGroup = (
    {
        attendeeType,
        options = [],
        selectedValue,
        errors = {},
        onChangeValue = () => {
        },
    }) => {
    options = options.filter(function (item) {
        return (item.attendeeTypes.filter(function(e) { return e.code === attendeeType; }).length > 0);
    }).map(({ id, name }) => ({ value: id, label: name }));
    return (
        <fieldset>
            <legend>Default {TICKET_TYPE_LABEL}</legend>
            <InputGroup
                messages={errors[TICKET_TYPE_PROP_NAME]}
                style={{maxWidth: `${FIELD_MAX_WIDTH}px`}}
            >
                <label className="required">{TICKET_TYPE_LABEL}</label>
                <select
                    value={selectedValue || ''}
                    onChange={(e) => onChangeValue(TICKET_TYPE_PROP_NAME, e.target.value)}
                >
                    {[DEFAULT_ATTENDEE_TYPE_OPTION, ...options].map(
                        ({value, label}) =>
                            <option key={value} value={value}>{label}</option>
                    )}
                </select>
            </InputGroup>
        </fieldset>
    )
};

export default TicketTypeInputGroup;

TicketTypeInputGroup.propTypes = {
    options: PropTypes.array,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    attendeeType: PropTypes.number.isRequired
};
