import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'app/react/components/_index';

const UPDATE_TOOLTIP = 'Update item';
const DELETE_TOOLTIP = 'Delete item';

const ArtistsItemRow = ({
    item = {},
    currency,
    onUpdate = () => {},
    onDelete = () => {},
}) => (
    <tr className="item-row">
        <td colSpan={2} style={{ padding: "0 0 0 30px" }}>
            <table className="overflow no-margin actions-right">
                <tbody>
                    <tr className="row-with-dashed-border-bottom">
                        <td>{item.name}</td>
                        <td style={{ width: '30px' }}>
                            {item.description &&
                                <Tooltip content={item.description}>
                                    <i className="bs-icon-circle-info" />
                                </Tooltip>
                            }
                        </td>
                        <td style={{ width: "100px" }}>{item.price !== 0 && `${item.price} ${currency}`}</td>
                        <td style={{ width: "100px" }}>
                            <Tooltip content={UPDATE_TOOLTIP}>
                                <a className="action" onClick={() => onUpdate(item.id)}>
                                    <i className="bs-icon-pencil" />
                                </a>
                            </Tooltip>
                            <Tooltip content={DELETE_TOOLTIP}>
                                <a className="action" onClick={() => onDelete(item.id)}>
                                    <i className="bs-icon-cross" />
                                </a>
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
);

ArtistsItemRow.propTypes = {
    item: PropTypes.object,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default ArtistsItemRow;
