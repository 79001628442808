import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import * as Day from '../../Day';
import * as Zone from './Zone';

@Model({
    key: 'profile'
})
class Profile extends BaseModel {

    static Relations() {
        return {
            event: require('../../Event').Model, // Circular
            zones: Zone.Collection,
            days: Day.Collection
        }
    }

    get validation() {
        return {
            name: {
                required: true,
                msg: 'Enter a name.'
            },
            days: 'validateDays',
            zones: 'validateZones'
        }
    }

    get defaults() {
        return {
            is_deletable: false
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
    }

    urlRoot() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/profiles`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if(this.hasRelation('days')) json.days = this.days.pluck('id');
        if(this.hasRelation('zones')) json.zones = this.zones.pluck('id');
        return json;
    }

    hasDayOn(day) {
        return this.getDayOn(day) ? true : false
    }

    getDayOn(day) {
        return this.days.get(day.id);
    }

    isAuthorizedFor(zone) {
        return this.zones.get(zone.id) ? true : false
    }

    validateDays() {
        if (this.getRelation('days').isEmpty()) return 'Select at least one day.';
    }

    validateZones() {
        if (this.getRelation('zones').isEmpty()) return 'Select at least one zone.';
    }

}

@Collection({
    key: 'profiles',
    model: Profile
})
class ProfileCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('../../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/profiles`;
    }

    filterAvailable() {
        let filtered = this.filter(model => model.get('available') > 0);
        return new ProfileCollection(filtered);
    }

    someHasDayOn(day) {
        return this.some(profile => profile.hasDayOn(day));
    }

    getAllUniqueDays() {
        const days = new Day.Collection();
        this.forEach(profile => { days.add(profile.getRelation('days').models); });
        return days;
    }

    countAvailable() {
        return this.filterAvailable().pluck('available').reduce((a, b) => a + b, 0);
    }

    restFromCustomProfileCollection(customProfiles) {
        this.reset(customProfiles.map(custom => custom.profile.clone()));
    }
}

const PageableCollection = ProfileCollection.prototype.PageableCollection();

export {
    Profile as Model,
    ProfileCollection as Collection,
    PageableCollection
};

