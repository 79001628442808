import React from 'react'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import Header from 'app/react/components/layout/content/header/Header'
import PlaylistPreview from 'app/react/views/shared/marketing/playlist_generator/PlaylistPreview'
import CTAs from 'app/react/views/shared/marketing/CTAs'

const { Content } = ContentWrapper;
const { TitleMenu } = Header;
const { Title } = TitleMenu;

const TITLE = 'Playlist generator';
const INTERCOM_MESSAGE = 'I’m interested in the playlist generator. Can you tell me how can I get this product?';

const Overview = () => (
    <ContentWrapper>
        <Header>
            <TitleMenu>
                <Title>{TITLE}</Title>
            </TitleMenu>
        </Header>
        <Content>
            <PlaylistPreview />
            <div className="text-align-center margin-medium-top">
                <h5>An official and a unique, personalised playlist for every one of your website visitors!</h5>
                <p>Engage fans with your lineup in the most simple & useful way.</p>
                <CTAs intercomMessage={INTERCOM_MESSAGE} />
            </div>
        </Content>
    </ContentWrapper>
);

export default Overview;
