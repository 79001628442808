import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Footer from "../../footer/Footer";
import TicketsDialog from "../../atoms/dialogs/TicketsDialog";
import Button from "../../atoms/button/Button";
import Icon from "../../../utils/Icon";
import FeedbackMessage from "../../atoms/feedback/Feedback";

export const TicketForm = ({
  setActiveStep,
  activeSubStep,
  eventState,
  createTicketTypeForEvent,
  updateTicketTypeForEvent,
  ticketTypes,
  error,
  setError,
}) => {
  const [ticketToEdit, setTicketToEdit] = useState(undefined);

  useEffect(() => {
    setTickets(ticketTypes);
  }, [ticketTypes]);

  const [tickets, setTickets] = useState(undefined);

  const goToNextStep = (step) => {
    setActiveStep(step, { tickets });
  };

  const addTicket = (ticket) => {
    const formattedTicket = {
      name: ticket.name,
      notes: ticket.notes,
      price: ticket.price,
      attendeeTypes: ticket.attendeeTypes
        .filter((type) => type.available)
        .map((type) => type.value),
      currency: eventState.event.currency,
    };
    if (!formattedTicket.notes) {
      delete formattedTicket.notes;
    }
    if (!formattedTicket.description) {
      delete formattedTicket.description;
    }
    //todo fix issue with real tickets
    if (ticket.id) {
      updateTicketTypeForEvent(ticket.id, formattedTicket).then((res) => {
        if (res && res.data) {
          const newTicket = res.data.updateTicketType.ticketType;
          setTickets((prev) => [
            ...prev.map((oldTicket) =>
              oldTicket.id !== newTicket.id ? oldTicket : newTicket
            ),
          ]);
        }
      });
    } else {
      createTicketTypeForEvent(eventState.event.id, formattedTicket).then(
        (res) => {
          if (res && res.data) {
            setTickets((prev) => [
              ...prev,
              res.data.createTicketType.ticketType,
            ]);
          }
        }
      );
    }
    setTicketToEdit(undefined);
  };

  const removeTicket = (ticket) => {
    setTickets((prev) =>
      prev.filter((existingTicket) => existingTicket.id !== ticket.id)
    );
  };

  return (
    <div className="form_content">
    {error && (
      <FeedbackMessage
        text={error}
        variant="error"
        closeFeedback={() => setError("")}
      />
    )}
      <h2 className="form-title">Tickets</h2>
      <h3 className="form-sub-title">
        Create tickets and access for your contacts
      </h3>
      <span>
        Add your ticket types below and choose which kind of attendees should be
        allowed to request this specific ticket type.
      </span>
      {tickets && tickets.length > 0 && (
        <table className="form__table">
          <thead>
            <tr className="form__table-header">
              <th>NAME</th>
              <th>COST</th>
              <th>ATTTENDEE TYPE(S)</th>
              <th></th>
            </tr>
          </thead>
          <tbody id="ticket-table-body">
            {tickets.map((ticket) => {
              return (
                <tr
                  className="form__table-row"
                  key={ticket.id}
                  id={`row-${ticket.id}`}
                >
                  <td>{ticket.name}</td>
                  <td>{ticket.price.amoutn}</td>
                  <td>{ticket.attendeeTypes.join(", ")}</td>
                  <td className="form__table__last-column">
                    <button
                      className="form__table__button"
                      onClick={() => setTicketToEdit(ticket)}
                    >
                      <Icon
                        size={26}
                        name="edit"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                    <button
                      className="form__table__button"
                      onClick={() => removeTicket(ticket)}
                    >
                      <Icon
                        size={26}
                        name="delete"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <br />
      <Button
        label="Add ticket types"
        variant="secondary"
        onClick={() =>
          setTicketToEdit({
            name: "",
            price: 0,
            notes: "",
            attendeeTypes: [],
            description: "",
          })
        }
      />
      {ticketToEdit && (
        <TicketsDialog
          addTicket={addTicket}
          setCancelDialogOpen={() => setTicketToEdit(undefined)}
          ticket={ticketToEdit}
          currencyUnit={eventState.event.currency}
        />
      )}
      <Footer
        setActiveStep={(step) => goToNextStep(step)}
        activeSubStep={activeSubStep}
        eventState={eventState}
        isNextDisabled={false}
      />
    </div>
  );
};

TicketForm.propTypes = {
  setActiveStep: PropTypes.func,
  activeSubstep: PropTypes.number,
};

export default TicketForm;
