import React from 'react';
import PropTypes from 'prop-types';

const HEADER = {
    ARTIST: {
        TEXT: 'Artist',
        WIDTH: 'initial'
    },
    ARTIST_TYPE: {
        TEXT: 'Artist Type',
        WIDTH: '200px'
    },
    TYPE: {
        TEXT: 'Type',
        WIDTH: '170px'
    },
    STATUS: {
        TEXT: 'Status',
        WIDTH: '170px'
    },
    ACTIONS: {
        TEXT: 'Actions',
        WIDTH: '140px'
    }
};

const BookingHeaderRow = ({
    isArtistTypesEnabled = true
}) => (
    <tr>
        <th style={{ width: HEADER.ARTIST.WIDTH }}>
            {HEADER.ARTIST.TEXT}
        </th>
        {isArtistTypesEnabled &&
            <th style={{ width: HEADER.ARTIST_TYPE.WIDTH }}>
                {HEADER.ARTIST_TYPE.TEXT}
            </th>
        }
        <th style={{ width: isArtistTypesEnabled ? HEADER.TYPE.WIDTH : '180px' }}>
            {HEADER.TYPE.TEXT}
        </th>
        <th style={{ width: isArtistTypesEnabled ? HEADER.STATUS.WIDTH : '180px' }}>
            {HEADER.STATUS.TEXT}
        </th>
        <th style={{ width: HEADER.ACTIONS.WIDTH }}>
            {HEADER.ACTIONS.TEXT}
        </th>
    </tr>
);

BookingHeaderRow.propTypes = {
    isArtistTypesEnabled: PropTypes.bool
};

export default BookingHeaderRow;
