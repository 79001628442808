import React from 'react';
import PropTypes from 'prop-types';
import withState from './withState';
import { Modal } from './Modal';

const { Header, Content, Footer } = Modal;
const { Title } = Header;
const { Button } = Footer;

export const TITLE = 'Confirm';
export const TEXT = 'Are you sure?';
export const BUTTON_TEXT = 'Yes';

const ConfirmModal = ({
    isOpened = false,
    isSyncing = false,
    title = TITLE,
    text = TEXT,
    children,
    buttonClassName = 'info',
    buttonText = BUTTON_TEXT,
    onConfirmed = () => {},
    onClosed = () => {},
}) => (
    <Modal
        className="confirmModal"
        width="35rem"
        opened={isOpened}
        syncing={isSyncing}
        onClosed={onClosed}
    >
        <Header onClosed={onClosed}>
            <Title>{title}</Title>
        </Header>
        <Content>
            {children
                ? children
                : <p dangerouslySetInnerHTML={{ __html: text }} />
            }
        </Content>
        <Footer>
            <Button
                className={buttonClassName}
                onClick={onConfirmed}
            >
                <span dangerouslySetInnerHTML={{ __html: buttonText }} />
            </Button>
        </Footer>
    </Modal>
);

ConfirmModal.propTypes = {
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    title: PropTypes.string,
    width: PropTypes.number,
    buttonClassName: PropTypes.string,
    buttonText: PropTypes.string,
    onConfirmed: PropTypes.func,
    onClosed: PropTypes.func
};

export default withState(ConfirmModal);
