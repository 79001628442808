import _ from 'underscore';
import Moment from 'moment';
import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';
import BaseCollection from 'app/backbone/models/BaseCollection';
import DateTime from 'app/backbone/helpers/DateTime';
import * as Artist from 'app/backbone/models/account/artists/Artist';
import * as Offer from './Offer';
import * as ArtistType from './ArtistType';
import * as Performance from './Performance';

@Model({
    key: 'booking'
})
class Booking extends BaseModel {

    static Relations() {
        return {
            event: require('../Event').Model, // circular
            performance: Performance.Model,
            artist: Artist.Model,
            artistType: ArtistType.Model,
            offers: Offer.Collection
        }
    }

    get validation() {
        return {
            day: {
                required: true,
                msg: 'Select a day.'
            },
            stage: {
                required: true,
                msg: 'Select a stage.'
            },
            artist: {
                required: true,
                msg: 'Select an artist.'
            },
            artist_type: 'validateArtistType',
            performance_type: {
                required: true,
                msg: 'Select a performance type.'
            },
            status: {
                required: true,
                msg: 'Select a booking status.'
            },
            start_date: [
                {
                    required: true,
                    msg: 'Enter a start date.'
                },
                { fn: 'validateIfStartDateIsBetweenDay' }
            ],
            start_time: [
                {
                    required: true,
                    msg: 'Enter a start time.'
                },
                { fn: 'validateIfStartTimeIsBetweenDay' }
            ],
            end_date: [
                {
                    required: true,
                    msg: 'Enter an end date.'
                },
                { fn: 'validateIfEndDateIsNotBeforeStartDate' },
                { fn: 'validateIfEndDateIsNotAfterNextDay' },
                { fn: 'validateIfEndDateIsBetweenDay' }
            ],
            end_time: [
                {
                    required: true,
                    msg: 'Enter an end time.'
                },
                { fn: 'validateIfEndTimeIsNotBeforeStartTime' },
                { fn: 'validateIfEndTimeIsBetweenDay' }
            ]
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options);
        this.on('change:start_date change:start_time', this.onChangeStartDate);
        this.on('change:end_date change:end_time', this.onChangeEndDate());
    }

    urlRoot() {
        this.validateRelations('event');
        const eventId = this.getRelation('event').get('id');
        return `/api/events/${eventId}/bookings`;
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);

        response.start_date = DateTime.account.formatDateToDateString(response.start);
        response.start_date_short = DateTime.account.formatDateToDateString(response.start, true);
        response.start_time = DateTime.account.formatDateToTimeString(response.start);
        response.end_date = DateTime.account.formatDateToDateString(response.end);
        response.end_date_sort = DateTime.account.formatDateToDateString(response.end, true);
        response.end_time = DateTime.account.formatDateToTimeString(response.end);

        const { artist } = this.relations;
        response.name = artist.get('name');

        return response;
    }

    hasOffer() {
        return !! this.getCurrentOffer();
    }

    getCurrentOffer() {
        const offers = this.getRelation('offers');
        if (!offers.isEmpty()) return offers.max(offer => offer.get('id'));
    }

    onChangeStartDate() {
        const startDate = this.get('start_date');
        const startTime = this.get('start_time');
        const start = DateTime.account.parseDateTimeStringToISOString(`${startDate} ${startTime}`);
        this.set('start', start);
    }

    onChangeEndDate() {
        const endDate = this.get('end_date');
        const endTime = this.get('end_time');
        const end = DateTime.account.parseDateTimeStringToISOString(`${endDate} ${endTime}`);
        this.set('end', end);
    }

    validateArtistType(artistType, attr, computedState) {
        if (this.getRelation('event').get('artist_types_enabled') && (_.isUndefined(artistType) || artistType == '')) {
            return 'Select an artist type.';
        }
    }

    validateIfStartDateIsBetweenDay(startDate, attr, computedState) {
        const day = this.getRelation('day');
        const dayStartDate = day.get('start_date');
        const dayEndDate = day.get('end_date');
        const mStart = DateTime.account.parseDateStringToMoment(dayEndDate);
        const mDayStart = DateTime.account.parseDateStringToMoment(dayStartDate);
        const mDayEnd = DateTime.account.parseDateStringToMoment(dayEndDate);

        if (this.isNotBetween(mStart, mDayStart, mDayEnd)) {
            return 'The start date must fall between the start and end date of the day';
        }
    }

    validateIfStartTimeIsBetweenDay(startTime, attr, computedState) {
        const day = this.getRelation('day');
        const dayStartDate = day.get('start_date');
        const dayStartTime = day.get('start_time');
        const dayEndDate = day.get('end_date');
        const dayEndTime = day.get('end_time');
        const mStart = DateTime.account.parseDateTimeStringToMoment(`${computedState.start_date} ${startTime}`);
        const mDayStart = DateTime.account.parseDateTimeStringToMoment(`${dayStartDate} ${dayStartTime}`);
        const mDayEnd = DateTime.account.parseDateTimeStringToMoment(`${dayEndDate} ${dayEndTime}`);

        if (this.isNotBetween(mStart, mDayStart, mDayEnd)) {
            return 'The start time must fall between the start and end time of the day';
        }
    }

    validateIfEndDateIsBetweenDay(endDate, attr, computedState) {
        const day = this.getRelation('day');
        const dayStartDate = day.get('start_date');
        const dayEndDate = day.get('end_date');
        const mEnd = DateTime.account.parseDateStringToMoment(endDate);
        const mDayStart = DateTime.account.parseDateStringToMoment(dayStartDate);
        const mDayEnd = DateTime.account.parseDateStringToMoment(dayEndDate);

        if (this.isNotBetween(mEnd, mDayStart, mDayEnd)) {
            return 'The end date must fall between the start and end date of the day';
        }
    }

    validateIfEndTimeIsBetweenDay(endTime, attr, computedState) {
        const day = this.getRelation('day');
        const dayStartDate = day.get('start_date');
        const dayStartTime = day.get('start_time');
        const dayEndDate = day.get('end_date');
        const dayEndTime = day.get('end_time');
        const mEnd = DateTime.account.parseDateTimeStringToMoment(`${computedState.end_date} ${endTime}`);
        const mDayStart = DateTime.account.parseDateTimeStringToMoment(`${dayStartDate} ${dayStartTime}`);
        const mDayEnd = DateTime.account.parseDateTimeStringToMoment(`${dayEndDate} ${dayEndTime}`);

        if (this.isNotBetween(mEnd, mDayStart, mDayEnd)) {
            return 'The end time must fall between the start and end time of the day';
        }
    }

    isNotBetween(mTarget, mStart, mEnd) {
        return !(mTarget.isBetween(mStart, mEnd) || mTarget.isSame(mStart) || mTarget.isSame(mEnd))
    }

    validateIfEndDateIsNotBeforeStartDate(endDate, attr, computedState) {
        const mStart = DateTime.account.parseDateStringToMoment(computedState.start_date);
        const mEnd = DateTime.account.parseDateStringToMoment(endDate);

        if (mEnd.isBefore(mStart)) {
            return 'The end date must be equal or later than the start date.';
        }
    }

    validateIfEndTimeIsNotBeforeStartTime(endTime, attr, computedState) {
        const mStart = DateTime.account.parseDateTimeStringToMoment(`${computedState.start_date} ${ computedState.start_time}`);
        const mEnd = DateTime.account.parseDateTimeStringToMoment(`${computedState.end_date} ${ endTime}`);

        if (mEnd.isBefore(mStart) || mEnd.isSame(mStart)) {
            return 'The end time must be later than the start time.';
        }
    }

    validateIfEndDateIsNotAfterNextDay(endDate, attr, computedState) {
        const mEnd =  DateTime.account.parseDateStringToMoment(endDate);
        const mNextDay = DateTime.account.parseDateStringToMoment(computedState.start_date).add(1, 'days');

        if (mEnd.isAfter(mNextDay)) {
            return 'A day\'s duration cannot span more than two days.';
        }
    }

    isValidSchedulerBooking() {
        const stage = this.get('stage');
        const artist = this.get('artist');
        const start = this.get('start');
        const end = this.get('end');
        const mStart = Moment(start);
        const mEnd = Moment(end);
        return stage && artist && start && end && mEnd.isAfter(mStart)
    }

    isMC() {
        const { performance_type } = this.attributes;
        return performance_type === 3;
    }

    hasReachedLimit(used, limit) {
        if (_.isNull(limit)) return false;
        return used >= limit
    }

    hasReachedCrewLimit() {
        const { crew_used, crew_limit } = this.attributes;
        return this.hasReachedLimit(crew_used, crew_limit)
    }

    hasOneMoreBeforeReachingCrewLimit() {
        const { crew_used, crew_limit } = this.attributes;
        const limit = crew_limit === null ? crew_limit : crew_limit - 1;
        return this.hasReachedLimit(crew_used, limit)
    }

    hasReachedPerformerLimit() {
        const { performers_used, performers_limit } = this.attributes;
        return this.hasReachedLimit(performers_used, performers_limit)
    }

    hasOneMoreBeforeReachingPerformerLimit() {
        const { performers_used, performers_limit } = this.attributes;
        const limit = performers_limit === null ? performers_limit : performers_limit - 1;
        return this.hasReachedLimit(performers_used, limit)
    }

}

@Collection({
    key: 'bookings',
    model: Booking
})
class BookingCollection extends BaseCollection {

    static Relations() {
        return {
            event: require('../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        const eventId = this.getRelation('event').get('id');
        return `/api/events/${eventId}/bookings`;
    }

    filterBookingsByDay(day) {
        return new Bookings(this.filter((booking) => {
            return booking.get('day') && (booking.get('day') == day.get('id'));
        }));
    }

}

const PageableCollection = BookingCollection.prototype.PageableCollection();

export {
    Booking as Model,
    BookingCollection as Collection,
    PageableCollection
};
