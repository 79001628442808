import React from 'react';
import PropTypes from 'prop-types';
import { StyledSelect } from './Filter';
import { reformatISOString } from 'app/react/helpers/_index';
import moment from 'moment';
import by from 'thenby';

const ALL_DAYS_OPTION = { value: 0, label: 'All days' };
const DAYS_MARGIN = 4;

const whereISOStringIsShowDay = (date) => (day) => {
    const mDate = moment.utc(date, moment.ISO_8601);
    const mDayStartDate = moment.utc(day.start, moment.ISO_8601);
    mDayStartDate.startOf('day');
    mDate.startOf('day');
    return mDayStartDate.isSame(mDate)
};

const EventDaysFilter = ({
    days = [],
    dateFormat,
    width,
    label,
    value,
    endOfDay = false,
    allOption = ALL_DAYS_OPTION,
    onChange = () => {},
}) => {
    const daysSorted = days.sort(by('start'));
    const firstDay = daysSorted[0];
    const lastDay = daysSorted[daysSorted.length-1];
    const generatedDays = [allOption];
    if (typeof firstDay !== 'undefined' && typeof lastDay !== 'undefined') {
        const mStartDate = moment.utc(firstDay.start, moment.ISO_8601);
        const mEndDate = moment.utc(lastDay.end, moment.ISO_8601);
        mStartDate.subtract(DAYS_MARGIN, 'days');
        mEndDate.add(DAYS_MARGIN + 1, 'days');
        const amountOfDaysBetween = mEndDate.diff(mStartDate, 'days');
        const mIteratingStartDate = mStartDate.clone();
        if (endOfDay) {
            mIteratingStartDate.endOf('day');
        } else {
            mIteratingStartDate.startOf('day');
        }
        for (let i = 1; i < amountOfDaysBetween + 1; i++) {
            let label = mIteratingStartDate.format(`dddd ${dateFormat}`);
            if (days.find(whereISOStringIsShowDay(mIteratingStartDate.toISOString()))) {
                label = `${label} (Show Day)`
            }
            generatedDays.push({
                value: reformatISOString(mIteratingStartDate.toISOString()),
                label
            });
            mIteratingStartDate.add(1, 'days');
        }
    }
    return (
        <label>
            {label}
            <StyledSelect
                hasLabel={typeof label !== 'undefined'}
                value={value}
                onChange={e => onChange(e.target.value)}
                style={{ width }}
            >
                {generatedDays.map(({ value, label }) => (
                    <option
                        key={value}
                        value={value}
                    >
                        {label}
                    </option>
                ))}
            </StyledSelect>
        </label>
    )
};

EventDaysFilter.propTypes = {
    days: PropTypes.array,
    dateFormat: PropTypes.string,
    width: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func
};

export default EventDaysFilter;
