import { connect } from 'react-redux';
import { ConfirmModal } from 'app/react/components/_index';
import * as State from './DeleteModalState';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Artist from 'app/react/entities/accounts/artists/index';

const TITLE = 'Delete pickup';
const TEXT = (name) => `Are you sure you want to delete pickup <strong>${name}?</strong>`;
const BUTTON_TEXT = 'Yes, delete pickup';

const mapStateToProps = (state) => {
    const {
        isOpened,
        isSyncing,
        pickup = { booking: { artist: {} } },
    } = State.stateHelpers.getState(state);
    const booking = Booking.stateHelpers.getOne(state, pickup.booking) || {};
    const artist = Artist.stateHelpers.getOne(state, booking.artist) || {};
    return {
        title: TITLE,
        text: TEXT(artist.name),
        buttonText: BUTTON_TEXT,
        buttonClassName: 'alert',
        isOpened,
        isSyncing,
        pickupId: pickup.id,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onConfirmed: () => {
        const { pickupId } = stateProps;
        dispatchProps.onConfirmed(pickupId);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch(State.actions.close());
    },
    onConfirmed: (pickupId) => {
        dispatch(State.thunks.deletePickup(pickupId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ConfirmModal);
