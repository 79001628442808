import CheckCell from 'app/backbone/components/tables/cells/CheckCell.view';

export default class ProfileZoneCell extends CheckCell {

    className() {
        return `${super.className()} center`;
    }

    isVisible() {
        return this.model.isAuthorizedFor(this.zone);
    }

}
