import _ from 'underscore';
import Backgrid from 'backgrid';
import SubModelCell from 'app/backbone/components/tables/cells/SubModelCell.view';
import SubCollectionCell from 'app/backbone/components/tables/cells/SubCollectionCell.view';
import SubCollectionFirstCell from 'app/backbone/components/tables/cells/SubCollectionFirstCell.view';
import LabelCell from 'app/backbone/components/tables/cells/LabelCell.view';
import CheckCell from 'app/backbone/components/tables/cells/CheckCell.view';
import ActionsCell from 'app/backbone/components/tables/cells/actions/ActionsCell.view';

export function createHeaderCell(width, className) {
    return Backgrid.HeaderCell.extend(generateDefaultCellProperties(Backgrid.HeaderCell, width, className))
}

export function createCell(width, className) {
    return Backgrid.Cell.extend(generateDefaultCellProperties(Backgrid.Cell, width, className))
}

export function createSelectAllHeaderCell(width = '50px', className = null) {
    return Backgrid.Extension.SelectAllHeaderCell.extend(generateDefaultCellProperties(Backgrid.Extension.SelectAllHeaderCell, width, className))
}

export function createActionCell(actions, width, className) {
    return ActionsCell.extend(_.extend(
        { actions },
        generateDefaultCellProperties(ActionsCell, width, className)));
}

export function createSubModelCell(width, className, path) {
    return SubModelCell.extend(_.extend(
        { path },
        generateDefaultCellProperties(SubModelCell, width, className)));
}

export function createSubModelLabelCell(width, className, path, labelClassName) {
    let hasLabel = true;
    className = appendClassName(className, 'input');
    return SubModelCell.extend(_.extend(
        { path, hasLabel, labelClassName },
        generateDefaultCellProperties(SubModelCell, width, className)));
}

export function createSubCollectionCell(width, className, path) {
    return SubCollectionCell.extend(
        { path },
        generateDefaultCellProperties(SubCollectionCell, width, className));
}

export function createSubCollectionLabelCell(width, className, path, labelClassName) {
    let hasLabel = true;
    className = appendClassName(className, 'input');
    return SubCollectionCell.extend(_.extend(
        { path, hasLabel, labelClassName },
        generateDefaultCellProperties(SubCollectionCell, width, className)));
}

export function createSubCollectionFirstCell(width, className, path) {
    return SubCollectionFirstCell.extend(
        { path },
        generateDefaultCellProperties(SubCollectionCell, width, className));
}

export function createSubCollectionFirstLabelCell(width, className, path, labelClassName) {
    let hasLabel = true;
    className = appendClassName(className, 'input');
    return SubCollectionFirstCell.extend(_.extend(
        { path, hasLabel, labelClassName },
        generateDefaultCellProperties(SubCollectionCell, width, className)));
}

export function createLabelCell(width, className, labelClassName) {
    return LabelCell.extend(_.extend(
        { labelClassName },
        generateDefaultCellProperties(LabelCell, width, className)));
}

export function createCheckCell(width, className) {
    return CheckCell.extend(generateDefaultCellProperties(CheckCell, width, className));
}

function generateDefaultCellProperties(Cell, width, className) {
    var properties = {};
    if(width) {
        if(_.isString(width)) {
            if(width.match('px$') || width.match('%$')) properties.attributes = { 'style': `width:${width}` };
            else throw new Error(`The width string must end with 'px' or '%'`)
        } else throw new Error('The width must be a string.')
    }
    if(className) {
        if(Cell && Cell.prototype.className) className = `${Cell.prototype.className} ${className}`;
        if(_.isString(className)) properties.className = className;
        else throw new Error('The className must be a string.')
    }
    return properties;
}

function appendClassName(className, appendClassName) {
    if(className) return `${className} ${appendClassName}`;
    else return appendClassName
}
