import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BackboneView } from 'app/react/components/_index';
import * as stateHelpers from 'app/react/state/helpers';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import ContentHeader, { TABS } from './ContentHeader';
import * as Item from 'app/backbone/models/account/accreditation/Item';
import * as ItemGroup from 'app/backbone/models/account/accreditation/ItemGroup';
import ItemsContentView from 'app/backbone/views/account/settings/accreditation/views/ItemsContent.view';

const { Content } = ContentWrapper;

const ItemsView = ({
    store,
    items,
    itemGroups,
    // State
    isLoading
}) => (
    <ContentWrapper store={store}>
        <ContentHeader activeTab={TABS.ITEMS.ID} />
        <Content isLoading={isLoading} >
            <BackboneView viewClass={ItemsContentView} items={items} itemGroups={itemGroups} />
        </Content>
    </ContentWrapper>
);

ItemsView.propTypes = {
    store: PropTypes.object.isRequired,
    items: PropTypes.instanceOf(Item.PageableCollection).isRequired,
    itemGroups: PropTypes.instanceOf(ItemGroup.PageableCollection).isRequired,
    // State
    isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: stateHelpers.isLoading(state)
});

export default connect(mapStateToProps)(ItemsView);
