import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyItem from 'app/backbone/models/event/accreditation/companies/CompanyItem';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import CompanyItemGroupCardContent from './CompanyItemGroupCardContent.view';

export default class CompanyItemGroupCard extends Card {

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            itemGroup: OptionTypes.instanceOf(EventItemGroup.Model).isRequired,
            items: OptionTypes.instanceOf(CompanyItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            editing: OptionTypes.bool
        };
    }

    optionDefaults() {
        return {
            editing: false
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // Check company status and hide header actions
        if (!this.company.canRequestApproval()) {
            this.header.hideAllActions();
        }
    }

    initHeader() {
        this.title = this.itemGroup.get('name');
        return super.initHeader();
    }

    initContent() {
        const { itemGroup, items, periods, editing } = this;
        return new CompanyItemGroupCardContent({ items, itemGroup, periods, editing });
    }

    initHeaderActions() {
        const actions = [];
        if (!this.editing) {
            actions.push({
                name: 'update',
                tooltip: 'Update items',
                icon: 'bs-icon-pencil',
                action: this.updateItems.bind(this)
            });
        }
        return actions;
    }

    updateItems() {
        const { itemGroup } = this;
        const productionWebsiteUrl = window.beatswitch.production_website.slug;
        navigate(`/production-website-accreditation/${productionWebsiteUrl}/company-form/item-group/${itemGroup.id}/edit`);
    }

    getUniqueName() {
        const { company, itemGroup } = this;
        const event = company.getRelation('event');
        return `companyItemGroupCard/event${event.id}company${company.id}itemGroup${itemGroup.id}`;
    }
}
