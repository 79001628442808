import { Model, Collection }  from 'app/backbone/models/decorators';
import BaseModel from 'app/backbone/models/BaseModel';

@Model({
    key: 'performance'
})
class Performance extends BaseModel {

    static Relations() {
        return {
            day: require('../Day').Model, // circular
            stage: require('../Stage').Model, // circular
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.initializeRelations(options)
    }

    parse(response, xhr) {
        response = super.parse(response, xhr);
        this.parseRelations(response);
        return response;
    }

}


export {
    Performance as Model,
};
