import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as ArtistType from 'app/react/entities/events/artist_types/helpers';
import * as Section from '../helpers';
import { updateAll } from './actions';

export const updateCurrent = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const artistTypeId = ArtistType.getCurrentId(state);
    const sectionId = Section.getCurrentId(state);
    return dispatch(updateAll(accountId, eventId, artistTypeId, sectionId, values));
};
