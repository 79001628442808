import { omit, pick, debounce } from 'underscore';
import { schema } from 'normalizr';
import dotProp from 'dot-prop-immutable';
import { createReducer } from 'redux-create-reducer';
import by from 'thenby';
import { CALL_API, METHODS, limitParam } from 'app/react/state/middleware/api';
import { createPaginationReducer } from 'app/react/state/reducers/higher_order/_index';
import {
    getOneEntity,
    getAllEntities,
    getSomeEntities,
    getEntitiesPagination,
    isFetchingEntities,
    getCurrentEventId,
    removeOneEntity,
    removeOneEntityFromPagination
} from 'app/react/state/helpers';

export const UPDATE_PAGE_SEQUENCE = 'UPDATE_PAGE_SEQUENCE';
export const DROP_OUTSIDE_LIST = 'DROP_OUTSIDE_LIST';
export const UPDATE_HOVER_STATE = 'UPDATE_HOVER_STATE';

export const ALL_PAGES_REQUEST = 'ALL_PAGES_REQUEST';
export const ALL_PAGES_SUCCESS = 'ALL_PAGES_SUCCESS';
export const ALL_PAGES_FAILURE = 'ALL_PAGES_FAILURE';

export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const PAGE_FAILURE = 'PAGE_FAILURE';

export const CHILD_PAGE_REDUCER_KEY = 'pwChildPages';
const childProcessStrategy = values => ({
    isOverTopArea: false,
    isOverBottomArea: false,
    children: [],
    ...omit(values, ['homepage', 'formPage']),
    isHomePage: values.homepage || false,
    isFormPage: values.formPage || false
});
const childEntityOptions = { processStrategy: childProcessStrategy };
const childEntityDefinition = {};
const childEntity = new schema.Entity(CHILD_PAGE_REDUCER_KEY, childEntityDefinition, childEntityOptions);
const childArray = new schema.Array(childEntity);

export const ENTITY_REDUCER_KEY = 'pwPages';
export const PAGINATION_REDUCER_KEY = ENTITY_REDUCER_KEY;
export const CHILD_PAGE_KEY = 'children';
const processStrategy = values => ({
    ...childProcessStrategy(values),
    isOverMiddleArea: false
});
const entityOptions = { processStrategy };
const entityDefinition = { [CHILD_PAGE_KEY]: childArray };
const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
const array = new schema.Array(entity);

export const schemas = {
    entity,
    array,
    childEntity,
    childArray
};

const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);

const getOneChild = (state, id) =>
    getOneEntity(state, CHILD_PAGE_REDUCER_KEY, id);

const getAll = (state) =>
    getAllEntities(state, ENTITY_REDUCER_KEY);

const getAllChildPages = (state) =>
    getAllEntities(state, CHILD_PAGE_REDUCER_KEY);

const getAllChildrenByParentId = (state, parentId) => {
    const parent = getOne(state, parentId);
    return getSomeEntities(state, CHILD_PAGE_REDUCER_KEY, parent[CHILD_PAGE_KEY])
        .map(page => ({ ...page, parentId }));
};

const getAllWithChildren = (state) =>
    getAll(state).map(page => ({
        ...page,
        children: getAllChildrenByParentId(state, page.id)
    }));

const getAllSortedWithSortedChildren = (state) =>
    getAllWithChildren(state)
        .map(page => ({
            ...page,
            children: getAllChildrenByParentId(state, page.id).sort(by('sequence'))
        }))
        .sort(by('sequence'));

const isFetching = (state) =>
    isFetchingEntities(state, PAGINATION_REDUCER_KEY);

const getPagination = (state) =>
    getEntitiesPagination(state, ENTITY_REDUCER_KEY, PAGINATION_REDUCER_KEY);

export const stateHelpers = {
    getAll,
    getAllSortedWithSortedChildren,
    isFetching
};

const paginationReducer = createPaginationReducer({
    types: [
        ALL_PAGES_REQUEST,
        ALL_PAGES_SUCCESS,
        ALL_PAGES_FAILURE
    ]
});

const reorderChildPages = (state, dragId, hoverId, hoverParentId, isOverTopArea = true) => {
    let newState = state;
    const allChildPages = getAllChildrenByParentId(state, hoverParentId)
    const hoverPage = allChildPages.find(page => page.id === hoverId);
    const dragPage = allChildPages.find(page => page.id === dragId);
    dragPage.sequence = hoverPage.sequence + (isOverTopArea ? -0.5 : 0.5);
    const sortedPages = allChildPages.sort(by('sequence'));
    sortedPages.forEach((page, index) => {
        newState = dotProp.set(
            newState,
            `entities.${CHILD_PAGE_REDUCER_KEY}.${page.id}.sequence`,
            index
        );
    });
    return newState;
};

const reorderPages = (state, dragId, hoverId, isOverTopArea = true) => {
    let newState = state;
    const allPages = getAll(newState);
    const hoverPage = allPages.find(page => page.id === hoverId);
    const dragPage = allPages.find(page => page.id === dragId);
    if (dragPage) {
        dragPage.sequence = hoverPage.sequence + (isOverTopArea ? -0.5 : 0.5);
    }
    const sortedPages = allPages.sort(by('sequence'));
    sortedPages.forEach((page, index) => {
        newState = dotProp.set(
            newState,
            `entities.${ENTITY_REDUCER_KEY}.${page.id}.sequence`,
            index
        );
    });
    return newState;
};

const clearAllHoverStates = (state, clearChildren = true) => {
    let newState = state;
    const allPages = getAll(newState);
    allPages.forEach(({ id, children = [] }) => {
        newState = dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${id}.isOverTopArea`, false);
        newState = dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${id}.isOverMiddleArea`, false);
        newState = dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${id}.isOverBottomArea`, false);
        if (clearChildren) {
            children.forEach(childId => {
                newState = dotProp.set(newState, `entities.${CHILD_PAGE_REDUCER_KEY}.${childId}.isOverTopArea`, false);
                newState = dotProp.set(newState, `entities.${CHILD_PAGE_REDUCER_KEY}.${childId}.isOverBottomArea`, false);
            });
        }
    });
    return newState;
};

const addParentPageAsChild = (state, dragId, newParentId) => {
    let newState = state;
    // Add to all child pages
    newState = dotProp.set(
        newState,
        `entities.${CHILD_PAGE_REDUCER_KEY}.${dragId}`,
        getOne(state, dragId)
    );
    // add to parent of hover parent page
    newState = dotProp.set(
        newState,
        `entities.${ENTITY_REDUCER_KEY}.${newParentId}.children`,
        children => [...children, dragId]
    );
    // remove from all parent pages
    newState = dotProp.delete(
        newState,
        `entities.${ENTITY_REDUCER_KEY}.${dragId}`
    );
    return newState;
};

const removeChildFromParentAndAddAsParentPage = (state, dragId, dragParentId) => {
    let newState = state;
    const dragParentPage = getOne(state, dragParentId);
    const indexInParent = dragParentPage.children.indexOf(dragId);
    // Remove from parent page and from all child pages
    newState = dotProp.delete(
        newState,
        `entities.${ENTITY_REDUCER_KEY}.${dragParentId}.children.${indexInParent}`
    );
    newState = dotProp.delete(
        newState,
        `entities.${CHILD_PAGE_REDUCER_KEY}.${dragId}`
    );
    // Add to all parent Pages
    newState = dotProp.set(
        newState,
        `entities.${ENTITY_REDUCER_KEY}.${dragId}`,
        getOneChild(state, dragId)
    );
    return newState;
};

const removeChildFromParentAndAddToNewParent = (state, dragId, oldParentId, newParentId) => {
    let newState = state;
    const oldParentPage = getOne(state, oldParentId);
    const indexInParent = oldParentPage.children.indexOf(dragId);

    newState = dotProp.delete(
        newState,
        `entities.${ENTITY_REDUCER_KEY}.${oldParentId}.children.${indexInParent}`
    );
    newState = dotProp.set(
        newState,
        `entities.${ENTITY_REDUCER_KEY}.${newParentId}.children`,
        children => [...children, dragId]
    );
    return newState;
};

const removePage = (state, id, parentId) => {
    let newState = state;
    const isChildPage = typeof parentId !== 'undefined';
    const reducerKey = isChildPage ? CHILD_PAGE_REDUCER_KEY : ENTITY_REDUCER_KEY;
    newState = removeOneEntity(newState, reducerKey, id);
    if (isChildPage) {
        const parentPage = getOne(state, parentId);
        const indexInParent = parentPage.children.indexOf(id);
        newState = dotProp.delete(newState, `entities.${ENTITY_REDUCER_KEY}.${parentId}.children.${indexInParent}`);
    } else {
        newState = removeOneEntityFromPagination(newState, reducerKey, id);
    }
    return newState;
};

const updateHomePage = (state, updatePageId) => {
    let newState = state;
    const allPages = getAll(newState);
    const allChildPages = getAllChildPages(newState);
    const updatedPage = getOne(state, updatePageId) || getOneChild(state, updatePageId);
    if (updatedPage.isHomePage) {
        allPages.forEach(({ id, children = [] }) => {
            if (id !== updatePageId) {
                newState = dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${id}.isHomePage`, false);
            }
            children.forEach(childId => {
                if (childId !== updatePageId) {
                    newState = dotProp.set(newState, `entities.${CHILD_PAGE_REDUCER_KEY}.${childId}.isHomePage`, false);
                }
            });
        });
        return newState;
    }
    const homePageAsParent = allPages.find(page => page.isHomePage);
    const homePageAsChild = allChildPages.find(page => page.isHomePage);
    const hasHomePage = !! (homePageAsParent || homePageAsChild);
    if (!hasHomePage) {
        const formPageAsParent = allPages.find(page => page.isFormPage);
        const formPageAsChild = allChildPages.find(page => page.isFormPage);
        if (formPageAsParent) {
            newState = dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${formPageAsParent.id}.isHomePage`, true);
        } else if (formPageAsChild) {
            newState = dotProp.set(newState, `entities.${CHILD_PAGE_REDUCER_KEY}.${formPageAsChild.id}.isHomePage`, true);
        }
        return newState;
    }

    return newState;
};

const featureReducer = createReducer({}, {
    [UPDATE_PAGE_SEQUENCE]: (state, action) => {
        const { dragId, dropId, dragParentId, dropParentId, dropOnTopArea, dropOnMiddleArea, dropOnBottomArea } = action;

        const dragIsChildPage = typeof dragParentId !== 'undefined';
        const dropIsChildPage = typeof dropParentId !== 'undefined';
        const dragPage = dragIsChildPage ? getOneChild(state, dragId) : getOne(state, dragId);
        if (typeof dragPage === 'undefined') return state;
        const dragHasChildren = dragIsChildPage ? false : (!! dragPage.children.length);

        const dropPage = dropIsChildPage ? getOneChild(state, dropId) : getOne(state, dropId);
        if (typeof dragPage === 'undefined') return state;
        const dropHasChildren = dropIsChildPage ? false : (!! dropPage.children.length);
        const dropIsFormPage = dropPage.isFormPage;
        const dropIsHomePage = dropPage.isHomePage;

        let newState = state;
        const hasDroppedParentOnTopOfParent = !dragIsChildPage && !dropIsChildPage;
        const hasDroppedChildOnTopOfChild = dragIsChildPage && dropIsChildPage;
        const hasDroppedChildOnTopOfParent = dragIsChildPage && !dropIsChildPage;
        const hasDroppedParentOnTopOfChild = !dragIsChildPage && dropIsChildPage;

        if (hasDroppedParentOnTopOfParent) {
            if (dropOnTopArea || dropOnBottomArea) {
                newState = reorderPages(state, dragId, dropId, dropOnTopArea);
            } else if (!dragHasChildren && !dropIsFormPage && !dropIsHomePage) {
                newState = addParentPageAsChild(newState, dragId, dropId);
                if (dropHasChildren) {
                    newState = reorderChildPages(newState, dragId, dropPage.children[0], dropId);
                }
            }
        } else if (hasDroppedChildOnTopOfChild) {
            const dropParentPage = getOne(state, dropParentId);
            const isDraggedInSameParent = dropParentPage.children.indexOf(dragId) !== -1;
            if (!isDraggedInSameParent) {
                newState = removeChildFromParentAndAddToNewParent(newState, dragId, dragParentId, dropParentId);
            }
            newState = reorderChildPages(newState, dragId, dropId, dropParentId, dropOnTopArea);
        } else if (hasDroppedChildOnTopOfParent) {
            if (dropOnBottomArea || dropOnTopArea) {
                newState = removeChildFromParentAndAddAsParentPage(newState, dragId, dragParentId);
                newState = reorderPages(newState, dragId, dropId, dropOnTopArea);
            } else if (dropOnMiddleArea && !dropHasChildren && !dropIsFormPage && !dropIsHomePage) {
                newState = removeChildFromParentAndAddToNewParent(newState, dragId, dragParentId, dropId);
            }
            newState = reorderPages(newState, dragId, dropId);
        } else if (hasDroppedParentOnTopOfChild) {
            if (dragHasChildren) {
                const dropParentPage = getOne(state, dropParentId);
                if (dropParentPage.dropOnBottomArea || dropParentPage.isOverTopArea) {
                    newState = reorderPages(state, dragId, dropParentId, dropParentPage.isOverTopArea);
                }
            } else {
                newState = addParentPageAsChild(newState, dragId, dropParentId);
                newState = reorderChildPages(newState, dragId, dropId, dropParentId, dropOnTopArea);
                newState = reorderPages(newState, dragId, dropId, dropParentId);
            }
        }

        newState = clearAllHoverStates(newState);

        return newState;
    },
    [DROP_OUTSIDE_LIST]: (state, action) => {
        const { dragId, parentId } = action;
        const isChildPage = typeof parentId !== 'undefined';
        const allPages = getAll(state);
        const lastHoveredPage =
            allPages.find(({ isOverTopArea, isOverBottomArea }) => (isOverTopArea || isOverBottomArea));

        let newState = state;
        if (lastHoveredPage) {
            if (isChildPage) {
                newState = removeChildFromParentAndAddAsParentPage(newState, dragId, parentId);
            }
            newState = reorderPages(newState, dragId, lastHoveredPage.id, lastHoveredPage.isOverTopArea);
        }
        return clearAllHoverStates(newState);
    },
    [UPDATE_HOVER_STATE]: (state, action) => {
        const { dragId, hoverId, dragParentId, hoverParentId, isOverTopArea, isOverMiddleArea, isOverBottomArea } = action;
        const dragIsChildPage = typeof dragParentId !== 'undefined';
        const hoverIsChildPage = typeof hoverParentId !== 'undefined';
        let newState = clearAllHoverStates(state);

        if (dragId !== hoverId) {
            if (hoverIsChildPage) {
                if (isOverTopArea) {
                    newState =
                        dotProp.set(newState, `entities.${CHILD_PAGE_REDUCER_KEY}.${hoverId}.isOverTopArea`, true);
                } else if (isOverBottomArea) {
                    newState =
                        dotProp.set(newState, `entities.${CHILD_PAGE_REDUCER_KEY}.${hoverId}.isOverBottomArea`, true);
                }
            } else {
                if (isOverTopArea) {
                    newState =
                        dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${hoverId}.isOverTopArea`, true);
                } else if (isOverMiddleArea) {
                    newState =
                        dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${hoverId}.isOverMiddleArea`, true);
                } else if (isOverBottomArea) {
                    newState =
                        dotProp.set(newState, `entities.${ENTITY_REDUCER_KEY}.${hoverId}.isOverBottomArea`, true);
                }
            }
        }
        return newState;
    },
    [PAGE_SUCCESS]: (state, action) => {
        if (action.method === METHODS.DELETE) {
            return removePage(state, action.id, action.parentId);
        }
        if (action.method === METHODS.PUT) {
            return updateHomePage(state, action.id);
        }
        if (action.method === METHODS.POST) {
            return updateHomePage(state, action.response.result);
        }
        return state;
    }
});

export const reducers = {
    pagination: paginationReducer,
    feature: featureReducer
};

const updatePageSequence = (
    dragId,
    dropId,
    dragParentId,
    dropParentId,
    dropOnTopArea,
    dropOnMiddleArea,
    dropOnBottomArea
) => ({
    type: [UPDATE_PAGE_SEQUENCE],
    dragId,
    dropId,
    dragParentId,
    dropParentId,
    dropOnTopArea,
    dropOnMiddleArea,
    dropOnBottomArea
});

const updateHoverState = (
    dragId,
    hoverId,
    dragParentId,
    hoverParentId,
    isOverTopArea,
    isOverMiddleArea,
    isOverBottomArea
) => ({
    type: [UPDATE_HOVER_STATE],
    dragId,
    hoverId,
    dragParentId,
    hoverParentId,
    isOverTopArea,
    isOverMiddleArea,
    isOverBottomArea
});

const dropOutsideList = (dragId, parentId) => ({
    type: [DROP_OUTSIDE_LIST],
    dragId,
    parentId
});

const Endpoints = {
    createOne: (eventId, module = 'artists') =>
        `events/${eventId}/production-website/${module}/pages`,
    updateOne: (eventId, pageId, module = 'artists') =>
        `events/${eventId}/production-website/${module}/pages/${pageId}`,
    fetchAll: (eventId, module = 'artists') =>
        `events/${eventId}/production-website/${module}/pages?${limitParam('all')}`,
    updateAll: (eventId, module = 'artists') =>
        `events/${eventId}/production-website/${module}/pages/sequences`
};

const transformOnePageToApiFormat = (page) => ({
    ...pick(page, ['id', 'sequence', 'slug', 'name', 'body', 'artistTypePages']),
    homepage: page.isHomePage || false
});

const transformAllPagesToApiFormat = (page) => ({
    ...pick(page, ['id', 'sequence']),
    children: page.children.map(transformAllPagesToApiFormat)
});

const createOne = (eventId, values, module) => ({
    [CALL_API]: {
        types: [
            PAGE_REQUEST,
            PAGE_SUCCESS,
            PAGE_FAILURE
        ],
        method: METHODS.POST,
        body: transformOnePageToApiFormat(values),
        endpoint: Endpoints.createOne(eventId, module),
        schema: entity
    }
});

const updateOne = (eventId, pageId, parentId, values, module) => ({
    [CALL_API]: {
        types: [
            PAGE_REQUEST,
            PAGE_SUCCESS,
            PAGE_FAILURE
        ],
        method: METHODS.PUT,
        id: pageId,
        body: transformOnePageToApiFormat(values),
        endpoint: Endpoints.updateOne(eventId, pageId, module),
        schema: parentId ? childEntity : entity
    }
});

const deleteOne = (eventId, pageId, parentId, module) => ({
    parentId,
    [CALL_API]: {
        types: [
            PAGE_REQUEST,
            PAGE_SUCCESS,
            PAGE_FAILURE
        ],
        method: METHODS.DELETE,
        id: pageId,
        endpoint: Endpoints.updateOne(eventId, pageId, module),
        schema: entity
    }
});

const updateAll = (eventId, pages, module) => ({
    [CALL_API]: {
        types: [
            ALL_PAGES_REQUEST,
            ALL_PAGES_SUCCESS,
            ALL_PAGES_FAILURE
        ],
        silentRequest: true,
        doNotMergeResponseWithCache: true,
        method: METHODS.PUT,
        body: pages.map(transformAllPagesToApiFormat),
        endpoint: Endpoints.updateAll(eventId, module),
        schema: array
    }
});

const fetchAll = (eventId, module) => ({
    [CALL_API]: {
        types: [
            ALL_PAGES_REQUEST,
            ALL_PAGES_SUCCESS,
            ALL_PAGES_FAILURE
        ],
        endpoint: Endpoints.fetchAll(eventId, module),
        schema: array
    }
});

const updateAllIfChanged = (module) => (dispatch, getState) => {
    const state = getState();
    const eventId = getCurrentEventId(state);
    const pages = getAllWithChildren(state);
    // Only update when something has changed
    return dispatch(updateAll(eventId, pages, module));
};

const updateAllIfChangedDebounced =
    debounce((dispatch, method) => dispatch(updateAllIfChanged(method)), 2000);

const fetchAllOrLoadFromCache = (module) => (dispatch, getState) => {
    const state = getState();
    const eventId = getCurrentEventId(state);
    return dispatch(fetchAll(eventId, module));
};

export const actions = {
    createOne,
    updateOne,
    deleteOne,
    fetchAll,
    fetchAllOrLoadFromCache,
    updateAll,
    updateAllIfChanged,
    updateAllIfChangedDebounced,
    updatePageSequence,
    dropOutsideList,
    updateHoverState
};
