import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import DialogContainer from "../dialogContainer/DialogContainer";
import DialogCard from "../dialogCard/DialogCard";
import DialogCardBottom from "../dialogCard/DialogBottom";
import FormField from "../formfields/FormField";
import Button from "../button/Button";

const TasksValidation = () =>
  yup.object({
    name: yup.string().required(),
  });

const TasksDialog = ({ addTask, task, contacts, ...props }) => {
  return (
    <DialogContainer>
      <DialogCard {...props}>
        <Formik
          initialValues={{
            name: task.name,
            assignee: task.assignee ? task.assignee.id : '',
            description: task.description,
          }}
          validationSchema={TasksValidation()}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            const { isValid, values } = formik;

            return (
              <Fragment>
                <div className={"dialog-inner-container"}>
                  <h3 className="dialog-title">
                    {task.id ? "Edit task" : "Create task"}
                  </h3>
                  <Form>
                    <Field name="name" label="Title" required as={FormField} />
                    <label className="form-field">
                      <span className="form-field__label">Assignee</span>
                      <Field as="select" id="assignee" name="assignee">
                        <option value="">
                          Who do you want to assign this task to
                        </option>
                        {contacts.map(({ firstName, lastName, id }) => (
                          <option
                            key={id}
                            value={id}
                          >{`${firstName} ${lastName}`}</option>
                        ))}
                      </Field>
                    </label>
                    <Field name="description" label="Description">
                      {({ field }) => (
                        <label className="form-field">
                          <span className="form-field__label">Description</span>
                          <textarea
                            className="form__textarea"
                            rows="10"
                            id="description"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                  </Form>
                </div>
                <DialogCardBottom>
                  <Button
                    label={task.id ? "Update" : "Add"}
                    variant="primary"
                    type="button"
                    onClick={() => addTask({ ...values, id: task.id })}
                    isDisabled={!isValid}
                  />
                </DialogCardBottom>
              </Fragment>
            );
          }}
        </Formik>
      </DialogCard>
    </DialogContainer>
  );
};

TasksDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
  addTask: PropTypes.func.isRequired,
  task: PropTypes.any.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.any),
};

export default TasksDialog;
