import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import ZoneTable from '../tables/ZoneTable.view';
import ZoneModalCreate from '../modals/ZoneModalCreate.view';

export default class ZoneOverviewCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            zones: OptionTypes.instanceOf(Zone.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // Subviews
        this.createModal = this.initCreateModal();
    }

    initContent() {
        return this.initTable();
    }

    initTable() {
        const { zones } = this;
        return new ZoneTable({ zones });
    }

    initCreateModal() {
        const createModal = new ZoneModalCreate();
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    onCreated(model) {
        this.zones.add(model);
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add Zone',
            icon: 'bs-icon-plus',
            action: this.create.bind(this)
        }];
    }

    create() {
        const event = this.zones.getRelation('event');
        this.createModal.open(null, { event });
    }

}
