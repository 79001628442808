import { connect } from 'react-redux';
import { trim } from 'common-tags';
import { mapEventContextToProps, mapBookingContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as actions from 'app/react/state/actions/_index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Performer from 'app/react/entities/events/bookings/performers/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as TicketType from 'app/react/entities/events/ticket_types/index';
import * as PWArtistSettings from 'app/react/entities/events/production_website/settings/artists/index';
import * as PWArtistSettingsSection from 'app/react/entities/events/production_website/settings/artists/sections/index';
import * as PWArtistSettingsSectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/index';
import View from '../../components/View';
import * as State from './State';

const FAILED_POPUP =
    'Failed to create performer.';
const SUCCESSFUL_POPUP = (fullName) =>
    `Performer <strong>${fullName}</strong> successfully updated.`;

const mapStateToProps = (state) => {
    const {
        values = {},
        errors = {},
        selectedPassport,
        isSaving,
    } = State.getViewState(state);
    const section = PWArtistSettingsSection.stateHelpers.getCurrent(state) || {};
    const formFields = PWArtistSettingsSectionFormField.stateHelpers.getSome(state, section.formFields);
    const ticketTypeOptions = TicketType.stateHelpers.getAllForCurrentContext(state);

    const props = {
        isCreating: false,
        ...mapEventContextToProps(state),
        ...mapBookingContextToProps(state),
        isFetching: (
            isSaving ||
            ArtistType.stateHelpers.isFetchingCurrent(state) ||
            PWArtistSettings.stateHelpers.isFetchingCurrent(state) ||
            Performer.stateHelpers.isFetchingCurrent(state) ||
            TicketType.stateHelpers.isFetchingAllForCurrentContext(state)
        ),
        values,
        errors,
        arePassportsEnabled: section.passportsEnabled,
        dynamicFields: formFields,
        ticketTypeOptions
    };


    if(selectedPassport != undefined && selectedPassport.name != undefined) props.selectedPassport = selectedPassport;

    return props;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSave: () => {
        const { values } = stateProps;
        const { redirect } = ownProps;
        dispatchProps.onSave(values, redirect);
    },
    onBack: () => {
        const { redirect } = ownProps;
        dispatchProps.onBack(redirect);
    }

});

const mapDispatchToProps = (dispatch) => ({
    onChangeValue: (key, value) => {
        dispatch(State.setViewStateValue(`values.${key}`, value));
    },
    onChangePassport: (passport) => {
        if (typeof passport === 'undefined') {
            dispatch(State.setViewStateValue('values.passport', null));
            dispatch(State.deleteViewStateValue('selectedPassport'));
        } else {
            dispatch(State.setViewStateValue('values.passport', passport.uuid));
        }
    },
    onUploadSuccess: (uploadedFile) => {
        dispatch(State.setViewStateValue('selectedPassport', uploadedFile));
        dispatch(State.mergeViewState({ errors: {} }));
    },
    onUploadFailed: (errors) => {
        dispatch(State.mergeViewState({ errors }));
        dispatch(State.setViewStateValue('values.passport', null));
    },
    onSelectContact: (contact) => {
        if (typeof contact !== 'undefined') {
            dispatch(State.mergeViewStateValue('values', {
                ...contact
            }));
        }
    },
    onSave: (values, redirect) => {
        dispatch((State.setViewStateValue('isSaving', true)));
        const { firstname, lastname } = values;
        const fullName = `${firstname} ${lastname}`.trim();
        dispatch(Performer.thunks.updateCurrent(values))
            .then(({ isFailure, errors }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(FAILED_POPUP));
                    dispatch(State.mergeViewState({
                        errors: errors,
                        isSaving: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP(fullName)));
                    if (typeof redirect === 'undefined') {
                        dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
                    } else {
                        dispatch(actions.navigate.pageReload(redirect))
                    }
                }
            });
    },
    onBack: (redirect) => {
        if (typeof redirect === 'undefined') {
            dispatch(Booking.thunks.navigateToCurrent({ pageReload: true }));
        } else {
            dispatch(actions.navigate.pageReload(redirect))
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(View);
