import React from 'react';

export const HEADER = {
    NAME: {
        TEXT: 'Name',
        WIDTH: 'initial'
    },
    TYPE: {
        TEXT: 'Type',
        WIDTH: 'initial'
    },
    TIME: {
        TEXT: 'Time',
        WIDTH: (150 * 2) + (150 * 2)
    },
    NOTE: {
        TEXT: 'Note',
        WIDTH: 'initial'
    },
    ACTIONS: {
        TEXT: 'Actions',
        WIDTH: '140px'
    }
};

const TimeSlotHeaderRow = () => (
    <tr>
        <th style={{ width: HEADER.NAME.WIDTH }}>
            {HEADER.NAME.TEXT}
        </th>
        <th style={{ width: `${150}px` }}>
            {HEADER.TIME.TEXT}
        </th>
        <th style={{ width: `${120}px` }} />
        <th style={{ width: `${150}px` }} />
        <th style={{ width: `${120}px` }} />
        <th style={{ width: HEADER.ACTIONS.WIDTH }}>
            {HEADER.ACTIONS.TEXT}
        </th>
    </tr>
);

export default TimeSlotHeaderRow;
