import _ from 'underscore';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import PeriodDaysTable from 'app/backbone/views/event/settings/accreditation/components/PeriodDaysTable.view';
import * as CellFactory from 'app/backbone/helpers/CellFactory';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import GrantedDayCell from './cells/GrantedDayCell.view';
import GrantedAllDaysCell from './cells/GrantedAllDaysCell.view';
import * as AccrediteeItem from 'app/backbone/models/event/accreditation/accreditees/AccrediteeItem';

export default class AccrediteeItemTable extends PeriodDaysTable {

    optionTypes() {
        return _.extend({}, super.optionTypes(), {
            collection: OptionTypes.instanceOf(AccrediteeItem.Collection).isRequired,
            items: OptionTypes.instanceOf(EventItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            editing: OptionTypes.bool,
            placeholderHeight: OptionTypes.number
        });
    }

    optionDefaults() {
        return _.extend({}, super.optionDefaults(), {
            editing: true,
            placeholderHeight: 100
        });
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        this.actions = this.initActions();
        this.placeholderText = this.getPlaceholderText();
        this.firstColumns = this.initFirstColumns();
        this.columns = this.initColumns();
        this.lastColumns = this.initLastColumns();

        super.initialize(options);
    }

    initFirstColumns() {
        return [{
            name: 'name',
            cell: 'string',
            label: 'Item',
            headerCell: CellFactory.createHeaderCell('125px'),
            sortable: false,
            editable: false
        }, {
            name: 'granted_by',
            cell: 'string',
            label: 'Added By',
            headerCell: CellFactory.createHeaderCell('125px'),
            sortable: false,
            editable: false
        }, {
            name: 'all_days',
            label: 'All',
            cell: this.createAllDaysCell(),
            headerCell: CellFactory.createHeaderCell('55px', 'center'),
            sortable: false,
            editable: false
        }];
    }

    initColumns() {
        return [];
    }

    initLastColumns() {
        const { actions } = this;
        return [{
            name: 'actions',
            label: 'Actions',
            cell: CellFactory.createActionCell(actions, null),
            sortable: false,
            editable: false
        }];
    }

    initActions() {
        return [{
            name: 'remove',
            icon: 'bs-icon-cross',
            tooltip: 'Remove',
            action: this.removeItem.bind(this),
            canRender: item => item.get('granted_by_promoter')
        }];
    }

    createDayCell(day, classNamePostfix) {
        const className = `${GrantedDayCell.prototype.className()} ${classNamePostfix}`;
        return GrantedDayCell.extend({ day, className });
    }

    createAllDaysCell() {
        return GrantedAllDaysCell;
    }

    removeItem(item) {
        this.collection.remove(item);
    }

    getPlaceholderText() {
        const { items } = this;

        if (!items.isEmpty()) return 'No items have been added for this attendee.';

        // Get the Event from the collection or  from the first model in the collection
        const event = items.hasRelation('event') ? items.getRelation('event') : items.first().getRelation('event');

        const createItemUrl = `/events/${event.id}/settings/accreditation/items`;
        return `No items have been created for this event. <a href="${createItemUrl}">Create your first item.</a>`;
    }

}
