import { schema } from 'normalizr';

export const ENTITY_REDUCER_KEY = 'accountArtistItems';
export const COLLECTION_REDUCER_KEY = ENTITY_REDUCER_KEY;

export const processStrategy = entity => ({
    ...entity,
    price: parseFloat(parseInt(entity.price || 0, 10) / 100).toFixed(2),
    total: parseFloat(parseInt(entity.total || 0, 10) / 100).toFixed(2)
});

const entityDefinition = {};
const entityOptions = { processStrategy };

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, entityOptions);
export const entities = new schema.Array(entity);

export const toAPI = entity => ({
    ...entity,
    price: parseFloat(entity.price || 0) * 100
});
