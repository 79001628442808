import BaseController from 'app/backbone/helpers/BaseController'
import * as Routes from './guestListRoutes'
import GuestListFormView from 'app/backbone/views/event/artists/bookings/guest_list/Form.view'

export default class GuestListController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.create(), null, this.create.bind(this));
        this.router.route(Routes.update(), null, this.update.bind(this));
    }

    create() {
        this.app.renderContentView(new GuestListFormView());
    }

    update() {
        this.app.renderContentView(new GuestListFormView());
    }
}
