import React from 'react'
import { connect } from 'react-redux'
import { SearchSelect } from 'ui-kit'
import * as Booking from 'app/react/entities/events/bookings/index'
import BookingOption, { toBookingOption } from './BookingOption'
import * as State from './SearchSelectBookingState'

const mapStateToProps = state => {
    const { searchTerm } = State.getViewState(state);

    return ({
        options: Booking.stateHelpers.getSearchResultsForCurrentContext(state, searchTerm, true),
        isLoading: Booking.stateHelpers.isSeachingForCurrentContext(state)
    })
};

const mapDispatchToProps = dispatch => ({
    onChangeInput: (searchTerm, includes) => {
        dispatch(State.setViewStateValue('searchTerm', searchTerm));
        dispatch(Booking.thunks.searchAllForCurrentContext(searchTerm, includes));
    }
});

const SearchSelectBookings = (props) => {
    const {
        options,
        toOption,
        additionalIncludes,
        onChangeInput,
    } = props;
    const includes = ['artist', 'artist_type', 'type', 'status', 'performance.day', 'performance.stage'];

    function onType(searchTerm) {
        onChangeInput(searchTerm, includes.concat(additionalIncludes));
    }

    return (
        <SearchSelect
            {...props}
            onChangeInput={onType}
            options={options.map(toOption)}
        />
    );
};

SearchSelectBookings.defaultProps = {
    additionalIncludes: [],
    toOption: toBookingOption,
    renderOption: (option) => (
        <BookingOption option={option} />
    ),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchSelectBookings);
