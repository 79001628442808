import { connect } from 'react-redux'
import * as actions from 'app/react/state/actions/_index'
import * as stateHelpers from 'app/react/state/helpers'
import {
    mergeStaticAndCustomFields,
    getFormFieldErrorsFromResponseJSON,
} from 'app/react/helpers/_index'
import { sectionMappings } from '../mappings'
import EditSectionView from './EditSectionView'
import * as Account from 'app/react/entities/accounts/index'
import * as Event from 'app/react/entities/events/index'
import * as Profile from 'app/react/entities/events/accreditation/profiles/index'
import * as ArtistType from 'app/react/entities/events/artist_types/index'
import * as TicketType from 'app/react/entities/events/ticket_types/index'
import * as Settings from 'app/react/entities/events/production_website/settings/artists/index'
import * as Section from 'app/react/entities/events/production_website/settings/artists/sections/index'
import * as SectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/index'
import { getStaticFormFields } from '../views/forms/staticFormFields'
import * as Routes from 'app/routes/event/production_website/artists/form/routes'

export const EDIT_SECTION_FORM_ID = 'editSectionForm';
export const EDIT_SECTION_CARD_ID = 'editSectionCard';
export const POPUP_MESSAGES = {
    FAILED: 'Failed to save section settings.',
    DONE: 'Section settings saved successfully.',
}

const mapStateToProps = (state) => {
    const account = Account.stateHelpers.getCurrent(state);
    const event = Event.stateHelpers.getCurrent(state);
    const section = Section.stateHelpers.getCurrent(state) || {};
    const formState = stateHelpers.getComponentState(state, EDIT_SECTION_FORM_ID) || {};
    const accreditationProfiles = Profile.stateHelpers.getAllForCurrentContext(state);

    const accreditationProfileOptions =
        accreditationProfiles.map(({ id, name }) => ({ value: id, label: name }));

    const ticketTypeOptions = TicketType.stateHelpers.getAllForCurrentContext(state);

    const { Form } = sectionMappings.find(mapping => mapping.id === section.id) || sectionMappings[0];
    const staticFields = getStaticFormFields(section.passportsEnabled, section.passportsRequired);
    const customFields = SectionFormField.stateHelpers.getSome(state, section.formFields);
    const exampleFormFields = mergeStaticAndCustomFields(staticFields, customFields);
    const isAccreditationEnabled = account.accreditationEnabled;
    const isArtistTypesEnabled = event.artistTypesEnabled;

    return {
        eventId: event.id,
        isArtistTypesEnabled,
        artistTypeId: ArtistType.stateHelpers.getCurrentId(state),
        sectionId: section.id,
        sectionName: section.name || section.defaultName,
        isFetching: (
            ArtistType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            Settings.stateHelpers.isFetchingCurrent(state) ||
            Section.stateHelpers.isFetchingCurrent(state) ||
            TicketType.stateHelpers.isFetchingAllForCurrentContext(state) ||
            (
                isAccreditationEnabled &&
                Profile.stateHelpers.isFetchingAllForCurrentContext(state)
            )
        ),

        Form,
        isAccreditationEnabled,
        accreditationProfileOptions,
        ticketTypeOptions,
        formValues: formState.values || {},
        formErrors: formState.errors || {},

        exampleFormFields,
        dateFormat: event.dateTimeFormat.date.momentjs,
    };
};

const mapDispatchToProps = (dispatch) => {
    const onChangeFormValue = (key, value) => {
        dispatch(actions.updateFormComponentStateValue(EDIT_SECTION_FORM_ID, key, value));
    };
    const backToSectionOverview = (isAccreditationEnabled, eventId, artistTypeId) => {
        dispatch(actions.navigate.to(Routes.form(isAccreditationEnabled, eventId, artistTypeId)));
    };
    const goToFormBuilder = (isAccreditationEnabled, eventId, artistTypeId, sectionId) => {
        dispatch(actions.navigate.to(
            Routes.formSectionFormBuilder(isAccreditationEnabled, eventId, artistTypeId, sectionId))
        );
    };
    const updateSection = () => {
        dispatch((stubDispatch, getState) => {
            const state = getState();
            const { values } = stateHelpers.getComponentState(state, EDIT_SECTION_FORM_ID) || {};
            const {
                description,
                limit,
                telephoneRequired,
                passportsEnabled,
                passportsRequired,
                updateBookings,
                accreditationProfile,
                ticketType,
                foodLimit,
                drinkLimit,
                roomLimit,
                nightLimit,
                create,
                requiresApproval,
            } = values;
            const body = {
                description,
                limit,
                telephoneRequired,
                passportsEnabled,
                passportsRequired,
                updateBookings,
                accreditationProfile,
                ticketType,
                foodLimit,
                drinkLimit,
                roomLimit,
                nightLimit,
                create,
                requiresApproval,
            };

            return stubDispatch(Section.thunks.updateCurrent(body))
                .then(({ error }) => {
                    if (error) {
                        const errors = getFormFieldErrorsFromResponseJSON(error);
                        dispatch(actions.mergeComponentState(EDIT_SECTION_FORM_ID, { errors }));
                        dispatch(actions.showErrorPopup(POPUP_MESSAGES.FAILED));
                    } else {
                        dispatch(actions.showSuccessPopup(POPUP_MESSAGES.DONE));
                        dispatch(actions.mergeComponentState(EDIT_SECTION_FORM_ID, {
                            values: {
                                ...values,
                                updateBookings: false
                            },
                            errors: {}
                        }));
                    }
                });
        });
    };
    return {
        onChangeFormValue,
        backToSectionOverview,
        updateSection,
        goToFormBuilder,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    backToSectionOverview: () => dispatchProps.backToSectionOverview(
        stateProps.isArtistTypesEnabled,
        stateProps.eventId,
        stateProps.artistTypeId,
    ),
    goToFormBuilder: () => dispatchProps.goToFormBuilder(
        stateProps.isArtistTypesEnabled,
        stateProps.eventId,
        stateProps.artistTypeId,
        stateProps.sectionId,
    )
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditSectionView);
