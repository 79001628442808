import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Grid } from 'ui-kit';
import { Modal, DebouncedInput } from 'app/react/components/_index';

const { Header, Content, Footer } = Modal;
const { Cell } = Grid;

const FIRST_NAME_LABEL = 'First name';
const LAST_NAME_LABEL = 'Last name';
const TEL_LABEL = 'Telephone';
const CAR_TYPE_LABEL = 'Car type';

const CREATE_TITLE = 'Create driver';
const CREATE_BUTTON_TEXT = 'Create';
const CREATE_ANOTHER_BUTTON_TEXT = 'Create another';

const UPDATE_TITLE = 'Update driver';
const UPDATE_BUTTON_TEXT = 'Update';

const DriverModal = ({
    id,
    isCreating = false,
    canCreateAnother = true,
    isOpened = false,
    isSaving = false,
    values = {},
    errors = {},
    onClosed = () => {},
    onChangeValue = () => {},
    onSave = () => {},
}) => (
    <Modal
        width={560}
        closeOnBackgroundClick={false}
        opened={isOpened}
        syncing={isSaving}
        onClosed={onClosed}
    >
        <Header onClosed={onClosed}>
            <Modal.Header.Title>{isCreating ? CREATE_TITLE : UPDATE_TITLE}</Modal.Header.Title>
        </Header>
        <Content>
            <Grid>
                <Cell>
                    <InputGroup messages={errors.firstname}>
                        <label className="required">
                            {FIRST_NAME_LABEL}
                        </label>
                        <DebouncedInput
                            type="text"
                            value={values.firstname || ''}
                            onChange={value => onChangeValue('firstname', value)}
                        />
                    </InputGroup>
                </Cell>
                <Cell>
                    <InputGroup messages={errors.lastname}>
                        <label className="required">
                            {LAST_NAME_LABEL}
                        </label>
                        <DebouncedInput
                            type="text"
                            value={values.lastname || ''}
                            onChange={value => onChangeValue('lastname', value)}
                        />
                    </InputGroup>
                </Cell>
            </Grid>
            <InputGroup messages={errors.telephone}>
                <label>
                    {TEL_LABEL}
                </label>
                <DebouncedInput
                    type="text"
                    value={values.telephone || ''}
                    onChange={value => onChangeValue('telephone', value)}
                />
            </InputGroup>
            <InputGroup messages={errors.carType}>
                <label>
                    {CAR_TYPE_LABEL}
                </label>
                <DebouncedInput
                    type="text"
                    value={values.carType || ''}
                    onChange={value => onChangeValue('carType', value)}
                />
            </InputGroup>
        </Content>
        {isCreating
            ? <Footer>
                {canCreateAnother &&
                    <Footer.Button
                        className="create-another"
                        onClick={() => onSave(true)}
                    >
                        {CREATE_ANOTHER_BUTTON_TEXT}
                    </Footer.Button>
                }
                <Footer.Button
                    className="success"
                    onClick={() => onSave()}
                >
                    {CREATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer> 
            : <Footer>
                <Footer.Button
                    className="success"
                    onClick={() => onSave()}
                >
                    {UPDATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
        }
    </Modal>
);

DriverModal.propTypes = {
    isCreating: PropTypes.bool,
    canCreateAnother: PropTypes.bool,
    isOpened: PropTypes.bool,
    isSaving: PropTypes.bool,
    values: PropTypes.object,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func
};

export default DriverModal;
