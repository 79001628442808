import React, { useState, useRef, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "../../../utils/Icon";

const FileDAD = ({ label, image, setImage, iconType }) => {
  const uploadButton = useRef();
  const container = useRef();

  const [error, setError] = useState(undefined);
  const [active, setActive] = useState(false);
  const fileHandler = (file, name, type) => {
    if (type.split("/")[0] !== "image") {
      setError("Please upload an image file");
      return false;
    }
    setError("");
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(iconType, { image: reader.result, name, file });
    };
  };

  const removeImage = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setImage(iconType, undefined);
  };

  useEffect(() => {
    if (uploadButton && uploadButton.current) {
      uploadButton.current.addEventListener("change", () => {
        setImage(iconType, undefined);
        Array.from(uploadButton.current.files).forEach((file) => {
          fileHandler(file, file.name, file.type);
        });
      });
    }

    if (container && container.current) {
      container.current.addEventListener(
        "dragenter",
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          setActive(true);
        },
        false
      );

      container.current.addEventListener(
        "dragleave",
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          setActive(false);
        },
        false
      );

      container.current.addEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          setActive(true);
        },
        false
      );

      container.current.addEventListener(
        "drop",
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          setActive(false);
          let draggedData = e.dataTransfer;
          let files = draggedData.files;
          setImage(iconType, undefined);
          Array.from(files).forEach((file) => {
            fileHandler(file, file.name, file.type);
          });
        },
        false
      );
    }
  }, [image]);

  return (
    <div className="file-DAD">
      <h4 className="production-website__label">{label}</h4>
      <label
        ref={container}
        htmlFor={`upload-button-${label}`}
        className={classNames([
          "file-DAD__container",
          active && "file-DAD__container--active",
        ])}
      >
        {!image && (
          <div className="file-DAD__image-display">
			  <Icon name="upload" />
            <input
              ref={uploadButton}
              className="file-DAD__input"
              type="file"
              id={`upload-button-${label}`}
              multiple
              accept="image/*"
            />
            <div className="file-DAD__image-display__label">
              <span className="file-DAD__upload-button">
                Drag and drop your file here or
                <a>select something from your device</a>
              </span>
              <span className="file-DAD__upload-button--sub">
                {iconType === 'favicon' ? 'ico or favicon': 'jpg or jpeg'}
              </span>
            </div>
          </div>
        )}
        {image && (
          <div className="file-DAD__image-display">
            <img
			className="file-DAD__image"
              alt={image.name}
              src={image.image}
            />
            <span>{image.name}</span>
            <button className="file-DAD__remove-button" onClick={removeImage}>
              <Icon name="close" />
            </button>
          </div>
        )}
        {error && <div className="file-DAD__error">{error}</div>}
      </label>
    </div>
  );
};

FileDAD.propTypes = {
  label: PropTypes.string,
  image: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    file: PropTypes.object,
  }),
};

export default FileDAD;
