import OptionTypes from 'app/backbone/helpers/OptionTypes';
import BaseView from 'app/backbone/components/Base.view';
import template from './CardContent.hbs';

export default class CardContent extends BaseView {

    get template() {
        return template;
    }

    optionTypes() {
        return {
            content: OptionTypes.string
        };
    }

    initialize(options) {
        this.resolveOptions(options);
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {
                content: this.content
            }
        };
    }

}
