import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import { setCurrentContextId } from 'app/react/state/actions/_index'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'

export const ONE_REQUEST = 'ONE_ARTIST_TYPE_REQUEST';
export const ONE_SUCCESS = 'ONE_ARTIST_TYPE_SUCCESS';
export const ONE_FAILURE = 'ONE_ARTIST_TYPE_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const ALL_REQUEST = 'ALL_ARTIST_TYPES_REQUEST';
export const ALL_SUCCESS = 'ALL_ARTIST_TYPES_SUCCESS';
export const ALL_FAILURE = 'ALL_ARTIST_TYPES_FAILURE';
export const ALL_TYPES = [
    ALL_REQUEST,
    ALL_SUCCESS,
    ALL_FAILURE
];

export const setCurrentId = id => setCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY, id);

export const fetchAll = (accountId, eventId) => ({
    [CALL_API]: {
        types: ALL_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchAll(eventId),
        schema: Schemas.entities
    },
    accountId,
    eventId
});

export const fetchOne = (accountId, eventId, artistTypeId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchOne(eventId, artistTypeId),
        schema: Schemas.entity,
        id: artistTypeId
    },
    accountId,
    eventId,
    artistTypeId
});

export const createOne = (accountId, eventId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId),
        schema: Schemas.entity
    },
    accountId,
    eventId
});

export const updateOne = (accountId, eventId, artistTypeId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        id: artistTypeId,
        endpoint: Endpoints.updateOne(eventId, artistTypeId),
        schema: Schemas.entity
    },
    accountId,
    eventId,
    artistTypeId
});

export const deleteOne = (accountId, eventId, artistTypeId) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, artistTypeId),
        schema: Schemas.entity,
        id: artistTypeId
    },
    accountId,
    eventId,
    artistTypeId
});

export const duplicateOne = (accountId, eventId, artistTypeId, values) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        id: artistTypeId,
        endpoint: Endpoints.duplicateOne(eventId, artistTypeId),
        schema: Schemas.entity
    },
    accountId,
    eventId,
    artistTypeId
});