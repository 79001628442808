import { connect } from 'react-redux';
import { omit } from 'underscore';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import { mapAccountContextToProps, mapEventContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import { enhanceStateWithHelpers } from 'app/react/entities/enhanceStateWithHelpers';
import * as actions from 'app/react/state/actions/_index';
import Modal from './ItemModalContainer';
import * as State from './ItemModalState';
import * as BookingItem from 'app/react/entities/events/bookings/items/index';

const FAILED_POPUP = 'Failed to create item.';
const SUCCESSFUL_POPUP = 'Item successfully created.';

const mapStateToProps = (state) => {
    const { helpers } = enhanceStateWithHelpers(state);
    const { eventArtistItemGroups, eventArtistItems } = helpers;

    const {
        isOpened,
        isSaving,
        isCreatingNewItem,
        values = {},
        errors = {},
    } = State.getCreateModalState(state);

    return {
        ...mapAccountContextToProps(state),
        ...mapEventContextToProps(state),
        isCreating: true,
        isOpened,
        isCreatingNewItem,
        values,
        errors,
        isSaving,

        groups: eventArtistItemGroups.getAllForCurrentContext(),
        items: eventArtistItems.getAllForCurrentContext(),

        token: state.tokens.jwt
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSave: (createAnother) => {
        const { values, isCreatingNewItem } = stateProps;
        dispatchProps.onSave(createAnother, values, isCreatingNewItem);
    }
});

const addItemEntity = (bookingItem) => ({
    ...omit(bookingItem, ['name', 'group', 'price', 'description']),
    item: {
        name: bookingItem.name,
        group: bookingItem.group,
        price: bookingItem.price,
        description: bookingItem.description,
    }
});

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch(State.mergeCreateModalState({
            isOpened: false,
            isCreatingNewItem: false
        }));
    },
    onChangeValue: (key, value) => {
        dispatch(State.setCreateModalStateValue(`values.${key}`, value));
    },
    onToggleCreateNewItem: (isCreatingNew) => {
        dispatch(State.setCreateModalStateValue('isCreatingNewItem', isCreatingNew));
    },
    onSave: (createAnother = false, values, isCreatingNewItem) => {
        dispatch((State.setCreateModalStateValue('isSaving', true)));

        // Make sure always to submit a location.
        if (typeof values.location === 'undefined') {
            values.location = '';
        }

        const apiValues = BookingItem.schemas.toAPI(values);
        dispatch(BookingItem.thunks.createOneForCurrentContext(isCreatingNewItem ? addItemEntity(apiValues) : apiValues))
            .then(({ error, response }) => {
                if (typeof error !== 'undefined') {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.showErrorPopup(FAILED_POPUP));
                    dispatch(State.mergeCreateModalState({
                        errors: errors,
                        isSaving: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP));
                    dispatch(State.mergeCreateModalState({
                        errors: {},
                        values: {},
                        isOpened: createAnother,
                        isSaving: false,
                        isCreatingNewItem: false
                    }));
                }
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Modal);
