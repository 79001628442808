import React from 'react'
import {
    AlertBox,
    useIsDismissed,
} from 'ui-kit'

const { Action } = AlertBox;

const LOCAL_STORAGE_KEY = 'dismissPwSettingTextEditorAlert';
const MESSAGE = 'In order to provide a coherent user experience on the Production website, link colors and font ' +
    'sizes cannot be altered using the text editor by default. However, if you happen to be an HTML wizard, you ' +
    'can use the editor in Code view to make any changes you want. Just make sure to make it beautiful.';
const DISMISS_TEXT = 'Dismiss';

const TextEditorAlert = () => {
    const [isDismissed, dismiss] = useIsDismissed(LOCAL_STORAGE_KEY, true);

    return !isDismissed
        ? <AlertBox hasIconPrefix>
            {MESSAGE}
            <Action onClick={() => dismiss()}>
                {DISMISS_TEXT}
            </Action>
        </AlertBox>
        : null;
};

export default TextEditorAlert;
