import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import PagesOverview from 'app/react/views/event/production_website/artists/pages/views/OverviewContainer';
import * as PWPage from 'app/react/entities/events/production_website/pages/index';
import * as Event from 'app/react/entities/events/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Routes from './routes'

export default class PagesController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        router.route(Routes.pages(), null, this.overview.bind(this));
    }

    overview(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(PagesOverview, { store }));
        const state = store.getState();

        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        let artistTypesPromise = Promise.resolve();
        if (Event.stateHelpers.isArtistTypesEnabledForCurrent(state)) {
            artistTypesPromise = dispatch(ArtistType.thunks.fetchAllForCurrentContext());
        }
        return Promise.all([
            artistTypesPromise,
            dispatch(PWPage.actions.fetchAllOrLoadFromCache()),
        ]);
    }

}
