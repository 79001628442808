import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import TaskForm from "./TasksForm";
import { apolloClient } from "../../../../../../state/data_sources/graphql";

const GET_EVENT_TASKS_QUERY = gql`
  query getEventTasks($eventId: ID!, $sort: [String!]) {
    event(id: $eventId) {
      tasks(sort: $sort) {
        id
        name
        description
        assignee {
          id
        }
      }
      users {
        edges {
          node {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const GET_CONTACTS_FOR_EVENT = gql`
  query getEvent($eventId: ID!) {
    event(id: $eventId) {
      users {
        edges {
          node {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const CREATE_TASK_FOR_EVENT = gql`
  mutation createTaskForEvent($event: ID!, $input: CreateTaskSettingInput!) {
    createTaskForEvent(event: $event, input: $input) {
      task {
        id
        name
        description
        assignee {
          id
        }
      }
    }
  }
`;

const UPDATE_TASK_FOR_EVENT = gql`
  mutation updateTaskForEvent($id: ID!, $input: UpdateTaskSettingInput!) {
    updateTaskForEvent(id: $id, input: $input) {
      task {
        id
        name
        description
        assignee {
          id
        }
      }
    }
  }
`;

const DELETE_TASK_FROM_EVENT = gql`
  mutation deleteTaskFromEvent($id: ID!) {
    deleteTaskFromEvent(id: $id) {
      id
    }
  }
`;
const CLEAR_TASKS_FROM_EVENT = gql`
  mutation clearTasksFromEvent($event: ID!) {
    clearTasksFromEvent(event: $event) {
      id
    }
  }
`;

const getContactsForEvent = (eventId) =>
  apolloClient.query({
    query: GET_CONTACTS_FOR_EVENT,
    variables: { eventId },
  });

const updateTaskForEvent = (id, input) =>
  apolloClient.mutate({
    mutation: UPDATE_TASK_FOR_EVENT,
    variables: { id, input },
  });

const createTaskForEvent = (event, input) =>
  apolloClient.mutate({
    mutation: CREATE_TASK_FOR_EVENT,
    variables: { event, input },
  });

const getTasksForEvent = (eventId) =>
  apolloClient.query({
    query: GET_EVENT_TASKS_QUERY,
    variables: { eventId: eventId, sort: ["sequence"] },
  });

const deleteTaskFromEvent = (taskId) =>
  apolloClient.mutate({
    mutation: DELETE_TASK_FROM_EVENT,
    variables: { id: taskId },
  });

const clearTasksFromEvent = (eventId) =>
  apolloClient.mutate({
    mutation: CLEAR_TASKS_FROM_EVENT,
    variables: { event: eventId },
  });

  
  const TasksFormContainer = ({ setActiveStep, activeSubStep, eventState }) => {
  const [tasks, setTasks] = useState(
    eventState.event.tasks ? eventState.event.tasks : []
  );
  const [contacts, setContacts] = useState(undefined);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    if (
      (!eventState.event.tasks || eventState.event.tasks.length === 0) &&
      eventState.event.oldEventId
    ) {
      getContactsForEvent(eventState.event.id).then((res) => {
        if (res.data) {
          setContacts(res.data.event.users.edges.map((edge) => edge.node));
        }
      });
      clearTasksFromEvent(eventState.event.id)
        .then((res) => {
          getTasksForEvent(eventState.event.oldEventId).then((res) => {
            batchCreateTasks(eventState.event.id, res.data.event.tasks);
          });
        })
        .catch((error) => {
          console.error("Fetch tasks:: ", error);
          setError(
            "Something went wrong while getting the tasks for the event"
          );
        });
    } else {
      getTasksForEvent(eventState.event.id)
        .then((res) => {
          if (res.data) {
            setContacts(res.data.event.users.edges.map((edge) => edge.node));
            setTasks(res.data.event.tasks);
          }
        })
        .catch((error) => {
          console.error("Fetch tasks:: ", error);
          setError(
            "Something went wrong while getting the tasks for the event"
          );
        });
    }
  }, []);
  
  const batchCreateTasks = (eventId, tasks) => {
    const cleanTasks = tasks.map((task) => ({
      name: task.name,
      description: task.description,
      assignee: task.assignee ? task.assignee.id : undefined,
    }));
    const promises = cleanTasks.map((task) => Promise.resolve(
      createTaskForEvent(eventId, task)
    ));
    Promise.all(promises).then((res) => {
      setTasks(res.map((taskRes) => taskRes.data.createTaskForEvent.task))
    }).catch((error) => {
      setError('Something went wrong while fetching the tasks from the event you are trying to copy.')
      console.error('Batch create:: ', error);
    })
  };
  return (
    <TaskForm
      eventState={eventState}
      setActiveStep={setActiveStep}
      activeSubStep={activeSubStep}
      createTaskForEvent={createTaskForEvent}
      updateTaskForEvent={updateTaskForEvent}
      deleteTaskFromEvent={deleteTaskFromEvent}
      tasks={tasks}
      setTasks={setTasks}
      error={error}
      setError={setError}
      contacts={contacts}
    />
  );
};

TasksFormContainer.propTypes = {};

export default TasksFormContainer;
