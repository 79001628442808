import BaseController from 'app/backbone/helpers/BaseController'
import AccreditationController from './accreditation/Controller'
import ArtistsController from './artists/Controller'
import UsersController from './users/Controller'
import ProductionWebsiteController from './production_website/Controller'
import SettingsController from './settings/Controller'

export default class EventController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.subControllers = [
            new AccreditationController({ parent: this }),
            new ArtistsController({ parent: this }),
            new UsersController({ parent: this }),
            new ProductionWebsiteController({ parent: this }),
            new SettingsController({ parent: this }),
        ];
    }

}
