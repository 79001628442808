var _ = require('underscore'),
    Backbone = require('backbone');

var DateTime = require('app/backbone/helpers/DateTime'),
    ErrorTemplate = require('../inputs/errors/Error.hbs');

var ValidationGroup = Backbone.View.extend({

    ui() {
        return {
            input: '[name=' + this.inputName  + ']',
            error: '>small.error'
        }
    },

    events: {
        'focusout': 'validateAttr'
    },

    options: [
        'inputName!',
        'model!',
        'modelAttr!'
    ],

    initialize: function(options) {
        this.setOptions(options);
        this.bindModel();
    },

    render: function() {
        this.resolveHandles();
        this.delegateEvents();
    },

    bindModel: function() {
        this.listenTo(this.model, 'validated', this.modelValidated);
        this.listenTo(this.model, 'change:' + this.modelAttr, this.validateAttr);
    },

    unbindModel: function() {
        this.stopListening(this.model);
    },

    resetModel: function(model) {
        this.model = model;
        this.bindModel();
        this.validateAttr();
    },

    modelValidated: function(isModelValid, model, errors) {
        if(isModelValid || this.isAttrValid(errors)) this.removeError();
        else this.addError(errors[this.modelAttr]);
    },

    validateAttr: function() {
        if(this.hasError()) {
            this.model.validate();
        }
    },

    isAttrValid: function(errors) {
        return ! _.has(errors, this.modelAttr);
    },

    addError: function(message) {
        this.removeError();
        this.error = message;
        this.addInputClass('error');
        this.$el.append(ErrorTemplate({message: message}));
        this.resolveHandles();

    },

    addInputClass: function(className) {
        this.ui.input.addClass(className);
        if(this.ui.input.hasClass('selectized')) {
            this.ui.input.siblings('.selectize-control').addClass(className)
        }
    },

    removeError: function() {
        if(this.hasError()) {
            delete this.error;
            this.ui.error.remove();
            this.resolveHandles();
            this.removeInputClass('error');
        }
    },

    removeInputClass: function(className) {
        this.ui.input.removeClass(className);
        if(this.ui.input.hasClass('selectized')) {
            this.ui.input.siblings('.selectize-control').removeClass(className)
        }
    },

    hasError: function() {
        this.resolveHandles();
        return ! _.isUndefined(this.error) || !! this.ui.error.length;
    }
});

module.exports = ValidationGroup;
