import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    InputGroup,
    DateTimeInput,
    PostfixedInput,
    Grid,
} from 'ui-kit';
import { Tooltip, DebouncedInput } from 'app/react/components/_index';
import SelectPickupMode from '../selects/SelectPickupMode';
import SelectLocation from '../selects/SelectLocation';
import SelectDriver from '../selects/SelectDriver';
import SelectVehicle from '../selects/SelectVehicle';

const { Cell } = Grid;

const MODE_LABEL = 'Mode';

const CREATE_DRIVER_TOOLTIP = 'Create a new driver';
const CREATE_VEHICLE_TOOLTIP = 'Create a new vehicle';
const DEPARTURE_LABEL = 'Departure';
const DEPARTURE_DATE_LABEL = 'Date & time';
const CUSTOM_DEPARTURE_NAME_TOOLTIP = 'Set a custom departure location';
const CANCEL_CUSTOM_DEPARTURE_NAME_TOOLTIP = 'Choose an existing departure location';
const ARRIVAL_LABEL = 'Arrival';
const ARRIVAL_DATE_LABEL = 'Date & time';
const CUSTOM_ARRIVAL_NAME_TOOLTIP = 'Set a custom arrival location';
const CANCEL_CUSTOM_ARRIVAL_NAME_TOOLTIP = 'Choose an existing arrival location';
const NOTES_LABEL = 'Additional notes';
const DRIVER_LABEL = 'Driver';
const VEHICLE_LABEL = 'Vehicle';
const DRIVER_DEPARTURE_TIME_LABEL = 'Time to leave before pickup';
const DRIVER_DEPARTURE_TIME_OPTIONS = [{
    value: '',
    label: 'None'
}, {
    value: 900000,
    label: '15 min'
}, {
    value: 1800000,
    label: '30 min'
}, {
    value: 2700000,
    label: '45 min'
}, {
    value: 3600000,
    label: '1 hour'
}, {
    value: 4500000,
    label: '1 hour 15 min'
}, {
    value: 5400000,
    label: '1 hour 30 min'
}, {
    value: 6300000,
    label: '1 hour 45 min'
}, {
    value: 7200000,
    label: '2 hours'
},  {
    value: 9000000,
    label: '2 hours 30 min'
},  {
    value: 10800000,
    label: '3 hours'
}, {
    value: 12600000,
    label: '3 hours 30 min'
}, {
    value: 14400000,
    label: '4 hours'
}];

const CreatePickupFields = ({
    values = {},
    errors = {},

    dateFormat,
    timeFormat,

    modes,
    drivers,
    vehicles,
    locations,

    isSettingCustomDepartureName,
    isSettingCustomArrivalName,

    onChangeValue = () => {},
    onChangeDeparture = () => {},
    onSelectDepartureLocation = () => {},
    onSelectArrivalLocation = () => {},
    onToggleSetCustomDepartureName = () => {},
    onToggleSetCustomArrivalName = () => {},
    onOpenCreateDriverModal = () => {},
    onOpenCreateVehicleModal = () => {},
}) => (
    <div>
        <InputGroup messages={errors.mode}>
            <label className="required">{MODE_LABEL}</label>
            <SelectPickupMode
                modes={modes}
                value={values.mode}
                onChange={value => onChangeValue('mode', value)}
            />
        </InputGroup>
        <Grid className="no-margin-bottom">
            <Cell>
                <InputGroup messages={errors.departureLocation}>
                    <label className="required">{DEPARTURE_LABEL}</label>
                    <PostfixedInput.Container
                        postfix={!isSettingCustomDepartureName
                            ? <Tooltip content={CUSTOM_DEPARTURE_NAME_TOOLTIP}>
                                <button
                                    onClick={() => onToggleSetCustomDepartureName(true)}
                                    className="postfix"
                                >
                                    Edit
                                </button>
                            </Tooltip>
                            : <Tooltip content={CANCEL_CUSTOM_DEPARTURE_NAME_TOOLTIP}>
                                <button
                                    onClick={() => onToggleSetCustomDepartureName(false)}
                                    className="postfix"
                                >
                                    Cancel
                                </button>
                            </Tooltip>
                        }
                    >
                        {!isSettingCustomDepartureName
                            ? <SelectLocation
                                locations={locations.filter(location => location.id !== values.arrivalLocation)}
                                value={values.departureLocation}
                                onChange={onSelectDepartureLocation}
                            />
                            : <DebouncedInput
                                type="text"
                                value={values.departureLocationName || ''}
                                onChange={value => onChangeValue('departureLocationName', value)}
                            />
                        }
                    </PostfixedInput.Container>
                </InputGroup>
            </Cell>
            <Cell>
                <InputGroup messages={errors.departure}>
                    <label className="required">{DEPARTURE_DATE_LABEL}</label>
                    <DateTimeInput
                        value={values.departure}
                        dateFormat={dateFormat}
                        timeFormat={timeFormat}
                        onChange={onChangeDeparture}
                    />
                </InputGroup>
            </Cell>
        </Grid>
        <Grid className="no-margin-bottom">
            <Cell>
                <InputGroup messages={errors.arrivalLocation}>
                    <label className="required">{ARRIVAL_LABEL}</label>
                    <PostfixedInput.Container
                        postfix={!isSettingCustomArrivalName
                            ? <Tooltip content={CUSTOM_ARRIVAL_NAME_TOOLTIP}>
                                <button
                                    onClick={() => onToggleSetCustomArrivalName(true)}
                                    className="postfix"
                                >
                                    Edit
                                </button>
                            </Tooltip>
                            : <Tooltip content={CANCEL_CUSTOM_ARRIVAL_NAME_TOOLTIP}>
                                <button
                                    onClick={() => onToggleSetCustomArrivalName(false)}
                                    className="postfix"
                                >
                                    Cancel
                                </button>
                            </Tooltip>
                        }
                    >
                        {!isSettingCustomArrivalName
                            ? <SelectLocation
                                locations={locations.filter(location => location.id !== values.departureLocation)}
                                value={values.arrivalLocation}
                                onChange={onSelectArrivalLocation}
                            />
                            : <DebouncedInput
                                type="text"
                                value={values.arrivalLocationName  || ''}
                                onChange={value => onChangeValue('arrivalLocationName', value)}
                            />
                        }
                    </PostfixedInput.Container>
                </InputGroup>
            </Cell>
            <Cell>
                <InputGroup messages={errors.arrival}>
                    <label className="required">{ARRIVAL_DATE_LABEL}</label>
                    <DateTimeInput
                        value={values.arrival }
                        dateFormat={dateFormat}
                        timeFormat={timeFormat}
                        onChange={value => onChangeValue('arrival', value)}
                    />
                </InputGroup>
            </Cell>
        </Grid>
        {values.mode === 3 &&
            <Fragment>
                <Grid>
                    <Cell
                        isAutoSized
                        width="50%"
                    >
                        <InputGroup messages={errors.driver}>
                            <label>{DRIVER_LABEL}</label>
                            <PostfixedInput.Container
                                postfix={
                                    <Tooltip content={CREATE_DRIVER_TOOLTIP}>
                                        <button
                                            onClick={onOpenCreateDriverModal}
                                            className="postfix success"
                                        >
                                            Add
                                        </button>
                                    </Tooltip>
                                }
                            >
                                <SelectDriver
                                    clearable
                                    drivers={drivers}
                                    value={values.driver}
                                    onChange={value => onChangeValue('driver', value)}
                                />
                            </PostfixedInput.Container>
                        </InputGroup>
                    </Cell>
                    <Cell>
                        <InputGroup messages={errors.driverDepartureTime}>
                            <label>{DRIVER_DEPARTURE_TIME_LABEL}</label>
                            <select
                                value={values.driverDepartureTime}
                                onChange={e => onChangeValue('driverDepartureTime', e.target.value)}
                            >
                                {DRIVER_DEPARTURE_TIME_OPTIONS.map(option =>
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                )}
                            </select>
                        </InputGroup>
                    </Cell>
                </Grid>
                <Grid className="margin-tiny-bottom">
                    <Cell
                        isAutoSized
                        width="100%"
                    >
                        <InputGroup messages={errors.vehicle}>
                            <label>{VEHICLE_LABEL}</label>
                            <PostfixedInput.Container
                                postfix={
                                    <Tooltip content={CREATE_VEHICLE_TOOLTIP}>
                                        <button
                                            onClick={onOpenCreateVehicleModal}
                                            className="button postfix button-icon success"
                                        >
                                            Add
                                        </button>
                                    </Tooltip>
                                }
                            >
                                <SelectVehicle
                                    clearable
                                    vehicles={vehicles}
                                    value={values.vehicle}
                                    onChange={value => onChangeValue('vehicle', value)}
                                />
                            </PostfixedInput.Container>
                        </InputGroup>
                    </Cell>
                </Grid>
            </Fragment>
        }
        <InputGroup messages={errors.notes}>
            <label>{NOTES_LABEL}</label>
            <DebouncedInput
                component="textarea"
                value={values.notes || ''}
                onChange={value => onChangeValue('notes', value)}
            />
        </InputGroup>
    </div>
);

CreatePickupFields.propTypes = {
    isEditingPerformanceName: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    dayStart: PropTypes.string,
    dayEnd: PropTypes.string,

    days: PropTypes.array,
    stages: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectDay: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
};

export default CreatePickupFields;
