import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import { loadData } from 'app/react/helpers/_index';
import ZonesView from 'app/react/views/event/settings/accreditation/zones/ZonesView';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';

// Backbone Controller
export default class ZoneController extends BaseController {

    overview(eventId) {
        const { app, data, store } = this;
        const event = data.registerCurrentEvent(eventId);
        const zones = data.register('pagedZones', Zone.PageableCollection, { event });

        app.renderContentComponent(React.createElement(ZonesView, { zones, store }));
        loadData({ models: [zones] });
    }

}
