import { schema } from 'normalizr';

export const ENTITY_REDUCER_KEY = 'eventRoutes';

const processStrategy = (entity) => ({
    ...entity,
    duration: typeof entity.duration !== 'undefined' ? entity.duration / 60 : undefined
});

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, {}, { processStrategy });
export const entities = new schema.Array(entity);
