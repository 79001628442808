import { connect } from 'react-redux';
import { trim } from 'common-tags';
import { mapEventContextToProps, mapBookingContextToProps } from 'app/react/components/util/connectBeatswitchContext';
import * as actions from 'app/react/state/actions/_index';
import * as Crew from 'app/react/entities/events/bookings/crew/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as PWArtistSettings from 'app/react/entities/events/production_website/settings/artists/index';
import * as PWArtistSettingsSection from 'app/react/entities/events/production_website/settings/artists/sections/index';
import * as PWArtistSettingsSectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/index';
import View from '../../components/View';
import * as State from './State';
import * as Routes from 'app/routes/production_website/artists/routes';

const FAILED_POPUP =
    'Failed to create crew member.';
const SUCCESSFUL_POPUP = (fullName) =>
    `Crew member <strong>${fullName}</strong> successfully updated.`;

const mapStateToProps = (state) => {
    const {
        values = {},
        errors = {},
        selectedPassport,
        isSaving,
    } = State.getViewState(state);
    const section = PWArtistSettingsSection.stateHelpers.getCurrent(state) || {};
    const formFields = PWArtistSettingsSectionFormField.stateHelpers.getSome(state, section.formFields);
    return {
        ...mapEventContextToProps(state),
        ...mapBookingContextToProps(state),
        isCreating: false,
        isFetching: (
            isSaving ||
            ArtistType.stateHelpers.isFetchingCurrent(state) ||
            PWArtistSettings.stateHelpers.isFetchingCurrent(state) ||
            Crew.stateHelpers.isFetchingCurrent(state)
        ),
        values,
        errors,
        dynamicFields: formFields,
        selectedPassport
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSave: () => {
        const { values } = stateProps;
        const { productionWebsiteUrl } = ownProps;
        dispatchProps.onSave(values, productionWebsiteUrl);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onChangeValue: (key, value) => {
        dispatch(State.setViewStateValue(`values.${key}`, value));
    },
    onChangePassport: (passport) => {
        if (typeof passport === 'undefined') {
            dispatch(State.setViewStateValue('values.passport', null));
            dispatch(State.deleteViewStateValue('selectedPassport'));
        } else {
            dispatch(State.setViewStateValue('values.passport', passport.uuid));
        }
    },
    onSelectContact: (contact) => {
        if (typeof contact !== 'undefined') {
            dispatch(State.mergeViewStateValue('values', {
                ...contact
            }));
        }
    },
    onSave: (values, productionWebsiteUrl) => {
        dispatch((State.setViewStateValue('isSaving', true)));
        const { firstname, lastname } = values;
        const fullName = `${firstname} ${lastname}`.trim();
        dispatch(Crew.thunks.updateCurrent(values))
            .then(({ isFailure, errors }) => {
                if (isFailure) {
                    dispatch(actions.showErrorPopup(FAILED_POPUP));
                    dispatch(State.mergeViewState({
                        errors: errors,
                        isSaving: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP(fullName)));
                    dispatch(actions.navigate.pageReload(Routes.form(productionWebsiteUrl)));
                }
            });
    }
});



export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(View);
