import React from "react";
import * as theme from "styles/theme";

const Error = ({ color }) => (
  <path
    fill={color}
    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
  />
);

const ArrowDropDown = ({ color }) => <path fill={color} d="M7 10l5 5 5-5z" />;

const ArrowDropUp = ({ color }) => <path fill={color} d="M7 14l5-5 5 5z" />;

const Logout = ({ color }) => (
  <path
    fill={color}
    d="M21 3.01H3c-1.1 0-2 .9-2 2V9h2V4.99h18v14.03H3V15H1v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88
        2-1.98v-14c0-1.11-.9-2-2-2zM11 16l4-4-4-4v3H1v2h10v3z"
  />
);

const Event = ({ color }) => (
  <path
    fill={color}
    d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9
        2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
  />
);

const Place = ({ color }) => (
  <path
    fill={color}
    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38
        0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
  />
);

const Time = ({ color }) => (
  <path
    fill={color}
    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12
        20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"
  />
);

const Checklist = ({ color }) => (
  <path
    fill={color}
    d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9
        2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10
        14.17l6.59-6.59L18 9l-8 8z"
  />
);

const Close = ({ color }) => (
  <path
    fill={color}
    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
  />
);

const Lock = ({ color }) => (
  <path
    fill={color}
    d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,
        2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,
        1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"
  />
);

const Link = ({ color }) => (
  <path
    fill={color}
    d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71
        0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76
        0 5-2.24 5-5s-2.24-5-5-5z"
  />
);

const Check = ({ color }) => (
  <path
    fill={color}
    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10
        14.17l7.59-7.59L19 8l-9 9z"
  />
);

const EmptyCheck = ({ color }) => (
  <circle fill={color} cx="12" cy="12" r="12" />
);

const Actions = ({ color }) => (
  <path
    fill={color}
    d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6
        0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
  />
);

const ArrowDown = ({ color }) => (
  <path fill={color} d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
);

const ArrowRight = ({ color }) => (
  <path fill={color} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
);

const ArrowForward = ({ color }) => (
  <path
    fill={color}
    d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
  />
);

const Edit = ({ color }) => (
  <path
    fill={color}
    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02
        0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
  />
);

const Delete = ({ color }) => (
  <path
    fill={color}
    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
  />
);

const Upload = ({ color }) => (
  <path
    fill={color}
    d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31
        2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
  />
);

const File = ({ color }) => (
  <path
    fill={color}
    d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"
  />
);

const Image = ({ color }) => (
  <path
    fill={color}
    d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5
        3.01L14.5 12l4.5 6H5l3.5-4.5z"
  />
);

const Download = ({ color }) => (
  <path fill={color} d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
);

const Passport = ({ color }) => (
  <path
    fill={color}
    d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V4A2,2 0 0,0 18,2H6M12,5A5,5 0 0,1 17,10A5,5 0 0,1
          12,15A5,5 0 0,1 7,10A5,5 0 0,1 12,5M12,6C11.59,6.62 11.25,7.29 11.04,8H12.96C12.75,7.29 12.42,6.62
          12,6M10.7,6.22C9.78,6.53 9,7.17 8.54,8H10C10.18,7.38 10.4,6.78 10.7,6.22M13.29,6.22C13.59,6.78 13.82,7.38
          14,8H15.46C15,7.17 14.21,6.54 13.29,6.22M8.13,9C8.05,9.32 8,9.65 8,10C8,10.35 8.05,10.68
          8.13,11H9.82C9.78,10.67 9.75,10.34 9.75,10C9.75,9.66 9.78,9.33 9.82,9H8.13M10.83,9C10.78,9.32
          10.75,9.66 10.75,10C10.75,10.34 10.78,10.67 10.83,11H13.17C13.21,10.67 13.25,10.34 13.25,10C13.25,9.66
          13.21,9.32 13.17,9H10.83M14.18,9C14.22,9.33 14.25,9.66 14.25,10C14.25,10.34 14.22,10.67
          14.18,11H15.87C15.95,10.68 16,10.35 16,10C16,9.65 15.95,9.32 15.87,9H14.18M8.54,12C9,12.83
          9.78,13.46 10.7,13.78C10.4,13.22 10.18,12.63 10,12H8.54M11.04,12C11.25,12.72 11.59,13.38
          12,14C12.42,13.38 12.75,12.72 12.96,12H11.04M14,12C13.82,12.63 13.59,13.22 13.29,13.78C14.21,13.46 15,12.83
          15.46,12H14M7,17H17V19H7V17Z"
  />
);

const Info = ({ color }) => (
  <path
    fill={color}
    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
  />
);

const Drink = ({ color }) => (
  <path
    fill={color}
    d="M21 5V3H3v2l8 9v5H6v2h12v-2h-5v-5l8-9zM7.43 7L5.66 5h12.69l-1.78 2H7.43z"
  />
);

const Food = ({ color }) => (
  <path
    fill={color}
    d="M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38
        1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12
        14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z"
  />
);

const User = ({ color }) => (
  <path
    fill={color}
    d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8
        4v2h16v-2c0-2.66-5.33-4-8-4z"
  />
);

const AddUser = ({ color }) => (
  <path
    fill={color}
    d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,
        0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
  />
);

const FlightLand = ({ color }) => (
  <path
    fill={color}
    d="M2.5 19h19v2h-19zm7.18-5.73l4.35 1.16 5.31 1.42c.8.21 1.62-.26
        1.84-1.06.21-.8-.26-1.62-1.06-1.84l-5.31-1.42-2.76-9.02L10.12 2v8.28L5.15 8.95l-.93-2.32-1.45-.39v5.17l1.6.43
        5.31 1.43z"
  />
);

const FlightTakeOff = ({ color }) => (
  <path
    fill={color}
    d="M2.5 19h19v2h-19zm19.57-9.36c-.21-.8-1.04-1.28-1.84-1.06L14.92 10l-6.9-6.43-1.93.51 4.14 7.17-4.97
        1.33-1.97-1.54-1.45.39 1.82 3.16.77 1.33 1.6-.43 5.31-1.42 4.35-1.16L21 11.49c.81-.23 1.28-1.05 1.07-1.85z"
  />
);

const Railway = ({ color }) => (
  <path
    fill={color}
    d="M4 15.5C4 17.43 5.57 19 7.5 19L6 20.5v.5h12v-.5L16.5 19c1.93 0 3.5-1.57 3.5-3.5V5c0-3.5-3.58-4-8-4s-8
        .5-8 4v10.5zm8 1.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-7H6V5h12v5z"
  />
);

const Car = ({ color }) => (
  <path
    fill={color}
    d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45
        1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67
        1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5
        11l1.5-4.5h11L19 11H5z"
  />
);

const Bus = ({ color }) => (
  <path
    fill={color}
    d="M4 16c0 .88.39 1.67 1 2.22V20c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45
        1-1v-1.78c.61-.55 1-1.34 1-2.22V6c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5
        14s1.5.67 1.5 1.5S8.33 17 7.5 17zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5
        1.5zm1.5-6H6V6h12v5z"
  />
);

const Boat = ({ color }) => (
  <path
    fill={color}
    d="M20 21c-1.39 0-2.78-.47-4-1.32-2.44 1.71-5.56 1.71-8 0C6.78 20.53 5.39 21 4 21H2v2h2c1.38 0
        2.74-.35 4-.99 2.52 1.29 5.48 1.29 8 0 1.26.65 2.62.99 4 .99h2v-2h-2zM3.95 19H4c1.6 0 3.02-.88 4-2 .98 1.12 2.4
        2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h.05l1.89-6.68c.08-.26.06-.54-.06-.78s-.34-.42-.6-.5L20
        10.62V6c0-1.1-.9-2-2-2h-3V1H9v3H6c-1.1 0-2 .9-2 2v4.62l-1.29.42c-.26.08-.48.26-.6.5s-.15.52-.06.78L3.95 19zM6
        6h12v3.97L12 8 6 9.97V6z"
  />
);

const Helicopter = ({ color }) => (
  <path
    fill={color}
    d="M22.3,17c-0.4,0-0.7,0.3-0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-1.2V17c0.7-0.1,1.4-0.4,1.9-0.9c0.6-0.6,1-1.5,1-2.3
		c0-0.1,0-0.3,0-0.4C22.3,9.8,19.3,7,15.8,7h-0.4V5.5h6.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H7.5c-0.4,0-0.7,0.3-0.7,0.7
		s0.3,0.7,0.7,0.7h6.5V7H2.4V6.3c0-0.4-0.3-0.7-0.7-0.7C1.3,5.5,1,5.9,1,6.3c0,0.5,0,3.9,0,4.3c0,0.4,0.3,0.7,0.7,0.7
		s0.7-0.3,0.7-0.7V10l6.6,1.7c0,0,0,0,0,0c0.3,0.1,0.5,0.4,0.5,0.7c0,1.9,1.2,3.6,2.9,4.3v1.8h-2.2c-0.4,0-0.7,0.3-0.7,0.7
		c0,0.4,0.3,0.7,0.7,0.7h10.5c1.2,0,2.2-1,2.2-2.2C23,17.3,22.7,17,22.3,17z M15.4,8.4h0.4c2.6,0,4.7,1.9,5.2,4.3h-4.9
		c-0.4,0-0.7-0.3-0.7-0.7L15.4,8.4L15.4,8.4z M18.2,18.5h-4.3V17c0.1,0,0.2,0,0.3,0h4V18.5zz"
  />
);

const Walk = ({ color }) => (
  <path
    fill={color}
    d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9.8 8.9L7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2
        .6-3C14.8 12 16.8 13 19 13v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1L6 8.3V13h2V9.6l1.8-.7"
  />
);

const TransferIn = ({ color }) => (
  <path
    fill={color}
    d="M3,8H5V16H3V8M7,8H9V16H7V8M11,8H13V16H11V8M15,19.25V4.75L22.25,12L15,19.25Z"
  />
);

const TransferOut = ({ color }) => (
  <path
    fill={color}
    d="M22.2,16h-2V8h2V16 M18.2,16h-2V8h2V16 M14.2,16h-2V8h2V16 M10.2,4.8v14.5L3,12L10.2,4.8z"
  />
);

const RequiredFiles = ({ color }) => (
  <path
    fill={color}
    d="M5.6,2.4 C4.5,2.4 3.61,3.3 3.61,4.4 L3.6,20.4 C3.6,21.5 4.49,22.4 5.59,22.4 L17.6,22.4 C18.7,22.4 19.6,21.5
        19.6,20.4 L19.6,8.4 L13.6,2.4 L5.6,2.4 L5.6,2.4 Z M12.6,9.4 L12.6,3.9 L18.1,9.4 L12.6,9.4 Z
        M10.9019531,14.7703125 L8.4,14.0484375 L8.85117188,12.6621094 L11.3285156,13.621875 L11.2054688,10.8
        L12.6738281,10.8 L12.5507812,13.6710938 L14.9871094,12.7277344 L15.4300781,14.1386719 L12.8871094,14.8605469
        L14.5277344,17.0425781 L13.3382812,17.9121094 L11.8617187,15.5496094 L10.4015625,17.8382812
        L9.20390625,17.0015625 L10.9019531,14.7703125 Z"
  />
);

const Contacts = ({ color }) => (
  <path
    fill={color}
    d="M20 0H4v2h16V0zM4 24h16v-2H4v2zM20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9
        2-2V6c0-1.1-.9-2-2-2zm-8 2.75c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25S9.75 10.24 9.75 9 10.76 6.75 12
        6.75zM17 17H7v-1.5c0-1.67 3.33-2.5 5-2.5s5 .83 5 2.5V17z"
  />
);

const Performers = ({ color }) => (
  <path
    fill={color}
    d="M11,14C12,14 13.05,14.16 14.2,14.44C13.39,15.31 13,16.33 13,17.5C13,18.39 13.25,19.23 13.78,20H3V18C3,16.81
        3.91,15.85 5.74,15.12C7.57,14.38 9.33,14 11,14M11,12C9.92,12 9,11.61 8.18,10.83C7.38,10.05 7,9.11 7,8C7,6.92
        7.38,6 8.18,5.18C9,4.38 9.92,4 11,4C12.11,4 13.05,4.38 13.83,5.18C14.61,6 15,6.92 15,8C15,9.11 14.61,10.05
        13.83,10.83C13.05,11.61 12.11,12 11,12M18.5,10H20L22,10V12H20V17.5A2.5,2.5 0 0,1 17.5,20A2.5,2.5 0 0,1
        15,17.5A2.5,2.5 0 0,1 17.5,15C17.86,15 18.19,15.07 18.5,15.21V10Z"
  />
);

const Crew = ({ color }) => (
  <path
    fill={color}
    d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66
        5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29
        0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
  />
);

const Riders = ({ color }) => (
  <path
    fill={color}
    d="M13.575,10.425 L18.9375,10.425 L13.575,5.0625 L13.575,10.425 Z M6.75,3.6 L14.55,3.6 L20.4,9.45
        L20.4,21.15 C20.4,22.2269553 19.5269553,23.1 18.45,23.1 L6.75,23.1 C5.66775,23.1 4.8,22.2225 4.8,21.15 L4.8,5.55
        C4.8,4.46775 5.66775,3.6 6.75,3.6 Z M15.525,19.2 L15.525,17.25 L6.75,17.25 L6.75,19.2 L15.525,19.2 Z M18.45,15.3
        L18.45,13.35 L6.75,13.35 L6.75,15.3 L18.45,15.3 Z"
  />
);

const Parking = ({ color }) => (
  <path
    fill={color}
    d="M13 3H6v18h4v-6h3c3.31 0 6-2.69 6-6s-2.69-6-6-6zm.2 8H10V7h3.2c1.1 0 2 .9 2 2s-.9 2-2 2z"
  />
);

const Accommodations = ({ color }) => (
  <path
    fill={color}
    d="M7 13c1.66 0 3-1.34 3-3S8.66 7 7 7s-3 1.34-3 3 1.34 3 3
        3zm12-6h-8v7H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z"
  />
);

const GuestList = ({ color }) => (
  <path
    fill={color}
    d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9
        2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3
        3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"
  />
);

const Tokens = ({ color }) => (
  <path
    fill={color}
    d="M20 12c0-1.1.9-2 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-1.99.9-1.99 2v4c1.1 0 1.99.9 1.99 2s-.89 2-2 2v4c0 1.1.9 2 2
        2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2zm-4.42 4.8L12 14.5l-3.58 2.3 1.08-4.12-3.29-2.69 4.24-.25L12 5.8l1.54
        3.95 4.24.25-3.29 2.69 1.09 4.11z"
  />
);

const Pickups = ({ color }) => (
  <path
    fill={color}
    d="M17 5H3c-1.1 0-2 .89-2 2v9h2c0 1.65 1.34 3 3 3s3-1.35 3-3h5.5c0 1.65 1.34 3 3 3s3-1.35 3-3H23v-5l-6-6zM3
        11V7h4v4H3zm3 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7-6.5H9V7h4v4zm4.5
        6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM15 11V7h1l4 4h-5z"
  />
);

const Catering = ({ color }) => (
  <path
    fill={color}
    d="M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38
        1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12
        14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z"
  />
);

const Hidden = ({ color }) => (
  <path
    fill={color}
    d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89
        3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2
        4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21
        20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55
        1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15
        3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
  />
);
const LightBulb = ({ color }) => (
  <path
    fill={color}
    d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"
  />
);

const DragAndDrop = ({ color }) => (
  <path
    fill={color}
    fillRule="evenodd"
    d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 
        1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 
        1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 
        0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 
        2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"
  />
);

const Ellipse = ({ color }) => (
  <circle cx="7" cy="7" r="6" stroke={color} fill="none" strokeWidth="2" />
);

const Plus = ({ color }) => (
  <path
    fill={color}
    d="M2.533 1.192H1.808V0.467a0.054 0.054 0 0 0 -0.054 -0.054H1.246a0.054 0.054 0 0 0 -0.054 0.054l0 0.725 -0.725 0c-0.029 0 -0.054 0.026 -0.054 0.054V1.754a0.054 0.054 0 0 0 0.054 0.054h0.725v0.725a0.054 0.054 0 0 0 0.054 0.054H1.754a0.054 0.054 0 0 0 0.054 -0.054V1.808l0.725 0a0.054 0.054 0 0 0 0.054 -0.054l0 -0.508a0.054 0.054 0 0 0 -0.054 -0.054z"
  />
);

const Icon = ({ name, color = theme.colorUI, size = "24", style, viewbox }) => (
  <svg
    width={size}
    height={size}
    viewBox={viewbox || "0 0 24 24"}
    style={style}
  >
    {(() => {
      switch (name) {
        case "error":
          return <Error color={color} />;
        case "arrowDropdown":
          return <ArrowDropDown color={color} />;
        case "arrowDropup":
          return <ArrowDropUp color={color} />;
        case "logout":
          return <Logout color={color} />;
        case "event":
          return <Event color={color} />;
        case "place":
          return <Place color={color} />;
        case "time":
          return <Time color={color} />;
        case "dragAndDrop":
          return <DragAndDrop color={color} />;
        case "checklist":
          return <Checklist color={color} />;
        case "close":
          return <Close color={color} />;
        case "lock":
          return <Lock color={color} />;
        case "link":
          return <Link color={color} />;
        case "check":
          return <Check color={color} />;
        case "emptyCheck":
          return <EmptyCheck color={color} />;
        case "actions":
          return <Actions color={color} />;
        case "arrowDown":
          return <ArrowDown color={color} />;
        case "arrowRight":
          return <ArrowRight color={color} />;
        case "arrowForward":
          return <ArrowForward color={color} />;
        case "edit":
          return <Edit color={color} />;
        case "delete":
          return <Delete color={color} />;
        case "upload":
          return <Upload color={color} />;
        case "file":
          return <File color={color} />;
        case "image":
          return <Image color={color} />;
        case "download":
          return <Download color={color} />;
        case "passport":
          return <Passport color={color} />;
        case "info":
          return <Info color={color} />;
        case "drink":
          return <Drink color={color} />;
        case "food":
          return <Food color={color} />;
        case "user":
          return <User color={color} />;
        case "addUser":
          return <AddUser color={color} />;
        case "flightLand":
          return <FlightLand color={color} />;
        case "flightTakeOff":
          return <FlightTakeOff color={color} />;
        case "railway":
          return <Railway color={color} />;
        case "car":
          return <Car color={color} />;
        case "bus":
          return <Bus color={color} />;
        case "boat":
          return <Boat color={color} />;
        case "helicopter":
          return <Helicopter color={color} />;
        case "walk":
          return <Walk color={color} />;
        case "transferIn":
          return <TransferIn color={color} />;
        case "transferOut":
          return <TransferOut color={color} />;
        case "requiredFiles":
          return <RequiredFiles color={color} />;
        case "contacts":
          return <Contacts color={color} />;
        case "performers":
          return <Performers color={color} />;
        case "crew":
          return <Crew color={color} />;
        case "riders":
          return <Riders color={color} />;
        case "parking":
          return <Parking color={color} />;
        case "accommodations":
          return <Accommodations color={color} />;
        case "guestList":
          return <GuestList color={color} />;
        case "tokens":
          return <Tokens color={color} />;
        case "pickups":
          return <Pickups color={color} />;
        case "catering":
          return <Catering color={color} />;
        case "hidden":
          return <Hidden color={color} />;
        case "lightbulb":
          return <LightBulb color={color} />;
        case "ellipse":
          return <Ellipse color={color} />;
          case 'plus' :
              return <Plus color={color} />
        default:
          return null;
      }
    })()}
  </svg>
);

export default Icon;
