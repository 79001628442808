import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import { loadData } from 'app/react/helpers/_index';
import PeriodsView from 'app/react/views/event/settings/accreditation/periods/PeriodsView';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';

// Backbone Controller
export default class PeriodController extends BaseController {

    overview(eventId) {
        const { app, data, store } = this;
        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const periods = data.register('pagedPeriods', Period.PageableCollection, { event });

        app.renderContentComponent(React.createElement(PeriodsView, { periods, store }));
        loadData({ models: [account, event, periods] });
    }

}
