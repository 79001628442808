import BaseView from 'app/backbone/components/Base.view';
import Datepicker from 'app/backbone/components/inputs/Datepicker.view';

export default class EventSettingsView extends BaseView {

    ui() {
        return {
            startDatePicker: '[name=start_date]',
            endDatePicker: '[name=end_date]'
        };
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.renderStartDatePicker();
        this.renderEndDatePicker();
        return this.delegateEvents();
    }

    renderStartDatePicker() {
        this.startDatePicker = new Datepicker({ el: this.ui.startDatePicker });
        this.startDatePicker.render();
    }

    renderEndDatePicker() {
        this.endDatePicker = new Datepicker({ el: this.ui.endDatePicker });
        this.endDatePicker.render();
    }

}
