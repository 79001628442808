import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as ArtistType from 'app/react/entities/events/artist_types/helpers';
import { fetchOne, updateOne } from './actions';
import { getCurrentId } from './helpers';

export const fetchCurrent = () => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const artistTypeId = ArtistType.getCurrentId(state);
    const artistSettingsId = getCurrentId(state);
    return dispatch(fetchOne(accountId, eventId, artistTypeId, artistSettingsId));
};

export const updateCurrent = (values) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const artistTypeId = ArtistType.getCurrentId(state);
    const artistSettingsId = getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, artistTypeId, artistSettingsId, values));
};
