import React from 'react'
import classNames from 'classnames'
import { Card } from 'ui-kit'
import { Tooltip, DynamicForm } from 'app/react/components/_index'
import FormFieldItem from './FormFieldItem'
import './FormBuilderView.css'

const { Header, Content, Footer } = Card;
const { Title, Action } = Header;

export const TITLE = 'Form builder';
export const CONTENT_MIN_HEIGHT = '120px';
export const SHOW_HIDE_TOOLTIP = 'Show/hide default fields';

export const FormBuilderCard = ({
    isDragging,
    isStaticFormFieldsShown = true,
    staticFormFields = [],
    formFields = [],
    dragula,
    dragulaContainerId,
    toggleShowStaticFormFields = () => {},
    selectFormField = () => {},
    removeFormField = () => {},
}) => (
    <Card>
        <Header>
            <Title>{TITLE}</Title>
            <Tooltip content={SHOW_HIDE_TOOLTIP}>
                <Action onClick={toggleShowStaticFormFields}>
                    {isStaticFormFieldsShown 
                        ? <i className="bs-icon-arrow-thin-up" />
                        : <i className="bs-icon-arrow-thin-down" />
                    }
                </Action>
            </Tooltip>
        </Header>
        <Content>
            {isStaticFormFieldsShown &&
                <fieldset>
                    <legend>Default fields</legend>
                    <DynamicForm
                        fields={staticFormFields}
                        width="100%"
                    />
                </fieldset>
            }
            <fieldset>
                <legend>New fields</legend>
                <ul
                    id={dragulaContainerId}
                    className={classNames("form-builder-list", { 
                        'drop-placeholder--highlight': isDragging,
                        'drop-placeholder--empty': !formFields.length
                    })}
                    style={{ minHeight: CONTENT_MIN_HEIGHT }}
                    ref={node => node && dragula.containers.push(node)}
                >
                    {formFields.map(({ id, type, required, label, placeholder, selected }) => (
                        <FormFieldItem
                            key={id}
                            id={id}
                            type={type}
                            required={required}
                            label={label}
                            placeholder={placeholder}
                            selected={selected}
                            selectFormField={selectFormField}
                            removeFormField={removeFormField}
                        />
                    ))}
                </ul>
            </fieldset>
        </Content>
        <Footer />
    </Card>
);

export default FormBuilderCard;
