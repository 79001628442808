import React, { useState, Fragment, useEffect, useRef } from "react";
import classNames from "classnames";
import dragula from "dragula";
import TextEditor from "app/react/components/inputs/TextEditor";
import { Field, FieldArray } from "formik";
import FormField from "../../../formfields/FormField";
import DisableSection from "./components/DisableSection";
import DatePickerField from "../../../datePicker/DatePickerField";
import Icon from "../../../../../utils/Icon";
import Checkbox from "../../../checkbox/Checkbox";

const CrewSection = ({
  setFieldValue,
  sectionId,
  values,
  profileTypes,
  ticketTypes,
}) => {
  const [activeTab, setActiveTab] = useState("configure");

  const [formFields, _setFormFields] = useState(values["form-fields"] || []);
  const formFieldsRef = useRef(formFields);
  useEffect(() => {
    if (values["form-fields"]) {
      setFormFields(values["form-fields"]);
    }
  }, [values]);

  const setFormFields = (data) => {
    formFieldsRef.current = data;
    _setFormFields(data);
  };

  const drake = dragula([]);
  useEffect(() => {
    if (
      activeTab === "example" &&
      document.getElementById("artist-dialog__form-field-table") &&
      drake.containers.length === 0
    ) {
      const table = document.getElementById("artist-dialog__form-field-table");
      drake.containers.push(table);
    }
  }, [activeTab]);

  drake.on("drop", () => {
    const mappedElements = [
      ...document.getElementsByClassName("form-field-label"),
    ].map((el, i) => {
      return { label: el.children[0].value, sequence: i };
    });
    const mappedValues = formFieldsRef.current.map((field, i) => {
      const el = mappedElements.find((el) => el.label === field.label);
      return {
        ...field,
        sequence: el.sequence,
      };
    });

    setFieldValue(`${sectionId}.form-fields`, mappedValues);
  });

  return (
    <div className="artist-dialog__section">
      <div className="artist-dialog__section-inner">
        <h3>Crew</h3>
        <div className="artist-dialog__configure-setup-button__container">
          <button
            onClick={() => setActiveTab("configure")}
            className={classNames([
              "artist-dialog__configure-setup-button",
              activeTab === "configure" &&
                "artist-dialog__configure-setup-button--active",
            ])}
          >
            Configuration
          </button>
          <button
            onClick={() => setActiveTab("example")}
            className={classNames([
              "artist-dialog__configure-setup-button",
              activeTab === "example" &&
                "artist-dialog__configure-setup-button--active",
            ])}
          >
            Form example
          </button>
        </div>
        <div className="artist-dialog__section-inner__content">
          {activeTab === "configure" && (
            <Fragment>
              <DisableSection sectionId={sectionId} values={values} />
              <Field
                name={`${sectionId}.name`}
                label="Rename"
                as={FormField}
                disabled={!values.enabled}
              />
              <DatePickerField
                disabled={!values.enabled}
                name={`${sectionId}.deadline`}
                value={values.deadline}
                onChange={setFieldValue}
                showPopperArrow={false}
                label="Deadline"
              />
              {values.enabled && (
                <Field name={`${sectionId}.description`} label="Intro text">
                  {({ field }) => {
                    return (
                      <Fragment>
                        <span className={"form-field__label"}>Intro text</span>
                        <TextEditor
                          imageInsertButtonsOverride={[
                            "imageBack",
                            "|",
                            "imageByURL",
                          ]}
                          height={200}
                          onBlur={(e) =>
                            setFieldValue(
                              `${sectionId}.description`,
                              e.target.value
                            )
                          }
                          id={"introText"}
                          value={field.value}
                        />
                      </Fragment>
                    );
                  }}
                </Field>
              )}
              {!values.enabled && (
                <Field name={`${sectionId}.description`} label="Intro text">
                  {({ field }) => {
                    return (
                      <Fragment>
                        <span className={"form-field__label"}>Intro text</span>
                        <div style={{ position: "relative", height: 295 }}>
                          <div className="artist-dialog__text-editor--disabled"></div>
                          <TextEditor
                            imageInsertButtonsOverride={[
                              "imageBack",
                              "|",
                              "imageByURL",
                            ]}
                            height={200}
                            id={"introText"}
                            value={field.value}
                          />
                        </div>
                      </Fragment>
                    );
                  }}
                </Field>
              )}
              <h5 className="artist-dialog__subheader">DEFAULT TICKET TYPE</h5>
              <label>
                <span className={"form-field__label"}>Ticket type</span>
                <Field
                  as="select"
                  name={`${sectionId}.ticket_type`}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select ticket type
                  </option>
                  {ticketTypes.map((ticketType) => (
                    <option key={ticketType.id} value={ticketType.id}>
                      {ticketType.name}
                    </option>
                  ))}
                </Field>
              </label>
              <h5 className="artist-dialog__subheader">LIMITS</h5>
              <label>
                <span className={"form-field__label"}>Max. crew</span>
                <Field name={`${sectionId}.limit`} type="number" />
              </label>
              <Field
                name={`${sectionId}.update_bookings`}
                id={`${sectionId}-update_bookings`}
                as={Checkbox}
                label="Update limits for existing bookings"
              />
              <h5 className="artist-dialog__subheader">OPTIONAL FIELDS</h5>
              <Field
                name={`${sectionId}.passports_enabled`}
                id={`${sectionId}-passports_enabled`}
                as={Checkbox}
                label="Enable to upload passports"
              />
              <Field
                name={`${sectionId}.passports_required`}
                id={`${sectionId}-passports_required`}
                as={Checkbox}
                label="Require passports on the production website"
              />
              <h5 className="artist-dialog__subheader">Accreditation</h5>
              <label>
                <span className={"form-field__label"}>
                  Accreditation profile
                </span>
                <Field
                  as="select"
                  name={`${sectionId}.accreditation_profile`}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select accreditation type
                  </option>
                  {profileTypes.map((profile) => (
                    <option key={profile.id} value={profile.id}>
                      {profile.name}
                    </option>
                  ))}
                </Field>
              </label>
            </Fragment>
          )}
          {activeTab === "example" && (
            <FieldArray name={`${sectionId}.form-fields`}>
              {({ remove, push, ...rest }) => {
                return (
                  <Fragment>
                    <div className="artist-dialog__example">
                      <div className="artist-dialog__example-container__row">
                        <label>Label</label>
                        <div className="artist-dialog__new-row-inner-right-title">
                          <label>Placeholder text</label>
                          <label>Required</label>
                        </div>
                      </div>
                      <div className="artist-dialog__example-container">
                        <div className="artist-dialog__example-container__row">
                          <Field
                            as={FormField}
                            modNoMargin={true}
                            value="First name"
                          />

                          <div className="artist-dialog__new-row-inner-right">
                            <Field as={FormField} modNoMargin={true} value="" />
                            <Field
                              as={Checkbox}
                              checked={true}
                              id={"firstName"}
                              disabled
                              modNoMargin={true}
                            />
                          </div>
                        </div>
                        <div className="artist-dialog__example-container__row">
                          <Field
                            as={FormField}
                            modNoMargin={true}
                            value="Last name"
                          />
                          <div className="artist-dialog__new-row-inner-right">
                            <Field as={FormField} modNoMargin={true} value="" />
                            <Field
                              as={Checkbox}
                              checked={true}
                              id={"lastName"}
                              disabled
                              modNoMargin={true}
                            />
                          </div>
                        </div>
                        <div className="artist-dialog__example-container__row">
                          <Field
                            as={FormField}
                            modNoMargin={true}
                            value="Role"
                          />
                          <div className="artist-dialog__new-row-inner-right">
                            <Field as={FormField} modNoMargin={true} value="" />
                            <Field
                              as={Checkbox}
                              checked={true}
                              id={"role"}
                              disabled
                              modNoMargin={true}
                            />
                          </div>
                        </div>
                        <div className="artist-dialog__example-container__row">
                          <Field
                            as={FormField}
                            modNoMargin={true}
                            value="Email address"
                          />
                          <div className="artist-dialog__new-row-inner-right">
                            <Field as={FormField} modNoMargin={true} value="" />
                            <Field
                              as={Checkbox}
                              checked={true}
                              id={"email"}
                              disabled
                              modNoMargin={true}
                            />
                          </div>
                        </div>
                        <div className="artist-dialog__example-container__row">
                          <Field
                            as={FormField}
                            modNoMargin={true}
                            value="Crew"
                          />
                          <div className="artist-dialog__new-row-inner-right">
                            <Field as={FormField} modNoMargin={true} value="" />
                            <Field
                              as={Checkbox}
                              checked={true}
                              id={"crew"}
                              disabled
                              modNoMargin={true}
                            />
                          </div>
                        </div>
                      </div>
                      <label>Custom fields</label>
                      <div
                        id="artist-dialog__form-field-table"
                        className="artist-dialog__example-container"
                      >
                        {values["form-fields"] &&
                          values["form-fields"].map((field, index) => (
                            <div
                              className="artist-dialog__example-container__new-field-row"
                              key={index}
                            >
                              <div className="artist-dialog__new-row-inner-left">
                                <button className="form__table__button">
                                  <Icon
                                    size={26}
                                    name="dragAndDrop"
                                    style={{ marginBottom: "-10px" }}
                                  />
                                </button>
                                <button
                                  className="form__table__button"
                                  onClick={() => remove(index)}
                                >
                                  <Icon size={26} name="delete" />
                                </button>
                                <Field
                                  as={FormField}
                                  modNoMargin={true}
                                  name={`${sectionId}.form-fields.${index}.label`}
                                  extraClasses={["form-field-label"]}
                                />
                              </div>
                              <div className="artist-dialog__new-row-inner-right">
                                {field.type === "text" ? (
                                  <Field
                                    as={FormField}
                                    modNoMargin={true}
                                    name={`${sectionId}.form-fields.${index}.placeholder`}
                                  />
                                ) : (
                                  <div></div>
                                )}
                                <Field
                                  as={Checkbox}
                                  id={`required-${index}`}
                                  name={`${sectionId}.form-fields.${index}.required`}
                                  modNoMargin={true}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="artist-dialog__add-new-button__container">
                        <button
                          className="artist-dialog__add-new-form-field-button"
                          type="button"
                          onClick={() =>
                            push({
                              label: `New text field - ${
                                values["form-fields"]
                                  ? values["form-fields"].length
                                  : 0
                              }`,
                              placeholder: "",
                              type: "text",
                              required: false,
                              sequence: values["form-fields"]
                                ? values["form-fields"].length
                                : 0,
                            })
                          }
                        >
                          + Add new text field
                        </button>
                        <button
                          className="artist-dialog__add-new-form-field-button"
                          type="button"
                          onClick={() =>
                            push({
                              label: `New date field - ${
                                values["form-fields"]
                                  ? values["form-fields"].length
                                  : 0
                              }`,
                              placeholder: "",
                              type: "date",
                              required: false,
                              sequence: values["form-fields"]
                                ? values["form-fields"].length
                                : 0,
                            })
                          }
                        >
                          + Add new date field
                        </button>
                      </div>
                    </div>
                  </Fragment>
                );
              }}
            </FieldArray>
          )}
        </div>
      </div>
    </div>
  );
};

CrewSection.propTypes = {};

export default CrewSection;
