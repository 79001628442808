import { Model, Collection }  from 'app/backbone/models/decorators';
import * as User from 'app/backbone/models/account/User';
import * as Company from 'app/backbone/models/account/Company';
import * as Event from '../../Event';
import * as EventItemGroup from '../../settings/accreditation/EventItemGroup';
import * as ProductionWebsiteSettings from '../AccreditationProductionWebsiteSettings';
import * as CompanyProfile from './CompanyProfile';
import * as CompanyItem from './CompanyItem';
import * as CompanyAccreditee from './CompanyAccreditee';
import * as RequestStatus from './RequestStatus';

@Model({
    key: 'event_company'
})
class EventCompany extends Company.Model {

    static Relations() {
        return {
            primary_contact: Company.Model.Relations().primary_contact,
            event: Event.Model,
            profiles: CompanyProfile.Collection,
            items: CompanyItem.Collection,
            accreditees: CompanyAccreditee.Collection,
            production_website_settings: ProductionWebsiteSettings.Model,
            request_status: RequestStatus.Model,
            responsible_user: User.Model
        };
    }

    get defaults() {
        return {
            total_available: 0,
            total_max: 0,
            total_used: 0
        }
    }

    get validation() {
        return {
            name: super.validation.name,
            profiles: 'validateProfiles',
            profileItems: 'validateProfileItems',
            items: 'validateItems'
        };
    }

    initialize(model, options = {}) {
        super.initialize(model, options);

        // Listeners to set the total available and max profiles
        this.listenTo(this.available_days, 'reset change:available', this.resetTotalAvailable);
        this.listenTo(this.available_days, 'reset change:max', this.resetTotalMax);
        this.listenTo(this.available_days, 'reset change:used', this.resetTotalUsed);
        this.resetTotalAvailable();
        this.resetTotalMax();
        this.resetTotalUsed();
    }

    urlRoot() {
        this.validateRelations('event');
        let eventId = this.event.id;
        return `/api/events/${eventId}/companies`;
    }

    toJSON(options) {
        let json = super.toJSON(options);
        if(this.hasRelation('profiles')) json.profiles = this.profiles.toJSON();
        if(this.hasRelation('items')) json.items = this.items.toJSON();
        if(this.hasRelation('request_status')) json.request_status = this.request_status.toJSON();
        if(this.hasRelation('responsible_user')) json.responsible_user = this.responsible_user.toJSON();

        return json;
    }

    validateProfiles() {
        if(this.getRelation('profiles').isEmpty()) return 'Select at least one profile.';
        if(this.getRelation('profiles').someHaveNoAvailableDay()) return 'Select at least one day per added profile.';
    }

    validateProfileItems() {
        if(this.getRelation('profiles').someHaveItemsWithNoAvailableDay()) {
            return `Select at least one day per added item to a profile.`;
        }
    }

    validateItems() {
        if(this.getRelation('items').someHaveNoAvailableDay()) return 'Select at least one day per added items.';
    }

    resetTotalAvailable() {
        this.set('total_available', this.getRelation('profiles').getTotalAvailable() )
    }

    resetTotalMax() {
        this.set('total_max', this.getRelation('profiles').getTotalMax() )
    }

    resetTotalUsed() {
        this.set('total_used', this.getRelation('profiles').getTotalUsed() )
    }

    isCreated() {
        return this.getRelation('request_status').get('id') == 1;
    }

    isLoginSent() {
        return this.getRelation('request_status').get('id') == 2;
    }

    isAwaitingApproval() {
        return this.getRelation('request_status').get('id') == 3;
    }

    isApproved() {
        return this.getRelation('request_status').get('id') == 4;
    }

    isDenied() {
        return this.getRelation('request_status').get('id') == 5;
    }

    areTicketsSent() {
        return this.getRelation('request_status').get('id') == 6;
    }

    canApprove() {
        return this.hasPermission('approve');
    }

    canDeny() {
        return this.hasPermission('deny');
    }

    canRequestApproval() {
        return this.hasPermission('request_approval');
    }

    canSendTickets() {
        return this.hasPermission('send_tickets');
    }

    hasPermission(permission) {
        return !! this.get('permissions').data[permission];
    }

    // Changes Request Status
    sendLogin() {
        const url = this.url();
        return $.post(`${url}/send-login`);
    }

    requestApproval() {
        const url = this.url();
        return $.post(`${url}/request-approval`);
    }

    approve() {
        const url = this.url();
        return $.post(`${url}/approve`);
    }

    deny(reason) {
        const url = this.url();
        return $.post(`${url}/deny`, { reason });
    }

    sendTickets() {
        const url = this.url();
        return $.post(`${url}/send-tickets`);
    }

    getItemGroups() {
        return new EventItemGroup.Collection(this.items.map(item => item.item_group));
    }

    getCompanyItemsByItemGroup(itemGroup) {
        return new CompanyItem.Collection(this.items.where({ item_group: itemGroup.id }), { company: this });
    }
}

@Collection({
    key: 'event_companies',
    model: EventCompany
})
class EventCompanyCollection extends Company.Collection {

    static Relations() {
        return {
            event: require('../../Event').Model // Circular
        }
    }

    url() {
        this.validateRelations('event');
        let event = this.event.id;
        return `/api/events/${event}/companies`;
    }
}

const PageableCollection = EventCompanyCollection.prototype.PageableCollection();

export {
    EventCompany as Model,
    EventCompanyCollection as Collection,
    PageableCollection
};
