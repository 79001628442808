import React, { Fragment } from 'react'
import { HeroModal as UIKitHeroModal } from 'ui-kit/lib/components/modal/HeroModal'
import { Provider as ReduxProvider } from 'react-redux'
import { reduxStore } from 'app/react/state/_index'
import withState from './withState'

const Wrapper = ({ isReduxProvider, children }) => {
    return isReduxProvider
        ? <ReduxProvider store={reduxStore}>{children}</ReduxProvider>
        : <Fragment>{children}</Fragment>
};

const HeroModal = (props) => {
    const {
        isReduxProvider,
        children,
    } = props;

    return (
        <Wrapper isReduxProvider={isReduxProvider}>
            <UIKitHeroModal {...props}>
                {children}
            </UIKitHeroModal>
        </Wrapper>
    );
};

HeroModal.defaultProps = {
    isReduxProvider: false,
};
HeroModal.PanelList = UIKitHeroModal.PanelList;
HeroModal.PanelListItem = UIKitHeroModal.PanelListItem;

export default withState(HeroModal);
