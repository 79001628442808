import classNames from "classnames";
import React, { Fragment, useState } from "react";

const FormField = ({
  id,
  value,
  name,
  label,
  type = "text",
  autofocus,
  disabled,
  loading = false,
  required,
  error,
  onChange,
  onBlur,
  placeholder,
  modFullwidth = false,
  modNoMargin = false,
  extraClasses = [],
}) => {
  const formfieldClasses = classNames([
    "form-field",
    modFullwidth ? "form-field--full-width" : null,
    modNoMargin ? "form-field--no-margin" : null,
    ...extraClasses,
  ]);

  const [focused, setFocused] = useState(false);

  return (
    <Fragment>
      <label htmlFor={id} className={formfieldClasses}>
        {label && (
          <span className={"form-field__label"}>
            {label}
            {required ? "*" : null}
          </span>
        )}
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          autoFocus={autofocus}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={(e) => {
            onBlur(e);
            setFocused(false);
          }}
          className={classNames(
            ["form-field__input"],
            loading && "form-field__input-loading"
          )}
          required={required}
          onFocus={() => setFocused(true)}
        />
        {loading && <span className={"form-field__input-loading__highlight"} />}
        {error && <p className={"form-field__error"}>{error}</p>}
      </label>
    </Fragment>
  );
};

export default FormField;
