import React from 'react'
import { Tooltip } from 'app/react/components/_index';

const ADD_TOOLTIP = 'Add subgroup';
const UPDATE_TOOLTIP = 'Update group';
const DELETE_TOOLTIP = 'Delete group';

const GroupRowContent = ({
    group,
    onAdd,
    onUpdate,
    onDelete
}) => (
    <tr className="row-with-border-bottom">
        <td>
            <strong>{group.name}</strong>
        </td>
        <td>
            {typeof group.subGroups !== "undefined" &&
                <Tooltip content={ADD_TOOLTIP}>
                    <a className="action" onClick={() => onAdd(group.id)}>
                        <i className="bs-icon-plus" />
                    </a>
                </Tooltip>
            }
            <Tooltip content={UPDATE_TOOLTIP}>
                <a className="action" onClick={() => onUpdate(group.id)}>
                    <i className="bs-icon-pencil" />
                </a>
            </Tooltip>
            <Tooltip content={DELETE_TOOLTIP}>
                <a className="action" onClick={() => onDelete(group.id)}>
                    <i className="bs-icon-cross" />
                </a>
            </Tooltip>
        </td>
    </tr>
)

export default GroupRowContent
