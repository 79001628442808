import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Company from 'app/backbone/models/account/Company';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as EventUser from 'app/backbone/models/event/EventUser';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import CompanyAccrediteeController from './CompanyAccrediteeController';
import CompanyItemController from './CompanyItemController';
import EventCompanyContentHeader from 'app/react/views/event/accreditation/companies/CompanyContentHeader';
import EventCompanyDetailsContentHeader from 'app/react/views/event/accreditation/companies/CompanyDetailsContentHeader';
import EventCompanyOverview from 'app/backbone/views/event/accreditation/companies/views/EventCompanyOverview.view';
import EventCompanyCreateView from 'app/backbone/views/event/accreditation/companies/views/EventCompanyCreate.view';
import EventCompanyUpdateView from 'app/backbone/views/event/accreditation/companies/views/EventCompanyUpdate.view';
import EventCompanyDetails from 'app/backbone/views/event/accreditation/companies/views/EventCompanyDetails.view';

// Backbone Controller
export default class EventCompanyController extends BaseController {

    constructor(options) {
        super(options);
        // Route definitions
        const baseName = 'events.accreditation.companies';
        const basePath = 'events/:event/accreditation/companies';
        this.router.route(`${basePath}`, `${baseName}`, this.overview.bind(this));
        this.router.route(`${basePath}/create`, `${baseName}.create`, this.create.bind(this));
        this.router.route(`${basePath}/:company/details`, `${baseName}.details`, this.details.bind(this));
        this.router.route(`${basePath}/:company/edit`, `${baseName}.edit`, this.edit.bind(this));
        this.router.route(`${basePath}/:company/attendees/create`, `${baseName}.attendees.create`, this.accrediteeCreate.bind(this));
        this.router.route(`${basePath}/:company/attendees/:attendee/edit`, `${baseName}.attendees.edit`, this.accrediteeEdit.bind(this));
        this.router.route(`${basePath}/:company/item_groups/:group/edit`, `${baseName}.itemGroups.edit`, this.itemsEdit.bind(this));
        // Sub controllers
        this.accrediteeController = new CompanyAccrediteeController(options);
        this.itemController = new CompanyItemController(options);
    }

    renderContentHeader() {
        const { app } = this;
        app.renderContentHeader(React.createElement(EventCompanyContentHeader));
    }

    renderDetailsContentHeader(eventId, companyId, companyName, activeTab) {
        const { app } = this;
        app.renderContentHeader(
            React.createElement(
                EventCompanyDetailsContentHeader,
                {
                    eventId,
                    companyId,
                    companyName,
                    activeTab
                }
            )
        );
    }

    registerCommonData(eventId) {
        const { data } = this;
        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const currentUser = data.registerCurrentUser();
        const eventCompanies = data.register('pagedEventCompanies', EventCompany.PageableCollection, { event });
        const myEventCompanies = data.register('pagedMyEventCompanies', EventCompany.PageableCollection, { event });

        // We need the id of the current user so we fetch from dom
        currentUser.fetchFromDOM();
        eventCompanies.setIncludes(['primary_contact', 'responsible_user', 'permissions'])
            .setObjectId('EventCompanyPageableCollection');
        myEventCompanies.setIncludes(['primary_contact', 'permissions'])
            .setObjectId('MyEventCompanyPageableCollection')
            .setFilters({ responsible_user: currentUser.id });

        return { account, event, currentUser, eventCompanies, myEventCompanies };
    }

    registerCommonFormData(eventId) {
        const { data } = this;
        const { account, event, currentUser, eventCompanies, myEventCompanies } = this.registerCommonData(eventId);
        const users = data.register('allUsers', EventUser.Collection, { event });
        const companies = data.register('allCompanies', Company.Collection, { account });
        const periods = data.register('allPeriods', Period.Collection, { event });
        const profiles = data.register('allProfiles', Profile.Collection, { event });
        const items = data.register('allEventItems', EventItem.Collection, { event });

        companies.setIncludes(['primary_contact'])
            .setFilters({ unused_companies: event.id });
        periods.setIncludes(['days']);
        profiles.setIncludes(['days']);
        items.setIncludes(['groups']);

        return { account, event, currentUser, eventCompanies, myEventCompanies, users, companies, periods, profiles, items };
    }

    overview(eventId) {
        const { app, data } = this;
        app.renderSyncingView();
        this.renderContentHeader();

        const { account, event, eventCompanies, myEventCompanies } = this.registerCommonData(eventId);

        this.when(
            account.fetch(),
            event.fetch(),
            eventCompanies.fetch(),
            myEventCompanies.fetch()
        ).done(() => {
            this.app.renderContentView(
                new EventCompanyOverview({ companies: eventCompanies, myCompanies: myEventCompanies })
            );
        });

    }

    create(eventId) {
        const { app } = this;
        app.renderSyncingView();
        this.renderContentHeader();

        const { account, event, currentUser, eventCompanies, myEventCompanies, users, companies, periods, profiles, items }
            = this.registerCommonFormData(eventId);

        this.when(
            account.fetch(),
            event.fetch(),
            users.fetch(),
            companies.fetch(),
            profiles.fetch(),
            periods.fetch(),
            items.fetch()
        ).done(() => {
            app.renderContentView(
                new EventCompanyCreateView({
                    event,
                    companies,
                    profiles,
                    items,
                    periods,
                    currentUser,
                    users,
                    eventCompanies,
                    myEventCompanies
                })
            );
        });
    }

    edit(eventId, companyId) {
        const { app, data } = this;
        const intEventId = parseInt(eventId, 10);
        const intCompanyId = parseInt(companyId, 10);
        app.renderSyncingView();
        this.renderDetailsContentHeader(intEventId, intCompanyId, null, 'settings');

        const { account, event, currentUser, eventCompanies, myEventCompanies, users, companies, periods, profiles, items }
            = this.registerCommonFormData(eventId);
        const company = data.register('eventCompany', EventCompany.Model, { event });
        company.set('id', intCompanyId);
        company.setIncludes([
            'primary_contact',
            'responsible_user',
            'profiles.days',
            'profiles.available_days',
            'profiles.available_items.available_days',
            'items.groups',
            'items.available_days'
        ]);

        this.when(
            account.fetch(),
            event.fetch(),
            currentUser.fetchFromDOMOrServer(),
            users.fetch(),
            companies.fetch(),
            profiles.fetch(),
            periods.fetch(),
            items.fetch(),
            company.fetch()
        ).done(() => {
            const companyName = company.get('name');
            this.renderDetailsContentHeader(intEventId, intCompanyId, companyName, 'settings');
            app.renderContentView(
                new EventCompanyUpdateView({
                    event,
                    companies,
                    profiles,
                    items,
                    periods,
                    company,
                    eventCompanies,
                    myEventCompanies,
                    currentUser,
                    users
                })
            );
        });

    }

    details(eventId, companyId) {
        const { app, data } = this;
        const intEventId = parseInt(eventId, 10);
        const intCompanyId = parseInt(companyId, 10);
        app.renderSyncingView();
        this.renderDetailsContentHeader(intEventId, intCompanyId, null, 'details');

        const { account, event } = this.registerCommonData(eventId);
        const company = data.register('eventCompany', EventCompany.Model, { event });
        const accreditees = data.register('pagedCompanyAccreditees', CompanyAccreditee.PageableCollection, { event, company });
        const periods = data.register('allPeriods', Period.Collection, { event });

        company.setIncludes([
            'items.groups',
            'items.available_days',
            'items.granted_days',
            'permissions'
        ]);
        company.set('id', intCompanyId);
        accreditees.setIncludes(['profiles', 'items.groups', 'permissions']);
        periods.setIncludes(['days']);

        this.when(
            account.fetch(),
            event.fetch(),
            periods.fetch(),
            company.fetch(),
            accreditees.fetch()
        ).done(() => {
            const companyName = company.get('name');
            this.renderDetailsContentHeader(intEventId, intCompanyId, companyName, 'details');
            app.renderContentView(new EventCompanyDetails({ company, accreditees, periods }));
        });
    }

    accrediteeCreate(eventId, companyId) {
        this.accrediteeController.create(eventId, companyId);
    }

    accrediteeEdit(eventId, companyId, accrediteeId) {
        this.accrediteeController.edit(eventId, companyId, accrediteeId);
    }

    itemsEdit(eventId, companyId, groupId) {
        this.itemController.edit(eventId, companyId, groupId);
    }

}
