import React from 'react'
import PropTypes from 'prop-types'
import { NoRecordsRow } from 'app/react/components/_index'
import HeaderRow from './HeaderRow'
import Row from './Row'
import DetailDrawer from 'app/react/views/event/artists/logistics/pickups/components/drawers/DetailDrawer'

const PickupsTable = ({
    pickups = [],
    sortKey,
    sortOrder,
    dateFormat,
    dateFormatShort,
    timeFormat,
    onSort = () => {},
    onToggleIsArrived = () => {},
    onGoToBooking = () => {},
    onEditPickup = () => {},
    onDeletePickup = () => {},
}) => (
    <table className="overflow actions-right">
        <thead>
            <HeaderRow
                sortKey={sortKey}
                sortOrder={sortOrder}
                onSort={onSort}
            />
        </thead>
        <tbody>
            {pickups.length === 0
                ? <NoRecordsRow colSpan={12} />
                : pickups.map(pickup => (
                    <DetailDrawer
                        key={pickup.id}
                        pickup={pickup}
                        dateFormat={dateFormat}
                        dateFormatShort={dateFormatShort}
                        timeFormat={timeFormat}
                        onClickEdit={(e) => {
                            onEditPickup(pickup.id, e.ctrlKey || e.metaKey);
                            e.stopPropagation();
                        }}
                        onClickDelete={() => onDeletePickup(pickup.id)}
                        renderTrigger={({ onOpenDrawer }) => (
                            <Row
                                id={pickup.id}
                                pickup={pickup}
                                timeFormat={timeFormat}
                                dateFormat={dateFormat}
                                dateFormatShort={dateFormatShort}
                                onClick={onOpenDrawer}
                                onChangeIsArrived={onToggleIsArrived}
                                onClickGoToBooking={onGoToBooking}
                                onClickEdit={onEditPickup}
                                onClickDelete={onDeletePickup}
                            />
                        )}
                    />
                ))
            }
        </tbody>
    </table>
);

PickupsTable.propTypes = {
    reservations: PropTypes.array,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func,
    onChangeCheckIn: PropTypes.func,
    onClickReservation: PropTypes.func,
    onDeleteReservation: PropTypes.func
};

export default PickupsTable;
