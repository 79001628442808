import React from 'react';
import PropTypes from 'prop-types';
import { AlertBox, Card } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { TableLoadingPlaceholder } from 'app/react/components/_index';
import ItemsContentHeader from '../../components/layout/ContentHeader';
import ItemsTable from '../../components/table/Table';
import ItemsTableTopBar from '../../components/table/TopBar';

const { Content } = ContentWrapper;
const { Header } = Card;
const { Title, } = Header;

const CARD_TITLE = 'Overview';
const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';
const NO_GROUPS_PLACEHOLDER_TEXT = 'No items have been assigned to any booking.';

const ItemsOverview = ({
    store,
    userCanMangeArtistItems = false,
    dateFormatShort,
    currencySymbol,

    isFetching = false,
    days = [],
    groups = [],
    items = [],
    itemsPagination = {},

    onDownloadReport = () => {},
    onChangeFilter = () => {},
    onSort = () => {},
}) => {
    const {
        isFetching: isFetchingItems = false,
        totalCount,
        requestedSortKey,
        requestedSortOrder,
        requestedFilters = {},
    } = itemsPagination;
    const hasRecords = totalCount !== 0;
    return (
        <ContentWrapper store={store}>
            <ItemsContentHeader
                onDownloadReport={onDownloadReport}
            />
            <Content isLoading={isFetching}>
                {!userCanMangeArtistItems 
                ? <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox> 
                : <Card>
                        <Header>
                            <Title>{CARD_TITLE}</Title>
                        </Header>
                        <Card.Content
                            isFlexContainer={!hasRecords}
                            style={{ minHeight: '300px' }}
                        >
                            <ItemsTableTopBar
                                dateFormatShort={dateFormatShort}
                                days={days}
                                groups={groups}
                                selectedDayId={requestedFilters.day}
                                selectedGroupId={requestedFilters.group}
                                onChangeFilter={onChangeFilter}
                            />
                            {isFetchingItems
                                ? <TableLoadingPlaceholder />
                                : <ItemsTable
                                    dateFormatShort={dateFormatShort}
                                    currencySymbol={currencySymbol}
                                    items={items}
                                    groups={groups}
                                    days={days}
                                    selectedDayId={requestedFilters.day}
                                    sortKey={requestedSortKey}
                                    sortOrder={requestedSortOrder}
                                    onSort={onSort}
                                />
                            }
                        </Card.Content>
                    </Card>
                }
            </Content>
        </ContentWrapper>
    )
};

ItemsOverview.propTypes = {
    store: PropTypes.object,
    userCanMangeAccommodations: PropTypes.bool,
    timeFormat: PropTypes.string,
    dateFormat: PropTypes.string,
    isFetching: PropTypes.bool,
    reservations: PropTypes.array,
    days: PropTypes.array,
    accommodations: PropTypes.array,
    roomTypes: PropTypes.array,
    reservationsPagination: PropTypes.object,
    onChangePageSize: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeSearchValue: PropTypes.func,
    onSearch: PropTypes.func,
    onClearSearch: PropTypes.func,
    onSort: PropTypes.func,
    onChangePage: PropTypes.func,
    onToggleReservationCheckIn: PropTypes.func,
    onEditReservation: PropTypes.func,
    onOpenDeleteModal: PropTypes.func,
};

export default ItemsOverview;
