var Handlebars = require('/root/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <form>\n        "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"name",{"name":"inArray","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "        "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"item_group",{"name":"inArray","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "        "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"date_dependable",{"name":"inArray","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "        "
    + ((stack1 = (helpers.inArray || (depth0 && depth0.inArray) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.view : depth0)) != null ? stack1.hiddenInputs : stack1),"uses_amounts",{"name":"inArray","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "    </form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"input-group\">\n                <label class=\"required\">Name</label>\n                <input type=\"text\" name=\"name\"/>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"input-group\">\n                <label>Group</label>\n                <select name=\"item_group\"></select>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"input-group\">\n                <label>\n                    <input type=\"checkbox\" name=\"date_dependable\"/>\n                    Is date dependable\n                </label>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"input-group\">\n                <label>\n                    <input type=\"checkbox\" name=\"uses_amounts\"/>\n                    Uses amounts\n                </label>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.SyncModal,depth0,{"name":"SyncModal","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});