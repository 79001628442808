import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'app/react/components/_index';

const UPDATE_TOOLTIP = 'Update item';
const DELETE_TOOLTIP = 'Revoke item';

const ArtistsItemRow = ({
    item = {},
    currency,
    onUpdate = () => {},
    onDelete = () => {},
}) => (
    <tr className="item-row">
        <td colSpan={2} style={{ padding: "0 0 0 30px" }}>
            <table className="overflow no-margin actions-right">
                <tbody>
                    <tr className="row-with-dashed-border-bottom">
                        <td style={{ width: '70px' }}>{item.amount}&nbsp;x</td>
                        <td>{item.name}</td>
                        <td style={{ width: '30px' }}>
                            {item.description &&
                                <Tooltip content={item.description}>
                                    <i className="bs-icon-circle-info" />
                                </Tooltip>
                            }
                        </td>
                        <td>
                            {item.location &&
                                <Fragment>
                                    <i className="bs-icon-marker" style={{ marginRight: "var(--spacingSmaller)" }} />
                                    {item.location}
                                </Fragment>
                            }
                        </td>
                        <td style={{ width: "100px" }}>{item.price !== 0 && `${parseFloat(item.price * item.amount).toFixed(2)} ${currency}`}</td>
                        <td style={{ width: "30px" }}>
                            {item.notes &&
                                <Tooltip content={`Item note: ${item.notes}`}>
                                    <i className="bs-icon-document" />
                                </Tooltip>
                            }
                        </td>
                        <td style={{ width: "100px" }}>
                            <Tooltip content={UPDATE_TOOLTIP}>
                                <a className="action" onClick={() => onUpdate(item.id)}>
                                    <i className="bs-icon-pencil" />
                                </a>
                            </Tooltip>
                            <Tooltip content={DELETE_TOOLTIP}>
                                <a className="action" onClick={() => onDelete(item.id)}>
                                    <i className="bs-icon-cross" />
                                </a>
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
);

ArtistsItemRow.propTypes = {
    item: PropTypes.object,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default ArtistsItemRow;
