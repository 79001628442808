import moment from 'moment';
import by from 'thenby';
import { reformatISOString } from './_index';

const DEFAULT_MARGIN = 4;

const whereISOStringIsShowDay = (date) => (day) => {
    const mDate = moment.utc(date, moment.ISO_8601);
    const mDayStartDate = moment.utc(day.start, moment.ISO_8601);
    mDayStartDate.startOf('day');
    mDate.startOf('day');
    return mDayStartDate.isSame(mDate)
};

export const generateEventDaysFromShowDays = (showDays, dateFormat, margin = DEFAULT_MARGIN, ) => {
    const daysSorted = showDays.sort(by('start'));

    const firstDay = daysSorted[0];
    const lastDay = daysSorted[daysSorted.length-1];
    const hasShowDays =
        typeof firstDay !== 'undefined' &&
        typeof lastDay !== 'undefined';

    const generatedDays = [];
    if (hasShowDays) {
        const mStartDate = moment.utc(firstDay.start, moment.ISO_8601);
        const mEndDate = moment.utc(lastDay.end, moment.ISO_8601);

        mStartDate.subtract(margin, 'days');
        mEndDate.add(margin + 1, 'days');

        const amountOfDaysBetween = mEndDate.diff(mStartDate, 'days');
        const mIteratingStartDate = mStartDate.clone();

        for (let i = 1; i < amountOfDaysBetween + 1; i++) {
            const start = reformatISOString(mIteratingStartDate.startOf('day').toISOString());
            const end = reformatISOString(mIteratingStartDate.endOf('day').toISOString());
            let name = mIteratingStartDate.format(dateFormat);
            if (showDays.find(whereISOStringIsShowDay(mIteratingStartDate.toISOString()))) {
                name = `${name} (Show Day)`
            }
            generatedDays.push({ id: i, name, start, end });

            mIteratingStartDate.add(1, 'days');
        }
    }
    return generatedDays
};
