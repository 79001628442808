import { transformResponseBody } from './util'

export const UPLOAD_URI = '/api/upload';

export const uploadFile = (jwt, file, fileType, schema) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', fileType);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', UPLOAD_URI);
    xhr.setRequestHeader('Authorization', `bearer ${jwt}`);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.send(formData);

    return new Promise((resolve, reject) => {
        xhr.onload = (ee) => {
            const responseJSON = JSON.parse(ee.target.response);
            resolve(transformResponseBody(responseJSON, schema));
        };
        xhr.onerror =  () => {
            reject();
        };
    });
};
