import React from 'react';
import PropTypes from 'prop-types';
import { AlertBox } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import ContentHeader from '../../components/layout/ContentHeader';
import IntegrationCard from '../components/IntegrationCard';
import AuthenticateModal from '../components/AuthenticateModalContainer';

const { Content } = ContentWrapper;

const PERMISSION_DENIED_MESSAGE = 'You do not have permission to view this page.';

const IntegrationsView = ({
    store,
    isFetching = false,
    canManageIntegrations = false,
    availableIntegrations = [],
    activatedIntegrations = [],
    activateIntegration = () => {},
    deactivateIntegration = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader activeTab="integrations" />
        <Content isLoading={isFetching}>
            {canManageIntegrations
                ? <div className="grid grid--gutters">
                    {availableIntegrations.map(({ id, name, logoUrl, categoryName }) => (
                        <div key={id} className="grid-cell grid-cell--autoSize">
                            <IntegrationCard
                                id={id}
                                activated={!!activatedIntegrations.find(({ type }) => type === id)}
                                name={name}
                                logoUrl={logoUrl}
                                categoryName={categoryName}
                                onActivate={activateIntegration}
                                onDeactivate={deactivateIntegration}
                            />
                        </div>
                    ))}
                </div>
                : <AlertBox type={AlertBox.DANGER}>{PERMISSION_DENIED_MESSAGE}</AlertBox>
            }
        </Content>
        <AuthenticateModal />
    </ContentWrapper>
);

IntegrationsView.propTypes = {
    store: PropTypes.object.isRequired,
    // State
    isFetching: PropTypes.bool,
    canManageIntegrations: PropTypes.bool,
    availableIntegrations: PropTypes.array,
    activatedIntegrations: PropTypes.array,
    activateIntegration: PropTypes.func,
    deactivateIntegration: PropTypes.func
};

export default IntegrationsView;
