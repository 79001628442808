import BaseView from 'app/backbone/components/Base.view';
import Tooltip from 'tether-tooltip';
import { DEFAULT_POSITION, DEFAULT_OPEN_DELAY } from './constants';

export default class TooltipView extends BaseView {

    initialize() {
        this.tooltip = this.initTooltip();
    }

    initTooltip() {
        const content = this.$el.data('tooltip-content');
        const target = this.el;
        const position = this.$el.data('tooltip-position') ? this.$el.data('tooltip-position') : DEFAULT_POSITION;
        const openDelay = DEFAULT_OPEN_DELAY;
        return new Tooltip({ target, content, position, openDelay });
    }
}
