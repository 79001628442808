import React, { useState } from 'react'
import RemoveUploadedPassportsModal from './RemoveUploadedPassportsModal'

const RemoveUploadedPassportsModalWithState = ({ onRemovePassports }) => {
    const [passwordVerification, setPasswordVerification] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    async function onConfirm({ closeModal }) {
        setIsLoading(true);

        const { hasErrors, errors } = await onRemovePassports({ passwordVerification });

        if (hasErrors) {
            setErrors(errors);
        } else {
            setPasswordVerification('');
            closeModal();
        }

        setIsLoading(false);
    }

    return (
        <RemoveUploadedPassportsModal
            isLoading={isLoading}
            passwordVerification={passwordVerification}
            setPasswordVerification={setPasswordVerification}
            errors={errors}
            onConfirm={onConfirm}
        />
    );
};

export default RemoveUploadedPassportsModalWithState;
