import merge from 'lodash.merge';
import { normalize } from 'normalizr';
import {
    MERGE_ENTITIES,
} from '../actions/_index';

const normalizeGraphQLData = (data, schemaMapping) => {
    let normalizedData = {};
    Object.keys(data).forEach((key) => {
        const  normalizedKey = normalize(data[key], schemaMapping[key]);
        normalizedData = merge(normalizedData, normalizedKey);
    });
    return normalizedData;
};

// Updates an entity cache in response to any action with response.entities.
export const entities = (state, action) => {
    const { response = {}, doNotMergeResponseWithCache = false } = action;
    const { entities } = response;
    if (typeof entities !== 'undefined' && !doNotMergeResponseWithCache) {
        return merge({}, state, entities);
    }
    if (action.type === MERGE_ENTITIES) {
        const { data, schemaMapping } = action;
        const normalizedData = normalizeGraphQLData(data, schemaMapping);
        const { entities } = normalizedData;
        return merge({}, state, entities);
    }
    return state;
};
