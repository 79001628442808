import React from 'react';
import PropTypes from 'prop-types';
import BookingTableHeaderRow from './HeaderRow';
import BookingTableRow from './Row';
import NoRecordsRow from './NoRecordsRow';
import BookingTableTopBar from './TopBar';

const BookingTable = ({
    bookings = [],
    sortKey,
    sortOrder,
    timeFormat,
    eventPermissions = {},

    onSort = () => {},

    onClickBooking = () => {},
    onClickBookingTransport = () => {},
    onClickBookingPickup = () => {},
    onCreateBookingTransport = () => {},
    onClickBookingParking = () => {},
    onClickBookingReservations = () => {},
    onToggleBookingTask = () => {},
    onSendBookingItinerary = () => {},
    onSendBookingLogin = () => {},
    onDeleteBooking = () => {},
}) => {
    const {
        manageLogistics: canManageTransports,
        manageParkingPasses: canManageParkingPasses,
        manageAccommodations: canManageReservations,
        manageTasks: canManageTask,
        sendEmails: canSendEmails,
        sendProductionWebsiteLogins: canSendLogins,
    } = eventPermissions;
    return (
        <table className="isLarge actions-right overflow">
            <thead>
                <BookingTableHeaderRow
                    canManageTransports={canManageTransports}
                    canManageParkingPasses={canManageParkingPasses}
                    canManageReservations={canManageReservations}
                    canManageTask={canManageTask}
                    canSendLogins={canSendLogins}
                    canSendEmails={canSendEmails}

                    sortKey={sortKey}
                    sortOrder={sortOrder}

                    onSort={onSort}
                />
            </thead>
            <tbody>
                {bookings.length !== 0
                    ? bookings.map(booking => (
                        <BookingTableRow
                            key={booking.id}
                            booking={booking}
                            timeFormat={timeFormat}

                            canManageTransports={canManageTransports}
                            canManageParkingPasses={canManageParkingPasses}
                            canManageReservations={canManageReservations}
                            canManageTask={canManageTask}
                            canSendLogins={canSendLogins}
                            canSendEmails={canSendEmails}

                            onClick={onClickBooking}
                            onClickTransport={onClickBookingTransport}
                            onClickPickup={onClickBookingPickup}
                            onCreateTransport={onCreateBookingTransport}
                            onClickParking={onClickBookingParking}
                            onClickReservations={onClickBookingReservations}
                            onToggleTask={onToggleBookingTask}
                            onSendItinerary={onSendBookingItinerary}
                            onSendLogin={onSendBookingLogin}
                            onDelete={onDeleteBooking}
                        />
                    ))
                    : <NoRecordsRow
                        canManageTransports={canManageTransports}
                        canManageParkingPasses={canManageParkingPasses}
                        canManageReservations={canManageReservations}
                        canManageTask={canManageTask}
                        canSendLogins={canSendLogins}
                        canSendEmails={canSendEmails}
                    />
                }
            </tbody>
        </table>
    )
};

BookingTable.propTypes = {
    bookings: PropTypes.array,
    onEdit: PropTypes.func
};

BookingTable.Row = BookingTableRow;
BookingTable.TopBar = BookingTableTopBar;

export default BookingTable;
