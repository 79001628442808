import BaseView from 'app/backbone/components/Base.view';

export default class SubMenu extends BaseView {

    events() {
        return {
            'click > a': 'onClick'
        };
    }

    onClick() {
        if (!this.isCollapsed || this.$el.hasClass('expanded')) this.trigger('opened', this);
    }

    open() {
        this.$el.addClass('open');
    }

    close() {
        this.$el.removeClass('open');
    }

    toggle() {
        this.$el.toggleClass('open');
    }

}
