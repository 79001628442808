var Backbone = require('backbone');

module.exports = Backbone.View.extend({

    events: {
        "click" : "onClick"
    },

    onClick(e) {
        e.preventDefault ? e.preventDefault() : e.returnValue = false;
        if (typeof Intercom !== 'undefined') Intercom('show');
    }

});
