import React, { Fragment } from 'react'
import { TableRow } from 'ui-kit'
import {
    hasNoRoomLimit,
    isRoomLimitReached,
    isRoomLimitAlmostReached,
    isBookedByPromoter,
} from 'app/react/helpers/reservations'
import TotalPriceForReservations from './TotalPriceForReservations'

const FirstColumnsReservationsTableRow = ({
    accommodation = {},
    currencySymbol,
    onClick,
}) => {
    const reservations = accommodation.reservations || [];
    const bookedByPromoter = reservations.filter(isBookedByPromoter);
    const roomsBookedByPromoter = bookedByPromoter
        .reduce((roomsBooked, reservation) => roomsBooked + reservation.nights, 0);
    return (
        <TableRow onClick={onClick}>
            <td>{accommodation.name}</td>
            <td className="center">{bookedByPromoter.length}</td>
            <td className="center">
                {roomsBookedByPromoter}
                {accommodation.roomLimitPerDay !== null &&
                    <span className="margin-tiny-left">max {accommodation.roomLimitPerDay}/day</span>
                }
            </td>
            <td className="center">
                <TotalPriceForReservations
                    currencySymbol={currencySymbol}
                    accommodation={accommodation}
                />
            </td>
        </TableRow>
    );
};

export default FirstColumnsReservationsTableRow;
