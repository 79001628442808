import CompanyItemsAvailableDayCell from '../../../../items/tables/cells/AvailableDayCell.view';

export default class AvailableDayCell extends CompanyItemsAvailableDayCell {

    initialize(options = {}) {
        super.initialize(options);

        // Props
        this.profile = this.model.getRelation('profile');
        // Listeners
        this.listenTo(this.profile.getRelation('available_days'), 'add', this.onAddProfileGrantedDay);
        this.listenTo(this.profile.getRelation('available_days'), 'remove', this.onRemoveProfileGrantedDay);
    }

    renderMaybe(day) {
        if (day.id === this.day.id) this.render();
    }

    isDisabled() {
        return ! this.model.getRelation('profile').hasAvailableDayOn(this.day);
    }

    isEditing() {
        return true;
    }

    onAddProfileGrantedDay(day) {
        this.renderMaybe(day);
    }

    onRemoveProfileGrantedDay(day) {
        if (day.id === this.day.id) this.model.getRelation('available_days').remove(this.day);
        this.renderMaybe(day);
    }
}

