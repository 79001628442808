import {
    getComponentState
} from 'app/react/state/helpers';
import {
    setComponentStateValue,
    setComponentState,
    mergeComponentState,
    deleteComponentStateValue,
    openConfirmModal,
    openModal,
    closeModal
} from 'app/react/state/actions/_index';

import { DELETE_CONFIRM_MODAL_ID } from '../../SchedulerView';
import { DETAILS_MODAL_ID } from './Modal';

// Helpers
export const getModalState = state =>
    getComponentState(state, DETAILS_MODAL_ID);

// Actions
export const setModalState = (key, value) =>
    setComponentStateValue(DETAILS_MODAL_ID, key, value);

export const deleteModalState = key =>
    deleteComponentStateValue(DETAILS_MODAL_ID, key);

export const closeDetailsModal = () =>
    closeModal(DETAILS_MODAL_ID);

export const openDetailsModal = () =>
    openModal(DETAILS_MODAL_ID);

export const mergeDetailsModalState = values =>
    mergeComponentState(DETAILS_MODAL_ID, values);

export const mergeDeleteConfirmModalState = values =>
    setComponentState(DELETE_CONFIRM_MODAL_ID, values);

export const openDeleteConfirmModal = action =>
    openConfirmModal(DELETE_CONFIRM_MODAL_ID, action);
