import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import DialogContainer from "../dialogContainer/DialogContainer";
import DialogCard from "../dialogCard/DialogCard";
import DialogCardBottom from "../dialogCard/DialogBottom";
import FormField from "../formfields/FormField";
import Button from "../button/Button";

const AddingTemplatesValidation = () =>
  yup.object({
    title: yup.string().required(),
    subject: yup
      .string()
      .min(3, "The subject must be at least 3 characters")
      .required(),
    body: yup
      .string()
      .min(3, "The body must be at least 3 characters")
      .required(),
  });

const tags = [
  "%artist_name%",
  "%event_name%",
  "%event_day%",
  "%stage_name%",
  "%set_time%",
  "%email%",
  "%first_name%",
  "%last_name%",
  "%user_first_name%",
  "%user_last_name%",
  "%user_name_or_email%",
];

const AddingTemplatesDialog = ({ addTemplate, template, ...props }) => {
  const insertTag = (tag, setFieldValue) => {
    const body = document.getElementById("body");
    const start = body.selectionStart;
    const end = body.selectionEnd;
    const currentValue = body.value;
    let patchedValue =
      currentValue.substring(0, start) + tag + currentValue.substring(end);
    setFieldValue("body", patchedValue);
    body.focus();
  };

  return (
    <DialogContainer>
      <DialogCard {...props}>
        <Formik
          initialValues={{
            title: template.title,
            subject: template.subject,
            body: template.body,
          }}
          validationSchema={AddingTemplatesValidation()}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            const { isValid, values, setFieldValue, touched, errors } = formik;

            return (
              <Fragment>
                <div className={"dialog-inner-container"}>
                  <h3 className="dialog-title">
                    {template.id
                      ? "Edit email template"
                      : "Create email template"}
                  </h3>
                  <Form>
                    <Field name="title" label="Title" required as={FormField} />
                    <Field
                      name="subject"
                      label="Subject"
                      required
                      as={FormField}
                      error={
                        touched.subject && errors.subject !== undefined
                          ? errors.subject
                          : undefined
                      }
                      onB
                    />
                    <Field name="body" label="Body" required>
                      {({ field }) => (
                        <label>
                          <span className="form-field__label">Body*</span>
                          <textarea
                            className="form__textarea"
                            rows="10"
                            id="body"
                            {...field}
                          />
                          {touched.body && errors.body !== undefined && (
                            <p className={"form-field__error"}>{errors.body}</p>
                          )}
                        </label>
                      )}
                    </Field>
                  </Form>
                  <div className="email-templates__tag-list">
                    {tags.map((tag) => (
                      <button
                        key={tag}
                        onClick={() => insertTag(tag, setFieldValue)}
                        className="email-templates__tag-button"
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                  <DialogCardBottom>
                    <Button
                      label={template.id ? "Update" : "Add"}
                      variant="primary"
                      type="button"
                      onClick={() =>
                        addTemplate({ ...values, id: template.id })
                      }
                      isDisabled={!isValid}
                    />
                  </DialogCardBottom>
                </div>
              </Fragment>
            );
          }}
        </Formik>
      </DialogCard>
    </DialogContainer>
  );
};

AddingTemplatesDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
  addTemplate: PropTypes.func.isRequired,
  template: PropTypes.any.isRequired,
};

export default AddingTemplatesDialog;
