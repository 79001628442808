import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import { CreateButtonRow } from 'app/react/components/_index';
import AppHeader from 'app/react/components/layout/content/header/Header';
import ContentHeader from '../../components/layout/ContentHeader';
import CreateBookingFields from './CreateBookingFields';
import CreatePerformanceFields from './CreatePerformanceFields';

const { Content } = ContentWrapper;
const { Header, Footer } = Card;
const { Title } = Header;
const { TitleMenu } = AppHeader;
const { Actions } = TitleMenu;

export const CARD_ID = 'createBookingCard';
export const VIEW_ID = 'createBookingView';

const CARD_TITLE = 'Create booking';
const FIELDSET_TITLE_PERFORMANCE = 'Create performance';

const CreateView = ({
    store,
    isFetching = true,
    isArtistTypesEnabled = true,
    dateFormat,
    timeFormat,
    selectedDay = {},

    isCreatingNewArtist = false,
    isCreatingNewPerformance = false,
    isEditingPerformanceName = false,
    values,
    errors,

    days = [],
    stages = [],
    artists = [],
    artistTypes = [],
    bookingTypes = [],
    bookingStatuses = [],

    onChangeValue = () => {},
    onSelectArtist = () => {},
    onSelectDay = () => {},
    onToggleCreateNewArtist = () => {},
    onToggleCreateNewPerformance = () => {},
    onToggleEditPerformanceName = () => {},
    onCreate = () => {},
    onBack = () => {},
}) => (
    <ContentWrapper store={store}>
        <ContentHeader>
            <Actions>
                <a className="button primary" onClick={onBack}>
                    <i className="bs-icon-arrow-back" /> Back
                </a>
            </Actions>
        </ContentHeader>
        <Content isLoading={isFetching}>
            <div style={{ maxWidth: '864px' }}>
                <Card>
                    <Header>
                        <Title>{CARD_TITLE}</Title>
                    </Header>
                    <Card.Content>
                        <CreateBookingFields
                            isCreatingNewArtist={isCreatingNewArtist}
                            isArtistTypesEnabled={isArtistTypesEnabled}
                            values={values}
                            errors={errors}
                            artists={artists}
                            artistTypes={artistTypes}
                            bookingTypes={bookingTypes}
                            bookingStatuses={bookingStatuses}
                            onChangeValue={onChangeValue}
                            onSelectArtist={onSelectArtist}
                            onToggleCreateNewArtist={onToggleCreateNewArtist}
                        />
                        {isCreatingNewPerformance 
                            ? <fieldset className="margin-top">
                                <legend>
                                    {FIELDSET_TITLE_PERFORMANCE}
                                    <a onClick={() => onToggleCreateNewPerformance(false)}>
                                        <i className="bs-icon-cross last" />
                                    </a>
                                </legend>
                                <CreatePerformanceFields
                                    isEditingPerformanceName={isEditingPerformanceName}
                                    values={values}
                                    errors={errors}
                                    days={days}
                                    stages={stages}
                                    dateFormat={dateFormat}
                                    timeFormat={timeFormat}
                                    dayStart={selectedDay.start}
                                    dayEnd={selectedDay.end}
                                    onChangeValue={onChangeValue}
                                    onSelectDay={onSelectDay}
                                    onToggleEditPerformanceName={onToggleEditPerformanceName}
                                />
                            </fieldset> 
                            : <div className="text-align-center margin-top">
                                <a onClick={() => onToggleCreateNewPerformance(true)}>
                                    + Create performance for this booking
                                </a>
                            </div>
                        }
                    </Card.Content>
                    <Footer />
                </Card>

                <CreateButtonRow
                    canCreateAnother
                    onCreate={() => onCreate(false)}
                    onCreateAnother={() => onCreate(true)}
                />
            </div>
        </Content>
    </ContentWrapper>
);

CreateView.propTypes = {
    store: PropTypes.object.isRequired,
    eventId: PropTypes.number,
    isFetching: PropTypes.bool,
    isArtistTypesEnabled: PropTypes.bool,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    selectedDay: PropTypes.object,

    isCreatingNewArtist: PropTypes.bool,
    isCreatingNewPerformance: PropTypes.bool,
    isEditingPerformanceName: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    days: PropTypes.array,
    stages: PropTypes.array,
    artists: PropTypes.array,
    artistTypes: PropTypes.array,
    bookingTypes: PropTypes.array,
    bookingStatuses: PropTypes.array,

    onChangeValue: PropTypes.func,
    onSelectArtist: PropTypes.func,
    onSelectDay: PropTypes.func,
    onToggleCreateNewArtist: PropTypes.func,
    onToggleCreateNewPerformance: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func,
    onCreate: PropTypes.func,
    onBack: PropTypes.func
};

export default CreateView;
