import BaseController from 'app/backbone/helpers/BaseController'
import ChecklistView from 'app/backbone/views/event/artists/checklist/Checklist.view'

export const route = (
    eventId = ':event',
) => `events/${eventId}/artists/checklist`;

export default class ChecklistController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(route(), null, this.checklist.bind(this));
    }

    checklist() {
        this.app.renderContentView(new ChecklistView());
    }
};
