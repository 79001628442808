import OptionTypes from 'app/backbone/helpers/OptionTypes'
import Card from 'app/backbone/components/cards/Card.view'
import OfferTable from '../tables/OfferTable.view'
import * as Day from 'app/backbone/models/event/Day'
import * as Booking from 'app/backbone/models/event/artists/Booking'

export default class OfferOverviewCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            bookings: OptionTypes.instanceOf(Booking.PageableCollection).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired,
            title
        };
    }

    initContent() {
        const { bookings, days } = this;
        return new OfferTable({ bookings, days });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add Offer',
            icon: 'bs-icon-plus',
            action: this.createOffer.bind(this)
        }];
    }

    createOffer() {
        const event = this.bookings.getRelation('event');
        navigate(`/events/${event.id}/artists/offers/create`, { pageReload: true });
    }
}
