import React from 'react';
import PropTypes from 'prop-types';
import { AlertBox, Card } from 'ui-kit';
import { Tooltip, DynamicForm } from 'app/react/components/_index';

const { Header, Content, Footer } = Card;
const { Title, Action } = Header;

export const CARD_TITLE = 'Production website form example';
export const EDIT_TOOLTIP = 'Edit form';
export const ALERT_TEXT =
    `This is an example of how the form will look like on the production website. 
    Any custom defined field can be viewed here.`;

export const ProductionWebsiteFormCard = ({
    formFields = [],
    dateFormat = 'L',
    goToFormBuilder = () => {}
}) => (
    <Card>
        <Header>
            <Title>{CARD_TITLE}</Title>
            <Tooltip content={EDIT_TOOLTIP}>
                <Action onClick={goToFormBuilder}>
                    <i className="icon bs-icon-pencil" />
                </Action>
            </Tooltip>
        </Header>
        <Content>
            <AlertBox isSubtle hasIconPrefix>
                {ALERT_TEXT}
            </AlertBox>
            <DynamicForm
                fields={formFields}
                dateFormat={dateFormat}
                width="100%"
            />
        </Content>
        <Footer />
    </Card>
);

ProductionWebsiteFormCard.propTypes = {
    formFields: PropTypes.array,
    dateFormat: PropTypes.string,
    goToFormBuilder: PropTypes.func
};

export default ProductionWebsiteFormCard;
