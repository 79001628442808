import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import CompanyModalCreate from 'app/backbone/views/account/crm/companies/modals/CompanyModalCreate.view';
import EventCompanyForm from '../forms/EventCompanyForm.view';
import * as Company from 'app/backbone/models/account/Company';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';

export default class EventCompanyCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            model: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            companies: OptionTypes.instanceOf(Company.Collection).isRequired,
            title
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // Subviews
        this.createModal = this.initCreateModel();
    }

    initContent() {
        this.form = this.initFrom(this.model, this.companies);
        return this.form;
    }

    initHeaderActions() {
        return [{
            name: 'create',
            tooltip: 'Create company',
            icon: 'bs-icon-plus',
            action: this.create.bind(this)
        }];
    }

    initCreateModel(canCreateAnother = false) {
        const createModal = new CompanyModalCreate({ canCreateAnother });
        this.listenTo(createModal, 'create', this.onCreated);
        return createModal;
    }

    initFrom(model, companies) {
        return new EventCompanyForm({ model, companies });
    }

    onCreated(company) {
        this.companies.add(company);
    }

    create() {
        this.createModal.open();
    }
}
