var _ = require('underscore'),
    Backbone = require('backbone');

var DateTime = require('app/backbone/helpers/DateTime');

module.exports = Backbone.View.extend({

    events: {
        'focus': 'open',
        'focusout': 'formatInput',
        'keyup': 'close'
    },

    options: [
        'formatKey',
        'format',
        'editable',
        'min',
        'max',
        'disable',
    ],

    initialize: function(options) {
        this.setOptions(options);
        !_.isUndefined(this.formatKey) || (this.formatKey = 'account');
        !_.isUndefined(this.editable) || (this.editable = true);
        !_.isUndefined(this.disable) || (this.disable = []);

        this.format = DateTime[this.formatKey].getDatepickerFormat();

        this.$el.pickadate({
            clear: '',
            today: 'Today',
            format: this.format,
            editable: this.editable,
            min: this.min,
            max: this.max,
            disable: this.disable,
            selectYears: 100,
        });
        this.picker = this.$el.pickadate('picker');
    },

    render: function() {
        this.picker.render();
    },

    open: function() {
        this.picker.open(true);
    },

    close: function() {
        this.picker.close(true);
    },

    getValue: function() {
        return this.$el.val();
    },

    setValue: function(value) {
        this.$el.val(value);
        this.$el.trigger('change');
    },

    setSelected: function(date) {
        if(date instanceof Date){
            this.picker.set('select', date);
        } else {
            this.picker.set('select', DateTime[this.formatKey].parseDateStringToDate(date));
        }
    },

    setMin: function(date) {
        if(date instanceof Date){
            this.picker.set('min', date)
        } else {
            this.picker.set('select', DateTime[this.formatKey].parseDateStringToDate(date));
        }
    },

    setMax: function(date) {
        if(date instanceof Date){
            this.picker.set('min', date)
        } else {
            this.picker.set('select', DateTime[this.formatKey].parseDateStringToDate(date));
        }
    },

    formatInput: function() {
        var value = this.getValue();
        if(value != '') {
            var mDate = DateTime[this.formatKey].parseDateStringToMoment(value);
            if(mDate.isValid()){
                this.setSelected(mDate.toDate());
                this.setValue(DateTime[this.formatKey].formatMomentToDateString(mDate));
            } else {
                this.setValue('');
            }
        }
    }
});
