import OptionTypes from 'app/backbone/helpers/OptionTypes';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';
import ItemsContentView from 'app/backbone/views/account/settings/accreditation/views/ItemsContent.view';
import EventItemsOverviewCard from './cards/EventItemsOverviewCard.view';
import EventItemGroupsOverviewCard from '../groups/views/cards/EventItemGroupsOverviewCard.view';

export default class EventItemsOverview extends ItemsContentView {

    optionTypes() {
        return {
            items: OptionTypes.instanceOf(EventItem.PageableCollection).isRequired,
            itemGroups: OptionTypes.instanceOf(EventItemGroup.PageableCollection).isRequired
        };
    }

    initItemsCard() {
        const { items, itemGroups } = this;
        const title = 'Items';
        return new EventItemsOverviewCard({ items, itemGroups, title });
    }

    initItemGroupsCard() {
        const { itemGroups } = this;
        const title = 'Groups';
        return new EventItemGroupsOverviewCard({ itemGroups, title });
    }

}
