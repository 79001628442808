import $ from 'jquery';
import Backbone from 'backbone';
import NavigationCollapsable from '../NavigationCollapsable.view';
import SidebarAccounts from './SidebarAccounts.view';
import SubMenu from './SubMenu.view';

export default class Sidebar extends NavigationCollapsable {

    ui() {
        return {
            expandNavigationButton: '#expand-navigation-button',
            subMenus: '.has-sub-menu',
            sidebarAccounts: '#sidebar-accounts'
        };
    }

    events() {
        return {
            'click expandNavigationButton': 'onClickExpandNavigationButton'
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        this.sidebarAccounts = new SidebarAccounts();
    }

    render() {
        // Enhanced view, html provided by server.
        this.resolveHandles();
        this.assignSubview(this.sidebarAccounts, this.ui.sidebarAccounts);
        this.subMenus = this.initSubMenus();
        this.bindSubMenus();
        return this.delegateEvents();
    }

    initSubMenus() {
        return new Backbone.ChildViewContainer(this.initViewsForEachEl(SubMenu, this.ui.subMenus));
    }

    bindSubMenus() {
        this.subMenus.forEach((subMenu) => {
            this.listenTo(subMenu, 'opened', this.onSubMenuOpened);
        });
    }

    onSubMenuOpened(subMenu) {
        if (this.openedSubMenu && this.openedSubMenu.cid == subMenu.cid) {
            subMenu.toggle();
        } else {
            this.subMenus.call('close');
            subMenu.open();
        }
        this.openedSubMenu = subMenu;
    }

    onClickExpandNavigationButton() {
        var windowIsSmall = $(window).width() < this.collapseWidth;
        this.toggleCollapse(windowIsSmall);
        this.trigger('collapsed', windowIsSmall);
    }

    toggleCollapse(windowIsSmall) {
        this.isCollapsed = windowIsSmall;
        this.$el.find('.open').removeClass('open');
        super.toggleCollapse(windowIsSmall);
    }

}
