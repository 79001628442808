import { oneLineTrim } from 'common-tags';
import {
    includeParam,
} from 'app/react/state/middleware/api'

export const fetchOne = (
    accountId,
    eventId,
    includes = []
) => oneLineTrim`
    accounts/${accountId}/events/${eventId}?
    ${includeParam(includes)}
`;
