import { oneLineTrim } from 'common-tags';
import { includeParam } from 'app/react/state/middleware/api'

const rootAll = (eventId, bookingId) =>
    `events/${eventId}/bookings/${bookingId}/performers`;
const rootOne = (eventId, bookingId, performerId) =>
    `${rootAll(eventId, bookingId)}/${performerId}`;

export const createOne = (
    eventId,
    bookingId
) => oneLineTrim`
    ${rootAll(eventId, bookingId)}
`;

export const fetchOne = (
    eventId,
    bookingId,
    performerId,
    includes
) => oneLineTrim`
    ${rootOne(eventId, bookingId, performerId)}?
    ${includeParam(includes)}
`;

export const updateOne = (
    eventId,
    bookingId,
    performerId
) => oneLineTrim`
    ${rootOne(eventId, bookingId, performerId)}
`;

export const deleteOne = (
    eventId,
    bookingId,
    performerId
) => oneLineTrim`
    ${rootOne(eventId, bookingId, performerId)}
`;
