import { html } from 'common-tags';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import EventCompanyOverviewCard from '../cards/EventCompanyOverviewCard.view';

export default class EventCompanyOverview extends Content {

    ui() {
        return {
            companiesCard: '#companies-card'
        };
    }

    optionTypes() {
        return {
            companies: OptionTypes.instanceOf(EventCompany.PageableCollection).isRequired,
            myCompanies: OptionTypes.instanceOf(EventCompany.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.companiesCard = this.initCompaniesCard();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.companiesCard, this.ui.companiesCard);
        return this.delegateEvents();
    }

    template() {
        return html`<div id="companies-card" class="card"></div>`;
    }

    remove() {
        super.remove();
        this.companiesCard.remove();
    }

    initCompaniesCard() {
        const { companies, myCompanies } = this;
        return new EventCompanyOverviewCard({ companies, myCompanies });
    }
}
