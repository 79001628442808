import React from 'react';
import Header from 'app/react/components/layout/content/header/Header';

const { TitleMenu, Tabs } = Header;
const { Title } = TitleMenu;
const { Tab } = Tabs;

const TITLE = 'Artists';
const TASKS_TAB = 'Tasks';
const ITEMS_TAB = 'Items';

const AccountArtistsSettingsContentHeader = ({
    activeTab
}) => {
    const baseUrl = '/settings/artists';
    return (
        <Header>
            <TitleMenu>
                <Title>{TITLE}</Title>
            </TitleMenu>
            <Tabs>
                <Tab activeTab={activeTab} tabId="tasks" url={`${baseUrl}/tasks`} pageReload>
                    {TASKS_TAB}
                </Tab>
                <Tab activeTab={activeTab} tabId="items" url={`${baseUrl}/items`} pageReload>
                    {ITEMS_TAB}
                </Tab>
            </Tabs>
        </Header>
    );
};

export default AccountArtistsSettingsContentHeader
