import BaseController from 'app/backbone/helpers/BaseController'
import RidersOverviewView from 'app/backbone/views/event/artists/documents/riders/Overview.view'

export const overviewRoute = (
    eventId = ':event',
) => `events/${eventId}/artists/documents/riders`;

export default class RidersController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(overviewRoute(), null, this.overview.bind(this));
    }

    overview() {
        this.app.renderContentView(new RidersOverviewView());
    }
};
