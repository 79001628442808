import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import UrllessLink from 'ui-kit/lib/components/link/UrllessLink'

const Tab = ({
    id,
    activeTab,
    children,
    onClick = () => {},
}) => (
    <li className="tab">
        <UrllessLink
            className={classNames('tabLink', {
                isActive: activeTab === id,
            })}
            onClick={() => onClick(id)}
        >
            {children}
        </UrllessLink>
    </li>
);

Tab.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default Tab;
