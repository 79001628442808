import React from 'react'
import { SortCell, NoRecordsRow, Tooltip } from 'app/react/components/_index'
import Row from './FirstColumnsReservationsTableRow'
import DetailDrawer from 'app/react/views/event/artists/reservations/views/dashboard/components/drawers/DetailDrawer'

const FirstColumnsReservationsTable = ({
    accommodations = [],
    currencySymbol,
}) => (
    <table className="overflow isLarge">
        <thead>
            <tr>
                <th
                    className="center"
                    colSpan="4"
                >
                    Rooms booked
                </th>
            </tr>
            <tr>
                <SortCell sortKey="name">Accommodation</SortCell>
                <SortCell
                    sortKey="reservations"
                    className="center"
                >
                    <Tooltip content="Total reservations booked by promoter">
                        <i className="bs-icon-accommodation" aria-hidden="true"/>
                    </Tooltip>
                </SortCell>
                <SortCell
                    sortKey="nights"
                    className="center"
                >
                    <Tooltip content="Total nights booked by promoter">
                        <i className="bs-icon-night" aria-hidden="true"/>
                    </Tooltip>
                </SortCell>
                <SortCell
                    sortKey="totalPrice"
                    className="center"
                >
                    <Tooltip content="Total price for nights paid by promoter">
                        <i className="bs-icon-money" aria-hidden="true"/>
                    </Tooltip>
                </SortCell>
            </tr>
        </thead>
        <tbody>
            {accommodations.length === 0
                ? <NoRecordsRow />
                : accommodations.map(accommodation => (
                    <DetailDrawer
                        key={accommodation.id}
                        accommodation={accommodation}
                        currencySymbol={currencySymbol}
                        renderTrigger={({ onOpenDrawer }) => (
                            <Row
                                id={accommodation.id}
                                accommodation={accommodation}
                                currencySymbol={currencySymbol}
                                onClick={onOpenDrawer}
                            />
                        )}
                    />
                ))
            }
        </tbody>
    </table>
);

export default FirstColumnsReservationsTable;
