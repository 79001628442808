import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import CompanyAccrediteeContactCard from '../cards/CompanyAccrediteeContactCard.view';
import CompanyAccrediteeProfilesCard from '../profiles/cards/CompanyAccrediteeProfilesCard.view';
import CompanyAccrediteeItemsCard from '../items/cards/CompanyAccrediteeItemsCard.view';
import template from './CompanyAccreditee.hbs';

export default class CompanyAccrediteeView extends Content {

    get template() {
        return template;
    }

    ui() {
        return {
            contactCard: '#contact-card',
            profilesCard: '#profiles-card',
            itemsCard: '#items-card'
        };
    }

    optionTypes() {
        return {
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            accreditees: OptionTypes.instanceOf(CompanyAccreditee.PageableCollection).isRequired,
            accreditee: OptionTypes.instanceOf(CompanyAccreditee.Model)
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        this.model = this.accreditee || this.newModel();
        // Subviews
        this.contactCard = this.initContactCard();
        this.profilesCard = this.initProfilesCard();
        this.itemsCard = this.initItemsCard();

        // If profiles only contains one profile, we automatically add it to the list of selected profiles.
        // We call this after all listeners from the item cards are setup
        if (this.profilesCard.profiles.length === 1) this.profilesCard.addInitialProfiles();
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        if (!this.isSyncing) {
            this.unbindModel();
            this.bindModel();
            this.assignSubview(this.contactCard, this.ui.contactCard);
            this.assignSubview(this.profilesCard, this.ui.profilesCard);
            this.assignSubview(this.itemsCard, this.ui.itemsCard);
        }
        return this.delegateEvents();
    }

    remove() {
        super.remove();
        this.contactCard.remove();
        this.profilesCard.remove();
        this.itemsCard.remove();
    }

    viewModel() {
        return {
            view: {
                canCreateAnother: this.canCreateAnother,
                syncingText: this.syncingText,
                isSyncing: this.isSyncing,
                isUpdating: this.isUpdating
            },
            model: this.model.attributes
        };
    }

    initContactCard() {
        const { model } = this;
        const title = 'Attendee';
        return new CompanyAccrediteeContactCard({ model, title });
    }

    initProfilesCard() {
        const { model, periods, company } = this;
        const profiles = company.getRelation('profiles');
        const title = 'Profiles';
        return new CompanyAccrediteeProfilesCard({ model, title, periods, profiles });
    }

    initItemsCard() {
        const { model, periods } = this;
        const title = 'items';
        // This card does not get any items, instead it will check and listen if profiles with items are added
        // to the model and populate the its table accordingly
        return new CompanyAccrediteeItemsCard({ model, title, periods });
    }

    newModel(attr) {
        const { company } = this;
        return new CompanyAccreditee.Model(attr, { company });
    }

    bindModel() {
        if (this.model) {
            this.listenTo(this.model, 'request', () => { this.setSyncing(true); });
            this.listenTo(this.model, 'error', this.onError);
            this.listenTo(this.model, 'sync', this.onSync);
        }
    }

    unbindModel() {
        if (this.model) this.stopListening(this.model);
    }

    setSyncing(syncing) {
        if (syncing !== this.isSyncing) {
            this.isSyncing = syncing;
            this.render();
        }
    }

    onError(model, xhr) {
        this.setSyncing(false);
        this.contactCard.form.validateResponse(xhr);
    }

    backToOverview() {
        const company = this.model.getRelation('company');
        const companyId = company.get('id');
        const eventId = company.getRelation('event').get('id');
        navigate(`/events/${eventId}/accreditation/companies/${companyId}/details`);
    }

}
