import * as AccountArtistItem from 'app/react/entities/accounts/items/helpers'
import * as AccountArtistItemGroup from 'app/react/entities/accounts/item-groups/helpers'
import * as EventsArtistItem from 'app/react/entities/events/artists/items/helpers'
import * as EventsArtistItemGroup from 'app/react/entities/events/artists/item_groups/helpers'

export const enhanceStateWithHelpers = (state, key = 'helpers') => ({
    ...state,
    [key]: {
        ...AccountArtistItem.mixins(state),
        ...AccountArtistItemGroup.mixins(state),
        ...EventsArtistItem.mixins(state),
        ...EventsArtistItemGroup.mixins(state)
    }
});
