import $ from 'jquery';
import { toggleTask, toggleLoadingTask } from './actions'

export const ajaxToggleTask = (eventId, bookingId, taskId) => (dispatch, getState) => {
    const url = `/events/${eventId}/artists/bookings/${bookingId}/tasks/${taskId}/ajax-toggle`;
    const type = 'PUT';
    dispatch(toggleLoadingTask(taskId, true));
    $.ajax({ type, url }).success(response => {
        dispatch(toggleLoadingTask(taskId, false));
        dispatch(toggleTask(taskId, response.completed))
    });
};
