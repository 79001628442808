import React from 'react';
import Header from 'app/react/components/layout/content/header/Header';

const { TitleMenu } = Header;
const { Title } = TitleMenu;

const TITLE = 'Items';

const ItemsContentHeader = ({
    onDownloadReport = () => {}
}) => {
    return (
        <Header>
            <TitleMenu>
                <Title>{TITLE}</Title>
            </TitleMenu>
        </Header>
    );
};

export default ItemsContentHeader;
