import React, { useState, Fragment } from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import moment from "moment";
import { useHTTP } from "../../../../hooks/UseHTTP";
import DialogContainer from "../../dialogContainer/DialogContainer";
import Icon from "../../../../utils/Icon";
import GeneralSection from "./sections/General";
import ContactsSection from "./sections/Contacts";
import PerformersSection from "./sections/Performers";
import CrewSection from "./sections/Crew";
import FilesSection from "./sections/Files";
import InOutboundSection from "./sections/InOutBound";
import RidersSection from "./sections/Riders";
import PickupSection from "./sections/Pickups";
import ParkingPassesSection from "./sections/ParkingPasses";
import AccommodationsSection from "./sections/Accommodations";
import GuestListSection from "./sections/GuestList";
import TokensVouchersSection from "./sections/TokensVouchers";
import CateringAllergiesSection from "./sections/CateringAllergies";

import ArtistGeneralFooter from "./GeneralFooter";
import classNames from "classnames";

const sectionsForms = [
  { form: GeneralSection, label: "General", sectionId: "intro" },
  { form: ContactsSection, label: "Contacts", sectionId: "contacts" },
  { form: PerformersSection, label: "Performers", sectionId: "performers" },
  { form: CrewSection, label: "Crew", sectionId: "crew" },
  { form: FilesSection, label: "Files", sectionId: "files" },
  { form: RidersSection, label: "Riders", sectionId: "riders" },
  {
    form: InOutboundSection,
    label: "Inbound / Outbound",
    sectionId: "transports",
  },
  { form: PickupSection, label: "Pickups", sectionId: "pickups" },
  {
    form: ParkingPassesSection,
    label: "Parking Passes",
    sectionId: "parking_passes",
  },
  {
    form: AccommodationsSection,
    label: "Accommodations",
    sectionId: "accommodations",
  },
  { form: GuestListSection, label: "Guest Lists", sectionId: "guest_lists" },
  {
    form: TokensVouchersSection,
    label: "Tokens & Vouchers",
    sectionId: "tokens",
  },
  {
    form: CateringAllergiesSection,
    label: "Catering Allergies & Diet",
    sectionId: "catering",
  },
];

const ArtistTypeDialog = ({
  artistTypeId,
  eventState,
  setCancelDialogOpen,
  sections,
  profileTypes,
  ticketTypes,
  artistTypeName
}) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [sectionToShow, setSectionToShow] = useState(
    sectionsForms[currentSectionIndex]
  );
  const { doHttpCallJson } = useHTTP();

  const mappedSections = sections.reduce((acc, section) => {
    const sectionName = section.id;
    return {
      ...acc,
      [sectionName]: {
        ...section,
        open: !section["closed"],
        ["form-fields"]: section["form_fields"]
          ? section["form_fields"].data.sort((a, b) =>
              a.sequence > b.sequence ? 1 : -1
            )
          : [],
      },
    };
  }, {});

  const updateArtistTypeSection = async ({
    currentSection,
    sectionToSet,
    values,
    resetForm,
    closeForm,
  }) => {
    const sectionId = sectionsForms[currentSection].sectionId;
    const {
      intro,
      description,
      limit,
      limit_for_2,
      limit_for_3,
      telephone_required,
      passports_enabled,
      passports_required,
      update_bookings,
      accreditation_profile,
      ticket_type,
      food_limit,
      drink_limit,
      room_limit,
      night_limit,
      create,
      requires_approval,
      enabled,
      open,
      deadline,
      name,
    } = values[sectionId];
    const body = {
      intro,
      description,
      limit,
      limit_for_2,
      limit_for_3,
      telephone_required,
      passports_enabled,
      passports_required,
      update_bookings,
      accreditation_profile,
      ticket_type,
      food_limit,
      drink_limit,
      room_limit,
      night_limit,
      create,
      requires_approval,
      enabled,
      closed: !open,
      deadline: deadline ? moment.utc(deadline).format() : null,
      name,
    };
    const url =
      sectionId === "intro"
        ? `/api/events/${eventState.event.id}/artist-types/${artistTypeId}/production-website/artists/settings?include=form_fields`
        : `/api/events/${eventState.event.id}/artist-types/${artistTypeId}/production-website/artists/settings/${sectionId}?include=form_fields`;
    doHttpCallJson(url, "PUT", body)
      .then((artistTypeRes) => {
        if (
          (artistTypeRes &&
            artistTypeRes.data &&
            !values[sectionId]["form-fields"]) ||
          values[sectionId]["form-fields"].length === 0
        ) {
          if (!closeForm) {
            resetForm({ values });
            setSectionToShow(sectionsForms[sectionToSet]);
            setCurrentSectionIndex(sectionToSet);
          } else {
            resetForm({ values });
            setCancelDialogOpen(false);
          }
        } else {
          updateSectionFormField({
            currentSection,
            values,
            sectionToSet,
            resetForm,
          });
        }
      })
      .catch((err) => {});
  };
  const updateSectionFormField = async ({
    currentSection,
    values,
    sectionToSet,
    resetForm,
  }) => {
    const sectionId = sectionsForms[currentSection].sectionId;

    const url = `/api/events/${eventState.event.id}/artist-types/${artistTypeId}/production-website/artists/settings/${sectionId}/form-fields`;
    doHttpCallJson(url, "PUT", values[sectionId]["form-fields"])
      .then((artistTypeRes) => {
        if (artistTypeRes && artistTypeRes.data) {
          resetForm({ values });
          setCurrentSectionIndex(sectionToSet);
          setSectionToShow(sectionsForms[sectionToSet]);
        }
      })
      .catch((err) => {});
  };

  const changeStep = ({
    currentSection,
    sectionToSet,
    values,
    resetForm,
    isDirty,
    closeForm,
  }) => {
    if (isDirty || closeForm) {
      updateArtistTypeSection({
        currentSection,
        values,
        sectionToSet,
        resetForm,
        closeForm,
      });
    } else {
      setSectionToShow(sectionsForms[sectionToSet]);
      setCurrentSectionIndex(sectionToSet);
    }
  };

  return (
    <DialogContainer>
      <Formik
        initialValues={{
          intro: "",
          ...mappedSections,
        }}
        validateOnMount
      >
        {(formik) => {
          const { values, setFieldValue, resetForm } = formik;
          return (
            <Fragment>
              <Form>
                <div className="artist-dialog__container">
                  <div className="artist-dialog__left-list">
                    <h2>{artistTypeName}</h2>
                    {Object.values(sectionsForms).map(({ label }, index) => (
                      <button
                        // type="submit"
                        key={`${index}-${label}`}
                        className={classNames(
                          "artist-dialog__left-list-item",
                          currentSectionIndex === index &&
                            "artist-dialog__left-list-item--active"
                        )}
                        onClick={() =>
                          changeStep({
                            currentSection: currentSectionIndex,
                            sectionToSet: index,
                            values,
                            resetForm,
                            isDirty: formik.dirty,
                          })
                        }
                      >
                        <Icon
                          name="ellipse"
                          style={{ marginBottom: "-6px" }}
                          color={
                            currentSectionIndex === index
                              ? "#51D0AE"
                              : undefined
                          }
                        />
                        {label}
                      </button>
                    ))}
                  </div>
                  <div className="artist-dialog__right-container">
                    <button
                      className={"artist-dialog__cancel-button"}
                      onClick={() => setCancelDialogOpen(false)}
                    >
                      <Icon
                        size={24}
                        name="close"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                    {
                      <sectionToShow.form
                        setFieldValue={setFieldValue}
                        sectionId={sectionToShow.sectionId}
                        values={values[sectionToShow.sectionId]}
                        profileTypes={profileTypes}
                        ticketTypes={ticketTypes}
                      />
                    }
                    <ArtistGeneralFooter
                      hasPrevious={currentSectionIndex !== 0}
                      hasNext={currentSectionIndex !== 12}
                      hasSave={currentSectionIndex == 12}
                      setActiveStep={(currentSection, sectionToSet) =>
                        changeStep({
                          currentSection,
                          sectionToSet,
                          values,
                          resetForm,
                          isDirty: formik.dirty,
                        })
                      }
                      saveAndClose={(currentSection, sectionToSet) =>
                        changeStep({
                          currentSection,
                          sectionToSet,
                          values,
                          resetForm,
                          isDirty: formik.dirty,
                          closeForm: true,
                        })
                      }
                      activeSectionIndex={currentSectionIndex}
                    />
                  </div>
                </div>
              </Form>
            </Fragment>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

ArtistTypeDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
};

export default ArtistTypeDialog;
