import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import AccountUsersOverview from 'app/backbone/views/account/users/views/AccountUsersOverview.view';
import RoleOverview from 'app/backbone/views/account/users/roles/views/RoleOverview.view';
import RoleDetailsView from 'app/backbone/views/account/users/roles/views/RoleDetails.view';
import * as Routes from './routes'

// Backbone Controller
export default class AccountArtistsItemsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.users(), null, this.users.bind(this));
        this.router.route(Routes.roles(), null, this.roles.bind(this));
        this.router.route(Routes.rolesDetail(), null, this.rolesDetail.bind(this));
    }

    users() {
        this.app.renderContentView(new AccountUsersOverview());
    }

    roles() {
        this.app.renderContentView(new RoleOverview());
    }

    rolesDetail() {
        this.app.renderContentView(new RoleDetailsView());
    }


}
