import {
    LOADING_DATA
} from '../actions/_index';

export function loadingData(state = {}, action) {
    const { type, status } = action;
    switch (type) {
        case LOADING_DATA:
            return Object.assign({}, state, {
                status
            });
        default:
            return state;
    }
}
