import _ from 'underscore';
import ServerSideFilter from './ServerSideFilter.view';
import template from './SearchFilter';

export default class SearchFilter extends ServerSideFilter {

    get template() {
        return template;
    }

    events() {
        return _.extend({}, super.events(), {
            keydown: 'keydown'
        });
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        this.resolveHandles();
        this.showClearButtonMaybe();
        if (this.active) this.ui.filterInput.focusAtTextEnd();
        return this.delegateEvents();
    }

    viewModel() {
        return {
            view: {
                value: this.value,
                placeholder: this.placeholder
            }
        };
    }

    keydown(e) {
        const $input = this.ui.filterInput;
        if ($input.isFocused()) {
            if (e.which === 13 && $input.val() !== '') this.filter(e);
            if (e.which === 27 && this.active) this.clear(e);
        }
    }

}
