import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import { combinePaginationFields } from 'app/react/helpers/_index'
import { getPaginationByKey } from './helpers';
import {
    fetchAllPaginated,
    awaitApprovalForOne,
    approveOne,
    denyOne,
    deleteOne,
    updateOne
} from './actions';

export const awaitApprovalForOneForCurrentContext = (reservationId, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(awaitApprovalForOne(accountId, eventId, reservationId, includes));
};

export const approveOneForCurrentContext = (reservationId, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(approveOne(accountId, eventId, reservationId, includes));
};

export const denyOneForCurrentContext = (reservationId, values, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(denyOne(accountId, eventId, reservationId, values, includes));
};

export const deleteOneForCurrentContext = (reservationId) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(deleteOne(accountId, eventId, reservationId));
};

export const toggleIsCheckInForCurrentContext = (reservationId, isCheckedIn) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    return dispatch(updateOne(accountId, eventId, reservationId, { isCheckedIn }));
};

export const fetchAllByPaginationForCurrentContext = (newPagination, includes) => (dispatch, getState) => {
    const state = getState();
    const accountId = Account.getCurrentId(state);
    const eventId = Event.getCurrentId(state);
    const pagination = getPaginationByKey(state, Event.generateCollectionKey(accountId, eventId));
    const combinedPagination = combinePaginationFields(newPagination, pagination);
    return dispatch(fetchAllPaginated(accountId, eventId, combinedPagination, includes));
};
