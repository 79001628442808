export const base = (
    eventId = ':event'
) => `events/${eventId}/artists/reports`;

export const guestList = (
    eventId = ':event'
) => `${base(eventId)}/guest-list`;

export const driverSchedules = (
    eventId = ':event'
) => `${base(eventId)}/driver-schedules`;

export const accommodations = (
    eventId = ':event'
) => `${base(eventId)}/accommodations`;

