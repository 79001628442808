import ItemGroupModalCreate from 'app/backbone/views/account/settings/accreditation/groups/views/modals/ItemGroupModalCreate.view';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';

export default class EventItemGroupModalCreate extends ItemGroupModalCreate {

    newModel(attributes, options) {
        return new EventItemGroup.Model(attributes, options);
    }

}
