import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'
import * as AttendeeSchemas from '../../attendees/schemas'
import { setCurrentContextId } from 'app/react/state/actions/_index'

export const ONE_REQUEST = 'ONE_PERFORMERS_REQUEST';
export const ONE_SUCCESS = 'ONE_PERFORMERS_SUCCESS';
export const ONE_FAILURE = 'ONE_PERFORMERS_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const setCurrentId = id =>
    setCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY, id);

export const createOne = (
    accountId,
    eventId,
    bookingId,
    values
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.createOne(eventId, bookingId),
        schema: Schemas.entity,
    },
    accountId,
    eventId,
    bookingId
});

export const fetchOne = (
    accountId,
    eventId,
    bookingId,
    performerId,
    includes
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchOne(eventId, bookingId, performerId, includes),
        schema: Schemas.entity,
        id: performerId
    },
    accountId,
    eventId,
    bookingId
});

export const updateOne = (
    accountId,
    eventId,
    bookingId,
    performerId,
    values
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.PUT,
        body: values,
        endpoint: Endpoints.updateOne(eventId, bookingId, performerId),
        schema: AttendeeSchemas.entity,
        id: performerId,
    },
    accountId,
    eventId,
    bookingId
});

export const deleteOne = (
    accountId,
    eventId,
    bookingId,
    performerId
) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.DELETE,
        endpoint: Endpoints.deleteOne(eventId, bookingId, performerId),
        schema: AttendeeSchemas.entity,
        id: performerId
    },
    accountId,
    eventId,
    bookingId
});

