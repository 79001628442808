import { combineReducers } from 'redux';
// Old
import * as Performance from 'app/react/entities/events/performances/index';
import * as PerformanceTimeSlot from 'app/react/entities/events/performances/time_slots/index';
import * as PerformanceTimeSlotType from 'app/react/entities/time_slot_types/index';
import * as Image from 'app/react/entities/images/index';
import * as Integration from 'app/react/entities/accounts/integrations/index';
import * as IntegrationEvents from 'app/react/entities/accounts/integrations/events/index';
import * as TicketProvider from 'app/react/entities/events/accreditation/tickets/provider/index';
import * as TicketSettings from 'app/react/entities/events/accreditation/tickets/settings/index';
import * as PWPage from 'app/react/entities/events/production_website/pages/index';
// New
import * as BookingType from 'app/react/entities/booking_types/reducers';
import * as Team from 'app/react/entities/team/reducers';
import * as User from 'app/react/entities/users/reducers';
import * as Account from 'app/react/entities/accounts/reducers';
import * as Artist from 'app/react/entities/accounts/artists/reducers';
import * as Contact from 'app/react/entities/accounts/contacts/reducers';
import * as Venue from 'app/react/entities/accounts/venues/reducers';
import * as AccountArtistItem from 'app/react/entities/accounts/items/reducers';
import * as AccountArtistItemGroup from 'app/react/entities/accounts/item-groups/reducers';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/reducers';
import * as Event from 'app/react/entities/events/reducers';
import * as Day from 'app/react/entities/events/days/reducers';
import * as Stage from 'app/react/entities/events/stages/reducers';
import * as Profile from 'app/react/entities/events/accreditation/profiles/reducers';
import * as ArtistType from 'app/react/entities/events/artist_types/reducers';
import * as PWSettings from 'app/react/entities/events/production_website/settings/general/reducers';
import * as PWArtistSettings from 'app/react/entities/events/production_website/settings/artists/reducers';
import * as PWArtistSettingsSection from 'app/react/entities/events/production_website/settings/artists/sections/reducers';
import * as PWArtistSettingsSectionFormField from 'app/react/entities/events/production_website/settings/artists/sections/form_fields/reducers';
import * as EventArtistItem from 'app/react/entities/events/artists/items/reducers';
import * as EventArtistItemGroup from 'app/react/entities/events/artists/item_groups/reducers';
import * as EventLocations from 'app/react/entities/events/locations/reducers';
import * as EventRoutes from 'app/react/entities/events/locations/routes/reducers';
import * as Booking from 'app/react/entities/events/bookings/reducers';
import * as BookingAttendees from 'app/react/entities/events/bookings/attendees/reducers';
import * as Task from 'app/react/entities/events/bookings/tasks/reducers';
import * as Pickup from 'app/react/entities/events/bookings/pickups/reducers';
import * as PickupMode from 'app/react/entities/events/bookings/pickups/modes/reducers';
import * as Driver from 'app/react/entities/events/drivers/reducers';
import * as Transport from 'app/react/entities/events/bookings/transports/reducers';
import * as Vehicle from 'app/react/entities/events/vehicles/reducers';
import * as VehicleType from 'app/react/entities/vehicle_types/reducers';
import * as BookingItem from 'app/react/entities/events/bookings/items/reducers';
import * as Reservation from 'app/react/entities/events/reservations/reducers';
import * as Accommodation from 'app/react/entities/events/accommodations/reducers';
import * as RoomType from 'app/react/entities/events/accommodations/room_types/reducers';
import * as Guest from 'app/react/entities/events/guest_list/reducers';
import * as TicketType from 'app/react/entities/events/ticket_types/reducers';
import * as Attendee from 'app/react/entities/events/attendees/reducers';
import * as Crew from 'app/react/entities/events/bookings/crew/reducers';
import * as Performer from 'app/react/entities/events/bookings/performers/reducers';
import * as File from 'app/react/entities/files/reducers';

export const entitiesReducers = {
    [PerformanceTimeSlot.ENTITY_REDUCER_KEY]: PerformanceTimeSlot.reducers.entity,
    [Integration.ACTIVATED_REDUCER_KEY]: Integration.reducers.activatedEntity,
    [Performance.ENTITY_REDUCER_KEY]: (state = {}) => state,
    [PerformanceTimeSlotType.ENTITY_REDUCER_KEY]: (state = {}) => state,
    [Image.ENTITY_REDUCER_KEY]: (state = {}) => state,
    [IntegrationEvents.ENTITY_REDUCER_KEY]: (state = {}) => state,
    [IntegrationEvents.PROFILE_ENTITY_REDUCER_KEY]: (state = {}) => state,
    [TicketSettings.ENTITY_REDUCER_KEY]: (state = {}) => state,
    [TicketProvider.ENTITY_REDUCER_KEY]: (state = {}) => state,
    [PWPage.ENTITY_REDUCER_KEY]: (state = {}) => state,
    [PWPage.CHILD_PAGE_REDUCER_KEY]: (state = {}) => state,
    [Integration.AVAILABLE_REDUCER_KEY]: (state = {}) => state,

    ...BookingType.entityReducers,
    ...Team.entityReducers,
    ...User.entityReducers,
    ...Artist.entityReducers,
    ...Contact.entityReducers,
    ...Venue.entityReducers,
    ...Account.entityReducers,
    ...AccountArtistItem.entityReducers,
    ...AccountArtistItemGroup.entityReducers,
    ...BookingStatus.entityReducers,
    ...Event.entityReducers,
    ...Day.entityReducers,
    ...Stage.entityReducers,
    ...Profile.entityReducers,
    ...ArtistType.entityReducers,
    ...PWSettings.entityReducers,
    ...PWArtistSettings.entityReducers,
    ...PWArtistSettingsSection.entityReducers,
    ...PWArtistSettingsSectionFormField.entityReducers,
    ...EventArtistItem.entityReducers,
    ...EventArtistItemGroup.entityReducers,
    ...Booking.entityReducers,
    ...BookingAttendees.entityReducers,
    ...Task.entityReducers,
    ...Pickup.entityReducers,
    ...PickupMode.entityReducers,
    ...EventLocations.entityReducers,
    ...EventRoutes.entityReducers,
    ...Driver.entityReducers,
    ...Transport.entityReducers,
    ...Vehicle.entityReducers,
    ...VehicleType.entityReducers,
    ...BookingItem.entityReducers,
    ...Reservation.entityReducers,
    ...Accommodation.entityReducers,
    ...RoomType.entityReducers,
    ...Guest.entityReducers,
    ...TicketType.entityReducers,
    ...Attendee.entityReducers,
    ...Crew.entityReducers,
    ...Performer.entityReducers,
    ...File.entityReducers
};

export default combineReducers(entitiesReducers);
