import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import * as Contact from 'app/backbone/models/account/Contact';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import * as EventItem from 'app/backbone/models/event/settings/accreditation/EventItem';
import * as CustomAccreditee from 'app/backbone/models/event/accreditation/accreditees/CustomAccreditee';
import * as Accreditee from 'app/backbone/models/event/accreditation/accreditees/Accreditee';
import AccrediteeContentHeader from 'app/react/views/event/accreditation/accreditees/AccrediteeContentHeader';
import AccrediteesOverview from 'app/backbone/views/event/accreditation/accreditees/views/AccrediteesOverview.view';
import CustomAccrediteeCreate from 'app/backbone/views/event/accreditation/accreditees/views/CustomAccrediteeCreate.view';
import CustomAccrediteeUpdate from 'app/backbone/views/event/accreditation/accreditees/views/CustomAccrediteeUpdate.view';

// Backbone Controller
export default class CompanyAccrediteeController extends BaseController {

    constructor(options) {
        super(options);
        // Route definitions
        const baseName = 'events.accreditation.attendees';
        const basePath = 'events/:event/accreditation/attendees';
        this.router.route(`${basePath}`, `${baseName}`, this.overview.bind(this));
        this.router.route(`${basePath}/create`, `${baseName}.create`, this.create.bind(this));
        this.router.route(`${basePath}/:accreditee/edit`, `${baseName}.edit`, this.edit.bind(this));
    }

    renderContentHeader(accrediteeName) {
        this.app.renderContentHeader(React.createElement(AccrediteeContentHeader, { accrediteeName }));
    }

    registerCommonData(eventId) {
        const { data } = this;
        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const periods = data.register('allPeriods', Period.Collection, { event });
        const accreditees = data.register('pagedAccreditees', Accreditee.PageableCollection, { event });

        periods.setIncludes(['days']);
        accreditees.setIncludes(['companies', 'profiles', 'items.groups', 'bookings.artist', 'permissions']);

        return { account, event, periods, accreditees };
    }

    registerCommonFormData(eventId) {
        const { data } = this;
        const { account, event, periods, accreditees } = this.registerCommonData(eventId);
        const contacts = data.register('allContacts', Contact.Collection, { account });
        const profiles = data.register('allProfiles', Profile.Collection, { event });
        const items = data.register('allItems', EventItem.Collection, { event });

        contacts.setFilters({ unused_accreditees: eventId });
        profiles.setIncludes(['days']);
        items.setIncludes(['groups']);

        return { account, event, periods, accreditees, contacts, profiles, items };
    }

    overview(eventId) {
        const { app } = this;
        app.renderSyncingView();
        this.renderContentHeader();

        const { account, event, periods, accreditees } = this.registerCommonFormData(eventId);

        this.when(
            account.fetch(),
            event.fetch(),
            periods.fetch(),
            accreditees.fetch()
        ).done(() => {
            const days = periods.getAllDays();
            app.renderContentView(new AccrediteesOverview({ accreditees, days }));
        });
    }

    create(eventId) {
        const { app } = this;
        app.renderSyncingView();
        this.renderContentHeader();

        const { account, event, periods, accreditees, contacts, profiles, items }
            = this.registerCommonFormData(eventId);

        // fetch data and render content view
        this.when(
            account.fetch(),
            event.fetch(),
            contacts.fetch(),
            periods.fetch(),
            profiles.fetch(),
            items.fetch()
        ).done(() => {
            app.renderContentView(
                new CustomAccrediteeCreate({ event, contacts, profiles, items, periods, accreditees })
            );
        });
    }

    edit(eventId, accrediteeId) {
        const { app, data } = this;
        app.renderSyncingView();
        this.renderContentHeader();

        const { account, event, periods, accreditees, contacts, profiles, items }
            = this.registerCommonFormData(eventId);
        const accreditee = data.register('customAccreditee', CustomAccreditee.Model, { event });

        accreditee.set('id', accrediteeId);
        accreditee.setIncludes([
            'profiles.days',
            'profiles.granted_days',
            'profiles.available_day',
            'items.granted_days'
        ]);
        accreditee.bustCache();
        this.when(
            account.fetch(),
            event.fetch(),
            contacts.fetch(),
            periods.fetch(),
            profiles.fetch(),
            items.fetch(),
            accreditee.fetch()
        ).done(() => {
            this.renderContentHeader(accreditee.get('fullname'));
            app.renderContentView(
                new CustomAccrediteeUpdate({ event, contacts, accreditee, profiles, items, periods, accreditees })
            );
        });
    }

}
