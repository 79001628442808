import { connect } from 'react-redux';
import { getFormFieldErrorsFromResponseJSON } from 'app/react/helpers/_index';
import { enhanceStateWithHelpers } from 'app/react/entities/enhanceStateWithHelpers';
import * as actions from 'app/react/state/actions/_index';
import Modal from '../GroupModal';
import * as State from './GroupModalState';
import * as ItemGroup from 'app/react/entities/accounts/item-groups/index';

const FAILED_POPUP = 'Failed to update group.';
const SUCCESSFUL_POPUP = 'Group successfully updated.';

const mapStateToProps = (state) => {
    const { helpers } = enhanceStateWithHelpers(state);
    const { accountArtistItemGroups } = helpers;

    const {
        isOpened,
        isSaving,
        values = {},
        errors = {},
        groupId
    } = State.getUpdateModalState(state);
    return {
        isCreating: false,
        isOpened,
        values,
        errors,
        isSaving,
        groupId,

        groups: accountArtistItemGroups
            .getAllForCurrentContext()
            .filter((group) => group.id !== groupId),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSave: () => {
        const { groupId, values, groups } = stateProps;
        dispatchProps.onSave(groupId, values, groups);
    }
});

const mapDispatchToProps = (dispatch) => ({
    onClosed: () => {
        dispatch((State.setUpdateModalStateValue('isOpened', false)));
    },
    onChangeValue: (key, value) => {
        dispatch(State.setUpdateModalStateValue(`values.${key}`, value));
    },
    onSave: (groupId, values, groups) => {
        dispatch((State.setUpdateModalStateValue('isSaving', true)));
        const apiValues = ItemGroup.schemas.toAPI(values, groups);
        dispatch(ItemGroup.thunks.updateOneForCurrentContext(groupId, apiValues))
            .then(({ error }) => {
                if (typeof error !== 'undefined') {
                    const errors = getFormFieldErrorsFromResponseJSON(error);
                    dispatch(actions.showErrorPopup(FAILED_POPUP));
                    dispatch(State.mergeUpdateModalState({
                        errors: errors,
                        isSaving: false
                    }));
                } else {
                    dispatch(actions.showSuccessPopup(SUCCESSFUL_POPUP));
                    dispatch(State.mergeUpdateModalState({
                        errors: {},
                        values: {},
                        isOpened: false,
                        isSaving: false
                    }));
                }
            });
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Modal);
