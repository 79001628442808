export const base = (
    eventId = ':event'
) => (
    `events/${eventId}/production-website/accreditation`
);

export const form = (
    eventId = ':event'
) => (
    `${base(eventId)}/form`
);

export const pages = (
    eventId = ':event'
) => (
    `${base(eventId)}/pages`
);

