import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Header from 'app/react/components/layout/content/header/Header'

const { TitleMenu, Tabs } = Header;
const { Title, SubTitle } = TitleMenu;
const { Tab } = Tabs;

const EventCompanyDetailsContentHeader = ({
    eventId,
    companyId,
    companyName,
    activeTab
}) => {
    const baseUrl = `/events/${eventId}/accreditation/companies/${companyId}`;
    return (
        <Fragment>
            <Title>Companies</Title>
            {companyName && <SubTitle>{companyName}</SubTitle>}
            <Tabs>
                <Tab activeTab={activeTab} tabId="details" url={`${baseUrl}/details`}>
                    Details
                </Tab>
                <Tab activeTab={activeTab} tabId="settings" url={`${baseUrl}/edit`}>
                    Settings
                </Tab>
            </Tabs>
        </Fragment>
    );
};

EventCompanyDetailsContentHeader.propTypes = {
    eventId: PropTypes.number,
    companyId: PropTypes.number,
    companyName: PropTypes.string,
    activeTab: PropTypes.string
};

export default EventCompanyDetailsContentHeader;
