import * as Forms from './views/forms/_index';

export const sectionMappings = [{
    id: 'contacts',
    Form: Forms.ContactsForm
}, {
    id: 'performers',
    Form: Forms.PerformersForm
}, {
    id: 'crew',
    Form: Forms.CrewForm
}, {
    id: 'files',
    Form: Forms.GeneralForm
}, {
    id: 'riders',
    Form: Forms.GeneralForm
}, {
    id: 'transports',
    Form: Forms.GeneralForm
}, {
    id: 'pickups',
    Form: Forms.GeneralForm
}, {
    id: 'parking_passes',
    Form: Forms.ParkingForm
}, {
    id: 'accommodations',
    Form: Forms.AccommodationForm
}, {
    id: 'guest_lists',
    Form: Forms.GeneralForm
}, {
    id: 'tokens',
    Form: Forms.TokensForm
}, {
    id: 'catering',
    Form: Forms.GeneralForm
}];
