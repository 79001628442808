import Backbone from 'backbone';
import Modal from 'app/backbone/components/modals/Modal.view';
import * as Account from 'app/backbone/models/account/Account';
import * as Event from 'app/backbone/models/event/Event';
import * as Day from 'app/backbone/models/event/Day';
import * as Stage from 'app/backbone/models/event/Stage';
import Form from 'app/backbone/components/forms/Form.mixin';
import template from './DownloadTasksModal.hbs';

const DownloadTasksModal = Modal.extend({

    template,

    mixins: [
        Form
    ],

    ui: {
        tasksDownloadButton: '.download'
    },

    events: {
        'click tasksDownloadButton': 'download',
        'change input[type=checkbox]': 'onChangeConfirmed'
    },

    options: [
        { 'width': 400 }
    ],

    initialize(options) {
        this._super(options);

        const account = new Account.Model().fetchFromDOM();
        const event = new Event.Model(null, { account }).fetchFromDOM();
        this.event = event;
        this.days = new Day.Collection(null, { event }).fetchFromDOM();
        this.stages = new Stage.Collection(null, { event }).fetchFromDOM();
        this.stagesRenamed = new Stage.Collection()
            .setIncludes(['day'])
            .fetchFromDOM();
        this.stagesRenamed.forEach((stage) => {
            stage.set('name', `${stage.getRelation('day').get('name')} - ${stage.get('name')}`);
        });

        this.days.add({ id: 0, name: 'All Days' }, { at: 0 });
        this.stages.add({ id: 0, name: 'All Stages' }, { at: 0 });
        this.stagesRenamed.add({ id: 0, name: 'All Stages' }, { at: 0 });
    },

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.delegateEvents();
    },

    initForm() {
        const { days, stagesRenamed } = this;
        this.inputs = new Backbone.ChildViewContainer();

        this.daysSelectize = this.initSingleSelectize('days', { collection: days });
        this.stagesSelectize = this.initSingleSelectize('stages', { collection: stagesRenamed });
        this.inputs.add(this.daysSelectize, 'days');
        this.inputs.add(this.stagesSelectize, 'stages');
        this.listenTo(this.daysSelectize, 'selected', this.onDaySelected);
    },

    renderForm() {
        this.inputs.call('render');
    },

    open() {
        this.render();
        this.initForm();
        this.delegateEvents();
        this.renderForm();
        this.show();
    },

    download() {
        const event = this.event;
        const dayId = this.daysSelectize.getSelectedValue();
        const stageId = this.stagesSelectize.getSelectedValue();
        const confirmed = this.confirmedOnly ? 1 : 0;
        navigate(
            `/events/${event.id}/artists/reports/tasks?day=${dayId}&stage=${stageId}&confirmed=${confirmed}`,
            { pageReload: true }
        );
    },

    onDaySelected(day) {
        const { stages, stagesRenamed } = this;
        if (day.get('id') !== 0) this.stagesSelectize.setCollection(stages.filterByDay(day));
        else this.stagesSelectize.setCollection(stagesRenamed);
    },

    onChangeConfirmed(e) {
        this.confirmedOnly = this.$(e.currentTarget).prop('checked');
    }
});

export default DownloadTasksModal;
