import { html } from 'common-tags';
import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Content from 'app/backbone/components/layout/content/Content.view';
import * as Day from 'app/backbone/models/event/Day'
import * as Accreditee from 'app/backbone/models/event/accreditation/accreditees/Accreditee';
import AccrediteesOverviewCard from '../cards/AccrediteesOverviewCard.view';

export default class AccrediteesOverview extends Content {

    ui() {
        return {
            accrediteesCard: '#accreditees-card'
        };
    }

    optionTypes() {
        return {
            accreditees: OptionTypes.instanceOf(Accreditee.PageableCollection).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);
        // Subviews
        this.accrediteesCard = this.initAccrediteesCard();
    }

    render() {
        this.$el.html(this.template());
        this.resolveHandles();
        this.assignSubview(this.accrediteesCard, this.ui.accrediteesCard);
        return this.delegateEvents();
    }

    template() {
        return html`<div id="accreditees-card" class="card"></div>`;
    }

    remove() {
        super.remove();
        this.accrediteesCard.remove();
    }

    initAccrediteesCard() {
        const { accreditees, days } = this;
        const title = 'Overview';
        return new AccrediteesOverviewCard({ accreditees, days, title });
    }
}
