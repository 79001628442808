import React from 'react'
import PropTypes from 'prop-types'
import Tab from './Tab'

const Tabs = ({ children }) => (
    <ul className="tabs">
        {children}
    </ul>
);

Tabs.propTypes = {
    children: PropTypes.node,
};

Tabs.Tab = Tab;

export default Tabs;

