import { schema } from 'normalizr';
import { CALL_API, METHODS } from 'app/react/state/middleware/api';
import { createPaginationReducer, createEntityReducer } from 'app/react/state/reducers/higher_order/_index';
import {
    getAllEntities,
    getOneEntity,
    getEntitiesPagination,
    isFetchingEntities,
    getCurrentAccountId
} from 'app/react/state/helpers';

export const AVAILABLE_REQUEST = 'AVAILABLE_INTEGRATIONS_REQUEST';
export const AVAILABLE_SUCCESS = 'AVAILABLE_INTEGRATIONS_SUCCESS';
export const AVAILABLE_FAILURE = 'AVAILABLE_INTEGRATIONS_FAILURE';

export const ACTIVATED_REQUEST = 'ACTIVATED_INTEGRATIONS_REQUEST';
export const ACTIVATED_SUCCESS = 'ACTIVATED_INTEGRATIONS_SUCCESS';
export const ACTIVATED_FAILURE = 'ACTIVATED_INTEGRATIONS_FAILURE';

export const AUTHENTICATE_REQUEST = 'AUTHENTICATE_INTEGRATIONS_REQUEST';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_INTEGRATIONS_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_INTEGRATIONS_FAILURE';

export const AVAILABLE_REDUCER_KEY = 'availableIntegrations';
export const AVAILABLE_PAGINATION_REDUCER_KEY = AVAILABLE_REDUCER_KEY;
const availableEntity = new schema.Entity(AVAILABLE_REDUCER_KEY);
const availableArray = new schema.Array(availableEntity);

export const ACTIVATED_REDUCER_KEY = 'activatedIntegrations';
export const ACTIVATED_PAGINATION_REDUCER_KEY = ACTIVATED_REDUCER_KEY;
const activatedEntity = new schema.Entity(ACTIVATED_REDUCER_KEY);
const activatedArray = new schema.Array(activatedEntity);

const getAllAvailable = (state) =>
    getAllEntities(state, AVAILABLE_REDUCER_KEY);

const getAllActivated = (state) =>
    getAllEntities(state, ACTIVATED_REDUCER_KEY);

const getActivated = (state, id) =>
    getOneEntity(state, ACTIVATED_REDUCER_KEY, id);

const getAvailablePagination = (state) =>
    getEntitiesPagination(state, AVAILABLE_REDUCER_KEY, AVAILABLE_PAGINATION_REDUCER_KEY);

const getActivatedPagination = (state) =>
    getEntitiesPagination(state, ACTIVATED_REDUCER_KEY, ACTIVATED_PAGINATION_REDUCER_KEY);

const isFetchingAllAvailable = (state) =>
    isFetchingEntities(state, AVAILABLE_PAGINATION_REDUCER_KEY);

const isFetchingAllActivated = (state) =>
    isFetchingEntities(state, ACTIVATED_PAGINATION_REDUCER_KEY);

export const stateHelpers = {
    getAllAvailable,
    getAllActivated,
    getActivated,
    getAvailablePagination,
    getActivatedPagination,
    isFetchingAllAvailable,
    isFetchingAllActivated
};

const availablePaginationReducer = createPaginationReducer({
    types: [
        AVAILABLE_REQUEST,
        AVAILABLE_SUCCESS,
        AVAILABLE_FAILURE
    ]
});

const activatedPaginationReducer = createPaginationReducer({
    types: [
        ACTIVATED_REQUEST,
        ACTIVATED_SUCCESS,
        ACTIVATED_FAILURE
    ]
});

const activatedEntityReducer = createEntityReducer({
    types: [
        ACTIVATED_REQUEST,
        ACTIVATED_SUCCESS,
        ACTIVATED_FAILURE
    ]
});

export const reducers = {
    availablePagination: availablePaginationReducer,
    activatedPagination: activatedPaginationReducer,
    activatedEntity: activatedEntityReducer
};

const Endpoints = {
    allAvailable: 'integrations',
    allActivated: (accountId) => `accounts/${accountId}/integrations`,
    deactivate: (accountId, integrationId) => `accounts/${accountId}/integrations/${integrationId}`,
    authenticate: (accountId, integrationName) => `accounts/${accountId}/settings/integrations/${integrationName}`
};

const fetchAllAvailable = () => ({
    [CALL_API]: {
        types: [
            AVAILABLE_REQUEST,
            AVAILABLE_SUCCESS,
            AVAILABLE_FAILURE
        ],
        endpoint: Endpoints.allAvailable,
        schema: availableArray
    }
});

const fetchAllActivated = (accountId) => ({
    [CALL_API]: {
        types: [
            ACTIVATED_REQUEST,
            ACTIVATED_SUCCESS,
            ACTIVATED_FAILURE
        ],
        endpoint: Endpoints.allActivated(accountId),
        schema: activatedArray
    }
});

const deactivate = (accountId, integrationId) => ({
    [CALL_API]: {
        types: [
            ACTIVATED_REQUEST,
            ACTIVATED_SUCCESS,
            ACTIVATED_FAILURE
        ],
        method: METHODS.DELETE,
        id: integrationId,
        endpoint: Endpoints.deactivate(accountId, integrationId),
        schema: activatedEntity
    }
});

const authenticate = (accountId, integrationName, values) => ({
    [CALL_API]: {
        types: [
            AUTHENTICATE_REQUEST,
            AUTHENTICATE_SUCCESS,
            AUTHENTICATE_FAILURE
        ],
        method: METHODS.POST,
        body: values,
        endpoint: Endpoints.authenticate(accountId, integrationName),
        schema: activatedEntity
    }
});

const loadAllAvailable = () => (dispatch, getState) => {
    const state = getState();
    const { pageCount = 0 } = getAvailablePagination(state);
    if (pageCount > 0) return null;
    return dispatch(fetchAllAvailable());
};

const loadAllActivated = () => (dispatch, getState) => {
    const state = getState();
    const { pageCount = 0 } = getActivatedPagination(state);
    if (pageCount > 0) return null;
    const accountId = getCurrentAccountId(state);
    return dispatch(fetchAllActivated(accountId));
};

export const schemas = {
    availableEntity,
    availableArray,
    activatedEntity,
    activatedArray
};
export const actions = {
    fetchAllAvailable,
    loadAllAvailable,
    fetchAllActivated,
    loadAllActivated,
    deactivate,
    authenticate
};
