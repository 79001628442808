import { connect } from 'react-redux';
import * as Event from 'app/react/entities/events/index';
import Content from './Content';

const mapStateToProps = (state) => {
    const { manageLogistics } = Event.stateHelpers.getUserPermissions(state);
    return {
        userCanManageLogistics: manageLogistics,
    };
};

export default connect(
    mapStateToProps
)(Content);
