import { CALL_API, METHODS } from 'app/react/state/middleware/api'
import * as Endpoints from './endpoints'
import * as Schemas from './schemas'
import { setCurrentContextId } from 'app/react/state/actions/_index'

export const ONE_REQUEST = 'ONE_EVENT_REQUEST';
export const ONE_SUCCESS = 'ONE_EVENT_SUCCESS';
export const ONE_FAILURE = 'ONE_EVENT_FAILURE';
export const ONE_TYPES = [
    ONE_REQUEST,
    ONE_SUCCESS,
    ONE_FAILURE
];

export const setCurrentId = id => setCurrentContextId(Schemas.ENTITY_CONTEXT_ID_KEY, id);

export const fetchOne = (accountId, eventId, includes) => ({
    [CALL_API]: {
        types: ONE_TYPES,
        method: METHODS.GET,
        endpoint: Endpoints.fetchOne(accountId, eventId, includes),
        id: eventId,
        schema: Schemas.entity
    },
    accountId,
    eventId
});
