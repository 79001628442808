import React from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import ContentHeader, { TABS } from '../../views/ContentHeader';
import PageContent from '../../../common/pages/views/Content';

const PagesOverview = ({
    store,
    eventId,
    isFetching = false,
    isArtistTypesEnabled = true,
    productionWebsiteUrl,
    pages = [],
    modalState = {
        isOpened: true,
        isSyncing: false,
        isCreating: true,
        values: {},
        errors: {}
    },
    confirmModalState = {
        isOpened: true,
        isSyncing: false
    },
    onConfirmModalConfirmed = () => {},
    onConfirmModalClosed = () => {},
    onModalClosed = () => {},
    onModalSave = () => {},
    onModalChangeFormValue = () => {},
    onCreate = () => {},
    onUpdate = () => {},
    onDelete = () => {},
    updatePageSequence = () => {},
    updateHoverState = () => {},
    dropOutsideList = () => {}
}) => (
    <ContentWrapper store={store}>
        <ContentHeader
            activeTab={TABS.PAGES.ID}
            eventId={eventId}
        />
        <PageContent
            isFetching={isFetching}
            isArtistTypesEnabled={isArtistTypesEnabled}
            productionWebsiteUrl={productionWebsiteUrl}
            pages={pages}
            confirmModalState={confirmModalState}
            modalState={modalState}
            onConfirmModalConfirmed={onConfirmModalConfirmed}
            onConfirmModalClosed={onConfirmModalClosed}
            onModalClosed={onModalClosed}
            onModalSave={onModalSave}
            onModalChangeFormValue={onModalChangeFormValue}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            updatePageSequence={updatePageSequence}
            updateHoverState={updateHoverState}
            dropOutsideList={dropOutsideList}
        />
    </ContentWrapper>
);

PagesOverview.propTypes = {
    store: PropTypes.object.isRequired,
    eventId: PropTypes.number.isRequired,
    ...PageContent.propTypes
};

export default PagesOverview;
