import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import by from 'thenby';
import { SearchSelect } from 'ui-kit';

export const PLACEHOLDER = 'Select group...';
export const NO_RESULTS_PLACEHOLDER = 'No group found.';

const toOption = ({
    id,
    name,
    parent
}) => ({
    value: id,
    label: `${(parent || {}).name} ${name}`,
    name,
    parent,
});

const toGroupWithParent = (groups) => (group) => ({
    ...group,
    parent: groups.find(({ id }) => id === group.parent)
});

const ItemGroupOption = ({
    option = {}
}) => {
    if (typeof option.parent !== 'undefined') {
        return (
            <Fragment>
                <span>{option.parent.fullName}</span> - <span><strong>{option.name}</strong></span>
            </Fragment>
        )
    }
    return (
        <Fragment>
            <span><strong>{option.name}</strong></span>
        </Fragment>
    )
};

const SelectItemGroup = ({
    placeholder = PLACEHOLDER,
    noResultsPlaceholder = NO_RESULTS_PLACEHOLDER,
    disabled = false,
    value,
    groups = [],
    onChange = () => {},
}) => (
    <SearchSelect
        placeholder={placeholder}
        noResultsPlaceholder={noResultsPlaceholder}
        value={value}
        isDisabled={disabled}
        options={groups
            .map(toGroupWithParent(groups))
            .sort(by(group => group.fullName, { ignoreCase: true }))
            .map(toOption)
        }
        onChange={onChange}
        renderOption={option => <ItemGroupOption option={option} />}
    />
);

SelectItemGroup.propTypes = {
    placeholder: PropTypes.string,
    noResultsPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    modes: PropTypes.array,
    onChange: PropTypes.func,
};

export default SelectItemGroup;
