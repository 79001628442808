import { oneLineTrim } from 'common-tags';
import { imitAllParam as pageSizeAllParam } from 'app/react/state/middleware/api'

export const fetchAll = (
    accountId,
    filters = {},
    includes = []
) => oneLineTrim`
    booking-types?
    ${pageSizeAllParam}
`;
