import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BackboneView } from 'app/react/components/_index';
import * as stateHelpers from 'app/react/state/helpers';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import ContentHeader, { TABS } from '../ContentHeader';
import PeriodsContent from 'app/backbone/views/event/settings/accreditation/periods/views/PeriodsContent.view';

const { Content } = ContentWrapper;

const PeriodsView = ({
    periods,
    isLoading,
    eventId
}) => (
    <ContentWrapper>
        <ContentHeader activeTab={TABS.PERIODS.ID} eventId={eventId} />
        <Content isLoading={isLoading} >
            <BackboneView viewClass={PeriodsContent} periods={periods} />
        </Content>
    </ContentWrapper>
);

PeriodsView.propTypes = {
    store: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    // State
    isLoading: PropTypes.bool.isRequired,
    eventId: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: stateHelpers.isLoading(state),
    eventId: stateHelpers.events.getCurrentId(state)
});

export default connect(mapStateToProps)(PeriodsView);
