import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui-kit';

const { Header, Content, Footer } = Card;
const { Title } = Header;
const { Error } = Footer;

export const TITLE = 'Intro';

export const IntroCard = ({
    footer = {},
    children
}) => (
    <Card>
        <Header>
            <Title>{TITLE}</Title>
        </Header>
        <Content>{children}</Content>
        <Footer>
            {footer.message ? <Error>{footer.message}</Error> : null}
        </Footer>
    </Card>
);

IntroCard.propTypes = {
    footer: PropTypes.object,
    children: PropTypes.node
};

export default IntroCard;
