import { oneLineTrim } from 'common-tags';
import {
    includeParam,
    pageParam,
    filterParam,
    limitParam as pageSizeParam,
    limitAllParam as pageSizeAllParam,
    searchParam,
    sortParam
} from 'app/react/state/middleware/api'

export const fetchOne = (
    eventId,
    bookingId,
    includes = []
) => oneLineTrim`
    events/${eventId}/bookings/${bookingId}?
    ${includeParam(includes)}
`;

export const createOne = (
    eventId,
    includes = []
) => oneLineTrim`
    events/${eventId}/bookings?
    ${includeParam(includes)}
`;

export const createOneForPerformance = (
    eventId,
    performanceId,
    includes = []
) => oneLineTrim`
    events/${eventId}/performances/${performanceId}/bookings?
    ${includeParam(includes)}
`;

export const updateOne = (
    eventId,
    bookingId,
    includes = []
) => oneLineTrim`
    events/${eventId}/bookings/${bookingId}?
    ${includeParam(includes)}
`;

export const updateEntranceOne = (
    eventId,
    bookingId
) => oneLineTrim`
    events/${eventId}/bookings/${bookingId}/entrance?
`;

export const deleteOne = (
    eventId,
    bookingId
) => `events/${eventId}/bookings/${bookingId}`;

export const fetchAll = (
    eventId,
    filters = {},
    includes = [],
    searchTerm
) => oneLineTrim`
    events/${eventId}/bookings?
    ${pageSizeAllParam}&
    ${filterParam(filters)}&
    ${includeParam(includes)}&
    ${searchParam(searchTerm)}
`;

export const fetchPaginated = (
    eventId,
    page = 1,
    pageSize = 100,
    filters = {},
    search = '',
    sortKey = '',
    sortOrder = 1,
    includes = []
) => oneLineTrim`
    events/${eventId}/bookings?
    ${pageParam(page)}&
    ${pageSizeParam(pageSize)}&
    ${filterParam(filters)}&
    ${searchParam(search)}&
    ${sortParam(sortKey, sortOrder)}&
    ${includeParam(includes)}
`;

export const fetchAllByArtistId = (
    eventId,
    artistId,
    filters = {},
    includes = []
) => oneLineTrim`
    events/${eventId}/bookings?
    ${pageSizeAllParam}&
    ${filterParam({ ...filters, artist: artistId })}&
    ${includeParam(includes)}&
`;
