var _ = require('underscore'),
    Backbone = require('backbone');

var ConfirmModal = require('app/backbone/components/modals/ConfirmModal.view');

var template = require('./DenyReasonModal.hbs');

var DenyReasonModal = ConfirmModal.extend({

    template,

    ui: {
        denyReasonTextarea: '#deny-reason'
    },

    confirm() {
        const denyReasonText = this.ui.denyReasonTextarea.val();
        if(typeof this.confirmFn === 'function') this.confirmFn(denyReasonText);
        if(this.autoClose) this.hide();
    }
});

module.exports = DenyReasonModal;
