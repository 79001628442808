import OptionTypes from 'app/backbone/helpers/OptionTypes';
import ItemGroupTable from 'app/backbone/views/account/settings/accreditation/groups/views/tables/ItemGroupTable.view';
import * as EventItemGroup from 'app/backbone/models/event/settings/accreditation/EventItemGroup';

export default class EventItemGroupTable extends ItemGroupTable {

    optionTypes() {
        return {
            itemGroups: OptionTypes.instanceOf(EventItemGroup.PageableCollection).isRequired
        };
    }

}
