import Backgrid from 'backgrid';
import { html } from 'common-tags';

export default class AddedByCell extends Backgrid.Cell {

    className() {
        return 'items-cell';
    }

    initialize(options = {}) {
        super.initialize(options);

        this.companies = this.model.getRelation('companies');
        this.bookings = this.model.getRelation('bookings');
    }

    render() {
        this.$el.html(this.template(this.viewModel()));
        return this.delegateEvents();
    }

    viewModel() {
        return {
            hasCustomProfiles: this.model.get('custom_profiles'),
            bookingNames: this.bookings.map(booking => booking.getRelation('artist').get('name')),
            companyNames: this.companies.map(company => company.get('name'))
        };
    }

    template(viewModel) {
        const { hasCustomProfiles, bookingNames, companyNames } = viewModel;
        const allNames = [ ...bookingNames, ...companyNames ];
        if (hasCustomProfiles) allNames.unshift('Promoter');
        return html`
            <div>
                ${ allNames.map((name, index) => {
                    const hasTwo = allNames.length === 2;
                    const hasMoreThanTwo = allNames.length > 2;
                    const isNotLast = index !== allNames.length-1;
                    return html`
                        <span>
                            ${name || ''}${hasTwo && isNotLast ? ' & ' : ''}${hasMoreThanTwo && index !== isNotLast ? ', ' : ''}
                        </span>
                    `
                })
            }
            </div>
        `;
    }

}
