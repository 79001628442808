import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BackboneView } from 'app/react/components/_index';
import * as stateHelpers from 'app/react/state/helpers';
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper';
import EventContentHeader, { TABS } from '../ContentHeader';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import ZonesContent from 'app/backbone/views/event/settings/accreditation/zones/views/ZonesContent.view';

const { Content } = ContentWrapper;

const ZonesView = ({
    zones,
    isLoading,
    eventId
}) => (
    <ContentWrapper>
        <EventContentHeader activeTab={TABS.ZONES.ID} eventId={eventId} />
        <Content isLoading={isLoading} >
            <BackboneView viewClass={ZonesContent} zones={zones} />
        </Content>
    </ContentWrapper>
);

ZonesView.propTypes = {
    zones: PropTypes.instanceOf(Zone.PageableCollection).isRequired,
    isLoading: PropTypes.bool.isRequired,
    eventId: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: stateHelpers.isLoading(state),
    eventId: stateHelpers.events.getCurrentId(state)
});

export default connect(mapStateToProps)(ZonesView);
