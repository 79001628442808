import { schema } from 'normalizr';

export const ENTITY_REDUCER_KEY = 'performances';

const KEYS = {
    DAY: 'day',
    STAGE: 'stage',
    BOOKINGS: 'bookings',
    TIME_SLOTS: 'timeSlots'
};

const entityDefinition = {
    [KEYS.DAY]: new schema.Entity('days'),
    [KEYS.STAGE]: new schema.Entity('stages'),
};

const processStrategy = (entity) => ({
    ...entity,
    changeover: entity.changeover && entity.changeover / 60
});

export const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition, { processStrategy });
export const entities = new schema.Array(entity);
