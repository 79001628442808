export const form = (
    productionWebsiteId = ':productionWebsite'
) => `production-website/${productionWebsiteId}/form`;

export const base = (
    productionWebsiteId = ':productionWebsite',
    bookingId = ':booking'
) => `production-website/${productionWebsiteId}/bookings/${bookingId}`;

export const performersCreate = (
    productionWebsiteId = ':productionWebsite',
    bookingId = ':booking'
) => `${base(productionWebsiteId, bookingId)}/performers/create`;

export const performersUpdate = (
    productionWebsiteId = ':productionWebsite',
    bookingId = ':booking',
    performerId = ':performer'
) => `${base(productionWebsiteId, bookingId)}/performers/${performerId}/edit`;

export const crewCreate = (
    productionWebsiteId = ':productionWebsite',
    bookingId = ':booking'
) => `${base(productionWebsiteId, bookingId)}/crew/create`;

export const crewUpdate = (
    productionWebsiteId = ':productionWebsite',
    bookingId = ':booking',
    crewMemberId = ':crewMember'
) => `${base(productionWebsiteId, bookingId)}/crew/${crewMemberId}/edit`;
