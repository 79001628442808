import _ from 'underscore';
import { Model, Collection }  from 'app/backbone/models/decorators';
import * as Profile from '../../settings/accreditation/Profile'
import * as GrantedDay from '../../settings/accreditation/GrantedDay';

@Model({
    key: 'accreditee_profile'
})
class AccrediteeProfile extends Profile.Model {

    static Relations() {
        return {
            event: Profile.Model.Relations().event,
            days: Profile.Model.Relations().days,
            granted_days: GrantedDay.Collection
        }
    }

    get defaults() {
        return {
            total_granted: 0
        }
    }

    get validation() {
        return {
            id: {
                required: true,
                msg: 'Select a profile.'
            }
        }
    }

    initialize(model, options = {}) {
        super.initialize(model, options);
        this.listenTo(this.granted_days, 'reset update', this.resetTotalGranted);
        this.resetTotalGranted();
    }

    toJSON(options) {
        let json = super.toJSON(options);
        delete json.days;
        if(this.hasRelation('granted_days')) json.granted_days = this.granted_days.toJSON();
        return json;
    }

    hasGrantedDay() {
        return !this.getRelation('granted_days').isEmpty();
    }

    hasGrantedDayOn(day) {
        return this.getGrantedDayOn(day) ? true : false;
    }

    hasGrantedDayOnEvery(days) {
        return days.every(day => this.hasGrantedDayOn(day));
    }

    getGrantedDayOn(day) {
        return this.getRelation('granted_days').get(day.id);
    }

    addGrantedDayOn(days) {
        this.getRelation('granted_days').add(_.isArray(days) ? days.map(day => day.toGrantedDay()) : days.toGrantedDay());
    }

    addGrantedDayOnAll(days) {
        this.addGrantedDayOn(days.models);
    }

    removeGrantedDayOn(days) {
        this.getRelation('granted_days').remove(_.isArray(days) ? _.pluck(days, 'id') : days.id );
    }

    removeAllGrantedDays() {
        this.getRelation('granted_days').remove(this.getRelation('granted_days').models);
    }

    removeGrantedDayOnAll(days) {
        this.removeGrantedDayOn(days.models)
    }

    resetTotalGranted() {
        this.set('total_granted', this.granted_days.length )
    }
}

@Collection({
    key: 'accreditee_profiles',
    model: AccrediteeProfile
})
class AccrediteeProfileCollection extends Profile.Collection {

    static Relations() {
        return {
            accreditee: require('./CustomAccreditee').Model // Circular
        }
    }

    someHaveGrantedDayOn(day) {
        return this.some(profile => profile.hasGrantedDayOn(day));
    }

    someHaveNoGrantedDays() {
        return this.some(profile => profile.getRelation('granted_days').isEmpty());
    }

    someGrantedByPromoterHaveNoGrantedDays() {
        return this.some(profile => profile.get('granted_by_promoter') && profile.getRelation('granted_days').isEmpty());
    }

    getAllUniqueGrantedDays() {
        const days = new GrantedDay.Collection();
        this.forEach(profile => {
            days.add(profile.getRelation('granted_days').models);
        });
        return days;
    }

    getTotalGranted() {
        let total = 0;
        this.each(profile => { total += profile.get('total_granted') });
        return total;
    }

    filterByGrantedByPromoter() {
        return this.constructor(this.filter(model => model.get('granted_by_promoter')));
    }
}

const PageableCollection = AccrediteeProfileCollection.prototype.PageableCollection();

export {
    AccrediteeProfile as Model,
    AccrediteeProfileCollection as Collection,
    PageableCollection
};
