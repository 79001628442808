import React from 'react'
import ContentWrapper from 'app/react/components/layout/content/ContentWrapper'
import Card from 'app/react/views/event/artists/box-office/components/Card'
import Header from 'app/react/components/layout/content/header/Header'

const { TitleMenu } = Header;
const { Title } = TitleMenu;
const { Content } = ContentWrapper;

const Overview = (props) => (
    <ContentWrapper>
        <Header>
            <TitleMenu>
                <Title>Box office</Title>
            </TitleMenu>
        </Header>
        <Content isLoading={props.isFetching}>
            <Card {...props} />
        </Content>
    </ContentWrapper>
);

export default Overview;
