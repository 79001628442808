import React from 'react'
import PropTypes from 'prop-types'
import { DateInput, Switch } from 'ui-kit'
import { Tooltip } from 'app/react/components/util/_index'

const ENABLED_PROP_KEY = 'enabled';
const ENABLED_LABEL = 'Enabled';
const DEFAULT_NAME_PROP_KEY = 'defaultName';
const DEFAULT_NAME_LABEL = 'Section';
const NAME_PROP_KEY = 'name';
const NAME_LABEL = 'Name';
const DEADLINE_PROP_KEY = 'deadline';
const DEADLINE_LABEL = 'Deadline';
const CLOSED_PROP_KEY = 'closed';
const CLOSED_LABEL = 'Open';
const INTRO_LABEL = 'Intro';

export const TOOLTIPS = {
    EDIT_INTRO: 'Edit intro',
    EDIT_SECTION: name => `Edit ${name.toLowerCase()}`,
    CLOSE_SECTION: (name, isClosed) => `${isClosed ? 'Open' : 'Close'} the ${name} section for all associated bookings.`,
};
const ENABLED_CELL_WIDTH = '70px';
const DEADLINE_CELL_WIDTH = '165px';

export const SectionHeaderRow = () => (
    <tr>
        <th className="center" style={{ width: ENABLED_CELL_WIDTH }}>{ENABLED_LABEL}</th>
        <th>{DEFAULT_NAME_LABEL}</th>
        <th>{NAME_LABEL}</th>
        <th style={{ width: DEADLINE_CELL_WIDTH }}>{DEADLINE_LABEL}</th>
        <th />
        <th className="center">{CLOSED_LABEL}</th>
        <th />
    </tr>
);

export const IntroSectionRow = ({
    onEdit = () => {},
}) => (
    <tr>
        <td />
        <td>{INTRO_LABEL}</td>
        <td />
        <td />
        <td />
        <td />
        <td className="actions">
            <Tooltip content={TOOLTIPS.EDIT_INTRO}>
                <a className="action" onClick={onEdit}>
                    <i className="bs-icon-pencil" />
                </a>
            </Tooltip>
        </td>
    </tr>
);

IntroSectionRow.propTypes = {
    onEdit: PropTypes.func,
};

export const SectionRow = ({
    sectionId,
    section = {},
    dateFormat = 'L',
    onEdit = () => {},
    onChangeValue = () => {},
}) => {
    const isEnabled = !! section.enabled;
    const isDisabled = !isEnabled;
    const isClosed = section.closed;

    return (
        <tr>
            <td className="input center">
                <input
                    type="checkbox"
                    checked={isEnabled}
                    onChange={e => onChangeValue(sectionId, ENABLED_PROP_KEY, e.target.checked)}
                />
            </td>
            <td>{section.defaultName}</td>
            <td className="input">
                <input
                    type="text"
                    disabled={isDisabled}
                    value={section.name || ''}
                    onChange={e => onChangeValue(sectionId, NAME_PROP_KEY, e.target.value)}
                />
            </td>
            <td className="input">
                <DateInput
                    value={section.deadline}
                    dateFormat={dateFormat}
                    disabled={isDisabled}
                    prefixWidth="37px"
                    onChange={value => onChangeValue(sectionId, DEADLINE_PROP_KEY, value)}
                />
            </td>
            <td>
                {
                    section.deadline && !isDisabled &&
                    <Tooltip content={"Clear deadline"}>
                        <a className="action" onClick={() => onChangeValue(sectionId, DEADLINE_PROP_KEY, null)}>
                            <i className="bs-icon-cross" />
                        </a>
                    </Tooltip>
                }
            </td>
            <td className="input center">
                <Tooltip
                    content={TOOLTIPS.CLOSE_SECTION(section.defaultName, isClosed)}
                    position="top right"
                >
                    <Switch
                        isOn={!isClosed}
                        isDisabled={isDisabled}
                        onChange={(e, newValue) => onChangeValue(sectionId, CLOSED_PROP_KEY, !newValue)}
                    />
                </Tooltip>
            </td>
            <td className="actions">
                {isEnabled &&
                    <Tooltip content={TOOLTIPS.EDIT_SECTION(section.defaultName)}>
                        <a className="action" onClick={() => onEdit(sectionId)}>
                            <i className="bs-icon-pencil" />
                        </a>
                    </Tooltip>
                }
            </td>
        </tr>
    );
};

SectionRow.propTypes = {
    sectionId: PropTypes.string.isRequired,
    dateFormat: PropTypes.string,
    section: PropTypes.shape({
        [ENABLED_PROP_KEY]: PropTypes.bool,
        [DEFAULT_NAME_PROP_KEY]: PropTypes.string,
        [NAME_PROP_KEY]: PropTypes.string,
        [DEADLINE_PROP_KEY]: PropTypes.string,
        [CLOSED_PROP_KEY]: PropTypes.bool
    }),
    onEdit: PropTypes.func,
    onChangeValue: PropTypes.func,
};
