import OptionTypes from 'app/backbone/helpers/OptionTypes';
import AccrediteesOverviewCard from 'app/backbone/views/event/accreditation/accreditees/cards/AccrediteesOverviewCard.view';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import CompanyAccrediteeTable from '../tables/CompanyAccrediteeTable.view';

export default class CompanyAccrediteesOverviewCard extends AccrediteesOverviewCard {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            accreditees: OptionTypes.instanceOf(CompanyAccreditee.PageableCollection).isRequired,
            title
        };
    }

    initTable() {
        const { accreditees } = this;
        return new CompanyAccrediteeTable({ accreditees });
    }

    createAccreditee() {
        const company = this.accreditees.getRelation('company');
        const eventId = company.getRelation('event').get('id');
        const companyId = company.get('id');
        navigate(`/events/${eventId}/accreditation/companies/${companyId}/attendees/create`);
    }
}
