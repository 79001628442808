import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as CompanyItem from 'app/backbone/models/event/accreditation/companies/CompanyItem';
import * as Period from 'app/backbone/models/event/settings/accreditation/Period';
import GrantedCompanyItemTable from '../tables/GrantedCompanyItemTable.view';

export default class CompanyItemsCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            items: OptionTypes.instanceOf(CompanyItem.Collection).isRequired,
            periods: OptionTypes.instanceOf(Period.Collection).isRequired,
            editing: OptionTypes.bool
        };
    }

    optionDefaults() {
        return {
            editing: false
        };
    }

    initialize(options = {}) {
        super.initialize(options);
        // If no items are added remove edit button
        if (this.items.isEmpty()) this.header.hideAllActions();
    }

    initContent() {
        const { items, periods, editing } = this;
        return new GrantedCompanyItemTable({ collection: items, periods, editing });
    }

    initHeaderActions() {
        const actions = [];
        if (!this.editing) {
            actions.push({
                name: 'update',
                tooltip: 'Update items',
                icon: 'bs-icon-pencil',
                action: this.updateItems.bind(this)
            });
        }
        return actions;
    }

    updateItems() {
        const company = this.items.getRelation('company');
        const event = company.getRelation('event');
        navigate(`/events/${event.id}/accreditation/companies/${company.id}/items/edit`);
    }

}
