import BaseController from 'app/backbone/helpers/BaseController'
import AccreditationFormController from './accreditation/FormController'
import AccreditationPagesController from './accreditation/PagesController'
import ArtistTypesController from './artists/form/ArtistTypesController'
import ArtistsPagesController from './artists/pages/Controller'
import GeneralOverview from 'app/backbone/views/event/production_webiste/GeneralOverview.view'
import * as Routes from './routes'

export default class ProductionWebsiteController extends BaseController {

    constructor(options = {}) {
        super(options);
        const { router } = this;
        this.subControllers = [
            new AccreditationFormController({ parent: this }),
            new AccreditationPagesController({ parent: this }),
            new ArtistTypesController({ parent: this }),
            new ArtistsPagesController({ parent: this }),
        ];
        router.route(Routes.base(), null, this.general.bind(this));
    }

    general(eventId) {
        this.app.renderContentView(new GeneralOverview());
    }
}
