import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import ArtistTypesOverview from 'app/react/views/event/production_website/artists/artist_types/views/OverviewContainer';
import FormController from './FormController';
import * as Event from 'app/react/entities/events/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Routes from './routes'

export default class Controller extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.artistTypes(), null, this.overview.bind(this));

        this.subControllers = [
            new FormController({ parent: this })
        ];
    }

    overview(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(ArtistTypesOverview, { store }));
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return dispatch(ArtistType.thunks.fetchAllForCurrentContext());
    }

}
