import * as Account from 'app/react/entities/accounts/helpers';
import * as Event from 'app/react/entities/events/helpers';
import * as Schemas from './schemas'
import {
    getCurrentContextId,
    getCurrentContextEntity,
    getOneEntity,
    getEntitiesCollectionByKey,
    getEntitiesPaginationByKey,
    getSomeEntities,
    getSomeEntitiesDenormalized
} from 'app/react/state/helpers';

const {
    ENTITY_REDUCER_KEY,
    ENTITY_CONTEXT_ID_KEY,
    COLLECTION_REDUCER_KEY,
    PAGINATION_REDUCER_KEY
} = Schemas;

export const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);

export const getSome = (state, ids, denormalize = false) => {
    if (denormalize) {
        return getSomeEntitiesDenormalized(state, ENTITY_REDUCER_KEY, Schemas.entity, ids)
    }
    return getSomeEntities(state, ENTITY_REDUCER_KEY, ids);
};

export const getCurrent = (state) =>
    getCurrentContextEntity(state, ENTITY_REDUCER_KEY, ENTITY_CONTEXT_ID_KEY);

export const getCurrentId = (state) =>
    getCurrentContextId(state, ENTITY_CONTEXT_ID_KEY);

export const isFetchingCurrent = (state) =>
    !! (getCurrent(state) || {}).isFetching;

export const getPaginationByKey = (state, key) =>
    getEntitiesPaginationByKey(state, PAGINATION_REDUCER_KEY, key);

export const getCollectionByKey = (state, key) =>
    getEntitiesCollectionByKey(state, COLLECTION_REDUCER_KEY, key);

export const getPaginationForCurrentContext = (state) => {
    const eventContextKey = Event.generateCurrentContextKey(state);
    return getEntitiesPaginationByKey(state, PAGINATION_REDUCER_KEY, eventContextKey);
};

export const getAllByPaginationForCurrentContext = (state, denormalize = false) => {
    const pagination = getPaginationForCurrentContext(state);
    return getSome(state, pagination.ids, denormalize);
};

export const generateBetweenDatesCollectionKeyForCurrentContext = (after, before, state) => {
    const eventContextKey = Event.generateCurrentContextKey(state);
    return `${eventContextKey}/${after}/${before}`;
};

export const getCollectionBetweenDatesForCurrentContext = (after, before, state) => {
    const finalKey = generateBetweenDatesCollectionKeyForCurrentContext(after, before, state);
    return getCollectionByKey(state, finalKey);
};

export const getAllBetweenDatesForCurrentContext = (after, before, state, denormalize = false) => {
    const collection = getCollectionBetweenDatesForCurrentContext(after, before, state);
    return getSome(state, collection.ids, denormalize);
};
