import OptionTypes from 'app/backbone/helpers/OptionTypes';
import Card from 'app/backbone/components/cards/Card.view';
import * as EventCompany from 'app/backbone/models/event/accreditation/companies/Company';
import * as CompanyAccreditee from 'app/backbone/models/event/accreditation/companies/CompanyAccreditee';
import AccrediteesCardContent from './AccrediteesCardContent.view';

export default class AccrediteeCard extends Card {

    optionTypes() {
        const { title } = super.optionTypes();
        return {
            title,
            company: OptionTypes.instanceOf(EventCompany.Model).isRequired,
            accreditees: OptionTypes.instanceOf(CompanyAccreditee.PageableCollection).isRequired
        };
    }

    initialize(options = {}) {
        super.initialize(options);

        this.listenTo(this.accreditees, 'destroy', this.onDeleteAccreditees);
        this.updateHeaderButtons();

        this.content.accrediteesTable.table.columns.findWhere({ label: 'Profiles' }).set('label', 'Credentials');

        if (this.company.isApproved() || this.company.areTicketsSent()) {
            this.collapsed = false;
        }
    }

    initContent() {
        const { accreditees, company } = this;
        return new AccrediteesCardContent({ accreditees, company });
    }

    initHeaderActions() {
        return [{
            name: 'add',
            tooltip: 'Add Attendees',
            icon: 'bs-icon-plus',
            action: this.createAccreditee.bind(this)
        }];
    }

    createAccreditee() {
        const productionWebsiteUrl = window.beatswitch.production_website.slug;
        navigate(`/production-website-accreditation/${productionWebsiteUrl}/company-form/accreditees/create`);
    }

    onDeleteAccreditees() {
        this.company.fetch({ bustCache: true }).done(() => {
            this.updateHeaderButtons();
            this.content.availableProfilesView.render();
        });
    }

    updateHeaderButtons() {
        this.header.toggleHideAction('add', !this.canShowAddButton());
    }

    canShowAddButton() {
        return this.company.getRelation('profiles').someAreAvailable() && this.company.canRequestApproval();
    }

    getUniqueName() {
        const { company } = this;
        return `accrediteesCard/event${company.event.id}company${company.id}`;
    }
}
