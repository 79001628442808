import OptionTypes from 'app/backbone/helpers/OptionTypes'
import PageableTable from 'app/backbone/components/tables/PageableTable.view'
import ConfirmModal from 'app/backbone/components/modals/ConfirmModal.view'
import SelectableRow from 'app/backbone/components/tables/rows/SelectableRow.view'
import * as CellFactory from 'app/backbone/helpers/CellFactory'
import * as Day from 'app/backbone/models/event/Day'
import * as Booking from 'app/backbone/models/event/artists/Booking'
import OfferBookingFeeCell from './cells/OfferBookingFeeCell.view'
import OfferStatusCell from './cells/OfferStatusCell.view'
import OfferSentCell from './cells/OfferSentCell.view'

export default class OfferTable extends PageableTable {

    optionTypes() {
        return {
            bookings: OptionTypes.instanceOf(Booking.PageableCollection).isRequired,
            days: OptionTypes.instanceOf(Day.Collection).isRequired
        };
    }

    initialize(options = {}) {
        this.resolveOptions(options);

        // Props
        this.collection = this.bookings;
        this.row = SelectableRow;
        this.event = this.bookings.getRelation('event');
        this.actions = this.initActions();
        this.columns = this.initColumns();
        this.filtersDef = this.getFiltersDef();
        this.placeholderText = this.getPlaceholderText();
        // SubViews
        this.confirmModal = this.initConfirmModal();
        // Listeners
        this.listenTo(this.bookings, 'backgrid:select', this.details);

        super.initialize(options);
    }

    initColumns() {
        const columns = super.initColumns();
        columns.push({
            name: 'name',
            label: 'Status',
            headerCell: CellFactory.createHeaderCell('100px'),
            cell: OfferStatusCell
        }, {
            name: 'id',
            label: 'Sent',
            headerCell: CellFactory.createHeaderCell('70px', 'center'),
            cell: OfferSentCell
        }, {
            name: 'name',
            label: 'Artist',
            headerCell: CellFactory.createHeaderCell(),
            cell: CellFactory.createSubModelCell(null, null, 'artist')
        });
        if (this.eventIsMultiDay()) {
            columns.push({
                name: 'name',
                label: 'Day',
                headerCell: CellFactory.createHeaderCell(),
                cell: CellFactory.createSubModelCell(null, null, 'performance.day')
            });
        }
        if (this.eventHasStages()) {
            columns.push({
                name: 'name',
                label: 'Stage',
                headerCell: CellFactory.createHeaderCell(),
                cell: CellFactory.createSubModelCell(null, null, 'performance.stage')
            });
        }
        columns.push({
            name: 'artist_fee_formatted',
            label: 'Artist Fee',
            headerCell: CellFactory.createHeaderCell(),
            cell: CellFactory.createSubCollectionFirstCell(null, null, 'offers')
        }, {
            name: 'booking_fee_formatted',
            label: 'Booking Fee',
            headerCell: CellFactory.createHeaderCell(),
            cell: OfferBookingFeeCell
        }, {
            name: 'total_fee_formatted',
            label: 'Total',
            headerCell: CellFactory.createHeaderCell(),
            cell: CellFactory.createSubCollectionFirstCell(null, null, 'offers')
        }, {
            name: 'actions',
            label: 'Actions',
            headerCell: CellFactory.createHeaderCell('125px'),
            cell: CellFactory.createActionCell(this.actions),
            sortable: false,
            editable: false
        });
        return columns;
    }

    getFiltersDef() {
        const filters = super.getFiltersDef();
        if (this.eventIsMultiDay()) {
            const { days } = this;
            filters.push({
                name: 'day',
                label: 'Day',
                placeholder: 'All Days',
                collection: days,
                filterName: 'day',
                optionValue: 'id',
                optionName: (day) => `${day.get('start_date_short')} ${day.get('name')}`
            });
        }
        return filters;
    }

    initActions() {
        return [{
            name: 'add',
            icon: 'bs-icon-plus',
            tooltip: 'Add offer',
            action: this.add.bind(this),
            canRender: (booking) => !booking.hasOffer()
        }, {
            name: 'edit',
            icon: 'bs-icon-pencil',
            tooltip: 'Edit offer',
            action: this.edit.bind(this),
            canRender: (booking) => booking.hasOffer()
        }, {
            name: 'send',
            icon: 'bs-icon-envelope',
            tooltip: 'Send offer',
            action: this.send.bind(this),
            canRender: (booking) => booking.hasOffer()
        }, {
            name: 'download',
            icon: 'bs-icon-download',
            tooltip: 'Download offer',
            action: this.download.bind(this),
            canRender: (booking) => booking.hasOffer()
        }];
    }

    details(booking) {
        const event = booking.getRelation('event');
        navigate(`/events/${event.id}/artists/bookings/${booking.id}/offers`, { pageReload: true });
    }

    add(booking) {
        const event = booking.getRelation('event');
        navigate(`/events/${event.id}/artists/bookings/${booking.id}/offers/create`, { pageReload: true });
    }

    edit(booking) {
        const event = booking.getRelation('event');
        const offer = booking.getCurrentOffer();
        navigate(`/events/${event.id}/artists/bookings/${booking.id}/offers/${offer.id}/edit`, { pageReload: true });
    }

    send(booking) {
        const event = booking.getRelation('event');
        const offer = booking.getCurrentOffer();
        navigate(`/events/${event.id}/artists/bookings/${booking.id}/offers/${offer.id}/send`, { pageReload: true });
    }

    download(booking) {
        const event = booking.getRelation('event');
        const offer = booking.getCurrentOffer();
        navigate(`/events/${event.id}/artists/bookings/${booking.id}/offers/${offer.id}/download`, { pageReload: true });
    }

    initConfirmModal() {
        return new ConfirmModal();
    }

    getPlaceholderText() {
        return 'No bookings have been added for this event yet.';
    }

    eventIsMultiDay() {
        const days = this.event.getRelation('days');
        return days && days.length > 1;
    }

    eventHasStages() {
        const stages = this.event.getAllStages();
        return stages && stages.length > 0;
    }

}
