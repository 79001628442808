import React from 'react';
import PropTypes from 'prop-types';
import { NoRecordsRow, Price } from 'app/react/components/_index'

const LastColumnsItemsTable = ({
    currencySymbol,
    items = [],
    sortKey,
    sortOrder,
    onSort = () => {},
}) => (
    <table className="overflow">
        <thead>
            <tr>
                <th className="center">
                    Total amount
                </th>
                <th style={{ textAlign: 'right' }}>
                    Total price
                </th>
            </tr>
        </thead>
        <tbody>
            {items.length === 0
                ? <NoRecordsRow colSpan={2} showText={false} />
                : items.map(item => (
                    <tr key={item.id}>
                        <td className="center">
                            {item.amount}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <Price
                                amount={item.total}
                                currencySymbol={currencySymbol}
                            />
                        </td>
                    </tr>
                ))
            }
        </tbody>
    </table>
);

LastColumnsItemsTable.propTypes = {
    currencySymbol: PropTypes.string,
    items: PropTypes.array,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.number,
    onSort: PropTypes.func
};

export default LastColumnsItemsTable;
