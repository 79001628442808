import React  from 'react';
import propTypes from 'prop-types';
import Loading from '../util/Loading'

const HEIGHT = '300px';

const LoadingPlaceholder = ({
    height = HEIGHT
}) => (
    <Loading height={height} />
);

Loading.propTypes = {
    height: propTypes.string
};

export default LoadingPlaceholder;
