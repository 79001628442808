
import PropTypes from 'prop-types';

export const EventStateType = PropTypes.shape({
	activeStep: PropTypes.number,
	activeSubStep: PropTypes.number,
	steps: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		subSteps: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number,
			title: PropTypes.string,
			subTitle: PropTypes.string,
			tips: PropTypes.arrayOf(PropTypes.shape({
				type: PropTypes.oneOf(['tip', 'note']).isRequired,
				message: PropTypes.string.isRequired,
				title: PropTypes.string
			}))
		}))
	})),
})

export const TipType = PropTypes.shape({
	type: PropTypes.oneOf(['tip', 'note']).isRequired,
	message: PropTypes.string.isRequired,
	title: PropTypes.string
})

export const Event = PropTypes.shape({
	name: PropTypes.string.isRequired,
	venue: PropTypes.number.isRequired,
	website: PropTypes.string,
	email: PropTypes.string,
	telephone: PropTypes.string,
	facebook: PropTypes.string,
	start_date: PropTypes.any.isRequired,
	end_date: PropTypes.any.isRequired,
	unit_of_distance: PropTypes.string,
	time_format: PropTypes.string,
	date_format: PropTypes.string,
	currency: PropTypes.string,
	// this hangs on the venue
	// address: PropTypes.shape({
	// 	street: PropTypes.string.isRequired,
	// 	address_line_2: PropTypes.string.isRequired,
	// 	city: PropTypes.string.isRequired,
	// 	zip: PropTypes.string.isRequired,
	// 	state: PropTypes.string.isRequired,
	// 	country: PropTypes.string.isRequired,
	// }),
	days: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		starts_at: PropTypes.string.isRequired,
		ends_at: PropTypes.string.isRequired,
		stages: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			sequence: PropTypes.string.isRequired,
			capacity: PropTypes.number.isRequired,
			address: PropTypes.shape({
				name: PropTypes.string,
				address_line_1: PropTypes.string,
				address_line_2: PropTypes.string,
				city: PropTypes.string,
				zip: PropTypes.string,
				state: PropTypes.string,
				country: PropTypes.string,
			})
		}))
	}))
})