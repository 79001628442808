import BaseController from 'app/backbone/helpers/BaseController';
import ProductionWebsiteFormView from 'app/backbone/views/event/production_webiste/form/ProductionWebsiteForm.view';
import * as Routes from './routes'

// Backbone Controller
export default class FormController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(Routes.form(), null, this.form.bind(this));
    }

    form() {
        this.app.renderContentView(new ProductionWebsiteFormView());
    }

}
