import { uploadFile } from '../data_sources/upload';

export const CALL_UPLOAD = Symbol('Call Upload');

export default store => next => action => {
    const callUpload = action[CALL_UPLOAD];
    if (typeof callUpload === 'undefined') {
        return next(action);
    }

    let { jwt } = callUpload;
    const { types, file, fileType, schema } = callUpload;

    if (typeof jwt === 'undefined') {
        jwt = store.getState().tokens.jwt;
    }
    if (!schema) {
        throw new Error('Specify one of the exported Schemas.');
    }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    const actionWith = data => {
        const finalAction = {
            ...action,
            ...data
        };
        delete finalAction[CALL_UPLOAD];
        return finalAction;
    };

    const [requestType, successType, failureType] = types;
    next(actionWith({ type: requestType }));

    return uploadFile(jwt, file, fileType, schema).then(
        response => next(actionWith({
            type: successType,
            response
        })),
        error => next(actionWith({
            type: failureType,
            error: error || 'Something bad happened'
        }))
    );
};
