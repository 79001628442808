import React from 'react';
import BaseController from 'app/backbone/helpers/BaseController';
import { loadData } from 'app/react/helpers/_index';
import * as Day from 'app/backbone/models/event/Day';
import * as Zone from 'app/backbone/models/event/settings/accreditation/Zone';
import * as Profile from 'app/backbone/models/event/settings/accreditation/Profile';
import ProfilesView from 'app/react/views/event/settings/accreditation/profiles/ProfilesView';
import ProfileCreateView from 'app/react/views/event/settings/accreditation/profiles/ProfileCreateView';
import ProfileUpdateView from 'app/react/views/event/settings/accreditation/profiles/ProfileUpdateView';

// Backbone Controller
export default class ProfileController extends BaseController {

    registerCommonData(eventId) {
        const { data } = this;
        const { account, event } = data.registerCurrentEventAndAccount(eventId);
        const zones = data.register('allZones', Zone.Collection, { event });
        const days = data.register('allAccreditationDays', Day.Collection, { event });
        const profiles = data.register('pagedProfiles', Profile.PageableCollection, { event });
        days.setFilters({ day_type: 'accreditation' });
        return { account, event, zones, days, profiles };
    }

    overview(eventId) {
        const { app, store } = this;
        const { zones, profiles } = this.registerCommonData(eventId);
        profiles.setIncludes(['zones']);

        app.renderContentComponent(React.createElement(ProfilesView, { profiles, zones, store }));
        loadData({ models: [zones, profiles] });
    }

    create(eventId) {
        const { app, store } = this;
        const { event, zones, days, profiles } = this.registerCommonData(eventId);

        app.renderContentComponent(
            React.createElement(ProfileCreateView, { event, profiles, zones, days, store })
        );
        loadData({ models: [zones, days] });
    }

    edit(eventId, profileId) {
        const { app, data, store } = this;
        const { event, zones, days, profiles } = this.registerCommonData(eventId);
        const profile = data.register('profile', Profile.Model, { event });

        profile.set('id', parseInt(profileId, 10));
        profile.setIncludes(['zones', 'days']);

        app.renderContentComponent(
            React.createElement(ProfileUpdateView, { event, profiles, profile, zones, days, store })
        );
        loadData({ models: [profiles, profile, zones, days] });
    }

}
