import React, { Fragment } from 'react'
import { Tooltip } from 'ui-kit'
import { isEmpty } from 'lodash'
import Stars from 'app/react/components/rating/Stars'
import MapsLink from 'app/react/components/address/MapsLink'

const AccommodationHeaderActions = ({ accommodation })  => {
    const {
        stars,
        address,
        email,
        telephone,
        website,
    } = accommodation;

    return (
        <Fragment>
            {stars && (
                <span className="margin-tiny-right">
                    <Stars rating={stars} />
                </span>
            )}
            {!isEmpty(address) && (
                <MapsLink
                    address={address}
                    className="headerAction"
                />
            )}
            {email && (
                <Tooltip renderContent={() => `Send an email to ${accommodation.name}`}>
                    <a
                        href={`mailto:${email}`}
                        className="headerAction"
                    >
                        <i className="bs-icon-send" />
                    </a>
                </Tooltip>
            )}
            {telephone && (
                <Tooltip renderContent={() => `Call ${accommodation.name}`}>
                    <a
                        href={`tel:${telephone}`}
                        className="headerAction"
                    >
                        <i className="bs-icon-phone" />
                    </a>
                </Tooltip>
            )}
            {website && (
                <Tooltip renderContent={() => `Visit ${website}`}>
                    <a
                        href={website}
                        target="_blank"
                        className="headerAction"
                    >
                        <i className="bs-icon-link" />
                    </a>
                </Tooltip>
            )}
        </Fragment>
    );
};

export default AccommodationHeaderActions;
