import { connect } from 'react-redux'
import * as stateHelpers from 'app/react/state/helpers'
import OverviewView from './OverviewView'

const mapStateToProps = state => ({
    accountId: stateHelpers.accounts.getCurrentId(state),
    canManageAccount: !!stateHelpers.accounts.getCurrent(state).permissions.manage,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export default connect(
    mapStateToProps,
    mergeProps
)(OverviewView);
