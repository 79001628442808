import React from 'react';
import PropTypes from 'prop-types';
import Header from 'app/react/components/layout/content/header/Header';
import * as PerformancesRoutes from 'app/routes/event/artists/performances/routes';

const { TitleMenu } = Header;
const { Title, Actions } = TitleMenu;

const SchedulerContentHeader = ({
    eventId,
    userCanManageTimetable = false
}) => (
    <Header>
        <TitleMenu>
            <Title>Timetable</Title>
            {userCanManageTimetable && (
                <Actions>
                    <a href={PerformancesRoutes.liveTimetable(eventId)} className="button primary" target="_blank">
                        Live timetable
                    </a>
                </Actions>
            )}
        </TitleMenu>
    </Header>
);

SchedulerContentHeader.propTypes = {
    eventId: PropTypes.number.isRequired,
    userCanManageTimetable: PropTypes.bool
};

export default SchedulerContentHeader;
