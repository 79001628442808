import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'ui-kit';
import { Modal } from 'app/react/components/_index';
import SelectParentGroup from './SelectParentGroup';

const { Header, Content, Footer } = Modal;

const NAME_LABEL = 'Name';
const PARENT_LABEL = 'Parent Group';

const CREATE_TITLE = 'Create Group';
const CREATE_BUTTON_TEXT = 'Create';
const CREATE_ANOTHER_BUTTON_TEXT = 'Create Another';

const UPDATE_TITLE = 'Update Group';
const UPDATE_BUTTON_TEXT = 'Update';

const ItemGroupModal = ({
    isCreating = false,
    canCreateAnother = true,
    isOpened = false,
    isSaving = false,
    values = {},
    errors = {},
    groups,
    onClosed = () => {},
    onChangeValue = () => {},
    onSave = () => {},
}) => {
    const hasChildren = groups.find(group => group.parent === values.id);
    const canSelectParent = isCreating || !hasChildren || values.parent !== null;

    return (
        <Modal
            width={460}
            closeOnBackgroundClick={false}
            opened={isOpened}
            syncing={isSaving}
            onClosed={onClosed}
        >
            <Header onClosed={onClosed}>
                <Modal.Header.Title>{isCreating ? CREATE_TITLE : UPDATE_TITLE}</Modal.Header.Title>
            </Header>
            <Content>
                <InputGroup messages={errors.name}>
                    <label className="required">
                        {NAME_LABEL}
                    </label>
                    <input
                        type="text"
                        value={values.name || ''}
                        onChange={e => onChangeValue('name', e.target.value)}
                    />
                </InputGroup>
                {canSelectParent &&
                    <InputGroup messages={errors.parent}>
                        <label>
                            {PARENT_LABEL}
                        </label>
                        <SelectParentGroup
                            groups={groups}
                            value={values.parent}
                            onChange={value => onChangeValue('parent', value)}
                        />
                    </InputGroup>
                }
            </Content>
            {isCreating
                ? <Footer>
                    {canCreateAnother &&
                        <Footer.Button
                            className="create-another"
                            onClick={() => onSave(true)}
                        >
                            {CREATE_ANOTHER_BUTTON_TEXT}
                        </Footer.Button>
                    }
                    <Footer.Button
                        className="success"
                        onClick={() => onSave()}
                    >
                        {CREATE_BUTTON_TEXT}
                    </Footer.Button>
                </Footer>
                : <Footer>
                    <Footer.Button
                        className="success"
                        onClick={() => onSave()}
                    >
                        {UPDATE_BUTTON_TEXT}
                    </Footer.Button>
                </Footer>
            }
        </Modal>
    )
};

ItemGroupModal.propTypes = {
    isCreating: PropTypes.bool,
    canCreateAnother: PropTypes.bool,
    isOpened: PropTypes.bool,
    isSaving: PropTypes.bool,
    values: PropTypes.object,
    errors: PropTypes.object,
    onChangeValue: PropTypes.func,
    onClosed: PropTypes.func,
    onSave: PropTypes.func,
    onCreateAnother: PropTypes.func,
};

export default ItemGroupModal;
