import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    AlertBox,
    InputGroup,
    DateTimeInput,
    PostfixedInput,
} from 'ui-kit';
import { Modal } from 'app/react/components/_index';
import SelectDay from '../../selects/SelectDay';
import SelectStage from '../../selects/SelectStage';

const { Header, Content, Footer, Tabs } = Modal;
const { Cell } = Grid;

const GENERAL_TAB_TEXT = 'General';
const GENERAL_TAB_ID = 'general';
const BIO_TAB_TEXT = 'Publish & Bio';
const BIO_TAB_ID = 'bio';
const PERFORMANCE_NAME_LABEL = 'Performance name';
const PERFORMANCE_CHANGEOVER_LABEL = 'Changeover duration';
const PERFORMANCE_PUBLISHED_DATE_LABEL = 'Publish date';
const PERFORMANCE_BIO = 'Bio';
const PERFORMANCE_START_LABEL = 'Start time';
const PERFORMANCE_END_LABEL = 'End time';
const SELECT_DAY_LABEL = 'Day';
const SELECT_STAGE_LABEL = 'Stage';
const DAY_WARNING = 'You have changed the day. Remember to choose a new start and end time.';

const TITLE = 'Update performance';
const CREATE_BUTTON_TEXT = 'Update';

export const UPDATE_MODAL_ID = 'updatePerformanceModal';

const UpdatePerformanceModal = ({
    isOpened = false,
    isSyncing = false,
    isEditingPerformanceName = false,
    activeTab = GENERAL_TAB_ID,
    selectedDay = {},

    originalPerformance,

    values = [],
    errors = {},

    dateFormat,
    timeFormat,

    days = [],
    stages = [],

    onChangeValue = () => {},
    onSelectDay = () => {},
    onClosed = () => {},
    onSaved = () => {},
    onToggleEditPerformanceName = () => {},
    onChangeTab = () => {}
}) => {
    return (
        <Modal
            width={700}
            closeOnBackgroundClick={false}
            opened={isOpened}
            syncing={isSyncing}
            onClosed={onClosed}
        >
            <Header onClosed={onClosed} hasTabs>
                <Modal.Header.Title>{TITLE}</Modal.Header.Title>
            </Header>
            <Tabs>
                <Tabs.Tab
                    id="general"
                    activeTab={activeTab}
                    onClick={onChangeTab}
                >
                    {GENERAL_TAB_TEXT}
                </Tabs.Tab>
                <Tabs.Tab
                    id="bio"
                    activeTab={activeTab}
                    onClick={onChangeTab}
                >
                    {BIO_TAB_TEXT}
                </Tabs.Tab>
            </Tabs>
            {activeTab === GENERAL_TAB_ID &&
                <Content>
                    <InputGroup messages={errors.title}>
                        <label>{PERFORMANCE_NAME_LABEL}</label>
                        {!values.customTitle 
                            ? <PostfixedInput.Container
                                postfix={!isEditingPerformanceName
                                    ? <button
                                        className="postfix success"
                                        onClick={() => onToggleEditPerformanceName(true)}
                                    >
                                        Edit
                                    </button>
                                    : <button
                                        className="postfix"
                                        onClick={() => onToggleEditPerformanceName(false)}
                                    >
                                        Cancel
                                    </button>
                                }
                            >
                                <input
                                    type="text"
                                    value={values.title || ''}
                                    disabled={!isEditingPerformanceName}
                                    onChange={e => onChangeValue('title', e.target.value)}
                                />
                            </PostfixedInput.Container>
                            : <input
                                type="text"
                                value={values.title || ''}
                                disabled={!isEditingPerformanceName}
                                onChange={e => onChangeValue('title', e.target.value)}
                            />
                        }
                    </InputGroup>
                    {days.length > 1 &&
                        <div>
                            <InputGroup messages={errors.day}>
                                <label className="required">
                                    {SELECT_DAY_LABEL}
                                </label>
                                <SelectDay
                                    days={days}
                                    dateFormat={dateFormat}
                                    timeFormat={timeFormat}
                                    value={values.day}
                                    onChange={value => onSelectDay(value)}
                                />
                            </InputGroup>
                        </div>
                    }
                    <InputGroup messages={errors.stage}>
                        <label className="required">
                            {SELECT_STAGE_LABEL}
                        </label>
                        <SelectStage
                            days={days}
                            stages={values.day ? stages.filter(({ day }) => day === values.day) : stages}
                            value={values.stage}
                            onChange={value => onChangeValue('stage', value)}
                        />
                    </InputGroup>
                    {originalPerformance.day !== values.day &&
                        <AlertBox type={AlertBox.WARNING} className="margin-small-bottom" light>{DAY_WARNING}</AlertBox>
                    }
                    <Grid hasMarginBottom={false}>
                        <Cell>
                            <InputGroup messages={errors.start}>
                                <label className="required">
                                    {PERFORMANCE_START_LABEL}
                                </label>
                                <DateTimeInput
                                    value={values.start}
                                    onChange={value => onChangeValue('start', value)}
                                    dateFormat={dateFormat}
                                    timeFormat={timeFormat}
                                    minDate={selectedDay.start}
                                    maxDate={selectedDay.end}
                                />
                            </InputGroup>
                        </Cell>
                        <Cell>
                            <InputGroup messages={errors.end}>
                                <label className="required">
                                    {PERFORMANCE_END_LABEL}
                                </label>
                                <DateTimeInput
                                    value={values.end}
                                    onChange={value => onChangeValue('end', value)}
                                    dateFormat={dateFormat}
                                    timeFormat={timeFormat}
                                    minDate={selectedDay.start}
                                    maxDate={selectedDay.end}
                                />
                            </InputGroup>
                        </Cell>
                    </Grid>
                    <Grid hasMarginBottom={false}>
                        <Cell isAutosized isContentJustifiedAtStart>
                            <InputGroup messages={errors.changeover}>
                                <label>{PERFORMANCE_CHANGEOVER_LABEL}</label>
                                <PostfixedInput label="min">
                                    <input
                                        type="number"
                                        value={values.changeover || ''}
                                        onChange={e => onChangeValue('changeover', e.target.value)}
                                    />
                                </PostfixedInput>
                            </InputGroup>
                        </Cell>
                    </Grid>
                </Content>
            }
            {activeTab === BIO_TAB_ID &&
                <Content>
                    <InputGroup style={{ maxWidth: '50%' }} messages={errors.publishDate}>
                        <label>{PERFORMANCE_PUBLISHED_DATE_LABEL}</label>
                        <DateTimeInput
                            value={values.publishDate}
                            dateFormat={dateFormat}
                            onChange={value => onChangeValue('publishDate', value)}
                        />
                    </InputGroup>
                    <InputGroup messages={errors.bio}>
                        <label>{PERFORMANCE_BIO}</label>
                        <textarea
                            style={{ height: '185px' }}
                            value={values.bio}
                            onChange={e => onChangeValue('bio', e.target.value)}
                        />
                    </InputGroup>
                </Content>
            }
            <Footer>
                <Footer.Button
                    className="success"
                    onClick={onSaved}
                >
                    {CREATE_BUTTON_TEXT}
                </Footer.Button>
            </Footer>
        </Modal>
    );
};

UpdatePerformanceModal.propTypes = {
    isOpened: PropTypes.bool,
    isSyncing: PropTypes.bool,
    isEditingPerformanceName: PropTypes.bool,

    values: PropTypes.object,
    errors: PropTypes.object,

    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,

    onChangeValue: PropTypes.func,
    onSelectDay: PropTypes.func,
    onClosed: PropTypes.func,
    onSaved: PropTypes.func,
    onToggleEditPerformanceName: PropTypes.func
};

export default UpdatePerformanceModal;
