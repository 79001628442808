import { schema } from 'normalizr';
import { CALL_API, includeParam } from 'app/react/state/middleware/api';
import { createPaginationReducerWithKey } from 'app/react/state/reducers/higher_order/_index';
import {
    getOneEntity,
    getSomeEntities,
    getAllEntitiesByPagination,
    getAllEntitiesByPaginationByKey,
    getEntitiesPaginationByKey,
    isFetchingEntitiesByKey,
    getCurrentAccountId
} from 'app/react/state/helpers';

export const INTEGRATION_EVENTS_REQUEST = 'INTEGRATION_EVENTS_REQUEST';
export const INTEGRATION_EVENTS_SUCCESS = 'INTEGRATION_EVENTS_SUCCESS';
export const INTEGRATION_EVENTS_FAILURE = 'INTEGRATION_EVENTS_FAILURE';

export const PROFILE_ENTITY_REDUCER_KEY = 'integrationEventProfiles';
const profileEntity = new schema.Entity(PROFILE_ENTITY_REDUCER_KEY);
const profileArray = new schema.Array(profileEntity);

export const ENTITY_REDUCER_KEY = 'integrationEvents';
export const PAGINATION_REDUCER_KEY = 'integrationEventsByIntegration';
export const PROFILES_KEY = 'profiles';

const entityDefinition = { [PROFILES_KEY]: profileArray };
const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityDefinition);
const array = new schema.Array(entity);

const getOne = (state, id) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, id);

const getAllByPagination = (state) =>
    getAllEntitiesByPagination(state, ENTITY_REDUCER_KEY);

const getAllByPaginationByTicketProviderId = (state, ticketProviderId) =>
    getAllEntitiesByPaginationByKey(state, ENTITY_REDUCER_KEY, PAGINATION_REDUCER_KEY, ticketProviderId);

const getPaginationByTicketProviderId = (state, ticketProviderId) =>
    getEntitiesPaginationByKey(state, PAGINATION_REDUCER_KEY, ticketProviderId);

const isFetchingAllByTicketProviderId = (state, ticketProviderId) =>
    isFetchingEntitiesByKey(state, PAGINATION_REDUCER_KEY, ticketProviderId);

const getProfilesByEventIntegrationId = (state, eventIntegrationId) => {
    const event = getOne(state, eventIntegrationId) || {};
    return getSomeEntities(state, PROFILE_ENTITY_REDUCER_KEY, event[PROFILES_KEY]);
};

const paginationReducer = createPaginationReducerWithKey({
    mapActionToKey: (action) => action.ticketProviderId,
    types: [
        INTEGRATION_EVENTS_REQUEST,
        INTEGRATION_EVENTS_SUCCESS,
        INTEGRATION_EVENTS_FAILURE
    ]
});

const Endpoints = {
    allByTicketProviderId: (accountId, ticketProviderId) =>
        `accounts/${accountId}/accreditation/ticket-providers/${ticketProviderId}/events?${includeParam(['profiles'])}`
};

const fetchAllByTicketProviderId = (accountId, ticketProviderId) => ({
    ticketProviderId,
    [CALL_API]: {
        types: [
            INTEGRATION_EVENTS_REQUEST,
            INTEGRATION_EVENTS_SUCCESS,
            INTEGRATION_EVENTS_FAILURE
        ],
        endpoint: Endpoints.allByTicketProviderId(accountId, ticketProviderId),
        schema: array
    }
});

const loadAllByTicketProviderId = (ticketProviderId) => (dispatch, getState) => {
    const state = getState();
    const { pageCount = 0 } = getPaginationByTicketProviderId(state, ticketProviderId);
    if (pageCount > 0) return null;
    const accountId = getCurrentAccountId(state);
    return dispatch(fetchAllByTicketProviderId(accountId, ticketProviderId));
};

export const schemas = {
    entity,
    array,
    profileEntity,
    profileArray
};

export const stateHelpers = {
    getOne,
    getAllByPagination,
    getAllByPaginationByTicketProviderId,
    getPaginationByTicketProviderId,
    isFetchingAllByTicketProviderId,
    getProfilesByEventIntegrationId
};

export const reducers = {
    pagination: paginationReducer
};

export const actions = {
    fetchAllByTicketProviderId,
    loadAllByTicketProviderId
};
