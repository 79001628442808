import { schema } from 'normalizr';
import { createReducer } from 'redux-create-reducer';
import { CALL_UPLOAD } from 'app/react/state/middleware/upload';
import { getOneEntity, addOneEntity } from 'app/react/state/helpers';

export const IMAGES_ADD = 'IMAGES_ADD';

export const IMAGES_UPLOAD_REQUEST = 'IMAGES_UPLOAD_REQUEST';
export const IMAGES_UPLOAD_SUCCESS = 'IMAGES_UPLOAD_SUCCESS';
export const IMAGES_UPLOAD_FAILURE = 'IMAGES_UPLOAD_FAILURE';

export const ENTITY_REDUCER_KEY = 'images';
const entityOptions = { idAttribute: 'uuid' };
const entity = new schema.Entity(ENTITY_REDUCER_KEY, entityOptions);

export const schemas = {
    entity
};

const getOne = (state, uuid) =>
    getOneEntity(state, ENTITY_REDUCER_KEY, uuid);

const addOne = (state, image) =>
    addOneEntity(state, ENTITY_REDUCER_KEY, image, 'uuid');

export const stateHelpers = {
    getOne,
    addOne
};

const featureReducer = createReducer({}, {
    [IMAGES_ADD]: (state, action) => {
        return addOne(state, action.image);
    }
});

export const reducers = {
    feature: featureReducer
};

const addImage = (image) => ({
    type: [IMAGES_ADD],
    image
});

const uploadImage = () => ({
    [CALL_UPLOAD]: {
        types: [
            IMAGES_UPLOAD_REQUEST,
            IMAGES_UPLOAD_SUCCESS,
            IMAGES_UPLOAD_FAILURE
        ],
        schema: entity
    }
});

export const actions = {
    uploadImage,
    addImage
};


