import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Form, Formik, Field } from "formik";
import DialogContainer from "../dialogContainer/DialogContainer";
import DialogCard from "../dialogCard/DialogCard";
import DialogCardBottom from "../dialogCard/DialogBottom";
import FormField from "../formfields/FormField";
import Button from "../button/Button";
import { countries } from "country-data";

const CreateVenueValidation = () =>
  yup.object({
    name: yup.string().required(),
  });

const CreateVenueDialog = ({ addVenue, ...props }) => {
  return (
    <DialogContainer>
      <DialogCard {...props}>
        <Formik
          initialValues={{ name: "" }}
          validationSchema={CreateVenueValidation()}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            const { handleChange, isValid, handleBlur, values } = formik;
            return (
              <Fragment>
                <div className="dialog-inner-container">
                  <h3 className="dialog-title">Create your venue</h3>
                  <Form>
                    <FormField
                      onBlur={handleBlur}
                      id="name"
                      name="name"
                      label="Name"
                      required
                      onChange={handleChange}
                    />
                    <div className={"dialog__input-line"}>
                      <FormField
                        modFullwidth
                        onBlur={handleBlur}
                        id="address_line_1"
                        name="address_line_1"
                        label="Street and number"
                        onChange={handleChange}
                      />
                      <FormField
                        modFullwidth
                        onBlur={handleBlur}
                        id="address_line_2"
                        name="address_line_2"
                        label="Address Line 2"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={"dialog__input-line"}>
                      <FormField
                        modFullwidth
                        onBlur={handleBlur}
                        id="zip"
                        name="zip"
                        label="Zip/Postal code"
                        onChange={handleChange}
                      />
                      <FormField
                        modFullwidth
                        onBlur={handleBlur}
                        id="city"
                        name="city"
                        label="City"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={"dialog__input-line"}>
                      <FormField
                        modFullwidth
                        onBlur={handleBlur}
                        id="state"
                        name="state"
                        label="State/Province/Region"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="date_format"
                        className="form-field"
                        style={{ width: "350px" }}
                      >
                        <span className="form-field__label">Country</span>
                        <Field as="select" id="country" name="country">
                          <option value="">Select your country</option>
                          {countries.all.map(({ alpha2, name }, i) => (
                            <option
                              key={`country-${i}-${alpha2}`}
                              value={alpha2}
                            >
                              {name}
                            </option>
                          ))}
                        </Field>
                      </label>
                    </div>
                    <FormField
                      onBlur={handleBlur}
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      onChange={handleChange}
                    />
                    <FormField
                      onBlur={handleBlur}
                      id="telephone"
                      name="telephone"
                      label="Phone number"
                      onChange={handleChange}
                    />
                    <FormField
                      onBlur={handleBlur}
                      id="capacity"
                      name="capacity"
                      label="Capacity"
                      onChange={handleChange}
                    />
                    <FormField
                      onBlur={handleBlur}
                      id="website"
                      name="website"
                      label="Website"
                      onChange={handleChange}
                    />
                  </Form>
                </div>
                <DialogCardBottom>
                  <Button
                    label="Add"
                    variant="primary"
                    type="button"
                    onClick={() => addVenue(values)}
                    isDisabled={!isValid}
                  />
                </DialogCardBottom>
              </Fragment>
            );
          }}
        </Formik>
      </DialogCard>
    </DialogContainer>
  );
};

CreateVenueDialog.propTypes = {
  setCancelDialogOpen: PropTypes.func.isRequired,
  addVenue: PropTypes.func.isRequired,
};

export default CreateVenueDialog;
