import React from 'react'
import PropTypes from 'prop-types'
import by from 'thenby'
import { Grid, SearchInput } from 'ui-kit'
import { TableFilter, EventDaysTableFilter } from 'app/react/components/_index'

const { Cell } = Grid;

const FIRST_DAY_OPTION = { value: 0, label: 'Select start date' };
const LAST_DAY_OPTION = { value: 0, label: 'Select end date' };
const ALL_DRIVERS = { value: 0, label: 'All drivers' };
const ARRIVED_OPTIONS = [{
    value: 0,
    label: 'All pickups'
}, {
    value: true,
    label: 'Arrived pickups'
}, {
    value: false,
    label: 'Not arrived pickups'
}];

const toDriverOption = ({
    id,
    firstname,
    lastname
}) => ({
    value: id,
    label: `${firstname} ${lastname}`
});

const PickupsTableTopBar = ({
    dateFormat,
    days = [],
    drivers = [],

    selectedDeparture = '0',
    selectedArrival = '0',
    selectedDriverId = 0,
    selectedArrivedFilter = 0,
    searchValue,

    onChangeFilter = () => {},
    onSearch = () => {},
    onClearSearch = () => {},
}) => (
    <Grid hasGutters={false} className="margin-tiny-bottom">
        <Cell isContentJustifiedAtStart>
            <TableFilter
                width="150px"
                options={ARRIVED_OPTIONS}
                value={selectedArrivedFilter}
                onChange={value => onChangeFilter('arrived', value === '0' ? 0 : value === 'true')} // 0, true or false
            />
            <EventDaysTableFilter
                allOption={FIRST_DAY_OPTION}
                width="150px"
                days={days}
                dateFormat={dateFormat}
                value={selectedDeparture}
                onChange={value => onChangeFilter('after', value)}
            />
            <EventDaysTableFilter
                endOfDay
                allOption={LAST_DAY_OPTION}
                width="150px"
                days={days}
                dateFormat={dateFormat}
                value={selectedArrival}
                onChange={value => onChangeFilter('before', value)}
            />
            <TableFilter
                width="150px"
                options={[
                    ALL_DRIVERS,
                    ...drivers
                        .sort(by('firstname', 1).thenBy('lastname', 2))
                        .map(toDriverOption),
                ]}
                value={selectedDriverId}
                onChange={value => onChangeFilter('driver', parseInt(value, 10))}
            />
        </Cell>
        <Cell
            isAutoSized
            isContentJustifiedAtEnd
            width="250px"
        >
            <SearchInput
                isExpanded
                value={searchValue || ''}
                placeholder="Artist, driver, departure or arrival..."
                info="You can search for pickups by artist, driver, departure and arrival location."
                onSearch={onSearch}
                onClear={onClearSearch}
            />
        </Cell>
    </Grid>
);

PickupsTableTopBar.propTypes = {
    booking: PropTypes.object,
    onEdit: PropTypes.func,
};

export default PickupsTableTopBar;
