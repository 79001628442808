import OptionTypes from 'app/backbone/helpers/OptionTypes';
import CardActionContainer from './CardActionContainer.view';
import template from './CardHeader.hbs';

export default class CardHeader extends CardActionContainer {

    get template() {
        return template;
    }

    optionTypes() {
        return {
            title: OptionTypes.string.isRequired,
            actions: OptionTypes.array
        };
    }

    optionDefaults() {
        return {
            actions: []
        };
    }


    initialize(options) {
        this.resolveOptions(options);
        this.actions = this.initHeaderActions();
    }

    viewModel() {
        return {
            view: {
                title: this.title,
                isCollapsible: this.isCollapsible
            },
            model: {}
        };
    }

}
