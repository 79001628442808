import React  from 'react'
import Header from 'app/react/components/layout/content/header/Header'

const { TitleMenu } = Header;
const { Title, Actions } = TitleMenu;

const Action = ({
    url,
    children,
}) => (
    <a href={url} className="button">
        {children}
    </a>
);

const CreateEventContentHeader = () => (
    <TitleMenu>
        <Title>Add event</Title>
        <Actions>
            <Action url="/events">Back</Action>
        </Actions>
    </TitleMenu>
);

export default CreateEventContentHeader;
