import React from 'react';
import Header from 'app/react/components/layout/content/header/Header';

const { TitleMenu } = Header;
const { Title } = TitleMenu;

export default class OffersContentHeader extends React.Component {

    render() {
        return (
            <Title>Offers</Title>
        );
    }
}
